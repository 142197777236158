import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppContext } from "../../../../context/app-context/AppContext";

const SingleTableRecordsMeeting = ({
  id,
  title,
  date,
  status,
  secretary,
  head,
}) => {
  const [serachParams] = useSearchParams();
  const navigate = useNavigate();
  const { setBradCrump3 } = useAppContext();

  return (
    <tr
      onClick={() => {
        console.log(id);
        navigate(`/managment-of-meetings/records-meeting/${id}`, {
          state: {
            search: `records-meeting?${serachParams.toString()}`,
          },
        });
        setBradCrump3(title);
      }}
      className="cursor-pointer bg-caspian-surface duration-300"
    >
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[20%]">
        <h2>{title}</h2>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 min-w-[100px] w-[20%]">
        <h2>{date}</h2>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[20%]">
        <h2>{secretary}</h2>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[20%]">
        <h2>{head}</h2>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[20%]">
        <button
          className={`text-xs rounded-md p-1 border ${
            status === "archived"
              ? "bg-[#01BC8D2E] border-[#01BC8D] text-[#01BC8D]"
              : "bg-[#FF5B5B2E] border-[#FF5B5B] text-[#FF5B5B]"
          }`}
        >
          {status === "archived" ? "برگزار شده" : "لغو شده"}
        </button>
      </td>
    </tr>
  );
};

export default SingleTableRecordsMeeting;
