import React from "react";
import {
  PiCalendar,
  PiMapPinLine,
  PiNotePencil,
  PiTrash,
} from "react-icons/pi";
import { useNavigate } from "react-router-dom";

const MainAddressAndTime = ({
  data,
  title,
  setShowDeletePopup,
  isAuditer,
  isChecklist,
  idProfile,
  idPlaning,
  idSection
}) => {
  console.log(data);
  const navigate = useNavigate();
  function convertTimeFormat(time) {
    let [hours, minutes] = time?.split(":");
    return `${hours}:${minutes}`;
  }
  return (
    <div className="p-4 flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between w-full">
          {/* دوره */}
          <div className="flex items-center gap-2">
            <span className="text-caspian-secondary-500 text-sm">
              برنامه دوره ای
            </span>
            <span className="text-xs">{title}</span>
          </div>
          {/* تاریخ */}
          <div className="flex items-center gap-2">
            <span className="text-caspian-gray4 text-xs">تاریخ:</span>
            <div className="text-caspian-gray4 flex items-center gap-2 border border-caspian-gray2 p-1 rounded-md bg-caspian-surface">
              <PiCalendar />
              <span className="text-xs">
                {new Date(data?.auditDate).toLocaleDateString("fa-IR")}
              </span>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between w-full">
          {/* واحد */}
          <div className="flex items-center gap-2">
            <span className="text-base">{data?.sectionRelated?.title}</span>
          </div>
          {/* ساعت */}
          <div className="flex items-center gap-2">
            <span className="text-caspian-gray4 text-xs">ساعت:</span>
            <div className="text-caspian-gray4 flex items-center gap-2 border border-caspian-gray2 p-1 rounded-md bg-caspian-surface">
              <div className="w-1.5 h-1.5 rounded-full bg-caspian-primary-500"></div>

              <span className="text-xs">
                شروع:{" "}
                {data?.AuditScheduleRelatedResponsible[0]?.startTime &&
                  convertTimeFormat(
                    data?.AuditScheduleRelatedResponsible[0]?.startTime
                  )}
              </span>
            </div>
            <div className="text-caspian-gray4 flex items-center gap-2 border border-caspian-gray2 p-1 rounded-md bg-caspian-surface">
              <div className="w-1.5 h-1.5 rounded-full bg-caspian-error"></div>
              <span className="text-xs">
                پایان:{" "}
                {data?.AuditScheduleRelatedResponsible[0]?.endTime &&
                  convertTimeFormat(
                    data?.AuditScheduleRelatedResponsible[0]?.endTime
                  )}
              </span>
            </div>
          </div>
        </div>
        {/* آدرس */}
        <div className="flex items-center gap-2 justify-between w-full">
          <div className="flex items-end gap-0 w-[80%]">
            <PiMapPinLine className="text-caspian-gray4 text-xl w-10" />
            <span className="text-xs lg:text-sm">
              {data?.AuditScheduleRelatedResponsible[0]?.address}
            </span>
          </div>
          {isAuditer && !isChecklist ? (
            <div className="flex items-center gap-2">
              <button onClick={() => setShowDeletePopup(true)} className="">
                <PiTrash className="text-caspian-secondary-500 text-xl" />
              </button>
              <button onClick={() => console.log("edit")} className="">
                <PiNotePencil className="text-caspian-secondary-500 text-xl" />
              </button>
            </div>
          ) : (
            isAuditer &&
            isChecklist && (
              <button
                onClick={() =>
                  navigate(
                    `/audit/${idProfile}/checklist/${idSection}`
                  )
                }
                className="bg-caspian-secondary-500 text-caspian-surface text-xs rounded-md p-1.5"
              >
                ایجاد چک لیست
              </button>
            )
          )}
        </div>
      </div>
      <div className="border-b-2 border-b-caspian-gray2/40"></div>
    </div>
  );
};

export default MainAddressAndTime;
