import { PiTagChevron, PiTray } from "react-icons/pi";
import ListItemSingle from "./ListItemSingle";

const MessageNavbar = ({ buttonActive }) => {
  return (
    <nav className="w-full bg-caspian-surface rtl">
      <ul className="flex items-center gap-12">
        <ListItemSingle
          title="پیام ها "
          active="chat"
          icon={<PiTray />}
          link="/message/chat"
          buttonActive={buttonActive}
        />
        <ListItemSingle
          title="پیام های ذخیره شده"
          active="saved"
          icon={<PiTagChevron />}
          link="/message/saved"
          buttonActive={buttonActive}
        />
      </ul>
    </nav>
  );
};

export default MessageNavbar;
