import React from "react";
import { priceType } from "../../../../../helper/pricetype";

const ProcressTopProfile = ({
  value,
  valueType,
  minValue,
  maxValue,
  valuePercent,
  dateSubmit,
  timeSubmit,
}) => {
  return (
    <div className="w-[300px] relative h-1.5 flex items-center flex-row-reverse rounded-full bg-caspian-gray3">
      <div
        style={{ width: value + "%" }}
        className={" relative rounded-full h-1.5 bg-caspian-secondary-600"}
      >
        {valueType === "روز" ? (
          <span className="absolute w-[100px] text-xs text-caspian-secondary-600  -top-5 ">
            {priceType(valuePercent)} {valueType} از {maxValue} {valueType}
          </span>
        ) : (
          <span className="absolute w-[100px] text-xs text-caspian-secondary-600  -top-5 ">
            {priceType(valuePercent)} {valueType}
          </span>
        )}
      </div>

      <span className="absolute text-[10px] right-0 top-3">
        {priceType(maxValue)} {valueType}
      </span>
      <span className="absolute text-[10px] left-0 top-3">{minValue}</span>

      <div className="absolute -left-52">
        <span className="text-caspian-gray text-xs">
          {timeSubmit} {dateSubmit}
        </span>
      </div>
    </div>
  );
};

export default ProcressTopProfile;
