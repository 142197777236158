const ButtonTab = ({ title, onClick, activeTab, active }) => {
  return (
    <button
      onClick={onClick}
      className={`${
        activeTab === active
          ? "cursor-default bg-caspian-surface outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 rounded-md"
          : " cursor-default"
      } w-fit text-xs p-2`}
    >
      {title}
    </button>
  );
};

export default ButtonTab;
