import React from 'react'
import { PiCaretLeft } from "react-icons/pi";
import { PiCloudArrowDown } from "react-icons/pi";
import { useNavigate } from 'react-router-dom'
const SingleListItemBlog = ({ item }) => {

    const navigate = useNavigate()
    return (
        <div className='flex flex-col items-center w-full py-2 basis-1/2 lg:basis-1/3 xl:basis-1/4'>
            <div className='flex flex-col items-end justify-around w-[95%] gap-3 border border-caspian-gray2 rounded-xl min-h-[280px] h-[310px]  px-3'>

                <div className='flex flex-col gap-2 w-full'>
                    <div className='w-full h-[150px] overflow-hidden flex items-center justify-center rounded-md bg-caspian-gray3'>
                        <img
                            className=' aspect-[2/3] w-full object-contain'
                            src={item?.image ? item?.image : '/assets/images/blog/not-found.jpg'}
                            alt=''
                        />
                    </div>

                    <div className='text-xs 2xl:text-sm text-caspian-darkgray-700 w-full'>
                        <span>{item?.title}</span>
                    </div>

                    <div className='w-full'>
                        <p className='text-xs text-caspian-gray line-clamp-2'>
                            {item?.descrition?.length > 120 ?
                                `${item?.describtion?.slice(0, 120)}${item?.describtion?.length > 120 ? '...' : ''}` :
                                (item?.describtion)}
                        </p>
                    </div>
                    {/* <div className='w-full'>
                        <p className='text-caspian-gray line-clamp-2 text-xs'
                            dangerouslySetInnerHTML={{ __html: item?.describtion }}>
                        </p>
                    </div> */}
                </div>

                <div className='flex items-end justify-between gap-2 w-full'>

                    <div className='text-xs text-caspian-gray'>
                        <span>
                            {new Date(item?.created_at).toLocaleDateString('fa-IR')}
                        </span>
                    </div>

                    <div className='flex items-center justify-end text-caspian-secondary-500 cursor-pointer'
                        onClick={() => navigate('/blog/' + item?.id)}>
                        <span className='text-xs'>جزئیات بیشتر</span>

                        <PiCaretLeft className='text-lg' />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SingleListItemBlog