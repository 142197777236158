import React from 'react'
// import FilterTabsProfileActionPlan from './FilterTabsProfileActionPlan'
import MainListProfileGoalActionplan from './list-profile-goal/MainListProfileGoalActionplan'

const MainTabsProfileActionPlan = ({activeTab}) => {
    return (
        <div className='flex w-full flex-col items-start px-6 gap-3' >
            <div className='w-full flex items-start justify-center'>

                <MainListProfileGoalActionplan activeTab={activeTab} />

            </div>
        </div>
    )
}

export default MainTabsProfileActionPlan