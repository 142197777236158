import React, { useContext } from "react";
import ActionPlanNavbar from "../ActionPlanNavbar";
import MainListActionplan from "./list-actionplan/MainListActionplan";
import { useNavigate, useOutletContext } from "react-router-dom";
import { PiIdentificationBadge } from "react-icons/pi";
import { AuthContext } from "../../../context/auth/AuthContext";
import Loading from "../../../Loading";

const MainPlans = () => {
  const { user } = useContext(AuthContext);
  const pmo = useOutletContext();
  const navigate = useNavigate();
  return pmo && user?.id ? (
    <div className="flex flex-col gap-2">
      <ActionPlanNavbar buttonActive="plans" />
      <div className="flex flex-col gap-2 p-2">
        {pmo === user?.id ? (
          <button
            onClick={() => navigate("/action-plan/plans/pmo")}
            className="w-fit flex items-center gap-1 rounded-md border border-caspian-secondary-500 text-caspian-secondary-500 text-xs p-2"
          >
            <PiIdentificationBadge className="text-xl" />
            <span className="text-xs">دفتر PMO</span>
          </button>
        ) : (
          <button
            onClick={() => navigate("/action-plan/plans/pm")}
            className="w-fit flex items-center gap-1 rounded-md border border-caspian-secondary-500 text-caspian-secondary-500 text-xs p-2"
          >
            <PiIdentificationBadge className="text-xl" />
            <span className="text-xs">برنامه‌های من</span>
          </button>
        )}
        <MainListActionplan />
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default MainPlans;
