import React, { useContext } from "react";
import { PiFileTextFill, PiXCircle } from "react-icons/pi";
import API from "../../../../../../api/API";
import { AuthContext } from "../../../../../../context/auth/AuthContext";

const PresentationSingle = ({ data, setFileId, fileId }) => {
  const { token } = useContext(AuthContext);

  const deleteFile = async (id) => {
    await API.delete(`/api/v1/meeting/info/document/${id}`, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        console.log(res.data);
        setFileId(fileId.filter((d) => d.id !== data.id));
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="inline-block border border-caspian-gray2 p-1.5 rounded-md w-fit">
      <div className="flex items-center gap-2">
        <span className="text-caspian-gray2 text-xs">
          {(data?.file?.size / 1024).toFixed()}KB
        </span>
        <div className="flex items-center gap-0">
          <span className="text-caspian-gray text-xs">{data?.file?.name}</span>
          <PiFileTextFill className="text-xl text-caspian-secondary-600" />
        </div>
        <PiXCircle
          onClick={() => deleteFile(data?.id)}
          className="text-[#FF5B5B] text-xl cursor-pointer"
        />
      </div>
    </div>
  );
};

export default PresentationSingle;
