import React, { useContext, useEffect, useState } from "react";
import MainAddProcessActivities from "./add-process-activites/MainAddProcessActivities";
import MainAddressDescForm from "./address-desc-form/MainAddressDescForm";
import MainMembersAndTimesForm from "./members-times-form/MainMembersAndTimesForm";
import API from "../../../../../api/API";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import { PiTrash } from "react-icons/pi";
import SingleProcessList from "./SingleProcessList";
import StartTimePicker from "./members-times-form/times/StartTimePicker";
import EndTimePicker from "./members-times-form/times/EndTimePicker";

const StepTwoForm = ({
  scheduleTimeId,
  membersList,
  address,
  setAddress,
  description,
  setDescription,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  supervisorSelected,
  setSupervisorSelected,
  membersSelected,
  setMembersSelected,
  membersFinalSelected,
  setMembersFinalSelected,
  membersExpertSelected,
  setMembersExpertSelected,
  membersExpertFinalSelected,
  setMembersExpertFinalSelected,
  membersObserverSelected,
  setMembersObserverSelected,
  membersObserverFinalSelected,
  setMembersObserverFinalSelected,
  processSelected,
  setProcessSelected,
  activitiesSelected,
  setActivitiesSelected,
  activitiesFinalSelected,
  setActivitiesFinalSelected,
  processSchedulesList,
  setProcessSchedulesList,
  windowHeight,
}) => {
  const { token } = useContext(AuthContext);

  const [refreshPL, setRefreshPL] = useState(0);
  useEffect(() => {
    setRefreshPL(1);
  }, []);
  useEffect(() => {
    if (!token || !scheduleTimeId || refreshPL === 0) return;
    const getProcesses = async () => {
      try {
        const res = await API.get(
          `/api/v1/audit/profile/schedule/process/list/${scheduleTimeId}`,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data);
        setProcessSchedulesList(res?.data);
        setRefreshPL(0);
      } catch (error) {
        console.log(error);
      }
    };
    getProcesses();
  }, [token, scheduleTimeId, refreshPL, setProcessSchedulesList]);
  return (
    <div className="flex flex-col gap-2 w-full h-full">
      <div className="flex gap-2 w-full">
        <div className="w-1/2 h-full flex flex-col gap-2">
          <div className="border border-caspian-secondary-400 rounded-md p-2 min-h-[180px] max-h-[180px] 2xl:min-h-[220px] 2xl:max-h-[220px] overflow-y-auto">
            {/* افزودن فرانید و فعالیت های مرتبط */}
            <MainAddProcessActivities
              scheduleTimeId={scheduleTimeId}
              processSelected={processSelected}
              setProcessSelected={setProcessSelected}
              activitiesSelected={activitiesSelected}
              setActivitiesSelected={setActivitiesSelected}
              activitiesFinalSelected={activitiesFinalSelected}
              setActivitiesFinalSelected={setActivitiesFinalSelected}
              setRefreshPL={setRefreshPL}
            />
            <div className="flex flex-col gap-0 w-full">
              {processSchedulesList && processSchedulesList?.length > 0 ? (
                processSchedulesList?.map((p, i) => (
                  <SingleProcessList
                    key={i}
                    title={p?.processActivityRelated[0].processTitle}
                    id={p?.id}
                    data={p?.processActivityRelated}
                    setRefreshPL={setRefreshPL}
                  />
                ))
              ) : (
                <span className="text-xs text-caspian-gray4">
                  فرایند اضافه نشده است!
                </span>
              )}
              {/* <SingleProcessList title="فرایند 1" />
            <SingleProcessList title="فرایند 2" />
            <SingleProcessList title="فرایند 3" /> */}
            </div>
          </div>
          <div className="flex items-center justify-between w-full gap-2">
            <div className="flex flex-col gap-1 w-full">
              <label className="text-xs 2xl:text-sm text-caspian-gray4">
                ساعت شروع
              </label>
              <StartTimePicker setStartTime={setStartTime} />
            </div>
            <div className="flex flex-col gap-1 w-full">
              <label className="text-xs 2xl:text-sm text-caspian-gray4">
                ساعت پایان
              </label>
              <EndTimePicker setEndTime={setEndTime} />
            </div>
          </div>
        </div>
        {/* انتخاب سرپرست ممیزی و انتخاب افراد تیم ممیزی */}
        <div className="w-1/2">
          <MainMembersAndTimesForm
            membersList={membersList}
            startTime={startTime}
            setStartTime={setStartTime}
            endTime={endTime}
            setEndTime={setEndTime}
            supervisorSelected={supervisorSelected}
            setSupervisorSelected={setSupervisorSelected}
            membersSelected={membersSelected}
            setMembersSelected={setMembersSelected}
            membersFinalSelected={membersFinalSelected}
            setMembersFinalSelected={setMembersFinalSelected}
            membersExpertSelected={membersExpertSelected}
            setMembersExpertSelected={setMembersExpertSelected}
            membersExpertFinalSelected={membersExpertFinalSelected}
            setMembersExpertFinalSelected={setMembersExpertFinalSelected}
            membersObserverSelected={membersObserverSelected}
            setMembersObserverSelected={setMembersObserverSelected}
            membersObserverFinalSelected={membersObserverFinalSelected}
            setMembersObserverFinalSelected={setMembersObserverFinalSelected}
          />
        </div>
      </div>
      <div className="w-full flex items-center justify-between gap-2">
        {/* فرم شروع پایان آدرس و توضیحات */}
        <MainAddressDescForm
          address={address}
          setAddress={setAddress}
          description={description}
          setDescription={setDescription}
          windowHeight={windowHeight}
        />
      </div>
    </div>
  );
};

export default StepTwoForm;
