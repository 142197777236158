import React from 'react';

const ButtonTab = ({activeTab,buttonTab,title,handleClick}) => {
    return (
        <button className={`${activeTab===buttonTab?'bg-caspian-secondary-50 text-[#3C77A2] border-b-2 border-[#3C77A2]':'text-caspian-gray4 border-b-2 border-caspian-gray2'} text-sm w-1/2 py-2`}
        onClick={handleClick}
        >
            {title}
        </button>
    );
}

export default ButtonTab;
