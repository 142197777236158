const WeightChart = ({ percentage }) => {
  const radius = 50;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - ((100 - percentage) / 100) * circumference;
  return (
    <div className="flex flex-col items-center relative">
      <div className="absolute bottom-3 -right-10 text-caspian-secondary-500 flex flex-col items-end">
        <span className="text-xs">تعریف شده</span>
        <span className="font-bold text-sm">{100 - percentage} %</span>
      </div>
      <div className="flex flex-col items-center justify-center relative">
        <svg className="w-32 h-32">
          <circle
            className="text-caspian-gray2/50"
            strokeWidth="6"
            stroke="currentColor"
            fill="transparent"
            r={radius}
            cx="64"
            cy="64"
          />
          <circle
            className="text-caspian-secondary-500"
            strokeWidth="6"
            strokeDasharray={circumference}
            strokeDashoffset={offset}
            strokeLinecap="round"
            stroke="currentColor"
            fill="transparent"
            r={radius}
            cx="64"
            cy="64"
            style={{ transition: "stroke-dashoffset 0.35s" }}
          />
        </svg>
        <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-caspian-gray4 flex flex-col items-center justify-center">
          <span className="text-xs">باقی مانده</span>
          <span className="font-bold">{percentage} %</span>
        </div>
      </div>
    </div>
  );
};

export default WeightChart;
