import React, { useEffect, useState } from "react";
import { PiX } from "react-icons/pi";
import DraftMeetingForm from "./DraftMeetingForm";
import API from "../../../../api/API";

const MainDraftMeetingForm = ({
  setShowDraftMeetingForm,
  setRefresh,
  draftId,
}) => {
  const [fullData, setFullData] = useState([-1]);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(null);
  const [dataRefresh, setDataRefresh] = useState(0);

  useEffect(() => {
    setId(draftId);
  }, [draftId]);

  useEffect(() => {
    if (id !== null) {
      setLoading(true);
      let url = `/api/v1/meeting/profile/detail/${id}/`;
      API.get(url)
        .then((d) => {
          setFullData(d?.data);
          setDataRefresh(0);
        })
        .catch((err) => {
          console.log("error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, dataRefresh]);

  // console.log(fullData);
  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full z-50">
      <div className="fixed inset-0 bg-caspian-gray3/50 z-40 p-4"></div>
      <div className="z-50 fixed flex items-center justify-center p-2">
        <div className="min-w-[50vw] max-w-[50vw] bg-caspian-surface shadow-[0_0_5px_#00000020] rounded-lg flex flex-col gap-3 py-3 px-4">
          <div className="flex items-center justify-between">
            <h2 className="text-sm font-bold">تشکیل پروفایل جلسات</h2>
            <PiX
              onClick={() => setShowDraftMeetingForm(false)}
              className="cursor-pointer z-50"
            />
          </div>
          <p className="text-xs text-caspian-gray">
            برای ایجاد پروفایل جلسات اطلاعات خواسته شده را وارد کنید.
          </p>
          <DraftMeetingForm
            id={id}
            dataDraft={fullData}
            setDataRefresh={setDataRefresh}
            setRefresh={setRefresh}
            setShowDraftMeetingForm={setShowDraftMeetingForm}
          />
        </div>
      </div>
    </div>
  );
};

export default MainDraftMeetingForm;
