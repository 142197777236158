import React from "react";

const ColorPickerNew = ({ setColor, setColorPicker }) => {
  return (
    <div className="shadow-[0_0_5px_#00000050] bg-caspian-surface rounded-md p-2 flex flex-col gap-2 w-32 absolute top-5 left-0">
      <div className="flex items-center justify-around gap-2 w-full">
        <span
          onClick={() => {
            setColor("#e9c2c2");
            setColorPicker(false);
          }}
          className="bg-opacity-100 cursor-pointer w-4 h-4 rounded-full bg-[#e9c2c2] shadow-[0_0_5px_#00000040]"
        ></span>
        <span
          onClick={() => {
            setColor("#fcd0fd");
            setColorPicker(false);
          }}
          className="bg-opacity-100 cursor-pointer w-4 h-4 rounded-full bg-[#fcd0fd] shadow-[0_0_5px_#00000040]"
        ></span>
        <span
          onClick={() => {
            setColor("#aaff8b");
            setColorPicker(false);
          }}
          className="bg-opacity-100 cursor-pointer w-4 h-4 rounded-full bg-[#aaff8b] shadow-[0_0_5px_#00000040]"
        ></span>
        <span
          onClick={() => {
            setColor("#ffffff");
            setColorPicker(false);
          }}
          className="bg-opacity-100 cursor-pointer w-4 h-4 rounded-full bg-[#ffffff] shadow-[0_0_5px_#00000040]"
        ></span>
      </div>

      <div className="flex items-center justify-around gap-2">
        <span
          onClick={() => {
            setColor("#68bcec");
            setColorPicker(false);
          }}
          className="bg-opacity-100 cursor-pointer w-4 h-4 rounded-full bg-[#68bcec] shadow-[0_0_5px_#00000040]"
        ></span>
        <span
          onClick={() => {
            setColor("#FFF7CB");
            setColorPicker(false);
          }}
          className="bg-opacity-100 cursor-pointer w-4 h-4 rounded-full bg-[#FFF7CB] shadow-[0_0_5px_#00000040]"
        ></span>
        <span
          onClick={() => {
            setColor("#fdcb7f");
            setColorPicker(false);
          }}
          className="bg-opacity-100 cursor-pointer w-4 h-4 rounded-full bg-[#fdcb7f] shadow-[0_0_5px_#00000040]"
        ></span>
        <span
          onClick={() => {
            setColor("#c4c4c4");
            setColorPicker(false);
          }}
          className="bg-opacity-100 cursor-pointer w-4 h-4 rounded-full bg-[#c4c4c4] shadow-[0_0_5px_#00000040]"
        ></span>
      </div>
    </div>
  );
};

export default ColorPickerNew;
