import React from "react";
import ProcessNavbar from "../ProcessNavbar";
import { useAppContext } from "../../../context/app-context/AppContext";
import { useEffect } from "react";

const MapIdProcess = ({ showMenu }) => {
  const { setBradCrump1, setBradCrump2, setBradCrump3 } = useAppContext();
  useEffect(() => {
    setBradCrump1("مدریت فرایند");
    setBradCrump2("نقشه فرایندی");
    setBradCrump3("");
  }, [setBradCrump1, setBradCrump2, setBradCrump3]);

  return (
    <div
      className={
        showMenu
          ? `pl-[50px] mr-auto w-full md:w-[75%] lg:w-[80%] xl:w-[85%] 2xl:w-[85%] bg-caspian-gray3 p-1 overflow-y-scroll`
          : `pl-[50px] mr-[54px] w-full bg-caspian-gray3 p-1 overflow-y-scroll`
      }
    >
      <ProcessNavbar buttonActive="map" />
    </div>
  );
};

export default MapIdProcess;
