import MainZoneTwo from "./zone-two/MainZoneTwo";
import MainZoneOne from "./zone-one/MainZoneOne";
import MainZoneThree from "./zone-three/MainZoneThree";
import { useEffect, useState } from "react";
import API from "../../../api/API";

const MainModelProcess = () => {
  const url = "/api/v1/businessProcess/related/Zone/";

  const [fullData, setFullData] = useState([-1]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    API.get(url)
      .then((d) => {
        setFullData(d.data);
      })
      .catch((err) => {
        console.log("error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  console.log(fullData);
  return (
    <div className="w-full h-full bg-caspian-surface rounded-md shadow-[0_0_10px_#00000056] flex items-center justify-center p-1">
      <div className="h-[95%] flex items-center justify-center">
        <img
          src="/assets/images/process/process-model-fill.svg"
          alt="process model"
          className="h-full w-auto"
        />
      </div>

      <div className="flex flex-col items-center justify-start w-[90%] h-full p-4">
        {/* Start Zone #1 */}
        {fullData.map(
          (d, i) =>
            d &&
            d.id === 1 && (
              <MainZoneOne
                key={i}
                data={d.categoryZoneRelated}
                title={d.title}
              />
            )
        )}
        {/* End Zone #1 */}

        {/* Start Zone #2 */}
        {fullData.map(
          (d, i) =>
            d &&
            d.id === 2 && (
              <MainZoneTwo
                key={i}
                data={d.categoryZoneRelated}
                title={d.title}
              />
            )
        )}
        {/* End Zone #2 */}

        {/* Start Zone #3 */}
        {fullData.map(
          (d, i) =>
            d &&
            d.id === 3 && (
              <MainZoneThree
                key={i}
                data={d.categoryZoneRelated}
                title={d.title}
              />
            )
        )}
        {/* End Zone #3 */}
      </div>
    </div>
  );
};

export default MainModelProcess;
