import React, { useEffect } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

const DocumentDetails = ({ data }) => {
  const navigate = useNavigate();
  const [seacrhParams, setSearchParams] = useSearchParams();
  const keyword = seacrhParams.get("keyword");

  function handleFilterChange(key, value) {
    setSearchParams((prevParams) => {
      if (value === null || value === "") {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    });
  }

  // useEffect(() => {
  //   keyword
  //     ? navigate(
  //         `/documents-and-records/documents/internal-documents?search=${keyword}`
  //       )
  //     : navigate();
  // }, [keyword, navigate]);
  return (
    <div className="flex flex-col justify-between items-center gap-3 w-full h-full p-3">
      <div className="flex flex-col items-center mx-auto gap-3 w-[90%]">
        <div className="flex items-center gap-10 border-b border-caspian-gray3 pb-3 w-full">
          <span className="text-caspian-gray text-sm">نوع :</span>
          <h2 className="text-sm">
            {data?.internalDocumentRelated?.InternalDocumentTypeRelated?.title}
          </h2>
        </div>
        <div className="flex items-center gap-10 border-b border-caspian-gray3 pb-3 w-full">
          <span className="text-caspian-gray text-sm">متولی مدرک</span>
          <h2 className="text-sm">
            {data?.ownerPlacementRelated?.placementPositionRelated?.title}
          </h2>
        </div>
        <div className="flex items-center gap-10 border-b border-caspian-gray3 pb-3 w-full">
          <span className="text-caspian-gray text-sm">واحد متولی:</span>
          <div className="flex items-center gap-2">
            <span className="text-sm">{data?.OwnerSectionsRelated?.title}</span>
          </div>
          {/* <div className="flex items-center gap-2">
            {data?.OwnerPartsRelated?.map((d, i) => (
              <span key={i} className="text-sm">
                {d.title}{" "}
                {data?.OwnerPartsRelated?.length - 1 !== i && <span>,</span>}
              </span>
            ))}
          </div> */}
        </div>
        <div className="flex items-center gap-10 border-b border-caspian-gray3 pb-3 w-full">
          <span className="text-caspian-gray text-sm">پست تایید کننده:</span>
          <h2 className="text-sm">{data?.verifying?.title}</h2>
        </div>
        <div className="flex items-center gap-10 border-b border-caspian-gray3 pb-3 w-full">
          <span className="text-caspian-gray text-sm">پست تصویب کننده:</span>
          <h2 className="text-sm">{data?.approval?.title}</h2>
        </div>
        <div className="flex items-start gap-10 border-b border-caspian-gray3 pb-3 w-full">
          <span className="text-caspian-gray text-sm whitespace-nowrap">
            واحد های بهره بردار:
          </span>
          <div className="flex flex-wrap gap-2">
            {data?.users?.map((d, i) => (
              <span key={i} className="text-sm">
                {d.title}
                {data?.users?.length - 1 !== i && <span>, </span>}
              </span>
            ))}
          </div>
        </div>
        <div className="flex items-center gap-10 w-full">
          <span className="text-caspian-gray text-sm">سیستم مدیریت مرتبط:</span>
          <div className="flex items-center gap-4">
            <h2 className="text-sm">-</h2>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap gap-2">
        {data?.keyword?.map((k, i) => (
          <button
            className="bg-caspian-gray3 text-caspian-on_primary/70 outline outline-1 outline-caspian-on_primary/70 p-2 rounded-xl text-[10px] font-bold"
            key={i}
            type="button"
            onClick={() => {
              handleFilterChange("keyword", k ? k : "");
              handleFilterChange("page", 1);
            }}
          >
            {k}
          </button>
        ))}
      </div>

      <div className="border-t border-caspian-gray3 pt-2 flex flex-col gap-4 w-[90%] mx-auto">
        <h2 className="text-sm">کلیدواژه</h2>
        <div className="flex flex-wrap gap-2">
          {data?.keyWords?.map((k, i) => (
            <Link
              to={`../documents/internal-documents?tag=${k}`}
              className="bg-caspian-gray3 text-caspian-on_primary/70 outline outline-1 outline-caspian-on_primary/70 p-2 rounded-xl text-[10px] font-bold"
              key={i}
              // type="button"
              // onClick={() => {
              //   handleFilterChange("keyword", k ? k : "");
              //   handleFilterChange("page", 1);
              // }}
            >
              {k}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DocumentDetails;
