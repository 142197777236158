import React from "react";

const InputTableSingle = ({
  title,
  type,
  provider,
  dataTransfer,
  system,
  className,
}) => {
  return (
    <tr
      className={`hover:bg-caspian-gray3 text-sm ${className}`}
    >
      <td className="text-right px-3 py-6">
        <h2>{title}</h2>
      </td>
      <td className="text-caspian-secondary-600 text-right px-3 py-6">
        <h2>{type}</h2>
      </td>
      <td className="text-right px-3 py-6">
        <input
          type="text"
          disabled
          className="w-full p-2.5 rounded-md text-sm disabled:bg-caspian-transparent outline outline-1 disabled:outline-caspian-gray2"
          defaultValue={provider}
        />
      </td>
      <td className="text-caspian-secondary-600 text-right px-3 py-6">
        <h2>{dataTransfer}</h2>
      </td>
      <td className="text-right px-3 py-6">
        {system ? (
          <input
            type="text"
            disabled
            className="w-full p-2.5 rounded-md text-sm disabled:bg-caspian-transparent outline outline-1 disabled:outline-caspian-gray2"
            defaultValue={system}
          />
        ) : (
          <></>
        )}
      </td>
    </tr>
  );
};

export default InputTableSingle;
