import { useState } from "react";
import { PiMagnifyingGlassBold, PiXBold } from "react-icons/pi";

const ListMembers = ({
  memberSelected,
  setMemberSelected,
  membersFinalSelected,
  setShowMembers,
  members,
  setMembersFinalSelected,
}) => {
  console.log(memberSelected);

  const [searchByPostAndProfile, setSearchByPostAndProfile] = useState("");
  let searchPostAndProfile = [];
  if (members !== -1) {
    searchPostAndProfile = searchByPostAndProfile
      ? members.filter(
          (d) =>
            (d.placementPositionRelated?.title !== undefined &&
              d.placementPositionRelated?.title
                .toLocaleLowerCase()
                .includes(searchByPostAndProfile.toLocaleLowerCase())) ||
            (d !== undefined &&
              (d?.firstName + " " + d?.lastName)
                .toLocaleLowerCase()
                .includes(searchByPostAndProfile.toLocaleLowerCase()))
        )
      : members;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full z-50">
      <div className="bg-caspian-surface rounded-md shadow-[0_0_5px_#00000050] p-3 w-[400px] flex flex-col gap-3">
        <div className="flex items-center justify-between">
          <h2 className="text-sm font-bold">انتخاب اعضای اصلی</h2>
          <button
            onClick={async () => {
              await setMemberSelected(membersFinalSelected);
              setShowMembers(false);
            }}
          >
            <PiXBold className={"text-caspian-gray1 text-sm"} />
          </button>
        </div>
        {/* جستجو و لغو */}
        <div className="flex items-center justify-between w-full">
          <div className="relative w-[50%]">
            <input
              onChange={(e) => setSearchByPostAndProfile(e.target.value)}
              type="search"
              id="search"
              className="outline-none outline-caspian-gray1 p-0.5 text-xs rounded-md outline-1 w-full placeholder:text-xs placeholder:text-caspian-gray pr-6"
              placeholder="جستجو"
            />
            <label
              htmlFor="search"
              className="absolute top-1/2 -translate-y-1/2 right-1"
            >
              <PiMagnifyingGlassBold className="text-caspian-gray text-" />
            </label>
          </div>
          <span className="text-xs text-caspian-secondary-600">
            {memberSelected.length > 0
              ? memberSelected.length + " مورد انتخاب شده"
              : "موردی انتخاب نشده"}
          </span>
        </div>
        {/* لیست اعضا */}
        <div className="max-h-[300px] overflow-y-scroll">
          {members[0] === -1 ? (
            <h2>Loading...</h2>
          ) : (
            searchPostAndProfile.length > 0 &&
            searchPostAndProfile?.map((m, i) => (
              <div
                key={i}
                className="flex items-center gap-1 py-2 border-t border-t-caspian-gray2"
              >
                {/* {memberSelected?.map((a) => console.log(a))} */}
                <input
                  checked={memberSelected?.some(
                    (a) =>
                      a?.id ===
                      m?.id
                  )}
                  onChange={(e) => {
                    setMemberSelected(
                      e.target.checked
                        ? [...memberSelected, m]
                        : memberSelected?.filter(
                            (mf) =>
                              mf?.id !==
                              m?.id
                          )
                    );
                  }}
                  type="checkbox"
                  id={m?.id}
                  className="accent-caspian-primary-400 cursor-pointer"
                />

                <label
                  htmlFor={m?.id}
                  className="flex items-center gap-8 w-full text-xs cursor-pointer"
                >
                  <span className="">
                    {m?.firstName +
                      " " +
                      m?.lastName}
                  </span>
                  <span className="mr-auto">کارشناس</span>
                </label>
              </div>
            ))
          )}
        </div>
        {/* تایید و لغو */}
        <div className="flex items-center gap-4">
          <button
            onClick={async () => {
              await setMembersFinalSelected(memberSelected);
              setShowMembers(false);
            }}
            className="rounded-md py-1.5 px-4 text-xs text-caspian-secondary-600 border border-caspian-secondary-600"
          >
            افزودن
          </button>
          <button
            onClick={async () => {
              await setMemberSelected(membersFinalSelected);
              await setShowMembers(false);
            }}
            className="text-xs text-[#FF5B5B]"
          >
            لغو
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListMembers;
