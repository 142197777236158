const ListGoal = ({ goalList }) => {
  return goalList?.length > 0 ? (
    goalList?.map((p, i) => (
      <div key={i} className="p-2">
        {p?.goalsRelatedRiskTeam?.map((g, i) => (
          <div
            className="text-xs flex items-center gap-2 even:bg-caspian-gray2/30 p-1"
            key={i}
          >
            <span>{g?.title}</span>
            <span>{g?.description}</span>
          </div>
        ))}
      </div>
    ))
  ) : (
    <div className="flex items-center justify-center gap-2 py-8">
      <span className="text-xs text-caspian-gray4">هدفی موجود نیست</span>
    </div>
  );
};

export default ListGoal;
