import React from "react";

const ProcessIndicatorsTableSingle = ({
  title,
  type,
  formula,
  monitoringPeriod,
  dataStorageSources,
  addressDataStorage,
  className,
}) => {
  return (
    <tr className={`hover:bg-caspian-gray3 duration-300 text-sm ${className}`}>
      <td className="text-right px-3 py-6">
        <h2>{title}</h2>
      </td>
      <td className="text-right px-3 py-6">
        <input
          type="text"
          disabled
          className="w-full p-2.5 rounded-md text-sm disabled:bg-caspian-transparent outline outline-1 disabled:outline-caspian-transparent text-caspian-secondary-600"
          defaultValue={type}
        />
      </td>
      <td className="text-right px-3 py-6">
        <textarea
          type="text"
          disabled
          className="resize-none w-full p-2.5 rounded-md text-sm disabled:bg-caspian-transparent outline outline-1 disabled:outline-caspian-gray2"
          defaultValue={formula}
        />
      </td>
      <td className="text-right px-3 py-6">
        <input
          type="text"
          disabled
          className="w-full p-2.5 rounded-md text-sm disabled:bg-caspian-transparent outline outline-1 disabled:outline-caspian-gray2"
          defaultValue={monitoringPeriod}
        />
      </td>
      <td className="text-right px-3 py-6">
        <input
          type="text"
          disabled
          className="w-full p-2.5 rounded-md text-sm disabled:bg-caspian-transparent outline outline-1 disabled:outline-caspian-transparent text-caspian-secondary-600"
          defaultValue={dataStorageSources}
        />
      </td>
      <td className="text-right px-3 py-6">
        <input
          type="text"
          disabled
          className="w-full p-2.5 rounded-md text-sm disabled:bg-caspian-transparent outline outline-1 disabled:outline-caspian-gray2"
          defaultValue={addressDataStorage}
        />
      </td>
    </tr>
  );
};

export default ProcessIndicatorsTableSingle;
