import ReplySingle from "./ReplySingle";

const ReplyMain = ({ replay, setReplay, item }) => {

  return (
    <div className="flex flex-col gap-0 pr-4 pb-4">

      <ReplySingle replay={replay} setReplay={setReplay} />
    </div>
  );
};

export default ReplyMain;
