import { useState } from "react";
import { PiCalendar, PiCaretDownBold, PiCaretLeftBold } from "react-icons/pi";

const MainProfileDetails = ({ fullData }) => {
  console.log(fullData);

  const [showDescription, setShowDescription] = useState(false);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-4 border-b border-b-caspian-gray2 pb-2">
        <div className="flex items-start justify-between">
          <div className="flex items-start gap-3">
            <div className="bg-caspian-gray2/40 p-1.5 rounded-md outline-1 outline-caspian-surface outline -outline-offset-2">
              <PiCalendar className="text-2xl text-caspian-gray4" />
            </div>
            <div className="flex flex-col justify-start gap-1">
              <div className="flex items-center gap-3">
                <h2 className="text-xs font-bold">{fullData?.title}</h2>
                <span className="text-xs font-bold text-caspian-error2">
                  {fullData?.privacy}
                </span>
              </div>
              <p className="text-xs text-caspian-gray">
                جلسه {fullData?.meetingTypeRelated?.title}
              </p>
            </div>
          </div>
          <span className="text-xs text-caspian-gray">
            کد پروفایل w-{fullData?.id}
          </span>
        </div>
        {/* توضیحات */}
        <div className="">
          <p
            className={`${
              showDescription ? "" : "line-clamp-3"
            } text-xs text-caspian-gray leading-6`}
          >
            <span className="font-bold text-xs text-caspian-gray4">
              توضیحات
            </span>{" "}
            {fullData?.description}
          </p>
          <div
            onClick={() => setShowDescription(!showDescription)}
            className="flex items-center gap-2 cursor-pointer my-2"
          >
            <span className="text-xs text-caspian-secondary-600">
              {!showDescription ? "جزیئات بیشتر" : "مخفی کردن جزئیات"}
            </span>
            {!showDescription ? (
              <PiCaretLeftBold className="text-sm text-caspian-secondary-600" />
            ) : (
              <PiCaretDownBold className="text-sm text-caspian-secondary-600" />
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        {/* مرجع */}
        <div className="border-b border-b-caspian-gray2 py-3 text-xs flex flex-col gap-3">
          <span className="font-bold">مرجع: </span>
          {fullData?.meetingProfileRefrenceRelated.map((refrence, i) => (
            <div key={i} className="flex items-start justify-start gap-1">
              <span className="font-bold text-xs w-[16px]">{i + 1}-</span>
              <div className="flex flex-col justify-start gap-1 border-r-2 border-r-caspian-primary-300 pr-1 w-full">
                <p className="text-xs font-bold">{refrence?.refrenceText}</p>
                {refrence?.content_type === 70 ? (
                  <span className="text-xs text-caspian-gray">
                    {refrence?.content_object?.standardTitlePersian}
                  </span>
                ) : refrence?.content_type === 49 ? (
                  <span className="text-xs text-caspian-gray">
                    {refrence?.content_object?.reference}
                  </span>
                ) : refrence?.content_type === 53 ? (
                  <span className="text-xs text-caspian-gray">
                    {
                      refrence?.content_object?.InternalDocumentTypeRelated
                        ?.title
                    }
                  </span>
                ) : (
                  <span className="text-xs text-caspian-gray">سایر</span>
                )}
              </div>
            </div>
          ))}
        </div>
        {/* ورودی */}
        <div className="flex flex-col gap-2 border-b border-b-caspian-gray2 py-3 text-xs">
          <span className="font-bold">ورودی های جلسه: </span>
          {fullData?.inputMeetingProfileRelated.map((input, i) => (
            <div key={i} className="flex items-start justify-start gap-1">
              <span className="font-bold text-xs w-[16px]">{i + 1}-</span>
              <div className="flex flex-col justify-start gap-1 border-r-2 border-r-caspian-primary-300 pr-1 w-full">
                <p className="pre text-xs font-bold">{input.description}</p>
                <span className="text-xs text-caspian-gray">{input.title}</span>
              </div>
            </div>
          ))}
        </div>
        {/* خروجی */}
        <div className="flex flex-col gap-2 text-xs pt-3">
          <span className="font-bold">خروجی های جلسه: </span>
          {fullData?.outputMeetingProfileRelated.map((output, i) => (
            <div key={i} className="flex items-start justify-start gap-1">
              <span className="font-bold text-xs w-[16px]">{i + 1}-</span>
              <div className="flex flex-col justify-start gap-1 border-r-2 border-r-caspian-primary-300 pr-1 w-full">
                <p className="text-xs font-bold">{output.description}</p>
                <span className="text-xs text-caspian-gray">
                  {output.title}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MainProfileDetails;
