import React, { useEffect, useRef } from 'react'
import { PiFunnel, PiMagnifyingGlassBold, PiSortAscendingLight, PiUsers, PiX } from 'react-icons/pi'
import { useSearchParams } from 'react-router-dom';

const FilterReportsActionPlan = ({ setSearchReportActionPlan, searchReportActionPlan, roleReports, setRoleReports, statusReports, setStatusReports, sortReports, setSortReports }) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const search = searchParams.get('search')

    const searchRef = useRef(null)

    const searchHandler = (e) => {
        e.preventDefault();
        handleSearch('search', searchRef?.current?.value)
        handleSearch("page", "1");
        setSearchReportActionPlan(searchRef?.current?.value);
    }

    const resetFilter = () => {
        searchRef.current.value = "";
        handleSearch("search", '')
        handleSearch("rows", '')
        handleSearch("page", '')
        setSearchReportActionPlan('')
    }

    const handleSearch = (key, value) => {
        setSearchParams((prev) => {
            if (value === null || value === "") {
                prev.delete(key)
            } else {
                prev.set(key, value)
            }
            return prev;
        })
    }
    useEffect(() => {
        if (search) {
            searchRef.current.value = search;
            setSearchReportActionPlan(search)
        }
    }, [search, setSearchReportActionPlan]);


    return (
        <form onSubmit={searchHandler} className='flex w-full items-end justify-between'>
            <div className='flex w-1/3 items-center justify-end px-3 py-2 gap-3 border-2 bg-caspian-surface rounded-lg border-caspian-gray2'>
                <PiMagnifyingGlassBold className='rotate-90 text-xl text-caspian-gray' />
                <input className='w-full outline-none text-xs 2xl:text-base' type='search' placeholder='جست و جو'
                    ref={searchRef}
                />
                <PiX
                    onClick={resetFilter}
                    className={`${searchReportActionPlan?.length > 0 ? ' visible' : ' invisible'} cursor-pointer`} />

            </div>

            <div className='flex items-center justify-end w-2/4'>

                <div className='flex flex-col items-start basis-2/6'>
                    <div className='flex flex-col items-end w-[90%]'>
                        <div className='w-full flex justify-start'>
                            <span className='text-caspian-gray text-xs xl:text-sm'>نقش</span>
                        </div>
                        <div className='flex w-full items-center justify-around rounded-lg gap-2 pr-2 py-2 border-2 bg-caspian-surface border-caspian-gray2  '>
                            <PiUsers className='text-2xl text-caspian-gray' />
                            <select className='w-full text-xs cursor-pointer 2xl:text-base outline-none bg-caspian-transparent'
                                value={roleReports}
                                onChange={(e) => {
                                    setRoleReports(e.target.value)
                                    console.log(e.target.value)
                                }}>
                                <option value="all">همه</option>
                                <option value="USER">مجری</option>
                                <option value="MANAGER">مدیر برنامه اجرایی</option>
                                <option value="SUPERVISOR">ناظر</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className='flex flex-col items-start basis-2/6 '>
                    <div className='flex flex-col items-end w-[90%]'>
                        <div className='w-full flex justify-start'>
                            <span className='text-caspian-gray text-xs xl:text-sm'>وضعیت</span>
                        </div>
                        <div className='flex w-full items-center justify-around rounded-lg gap-2 pr-2 py-2 border-2 bg-caspian-surface border-caspian-gray2 '>
                            <PiFunnel className='text-2xl text-caspian-gray' />
                            <select className='w-full outline-none cursor-pointer text-xs 2xl:text-base  bg-caspian-transparent'
                                value={statusReports}
                                onChange={(e) => {
                                    setStatusReports(e.target.value)
                                    console.log(e.target.value)
                                }}>
                                <option value="all">همه</option>
                                <option value="accepted">قبول شده</option>
                                <option value="waiting">درحال بررسی</option>
                                <option value="unread">خوانده نشده</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className='flex flex-col items-start basis-2/6'>
                    <div className='flex flex-col items-end w-[90%]'>
                        <div className='w-full flex justify-start'>
                            <span className='text-caspian-gray text-xs xl:text-sm'>مرتب سازی</span>
                        </div>
                        <div className='flex w-full items-center justify-around rounded-lg gap-2 pr-2 py-2 border-2 bg-caspian-surface border-caspian-gray2  '>
                            <PiSortAscendingLight className='text-2xl text-caspian-gray' />
                            <select className='w-full outline-none  cursor-pointer text-xs 2xl:text-base  bg-caspian-transparent'
                                value={sortReports}
                                onChange={((e) => {
                                    setSortReports(e.target.value)
                                    console.log(e.target.value)
                                })}>
                                <option value="a_start"> زودترین آغاز</option>
                                <option value="b_start"> دیرترین آغاز</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

        </form >
    )
}

export default FilterReportsActionPlan