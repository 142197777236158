import React, { useContext, useEffect, useState } from "react";
import { PiPlusBold } from "react-icons/pi";
import MeetingOrderForm from "./meeting-order/MeetingOrderForm";
import { toast } from "react-toastify";
import SingleListMeetingOrder from "./meeting-order/SingleListMeetingOrder";
import API from "../../../../../api/API";
import { AuthContext } from "../../../../../context/auth/AuthContext";

const MeetingOrder = ({
  setActiveTab,
  setShowEditPlanningMeetingForm,
  setRefresh,
  thisId,
  fullData,
  members,
  meetingAgendaRelated,
  meetingProfileRelated,
  setMeetingListRefresh,
}) => {
  const { token } = useContext(AuthContext);

  const [disableButton, setDisableButton] = useState(false);
  const [showMeetingOrderForm, setShowMeetingOrderForm] = useState(false);
  const [data, setData] = useState([]);

  const dataHandler = async () => {
    console.log(data);
  };

  const dataHandlerDraft = async () => {
    // e.preventDefault();
    await API.post(
      `/api/v1/meeting/info/detail/${thisId}/status/`,
      {
        status: "agenda",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await dataHandler();
        await toast.success("دستور جلسه به صورت پیشنویس ثبت شد.", {
          className: "toast-success",
          bodyClassName: "toast-success",
        });
        await setShowEditPlanningMeetingForm(false);
        await setMeetingListRefresh(1);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataHandlerNext = async () => {
    // e.preventDefault();
    await API.post(
      `/api/v1/meeting/info/detail/${thisId}/status/`,
      {
        status: "presentation",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await dataHandler();
        await setActiveTab("presentationFile");
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataHandlerBack = async () => {
    await setActiveTab("timeAndPlace");
  };

  useEffect(() => {
    if (meetingAgendaRelated.length < 1) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [meetingAgendaRelated]);
  return (
    <div className="min-h-[270px] flex flex-col justify-between">
      <div className="flex flex-col gap-3">
        <button
          onClick={() => setShowMeetingOrderForm(true)}
          className="flex items-center justify-between w-full border-y border-y-caspian-secondary-500 text-caspian-secondary-500 py-2 cursor-pointer hover:bg-caspian-secondary-50/20"
        >
          <span className="text-xs">افزودن دستور جلسه</span>
          <PiPlusBold className="text-sm" />
        </button>
        {showMeetingOrderForm && (
          <MeetingOrderForm
            members={members}
            fullData={fullData}
            data={data}
            setData={setData}
            setShowMeetingOrderForm={setShowMeetingOrderForm}
            setRefresh={setRefresh}
            thisId={thisId}
            meetingProfileRelated={meetingProfileRelated}
          />
        )}
        <div className="p-0.5 max-h-[180px] overflow-y-scroll">
          {meetingAgendaRelated?.length > 0 ? (
            <table className="w-full inline-table rounded-md overflow-hidden outline outline-1 outline-caspian-gray1">
              <thead className="text-xs text-caspian-gray text-right">
                <tr className="bg-caspian-gray3 w-full">
                  <th className="p-2">عنوان</th>
                  <th className="p-2">ورودی</th>
                  <th className="p-2">ارائه دهنده</th>
                  <th className="p-2">مدت زمان ارائه</th>
                  <th className="p-2"></th>
                </tr>
              </thead>
              <tbody className="text-xs text-caspian-gray text-right">
                {meetingAgendaRelated?.map((d, i) => (
                  <SingleListMeetingOrder
                    key={i}
                    id={d?.id}
                    title={d?.title}
                    InputRelated={d?.InputRelated?.title}
                    presentor={d?.presentor?.fullName}
                    setRefresh={setRefresh}
                    timeDuration={d?.timeDuration}
                  />
                ))}
              </tbody>
            </table>
          ) : (
            <h2 className="text-xs text-caspian-gray1">
              دستور جلسه ای اضافه نشده است!
            </h2>
          )}
        </div>
      </div>
      <div className="flex items-center gap-2">
        <button
          onClick={async () => {
            await dataHandlerBack();
          }}
          className="min-w-[6rem] outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs p-1.5 rounded-md"
        >
          مرحله قبل
        </button>
        <button
          disabled={disableButton}
          onClick={async () => {
            await dataHandlerNext();
          }}
          className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
        >
          مرحله بعد
        </button>
        <button
          disabled={disableButton}
          onClick={async () => {
            await dataHandlerDraft();
          }}
          className={`disabled:cursor-not-allowed disabled:text-caspian-gray text-caspian-secondary-500 text-xs p-1.5 rounded-md`}
        >
          ذخیره پیشنویس
        </button>
      </div>
    </div>
  );
};

export default MeetingOrder;
