import React, { useContext, useState } from "react";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import API from "../../../../../api/API";
import Dropdown from "../../../../ui/Dropdown";
import Line from "../../../../ui/Line";
import BudgetSingle from "../../../pmo/profile/BudgetSingle";
import ResponsibleSingle from "../../../pmo/profile/ResponsibleSingle";
import { toast } from "react-toastify";

const PmoProfile = ({ fullData, setRefresh }) => {
  const { token } = useContext(AuthContext);
  const [isFirst, setIsFirst] = useState(null);

  const [isAccept, setIsAccept] = useState(false);

  const dataHandler = async () => {
    let formData = {
      ActionPlanSelected_id: fullData?.id,
      link: `/action-plan/my-actionplan/${fullData?.id}/action`,
    };
    console.log(formData);
    // if (!fullData?.id) return;
    try {
      const res = await API.post(
        `/api/v1/actionplan/actionplanactivity`,
        formData,
        {
          headers: { Authorization: token },
        }
      );
      // await archiveActionplanHandler();
      setRefresh(1);
      console.log(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getArchiveActionplan = async () => {
    try {
      const res = await API.get(
        `/api/v1/actionplan/archives/actionplan/${fullData?.id}`,
        {
          headers: { Authorization: token },
        }
      );
      console.log(res?.data);
      if (res?.data?.length > 0) {
        setIsFirst(true);
      } else {
        setIsFirst(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const archiveActionplanHandler = async () => {
    let formData = {
      actionplanRelated: fullData?.id,
      title: fullData?.title,
      description: fullData?.description,
      // typeProject: fullData?.typeProject,

      privacy: fullData?.actionplan_detail?.privacy,
      observers_selected: fullData?.actionplan_detail?.observers_selected,
      startDate: fullData?.actionplan_detail?.startDate,
      endDate: fullData?.actionplan_detail?.endDate,
      cost: fullData?.actionplan_detail?.cost,

      approval_placement:
        fullData?.actionplan_responsibles?.approval_placement?.id,
      varify_placement: fullData?.actionplan_responsibles?.varify_placement?.id,
      tech_observer:
        fullData?.actionplan_responsibles?.tech_observer
          ?.placementProfileRelated?.id,
      accounting_observer:
        fullData?.actionplan_responsibles?.accounting_observer
          ?.placementProfileRelated?.id,
      budget_observer:
        fullData?.actionplan_responsibles?.budget_observer
          ?.placementProfileRelated?.id,
      legal_observer:
        fullData?.actionplan_responsibles?.legal_observer
          ?.placementProfileRelated?.id,
      hse_observer:
        fullData?.actionplan_responsibles?.hse_observer?.placementProfileRelated
          ?.id,
      general_observer:
        fullData?.actionplan_responsibles?.general_observer
          ?.placementProfileRelated?.id,
      manager: fullData?.actionplan_responsibles?.manager?.id,
      controller: fullData?.actionplan_responsibles?.controller?.id,

      outputProject: fullData?.actionplan_goal?.outputProject,
      goal: fullData?.actionplan_goal?.goal?.map((goalId) => goalId?.id),
      processes: fullData?.actionplan_goal?.processes?.map(
        (processesId) => processesId?.id
      ),
      stackholders: fullData?.stack_actionplan
        ?.map((stackId) => stackId?.StackHolderRelated?.id)
        .toString(),
      stackholdertype: fullData?.stack_actionplan
        ?.map((stack) => (stack?.typeProject === "influencer" ? 0 : 1))
        .toString(),
      descriptions_stackholder: fullData?.stack_actionplan
        ?.map((stack) => stack?.description.replaceAll(",", " "))
        .toString(),
      is_first: true,
    };
    console.log(formData);
    if (!fullData?.id) return;
    await getArchiveActionplan();
    // if (isFirst) return;
    try {
      const res = await API.post(
        "/api/v1/actionplan/actionplan_archived/",
        formData,
        {
          headers: { Authorization: token },
        }
      );
      console.log(res?.data);
      dataHandler();
      // setIsFirst(null);
    } catch (err) {
      console.log(err);
      if (err?.message === "Network Error") {
        toast.error("اتصال با سرور برقرار نشد", {
          className: "toast-faild",
          bodyClassName: "toast-faild",
        });
      } else if (err?.response?.data[0]) {
        toast.error(err?.response?.data[0], {
          className: "toast-faild",
          bodyClassName: "toast-faild",
        });
      } else {
        toast.error("مشکل ناشناخته ای به وجود آمد.", {
          className: "toast-faild",
          bodyClassName: "toast-faild",
        });
      }
    }
  };
  return (
    <div className="w-full md:w-[90%] lg:w-85%] xl:w-[75%] p-2 rounded-md shadow-[0_0_10px_#00000050] min-h-screen bg-caspian-surface flex flex-col gap-4">
      {/* عنوان */}
      <div className="flex flex-col gap-4 w-full">
        <div className="flex items-center gap-2 w-full">
          <span className="text-sm font-bold basis-[20%] text-caspian-primary-500">
            عنوان
          </span>
          <h2 className="text-sm font-bold basis-[80%]">{fullData?.title}</h2>
        </div>
        <div className="flex items-start gap-2 w-full">
          <span className="text-sm font-bold basis-[20%] text-caspian-primary-500">
            توضیحات
          </span>
          <p className="text-xs basis-[80%]">{fullData?.description}</p>
        </div>
        <div className="flex items-start gap-2 w-full">
          <span className="text-sm font-bold basis-[20%] text-caspian-primary-500">
            نتایج مورد انتظار
          </span>
          <p className="text-xs basis-[80%]">
            {fullData?.actionplan_goal?.outputProject}
          </p>
        </div>
      </div>
      {/* هدف و فرایند */}
      <div className="flex flex-col gap-4 w-full">
        <div className="flex items-center justify-between gap-2 w-full">
          <span className="text-sm font-bold w-[30%] whitespace-nowrap">
            هدف و فرایند
          </span>
          <Line color="bg-caspian-secondary-100" />
        </div>
        <div className="flex items-start justify-between w-full gap-2">
          <Dropdown
            className={
              "border border-caspian-secondary-700 text-caspian-secondary-700 text-xs p-3 rounded-tl-xl"
            }
            title="اهداف مرتبط"
          >
            <div className="flex flex-col gap-3 p-2">
              {fullData?.actionplan_goal?.goal?.map((p, i) => (
                <div key={i} className="flex items-center gap-1">
                  <div className="w-[4px] h-[4px] rounded-full bg-caspian-secondary-500"></div>
                  <p className="text-xs text-caspian-secondary-500 font-bold">
                    {p?.title}
                  </p>
                </div>
              ))}
            </div>
          </Dropdown>
          <Dropdown
            className={
              "border border-caspian-secondary-700 text-caspian-secondary-700 text-xs p-3 rounded-tl-xl"
            }
            title="فرانید‌های مرتبط"
          >
            <div className="flex flex-col gap-3 p-2">
              {fullData?.actionplan_goal?.processes?.map((p, i) => (
                <div key={i} className="flex items-center gap-1">
                  <div className="w-[4px] h-[4px] rounded-full bg-caspian-secondary-500"></div>
                  <p className="text-xs text-caspian-secondary-500 font-bold">
                    {p?.title}
                  </p>
                </div>
              ))}
            </div>
          </Dropdown>
        </div>
      </div>
      {/* ذینفعان */}
      <div className="flex flex-col gap-4 w-full">
        <div className="flex items-center justify-between gap-2 w-full">
          <span className="text-sm font-bold w-[30%] whitespace-nowrap">
            ذینفعان
          </span>
          <Line color="bg-caspian-primary-100" />
        </div>
        <div className="flex items-start justify-between w-full gap-2">
          <Dropdown
            className={
              "border border-caspian-primary-700 text-caspian-primary-700 text-xs p-3 rounded-tl-xl"
            }
            title="ذینفعان تاثیرگذار"
          >
            <div className="flex flex-col gap-3 p-2">
              {fullData?.stack_actionplan
                ?.filter((s) => s?.typeProject === "influencer")
                ?.map((s, i) => (
                  <div key={i} className="flex items-center gap-1">
                    <div className="w-[4px] h-[4px] rounded-full bg-caspian-primary-500"></div>
                    <p className="text-xs text-caspian-primary-500 font-bold">
                      {s?.description}
                    </p>
                  </div>
                ))}
            </div>
          </Dropdown>
          <Dropdown
            className={
              "border border-caspian-primary-700 text-caspian-primary-700 text-xs p-3 rounded-tl-xl"
            }
            title="ذینفعان تاثیرپذیر"
          >
            <div className="flex flex-col gap-3 p-2">
              {fullData?.stack_actionplan
                ?.filter((s) => s?.typeProject === "affected")
                ?.map((s, i) => (
                  <div key={i} className="flex items-center gap-1">
                    <div className="w-[4px] h-[4px] rounded-full bg-caspian-primary-500"></div>
                    <p className="text-xs text-caspian-primary-500 font-bold">
                      {s?.description}
                    </p>
                  </div>
                ))}
            </div>
          </Dropdown>
        </div>
      </div>

      <div className="flex items-start w-full gap-2">
        {/* بودجه و چهارچوب زمانی */}
        <div className="flex flex-col gap-4 w-full">
          {/* بودجه */}
          <div className="flex flex-col gap-4 w-full">
            <div className="flex items-center justify-between gap-2 w-full">
              <span className="text-sm font-bold w-[30%] whitespace-nowrap">
                بودجه
              </span>
              <Line color="bg-caspian-gray2" />
            </div>
            <div className="grid grid-cols-2 gap-2">
              <BudgetSingle
                title="بودجه تخمینی PMO(ریال)"
                // cost={fullData?.actionplan_detail?.cost}
              />
              <BudgetSingle
                title="بودجه برنامه اجرایی"
                // cost={fullData?.actionplan_detail?.cost}
                className="text-[#5E7D06] font-bold"
              />
              <BudgetSingle
                title="جمع بودجه وظایف(ریال)"
                cost={fullData?.actionplan_detail?.cost}
              />
              <BudgetSingle
                title="بودجه برای سایر موارد"
                // cost={fullData?.actionplan_detail?.cost}
              />
            </div>
          </div>
          {/* چهارچوب زمانی */}
          <div className="flex flex-col gap-4 w-full">
            <div className="flex items-center justify-between gap-2">
              <span className="text-sm font-bold w-[30%] whitespace-nowrap">
                چهارچوب زمانی
              </span>
              <Line color="bg-caspian-gray2" />
            </div>
            <div className="flex items-center justify-between gap-2">
              <span className="text-xs text-caspian-gray4">
                برنامه‌ریزی PMO
              </span>
              <div className="flex items-center gap-2">
                <div className="flex items-center gap-0 bg-caspian-gray2/40 rounded-lg border border-caspian-gray">
                  <span className="text-xs rounded-lg bg-caspian-gray3 p-2 border-l border-l-caspian-gray">
                    شروع
                  </span>
                  <span className="text-xs p-2">
                    {new Date(
                      fullData?.actionplan_detail?.startDate
                    ).toLocaleDateString("fa-IR")}
                  </span>
                </div>
                <div className="flex items-center gap-0 bg-caspian-gray2/40 rounded-lg border border-caspian-gray">
                  <span className="text-xs rounded-lg bg-caspian-gray3 p-2 border-l border-l-caspian-gray">
                    پایان
                  </span>
                  <span className="text-xs p-2">
                    {new Date(
                      fullData?.actionplan_detail?.endDate
                    ).toLocaleDateString("fa-IR")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* مجریان */}
        <div className="flex flex-col gap-4 w-full">
          <div className="flex items-center justify-between gap-2">
            <span className="text-sm font-bold w-[30%] whitespace-nowrap">
              مجریان
            </span>
            <Line color="bg-caspian-gray2" />
          </div>
          <Dropdown
            className={
              "border border-caspian-secondary-400 text-caspian-secondary-400 text-xs p-3 rounded-tl-xl"
            }
            title="نقش‌ها"
          >
            <div className="flex flex-col gap-2 p-2">
              <ResponsibleSingle
                title="مسئول برنامه اجرایی"
                fullName={
                  fullData?.actionplan_responsibles?.manager?.firstName +
                  " " +
                  fullData?.actionplan_responsibles?.manager?.lastName
                }
              />
              <ResponsibleSingle
                title="کنترل کننده برنامه"
                fullName={
                  fullData?.actionplan_responsibles?.controller?.firstName +
                  " " +
                  fullData?.actionplan_responsibles?.controller?.lastName
                }
              />
              <ResponsibleSingle
                title="تایید کننده"
                fullName={
                  fullData?.actionplan_responsibles?.varify_placement
                    ?.placementProfileRelated?.firstName +
                  " " +
                  fullData?.actionplan_responsibles?.varify_placement
                    ?.placementProfileRelated?.lastName
                }
              />
              <ResponsibleSingle
                title="تصویب کننده"
                fullName={
                  fullData?.actionplan_responsibles?.approval_placement
                    ?.placementProfileRelated?.firstName +
                  " " +
                  fullData?.actionplan_responsibles?.approval_placement
                    ?.placementProfileRelated?.lastName
                }
              />
              <ResponsibleSingle
                title="ناظر فنی"
                fullName={
                  fullData?.actionplan_responsibles?.tech_observer
                    ?.placementProfileRelated?.firstName +
                  " " +
                  fullData?.actionplan_responsibles?.tech_observer
                    ?.placementProfileRelated?.lastName
                }
              />
              <ResponsibleSingle
                title="ناظر حقوقی"
                fullName={
                  fullData?.actionplan_responsibles?.legal_observer
                    ?.placementProfileRelated?.firstName +
                  " " +
                  fullData?.actionplan_responsibles?.legal_observer
                    ?.placementProfileRelated?.lastName
                }
              />
              <ResponsibleSingle
                title="ناظر بودجه"
                fullName={
                  fullData?.actionplan_responsibles?.budget_observer
                    ?.placementProfileRelated?.firstName +
                  " " +
                  fullData?.actionplan_responsibles?.budget_observer
                    ?.placementProfileRelated?.lastName
                }
              />
              <ResponsibleSingle
                title="ناظر عمومی"
                fullName={
                  fullData?.actionplan_responsibles?.general_observer
                    ?.placementProfileRelated?.firstName +
                  " " +
                  fullData?.actionplan_responsibles?.general_observer
                    ?.placementProfileRelated?.lastName
                }
              />
              <ResponsibleSingle
                title="ناظر HSE"
                fullName={
                  fullData?.actionplan_responsibles?.hse_observer
                    ?.placementProfileRelated?.firstName +
                  " " +
                  fullData?.actionplan_responsibles?.hse_observer
                    ?.placementProfileRelated?.lastName
                }
              />
              <ResponsibleSingle
                title="ناظر حسابداری"
                fullName={
                  fullData?.actionplan_responsibles?.accounting_observer
                    ?.placementProfileRelated?.firstName +
                  " " +
                  fullData?.actionplan_responsibles?.accounting_observer
                    ?.placementProfileRelated?.lastName
                }
              />
            </div>
          </Dropdown>
        </div>
      </div>

      {/* تایید PMO */}
      {fullData?.id && fullData?.statusAction === "PMOPlan" && (
        <div className="flex flex-col gap-4 border-t border-t-caspian-gray2 pt-4">
          <div className="flex items-center gap-2">
            <input
              onChange={(e) => fullData?.id && setIsAccept(!isAccept)}
              id="accept"
              type="checkbox"
              className=" cursor-pointer accent-caspian-secondary-400"
            />
            <label
              htmlFor="accept"
              className="text-xs cursor-pointer font-bold"
            >
              با ارسال اطلاعات فوق به PM موافقت میفرمائید؟
            </label>
          </div>
          <div className="flex items-center gap-2">
            <button
              disabled={isAccept ? false : true}
              onClick={archiveActionplanHandler}
              className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
            >
              ثبت و ارسال
            </button>

            {/* <button
                onClick={async () => {
                  await console.log("cancel");
                }}
                className={`disabled:cursor-not-allowed disabled:text-caspian-gray text-caspian-error2 text-xs p-1.5 rounded-md`}
              >
                انصراف
              </button> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default PmoProfile;
