import ZoneTitle3 from "./ZoneTitle3";
import Category from "./Category";
import { useRef, useState } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import { PiCaretDoubleRight } from "react-icons/pi";

const MainZoneThree = ({ data, title }) => {
  const refZoneThree = useRef();
  const { events } = useDraggable(refZoneThree);

  const [showArrow, setShowArrow] = useState(true);
  // console.log(data);

  return (
    <div className="w-full flex flex-col gap-1 h-full relative">
      <ZoneTitle3 title={title} />
      <div
        {...events}
        ref={refZoneThree}
        onPointerDownCapture={() => setShowArrow(false)}
        onPointerUpCapture={() => setShowArrow(true)}
        className={`${data.length > 4 && showArrow && "cursor-grab"} ltr w-full h-full flex items-start gap-2 py-2 overflow-x-scroll`}
      >
        {data.length > 4 && showArrow && (
          <div className="absolute top-1/2 -translate-y-1/2 right-2">
            <PiCaretDoubleRight className="text-caspian-primary-300 text-2xl drop-shadow-[0_0_1px_#00000050]" />
          </div>
        )}
        {/* Start Category */}
        {data.map(
          (d, i) =>
            // d.groupCategoryRelated.length > 0 && (
              <Category
                key={i}
                dataGroup={d.groupCategoryRelated}
                title={d.title}
              />
            // )
        )}
        {/* End Category */}
      </div>
    </div>
  );
};

export default MainZoneThree;
