import React from 'react';
import TopProfileBlog from './TopProfileBlog';
import BottomProfileBlog from './BottomProfileBlog';

const MyProfileBlog = ({data}) => {
    return (
        <>
        <div className='flex flex-col gap-6 justify-center bg-caspian-surface w-full xl:w-[95%] py-10 px-5 2xl:px-20 shadow-[0_0_10px_#00000020] rounded-lg'>
          <TopProfileBlog data={data}/>
          <BottomProfileBlog text={data?.text} />
        </div>
        </>
    );
}

export default MyProfileBlog;
