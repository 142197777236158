import React, { useContext, useEffect, useState } from "react";
import {
  PiCaretDownBold,
  PiMagnifyingGlassBold,
  PiXBold,
} from "react-icons/pi";
import API from "../../../../../../api/API";
import { AuthContext } from "../../../../../../context/auth/AuthContext";

const AddGuestForm = ({
  data,
  setData,
  setShowAddGuestForm,
  thisId,
  setRefresh,
}) => {
  const { token } = useContext(AuthContext);
  const [members, setMembers] = useState([-1]);
  const [companyMemberId, setCompanyMemberId] = useState(null);

  const [memberType, setMemberType] = useState("guest");
  const [fullName, setFullName] = useState("");
  const [position, setPosition] = useState("");
  const [company, setCompany] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const addGuestHandler = async () => {
    // if (memberType === "guest") {
    API.post(
      "/api/v1/meeting/info/member/",
      {
        memberType: memberType,
        fullName: memberType === "guest" ? fullName : null,
        position: memberType === "guest" ? position : null,
        company: memberType === "guest" ? company : null,
        phoneNumber: memberType === "guest" ? phoneNumber : null,
        email: memberType === "guest" ? email : null,
        description: description,
        meetingMemberRelated: +thisId,
        member: memberType === "company" ? companyMemberId : null,
      },
      {
        headers: { Authorization: token },
      }
    )
      .then((res) => {
        console.log(res.data);
        setRefresh(1);
      })
      .catch((err) => {
        console.log(err);
      });
    await setData([
      ...data,
      {
        memberType: memberType,
        fullName: fullName,
        position: position,
        company: company,
        phoneNumber: phoneNumber,
        email: email,
        description: description,
        meetingMemberRelated: +thisId,
        member: null,
      },
    ]);
    await setShowAddGuestForm(false);
    // }
  };

  const [showListHeadMeeting, setShowListHeadMeeting] = useState(false);
  useState(false);

  let url2 = "/api/v1/profile/";
  useEffect(() => {
    const getMembers = () => {
      API.get(url2, {
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          setMembers(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getMembers();
  }, [token, url2]);

  // رئیس جلسه
  const [headSelected, setHeadSelected] = useState(null);
  const [searchByHead, setSearchByHead] = useState("");
  let searchHead = [];
  if (members.length > 0) {
    searchHead = searchByHead
      ? members.filter(
          (d) =>
            (d?.title !== undefined &&
              d?.title
                .toLocaleLowerCase()
                .includes(searchByHead.toLocaleLowerCase())) ||
            (d?.firstName + " " + d?.lastName)
              .toLocaleLowerCase()
              .includes(searchByHead.toLocaleLowerCase())
        )
      : members;
  }

  console.log(companyMemberId);

  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full z-50">
      <div className="bg-caspian-surface rounded-md shadow-[0_0_5px_#00000050] p-3 w-[400px] flex flex-col gap-3">
        <div className="flex items-center justify-between p-2">
          <h2 className="text-sm font-bold">افزودن مهمان به جلسه</h2>
          <button onClick={() => setShowAddGuestForm(false)}>
            <PiXBold className={"text-caspian-gray4 text-sm"} />
          </button>
        </div>

        <div className="flex flex-col gap-3">
          <div className="flex items-center justify-between gap-4">
            <div className="flex flex-col gap-1.5 w-1/2">
              <label className="text-xs text-caspian-gray4">
                درون سازمانی/برون سازمانی
              </label>
              <select
                name="memberType"
                onChange={(e) => setMemberType(e.target.value)}
                defaultValue={"guest"}
                className="text-xs outline outline-1 outline-caspian-gray1 p-1.5 rounded-md bg-caspian-transparent cursor-pointer w-full"
              >
                <option value={"company"}>درون سازمانی</option>
                <option value={"guest"}>برون سازمانی</option>
              </select>
            </div>
            {memberType === "guest" ? (
              <div className="w-1/2 flex flex-col gap-1.5">
                <label className="text-xs text-caspian-gray4">
                  نام و نام خانوادگی
                </label>
                <input
                  onChange={(e) => setFullName(e.target.value)}
                  name="fullName"
                  type="text"
                  placeholder="وارد کنید"
                  className="outline-none outline-caspian-gray1 p-1.5 text-xs rounded-md outline-1"
                />
              </div>
            ) : (
              <>
                {/* شروع مسئول گزارش جدید */}
                <div className="relative flex flex-col gap-2 w-1/2">
                  <label className="text-xs font-bold text-caspian-on_primary/50">
                    نام و نام خانوادگی
                  </label>

                  <div
                    onClick={() => {
                      setShowListHeadMeeting(!showListHeadMeeting);
                    }}
                    className="text-xs outline outline-1 outline-caspian-gray1 p-1.5 rounded-md bg-caspian-transparent cursor-pointer flex items-center justify-between relative"
                  >
                    {members.length > 0 && headSelected !== null ? (
                      <span className="text-xs">
                        {headSelected?.firstName + " " + headSelected?.lastName}
                      </span>
                    ) : (
                      <span className="text-caspian-gray1 text-xs">
                        انتخاب کنید
                      </span>
                    )}
                    <PiCaretDownBold className="text-caspian-gray" />
                  </div>
                  {showListHeadMeeting && (
                    <div className="rounded-md shadow-[0_0_5px_#00000050] w-full flex flex-col gap-3 py-3 absolute left-0 right-0 top-[52px] bg-caspian-surface z-50">
                      <div className="relative w-[92%] mx-auto">
                        <input
                          onChange={(e) => {
                            setSearchByHead(e.target.value);
                          }}
                          type="search"
                          className="outline-none outline-caspian-gray1 p-1 text-xs rounded-md outline-1 w-full placeholder:text-xs placeholder:text-caspian-gray pr-6"
                          placeholder="جستجو"
                        />
                        <button className="absolute top-1/2 -translate-y-1/2 right-1">
                          <PiMagnifyingGlassBold className="text-caspian-gray text-" />
                        </button>
                      </div>
                      <div className="flex flex-col gap-2 w-full max-h-[200px] overflow-y-scroll">
                        {searchHead.length > 0 &&
                          searchHead.map((m, i) => (
                            <div
                              key={i}
                              onClick={() => {
                                setCompanyMemberId(m?.id);
                                setHeadSelected(m);
                                setShowListHeadMeeting(false);
                                setSearchByHead("");
                              }}
                              className="flex items-center justify-start gap-2 text-xs hover:bg-caspian-secondary-100 py-1 px-2 w-full cursor-pointer duration-300"
                            >
                              <span className="">
                                {m?.firstName + " " + m?.lastName}
                              </span>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
                {/* پایان مسئول گزارش جدید */}
              </>
            )}
          </div>
          {memberType === "guest" && (
            <>
              <div className="flex items-center justify-between gap-4">
                <div className="flex flex-col gap-1.5 w-1/2">
                  <label className="text-xs text-caspian-gray4">سمت</label>
                  <input
                    onChange={(e) => setPosition(e.target.value)}
                    name="position"
                    type="text"
                    placeholder="وارد کنید"
                    className="outline-none outline-caspian-gray1 p-1.5 text-xs rounded-md outline-1"
                  />
                </div>
                <div className="w-1/2 flex flex-col gap-1.5">
                  <label className="text-xs text-caspian-gray4">سازمان</label>
                  <input
                    onChange={(e) => setCompany(e.target.value)}
                    name="company"
                    type="text"
                    placeholder="وارد کنید"
                    className="outline-none outline-caspian-gray1 p-1.5 text-xs rounded-md outline-1"
                  />
                </div>
              </div>
              <div className="flex items-center justify-between gap-4">
                <div className="flex flex-col gap-1.5 w-1/2">
                  <label className="text-xs text-caspian-gray4">
                    شماره تماس
                  </label>
                  <input
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    name="phoneNumber"
                    type="text"
                    placeholder="وارد کنید"
                    className="outline-none outline-caspian-gray1 p-1.5 text-xs rounded-md outline-1"
                  />
                </div>
                <div className="w-1/2 flex flex-col gap-1.5">
                  <label className="text-xs text-caspian-gray4">ایمیل</label>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    name="email"
                    type="text"
                    placeholder="وارد کنید"
                    className="outline-none outline-caspian-gray1 p-1.5 text-xs rounded-md outline-1"
                  />
                </div>
              </div>
            </>
          )}
          <div className="w-full flex flex-col gap-1.5">
            <label className="text-xs text-caspian-gray4">توضیحات</label>
            <textarea
              onChange={(e) => setDescription(e.target.value)}
              rows={6}
              className="resize-none p-2 rounded-md outline-none outline-1 outline-caspian-gray2 text-xs"
            />
          </div>
        </div>
        <div className="flex items-center gap-2 mt-2">
          <button
            onClick={() => addGuestHandler()}
            className="outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs py-1.5 px-6 rounded-md"
          >
            افزودن
          </button>
          <button className="text-xs text-[#FF5B5B] mr-4">لغو</button>
        </div>
      </div>
    </div>
  );
};

export default AddGuestForm;
