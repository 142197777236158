import React, { useState } from 'react'
import { priceType } from '../../../helper/pricetype'

const ProfileCollapse = ({ data }) => {
    const [showModalDescription, setShowModalDescription] = useState(true);

    return (
        <div className=' w-full flex items-center flex-col border-t-2 border-caspian-primary-50'>
            <div className='flex flex-col justify-center gap-4 w-full py-5'>

                <div className='flex gap-4 items-center pr-12'>
                    <div className='flex items-center basis-1/2 gap-2'>
                        <span className='text-xs 2xl:text-sm text-[#4B5563]'>دوره زمانی </span>
                    </div>

                    <div className='flex items-center basis-1/2 gap-2 '>
                        <span className='text-xs 2xl:text-sm text-[#4B5563]'>مسئول :</span>
                        <span className='text-xs 2xl:text-base  text-caspian-on_primary/90'>
                            {data?.owner?.placementPositionRelated?.positionSectionRelated?.title ? data?.owner?.placementPositionRelated?.positionSectionRelated?.title : '-'}
                        </span>
                    </div>
                </div>

                <div className='flex items-center basis-1/2 gap-2 pr-16'>
                    <span className='text-xs 2xl:text-sm text-[#4B5563]'>تاریخ شروع برنامه اجرایی هدف  :</span>
                    <span className='text-xs 2xl:text-base  text-caspian-on_primary/90'>
                        {new Date(data?.startDate).toLocaleDateString("fa-IR") ? new Date(data?.startDate).toLocaleDateString("fa-IR") : '-'}

                    </span>
                </div>

                <div className='flex items-center basis-1/2 gap-2 pr-16'>
                    <span className='text-xs 2xl:text-sm text-[#4B5563]'>تاریخ پایان برنامه اجرایی هدف  :</span>
                    <span className='text-xs 2xl:text-base  text-caspian-on_primary/90'>
                        {new Date(data?.endDate).toLocaleDateString("fa-IR") ? new Date(data?.endDate).toLocaleDateString("fa-IR") : '-'}
                    </span>
                </div>

                <div className='flex gap-4 items-center border-y-2 py-5 border-caspian-primary-50'>
                    <div className='flex items-center basis-1/2 gap-2  pr-12 '>
                        <span className='text-xs 2xl:text-sm text-[#4B5563]'>تاریخ تعریف هدف :</span>
                        <span className='text-xs 2xl:text-base text-caspian-on_primary/90'>
                            {new Date(data?.created_at).toLocaleDateString("fa-IR") ? new Date(data?.created_at).toLocaleDateString("fa-IR") : '-'}
                        </span>
                    </div>

                    <div className='flex items-center basis-1/2 gap-2  pr-6 '>
                        <span className='text-xs 2xl:text-sm text-[#4B5563]'>تاریخ گزارش نتایج نهایی :</span>
                        <span className='text-xs 2xl:text-base  text-caspian-on_primary/90'>
                            {new Date(data?.finalResultControlDate).toLocaleDateString("fa-IR") ? new Date(data?.finalResultControlDate).toLocaleDateString("fa-IR") : '-'}
                        </span>
                    </div>
                </div>
            </div>

            <div className='flex items-start flex-col gap-4 py-5 w-full '>
                <div className='flex items-center gap-2 pr-12 '>
                    <span className='text-xs 2xl:text-sm text-[#4B5563]'>ذینفعان </span>
                </div>

                <div className='w-full flex items-start pr-16'>
                    <div className='flex items-start flex-col gap-2 basis-1/2 '>
                        <span className='text-xs 2xl:text-sm text-[#4B5563]'>ذینفعان تاثیر گذار :</span>

                        <ul className='flex flex-col text-caspian-on_primary/90 items-start pr-12 list-disc gap-3'>
                            {data?.inOutObjectiveRelated?.filter(item => item?.inOutType === "in") ? data?.inOutObjectiveRelated?.filter(item => item?.inOutType === "in").map((item) => (
                                <li className='text-xs 2xl:text-sm' key={item?.id}>
                                    {item?.title}
                                </li>
                            )) : '-'}
                        </ul>
                    </div>

                    <div className='flex items-start flex-col gap-2  basis-1/2 '>
                        <span className='text-xs 2xl:text-sm text-[#4B5563]'>ذینفعان تاثیر پذیر :</span>

                        <ul className='flex flex-col text-caspian-on_primary/90 items-start pr-12 list-disc gap-3'>
                            {data?.inOutObjectiveRelated?.filter(item => item?.inOutType === "out") ? data?.inOutObjectiveRelated?.filter(item => item?.inOutType === "out").map((item) => (
                                <li className='text-xs 2xl:text-sm' key={item?.id}>
                                    {item?.title}
                                </li>
                            )) : '-'}
                        </ul>
                    </div>
                </div>

                <div className='flex items-start py-5 gap-4 w-full'>
                    <div className='flex gap-4 items-start flex-col pr-12 basis-1/2'>
                        <div className='flex items-center gap-2 '>
                            <span className='text-xs 2xl:text-sm text-[#4B5563]'>پیشبینی بودجه :</span>
                        </div>

                        <div className='flex flex-col items-start gap-5  pr-4'>
                            <div className='flex items-center gap-2 '>
                                <span className='text-[#323c4a] text-xs 2xl:text-sm'>ریالی :</span>
                                <span className='text-xs 2xl:text-sm text-caspian-on_primary/90'>{priceType(data?.budgetRial)}</span>
                            </div>

                            <div className='flex items-center gap-2 '>
                                <span className='text-[#323c4a] text-xs 2xl:text-sm'>ارزی :</span>
                                <span className='text-xs 2xl:text-sm text-caspian-on_primary/90'>{priceType(data?.budgetArz)}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className=' w-11/12 flex flex-col items-center gap-5 px-12'>

                    <div className='flex w-full items-start gap-2'>
                        <span className='text-xs 2xl:text-sm text-[#323c4a]'>توضیحات :</span>
                    </div>

                    <div className='flex w-full justify-start pr-4 items-center'>
                        <span className='text-xs 2xl:text-sm leading-6 text-justify text-caspian-on_primary/90'>

                            {data?.description?.length > 0 ?
                                (<div>
                                    {showModalDescription ? `${data?.description?.slice(0, 250)}${data?.description?.length > 250 ? '...' : ''}` : data?.description}
                                    {data?.description?.length > 250 && (
                                        <span
                                            onClick={() => setShowModalDescription(!showModalDescription)}
                                            className="text-caspian-secondary-600 mx-1 text-xs 2xl:text-sm cursor-pointer">
                                            {showModalDescription ? "بیشتر" : "کمتر"}
                                        </span>
                                    )}
                                </div>
                                ) : ('-')
                            }
                        </span>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ProfileCollapse