import Modal from '../../../../../../modal/Modal'
import { PiX, PiPaperclip } from 'react-icons/pi'
import ShowUploadFile from './ShowUploadFile'
import { useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '../../../../../../../context/auth/AuthContext'
import API from '../../../../../../../api/API'




const ModalUploadFile = ({ reportId, setShowModalUploadFile, setRefreshList, setAddDoc, listDoc, setIdDocument }) => {
    const { token } = useContext(AuthContext);
    const [titleDoc, setTitleDoc] = useState(listDoc?.name ? listDoc?.name : "")
    const [descreiptionDoc, setDescreiptionDoc] = useState(listDoc?.description ? listDoc?.description : "")
    const [file, setFile] = useState(listDoc?.fileDocument ? listDoc?.fileDocument : null)
    const [emptyTitle, setEmptyTitle] = useState(false)
    const [emptyFile, setEmptyFile] = useState(false)

    const [showFiles, setShowFiles] = useState(file?.length > 0)

    const fileRef = useRef();
    const titleRef = useRef();
    const descRef = useRef();


    // post سند 
    const handleDocument = async () => {
        if (!token || !reportId || !file) return;


        let hasError = false;

        if (!titleDoc) {
            setEmptyTitle(true);
            hasError = true;
        }
        if (!file) {
            setEmptyFile(true);
            hasError = true;
        }

        if (hasError) return



        let formData = {
            reportType: "reportprogress",
            fileDocument: file,
            name: titleDoc,
            size: file?.size,
            description: descreiptionDoc,
            reportRelated: reportId,
        }

        try {
            const res = await API.post('/api/v1/actionplan/projectDocument/', formData,
                {
                    headers: {
                        Authorization: token,
                        "content-type": "multipart/form-data",
                        Accept: "application/json"
                    }
                }
            )
            console.log(res?.data)

            setIdDocument(res?.data?.id)
            setAddDoc(true)
            setRefreshList(1)
            setShowModalUploadFile(false)


        } catch (error) {
            console.log(error)
        }
    }




    const clearDate = () => {
        setShowModalUploadFile(false)
        setShowFiles(false)
        setFile(null);
        setTitleDoc();
        setDescreiptionDoc("");
    }




    return (
        <Modal>
            <div className='flex flex-col py-3 items-center w-[350px] md:w-[450px] bg-caspian-surface rounded-lg'>
                <div className='w-full flex items-center justify-between border-b-2 px-5 pb-3 border-b-caspian-graygreen-200'>
                    <span className='text-xs 2xl:text-base'>افزودن سند</span>

                    <PiX
                        className='text-xl cursor-pointer text-caspian-gray4'
                        onClick={clearDate} />
                </div>
                <div className='flex flex-col py-3 px-5 items-start w-full gap-2'>

                    <div className='flex flex-col items-start w-full gap-2'>
                        <div className='w-full flex items-center gap-5'>
                            <span className='text-xs text-caspian-gray4'>
                                عنوان سند
                            </span>
                            {emptyTitle &&
                                <span className='text-xs text-caspian-error2'>فیلد الزامی *</span>
                            }
                        </div>


                        <input
                            type='text'
                            placeholder='عنوان سند را مشخص کنید'
                            onChange={(e) => setTitleDoc(e.target.value)}
                            ref={titleRef}

                            className='w-full rounded-md py-2 px-3 text-xs  font-semibold placeholder:text-caspian-gray placeholder:font-normal outline-none border-2 border-caspian-gray2/80' />
                    </div>

                    <div className='flex flex-col items-start w-full gap-2'>
                        <span className='text-xs 2 text-caspian-gray4'>توضیحات</span>
                        <textarea placeholder='توضیحات خود را وارد کنید' rows={5} onChange={(e) => setDescreiptionDoc(e.target.value)} ref={descRef}
                            className='w-full rounded-md py-2 px-3 resize-none text-xs  font-semibold placeholder:text-caspian-gray placeholder:font-normal outline-none border-2 border-caspian-gray2/80' />
                    </div>

                    <div className='flex flex-col items-start w-full gap-2'>

                        <div className='w-full flex items-center gap-5'>
                            <label htmlFor='upload-file' className='flex gap-2 items-center text-caspian-secondary-600 cursor-pointer'>
                                <PiPaperclip />
                                <span className='text-xs '>انتخاب فایل</span>
                            </label>
                            {emptyFile &&
                                <span className='text-xs text-caspian-error2'>فیلد الزامی *</span>
                            }
                        </div>

                        <input type='file' ref={fileRef} id='upload-file' className='hidden' onChange={(e) => {
                            setFile(e.target.files[0])
                            setShowFiles(true)
                        }}
                        />

                        {showFiles &&
                            <ShowUploadFile fileRef={fileRef} file={file} setFile={setFile} showFiles={showFiles} setShowFiles={setShowFiles} />
                        }
                    </div>
                </div>



                <div className='flex gap-5 px-5 items-center justify-start w-full '>
                    <button
                        onClick={handleDocument}
                        className='text-xs 2xl:text-sm text-caspian-secondary-600 border border-caspian-secondary-600 rounded-md w-[70px] h-[30px] '>
                        تائید
                    </button>

                    <button
                        onClick={clearDate}
                        className='text-xs 2xl:text-sm text-caspian-error2 '>
                        لغو
                    </button>
                </div>
            </div>

        </Modal>

    )
}

export default ModalUploadFile