import React, { useContext, useState } from "react";
import { PiCircleNotch, PiX } from "react-icons/pi";

import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../context/auth/AuthContext";
import API from "../../../api/API";
import Modal from "../../modal/Modal";

const DeleteSchedulePopup = ({
  setShowDeletePopup,
  scheduleTimeId,
  title,
  // setRefreshProfile,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const removeSchedule = async () => {
    if (!scheduleTimeId || !token) return;
    setLoading(true);
    try {
      const res = await API.delete(
        `/api/v1/audit/profile/schedule/time/${scheduleTimeId}/`,
        {
          headers: { Authorization: token },
        }
      );
      console.log(res?.data);
      // setRefreshProfile(1);
      navigate(`/audit/${id}`, { state: "refresh" });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal>
      <div className="flex flex-col gap-0 min-w-[320px] max-w-[320px] z-50 drop-shadow-[0_0_5px_#00000080]  bg-caspian-surface rounded-md">
        <div className=" flex flex-col gap-0">
          <div className="flex items-center justify-between w-full p-3 pb-0">
            <PiX
              onClick={() => !loading && setShowDeletePopup(false)}
              className="cursor-pointer mr-auto"
            />
          </div>
          <div className="min-h-[120px] max-h-[120px] p-3 flex flex-col gap-6">
            <p className="text-sm text-center">آیا ممیزی {title} حذف شود؟</p>
            <div className="flex items-center justify-center gap-4 text-xs">
              <button
                onClick={removeSchedule}
                className="relative rounded-md p-2 px-6 bg-caspian-error text-caspian-surface flex items-center justify-center gap-2 w-auto"
              >
                <span>بله، حذف شود</span>
                {loading && (
                  <div className="absolute top-1/2 -translate-y-1/2 left-1">
                    <PiCircleNotch className="animate-spin text-base" />
                  </div>
                )}
              </button>
              <button
                onClick={() => !loading && setShowDeletePopup(false)}
                className=""
              >
                خیر
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteSchedulePopup;
