import React, { useContext, useState } from "react";
import { PiX } from "react-icons/pi";
import API from "../../../api/API";
import { AuthContext } from "../../../context/auth/AuthContext";

const AddFolder = ({ setShowAddFolder, setRefresh }) => {
  const { user, token } = useContext(AuthContext);
  console.log(user);
  const [title, setTitle] = useState("");

  const addFolder = () => {
    if (!title || !user) return null;
    API.post(
      "/api/v1/note/notebook/",
      {
        title: title,
        owner: user?.placementProfileRelated?.id,
      },
      {
        headers: { Authorization: token },
      }
    )
      .then((res) => {
        console.log(res.data);
        setRefresh(1);
        setShowAddFolder(false);
      })
      .catch((err) => {
        console.log("error");
      });
  };
  return (
    <div className="bg-caspian-surface rounded-md border border-caspian-gray1 shadow-[0_0_10px_#00000050] p-3 absolute top-32 left-1/2 -translate-x-1/2 w-[90%] h-[150px] flex flex-col justify-between gap-4">
      <div className="flex items-center justify-between w-full">
        <h2 className="text-xs font-bold">ایجاد پوشه</h2>
        <PiX
          onClick={() => setShowAddFolder(false)}
          className="cursor-pointer text-sm text-caspian-gray4"
        />
      </div>
      <input
        onChange={(e) => setTitle(e.target.value)}
        type="text"
        className="p-2 bg-caspian-transparent text-xs outline-none shadow-[0_0_5px_#00000050] rounded-md"
        placeholder="عنوان پوشه"
      />
      <div className="flex items-center gap-4">
        <button
          onClick={addFolder}
          className="text-xs rounded-sm bg-caspian-secondary-500 text-caspian-surface px-3 py-1"
        >
          ایجاد
        </button>
        <button
          onClick={() => setShowAddFolder(false)}
          className="text-xs text-caspian-error2"
        >
          انصراف
        </button>
      </div>
    </div>
  );
};

export default AddFolder;
