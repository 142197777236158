import { useRef } from "react";
import {
  PiCheck,
  PiPaperclip,
  PiTelegramLogo,
  PiUpload,
  PiX,
} from "react-icons/pi";

const ChatInput = ({
  messageInput,
  handleInputChange,
  messageHandler,
  editMessageHandler,
  editButton,
  setMessageId,
  setEditButton,
  setMessageInput,
  fileHandler,
  setFile,
}) => {
  const fileRef = useRef();
  return (
    <div className="bg-caspian-gray2/50 w-full">
      <div className="flex items-end gap-2 w-full p-2">
        <input
          value={messageInput}
          onChange={handleInputChange}
          placeholder="پیام خود را اینجا وارد کنید"
          className="resize-none p-2 w-[90%] outline-none bg-caspian-surface shadow-[0_0_5px_#00000050] rounded-md text-xs"
        />
        {editButton ? (
          <div className="flex flex-col justify-center items-center gap-1 w-[10%]">
            <button
              onClick={() => {
                setMessageId(null);
                setEditButton(false);
                setMessageInput("");
              }}
              type="button"
              className="text-xs text-caspian-surface bg-caspian-gray rounded-full p-1 w-fit duration-300"
            >
              <PiX />
            </button>
            <button
              type="button"
              onClick={editMessageHandler}
              className="w-8 h-8 rounded-full bg-caspian-primary-100 flex items-center justify-center"
            >
              <PiCheck className="text-2xl text-caspian-primary-700" />
            </button>
          </div>
        ) : (
          <div className="w-[10%] relative">
            <button
              type="button"
              onClick={messageHandler}
              className="w-8 h-8 rounded-full bg-caspian-primary-100 flex items-center justify-center shadow-[0_0_5px_#00000050]"
            >
              <PiTelegramLogo className="text-2xl text-caspian-primary-700" />
            </button>
            {/* <div>
              <label
                htmlFor="imageFile"
                className="absolute bottom-7 left-5 w-5 h-5 rounded-full bg-caspian-surface flex items-center justify-center shadow-[0_0_5px_#00000050] cursor-pointer"
              >
                <PiPaperclip className="text-2xl text-caspian-gray4" />
                <input
                  name="file"
                  className="hidden"
                  ref={fileRef}
                  id="imageFile"
                  type="file"
                  accept=".png,.jpg"
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                  }}
                />
              </label>
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatInput;
