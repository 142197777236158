import React from "react";
import { PiWarningCircle } from "react-icons/pi";

const NotfoundData = () => {
  return (
    <div className="flex items-center justify-center flex-col gap-2 py-12">
      <span className="text-xs text-caspian-gray4">اطلاعات یافت نشد</span>
      <PiWarningCircle className="text-caspian-gray text-3xl" />
    </div>
  );
};

export default NotfoundData;
