import React from "react";
import ButtonChart from "../ButtonChart";

const Chart1 = () => {
  return (
    <div className={`flex flex-col items-center mx-40 2xl:mx-auto w-full min-w-[760px] max-w-[760px]

    `}>

      <div className="flex flex-col w-full items-center h-full">
        {/* 1 */}
        <ButtonChart
          title="مدیرعامل"
          bgColor="#DDEAF4"
          textColor="#4B6B90"
          borderColor="#4B6B90"
        />
        {/* 1 */}
        <div className="h-5 border-r-2 border-r-caspian-gray"></div>
        {/* 2 */}
        <ButtonChart
          title="قائم مقام"
          bgColor="#F6E5E7"
          textColor="#7A3F47"
          borderColor="#7A3F47"
        />
        {/* 2 */}
      </div>

      <div className="h-5 border-r-2 border-r-caspian-gray"></div>

      <div className="flex flex-col w-full items-center">
        {/* 3 */}
        <div className="flex justify-center relative h-64 gap-10 items-center">
          <div className="flex flex-col gap-2 h-full items-center">
            <div className="flex items-center justify-center">
              <ButtonChart
                title="دفتر هیئت مدیره و مدیر عامل"
                bgColor="#C7EBEA"
                textColor="#225C58"
                borderColor="#225C58">
              </ButtonChart>
              <div className="w-14 absolute right-[47%] h-2 border-b-2 border-b-caspian-gray"></div>
            </div>

            <div className="flex items-center justify-center">
              <ButtonChart
                title="مشاور"
                bgColor="#C7EBEA"
                textColor="#225C58"
                borderColor="#225C58">
              </ButtonChart>
              <div className="w-14 absolute right-[47%] h-2 border-b-2 border-b-caspian-gray"></div>
            </div>

            <div className="flex items-center justify-center">
              <ButtonChart
                title="دفتر حراست و امور محرمانه"
                bgColor="#C7EBEA"
                textColor="#225C58"
                borderColor="#225C58">
              </ButtonChart>
              <div className="w-14 absolute right-[47%] h-2 border-b-2 border-b-caspian-gray"></div>
            </div>

            <div className="flex items-center justify-center">
              <ButtonChart
                title="مدیر نواحی"
                bgColor="#C7EBEA"
                textColor="#225C58"
                borderColor="#225C58">
              </ButtonChart>
              <div className="w-14 absolute right-[47%] h-2 border-b-2 border-b-caspian-gray"></div>
            </div>
          </div>

          <div className="h-full border-r-2 border-r-caspian-gray"></div>

          <div className="flex flex-col gap-2  h-full items-center">
            <div className="flex items-center justify-center">
              <ButtonChart
                title="دفترروابط عمومی و آموزش همگانی"
                bgColor="#C7EBEA"
                textColor="#225C58"
                borderColor="#225C58">
              </ButtonChart>
              <div className="w-14 absolute left-[47%] h-2 border-b-2 border-b-caspian-gray"></div>
            </div>

            <div className="flex items-center justify-center">
              <ButtonChart
                title="دفتر مدیریت بحران،پدافند غیرعامل وHSE"
                bgColor="#C7EBEA"
                textColor="#225C58"
                borderColor="#225C58">
              </ButtonChart>
              <div className="w-14 absolute left-[47%] h-2 border-b-2 border-b-caspian-gray"></div>
            </div>

            <div className="flex items-center justify-center">
              <ButtonChart
                title="دفتر حقوقی و رسیدگی به شکایات"
                bgColor="#C7EBEA"
                textColor="#225C58"
                borderColor="#225C58">
              </ButtonChart>
              <div className="w-14 absolute left-[47%] h-2 border-b-2 border-b-caspian-gray"></div>
            </div>

            <div className="flex items-center justify-center">
              <ButtonChart
                title="دفتر قراردادهاو بازرگانی"
                bgColor="#C7EBEA"
                textColor="#225C58"
                borderColor="#225C58">
              </ButtonChart>
              <div className="w-14 absolute left-[47%] h-2 border-b-2 border-b-caspian-gray"></div>
            </div>
          </div>
        </div>
        {/* 3 */}

        <div className="flex w-full flex-col justify-center relative gap-5 items-center">
          <div className="w-[880px] h-2 border-t-2 border-t-caspian-gray"></div>
          {/* 4 */}
          <div className="flex w-full items-start justify-center gap-10">
            <div className="flex flex-col gap-2 h-full items-center">
              <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
              <div className="flex items-center relative h-full">
                <div className="h-[70%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>
                <div className="flex flex-col items-center gap-2 h-full">
                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="معاونت مهندسی و توسعه"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="دفتر مطالعات و بررسی های فنی"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="امور اجرایی طرح های آب و فاضلاب"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>
                </div>
              </div>
            </div>


            <div className="flex flex-col gap-2 h-full items-center">
              <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
              <div className="flex items-center relative h-full">
                <div className="h-[87.5%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>
                <div className="flex flex-col items-center gap-2 h-full">
                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="معاونت بهره برداری"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="مشاور"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="دفتر بهره برداری از تاسیسات آب"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="دفتر بهره برداری از تاسیسات فاضلاب"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="دفتر مدیریت مصرف، کاهش آب بدون در آمد"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="امور کنترل کیفیت و بهداشت آب و فاضلاب"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>
                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="دفتر سامانه‌های کنترل و انرژی"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-2 h-full items-center">
              <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
              <div className="flex items-center relative h-full">
                <div className="h-[70%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>
                <div className="flex flex-col items-center gap-2 h-full">
                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="معاونت خدمات مشترکین و درآمد"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="دفتر خدمات مشترکین"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="دفتر نظارت بردرآمد و وصول مطالبات"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-2 h-full items-center">
              <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
              <div className="flex items-center relative h-full">
                <div className="h-[82%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>
                <div className="flex flex-col items-center gap-2 h-full">
                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="معاونت برنامه ریزی و منابع انسانی"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="دفتر برنامه ریزی و بودجه"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="دفتر تشکیلات،آموزش و منابع انسانی"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="دفتر توسعه مدیریت و تحقیقات"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="امور کارکنان و رفاه"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-20 h-full items-center">
              <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>

              <div className="flex items-center relative  h-full">
                <div className="h-[69%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>

                <div className="flex flex-col items-center gap-2 h-full">
                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="مالی و پشتیبانی"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="امور مالی"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="امور پشتیبانی"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* 4 */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chart1;
