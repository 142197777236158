import React, { useRef } from "react";
import { PiMagnifyingGlassBold, PiX } from "react-icons/pi";

const SearchInput = ({ searchMember, setSearchMember }) => {
  const searchRef = useRef();

  const clearHandler = (e) => {
    setSearchMember("");
    searchRef.current.value = "";
  };

  return (
    <form className="relative w-full">
      <input
        className="w-full rounded-md bg-caspian-surface px-7 py-2 focus:outline-none text-xs border border-caspian-gray2"
        placeholder="جستجو"
        ref={searchRef}
        type="search"
        onChange={(e) => setSearchMember(e.target.value)}
      />
      <PiMagnifyingGlassBold className="absolute top-1/2 -translate-y-1/2 right-2 text-caspian-gray" />
      {searchMember?.length > 0 && (
        <PiX
          onClick={clearHandler}
          className="absolute top-1/2 -translate-y-1/2 left-2 text-caspian-gray4 text-lg"
        />
      )}
    </form>
  );
};

export default SearchInput;
