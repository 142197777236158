import { useState } from "react";

const ReplySingle = ({ replyText, date, fullName, position, profileImg, positionActionPlan, item, replay
}) => {

  console.log(replay)

  const [showText, setShowText] = useState(false);

  return (
    <div className="relative flex flex-col gap-4">
      <div className="first:rounded-tr-lg border-t border-r absolute -right-4 top-4 w-4 h-full border-caspian-gray2/90"></div>
      <div className="flex justify-between items-center ">
        <div className="flex items-center gap-2">
          <img alt="" className="rounded-full w-8 h-8" src={profileImg}></img>

          <div className="flex flex-col">
            <div className="flex items-center gap-2 ">
              <h3 className="text-caspian-secondary-900 text-sm">{fullName}</h3>
              <span className="text-caspian-gray text-[10px] relative ">
                {position}
              </span>
            </div>

            <span className="text-caspian-gray text-[10px]">
              {positionActionPlan}
            </span>
          </div>
        </div>
        <div className="flex justify-center items-center ">
          <p className="text-caspian-gray text-xs">
            {new Date(date).toLocaleDateString("fa-IR")}
          </p>
        </div>
      </div>
      <p className="text-xs pb-4">
        {showText ? replay?.slice(0, 120) : replay}
        {replay?.length > 120 && (
          <span
            onClick={() => setShowText(!showText)}
            className="text-caspian-secondary-600 mx-1 text-[12px] cursor-pointer "
          >
            {showText ? "بیشتر" : "کمتر"}
          </span>
        )}
      </p>
    </div>
  );
};

export default ReplySingle;
