import { PiCheckCircle, PiCheckCircleFill, PiTrash, PiX, PiXCircleFill } from "react-icons/pi";
import API from "../../../../../api/API";
import { toast } from "react-toastify";
import { useState } from "react";

const SingleTableDraftProfileMeeting = ({
  id,
  title,
  type,
  period,
  date,
  setShowDraftMeetingForm,
  setDraftId,
  setDarftTitle,
  setRefresh,
}) => {
  const removeHandler = (isId) => {
    API.delete(`/api/v1/meeting/profile/detail/${isId}/`)
      .then((res) => {
        setRefresh(1);
        console.log("remove");
        toast.success(`${title} با موفقیت حذف شد.`, {
          className: "toast-success",
          bodyClassName: "toast-success",
        });
      })
      .catch((err) => {
        toast.error("مشکلی در حذف پیش آمد!", {
          className: "toast-faild",
          bodyClassName: "toast-faild",
        });
      });
  };
  const [showIsDelete, SetshowIsDelete] = useState(false);
  return (
    <tr className="bg-caspian-surface duration-300">
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 p-2 w-[20%]">
        <h2 className="text-center">{title}</h2>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 min-w-[100px] w-[20%]">
        <h2 className="text-center">{type?.title}</h2>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 p-2 w-[20%]">
        <h2 className="text-center">{period ? period : "--------"}</h2>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 p-2 w-[20%]">
        <h2 className="text-center">
          {new Date(date).toLocaleDateString("fa-IR", {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </h2>
      </td>
      <td className="whitespace-nowrap text-right border-b border-b-caspian-gray2 p-2 w-[20%]">
        <div className="flex justify-center items-center gap-2">
          <button
            onClick={() => {
              setShowDraftMeetingForm(true);
              setDraftId(id);
              setDarftTitle(title);
            }}
            className="min-w-[6rem] outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs p-1.5 rounded-md"
          >
            تکمیل پروفایل
          </button>
          <div className="relative flex items-center justify-center w-full">
            <button
              onClick={() => {
                SetshowIsDelete(true);
              }}
              className="text-caspian-error2/30 hover:text-caspian-error2 duration-300 text-xl"
            >
              <PiTrash />
            </button>
            <div
              className={
                showIsDelete
                  ? "absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center gap-0.5 w-fit bg-caspian-surface shadow-[0_0_5px_#00000050] p-0.5 rounded-md duration-500"
                  : "hidden duration-500"
              }
            >
              <PiCheckCircleFill
                onClick={() => {
                  SetshowIsDelete(false);
                  removeHandler(id);
                }}
                className="text-caspian-primary-600 text-lg cursor-pointer"
              />
              <PiXCircleFill
                onClick={() => {
                  SetshowIsDelete(false);
                }}
                className="text-caspian-gray text-lg cursor-pointer"
              />
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default SingleTableDraftProfileMeeting;
