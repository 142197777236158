import React, { useContext, useState } from 'react';
import Modal from '../../../../../../../modal/Modal';
import { PiCircleNotch, PiX } from 'react-icons/pi';
import API from '../../../../../../../../api/API';
import { AuthContext } from '../../../../../../../../context/auth/AuthContext';

const EditDesc = ({ id, data, setRefresh, setEditDesc }) => {
    const { token } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [inp, setInp] = useState('');
    const idDesc = data?.positionRelatedForDescribtion.length > 0 ? data?.positionRelatedForDescribtion[0]?.id : -1;
    const desc = data?.positionRelatedForDescribtion[0]?.describtion;

    const submitHandler = async () => {
        let formData = {
            describtion: inp,
            positionRelatedDescribtion: id
        }
        console.log(formData);
        setIsLoading(true);
        let response;
        try {
            if (idDesc !== -1) {
                response = await API.patch(`/api/v1/organization/role/describtionposition/${idDesc}/`, formData,
                    { headers: { Authorization: token } }
                )
            }
            else {
                response = await API.post('/api/v1/organization/role/describtionposition/', formData, { headers: { Authorization: token } })
            }
            if (response?.data) {
                console.log(response?.data);
                setRefresh(true);
                setIsLoading(false);
                setEditDesc(false);
            }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <Modal>
            <div className='bg-caspian-surface w-[50%] max-w-[600px] rounded-md'>
                <div className='flex justify-between items-center p-1.5'>
                    <span className='font-semibold'>ویرایش فرم توضیحات شرح وظایف</span>
                    <PiX disabled={isLoading} className='cursor-pointer' onClick={() => setEditDesc(false)} />
                </div>
                <div className='border-t border-caspian-gray1 px-1.5 flex flex-col gap-4 py-5'>
                    <span className='text-xs text-caspian-gray4'>توضیحات:</span>
                    <textarea type="text"
                        defaultValue={desc}
                        onChange={(e) => setInp(e.target.value)}
                        rows={5}
                        cols={5}
                        placeholder='توضیحات...'
                        className='border border-caspian-gray1 resize-none rounded-md p-1.5 text-xs placeholder:text-caspian-gray4 placeholder:text-xs'
                    />
                    <button
                        onClick={() => submitHandler(id)}
                        className='flex justify-center gap-2 w-[100px] p-1.5 rounded-md text-caspian-surface bg-[#3C77C2] '>
                        <span>ثبت</span>
                        {isLoading && <span>
                            <PiCircleNotch className='animate-spin' />
                        </span>}
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default EditDesc;
