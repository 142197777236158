import DatePicker, { DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import { useEffect, useState } from "react";

//gregorian calendar & locale
import gregorian from "react-date-object/calendars/gregorian";
import gregorian_fa from "react-date-object/locales/gregorian_fa";

function toEnglishNumber(number) {
  // تبدیل عدد به رشته
  var str = number?.toString();

  // جایگزینی کاراکترهای فارسی با کاراکترهای انگلیسی
  str = str?.replace(/۰/g, "0");
  str = str?.replace(/۱/g, "1");
  str = str?.replace(/۲/g, "2");
  str = str?.replace(/۳/g, "3");
  str = str?.replace(/۴/g, "4");
  str = str?.replace(/۵/g, "5");
  str = str?.replace(/۶/g, "6");
  str = str?.replace(/۷/g, "7");
  str = str?.replace(/۸/g, "8");
  str = str?.replace(/۹/g, "9");

  // بازگشت رشته تبدیل‌شده
  return str;
}
const DateTimePickerCDTAD = ({ setDateTahvil }) => {
  const [state, setState] = useState({ format: "MM/DD/YYYY" });
  const convert = (date, format = state.format) => {
    let object = { date, format };

    setState({
      gregorian: new DateObject(object)
        .convert(gregorian, gregorian_fa)
        .format(),
      persian: new DateObject(object).format(),
      jsDate: date?.toDate(),
      ...object,
    });
  };

  // console.log(toEnglishNumber(new Date()?.toLocaleDateString("fa-IR")));
  // console.log(toEnglishNumber(state?.persian));

  // console.log(toEnglishNumber(state?.gregorian)?.replaceAll("/", "-"));
  // console.log(
  //   state?.gregorian &&
  //     new Date(toEnglishNumber(state?.gregorian)).toISOString().split(".")[0] +
  //       "Z"
  // );
  useEffect(() => {
    setDateTahvil(
      state?.gregorian &&
        new Date(toEnglishNumber(state?.gregorian))
          .toISOString()
          .split(".")[0] + "Z"
    );
  }, [state?.gregorian, setDateTahvil]);
  return (
    <DatePicker
      minDate={new Date()}
      value={state?.persian}
      onChange={convert}
      calendar={persian}
      locale={persian_fa}
      calendarPosition="bottom-right"
      format="YYYY/MM/DD HH:mm:ss"
      plugins={[<TimePicker position="right" />]}
      placeholder="انتخاب کنید"
      inputClass="p-1.5 text-sm rounded-md outline outline-1 outline-caspian-gray2 w-full cursor-pointer"
    />
  );
};

export default DateTimePickerCDTAD;
