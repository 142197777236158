import React from "react";
import { PiX } from "react-icons/pi";

const PopUpPodCast = ({ setShowPopupPodCast, popupPodCastData }) => {
  console.log(popupPodCastData);
  return (
    <div className="fixed inset-0 bg-caspian-gray/50 z-50">
      <div className="flex items-center justify-center w-full h-full">
        <div className="flex flex-col gap-4 p-4 rounded-xl w-1/2 h-auto bg-caspian-gray">
          <div className="flex items-center justify-between z-50">
            <h2 className="text-caspian-surface bg-caspian-on_primary/50 shadow-md p-2 rounded-xl text-sm">
              {popupPodCastData.title}
            </h2>
            <button onClick={() => setShowPopupPodCast(false)} className="">
              <PiX className="text-2xl text-caspian-surface" />
            </button>
          </div>
          <div className="bg-cover w-full h-auto flex items-center justify-center">
            <audio className="w-full" src={popupPodCastData.file} controls={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUpPodCast;
