import React, { useState } from "react";
import ButtonTab from "./ButtonTab";
import GeneralMeeting from "./forms/GeneralMeeting";
import Roles from "./forms/Roles";
import InputOutput from "./forms/InputOutput";
import OtherInformation from "./forms/OtherInformation";
import RefrenceMeeting from "./forms/RefrenceMeeting";

const CreateMeetingForm = ({
  generalId,
  setGeneralId,
  generalIdCreated,
  setGeneralIdCreated,
  createFormStart,
  setCreateFormStart,
  setShowCreateMeetingForm,
  setRefresh,
  dataDraft,
  id,
  setDataRefresh,
}) => {
  const [activeTab, setActiveTab] = useState("generalMeeting");
  const [data, setData] = useState();

  return (
    <div className="flex flex-col justify-between h-full">
      {/* تب های فرم برنامه ریزی برگزار جلسه */}
      <div className="flex items-center justify-between gap-4 bg-caspian-gray2/40 py-1.5 px-2 rounded-md">
        <ButtonTab
          activeTab={activeTab}
          active="generalMeeting"
          onClick={() => setActiveTab("generalMeeting")}
          title="کلیات جلسه"
        />
        <ButtonTab
          activeTab={activeTab}
          active="refrence"
          onClick={() => setActiveTab("refrence")}
          title="مرجع"
          setShowCreateMeetingForm={setShowCreateMeetingForm}
          setRefresh={setRefresh}
        />
        <ButtonTab
          activeTab={activeTab}
          active="roles"
          onClick={() => setActiveTab("roles")}
          title="نقش ها"
        />
        <ButtonTab
          activeTab={activeTab}
          active="inputOutput"
          onClick={() => setActiveTab("inputOutput")}
          title="ورودی و خروجی جلسه"
        />
        <ButtonTab
          activeTab={activeTab}
          active="otherInformation"
          onClick={() => setActiveTab("otherInformation")}
          title="سایر اطلاعات"
        />
      </div>
      <div className=" py-2">
        {/* کلیات جلسه  */}
        {activeTab === "generalMeeting" && (
          <GeneralMeeting
            createFormStart={createFormStart}
            setCreateFormStart={setCreateFormStart}
            dataDraft={dataDraft}
            data={data}
            setData={setData}
            setActiveTab={setActiveTab}
            generalId={generalId}
            setGeneralId={setGeneralId}
            generalIdCreated={generalIdCreated}
            setGeneralIdCreated={setGeneralIdCreated}
            setShowCreateMeetingForm={setShowCreateMeetingForm}
            setRefresh={setRefresh}
            setDataRefresh={setDataRefresh}
          />
        )}
        {/* مرجع ها */}
        {activeTab === "refrence" && (
          <RefrenceMeeting
            setActiveTab={setActiveTab}
            generalId={generalId}
            setShowCreateMeetingForm={setShowCreateMeetingForm}
            setRefresh={setRefresh}
            setDataRefresh={setDataRefresh}
          />
        )}
        {/* نقش ها */}
        {activeTab === "roles" && (
          <Roles
            dataDraft={dataDraft}
            generalId={id}
            setActiveTab={setActiveTab}
            setShowCreateMeetingForm={setShowCreateMeetingForm}
            setRefresh={setRefresh}
            setDataRefresh={setDataRefresh}
          />
        )}
        {/* ورودی و خروجی جلسه */}
        {activeTab === "inputOutput" && (
          <InputOutput
            generalId={id}
            setActiveTab={setActiveTab}
            setShowCreateMeetingForm={setShowCreateMeetingForm}
            setRefresh={setRefresh}
            setDataRefresh={setDataRefresh}
          />
        )}
        {/* سایر اطلاعات */}
        {activeTab === "otherInformation" && (
          <OtherInformation
            dataDraft={dataDraft}
            setShowCreateMeetingForm={setShowCreateMeetingForm}
            generalId={id}
            setActiveTab={setActiveTab}
            setRefresh={setRefresh}
            setDataRefresh={setDataRefresh}
          />
        )}
      </div>
    </div>
  );
};

export default CreateMeetingForm;
