import React, { useContext, useEffect, useState } from "react";
import API from "../../../../api/API";
import { AuthContext } from "../../../../context/auth/AuthContext";
import MainPresent from "./present/MainPresent";
import MainApprovals from "./approvals/MainApprovals";
import { PiCaretLeft } from "react-icons/pi";

import { useSearchParams, useNavigate } from "react-router-dom";
import MainDetailsPlaninngMeeting from "../../profile-meeting/all-profiles/details-planning-meeting/MainDetailsPlaninngMeeting";
import MainFilesMeeting from "./files-meeting/MainFilesMeeting";

const MainDetailsListMeeting = ({ idMeeting, setRefreshMeetingList }) => {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const [fullData, setFullData] = useState([-1]);
  const [loading, setLoading] = useState(false);
  const [refreshFullData, setRefreshFullData] = useState(0);

  const [serachParams, setSearchParams] = useSearchParams();
  const meeting = serachParams.get("meeting");

  function handleSearch(key, value) {
    setSearchParams((prevParams) => {
      if (value === null || value === "") {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    });
  }
  useEffect(() => {
    if (token) {
      setLoading(true);
      let url = `/api/v1/meeting/info/detail/${idMeeting}/`;
      API.get(url, {
        headers: { Authorization: token },
      })
        .then((d) => {
          setFullData(d.data);
          setRefreshFullData(0);
        })
        .catch((err) => {
          console.log("error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [idMeeting, token, refreshFullData]);
  console.log(fullData);

  const [present, setPresent] = useState(true);
  const [approvals, setApprovals] = useState(false);
  const [filesMeeting, setFilesMeeting] = useState(false);
  const [activeTab, setActiveTab] = useState("present");

  useEffect(() => {}, []);

  return fullData?.status === "archived" ? (
    navigate(`/managment-of-meetings/records-meeting/${idMeeting}`, {
      relative: "path",
    })
  ) : (
    <>
      <button
        onClick={() =>
          meeting
            ? handleSearch("meeting", "")
            : navigate("/managment-of-meetings/list-meeting/all-meeting")
        }
        className="mr-auto flex items-center gap-2 text-caspian-secondary-700 p-2"
      >
        <span className="text-sm">بازگشت</span>
        <PiCaretLeft />
      </button>
      {fullData?.is_secretary && meeting ? (
        <div className="w-full min-h-screen bg-caspian-surface rounded-sm p-2 flex flex-col gap-2">
          <div>
            <h2 className="text-sm text-caspian-gray4">
              جلسه {fullData.title}
            </h2>
          </div>
          <div className="flex flex-col gap-0">
            <div className="flex items-center gap-0">
              <span
                // onClick={() => {
                //   setPresent(true);
                //   setApprovals(false);
                //   setFilesMeeting(false);
                // }}
                className={`p-2 rounded-t-md text-sm ${
                  present ? "bg-caspian-gray3" : "bg-caspian-transparent"
                }`}
              >
                حضور/غیاب
              </span>
              <span
                // onClick={() => {
                //   setPresent(false);
                //   setApprovals(true);
                //   setFilesMeeting(false);
                // }}
                className={`p-2 rounded-t-md text-sm ${
                  approvals ? "bg-caspian-gray3" : "bg-caspian-transparent"
                }`}
              >
                مصوبات
              </span>
              <span
                // onClick={() => {
                //   setPresent(false);
                //   setApprovals(false);
                //   setFilesMeeting(true);
                // }}
                className={`p-2 rounded-t-md text-sm ${
                  filesMeeting ? "bg-caspian-gray3" : "bg-caspian-transparent"
                }`}
              >
                فایل ها
              </span>
            </div>
            <div
              className={`w-full min-h-screen bg-caspian-gray3 rounded-md p-2 ${
                present ? "rounded-tr-none" : ""
              }`}
            >
              {present && (
                <MainPresent
                  setActiveTab={setActiveTab}
                  setRefreshFullData={setRefreshFullData}
                  meetingId={fullData?.id}
                  fullData={fullData?.meetingMemberRelated}
                  setApprovals={setApprovals}
                  setPresent={setPresent}
                />
              )}
              {approvals && (
                <MainApprovals
                  setActiveTab={setActiveTab}
                  setRefreshFullData={setRefreshFullData}
                  idMeeting={idMeeting}
                  thisApproval={fullData?.meetingAprovalRelated}
                  output={
                    fullData?.meetingProfileRelated?.outputMeetingProfileRelated
                  }
                  setApprovals={setApprovals}
                  setFilesMeeting={setFilesMeeting}
                  setPresent={setPresent}
                />
              )}
              {filesMeeting && (
                <MainFilesMeeting
                  setApprovals={setApprovals}
                  setFilesMeeting={setFilesMeeting}
                  setRefreshFullData={setRefreshFullData}
                  setActiveTab={setActiveTab}
                  fullData={fullData?.MeetingFinalDocumentRelated}
                  documentsFile={fullData?.MeetingFinalDocumentRelated.filter(
                    (d) => d.documentsRelated.length > 0
                  )}
                  thisId={idMeeting}
                  setRefresh={setRefreshFullData}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          <MainDetailsPlaninngMeeting
            setRefreshMeetingList={setRefreshMeetingList}
          />
        </div>
      )}
    </>
  );
};

export default MainDetailsListMeeting;
