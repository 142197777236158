import ProfileNavbar from "../ProfileNavbar";
import Notification from "./Notification";
import { useEffect } from "react";
import JobInfoBox from "./JobInfoBox";
import { useAppContext } from "../../../context/app-context/AppContext";
import ProfilePicture from "../person-info/ProfilePicture";

const JobInfo = ({ showMenu }) => {
  const { setBradCrump1, setBradCrump2, setBradCrump3 } = useAppContext();
  useEffect(() => {
    setBradCrump1("پروفایل کاربری");
    setBradCrump2("اطلاعات شغلی");
    setBradCrump3("");
  }, [setBradCrump1, setBradCrump2, setBradCrump3]);

  return (
    <div
      className={
        showMenu
          ? `pl-[50px] mr-auto w-full md:w-[75%] lg:w-[80%] xl:w-[85%] 2xl:w-[85%] bg-caspian-gray3 p-1 overflow-y-scroll flex flex-col items-center gap-4`
          : `pl-[50px] mr-[54px] w-full bg-caspian-gray3 p-1 overflow-y-scroll flex flex-col items-center gap-4`
      }
    >
      <ProfileNavbar buttonActive="job-info" />
      <div className="flex flex-col gap-2 items-center justify-center w-3/4 py-4">
        <Notification />
        <ProfilePicture />
        <JobInfoBox />
      </div>
    </div>
  );
};

export default JobInfo;
