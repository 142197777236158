import React, { useContext, useEffect, useState } from "react";
import API from "../../../../../api/API";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import { toast } from "react-toastify";

const GeneralMeeting = ({
  setActiveTab,
  generalId,
  setGeneralId,
  generalIdCreated,
  setGeneralIdCreated,
  setShowCreateMeetingForm,
  setRefresh,
  dataDraft,
  setDataRefresh,
}) => {
  const { token } = useContext(AuthContext);
  // console.log(dataDraft);

  useEffect(() => {
    if (generalId !== null) {
      setGeneralIdCreated(generalId);
    }
  }, [generalId, setGeneralIdCreated]);

  const [title, setTitle] = useState(dataDraft?.title ? dataDraft?.title : "");
  const [type, setType] = useState(
    dataDraft?.meetingTypeRelated ? dataDraft?.meetingTypeRelated?.id : 1
  );
  const [typeData, setTypeData] = useState([]);
  const [periodData, setPeriodData] = useState("");

  useEffect(() => {
    const typeHandler = (e) => {
      API.get("api/v1/meeting/profile/type/", {
        headers: { Authorization: token },
      })
        .then((res) => {
          setTypeData(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    typeHandler();
  }, [token]);

  const dataHandler = async (input) => {
    // e.preventDefault();
    if (title === "") return null;

    if (generalIdCreated === null) {
      await API.post(
        "/api/v1/meeting/profile/detail/",
        {
          title: title,
          meetingTypeRelated: type,
          holdingTime: periodData,
          status: "general",
        },
        { headers: { Authorization: token } }
      )
        .then(async (res) => {
          await setGeneralId(res?.data?.id);
          if (input === "next") {
            await dataHandlerNext(res?.data?.id);
          } else if (input === "draft") {
            await dataHandlerDraft(res?.data?.id);
          }
          await console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (generalIdCreated) {
      API.patch(
        `/api/v1/meeting/profile/detail/${generalIdCreated}/`,
        {
          title: title ? title : dataDraft.title,
          holdingTime: periodData ? periodData : dataDraft.holdingTime,
          meetingTypeRelated: type ? +type : dataDraft?.meetingTypeRelated?.id,
        },
        { headers: { Authorization: token } }
      )
        .then(async (res) => {
          console.log(res);
          if (input === "next") {
            await dataHandlerNext(generalIdCreated);
          } else if (input === "draft") {
            await dataHandlerDraft(generalIdCreated);
          }
          setDataRefresh(1);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const dataHandlerNext = async (id) => { 
    // e.preventDefault();
    await API.post(
      `/api/v1/meeting/profile/detail/${id}/status/`,
      {
        status: "refrence",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await setActiveTab("refrence");
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataHandlerDraft = async (id) => {
    // e.preventDefault();
    await API.post(
      `/api/v1/meeting/profile/detail/${id}/status/`,
      {
        status: "general",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await toast.success("پروفایل به صورت پیشنویس ثبت شد.", {
          className: "toast-success",
          bodyClassName: "toast-success",
        });
        await setShowCreateMeetingForm(false);
        await setRefresh(1);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (title === "") {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [title]);

  return (
    <div className="min-h-[270px] flex flex-col justify-between">
      <div className="flex flex-col gap-3 h-full">
        <div className="flex items-center gap-3">
          {/* عنوان */}
          <div className="w-full flex flex-col gap-1.5">
            <label className="text-xs text-caspian-gray">عنوان</label>
            <input
              defaultValue={title ? title : dataDraft?.title}
              name="title"
              onChange={(e) => setTitle(e.target.value)}
              type="text"
              placeholder="عنوان پروفایل را وارد کنید"
              className="outline-none border border-caspian-gray1 p-1.5 text-xs rounded-md"
            />
          </div>
          {/* نوع جلسه */}
          <div className="flex flex-col gap-1.5 w-full">
            <label className="text-xs text-caspian-gray">نوع جلسه</label>
            <select
              onChange={(e) => setType(e.target.value)}
              name="typeMeeting"
              value={type ? +type : dataDraft?.meetingTypeRelated?.id}
              // defaultValue={dataDraft?.meetingTypeRelated?.id}
              className={`text-xs outline outline-1 outline-caspian-gray1 p-1.5 rounded-md bg-caspian-transparent cursor-pointer`}
            >
              {typeData.map((t, i) => (
                <option
                  className="text-caspian-on_primary"
                  key={i}
                  value={+t.id}
                >
                  {t.title}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="flex items-center gap-4 w-full">
          <div className="w-full flex flex-col gap-1.5">
            <label className="text-xs text-caspian-gray">دوره برگزاری</label>
            <input
              defaultValue={dataDraft.holdingTime}
              onChange={(e) => setPeriodData(e.target.value)}
              name="peridMeeting"
              type="text"
              placeholder="دوره برگزاری را وارد کنید"
              className="outline-none outline-caspian-gray1 p-1.5 text-xs rounded-md outline-1"
            />
          </div>
          <div className="w-full"></div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <button
          disabled={disableButton}
          onClick={async () => {
            await dataHandler("next");
          }}
          className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
        >
          مرحله بعد
        </button>
        <button
          disabled={disableButton}
          onClick={async () => {
            await dataHandler("draft");
          }}
          className={`disabled:cursor-not-allowed disabled:text-caspian-gray text-caspian-secondary-500 text-xs p-1.5 rounded-md`}
        >
          ذخیره پیشنویس
        </button>
      </div>
    </div>
  );
};

export default GeneralMeeting;
