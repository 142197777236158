import React from "react";
import { PiTagChevron, PiTagChevronFill } from "react-icons/pi";

const ChatListSingle = ({
  fullName,
  subject,
  desc,
  time,
  read,
  save,
  onClick,
}) => {
  return (
    <td
      className={`flex items-center gap-0 border-b border-caspian-gray3 ${
        read && "bg-[#F5F7F7]"
      }`}
    >
      <div className="flex items-center gap-4 px-5 py-2">
        <input type="checkbox" className="w-4 h-4" />
        <button>
          {save ? (
            <PiTagChevronFill className="text-xl text-[#fae360]" />
          ) : (
            <PiTagChevron className="text-xl" />
          )}
        </button>
      </div>
      <div
        onClick={onClick}
        className="w-full flex items-center justify-between gap-8 cursor-pointer"
      >
        <div className="flex items-center gap-8">
          <h2 className={`text-sm whitespace-nowrap ${!read && "font-bold"}`}>
            {fullName}
          </h2>
          <div className="flex items-center gap-1">
            <h2
              className={`text-sm  whitespace-nowrap ${!read && "font-bold"}`}
            >
              {subject}
            </h2>
            -<p className="text-sm text-caspian-gray line-clamp-1">{desc}</p>
          </div>
        </div>
        <div className="ml-8">
          <h2 className="text-sm whitespace-nowrap">{time}</h2>
        </div>
      </div>
    </td>
  );
};

export default ChatListSingle;
