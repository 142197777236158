const CircleChart = ({ daysRemaining, totalDays, remainedDay }) => {
  const size = 32;
  const radius = size / 2;
  const strokeWidth = 16;
  const circumference = 2 * Math.PI * (radius - strokeWidth / 2);
  const progress = daysRemaining / totalDays;
  const offset = circumference * (1 - progress);

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <circle
        cx={radius}
        cy={radius}
        r={radius - strokeWidth / 2}
        fill="none"
        stroke={remainedDay < 0 ? "#cf6679 " : "#dddddd"}
        strokeWidth={strokeWidth}
      />
      <circle
        cx={radius}
        cy={radius}
        r={radius - strokeWidth / 2}
        fill="none"
        stroke="#3BB758"
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        style={{ transform: "rotate(-90deg)", transformOrigin: "50% 50%" }}
      />
    </svg>
  );
};
export default CircleChart;
