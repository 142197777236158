import React, { useEffect, useState } from "react";
import { PiPlusBold } from "react-icons/pi";
import ListSelected from "./ListSelected";

const OneTabStepThree = ({
  setTabStep,
  description,
  setDescription,
  processFinalSelected,
  setProcessFinalSelected,
  processSelected,
  setProcessSelected,
  goalFinalSelected,
  setGoalFinalSelected,
  goalSelected,
  setGoalSelected,
  setIsDisable,
}) => {
  const [showListProcess, setShowListProcess] = useState(false);
  const [showListGoal, setShowListGoal] = useState(false);

  let processUrl = "/api/v1/businessProcess/process/";
  let processGroupUrl = "/api/v1/businessProcess/group/";
  const [isDisableOne, setIsDisableOne] = useState(true);
  useEffect(() => {
    if (
      !description ||
      processFinalSelected?.length < 1 ||
      goalFinalSelected?.length < 1
    ) {
      setIsDisableOne(true);
    } else {
      setIsDisableOne(false);
    }
  }, [description, processFinalSelected, goalFinalSelected]);

  useEffect(() => {
    setIsDisable(true);
  }, [setIsDisable]);
  return (
    <div className="flex flex-col justify-between h-full  min-h-[260px] max-h-[260px]">
      <div className="flex flex-col gap-2">
        <div className="flex items-center w-full gap-1.5">
          {showListProcess && (
            <ListSelected
              finalSelected={processFinalSelected}
              setFinalSelected={setProcessFinalSelected}
              selected={processSelected}
              setSelected={setProcessSelected}
              setShowListSelected={setShowListProcess}
              title="لیست فرانیدهای مرتبط"
              url={processGroupUrl}
            />
          )}
          {showListGoal && (
            <ListSelected
              finalSelected={goalFinalSelected}
              setFinalSelected={setGoalFinalSelected}
              selected={goalSelected}
              setSelected={setGoalSelected}
              setShowListSelected={setShowListGoal}
              title="لیست اهداف مرتبط"
              url="/api/v1/goal/goal/"
            />
          )}
          <div className="w-full flex flex-col gap-1.5">
            <label className="text-xs text-caspian-gray">
              فرایند مرتبط با این برنامه اجرایی
            </label>
            <button
              onClick={() => setShowListProcess(true)}
              className="rounded-md border border-caspian-secondary-400 p-1.5 flex items-center justify-between gap-1 w-full"
            >
              <div className="flex items-center gap-1.5">
                <PiPlusBold className="text-caspian-secondary-500 text-md" />
                <span className="text-caspian-secondary-500 text-xs">
                  افزودن
                </span>
              </div>
              {processFinalSelected?.length > 0 ? (
                <span className="text-xs">
                  {processFinalSelected?.length} فرایند افزوده شد
                </span>
              ) : (
                <span className="text-xs">فرایندی انتخاب نشده</span>
              )}
            </button>
          </div>
          <div className="w-full flex flex-col gap-1.5">
            <label className="text-xs text-caspian-gray">
              اهداف مرتبط با این برنامه اجرایی
            </label>
            <button
              onClick={() => setShowListGoal(true)}
              className="rounded-md border border-caspian-secondary-400 p-1.5 flex items-center justify-between gap-1 w-full"
            >
              <div className="flex items-center gap-1.5">
                <PiPlusBold className="text-caspian-secondary-500 text-md" />
                <span className="text-caspian-secondary-500 text-xs">
                  افزودن
                </span>
              </div>
              {goalFinalSelected?.length > 0 ? (
                <span className="text-xs">
                  {goalFinalSelected?.length} هدف افزوده شد
                </span>
              ) : (
                <span className="text-xs">هدفی انتخاب نشده</span>
              )}
            </button>
          </div>
          {/* <div className="w-full flex flex-col gap-1.5">
            <label className="text-xs text-caspian-gray">
              هدف مرتبط با این برنامه اجرایی
            </label>
            <select
              onChange={(e) => setGoal(e.target.value)}
              defaultValue={goalData[0]?.id}
              className={`outline-none text-xs border border-caspian-gray2 p-1.5 rounded-md bg-caspian-transparent cursor-pointer w-full`}
            >
              {goalData?.map((g, i) => (
                <option
                  key={i}
                  className="text-caspian-on_primary"
                  value={g?.id}
                >
                  {g?.title}
                </option>
              ))}
            </select>
          </div>
          <div className="w-full flex flex-col gap-1.5">
            <label className="text-xs text-caspian-gray">
              فرایند مرتبط با این برنامه اجرایی
            </label>
            <select
              onChange={(e) => setProcess(e.target.value)}
              defaultValue={processData[0]?.id}
              className={`outline-none text-xs border border-caspian-gray2 p-1.5 rounded-md bg-caspian-transparent cursor-pointer w-full`}
            >
              {processData?.map((p, i) => (
                <option
                  key={i}
                  className="text-caspian-on_primary"
                  value={p?.id}
                >
                  {p?.title}
                </option>
              ))}
            </select>
          </div> */}
        </div>
        <div className="w-full flex flex-col gap-1.5">
          <label className="text-xs text-caspian-gray">
            نتایج مورد انتظار از این برنامه اجرایی
          </label>
          <textarea
            defaultValue={description}
            name="description"
            rows={8}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="نتایج مورد انتظار از این برنامه اجرایی را یادداشت کنید"
            className="resize-none outline-none border border-caspian-gray1 p-1.5 text-xs rounded-md"
          />
        </div>
      </div>
      <div className="flex items-center gap-2">
        <button
          disabled={isDisableOne ? true : false}
          onClick={async () => {
            await setTabStep("StepTwo");
          }}
          className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
        >
          ثبت و رفتن به گام بعد
        </button>
      </div>
    </div>
  );
};

export default OneTabStepThree;
