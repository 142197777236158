import { useContext, useEffect, useState } from 'react'
import API from '../../../../../api/API';
import { AuthContext } from '../../../../../context/auth/AuthContext';
import { useSearchParams } from "react-router-dom";
import TableListReportsActionPlan from './TableListReportsActionPlan';
import FilterReportsActionPlan from './FilterReportsActionPlan';

const MainListReportsActionPlan = () => {

    const {
        token
    } = useContext(AuthContext)

    const [fullData, setFullData] = useState([-1])
    const [roleReports, setRoleReports] = useState("all")
    const [statusReports, setStatusReports] = useState("all")
    const [sortReports, setSortReports] = useState("a_start");
    const [searchReportActionPlan, setSearchReportActionPlan] = useState("");

    const [searchParams] = useSearchParams();
    const search = searchParams.get('search')

    useEffect(() => {
        if (!token) return
        const handleData = async () => {
            try {
                const res = await API.get('/api/v1/actionplan/taskreport/',
                    { headers: { Authorization: token } })
                console.log(res?.data)
                setFullData(res?.data)
            } catch (err) {
                console.log(err)
            }
        }
        handleData()
    }, [token])

    let searchTasks = [-1];
    let filterRole = [];
    let filterStatus = [];
    let sortData = [];

    if (fullData[0] !== -1) {

        searchTasks = searchReportActionPlan
            ? fullData?.filter((item) =>
                item?.reports?.some(report => report.toLowerCase().includes(searchReportActionPlan.toLowerCase())) ||
                item?.title?.toLowerCase().includes(searchReportActionPlan.toLowerCase()) ||
                item?.taskRelated?.title?.toLowerCase().includes(searchReportActionPlan.toLowerCase()) ||
                item?.taskRelated?.responsible_task?.firstName?.toLowerCase().includes(searchReportActionPlan.toLowerCase()) ||
                item?.taskRelated?.responsible_task?.lastName?.toLowerCase().includes(searchReportActionPlan.toLowerCase()) ||
                item?.taskRelated?.supervisor?.firstName?.toLowerCase().includes(searchReportActionPlan.toLowerCase()) ||
                item?.taskRelated?.supervisor?.lastName?.toLowerCase().includes(searchReportActionPlan.toLowerCase())
            )
            : fullData;

        filterRole =
            roleReports !== "all"
                ? searchTasks.filter((item) => item?.actionplanRelated?.userRole?.includes(roleReports))
                : searchTasks;

        filterStatus =
            statusReports !== "all"
                ? filterRole.filter((item) => item?.status === statusReports)
                : filterRole

        sortData =
            sortReports === "a_start"
                ? filterStatus?.sort((a, b) =>
                    b?.created_at > a?.created_at ? -1 : 1)

                : sortReports === "b_start"
                    ? filterStatus?.sort((a, b) =>
                        b?.created_at > a?.created_at ? 1 : -1)

                    : filterStatus?.sort((a, b) =>
                        b?.created_at > a?.created_at ? -1 : 1);


    }

    useEffect(() => {
        if (search) {
            setSearchReportActionPlan(search)
        }
    }, [search])

    return (
        <div className='flex w-full flex-col items-center gap-5'>

            <div className='w-full flex flex-col items-center gap-5 px-5 '>
                <FilterReportsActionPlan
                    searchReportActionPlan={searchReportActionPlan}
                    setSearchReportActionPlan={setSearchReportActionPlan}
                    roleReports={roleReports}
                    setRoleReports={setRoleReports}
                    statusReports={statusReports}
                    setStatusReports={setStatusReports}
                    sortReports={sortReports}
                    setSortReports={setSortReports}
                />

                <div className='flex w-[95%] items-center justify-between py-5 gap-5'>
                    <span>گزارش‌ها</span>
                    <div className='h-[1.5px] w-[90%] bg-caspian-gray2'></div>
                </div>

                <TableListReportsActionPlan
                    sortData={sortData}
                    fullData={fullData}
                />
            </div>

        </div >
    )
}

export default MainListReportsActionPlan