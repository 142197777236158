import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import GoalsNavbar from "../GoalsNavbar";
import API from "../../../api/API";
import ListGoalsTable from "./goalstable/ListGoalsTable";
import Loading from "../../../Loading";
import { AuthContext } from "../../../context/auth/AuthContext";

const MainListGoals = () => {
  const { token } = useContext(AuthContext);
  const [data, setData] = useState([-1]);
  const [searchRes, setSearchRes] = useState([-1]);
  const [ttileRes, setTitleRes] = useState([-1]);
  //
  const [serachParams] = useSearchParams();
  const searchQuery = serachParams.get("search");
  const page = serachParams.get("page");
  const rows = serachParams.get('rows');
  //
  const [search, setSearch] = useState(searchQuery ? searchQuery : '');
  const [title, setTitle] = useState('');
  const [cat, setCat] = useState('');
  const [post, setPost] = useState('');
  const [group, setGroup] = useState('');
  const [code, setCode] = useState('')
  // const [offset, setOffset] = useState(0);
  // const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(prev => {
    if (page && rows === 20) {
      return (page === 1 ? 0 : (page - 1) * 20)
    }
    else if (page && rows === 30) {
      return (page === 1 ? 0 : (page - 1) * 30)
    }
    else if (page) {
      return (page === 1 ? 0 : (page - 1) * 10)
    }
    else return 0
  });
  const [limit, setLimit] = useState(rows ? rows : 10);


  useEffect(() => {
    if (!token) return
    const fetchData = async () => {
      try {
        let res;
        if (search) {
          res = await API.get(`/api/v1/goal/objective/?offset=${offset}&limit=${limit}
            &search=${search}`, { headers: { Authorization: token } });
          setSearchRes(res.data);
          console.log(res.data);
        }
        else {
          res = await API.get(`/api/v1/goal/objective/?offset=${offset}&limit=${limit}`, { headers: { Authorization: token } });
          setSearchRes([-1])
          setData(res.data);
          console.log(res.data);
        }
      }
      catch (err) {
        console.log('error message:', err.message);
        if (err.response.status === 404) {
          console.log('my error');
          setData([])
        }
      }
    }
    fetchData();
  }, [limit, offset, search, token])

  useEffect(() => {
    if (!token) return
    const fetchData = async () => {
      let res;
      try {
        if (title?.length > 2 || post?.length > 2 || cat?.length > 2) {
          if (search) {
            res = await API.get(`/api/v1/goal/objective/?title=${title}&goalRelated__title=${post}
              &goalRelated__prespectiveRelated__title=${cat}&offset=${offset}&limit=${limit}&search=${search}`, { headers: { Authorization: token } });
            console.log(res.data);
          }
          else {
            res = await API.get(`/api/v1/goal/objective/?title=${title}&goalRelated__title=${post}
              &goalRelated__prespectiveRelated__title=${cat}&offset=${offset}&limit=${limit}`, { headers: { Authorization: token } });
            console.log(res.data);
          }
          setTitleRes(res.data)
        }
        else {
          setTitleRes([-1])
        }
      }
      catch (err) {
        if (err.response.status === 404) {
          console.log(err.message);
          setData([])
        }
      }
    }
    const time = setTimeout(() => {
      fetchData();
    }, 1000)

    return () => {
      clearTimeout(time)
    }



  }, [title, post, cat, search, offset, limit])
  return (
    <div className="flex flex-col gap-0">
      <GoalsNavbar buttonActive="list" />
      {
        (!search && data[0] === -1) ? (<Loading />) : (data?.length < 1) ? (<p className="text-center py-20 text-xs 2xl:text-sm font-bold text-caspian-gray4">داده وجود ندارد.</p>) :
          (<ListGoalsTable
            // data={(searchRes[0] === -1 && ttileRes[0] === -1) ? data : (ttileRes[0] !== -1) ? ttileRes : searchRes[0] !== -1 ? searchRes : data}
            data={(ttileRes[0] !== -1) ? ttileRes : search ? searchRes : data}
            group={group}
            cat={cat}
            setCat={setCat}
            title={title}
            post={post}
            setTitle={setTitle}
            setPost={setPost}
            setGroup={setGroup}
            search={search}
            setSearch={setSearch}
            offset={offset}
            setOffset={setOffset}
            setLimit={setLimit}
            limit={limit}
            setCode={setCode}
          />)
      }
    </div>
  );
};

export default MainListGoals;
