import { useEffect } from "react";
import { useAppContext } from "../context/app-context/AppContext";

const ManagmentOfMeetings = ({ showMenu }) => {
  const { setBradCrump1, setBradCrump2 } = useAppContext();
  useEffect(() => {
    setBradCrump1("مدیریت جلسات");
    setBradCrump2("");
  }, [setBradCrump1, setBradCrump2]);
  return (
    <div
      className={
        showMenu
          ? `pl-[50px] mr-auto w-full md:w-[75%] lg:w-[80%] xl:w-[85%] 2xl:w-[85%] bg-caspian-gray3 p-1 overflow-y-scroll`
          : `pl-[50px] mr-[54px] w-full bg-caspian-gray3 p-1 overflow-y-scroll`
      }
    >
      مدیریت جلسات
    </div>
  );
};

export default ManagmentOfMeetings;
