import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppContext } from "../../../context/app-context/AppContext";
import API from "../../../api/API";
import { useContext } from "react";
import { AuthContext } from "../../../context/auth/AuthContext";

const SingleTableReceviedRequest = ({
  id,
  sender,
  jobSender,
  title,
  description,
  date,
  unread,
  activityId,
}) => {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const { setBradCrump3 } = useAppContext();
  const [searchParams] = useSearchParams();

  const readHandler = async () => {
    API.patch(
      `/api/v1/request/activity/${activityId}/`,
      { status: "read" },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err));
  };

  return (
    <tr
      onClick={() => {
        unread === "unRead" && readHandler();
        setBradCrump3(id);
        navigate(`${id}`, {
          state: { search: `/requests/received?${searchParams.toString()}` },
        });
      }}
      className={`${
        unread === "unRead" ? "bg-caspian-gray3/10" : "bg-caspian-surface"
      } cursor-pointer hover:bg-caspian-gray3 duration-300 w-full`}
    >
      <td
        colSpan={1}
        className="text-center border-b border-b-caspian-gray2 p-2 w-[35%]"
      >
        <div className=" flex justify-center items-center gap-1">
          <h2 className="text-sm text-center">{sender}</h2>
          <span className="text-xs text-center text-caspian-gray">{jobSender}</span>
        </div>
      </td>
      <td
        colSpan={1}
        className="text-center border-b border-b-caspian-gray2 p-2 w-[50%]"
      >
        <div className=" flex justify-center items-center gap-1">
          <h2
            className={`${
              unread === "unRead" ? "font-bold" : ""
            } text-sm whitespace-nowrap text-center`}
          >
            {title}
          </h2>
          -
          <p
            className={`${
              unread === "unRead"
                ? "text-caspian-on_primary/80"
                : "text-caspian-gray"
            } text-xs text-center line-clamp-1`}
          >
            {description}
          </p>
        </div>
      </td>
      <td
        colSpan={1}
        className="text-center border-b border-b-caspian-gray2 p-2 w-[15%]"
      >
        <h2 className="text-xs text-center text-[#666666]">
          {new Date(date).toLocaleDateString("fa-IR", {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </h2>
      </td>
    </tr>
  );
};

export default SingleTableReceviedRequest;
