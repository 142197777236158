import React, { useState } from "react";
import { PiCaretLeft } from "react-icons/pi";

const SingleProcessActivities = ({ processTitle, activitiesList }) => {
  const [show, setShow] = useState(false);
  return (
    <div className="border border-caspian-gray2 rounded-md p-4 flex flex-col gap-4">
      <div className="flex items-center justify-between gap-2">
        <span className="text-sm text-caspian-secondary-500">
          {processTitle}
        </span>
        <div className="h-[1px] bg-caspian-gray2 w-[60%]"></div>
        <button
          onClick={() => setShow(!show)}
          className="flex items-center gap-2 text-caspian-secondary-700"
        >
          <span className="text-xs 2xl:text-base">فعالیت ها</span>
          <PiCaretLeft
            className={`2xl:text-lg ${
              show ? "-rotate-90 duration-500" : "duration-500"
            }`}
          />
        </button>
      </div>
      {show && (
        <div className="flex flex-col gap-0">
          {activitiesList?.map((a, i) => (
            <div
              onClick={() => console.log(a)}
              key={i}
              className="w-full odd:bg-caspian-gray3 p-1"
            >
              <span className="text-xs">{a?.title}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SingleProcessActivities;
