import React, { useContext, useEffect, useState } from 'react'
import { PiPlus } from 'react-icons/pi'
import SingleListUploadFileCost from './SingleListUploadFileCost'
import ModalUploadFileCost from './ModalUploadFileCost'
import { AuthContext } from '../../../../../../../context/auth/AuthContext'
import API from '../../../../../../../api/API'


const ListUploadFileCost = ({ reportId, setListDocCost, setAddRecord, listDocCost, setIdRecord, idRecord, checked, setCostListObject, costListObject, refreshList, setRefreshList }) => {

    const { token } = useContext(AuthContext);
    const [showModalUploadFile, setShowModalUploadFile] = useState(false)

    useEffect(() => {
        setRefreshList(1)
    }, [setRefreshList])

    useEffect(() => {
        if (!token || !reportId || refreshList !== 1) return;

        const handleRecord = async () => {
            try {
                const res = await API.get(`/api/v1/actionplan/reports/recordCost/${reportId}`,
                    { headers: { Authorization: token } })

                console.log(res?.data)
                setListDocCost(res?.data)
                setRefreshList(0)

            } catch (error) {
                console.log(error)
            }
        }
        handleRecord()
    }, [token, reportId, setListDocCost, setRefreshList, refreshList])


    return (
        <div className='w-full flex flex-col items-start gap-2  pt-3 '>
            <div className='flex items-center gap-2'
                onClick={() => checked && setShowModalUploadFile(true)}>

                <span className={`text-xs 2xl:text-sm ${checked ? "cursor-pointer text-caspian-secondary-600" : " text-caspian-secondary-600/30"} `}>افزدون فاکتور</span>
                <PiPlus className={`text-base ${checked ? "cursor-pointer text-caspian-secondary-600" : "text-caspian-secondary-600/30"} `} />
            </div>


            <div className='h-[20vh] px-3 w-full flex flex-col items-center gap-8 overflow-auto'>
                <SingleListUploadFileCost setAddRecord={setAddRecord} listDocCost={listDocCost} setListDocCost={setListDocCost} refreshList={refreshList} setRefreshList={setRefreshList}
                    setCostListObject={setCostListObject} costListObject={costListObject} reportId={reportId} idRecord={idRecord} checked={checked} />
            </div>

            {showModalUploadFile &&
                <ModalUploadFileCost setAddRecord={setAddRecord} setCostListObject={setCostListObject} costListObject={costListObject} reportId={reportId} listDocCost={listDocCost} setIdRecord={setIdRecord} idRecord={idRecord}

                    setShowModalUploadFile={setShowModalUploadFile} setListDocCost={setListDocCost} refreshList={refreshList} setRefreshList={setRefreshList}
                />
            }
        </div>

    )
}

export default ListUploadFileCost