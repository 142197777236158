import { useContext, useEffect, useState } from "react";
import DateTimePicker from "./time-and-place/DateTimePicker";
import { toast } from "react-toastify";
import API from "../../../../../api/API";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import StartTimePicker from "./time-and-place/StartTimePicker";
import EndTimePicker from "./time-and-place/EndTimePicker";
import { PiCaretDownBold, PiMagnifyingGlassBold } from "react-icons/pi";

const TimeAndPlace = ({
  fullData,
  setActiveTab,
  setShowPlanningMeetingForm,
  thisId,
  setRefresh,
  setMeetingListRefresh,
}) => {
  const { token } = useContext(AuthContext);
  const [date, setDate] = useState(fullData[0]?.meetingDate);
  const [startTime, setStartTime] = useState(fullData[0]?.meetingTimeStart);
  const [endTime, setEndTime] = useState(fullData[0]?.meetingTimeEnd);
  const [type, setType] = useState(
    fullData[0] ? fullData[0]?.presentationType : "inperson"
  );
  console.log(fullData);
  const [link, setLink] = useState(fullData[0]?.meetingOnlineLink);
  const [address, setAddress] = useState(fullData[0]?.meetingRoomOther);
  const [showAddress, setShowAddress] = useState(false);

  const [roomId, setRoomId] = useState(null);
  const [showRoom, setShowRoom] = useState(false);
  const [roomData, setRoomData] = useState([-1]);

  useEffect(() => {
    const getRoom = () => {
      API.get("/api/v1/location/Room/", {
        headers: { Authorization: token },
      })
        .then((res) => {
          setRoomData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getRoom();
  }, [token]);

  const [searchByTitle, setSearchByTitle] = useState("");

  let searchTitle = [];
  if (roomData !== -1) {
    searchTitle = searchByTitle
      ? roomData.filter(
          (d) =>
            d?.title !== undefined &&
            d?.title
              ?.toLocaleLowerCase()
              ?.includes(searchByTitle?.toLocaleLowerCase())
        )
      : roomData;
  }

  const dataHandler = async () => {
    // if (
    //   date === "" ||
    //   startTime === "" ||
    //   startTime === undefined ||
    //   endTime === "" ||
    //   endTime === undefined ||
    //   address === "" ||
    //   address === undefined
    // )
    //   return null;

    if (await fullData[0]?.id) {
      await API.patch(
        `/api/v1/meeting/info/dateTime/${fullData[0]?.id}/`,
        {
          meetingDate: date,
          meetingTimeStart: startTime,
          meetingTimeEnd: endTime,
          presentationType: type,
          meetingOnlineLink: type === "Online" ? link : null,
          meetingRoomOther:
            type === "inperson" && showAddress && roomId === null
              ? address
              : null,
          meetingRoomRelated:
            type === "inperson" && roomId !== null
              ? +roomId?.id
              : (type === "inperson" && showAddress) || type === "Online"
              ? null
              : fullData[0]?.meetingRoomRelated?.id,
        },
        {
          headers: { Authorization: token },
        }
      )
        .then((res) => {
          console.log(res.data);
          setRefresh(1);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log({
        meetingDatetimeRelated: +thisId,
        meetingDate: date,
        meetingTimeStart: startTime,
        meetingTimeEnd: endTime,
        presentationType: type,
        meetingOnlineLink: type === "Online" ? link : null,
        meetingRoomOther:
          type === "inperson" && showAddress && roomId === null
            ? address
            : null,
        meetingRoomRelated:
          type === "inperson" && roomId !== null
            ? +roomId?.id
            : (type === "inperson" && showAddress) || type === "Online"
            ? null
            : fullData[0]?.meetingRoomRelated?.id,
      });
      await API.post(
        `/api/v1/meeting/info/dateTime/`,
        {
          meetingDatetimeRelated: +thisId,
          meetingDate: date,
          meetingTimeStart: startTime,
          meetingTimeEnd: endTime,
          presentationType: type,
          meetingOnlineLink: type === "Online" ? link : null,
          meetingRoomOther:
            type === "inperson" && showAddress && roomId === null
              ? address
              : null,
          meetingRoomRelated:
            type === "inperson" && roomId !== null
              ? +roomId?.id
              : (type === "inperson" && showAddress) || type === "Online"
              ? null
              : fullData[0]?.meetingRoomRelated?.id,
        },
        {
          headers: { Authorization: token },
        }
      )
        .then((res) => {
          console.log(res.data);
          setRefresh(1);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const dataHandlerDraft = async () => {
    // e.preventDefault();
    await API.post(
      `/api/v1/meeting/info/detail/${thisId}/status/`,
      {
        status: "date",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await dataHandler();
        await toast.success("زمان و محل جلسه به صورت پیشنویس ثبت شد.", {
          className: "toast-success",
          bodyClassName: "toast-success",
        });
        await setShowPlanningMeetingForm(false);
        await setMeetingListRefresh(1);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataHandlerNext = async () => {
    // e.preventDefault();
    await API.post(
      `/api/v1/meeting/info/detail/${thisId}/status/`,
      {
        status: "agenda",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await dataHandler();
        setActiveTab("meetingOrder");
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataHandlerBack = async () => {
    await setActiveTab("addGuest");
  };

  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (
      date === "" ||
      startTime === "" ||
      startTime === undefined ||
      endTime === "" ||
      endTime === undefined
    ) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [date, startTime, endTime, address]);

  useEffect(() => {
    if (type === "Online") {
      setShowAddress(false);
      setRoomId(null);
      setAddress(null);
    } else if (type === "inperson" && showAddress) {
      setLink(null);
    } else if (type === "inperson" && !showAddress) {
      setLink(null);
      setShowAddress(false);
    }
  }, [type, showAddress]);

  useEffect(() => {
    fullData[0]?.meetingRoomOther
      ? setShowAddress(true)
      : setShowAddress(false);
  }, [fullData]);
  return (
    <div className="min-h-[270px] flex flex-col justify-between">
      <div className="flex flex-col gap-3">
        <div className="flex items-center justify-between gap-4">
          <div className="w-full flex flex-col gap-1.5">
            <label className="text-xs text-caspian-gray">تاریخ جلسه</label>
            <DateTimePicker
              setDate={setDate}
              oldTime={new Date(fullData[0]?.meetingDate)?.toLocaleDateString(
                "fa-IR"
              )}
            />
          </div>
          <div className="w-full flex flex-col gap-1.5">
            <label className="text-xs text-caspian-gray">ساعت شروع جلسه</label>
            <StartTimePicker
              setStartTime={setStartTime}
              oldStartTime={fullData[0]?.meetingTimeStart}
            />
          </div>
          <div className="w-full flex flex-col gap-1.5">
            <label className="text-xs text-caspian-gray">ساعت پایان جلسه</label>
            <EndTimePicker
              setEndTime={setEndTime}
              oldEndTime={fullData[0]?.meetingTimeEnd}
            />
          </div>
        </div>

        <div className="flex items-center justify-between gap-4">
          <div className="flex flex-col gap-1.5 w-1/2">
            <label className="text-xs text-caspian-gray">
              نوع برگزاری جلسه
            </label>
            <select
              name="presentationType"
              onChange={(e) => setType(e.target.value)}
              defaultValue={fullData[0]?.presentationType}
              className="text-xs outline outline-1 outline-caspian-gray1 p-1.5 rounded-md bg-caspian-transparent cursor-pointer"
            >
              <option value={"inperson"}>حضوری</option>
              <option value={"Online"}>آنلاین</option>
            </select>
          </div>
          {type === "inperson" && (
            <div className="flex flex-col gap-1.5 w-1/2 relative">
              <label className="text-xs text-caspian-gray">
                لیست اتاق جلسات
              </label>
              <div
                onClick={() => {
                  setShowRoom(!showRoom);
                }}
                className="text-xs outline outline-1 outline-caspian-gray1 p-[8px] rounded-md bg-caspian-transparent cursor-pointer flex items-center justify-between z-50"
              >
                {fullData[0]?.meetingRoomRelated !== null && roomId === null ? (
                  fullData[0]?.meetingRoomRelated?.title
                ) : roomId !== null && !showAddress ? (
                  <span className="text-xs">{roomId.title}</span>
                ) : showAddress ? (
                  <span className="text-xs">سایر</span>
                ) : (
                  <span className="text-caspian-gray1 text-xs">
                    انتخاب کنید
                  </span>
                )}
                <PiCaretDownBold className="text-caspian-gray" />
              </div>
              {showRoom && (
                <div className="rounded-md shadow-[0_0_5px_#00000050] w-full flex flex-col gap-3 py-3 absolute left-0 right-0 bottom-[30px] bg-caspian-surface z-50">
                  {roomData.length > 0 && (
                    <div className="relative w-[92%] mx-auto">
                      <input
                        type="search"
                        onChange={(e) => setSearchByTitle(e.target.value)}
                        className="outline-none outline-caspian-gray1 p-1 text-xs rounded-md outline-1 w-full placeholder:text-xs placeholder:text-caspian-gray pr-6"
                        placeholder="جستجو"
                      />
                      <button className="absolute top-1/2 -translate-y-1/2 right-1">
                        <PiMagnifyingGlassBold className="text-caspian-gray text-" />
                      </button>
                    </div>
                  )}
                  <div className="flex flex-col gap-2 w-full max-h-[160px] overflow-y-scroll">
                    {roomData.length > 0 &&
                      searchTitle !== -1 &&
                      searchTitle?.map((d, i) => (
                        <span
                          key={i}
                          onClick={() => {
                            setShowRoom(false);
                            setShowAddress(false);
                            setRoomId({
                              id: d.id,
                              title: d.title,
                            });
                          }}
                          className="text-xs hover:bg-caspian-secondary-100 py-1 px-4 w-full cursor-pointer duration-300"
                        >
                          {d.title}
                        </span>
                      ))}
                    <span
                      onClick={() => {
                        setShowRoom(false);
                        setShowAddress(true);
                      }}
                      className="text-xs hover:bg-caspian-secondary-100 py-1 px-4 w-full cursor-pointer duration-300"
                    >
                      سایر
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {type === "Online" && (
          <div className="w-full flex flex-col gap-1.5">
            <label className="text-xs text-caspian-gray">لینک جلسه</label>
            <input
              onChange={(e) => setLink(e.target.value)}
              defaultValue={link ? link : fullData[0]?.meetingOnlineLink}
              name="addressMeeting"
              type="text"
              placeholder="لینک جلسه را وارد کنید"
              className="outline-none outline-caspian-gray1 p-1.5 text-xs rounded-md outline-1"
            />
          </div>
        )}
        {showAddress && (
          <div className="w-full flex flex-col gap-1.5">
            <label className="text-xs text-caspian-gray">آدرس جلسه</label>
            <input
              onChange={(e) => setAddress(e.target.value)}
              defaultValue={address ? address : fullData[0]?.meetingRoomOther}
              name="addressMeeting"
              type="text"
              placeholder="آدرس جلسه را وارد کنید"
              className="outline-none outline-caspian-gray1 p-1.5 text-xs rounded-md outline-1"
            />
          </div>
        )}
      </div>
      <div className="flex items-center gap-2">
        <button
          onClick={async () => {
            await dataHandlerBack();
          }}
          className="min-w-[6rem] outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs p-1.5 rounded-md"
        >
          مرحله قبل
        </button>
        <button
          disabled={disableButton}
          onClick={async () => {
            await dataHandlerNext();
          }}
          className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
        >
          مرحله بعد
        </button>
        <button
          disabled={disableButton}
          onClick={async () => {
            await dataHandlerDraft();
          }}
          className={`disabled:cursor-not-allowed disabled:text-caspian-gray text-caspian-secondary-500 text-xs p-1.5 rounded-md`}
        >
          ذخیره پیشنویس
        </button>
      </div>{" "}
    </div>
  );
};

export default TimeAndPlace;
