import React from "react";
import RiskNavbar from "../RiskNavbar";

const MainRisks = () => {
  return (
    <div>
      <RiskNavbar buttonActive="risks" />
    </div>
  );
};

export default MainRisks;
