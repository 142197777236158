import React, { useEffect, useState } from "react";
import GoalsNavbar from "../GoalsNavbar";
import { useNavigate, useParams } from "react-router-dom";
import Profile from "./Profile";
import API from "../../../api/API";
import TabsProfile from "./tab-profile/TabsProfile";
import Loading from "../../../Loading";
import { PiCaretLeftLight } from "react-icons/pi";

const MainProfileGoals = () => {
  const [dataProfile, setDataProfile] = useState([-1])
  const params = useParams()
  const navigate = useNavigate();
  useEffect(() => {
    const handleData = async () => {
      try {
        const res = await API.get(`/api/v1/goal/objective/${params?.id}`)
        console.log(res.data)
        setDataProfile(res.data)
      } catch (error) {
        if (error.response.status === 404)
          console.log(error)
        setDataProfile([])
      }
    }
    handleData()
  }, [])

  return (
    dataProfile[0] === -1 ? <Loading /> : dataProfile.length < 1 ? <span>پروفایل مورد نظر یافت نشد</span> :

      <div className="flex flex-col gap-0">

        <GoalsNavbar buttonActive="profile" />
        <div className='flex flex-col items-end gap-3 p-5'>
          <span className="text-caspian-secondary-600 flex gap-3 items-center cursor-pointer"
            onClick={() => navigate(-1)}>
            بازگشت
            <PiCaretLeftLight />
          </span>
          <div className="flex flex-col gap-5 w-full">
            {dataProfile && (
              <div>
                <Profile data={dataProfile} />
              </div>
            )}
            <TabsProfile data={dataProfile} />
          </div>
        </div>
      </div>
  );
};

export default MainProfileGoals;
