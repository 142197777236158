import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import SingleRowList from './SingleRowList';
import Paginate from './Paginate'
import RowView from './RowView'
import Filter from './Filter';
import Loading from '../../../../../Loading';
import { PiCircleNotch } from 'react-icons/pi';
import PaginateApi from '../../../../ui/PaginateApi';
import RowViewApi from '../../../../ui/RowViewApi';

const TableListQuestion = ({ data, refresh, setRefresh, search,
    // offset, setOffset, limit, setLimit, 
    filterBysec, setFilterBySec, filterByProcess, setFilterByProcess,
    currentPage, setCurrentPage,
    filterByQues, setFilterByQues, filterByCri, setFilterByCri, filterByActive, setFilterByActive
}) => {
    const [serachParams, setSearchParams] = useSearchParams();
    const [filterBycode, setFilterByCode] = useState('');
    const [filterByStandard, setFilterByStandard] = useState('');
    const [filterByClause, setfilterByClause] = useState('');
    // const [filterByQues, setFilterByQues] = useState('');
    // const [filterByCri, setFilterByCri] = useState('');
    const [filterByPdca, setFilterByPdca] = useState([]);
    // const [filterBysec, setFilterBySec] = useState('');
    // const [filterByProcess, setFilterByProcess] = useState('');

    const [showMenu, setShowMenu] = useState(false);

    const [searchParams] = useSearchParams();
    const page = searchParams.get('page');
    const rows = searchParams.get('rows');

    let newData = [-1];
    let filterCode = [-1];
    let filterStandard = [-1];
    let filterClause = [-1];
    let filterQues = [-1];
    let filterCri = [-1];
    let filterPdca = [];
    let filterSec = [];
    let filterProcess = [];
    let filterActivity = [];
    console.log('secc', filterBysec);
    if (data[0] !== -1) {
        newData = search ? (data?.filter(d => (d?.title?.toString()?.toLocaleLowerCase() + ' ' + d?.criteriaon?.toString()?.toLocaleLowerCase()).includes(search?.toString()?.toLocaleLowerCase()))) : data

        filterSec = filterBysec ? (newData?.filter(d => d?.questionSectionRelated[0]?.sectionRelatedQuestion?.find(s => s.id === filterBysec))) : newData;

        filterProcess = filterByProcess ? (filterSec?.filter(d => d?.questionRelatedQuestionProcess?.find(q =>
            q?.processProfileRelated?.profileProcessRelated === filterByProcess))) : filterSec;

        filterActivity = filterByActive?.length > 0 ? filterProcess?.filter(d => d?.questionRelatedQuestionProcess?.find(
            q => q?.processActivityRelated?.find(a => filterByActive?.some(f => f === a?.id))
        )) : filterProcess

        filterCode = filterBycode ? (filterActivity?.filter(d => d?.code?.toString()?.toLocaleLowerCase().includes(filterBycode?.toString()?.toLocaleLowerCase()))) : filterActivity;

        filterStandard = filterByStandard ? (filterCode?.filter(d => d?.questionClauseRelatedQuestion?.find(i=>i?.standardRelated?.standardNumber?.toString().toLocaleLowerCase()?.includes(filterByStandard?.toString()?.toLocaleLowerCase())))) : filterCode;

        filterClause = filterByClause ? (filterStandard?.filter(d => d?.questionClauseRelatedQuestion?.find(q=>
            q?.clauseRelated?.find(i=>i?.clauseNumber?.toString().toLowerCase()?.includes(filterByClause?.toString()?.toLocaleLowerCase()))
        ))) : filterStandard;

        filterQues = filterByQues ? (filterClause?.filter(d => d?.title?.toLocaleLowerCase().includes(filterByQues.toLocaleLowerCase()))) : filterClause;

        filterCri = filterByCri ? (filterQues?.filter(d => d?.criteriaon?.toLocaleLowerCase().includes(filterByCri.toLocaleLowerCase()))) : filterQues;

        filterPdca = filterByPdca?.length > 0 ? (filterCri?.filter(d => filterByPdca?.includes(d.pdcaType))) : filterCri;

        // filterSec = filterBysec ? (filterPdca?.filter(d => d?.questionSectionRelated.filter(q=>q?.sectionRelatedQuestion?.some(s=>s.id==filterBysec)))) : filterPdca;

        // filterProcess = filterSec;
        // filterByProcess ? (filterSec?.filter(d => d?.processActivityRelatedQuestion?.toString()?.toLocaleLowerCase().includes(filterByProcess?.toString()?.toLocaleLowerCase()))) : filterSec;
    }

    // const [currentPage, setCurrentPage] = useState(page ? page : 1);
    const [limit, setLimit] = useState(rows ? rows : 10);
    // console.log(currentPage);
    const lastIndex = currentPage * limit;
    const firstIndex = lastIndex - limit;
    const records = filterPdca?.slice(firstIndex, lastIndex);
    const npage = Math.ceil(filterPdca?.length / limit);

    // const lastIndex = currentPage * limit;
    // const firstIndex = lastIndex - limit;
    // const records=data?.results?.slice(firstIndex,lastIndex);
    // const npage = Math.ceil(data?.count / limit);

    function handleSearch(key, value) {
        setSearchParams((prevParams) => {
            if (value === null || value === "") {
                prevParams.delete(key);
            } else {
                prevParams.set(key, value);
            }
            return prevParams;
        });
    }

    useEffect(() => {
        if (currentPage != 1 && records?.length < 1) {
            setCurrentPage(currentPage - 1);
            handleSearch('page', currentPage - 1);
        }
    }, [currentPage, records]);
    return (
        <div className='flex flex-col gap-4'>
            <div className={`${refresh ? 'h-[250px]' : 'min-h-[240px]'} overflow-x-auto`}>
                <table className='min-w-[1200px] w-full text-xs xl:text-sm bg-caspian-surface rounded-tr-xl
                rounded-tl-xl'>
                    <thead>
                        <tr>
                            {/* <td className='bg-caspian-primary-500 text-caspian-surface font-light text-center p-2 w-[5%] rounded-tr-xl cursor-none'>
                                ردیف
                            </td> */}
                            <td className='bg-caspian-primary-500 text-caspian-surface font-light p-2 w-[10%] text-center rounded-tr-xl relative'>
                                <input type="text"
                                    id="t1"
                                    onChange={(e) => setFilterByCode(e.target.value)}
                                    className={`${filterBycode ? 'outline-caspian-surface/50 border' : ''}
                                    peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-6 pl-0 border border-caspian-gray2`}
                                />
                                <label htmlFor="t1"
                                    className={`${filterBycode ? "right-3 left-full" : ""
                                        } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 bg-caspian-primary-500 rounded-md border border-caspian-primary-500 whitespace-nowrap cursor-pointer font-light
                            peer-focus:left-full peer-focus:right-3 z-50}`}
                                >
                                    کد
                                </label>
                            </td>
                            <td className='bg-caspian-primary-500 text-caspian-surface font-light p-2 w-[10%] relative'>
                                <input type="text"
                                    id="t2"
                                    onChange={(e) => setFilterByStandard(e.target.value)}
                                    className={`${filterByStandard ? 'outline-caspian-surface/50 border' : ''}
                                    peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-[60px] pl-0 border border-caspian-gray2`}
                                />
                                <label htmlFor="t2"
                                    className={`${filterByStandard ? "right-3 left-full" : ""
                                        } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 bg-caspian-primary-500 rounded-md border border-caspian-primary-500 whitespace-nowrap cursor-pointer font-light
                            peer-focus:left-full peer-focus:right-3 z-50}`}
                                >
                                    استاندارد
                                </label>
                            </td>
                            <td className='bg-caspian-primary-500 text-caspian-surface font-light p-2 w-[10%] relative'>
                                <input type="text"
                                    id="t3"
                                    onChange={(e) => setfilterByClause(e.target.value)}
                                    className={`${filterByClause ? 'outline-caspian-surface/50 border' : ''}
                                    peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-7 pl-0 border border-caspian-gray2`}
                                />
                                <label htmlFor="t3"
                                    className={`${filterByClause ? "right-3 left-full" : ""
                                        } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 bg-caspian-primary-500 rounded-md border border-caspian-primary-500 whitespace-nowrap cursor-pointer font-light
                            peer-focus:left-full peer-focus:right-3 z-50}`}
                                >
                                    بند
                                </label>
                            </td>
                            <td className='bg-caspian-primary-500 text-caspian-surface font-light p-2 w-[20%] relative'>
                                <input type="text"
                                    id="t4"
                                    onChange={(e) => setFilterByQues(e.target.value)}
                                    className={`${filterByQues ? 'outline-caspian-surface/50 border' : ''}
                                    peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-11 pl-0 border border-caspian-gray2`}
                                />
                                <label htmlFor="t4"
                                    className={`${filterByQues ? "right-3 left-full" : ""
                                        } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 bg-caspian-primary-500 rounded-md border border-caspian-primary-500 whitespace-nowrap cursor-pointer font-light
                            peer-focus:left-full peer-focus:right-3 z-50}`}
                                >
                                    سوال
                                </label>
                            </td>
                            <td className='bg-caspian-primary-500 text-caspian-surface font-light p-2 w-[20%] relative'>
                                <input type="text"
                                    id="t5"
                                    onChange={(e) => setFilterByCri(e.target.value)}
                                    className={`${filterByCri ? 'outline-caspian-surface/50 border' : ''}
                                    peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-11 pl-0 border border-caspian-gray2`}
                                />
                                <label htmlFor="t5"
                                    className={`${filterByCri ? "right-3 left-full" : ""
                                        } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 bg-caspian-primary-500 rounded-md border border-caspian-primary-500 whitespace-nowrap cursor-pointer font-light
                            peer-focus:left-full peer-focus:right-3 z-50}`}
                                >
                                    معیار
                                </label>
                            </td>
                            <td className='bg-caspian-primary-500 text-caspian-surface font-light p-2 w-[5%] text-center relative'>
                                <input type="text"
                                    id="t6"
                                    // onChange={(e) => setFilterByPdca(e.target.value)}
                                    disabled="disabled"
                                    onMouseOver={() => setShowMenu(true)}
                                    onMouseLeave={() => setShowMenu(false)}
                                    // className={`${filterByPdca ? 'outline-caspian-surface/50 border' : ''}
                                    // peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-11 pl-0 border border-caspian-gray2`}
                                    className='placeholder:text-right placeholder:text-caspian-surface outline-caspian-surface/50
                                border border-caspian-gray2 bg-caspian-primary-500 cursor-default rounded-md p-1.5'
                                />
                                {
                                    showMenu && filterByPdca?.length > 0 && <div className='rounded-md overflow-hidden bg-caspian-darkgray-900
                                    absolute right-4 left-4'>
                                        {
                                            filterByPdca?.map((item, index) => <p key={index} className=''>{item}</p>)
                                        }
                                    </div>
                                }
                                <Filter
                                    filterByPdca={filterByPdca}
                                    setFilterByPdca={setFilterByPdca}
                                    // data={data.map(d => d?.pdcaType)}
                                    data={['p', 'd', 'c', 'a']}
                                />
                                <label onMouseOver={() => setShowMenu(true)}
                                    onMouseLeave={() => setShowMenu(false)}
                                    htmlFor="t6"
                                    className='absolute top-1/2 right-6 -translate-y-1/2 text-caspian-surface'>
                                    {
                                        filterByPdca?.length > 0 ? filterByPdca?.length + ' مورد انتخاب شد ' : 'PDCA'
                                    }
                                </label>
                                {/* <label htmlFor="t6"
                                    className={`${filterByPdca ? "right-3 left-full" : ""
                                        } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 bg-caspian-primary-500 rounded-md border border-caspian-primary-500 whitespace-nowrap cursor-pointer font-light
                            peer-focus:left-full peer-focus:right-3 z-50}`}
                                >
                                    PDCA
                                </label> */}
                            </td>
                            {/* <td className='bg-caspian-primary-500 text-caspian-surface font-light p-2 w-[10%] relative'>
                                <input type="text"
                                    id="t7"
                                    onChange={(e) => setFilterBySec(e.target.value)}
                                    className={`${filterBysec ? 'outline-caspian-surface/50 border' : ''}
                                    peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-10 pl-0 border border-caspian-gray2`}
                                />
                                <label htmlFor="t7"
                                    className={`${filterBysec ? "right-3 left-full" : ""
                                        } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 bg-caspian-primary-500 rounded-md border border-caspian-primary-500 whitespace-nowrap cursor-pointer font-light
                            peer-focus:left-full peer-focus:right-3 z-50}`}
                                >
                                    واحد
                                </label>
                            </td> */}
                            <td className='bg-caspian-primary-500 rounded-tl-xl text-caspian-surface font-light p-2 w-[2%] relative'>
                                {/* <input type="text"
                                    id="t8"
                                    onChange={(e) => setFilterByProcess(e.target.value)}
                                    className={`${filterByProcess ? 'outline-caspian-surface/50 border' : ''}
                                    peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-10 pl-0 border border-caspian-gray2`}
                                />
                                <label htmlFor="t8"
                                    className={`${filterByProcess ? "right-3 left-full" : ""
                                        } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 bg-caspian-primary-500 rounded-md border border-caspian-primary-500 whitespace-nowrap cursor-pointer font-light
                            peer-focus:left-full peer-focus:right-3 z-50}`}
                                >
                                    فرایند
                                </label> */}
                            </td>
                            {/* <td className='bg-caspian-primary-500 p-2 rounded-tl-xl'></td> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            refresh === true ? <tr><td colSpan={10} className='pt-20 bg-caspian-gray3'>
                                <PiCircleNotch className='animate-spin text-3xl mx-auto text-caspian-primary-400' /></td></tr> :
                                records?.length > 0 ? (
                                    records?.map((item, index) => <SingleRowList key={item.id} index={index + 1} {...item}
                                        setRefresh={setRefresh}
                                        refresh={refresh}
                                    />)
                                ) : (
                                    <tr><td colSpan={8} className='bg-caspian-gray3'>
                                        <div className='py-5 flex flex-col gap-2 items-center justify-center'>
                                            <div className='w-[220px] h-[220px] flex justify-center'>
                                                <img src="/assets/images/library/not-found.svg" alt="موردی یافت نشد..." />
                                            </div>
                                            <h2 className='text-center text-sm'> موردی یافت نشد...</h2>
                                        </div>
                                    </td></tr>
                                )
                        }
                    </tbody>
                </table>
            </div>
            {records?.length > 0 &&
                (<div className='flex justify-between items-center'>
                    <RowView
                        setLimit={setLimit}
                        setCurrentPage={setCurrentPage}
                    />

                    <Paginate
                        pageCount={npage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                    <div className="text-sm flex items-center gap-4">
                        <span>نمایش {firstIndex + 1}</span>
                        <span>تا {lastIndex - (limit - records?.length)}</span>
                        <span>از کل {filterPdca?.length}</span>
                    </div>
                </div>)

            }
            {/* {
                data?.results?.length > 0 && (<div className="flex items-center justify-between">
                    <RowViewApi
                        limit={limit}
                        setOffset={setOffset}
                        setLimit={setLimit}
                        setCurrentPage={setCurrentPage}
                    />
                    <PaginateApi
                        pageCount={npage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        setOffset={setOffset}
                        offset={offset}
                    />
                    <div className='flex gap-3 text-xs 2xl:text-sm'>
                        <span>نمایش {firstIndex + 1}</span>
                        <span>تا {lastIndex - (limit - data?.results?.length)}</span>
                        <span>از کل {data?.count}</span>
                    </div>
                </div>)
            } */}
        </div>
    );
}

export default TableListQuestion;
