import React from 'react'
import Modal from '../../../../../../modal/Modal'
import { PiX } from 'react-icons/pi'

const DetailsDocMaterial = ({ setShowModalDetail, selectItemIndex, listDocMaterial }) => {

    console.log(listDocMaterial)

    return (
        <Modal>
            {listDocMaterial?.map((item, index) => (

                selectItemIndex === index &&

                <div key={index} className='w-[350px] md:w-[450px] flex gap-10 rounded-lg shadow-[0_0_5px_#00000050] items-center flex-col bg-caspian-surface p-7' >


                    <div className='flex items-center justify-between w-full'>
                        <span className='text-xs 2xl:text-base'>جزئیات فاکتور</span>

                        <PiX onClick={() => setShowModalDetail(false)}
                            className='text-base cursor-pointer text-caspian-gray4 2xl:text-2xl' />
                    </div>

                    <div className='flex flex-col items-center w-full gap-3'>

                        <div className='flex items-center justify-between w-full pb-2 border-b-2 border-b-caspian-gray2/40'>
                            <span className='text-xs 2xl:text-sm text-caspian-gray4'>نوع قلم مصرفی:</span>
                            <span className='text-xs 2xl:text-sm'>{item?.materialRelated?.name}</span>
                        </div>



                        <div className='flex items-center justify-between w-full pb-2 border-b-2 border-b-caspian-gray2/40'>
                            <span className='text-xs 2xl:text-sm text-caspian-gray4'>واحد:</span>
                            <span className='text-xs 2xl:text-sm'>{item?.materialRelated?.unit}</span>
                        </div>

                        <div className='flex items-center justify-between w-full pb-2 '>
                            <span className='text-xs 2xl:text-sm whitespace-nowrap text-caspian-gray4'>میزان مصرفی در این مرحله:</span>
                            <span className='text-xs leading-7 2xl:text-sm'>{item?.used_amount}</span>
                        </div>

                    </div>

                    <div className='flex flex-col w-full items-center gap-3'>

                        <div className='flex items-center gap-3 w-full'>
                            <span className='text-xs 2xl:text-sm whitespace-nowrap text-caspian-gray4'>میزان مصرفی این قلم</span>
                            <div className='w-full h-[2px] bg-caspian-gray2/40'></div>
                        </div>

                        <div className='flex flex-col items-center w-full justify-start '>

                            <div className='flex items-center gap-5 w-full '>
                                <span className='text-xs  whitespace-nowrap text-caspian-gray4'>میزان مصرف این قلم پیش از این مرحله (کیلوگرم): </span>
                                <span className='text-xs leading-7  text-caspian-gray4'>{item?.usageProgress}</span>
                            </div>

                            <div className='flex items-center gap-5 w-full '>
                                <span className='text-xs font-medium whitespace-nowrap'>میزان مصرف این قلم در این مرحله (کیلوگرم):</span>
                                <span className='text-xs font-medium leading-7'>{item?.used_amount}</span>
                            </div>

                            <div className='flex items-center gap-5 w-full '>
                                <span className='text-xs font-medium whitespace-nowrap'>تجمیع میزان این قلم مصرفی (کیلوگرم):</span>
                                <span className='text-xs font-medium leading-7 '>{(item?.used_amount) + (item?.usageProgress)}</span>
                            </div>



                        </div>

                    </div>

                </div>
            ))
            }
        </ Modal >
    )
}

export default DetailsDocMaterial