import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../../context/app-context/AppContext";

const ListItemSingle = ({
  active,
  title,
  icon,
  onClick,
  link,
  buttonActive,
  imageAddress,
  number,
}) => {
  const { setNavbarRequests } = useAppContext();
  useEffect(() => {
    if (active === "req-received") {
      setNavbarRequests("req-received");
    } else if (active === "sent") {
      setNavbarRequests("sent");
    } else if (active === "refer") {
      setNavbarRequests("refer");
    }
  }, [active, setNavbarRequests]);

  return (
    <li>
      <Link
        onClick={onClick}
        to={link}
        className={`${
          buttonActive === active
            ? "border border-caspian-secondary-600 shadow-[0_0_5px_#00000050]"
            : "border border-caspian-gray2 border-b-caspian-gray1"
        } flex items-center gap-2 p-3 pl-14 cursor-pointer rounded-lg bg-caspian-surface`}
      >
        <div
          className={`${
            active === "req-received"
              ? "bg-[#C1F0FF]"
              : active === "sent"
              ? "bg-[#A6F3E5]"
              : "bg-[#FFCC9C]"
          } w-12 h-12 p-2 rounded-full flex items-center justify-center`}
        >
          <img src={imageAddress} alt={title} />
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-sm text-caspian-gray font-normal">{title}</span>
          <span className="text-sm font-medium">
            {active === "req-received"
              ? number === ""
                ? "..."
                : number + " درخواست"
              : active === "sent"
              ? number === ""
                ? "..."
                : number + " ارسال"
              : !number
              ? "..."
              : number + " ارجاع"}
          </span>
        </div>
      </Link>
    </li>
  );
};

export default ListItemSingle;
