import React from "react";
import InfoBox from "./InfoBox";
import { PiMapPin } from "react-icons/pi";

const DetailsLegalExternalContact = ({ heightHandler }) => {
  return (
    <div className="w-full flex flex-col gap-2">
      <InfoBox title="توضیحات" desc={["توضیحات سازمان"]} />
      <InfoBox title="شماره تماس مستقیم" desc={["021-8812578"]} />
      {heightHandler && (
        <div className="w-full flex flex-col gap-2 mb-8">
          <InfoBox title="رابط" desc={["علی حقی"]} />
          <InfoBox title="تلفن رابط" desc={["99565476"]} />
          <InfoBox title="کدپستی" desc={["188954"]} />
          <InfoBox title="کشور" desc={["ایران"]} />
          <InfoBox title="استان" desc={["تهران"]} />
          <InfoBox title="شهر" desc={["تهران"]} />
          <InfoBox title="منطقه" desc={["1"]} />
          <InfoBox
            title="آدرس"
            desc={["تهران بلوار کشاورز 16 آذر پلاک 1 طبقه 9 اتاق 909"]}
          />
          <div className="flex items-start gap-0 odd:py-1 even:bg-caspian-surface odd:bg-caspian-gray3 p-1">
            <div className="basis-1/2 flex flex-col gap-2">
              <span className="text-xs text-caspian-gray4">لوکیشن</span>
            </div>
            <div className="basis-1/2 flex flex-col gap-0">
              <div className="flex items-center gap-2 text-caspian-secondary-500">
                <PiMapPin />
                <span className="text-xs">تهران</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailsLegalExternalContact;
