import React, { useContext, useEffect, useState } from "react";
import ButtonTab from "./ButtonTab";
import MainInprogress from "./Inprogress/MainInprogress";
import MainMyplans from "./myplans/MainMyplans";
import MainReport from "./report/MainReport";
import ActionPlanNavbar from "../../ActionPlanNavbar";
import { AuthContext } from "../../../../context/auth/AuthContext";
import API from "../../../../api/API";
import { useSearchParams } from "react-router-dom";

const MainPmDashboard = () => {
  const { token } = useContext(AuthContext);
  const [fullData, setfullData] = useState([-1]);
  const [serachParams, setSearchParams] = useSearchParams();
  const plan = serachParams.get("plan");
  const [activeTab, setActiveTab] = useState("inprogress");

  useEffect(() => {
    if (token) {
      // let url = "/api/v1/actionplan/actionplanLISTROLE";
      // let url = "/api/v1/actionplan/actionplans?type=manager";
      let url = "/api/v1/actionplan/actionplanROLE";
      const getPmData = async () => {
        API.get(url, {
          headers: { Authorization: token },
        })
          .then((res) => {
            setfullData(res.data);
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      };
      getPmData();
    }
  }, [token]);

  function handleSearch(key, value) {
    setSearchParams((prevParams) => {
      if (value === null || value === "") {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    });
  }

  useEffect(() => {
    if (plan === "inprogress") {
      setActiveTab("inprogress");
    } else if (plan === "myplans") {
      setActiveTab("myplans");
    } else if (plan === "report") {
      setActiveTab("report");
    } else {
      setActiveTab("inprogress");
    }
  }, [plan]);

  return (
    <div className="flex flex-col gap-2">
      <ActionPlanNavbar buttonActive="plans" />
      <div className="w-full flex flex-col gap-4">
        <div className="flex items-center gap-4 py-1.5 px-2 rounded-md">
          <ButtonTab
            activeTab={activeTab}
            active="inprogress"
            onClick={() => {
              setActiveTab("inprogress");
              handleSearch("plan", "inprogress");
              handleSearch("s", "");
              handleSearch("search", "");
            }}
            title="درحال برنامه ریزی"
          />
          <ButtonTab
            activeTab={activeTab}
            active="myplans"
            onClick={() => {
              setActiveTab("myplans");
              handleSearch("plan", "myplans");
              handleSearch("s", "");
              handleSearch("search", "");
            }}
            title="برنامه‌های مصوب"
          />
          {/* myplans === approved */}
          {/* برنامه های مصوب === برنامه های من */}
          <ButtonTab
            activeTab={activeTab}
            active="report"
            onClick={() => {
              setActiveTab("report");
              handleSearch("plan", "report");
              handleSearch("s", "");
              handleSearch("search", "");
            }}
            title="لیست گزارش ها"
          />
        </div>
      </div>
      <div className="p-2">
        {activeTab === "inprogress" ? (
          <MainInprogress
            fullData={fullData?.filter((d) => d?.statusAction !== "StartTasks")}
          />
        ) : activeTab === "myplans" ? (
          <MainMyplans
            fullData={fullData?.filter((d) => d?.statusAction === "StartTasks")}
          />
        ) : (
          <MainReport />
        )}
      </div>
    </div>
  );
};

export default MainPmDashboard;
