import React from 'react'
import { PiTrash } from 'react-icons/pi'

const ShowUploadFile = ({ fileRef, file, setFile, setShowFiles, showFiles }) => {

    const convertSize = (size) => {
        if (size < (1024 * 1024)) {
            return (size / 1024).toFixed(1) + "KB"
        } else if (size < (1024 * 1024 * 1024)) {
            return (size / (1024 * 1024)).toFixed(1) + "MB"
        } else {
            return (size / (1024 * 1024 * 1024)).toFixed(1) + "GB"
        }
    }

    const handleDeleteFile = () => {
        setFile(0)
        setShowFiles(false)
        console.log(file, showFiles)
        fileRef.current.value = ''
    }

    return (
        <div className='w-full flex items-center gap-5'>
            <div className='flex items-center justify-between px-3 py-2 border-b-2 border-b-caspian-gray2/80 rounded-md w-[90%] '>
                <div className='flex items-center gap-5'>
                    <img src="/assets/images/action-plan/Group.svg" className='w-4 2xl:w-5' alt="" />
                    <span className='text-xs'>{file?.name}</span>
                </div>

                <span className='text-xs text-caspian-gray4'>{convertSize(file?.size)}</span>
            </div>
            <PiTrash
                onClick={handleDeleteFile}
                className='text-sm 2xl:text-[22px] cursor-pointer text-caspian-secondary-600' />
        </div>
    )
}

export default ShowUploadFile