import React, { useState } from "react";
import Draggable from "react-draggable";
import { useForm } from "react-hook-form";
import { PiFileTextFill, PiPaperclip, PiPen, PiX } from "react-icons/pi";

const NewMessage = () => {
  const {
    register,
    handleSubmit,
    reset,
    resetField,
    formState: { errors },
    watch,
  } = useForm({});
  const [showNewMessage, setShowNewMessage] = useState(false);
  const [fileData, setFileData] = useState([]);
  let fileNames = [];
  for (let i = 0; i < fileData.length; i++) {
    fileNames.push(fileData[i].name);
  }

  const submitHandler = (e) => {
    // e.preventDefault();
    const formData = {
      to: watch("to"),
      subject: watch("subject"),
      desc: watch("desc"),
      formFile: fileData,
    };
    console.log(formData);
    setFileData([]);
    setShowNewMessage(false);
    resetField("to");
    resetField("subject");
    resetField("desc");
  };

  const clearHandler = () => {
    setShowNewMessage(false);
    reset();
    setFileData([]);
  };
  return (
    <>
      <button
        onClick={() => setShowNewMessage(true)}
        className="bg-caspian-secondary-600 text-caspian-surface rounded-md p-2 flex items-center gap-2"
      >
        <PiPen className="text-xl" />
        <span className="text-sm">نوشتن پیام</span>
      </button>
      {showNewMessage && (
        <Draggable handle="strong">
          <div className="bg-caspian-surface rounded-md shadow-[0_0_5px_#00000050] w-[400px] min-h-[500px] fixed bottom-2 left-16 z-50">
            <strong className="flex items-center justify-between cursor-move p-4">
              <span className="text-sm">پیام جدید</span>
              <PiX onClick={clearHandler} className="text-xl cursor-pointer" />
            </strong>
            <form
              onSubmit={handleSubmit(submitHandler)}
              className="flex flex-col gap-4 px-4 pb-4"
            >
              <div className="relative">
                <input
                  name="to"
                  className="p-2 pr-20 text-sm rounded-md outline outline-1 outline-caspian-gray2 w-full"
                  {...register("to", {
                    required: true,
                  })}
                />
                <label className="absolute top-1/2 -translate-y-1/2 right-2 text-caspian-gray text-sm">
                  ارسال به:
                </label>
                {errors.to && errors.to.type === "required" && (
                  <span className="text-caspian-error text-xs absolute left-2 top-1/2 -translate-y-1/2">
                    ایمیل خود را وارد کنید
                  </span>
                )}
              </div>
              <div className="relative">
                <input
                  name="subject"
                  className="p-2 pr-20 text-sm rounded-md outline outline-1 outline-caspian-gray2 w-full"
                  {...register("subject", {
                    required: true,
                  })}
                />
                <label className="absolute top-1/2 -translate-y-1/2 right-2 text-caspian-gray text-sm">
                  موضوع:
                </label>
                {errors.subject && errors.subject.type === "required" && (
                  <span className="text-caspian-error text-xs absolute left-2 top-1/2 -translate-y-1/2">
                    موضوع پیام را وارد کنید
                  </span>
                )}
              </div>

              <div className="relative">
                <textarea
                  name="desc"
                  placeholder="متن"
                  rows={10}
                  {...register("desc", {
                    required: true,
                  })}
                  className="resize-none p-2 text-sm rounded-md outline outline-1 outline-caspian-gray2 w-full"
                />
                {errors.desc && errors.desc.type === "required" && (
                  <span className="text-caspian-error text-xs absolute left-2 top-2">
                    متن پیام را وارد کنید
                  </span>
                )}
              </div>

              <div className="relative">
                {fileData.length > 0 && (
                  <PiX
                    onClick={(e) => setFileData([])}
                    className="absolute left-2 top-1/2 -translate-y-1/2 cursor-pointer"
                  />
                )}
                <label
                  htmlFor="formFile"
                  className="flex items-center gap-2 cursor-pointer rounded-md outline outline-1 outline-caspian-gray2 px-2 py-6 w-full"
                >
                  <span className="text-sm text-caspian-gray whitespace-nowrap flex items-center gap-1">
                    <PiPaperclip className="text-caspian-gray w-5 h-5" />
                    پیوست فایل
                  </span>
                  <input
                    name="formFile"
                    multiple
                    id="formFile"
                    type="file"
                    className="hidden"
                    onChange={(e) => {
                      setFileData(e.target.files);
                    }}
                  />
                  {fileData.length < 1 || fileData === "" ? (
                    <h2 className="text-xs text-caspian-gray">بدون فایل</h2>
                  ) : (
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center gap-1">
                        <h2 className="text-sm text-caspian-gray">
                          {fileNames.toString().replaceAll(",", `, `)}
                        </h2>
                        <PiFileTextFill className="text-caspian-secondary-500 text-xl" />
                      </div>
                    </div>
                  )}
                </label>
              </div>
              <div className="flex items-center gap-2">
                <button className="outline outline-1 outline-caspian-secondary-500 bg-caspian-secondary-500 text-caspian-surface py-1.5 px-10 rounded-md text-sm">
                  ارسال
                </button>
                <button
                  type="reset"
                  onClick={clearHandler}
                  className="outline outline-1 
                  outline-caspian-error text-caspian-error py-1.5 px-6 rounded-md text-sm"
                >
                  لغو
                </button>
              </div>
            </form>
          </div>
        </Draggable>
      )}
    </>
  );
};

export default NewMessage;
