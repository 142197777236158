import React from 'react'
import { useNavigate } from 'react-router-dom'

const SingleListPopUpGoal = ({ titleObjective }) => {
    
    const navigate = useNavigate();
    return (
        <div className='flex flex-col justify-center'>
            <span onClick={() => navigate('/goals/' + titleObjective?.id)}
                style={{ background: `#${titleObjective?.colorHex}` }}
                className='cursor-pointer text-xs xl:text-sm py-2 text-center z-100 rounded-lg text-caspian-on_primary '>
                {titleObjective?.title}
            </span>
        </div >
    )
}

export default SingleListPopUpGoal;