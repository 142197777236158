
const SingleModel = ({ titleGoal }) => {

    return (
        <div className="flex justify-center items-center flex-col relative gap-3 ">

            <div className="absolute top-7 left-1/2 w-4/5 h-0.5 bg-caspian-darkgray-600 translate-x-1/2" />

            <span 
             style={{ background: `#${titleGoal?.colorHex}` }}
            className='text-center z-20 text-xs xl:text-base text-caspian-surface/95 font-bold py-4 w-[180px] rounded-[50%] '>
                
                {titleGoal.title}
            </span>

            <div className='h-[45px]'>
                <img src="/assets/images/goals/arrow1.svg" className="h-[45px]" alt="arrow" />
            </div>

        </div>
    )
}

export default SingleModel;