import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppContext } from "../../../context/app-context/AppContext";

const SingleTableProcesses = ({
  businessProcessTitle,
  businessProcessCode,
  groupCategoryRelated,
  processGroupRelated,
  vahedMotevali,
}) => {
  const [serachParams] = useSearchParams();
  const navigate = useNavigate();
  const { setBradCrump3 } = useAppContext();
  // console.log(businessProcessTitle);

  const openNewWindow = (url) => {
    const newWindow = window.open(url, "_blank");
    if (newWindow) {
      localStorage.setItem("referrer", window.location.href);
    }
  };
  return (
    <tr
      onClick={() => {
        setBradCrump3(businessProcessTitle);
        openNewWindow(
          `/process-management/list-processes/${businessProcessCode}`
        );
        // window.open(
        //   `/process-management/list-processes/${businessProcessCode}`,
        //   "_blank"
        // );
        // navigate(`/process-management/list-processes/${businessProcessCode}`, {
        //   state: { search: `list-processes?${serachParams.toString()}` },
        // });
      }}
      className="cursor-pointer bg-caspian-surface duration-300"
    >
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[25%]">
        <h2>{businessProcessTitle}</h2>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 font-sans min-w-[100px] w-[10%]">
        <h2>{businessProcessCode}</h2>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[25%]">
        <h2>{groupCategoryRelated}</h2>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[25%]">
        <h2>{processGroupRelated}</h2>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[15%]">
        <h2>{vahedMotevali}</h2>
      </td>
    </tr>
  );
};

export default SingleTableProcesses;
