import React, { useState } from "react";
import ProcessNavbar from "../ProcessNavbar";
import SearchInput from "./SearchInput";
import { PiPlusCircle } from "react-icons/pi";
import TableListProcesses from "./TableListProcesses";
import {
  useParams,
  useSearchParams,
  // useNavigate,
} from "react-router-dom";
import MainSingleListProcess from "./single-list-process/MainSingleListProcess";
import { useAppContext } from "../../../context/app-context/AppContext";
import { useEffect } from "react";
import API from "../../../api/API";

const MainListProcess = ({ showMenu }) => {
  const params = useParams();
  // const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const { setBradCrump1, setBradCrump2, setBradCrump3, setBrcLink2 } =
    useAppContext();
  useEffect(() => {
    setBradCrump1("مدیریت فرایند");
    setBradCrump2("لیست فرایند ها");
    setBrcLink2("/process-management/");
    setBradCrump3("");
  }, [setBradCrump1, setBradCrump2, setBradCrump3, setBrcLink2]);

  const [fullData, setFullData] = useState([-1]);
  useEffect(() => {
    setLoading(true);
    let url = "/api/v1/businessProcess/related/Process/";
    API.get(url)
      .then((d) => {
        setFullData(d.data);
      })
      .catch((err) => {
        console.log("error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div
      className={
        showMenu
          ? `pl-[50px] mr-auto w-full md:w-[75%] lg:w-[80%] xl:w-[85%] 2xl:w-[85%] bg-caspian-gray3 p-1 overflow-y-scroll`
          : `pl-[50px] mr-[54px] w-full bg-caspian-gray3 p-1 overflow-y-scroll`
      }
    >
      <ProcessNavbar buttonActive="list-processes" />
      <div className="flex flex-col gap-2 p-4 py-10 px-8 xl:px-16">
        {params.code ? (
          <div className="flex flex-col gap-0">
            {/* <Link
              to={"/process-management/list-processes"}
              state={{ search: `?${searchParams.toString()}` }}
              onClick={() => {
                // navigate("/process-management/list-processes");
                setBradCrump3("");
              }}
              className="mr-auto flex items-center gap-2 text-caspian-secondary-700 p-2"
            >
              <span className="">بازگشت</span>
              <PiCaretLeft />
            </Link> */}
            <MainSingleListProcess data={fullData} />
          </div>
        ) : (
          <div className="flex flex-col gap-6">
            <div className="flex items-center gap-2">
              <SearchInput />
              {/* دکمه ثبت درخواست */}
              {/* <button className="flex items-center gap-1 rounded-md bg-caspian-secondary-500 text-caspian-surface text-sm p-2">
                <PiPlusCircle className="text-2xl" />
                <span>ثبت درخواست</span>
              </button> */}
            </div>
            <TableListProcesses data={fullData} loading={loading} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MainListProcess;
