import React, { useState } from "react";
import SingleFolderList from "./SingleFolderList";
import AddFolder from "./AddFolder";
import {
  PiBookmarkSimple,
  PiBookmarkSimpleFill,
  PiFolderPlus,
  PiListBullets,
  PiListBulletsFill,
  PiTrash,
  PiTrashFill,
} from "react-icons/pi";
import SingleOtherFolder from "./SingleOtherFolder";
import { useNavigate, useParams } from "react-router-dom";

const Sidebar = ({ fullDataNotebook, setRefresh }) => {
  const [showAddFolder, setShowAddFolder] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  return (
    <div className="relative w-[25%] bg-caspian-surface rounded-md border border-caspian-gray2 min-h-screen max-h-screen p-2 flex flex-col gap-4">
      {showAddFolder && (
        <AddFolder
          setRefresh={setRefresh}
          setShowAddFolder={setShowAddFolder}
        />
      )}
      <div className="flex items-center justify-between">
        <h2 className="text-sm font-bold">پوشه یادداشت‌ها</h2>
      </div>
      <div className="flex items-center justify-between">
        <span className="text-xs font-bold text-caspian-secondary-500">
          پوشه های من
        </span>
        <button
          className="flex items-center gap-2"
          onClick={() => setShowAddFolder(true)}
        >
          <PiFolderPlus className="text-2xl text-caspian-secondary-500" />
        </button>
      </div>
      <div className="flex flex-col gap-4">
        {fullDataNotebook?.length < 1 ? (
          <h2 className="text-xs text-caspian-gray4 text-center">
            لطفا یک پوشه ایجاد کنید!
          </h2>
        ) : (
          fullDataNotebook?.map((d, i) => (
            <SingleFolderList
              folder={+params.notebook}
              folderId={d?.id}
              folderTitle={d?.title}
              key={i}
              title={d?.title}
              counter={d?.notes?.length}
              onClick={() => {
                navigate(`${d?.id}`);
              }}
            />
          ))
        )}
      </div>

      <div className="flex items-center justify-between">
        <span className="text-xs font-bold text-caspian-secondary-500">
          بیشتر
        </span>
      </div>
      <div className="flex flex-col gap-4">
        <SingleOtherFolder
          onClick={() => {
            // setShowList("favorite");
            // handleFolder("folder", "favorite");
            console.log("favorite");
          }}
          icon={<PiBookmarkSimple />}
          // icon={
          //   folder === "favorite" ? (
          //     <PiBookmarkSimpleFill />
          //   ) : (
          //     <PiBookmarkSimple />
          //   )
          // }
          iconClass={"text-2xl text-caspian-secondary-500"}
          title="علاقه مندی ها"
          counter={0}
        />
        <SingleOtherFolder
          onClick={() => {
            // setShowList("check-list");
            // handleFolder("folder", "check-list");
            console.log("cheklist");
          }}
          icon={<PiListBullets />}
          // icon={
          //   folder === "check-list" ? <PiListBulletsFill /> : <PiListBullets />
          // }
          iconClass={"text-2xl text-caspian-secondary-500"}
          title="چک لیست"
          counter={0}
        />
        <SingleOtherFolder
          onClick={() => {
            // setShowList("trash");
            // handleFolder("folder", "trash");
            console.log("trash");
          }}
          icon={<PiTrash />}
          counter={0}
          // icon={folder === "trash" ? <PiTrashFill /> : <PiTrash />}
          // counter={trashCounter}
          iconClass={"text-2xl text-caspian-secondary-500"}
          title="حذف شده‌ها"
        />
      </div>
    </div>
  );
};

export default Sidebar;
