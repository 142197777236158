import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../../../context/auth/AuthContext';
import ListStandard from '../../create-form-question/ListStandard';
import API from '../../../../../../api/API';
import ListClause from '../../create-form-question/ListClause';
import { PiCircleNotch } from 'react-icons/pi';

const StepOne = ({ setActiveStep, setRefresh, setIdPost, standard, setStandard, idPost, q, setQ, setStandard2
    , setStandardSelected2, standardSelected2
    , edit, setEdit
}) => {
    const { token } = useContext(AuthContext);
    const [check, setCheck] = useState(false);
    const [title, setTitle] = useState('');
    const [cri, setCri] = useState('');
    const [pdca, setPdca] = useState('p');
    const [loading, setLoading] = useState(false);


    const [standardList, setStandardList] = useState([]);
    // const [standard, setStandard] = useState("");
    const [standardSelected, setStandardSelected] = useState(null);
    const [showListStandard, setShowListStandard] = useState(false);


    const [clauseList, setClauseList] = useState([]);
    const [clause, setClause] = useState("");
    const [clauseSelected, setClauseSelected] = useState(null);
    const [showListClause, setShowListClause] = useState(false);

    const [showDisable, setShowDisable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [first, setFirst] = useState(0);
    useEffect(() => {
        if (standard) {
            // setFirst(1);
            setStandard2(standard)
            setStandardSelected2(standardSelected)
        }
    }, [standard, setFirst, setStandard2, setStandardSelected2, standardSelected])
    // useEffect(()=>{
    //     if(first){
    //         setStandard2(standard)
    //     }
    //     else return
    // },[first,setStandard2])
    useEffect(() => {
        if (!token) return
        const fetchDataStandard = async () => {
            try {
                const res = await API.get('/api/v1/standard/', { headers: { Authorization: token } });
                setStandardList(res.data);
                console.log(res.data);
                // fetchClause()
                setClauseSelected(null);
                setClause('');
            }
            catch (err) {
                console.log(err.message);
            }
        }
        fetchDataStandard();
    }, [token, standard])

    useEffect(() => {
        if (!standard) return
        const fetchClause = async () => {
            try {
                const res = await API.get(`/api/v1/standard/clause/all/${standard}`);
                setClauseList(res.data);
                console.log(res.data);
            }
            catch (err) {
                console.log(err);
            }
        }
        fetchClause();
    }, [token, standard]);

    //edit
    const handleEditSubmit = async (id) => {
        setIsLoading(true);
        let formData = {
            pdcaType: pdca,
            title: title,
            criteriaon: cri,
        };
        try {
            const res = await API.patch(`/api/v1/audit/question/detial/${id}/`, formData, { headers: { Authorization: token } });
            if (res?.data) {
                console.log(res?.data);
                handleEditStandard(id);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleEditStandard = async (id) => {
        if (!id) return
        let formData = {
            questionClauseRelated: id,
            standardRelated: standard,
            clauseRelated: [clause]
        };
        try {
            const res = await API.patch('/api/v1/audit/question/clause/', formData, { headers: { Authorization: token } })
            console.log(res.data);
            if (res.data) {
                setShowDisable(true)
                setIsLoading(false)
                setQ(1);
                setEdit(false);
            }
            else {
                setShowDisable(false);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setIsLoading(false);
        }
    }

    //submit
    const handleSubmit = async () => {
        if (!title || !pdca || !cri || !standard || !clause) {
            setCheck(true);
            return;
        }
        if (edit) {
            handleEditSubmit(idPost);
        }
        setCheck(false);
        setIsLoading(true);
        let formData = {
            pdcaType: pdca,
            title: title,
            criteriaon: cri,
        };
        try {

            const res = await API.post('/api/v1/audit/question/detial/', formData, { headers: { Authorization: token } });
            // console.log(res.data);
            if (res.data) {
                setIdPost(res?.data?.id)
                handleStandard(res?.data?.id);
                // setRefresh(1)
                // setLoading(true)
                console.log(res.data.id);
            }
        }
        catch (err) {
            console.log(err);
            setIsLoading(false)
        }

    }
    const handleStandard = async (id) => {
        if (!id) return
        let formData = {
            questionClauseRelated: id,
            standardRelated: standard,
            clauseRelated: [clause]
        };
        try {
            const res = await API.post('/api/v1/audit/question/clause/', formData, { headers: { Authorization: token } })
            console.log(res.data);
            if (res.data) {
                setShowDisable(true)
                setIsLoading(false)
                setQ(1);
            }
            else {
                setShowDisable(false);
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setIsLoading(false);
        }
    }
    return (
        <>

            <div className='flex h-[40%] gap-2 pt-1 justify-between px-2 2xl:px-5 pb-1 border-b border-b-caspian-gray2'>

                <div className='flex flex-col justify-between gap-2 basis-2/3'>
                    {/* clause & standard */}
                    <div className='flex gap-2 items-center'>
                        <div className='basis-1/2'>
                            <ListStandard
                                lable="استاندارد مرتبط"
                                standardList={standardList}
                                standard={standard}
                                setStandard={setStandard}
                                standardSelected={standardSelected}
                                setStandardSelected={setStandardSelected}
                                showListStandard={showListStandard}
                                setShowListStandard={setShowListStandard}
                                idPost={idPost}
                                setStandard2={setStandard2}
                                check={check}
                                edit={edit}
                            />
                        </div>
                        <div className='basis-1/2'>
                            <ListClause
                                lable="بند مرتبط"
                                clauseList={clauseList}
                                clause={clause}
                                setClause={setClause}
                                clauseSelected={clauseSelected}
                                setClauseSelected={setClauseSelected}
                                showListClause={showListClause}
                                setShowListClause={setShowListClause}
                                standard={standard}
                                idPost={idPost}
                                check={check}
                                edit={edit}
                            />
                        </div>
                    </div>
                    <div className='flex gap-2 items-center justify-between'>
                        <div className='flex flex-col gap-1 basis-1/2'>
                            {/* موضوع */}
                            <div className='flex justify-between items-center'>
                                <span className='text-xs text-caspian-gray4 font-medium'>موضوع</span>
                                {check && !title && <p className='text-xs text-caspian-error2'>موضوع را پر کنید.</p>}
                            </div>
                            <textarea
                                disabled={idPost && !edit ? true : idPost && edit ? false : false}
                                onChange={(e) => setTitle(e.target.value)}
                                rows={4} placeholder='موضوع مورد نظر خود را وارد کنید' className='w-full text-xs border border-caspian-gray2
                            px-3 py-2 rounded-md placeholder:text-caspian-gray4 placeholder:text-xs resize-none'>

                            </textarea>
                        </div>
                        {/* معیار */}
                        <div className='flex flex-col gap-1 basis-1/2'>
                            <div className='flex justify-between items-centerّ'>
                                <span className='text-xs text-caspian-gray4 font-medium'>معیار</span>
                                {check && !cri && <p className='text-xs text-caspian-error2'>معیار را پر کنید.</p>}
                            </div>
                            <textarea
                                disabled={idPost && !edit ? true : idPost && edit ? false : false}
                                onChange={(e) => setCri(e.target.value)}
                                rows={4} placeholder='معیار مورد نظر خود را وارد کنید' className='w-full text-xs border border-caspian-gray2
                            px-3 py-2 rounded-md placeholder:text-caspian-gray4 placeholder:text-xs resize-none'>


                            </textarea>
                        </div>
                    </div>
                    {/* pdca */}
                    <div className='flex flex-row-reverse justify-center items-center gap-8'>
                        <div className='flex justify-between items-baseline'>
                            <span className={`${check && !pdca ? 'text-caspian-error2' : 'text-caspian-gray4'} text-sm ml-2 ltr font-bold`}>PDCA :</span>
                            {/* {check && !pdca && <span className='text-xs text-caspian-error2'>انتخاب کنید</span>} */}
                        </div>
                        <div className='flex flex-row-reverse items-center gap-1'>
                            <input
                                checked={pdca === 'p' ? true : false}
                                disabled={idPost && !edit ? true : idPost && edit ? false : false} onChange={(e) => setPdca(e.target.value)} type="radio" id='p' name="pdca" value='p' />
                            <label htmlFor="p" className='text-[#3CA29C] font-bold text-xs'>P</label>
                        </div>

                        <div className='flex flex-row-reverse items-center gap-1'>
                            <input
                                disabled={idPost && !edit ? true : idPost && edit ? false : false} onChange={(e) => setPdca(e.target.value)} type="radio" id='d' name="pdca" value='d' />
                            <label htmlFor="d" className='text-[#FF5B5B] font-bold text-xs'>D</label>
                        </div>

                        <div className='flex flex-row-reverse items-center gap-1'>
                            <input
                                disabled={idPost && !edit ? true : idPost && edit ? false : false} onChange={(e) => setPdca(e.target.value)} type="radio" id='c' name="pdca" value='c' />
                            <label htmlFor="c" className='text-[#C38C15] font-bold text-xs'>C</label>
                        </div>

                        <div className='flex flex-row-reverse items-center gap-1'>
                            <input
                                disabled={idPost && !edit ? true : idPost && edit ? false : false} onChange={(e) => setPdca(e.target.value)} type="radio" id='a' name="pdca" value='a' />
                            <label htmlFor="a" className='text-[#4D5DAA] font-bold text-xs'>A</label>
                        </div>

                    </div>

                    <button
                        disabled={idPost && !edit ? true : idPost && edit ? false : false}
                        onClick={handleSubmit}
                        className='p-1 2xl:p-1.5 flex gap-2 justify-center items-center w-[130px] bg-caspian-secondary-600 rounded-md text-caspian-surface text-xs 2xl:text-xs disabled:bg-caspian-gray4 disabled:text-caspian-gray2'>
                        <span>ثبت سوال</span>
                        {isLoading && <PiCircleNotch />}
                    </button>
                </div>
                <div className='flex flex-col gap-2 basis-1/3'>
                    <div className="flex flex-col min-h-full max-h-44 overflow-y-auto gap-4 p-2 bg-[#E4F0F1] rounded-lg border-2 border-[#4B807F]">
                        {
                            clause ? <div className=''>
                                <p className='text-caspian-gray4 pb-1 text-sm'>{clauseSelected?.title}</p>
                                <p className='text-xs leading-5 pb-1' dangerouslySetInnerHTML={{ __html: clauseSelected?.description }}></p>
                            </div> : <div className='flex w-full h-full flex-col items-center justify-center'>
                                <p className='text-xs 2xl:text-sm text-caspian-gray'>بند و استاندارد انتخاب نشده</p>
                            </div>
                        }
                    </div>

                </div>
            </div>
        </>
    );
}

export default StepOne;
