import React, { useContext } from "react";
import {
  PiArrowArcRight,
  PiBookmarkSimple,
  PiBookmarkSimpleFill,
  PiInfo,
  PiTrash,
} from "react-icons/pi";
import API from "../../../api/API";
import { AuthContext } from "../../../context/auth/AuthContext";

const DetailsMenubar = ({
  open,
  setOpen,
  noteId,
  setShowDetails,
  isFav,
  refresh,
  setRefresh,
  isTrash,
  setShowEditNote,
  showEditNote,
}) => {
  const { token } = useContext(AuthContext);
  const trashForeverHandler = async (id) => {
    API.delete(`/api/v1/note/note/${id}/`, {
      headers: { Authorization: token },
    })
      .then((res) => {
        console.log(res.data);
        setShowEditNote(showEditNote?.filter((id) => id !== noteId));
        setRefresh(1);
      })
      .catch((err) => {
        console.log("error");
      });
  };
  const restorHandler = async (id) => {
    API.patch(
      `/api/v1/note/note/${id}/`,
      {
        trash: false,
      },
      {
        headers: { Authorization: token },
      }
    )
      .then((res) => {
        console.log(res.data);
        setRefresh(1);
      })
      .catch((err) => {
        console.log("error");
      });
  };
  const trashHandler = async (id) => {
    API.patch(
      `/api/v1/note/note/${id}/`,
      {
        trash: true,
      },
      {
        headers: { Authorization: token },
      }
    )
      .then((res) => {
        console.log(res.data);
        setRefresh(1);
      })
      .catch((err) => {
        console.log("error");
      });
  };
  const favHandler = async (id) => {
    if (token) {
      API.patch(
        `/api/v1/note/note/${id}/`,
        {
          fav: true,
        },
        {
          headers: { Authorization: token },
        }
      )
        .then((res) => {
          console.log(res.data);
          setRefresh(1);
        })
        .catch((err) => {
          console.log("error");
        });
    }
  };
  const unfavHandler = async (id) => {
    API.patch(
      `/api/v1/note/note/${id}/`,
      {
        fav: false,
      },
      {
        headers: { Authorization: token },
      }
    )
      .then((res) => {
        console.log(res.data);
        setRefresh(1);
      })
      .catch((err) => {
        console.log("error");
      });
  };

  return (
    <div
      className={
        open
          ? `p-1 absolute top-5 right-3
              w-28 bg-caspian-surface rounded-sm flex items-center justify-center z-20 shadow-[0_0_5px_#00000050]`
          : "hidden"
      }
    >
      {isTrash ? (
        <ul className="flex flex-col gap-0 items-center justify-center w-full">
          <li
            onClick={() => {
              setOpen(false);
              trashForeverHandler(noteId);
            }}
            className="hover:bg-caspian-surface border-b border-b-caspian-gray2 p-1.5 w-full cursor-pointer flex items-center gap-1"
          >
            <PiTrash className="text-lg text-caspian-gray4" />
            <span className="text-xs text-caspian-gray4">حذف</span>
          </li>
          <li
            onClick={() => {
              setOpen(false);
              restorHandler(noteId);
            }}
            className="hover:bg-caspian-surface border-b border-b-caspian-gray2 p-1.5 w-full cursor-pointer flex items-center gap-1"
          >
            <PiArrowArcRight className="text-lg text-caspian-gray4" />
            <span className="text-xs text-caspian-gray4">بازگردانی</span>
          </li>
          <li
            onClick={() => {
              setOpen(false);
              setShowDetails(true);
            }}
            className="hover:bg-caspian-surface p-1.5 w-full cursor-pointer flex items-center gap-1"
          >
            <PiInfo className="text-lg text-caspian-gray4" />
            <span className="text-xs text-caspian-gray4">جزئیات</span>
          </li>
        </ul>
      ) : (
        <ul className="flex flex-col gap-0 items-center justify-center w-full">
          <li
            onClick={() => (isFav ? unfavHandler(noteId) : favHandler(noteId))}
            className="hover:bg-caspian-surface border-b border-b-caspian-gray2 p-1.5 w-full cursor-pointer flex items-center gap-1"
          >
            {isFav ? (
              <PiBookmarkSimpleFill className="text-lg text-caspian-gray4" />
            ) : (
              <PiBookmarkSimple className="text-lg text-caspian-gray4" />
            )}
            <span className="text-xs text-caspian-gray4">علاقه مندی ها</span>
          </li>
          <li
            onClick={() => {
              setOpen(false);
              trashHandler(noteId);
            }}
            className="hover:bg-caspian-surface border-b border-b-caspian-gray2 p-1.5 w-full cursor-pointer flex items-center gap-1"
          >
            <PiTrash className="text-lg text-caspian-gray4" />
            <span className="text-xs text-caspian-gray4">حذف</span>
          </li>
          <li
            onClick={() => {
              setOpen(false);
              setShowDetails(true);
            }}
            className="hover:bg-caspian-surface p-1.5 w-full cursor-pointer flex items-center gap-1"
          >
            <PiInfo className="text-lg text-caspian-gray4" />
            <span className="text-xs text-caspian-gray4">جزئیات</span>
          </li>
        </ul>
      )}
    </div>
  );
};

export default DetailsMenubar;
