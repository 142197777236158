import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/auth/AuthContext";
import API from "../../../../api/API";
import { useOutletContext } from "react-router-dom";
import MainCreateForm from "./form/create/MainCreateForm";
import LoadingData from "../../LoadingData";
import NotfoundData from "../../NotfoundData";
import DoneActivity from "./form/create/DoneActivity";
import OutActivity from "./form/create/OutActivity";

const MainActivityProfile = () => {
  const { token } = useContext(AuthContext);
  const [fullData, setfullData] = useState([-1]);
  const [activityId] = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [refreshRiskActivity, setRefreshRiskActivity] = useState(0);
  useEffect(() => {
    setRefreshRiskActivity(1);
  }, []);
  useEffect(() => {
    if (!token || !activityId || refreshRiskActivity === 0) return;
    setLoading(true);
    const getIdentification = async () => {
      try {
        const res = await API.get(
          `/api/v1/risk/identification/profile/${activityId}/`,
          {
            headers: { Authorization: token },
          }
        );
        setfullData(res.data);
        setRefreshRiskActivity(0);
        console.log(res.data);
      } catch (error) {
        console.log(error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    getIdentification();
  }, [token, activityId, refreshRiskActivity]);
  console.log(fullData);
  return (
    <div className="flex flex-col gap-0 pb-4">
      {loading ? (
        <LoadingData />
      ) : error ? (
        <NotfoundData />
      ) : fullData?.statusType === "outdate" ? (
        <OutActivity
          thisTitleProcess={fullData?.groupProcessSelected?.title}
          thisTitleGoal={fullData?.goalObjectiveSelected?.title}
        />
      ) : fullData?.statusType === "done" ? (
        <DoneActivity
          thisTitleProcess={fullData?.groupProcessSelected?.title}
          thisTitleGoal={fullData?.goalObjectiveSelected?.title}
          riskForm={fullData?.RiskIdentificationRelated}
        />
      ) : (
        <MainCreateForm
          isDoing={fullData?.statusType === "doing"}
          setRefreshRiskActivity={setRefreshRiskActivity}
          fullData={fullData}
          thisProfileId={activityId}
          thisIdProcess={fullData?.groupProcessSelected?.id}
          thisTitleProcess={fullData?.groupProcessSelected?.title}
          thisIdGoal={fullData?.goalObjectiveSelected?.id}
          thisTitleGoal={fullData?.goalObjectiveSelected?.title}
          riskForm={fullData?.RiskIdentificationRelated}
        />
      )}
    </div>
  );
};

export default MainActivityProfile;
