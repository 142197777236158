import OrganizationTabs from "./tabs/OrganizationTabs";

const BottomProfile = ({data,refresh,setRefresh}) => {
    return (
        
        <OrganizationTabs data={data} refresh={refresh} setRefresh={setRefresh} />
    );
}

export default BottomProfile;
