import React, { useContext, useEffect, useState } from "react";
import ButtonTab from "./ButtonTab";
import MainStepOne from "./forms/step-one/MainStepOne";
import MainStepTwo from "./forms/step-two/MainStepTwo";
import MainStepThree from "./forms/step-three/MainStepThree";
import MainStepFour from "./forms/step-four/MainStepFour";
import { AuthContext } from "../../../../../../context/auth/AuthContext";
import API from "../../../../../../api/API";

const EditOfficeForm = ({
  setShowEditOfficeForm,
  actionplanId,
  setRefresh,
}) => {
  const { token } = useContext(AuthContext);
  const [actionplanIdRelated, setActionplanIdRelated] = useState(null);
  const [activeTab, setActiveTab] = useState("StepOne");
  const [members, setMembers] = useState([-1]);
  const [membersProfile, setMembersProfile] = useState([-1]);

  useEffect(() => {
    const getMembers = async () => {
      try {
        const res = await API.get("/api/v1/organization/role/placement/", {
          headers: {
            Authorization: token,
          },
        });
        setMembers(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getMembers();
  }, [token]);
  useEffect(() => {
    const getMembersProfile = async () => {
      try {
        const res = await API.get("/api/v1/profile/", {
          headers: {
            Authorization: token,
          },
        });
        setMembersProfile(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getMembersProfile();
  }, [token]);
  return (
    <div className="flex flex-col justify-between h-full">
      {/* تب های فرم برنامه ریزی برگزار جلسه */}
      <div className="flex items-center justify-between gap-4 bg-caspian-gray2/40 py-1.5 px-2 rounded-md">
        <ButtonTab
          activeTab={activeTab}
          active="StepOne"
          onClick={() => setActiveTab("StepOne")}
          title="مرحله اول"
        />
        <ButtonTab
          activeTab={activeTab}
          active="StepTwo"
          onClick={() => setActiveTab("StepTwo")}
          title="مرحله دوم"
        />
        <ButtonTab
          activeTab={activeTab}
          active="StepThree"
          onClick={() => setActiveTab("StepThree")}
          title="مرحله سوم"
        />
        <ButtonTab
          activeTab={activeTab}
          active="StepFour"
          onClick={() => setActiveTab("StepFour")}
          title="مرحله چهارم"
        />
      </div>
      <div className=" py-2">
        {/* مرحله اول  */}
        {activeTab === "StepOne" && (
          <MainStepOne
            // setRefresh={setRefresh}
            setActiveTab={setActiveTab}
            // titleData={actionPlanData?.title}
            // descriptionData={actionPlanData?.description}
            actionplanIdRelated={actionplanId}
            setActionplanIdRelated={setActionplanIdRelated}
            setShowEditOfficeForm={setShowEditOfficeForm}
          />
        )}
        {/* مرحله دوم */}
        {activeTab === "StepTwo" && (
          <MainStepTwo
            // setRefresh={setRefresh}
            // dataStepTwo={actionPlanData?.actionplan_responsibles}
            members={members}
            membersProfile={membersProfile}
            setActiveTab={setActiveTab}
            actionplanIdRelated={actionplanId}
            setShowEditOfficeForm={setShowEditOfficeForm}
          />
        )}
        {/* مرحله سوم */}
        {activeTab === "StepThree" && (
          <MainStepThree
            setActiveTab={setActiveTab}
            actionplanIdRelated={actionplanId}
            setShowEditOfficeForm={setShowEditOfficeForm}
          />
        )}
        {/* مرحله چهارم */}
        {activeTab === "StepFour" && (
          <MainStepFour
            setActiveTab={setActiveTab}
            actionplanIdRelated={actionplanId}
            setShowEditOfficeForm={setShowEditOfficeForm}
            setRefresh={setRefresh}
          />
        )}
      </div>
    </div>
  );
};

export default EditOfficeForm;
