import React, { useContext, useState } from "react";
import Modal from "../../../../../../../../modal/Modal";
import { PiX } from "react-icons/pi";
import SingleInput from "../SingleInput";
import API from "../../../../../../../../../api/API";
import { AuthContext } from "../../../../../../../../../context/auth/AuthContext";

const MainMaterialForm = ({ setShowNewMaterialForm }) => {
  const { token } = useContext(AuthContext);
  const [name, setName] = useState("");
  const [unit, setUnit] = useState("");
  const [description, setDescription] = useState("");

  const formHandler = async () => {
    if (!token || !name || !unit) return;
    let dataForm = {
      name: name,
      unit: unit,
      description: description,
    };

    try {
      const res = await API.post("/api/v1/material/materials/", dataForm, {
        headers: { Authorization: token },
      });
      console.log("response", res?.data);
      console.log("local", dataForm);
      setShowNewMaterialForm(false);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Modal>
      <div className="w-full flex flex-col gap-0 bg-caspian-surface rounded-md">
        <div className="p-2 flex items-center justify-between">
          <span className="text-xs">الزامات ایمنی و بهداشت</span>
          <PiX
            className="cursor-pointer"
            onClick={() => setShowNewMaterialForm(false)}
          />
        </div>
        <div className="p-2 flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <SingleInput lable={"نام قلم مصرفی"}>
              <input
                onChange={(e) => setName(e.target.value)}
                className="text-xs p-2 rounded-md bg-caspian-transparent outline-none border border-caspian-gray2 w-full"
                placeholder="نام قلم مصرفی را وارد کنید"
              />
            </SingleInput>
            <SingleInput lable={"واحد قلم مصرفی"}>
              <input
                onChange={(e) => setUnit(e.target.value)}
                className="text-xs p-2 rounded-md bg-caspian-transparent outline-none border border-caspian-gray2 w-full"
                placeholder="واحد قلم مصرفی را وارد کنید"
              />
            </SingleInput>
          </div>
          <SingleInput lable={"توضیحات"}>
            <textarea
              onChange={(e) => setDescription(e.target.value)}
              rows={5}
              className="text-xs p-2 rounded-md bg-caspian-transparent outline-none border border-caspian-gray2 w-full resize-none"
              placeholder="توضیحات را وارد کنید"
            />
          </SingleInput>
          <div className="flex items-center gap-2 p-2 pt-4">
            <button
              onClick={() => formHandler()}
              className="bg-caspian-secondary-500 text-caspian-surface text-xs py-1.5 px-6 rounded-md"
            >
              ایجاد
            </button>
            <button
              onClick={() => setShowNewMaterialForm(false)}
              className="text-xs text-[#FF5B5B] mr-4"
            >
              انصراف
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MainMaterialForm;
