import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import API from "../../../../../api/API";
import Dropdown from "../../../../ui/Dropdown";
import Line from "../../../../ui/Line";
import BudgetSingle from "../../../pmo/profile/BudgetSingle";
import ResponsibleSingle from "../../../pmo/profile/ResponsibleSingle";
import ChallengeList from "./ChallengeList";
import OpportunityList from "./OpportunityList";
import SingleFile from "./SingleFile";
import { toast } from "react-toastify";

const Profile = ({ fullData, costData, setRefresh }) => {
  const { token } = useContext(AuthContext);

  const [isAccept, setIsAccept] = useState(false);

  const [material, setMaterial] = useState([]);
  useEffect(() => {
    const getMaterialList = async () => {
      if (!fullData?.id || !token) return;
      try {
        const res = await API.get(
          `/api/v1/actionplan/actionplan/material/${fullData?.id}`,
          {
            headers: { Authorization: token },
          }
        );
        setMaterial(res?.data);
        console.log(res?.data);
      } catch (err) {
        console.log(err);
      }
    };
    getMaterialList();
  }, [fullData, token]);

  const dataHandler = async () => {
    let formData = {
      ActionPlanSelected_id: fullData?.id,
      link: `/action-plan/my-actionplan/${fullData?.id}/profile`,
    };
    if (!fullData?.id) return;
    console.log(formData);
    try {
      const res = await API.post(
        `/api/v1/actionplan/actionplanactivity`,
        formData,
        {
          headers: { Authorization: token },
        }
      );
      console.log(res?.data);
      setRefresh(1);
    } catch (err) {
      console.log(err);
    }
  };

  const sendToPMOHandler = async () => {
    let formData = {
      ActionPlanSelected_id: fullData?.id,
      link: `/action-plan/my-actionplan/${fullData?.id}/profile`,
    };
    if (!fullData?.id) return;
    try {
      const res = await API.post(
        `/api/v1/actionplan/actionplanactivity`,
        formData,
        {
          headers: { Authorization: token },
        }
      );
      console.log(res?.data);
      setRefresh(1);
    } catch (err) {
      console.log(err);
    }
  };

  const PmoConfirmHandler = async () => {
    let formData = {
      ActionPlanSelected_id: fullData?.id,
      link: `/action-plan/my-actionplan/${fullData?.id}/profile`,
    };
    if (!fullData?.id) return;
    try {
      const res = await API.post(
        `/api/v1/actionplan/actionplanactivity`,
        formData,
        {
          headers: { Authorization: token },
        }
      );
      console.log(res?.data);
      setRefresh(1);
    } catch (err) {
      console.log(err);
    }
  };

  const ObserversCommentsHandler = async () => {
    let formData = {
      ActionPlanSelected_id: fullData?.id,
      link: `/action-plan/my-actionplan/${fullData?.id}/profile`,
    };
    if (!fullData?.id) return;
    try {
      const res = await API.post(
        `/api/v1/actionplan/actionplanactivity`,
        formData,
        {
          headers: { Authorization: token },
        }
      );
      console.log(res?.data);
      setRefresh(1);
    } catch (err) {
      console.log(err);
    }
  };

  const VarifyConfirm = async () => {
    let formData = {
      ActionPlanSelected_id: fullData?.id,
      link: `/action-plan/my-actionplan/${fullData?.id}/profile`,
    };
    if (!fullData?.id) return;
    try {
      const res = await API.post(
        `/api/v1/actionplan/actionplanactivity`,
        formData,
        {
          headers: { Authorization: token },
        }
      );
      console.log(res?.data);
      setRefresh(1);
    } catch (err) {
      console.log(err);
    }
  };

  const ApprovalConfirm = async () => {
    let formData = {
      ActionPlanSelected_id: fullData?.id,
      link: `/action-plan/my-actionplan/${fullData?.id}/profile`,
    };
    if (!fullData?.id) return;
    try {
      const res = await API.post(
        `/api/v1/actionplan/actionplanactivity`,
        formData,
        {
          headers: { Authorization: token },
        }
      );
      console.log(res?.data);
      setRefresh(1);
      toast.success("برنامه سازمانی با موفقیت ساخته شد", {
        className: "toast-success",
        bodyClassName: "toast-success",
      });
    } catch (err) {
      console.log(err);
    }
  };

  const StartTaskHandler = async () => {
    let formData = {
      ActionPlanSelected_id: fullData?.id,
      link: `/action-plan/my-actionplan/${fullData?.id}/profile`,
    };
    if (!fullData?.id) return;
    try {
      const res = await API.post(
        `/api/v1/actionplan/actionplanactivity`,
        formData,
        {
          headers: { Authorization: token },
        }
      );
      console.log(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="w-full flex flex-col gap-4">
      {/* عنوان */}
      <div className="flex flex-col gap-4 w-full">
        <div className="flex items-center gap-2 w-full">
          <span className="text-sm font-bold basis-[20%] text-caspian-primary-500">
            عنوان
          </span>
          <h2 className="text-sm font-bold basis-[80%]">{fullData?.title}</h2>
        </div>
        <div className="flex items-start gap-2 w-full">
          <span className="text-sm font-bold basis-[20%] text-caspian-primary-500">
            توضیحات
          </span>
          <p className="text-xs basis-[80%]">{fullData?.description}</p>
        </div>
        <div className="flex items-start gap-2 w-full">
          <span className="text-sm font-bold basis-[20%] text-caspian-primary-500">
            نتایج مورد انتظار
          </span>
          <p className="text-xs basis-[80%]">
            {fullData?.actionplan_goal?.outputProject}
          </p>
        </div>
      </div>

      {/* هدف و فرایند */}
      <div className="flex flex-col gap-4 w-full">
        <div className="flex items-center justify-between gap-2 w-full">
          <span className="text-sm font-bold w-[30%] whitespace-nowrap">
            هدف و فرایند
          </span>
          <Line color="bg-caspian-secondary-100" />
        </div>
        <div className="flex items-start justify-between w-full gap-2">
          <Dropdown
            className={
              "border border-caspian-secondary-700 text-caspian-secondary-700 text-xs p-3 rounded-tl-xl"
            }
            title="اهداف مرتبط"
          >
            <div className="flex flex-col gap-3 p-2">
              {fullData?.actionplan_goal?.goal?.map((p, i) => (
                <div key={i} className="flex items-center gap-1">
                  <div className="w-[4px] h-[4px] rounded-full bg-caspian-secondary-500"></div>
                  <p className="text-xs text-caspian-secondary-500 font-bold">
                    {p?.title}
                  </p>
                </div>
              ))}
            </div>
          </Dropdown>
          <Dropdown
            className={
              "border border-caspian-secondary-700 text-caspian-secondary-700 text-xs p-3 rounded-tl-xl"
            }
            title="فرانید‌های مرتبط"
          >
            <div className="flex flex-col gap-3 p-2">
              {fullData?.actionplan_goal?.processes?.map((p, i) => (
                <div key={i} className="flex items-center gap-1">
                  <div className="w-[4px] h-[4px] rounded-full bg-caspian-secondary-500"></div>
                  <p className="text-xs text-caspian-secondary-500 font-bold">
                    {p?.title}
                  </p>
                </div>
              ))}
            </div>
          </Dropdown>
        </div>
      </div>
      {/* ذینفعان */}
      <div className="flex flex-col gap-4 w-full">
        <div className="flex items-center justify-between gap-2 w-full">
          <span className="text-sm font-bold w-[30%] whitespace-nowrap">
            ذینفعان
          </span>
          <Line color="bg-caspian-primary-100" />
        </div>
        <div className="flex items-start justify-between w-full gap-2">
          <Dropdown
            className={
              "border border-caspian-primary-700 text-caspian-primary-700 text-xs p-3 rounded-tl-xl"
            }
            title="ذینفعان تاثیرگذار"
          >
            <div className="flex flex-col gap-3 p-2">
              {fullData?.stack_actionplan
                ?.filter((s) => s?.typeProject === "influencer")
                ?.map((s, i) => (
                  <div key={i} className="flex flex-col gap-1">
                    <div className="flex items-center gap-1">
                      <div className="w-[4px] h-[4px] rounded-full bg-caspian-primary-500"></div>
                      <span className="text-xs text-caspian-primary-500 font-bold">
                        {s?.StackHolderRelated?.title}
                      </span>
                    </div>
                    <p className="text-xs text-caspian-gray4">
                      {s?.description}
                    </p>
                  </div>
                ))}
            </div>
          </Dropdown>
          <Dropdown
            className={
              "border border-caspian-primary-700 text-caspian-primary-700 text-xs p-3 rounded-tl-xl"
            }
            title="ذینفعان تاثیرپذیر"
          >
            <div className="flex flex-col gap-3 p-2">
              {fullData?.stack_actionplan
                ?.filter((s) => s?.typeProject === "affected")
                ?.map((s, i) => (
                  <div key={i} className="flex flex-col gap-1">
                    <div className="flex items-center gap-1">
                      <div className="w-[4px] h-[4px] rounded-full bg-caspian-primary-500"></div>
                      <span className="text-xs text-caspian-primary-500 font-bold">
                        {s?.StackHolderRelated?.title}
                      </span>
                    </div>
                    <p className="text-xs text-caspian-gray4">
                      {s?.description}
                    </p>
                  </div>
                ))}
            </div>
          </Dropdown>
        </div>
      </div>

      <div className="flex items-start w-full gap-2">
        {/* بودجه و چهارچوب زمانی */}
        <div className="flex flex-col gap-4 w-full">
          {/* بودجه */}
          <div className="flex flex-col gap-4 w-full">
            <div className="flex items-center justify-between gap-2 w-full">
              <span className="text-sm font-bold w-[30%] whitespace-nowrap">
                بودجه
              </span>
              <Line color="bg-caspian-gray2" />
            </div>
            <div className="grid grid-cols-2 gap-2">
              <BudgetSingle
                title="بودجه تخمینی PMO(ریال)"
                cost={fullData?.actionplan_detail?.cost}
              />
              <BudgetSingle
                title="بودجه برنامه اجرایی"
                cost={costData?.costTotalTasks}
                className="text-[#5E7D06] font-bold"
              />
              <BudgetSingle
                title="جمع بودجه وظایف(ریال)"
                cost={costData?.costTotalTasks}
              />
              <BudgetSingle
                title="بودجه برای سایر موارد"
                cost={fullData?.actionplan_PM?.other_budget}
              />
            </div>
          </div>
          {/* چهارچوب زمانی */}
          <div className="flex flex-col gap-4 w-full">
            <div className="flex items-center justify-between gap-2">
              <span className="text-sm font-bold w-[30%] whitespace-nowrap">
                چهارچوب زمانی
              </span>
              <Line color="bg-caspian-gray2" />
            </div>
            <div className="flex items-center justify-between gap-2">
              <span className="text-xs text-caspian-gray4">
                برنامه‌ریزی PMO
              </span>
              <div className="flex items-center gap-2">
                <div className="flex items-center gap-0 bg-caspian-gray2/40 rounded-lg border border-caspian-gray">
                  <span className="text-xs rounded-lg bg-caspian-gray3 p-2 border-l border-l-caspian-gray">
                    شروع
                  </span>
                  <span className="text-xs p-2">
                    {new Date(
                      fullData?.actionplan_detail?.startDate
                    ).toLocaleDateString("fa-IR")}
                  </span>
                </div>
                <div className="flex items-center gap-0 bg-caspian-gray2/40 rounded-lg border border-caspian-gray">
                  <span className="text-xs rounded-lg bg-caspian-gray3 p-2 border-l border-l-caspian-gray">
                    پایان
                  </span>
                  <span className="text-xs p-2">
                    {new Date(
                      fullData?.actionplan_detail?.endDate
                    ).toLocaleDateString("fa-IR")}
                  </span>
                </div>
              </div>
            </div>
            {costData?.startDateReal && (
              <div className="flex items-center justify-between gap-2">
                <span className="text-xs text-caspian-gray4">
                  برنامه‌ریزی مسئول برنامه اجرایی‌
                </span>
                <div className="flex items-center gap-2">
                  <div className="flex items-center gap-0 bg-caspian-gray2/40 rounded-lg border border-caspian-gray">
                    <span className="text-xs rounded-lg bg-caspian-gray3 p-2 border-l border-l-caspian-gray">
                      شروع
                    </span>
                    <span className="text-xs p-2">
                      {new Date(costData?.startDateReal).toLocaleDateString(
                        "fa-IR"
                      )}
                    </span>
                  </div>
                  <div className="flex items-center gap-0 bg-caspian-gray2/40 rounded-lg border border-caspian-gray">
                    <span className="text-xs rounded-lg bg-caspian-gray3 p-2 border-l border-l-caspian-gray">
                      پایان
                    </span>
                    <span className="text-xs p-2">
                      {new Date(costData?.endDateReal).toLocaleDateString(
                        "fa-IR"
                      )}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* مجریان */}
        <div className="flex flex-col gap-4 w-full">
          <div className="flex items-center justify-between gap-2">
            <span className="text-sm font-bold w-[30%] whitespace-nowrap">
              مجریان
            </span>
            <Line color="bg-caspian-gray2" />
          </div>
          <Dropdown
            className={
              "border border-caspian-secondary-400 text-caspian-secondary-400 text-xs p-3 rounded-tl-xl"
            }
            title="نقش‌ها"
          >
            <div className="flex flex-col gap-2 p-2">
              <ResponsibleSingle
                title="مسئول برنامه اجرایی"
                fullName={
                  fullData?.actionplan_responsibles?.manager?.firstName +
                  " " +
                  fullData?.actionplan_responsibles?.manager?.lastName
                }
              />
              <ResponsibleSingle
                title="کنترل کننده برنامه"
                fullName={
                  fullData?.actionplan_responsibles?.controller?.firstName +
                  " " +
                  fullData?.actionplan_responsibles?.controller?.lastName
                }
              />
              <ResponsibleSingle
                title="تایید کننده"
                fullName={
                  fullData?.actionplan_responsibles?.varify_placement
                    ?.placementProfileRelated?.firstName +
                  " " +
                  fullData?.actionplan_responsibles?.varify_placement
                    ?.placementProfileRelated?.lastName
                }
              />
              <ResponsibleSingle
                title="تصویب کننده"
                fullName={
                  fullData?.actionplan_responsibles?.approval_placement
                    ?.placementProfileRelated?.firstName +
                  " " +
                  fullData?.actionplan_responsibles?.approval_placement
                    ?.placementProfileRelated?.lastName
                }
              />
              <ResponsibleSingle
                title="ناظر فنی"
                fullName={
                  fullData?.actionplan_responsibles?.tech_observer
                    ?.placementProfileRelated?.firstName +
                  " " +
                  fullData?.actionplan_responsibles?.tech_observer
                    ?.placementProfileRelated?.lastName
                }
              />
              <ResponsibleSingle
                title="ناظر حقوقی"
                fullName={
                  fullData?.actionplan_responsibles?.legal_observer
                    ?.placementProfileRelated?.firstName +
                  " " +
                  fullData?.actionplan_responsibles?.legal_observer
                    ?.placementProfileRelated?.lastName
                }
              />
              <ResponsibleSingle
                title="ناظر بودجه"
                fullName={
                  fullData?.actionplan_responsibles?.budget_observer
                    ?.placementProfileRelated?.firstName +
                  " " +
                  fullData?.actionplan_responsibles?.budget_observer
                    ?.placementProfileRelated?.lastName
                }
              />
              <ResponsibleSingle
                title="ناظر عمومی"
                fullName={
                  fullData?.actionplan_responsibles?.general_observer
                    ?.placementProfileRelated?.firstName +
                  " " +
                  fullData?.actionplan_responsibles?.general_observer
                    ?.placementProfileRelated?.lastName
                }
              />
              <ResponsibleSingle
                title="ناظر HSE"
                fullName={
                  fullData?.actionplan_responsibles?.hse_observer
                    ?.placementProfileRelated?.firstName +
                  " " +
                  fullData?.actionplan_responsibles?.hse_observer
                    ?.placementProfileRelated?.lastName
                }
              />
              <ResponsibleSingle
                title="ناظر حسابداری"
                fullName={
                  fullData?.actionplan_responsibles?.accounting_observer
                    ?.placementProfileRelated?.firstName +
                  " " +
                  fullData?.actionplan_responsibles?.accounting_observer
                    ?.placementProfileRelated?.lastName
                }
              />
            </div>
          </Dropdown>
        </div>
      </div>

      {/* چالش و فرصت */}
      <div className="flex flex-col gap-4 w-full">
        <div className="flex items-center justify-between gap-2">
          <span className="text-sm font-bold w-[30%] whitespace-nowrap">
            چالش و فرصت
          </span>
          <Line color="bg-caspian-gray2" />
        </div>
        <div className="flex items-start w-full gap-2">
          <div className="flex flex-col gap-2 w-full">
            <span className="text-sm font-bold whitespace-nowrap">
              چالش‌های مرتبط
            </span>
            <ChallengeList actionplanIdRelated={fullData?.id} />
          </div>
          <div className="flex flex-col gap-2 w-full">
            <span className="text-sm font-bold whitespace-nowrap">
              فرصت‌های مرتبط
            </span>
            <OpportunityList actionplanIdRelated={fullData?.id} />
          </div>
        </div>
      </div>

      {/* فایل پیوست */}
      {fullData?.document_actionplan?.length > 0 && (
        <div className="flex flex-col gap-4 w-full">
          <div className="flex items-center justify-between gap-2">
            <span className="text-sm font-bold w-[30%] whitespace-nowrap">
              فایل پیوست
            </span>
            <Line color="bg-caspian-gray2" />
          </div>
          <div className="flex flex-col gap-4">
            {fullData?.document_actionplan?.map((f, i) => (
              <SingleFile key={i} data={f} />
            ))}
          </div>
        </div>
      )}

      {/* نتایج مورد انتظار PM */}
      {fullData?.actionplan_PM?.pm_output && (
        <div className="flex flex-col gap-4 w-full">
          <div className="flex items-center justify-between gap-2">
            <span className="text-sm font-bold text-caspian-primary-500 w-[30%] whitespace-nowrap">
              نتایج مورد انتظار مسئول برنامه اجرایی
            </span>
            <Line color="bg-caspian-gray2" />
          </div>
          <p className="text-xs">{fullData?.actionplan_PM?.pm_output}</p>
        </div>
      )}
      {/* توضیحات PM */}
      {fullData?.actionplan_PM?.pm_description && (
        <div className="flex flex-col gap-4 w-full">
          <div className="flex items-center justify-between gap-2">
            <span className="text-sm font-bold text-caspian-primary-500 w-[30%] whitespace-nowrap">
              توضیحات مسئول برنامه اجرایی
            </span>
            <Line color="bg-caspian-gray2" />
          </div>
          <p className="text-xs">{fullData?.actionplan_PM?.pm_description}</p>
        </div>
      )}

      {material && (
        <div className="flex flex-col gap-3 w-[400px]">
          <span className="text-xs text-caspian-gray4">لیست اقلام مصرفی</span>
          <div className="flex items-center justify-between gap-4 pr-2 border-b border-b-caspian-gray2/50 pb-3">
            <span className="text-xs text-caspian-gray4 basis-1/3">نام</span>
            <span className="text-xs text-caspian-gray4 basis-1/3">واحد</span>
            <span className="text-xs text-caspian-gray4 basis-1/3">
              حجم مصرف شده
            </span>
          </div>
          <div className="h-[150px] overflow-y-auto flex flex-col gap-0 ">
            {material?.map((r, i) => (
              <div
                className="flex items-center justify-between gap-4 border-r-2 border-r-caspian-primary-200 pr-2 py-2 first:pt-0"
                key={i}
              >
                <span className="text-xs text-caspian-gray4 basis-1/3 ">
                  {r?.materialRelated?.name}
                </span>
                <span className="text-xs text-caspian-gray4 basis-1/3 ">
                  {r?.materialRelated?.unit}
                </span>
                <span className="text-xs text-caspian-gray4 basis-1/3 ">
                  {0}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* تایید PMO */}
      {fullData?.id && fullData?.statusAction === "PMOPlan" && (
        <div className="flex flex-col gap-4 border-t border-t-caspian-gray2 pt-4">
          <div className="flex items-center gap-2">
            <input
              onChange={(e) => fullData?.id && setIsAccept(!isAccept)}
              id="accept"
              type="checkbox"
              className=" cursor-pointer accent-caspian-secondary-400"
            />
            <label
              htmlFor="accept"
              className="text-xs cursor-pointer font-bold"
            >
              با ارسال اطلاعات فوق به PM موافقت میفرمائید؟
            </label>
          </div>
          <div className="flex items-center gap-2">
            <button
              disabled={isAccept ? false : true}
              onClick={dataHandler}
              className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
            >
              ثبت و ارسال
            </button>
          </div>
        </div>
      )}
      {/* تایید PM */}
      {fullData?.id &&
        fullData?.userRole?.includes("MANAGER") &&
        fullData?.statusAction === "ManagerPlan" &&
        costData?.remained_weight < 1 && (
          <div className="flex flex-col gap-4 border-t border-t-caspian-gray2 pt-4">
            <div className="flex items-center gap-2">
              <input
                onChange={(e) => fullData?.id && setIsAccept(!isAccept)}
                id="accept"
                type="checkbox"
                className=" cursor-pointer accent-caspian-secondary-400"
              />
              <label
                htmlFor="accept"
                className="text-xs cursor-pointer font-bold"
              >
                با ارسال اطلاعات فوق به PMO موافقت میفرمائید؟
              </label>
            </div>
            <div className="flex items-center gap-2">
              <button
                disabled={isAccept ? false : true}
                onClick={sendToPMOHandler}
                className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
              >
                ثبت و ارسال
              </button>
            </div>
          </div>
        )}

      {/* Pmo Confirm */}
      {fullData?.id &&
        fullData?.userRole?.includes("PMO") &&
        fullData?.statusAction === "PMOConfirm" &&
        costData?.remained_weight < 1 && (
          <div className="flex flex-col gap-4 border-t border-t-caspian-gray2 pt-4">
            <div className="flex items-center gap-2">
              <input
                onChange={(e) => fullData?.id && setIsAccept(!isAccept)}
                id="accept"
                type="checkbox"
                className=" cursor-pointer accent-caspian-secondary-400"
              />
              <label
                htmlFor="accept"
                className="text-xs cursor-pointer font-bold"
              >
                با ارسال اطلاعات فوق برای نظارت ناظر ها موافقت می فرمائید ؟
              </label>
            </div>
            <div className="flex items-center gap-2">
              <button
                disabled={isAccept ? false : true}
                onClick={PmoConfirmHandler}
                className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
              >
                ثبت و ارسال
              </button>
            </div>
          </div>
        )}

      {/* ObseversCommentsApproval */}
      {fullData?.id &&
        fullData?.userRole?.includes("PMO") &&
        fullData?.statusAction === "ObseversCommentsApproval" &&
        costData?.remained_weight < 1 && (
          <div className="flex flex-col gap-4 border-t border-t-caspian-gray2 pt-4">
            <div className="flex items-center gap-2">
              <input
                onChange={(e) => fullData?.id && setIsAccept(!isAccept)}
                id="accept"
                type="checkbox"
                className=" cursor-pointer accent-caspian-secondary-400"
              />
              <label
                htmlFor="accept"
                className="text-xs cursor-pointer font-bold"
              >
                آیا تغییرات ایجاد شده را تایید می فرمائید ؟
              </label>
            </div>
            <div className="flex items-center gap-2">
              <button
                disabled={isAccept ? false : true}
                onClick={ObserversCommentsHandler}
                className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
              >
                ثبت و ارسال
              </button>
            </div>
          </div>
        )}

      {/* VARIFY_PLACEMENT */}
      {fullData?.id &&
        fullData?.userRole?.includes("VARIFY_PLACEMENT") &&
        fullData?.statusAction === "VarifyConfirm" &&
        costData?.remained_weight < 1 && (
          <div className="flex flex-col gap-4 border-t border-t-caspian-gray2 pt-4">
            <div className="flex items-center gap-2">
              <input
                onChange={(e) => fullData?.id && setIsAccept(!isAccept)}
                id="accept"
                type="checkbox"
                className=" cursor-pointer accent-caspian-secondary-400"
              />
              <label
                htmlFor="accept"
                className="text-xs cursor-pointer font-bold"
              >
                آیا پروفایل را تایید می فرمائید ؟
              </label>
            </div>
            <div className="flex items-center gap-2">
              <button
                disabled={isAccept ? false : true}
                onClick={VarifyConfirm}
                className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
              >
                ثبت و ارسال
              </button>
            </div>
          </div>
        )}
      {/* APPROVAL_PLACEMENT */}
      {fullData?.id &&
        fullData?.userRole?.includes("APPROVAL_PLACEMENT") &&
        fullData?.statusAction === "ApprovalConfirm" &&
        costData?.remained_weight < 1 && (
          <div className="flex flex-col gap-4 border-t border-t-caspian-gray2 pt-4">
            <div className="flex items-center gap-2">
              <input
                onChange={(e) => fullData?.id && setIsAccept(!isAccept)}
                id="accept"
                type="checkbox"
                className=" cursor-pointer accent-caspian-secondary-400"
              />
              <label
                htmlFor="accept"
                className="text-xs cursor-pointer font-bold"
              >
                آیا پروفایل را تایید می فرمائید ؟
              </label>
            </div>
            <div className="flex items-center gap-2">
              <button
                disabled={isAccept ? false : true}
                onClick={ApprovalConfirm}
                className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
              >
                ثبت و ارسال
              </button>
            </div>
          </div>
        )}
    </div>
  );
};

export default Profile;
