import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppContext } from "../../../../context/app-context/AppContext";
import { PiCheckCircleFill, PiTrash, PiXCircleFill } from "react-icons/pi";
import API from "../../../../api/API";
import { useContext, useState } from "react";
import { AuthContext } from "../../../../context/auth/AuthContext";
import MainProfile from "../profile/MainProfile";

const SingleTableListTeams = ({
  id,
  title,
  leader,
  coordinator,
  riskTeamSubjectType,
  setRefreshTeamsList,
  statusType,
}) => {
  const { token } = useContext(AuthContext);

  const [serachParams] = useSearchParams();
  const navigate = useNavigate();
  const { setBradCrump3 } = useAppContext();
  // const [showAction, setShowAction] = useState(true);
  const [showProfile, setShowProfile] = useState(false);
  const [showIsDelete, setShowIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const removeHandler = async () => {
    API.delete(`/api/v1/risk/team/detail/${id}/`, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        setRefreshTeamsList(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const viewTeam = async () => {
    setShowProfile(true);
  };

  // const mouseEnter = () => {
  //   setShowAction(true);
  // };
  // const mouseLeave = () => {
  //   setShowAction(false);
  //   setDeleteId(null);
  //   setShowIsDelete(false);
  // };

  // useEffect(() => {
  //   if (showProfile) setShowAction(false);
  // }, [showProfile]);
  return (
    <tr
      // onMouseEnter={mouseEnter}
      // onMouseLeave={mouseLeave}
      className="bg-caspian-surface duration-300"
    >
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[22.5%] cursor-pointer"
      >
        <h2>{title}</h2>
      </td>
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[18.55%] cursor-pointer"
      >
        <h2>{riskTeamSubjectType === "strategic" ? "راهبردی" : "عملیاتی"}</h2>
      </td>
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[22.5%] cursor-pointer"
      >
        <h2>{leader}</h2>
      </td>
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 w-[22.5%] cursor-pointer"
      >
        <h2>{coordinator}</h2>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 w-[15%]">
        <div className="flex items-center justify-between gap-2 pl-2">
          <span
            className={`${
              statusType === "selectSubject"
                ? "bg-caspian-secondary-50 border-caspian-secondary-600 text-caspian-secondary-500"
                : statusType === "ready"
                ? "bg-caspian-primary-50 border-caspian-primary-600 text-caspian-primary-500"
                : statusType === "selectMember" &&
                  "bg-caspian-secondary-50 border-caspian-secondary-600 text-caspian-secondary-500"
            }  py-0.5 px-1 rounded-md border text-xs`}
          >
            {statusType === "selectSubject"
              ? "انتخاب موضوع"
              : statusType === "ready"
              ? "تیم آماده"
              : statusType === "selectMember" && "انتخاب اعضا"}
          </span>
          {showProfile && (
            <MainProfile
              profileId={id}
              profileTitle={title}
              setShowProfile={setShowProfile}
              setRefreshTeamsList={setRefreshTeamsList}
            />
          )}
          {
            <div className="flex items-center gap-1">
              <div className="relative flex items-center justify-center">
                <button
                  onClick={async () => {
                    await setDeleteId(id);
                    await setShowIsDelete(true);
                  }}
                  className="text-caspian-error2 text-lg"
                >
                  <PiTrash />
                </button>
                <div
                  className={
                    id === deleteId && showIsDelete
                      ? "absolute left-0 top-0 bottom-0 flex items-center gap-0.5 w-fit bg-caspian-surface shadow-[0_0_5px_#00000050] p-0.5 rounded-md duration-500"
                      : "hidden duration-500"
                  }
                >
                  <PiCheckCircleFill
                    onClick={() => {
                      removeHandler(id);
                      setShowIsDelete(false);
                    }}
                    className="text-caspian-primary-600 text-lg cursor-pointer"
                  />
                  <PiXCircleFill
                    onClick={() => {
                      setShowIsDelete(false);
                      setDeleteId(null);
                    }}
                    className="text-caspian-gray text-lg cursor-pointer"
                  />
                </div>
              </div>
            </div>
          }
        </div>
      </td>
    </tr>
  );
};

export default SingleTableListTeams;
