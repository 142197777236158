import { PiCheckSquareOffsetBold } from "react-icons/pi";

const Knowledge = ({positionKnowledge}) => {
    return (
        <div className=" rounded-md">
            <div className="header flex basis-full divide-caspian-gray2 text-caspian-darkgray-600 text-sm xl:text-base">
                <div className="basis-1/12 p-4 text-center whitespace-nowrap">ردیف</div>
                <div className="basis-10/12 p-4 whitespace-nowrap">عنوان</div>
                {/* <div className="basis-3/12 p-4 whitespace-nowrap">گواهینامه  مورد نیاز است؟</div> */}
            </div>
            {
                positionKnowledge?.map((item,index)=>(
                    <div key={item?.id} className="body flex basis-full divide-caspian-gray2 border-t border-caspian-darkgray-100 text-xs xl:text-sm">
                        <div className="basis-1/12 p-4 text-center">{index+1}</div>
                        <div className="basis-10/12 p-4">{item?.title}</div>
                        {/* <div className="basis-3/12 p-4 flex justify-center">{item?.is_certificate && <PiCheckSquareOffsetBold className="text-xl xl:text-2xl"/> }</div> */}
                    </div>
                ))
            }
            {/* <div className="body flex basis-full divide-caspian-darkgray-100 border-t border-caspian-darkgray-100">
                <div className="basis-1/12 p-4 text-center">1</div>
                <div className="basis-8/12 p-4">دوره میمزی ایزو 9001</div>
                <div className="basis-3/12 p-4 flex justify-center"> <PiCheckSquareOffsetBold className="text-xl xl:text-2xl"/> </div>
            </div>
            <div className="body flex basis-full divide-caspian-darkgray-100 border-t border-caspian-darkgray-100">
                <div className="basis-1/12 p-4 text-center">2</div>
                <div className="basis-8/12 p-4">دوره میمزی ایزو 5001</div>
                <div className="basis-3/12 p-4 flex justify-center">  </div>
            </div> */}
        </div>
    );
}

export default Knowledge;
