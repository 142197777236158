import React, { useEffect, useRef, useState } from 'react'
import TooltipObjectiveProfileGoal from './TooltipObjectiveProfileGoal'

const SingleObjectiveProfileGoal = ({ desc, title, shape }) => {
    const [showDesc, setShowDesc] = useState(false)

    let timer;
    const mouseEnter = () => {
        timer = setTimeout(() => {
            setShowDesc(true);
        }, 1000);
    };
    const mouseLeave = () => {
        clearTimeout(timer);
        setShowDesc(false);
    };

    return (
        <div className='flex flex-col w-full'>
            <div className='flex items-stretch w-full gap-2'>
                <div className='basis-[15%] xl:basis-[12%] flex  items-center justify-center border-y-2 border-caspian-gray2'>
                    <span className={`basis-1/4 pr-2 text-xs 2xl:text-xl font-black
                        ${shape === "S" && 'text-caspian-purple-600'} ${shape === "M" && 'text-caspian-green-600'}  ${shape === "A" && 'text-caspian-brown-600'} ${shape === "R" && 'text-caspian-blue-600'} ${shape === "T" && 'text-caspian-darkblue-600'}`}>
                        {shape}
                    </span>
                    <span className={`basis-[85%] xl:basis-[88%]  text-xs 2xl:text-base w-full whitespace-nowrap
                        ${shape === "S" && 'text-caspian-purple-600'} ${shape === "M" && 'text-caspian-green-600'}  ${shape === "A" && 'text-caspian-brown-600'} ${shape === "R" && 'text-caspian-blue-600'} ${shape === "T" && 'text-caspian-darkblue-600'}`}>
                        {title}
                    </span>
                </div>

                <div
                    className={`basis-[85%] xl:basis-[88%] p-3 min-h-[44px] transition-all delay-700 duration-700 relative flex cursor-pointer justify-between items-center h-full'
                        ${shape === "S" && 'bg-caspian-purple-300'}
                        ${shape === "M" && 'bg-caspian-green-300'} 
                        ${shape === "A" && 'bg-caspian-brown-300'}
                        ${shape === "R" && 'bg-caspian-blue-300'}
                        ${shape === "T" && 'bg-caspian-darkblue-300'}`}
                    onMouseEnter={mouseEnter}
                    onMouseLeave={mouseLeave}>
                    <p className='text-xs leading-5 xl:text-sm text-caspian-on_primary/80 '>
                        {desc?.length > 0 ?
                            !showDesc ? `${desc?.slice(0, 150)}${desc?.length > 150 ? '...' : ""}` : desc?.slice(0, 150)
                            : '-'
                        }

                        {showDesc && desc.length > 150 &&
                            <TooltipObjectiveProfileGoal shape={shape}>
                                {desc}
                            </TooltipObjectiveProfileGoal>
                        }
                    </p>
                </div>
            </div>
        </div>
    )
}

export default SingleObjectiveProfileGoal