import React from "react";

const FeatureBox = ({ title, dataTitle, icon }) => {
  return (
    <div className="flex items-center gap-2">
      <div className="flex items-center gap-3">
        <span className="text-caspian-gray text-xl">{icon}</span>
        <span className="text-caspian-gray text-sm">{title}:</span>
      </div>
      <span className="text-caspian-secondary-900 text-sm">{dataTitle}</span>
    </div>
  );
};

export default FeatureBox;
