const ButtonTab = ({ title, onClick, activeTab, active }) => {
  return (
    <button
      // onClick={onClick}
      className="flex flex-col gap-2 items-center justify-center cursor-default min-w-[60px] relative"
    >
      <div
        className={`${
          activeTab === active
            ? "bg-caspian-secondary-500 rounded-full w-3 h-3"
            : "bg-caspian-gray2 rounded-full w-3 h-3"
        } `}
      ></div>
      <span
        className={`${
          activeTab === active
            ? "text-caspian-secondary-500 text-xs"
            : "text-caspian-gray2 text-xs"
        } absolute top-5 left-0 right-0`}
      >
        {title}
      </span>
    </button>
  );
};

export default ButtonTab;
