import React from "react";

const TimeChart = ({ startDatePmo, endDatePmo, startDate, endDate }) => {
  const startDatePmoValue = new Date(startDatePmo).valueOf();
  const endDatePmoValue = new Date(endDatePmo).valueOf();
  const startDateValue = new Date(startDate).valueOf();
  const endDateValue = new Date(endDate).valueOf();
  return (
    <div className="flex flex-col gap-2 w-full">
      {/* بازه زمان تخمینی PMO */}
      <div className="flex items-center gap-2 w-full justify-between">
        <div className="basis-[75%] h-1.5 bg-caspian-gray2/00 rounded-full">
          {startDatePmoValue > startDateValue &&
            endDatePmoValue < endDateValue && (
              <div className=" h-1.5 flex items-center justify-center gap-2">
                <span className="text-xs text-caspian-gray4">
                  {new Date(endDatePmoValue).toLocaleDateString("fa-IR")}
                </span>
                <div className="w-[50%] flex items-center gap-2 h-1.5 bg-caspian-primary-500 rounded-full"></div>
                <span className="text-xs text-caspian-gray4">
                  {new Date(startDatePmoValue).toLocaleDateString("fa-IR")}
                </span>
              </div>
            )}
          {startDatePmoValue < startDateValue &&
            endDatePmoValue > endDateValue && (
              <div className=" h-1.5 flex items-center justify-center gap-2">
                <span className="text-xs text-caspian-gray4">
                  {new Date(endDatePmoValue).toLocaleDateString("fa-IR")}
                </span>
                <div className="w-[70%] flex items-center gap-2 h-1.5 bg-caspian-primary-500 rounded-full"></div>
                <span className="text-xs text-caspian-gray4">
                  {new Date(startDatePmoValue).toLocaleDateString("fa-IR")}
                </span>
              </div>
            )}
          {startDatePmoValue < startDateValue &&
            endDatePmoValue < endDateValue && (
              <div className="mr-10 h-1.5 flex items-center justify-center gap-2">
                <span className="text-xs text-caspian-gray4">
                  {new Date(endDatePmoValue).toLocaleDateString("fa-IR")}
                </span>
                <div className="w-[60%] flex items-center gap-2 h-1.5 bg-caspian-primary-500 rounded-full"></div>
                <span className="text-xs text-caspian-gray4">
                  {new Date(startDatePmoValue).toLocaleDateString("fa-IR")}
                </span>
              </div>
            )}
          {startDatePmoValue > startDateValue &&
            endDatePmoValue > endDateValue && (
              <div className="ml-10 h-1.5 flex items-center justify-center gap-2">
                <span className="text-xs text-caspian-gray4">
                  {new Date(endDatePmoValue).toLocaleDateString("fa-IR")}
                </span>
                <div className="w-[60%] flex items-center gap-2 h-1.5 bg-caspian-primary-500 rounded-full"></div>
                <span className="text-xs text-caspian-gray4">
                  {new Date(startDatePmoValue).toLocaleDateString("fa-IR")}
                </span>
              </div>
            )}
          {startDatePmoValue === startDateValue &&
            endDatePmoValue === endDateValue && (
              <div className=" h-1.5 flex items-center justify-center gap-2">
                <span className="text-xs text-caspian-gray4">
                  {new Date(endDatePmoValue).toLocaleDateString("fa-IR")}
                </span>
                <div className="w-[60%] flex items-center gap-2 h-1.5 bg-caspian-primary-500 rounded-full"></div>
                <span className="text-xs text-caspian-gray4">
                  {new Date(startDatePmoValue).toLocaleDateString("fa-IR")}
                </span>
              </div>
            )}
          {startDatePmoValue === startDateValue &&
            endDatePmoValue > endDateValue && (
              <div className="ml-4 h-1.5 flex items-center justify-end gap-2">
                <span className="text-xs text-caspian-gray4">
                  {new Date(endDatePmoValue).toLocaleDateString("fa-IR")}
                </span>
                <div className="w-[70%] flex items-center gap-2 h-1.5 bg-caspian-primary-500 rounded-full"></div>
                <span className="text-xs text-caspian-gray4">
                  {new Date(startDatePmoValue).toLocaleDateString("fa-IR")}
                </span>
              </div>
            )}
          {startDatePmoValue === startDateValue &&
            endDatePmoValue < endDateValue && (
              <div className="ml-4 h-1.5 flex items-center justify-end gap-2">
                <span className="text-xs text-caspian-gray4">
                  {new Date(endDatePmoValue).toLocaleDateString("fa-IR")}
                </span>
                <div className="w-[60%] flex items-center gap-2 h-1.5 bg-caspian-primary-500 rounded-full"></div>
                <span className="text-xs text-caspian-gray4">
                  {new Date(startDatePmoValue).toLocaleDateString("fa-IR")}
                </span>
              </div>
            )}
            {startDatePmoValue > startDateValue &&
            endDatePmoValue === endDateValue && (
              <div className="mr-4 h-1.5 flex items-center justify-start gap-2">
                <span className="text-xs text-caspian-gray4">
                  {new Date(endDatePmoValue).toLocaleDateString("fa-IR")}
                </span>
                <div className="w-[60%] flex items-center gap-2 h-1.5 bg-caspian-primary-500 rounded-full"></div>
                <span className="text-xs text-caspian-gray4">
                  {new Date(startDatePmoValue).toLocaleDateString("fa-IR")}
                </span>
              </div>
            )}
          {startDatePmoValue < startDateValue &&
            endDatePmoValue === endDateValue && (
              <div className="mr-4 h-1.5 flex items-center justify-start gap-2">
                <span className="text-xs text-caspian-gray4">
                  {new Date(endDatePmoValue).toLocaleDateString("fa-IR")}
                </span>
                <div className="w-[70%] flex items-center gap-2 h-1.5 bg-caspian-primary-500 rounded-full"></div>
                <span className="text-xs text-caspian-gray4">
                  {new Date(startDatePmoValue).toLocaleDateString("fa-IR")}
                </span>
              </div>
            )}
        </div>
        <div className="basis-[25%] flex items-center gap-2">
          <div className="w-1.5 h-1.5 bg-caspian-primary-500 rounded-full"></div>
          <span className="text-xs">بازه زمانی تخمینی توسط PMO</span>
        </div>
      </div>
      {/* بازه زمان وظایف  */}
      <div className="flex items-center gap-2 w-full justify-between">
        <div className="basis-[75%] h-1.5 bg-caspian-gray2/00 rounded-full">
          {startDatePmoValue > startDateValue &&
            endDatePmoValue < endDateValue && (
              <div className=" h-1.5 flex items-center justify-center gap-2">
                <span className="text-xs text-caspian-gray4">
                  {new Date(endDateValue).toLocaleDateString("fa-IR")}
                </span>
                <div className="w-[70%] flex items-center gap-2 h-1.5 bg-caspian-secondary-500 rounded-full"></div>
                <span className="text-xs text-caspian-gray4">
                  {new Date(startDateValue).toLocaleDateString("fa-IR")}
                </span>
              </div>
            )}

          {startDatePmoValue < startDateValue &&
            endDatePmoValue > endDateValue && (
              <div className=" h-1.5 flex items-center justify-center gap-2">
                <span className="text-xs text-caspian-gray4">
                  {new Date(endDateValue).toLocaleDateString("fa-IR")}
                </span>
                <div className="w-[50%] flex items-center gap-2 h-1.5 bg-caspian-secondary-500 rounded-full"></div>
                <span className="text-xs text-caspian-gray4">
                  {new Date(startDateValue).toLocaleDateString("fa-IR")}
                </span>
              </div>
            )}
          {startDatePmoValue < startDateValue &&
            endDatePmoValue < endDateValue && (
              <div className="ml-10 h-1.5 flex items-center justify-center gap-2">
                <span className="text-xs text-caspian-gray4">
                  {new Date(endDateValue).toLocaleDateString("fa-IR")}
                </span>
                <div className="w-[60%] flex items-center gap-2 h-1.5 bg-caspian-secondary-500 rounded-full"></div>
                <span className="text-xs text-caspian-gray4">
                  {new Date(startDateValue).toLocaleDateString("fa-IR")}
                </span>
              </div>
            )}
          {startDatePmoValue > startDateValue &&
            endDatePmoValue > endDateValue && (
              <div className="mr-10 h-1.5 flex items-center justify-center gap-2">
                <span className="text-xs text-caspian-gray4">
                  {new Date(endDateValue).toLocaleDateString("fa-IR")}
                </span>
                <div className="w-[60%] flex items-center gap-2 h-1.5 bg-caspian-secondary-500 rounded-full"></div>
                <span className="text-xs text-caspian-gray4">
                  {new Date(startDateValue).toLocaleDateString("fa-IR")}
                </span>
              </div>
            )}
          {startDatePmoValue === startDateValue &&
            endDatePmoValue === endDateValue && (
              <div className=" h-1.5 flex items-center justify-center gap-2">
                <span className="text-xs text-caspian-gray4">
                  {new Date(endDateValue).toLocaleDateString("fa-IR")}
                </span>
                <div className="w-[60%] flex items-center gap-2 h-1.5 bg-caspian-secondary-500 rounded-full"></div>
                <span className="text-xs text-caspian-gray4">
                  {new Date(startDateValue).toLocaleDateString("fa-IR")}
                </span>
              </div>
            )}
          {startDatePmoValue === startDateValue &&
            endDatePmoValue > endDateValue && (
              <div className="ml-4 h-1.5 flex items-center justify-end gap-2">
                <span className="text-xs text-caspian-gray4">
                  {new Date(endDateValue).toLocaleDateString("fa-IR")}
                </span>
                <div className="w-[60%] flex items-center gap-2 h-1.5 bg-caspian-secondary-500 rounded-full"></div>
                <span className="text-xs text-caspian-gray4">
                  {new Date(startDateValue).toLocaleDateString("fa-IR")}
                </span>
              </div>
            )}
          {startDatePmoValue === startDateValue &&
            endDatePmoValue < endDateValue && (
              <div className="ml-4 h-1.5 flex items-center justify-end gap-2">
                <span className="text-xs text-caspian-gray4">
                  {new Date(endDateValue).toLocaleDateString("fa-IR")}
                </span>
                <div className="w-[70%] flex items-center gap-2 h-1.5 bg-caspian-secondary-500 rounded-full"></div>
                <span className="text-xs text-caspian-gray4">
                  {new Date(startDateValue).toLocaleDateString("fa-IR")}
                </span>
              </div>
            )}
            {startDatePmoValue > startDateValue &&
            endDatePmoValue === endDateValue && (
              <div className="mr-4 h-1.5 flex items-center justify-start gap-2">
                <span className="text-xs text-caspian-gray4">
                  {new Date(endDateValue).toLocaleDateString("fa-IR")}
                </span>
                <div className="w-[70%] flex items-center gap-2 h-1.5 bg-caspian-secondary-500 rounded-full"></div>
                <span className="text-xs text-caspian-gray4">
                  {new Date(startDateValue).toLocaleDateString("fa-IR")}
                </span>
              </div>
            )}
          {startDatePmoValue < startDateValue &&
            endDatePmoValue === endDateValue && (
              <div className="mr-4 h-1.5 flex items-center justify-start gap-2">
                <span className="text-xs text-caspian-gray4">
                  {new Date(endDateValue).toLocaleDateString("fa-IR")}
                </span>
                <div className="w-[60%] flex items-center gap-2 h-1.5 bg-caspian-secondary-500 rounded-full"></div>
                <span className="text-xs text-caspian-gray4">
                  {new Date(startDateValue).toLocaleDateString("fa-IR")}
                </span>
              </div>
            )}
        </div>

        <div className="basis-[25%] flex items-center gap-2">
          <div className="w-1.5 h-1.5 bg-caspian-secondary-500 rounded-full"></div>
          <span className="text-xs">بازه زمان وظایف این برنامه اجرایی</span>
        </div>
      </div>
    </div>
  );
};

export default TimeChart;
