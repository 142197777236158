import { useContext, useEffect, useRef, useState } from "react";
import { PiArrowsClockwise, PiCaretLeftBold, PiX } from "react-icons/pi";
import { AuthContext } from "../../../context/auth/AuthContext";
import API from "../../../api/API";
import { fromNowPersian } from "../../../helper/fromNowPersian";
import ChatBoxSender from "./ChatBoxSender";
import ChatBoxReciver from "./ChatBoxReciver";

const MainFavoritesChat = ({
  setFavoritesShow,
  setShowChatContent,
  setMemberSelected,
  setIsIdConversation,
  setIsIdUser,
  setRefreshMyconversation,
  groupName,
  setShowChatBox,
  favoritesRefresh,
  setFavoritesRefresh,
}) => {
  const { user, token } = useContext(AuthContext);
  const scrollRef = useRef();

  const [allMessages, setAllMessages] = useState([]);
  const [loadingGetMessage, setLoadingGetMessage] = useState(false);

  useEffect(() => {
    setFavoritesRefresh(1);
  }, [setFavoritesRefresh]);
  useEffect(() => {
    const messageHandler = async () => {
      if (!token || favoritesRefresh === 0) return;
      try {
        setLoadingGetMessage(true);
        const res = await API.get(`/api/v1/message/myfavMessages`, {
          headers: { Authorization: token },
        });
        console.log(res.data);
        setAllMessages(res?.data);
        setFavoritesRefresh(0);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingGetMessage(false);
      }
    };
    messageHandler();
  }, [token, favoritesRefresh, setFavoritesRefresh]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [allMessages]);

  return (
    <div className="min-w-[280px] max-w-[280px] min-h-[460px] max-h-[460px] bg-caspian-primary-50 rounded-md flex flex-col fixed bottom-16 left-28 z-40">
      <div className="flex items-center justify-between px-2 border-b border-b-caspian-gray2">
        <div className="flex items-center gap-4 py-2">
          <PiX
            onClick={() => {
              setFavoritesShow(false);
              setShowChatBox(false);
              setRefreshMyconversation(1);
            }}
            className="text-caspian-gray4 text-xl cursor-pointer"
          />

          <span className="text-xs text-caspian-gray4">ذخیره شده ها</span>
        </div>

        <div className="flex items-center gap-2">
          <PiArrowsClockwise
            onClick={() => setFavoritesRefresh(1)}
            className={`z-50 text-xl cursor-pointer text-caspian-gray4 ${
              loadingGetMessage ? "animate-spin" : ""
            }`}
          />
          <PiCaretLeftBold
            onClick={() => {
              setShowChatBox(true);
              setShowChatContent(false);
              setIsIdConversation(null);
              setIsIdUser(null);
              setRefreshMyconversation(1);
              setMemberSelected([]);
            }}
            className="text-caspian-gray4 cursor-pointer"
          />
        </div>
      </div>

      <div className="h-full w-full flex flex-col gap-0">
        <div className="h-[365px]">
          <div className="h-full overflow-y-scroll w-full p-2  pt-6 pb-4">
            {allMessages?.length > 0 ? (
              <div className="flex flex-col gap-2">
                {allMessages?.map((d, i) => (
                  <div ref={scrollRef} className="" key={i}>
                    {d?.sender?.user === user?.placementProfileRelated?.user ? (
                      <ChatBoxSender
                        isFav={d?.is_fav}
                        firstIndex={i}
                        messageId={d?.id}
                        setFavoritesRefresh={setFavoritesRefresh}
                        sender={
                          d?.sender?.firstName + " " + d?.sender?.lastName ||
                          d?.reciver
                        }
                        avatar={
                          API.defaults.baseURL +
                          d?.sender?.personalInfoProfileRelated[0]?.avatar
                        }
                        message={d?.body.replaceAll("فیلتر", "*****")}
                        date={fromNowPersian(new Date(d?.created))}
                      />
                    ) : (
                      <ChatBoxReciver
                        reciverMenu={true}
                        isFav={d?.is_fav}
                        firstIndex={i}
                        messageId={d?.id}
                        setFavoritesRefresh={setFavoritesRefresh}
                        reciver={
                          d?.sender?.firstName + " " + d?.sender?.lastName
                        }
                        avatar={
                          API?.defaults?.baseURL +
                          d?.sender?.personalInfoProfileRelated[0]?.avatar
                        }
                        message={d?.body?.replaceAll("فیلتر", "*****")}
                        date={fromNowPersian(new Date(d?.created))}
                      />
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex items-center justify-center pt-12 w-full">
                <h2 className="text-xs text-caspian-gray4">پیام وجود ندارد</h2>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainFavoritesChat;
