import React, { useEffect, useState } from "react";
import { PiCaretLeftBold } from "react-icons/pi";

const Description = ({
  description,
  showAll,
  hideAll,
  setShowAll,
  setHideAll,
}) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (showAll) {
      setShow(true);
    }
  }, [showAll]);
  useEffect(() => {
    if (hideAll) {
      setShow(false);
    }
  }, [hideAll]);
  useEffect(() => {
    setShowAll(false);
  }, [show, setShowAll]);
  useEffect(() => {
    setHideAll(false);
  }, [show, setHideAll]);
  return (
    <div className="w-full flex flex-col gap-0 border border-caspian-gray2 rounded-md overflow-hidden">
      <div
        onClick={() => setShow(!show)}
        className="bg-caspian-gray3 p-3 w-full border-b border-b-caspian-gray2 flex items-center justify-between cursor-pointer"
      >
        <h2 className="text-xs">توضیحات</h2>
        <PiCaretLeftBold
          className={show ? "-rotate-90 duration-500" : "rotate-0 duration-500"}
        />
      </div>
      {show && (
        <div className="p-3 w-full">
          <p className="text-xs text-caspian-gray4 text-justify leading-5">
            {description}
          </p>
        </div>
      )}
    </div>
  );
};

export default Description;
