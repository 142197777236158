import React from 'react';

const ChartComponent = () => {
    return (
        <div className='basis-2/3'>
            Chart Component
        </div>
    );
}

export default ChartComponent;
