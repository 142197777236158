import React, { useEffect, useState } from "react";
import { PiCaretLeftBold } from "react-icons/pi";

const Approvals = ({ fullData, showAll, hideAll, setShowAll, setHideAll }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (showAll) {
      setShow(true);
    }
  }, [showAll]);
  useEffect(() => {
    if (hideAll) {
      setShow(false);
    }
  }, [hideAll]);
  useEffect(() => {
    setShowAll(false);
  }, [show, setShowAll]);
  useEffect(() => {
    setHideAll(false);
  }, [show, setHideAll]);
  console.log(fullData);
  return (
    <div className="w-full flex flex-col gap-0 border border-caspian-gray2 rounded-md overflow-hidden">
      <div
        onClick={() => setShow(!show)}
        className="bg-caspian-gray3 p-3 w-full border-b border-b-caspian-gray2 flex items-center justify-between cursor-pointer"
      >
        <h2 className="text-xs">مصوبات</h2>
        <PiCaretLeftBold
          className={show ? "-rotate-90 duration-500" : "rotate-0 duration-500"}
        />
      </div>
      {show && (
        <div className="w-full flex flex-col gap-0">
          {fullData.length < 1 ? (
            <span className="text-xs text-caspian-gray p-3">مصوبه ای ثبت نشده است!</span>
          ) : (
            fullData.map((d, i) => (
              <div
                key={i}
                className={`flex flex-col gap-0 ${
                  fullData.length > 0 &&
                  "last:border-none border-b-2 border-b-caspian-secondary-600"
                }`}
              >
                <div className="flex flex-col gap-2 border-b border-b-caspian-gray2 p-3">
                  <h2 className="text-xs">شرح مصوبه {d.title}</h2>
                  <p className="text-xs text-caspian-gray mr-2 text-justify">
                    {d.description}
                  </p>
                </div>
                {/* مسئول اجرا */}
                <div className="flex items-center gap-2 border-b border-b-caspian-gray2 p-3">
                  <span className="text-xs text-caspian-secondary-600">
                    مسئول اجرا:
                  </span>
                  <span className="text-xs">
                    {d?.responsibleExecutive?.firstName +
                      " " +
                      d?.responsibleExecutive?.lastName}
                  </span>
                </div>
                {/* پیگیری کننده */}
                <div className="flex items-center gap-2 border-b border-b-caspian-gray2 p-3">
                  <span className="text-xs text-caspian-secondary-600">
                    پیگیری کننده:
                  </span>
                  <span className="text-xs">
                    {d?.responsibleFollow?.firstName +
                      " " +
                      d?.responsibleFollow?.lastName}
                  </span>
                </div>
                {/* خروجی */}
                <div className="flex items-center gap-2 p-3">
                  <span className="text-xs text-caspian-secondary-600">
                    خروجی:
                  </span>
                  <span className="text-xs">{d?.outputRelated?.title}</span>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default Approvals;
