import Details from "./Details";

const MainProfile = ({ data }) => {
  return (
    <div className="flex items-center justify-center w-full h-auto rounded-xl bg-caspian-surface shadow-[0_0_5px_#00000010] border border-caspian-gray2 p-4">
      <Details data={data} />
    </div>
  );
};

export default MainProfile;
