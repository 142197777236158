import React, { useContext, useEffect, useState } from 'react';
import StepOne from './step-one/StepOne';
import StepTwo from './step-two/StepTwo';
import Modal from '../../../../modal/Modal';
import { PiCircleNotch, PiX } from 'react-icons/pi';
import MainGuidList from './guid-list/MainGuidList';
import API from '../../../../../api/API';
import { AuthContext } from '../../../../../context/auth/AuthContext';
import Loading from '../../../../../Loading';
import DeleteAlertBox from './alert-box/DeleteAlertBox';

const MainCreateFormQuestion = ({ setShowModal, setRefresh }) => {
    const { token } = useContext(AuthContext);
    const [activeStep, setActiveStep] = useState('one');
    const [idPost, setIdPost] = useState('');
    const [standard, setStandard] = useState("");
    const [standardSelected2, setStandardSelected2] = useState(null);
    const [standard2, setStandard2] = useState(standard);
    const [showAlert, setShowAlert] = useState(false);
    const [loadingAlert, setLoadingAlert] = useState(false);
    const [q, setQ] = useState(0);

    const [showSection, setShowSection] = useState(false);
    // const [sectionSelected, setSectionSelected] = useState(null);
    const [activitiesSelected2, setActivitiesSelected2] = useState([]);
    const [activitiesFinalSelected2, setActivitiesFinalSelected2] =
        useState(activitiesSelected2);
    const [list3, setList3] = useState([]);
    const [final, setFinal] = useState(list3);
    console.log('selected', activitiesFinalSelected2);
    const [all, setAll] = useState(false);

    //edit question state
    const [edit,setEdit]=useState(false);

    const handleClick = async (id) => {
        console.log(id);
        if (!id) {
            setShowModal(false)
            return
        }
        else {
            setShowAlert(true)
            console.log(showAlert);
        }
    }
    const handleDelete = async (id) => {
        setLoadingAlert(true);
        try {
            const res = await API.delete(`/api/v1/audit/question/detial/${id}/`, { headers: { Authorization: token } });
            setRefresh(true);
            if (!res?.data) {
                setLoadingAlert(false);
                setShowModal(false);
            }
        } catch (error) {
            console.log(error);
        }
    }
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    console.log(windowHeight);
    return (
        <div className='w-full h-full bg-caspian-on_primary/30 fixed inset-0 z-[100] flex justify-center items-center py-2'>

            <div className='flex flex-col gap-0 bg-caspian-surface w-[95%] min-h-[95vh] h-full 2xl:w-[80%] rounded-lg'
            // style={{height:'auto'}}
            >

                {
                    showAlert && <DeleteAlertBox loadingAlert={loadingAlert}
                        setShowAlert={setShowAlert}
                        handleDelete={handleDelete}
                        idPost={idPost} />
                }
                <div className='flex justify-between items-center border-b border-caspian-gray2 px-5 py-2'>
                    <p className='font-semibold text-sm 2xl:text-base'>تعریف سوال</p>
                    <button className='p-0 text-caspian-gray4' onClick={() => handleClick(idPost)}>
                        <PiX className='text-lg' />
                    </button>
                </div>

                <div className='flex w-full h-full'>
                    <div className='basis-3/4 flex flex-col h-full border-l  border-caspian-gray'>
                        <StepOne activeStep={activeStep} setActiveStep={setActiveStep} idPost={idPost}
                            setRefresh={setRefresh} setIdPost={setIdPost} standard={standard} setStandard={setStandard}
                            q={q} setQ={setQ}
                            setStandard2={setStandard2}
                            setStandardSelected2={setStandardSelected2} standardSelected2={standardSelected2}
                            edit={edit} setEdit={setEdit}
                            />
                        <StepTwo
                            setShowModal={setShowModal}
                            loadingAlert={loadingAlert}
                            idPost={idPost} handleDelete={handleDelete}
                            q={q} setQ={setQ}
                            setRefresh={setRefresh}
                            showSection={showSection} setShowSection={setShowSection}
                            activitiesSelected2={activitiesSelected2}
                            setActivitiesSelected2={setActivitiesSelected2}
                            activitiesFinalSelected2={activitiesFinalSelected2}
                            setActivitiesFinalSelected2={setActivitiesFinalSelected2}
                            list3={list3} setList3={setList3} final={final} setFinal={setFinal}
                            all={all} setAll={setAll}
                            edit={edit} setEdit={setEdit}
                        />
                    </div>
                    <div className='basis-1/4 flex flex-col'>
                        <MainGuidList standard2={standard2} setStandard2={setStandard2}
                            setStandardSelected2={setStandardSelected2} standardSelected2={standardSelected2} />
                    </div>
                </div>


            </div>
        </div>
    );
}

export default MainCreateFormQuestion;
