import { useContext, useEffect, useState } from "react";
import Modal from "../../../../modal/Modal";
import { PiCheckSquareBold, PiSquare, PiX } from "react-icons/pi";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import API from "../../../../../api/API";

const ListRiskSubjectProcess = ({
  setShowListRiskSubject,
  profileId,
  processFinalSelected,
  setProcessFinalSelected,
  processSelected,
  setProcessSelected,
}) => {
  const { token } = useContext(AuthContext);

  const [processData, setProcessData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getProcess = async () => {
      if (!token) return;

      setLoading(true);
      try {
        const res = await API.get(`/api/v1/businessProcess/group/`, {
          headers: { Authorization: token },
        });
        console.log(res?.data);
        setProcessData(res?.data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    getProcess();
  }, [token]);

  const processFinalHandler = () => {
    if (processSelected?.length > 0) {
      setProcessFinalSelected(processSelected);
      setShowListRiskSubject(false);
    }
  };

  console.log(processSelected);
  console.log(processFinalSelected);

  return (
    <Modal>
      <div className="flex flex-col gap-0 min-w-[400px] z-50 bg-caspian-surface rounded-md shadow-[0_0_5px_#00000050] overflow-hidden">
        <div className="flex items-center justify-between w-full p-2 border-b border-b-caspian-gray2">
          <span className="text-xs text-caspian-gray font-bold">
            لیست فرایندهای مرتبط
          </span>
          <PiX
            onClick={() => {
              setShowListRiskSubject(false);
              setProcessSelected(processFinalSelected)
            }}
            className="cursor-pointer"
          />
        </div>
        <div className="max-h-[360px] min-h-[360px] overflow-y-scroll cursor-pointer">
          {processData?.map((p, i) => (
            <div
              onClick={() => {
                !processSelected.includes(p?.id)
                  ? setProcessSelected([...processSelected, p?.id])
                  : setProcessSelected(
                      processSelected.filter((d) => d !== p?.id)
                    );
              }}
              key={i}
              className="flex items-center gap-1 w-full even:bg-caspian-gray2/30 p-2"
            >
              {processSelected.includes(p?.id) ? (
                <PiCheckSquareBold className="text-xl text-caspian-primary-500" />
              ) : (
                <PiSquare className="text-xl text-caspian-primary-500" />
              )}
              <span className="text-xs text-caspian-gray4 w-full">
                {p?.title}
              </span>
              <span className="text-xs text-caspian-gray4 mr-auto">
                {p?.groupCode}
              </span>
            </div>
          ))}
        </div>
        <div className="p-2 border-t border-t-caspian-gray2">
          <button
            onClick={processFinalHandler}
            disabled={processSelected.length < 1 ? true : false}
            className="bg-caspian-secondary-500 text-xs rounded-md text-caspian-surface py-1.5 px-4 disabled:bg-caspian-gray2 disabled:text-caspian-gray4"
          >
            تایید
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ListRiskSubjectProcess;
