import { useState } from 'react';
import { PiMagnifyingGlassBold } from "react-icons/pi";

const SearchList = ({ groupRelatedStackholder }) => {
    const [search, setSearch] = useState('');
    let newData = [-1];
    newData = search ? groupRelatedStackholder.filter(item => item.title.includes(search)) : groupRelatedStackholder;
    return (
        <>
            <div className="relative">
                <PiMagnifyingGlassBold className='absolute -translate-y-1/2 top-1/2 right-2 text-caspian-gray2 text-xl' />
                <input type="search"
                    className='text-xs 2xl:text-base w-full border border-caspian-gray2 rounded-md placeholder:text-xs placeholder:text-caspian-gray px-8 py-2 focus:outline-none'
                    name="search" id="search"
                    placeholder='جستجو'
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>


            <ol className="list-decimal list-inside overflow-hidden overflow-y-scroll max-h-full flex flex-col gap-2">

                {
                    newData[0] === -1 ? (<p className='px-8'>داده ای وجود ندارد</p>)
                        : newData.length < 1 ? (
                            <p className='px-8'>یافت نشد</p>
                        ) : (newData.map((item, index) => <li key={index} title={item.title} className='cursor-pointer pb-1'>
                            <span>
                                {item?.title?.length > 20 ? (item?.title.slice(0, 20) + "...") : (item?.title)}
                            </span>
                        </li>))
                }
            </ol>
        </>
    );
}

export default SearchList;
