import React from 'react'
import SingListPopUpModelStackholder from './SingListPopUpModelStackholder';

const SinglePopUpModelStackholder = ({ sectionData, categoryData }) => {
    return (
        <div className='flex flex-col w-full gap-2'>
            {sectionData?.map((section, index) => (
                <SingListPopUpModelStackholder categoryData={categoryData} key={index} section={section} />
            ))}
            
        </div>
    )
}

export default SinglePopUpModelStackholder