import React, { useContext, useState } from "react";
import TabSideProfile from "./TabSideProfile";
import MainPlanningMeetingForm from "../../../../forms/planinng-meeting/MainPlanningMeetingForm";
import { AuthContext } from "../../../../../../context/auth/AuthContext";

const SideProfileDetails = ({ fullData, setMeetingListRefresh }) => {
  const { user } = useContext(AuthContext);
  const [showPlanningMeetingForm, setShowPlanningMeetingForm] = useState(false);
  return (
    <div className="flex flex-col gap-2">
      {fullData?.secretary?.placementProfileRelated?.user ===
        user?.placementProfileRelated?.user && (
        <button
          onClick={() => setShowPlanningMeetingForm(true)}
          className="text-xs text-caspian-secondary-500 border border-caspian-secondary-500 rounded-md p-2 w-full"
        >
          برنامه ریزی برگزاری جلسه
        </button>
      )}
      {showPlanningMeetingForm && (
        <MainPlanningMeetingForm
          setMeetingListRefresh={setMeetingListRefresh}
          meetingProfile={{ id: fullData?.id, title: fullData?.title }}
          setShowPlanningMeetingForm={setShowPlanningMeetingForm}
          showPlanningMeetingForm={showPlanningMeetingForm}
        />
      )}
      <div className="bg-caspian-surface rounded-md shadow-[0_0_2px_#00000050]">
        <TabSideProfile fullData={fullData} />
      </div>
    </div>
  );
};

export default SideProfileDetails;
