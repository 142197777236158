import { useContext, useEffect, useState } from 'react';
import StackholdersNavbar from "../StackholdersNavbar";
import TopProfile from "./rightcomponent/TopProfile";
import BottomProfile from "./rightcomponent/BottomProfile";
import { useNavigate, useParams } from 'react-router-dom';
import API from '../../../api/API';
import Loading from '../../../Loading';
import MainRightComponent from './rightcomponent/MainRightComponent';
import StackholderTab from './leftcomponent/StackholderTab';
import { PiCaretLeft } from "react-icons/pi";
import { AuthContext } from '../../../context/auth/AuthContext';

const MainProfileStackholders = () => {
  const {token}=useContext(AuthContext);
  const [data, setData] = useState([-1]);
  const { id } = useParams();
  const navigate=useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      if (!id || !token) return
      try {
        const res = await API.get(`/api/v1/stackholder/group/${id}/`,{headers:{Authorization:token}})
        setData(res.data)
        console.log(res.data);
      }
      catch (err) {
        console.log('error message:', err.message);
        if (err.response.status === 404) {
          console.log('my error');
          setData([])
        }
      }
    }
    fetchData();
  }, [id,token])

  return (
    <>

      <StackholdersNavbar buttonActive="profile" />
      <div className='flex flex-col gap-2 pt-3'>
        <button className='flex items-center ml-3 mr-auto text-caspian-primary-600' onClick={() =>{ data.length < 1 ? navigate('/stackholders/list') : navigate(-1) }}>
          <span>بازگشت</span>
          <PiCaretLeft />
        </button>
        {
          data[0] === -1 ? (<Loading />) : data?.length < 1 ? (<div className='flex py-24 justify-center items-center'>

            <p className='text-caspian-gray4 text-xs 2xl:text-sm text-center font-bold'>پروفایل موردنظر موجود نمی باشد.</p>

          </div>) : (
            <div className="flex justify-center gap-3 p-3">
              <div className='w-[70%]'>
                <MainRightComponent data={data} />
              </div>
              <div className='min-w-[30%] w-[25%]'>
                <StackholderTab data={data} />
              </div>
            </div>
          )
        }
      </div>
    </>

  );
};

export default MainProfileStackholders;
