import React, { useContext, useRef, useState } from 'react';
import Modal from '../../modal/Modal';
import { PiCircleNotch, PiPlusCircle, PiX } from 'react-icons/pi';
import DateForm from './DateForm';
import API from '../../../api/API';
import { AuthContext } from '../../../context/auth/AuthContext';

const ArticleForms = ({ setShowForm, setType,setShowArticle }) => {
    const {token}=useContext(AuthContext);
    const refTag = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [subject, setSubject] = useState('');
    const [creator, setCreator] = useState('');
    const [startDate, setStartDate] = useState('');
    const [refrence, setRefrence] = useState('');
    const [source, setSource] = useState('');
    const [lang, setLang] = useState('');
    const [translator, setTranslator] = useState('');
    const [tag, setTag] = useState('');
    const [tagList, setTagList] = useState([]);
    const [desc, setDesc] = useState('');
    const [files, setFiles] = useState([]);
    console.log('tag', tag);
    const handleTag = (e) => {
        if (e.key === 'Enter') {
            if (e.target.value === '') return
            setTagList([...tagList, e.target.value]);
            refTag.current.value = '';
            refTag.current.focus();
        }
    }
    const removeTag = (index) => {
        const item = tagList?.filter((t, i) => i !== index);
        if (item?.length < 1) {
            setTag('');
        }
        setTagList(item);
    }
    const closeBtn = () => {
        setType('');
        setShowArticle(false);
        setShowForm('');
    }
    const handleUpload = (event) => {
        const uploadFiles = event.target.files;
        if (uploadFiles.length > 0) {
            const newFiles = Array.from(uploadFiles);
            setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        }
    }
    const handleRemoveFile = (i) => {
        // console.log(i);
        const item = files?.filter((f, index) => index !== i);
        setFiles(item);
    }
    const handleSubmit = async () => {
        if (!token) return
        let formData={
            tags:tagList,
            creationDate:startDate,
            title,
            subject,
            language:lang,
            translator,
            creator:creator,
            description:desc,
            source,
            typeLibrary:'article'
        }
        setIsLoading(true);
        try {
            const res=await API.post('/api/v1/library/',formData,{headers:{Authorization:token}})
            if(res?.data.id){
                console.log(res?.data);
                await handleImg(res?.data?.id);
                // setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }
    const handleImg=async(id)=>{
        if(!id) return
        let formData={
            imagesDocumentLibraryRelated:id,
            image:files[0]
        }
        console.log(formData);
        try {
            const res=await API.post('/api/v1/library/images/',formData,{
                headers: {
                    Authorization: token,
                    "Content-Type": "multipart/form-data",
                    Accept: "application/json"
                }
            })
            if(res?.data){
                setIsLoading(false)
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false)
        }
    }
    return (
        <Modal>
            <div className='bg-caspian-surface w-[50%] rounded-md pb-2'>
                <div className='flex justify-between items-center p-2'>
                    <span className='text-xs xl:text-sm'>
                        لطفا اطلاعات زیر را وارد کنید.
                    </span>
                    <button
                        disabled={isLoading}
                        onClick={closeBtn} >
                        <PiX />
                    </button>
                </div>
                <div className='flex flex-col gap-1 xl:gap-1.5 px-4'>
                    <div class="flex flex-col gap-1 xl:gap-2">
                        <label htmlFor="floating_outlined" className='text-caspian-gray4 text-xs'>عنوان:</label>
                        <input type="text" id="floating_outlined" className='text-xs placeholder:text-xs border border-caspian-gray2 p-2 rounded-lg' placeholder=" عنوان مقاله را وارد کنید"
                            onChange={(e) => { setTitle(e.target.value) }} />
                    </div>

                    {/* title */}
                    {/* <div className='relative'>
                        <input type="text"
                            id='t1'
                            // placeholder='عنوان'
                            className={`${title ? "border border-[#0A66C2]" : ""} border border-caspian-gray p-[9px] placeholder:text-sm text-xs xl:placeholder:text-xs xl:text-sm w-full rounded focus:border focus:outline-none focus:border-[#0A66C2] peer`}
                            onChange={(e) => { setTitle(e.target.value) }}
                        />
                        <label
                            htmlFor='t1'
                            className={`${title ? "px-2 text-[#0A66C2] absolute top-0 -translate-y-1/2 text-sm right-2" : ""} text-xs text-caspian-gray bg-caspian-surface absolute  -translate-y-1/2 right-2 peer-focus:top-0 px-2 peer-focus:text-[#0A66C2]`}>
                            عنوان
                        </label>
                    </div> */}
                    {/* subject */}
                    <div className='flex flex-col gap-1 xl:gap-2'>
                        <label htmlFor="t2" className='text-caspian-gray4 text-xs'>موضوع:</label>
                        <input type="text"
                            id='t2'
                            onChange={(e) => setSubject(e.target.value)}
                            placeholder=' موضوع مقاله را وارد کنید'
                            className='text-xs placeholder:text-xs border border-caspian-gray2 p-1 xl:p-2 rounded-lg' />

                    </div>
                    {/* creator and date */}
                    <div className='flex justify-between items-center gap-4'>
                        <div className='basis-1/2 flex flex-col gap-1 xl:gap-2'>
                            <label htmlFor="t3" className='text-caspian-gray4 text-xs'>پدیدآورنده:</label>
                            <input type="text"
                                id='t3'
                                onChange={(e) => setCreator(e.target.value)}
                                placeholder='پدیدآورنده مقاله را وارد کنید '
                                className='text-xs placeholder:text-xs border border-caspian-gray2 p-1 xl:p-2 rounded-lg' />

                        </div>

                        <div className='basis-1/2 flex flex-col gap-1 xl:gap-2'>
                            <label htmlFor="t3" className='text-caspian-gray4 text-xs'>تاریخ نگارش:</label>
                            <DateForm setDate={setStartDate} />
                        </div>
                    </div>
                    {/* refrence and source */}
                    <div className='flex justify-between items-center gap-4'>
                        <div className='basis-1/2 flex flex-col gap-1 xl:gap-2'>
                            <label htmlFor="t4" className='text-caspian-gray4 text-xs'>مرجع:</label>
                            <input type="text"
                                id='t4'
                                onChange={(e) => setRefrence(e.target.value)}
                                placeholder=' مرجع مقاله را وارد کنید'
                                className='text-xs placeholder:text-xs border border-caspian-gray2 p-1 xl:p-2 rounded-lg' />

                        </div>
                        <div className='basis-1/2 flex flex-col gap-1 xl:gap-2'>
                            <label htmlFor="t5" className='text-caspian-gray4 text-xs'>منبع:</label>
                            <input type="text"
                                id='t5'
                                onChange={(e) => setSource(e.target.value)}
                                placeholder=' منبع مقاله را وارد کنید'
                                className='text-xs placeholder:text-xs border border-caspian-gray2 p-1 xl:p-2 rounded-lg' />

                        </div>
                    </div>
                    {/* language and translator */}
                    <div className='flex justify-between items-center gap-4'>
                        <div className='basis-1/2 flex flex-col gap-1 xl:gap-2'>
                            <label htmlFor="t6" className='text-caspian-gray4 text-xs'>زبان:</label>
                            <input type="text"
                                id='t6'
                                onChange={(e) => setLang(e.target.value)}
                                placeholder=' زبان مقاله را وارد کنید'
                                className='text-xs placeholder:text-xs border border-caspian-gray2 p-1 xl:p-2 rounded-lg' />

                        </div>
                        <div className='basis-1/2 flex flex-col gap-1 xl:gap-2'>
                            <label htmlFor="t7" className='text-caspian-gray4 text-xs'>مترجم:</label>
                            <input type="text"
                                id='t7'
                                onChange={(e) => setTranslator(e.target.value)}
                                placeholder=' نام مترجم مقاله را وارد کنید'
                                className='text-xs placeholder:text-xs border border-caspian-gray2 p-1 xl:p-2 rounded-lg' />

                        </div>
                    </div>
                    {/* tag */}
                    <div className='flex flex-col gap-1 xl:gap-2'>
                        <label htmlFor="t8" className='text-caspian-gray4 text-xs'>کلیدواژه‌ها:</label>
                        <div className='border border-caspian-gray1 rounded-lg p-1 xl:p-1.5'>
                            <input type="text"
                                id='t8'
                                placeholder='کلیدواژه‌ها را وارد کنید'
                                ref={refTag}
                                onKeyUp={(e) => handleTag(e)}
                                onChange={(e) => setTag(e.target.value)}
                                className='text-xs w-full focus:outline-none ' />

                            {/* <div className='flex gap-2 items-center'>
                        <label htmlFor="t8" className='text-caspian-gray4 text-xs'>کلیدواژه‌ها:</label>
                        <input type="text"
                            id='t8'
                            ref={refTag}
                            onKeyUp={(e) => handleTag(e)}
                            onChange={(e) => setTag(e.target.value)}
                            className='text-xs w-full p-1.5 focus:outline-none '/>
                        </div> */}

                            <div className='h-10 px-2 overflow-y-auto flex flex-wrap gap-2 items-center text-xs'>
                                {
                                    tagList?.map((t, index) => <div key={index} className='flex rounded-full border border-[#0A66C2] bg-[#DAE8F6] text-[#0A66C2]
                                text-xs p-1.5 pb-0 gap-2 items-start'>
                                        <span>{t}</span>
                                        <PiX onClick={() => removeTag(index)} className='cursor-pointer text-xs' />
                                    </div>)
                                }
                            </div>
                        </div>
                    </div>
                    {/* description */}
                    <div className='flex flex-col gap-1 xl:gap-2'>
                        <label htmlFor="t9" className='text-caspian-gray4 text-xs'>توضیحات:</label>
                        <textarea
                            id='t9'
                            placeholder='توضیحات مقاله را وارد کنید'
                            className='text-xs placeholder:text-xs border border-caspian-gray2 p-1 xl:p-2 rounded-lg resize-none'
                            onChange={(e) => setDesc(e.target.value)}
                            rows={4} cols={4}></textarea>

                    </div>
                    {/* add picture */}
                    <div>
                        <div>
                            <label htmlFor='file-upload' className='flex gap-1.5 mt-1 items-center text-sm text-[#0A66C2]'>
                                <PiPlusCircle className='text-base' />
                                <span className='text-xs xl:text-sm'>افزودن تصاویر مقاله</span>
                            </label>
                            <input type="file" id="file-upload"
                                onChange={handleUpload}
                                multiple
                                // accept='.jpg,.jpeg,.png,.svg'
                                className='hidden'
                            />
                        </div>
                        <div className='h-9 flex flex-wrap overflow-y-auto gap-2 pt-1'>
                            {
                                files?.length > 0 && files?.map((f, i) => <div key={i} className='relative'>
                                    <PiX className='absolute bg-caspian-gray1 rounded-full p-1' size={18} onClick={() => handleRemoveFile(i)} />
                                    <img className='w-8 h-8' src='/assets/images/library/icon_article.svg' alt='article icon' />
                                    {/* <span>{f?.name}</span> */}
                                </div>)
                            }
                        </div>
                    </div>
                    <button className=' w-[80px] justify-center p-1 xl:p-2 text-xs xl:text-sm rounded-md bg-[#3C77A2] flex gap-2 text-caspian-surface'
                        onClick={handleSubmit}>
                        <span>بارگزاری</span>
                        {isLoading && <PiCircleNotch className='animate-spin' />}
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default ArticleForms;
