import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../../../context/auth/AuthContext";
import API from "../../../../../../api/API";
import MainEditPlanningMeetingForm from "../../../../forms/edit-planinng-meeting/MainEditPlanningMeetingForm";

const MeetingList = ({
  profileId,
  meetingListRefresh,
  setMeetingListRefresh,
  showEditPlanningMeetingForm,
  setShowEditPlanningMeetingForm,
  secretaryId,
}) => {
  const navigate = useNavigate();

  const { token, user } = useContext(AuthContext);
  const [fullData, setFullData] = useState([]);

  const [thisId, setThisId] = useState(null);
  useEffect(() => {
    const getDataHandler = (e) => {
      API.get(`/api/v1/meeting/info/detail/`, {
        headers: { Authorization: token },
      })
        .then((res) => {
          setFullData(
            res?.data.filter(
              (d) =>
                d?.meetingProfileRelated === +profileId &&
                d?.status !== "archived"
            )
          );
          setMeetingListRefresh(0);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getDataHandler();
  }, [token, profileId, meetingListRefresh, setMeetingListRefresh]);
  console.log(fullData);
  return (
    <div className="flex flex-col gap-2">
      {showEditPlanningMeetingForm && (
        <MainEditPlanningMeetingForm
          setMeetingListRefresh={setMeetingListRefresh}
          thisId={thisId}
          setShowEditPlanningMeetingForm={setShowEditPlanningMeetingForm}
          showEditPlanningMeetingForm={showEditPlanningMeetingForm}
        />
      )}
      <h2 className="text-xs font-bold">لیست جلسات</h2>
      <div className="flex flex-col gap-2">
        {fullData.length < 1 ? (
          <p className="text-caspian-gray text-sm text-center">
            جلسه ای وجود ندارد...
          </p>
        ) : (
          fullData.map((d, i) => (
            <div
              key={i}
              className="bg-caspian-surface rounded-md border border-caspian-gray2/50 flex items-center justify-between p-2"
            >
              <h2 className="text-xs">{d.title}</h2>
              <div className="flex items-center gap-2">
                {secretaryId === user?.placementProfileRelated.user ? (
                  d.status !== "wait" && d.status !== "conf" ? (
                    <button
                      onClick={async () => {
                        await setThisId(d.id);
                        setShowEditPlanningMeetingForm(true);
                      }}
                      className={`p-1 text-xs border rounded-md border-[#EC76FF] bg-[#EC76FF18] text-[#EC76FF]`}
                    >
                      پیش نویس
                    </button>
                  ) : (
                    <button
                      onClick={() =>
                        navigate(
                          `/managment-of-meetings/list-meeting/all-meeting/${d.id}`
                        )
                      }
                      className={`p-1 text-xs border rounded-md "border-[#01BC8D] bg-[#01BC8D18] text-[#01BC8D]
              `}
                    >
                      در انتظار برگزاری
                    </button>
                  )
                ) : d.status !== "wait" && d.status !== "conf" ? (
                  <span
                    className={`p-1 text-xs border rounded-md border-[#EC76FF] bg-[#EC76FF18] text-[#EC76FF]`}
                  >
                    پیش نویس
                  </span>
                ) : (
                  <button
                    onClick={() =>
                      navigate(
                        `/managment-of-meetings/list-meeting/all-meeting/${d.id}`
                      )
                    }
                    className={`p-1 text-xs border rounded-md "border-[#01BC8D] bg-[#01BC8D18] text-[#01BC8D]
              `}
                  >
                    در انتظار برگزاری
                  </button>
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default MeetingList;
