import React, { useEffect, useRef, useState } from "react";
import ProcessList from "./ProcessList";

const Group = ({ dataProcessList }) => {
  // console.log(dataProcessList);
  let menuRef = useRef(); 
  const [showHover, setShowHover] = useState(false);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <div ref={menuRef} className="relative flex flex-col gap-2 w-full">
      <div
        onMouseEnter={() => setShowHover(true)}
        onMouseLeave={() => setShowHover(false)}
        onClick={() => setOpen(!open)}
        className="relative cursor-pointer p-4 bg-[#61B9A5] rounded-lg outline-1 outline-caspian-surface outline -outline-offset-2 w-full"
      >
        <h2 className="text-xs text-center">{dataProcessList.title}</h2>
        <div
          className={
            showHover
              ? "absolute right-0 top-0 bg-[#00000015] w-[100%] h-[100%] duration-300 ease-in z-40 rounded-lg"
              : "absolute right-0 top-0 bg-[#00000015] w-[0px] h-[100%] duration-300 ease-in z-40 rounded-lg"
          }
        ></div>
      </div>
      {/* Start Open Menu */}
      <div
        className={
          open
            ? "z-50 absolute -bottom-[100%] left-1/2 -translate-x-1/2 flex items-center flex-col drop-shadow-[0_0_1px_#00000050] h-full"
            : "hidden"
        }
      >
        <div
          className="w-0 h-0
          border-l-[6px] 
          border-l-caspian-transparent
          border-b-[6px] 
        border-b-caspian-surface
          border-r-[6px] 
          border-r-caspian-transparent"
        ></div>
        {/* Start Process List */}
        <ProcessList
          dataProcessListTable={dataProcessList.processGroupRelated}
        />
        {/* End Process List */}
      </div>
      {/* End Open Menu */}
    </div>
  );
};

export default Group;
