import React, { useContext, useEffect, useState } from "react";
import { useAppContext } from "../../context/app-context/AppContext";
import Sidebar from "./sidebar/Sidebar";
import { AuthContext } from "../../context/auth/AuthContext";
import API from "../../api/API";
import MainNotebook from "./notebook/MainNotebook";

const NoteLayout = ({ showMenu }) => {
  const { setBradCrump1, setBradCrump2 } = useAppContext();
  useEffect(() => {
    setBradCrump1("یادداشت ها");
    setBradCrump2("");
  }, [setBradCrump1, setBradCrump2]);

  const { token } = useContext(AuthContext);
  const [fullDataNotebook, setFullDataNotebook] = useState([-1]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    if (token) {
      setLoading(true);
      API.get("/api/v1/profile/notebook", {
        headers: { Authorization: token },
      })
        .then((d) => {
          setFullDataNotebook(d.data);
          setRefresh(0);
        })
        .catch((err) => {
          console.log("error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token, refresh]);
  return (
    <div
      className={
        showMenu
          ? `pl-[50px] mr-auto w-full md:w-[75%] lg:w-[80%] xl:w-[85%] 2xl:w-[85%] bg-caspian-gray3 p-1 overflow-y-scroll`
          : `pl-[50px] mr-[54px] w-full bg-caspian-gray3 p-1 overflow-y-scroll`
      }
    >
      <div className="w-full flex items-start gap-2 p-2">
        <MainNotebook fullDataNotebook={fullDataNotebook} />
        <Sidebar fullDataNotebook={fullDataNotebook} setRefresh={setRefresh} />
      </div>
    </div>
  );
};

export default NoteLayout;
