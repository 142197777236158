import { useEffect, useRef } from "react";
import { PiMagnifyingGlassBold, PiUsers, PiFunnel, PiSortAscendingLight, PiX } from "react-icons/pi";
import { useSearchParams } from "react-router-dom";

const FilterTabsProfileActionPlan = ({ searchProfileGoal, setSearchProfileGoal, roleProfileGoal, setRoleProfileGoal, stateProfileGoal, setStateProfileGoal, sortProfileGoal, setSortProfileGoal }) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const search = searchParams.get('search')
    const searchRef = useRef(null)

    const searchHandler = (e) => {
        e.preventDefault();
        handleSearch('search', searchRef?.current?.value)
        handleSearch("page", "1");
        setSearchProfileGoal(searchRef?.current?.value);
    }

    const resetFilter = () => {
        searchRef.current.value = "";
        handleSearch('search', '')
        handleSearch("rows", '')
        handleSearch("page", '')
        setSearchProfileGoal('')
    }

    const handleSearch = (key, value) => {
        setSearchParams((prev) => {
            if (value === null || value === "") {
                prev.delete(key)
            } else {
                prev.set(key, value)
            }
            return prev;
        })
    }
    
    useEffect(() => {
        if (search) {
            searchRef.current.value = search;
            setSearchProfileGoal(search)
        }
    }, [search, setSearchProfileGoal]);

    return (
        <form onSubmit={searchHandler} className='flex w-full items-end justify-between'>
            <div className='flex w-1/4 items-center justify-end px-3 gap-3 border-2 rounded-lg border-caspian-gray2'>
                <PiMagnifyingGlassBold className='rotate-90 text-lg text-caspian-gray' />
                <input className='py-2 w-full outline-none' type='search' placeholder='جست و جو'
                    ref={searchRef}
                />
                <PiX
                    onClick={resetFilter}
                    className={`${searchProfileGoal?.length > 0 ? ' visible' : ' invisible'} cursor-pointer`} />

            </div>

            <div className='flex items-center justify-center w-2/4 gap-3'>
                <div className='flex flex-col items-start basis-2/6'>
                    <span className='text-caspian-gray text-xs xl:text-sm'>نقش</span>
                    <div className='flex items-center justify-around rounded-lg gap-2 pr-2 py-2 border-2 border-caspian-gray2 w-full '>
                        <PiUsers className='text-2xl text-caspian-gray' />
                        <select className='w-full text-xs 2xl:text-base outline-none bg-caspian-transparent' value={roleProfileGoal}
                            onChange={(e) => {
                                console.log(e.target.value)
                                setRoleProfileGoal(e.target.value)
                            }}>
                            <option value="all">همه</option>
                            <option value="USER">مجری</option>
                            <option value="MANAGER">مدیر برنامه اجرایی</option>
                            <option value="CONTROLLER">کنترل کننده</option>
                            <option value="VARIFY_PLACEMENT">تایید کننده</option>
                            <option value="APPROVAL_PLACEMENT">تصویب کننده</option>
                        </select>
                    </div>
                </div>

                <div className='flex flex-col items-start basis-2/6'>
                    <span className='text-caspian-gray text-xs xl:text-sm'>وضعیت</span>
                    <div className='flex items-center justify-around rounded-lg gap-2 pr-2 py-2 border-2 border-caspian-gray2 w-full '>
                        <PiFunnel className='text-2xl text-caspian-gray' />
                        <select className='w-full outline-none text-xs 2xl:text-base  bg-caspian-transparent' value={stateProfileGoal}
                            onChange={(e) => {
                                console.log(e.target.value)
                                setStateProfileGoal(e.target.value)
                            }}>
                            <option value="all">همه</option>
                            <option value="Working">در حال انجام</option>
                            <option value="NotStarted">شروع نشده</option>
                            <option value="Done">انجام شده</option>
                            <option value="PrePlaning">درحال برنامه‌ریزی</option>
                            <option value="Closed">بسته شده</option>
                        </select>
                    </div>
                </div>

                <div className='flex flex-col items-start basis-3/6'>
                    <span className='text-caspian-gray text-xs xl:text-sm'>مرتب سازی</span>
                    <div className='flex w-[90%] items-center justify-around rounded-lg gap-2 pr-2 py-2 border-2 border-caspian-gray2  '>
                        <PiSortAscendingLight className='text-2xl text-caspian-gray' />
                        <select className='w-full outline-none text-xs 2xl:text-base  bg-caspian-transparent' value={sortProfileGoal}
                            onChange={((e) => {
                                setSortProfileGoal(e.target.value)
                                console.log(e.target.value)
                            })}>
                            <option value="a_start"> زودترین آغاز</option>
                            <option value="b_start"> دیرترین آغاز</option>
                            <option value="a_end"> زودترین پایان </option>
                            <option value="b_end"> دیرترین پایان</option>
                            <option value="a_cost"> بیشترین هزینه </option>
                            <option value="b_cost"> کمترین هزینه</option>
                            <option value="a_progress"> بیشترین پیشرفت</option>
                            <option value="b_progress"> کمترین پیشرفت</option>
                        </select>
                    </div>
                </div>
            </div>
        </form >
    )
}

export default FilterTabsProfileActionPlan