import React, { useState } from "react";
import {
  PiArrowBendDownLeft,
  PiCaretDoubleDown,
  PiCaretDoubleUp,
  PiFileTextFill,
} from "react-icons/pi";
import ReplyForm from "../../forms/ReplyForm";

const RequestReceivedDetails = ({ fullData, setSuccessReply }) => {
  const [showRequest, setShowRequest] = useState(true);
  const [showReplyForm, setShowReplyForm] = useState(false);

  return (
    <div className="bg-caspian-surface flex flex-col border border-caspian-gray2 rounded-xl p-4 shadow-[0_0_5px_#00000050] border-r-8 border-r-[#3C77A280]">
      <div className="flex items-center justify-between pb-2 border-b border-b-caspian-gray2">
        {/* نام ارسال کننده */}
        <div className="flex flex-col items-start gap-0">
          <div className="flex items-center gap-2">
            <div className="w-8 h-8 bg-caspian-primary-200 rounded-full overflow-hidden">
              <img
                src={
                  fullData?.sender?.placementProfileRelated
                    ?.personalInfoProfileRelated[0]?.avatar
                }
                alt={
                  fullData?.sender?.firstName + " " + fullData?.sender?.lastName
                }
              />
            </div>
            <h2 className="text-sm">
              {fullData?.firstActivityRelated?.sender?.firstName +
                " " +
                fullData?.firstActivityRelated?.sender?.lastName}
            </h2>
            <span className="text-xs text-caspian-gray">
              {fullData?.sender === null
                ? "نامشخص"
                : fullData?.sender?.positionJobRelated?.jobLevelRelated?.title}
            </span>
          </div>
          <div className="flex items-center gap-1 mr-10">
            <PiArrowBendDownLeft className="text-lg text-caspian-secondary-600" />
            <span className="text-xs text-caspian-secondary-600">
              ارسال به: من
            </span>
          </div>
        </div>
        {/* تاریخ ارسال گزارش */}
        <span className="text-xs text-caspian-gray">
          {new Date(
            fullData?.firstActivityRelated?.created_at
          ).toLocaleDateString("fa-IR", {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </span>
      </div>

      <div>
        {showRequest && (
          <div className="py-2 border-b border-b-caspian-gray2">
            <div className="flex flex-col gap-3">
              {/* توضیحات درخواست */}
              <h2 className="text-sm text-caspian-secondary-700">
                {fullData?.title}
              </h2>
              <p className="text-sm">{fullData?.description}</p>
              {/* توضیحات درخواست
              <h2 className="text-sm text-caspian-secondary-700">
                {fullData?.RequestRelated?.title}
              </h2>
              <p className="text-sm">{fullData?.RequestRelated?.description}</p> */}

              {/* موعد تحویل گزارش */}
              <div className="flex items-center gap-2">
                <div className="flex items-center gap-2 border border-[#3C77A2] bg-[#3C77A208] rounded-md p-2">
                  <span className="text-xs text-caspian-gray">موعد تحویل:</span>
                  <span className="text-xs">
                    {new Date(fullData?.deadLine).toLocaleDateString("fa-IR", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                </div>
                {/* دوره درخواست گزارش */}
                <div className="flex items-center gap-2 border border-[#3C77A2] bg-[#3C77A208] rounded-md p-2">
                  <span className="text-xs text-caspian-gray">
                    دوره درخواست:
                  </span>
                  {fullData?.period ? (
                    <span className="text-xs">{fullData?.period}</span>
                  ) : (
                    <span className="text-xs">بدون دوره درخواست</span>
                  )}
                </div>
              </div>
              {/* فایل پیوست */}
              <div className="">
                {fullData?.document !== null ? (
                  <a
                    download={fullData?.document}
                    href={fullData?.document}
                    target="_blank"
                    rel="noreferrer"
                    className="text-sm"
                  >
                    <div className="flex items-center gap-2 text-caspian-gray text-[15px] p-2 rounded-md border border-caspian-gray w-fit">
                      {fullData?.document.replace(/^.*[\\/]/, "")}
                      <PiFileTextFill className="text-caspian-secondary-600 text-2xl" />
                    </div>
                  </a>
                ) : (
                  <div className="flex items-center justify-start p-2">
                    <h2 className="text-xs text-caspian-gray">
                      فایلی جهت دانلود وجود ندارد!
                    </h2>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={`justify-between flex items-center w-full gap-4 pt-4`}>
        <div className="flex items-center gap-2">
          <button className="flex items-center gap-2 text-caspian-secondary-600 outline outline-1 outline-caspian-secondary-600 py-1 px-3 rounded-md">
            <span className="text-xs">ارجاع</span>
          </button>
          {(fullData.status !== "complated" ||
            fullData.status !== "rejected") && (
            <>
              <button
                onClick={() => setShowReplyForm(true)}
                className="flex items-center gap-2 text-caspian-secondary-600 outline outline-1 outline-caspian-secondary-600 py-1 px-3 rounded-md"
              >
                <span className="text-xs">پاسخ</span>
              </button>
              {showReplyForm && (
                <ReplyForm
                  setSuccessReply={setSuccessReply}
                  setShowReplyForm={setShowReplyForm}
                  showReplyForm={showReplyForm}
                  activityId={fullData?.firstActivityRelated?.id}
                  reciver={
                    fullData?.firstActivityRelated?.sender?.firstName +
                    " " +
                    fullData?.firstActivityRelated?.sender?.lastName
                  }
                />
              )}
            </>
          )}
        </div>
        <button
          onClick={() => setShowRequest(!showRequest)}
          className="text-caspian-secondary-500 text-sm"
        >
          {!showRequest ? <PiCaretDoubleDown /> : <PiCaretDoubleUp />}
        </button>
      </div>
    </div>
  );
};

export default RequestReceivedDetails;
