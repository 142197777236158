import React, { useContext, useEffect, useState } from "react";
import {
  PiCaretDownBold,
  PiMagnifyingGlassBold,
  PiXBold,
} from "react-icons/pi";
import API from "../../../../../../api/API";
import { AuthContext } from "../../../../../../context/auth/AuthContext";

const Refrence = ({ setShowRefrence, setRefreshRefrence, generalId }) => {
  const { token } = useContext(AuthContext);
  const [showInternalDocuments, setShowInternalDocuments] = useState(false);
  const [showExternalDocuments, setShowExternalDocuments] = useState(false);
  const [showManagementSystem, setShowManagementSystem] = useState(false);
  const [referenceSelected, setReferenceSelected] =
    useState("ManagementSystem");

  const [contentType, setContentType] = useState(null);

  // مستندات درون سازمانی
  const [internalDocumentsData, setInternalDocumentsData] = useState([]);
  let urlInternalDocuments = "/api/v1/deocument/internal/list";
  const getInternalDocuments = () => {
    API.get(urlInternalDocuments, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        setInternalDocumentsData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [searchByTitle, setSearchByTitle] = useState("");
  const [internalDocumentsId, setInternalDocumentsId] = useState(null);
  let searchTitle = [];
  if (internalDocumentsData !== -1) {
    searchTitle = searchByTitle
      ? internalDocumentsData.filter(
          (d) =>
            d?.title !== undefined &&
            d?.title
              ?.toLocaleLowerCase()
              ?.includes(searchByTitle?.toLocaleLowerCase())
        )
      : internalDocumentsData;
  }

  // مستندات برون سازمانی
  const [externalDocumentsData, setExternalDocumentsData] = useState([]);
  let urlExternalDocuments = "/api/v1/deocument/external/list";
  const getExternalDocuments = () => {
    API.get(urlExternalDocuments, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        setExternalDocumentsData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [searchByTitleEx, setSearchByTitleEx] = useState("");
  const [externalDocumentsId, setExternalDocumentsId] = useState(null);
  let searchTitleEx = [];
  if (externalDocumentsData !== -1) {
    searchTitleEx = searchByTitleEx
      ? externalDocumentsData?.filter(
          (d) =>
            d?.title !== undefined &&
            d?.title
              ?.toLocaleLowerCase()
              ?.includes(searchByTitleEx?.toLocaleLowerCase())
        )
      : externalDocumentsData;
  }

  // سیستم های مدیریتی
  const [managementSystemData, setManagementSystemData] = useState([]);
  const [managementSystemDataDetails, setManagementSystemDataDetails] =
    useState([]);

  let urlManagementSystem = "/api/v1/standard/managmentSystem";
  const getManagementSystem = () => {
    API.get(urlManagementSystem, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        setManagementSystemData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getManagementSystemDetails = (id) => {
    API.get(`/api/v1/standard/managmentSystem/${id}/`, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        setManagementSystemDataDetails(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [searchByTitleMs, setSearchByTitleMs] = useState("");
  const [managementSystemId, setManagementSystemId] = useState(null);
  let searchTitleMs = [];
  if (managementSystemData !== -1) {
    searchTitleMs = searchByTitleMs
      ? managementSystemData?.filter(
          (d) =>
            d?.title !== undefined &&
            d?.title
              ?.toLocaleLowerCase()
              ?.includes(searchByTitleMs?.toLocaleLowerCase())
        )
      : managementSystemData;
  }

  const [otherRefrenceData, setOtherRefrenceData] = useState(null);

  useEffect(() => {
    setContentType(
      referenceSelected === "ManagementSystem"
        ? "standard_app.managementsystem"
        : referenceSelected === "ExternalDocuments"
        ? "document_app.externaldocument"
        : referenceSelected === "InternalDocumentProfile"
        ? "document_app.internaldocumentprofile"
        : null
    );
  }, [referenceSelected]);

  useEffect(() => {
    if (referenceSelected !== "other") {
      setOtherRefrenceData(null);
    }
    if (referenceSelected !== "ManagementSystem") {
      setShowManagementSystem(false);
      setManagementSystemDataDetails([]);
      setManagementSystemId(null);
      setSystemMangamentSelected([]);
      setPrimarySelected([]);
      setMainSelected([]);
      setSecondarySelected([]);
    }
    if (referenceSelected !== "ExternalDocuments") {
      setShowExternalDocuments(false);
    }
    if (referenceSelected !== "InternalDocumentProfile") {
      setShowInternalDocuments(false);
    }
  }, [referenceSelected]);

  const dataHandler = async (e) => {
    e.preventDefault();
    if (
      (referenceSelected === "ManagementSystem" &&
        systemMangamentSelected.length < 1) ||
      (referenceSelected === "ExternalDocuments" && !externalDocumentsId) ||
      (referenceSelected === "InternalDocumentProfile" &&
        !internalDocumentsId) ||
      (referenceSelected === "other" &&
        (!otherRefrenceData || otherRefrenceData === " "))
    )
      return null;
    await API.post(
      "/api/v1/meeting/profile/refrence/",
      {
        refrence:
          referenceSelected === "ManagementSystem"
            ? "ManagementSystem"
            : referenceSelected === "ExternalDocuments"
            ? "ExternalDocuments"
            : referenceSelected === "InternalDocumentProfile"
            ? "InternalDocumentProfile"
            : referenceSelected === "other" && "other",
        refrenceText:
          referenceSelected === "other"
            ? otherRefrenceData
            : contentType === "standard_app.managementsystem"
            ? managementSystemId?.title
            : contentType === "document_app.externaldocument"
            ? externalDocumentsId?.title
            : contentType === "document_app.internaldocumentprofile"
            ? internalDocumentsId?.title
            : null,
        content_type:
          referenceSelected === "ManagementSystem"
            ? "standard_app.managementsystem"
            : referenceSelected === "ExternalDocuments"
            ? "document_app.externaldocument"
            : referenceSelected === "InternalDocumentProfile"
            ? "document_app.internaldocumentprofile"
            : null,
        object_id:
          contentType === "standard_app.managementsystem"
            ? managementSystemId?.id
            : contentType === "document_app.externaldocument"
            ? externalDocumentsId?.id
            : contentType === "document_app.internaldocumentprofile"
            ? internalDocumentsId?.id
            : null,
        meetingProfileRefrenceRelated: +generalId,
        meetingStandardRelated:
          contentType === "standard_app.managementsystem"
            ? systemMangamentSelected
            : [],
      },
      {
        headers: { Authorization: token },
      }
    )
      .then((res) => {
        console.log(res);
        setRefreshRefrence(1);
        setShowRefrence(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [primarySelected, setPrimarySelected] = useState([]);
  const [mainSelected, setMainSelected] = useState([]);
  const [secondarySelected, setSecondarySelected] = useState([]);

  const [systemMangamentSelected, setSystemMangamentSelected] = useState([
    primarySelected,
    mainSelected,
    secondarySelected,
  ]);

  useEffect(() => {
    const changeArrays = async () => {
      const newArray1 = primarySelected;
      const newArray2 = mainSelected;
      const newArray3 = secondarySelected;
      await setSystemMangamentSelected(
        [newArray1, newArray2, newArray3].reduce(
          (acc, curr) => acc.concat(curr),
          []
        )
      );
    };
    changeArrays();
  }, [primarySelected, mainSelected, secondarySelected]);

  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full z-50">
      <div className="bg-caspian-surface rounded-md shadow-[0_0_5px_#00000050] p-3 w-[400px] flex flex-col gap-3">
        <div className="flex items-center justify-between">
          <h2 className="text-sm font-bold">افزودن مرجع</h2>
          <button onClick={() => setShowRefrence(false)}>
            <PiXBold className={"text-caspian-gray4 text-sm"} />
          </button>
        </div>

        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-1.5 w-full">
            <label className="text-xs text-caspian-gray">مرجع</label>
            <select
              onChange={(e) => setReferenceSelected(e.target.value)}
              name="reference"
              defaultValue={"ManagementSystem"}
              className="text-xs outline outline-1 outline-caspian-gray1 p-1.5 rounded-md bg-caspian-transparent cursor-pointer"
            >
              <option value={"ManagementSystem"}>سیستم مدیریتی</option>
              <option value={"ExternalDocuments"}>
                الزامات قانونی و مقررات برون سازمانی
              </option>
              {/* <option value={"mosavabatDakheli"}>مصوبات داخلی</option> */}
              <option value={"InternalDocumentProfile"}>
                مستندات درون سازمانی
              </option>
              <option value={"other"}>سایر</option>
            </select>
          </div>

          {/* اینپوت سایر */}
          {referenceSelected === "other" && (
            <div className="flex flex-col gap-1.5 w-full">
              <label className="text-xs text-caspian-gray">توضیحات</label>
              <input
                onChange={(e) => setOtherRefrenceData(e.target.value)}
                name="referenceDescription"
                type="text"
                placeholder="وارد کنید"
                className="outline-none outline-caspian-gray1 p-1.5 text-xs rounded-md outline-1"
              />
            </div>
          )}

          {/* لیست مدارک درون سازمانی */}
          {referenceSelected === "InternalDocumentProfile" && (
            <div className="flex flex-col gap-1.5 w-full relative">
              <label className="text-xs text-caspian-gray">
                لیست مستندات درون سازمانی
              </label>
              <div
                onClick={() => {
                  setShowInternalDocuments(!showInternalDocuments);
                  getInternalDocuments();
                }}
                className="text-xs outline outline-1 outline-caspian-gray1 p-[8px] rounded-md bg-caspian-transparent cursor-pointer flex items-center justify-between z-50"
              >
                {internalDocumentsId !== null ? (
                  <span className="text-xs">{internalDocumentsId.title}</span>
                ) : (
                  <span className="text-caspian-gray1 text-xs">
                    انتخاب کنید
                  </span>
                )}
                <PiCaretDownBold className="text-caspian-gray" />
              </div>
              {showInternalDocuments && (
                <div className="rounded-md shadow-[0_0_5px_#00000050] w-full flex flex-col gap-3 py-3 absolute left-0 right-0 bottom-[30px] bg-caspian-surface z-50">
                  <div className="relative w-[92%] mx-auto">
                    <input
                      type="search"
                      onChange={(e) => setSearchByTitle(e.target.value)}
                      className="outline-none outline-caspian-gray1 p-1 text-xs rounded-md outline-1 w-full placeholder:text-xs placeholder:text-caspian-gray pr-6"
                      placeholder="جستجو"
                    />
                    <button className="absolute top-1/2 -translate-y-1/2 right-1">
                      <PiMagnifyingGlassBold className="text-caspian-gray text-" />
                    </button>
                  </div>
                  <div className="flex flex-col gap-2 w-full max-h-[160px] overflow-y-scroll">
                    {searchTitle !== -1 &&
                      searchTitle?.map((d, i) => (
                        <span
                          key={i}
                          onClick={() => {
                            setShowInternalDocuments(false);
                            setInternalDocumentsId({
                              id: d.id,
                              title: d.title,
                            });
                          }}
                          className="text-xs hover:bg-caspian-secondary-100 py-1 px-4 w-full cursor-pointer duration-300"
                        >
                          {d.title}
                        </span>
                      ))}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* لیست مدارک برون سازمانی */}
          {referenceSelected === "ExternalDocuments" && (
            <div className="flex flex-col gap-1.5 w-full relative">
              <label className="text-xs text-caspian-gray">
                لیست الزامات قانونی
              </label>
              <div
                onClick={() => {
                  setShowExternalDocuments(!showExternalDocuments);
                  getExternalDocuments();
                }}
                className="text-xs outline outline-1 outline-caspian-gray1 p-[8px] rounded-md bg-caspian-transparent cursor-pointer flex items-center justify-between z-50"
              >
                {externalDocumentsId !== null ? (
                  <span className="text-xs">{externalDocumentsId?.title}</span>
                ) : (
                  <span className="text-caspian-gray1 text-xs">
                    انتخاب کنید
                  </span>
                )}
                <PiCaretDownBold className="text-caspian-gray" />
              </div>
              {showExternalDocuments && (
                <div className="rounded-md shadow-[0_0_5px_#00000050] w-full flex flex-col gap-3 py-3 absolute left-0 right-0 bottom-[30px] bg-caspian-surface z-50">
                  <div className="relative w-[92%] mx-auto">
                    <input
                      type="search"
                      onChange={(e) => setSearchByTitleEx(e.target.value)}
                      className="outline-none outline-caspian-gray1 p-1 text-xs rounded-md outline-1 w-full placeholder:text-xs placeholder:text-caspian-gray pr-6"
                      placeholder="جستجو"
                    />
                    <button className="absolute top-1/2 -translate-y-1/2 right-1">
                      <PiMagnifyingGlassBold className="text-caspian-gray text-" />
                    </button>
                  </div>
                  <div className="flex flex-col gap-2 w-full max-h-[160px] overflow-y-scroll">
                    {searchTitleEx !== -1 &&
                      searchTitleEx?.map((d, i) => (
                        <span
                          key={i}
                          onClick={() => {
                            setShowExternalDocuments(false);
                            setExternalDocumentsId({
                              id: d?.id,
                              title: d?.title,
                            });
                          }}
                          className="text-xs hover:bg-caspian-secondary-100 py-1 px-4 w-full cursor-pointer duration-300"
                        >
                          {d?.title}
                        </span>
                      ))}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* سیستم های مدیریتی */}
          {referenceSelected === "ManagementSystem" && (
            <div className="flex flex-col gap-1.5 w-full relative">
              <label className="text-xs text-caspian-gray">
                لیست سیستم های مدیریتی
              </label>
              <div
                onClick={() => {
                  setShowManagementSystem(!showManagementSystem);
                  getManagementSystem();
                }}
                className="text-xs outline outline-1 outline-caspian-gray1 p-[8px] rounded-md bg-caspian-transparent cursor-pointer flex items-center justify-between z-50"
              >
                {managementSystemId !== null ? (
                  <span className="text-xs">{managementSystemId.title}</span>
                ) : (
                  <span className="text-caspian-gray1 text-xs">
                    انتخاب کنید
                  </span>
                )}
                <PiCaretDownBold className="text-caspian-gray" />
              </div>
              {showManagementSystem && (
                <div className="rounded-md shadow-[0_0_5px_#00000050] w-full flex flex-col gap-3 py-3 absolute left-0 right-0 bottom-[30px] bg-caspian-surface z-50">
                  <div className="relative w-[92%] mx-auto">
                    <input
                      type="search"
                      onChange={(e) => setSearchByTitleMs(e.target.value)}
                      className="outline-none outline-caspian-gray1 p-1 text-xs rounded-md outline-1 w-full placeholder:text-xs placeholder:text-caspian-gray pr-6"
                      placeholder="جستجو"
                    />
                    <button className="absolute top-1/2 -translate-y-1/2 right-1">
                      <PiMagnifyingGlassBold className="text-caspian-gray text-" />
                    </button>
                  </div>
                  <div className="flex flex-col gap-2 w-full max-h-[160px] overflow-y-scroll">
                    {searchTitleMs !== -1 &&
                      searchTitleMs?.map((d, i) => (
                        <span
                          key={i}
                          onClick={() => {
                            getManagementSystemDetails(d.id);
                            setShowManagementSystem(false);
                            setManagementSystemId({
                              id: d.id,
                              title: d.title,
                            });
                          }}
                          className="text-xs hover:bg-caspian-secondary-100 py-1 px-4 w-full cursor-pointer duration-300"
                        >
                          {d.title}
                        </span>
                      ))}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* جزئیات مدیریتی */}
          {managementSystemDataDetails.length !== 0 && (
            <div className="flex flex-col gap-1.5 w-full relative">
              <label className="text-xs text-caspian-gray">
                جزئیات سیستم های مدیریتی
              </label>

              {/* start primary standard */}
              <div className="flex flex-col gap-2 w-full">
                <span className="text-xs">primary standard</span>

                <div className="w-full">
                  <div className="flex items-center gap-1">
                    <input
                      checked={primarySelected.includes(
                        managementSystemDataDetails?.primaryStandard?.id
                      )}
                      onChange={(e) => {
                        setPrimarySelected(
                          referenceSelected === "ManagementSystem"
                            ? e.target.checked
                              ? [
                                  ...primarySelected,
                                  managementSystemDataDetails?.primaryStandard
                                    ?.id,
                                ]
                              : primarySelected.filter(
                                  (f) =>
                                    f !==
                                    managementSystemDataDetails?.primaryStandard
                                      ?.id
                                )
                            : []
                        );
                      }}
                      type="checkbox"
                      id={managementSystemDataDetails?.primaryStandard?.id}
                      className="accent-caspian-primary-400 cursor-pointer"
                    />
                    <label
                      htmlFor={managementSystemDataDetails?.primaryStandard.id}
                      className="flex items-center gap-8 w-full text-xs cursor-pointer"
                    >
                      <span>
                        {
                          managementSystemDataDetails?.primaryStandard
                            .standardTitlePersian
                        }
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              {/* end primary standard */}

              {/* start main standard */}
              <div className="flex flex-col gap-2">
                <span className="text-xs">main standard</span>

                <div className="w-full">
                  <div className="flex flex-col gap-1">
                    {managementSystemDataDetails?.mainStandard?.map((m, i) => (
                      <div key={i} className="flex items-center gap-1">
                        <input
                          checked={mainSelected.includes(m?.id)}
                          onChange={(e) => {
                            setMainSelected(
                              referenceSelected === "ManagementSystem"
                                ? e.target.checked
                                  ? [...mainSelected, m?.id]
                                  : mainSelected.filter((f) => f !== m?.id)
                                : []
                            );
                          }}
                          type="checkbox"
                          id={m?.id}
                          className="accent-caspian-primary-400 cursor-pointer"
                        />
                        <label
                          htmlFor={m?.id}
                          className="flex items-center gap-8 w-full text-xs cursor-pointer"
                        >
                          <span>{m?.standardTitlePersian}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* end main standard */}

              {/* start secondary standard */}
              <div className="flex flex-col gap-2">
                <span className="text-xs">secondary standard</span>

                <div className="w-full">
                  <div className="flex flex-col gap-1">
                    {managementSystemDataDetails?.secondaryStandard?.map(
                      (m, i) => (
                        <div key={i} className="flex items-center gap-1">
                          <input
                            checked={secondarySelected.includes(m?.id)}
                            onChange={(e) => {
                              setSecondarySelected(
                                referenceSelected === "ManagementSystem"
                                  ? e.target.checked
                                    ? [...secondarySelected, m?.id]
                                    : secondarySelected.filter(
                                        (f) => f !== m?.id
                                      )
                                  : []
                              );
                            }}
                            type="checkbox"
                            id={m?.id}
                            className="accent-caspian-primary-400 cursor-pointer"
                          />
                          <label
                            htmlFor={m?.id}
                            className="flex items-center gap-8 w-full text-xs cursor-pointer"
                          >
                            <span>{m?.standardTitlePersian}</span>
                          </label>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              {/* end secondary standard */}
            </div>
          )}
        </div>
        <div className="flex items-center gap-2 mt-2">
          <button
            onClick={dataHandler}
            className="outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs py-1.5 px-6 rounded-md"
          >
            افزودن
          </button>
          <button
            onClick={() => setShowRefrence(false)}
            className="text-xs text-[#FF5B5B] mr-4"
          >
            لغو
          </button>
        </div>
      </div>
    </div>
  );
};

export default Refrence;
