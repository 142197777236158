import React, { useState } from "react";
import ButtonTab from "./ButtonTab";
import GeneralMeeting from "./forms/GeneralMeeting";
import Roles from "./forms/Roles";
import InputOutput from "./forms/InputOutput";
import OtherInformation from "./forms/OtherInformation";
import RefrenceMeeting from "./forms/RefrenceMeeting";

const EditMeetingForm = ({
  setShowEditMeetingForm,
  setRefresh,
  dataEdit,
  id,
  editTitle,
  setDataRefresh,
}) => {
  const [activeTab, setActiveTab] = useState("generalMeeting");
  const [data, setData] = useState();

  const [generalId, setGeneralId] = useState(null);

  return (
    <div className="flex flex-col justify-between h-full">
      {/* تب های فرم برنامه ریزی برگزار جلسه */}
      <div className="flex items-center justify-between gap-4 bg-caspian-gray2/40 py-1.5 px-2 rounded-md">
        <ButtonTab
          activeTab={activeTab}
          active="generalMeeting"
          onClick={() => setActiveTab("generalMeeting")}
          title="کلیات جلسه"
        />
        <ButtonTab
          activeTab={activeTab}
          active="refrence"
          onClick={() => setActiveTab("refrence")}
          title="مرجع"
          setShowEditMeetingForm={setShowEditMeetingForm}
          setRefresh={setRefresh}
        />
        <ButtonTab
          activeTab={activeTab}
          active="roles"
          onClick={() => setActiveTab("roles")}
          title="نقش ها"
        />
        <ButtonTab
          activeTab={activeTab}
          active="inputOutput"
          onClick={() => setActiveTab("inputOutput")}
          title="ورودی و خروجی جلسه"
        />
        <ButtonTab
          activeTab={activeTab}
          active="otherInformation"
          onClick={() => setActiveTab("otherInformation")}
          title="سایر اطلاعات"
        />
      </div>
      <div className=" py-2">
        {/* کلیات جلسه  */}
        {activeTab === "generalMeeting" && (
          <GeneralMeeting
            dataEdit={dataEdit}
            data={data}
            setData={setData}
            setActiveTab={setActiveTab}
            setGeneralId={setGeneralId}
            generalId={id}
            setShowEditMeetingForm={setShowEditMeetingForm}
            setRefresh={setRefresh}
            setDataRefresh={setDataRefresh}
            editTitle={editTitle}
          />
        )}
        {/* مرجع ها */}
        {activeTab === "refrence" && (
          <RefrenceMeeting
            setActiveTab={setActiveTab}
            generalId={id}
            setShowEditMeetingForm={setShowEditMeetingForm}
            setRefresh={setRefresh}
            setDataRefresh={setDataRefresh}
            editTitle={editTitle}
          />
        )}
        {/* نقش ها */}
        {activeTab === "roles" && (
          <Roles
            dataEdit={dataEdit}
            generalId={id}
            setActiveTab={setActiveTab}
            setShowEditMeetingForm={setShowEditMeetingForm}
            setRefresh={setRefresh}
            setDataRefresh={setDataRefresh}
            editTitle={editTitle}
          />
        )}
        {/* ورودی و خروجی جلسه */}
        {activeTab === "inputOutput" && (
          <InputOutput
            generalId={id}
            setActiveTab={setActiveTab}
            setShowEditMeetingForm={setShowEditMeetingForm}
            setRefresh={setRefresh}
            setDataRefresh={setDataRefresh}
            editTitle={editTitle}
          />
        )}
        {/* سایر اطلاعات */}
        {activeTab === "otherInformation" && (
          <OtherInformation
            dataEdit={dataEdit}
            setShowEditMeetingForm={setShowEditMeetingForm}
            generalId={id}
            setActiveTab={setActiveTab}
            setRefresh={setRefresh}
            setDataRefresh={setDataRefresh}
            editTitle={editTitle}
          />
        )}
      </div>
    </div>
  );
};

export default EditMeetingForm;
