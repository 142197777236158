import { useSearchParams } from "react-router-dom";

const RowViewProfileGoal = ({ setRecordsPerPage, setCurrentPage }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const rows = searchParams.get("rows");

    function handleSearch(key, value) {
        setSearchParams((prevParams) => {
            if (value === null || value === "") {
                prevParams.delete(key);
            } else {
                prevParams.set(key, value);
            }
            return prevParams;
        });
    }
    return (
        <select
            onChange={(e) => {
                setRecordsPerPage(e.target.value);
                setCurrentPage(1);
                handleSearch("rows", e.target.value);
                handleSearch("page", "");
            }}
            defaultValue={rows ? rows : 10}
            className="text-sm outline outline-1 outline-caspian-secondary-500 py-1 px-2 rounded-md bg-caspian-transparent cursor-pointer"
        >
            <option value={10}>ردیف 10</option>
            <option value={20}>ردیف 20</option>
            <option value={30}>ردیف 30</option>
        </select>
    );
};

export default RowViewProfileGoal;
