import React, { useEffect, useState } from "react";
import IdProcess from "./IdProcess";
import OutputTable from "./OutputTable";
import InputTable from "./InputTable";
import ActivitiesTable from "./ActivitiesTable";
import ProcessIndicatorsTable from "./ProcessIndicatorsTable";
import Requirements from "./RequirementsComponent/Requirements";
import { Link, useLocation, useParams } from "react-router-dom";
import { useAppContext } from "../../../../context/app-context/AppContext";
import { PiCaretLeft } from "react-icons/pi";
import API from "../../../../api/API";

const MainSingleListProcess = ({ data }) => {
  const params = useParams();
  const location = useLocation();

  const search = (location.state && location.state.search) || "";
  const { setBradCrump3 } = useAppContext();
  const [fullData, setFullData] = useState([-1]);

  // useEffect(() => {
  //   setFullData(
  //     data.filter(
  //       (d) =>
  //         d.profileProcessRelated &&
  //         +d.profileProcessRelated.id === +params.code
  //     )
  //   );
  // }, [data, params]);
  const [error, setError] = useState(0);

  useEffect(() => {
    const getData = async () => {
      await API.get(`/api/v1/profileProcess/profile/${params?.code}`)
        .then((res) => {
          setError(-1);
          setFullData(res.data);
          console.log(res?.data);
        })
        .catch((err) => {
          setError(1);
          console.log(err);
        });
    };
    getData();
  }, [params, location]);

  const [profileProcessDetails, setProfileProcessDetails] = useState([-1]);
  useEffect(() => {
    if (params.code) {
      API.get(`/api/v1/businessProcess/process/${params.code}`)
        .then((d) => {
          setProfileProcessDetails(d.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [fullData, params]);

  useEffect(() => {
    setBradCrump3(
      profileProcessDetails[0] !== -1 && profileProcessDetails?.title
    );
  }, [setBradCrump3, profileProcessDetails]);

  const [referrer, setReferrer] = useState("");
  const [lastSegment, setLastSegment] = useState("");
  useEffect(() => {
    const storedReferrer = localStorage.getItem("referrer");
    if (storedReferrer) {
      setReferrer(storedReferrer);
      console.log(referrer);
    }
  }, [referrer]);

  useEffect(() => {
    const segments = referrer?.split("/");
    const lastSegment = segments[segments.length - 1];
    setLastSegment(lastSegment);
  }, [referrer]);
  return (
    <>
      <Link
        onClick={() => {
          console.log(location);
          setBradCrump3("");
        }}
        // list-processes
        // to={`/process-management/${search}`}
        to={
          lastSegment === "list-processes"
            ? `/process-management/list-processes`
            : `/process-management/model`
        }
        className="mr-auto flex items-center gap-2 text-caspian-secondary-700 p-2"
      >
        <span className="">بازگشت</span>
        <PiCaretLeft />
      </Link>
      {
        profileProcessDetails[0] === -1 ? (
          <div className="flex items-center justify-center w-full h-full mt-12">
            <h2 className="text-caspian-gray text-sm">
              در حال بارگذاری اطلاعات...
            </h2>
          </div>
        ) : error === 1 && profileProcessDetails.id !== undefined ? (
          // <div className="flex flex-col gap-2 w-full">
          <>
            <div className="ml-8 py-4 flex items-center justify-between gap-4">
              <div className="flex items-center gap-4">
                <h2 className="text-caspian-secondary-600">عنوان</h2>
                <p>{profileProcessDetails.title}</p>
              </div>
              <div className="flex items-center gap-4">
                <h2 className="text-caspian-gray text-sm">کد فرایند</h2>
                <p className="text-sm">{profileProcessDetails.id}</p>
              </div>
            </div>
            <div className="flex items-center justify-center w-full h-full mt-12">
              <h2 className="text-caspian-gray text-sm">بدون شناسنامه</h2>
            </div>
          </>
        ) : (
          error === -1 &&
          profileProcessDetails?.id !== undefined && (
            <div className="flex flex-col gap-2">
              <div className="ml-8 py-4 flex items-center justify-between gap-4">
                <div className="flex items-center gap-4">
                  <h2 className="text-caspian-secondary-600">عنوان</h2>
                  <p>{profileProcessDetails?.title}</p>
                </div>
                <div className="flex items-center gap-4">
                  <h2 className="text-caspian-gray text-sm">کد فرایند</h2>
                  <p className="text-sm">{profileProcessDetails?.id}</p>
                </div>
              </div>
              <IdProcess
                data={{
                  processGoal: fullData?.processGoal,
                  processMission: fullData?.processMission,
                  processMotivator: fullData?.processMotivator,
                  processStackHolder: fullData?.processStackHolder,
                  groupCategoryRelated:
                    profileProcessDetails.processGroupRelated
                      ?.groupCategoryRelated?.title,
                  processGroupRelated:
                    profileProcessDetails.processGroupRelated?.title,
                  processResponsibleJanamai:
                    fullData?.processResponsibleJanamai,
                }}
              />
              <InputTable data={fullData?.inputProfileProcessRelated} />
              <ActivitiesTable data={fullData?.activityProfileProcessRelated} />
              <OutputTable data={fullData?.outputProfileProcessRelated} />
              <Requirements data={fullData?.challengeProfileProcessRelated} />
              <ProcessIndicatorsTable
                data={fullData?.processIndicatorProfileProcessRelated}
              />
            </div>
          )
        )

        // </div>
      }
    </>
  );
};

export default MainSingleListProcess;
