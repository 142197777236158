import React, { useEffect, useState } from "react";
import RowView from "./RowView";
import Paginate from "./Paginate";
import { useSearchParams } from "react-router-dom";
import { PiCircleNotch } from "react-icons/pi";
import SingleTableProfileMeeting from "./SingleTableChart";

const TableListChart = ({ data }) => {
  const [filterByTitle, setFilterByTitle] = useState("");
  const [filterByPartDivision, setFilterByPartDivision] = useState("");
  const [filterByDivisionDepartman, setFilterByDivisionDepartman] =
    useState("");
  const [filterBydDepartment, setFilterBydDepartment] = useState("");

  const [searchParams] = useSearchParams();
  const search = searchParams.get("search");
  const page = searchParams.get("page");
  const rows = searchParams.get("rows");

  let filterTitle = [];
  let filterPartDivision = [];
  let filterDivisionDepartman = [];
  let filterDepartment = [];

  useEffect(() => {
    if (search) {
      setCurrentPage(1);
    }
  }, [search]);

  let newData = [-1];
  if (data[0] !== -1) {
    newData = search
      ? data.filter((d) =>
        d.title?.toLocaleLowerCase()?.includes(search.toLocaleLowerCase())
      )
      : data;
  }

  if (newData[0] !== -1) {
    filterTitle = filterByTitle
      ? newData.filter((d) =>
        d?.title
          ?.toLocaleLowerCase()
          ?.includes(filterByTitle.toLocaleLowerCase())
      )
      : newData;

    filterPartDivision = filterByPartDivision
      ? filterTitle?.filter((d) =>
        d?.positionPartRelated?.title
          ?.toLocaleLowerCase()
          ?.includes(filterByPartDivision.toLocaleLowerCase())
      )
      : filterTitle;

    filterDivisionDepartman = filterByDivisionDepartman
      ? filterPartDivision?.filter((d) =>
        d?.divisionRelated
          ?.toLocaleLowerCase()
          ?.includes(filterByDivisionDepartman.toLocaleLowerCase())
      )
      : filterPartDivision;

    filterDepartment = filterBydDepartment
      ? filterDivisionDepartman.filter((d) =>
        d?.departmanRelated
          ?.toLocaleLowerCase()
          ?.includes(filterBydDepartment.toLocaleLowerCase())
      )
      : filterDivisionDepartman;
  }
  const [currentPage, setCurrentPage] = useState(page ? page : 1);
  const [recordsPerPage, setRecordsPerPage] = useState(rows ? rows : 10);
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = filterDepartment.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filterDepartment.length / recordsPerPage);

  return (
    <div className="flex flex-col gap-4 py-10 px-8 xl:px-16">
            <div className="w-full overflow-x-auto">
              <table className="min-w-[992px] w-full text-left text-sm font-light">
                <thead>
                  <tr className=' rounded-tr-xl rounded-tl-xl'>
                    <th className='bg-caspian-primary-500 w-[15%] px-2 py-1 rounded-tr-xl relative'>
                      <input id='t1' type="text" className={`${filterByTitle ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-10 border border-caspian-gray2 cursor-pointer`}
                        onChange={(e) => { setFilterByTitle(e.target.value) }}
                      />
                      <label htmlFor="t1"
                        className={`${filterByTitle ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface peer-focus:right-3 z-50`}>
                        عنوان
                      </label>
                    </th>

                    <th className='bg-caspian-primary-500 w-[20%] p-2 relative'>
                      <input id='t2' type="text"
                        className={`${filterByPartDivision ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50  cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-10 border border-caspian-gray2`}
                        onChange={(e) => { setFilterByPartDivision(e.target.value) }}
                      />
                      <label htmlFor="t2"
                        className={`${filterByPartDivision ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface peer-focus:right-3 z-50`}>
                        دسته
                      </label>
                    </th>
                    <th className='bg-caspian-primary-500 w-[30%] p-2 relative'>
                      <input id='t3' type="text"
                        className={`${filterByDivisionDepartman ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-9`}
                        onChange={(e) => { setFilterByDivisionDepartman(e.target.value) }}
                      />
                      <label htmlFor="t3"
                        className={`${filterByDivisionDepartman ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface peer-focus:right-3 z-50`}>
                        حوزه
                      </label>
                    </th>
                    <th className='bg-caspian-primary-500 w-[30%] p-2 rounded-tl-xl relative'>
                      <input id='t4' type="text"
                        className={`${filterBydDepartment ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-9 border border-caspian-gray2`}
                        onChange={(e) => { setFilterBydDepartment(e.target.value) }}
                      />
                      <label htmlFor="t4"
                        className={`${filterBydDepartment ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface peer-focus:right-3 z-50`}>
                        گروه
                      </label>
                    </th>
                  </tr>
                </thead>




                {/* <thead className="border-b border-b-caspian-gray2 font-medium">
                    <tr>
                      <th
                        scope="col"
                        className="rounded-tr-xl bg-caspian-surface text-right px-3 py-6 w-[20%]"
                      >
                        <input
                          // onClick={() => setCurrentPage(1)}
                          onChange={(e) => {
                            setFilterByTitle(e.target.value);
                            setCurrentPage(1);
                          }}
                          type="search"
                          placeholder="عنوان پست"
                          className="rounded-md outline outline-1 outline-caspian-gray2 p-1.5 font-light text-sm w-full"
                        />
                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-surface text-right px-3 py-6 w-[20%]"
                      >
                        <input
                          onChange={(e) => {
                            setFilterByPartDivision(e.target.value);
                            setCurrentPage(1);
                          }}
                          type="search"
                          placeholder="دسته"
                          className="rounded-md outline outline-1 outline-caspian-gray2 p-1.5 font-light text-sm w-full"
                        />
                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-surface text-right px-3 py-6 w-[20%]"
                      >
                        <input
                          onChange={(e) =>
                            setFilterByDivisionDepartman(e.target.value)
                          }
                          type="search"
                          placeholder="بخش"
                          className="rounded-md outline outline-1 outline-caspian-gray2 p-1.5 font-light text-sm w-full"
                        />
                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-surface text-right px-3 py-6 w-[20%]"
                      >
                        <input
                          onChange={(e) =>
                            setFilterBydDepartment(e.target.value)
                          }
                          type="search"
                          placeholder="دپارتمان"
                          className="rounded-md outline outline-1 outline-caspian-gray2 p-1.5 font-light text-sm w-full"
                        />
                      </th>
                    </tr>
                  </thead> */}
                {records.length > 0 ? (
                  <tbody>
                    {records.map((d, i) => (
                      <SingleTableProfileMeeting
                        key={i}
                        id={d.id}
                        //عنوان
                        title={d?.positionJobRelated?.title}
                        //دسته
                        partDivision={d?.positionPartRelated?.title}
                        //بخش
                        divisionDepartman={d?.divisionRelated}
                        //دپارتمان
                        department={d?.departmanRelated}
                      />
                    ))}
                  </tbody>
                ) : (
                  <tbody className="py-24">
                    <tr>
                      <td className="py-24">
                        <div className="flex items-center justify-center w-full py-10">
                          <div className="z-10 absolute left-1/2 -translate-x-1/2 mx-auto">
                            <div className="flex flex-col gap-2 items-center justify-center w-full">
                              <img
                                src="/assets/images/library/not-found.svg"
                                alt="شغلی یافت نشد..."
                              />
                              <h2>شغلی یافت نشد...</h2>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
      {records.length > 0 && (
        <div className="flex items-center justify-between">
          <RowView
            setRecordsPerPage={setRecordsPerPage}
            setCurrentPage={setCurrentPage}
          />
          <Paginate
            pageCount={npage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
          <div className="text-sm flex items-center gap-4">
            <span>نمایش {firstIndex + 1}</span>
            <span>تا {lastIndex - (recordsPerPage - records.length)}</span>
            <span>از کل {filterDepartment.length}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableListChart;
