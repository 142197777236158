import { useContext, useEffect, useRef, useState } from "react";
import API from "../../../api/API";
import { AuthContext } from "../../../context/auth/AuthContext";

const Notebook = ({
  title,
  counter,
  icon,
  onClick,
  notes,
  showNotesUnderNotebook,
  setShowNotesUnderNotebook,
  setNoteId,
  noteId,
  id,
  setShowEditNote,
  setThisNoteId,
  showEditNote,
  setRefresh,
}) => {
  let menuRef = useRef();
  const { token } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleRightClick = (e) => {
    e.preventDefault(); // جلوگیری از نمایش منوی راست کلیک مرورگر
    setOpen(!open);
  };

  const removeNotebook = () => {
    if (token && id) {
      API.delete(`/api/v1/note/notebook/${id}/`, {
        headers: { Authorization: token },
      })
        .then((res) => {
          console.log(res.data);
          setNoteId(null);
          setShowEditNote([]);
          setRefresh(1);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  console.log(id);
  return (
    <div
      onClick={() => {
        setNoteId(id);
      }}
      className={`flex flex-col gap-1 p-1 ${
        id === noteId && showNotesUnderNotebook
          ? "bg-caspian-secondary-100/20 rounded-md"
          : ""
      }`}
    >
      <div
        ref={menuRef}
        onContextMenu={handleRightClick}
        className="flex items-center justify-between w-full relative"
      >
        {
          <div
            className={
              open
                ? "bg-caspian-primary-200 rounded-md px-2 py-1/2 absolute top-0 left-1/2 -translate-x-1/2 cursor-pointer w-full"
                : "hidden"
            }
          >
            <span onClick={removeNotebook} className="text-[10px]">
              حذف دفترچه <span className="font-bold">{title}</span>
            </span>
          </div>
        }
        <div
          onClick={onClick}
          className="flex items-center gap-1 cursor-pointer"
        >
          <img src={icon} alt="notebook" className="w-6 h-6" />
          <span className="text-xs">{title}</span>
        </div>
        <div className="flex items-center gap-2">
          <span className="text-sm text-caspian-gray">{counter}</span>
        </div>
      </div>
      {id === noteId && showNotesUnderNotebook && (
        <div className="flex flex-col gap-1">
          {notes?.map((note, i) => (
            <div
              onClick={() => {
                setThisNoteId(note?.id);

                !showEditNote.includes(note?.id) &&
                  setShowEditNote((n) => [...n, note?.id]);
              }}
              key={i}
              className="flex items-center gap-1 mr-5 cursor-pointer"
            >
              <span className="text-xs">{note?.title}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Notebook;
