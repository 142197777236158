import React, { useEffect, useState } from "react";
import DocumentsRecordsNavbar from "../DocumentsRecordsNavbar";
import { useAppContext } from "../../../context/app-context/AppContext";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import SearchInput from "./SearchInput";
import { PiPlusCircle } from "react-icons/pi";
import MainCreateFormDocument from '../form/create-form-document/MainCreateFormDocument'

const MainDocuments = ({ showMenu }) => {
  const { setBradCrump1, setBradCrump2, setBradCrump3 } = useAppContext();
  const location = useLocation();
  const [showForm, setShowForm] = useState(false);
  const [refresh, setRefresh] = useState(0);
  useEffect(() => {
    setBradCrump1("مدارک و سوابق");
    setBradCrump2("اسناد و مدارک");
    setBradCrump3("");
  }, [setBradCrump1, setBradCrump2, setBradCrump3]);
  const [folderOpen, setFolderOpen] = useState("internal");
  useEffect(() => {
    if (location.pathname.includes("internal")) {
      setFolderOpen("internal");
    } else if (location.pathname.includes("external")) {
      setFolderOpen("external");
    } else if (location.pathname.includes("obsolete")) {
      setFolderOpen("obsolete");
    } else if (location.pathname.includes("favorite")) {
      setFolderOpen("favorite");
    }
  }, [location]);

  return (
    <div
      className={
        showMenu
          ? `pl-[50px] mr-auto w-full md:w-[75%] lg:w-[80%] xl:w-[85%] 2xl:w-[85%] bg-caspian-gray3 p-1 overflow-y-scroll`
          : `pl-[50px] mr-[54px] w-full bg-caspian-gray3 p-1 overflow-y-scroll`
      }
    >
      <DocumentsRecordsNavbar buttonActive="documents" />
      <div className="flex flex-col gap-2 p-4">
        <div className="flex items-center gap-2">
          <SearchInput />
          {/* <button className="flex items-center gap-1 rounded-md bg-caspian-secondary-500 text-caspian-surface text-sm p-2">
            <PiPlusCircle className="text-xl" />
            <span>ثبت درخواست</span>
          </button> */}
          {
            folderOpen === 'internal' && <button
              onClick={() => setShowForm(true)}
              className="flex items-center gap-1 rounded-md bg-caspian-primary-400 text-caspian-surface text-sm p-2">
              <PiPlusCircle className="text-xl" />
              <span>ایجاد سند</span>
            </button>
          }
          {
            showForm && <MainCreateFormDocument refresh={refresh} setRefresh={setRefresh} setShowForm={setShowForm} />
          }
        </div>
        <div className="flex flex-col gap-0">
          <nav>
            <ul className="flex items-center gap-4 w-full">
              <li>
                <NavLink
                  to={"internal-documents"}
                  className={({ isActive }) =>
                    `${isActive
                      ? "border-b border-b-caspian-secondary-600 text-caspian-secondary-600"
                      : "border-b border-b-caspian-transparent text-caspian-gray"
                    } flex items-center gap-2 w-fit text-sm p-1`
                  }
                >
                  {folderOpen === "internal" ? (
                    <img
                      src="/assets/images/documents-records/folder-open.svg"
                      alt="مستندات و سوابق"
                      className="w-8 h-8"
                    />
                  ) : (
                    <img
                      src="/assets/images/documents-records/folder-close.svg"
                      alt="مستندات و سوابق"
                      className="w-8 h-8"
                    />
                  )}
                  مدارک درون سازمانی
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"external-documents"}
                  className={({ isActive }) =>
                    `${isActive
                      ? "border-b border-b-caspian-secondary-600 text-caspian-secondary-600"
                      : "border-b border-b-caspian-transparent text-caspian-gray"
                    } flex items-center gap-2 w-fit text-sm p-1`
                  }
                >
                  {folderOpen === "external" ? (
                    <img
                      src="/assets/images/documents-records/folder-open.svg"
                      alt="مستندات و سوابق"
                      className="w-8 h-8"
                    />
                  ) : (
                    <img
                      src="/assets/images/documents-records/folder-close.svg"
                      alt="مستندات و سوابق"
                      className="w-8 h-8"
                    />
                  )}
                  مدارک برون سازمانی
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"obsolete-documents"}
                  className={({ isActive }) =>
                    `${isActive
                      ? "border-b border-b-caspian-secondary-600 text-caspian-secondary-600"
                      : "border-b border-b-caspian-transparent text-caspian-gray"
                    } flex items-center gap-2 w-fit text-sm p-1`
                  }
                >
                  {folderOpen === "obsolete" ? (
                    <img
                      src="/assets/images/documents-records/folder-open.svg"
                      alt="مستندات و سوابق"
                      className="w-8 h-8"
                    />
                  ) : (
                    <img
                      src="/assets/images/documents-records/folder-close.svg"
                      alt="مستندات و سوابق"
                      className="w-8 h-8"
                    />
                  )}
                  مدارک منسوخ شده
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"favorite-documents"}
                  className={({ isActive }) =>
                    `${isActive
                      ? "border-b border-b-caspian-secondary-600 text-caspian-secondary-600"
                      : "border-b border-b-caspian-transparent text-caspian-gray"
                    } flex items-center gap-2 w-fit text-sm p-1`
                  }
                >
                  {folderOpen === "favorite" ? (
                    <img
                      src="/assets/images/documents-records/folder-open.svg"
                      alt="مستندات و سوابق"
                      className="w-8 h-8"
                    />
                  ) : (
                    <img
                      src="/assets/images/documents-records/folder-close.svg"
                      alt="مستندات و سوابق"
                      className="w-8 h-8"
                    />
                  )}
                  علاقه مندی ها
                </NavLink>
              </li>
            </ul>
          </nav>
          <Outlet context={[refresh,setRefresh]} />
        </div>
      </div>
    </div>
  );
};

export default MainDocuments;
