import React, { useEffect } from "react";
import AllLibrary from "./all-library/AllMainLibrary";
import { useAppContext } from "../../context/app-context/AppContext";

const ListItemSingle = ({
  active,
  title,
  icon,
  onClick,
  setLibraryDetails,
  buttonActive,
}) => {
  const { setNavbarLibraryActive } = useAppContext();

  useEffect(() => {
    if (active === "all") {
      setLibraryDetails(<AllLibrary />);
      setNavbarLibraryActive("all");
    } else if (active === "book") {
      setLibraryDetails(<AllLibrary />);
      setNavbarLibraryActive("book");
    } else if (active === "article") {
      setLibraryDetails(<AllLibrary />);
      setNavbarLibraryActive("article");
    } else if (active === "standard") {
      setNavbarLibraryActive("standard");
      setLibraryDetails(<AllLibrary />);
    } else if (active === "film") {
      setNavbarLibraryActive("film");
      setLibraryDetails(<AllLibrary />);
    } else if (active === "podcast") {
      setNavbarLibraryActive("podcast");
      setLibraryDetails(<AllLibrary />);
    } else if (active === "other") {
      setNavbarLibraryActive("other");
      setLibraryDetails(<AllLibrary />);
    } else if (active === "favorites") {
      setLibraryDetails(<AllLibrary />);
      setNavbarLibraryActive("favorites");
    }
  }, [active, setLibraryDetails, setNavbarLibraryActive]);
  return (
    <li
      onClick={onClick}
      className={`${
        buttonActive === active
          ? "bg-caspian-gray3 border border-caspian-secondary-400 border-b-0 rounded-t-md font-bold"
          : "border-b-2 border-dashed border-caspian-gray"
      } flex items-center gap-2 px-4 py-2 cursor-pointer text-sm text-caspian-secondary-900`}
    >
      <div
        className={`${
          buttonActive === active ? "text-2xl" : "text-xl"
        } text-caspian-secondary-900`}
      >
        {icon}
      </div>
      <span>{title}</span>
    </li>
  );
};

export default ListItemSingle;
