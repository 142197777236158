import { useNavigate } from "react-router-dom";

const SingleRowTable = ({ id, title, code, colorHex, sectionRelated }) => {
    const navigate = useNavigate();
    return (
        <tr className='cursor-pointer text-xs 2xl:text-sm text-center' onClick={() => navigate(`/stackholders/${id}`)}>


            <td className='w-[40%] py-2 font-light border-b border-caspian-gray2'
                title={title}
                style={{ backgroundColor: `#${colorHex}` }}
            >
                <p className="max-w-[90%] mx-auto truncate text-center">
                    {/* {title?.length > 20 ? title?.slice(0, 20) + '...' : title} */}
                    {title}
                </p>
            </td>

            <td className='w-[25%] truncate py-2 text-center font-light border-b border-caspian-gray2 text-caspian-surface' title={sectionRelated?.title}
                style={{ backgroundColor: `#${sectionRelated.colorHex}` }}
            >
                <p className="max-w-[90%] mx-auto truncate text-center">
                    {/* مشتریان مشتریان */}
                    {sectionRelated?.title}
                </p>
            </td>

            <td className='max-w-[20%] truncate py-2 font-light border-b border-caspian-gray2 text-center text-caspian-surface' title={sectionRelated?.categoryRelated?.title}
                style={{ backgroundColor: `#${sectionRelated?.categoryRelated?.colorHex}` }}>

                <p className="max-w-[90%] mx-auto truncate text-center">
                    {/* اجتماعی */}
                    {sectionRelated?.categoryRelated?.title}
                </p>
            </td>

            <td className='w-[15%] truncate py-2 font-light border-b border-caspian-gray2 text-center' title={sectionRelated?.categoryRelated?.categoryType === 'internal' ? "درون سازمانی" : "برون سازمانی"}>
                <p className="max-w-[90%] mx-auto truncate text-center">
                    {/* درون سازمانی */}
                    {sectionRelated?.categoryRelated?.categoryType === 'internal' ? "درون سازمانی" : "برون سازمانی"}
                </p>
            </td>
        </tr>
    );
}

export default SingleRowTable;
