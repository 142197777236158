import React, { useState } from "react";
import { PiCaretLeftBold } from "react-icons/pi";
import ActivitiesTableSingle from "./ActivitiesTableSingle";

const ActivitiesTable = ({ data }) => {
  const [showCollapse, setShowCollapse] = useState(false);
  return (
    <div className="w-full bg-caspian-surface rounded-xl shadow-md">
      <div
        onClick={() => setShowCollapse(!showCollapse)}
        className="w-full flex items-center justify-between p-3 cursor-pointer"
      >
        <div className="flex items-center gap-2 text-caspian-secondary-600">
          <span className="font-medium">فعالیت ها (گام های فرایند)</span>
        </div>
        <button>
          <PiCaretLeftBold
            className={
              showCollapse ? "-rotate-90 duration-300" : "rotate-0 duration-300"
            }
          />
        </button>
      </div>
      {showCollapse && (
        <div className="p-3">
          <div className="border border-caspian-gray2 rounded-lg overflow-auto">
            {data.length < 1 ? (
              <h2 className="text-caspian-gray2 p-4 w-full flex items-center justify-center">
                فعالیتی وجود ندارد!
              </h2>
            ) : (
              <table className="min-w-full text-left text-sm">
                <thead className="bg-[#FBFCFD] p-4 border-b border-caspian-gray2">
                  <tr>
                    <th scope="col" className="text-right px-3 py-6 ">
                      <h2 className="text-caspian-gray">عنوان</h2>
                    </th>
                    <th scope="col" className="text-right px-3 py-6">
                      <h2 className="text-caspian-gray">مسئول</h2>
                    </th>
                    <th scope="col" className="text-right px-3 py-6">
                      <h2 className="text-caspian-gray">
                        دستورالعمل/آیین‌نامه
                      </h2>
                    </th>
                    <th scope="col" className="text-right px-3 py-6">
                      <h2 className="text-caspian-gray">عنوان سیستم</h2>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {data.map((d, i) => (
                    <ActivitiesTableSingle
                      key={i}
                      title={d.title}
                      responsible={d.activityJanamayiResponsible}
                      procedure={d.procedure}
                      system={d.software}
                      className={i % 2 !== 0 && "bg-[#FBFCFD]"}
                    />
                  ))}
                  {/* <ActivitiesTableSingle
                  title="آغاز پروژه"
                  responsible="نماینده دفتر  مدیریت پروژه در شرکت / حوزه تخصصی مربوطه"
                  procedure="دستورالعمل 1"
                  system="فایل مدیریت پروژه"
                />
                <ActivitiesTableSingle
                  title="برنامه ریزی پروژه"
                  responsible="نماینده حوزه تخصصی ناظر بر پروژه"
                  procedure="دستورالعمل 1"
                  system="فایل مدیریت پروژه"
                  className="bg-[#FBFCFD]"
                />
                <ActivitiesTableSingle
                  title="اجرا پروژه"
                  responsible="نماینده حوزه تخصصی ناظر بر پروژه"
                  procedure="دستورالعمل 1"
                  system="عنوان سیستم"
                />
                <ActivitiesTableSingle
                  title="کنترل پروژه"
                  responsible="نمایند کنترل پروژه در شرکت/حوزه تخصصی مربوطه"
                  procedure="دستورالعمل 1"
                  system="عنوان سیستم"
                  className="bg-[#FBFCFD]"
                />
                <ActivitiesTableSingle
                  title="خاتمه پروژه"
                  responsible="نماینده حوزه تخصصی ناظر بر پروژه"
                  procedure="دستورالعمل 1"
                  system="عنوان سیستم"
                /> */}
                </tbody>
              </table>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivitiesTable;
