import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { PiCaretLeft, PiPlusCircle } from "react-icons/pi";
import Details from "./Details";
import { useAppContext } from "../../../../../context/app-context/AppContext";
import API from "../../../../../api/API";

const MainExternalProfile = ({ showMenu }) => {
  const params = useParams();

  const location = useLocation();

  const search = (location.state && location.state.search) || "";
  const [fullData, setFullData] = useState([-1]);
  const [loading, setLoading] = useState(false);
  const { setBradCrump1, setBradCrump2, setBradCrump3, setBrcLink2 } =
    useAppContext();
  useEffect(() => {
    setBradCrump1("مدارک و سوابق");
    setBradCrump2("اسناد و مدارک");
    setBrcLink2(location.pathname.split(`/${params.id}`)[0]);
    // setBrcLink2(location.pathname.split(`/${params.code}`)[0]);
    setBradCrump3(fullData[0] ? fullData[0].title : "");
  }, [
    setBradCrump1,
    setBradCrump2,
    setBradCrump3,
    setBrcLink2,
    params,
    fullData,
    location,
  ]);

  useEffect(() => {
    setLoading(true);
    API.get(`/api/v1/deocument/external/${params.id}/`)
      .then((d) => {
        // setFullData(d.data.filter((d) => (d?.code).toString() === params.code));
        setFullData(d.data);
      })
      .catch((err) => {
        console.log("error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params]);

  console.log(fullData);

  return (
    <div
      className={
        showMenu
          ? `pl-[50px] mr-auto w-full md:w-[75%] lg:w-[80%] xl:w-[85%] 2xl:w-[85%] bg-caspian-gray3 p-1 overflow-y-scroll`
          : `pl-[50px] mr-[54px] w-full bg-caspian-gray3 p-1 overflow-y-scroll`
      }
    >
      <div className="flex items-center justify-between w-full px-2 pt-1">
        <button className="flex items-center gap-1 rounded-md bg-caspian-secondary-500 text-caspian-surface text-sm p-2">
          <PiPlusCircle className="text-xl" />
          <span>ثبت درخواست</span>
        </button>
        <Link
          to={`./..${search}`}
          className="mr-auto flex items-center gap-2 text-caspian-secondary-700 p-2"
        >
          <span className="">بازگشت</span>
          <PiCaretLeft />
        </Link>
      </div>
      <>
        {fullData[0] === -1 ? (
          <div className="flex items-center justify-center py-20">
            <h2 className="text-caspian-gray text-sm">
              در حال بارگذاری اطلاعات...
            </h2>
          </div>
        ) : fullData.length < 1 ? (
          <div className="flex items-center justify-center py-20">
            <h2 className="text-caspian-gray text-sm">شناسنامه ای یافت نشد!</h2>
          </div>
        ) : (
          <div className="flex flex-col gap-2 w-full lg:w-[90%] xl:w-[80%] h-auto p-2">
            <div className="flex items-center justify-center w-full h-auto rounded-xl bg-caspian-surface shadow-[0_0_5px_#00000010] border border-caspian-gray2 p-4">
              <Details data={fullData} />
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default MainExternalProfile;
