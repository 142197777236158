import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { PiCaretLeft, PiChatsCircle, PiPencilSimpleLine } from "react-icons/pi";
import MainProfile from "./profile/MainProfile";
import { useContext, useEffect, useState } from "react";
import ButtonTab from "./ButtonTab";
import MainPlanning from "./profile/MainPlanning";
import CommentsMain from "../../comments/CommentsMain";
import { AuthContext } from "../../../../context/auth/AuthContext";

const MainProfileActionplan = () => {
  const { token, user } = useContext(AuthContext)

  const [loading, actionplanId, fullData, costData, setRefresh] =
    useOutletContext();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("profile");
  useEffect(() => {
    setRefresh(1);
  }, [setRefresh]);

  const [showComments, setShowComments] = useState(false);
  const [serachParams, setSearchParams] = useSearchParams();
  const [isAccessComment, setIsAccessComment] = useState(false)
  const p = serachParams.get("p");

  useEffect(() => {
    if (p === "profile") {
      setActiveTab("profile");
    } else if (p === "planning") {
      setActiveTab("planning");
    } else {
      setActiveTab("profile");
    }
  }, [p]);

  function handleSearch(key, value) {
    setSearchParams((prevParams) => {
      if (value === null || value === "") {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    });
  }
  useEffect(() => {
    setIsAccessComment(user?.placementProfileRelated?.id ===
      (fullData?.actionplan_responsibles?.manager?.id
        || fullData?.actionplan_responsibles?.accounting_observer?.placementProfileRelated?.id
        || fullData?.actionplan_responsibles?.approval_placement?.placementProfileRelated?.id
        || fullData?.actionplan_responsibles?.budget_observer?.placementProfileRelated?.id
        || fullData?.actionplan_responsibles?.general_observer?.placementProfileRelated?.id
        || fullData?.actionplan_responsibles?.hse_observer?.placementProfileRelated?.id
        || fullData?.actionplan_responsibles?.legal_observer?.placementProfileRelated?.id
        || fullData?.actionplan_responsibles?.tech_observer?.placementProfileRelated?.id
        || fullData?.actionplan_responsibles?.varify_placement?.placementProfileRelated?.id
      )
      ? true
      : false)
  }, [user, fullData])

  // console.log(user?.actionplan_responsibles)

  console.log(isAccessComment)


  // console.log(user)
  // console.log(user?.placementProfileRelated?.id === ())

  return loading ? (
    <div className="flex items-center justify-center w-full py-12">
      <span className="text-xs text-caspian-gray4">در حال بارگزاری...</span>
    </div>
  ) : fullData.length < 1 ? (
    <div className="flex items-center justify-center w-full py-12">
      <span className="text-xs text-caspian-gray4">
        شما به این صفحه دسترسی ندارید
      </span>
    </div>
  ) : (
    <div className="flex flex-col gap-2 w-full">
      <div className="flex items-center justify-between w-full">
        <button
          onClick={() => navigate(-1)}
          className="flex gap-2 text-caspian-secondary-700 p-2 mr-auto"
        >
          <span className="text-sm">بازگشت</span>
          <PiCaretLeft />
        </button>
      </div>

      <div className="w-full relative md:w-[90%] lg:w-85%] xl:w-[75%] p-2 rounded-md shadow-[0_0_10px_#00000050] min-h-screen bg-caspian-surface flex flex-col gap-4">
        <div className="flex items-center justify-between py-1.5 px-2 rounded-md">
          <div className="flex items-center gap-4 py-1.5 px-2 rounded-md">
            <ButtonTab
              activeTab={activeTab}
              active="profile"
              onClick={() => {
                setActiveTab("profile");
                handleSearch("p", "profile");
              }}
              title="پروفایل"
            />
            <ButtonTab
              activeTab={activeTab}
              active="planning"
              onClick={() => {
                setActiveTab("planning");
                handleSearch("p", "planning");
              }}
              title="برنامه"
            />
          </div>

          {isAccessComment &&
            fullData?.statusAction !== "PMOPlan" &&
            fullData?.statusAction !== "ManagerPlan" &&
            fullData?.statusAction !== "StartTasks" && (
              <PiChatsCircle
                className="text-2xl absolute left-5 cursor-pointer text-caspian-secondary-500"
                onClick={() => setShowComments(true)}
              />
            )}
          {showComments && <CommentsMain isAccessComment={isAccessComment} setShowComments={setShowComments} />}

        </div>
        {fullData?.userRole?.includes("MANAGER") && (
          <button
            onClick={() =>
              navigate(`/action-plan/my-actionplan/${actionplanId}/action`)
            }
            className="w-fit flex items-center gap-1 rounded-md border border-caspian-secondary-500 text-caspian-secondary-500 text-sm p-2"
          >
            <span className="text-sm">ویرایش پروفایل</span>
            <PiPencilSimpleLine className="text-xl" />
          </button>
        )}
        {activeTab === "profile" ? (
          <MainProfile
            loading={loading}
            actionPlanData={fullData}
            costData={costData}
            setRefresh={setRefresh}
          />
        ) : (
          <MainPlanning taskData={fullData?.task_actionplan} />
        )}
      </div>
    </div>
  );
};

export default MainProfileActionplan;
