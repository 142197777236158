import React, { useContext, useState } from "react";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import API from "../../../../../api/API";
import { PiCheckCircleFill, PiTrash, PiXCircleFill } from "react-icons/pi";

const ApprovalSingle = ({
  setRefresh,
  id,
  title,
  runner,
  follower,
  output,
  deadline,
  setRefreshFullData,
}) => {
  const { token } = useContext(AuthContext);
  const [showEditBox, setShowEditBox] = useState(false);
  const [showIsDelete, setShowIsDelete] = useState(false);

  const removeHandler = () => {
    API.delete(`/api/v1/meeting/info/aproval/${id}/`, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        setRefreshFullData(1);
        setRefresh(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <tr
      onMouseEnter={() => setShowEditBox(true)}
      onMouseLeave={() => setShowEditBox(false)}
      className="bg-caspian-surface border-t border-t-caspian-gray1 min-w-full"
    >
      <td className="p-2.5">{title ? title : "نامشخص"}</td>
      <td className="p-2.5">{runner ? runner : "نامشخص"}</td>
      <td className="p-2.5">{follower ? follower : "نامشخص"}</td>
      <td className="p-2.5">{output ? output : "نامشخص"}</td>
      <td className="p-2.5">
        {deadline ? new Date(deadline).toLocaleDateString("fa-IR") : "نامشخص"}
      </td>
      <td className="">
        {showEditBox && (
          <div className="w-full flex items-center justify-center gap-1 relative mx-auto">
            <button
              onClick={async () => {
                await setShowIsDelete(true);
              }}
              className="text-caspian-error2/30 hover:text-caspian-error2 duration-300 text-lg"
            >
              <PiTrash />
            </button>
            <div
              className={
                showIsDelete
                  ? "absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center mx-auto gap-0.5 w-fit bg-caspian-surface shadow-[0_0_5px_#00000050] p-0.5 rounded-md duration-500"
                  : "hidden duration-500"
              }
            >
              <PiCheckCircleFill
                onClick={() => {
                  removeHandler();
                  setShowIsDelete(false);
                }}
                className="text-caspian-primary-600 text-lg cursor-pointer"
              />
              <PiXCircleFill
                onClick={() => {
                  setShowIsDelete(false);
                }}
                className="text-caspian-gray text-lg cursor-pointer"
              />
            </div>
          </div>
        )}
      </td>
    </tr>
  );
};

export default ApprovalSingle;
