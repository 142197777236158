import React, { useContext, useState } from "react";
import { PiCaretDown } from "react-icons/pi";
import ColorPicker from "./ColorPicker";
import { AuthContext } from "../../../context/auth/AuthContext";
import API from "../../../api/API";
import { useSearchParams } from "react-router-dom";

const FormNote = ({
  notebook,
  setShowAddNote,
  setRefresh,
  color,
  setColor,
}) => {
  const [serachParams, setSearchParams] = useSearchParams();
  const folder = serachParams.get("folder");

  const [colorPicker, setColorPicker] = useState(false);
  // const [color, setColor] = useState("#FFF7CB");

  const { user, token } = useContext(AuthContext);

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const addNote = () => {
    if (!title || !user) return null;
    API.post(
      "/api/v1/note/note/",
      {
        title: title,
        content: content,
        color: color,
        notebook: folder ? folder : notebook,
      },
      {
        headers: { Authorization: token },
      }
    )
      .then((res) => {
        console.log(res.data);
        setShowAddNote(false);
        setRefresh(1);
      })
      .catch((err) => {
        console.log("error");
      });
  };
  return (
    <div className="w-full flex flex-col gap-2">
      <div className="w-full flex items-start gap-2">
        <input
          type="text"
          className="w-[60%] outline-none border border-caspian-gray2 rounded-md p-2 text-xs"
          placeholder="عنوان یادداشت"
          onChange={(e) => setTitle(e.target.value)}
        />
        <div className="w-[40%]">
          <div
            onClick={() => setColorPicker(!colorPicker)}
            className="relative cursor-pointer flex items-center justify-center w-full gap-2 p-2 rounded-md text-caspian-gray4 border border-caspian-gray2 bg-caspian-surface"
          >
            <div
              className={`w-4 h-4 rounded-full bg-[${color}] bg-opacity-50`}
            ></div>
            <span className="text-xs">رنگ یادداشت</span>
            <PiCaretDown className="text-base" />
            {colorPicker && (
              <ColorPicker
                setColor={setColor}
                setColorPicker={setColorPicker}
              />
            )}
          </div>
        </div>
      </div>
      <div>
        <textarea
          rows={16}
          className="w-full outline-none border border-caspian-gray2 rounded-md p-2 text-xs resize-none"
          placeholder="متن یادداشت"
          onChange={(e) => setContent(e.target.value)}
        />
      </div>
      <div className="flex items-center gap-4">
        <button
          onClick={addNote}
          className="text-xs rounded-sm bg-caspian-secondary-500 text-caspian-surface px-3 py-1"
        >
          ایجاد
        </button>
        <button
          onClick={() => setShowAddNote(false)}
          className="text-xs text-caspian-error2"
        >
          انصراف
        </button>
      </div>
    </div>
  );
};

export default FormNote;
