import React from 'react'

const HistorySingle = ({ fullName, profileHistory, positionHistory, edite, time, date }) => {
    return (
        <div className='border-b-2 border-caspian-gray3 last:border-none'>
            <div className='flex items-center py-3 w-[70%] '>
                <div className='flex items-center gap-2 basis-1/3'>
                    <img src={profileHistory} alt='' className='w-8 h-8' ></img>
                    <div className='flex items-end gap-2'>
                        <span className='text-sm '>{fullName}</span>
                        <span className='text-sm text-caspian-gray'>{positionHistory}</span>
                    </div>
                </div>

                <div className='flex items-center gap-2 basis-1/3'>
                    <span className='text-caspian-gray text-sm'>{edite}</span>
                </div>

                <div className='flex gap-2 basis-1/3'>
                    <span className='text-caspian-gray text-sm'>{time}</span>
                    <span className='text-caspian-gray text-sm'>{date}</span>
                </div>
            </div>
        </div>
    )
}

export default HistorySingle