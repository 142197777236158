import React, { useContext, useEffect, useState } from "react";
import { PiCloudArrowUpLight } from "react-icons/pi";
import { toast } from "react-toastify";
import API from "../../../../../api/API";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import PresentationSingle from "./presentation-file/PresentationSingle";
import PresentationOldSingle from "./presentation-file/PresentationOldSingle";

const PresentationFile = ({
  documentsFile,
  fullData,
  thisId,
  setActiveTab,
  setShowPlanningMeetingForm,
  setRefresh,
  setMeetingListRefresh
}) => {
  const { token } = useContext(AuthContext);
  const [disableButton, setDisableButton] = useState(false);

  console.log(fullData);

  const [file, setFile] = useState(null);
  const [fileId, setFileId] = useState([]);
  const [oldFileId, setOldFileId] = useState(
    fullData[0]?.documentsRelated.map((f) => f.id)
  );

  useEffect(() => {
    if (file !== null) {
      const getFile = async () => {
        await API.post(
          "/api/v1/meeting/info/document/",
          {
            document: file,
          },
          {
            headers: {
              Authorization: token,
              "content-type": "multipart/form-data",
              Accept: "application/json",
            },
          }
        )
          .then((res) => {
            setFileId((f) => [...f, { id: res?.data?.id, file: file }]);
            console.log(res.data);
            // setFile(null);
          })
          .catch((err) => console.log(err));
      };
      getFile();
    } else if (file === null || file === undefined) {
      setFile(null);
    }
  }, [file, token]);

  const getFileId = async () => {
    if (fileId.length < 1) return null;
    if (fullData[0]?.id) {
      await API.patch(
        `/api/v1/meeting/info/presentation/${fullData[0]?.id}/`,
        {
          documentsRelated: oldFileId.concat(fileId?.map((d) => d?.id)),
          // documentsRelated: fileId?.map((d) => d?.id),
        },
        {
          headers: {
            Authorization: token,
            Accept: "application/json",
          },
        }
      )
        .then((res) => {
          setRefresh(1);
          setFileId([]);

          console.log(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      await API.post(
        "/api/v1/meeting/info/presentation/",
        {
          documentsRelated: fileId?.map((d) => d?.id),
          MeetingPresentationRelated: +thisId,
        },
        {
          headers: {
            Authorization: token,
            Accept: "application/json",
          },
        }
      )
        .then((res) => {
          setRefresh(1);
          setFileId([]);

          console.log(res.data);
        })
        .catch((err) => console.log(err));
    }
  };

  const dataHandler = async () => {
    await getFileId();
  };

  const dataHandlerDraft = async () => {
    // e.preventDefault();
    await API.post(
      `/api/v1/meeting/info/detail/${thisId}/status/`,
      {
        status: "presentation",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await dataHandler();
        await toast.success("فایل های ارائه جلسه به صورت پیشنویس ثبت شد.", {
          className: "toast-success",
          bodyClassName: "toast-success",
        });
        await setShowPlanningMeetingForm(false);
        await setMeetingListRefresh(1);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataHandlerNext = async () => {
    // e.preventDefault();
    await API.post(
      `/api/v1/meeting/info/detail/${thisId}/status/`,
      {
        status: "reminder",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await dataHandler();
        await setActiveTab("reminder");
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataHandlerBack = async () => {
    await setActiveTab("meetingOrder");
  };

  useEffect(() => {
    if (documentsFile?.length < 1 && fileId?.length < 1) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [documentsFile, fileId]);
  return (
    <div className="min-h-[270px] flex flex-col justify-between">
      <div className="flex flex-col gap-4 w-full">
        <div className="flex flex-wrap gap-2">
          {fileId?.map((d, i) => (
            <PresentationSingle
              key={i}
              data={d}
              setFileId={setFileId}
              fileId={fileId}
            />
          ))}
        </div>
        {documentsFile?.length < 1 && fileId?.length < 1 ? (
          <h2 className="text-xs text-caspian-gray1">فایلی اضافه نشده است!</h2>
        ) : (
          <div className="flex flex-wrap gap-2">
            {fullData?.map((d, i) =>
              d.documentsRelated.map((f, i) => (
                <PresentationOldSingle
                  setRefresh={setRefresh}
                  key={i}
                  data={f}
                  oldFileId={oldFileId}
                  setOldFileId={setOldFileId}
                />
              ))
            )}
          </div>
        )}

        <div className="flex items-center justify-center w-full">
          <div className="border border-dashed border-caspian-gray2 p-4 rounded-md flex items-center gap-16">
            <div className="flex items-center gap-4">
              <PiCloudArrowUpLight className="text-4xl text-caspian-gray" />
              <div className="flex flex-col justify-center gap-1">
                <span className="text-xs font-bold">
                  لطفا فایل خود را انتخاب کنید
                </span>
                <span className="text-xs text-caspian-gray2">
                  فایل exl و حداکثر 20 مگابایت
                </span>
              </div>
            </div>

            <div>
              <label
                htmlFor="imageFile"
                className="p-2 border border-caspian-secondary-500 rounded-md text-caspian-secondary-500 cursor-pointer"
              >
                <span className="text-xs">افزودن فایل</span>
                <input
                  name="file"
                  className="hidden"
                  id="imageFile"
                  type="file"
                  accept=".png,.jpg"
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                    console.log(e.target.files[0]);
                  }}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <button
          onClick={async () => {
            await dataHandlerBack();
          }}
          className="min-w-[6rem] outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs p-1.5 rounded-md"
        >
          مرحله قبل
        </button>
        <button
          disabled={disableButton}
          onClick={async () => {
            await dataHandlerNext();
          }}
          className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
        >
          مرحله بعد
        </button>
        <button
          disabled={disableButton}
          onClick={async () => {
            await dataHandlerDraft();
          }}
          className={`disabled:cursor-not-allowed disabled:text-caspian-gray text-caspian-secondary-500 text-xs p-1.5 rounded-md`}
        >
          ذخیره پیشنویس
        </button>
      </div>
    </div>
  );
};

export default PresentationFile;
