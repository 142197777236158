import React, { useState } from "react";
import { PiCaretLeftBold } from "react-icons/pi";
// import SystematicProblems from "./SystematicProblems";
// import SystemRequirements from "./SystemRequirements";
// import BusinessProblems from "./BusinessProblems";
// import BusinessRequirements from "./BusinessRequirements";
import MainChallenge from "./MainChallenge";

const Requirements = ({ data }) => {
  const [showCollapse, setShowCollapse] = useState(false);
  return (
    <div className="w-full bg-caspian-surface rounded-xl shadow-md">
      <div
        onClick={() => setShowCollapse(!showCollapse)}
        className="w-full flex items-center justify-between p-3 cursor-pointer"
      >
        <div className="flex items-center gap-2 text-caspian-secondary-600">
          {/* <PiNewspaperClipping className="text-2xl" /> */}
          <span className="font-medium">چالش ها</span>
        </div>
        <button>
          <PiCaretLeftBold
            className={
              showCollapse ? "-rotate-90 duration-300" : "rotate-0 duration-300"
            }
          />
        </button>
      </div>
      {showCollapse && (
        <div className="p-3 space-y-2">
          {data.map((d, i) => (
            <MainChallenge data={d} key={i} />
          ))}
          {/* <SystematicProblems />
          <BusinessProblems />
          <SystemRequirements />
          <BusinessRequirements /> */}
        </div>
      )}
    </div>
  );
};

export default Requirements;
