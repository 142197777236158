import React from "react";

const ResponsibleSingle = ({ title, fullName }) => {
  return (
    <div className="flex items-center gap-2 border-b border-b-caspian-gray2 last:border-none pb-2">
      <span className="text-xs text-caspian-gray4 basis-[40%]">{title}:</span>
      <span className="text-xs">{fullName}</span>
    </div>
  );
};

export default ResponsibleSingle;
