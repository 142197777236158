import React from "react";
import NewDetails from "./NewDetails";

const MainDetailsSingleSent = () => {
  return (
    <div className="min-h-screen">
      <NewDetails />
    </div>
  );
};

export default MainDetailsSingleSent;
