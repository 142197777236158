import React from "react";

const MainMembers = ({ data }) => {
  return (
    <div className="p-4 pb-2 flex flex-col gap-4">
      <span className="text-[#45706E] text-sm">تیم ممیزی</span>
      <div className="flex flex-col gap-1 border-b-2 border-b-caspian-gray2/40">
        {/* سرپرست ممیزی */}
        <div className="flex items-center gap-4 p-2 bg-caspian-gray2/50 w-full">
          <span className="text-xs text-[#01BC8D]">سرپرست ممیزی</span>
          <span className="text-xs">
            {data?.responsibleTeam?.profileAuditorRelated?.firstName +
              " " +
              data?.responsibleTeam?.profileAuditorRelated?.lastName}
          </span>
        </div>
        {/* ممیزها */}
        <div className="flex items-center gap-4 p-2 bg-caspian-gray2/50 w-full">
          <span className="text-xs text-caspian-secondary-500">ممیز</span>
          <div className="flex items-center flex-wrap gap-4">
            {data?.teamRelated?.map((m, i) => (
              <span key={i} className="text-xs">
                {m?.profileAuditorRelated?.firstName +
                  " " +
                  m?.profileAuditorRelated?.lastName}
              </span>
            ))}
          </div>
        </div>
        {/* کارشناس فنی */}
        <div className="flex items-center gap-4 p-2 bg-caspian-gray2/50 w-full">
          <span className="text-xs text-caspian-secondary-500">
            کارشناس فنی
          </span>
          <div className="flex items-center flex-wrap gap-4">
            {data?.expert?.length < 1 ? (
              <span className="text-xs">-</span>
            ) : (
              data?.expert?.map((m, i) => (
                <span key={i} className="text-xs">
                  {m?.firstName + " " + m?.lastName}
                </span>
              ))
            )}
          </div>
        </div>
        {/* مشاهده‌گر */}
        <div className="flex items-center gap-4 p-2 bg-caspian-gray2/50 w-full">
          <span className="text-xs text-caspian-secondary-500">مشاهده‌گر</span>
          <div className="flex items-center flex-wrap gap-4">
            {data?.observer?.length < 1 ? (
              <span className="text-xs">-</span>
            ) : (
              data?.observer?.map((m, i) => (
                <span key={i} className="text-xs">
                  {m?.firstName + " " + m?.lastName}
                </span>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainMembers;
