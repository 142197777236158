import React, { useEffect, useRef, useState } from "react";
import { PiSortAscending, PiCheck } from "react-icons/pi";
import { useSearchParams } from "react-router-dom";

const Sort = ({ setCurrentPage, data, setData }) => {
  const [open, setOpen] = useState(false);
  let menuRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const [serachParams, setSearchParams] = useSearchParams();
  const sortData = serachParams.get("sort");
  function handleFilterChange(key, value) {
    setSearchParams((prevParams) => {
      if (value === null || value === "") {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    });
  }

  return (
    <div ref={menuRef} className="relative">
      <button
        onClick={() => setOpen(!open)}
        className="flex items-center gap-2 rounded-md outline outline-1 outline-caspian-secondary-900 px-2 py-1 text-caspian-secondary-900"
      >
        <PiSortAscending />
        <span className="text-sm">مرتب سازی</span>
      </button>
      <div
        className={
          open
            ? "w-[138px] rounded-xl shadow-[0_0_10px_#00000050] bg-caspian-surface absolute top-8 right-0 z-50 overflow-hidden"
            : "hidden"
        }
      >
        <div className="flex flex-col">
          <button
            disabled={sortData === "date" ? true : false}
            onClick={() => {
              setCurrentPage(1);
              handleFilterChange("sort", "date");
              data[0] !== -1 &&
                setData(data.sort((a, b) => (b.id > a.id ? 1 : -1)));
            }}
            type="button"
            className={`${
              sortData === "date"
                ? "bg-caspian-gray3/50"
                : "hover:bg-caspian-gray3"
            } px-4 py-2 duration-300 text-sm w-full text-right relative`}
          >
            <span>آخرین مطالب</span>
            {sortData === "date" && (
              <PiCheck className="absolute left-2 top-1/2 -translate-y-1/2 text-caspian-primary-500" />
            )}
          </button>
          <button
            disabled={sortData === "atoz" ? true : false}
            onClick={() => {
              setCurrentPage(1);
              handleFilterChange("sort", "atoz");
              data[0] !== -1 &&
                setData(
                  data.sort((a, b) =>
                    a.title.toLocaleLowerCase() > b.title.toLocaleLowerCase()
                      ? 1
                      : -1
                  )
                );
            }}
            className={`${
              sortData === "atoz"
                ? "bg-caspian-gray3/50"
                : "hover:bg-caspian-gray3"
            } px-4 py-2 duration-300 text-sm w-full text-right relative`}
          >
            {sortData === "atoz" && (
              <PiCheck className="absolute left-2 top-1/2 -translate-y-1/2 text-caspian-primary-500" />
            )}
            <span>حروف الفبا</span>
          </button>
          <button
            disabled={sortData === "view" ? true : false}
            onClick={() => {
              setCurrentPage(1);
              handleFilterChange("sort", "view");
              data[0] !== -1 &&
                setData(data.sort((a, b) => (b.viewers > a.viewers ? 1 : -1)));
            }}
            className={`${
              sortData === "view"
                ? "bg-caspian-gray3/50"
                : "hover:bg-caspian-gray3"
            } px-4 py-2 duration-300 text-sm w-full text-right relative`}
          >
            {sortData === "view" && (
              <PiCheck className="absolute left-2 top-1/2 -translate-y-1/2 text-caspian-primary-500" />
            )}
            <span>پربازدیدترین</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sort;
