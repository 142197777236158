import { useContext, useEffect, useState } from "react";
import TableListListMeeting from "./TableListTeams";
import API from "../../../../api/API";
import { AuthContext } from "../../../../context/auth/AuthContext";

const MainListTeams = ({ refreshTeamsList, setRefreshTeamsList }) => {
  const { token } = useContext(AuthContext);
  const [fullData, setfullData] = useState([-1]);

  useEffect(() => {
    if (token && refreshTeamsList === 1) {
      const getTeams = async () => {
        try {
          const res = await API.get("/api/v1/risk/team/detail/", {
            headers: { Authorization: token },
          });
          setfullData(res.data);
          setRefreshTeamsList(0);
        } catch (error) {
          console.log(error);
        }
      };
      getTeams();
    }
  }, [token, refreshTeamsList, setRefreshTeamsList]);

  useEffect(() => {
    setRefreshTeamsList(1);
  }, [setRefreshTeamsList]);

  return (
    <TableListListMeeting
      listTeams={fullData}
      setRefreshTeamsList={setRefreshTeamsList}
    />
  );
};

export default MainListTeams;
