import React, { useEffect, useState } from 'react'
import SingleListReportActionPlan from './SingleListReportActionPlan'
import RowsReportsActionPlan from './RowsReportsActionPlan'
import PaginateReportsActionPlan from './PaginateReportsActionPlan'
import { useSearchParams } from 'react-router-dom'
import Loading from '../../../../../Loading'

const TableListReportsActionPlan = ({ sortData, fullData }) => {

    const [searchParams] = useSearchParams();
    const search = searchParams.get("search");
    const page = searchParams.get("page");
    const rows = searchParams.get('rows');
    const [currentPage, setCurrentPage] = useState(page ? page : 1);
    const [recordsPerPage, setRecordsPerPage] = useState(rows ? rows : 10);
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = sortData.slice(firstIndex, lastIndex);
    const npage = Math.ceil(sortData.length / recordsPerPage);
    useEffect(() => {
        if (search) {
            setCurrentPage(1);
        }
    }, [search]);

    return (
        fullData[0] === -1 ? <Loading /> : fullData?.length < 1 ? <span>دیتایی یافت نشد</span> :

            <div className='w-full' >
                <div className='overflow-x-auto w-full'>
                    <table className='min-w-[992px] w-full table-fixed flex flex-col gap-3 items-center'>
                        <thead className='w-full'>
                            <tr className='w-full flex items-center justify-between rounded-t-md h-8 pr-5 bg-caspian-primary-500'>
                                <th className=' w-[20%] flex justify-start text-xs font-thin 2xl:text-[15px] text-caspian-surface'>گزارش</th>
                                <th className=' w-[10%] flex justify-start text-xs font-thin 2xl:text-[15px] text-caspian-surface'>برنامه</th>
                                <th className=' w-[10%] flex justify-start text-xs font-thin 2xl:text-[15px] text-caspian-surface'>فعالیت</th>
                                <th className=' w-[10%] flex justify-start text-xs font-thin 2xl:text-[15px] text-caspian-surface'>مجری</th>
                                <th className=' w-[10%] flex justify-start text-xs font-thin 2xl:text-[15px] text-caspian-surface'>ناظر</th>
                                <th className=' w-[10%] flex justify-start text-xs font-thin 2xl:text-[15px] text-caspian-surface'>وضعیت</th>
                                <th className=' w-[10%] flex justify-start text-xs font-thin 2xl:text-[15px] text-caspian-surface'>تاریخ</th>
                            </tr>
                        </thead>
                        <tbody className='w-full flex  gap-3 flex-col'>
                            {records?.length > 0 ? records?.map((item) => (
                                <SingleListReportActionPlan key={item?.id} item={item} />
                            )) :
                                <tr className='py-5 w-full flex flex-col gap-3 items-center justify-center'>
                                    <td className='w-[220px] h-[220px] flex flex-col justify-center'>
                                        <img src="/assets/images/library/not-found.svg" alt="هدف یافت نشد..." />
                                        <h2 className='text-center text-sm'> مورد یافت نشد...</h2>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                {records?.length > 0 &&
                    <div className="flex items-center w-full px-10 py-5 justify-between">
                        <RowsReportsActionPlan
                            setRecordsPerPage={setRecordsPerPage}
                            setCurrentPage={setCurrentPage}
                        />
                        <PaginateReportsActionPlan
                            pageCount={npage}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                        <div className='flex gap-3 text-xs 2xl:text-sm'>
                            <span>نمایش {firstIndex + 1}</span>
                            <span>تا {lastIndex - (recordsPerPage - records?.length)}</span>
                            <span>از کل {sortData?.length}</span>
                        </div>
                    </div>
                }
            </div >
    )
}

export default TableListReportsActionPlan