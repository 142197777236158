import React, { useContext, useEffect, useRef, useState } from "react";
import CommentSingle from "./CommentSingle";
import { PiChatText } from "react-icons/pi";
import { PiXLight } from "react-icons/pi";
import { AuthContext } from "../../../context/auth/AuthContext";
import API from "../../../api/API";

const CommentsMain = ({ setShowComments,isAccessComment }) => {
  const { token, user } = useContext(AuthContext)
  const valueRef = useRef();

  const [valueForm, setValueForm] = useState("");
  const [listComments, setListComments] = useState([])
  const [replay, setReplay] = useState("")
  const [idComment, setIdComment] = useState(1)

  const [listReplay, setListReplay] = useState([])

  const [isDisable, setIsDisable] = useState(false);
  const [refresh, setRefresh] = useState(-1)

  useEffect(() => {
    if (valueForm.length < 1) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [valueForm]);


  console.log(user)

  // post Comment
  const submitData = async () => {
    if (!token || !user) return;

    let formData = {
      id: idComment,
      comment: valueForm,
      replay: replay ? [replay] : [],
    }

    try {
      // const res = await API.post(``, formData,
      //   { headers: { Authorization: token } }
      // )
      console.log(formData)
      setRefresh(1)
      setListComments((prevData) => [formData, ...prevData]);
      clearHandler()

    } catch (error) {
      console.log(error)
    }
  }

  const clearHandler = () => {
    setValueForm("");
    valueRef.current.value = "";
  };

  return (
    <div className="z-50">
      <div className=" flex flex-col justify-between shadow-xl bg-caspian-surface rounded-lg bottom-4 left-16 w-[365px] fixed">
        <div className="w-full flex flex-col items-center min-h-[77vh] max-h-[77vh] overflow-auto">
          <div className="bg-caspian-secondary-300 w-full rounded-t-md p-3">
            <div className="flex justify-between  items-center h-full">
              <div className="flex justify-start items-center ">
                <PiChatText className="text-caspian-surface text-xl" />
                <h2 className="text-sm text-caspian-surface mx-2">دیدگاه</h2>
              </div>
              <PiXLight
                className=" text-caspian-surface cursor-pointer text-2xl"
                onClick={() => setShowComments(false)}
              />
            </div>
          </div>

          {listComments?.map((item, index) => (
            <CommentSingle key={index} setListComments={setListComments} replay={replay} setReplay={setReplay} item={item} />
          ))}

        </div>

        <div className="w-full shadow-2xl border-t-2 border-caspian-gray1  rounded-b-lg bg-caspian-background relative flex gap-2 items-end">
          <textarea
            ref={valueRef}
            rows={5}
            onChange={(e) => setValueForm(e.target.value)}
            placeholder="دیدگاه خود را ارسال کنید"
            className="resize-none w-full relative px-2 bg-caspian-background rounded-b-lg text-caspian-gray py-2 outline-none text-xs"
          />
          <div className="flex flex-col gap-2 items-center relative left-1 bottom-3">
            <button
              type="button"
              onClick={submitData}
              disabled={isDisable ? true : false}
              className={`rounded-lg text-sm text-caspian-surface py-2 px-3 duration-700
                 ${valueForm.length < 1 ? "bg-caspian-gray1" : "bg-[#01BC8D] cursor-pointer text-caspian-surface"}`}>
              ارسال
            </button>

            <button
              onClick={clearHandler}
              disabled={isDisable ? true : false}
              className={` rounded-lg text-xs duration-700 
                ${valueForm.length < 1 ? "text-caspian-gray1" : "cursor-pointer text-caspian-error2"}`}>
              پاک کردن
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentsMain;
