import React from 'react'
import { priceType } from '../../../../../../helper/pricetype'
import { useNavigate } from 'react-router-dom';

const SingleTableListGoalActionplan = ({ item }) => {


    let widthDuration =
        item?.duration - item?.remained_day < 1 ? 0 : 100 - (item?.remained_day / item?.duration) * 100;


    let statusDay = '#01BC8D'
    let statusName;
    let statusColor;


    let widthProgress =

        item?.actionplan_detail?.progress > 100 || item?.actionplan_detail?.progress < 0 ? 0 : (item?.actionplan_detail?.progress / 100) * 100;

    if (widthDuration < 33.3) {
        statusDay = "#01BC8D";
    } else if (widthDuration > 33.4 && widthDuration < 66.5) {
        statusDay = "#D6A834";
    } else if (widthDuration > 66.6) {
        statusDay = "#FF440A";
    }



    if (item?.status === "Working") {
        statusName = "در حال انجام";
        statusColor = "#3C77A2";
    } else if (item?.status === "NotStarted") {
        statusName = "شروع نشده";
        statusColor = "#99999999";
    } else if (item?.status === "Done") {
        statusName = "انجام شده";
        statusColor = "#01BC8D";
    } else if (item?.status === "PrePlaning") {
        statusName = "درحال برنامه‌ریزی";
        statusColor = "#D6A834";
    } else if (item?.status === "Closed") {
        statusName = "بسته شده";
        statusColor = "#FF440A";
    }


    const navigate = useNavigate()
    return (

        <tr className='w-full flex items-center justify-between text-center text-xs cursor-pointer'
            onClick={() => navigate('/action-plan/my-actionplan/' + item?.id + '/profile/')}>

            <td className='text-xs 2xl:text-sm w-[14%] font-semibold'>
                <div className='py-4'>
                    {item?.title}
                </div>
            </td>

            <td className='text-xs 2xl:text-sm w-[8%]'>
                <div className='py-4'>
                    AP-{item?.id}
                </div>
            </td>

            <td className='text-xs 2xl:text-sm w-[13%] font-semibold'>
                <div className='py-4'>
                    {item?.manager?.firstName} {item?.manager?.lastName}
                </div>
            </td>

            <td className='text-xs 2xl:text-sm w-[8%] text-caspian-secondary-600'>
                <div className='py-4'>
                    {new Date(item?.actionplan_detail?.startDate).toLocaleDateString('fa-IR')}
                </div>
            </td>
            <td className='text-xs 2xl:text-sm w-[8%] '>
                <div className=' bg-caspian-darkgray-400 rounded-full w-full'>
                    <div className={`border max-w-full border-[${statusDay}] bg-[${statusDay}] h-1 rounded-full `}
                        style={{ width: `${widthDuration}%` }}>
                    </div>
                </div>
            </td>
            <td className='text-xs 2xl:text-sm w-[8%] text-caspian-secondary-600'>
                <div className='py-4'>
                    {new Date(item?.actionplan_detail?.endDate).toLocaleDateString('fa-IR')}
                </div>
            </td>

            <td className='text-xs 2xl:text-sm w-[14%] font-semibold'>
                <div className='py-4'>
                    {priceType(item?.actionplan_detail?.cost)}(ریال)
                </div>
            </td>
            <td className='text-xs 2xl:text-sm w-[8%] text-caspian-secondary-600'>
                <div className='py-4'>
                    {new Date(item?.approval_date).toLocaleDateString('fa-IR')}
                </div>
            </td>
            <td className='text-xs 2xl:text-sm w-[12%]'>

                <div className=' bg-caspian-darkgray-400 rounded-full relative w-full '>
                    <div className={`border border-caspian-secondary-600 text-xs 2xl:text-sm bg-caspian-secondary-600 rounded-full h-1`}
                        style={{ width: `${widthProgress}%` }}>
                    </div>
                    <span className='text-caspian-secondary-600 text-xs 2xl:text-sm bottom-1 left-0 absolute'>
                        {item?.actionplan_detail?.progress}%
                    </span>
                </div>

            </td>
            <td className={`text-xs 2xl:text-sm w-[12%] text-[${statusColor}]`}>
                <div className='py-4'>
                    {statusName}
                </div>
            </td>

        </tr >
    )
}

export default SingleTableListGoalActionplan