// import {
//   PiCalendarX,
//   PiListBullets,
//   PiStack,
// } from "react-icons/pi";
import ListItemSingle from "./ListItemSingle";
// import { useAppContext } from "../../context/app-context/AppContext";

const RiskNavbar = ({ buttonActive }) => {
  // const { setBradCrump3 } = useAppContext();

  return (
    <nav className="w-full bg-caspian-surface rtl">
      <ul className="flex items-center gap-0.5">
        {/* <ListItemSingle
          title="گروه ها"
          active="groups"
          // icon={<PiListBullets />}
          link="/risk/groups"
          buttonActive={buttonActive}
          // onClick={() => setBradCrump3("گروه ها")}
        /> */}
        <ListItemSingle
          title="تیم ها"
          active="teams"
          // icon={<PiListBullets />}
          link="/risk/teams"
          buttonActive={buttonActive}
          // onClick={() => setBradCrump3("تیم ها")}
        />
        <ListItemSingle
          title="ریسک ها"
          active="risks"
          // icon={<PiCalendarX />}
          link="/risk/risks"
          buttonActive={buttonActive}
          // onClick={() => setBradCrump3("ریسک ها")}
        />
        <ListItemSingle
          title="فعالیت ها"
          active="activities"
          // icon={<PiCalendarX />}
          link="/risk/activities"
          buttonActive={buttonActive}
          // onClick={() => setBradCrump3("ریسک ها")}
        />
      </ul>
    </nav>
  );
};

export default RiskNavbar;
