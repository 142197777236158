import React, { useContext, useEffect, useState } from "react";
import Title from "./Title";
import DateTime from "./DateTime";
import Description from "./Description";
import Inputs from "./Inputs";
import HeadMembers from "./HeadMembers";
// import Members from "./Members";
import OrderMeeting from "./OrderMeeting";
// import Guest from "./Guest";
import Presentations from "./Presentations";
// import Invitation from "./Invitation";
import { PiCaretLeftBold } from "react-icons/pi";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import AllMembers from "./AllMembers";
import PresentMembers from "./PresentMembers";
import Approvals from "./Approvals";
import ApprovalsFile from "./ApprovalsFile";
import Outputs from "./Outputs";
import Refrence from "./Refrence";
import { AuthContext } from "../../../../context/auth/AuthContext";
import API from "../../../../api/API";

const MainDetailsRecordsMeeting = () => {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const params = useParams();
  console.log(params);

  const [serachParams, setSearchParams] = useSearchParams();
  const meeting = serachParams.get("meeting");

  function handleSearch(key, value) {
    setSearchParams((prevParams) => {
      if (value === null || value === "") {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    });
  }

  const [fullData, setFullData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const [showAll, setShowAll] = useState(false);
  const [hideAll, setHideAll] = useState(false);
  useEffect(() => {
    if (params) {
      setLoading(true);
      const getDataHandler = (e) => {
        API.get(`/api/v1/meeting/info/detail/${params.id}/`, {
          headers: { Authorization: token },
        })
          .then((res) => {
            setFullData(res?.data);
            setRefresh(0);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          })
          .finally(() => setLoading(false));
      };
      getDataHandler();
    }
  }, [token, refresh, params]);

  console.log(fullData);

  // ارسال اکتیویتی ثبت مصوبات

  return loading ? (
    <h2 className="text-xs text-caspian-gray">در حال بارگزاری اطلاعات</h2>
  ) : fullData.length < 1 ? (
    <h2 className="text-xs text-caspian-gray">جلسه ی مورد نظر موجود نیست</h2>
  ) : (
    <div className="bg-caspian-surface p-3 pb-8 min-h-screen relative">
      <div className="flex items-center gap-0 absolute top-2 left-2">
        <button
          onClick={() => {
            setShowAll(true);
            setHideAll(false);
          }}
          className="text-[10px] p-1 bg-caspian-gray2/50 rounded-md rounded-l-none text-caspian-gray4 border-l border-l-caspian-gray2"
        >
          بازکردن همه
        </button>
        <button
          onClick={() => {
            setHideAll(true);
            setShowAll(false);
          }}
          className="text-[10px] p-1 bg-caspian-gray2/50 rounded-md rounded-r-none text-caspian-gray4"
        >
          بستن همه
        </button>
      </div>
      <div className="flex flex-col gap-3 w-full md:w-[90%] lg:w-[80%]">
        {/* تیتر */}
        <Title
          id={fullData?.id}
          status={fullData?.status}
          showAll={showAll}
          hideAll={hideAll}
          setShowAll={setShowAll}
          setHideAll={setHideAll}
          title={fullData?.title}
          profileTitle={fullData?.meetingProfileRelated?.title}
        />
        {/* ساعت و تاریخ */}
        <DateTime fullData={fullData?.meetingDatetimeRelated[0]} />
        {/* توضیحات */}
        <Description
          showAll={showAll}
          hideAll={hideAll}
          setShowAll={setShowAll}
          setHideAll={setHideAll}
          description={fullData?.description}
        />
        {/* مرجع */}
        <Refrence
          showAll={showAll}
          hideAll={hideAll}
          setShowAll={setShowAll}
          setHideAll={setHideAll}
          fullData={
            fullData?.meetingProfileRelated?.meetingProfileRefrenceRelated
          }
        />
        {/* ورودی های جلسه */}
        <Inputs
          showAll={showAll}
          hideAll={hideAll}
          setShowAll={setShowAll}
          setHideAll={setHideAll}
          fullData={fullData?.meetingProfileRelated?.inputMeetingProfileRelated}
        />
        {/* خروجی های جلسه */}
        <Outputs
          showAll={showAll}
          hideAll={hideAll}
          setShowAll={setShowAll}
          setHideAll={setHideAll}
          fullData={
            fullData?.meetingProfileRelated?.outputMeetingProfileRelated
          }
        />
        {/* اعضای هیئت رئیسه */}
        <HeadMembers
          showAll={showAll}
          hideAll={hideAll}
          setShowAll={setShowAll}
          setHideAll={setHideAll}
          chairman={fullData?.meetingProfileRelated?.chairman}
          viceChairman={fullData?.meetingProfileRelated?.viceChairman}
          secretary={fullData?.meetingProfileRelated?.secretary}
        />
        {/* اعضای اصلی جلسه */}
        {/* <Members members={fullData?.meetingProfileRelated?.members} /> */}
        {/* همه ی اعضا */}
        <AllMembers
          showAll={showAll}
          hideAll={hideAll}
          setShowAll={setShowAll}
          setHideAll={setHideAll}
          mainMembers={fullData?.meetingMemberRelated?.filter(
            (d) => d.memberType === "main"
          )}
          guestMembers={fullData?.meetingMemberRelated?.filter(
            (d) => d.memberType === "guest"
          )}
          companyMembers={fullData?.meetingMemberRelated?.filter(
            (d) => d.memberType === "company"
          )}
        />

        {/* دستور */}
        <OrderMeeting
          showAll={showAll}
          hideAll={hideAll}
          setShowAll={setShowAll}
          setHideAll={setHideAll}
          fullData={fullData?.meetingAgendaRelated}
        />
        {/* مهمانان جلسه */}
        {/* <Guest
            fullData={fullData?.meetingMemberRelated?.filter(
              (d) => d.memberType !== "main"
            )}
          /> */}
        {/* فایل ارائه */}
        <Presentations
          showAll={showAll}
          hideAll={hideAll}
          setShowAll={setShowAll}
          setHideAll={setHideAll}
          fullData={fullData?.MeetingPresentationRelated[0].documentsRelated}
        />
        {/* دعوت‌نامه */}
        {/* <Invitation /> */}
        {/* لیست حضور غیاب */}
        {(fullData?.status === "approvalschairman" ||
          fullData?.status === "archived") && (
          <PresentMembers
            showAll={showAll}
            hideAll={hideAll}
            setShowAll={setShowAll}
            setHideAll={setHideAll}
            members={fullData?.meetingMemberRelated}
          />
        )}
        {/* مصوبات */}
        {(fullData?.status === "approvalschairman" ||
          fullData?.status === "archived") && (
          <Approvals
            showAll={showAll}
            hideAll={hideAll}
            setShowAll={setShowAll}
            setHideAll={setHideAll}
            fullData={fullData?.meetingAprovalRelated}
          />
        )}
        {/* فایل مصوبات */}
        {(fullData?.status === "approvalschairman" ||
          fullData?.status === "archived") && (
          <ApprovalsFile
            showAll={showAll}
            hideAll={hideAll}
            setShowAll={setShowAll}
            setHideAll={setHideAll}
            fullData={
              fullData?.MeetingFinalDocumentRelated[0]?.documentsRelated
            }
          />
        )}
      </div>
    </div>
  );
};

export default MainDetailsRecordsMeeting;
