import React from "react";
import { priceType } from "../../../../../../helper/pricetype";

const BudgetChart2 = ({ cost, costTotalTasks }) => {
  const maxCost = costTotalTasks;
  const minCost = cost;

  // بودجه مازاد
  const surplus = maxCost - minCost;

  // درصد بودجه
  const costPercent = (minCost / maxCost) * 100;

  return (
    <div className="flex flex-col gap-4 w-full">
      {/* بودجه تخمینی PMO */}
      <div className="flex flex-col items-end gap-2 w-full">
        <div className="flex items-center gap-4 w-full">
          <div className="basis-[10%]"></div>
          <div className="w-full basis-[70%] bg-caspian-gray2/30 h-1">
            <div
              style={{
                width: costPercent + "%",
              }}
              className={`bg-caspian-gray4 h-1 mr-auto relative`}
            >
              <div className="flex items-center gap-1 absolute left-1/2 -translate-x-1/2 bottom-2">
                <span className="text-xs ltr">{priceType(cost)}</span>
                <span className="text-xs">ریال</span>
              </div>
              <div className="border-l border-dashed border-l-caspian-gray h-20 absolute right-0 top-0"></div>
            </div>
          </div>
          <div className="basis-[20%]">
            <span className="text-xs whitespace-nowrap">براورد تخمینی PMO</span>
          </div>
        </div>
      </div>
      {/* جمع بودجه وظایف */}
      <div className="flex flex-col items-end gap-2 w-full">
        <div className="flex items-center gap-4 w-full">
          <div className="basis-[10%]"></div>
          <div className="w-full basis-[70%] bg-caspian-gray2/30 h-1">
            <div
              style={{
                width: 100 + "%",
              }}
              className={`bg-caspian-primary-500 h-1 mr-auto relative`}
            >
              <div className="flex items-center gap-1 absolute left-1/2 -translate-x-1/2 bottom-2">
                <span className="text-xs ltr">{priceType(maxCost)}</span>
                <span className="text-xs">ریال</span>
              </div>
            </div>
          </div>
          <div className="basis-[20%]">
            <span className="text-xs">بودجه وظایف</span>
          </div>
        </div>
      </div>
      {/* بودجه مازاد وظایف */}
      <div className="flex flex-col items-end gap-2 w-full">
        <div className="flex items-center gap-4 w-full">
          <div className="basis-[10%]"></div>
          <div className="w-full basis-[70%] bg-caspian-gray2/30 h-1">
            <div
              style={{
                width: 100 - costPercent + "%",
              }}
              className="bg-caspian-error2 h-1 relative"
            >
              <div className="flex items-center gap-1 absolute left-1/2 -translate-x-1/2 bottom-2">
                <span className="text-xs ltr">{priceType(surplus)}</span>
                <span className="text-xs">ریال</span>
              </div>
            </div>
          </div>
          <div className="basis-[20%]">
            <span className="text-xs">بودجه مازاد</span>
          </div>
        </div>
      </div>
      {/* <div className="flex items-center justify-center gap-2">
        <span className="text-xs text-center">مجموع بودجه وظایف و مازاد</span>
        <div className="flex items-center gap-1">
          <span className="text-sm font-bold ltr">{priceType(maxCost)}</span>
          <span className="text-xs">ریال</span>
        </div>
      </div> */}
    </div>
  );
};

export default BudgetChart2;
