import React from 'react'
import ButtonChart from '../ButtonChart'

const Chart7 = () => {
    return (
        <div className="flex flex-col items-center mx-32 2xl:mx-auto w-full min-w-[760px] max-w-[760px] ">

            <div className="flex flex-col w-full items-center h-full">
                {/* 1 */}
                <ButtonChart
                    title="معاون مالي و پشتيباني(1)"
                    bgColor="#DDEAF4"
                    textColor="#4B6B90"
                    borderColor="#4B6B90"
                />
                {/* 1 */}
                <div className="h-5 border-r-2 border-r-caspian-gray"></div>
            </div>

            <div className="h-5 border-r-2 border-r-caspian-gray"></div>

            <div className="flex flex-col w-full items-center">
                {/* 2 */}
                <div className="flex justify-center w-full relative h-0 gap-10 items-center">

                    <div className="flex flex-col gap-2 h-full items-center">
                        <div className="flex items-center justify-center">
                            <div className="flex items-center justify-center">
                                <div className="w-[250px]"></div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col h-full items-center">
                        <div className="flex items-center">
                            <ButtonChart
                                title="متصدی امور دفتري و کاربر رایانه (1)"
                                bgColor="#C7EBEA"
                                textColor="#225C58"
                                borderColor="#225C58">
                            </ButtonChart>
                            <div className="w-14 absolute right-[50%] h-2 border-b-2 border-b-caspian-gray"></div>
                        </div>
                    </div>
                </div>
                <div className="h-[100px] border-r-2 border-r-caspian-gray"></div>
                {/* 2 */}

                <div className="flex w-full flex-col justify-center relative gap-5 items-center">
                    <div className="w-[520px] xl:w-[778px] h-2 border-t-2 border-t-caspian-gray"></div>
                    {/* 3 */}
                    <div className="flex w-full items-start justify-center gap-20 xl:gap-52">
                        <div className="flex flex-col gap-2 h-full items-center">
                            <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
                            <div className="flex items-center relative h-full">
                                <div className="flex flex-col items-center gap-2 h-full">
                                    <div className="h-[89%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>
                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="مدیر امور مالی (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="رئیس قسمت حسابداری(1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس مالی (7)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کمک کارشناس مالی (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="رئیس اداره حسابداری ممیزی و تنظیم اسناد (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="h-2 absolute top-[61.8%] border-l-2 border-l-caspian-gray"></div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس رسیدگی به اسناد (2)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس مسئول ممیزی(1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس مسئول جمعدار اموال (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-2 h-full items-center">
                            <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
                            <div className="flex items-center relative h-full">
                                <div className="h-[82%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>
                                <div className="h-[41.5%] absolute top-[30.5%] -left-5 border-l-2 border-l-caspian-gray"></div>
                                <div className="flex flex-col items-center gap-2 h-full">
                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="مدیر امور پشتیبانی (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس مسئول خدمات عمومی(1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                        <div className="w-5 h-2 absolute -left-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کمک کارشناس خدمات عمومی(1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -left-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>


                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="*راننده خودرو سنگین (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -left-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس مسئول دبيرخانه(1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="flex flex-col gap-2 h-full items-center">
                            <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
                            <div className="flex items-center relative h-full">
                                <div className="h-[69.5%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>
                                <div className="flex flex-col items-center gap-2 h-full">
                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="رئیس قسمت انبار(1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس انبار (2)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="h-2 absolute bottom-[14.8%] border-l-2 border-l-caspian-gray"></div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کمک کارشناس انبار (2)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 3 */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Chart7