import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../../../../context/auth/AuthContext';
import API from '../../../../../../../api/API';
import { PiMagnifyingGlassBold } from 'react-icons/pi';

const AllQuestionList = ({ standard2 }) => {
    const { token } = useContext(AuthContext);
    const [questionList, setQuestionList] = useState([-1]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    let searchData = [-1];
    let standardData = [-1];

    useEffect(() => {
        const fetchQues = async () => {
            try {
                const res = await API.get('/api/v1/audit/question/detial/', { headers: { Authorization: token } })
                setQuestionList(res.data);
                setLoading(false);
                console.log(res.data);
            }
            catch (err) {
                console.log(err);
            }
        }
        fetchQues()
    }, [token])
    if (questionList[0] !== -1) {
        searchData = search ? (questionList?.filter(d => d?.title?.toString().toLocaleLowerCase().includes(search?.toString().toLocaleLowerCase())))
            : questionList;
        standardData = standard2 ? (searchData?.filter(d => d?.questionClauseRelatedQuestion.find(s => s?.standardRelated?.id === standard2)))
            : searchData;
    }
    return (
        <div className='flex flex-col gap-0 flex-grow '>
            <div className='px-3 py-2 relative border-y border-caspian-gray'>
                {/* search box */}
                <input type="search" placeholder='جستجو'
                    className='border border-[#979797] rounded-lg w-full p-1.5 pl-0 pr-9 placeholder:text-xs text-sm outline-none'
                    onChange={(e) => setSearch(e.target.value)}
                />
                <PiMagnifyingGlassBold className='absolute top-1/2 -translate-y-1/2 right-6 text-caspian-gray ' />
            </div>
            <div className='flex gap-0 items-start justify-center h-44 overflow-x-hidden overflow-y-auto flex-grow '>
                {/* question list */}
                <div className='w-full flex flex-col items-stretch min-h-fit h-full '>
                    {
                        loading ? (<div className='flex items-center h-full text-xs 2xl:text-sm justify-center  text-caspian-gray'>
                            در حال بارگزاری...
                        </div>) :
                            standardData[0] === -1 ? (<div className='flex items-center h-full text-xs 2xl:text-sm justify-center  text-caspian-gray'>
                                سوالی ثبت نشده
                            </div>) : standardData?.length < 1 ? (<div className='flex items-center h-full justify-center  text-xs 2xl:text-sm text-caspian-gray'>
                                موردی یافت نشد
                            </div>) : standardData?.map(item => (
                                <div key={item?.id} className='odd:bg-[#E4F0F1] p-4 flex flex-col gap-3 w-full text-xs'>
                                    <div className='flex justify-between text-xs'>
                                        <div>
                                            {
                                                item?.questionClauseRelatedQuestion.map(i => 
                                                <div key={i?.id} className='flex gap-2 text-[#0D358C] text-xs leading-5 items-center'>
                                                    <span>{i?.standardRelated?.symbol}</span>
                                                    <span>{i?.standardRelated?.standardNumber}</span>
                                                    {
                                                        i?.clauseRelated?.map(c=><span key={c?.id}>{c?.clauseNumber}</span>)

                                                    }
                                                </div>
                                                )

                                            }
                                        </div>
                                        <p className='text-[#A171A4] font-medium'>{item?.code}</p>
                                    </div>
                                    <p className='leading-5'>سوال: {item?.title}</p>
                                    <p className='text-[#3C77A2] leading-5'>معیار: {item?.criteriaon}</p>
                                </div>
                            ))
                    }


                    {/* {
                        searchData[0] !== -1 ? (searchData?.map((item, index) => <div className='border-t border-caspian-gray first:border-t-0' key={item.id}> <p className='p-2 text-center text-sm whitespace-nowrap'>
                            {index+1}
                        </p></div>
                        )) :
                            (<p className='text-center text-sm'></p>)
                    }
                </div>
                <div className='basis-3/4 flex flex-col '>
                    {
                        searchData.length < 1 ? ((<div className='flex items-center pt-20 justify-center text-sm text-caspian-gray'>
                            موردی یافت نشد
                        </div>)) :
                            searchData[0] !== -1 ? (searchData?.map(item => <div className='border-t border-caspian-gray first:border-t-0' key={item.id}>
                                <p title={item?.title} className='p-2 text-sm'>{!item?.title ? '-' : item?.title.length > 30 ?
                                    item?.title?.slice(0, 30) + '...' : item?.title}</p>
                            </div>)) : (<div className='flex items-center pt-20 justify-center text-sm text-caspian-gray'>
                                سوالی ثبت نشده
                            </div>)
                    } */}
                </div>
            </div>
        </div>
    );
}

export default AllQuestionList;
