import React from "react";

const IdProcessInputBox = ({
  title,
  defaultValue1,
  defaultValue2,
  defaultValue3,
  defaultValue4,
  className,
}) => {
  return (
    <div className={`px-4 py-6 ${className}`}>
      <div className="flex items-center gap-2">
        <span className="text-sm w-1/5 font-bold">{title}</span>
        <div className="flex flex-col gap-6 w-full">
          {defaultValue1 && (
            <input
              type="text"
              disabled
              className="w-full p-2.5 rounded-md text-sm disabled:bg-caspian-transparent outline outline-1 disabled:outline-caspian-gray2"
              defaultValue={defaultValue1}
            />
          )}
          {defaultValue2 && (
            <input
              type="text"
              disabled
              className="w-full p-2.5 rounded-md text-sm disabled:bg-caspian-transparent outline outline-1 disabled:outline-caspian-gray2"
              defaultValue={defaultValue2}
            />
          )}
          {defaultValue3 && (
            <input
              type="text"
              disabled
              className="w-full p-2.5 rounded-md text-sm disabled:bg-caspian-transparent outline outline-1 disabled:outline-caspian-gray2"
              defaultValue={defaultValue3}
            />
          )}
          {defaultValue4 && (
            <input
              type="text"
              disabled
              className="w-full p-2.5 rounded-md text-sm disabled:bg-caspian-transparent outline outline-1 disabled:outline-caspian-gray2"
              defaultValue={defaultValue4}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default IdProcessInputBox;
