import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../../context/auth/AuthContext";
import { useParams } from "react-router-dom";
import API from "../../../../../../api/API";
import PmForm from "./PmForm";

const MainForm = () => {
  const params = useParams();
  const { token } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [fullData, setFullData] = useState([]);
  const [costData, setCostData] = useState([]);
  const url = "/api/v1/actionplan/actionPlanPM/";

  useEffect(() => {
    if (!token || !params?.id) return;
    const getActionpalnData = async () => {
      setLoading(true);
      try {
        const res = await API.get(
          `/api/v1/actionplan/actionplanWithRole/${params?.id}/`,
          {
            headers: { Authorization: token },
          }
        );
        getActionPlanBrief();
        console.log(res?.data);
        setFullData(res?.data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getActionpalnData();
  }, [token, params?.id]);

  const getActionPlanBrief = async () => {
    if (!token || !params?.id) return;
    try {
      const res = await API.get(
        `/api/v1/actionplan/actionplan/actionplanBrief/${params?.id}`,
        {
          headers: { Authorization: token },
        }
      );
      console.log(res?.data);
      setCostData(res?.data[0]);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="py-4">
      {loading ? (
        <div>در حال بارگزاری...</div>
      ) : (
        <PmForm fullData={fullData} costData={costData} />
      )}
    </div>
  );
};

export default MainForm;
