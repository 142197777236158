import { useNavigate } from "react-router-dom";

const SingleTableListReport = ({
  id,
  title,
  manager,
  manager1,
  manager2,
  date,
  status,
}) => {
  const navigate = useNavigate();
  const viewTeam = async () => {
    navigate(`/action-plan/plans/${id}`);
  };

  let statusName = "";
  let statusClass = "";

  if (status === "Working") {
    statusName = "در حال انجام";
    statusClass = "text-[#3C77A2]";
  } else if (status === "NotStarted") {
    statusName = "شروع نشده";
    statusClass = "text-[#99999999]";
  } else if (status === "Done") {
    statusName = "انجام شده";
    statusClass = "text-[#01BC8D]";
  } else if (status === "PrePlaning") {
    statusName = "درحال برنامه‌ریزی";
    statusClass = "text-[#D6A834]";
  } else if (status === "Closed") {
    statusName = "بسته شده";
    statusClass = "text-[#FF440A]";
  }
  return (
    <tr className="bg-caspian-surface duration-300">
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-right border-b border-b-caspian-gray2 px-2 py-4 w-[25%] cursor-pointer"
      >
        <h2 className="text-xs">{title}</h2>
      </td>
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-right border-b border-b-caspian-gray2 px-2 py-4 w-[15%] cursor-pointer"
      >
        <h2 className="text-xs">{manager}</h2>
      </td>
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-right border-b border-b-caspian-gray2 px-2 py-4 w-[15%] cursor-pointer"
      >
        <h2 className="text-xs">{manager1}</h2>
      </td>
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-right border-b border-b-caspian-gray2 px-2 py-4 w-[15%] cursor-pointer"
      >
        <h2 className="text-xs">{manager2}</h2>
      </td>
      <td className="whitespace-nowrap text-right border-b border-b-caspian-gray2 w-[15%]">
        <div className="flex items-center justify-between gap-2 pl-2">
          <span className={`${statusClass} text-xs`}>{statusName}</span>
        </div>
      </td>
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-2 py-4 w-[15%] cursor-pointer"
      >
        <h2 className="text-xs">{date}</h2>
      </td>
    </tr>
  );
};

export default SingleTableListReport;
