import React from "react";

const SingleInput = ({ lable, children }) => {
  return (
    <div className="flex flex-col gap-1 items-start w-full">
      <span className="text-xs text-caspian-on_primary/70">{lable}</span>
      {children}
    </div>
  );
};

export default SingleInput;
