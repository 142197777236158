import React, { useEffect, useRef, useState } from "react";
import { PiTrash } from "react-icons/pi";
import ChatBoxSender from "./chat-tools/ChatBoxSender";
import ChatBoxReciver from "./chat-tools/ChatBoxReciver";
import ChatInput from "./chat-tools/ChatInput";

const ChatDetils = () => {
  const chatParent = useRef(null);

  useEffect(() => {
    const domNode = chatParent.current;
    if (domNode) {
      domNode.scrollTop = domNode.scrollHeight;
    }
  });

  const [messageInput, setMessageInput] = useState({
    id: "",
    name: "",
    text: "",
    date: "",
  });
  const [allMessages, setAllMessages] = useState([]);

  const handleInputChange = (e) => {
    setMessageInput({
      id: 1,
      name: "محمد کامران",
      text: e.target.value,
      date: new Date(),
    });
  };

  const messageHandler = async () => {
    if (messageInput.text.trim() !== "") {
      setAllMessages([...allMessages, messageInput]);
      setMessageInput({
        id: "",
        name: "",
        text: "",
        date: "",
      });
    }
  };

  console.log(allMessages);
  return (
    <div className="w-full bg-caspian-surface pb-16">
      <div className="border-b border-b-caspian-gray3 p-2">
        <PiTrash className="text-2xl text-caspian-gray cursor-pointer" />
      </div>
      <div className="h-[60vh] max-h-[60vh]">
        <div ref={chatParent} className="h-full flex gap-0">
          <div className="h-auto overflow-y-scroll w-full p-2 border-b border-b-caspian-gray2">
            {allMessages.length > 0 ? (
              <div className="flex flex-col gap-2">
                <ChatBoxReciver
                  sender={"علی حسین زاده"}
                  message={"سلام ممنون بابت اطلاع رسانی"}
                  date={"29/01/1403"}
                />
                {allMessages.map((d, i) => (
                  <div className="" key={i}>
                    <ChatBoxSender
                      sender={d.name}
                      message={d.text}
                      date={new Date(d.date).toLocaleDateString("fa-IR")}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <h2>پیام وجود ندارد</h2>
            )}
          </div>
        </div>
        <ChatInput
          messageInput={messageInput}
          handleInputChange={handleInputChange}
          messageHandler={messageHandler}
        />
      </div>
    </div>
  );
};

export default ChatDetils;
