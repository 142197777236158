import React, { useContext, useState } from 'react';
import { PiPencilSimpleLine, PiTrash, PiXCircleFill, PiCheckCircleFill } from "react-icons/pi";
import API from '../../../../../api/API';
import { AuthContext } from '../../../../../context/auth/AuthContext';

const SingleRowList = (
    { id, index, code, standard, clause, title, criteriaon, pdcaType,
        sectionRelatedQuestion, processActivityRelatedQuestion, setRefresh, refresh, questionClauseRelatedQuestion }
) => {
    const { token } = useContext(AuthContext);
    const [show, setShow] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [rmv, setRmv] = useState(false);
    const handleDelete = async (detailsId) => {
        if (!detailsId) return
        if (rmv) return;
        setRmv(true);
        console.log('delete');
        try {
            const res = await API.delete(`/api/v1/audit/question/detial/${detailsId}/`, { headers: { Authorization: token } });
            setRefresh(true)
        } catch (error) {
            console.log(error);
        }
        finally {
            setRmv(true)
        }
    }
    return (
        <tr className='odd:bg-[#E4F0F1] cursor-pointer text-xs leading-5' onMouseOver={() => setShow(true)} onMouseLeave={() => setShow(false)}>
            {/* <td className='text-center py-3 w-[5%] whitespace-nowrap'>
                {index}
            </td> */}
            <td className='text-center py-3 w-[10%] whitespace-nowrap'>
                {code}
            </td>
            <td className='text-center py-3 w-[10%] whitespace-nowrap text-[#3C77A2]'>
                {questionClauseRelatedQuestion?.map(item => <p key={item?.id}>
                    <span className='ml-1.5'>{item?.standardRelated?.standardNumber}</span>
                </p>)}
            </td>
            <td className='text-center py-3 w-[10%] whitespace-nowrap text-[#3C77A2]'>
               <div className='flex flex-wrap'>
               {questionClauseRelatedQuestion?.map(item =>item?.clauseRelated?.map(i=>
                    <span key={i?.id} className='ml-1.5'>{i?.clauseNumber}</span>
                ))}
               </div>
            </td>
            <td className='text-right py-3 w-[20%]' title={title}>
                <p className='line-clamp-2'>{title}</p>
            </td>
            <td className='text-right py-3 w-[20%]' title={criteriaon}>
                <p className=' line-clamp-2'>{criteriaon}</p>
            </td>
            <td className='text-center py-3 w-[5%]'>
                {(<img src={`/assets/images/audit/pdca-${pdcaType}.svg`} className='mx-auto' alt='P' />)}
            </td>
            {/* <td className='text-center py-3 w-[10%]'>
                {sectionRelatedQuestion?.join(' , ')}
            </td> */}
            <td className='text-center py-3 w-[2%] relative ' onClick={() => console.log('profile')}>
                {/* {processActivityRelatedQuestion?.join(',')} */}
                {show && <>
                    {/* <button className=' absolute top-1/2 left-2 -translate-y-1/2 p-1.5  rounded-md'>
                    <PiPencilSimpleLine
                        onClick={(e) => {
                            e.stopPropagation();
                            console.log('edit')
                        }}
                        className='text-xl' />
                </button> */}
                    <button
                        disabled={rmv}
                        className=' absolute text-caspian-error2 top-1/2 left-10 -translate-y-1/2 p-1.5 rounded-md'>
                        {showDelete === false ? <PiTrash
                            onClick={() => setShowDelete(true)}
                            className='text-xl' /> : (
                            <div className='flex flex-row-reverse rounded-md gap-1 bg-caspian-surface'>
                                <PiXCircleFill className='text-xl text-caspian-gray' onClick={() => setShowDelete(false)} />
                                <PiCheckCircleFill className='text-xl text-caspian-primary-600' onClick={(e) => {
                                    e.stopPropagation();
                                    handleDelete(id)
                                }} />
                            </div>
                        )
                        }
                    </button>
                </>
                }
            </td>
            {/* <td className='text-center py-3'> */}

            {/* </td> */}
        </tr>
    );
}

export default SingleRowList;
