import React, { useContext, useEffect, useState } from 'react';
import Modal from '../../../../../../../modal/Modal';
import { PiCircleNotch, PiPlusBold, PiX } from 'react-icons/pi';
import ListGender from './ListGender';
import ListOrientation from './ListOrientation';
import { AuthContext } from '../../../../../../../../context/auth/AuthContext';
import API from '../../../../../../../../api/API';
import ListDegree from './ListDegree';
import ListStudy from './ListStudy';
import ListKnowledge from './ListKnowledge';
import ListSkill from './ListSkill';

const EditAuthForm = ({ id, item, setEdit, setRefresh }) => {
    const { token } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [edu, setEdu] = useState([-1]);
    const idCondition = item !== null ? item?.id : -1;
    console.log('idCondition', idCondition);
    const [requirementYear, setRequirementYear] = useState(item?.requirementYear ? item?.requirementYear : 0);
    const [approvedYear, setApprovedYear] = useState(item?.approvedYear ? item?.approvedYear : 0);
    useEffect(() => {
        if (!token) return
        const fetchEducation = async () => {
            try {
                const res = await API.get('/api/v1/knowledge/degree/');
                console.log(res?.data);
                setEdu(res?.data);
            } catch (error) {
                console.log(error);
            }
        }
        fetchEducation();
    }, [token])
    // console.log(edu);

    //gender
    const [gender, setGender] = useState(item?.genderType ? item?.genderType : '');
    const [genderList, setGenderList] = useState([{ title: 'زن', val: 'woman' }, { title: 'مرد', val: 'men' }, { title: 'هر دو', val: 'both' }]);
    const [genderSelected, setGenderSelected] = useState(gender ? genderList.find(g => g.val === gender) : null);


    const [physical, setPhysical] = useState(item?.physical ? item?.physical : '');
    const [desc, setDesc] = useState(item?.describtion ? item?.describtion : '');

    //مقطع
    const degree = item?.positionOrientation?.length > 0 ?
        [...new Set(item?.positionOrientation?.map(p => { return p?.studyfieldRelated?.DegreeRelated?.id }))] :
        [];
    // let degree = item?.positionOrientation?.length > 0 ? [...new Set(item?.positionOrientation?.map(p => {if(p?.studyfieldRelated?.DegreeRelated?.id)
    //     return p?.studyfieldRelated?.DegreeRelated?.id
    // } ))] :
    // [];

    console.log('degree', degree);
    const [showDegree, setShowDegree] = useState(false);
    const [degreeSelected, setDegreeSelected] = useState(degree);
    const [degreeFinalSelected, setDegreeFinalSelected] =
        useState(degreeSelected);
    //رشته
    const study = item?.positionOrientation?.length > 0 ?
        [...new Set(item?.positionOrientation?.map(p => { return p?.studyfieldRelated?.id }))] :
        [];
    const [showStudy, setShowStudy] = useState(false);
    const [studySelected, setStudySelected] = useState(study);
    const [studyFinalSelected, setStudyFinalSelected] =
        useState(studySelected);
    console.log("studyFinalSelected", studyFinalSelected);
    //گرایش
    const orientation = item?.positionOrientation?.length > 0 ? item?.positionOrientation?.map(p => { return p?.id }) : [];
    const [showOrientation, setShowOrientation] = useState(false);
    const [orientationSelected, setOrientationSelected] = useState(orientation);
    const [orientationFinalSelected, setOrientationFinalSelected] =
        useState(orientationSelected);

    //دانش
    const knoweldeg = item?.positionKnowledge.length > 0 ? item?.positionKnowledge?.map(p => { return p?.id }) : [];
    console.log('knoweldeg', knoweldeg);
    const [showKnow, setShowKnow] = useState(false);
    const [knowSelected, setKnowSelected] = useState(knoweldeg);
    const [knowFinalSelected, setKnowFinalSelected] =
        useState(knowSelected);

    //مهارت
    const skill = item?.positionSkill.length > 0 ? item?.positionSkill?.map(p => { return p?.id }) : [];
    const [showSkill, setShowSkill] = useState(false);
    const [skillSelected, setSkillSelected] = useState(skill);
    const [skillFinalSelected, setSkillFinalSelected] =
        useState(skillSelected);
    const [check, setCheck] = useState(false);

    const submitHandler = async (id) => {
        if (!id || !token) return
        if (orientationFinalSelected?.length < 1 || knowFinalSelected?.length < 1 || skillFinalSelected?.length < 1
            || degreeFinalSelected?.length < 1 || studyFinalSelected?.length < 1) {
            setCheck(true);
            return
        }
        setCheck(false);
        let formData = {
            physical,
            genderType: gender,
            requirementYear,
            approvedYear,
            describtion: desc,
            positionRelatedCondition: id,
            positionOrientation: orientationFinalSelected,
            positionKnowledge: knowFinalSelected,
            positionSkill: skillFinalSelected
        }
        console.log(formData);
        setIsLoading(true);
        let res;
        try {
            if (idCondition !== -1) {
                res = await API.patch(`/api/v1/organization/role/conditionposition/${idCondition}/`, formData, { headers: { Authorization: token } })
            }
            else {
                res = await API.post('/api/v1/organization/role/conditionposition/', formData, { headers: { Authorization: token } })
            }
            if (res?.data) {
                console.log(res?.data);
                setIsLoading(false);
                setRefresh(true);
                setEdit(false);
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }
    return (
        <Modal>
            <div className='bg-caspian-surface w-[50%] max-w-[600px] rounded-md'>
                <div className='flex justify-between items-center p-1.5'>
                    <span className='font-semibold'> ویرایش فرم شرایط احراز</span>
                    <PiX disabled={isLoading} className='cursor-pointer' onClick={() => setEdit(false)} />
                </div>

                <div className='border-t border-caspian-gray1 px-1.5 flex flex-col gap-4 py-5'>
                    {/* <div className='flex items-start gap-2'> */}
                    {/* <div className='w-[50%]'>
                        <ListGender
                            gender={gender}
                            setGender={setGender}
                            genderList={genderList}
                            genderSelected={genderSelected}
                            setGenderSelected={setGenderSelected}
                            lable='جنسیت'
                        />
                    </div> */}

                    {/* </div> */}
                    <div className='flex items-center gap-2'>
                        <div className='basis-1/2 flex flex-col gap-2'>
                            <span className='text-xs text-caspian-gray4'>شرایط فیزیکی</span>
                            <textarea
                                defaultValue={item?.physical}
                                onChange={(e) => setPhysical(e?.target?.value)}
                                rows={3} cols={3} className='resize-none p-1.5 pl-0 text-xs border border-caspian-gray1 rounded-md'></textarea>
                        </div>

                        {/* <div className='basis-1/2 flex flex-col gap-2'>
                            <span className='text-xs text-caspian-gray4'>توضیحات</span>
                            <textarea
                                defaultValue={item?.describtion}
                                onChange={(e) => setDesc(e?.target?.value)}
                                rows={3} cols={3} className='resize-none p-1.5 pl-0 text-xs border border-caspian-gray1 rounded-md'></textarea>
                        </div> */}
                    </div>

                    <div className='flex items-center gap-2'>
                        <div className='basis-1/4'>
                            <ListGender
                                gender={gender}
                                setGender={setGender}
                                genderList={genderList}
                                genderSelected={genderSelected}
                                setGenderSelected={setGenderSelected}
                                lable='جنسیت'
                            />
                        </div>
                        <div className='basis-1/2 flex flex-col gap-2'>
                            <span className='text-xs text-caspian-gray4 whitespace-nowrap'>سابقه کار مورد نیاز مرتبط (سال)</span>
                            <input
                                type='number'
                                min={0}
                                defaultValue={item?.requirementYear}
                                onChange={(e) => setRequirementYear(e?.target?.value)}
                                className='resize-none p-1.5 pl-0 text-xs border border-caspian-gray1 rounded-md'></input>
                        </div>

                        <div className='basis-1/2 flex flex-col gap-2'>
                            <span className='text-xs text-caspian-gray4 whitespace-nowrap'>سابقه کار مورد قبول (سال)</span>
                            <input
                                type='number'
                                min={0}
                                defaultValue={item?.approvedYear}
                                onChange={(e) => setApprovedYear(e?.target?.value)}
                                className='resize-none p-1.5 pl-0 text-xs border border-caspian-gray1 rounded-md'></input>
                        </div>
                    </div>
                    <div className='flex gap-2 items-baseline'>
                        <div className='flex flex-col gap-2 basis-1/2 border border-caspian-primary-500 bg-caspian-primary-50 p-1.5 rounded-md'>
                            {/* degree */}
                            <div className='flex flex-col gap-2'>
                                <div className='flex justify-between'>
                                    <span className='text-xs text-caspian-gray4'>انتخاب مقطع‌ها</span>
                                    {check && degreeFinalSelected?.length < 1 &&
                                        <span className='text-caspian-error2 text-xs'>انتخاب کنید.</span>}
                                </div>
                                <div
                                    onClick={() => setShowDegree(true)}
                                    className='cursor-pointer border border-[#3C77A2] rounded-md py-2 p-3 '>
                                    <div className='flex justify-between items-center'>
                                        <p className='text-[#3C77A2] flex items-center gap-1 text-xs'>
                                            <PiPlusBold />
                                            <span>انتخاب</span>
                                        </p>
                                        {degreeFinalSelected?.length > 0 ? (
                                            <span className="text-xs whitespace-nowrap">
                                                {degreeFinalSelected?.length} مورد افزوده شد
                                            </span>
                                        ) : (
                                            <span className="text-xs whitespace-nowrap">موردی انتخاب نشده</span>
                                        )}
                                    </div>
                                    {showDegree && (
                                        <ListDegree
                                            showSection={showDegree}
                                            setShowSection={setShowDegree}
                                            selected={degreeSelected}
                                            setSelected={setDegreeSelected}
                                            finalSelected={degreeFinalSelected}
                                            setFinalSelected={setDegreeFinalSelected}
                                            // lable='تحصیلات مورد نیاز'
                                            url={''}
                                            edu={edu}
                                            setStudySelected={setStudySelected}
                                            setStudyFinalSelected={setStudyFinalSelected}
                                            setOrientationFinalSelected={setOrientationFinalSelected}
                                            setOrientationSelected={setOrientationSelected}
                                        />
                                    )}
                                </div>
                            </div>
                            {/* studyField */}
                            <div className='flex flex-col gap-2'>
                                <div className='flex justify-between'>
                                    <span className='text-xs text-caspian-gray4'>انتخاب رشته‌ها</span>
                                    {check && studyFinalSelected?.length < 1 &&
                                        <span className='text-caspian-error2 text-xs'>انتخاب کنید.</span>}
                                </div>
                                <div
                                    onClick={() => setShowStudy(true)}
                                    className='cursor-pointer border border-[#3C77A2] rounded-md py-2 p-3 '>
                                    <div className='flex justify-between items-center'>
                                        <p className='text-[#3C77A2] flex items-center gap-1 text-xs'>
                                            <PiPlusBold />
                                            <span>انتخاب</span>
                                        </p>
                                        {studyFinalSelected?.length > 0 ? (
                                            <span className="text-xs whitespace-nowrap">
                                                {studyFinalSelected?.length} مورد افزوده شد
                                            </span>
                                        ) : (
                                            <span className="text-xs whitespace-nowrap">موردی انتخاب نشده</span>
                                        )}
                                    </div>
                                    {showStudy && (
                                        <ListStudy
                                            showSection={showStudy}
                                            setShowSection={setShowStudy}
                                            selected={studySelected}
                                            setSelected={setStudySelected}
                                            finalSelected={studyFinalSelected}
                                            setFinalSelected={setStudyFinalSelected}
                                            // lable='تحصیلات مورد نیاز'
                                            url={''}
                                            setOrientationFinalSelected={setOrientationFinalSelected}
                                            setOrientationSelected={setOrientationSelected}
                                            setDegreeFinalSelected={setDegreeFinalSelected}
                                            degreeFinalSelected={degreeFinalSelected}
                                            edu={edu}
                                        />
                                    )}
                                </div>
                            </div>
                            {/* orientation */}
                            <div className='flex flex-col gap-2'>
                                <div className='flex justify-between'>
                                    <span className='text-xs text-caspian-gray4'>انتخاب گرایش‌ها</span>
                                    {check && orientationFinalSelected?.length < 1 &&
                                        <span className='text-caspian-error2 text-xs'>انتخاب کنید.</span>}
                                </div>
                                <div
                                    onClick={() => setShowOrientation(true)}
                                    className='cursor-pointer border border-[#3C77A2] rounded-md py-2 p-3 '>
                                    <div className='flex justify-between items-center'>
                                        <p className='text-[#3C77A2] flex items-center gap-1 text-xs'>
                                            <PiPlusBold />
                                            <span>انتخاب</span>
                                        </p>
                                        {orientationFinalSelected?.length > 0 ? (
                                            <span className="text-xs whitespace-nowrap">
                                                {orientationFinalSelected?.length} مورد افزوده شد
                                            </span>
                                        ) : (
                                            <span className="text-xs whitespace-nowrap">موردی انتخاب نشده</span>
                                        )}
                                    </div>
                                    {showOrientation && (
                                        <ListOrientation
                                            showSection={showOrientation}
                                            setShowSection={setShowOrientation}
                                            selected={orientationSelected}
                                            setSelected={setOrientationSelected}
                                            finalSelected={orientationFinalSelected}
                                            setFinalSelected={setOrientationFinalSelected}
                                            // lable='تحصیلات مورد نیاز'
                                            url={''}
                                            studyFinalSelected={studyFinalSelected}
                                            edu={edu}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className='flex flex-col gap-2 basis-1/2 p-1.5s'>
                            {/* knowledge */}
                            <div className='flex flex-col gap-2'>
                                <div className='flex justify-between'>
                                    <span className='text-xs text-caspian-gray4'>انتخاب دانش‌ها</span>
                                    {check && knowFinalSelected?.length < 1 &&
                                        <span className='text-caspian-error2 text-xs'>انتخاب کنید.</span>}
                                </div>
                                <div
                                    onClick={() => setShowKnow(true)}
                                    className='cursor-pointer border border-[#3C77A2] rounded-md py-2 p-3 '>
                                    <div className='flex justify-between items-center'>
                                        <p className='text-[#3C77A2] flex items-center gap-1 text-xs'>
                                            <PiPlusBold />
                                            <span>انتخاب</span>
                                        </p>
                                        {knowFinalSelected?.length > 0 ? (
                                            <span className="text-xs whitespace-nowrap">
                                                {knowFinalSelected?.length} مورد افزوده شد
                                            </span>
                                        ) : (
                                            <span className="text-xs whitespace-nowrap">موردی انتخاب نشده</span>
                                        )}
                                    </div>
                                    {showKnow && (
                                        <ListKnowledge
                                            showSection={showKnow}
                                            setShowSection={setShowKnow}
                                            selected={knowSelected}
                                            setSelected={setKnowSelected}
                                            finalSelected={knowFinalSelected}
                                            setFinalSelected={setKnowFinalSelected}
                                            // lable='تحصیلات مورد نیاز'
                                            url={'/api/v1/knowledge/knowledge/'}
                                        />
                                    )}
                                </div>
                            </div>
                            {/* skill */}
                            <div className='flex flex-col gap-2'>
                                <div className='flex justify-between'>
                                    <span className='text-xs text-caspian-gray4'>انتخاب مهارت‌ها</span>
                                    {check && skillFinalSelected?.length < 1 &&
                                        <span className='text-caspian-error2 text-xs'>انتخاب کنید.</span>}
                                </div>
                                <div
                                    onClick={() => setShowSkill(true)}
                                    className='cursor-pointer border border-[#3C77A2] rounded-md py-2 p-3 '>
                                    <div className='flex justify-between items-center'>
                                        <p className='text-[#3C77A2] flex items-center gap-1 text-xs'>
                                            <PiPlusBold />
                                            <span>انتخاب</span>
                                        </p>
                                        {skillFinalSelected?.length > 0 ? (
                                            <span className="text-xs whitespace-nowrap">
                                                {skillFinalSelected?.length} مورد افزوده شد
                                            </span>
                                        ) : (
                                            <span className="text-xs whitespace-nowrap">موردی انتخاب نشده</span>
                                        )}
                                    </div>
                                    {showSkill && (
                                        <ListSkill
                                            showSection={showSkill}
                                            setShowSection={setShowSkill}
                                            selected={skillSelected}
                                            setSelected={setSkillSelected}
                                            finalSelected={skillFinalSelected}
                                            setFinalSelected={setSkillFinalSelected}
                                            // lable='تحصیلات مورد نیاز'
                                            url={'/api/v1/knowledge/skill/'}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='flex '>
                        <div className='basis-1/2 flex flex-col gap-2'>
                            <span className='text-xs text-caspian-gray4'>توضیحات</span>
                            <textarea
                                defaultValue={item?.describtion}
                                onChange={(e) => setDesc(e?.target?.value)}
                                rows={3} cols={3} className='resize-none p-1.5 pl-0 text-xs border border-caspian-gray1 rounded-md'></textarea>
                        </div>

                    </div>
                    <button
                        disabled={isLoading}
                        onClick={() => submitHandler(id)}
                        className='bg-[#3C77C2] w-[100px] flex gap-2 justify-center items-center text-caspian-surface text-xs rounded-md p-1.5'>
                        <span>ثبت</span>
                        {isLoading && <span>
                            <PiCircleNotch className='animate-spin' />
                        </span>}
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default EditAuthForm;
