import React from "react";
import TableReceviedRequest from "./TableReceviedRequest";
import { useParams } from "react-router-dom";
import MainDetailsSingleRecevied from "./details-single-recevied/MainDetailsSingleRecevied";

const DetailsReceviedRequest = () => {
  const params = useParams();
  return (
    <div>
      {params?.id ? <MainDetailsSingleRecevied /> : <TableReceviedRequest />}
    </div>
  );
};

export default DetailsReceviedRequest;
