import React from 'react'
import { PiCheckCircleLight } from "react-icons/pi";

const SuccessUpload = () => {
    return (

        <div className="fixed inset-0 flex items-center justify-center w-full h-full z-50">
            <div className='fixed inset-0 bg-caspian-on_primary/30 z-50 p-4' ></div>
            <div className="z-50 fixed flex flex-col items-center gap-3 rounded-lg shadow-[0_0_10px_#00000070] bg-caspian-surface justify-center w-1/3 h-1/4">
                <PiCheckCircleLight className='text-caspian-green-700 text-5xl' />

                <span className='text-xs xl:text-sm'>بارگزاری با موفقیت انجام شد</span>
                <span className='text-xs xl:text-sm text-caspian-darkgray-400'>فایل ارسالی پس از تایید در سامانه قرار می‌گیرد</span>

            </div>

        </div>
    )
}

export default SuccessUpload