import React, { useState } from 'react'
import { PiCaretLeft } from 'react-icons/pi'
import ModalListCostReport from './ModalListCostReport'
import { priceType } from '../../../../../../../helper/pricetype'

const SingleListCostReport = ({ dataListCost }) => {
    const [showModal, setShowModal] = useState(false)
    const [selectId, setSelectId] = useState(null)

    return (
        <div className='flex flex-col items-center w-full h-[150px] overflow-auto gap-5'>

            {/* {fullData?.costRelatedreportProfile?.map((costRecord) => (
                costRecord?.reportRecordRelated?.map((item) => ( */}

            {dataListCost?.map((item) => (
                <div key={item?.id} className='flex items-center justify-between px-2 w-[90%] pb-1 border-b-2 border-b-caspian-gray2'>
                    <span className='text-xs'>
                        {item?.title}
                    </span>

                    <span className='text-xs 2xl:text-sm text-caspian-gray'>
                        {priceType(item?.amount)}(ريال)
                    </span>

                    <div className='flex items-center gap-2 cursor-pointer text-caspian-secondary-600'
                        onClick={() => {
                            setShowModal(true)
                            setSelectId(item?.id)
                        }}>
                        <span className='text-xs 2xl:text-sm'>بیشتر</span>
                        <PiCaretLeft />
                    </div>
                </div >
            ))}
            {showModal && <ModalListCostReport setShowModal={setShowModal} selectId={selectId} dataListCost={dataListCost} />}
        </div>
    )
}

export default SingleListCostReport