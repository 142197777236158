import React from "react";
import InfoBox from "./InfoBox";
import { PiMapPin } from "react-icons/pi";

const DetailsPersonInternalContact = ({ heightHandler }) => {
  return (
    <div className="w-full flex flex-col gap-2">
      <InfoBox title="واحد سازمانی" desc={["مدیریت"]} />
      <InfoBox title="داخلی 1" desc={["88454789", "داخلی 87"]} />
      <InfoBox title="شماره تماس مستقیم" desc={["021-8812578"]} />
      <InfoBox title="شماره همراه" desc={["0912958789"]} />
      {heightHandler && (
        <div className="w-full flex flex-col gap-2 mb-8">
          <InfoBox title="ایمیل سازمانی" desc={["test@gmail.com"]} />
          <InfoBox title="ایمیل شخصی" desc={["test@gmail.com"]} />
          <InfoBox title="سایر نقش ها" desc={["مسئول دفترل", "مسئول حفاظت"]} />
          <InfoBox title="محل خدمت" desc={["ساختمان الزهرا طبقه 9 اتاق 901"]} />
          <div className="flex items-start gap-0 odd:py-1 even:bg-caspian-surface odd:bg-caspian-gray3 p-1">
            <div className="basis-1/2 flex flex-col gap-2">
              <span className="text-xs text-caspian-gray4">لوکیشن</span>
            </div>
            <div className="basis-1/2 flex flex-col gap-0">
              <div className="flex items-center gap-2 text-caspian-secondary-500">
                <PiMapPin />
                <span className="text-xs">تهران</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailsPersonInternalContact;
