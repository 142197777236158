import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import StackholdersNavbar from "../StackholdersNavbar";
import API from "../../../api/API";
import StackHoldersTable from "./stackholderstable/StackHoldersTable";
import Loading from "../../../Loading";
import { AuthContext } from "../../../context/auth/AuthContext";

const MainListStackholders = () => {
  const {token}=useContext(AuthContext);
  const [data, setData] = useState([-1]);
  const [searchRes, setSearchRes] = useState([-1]);
  const [ttileRes, setTitleRes] = useState([-1]);
  //
  const [serachParams, setSearchParams] = useSearchParams();
  const searchQuery = serachParams.get('search')
  const page = serachParams.get("page");
  const rows = serachParams.get('rows');

  const [search, setSearch] = useState(prev=>{
    if(searchQuery){
      return searchQuery;
    }
  });
  const [title, setTitle] = useState('');
  const [cat, setCat] = useState('');
  const [post, setPost] = useState('');
  const [group, setGroup] = useState('');
  const [code, setCode] = useState('')
  const [refresh, setRefresh] = useState(0);
  // const [offset, setOffset] = useState(0);
  // const [limit, setLimit] = useState(10);

  const [offset, setOffset] = useState(prev => {
    if (page && rows === 20) {
      return (page === 1 ? 0 : (page - 1) * 20)
    }
    else if (page && rows === 30) {
      return (page === 1 ? 0 : (page - 1) * 30)
    }
    else if (page) {
      return (page === 1 ? 0 : (page - 1) * 10)
    }
    else return 0
  });
  const [limit, setLimit] = useState(rows ? rows : 10);

  useEffect(() => {
    setRefresh(1)
  }, [])

  useEffect(() => {
    if (!search || !token) return
    const fetchData = async () => {
      try {
        const res = await API.get(`/api/v1/stackholder/group/?offset=${offset}&limit=${limit}
            &search=${search}`,{headers:{Authorization:token}});
        setSearchRes(res.data);
        console.log(res.data);
        setRefresh(0)
      }
      catch (err) {
        console.log('error message:', err.message);
        if (err.response.status === 404) {
          console.log('my error');
          // setSearchRes([-1])
          setData([])
        }
      }
    }
    fetchData()
  }, [limit, offset, search,searchQuery,token])


  useEffect(() => {
    if (search) return
    const fetchData = async () => {
      try {
        const res = await API.get(`/api/v1/stackholder/group/?offset=${offset}&limit=${limit}`,{headers:{Authorization:token}});
        setSearchRes([-1])
        setData(res.data);
        console.log(res.data);
        setRefresh(0)
      }
      catch (err) {
        console.log('error message:', err.message);
        if (err.response.status === 404) {
          console.log('my error');
          setData([])
        }
      }
    }
    fetchData();
  }, [limit, offset, search,token])
  useEffect(() => {
    // if (group?.length < 2 && !code) {
    //   setTitleRes([-1])
    //   return
    // }
    const fetchData = async () => {
      let res;
      try {
        if (title?.length > 2 || group?.length > 2 || cat?.length > 2 || post?.length > 2) {
          if(title && title!=='internal' && title !=='external'){
            setTitleRes([])
            return
          }
          if (search) {
            res = await API.get(`/api/v1/stackholder/group/?title=${group}&sectionRelated__title=${cat}&sectionRelated__categoryRelated__title=${post}&offset=${offset}&limit=${limit}
                    &sectionRelated__categoryRelated__categoryType=${title}&search=${search}`,{headers:{Authorization:token}});
            console.log(res.data);
          }
          else {
            res = await API.get(`/api/v1/stackholder/group/?title=${group}&sectionRelated__title=${cat}&sectionRelated__categoryRelated__title=${post}&sectionRelated__categoryRelated__categoryType=${title}&offset=${offset}&limit=${limit}`,{headers:{Authorization:token}});
            console.log(res.data);
          }
          setTitleRes(res.data)
        }
        else {
          setTitleRes([-1])
        }
      }
      catch (err) {
        if (err.response.status === 404) {
          console.log(err.message);
          setData([])
        }
        // else if(err.response.status === 400){
        //   setTitleRes([])
        // }
        // console.log('error:', err.message);
        // setTitleRes([-1]);
        // //setSearchRes([-1]);
      }
    }
    const time = setTimeout(() => {
      fetchData();
    }, 1000);
    return () => {
      clearTimeout(time)
    }


  }, [group, cat, post, title, search, offset, limit,token])
  return (
    <div className="flex flex-col gap-0">
      <StackholdersNavbar buttonActive="list" />
      {/* {
        searchRes[0] !== -1 && <StackholdersSearchTable data={searchRes} />
      } */}
      {
        // (searchRes[0] === -1) &&
        (!search &&data[0] === -1) ? (<Loading />) : data?.length < 1 ? (<p className="text-center py-20 text-xs 2xl:text-sm font-bold text-caspian-gray4">داده وجود ندارد.</p>) : (<StackHoldersTable
          // data={(searchRes[0] === -1 && ttileRes[0] === -1) ? data : (ttileRes[0] !== -1) ? ttileRes : search ? searchRes : data}
          data={(ttileRes[0] !== -1) ? ttileRes : search ? searchRes : data}
          // data={ttileRes[0] !== -1 ? ttileRes : data}
          group={group}
          cat={cat}
          setCat={setCat}
          title={title}
          post={post}
          setTitle={setTitle}
          setPost={setPost}
          search={search}
          setData={setData}
          setSearch={setSearch}
          offset={offset}
          setOffset={setOffset}
          setLimit={setLimit}
          limit={limit}
          setGroup={setGroup}
          setCode={setCode}
        />)
      }

    </div>
  );
};

export default MainListStackholders;
