import React, { useEffect, useRef, useState } from "react";
import HoverItemsDetails from "./HoverItemsDetails";

const ItemSingle = ({ title, data }) => {
  const [showHoverItemsDetails, setshowHoverItemsDetails] = useState(false);

  const [open, setOpen] = useState(false);
  let menuRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <div ref={menuRef} className="relative">
      <span
        onClick={() => setOpen(!open)}
        // onMouseEnter={() => setOpen(true)}
        // onMouseLeave={() => setOpen(false)}
        className="cursor-pointer hover:text-caspian-secondary-600 text-xs"
      >
        {title}
      </span>
      <HoverItemsDetails open={open} setOpen={setOpen} data={data} />
    </div>
  );
};

export default ItemSingle;
