import { useRef } from "react";

const SingleTablePresent = ({
  id,
  fullName,
  role,
  position,
  company,
  absent,
  isAbsent,
  setIsAbsent,
}) => {
  const checkedRef = useRef();
  return (
    <tr className="cursor-pointer bg-caspian-surface duration-300">
      <td className="whitespace-nowrap text-right border-b border-b-caspian-gray2 px-3 py-2 w-[20%]">
        <h2>{fullName}</h2>
      </td>
      <td className="whitespace-nowrap text-right border-b border-b-caspian-gray2 min-w-[100px] w-[20%]">
        <h2>{role}</h2>
      </td>
      <td className="whitespace-nowrap text-right border-b border-b-caspian-gray2 px-3 py-2 w-[20%]">
        <h2>{position}</h2>
      </td>
      <td className="whitespace-nowrap text-right border-b border-b-caspian-gray2 px-3 py-2 w-[20%]">
        <h2>{company}</h2>
      </td>
      <td className="whitespace-nowrap text-right border-b border-b-caspian-gray2 px-3 py-2 w-[20%]">
        <div className="flex items-center gap-2">
          <label
            className={`cursor-pointer flex items-center gap-1 text-xs ${
              checkedRef?.current?.checked ? "text-[#389b82]" : "text-[#db3d5f]"
            }`}
          >
            <input
              ref={checkedRef}
              type="checkbox"
              defaultChecked={absent}
              onChange={async (e) => {
                (await e?.target?.checked)
                  ? setIsAbsent([...isAbsent, id])
                  : setIsAbsent(isAbsent.filter((d) => d !== id));
              }}
              className={`cursor-pointer accent-[#389b82]`}
            />
            {checkedRef?.current?.checked ? "حاضر" : "غایب"}
          </label>
        </div>
      </td>
    </tr>
  );
};

export default SingleTablePresent;
