import React, { useState } from "react";

const MainAcceptChecklist = ({ allQuestionSelected }) => {
  const [isAccept, setIsAccept] = useState(false);
  return (
    <div className="w-full flex items-center">
      <div className="flex items-center gap-8">
        <div className="flex items-center gap-2">
          <input
            onChange={() => setIsAccept(!isAccept)}
            id="accept"
            type="checkbox"
            className=" cursor-pointer accent-caspian-secondary-400"
          />
          <label htmlFor="accept" className="text-xs cursor-pointer font-bold">
            آیا از صحت و درستی سوالات چک لیست تمام بندها اطمینان کامل دارید؟
          </label>
        </div>
        <div className="flex items-center gap-2">
          <button
            disabled={isAccept ? false : true}
            onClick={() => console.log(allQuestionSelected)}
            className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
          >
            ثبت چک لیست
          </button>
        </div>
      </div>
    </div>
  );
};

export default MainAcceptChecklist;
