import React, { useState } from "react";
import InternalContactMain from "./internal-contact/InternalContactMain";
import ExternalContactMain from "./external-contact/ExternalContactMain";

const TabContact = ({ setShowChatPerson }) => {
  const [contactType, setContactType] = useState("internal");
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <span
          onClick={() => setContactType("internal")}
          className={`text-xs border-b pb-2 ${
            contactType === "internal"
              ? "cursor-default text-caspian-secondary-500 border-b-caspian-secondary-500"
              : "cursor-pointer text-caspian-gray4 border-b-caspian-transparent"
          }`}
        >
          درون سازمانی
        </span>
        <span
          onClick={() => setContactType("external")}
          className={`text-xs border-b pb-2 ${
            contactType === "external"
              ? "cursor-default text-caspian-secondary-500 border-b-caspian-secondary-500"
              : "cursor-pointer text-caspian-gray4 border-b-caspian-transparent"
          }`}
        >
          برون سازمانی
        </span>
      </div>
      {contactType === "internal" && (
        <InternalContactMain setShowChatPerson={setShowChatPerson} />
      )}
      {contactType === "external" && <ExternalContactMain />}
    </div>
  );
};

export default TabContact;
