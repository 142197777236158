import React from "react";
import { PiX } from "react-icons/pi";

const DetailsPopup = ({ open, setOpen, data, title }) => {
  return (
    <div
      className={
        open
          ? "fixed top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 z-[500]"
          : "hidden"
      }
    >
      <div className="p-4 flex flex-col gap-0 min-w-[320px] max-w-[320px] z-50 drop-shadow-[0_0_5px_#00000080]  bg-caspian-surface rounded-md">
        <div className=" flex flex-col gap-0">
          <div className="flex items-center justify-between w-full p-3">
            <h2 className="text-xs">مشاهده فعالیت و فرایندهای مرتبط</h2>
            <PiX onClick={() => setOpen(false)} className="cursor-pointer" />
          </div>
          <div className="flex items-center w-full gap-6 border-b border-b-caspian-gray pb-2">
            <span className="text-xs text-caspian-gray4">فرایند:</span>
            <span className="text-xs">{title}</span>
          </div>
          <span className="text-xs pt-2 pb-4">فعالیت های انتخاب شده</span>
          <div className="min-h-[100px] max-h-[180px] overflow-y-auto">
            {data?.map((p, i) => (
              <p
                key={i}
                className="text-xs leading-5 text-justify p-1 odd:bg-caspian-gray2/50 bg-caspian-gray2/20"
              >
                {p?.title}
              </p>
            ))}
            {/* <p className="text-xs leading-5 text-justify p-1 bg-caspian-gray2/20">فعالیت شماره دو</p>
            <p className="text-xs leading-5 text-justify p-1 bg-caspian-gray2/50">فعالیت شماره سه</p>
            <p className="text-xs leading-5 text-justify p-1 bg-caspian-gray2/20">فعالیت شماره چهار</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsPopup;
