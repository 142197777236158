import React, { useEffect, useState } from 'react';
import { PiX } from 'react-icons/pi';
import TopForm from './TopForm';
import BottomForm from './BottomForm';
import API from '../../../../api/API';

const MainCreateFormDocument = ({ setShowForm,refresh,setRefresh  }) => {
    const [title, setTitle] = useState('');
    const [oldCode, setOldCode] = useState('');
    const [version, setVersion] = useState('');
    const [typeDocs, setTypeDocs] = useState("");

    const [conf, setConf] = useState("internal");
    const [system, setSystem] = useState("");


    const [tagSelected, setTagSelected] = useState([]);
    const [tagFinalSelected, setTagFinalSelected] =
        useState(tagSelected);
    //date
    const [startDate, setStartDate] = useState('');
    const [check,setCheck]=useState(false);
    return (
        <div className='w-full h-full bg-caspian-on_primary/30 fixed inset-0 z-[100] flex justify-center items-center py-1'>
            <div className='flex flex-col gap-2 bg-caspian-surface w-[80%] lg:w-[65%] h-auto rounded-lg pb-3'>

                <div className='flex justify-between items-center border-b border-caspian-gray2 px-2 2xl:px-5 py-1 2xl:py-2'>
                    <p className='font-semibold text-xs 2xl:text-sm'>ایجاد سند</p>
                    <button className='p-0 text-caspian-gray4' onClick={() => setShowForm(false)}>
                        <PiX className='text-lg' />
                    </button>
                </div>
                <div className='flex flex-col gap-2'>
                    <TopForm
                        title={title} setTitle={setTitle} oldCode={oldCode} setOldCode={setOldCode}
                        version={version} setVersion={setVersion} conf={conf} setConf={setConf}
                        system={system} setSystem={setSystem} tagSelected={tagSelected} setTagSelected={setTagSelected}
                        tagFinalSelected={tagFinalSelected} setTagFinalSelected={setTagFinalSelected}
                        typeDocs={typeDocs} setTypeDocs={setTypeDocs}
                        setStartDate={setStartDate}
                        check={check} setCheck={setCheck}
                    />
                    <BottomForm setShowForm={setShowForm}
                        title={title} setTitle={setTitle} oldCode={oldCode} setOldCode={setOldCode}
                        version={version} setVersion={setVersion} conf={conf} setConf={setConf}
                        system={system} setSystem={setSystem} tagSelected={tagSelected} setTagSelected={setTagSelected}
                        tagFinalSelected={tagFinalSelected} setTagFinalSelected={setTagFinalSelected}
                        typeDocs={typeDocs} setTypeDocs={setTypeDocs}
                        startDate={startDate} setStartDate={setStartDate}
                        check={check} setCheck={setCheck} refresh={refresh} setRefresh={setRefresh}
                        />
                </div>
            </div>
        </div>
    );
}

export default MainCreateFormDocument;
