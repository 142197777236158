import React, { useContext, useEffect, useState } from "react";
import ButtonTab from "./ButtonTab";
import MainInprogress from "./Inprogress/MainInprogress";
import MainMyplans from "./myplans/MainMyplans";
import MainReport from "./report/MainReport";
import ActionPlanNavbar from "../../ActionPlanNavbar";
import { AuthContext } from "../../../../context/auth/AuthContext";
import API from "../../../../api/API";
import { PiPlusCircle } from "react-icons/pi";
import MainCreateOfficeForm from "./form/create-office/MainCreateOfficeForm";

const MainPmoDashboard = () => {
  const { token } = useContext(AuthContext);
  const [fullData, setfullData] = useState([-1]);
  const [showCreateOfficeForm, setShowCreateOfficeForm] = useState(false);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    setRefresh(1);
  }, []);
  useEffect(() => {
    if (!token || refresh !== 1) return;
    // let url = "/api/v1/actionplan/actionplanLISTROLE";
    // let url = "/api/v1/actionplan/actionplans?type=حئخ";
    let url = "/api/v1/actionplan/actionplanROLE";

    const getPmData = async () => {
      API.get(url, {
        headers: { Authorization: token },
      })
        .then((res) => {
          setfullData(res.data);
          console.log(res.data);
          setRefresh(0);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getPmData();
  }, [token, refresh]);
  const [activeTab, setActiveTab] = useState("inprogress");
  return (
    <div className="flex flex-col gap-2">
      {showCreateOfficeForm && (
        <MainCreateOfficeForm
          setRefresh={setRefresh}
          setShowCreateOfficeForm={setShowCreateOfficeForm}
        />
      )}
      <ActionPlanNavbar buttonActive="plans" />
      <div className="w-full flex flex-col gap-4">
        <div className="flex items-center gap-4 py-1.5 px-2 rounded-md">
          <ButtonTab
            activeTab={activeTab}
            active="inprogress"
            onClick={() => setActiveTab("inprogress")}
            title="درحال برنامه ریزی"
          />
          <ButtonTab
            activeTab={activeTab}
            active="myplans"
            onClick={() => setActiveTab("myplans")}
            title="برنامه های من"
          />
          <ButtonTab
            activeTab={activeTab}
            active="report"
            onClick={() => setActiveTab("report")}
            title="لیست گزارش ها"
          />
        </div>
      </div>
      <div className="p-2">
        <button
          onClick={() => setShowCreateOfficeForm(true)}
          className="w-fit flex items-center gap-1 rounded-md bg-caspian-secondary-500 text-caspian-surface text-sm p-2"
        >
          <PiPlusCircle className="text-xl" />
          <span className="text-sm">ایجاد برنامه سازمانی</span>
        </button>
        {activeTab === "inprogress" ? (
          <MainInprogress
            fullData={fullData?.filter((d) => d?.statusAction !== "StartTasks")}
          />
        ) : activeTab === "myplans" ? (
          <MainMyplans
            fullData={fullData?.filter((d) => d?.statusAction === "StartTasks")}
          />
        ) : (
          <MainReport />
        )}
      </div>
    </div>
  );
};

export default MainPmoDashboard;
