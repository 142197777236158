import React, { useEffect, useRef, useState } from "react";
import { PiCaretDownBold, PiMagnifyingGlassBold } from "react-icons/pi";

const ManagerMember = ({
  setManager,
  managerSelected,
  setManagerSelected,
  showListManager,
  setShowListManager,
  members,
  lable,
}) => {
  let menuRef = useRef();

  const [open, setOpen] = useState(false);
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const [searchByMember, setSearchByMember] = useState("");
  let searchMember = [];

  if (members !== -1) {
    searchMember = searchByMember
      ? members?.filter(
          (d) =>
            (d?.title !== undefined &&
              d?.title
                .toLocaleLowerCase()
                .includes(searchByMember.toLocaleLowerCase())) ||
            (d !== undefined &&
              (d?.firstName + " " + d?.lastName)
                .toLocaleLowerCase()
                .includes(searchByMember.toLocaleLowerCase()))
        )
      : members;
  }

  return (
    <div ref={menuRef} className="flex flex-col gap-1.5 w-full relative">
      <label className="text-xs text-caspian-gray">{lable}</label>
      <div
        onClick={() => {
          // setShowListManager(!showListManager);
          setOpen(!open);
        }}
        className="text-xs outline outline-1 outline-caspian-gray1 p-2 rounded-md bg-caspian-transparent cursor-pointer flex items-center justify-between"
      >
        {managerSelected !== null && managerSelected !== undefined ? (
          <span className="text-xs">
            {managerSelected?.firstName + " " + managerSelected?.lastName}
          </span>
        ) : (
          <span className="text-caspian-gray1 text-xs">انتخاب کنید</span>
        )}
        <PiCaretDownBold className="text-caspian-gray" />
      </div>
      {
        <div
          className={
            open
              ? "rounded-md shadow-[0_0_5px_#00000050] w-full flex flex-col gap-3 py-3 absolute left-0 right-0 top-[58px] bg-caspian-surface z-50"
              : "hidden"
          }
        >
          <div className="relative w-[92%] mx-auto">
            <input
              onChange={(e) => {
                setSearchByMember(e.target.value);
              }}
              type="search"
              className="outline-none outline-caspian-gray1 p-1 text-xs rounded-md outline-1 w-full placeholder:text-xs placeholder:text-caspian-gray pr-6"
              placeholder="جستجو"
            />
            <button className="absolute top-1/2 -translate-y-1/2 right-1">
              <PiMagnifyingGlassBold className="text-caspian-gray text-" />
            </button>
          </div>
          <div className="flex flex-col gap-2 w-full max-h-[200px] overflow-y-scroll">
            {searchMember?.length > 0 &&
              searchMember.map((m, i) => (
                <div
                  key={i}
                  onClick={() => {
                    setManagerSelected(m);
                    setManager(m?.id);
                    setSearchByMember("");
                    setOpen(false);
                  }}
                  className="flex items-center justify-start gap-2 text-xs hover:bg-caspian-secondary-100 py-1 px-2 w-full cursor-pointer duration-300"
                >
                  <span className="">{m?.firstName + " " + m?.lastName}</span>
                </div>
              ))}
          </div>
        </div>
      }
    </div>
  );
};

export default ManagerMember;
