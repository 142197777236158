import React, { useContext, useEffect, useState } from "react";
import { PiPlusBold } from "react-icons/pi";
import ReminderForm from "./reminder/ReminderForm";
import SingleListReminder from "./reminder/SingleListReminder";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import API from "../../../../../api/API";
import { AuthContext } from "../../../../../context/auth/AuthContext";

const Reminder = ({
  thisTitle,
  thisId,
  setActiveTab,
  setShowPlanningMeetingForm,
  setRefresh,
  fullData,
  setMeetingListRefresh,
}) => {
  const location = useLocation();

  const navigate = useNavigate();
  const { token } = useContext(AuthContext);

  const [showReminderForm, setShowReminderForm] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [data, setData] = useState([]);
  const [getReminder, setGetReminder] = useState([]);

  useEffect(() => {
    setGetReminder(fullData);
  }, [fullData]);

  // get activity
  const getActivitySend = async () => {
    await API.get("/api/v1/profile/activity", {
      headers: { Authorization: token },
    })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // post activity
  const postActivitySend = async (input) => {
    await API.post(
      "/api/v1/meeting/info/activity",
      {
        meetingSelected_id: thisId,
        link:
          input === "conf"
            ? `/managment-of-meetings/list-meeting/all-meeting/${thisId}`
            : location.pathname,
      },
      {
        headers: { Authorization: token },
      }
    )
      .then(async (res) => {
        await getActivitySend();
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataHandler = async () => {
    console.log(data);
  };

  const dataHandlerDraft = async () => {
    // e.preventDefault();
    await API.post(
      `/api/v1/meeting/info/detail/${thisId}/status/`,
      {
        status: "reminder",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await dataHandler();
        await toast.success("برنامه ریزی جلسه به صورت پیشنویس ثبت شد.", {
          className: "toast-success",
          bodyClassName: "toast-success",
        });
        await setShowPlanningMeetingForm(false);
        await setMeetingListRefresh(1);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataHandlerEnd = async () => {
    // e.preventDefault();
    await API.post(
      `/api/v1/meeting/info/detail/${thisId}/status/`,
      {
        status: "conf",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await dataHandler();
        await setShowPlanningMeetingForm(false);
        await navigate(
          `/managment-of-meetings/list-meeting/all-meeting/${thisId}`
        );
        postActivitySend("conf");
        // await navigate(`?meeting=${thisId}`);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataHandlerBack = async () => {
    await setActiveTab("presentationFile");
  };

  useEffect(() => {
    if (fullData.length < 1) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [fullData]);
  return (
    <div className="min-h-[270px] flex flex-col justify-between">
      <div className="flex flex-col gap-3">
        <button
          onClick={() => setShowReminderForm(true)}
          className="flex items-center justify-between w-full border-y border-y-caspian-secondary-500 text-caspian-secondary-500 py-2 cursor-pointer hover:bg-caspian-secondary-50/20"
        >
          <span className="text-xs">افزودن یادآوری جلسه</span>
          <PiPlusBold className="text-sm" />
        </button>
        {showReminderForm && (
          <ReminderForm
            thisTitle={thisTitle}
            setRefresh={setRefresh}
            thisId={thisId}
            data={data}
            setData={setData}
            setShowReminderForm={setShowReminderForm}
          />
        )}
        <div className="p-0.5 max-h-[180px] overflow-y-scroll">
          {getReminder.length > 0 ? (
            <table className="rounded-md overflow-hidden outline outline-1 outline-caspian-gray1 min-w-full">
              <thead className="table-fixed w-full text-xs text-caspian-gray text-right">
                <tr className="bg-caspian-gray3 w-full">
                  <th className="p-2">اعضا جلسه</th>
                  <th className="p-2">تاریخ یادآوری</th>
                  <th className="p-2">ساعت یادآوری</th>
                  <th className="p-2 w-20"></th>
                </tr>
              </thead>
              <tbody className="w-full text-xs text-caspian-gray4 text-right">
                {getReminder.map((d, i) => (
                  <SingleListReminder
                    key={i}
                    id={d?.id}
                    members={d.title}
                    date={d.meetingReminderDate}
                    time={d.meetingReminderTime}
                    setRefresh={setRefresh}
                  />
                ))}
              </tbody>
            </table>
          ) : (
            <h2 className="text-xs text-caspian-gray1">
              یادآوری جلسه ای اضافه نشده است!
            </h2>
          )}
        </div>
      </div>
      <div className="flex items-center gap-2">
        <button
          onClick={async () => {
            await dataHandlerBack();
          }}
          className="min-w-[6rem] outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs p-1.5 rounded-md"
        >
          مرحله قبل
        </button>
        <button
          disabled={disableButton}
          onClick={async () => {
            await dataHandlerEnd();
          }}
          className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
        >
          ثبت نهایی
        </button>
        <button
          disabled={disableButton}
          onClick={async () => {
            await dataHandlerDraft();
          }}
          className={`disabled:cursor-not-allowed disabled:text-caspian-gray text-caspian-secondary-500 text-xs p-1.5 rounded-md`}
        >
          ذخیره پیشنویس
        </button>
      </div>
    </div>
  );
};

export default Reminder;
