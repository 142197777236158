import React, { useContext, useState } from "react";
import ListRiskSubjectProcess from "./ListRiskSubjectProcess";
import ListRiskSubjectGoal from "./ListRiskSubjectGoal";
import Modal from "../../../../modal/Modal";
import { PiPlusCircleBold, PiX } from "react-icons/pi";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import API from "../../../../../api/API";

const FormRiskSubject = ({
  riskTeamObject,
  profileId,
  setShowFormRiskSubject,
  setRefreshTeamProfile,
}) => {
  const { token } = useContext(AuthContext);

  const [showListRiskSubject, setShowListRiskSubject] = useState(false);

  const [processSelected, setProcessSelected] = useState([]);
  const [processFinalSelected, setProcessFinalSelected] =
    useState(processSelected);
  const [goalSelected, setGoalSelected] = useState([]);
  const [goalFinalSelected, setGoalFinalSelected] = useState(goalSelected);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const processHandler = async () => {
    if ((!token, !profileId)) return;
    if (!title) return;
    let formData = {
      title: title,
      description: description,
      teamSpecializedRelated: +profileId,
      groupProcessRelatedRiskTeam: processFinalSelected,
    };
    console.log(formData);
    try {
      const res = await API.post(
        `/api/v1/risk/team/subject/operational/`,
        formData,
        {
          headers: { Authorization: token },
        }
      );
      console.log(res?.data);
      setProcessSelected([]);
      setProcessFinalSelected([]);
      setShowFormRiskSubject(false);
      statusTeamHandler(+profileId);
    } catch (err) {
      console.log(err);
    }
  };

  const goalHandler = async () => {
    if ((!token, !profileId)) return;
    if (!title) return;
    let formData = {
      title: title,
      description: description,
      teamSpecializedRelated: +profileId,
      goalsRelatedRiskTeam: goalFinalSelected,
    };
    console.log(formData);
    try {
      const res = await API.post(
        `/api/v1/risk/team/subject/strategic/`,
        formData,
        {
          headers: { Authorization: token },
        }
      );
      console.log(res?.data);
      setGoalSelected([]);
      setGoalFinalSelected([]);
      setShowFormRiskSubject(false);
      statusTeamHandler(+profileId);
    } catch (err) {
      console.log(err);
    }
  };

  const statusTeamHandler = async (id) => {
    API.patch(
      `/api/v1/risk/team/detail/${id}/`,
      {
        statusType: "ready",
      },
      {
        headers: { Authorization: token },
      }
    )
      .then((res) => {
        setRefreshTeamProfile(1);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Modal>
      <div className="flex flex-col gap-0 min-w-[400px] z-50 bg-caspian-surface rounded-md shadow-[0_0_5px_#00000050] overflow-hidden">
        <div className="flex items-center justify-between w-full p-2 border-b border-b-caspian-gray2">
          <span className="text-xs text-caspian-gray font-bold">
            فرایندهای مرتبط
          </span>
          <PiX
            onClick={() => setShowFormRiskSubject(false)}
            className="cursor-pointer"
          />
        </div>
        <div className="p-2 flex flex-col gap-2">
          <div className="w-full flex flex-col gap-1.5">
            <label className="text-xs text-caspian-gray">عنوان</label>
            <input
              name="title"
              onChange={(e) => setTitle(e.target.value)}
              type="text"
              placeholder="عنوان را وارد کنید"
              className="outline-none border border-caspian-gray1 p-1.5 text-xs rounded-md"
            />
          </div>
          <div className="w-full flex flex-col gap-1.5">
            <label className="text-xs text-caspian-gray">عنوان</label>
            <textarea
              rows={4}
              onChange={(e) => setDescription(e.target.value)}
              type="text"
              placeholder="توضیحات را وارد کنید"
              className="outline-none border border-caspian-gray1 p-1.5 text-xs rounded-md"
            />
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={() => setShowListRiskSubject(true)}
              className="rounded-md bg-caspian-secondary-400 text-caspian-surface p-2 flex items-center gap-1 w-fit"
            >
              <PiPlusCircleBold className="text-xl" />
              <span className="text-xs">
                {riskTeamObject === "operational"
                  ? "فرایندهای مرتبط"
                  : "اهداف مرتبط"}
              </span>
            </button>
            {showListRiskSubject && riskTeamObject === "operational" ? (
              <ListRiskSubjectProcess
                processFinalSelected={processFinalSelected}
                setProcessFinalSelected={setProcessFinalSelected}
                profileId={profileId}
                setShowListRiskSubject={setShowListRiskSubject}
                processSelected={processSelected}
                setProcessSelected={setProcessSelected}
              />
            ) : (
              showListRiskSubject &&
              riskTeamObject === "strategic" && (
                <ListRiskSubjectGoal
                  goalFinalSelected={goalFinalSelected}
                  setGoalFinalSelected={setGoalFinalSelected}
                  setShowListRiskSubject={setShowListRiskSubject}
                  goalSelected={goalSelected}
                  setGoalSelected={setGoalSelected}
                />
              )
            )}
            {riskTeamObject === "operational"
              ? processFinalSelected?.length > 0 && (
                  <span className="text-xs font-bold">
                    {processFinalSelected?.length} مورد انتخاب شده است
                  </span>
                )
              : goalFinalSelected?.length > 0 && (
                  <span className="text-xs font-bold">
                    {goalFinalSelected?.length} مورد انتخاب شده است
                  </span>
                )}
          </div>
          {riskTeamObject === "operational" ? (
            <button
              disabled={
                processFinalSelected?.length > 0 && title ? false : true
              }
              onClick={processHandler}
              className="rounded-md bg-caspian-secondary-400 text-caspian-surface py-2 px-6 flex items-center gap-1 w-fit"
            >
              <span className="text-xs">ثبت</span>
            </button>
          ) : (
            <button
              disabled={goalFinalSelected?.length > 0 && title ? false : true}
              onClick={goalHandler}
              className="rounded-md bg-caspian-secondary-400 text-caspian-surface py-2 px-6 flex items-center gap-1 w-fit"
            >
              <span className="text-xs">ثبت</span>
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default FormRiskSubject;
