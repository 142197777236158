import React, { useState } from 'react';
import SinglePersonalTable from './SinglePersonalTable';
import { useSearchParams } from 'react-router-dom';
import RowView from './RowView';
import Paginate from './Paginate';

const PersonalTable = ({ data }) => {
    const [name, setName] = useState('');
    const [code, setCode] = useState('')
    const [job, setJob] = useState('');
    const [section, setsection] = useState('');

    const [searchParams] = useSearchParams();
    const page = searchParams.get('page');
    const rows = searchParams.get('rows');

    let filterName = [];
    let filterCode = [];
    let filterJob = [];
    let filterId = [];

    if (data[0] !== -1) {
        filterName = name ? data?.filter(d => (d?.placementProfileRelated?.firstName + ' ' + d?.placementProfileRelated?.lastName).toLocaleLowerCase().includes(name.toLocaleLowerCase())) :
            data;

        filterJob = job ? filterName?.filter(d => d?.placementPositionRelated?.title?.toLocaleLowerCase().includes(job.toLocaleLowerCase())) : filterName;

        filterId = section ? filterJob?.filter(d => d?.placementPositionRelated?.positionSectionRelated?.title?.toLocaleLowerCase().includes(section.toLocaleLowerCase()))
            : filterJob;
    }
    const [currentPage, setCurrentPage] = useState(page ? page : 1);
    const [limit, setLimit] = useState(rows ? rows : 10);
    const lastIndex = currentPage * limit;
    const firstIndex = lastIndex - limit;
    const records = filterId.slice(firstIndex, lastIndex);
    const npage = Math.ceil(filterId.length / limit);
    return (
        <div className='flex flex-col gap-4 py-10 px-8 xl:px-16'>
            <div className='w-full overflow-auto'>
                <table className='min-w-[992px] w-full rounded-tr-xl rounded-tl-xl bg-caspian-surface'>
                    <thead className='rounded-tr-xl rounded-tl-xl
                    text-caspian-surface'>
                        <tr>
                            <th className='bg-caspian-primary-500 w-[30%] rounded-tr-xl relative font-light px-2 py-2'>
                                <input type="text" id="title"
                                    className={`${name ? 'outline-caspian-surface/50 border' : ''}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-28 border border-caspian-gray2 cursor-pointer`}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <label htmlFor="title"
                                    className={`${name ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full  z-50 text-caspian-surface peer-focus:right-3 whitespace-nowrap`}
                                >
                                    نام و نام خانوادگی
                                </label>
                            </th>

                            <th className='bg-caspian-primary-500 w-[15%] relative font-light px-2 py-2'>
                                <input type="text" id="code"
                                    className={`${code ? 'outline-caspian-surface/50 border' : ''}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-16 pl-0 border border-caspian-gray2 cursor-pointer`}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                                <label htmlFor="code"
                                    className={`${code ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full z-50 text-caspian-surface peer-focus:right-3 whitespace-nowrap`}
                                >
                                    کد پرسنلی
                                </label>
                            </th>

                            <th className='bg-caspian-primary-500 w-[35%] relative font-light px-2 py-2'>
                                <input type="text" id="job"
                                    className={`${job ? 'outline-caspian-surface/50 border' : ''}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-10 border border-caspian-gray2 cursor-pointer`}
                                    onChange={(e) => setJob(e.target.value)}
                                />
                                <label htmlFor="job"
                                    className={`${job ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface peer-focus:right-3 z-50`}
                                >شغل</label>
                            </th>
                            <th className='bg-caspian-primary-500 w-[20%] rounded-tl-xl relative font-light px-2 py-2'>
                                <input type="text" id="section"
                                    className={`${section ? 'outline-caspian-surface/50 border' : ''}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-[72px] border border-caspian-gray2 cursor-pointer`}
                                    onChange={(e) => setsection(e.target.value)}
                                />
                                <label htmlFor="section"
                                    className={`${section ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full z-50 text-caspian-surface peer-focus:right-3 whitespace-nowrap`}
                                >واحد مرتبط</label>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            records?.length > 0 ? (
                                records?.map(item => <SinglePersonalTable key={item.id} {...item} />)
                            ) : (<tr><td colSpan={4} className='bg-caspian-gray3'>
                                <div className='py-5 flex flex-col gap-3 items-center justify-center'>
                                    <div className='w-[220px] h-[220px] flex justify-center'>
                                        <img src="/assets/images/library/not-found.svg" alt="هدف یافت نشد..." />
                                    </div>
                                    <h2 className='text-center text-sm'> یافت نشد...</h2>
                                </div>
                            </td></tr>)
                        }
                    </tbody>

                </table>
            </div>
            {
                records?.length > 0 && (
                    <div className='flex justify-between items-center'>
                        <RowView
                            setLimit={setLimit}
                            setCurrentPage={setCurrentPage}
                        />

                        <Paginate
                            pageCount={npage}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                        <div className="text-sm flex items-center gap-4">
                            <span>نمایش {firstIndex + 1}</span>
                            <span>تا {lastIndex - (limit - records?.length)}</span>
                            <span>از کل {filterId?.length}</span>
                        </div>
                    </div>
                )
            }

        </div>
    );
}

export default PersonalTable;
