import React, { useContext, useEffect, useState } from "react";
import Draggable from "react-draggable";
import { PiCornersOut, PiFolderPlus, PiPlusCircle, PiX } from "react-icons/pi";
import Trash from "./sidebar/Trash";
import AddFolder from "./sidebar/AddFolder";
import API from "../../api/API";
import { AuthContext } from "../../context/auth/AuthContext";
import MainNoteContent from "./MainNoteContent";

const MainNotebook = ({
  setShowMainNotebook,
  setShowSidebar,
  setShowAddNote,
  setShowEditNote,
  setThisNoteId,
  showEditNote,
  refresh,
  setRefresh,
}) => {
  const [showAddFolder, setShowAddFolder] = useState(false);

  const { token } = useContext(AuthContext);
  const [fullData, setFullData] = useState([-1]);
  const [loading, setLoading] = useState(false);
  const [favData, setFavData] = useState([-1]);
  const [favConter, setFavConter] = useState(0);
  const [trashData, setTrashData] = useState([-1]);
  const [trashCounter, setTrashCounter] = useState(0);

  const [filterByTitle, setFilterByTitle] = useState("");
  const [filterByTitleFav, setFilterByTitleFav] = useState("");
  const [filterByTitleTrash, setFilterByTitleTrash] = useState("");

  let filterTitle = filterByTitle
    ? fullData.filter((d) =>
        d.title.toLocaleLowerCase().includes(filterByTitle.toLocaleLowerCase())
      )
    : fullData;

  let filterFav = filterByTitleFav
    ? favData.filter((d) =>
        d?.title
          .toLocaleLowerCase()
          .includes(filterByTitleFav.toLocaleLowerCase())
      )
    : favData;
  let filterTrash = filterByTitleTrash
    ? trashData.filter((d) =>
        d?.title
          .toLocaleLowerCase()
          .includes(filterByTitleTrash.toLocaleLowerCase())
      )
    : trashData;
  useEffect(() => {
    if (token) {
      setLoading(true);
      API.get("/api/v1/profile/notebook", {
        headers: { Authorization: token },
      })
        .then((d) => {
          setFullData(d.data);
          setRefresh(0);
        })
        .catch((err) => {
          console.log("error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token, refresh, setRefresh]);

  const [noteId, setNoteId] = useState(null);
  const [notes, setNotes] = useState([-1]);
  useEffect(() => {
    if (fullData?.length > 0) {
      setNotes(
        fullData?.filter((d) =>
          noteId !== null && noteId !== undefined ? d?.id === noteId : d?.notes
        )
      );
    }
  }, [fullData, noteId]);
  // favorites config
  useEffect(() => {
    if (token) {
      API.get("/api/v1/profile/notebook/note?fav=True", {
        headers: { Authorization: token },
      })
        .then((d) => {
          setFavData(d.data);
          setFavConter(d.data?.length);
          setRefresh(0);
        })
        .catch((err) => {
          console.log("error");
        });
    }
  }, [setRefresh, token, refresh]);
  useEffect(() => {
    if (token) {
      API.get("/api/v1/profile/notebook/note?trash=True", {
        headers: { Authorization: token },
      })
        .then((d) => {
          setTrashData(d.data);
          setTrashCounter(d.data?.length);
          setRefresh(0);
        })
        .catch((err) => {
          console.log("error");
        });
    }
  }, [setRefresh, token, refresh]);

  const [notebookTab, setNotebookTab] = useState(true);
  const [favTab, setFavTab] = useState(false);
  const [trashTab, setTrashTab] = useState(false);
  const [activeTab, setActiveTab] = useState("notebook");

  useEffect(() => {
    if (activeTab === "notebook") {
      setNotebookTab(true);
      setFavTab(false);
      setTrashTab(false);
    } else if (activeTab === "favorite") {
      setNotebookTab(false);
      setFavTab(true);
      setTrashTab(false);
    } else if (activeTab === "trash") {
      setNotebookTab(false);
      setFavTab(false);
      setTrashTab(true);
    }
  }, [activeTab]);
  return (
    <Draggable
      handle="strong"
      bounds={{ top: 0, bottom: 500, right: 740, left: -250 }}
    >
      <div className="w-[65%] h-[70vh] fixed left-14 top-14 rounded-md shadow-[0_0_10px_#00000050] z-[49] flex flex-col overflow-hidden">
        <strong className="flex items-center justify-between cursor-move p-2 border-b border-b-caspian-gray2 bg-caspian-surface">
          <span className="text-xs">یادداشت‌ها</span>
          <div className="flex items-center gap-1">
            <PiCornersOut
              className="cursor-pointer"
              onClick={() => {
                setShowMainNotebook(false);
                setShowSidebar(true);
              }}
            />
            <PiX
              onClick={() => setShowMainNotebook(false)}
              className="text-sm cursor-pointer"
            />
          </div>
        </strong>
        <div className="flex items-start justify-between h-full">
          <div className="w-full z-50 flex flex-col justify-between bg-caspian-surface h-full">
            {notes[0] === -1 ? (
              <div className="flex items-center justify-center pt-12">
                <span className="text-xs text-caspian-gray">
                  درحال بارگزاری...
                </span>
              </div>
            ) : notes[0]?.notes?.length < 1 ? (
              <div className="flex items-center justify-center pt-12">
                <span className="text-xs text-caspian-gray">
                  یادداشتی یافت نشد...
                </span>
              </div>
            ) : (
              <div className="flex flex-wrap gap-1 p-1 overflow-y-scroll">
                {trashTab
                  ? trashData.map((d, i) => (
                      <MainNoteContent
                        key={i}
                        noteId={d.id}
                        title={d.title}
                        created_at={d.created_at}
                        update_at={d.update_at}
                        content={d.content}
                        color={d.color}
                        refresh={refresh}
                        setRefresh={setRefresh}
                        fav={d.fav}
                        setShowEditNote={setShowEditNote}
                        showEditNote={showEditNote}
                      />
                    ))
                  : favTab
                  ? favData.map((d, i) => (
                      <MainNoteContent
                        key={i}
                        noteId={d.id}
                        title={d.title}
                        created_at={d.created_at}
                        update_at={d.update_at}
                        content={d.content}
                        color={d.color}
                        refresh={refresh}
                        setRefresh={setRefresh}
                        fav={d.fav}
                        setShowEditNote={setShowEditNote}
                        showEditNote={showEditNote}
                      />
                    ))
                  : notes[0]?.notes.map((d, i) => (
                      <MainNoteContent
                        key={i}
                        noteId={d.id}
                        title={d.title}
                        created_at={d.created_at}
                        update_at={d.update_at}
                        content={d.content}
                        color={d.color}
                        refresh={refresh}
                        setRefresh={setRefresh}
                        fav={d.fav}
                        setShowEditNote={setShowEditNote}
                        showEditNote={showEditNote}
                      />
                    ))}
              </div>
            )}
          </div>
          <div className="min-w-[200px] max-w-[200px] z-50 flex flex-col justify-between border-r border-r-caspian-gray2 h-full bg-caspian-surface">
            <div>
              {showAddFolder && (
                <AddFolder
                  setRefresh={setRefresh}
                  setShowAddFolder={setShowAddFolder}
                />
              )}

              <div className="flex items-center justify-around w-full">
                <div
                  onClick={() => setActiveTab("notebook")}
                  className={`${
                    notebookTab ? "bg-caspian-gray2/20" : "cursor-pointer"
                  } flex items-center justify-center gap-2 w-full py-1.5 relative`}
                >
                  {fullData?.length > 0 && (
                    <span className="bg-caspian-secondary-900 w-4 h-4 flex items-center justify-center rounded-full text-caspian-surface text-[10px] absolute left-4 top-0.5 p-1">
                      {fullData?.length < 10 ? fullData?.length : "9+"}
                    </span>
                  )}
                  <img
                    src="/assets/images/notebook/notebook.svg"
                    alt="دفترچه ها"
                    className="w-6 h-6"
                  />
                </div>
                <div
                  onClick={() => setActiveTab("favorite")}
                  className={`${
                    favTab ? "bg-caspian-gray2/20" : "cursor-pointer"
                  } flex items-center justify-center gap-2 w-full py-1.5 relative`}
                >
                  {favConter > 0 && (
                    <span className="bg-caspian-secondary-900 w-4 h-4 flex items-center justify-center rounded-full text-caspian-surface text-[10px] absolute left-4 top-0.5 p-1">
                      {favConter < 10 ? favConter : "9+"}
                    </span>
                  )}
                  <img
                    src="/assets/images/notebook/favorites.svg"
                    alt="علاقه مندی ها"
                    className="w-6 h-6"
                  />
                </div>
                <div
                  onClick={() => setActiveTab("trash")}
                  className={`${
                    trashTab ? "bg-caspian-gray2/20" : "cursor-pointer"
                  } flex items-center justify-center gap-2 w-full py-1.5 relative`}
                >
                  {trashCounter > 0 && (
                    <span className="bg-caspian-secondary-900 w-4 h-4 flex items-center justify-center rounded-full text-caspian-surface text-[10px] absolute left-4 top-0.5 p-1">
                      {trashCounter < 10 ? trashCounter : "9+"}
                    </span>
                  )}
                  <img
                    src="/assets/images/notebook/trash.svg"
                    alt="حذف شده ها"
                    className="w-6 h-6"
                  />
                </div>
              </div>

              {notebookTab && (
                <>
                  <div className="flex items-center justify-between p-2">
                    <input
                      onChange={(e) => setFilterByTitle(e.target.value)}
                      type="search"
                      placeholder="دفترچه‌ها"
                      className="placeholder:text-caspian-secondary-500 text-xs text-caspian-secondary-500 p-2 outline-none cursor-pointer"
                    />
                    <button
                      className="flex items-center gap-2"
                      onClick={() => setShowAddFolder(true)}
                    >
                      <PiFolderPlus className="text-2xl text-caspian-secondary-500" />
                    </button>
                  </div>

                  <div className="min-h-[45vh] px-2 flex flex-col gap-2 max-h-[45vh] overflow-y-scroll mb-2">
                    {filterTitle[0] === -1 ? (
                      <div className="flex items-center justify-center w-full">
                        <span className="text-xs text-caspian-gray4 text-center">
                          در حال بارگزاری...
                        </span>
                      </div>
                    ) : filterTitle.length < 1 ? (
                      <div className="flex items-center justify-center w-full">
                        <span className="text-xs text-caspian-gray4 text-center">
                          دفترچه ای یافت نشد...
                        </span>
                      </div>
                    ) : (
                      filterTitle?.map((note, i) => (
                        <div
                          key={i}
                          onClick={() => {
                            setNoteId(note.id);
                          }}
                          className="flex flex-col gap-1 p-1"
                        >
                          <div className="flex items-center justify-between w-full">
                            <div className="flex items-center gap-1 cursor-pointer">
                              <img
                                src={"/assets/images/notebook/notebook.svg"}
                                alt="notebook"
                                className="w-6 h-6"
                              />
                              <span className="text-xs">{note.title}</span>
                            </div>
                            <div className="flex items-center gap-2">
                              <span className="text-sm text-caspian-gray">
                                {note?.notes?.length}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </>
              )}
            </div>

            <div className="w-full pb-3 flex flex-col gap-4">
              <button
                className="flex items-center justify-center gap-2"
                onClick={() => setShowAddNote(true)}
              >
                <span className="text-xs text-caspian-secondary-500">
                  یادداشت جدید
                </span>
                <PiPlusCircle className="text-xl text-caspian-secondary-500" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default MainNotebook;
