import { PiX } from 'react-icons/pi'
import SinglePopUpModelStackholder from './SinglePopUpModelStackholder'
import Modal from '../../../ui/ModalModel';

const MainPopUpStackholder = ({ section, sectionData, setShowPopUp, setStackholderId, categoryData }) => {
    return (
        <Modal>
            <div className='w-full h-full fixed '>
                <PiX className='cursor-pointer text-3xl absolute left-5 top-5 ' onClick={() => {
                    setShowPopUp(false)
                    setStackholderId(null)
                }} />
            </div>

            <div className='flex w-full items-start px-3 justify-around '>

                <div className='flex w-full items-center justify-center basis-1/3'>
                    <span className='text-xs 2xl:text-xl text-center px-5 py-2 rounded-md shadow-[0_0_10px_#00000080] bg-caspian-surface'>
                        {categoryData?.title === "درون سازمانی" ?
                            ("ذینفعان درون سازمانی") : ("ذینفعان برون سازمانی")
                        }
                    </span>
                </div>

                <div className='flex w-full items-center justify-start basis-2/3'>

                    <div className='flex w-full z-50 items-center relative justify-center flex-col basis-1/2'>
                        <div className='flex items-center flex-col'>
                            <span
                                style={{ background: `#${categoryData?.colorHex}` }}
                                className='shadow-[0_0_10px_#00000080] text-xs 2xl:text-base text-center text-caspian-surface/95 z-50 font-bold p-5 min-w-[150px] rounded-[50%] '>
                                {categoryData?.title}
                            </span>
                        </div>

                        <div className='w-1 h-8 border-dashed border-l-2 border-caspian-darkgray-950'></div>

                        <div className='flex items-center flex-col'>
                            <span
                                style={{ background: `#${section?.colorHex}` }}
                                className='shadow-[0_0_20px_#00000050] z-50 text-xs 2xl:text-sm text-center min-h-16 h-16 px-5 min-w-[430px] max-w-[430px] text-caspian-on_primary flex items-center justify-center rounded-lg '>
                                {section?.title}
                            </span>
                        </div>

                        <div className='w-1 h-8 border-dashed border-l-2 border-caspian-darkgray-950'></div>

                        <div className='flex flex-col justify-start items-center w-[430px] h-fit max-h-[400px]  p-5 gap-2 overflow-auto  rounded-lg shadow-[0_0_20px_#00000050] bg-caspian-surface'>
                            <SinglePopUpModelStackholder categoryData={categoryData} sectionData={sectionData} />
                        </div>
                    </div>

                    {/* start description  */}

                    {/* <div className='flex z-50 w-full h-full items-end justify-end basis-1/2 bg-caspian-surface shadow-[0_0_20px_#00000050] rounded-lg  '>
                        <p className='text-justify p-8 text-xs xl:text-sm '>
                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
                        </p>
                    </div> */}


                    {/* end description  */}

                </div>
            </div>

        </Modal >
    )
}

export default MainPopUpStackholder;


