import React, { useEffect, useState } from "react";
import API from "../../../../../api/API";
import TableListProfileMeeting from "./TableListProfileMeeting";

const MainListProfileMeeting = ({
  refresh,
  setRefresh,
  setAllProfileNumber,
}) => {
  const [fullData, setFullData] = useState([-1]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    let url = "/api/v1/meeting/profile/detail/";
    API.get(url)
      .then((d) => {
        setFullData(d.data.filter((d) => d?.is_draft === false));
        setAllProfileNumber({
          data: {
            isDraft: d?.data.filter((d) => d?.is_draft === true).length,
            publish: d?.data.filter((d) => d?.is_draft === false).length,
          },
        });
        setRefresh(0);
      })
      .catch((err) => {
        console.log("error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [refresh, setRefresh, setAllProfileNumber]);

  console.log(fullData);

  return <TableListProfileMeeting data={fullData} loading={loading} />;
};

export default MainListProfileMeeting;
