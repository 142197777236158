import React, { useContext, useEffect, useState } from "react";
import { PiX } from "react-icons/pi";
import PlanningMeetingForm from "./PlanningMeetingForm";
import API from "../../../../api/API";
import { AuthContext } from "../../../../context/auth/AuthContext";

const MainPlanningMeetingForm = ({
  meetingProfile,
  setShowPlanningMeetingForm,
  setMeetingListRefresh,
}) => {
  const { token } = useContext(AuthContext);

  const [fullData, setFullData] = useState([]);
  const [thisId, setThisId] = useState(null);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    if (thisId !== null && thisId !== undefined) {
      const getDataHandler = (e) => {
        API.get(`/api/v1/meeting/info/detail/${thisId}/`, {
          headers: { Authorization: token },
        })
          .then((res) => {
            setFullData(res?.data);
            setRefresh(0);
          })
          .catch((error) => {
            console.log(error);
          });
      };
      getDataHandler();
    }
  }, [token, thisId, refresh]);

  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full z-50">
      <div className="fixed inset-0 bg-caspian-gray3/50 z-40 p-4"></div>
      <div className="z-50 fixed flex items-center justify-center p-2">
        <div className="min-w-[50vw] bg-caspian-surface shadow-[0_0_5px_#00000020] rounded-lg flex flex-col gap-3 py-3 px-4">
          <div className="flex items-center justify-between">
            <h2 className="text-sm font-bold">برنامه‌ریزی برگزاری جلسه</h2>
            <PiX
              onClick={() => {
                setShowPlanningMeetingForm(false);
                setMeetingListRefresh(1);
              }}
              className="cursor-pointer z-50"
            />
          </div>
          <p className="text-xs text-caspian-gray">
            برای تنظیم و تشکیل جلسه اطلاعات فوق را وارد کنید.
          </p>
          <PlanningMeetingForm
            setMeetingListRefresh={setMeetingListRefresh}
            setRefresh={setRefresh}
            fullData={fullData}
            thisId={thisId}
            setThisId={setThisId}
            meetingProfile={meetingProfile}
            setShowPlanningMeetingForm={setShowPlanningMeetingForm}
          />
        </div>
      </div>
    </div>
  );
};

export default MainPlanningMeetingForm;
