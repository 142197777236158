import React, { useState } from "react";
import { PiImagesSquare, PiX } from "react-icons/pi";

const PicturesMain = ({ dataPictures }) => {
  const [showImage, setShowImage] = useState(false);
  const [linkImage, setLinkImage] = useState("");
  return (
    <>
      {dataPictures.length > 0 ? (
        <div className="grid grid-cols-2 gap-2">
          {dataPictures.map((img, i) => (
            <div key={i}>
              <img
                onClick={() => {
                  setLinkImage(img.image);
                  setShowImage(true);
                }}
                src={img.image}
                alt="test"
                className="rounded-xl w-auto h-auto cursor-pointer"
              />
            </div>
          ))}
          {showImage && (
            <div className="fixed inset-0 flex items-center justify-center w-full h-full z-50">
              <PiX
                onClick={() => setShowImage(false)}
                className="cursor-pointer absolute top-2 right-2 text-4xl text-caspian-primary-500 z-50"
              />
              <div
                onClick={() => setShowImage(false)}
                className="fixed inset-0 bg-caspian-gray3/50 z-40 p-4"
              ></div>
              <div className="z-50 fixed flex items-center justify-center p-2">
                <img
                  src={linkImage}
                  alt={"alt"}
                  width={800}
                  height={600}
                  className="w-auto h-auto rounded-md"
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center gap-4 py-6">
          <PiImagesSquare className="text-5xl text-caspian-gray" />
          <h2 className="text-caspian-gray text-sm">
            گالری تصاویر خالی می باشد!
          </h2>
        </div>
      )}
    </>
  );
};

export default PicturesMain;
