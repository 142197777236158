import Category from "./Category";
import ZoneTitle1 from "./ZoneTitle1";

const MainZoneOne = ({ data, title }) => {
  // console.log(data);
  return (
    <div className="w-full flex flex-col gap-1">
      <ZoneTitle1 title={title} />
      <div className="ltr flex flex-col justify-center items-center gap-2 w-full">
        {/* Start Category */}
        {data.map((d, i) => (
          //  d.id === 2 &&
          <Category
            key={i}
            dataGroup={d?.groupCategoryRelated}
            title={d?.title}
          />
        ))}
        {/* End Category */}
      </div>
    </div>
  );
};

export default MainZoneOne;
