import React, { useContext, useEffect, useState } from "react";
import {
  PiCaretDownBold,
  PiMagnifyingGlassBold,
  PiPlusBold,
} from "react-icons/pi";
import ListMembers from "./ListMembers";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import API from "../../../../../api/API";
import SingleInput from "./SingleInput";
import ListMembersGroups from "./ListMembersGroups";
import AllMembers from "./AllMembers";

const MainMembers = ({
  title,
  description,
  aim,
  riskTeamType,
  riskTeamSubjectType,
  msSelectedId,
  setShowCreateForm,
  setRefreshTeamsList,
}) => {
  const [showListLeaderMeeting, setShowListLeaderMeeting] = useState(false);
  const [showListCoordinatorMeeting, setShowListCoordinatorMeeting] =
    useState(false);

  const [showMembers, setShowMembers] = useState(false);
  const [showMembersGroups, setShowMembersGroups] = useState(false);

  const { token } = useContext(AuthContext);

  const [memberSelected, setMemberSelected] = useState([]);

  const [members, setMembers] = useState([-1]);
  const [membersFinalSelected, setMembersFinalSelected] = useState([]);
  const [membersGroupsFinalSelected, setMembersGroupsFinalSelected] = useState(
    []
  );

  const [allMembers, setAllMembers] = useState([]);
  useEffect(() => {
    setAllMembers([...membersGroupsFinalSelected, ...membersFinalSelected]);
  }, [membersGroupsFinalSelected, membersFinalSelected]);

  let url = "/api/v1/profile/";
  useEffect(() => {
    const getMembers = () => {
      API.get(url, {
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          setMembers(res.data);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getMembers();
  }, [token, url]);

  // مسئول تیم
  const [leaderSelected, setLeaderSelected] = useState("");
  const [searchByLeader, setSearchByLeader] = useState("");
  let searchLeader = [];
  if (membersGroupsFinalSelected?.length > 0) {
    searchLeader = searchByLeader
      ? membersGroupsFinalSelected.filter(
          (d) =>
            (d.placementPositionRelated?.title !== undefined &&
              d.placementPositionRelated?.title
                .toLocaleLowerCase()
                .includes(searchByLeader.toLocaleLowerCase())) ||
            (d !== undefined &&
              (d?.firstName + " " + d?.lastName)
                .toLocaleLowerCase()
                .includes(searchByLeader.toLocaleLowerCase()))
        )
      : membersGroupsFinalSelected;
  }

  //  هماهنگ کننده
  const [coordinatorSelected, setCoordinatorSelected] = useState([]);
  const [searchByCoordinator, setSearchByCoordinator] = useState("");
  let searchCoordinator = [];
  if (membersGroupsFinalSelected?.length > 0) {
    searchCoordinator = searchByCoordinator
      ? membersGroupsFinalSelected.filter(
          (d) =>
            (d.placementPositionRelated?.title !== undefined &&
              d.placementPositionRelated?.title
                .toLocaleLowerCase()
                .includes(searchByCoordinator.toLocaleLowerCase())) ||
            (d !== undefined &&
              (d?.firstName + " " + d?.lastName)
                .toLocaleLowerCase()
                .includes(searchByCoordinator.toLocaleLowerCase()))
        )
      : membersGroupsFinalSelected;
  }

  const [showAllMembers, setShowAllMembers] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [groupListMembers, setGroupListMembers] = useState([]);
  const [selectedMembersGroupId, setSelectedMembersGroup] = useState([]);
  let urlGroupList = "/api/v1/risk/group/detail/";
  useEffect(() => {
    const getMembers = () => {
      API.get(urlGroupList, {
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          setGroupList(res.data);
          getGroupsMembers(res.data[0]?.id);
          // console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getMembers();
  }, [token, urlGroupList]);

  const getGroupsMembers = (id) => {
    API.get(`/api/v1/risk/group/detail/${id}/`, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        setGroupListMembers(res.data?.memberRiskGroup);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const submitHandler = async () => {
    if (
      membersGroupsFinalSelected?.length < 1 ||
      leaderSelected === null ||
      coordinatorSelected === null
    )
      return null;
    let formData = {
      title: title,
      description: description,
      aim: aim,
      riskTeamType: riskTeamType,
      riskTeamSubjectType: riskTeamSubjectType,
      msRelated: +msSelectedId,
      memberRiskTeamSpecialized: allMembers.map((member) => +member?.id),
      leader: +leaderSelected.id,
      coordinator: +coordinatorSelected.id,
      statusType: "selectSubject",
    };
    console.log(formData);

    API.post("/api/v1/risk/team/detail/", formData, {
      headers: { Authorization: token },
    })
      .then((res) => {
        console.log(res.data);
        setRefreshTeamsList(1);
        setShowCreateForm(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="h-full flex flex-col gap-2 justify-between px-2">
      <div className="flex flex-col gap-2 w-full border-b border-b-caspian-gray2 pb-4">
        <div className="grid grid-cols-2 gap-2">
          <SingleInput lable={"انتخاب گروه"}>
            <select
              disabled
              onChange={(e) => setGroupList(e.target.value)}
              defaultValue={groupList[0]?.id}
              className={`outline-none text-xs border border-caspian-gray2 p-1.5 rounded-md bg-caspian-transparent cursor-pointer w-full`}
            >
              {groupList?.map((ms, i) => (
                <option
                  key={i}
                  className="text-caspian-on_primary"
                  value={ms?.id}
                >
                  {ms?.title}
                </option>
              ))}
            </select>
          </SingleInput>
          <SingleInput lable={"انتخاب اعضا از گروه"}>
            <div className="flex items-center justify-center border border-caspian-secondary-500 rounded-md relative p-1.5 w-full">
              <span className="text-xs font-medium">
                {membersGroupsFinalSelected?.length > 0
                  ? membersGroupsFinalSelected?.length + " عضو افزوده شد"
                  : "عضوی وجود ندارد"}
              </span>
              <button
                onClick={() => {
                  setShowMembersGroups(true);
                }}
                className="text-xs text-caspian-secondary-500 absolute-caspian-secondary-500 absolute right-2 top-1/2 -translate-y-1/2 flex items-center gap-1"
              >
                <PiPlusBold />
                <span>افزودن</span>
              </button>
            </div>
          </SingleInput>

          {showMembersGroups && (
            <ListMembersGroups
              setMembersGroupsFinalSelected={setMembersGroupsFinalSelected}
              membersGroupsFinalSelected={membersGroupsFinalSelected}
              groupListMembers={groupListMembers}
              setShowMembersGroups={setShowMembersGroups}
              selectedMembersGroupId={selectedMembersGroupId}
              setSelectedMembersGroup={setSelectedMembersGroup}
              setLeaderSelected={setLeaderSelected}
              setCoordinatorSelected={setCoordinatorSelected}
            />
          )}
        </div>

        <div className="grid grid-cols-2 gap-2 w-full">
          {/* مسئول تیم */}
          <div className="flex flex-col gap-1.5 w-full relative">
            <label className="text-xs text-caspian-gray">مسئول تیم</label>
            <div
              onClick={() => {
                setShowListLeaderMeeting(!showListLeaderMeeting);
                setShowListCoordinatorMeeting(false);
              }}
              className="text-xs outline outline-1 outline-caspian-gray1 p-1.5 rounded-md bg-caspian-transparent cursor-pointer flex items-center justify-between"
            >
              {membersGroupsFinalSelected?.length > 0 &&
              leaderSelected !== null ? (
                <span className="text-xs">
                  {leaderSelected?.firstName + " " + leaderSelected?.lastName}
                </span>
              ) : (
                <span className="text-caspian-gray1 text-xs">انتخاب کنید</span>
              )}
              <PiCaretDownBold className="text-caspian-gray" />
            </div>
            {showListLeaderMeeting && (
              <div className="rounded-md shadow-[0_0_5px_#00000050] w-full flex flex-col gap-3 py-3 absolute left-0 right-0 top-[30px] bg-caspian-surface z-50">
                <div className="relative w-[92%] mx-auto">
                  <input
                    onChange={(e) => {
                      setSearchByLeader(e.target.value);
                    }}
                    type="search"
                    className="outline-none outline-caspian-gray1 p-1 text-xs rounded-md outline-1 w-full placeholder:text-xs placeholder:text-caspian-gray pr-6"
                    placeholder="جستجو"
                  />
                  <button className="absolute top-1/2 -translate-y-1/2 right-1">
                    <PiMagnifyingGlassBold className="text-caspian-gray text-" />
                  </button>
                </div>
                <div className="flex flex-col gap-2 w-full max-h-[200px] overflow-y-scroll">
                  {searchLeader?.length > 0 &&
                    searchLeader.map((m, i) => (
                      <div
                        key={i}
                        onClick={() => {
                          setLeaderSelected(m);
                          setShowListLeaderMeeting(false);
                          setSearchByLeader("");
                        }}
                        className="flex items-center justify-start gap-2 text-xs hover:bg-caspian-secondary-100 py-1 px-2 w-full cursor-pointer duration-300"
                      >
                        <span className="basis-[40%]">
                          {m?.firstName + " " + m?.lastName}
                        </span>
                        <span className="basis-[60%]">
                          {m?.placementPositionRelated?.title}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
          {/* هماهنگ کننده */}
          <div className="flex flex-col gap-1.5 w-full relative">
            <label className="text-xs text-caspian-gray">هماهنگ کننده</label>
            <div
              onClick={() => {
                setShowListLeaderMeeting(false);
                setShowListCoordinatorMeeting(!showListCoordinatorMeeting);
              }}
              className="text-xs outline outline-1 outline-caspian-gray1 p-1.5 rounded-md bg-caspian-transparent cursor-pointer flex items-center justify-between bg-caspian-surface"
            >
              {membersGroupsFinalSelected?.length > 0 &&
              coordinatorSelected !== null ? (
                <span className="text-xs">
                  {coordinatorSelected?.firstName +
                    " " +
                    coordinatorSelected?.lastName}
                </span>
              ) : (
                <span className="text-caspian-gray1 text-xs">انتخاب کنید</span>
              )}
              <PiCaretDownBold className="text-caspian-gray" />
            </div>
            {showListCoordinatorMeeting && (
              <div className="rounded-md shadow-[0_0_5px_#00000050] w-full flex flex-col gap-3 py-3 absolute left-0 right-0 top-[52px] bg-caspian-surface z-50">
                <div className="relative w-[92%] mx-auto">
                  <input
                    onChange={(e) => {
                      setSearchByCoordinator(e.target.value);
                    }}
                    type="search"
                    className="outline-none outline-caspian-gray1 p-1 text-xs rounded-md outline-1 w-full placeholder:text-xs placeholder:text-caspian-gray pr-6"
                    placeholder="جستجو"
                  />
                  <button className="absolute top-1/2 -translate-y-1/2 right-1">
                    <PiMagnifyingGlassBold className="text-caspian-gray text-" />
                  </button>
                </div>
                <div className="flex flex-col gap-2 w-full max-h-[200px] overflow-y-scroll">
                  {searchCoordinator?.length > 0 &&
                    searchCoordinator.map((m, i) => (
                      <div
                        key={i}
                        onClick={() => {
                          setCoordinatorSelected(m);
                          setShowListCoordinatorMeeting(false);
                          setSearchByCoordinator("");
                        }}
                        className="flex items-center justify-start gap-2 text-xs hover:bg-caspian-secondary-100 py-1 px-2 w-full cursor-pointer duration-300"
                      >
                        <span className="basis-[40%]">
                          {m?.firstName + " " + m?.lastName}
                        </span>
                        <span className="basis-[60%]">
                          {m?.placementPositionRelated?.title}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-3 h-full">
        {showMembers && (
          <ListMembers
            setMembersFinalSelected={setMembersFinalSelected}
            membersFinalSelected={membersFinalSelected}
            members={members}
            setShowMembers={setShowMembers}
            memberSelected={memberSelected}
            setMemberSelected={setMemberSelected}
          />
        )}
        <div className="flex flex-col gap-3">
          <div className="grid grid-cols-2 gap-2 pb-4">
            <div className="flex flex-col gap-1.5 w-full relative">
              <label className="text-xs text-caspian-gray">
                افزودن اعضا از شرکت
              </label>
              <div className="flex items-center justify-center border border-caspian-secondary-500 rounded-md relative p-1.5">
                <span className="text-xs font-medium">
                  {membersFinalSelected?.length > 0
                    ? membersFinalSelected?.length + " عضو افزوده شد"
                    : "عضوی وجود ندارد"}
                </span>
                <button
                  onClick={() => {
                    setShowMembers(true);
                    setShowListLeaderMeeting(false);
                    setShowListCoordinatorMeeting(false);
                  }}
                  className="text-xs text-caspian-secondary-500 absolute-caspian-secondary-500 absolute right-2 top-1/2 -translate-y-1/2 flex items-center gap-1"
                >
                  <PiPlusBold />
                  <span>افزودن</span>
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            {/* <div className="flex items-center flex-wrap gap-2">
              {allMembers?.slice(0, 5)?.map((m, i) => (
                <div
                  key={i}
                  className={
                    m?.firstName
                      ? "bg-caspian-secondary-100 py-0.5 p-1 rounded-md"
                      : ""
                  }
                >
                  <span className="text-xs text-caspian-secondary-900">
                    {m?.firstName
                      ? m?.firstName + " " + m?.lastName
                      : m?.placementProfileRelated?.firstName +
                        " " +
                        m?.placementProfileRelated?.lastName}
                  </span>
                </div>
              ))}
              {allMembers.length > 5 && (
                <span className="text-xs text-caspian-secondary-900">...</span>
              )}
              <button
                onClick={() => setShowAllMembers(true)}
                className="text-xs text-caspian-surface bg-caspian-secondary-500 rounded-md p-1 w-fit"
              >
                مشاهده اطلاعات
              </button>
              {showAllMembers && (
                <AllMembers
                  allMembers={allMembers}
                  setShowAllMembers={setShowAllMembers}
                />
              )}
            </div> */}
            <div className="flex items-center flex-wrap gap-2">
              {membersGroupsFinalSelected?.slice(0, 5)?.map((m, i) => (
                <div
                  key={i}
                  className="bg-caspian-secondary-100 py-0.5 p-1 rounded-md"
                >
                  <span className="text-xs text-caspian-secondary-900">
                    {m?.firstName + " " + m?.lastName}
                  </span>
                </div>
              ))}
              {membersFinalSelected?.slice(0, 5)?.map((m, i) => (
                <div key={i}>
                  <span className="text-xs text-caspian-gray4">
                    {m?.firstName + " " + m?.lastName}
                  </span>
                </div>
              ))}
              {(membersGroupsFinalSelected.length > 0 ||
                membersFinalSelected.length > 0) && (
                <button
                  onClick={() => setShowAllMembers(true)}
                  className="text-xs text-caspian-surface bg-caspian-secondary-500 rounded-md p-1 w-fit"
                >
                  مشاهده اطلاعات
                </button>
              )}
              {showAllMembers && (
                <AllMembers
                  membersFinalSelected={membersFinalSelected}
                  membersGroupsFinalSelected={membersGroupsFinalSelected}
                  setShowAllMembers={setShowAllMembers}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* start دکمه ها */}
      <div className="flex items-center gap-2 p-2 pb-4">
        <button
          onClick={submitHandler}
          className="bg-caspian-secondary-500 text-caspian-surface text-xs py-1.5 px-6 rounded-md"
        >
          ثبت
        </button>
        <button
          onClick={() => setShowCreateForm(false)}
          className="text-xs text-[#FF5B5B] mr-4"
        >
          انصراف
        </button>
      </div>
      {/* end دکمه ها */}
    </div>
  );
};

export default MainMembers;
