import React, { useContext, useEffect, useState } from "react";

import { PiX } from "react-icons/pi";
import { AuthContext } from "../../../../../../../../../../context/auth/AuthContext";
import API from "../../../../../../../../../../api/API";
import Modal from "../../../../../../../../../modal/Modal";

const StackholderForm = ({
  setShowStackholderForm,
  actionplanIdRelated,
  setRefresh,
}) => {
  const { token } = useContext(AuthContext);
  const [description, setDescription] = useState("");
  const [stackholderData, setStackholderData] = useState([]);
  const [stackholderIdSelected, setStackholderIdSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!token) return;
    const getStackholderData = async () => {
      setLoading(true);
      try {
        const res = await API.get(`/api/v1/stackholder/stackholder/`, {
          headers: { Authorization: token },
        });
        console.log(res?.data);
        setStackholderData(res?.data);
        setStackholderIdSelected(res?.data[0].id);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    getStackholderData();
  }, [token, setRefresh]);

  const dataHandler = async () => {
    if (!stackholderData[0]) return;
    let formData = {
      typeProject: "influencer",
      description: description,
      StackHolderRelated: +stackholderIdSelected,
      id: actionplanIdRelated,
      actionPlanRelated: actionplanIdRelated,
    };

    try {
      const res = await API.post(
        `/api/v1/actionplan/stockholders_plan/`,
        formData,
        {
          headers: { Authorization: token },
        }
      );
      console.log(res?.data);
      setShowStackholderForm(false);
      setRefresh(1);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal>
      <div className="flex flex-col gap-1.5 w-[460px] bg-caspian-surface p-2 rounded-md shadow-[0_0_10px_#00000050]">
        <div className="flex items-center justify-between w-full pb-2 border-b border-b-caspian-gray2">
          <span className="text-xs text-caspian-gray4">
            افزودن ذینفع تاثیرگذار
          </span>
          <PiX
            className="cursor-pointer"
            onClick={() => setShowStackholderForm(false)}
          />
        </div>
        <div className="w-full flex flex-col gap-1.5">
          <label className="text-xs text-caspian-gray">
            انتخاب گروه ذینفعان
          </label>
          <select
            defaultValue={stackholderData[0]?.id}
            onChange={(e) => setStackholderIdSelected(e.target.value)}
            className={`outline-none text-xs border border-caspian-gray2 p-1.5 rounded-md bg-caspian-transparent cursor-pointer w-full`}
          >
            {stackholderData?.map((s, i) => (
              <option key={i} className="text-caspian-on_primary" value={s?.id}>
                {s?.title}
              </option>
            ))}
          </select>
        </div>

        <div className="flex flex-col gap-1.5 w-full">
          <label className="text-xs text-caspian-gray">موضوع</label>
          <textarea
            onChange={(e) => setDescription(e.target.value)}
            rows={4}
            className="p-2 rounded-md outline-none outline-1 outline-caspian-gray2 text-xs"
          />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <button
            onClick={dataHandler}
            className="outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs py-1.5 px-6 rounded-md"
          >
            افزودن
          </button>
          <button
            onClick={() => setShowStackholderForm(false)}
            className="text-xs text-[#FF5B5B] mr-4"
          >
            لغو
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default StackholderForm;
