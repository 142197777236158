import React, { useContext, useEffect, useState } from "react";
import { PiCloudArrowUpLight } from "react-icons/pi";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import API from "../../../../../api/API";
import FileConfigSingle from "./file-config/FileConfigSingle";
import FileConfigOldSingle from "./file-config/FileConfigOldSingle";
import { useLocation, useSearchParams } from "react-router-dom";

const AddFiles = ({
  documentsFile,
  fullData,
  thisId,
  setRefresh,
  setApprovals,
  setFilesMeeting,
  setRefreshFullData,
}) => {
  const { token } = useContext(AuthContext);
  const location = useLocation();

  const [disableButton, setDisableButton] = useState(false);
  const [serachParams, setSearchParams] = useSearchParams();

  const [file, setFile] = useState(null);
  const [fileId, setFileId] = useState([]);
  const [oldFileId, setOldFileId] = useState(
    fullData[0]?.documentsRelated.map((f) => f.id)
  );

  useEffect(() => {
    setOldFileId(fullData[0]?.documentsRelated.map((f) => f.id));
  }, [fullData]);

  useEffect(() => {
    if (file !== null) {
      const getFile = async () => {
        await API.post(
          "/api/v1/meeting/info/document/",
          {
            document: file,
          },
          {
            headers: {
              Authorization: token,
              "content-type": "multipart/form-data",
              Accept: "application/json",
            },
          }
        )
          .then((res) => {
            setFileId((f) => [...f, { id: res?.data?.id, file: file }]);
            console.log(res.data);
            // setFile(null);
          })
          .catch((err) => console.log(err));
      };
      getFile();
    } else if (file === null || file === undefined) {
      setFile(null);
    }
  }, [file, token]);

  const getFileId = async () => {
    if (fileId.length < 1) return null;
    if (fullData[0]?.id) {
      await API.patch(
        `/api/v1/meeting/info/final/document/${fullData[0]?.id}/`,
        {
          documentsRelated: oldFileId?.concat(fileId?.map((d) => d?.id)),
          // documentsRelated: fileId?.map((d) => d?.id),
        },
        {
          headers: {
            Authorization: token,
            Accept: "application/json",
          },
        }
      )
        .then((res) => {
          setRefresh(1);
          setFileId([]);

          console.log(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      await API.post(
        "/api/v1/meeting/info/final/document/",
        {
          documentsRelated: fileId?.map((d) => d?.id),
          MeetingFinalDocumentRelated: +thisId,
        },
        {
          headers: {
            Authorization: token,
            Accept: "application/json",
          },
        }
      )
        .then((res) => {
          setRefresh(1);
          setFileId([]);
          console.log(res.data);
        })
        .catch((err) => console.log(err));
    }
  };

  const dataHandler = async () => {
    await getFileId();
    // await setRefresh(1);
  };
  const dataHandlerEnd = async () => {
    await dataHandler();
    await API.post(
      `/api/v1/meeting/info/detail/${thisId}/status/`,
      {
        status: "approvalschairman",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        // await dataHandler();
        await postActivitySend();
        await setRefreshFullData(1);
        await handleSearch("meeting", "");
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataHandlerBack = async () => {
    // await dataHandler();
    await API.post(
      `/api/v1/meeting/info/detail/${thisId}/status/`,
      {
        status: "approvals",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await setApprovals(true);
        await setFilesMeeting(false);
        await setRefreshFullData(1);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // get activity
  const getActivitySend = async () => {
    await API.get("/api/v1/profile/activity", {
      headers: { Authorization: token },
    })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // post activity
  const postActivitySend = async () => {
    await API.post(
      "/api/v1/meeting/info/activity",
      {
        meetingSelected_id: thisId,
        link: location.pathname,
      },
      {
        headers: { Authorization: token },
      }
    )
      .then(async (res) => {
        await getActivitySend();
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (documentsFile?.length < 1 && fileId?.length < 1) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [documentsFile, fileId]);

  console.log(oldFileId);

  function handleSearch(key, value) {
    setSearchParams((prevParams) => {
      if (value === null || value === "") {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    });
  }
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-wrap gap-2">
        {fileId?.map((d, i) => (
          <FileConfigSingle
            key={i}
            data={d}
            setFileId={setFileId}
            fileId={fileId}
          />
        ))}
      </div>
      <div className="min-h-[200px] flex flex-col gap-2 justify-between w-full bg-caspian-surface p-2 rounded-md shadow-[0_0_10px_#00000050]">
        {documentsFile?.length < 1 && fileId?.length < 1 ? (
          <h2 className="text-xs text-caspian-gray1">فایلی اضافه نشده است!</h2>
        ) : (
          <div className="flex flex-wrap gap-2">
            {fullData?.map((d, i) =>
              d.documentsRelated.map((f, i) => (
                <FileConfigOldSingle
                  setRefresh={setRefresh}
                  key={i}
                  data={f}
                  oldFileId={oldFileId}
                  setOldFileId={setOldFileId}
                />
              ))
            )}
          </div>
        )}

        <div className="flex items-center justify-center w-full">
          <div className="border border-dashed border-caspian-gray2 p-4 rounded-md flex items-center gap-16">
            <div className="flex items-center gap-4">
              <PiCloudArrowUpLight className="text-4xl text-caspian-gray" />
              <div className="flex flex-col justify-center gap-1">
                <span className="text-xs font-bold">
                  لطفا فایل خود را انتخاب کنید
                </span>
                <span className="text-xs text-caspian-gray2">
                  فایل exl و حداکثر 20 مگابایت
                </span>
              </div>
            </div>

            <div>
              <label
                htmlFor="imageFile"
                className="p-2 border border-caspian-secondary-500 rounded-md text-caspian-secondary-500 cursor-pointer"
              >
                <span className="text-xs">افزودن فایل</span>
                <input
                  name="file"
                  className="hidden"
                  id="imageFile"
                  type="file"
                  accept=".png,.jpg"
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                    console.log(e.target.files[0]);
                  }}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <button
          onClick={async () => {
            await dataHandlerBack();
          }}
          className="min-w-[6rem] outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs p-1.5 rounded-md"
        >
          بازگشت
        </button>
        <button
          onClick={async () => {
            await dataHandlerEnd();
          }}
          className="min-w-[6rem] outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs p-1.5 rounded-md"
        >
          ثبت
        </button>
      </div>
    </div>
  );
};

export default AddFiles;
