import React, { useContext, useEffect, useRef, useState } from 'react';
import Modal from '../../../../../../../modal/Modal';
import { PiCircleNotch, PiDotBold, PiTrash, PiX } from 'react-icons/pi';
import API from '../../../../../../../../api/API';
import { AuthContext } from '../../../../../../../../context/auth/AuthContext';

const EditAssetForm = ({ item, id, setRefresh, setEditAsset }) => {
    const { token } = useContext(AuthContext);

    const ref = useRef(null);
    const ref2 = useRef(null);
    //dutyType
    const [isLoading, setIsLoading] = useState(false);

    const [title, setTitle] = useState('');
    const [inp, setInp] = useState('');

    const [res, setRes] = useState(item?.length > 0 ? item : []);


    const submitHandler = async (id) => {
        // let newRes = res.join(',');
        if (!inp || !title) return
        // setRes([...res, { title, describtion: inp, positionRelated: id }])
        ref.current.value = '';
        ref2.current.value = '';
        let formData = {
            title,
            describtion: inp,
            positionRelated: id
        }
        console.log("formData", formData);
        setIsLoading(true);
        let response;
        try {
            response = await API.post('/api/v1/organization/role/requirementAssetposition/', formData, { headers: { Authorization: token } });
            if (response?.data) {
                console.log(response?.data);
                setRefresh(true);
                // setEditAsset(false);
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }
    const removeHandle = async (id, index) => {
        setIsLoading(true);
        try {
            // const item = res?.filter((s, i) => i !== index);
            setRes(item);
            const response = await API.delete(`/api/v1/organization/role/requirementAssetposition/${id}/`);
            setRefresh(true);
            setIsLoading(false);

        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }
    return (
        <Modal>
            <div className='bg-caspian-surface w-[50%] max-w-[600px] rounded-md'>
                <div className='flex justify-between items-center p-1.5'>
                    <span className='font-semibold'>ویرایش فرم قوانین و مقررات</span>
                    <PiX disabled={isLoading} className='cursor-pointer' onClick={() => setEditAsset(false)} />
                </div>
                <div className='border-t border-caspian-gray1 px-1.5 flex flex-col gap-4 py-5'>
                    <div className=' flex flex-col gap-2'>
                        <span className='text-caspian-gray4 text-xs'>عنوان تجهیزات:</span>
                        <input type="text"
                            ref={ref2}
                            onChange={(e) => setTitle(e.target.value)}
                            className='border text-xs placeholder:text-xs placeholder:text-caspian-gray4 border-caspian-gray1 rounded-md p-1.5'
                            name="" id="" />
                    </div>
                    <div className='flex flex-col gap-2'>
                        <span className='text-caspian-gray4 text-xs'>توضیحات تجهیزات :</span>
                        <textarea
                            cols={3}
                            rows={3}
                            ref={ref}
                            onChange={(e) => setInp(e.target.value)}
                            placeholder=' تجهیزات...'
                            className='border text-xs placeholder:text-xs placeholder:text-caspian-gray4 border-caspian-gray1 rounded-md p-1.5 resize-none' />
                    </div>
                    <div className='h-[40vh] overflow-y-auto'>
                        {/* responsible */}
                        <div className='p-1.5 pt-0 pr-0'>
                            {
                                item?.length > 0 ? item?.map((item, index) =>
                                    <div className='flex justify-between items-start my-2 first:mt-0'
                                        key={index}>
                                        <div className='flex gap-1.5 items-start'>
                                            <p className='w-[20px]'><PiDotBold className='text-2xl' /></p>
                                            <div className='w-[90%]'>
                                                <span>{item?.title} :</span>
                                                <span>{item?.describtion}</span>
                                            </div>
                                        </div>
                                        <div className='w-[50px]'>
                                            <PiTrash className='cursor-pointer mr-auto' onClick={() => removeHandle(item?.id, index)} />
                                        </div>
                                    </div>) : <p className='text-center pt-10 text-xs text-caspian-gray4'>موردی ثبت نشده</p>
                            }
                        </div>
                    </div>
                    <button
                        onClick={() => submitHandler(id)}
                        className='flex justify-center gap-2 p-1.5 rounded-md text-caspian-surface bg-[#3C77C2] '>
                        <span>ثبت</span>
                        {isLoading && <span>
                            <PiCircleNotch className='animate-spin' />
                        </span>}
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default EditAssetForm;
