import React, { useState } from "react";
import {
  PiArrowBendDownLeft,
  PiCaretDoubleDown,
  PiCaretDoubleUp,
  PiFileTextFill,
} from "react-icons/pi";

const RequestReplyDetails = ({ fullData }) => {
  const [showRequest, setShowRequest] = useState(true);

  return (
    <div className="bg-caspian-surface flex flex-col border border-caspian-gray2 rounded-xl p-4 shadow-[0_0_5px_#00000050] border-r-8 border-r-[#F4802380]">
      <div className="flex items-center justify-between pb-2 border-b border-b-caspian-gray2">
        {/* نام ارسال کننده */}
        <div className="flex flex-col items-start gap-0">
          <div className="flex items-center gap-2">
            <div className="w-8 h-8 bg-caspian-primary-200 rounded-full overflow-hidden">
              <img
                src={
                  fullData?.firstActivityRelated?.reciver
                    ?.placementProfileRelated?.personalInfoProfileRelated[0]
                    ?.avatar
                }
                alt={
                  fullData?.firstActivityRelated?.receiver?.firstName +
                  " " +
                  fullData?.firstActivityRelated?.receiver?.lastName
                }
              />
            </div>
            <h2 className="text-sm">
              {fullData?.firstActivityRelated?.receiver?.firstName +
                " " +
                fullData?.firstActivityRelated?.receiver?.lastName}
            </h2>
            <span className="text-xs text-caspian-gray">
              {fullData?.receiver?.placementPositionRelated === null
                ? "نامشخص"
                : fullData?.receiver?.placementPositionRelated
                    ?.positionJobRelated?.jobLevelRelated?.title}
            </span>
          </div>
          <div className="flex items-center gap-1 mr-10">
            <PiArrowBendDownLeft className="text-lg text-caspian-secondary-600" />
            <span className="text-xs text-caspian-secondary-600">پاسخ به:</span>
            <span className="text-xs">
              {fullData?.firstActivityRelated?.sender?.firstName +
                " " +
                fullData?.firstActivityRelated?.sender?.lastName}
            </span>
            <span className="text-xs text-caspian-gray">
              {fullData?.sender?.placementPositionRelated === null
                ? "نامشخص"
                : fullData?.sender?.placementPositionRelated?.positionJobRelated
                    ?.jobLevelRelated?.title}
            </span>
          </div>
        </div>
        {/* تاریخ پاسخ به گزارش */}
        <span className="text-xs text-caspian-gray">
          {new Date(
            fullData?.firstActivityRelated?.updated_at
          ).toLocaleDateString("fa-IR", {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </span>
      </div>

      <div>
        {showRequest && (
          <div className="py-2 border-b border-b-caspian-gray2">
            <div className="flex flex-col gap-3 bg-caspian-gray2/20 p-2 rounded-md">
              {/* توضیحات درخواست */}
              <p className="text-sm">{fullData?.firstActivityRelated?.text}</p>

              {/* فایل پیوست */}
              <div>
                {fullData?.firstActivityRelated?.document !== null ? (
                  <a
                    download={fullData?.firstActivityRelated?.document}
                    href={fullData?.firstActivityRelated?.document}
                    target="_blank"
                    rel="noreferrer"
                    className="text-sm"
                  >
                    <div className="flex items-center gap-2 text-caspian-gray text-[15px] p-2 rounded-md border border-caspian-gray w-fit">
                      {fullData?.firstActivityRelated?.document.replace(
                        /^.*[\\/]/,
                        ""
                      )}
                      <PiFileTextFill className="text-caspian-secondary-600 text-2xl" />
                    </div>
                  </a>
                ) : (
                  <div className="flex items-center justify-start p-2">
                    <h2 className="text-xs text-caspian-gray">
                      فایلی جهت دانلود وجود ندارد!
                    </h2>
                  </div>
                )}
              </div>
            </div>

            {/* تایید و عدم تایید */}
            {fullData?.status === "progressing" ? (
              <div className="flex items-center justify-between pt-2">
                <span className="bg-[#FFE7BC] text-[#9E5F00] text-xs p-1.5 rounded-md">
                  در انتظار تایید
                </span>
                {/* <PiChecks className="text-[#01BC8D] text-xl" /> */}
              </div>
            ) : fullData?.status === "complated" ? (
              <div className="pt-2 flex flex-col gap-2 border-t border-t-caspian-gray2 border-dotted mt-2">
                <span className="bg-[#73AEAF30] text-[#73AEAF] p-1.5 rounded-md text-[10px] font-bold w-fit">
                  گزارش توسط{" "}
                  {fullData?.firstActivityRelated?.sender?.firstName +
                    " " +
                    fullData?.firstActivityRelated?.sender?.lastName}{" "}
                  تایید شد.
                </span>
                <p className="text-xs text-caspian-gray">
                  امتیاز{" "}
                  {fullData?.firstActivityRelated?.sender?.firstName +
                    " " +
                    fullData?.firstActivityRelated?.sender?.lastName}{" "}
                  به این گزارش{" "}
                  <span className="font-bold text-xs text-caspian-gray">
                    {fullData?.score === "1" && "خیلی ضعیف"}
                    {fullData?.score === "2" && "ضعیف"}
                    {fullData.score === "3" && "متوسط"}
                    {fullData?.score === "4" && "خوب"}
                    {fullData?.score === "5" && "عالی "}
                  </span>{" "}
                  می باشد.
                </p>
                {fullData?.comment && (
                  <div className="flex items-center gap-1 text-xs text-caspian-gray">
                    <span>
                      پیام{" "}
                      {fullData?.firstActivityRelated?.sender?.firstName +
                        " " +
                        fullData?.firstActivityRelated?.sender?.lastName}
                      :
                    </span>
                    <p>{fullData?.comment}</p>
                  </div>
                )}
              </div>
            ) : (
              fullData?.status === "rejected" &&
              fullData?.comment !== null && (
                <div className="pt-2 flex flex-col gap-2 border-t border-t-caspian-gray2 border-dotted mt-2">
                  <span className="bg-[#FF5B5B30] text-[#FF5B5B] p-1.5 rounded-md text-[10px] font-bold w-fit">
                    گزارش توسط{" "}
                    {fullData?.firstActivityRelated?.sender?.firstName +
                      " " +
                      fullData?.firstActivityRelated?.sender?.lastName}{" "}
                    تایید نشد!
                  </span>
                  <div className="flex items-center gap-1 text-xs text-caspian-gray">
                    <span>
                      پیام{" "}
                      {fullData?.firstActivityRelated?.sender?.firstName +
                        " " +
                        fullData?.firstActivityRelated?.sender?.lastName}
                      :
                    </span>
                    <p>{fullData?.comment}</p>
                  </div>
                </div>
              )
            )}
            {/* در انتظار تایید */}
          </div>
        )}
      </div>
      <div className={`justify-between flex items-center w-full gap-4 pt-4`}>
        <div className="flex items-center gap-2">
          <button className="flex items-center gap-2 text-caspian-secondary-600 outline outline-1 outline-caspian-secondary-600 py-1 px-3 rounded-md">
            <span className="text-xs">ویرایش</span>
          </button>
          {fullData.status === "done" && (
            <div>
              <button className="flex items-center gap-2 text-caspian-secondary-600 outline outline-1 outline-caspian-secondary-600 py-1 px-3 rounded-md">
                <span className="text-xs">باز ارسال</span>
              </button>
            </div>
          )}
        </div>
        <button
          onClick={() => setShowRequest(!showRequest)}
          className="text-caspian-secondary-500 text-sm"
        >
          {!showRequest ? <PiCaretDoubleDown /> : <PiCaretDoubleUp />}
        </button>
      </div>
    </div>
  );
};

export default RequestReplyDetails;
