import { PiNote, PiClipboardText } from "react-icons/pi";
import ListItemSingle from "./ListItemSingle";
import { useAppContext } from "../../context/app-context/AppContext";
import { useLocation, useParams } from "react-router-dom";

const ChartNavbar = ({ buttonActive }) => {
  const { setBradCrump2, setBradCrump3 } = useAppContext();
  const params = useParams();
  const location = useLocation();
  const search = (location.state && location.state.search) || "";
  const { id } = useParams();
  return (
    <nav className="w-full bg-caspian-surface rtl">
      <ul className="flex items-center gap-0.5">
        <ListItemSingle
          title="چارت"
          active="tree-chart"
          icon={<PiClipboardText />}
          link={"/chart/tree-chart"}
          buttonActive={buttonActive}
          onClick={() => {
            setBradCrump2("چارت");
            setBradCrump3("");
          }}
        />
        <ListItemSingle
          title="لیست پست‌ها"
          active="list-chart"
          icon={<PiClipboardText />}
          link="/chart/list-chart"
          buttonActive={buttonActive}
          onClick={() => setBradCrump2("لیست پست‌ها")}
        />
        <ListItemSingle
          title="لیست افراد"
          active="list-personal"
          icon={<PiClipboardText />}
          link="/chart/list-personal-chart"
          buttonActive={buttonActive}
          onClick={() => setBradCrump2("لیست افراد")}
        />

        {
          id && <ListItemSingle
          title="پروفایل پست‌ها"
          active="profile"
          icon={<PiClipboardText />}
          // link="/chart/list-chart/profile"
          buttonActive={buttonActive}
          onClick={() => setBradCrump2("لیست پست‌ها")}
        />
        }
      </ul>
    </nav>
  );
};

export default ChartNavbar;
