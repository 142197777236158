import React from 'react'

const SingleListMaterialReport = ({ item }) => {
    return (
        <tr className='w-full flex items-center justify-between pr-5 h-8 bg-caspian-gray3'>
            <td className='w-[20%] flex items-center justify-center'>
                {item?.materialRelated?.name}
            </td>

            <td className='w-[20%] flex items-center justify-center'>
                {item?.materialRelated?.unit}
            </td>

            <td className='w-[20%] flex items-center justify-center'>
                {item?.materialRelated?.amount}
            </td>

            <td className='w-[20%] flex items-center justify-center'>
                {item?.used_amount}
            </td>

            <td className='w-[20%] flex items-center justify-center'>
            {item?.materialRelated?.amount + item?.used_amount}
            </td>
        </tr>
    )
}

export default SingleListMaterialReport