import React, { useState } from "react";
import OneTabStepThree from "./step-one/OneTabStepThree";
import TwoTabStepThree from "./step-two/TwoTabStepThree";
import ThreeTabStepThree from "./step-three/ThreeTabStepThree";

const TabStepThree = ({
  actionplanIdRelated,
  description,
  setDescription,
  processFinalSelected,
  setProcessFinalSelected,
  processSelected,
  setProcessSelected,
  goalFinalSelected,
  setGoalFinalSelected,
  goalSelected,
  setGoalSelected,
  stackholderList,
  setRefresh,
  setIsDisable,
  loading,
}) => {
  const [tabStep, setTabStep] = useState("StepOne");

  return (
    <div className="flex flex-col gap-2 h-full">
      <div className="flex items-center gap-6">
        <span
          className={
            tabStep === "StepOne"
              ? "text-caspian-secondary-500 text-xs underline underline-offset-4"
              : "text-caspian-gray4 text-xs"
          }
        >
          گام اول
        </span>
        <span
          className={
            tabStep === "StepTwo"
              ? "text-caspian-secondary-500 text-xs underline underline-offset-4"
              : "text-caspian-gray4 text-xs"
          }
        >
          گام دوم
        </span>
        <span
          className={
            tabStep === "StepThree"
              ? "text-caspian-secondary-500 text-xs underline underline-offset-4"
              : "text-caspian-gray4 text-xs"
          }
        >
          گام سوم
        </span>
      </div>
      {tabStep === "StepOne" ? (
        loading ? (
          <div className="flex items-center justify-center w-full py-12">
            <span className="text-xs text-caspian-gray4">
              در حال بارگزاری...
            </span>
          </div>
        ) : (
          <OneTabStepThree
            description={description}
            setDescription={setDescription}
            setTabStep={setTabStep}
            processFinalSelected={processFinalSelected}
            setProcessFinalSelected={setProcessFinalSelected}
            processSelected={processSelected}
            setProcessSelected={setProcessSelected}
            goalFinalSelected={goalFinalSelected}
            setGoalFinalSelected={setGoalFinalSelected}
            goalSelected={goalSelected}
            setGoalSelected={setGoalSelected}
            setIsDisable={setIsDisable}
          />
        )
      ) : tabStep === "StepTwo" ? (
        <TwoTabStepThree
          setTabStep={setTabStep}
          actionplanIdRelated={actionplanIdRelated}
          stackholderList={stackholderList?.filter(
            (s) => s.typeProject === "influencer"
          )}
          setRefresh={setRefresh}
          setIsDisable={setIsDisable}
        />
      ) : (
        tabStep === "StepThree" && (
          <ThreeTabStepThree
            setTabStep={setTabStep}
            actionplanIdRelated={actionplanIdRelated}
            stackholderList={stackholderList?.filter(
              (s) => s.typeProject === "affected"
            )}
            setRefresh={setRefresh}
            setIsDisable={setIsDisable}
          />
        )
      )}
    </div>
  );
};

export default TabStepThree;
