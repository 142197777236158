import React, { useContext, useEffect, useState } from "react";
import ProfileNavbar from "../ProfileNavbar";
import { useAppContext } from "../../../context/app-context/AppContext";
import { useForm } from "react-hook-form";
import API from "../../../api/API";
import { toast } from "react-toastify";
import { PiCircleNotch, PiEyeBold, PiEyeClosed } from "react-icons/pi";
import { AuthContext } from "../../../context/auth/AuthContext";
import { useNavigate } from "react-router-dom";

const ForgetPassword = ({ showMenu }) => {
  const navigate = useNavigate();
  const { setBradCrump1, setBradCrump2, setBradCrump3 } = useAppContext();
  useEffect(() => {
    setBradCrump1("پروفایل کاربری");
    setBradCrump2("تغییر کلمه عبور");
    setBradCrump3("");
  }, [setBradCrump1, setBradCrump2, setBradCrump3]);

  const { token } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({});

  const [visibleCu, setVisibleCu] = useState(false);
  const [visibleNew, setVisibleNew] = useState(false);
  const [visibleCr, setVisibleCr] = useState(false);
  const [loading, setLoading] = useState(false);

  const forgotLogin = async (e) => {
    const formData = {
      old_password: watch("old_password"),
      new_password1: watch("new_password1"),
      new_password2: watch("new_password2"),
    };
    setLoading(true);
    if (token) {
      API.post("/api/v1/profile/cahnge/password/", formData, {
        headers: {
          Authorization: token,
        },
      })
        .then((d) => {
          toast.success("کلمه عبور با موفقیت تغییر کرد.", {
            className: "toast-success",
            bodyClassName: "toast-success",
          });
          setTimeout(() => {
            navigate(0);
          }, 2000);
        })
        .catch((err) => {
          console.log(err.response.data);
          toast.error(
            err?.response?.data
              ? err?.response?.data?.detail
              : "مشکلی به وجود آمده است",
            {
              className: "toast-faild",
              bodyClassName: "toast-faild",
            }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleClickCu = () => {
    setVisibleCu(!visibleCu);
  };
  const handleClickNew = () => {
    setVisibleNew(!visibleNew);
  };
  const handleClickCr = () => {
    setVisibleCr(!visibleCr);
  };
  return (
    <div
      className={
        showMenu
          ? `pl-[50px] mr-auto w-full md:w-[75%] lg:w-[80%] xl:w-[85%] 2xl:w-[85%] bg-caspian-gray3 p-1 overflow-y-scroll`
          : `pl-[50px] mr-[54px] w-full bg-caspian-gray3 p-1 overflow-y-scroll`
      }
    >
      <ProfileNavbar buttonActive="forget-password" />
      <div className="flex flex-col items-center justify-center w-full mt-24">
        <div className="w-2/3 h-auto flex flex-col gap-1">
          <h2 className="ml-auto text-sm border-b-2 border-b-caspian-error">
            تغییر کلمه عبور
          </h2>
          <div className="w-full h-auto flex flex-col items-start justify-center bg-caspian-surface rounded-xl px-4 py-8">
            <form onSubmit={handleSubmit(forgotLogin)} className="w-1/2">
              <div className="flex flex-col items-start justify-center gap-6 w-[90%]">
                <div className="flex flex-col gap-1 w-full">
                  <div className="relative">
                    <div
                      onClick={handleClickCu}
                      className="text-caspian-gray text-lg absolute left-3 top-1/2 -translate-y-1/2 cursor-pointer"
                    >
                      {visibleCu ? <PiEyeBold /> : <PiEyeClosed />}
                    </div>
                    <input
                      type={visibleCu ? "text" : "password"}
                      name="old_password"
                      placeholder="کلمه عبور فعلی"
                      {...register("old_password", {
                        required: true,
                        minLength: 4,
                      })}
                      className={`outline-caspian-gray ${
                        errors.old_password &&
                        (errors.old_password.type === "required" ||
                          errors.old_password.type === "minLength")
                          ? "focus-visible:outline-caspian-error"
                          : "focus-visible:outline-caspian-secondary-600"
                      }  outline outline-1 bg-white p-2 rounded-md w-full text-right text-sm`}
                    />
                  </div>

                  {errors.old_password &&
                    errors.old_password.type === "required" && (
                      <span className="text-caspian-error text-sm">
                        لطفا کلمه عبور فعلی را وارد کنید
                      </span>
                    )}
                  {errors.old_password &&
                    errors.old_password.type === "minLength" && (
                      <span className="text-caspian-error text-sm">
                        کلمه عبور باید بیشتر از 4 کارکتر باشد..
                      </span>
                    )}
                </div>

                <div className="flex flex-col gap-1 w-full">
                  <div className="relative">
                    <label
                      className={`${
                        errors.new_password2 && "text-caspian-error"
                      } text-xs absolute bg-caspian-surface p-0.5 -top-3 right-2`}
                    >
                      کلمه عبور جدید
                    </label>
                    <div
                      onClick={handleClickNew}
                      className="text-caspian-gray text-lg absolute left-3 top-1/2 -translate-y-1/2 cursor-pointer"
                    >
                      {visibleNew ? <PiEyeBold /> : <PiEyeClosed />}
                    </div>
                    <input
                      type={visibleNew ? "text" : "password"}
                      name="new_password1"
                      // placeholder="کلمه عبور جدید"
                      {...register("new_password1", {
                        required: true,
                        minLength: 4,
                      })}
                      className={`outline-caspian-gray ${
                        errors.new_password1 &&
                        (errors.new_password1.type === "required" ||
                          errors.new_password1.type === "minLength")
                          ? "focus-visible:outline-caspian-error"
                          : "focus-visible:outline-caspian-secondary-600"
                      }  outline outline-1 bg-white p-2 rounded-md w-full text-right text-sm`}
                    />
                  </div>

                  {errors.new_password1 &&
                    errors.new_password1.type === "required" && (
                      <span className="text-caspian-error text-sm">
                        لطفا کلمه عبور جدید را وارد کنید
                      </span>
                    )}
                  {errors.new_password1 &&
                    errors.new_password1.type === "minLength" && (
                      <span className="text-caspian-error text-sm">
                        کلمه عبور باید بیشتر از 4 کارکتر باشد..
                      </span>
                    )}
                </div>

                <div className="flex flex-col gap-1 w-full">
                  <div className="relative">
                    <label
                      className={`${
                        errors.new_password2 && "text-caspian-error"
                      } text-xs absolute bg-caspian-surface p-0.5 -top-3 right-2`}
                    >
                      تکرار کلمه عبور جدید
                    </label>
                    <div
                      onClick={handleClickCr}
                      className="text-caspian-gray text-lg absolute left-3 top-1/2 -translate-y-1/2 cursor-pointer"
                    >
                      {visibleCr ? <PiEyeBold /> : <PiEyeClosed />}
                    </div>
                    <input
                      type={visibleCr ? "text" : "password"}
                      name="new_password2"
                      // placeholder="تکرار کلمه عبور جدید"
                      {...register("new_password2", {
                        required: true,
                        minLength: 4,
                        validate: (val) => val === watch("new_password1"),
                      })}
                      className={`outline-caspian-gray ${
                        errors.new_password2 &&
                        (errors.new_password2.type === "required" ||
                          errors.new_password2.type === "minLength")
                          ? "focus-visible:outline-caspian-error"
                          : "focus-visible:outline-caspian-secondary-600"
                      }  outline outline-1 bg-white p-2 rounded-md w-full text-right text-sm`}
                    />
                  </div>

                  {errors.new_password2 &&
                    errors.new_password2.type === "required" && (
                      <span className="text-caspian-error text-sm">
                        لطفا تکرار کلمه عبور جدید را وارد کنید
                      </span>
                    )}
                  {errors.new_password2 &&
                    errors.new_password2.type === "validate" && (
                      <span className="text-caspian-error text-sm">
                        تکرار کلمه عبور جدید مطابقت ندارد.
                      </span>
                    )}
                  {errors.new_password2 &&
                    errors.new_password2.type === "minLength" && (
                      <span className="text-caspian-error text-sm">
                        کلمه عبور باید بیشتر از 8 کارکتر باشد..
                      </span>
                    )}
                </div>
                <button
                  type="submit"
                  className="relative border border-caspian-secondary-400 text-caspian-secondary-400 rounded-md px-4 py-2 flex items-center justify-center gap-2 text-sm w-fit"
                >
                  <span>ذخیره</span>
                  {loading && (
                    <div className="absolute top-1/2 -translate-y-1/2 left-3">
                      <PiCircleNotch className="animate-spin" />
                    </div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
