import { PiFadersBold } from "react-icons/pi";
import FilterComponent from "./FilterComponent";

const FilterButton = ({
  showFilter,
  setShowFilter,
  data,
  // setDataFiltered,
  setFavorite,
  favorite,
  setCurrentPage,
}) => {
  return (
    <div className="relative">
      <button
        onClick={() => {
          setShowFilter(!showFilter);
        }}
        className="flex items-center gap-2 rounded-md outline outline-1 outline-caspian-secondary-900 px-2 py-1 text-caspian-secondary-900"
      >
        <PiFadersBold />
        <span className="text-sm">فیلتر</span>
      </button>
      {showFilter && (
        <FilterComponent
          data={data}
          setShowFilter={setShowFilter}
          // setDataFiltered={setDataFiltered}
          setFavorite={setFavorite}
          favorite={favorite}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
};

export default FilterButton;
