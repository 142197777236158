import React from "react";
import { useNavigate } from "react-router-dom";
import Chart3 from "./Chart3";
import { PiCaretLeftLight } from "react-icons/pi";

const MainChart3 = ({ setActiveChart }) => {
  const navigate = useNavigate();
  return (
    <div className="flex w-full gap-2 flex-col items-start">
      <div className='flex flex-col items-end w-full'>
        <button
          className="text-caspian-secondary-600 flex  items-center cursor-pointer"
          onClick={() => navigate("/chart/tree-chart")}>
          بازگشت
          <PiCaretLeftLight />
        </button>
      </div>
      <div className="p-5 pb-10 w-full overflow-x-auto bg-caspian-surface shadow-[0_5px_5px_#00000050] rounded-md ">
        <span>معاون مهندسی و توسعه</span>
        <Chart3 />
      </div >
    </div >

  );
};

export default MainChart3;
