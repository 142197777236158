import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/auth/AuthContext";
import API from "../../../api/API";
import SingleNote from "../note/SingleNote";

const AllNotes = ({ notebookId }) => {
  const { token } = useContext(AuthContext);
  const [fullData, setFullData] = useState([-1]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    if (token) {
      setLoading(true);
      API.get(`/api/v1/profile/notebook`, {
        headers: { Authorization: token },
      })
        .then((d) => {
          setFullData(d.data.filter((data) => data?.id === +notebookId));
          setRefresh(0);
        })
        .catch((err) => {
          console.log("error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token, refresh, notebookId]);
  console.log(fullData);
  return (
    <div className="flex flex-wrap gap-2 w-full">
      {fullData[0]?.notes?.map((data, index) => (
        <SingleNote key={index} data={data} />
      ))}
    </div>
  );
};

export default AllNotes;
