import React, { useContext, useEffect, useState } from 'react'
import Modal from '../../../../../../modal/Modal'
import { PiCloudArrowDownLight, PiX } from 'react-icons/pi'
import convertFileSize from '../../../../../../../helper/convertFileSize'
import { AuthContext } from '../../../../../../../context/auth/AuthContext'
import API from '../../../../../../../api/API'

const DetailsDocCost = ({ setShowModalDetail, listDocCost, selectItemIndex, idRecord, costListObject }) => {
    const { token } = useContext(AuthContext);
    const [showFile, setShowFile] = useState([])


    useEffect(() => {
        if (!token || selectItemIndex === null || selectItemIndex === undefined) return;

        const handleFileRecord = async () => {
            try {
                const selectedId = idRecord[selectItemIndex];

                const res = await API.get(`/api/v1/accounting/record/document${selectedId}`,
                    {
                        headers: { Authorization: token }
                    }
                )
                console.log(res?.data)
                setShowFile(res?.data)

            } catch (error) {
                console.log(error)
            }
        }
        handleFileRecord()
    }, [token, selectItemIndex, idRecord])



    return (
        <Modal>
            {listDocCost?.map((item, index) => (

                selectItemIndex === index &&
                <div key={index} className='w-[350px] md:w-[450px] flex gap-10 rounded-lg shadow-[0_0_5px_#00000050] items-center flex-col bg-caspian-surface p-7' >

                    <div className='flex items-center justify-between w-full'>
                        <span className='text-xs 2xl:text-base'>جزئیات فاکتور</span>

                        <PiX onClick={() => setShowModalDetail(false)}
                            className='text-base cursor-pointer text-caspian-gray4 2xl:text-2xl' />
                    </div>

                    <div className='flex flex-col items-center w-full gap-3'>

                        <div className='flex items-center justify-between w-full pb-2 border-b-2 border-b-caspian-gray2/40'>
                            <span className='text-xs 2xl:text-sm text-caspian-gray4'>عنوان فاکتور :</span>
                            <span className='text-xs 2xl:text-sm'>{item?.title}</span>
                        </div>

                        <div className='flex items-center justify-between w-full pb-2 border-b-2 border-b-caspian-gray2/40'>
                            <span className='text-xs 2xl:text-sm text-caspian-gray4'>تاریخ فاکتور :</span>
                            <span className='text-xs 2xl:text-sm'>{new Date(item?.recordDate).toLocaleDateString('fa-IR')}</span>
                        </div>

                        <div className='flex items-center justify-between w-full pb-2 border-b-2 border-b-caspian-gray2/40'>
                            <span className='text-xs 2xl:text-sm text-caspian-gray4'>شماره فاکتور :</span>
                            <span className='text-xs 2xl:text-sm'>{item?.number ? item?.number : "-"}</span>
                        </div>

                        <div className='flex items-center justify-between w-full pb-2 border-b-2 border-b-caspian-gray2/40'>
                            <span className='text-xs 2xl:text-sm text-caspian-gray4'>مبلغ فاکتور(ريال) :</span>
                            <span className='text-xs 2xl:text-sm'>{item?.amount}</span>
                        </div>

                        <div className='flex items-center w-full gap-5 py-2 pb-2 border-b-2 border-b-caspian-gray2/40'>
                            <span className='text-xs 2xl:text-sm whitespace-nowrap text-caspian-gray4'>توضیحات :</span>
                            <span className='text-xs leading-7 2xl:text-sm'>{item?.describtion ? item?.describtion : "-"}</span>
                        </div>

                        <div className='flex flex-col items-start w-full '>
                            <span className='py-3 text-xs 2xl:text-sm text-caspian-gray4'>فایل بارگذاری شده سند :</span>

                            {showFile?.map((file, index) => (
                                <div key={index} className='flex items-center w-full gap-3'>
                                    <div className='w-[90%] px-4 py-2 flex items-center justify-between border-b-2 rounded-md  border-caspian-gray2'>
                                        <div className='flex items-center w-full gap-5 '>
                                            <img src='/assets/images/action-plan/Group.svg' className='w-4 2xl:w-6' alt='' />
                                            <span className='text-xs '>{file?.title}</span>
                                        </div>


                                        <span className='text-xs text-caspian-gray4'>{convertFileSize(file?.size)}</span>

                                    </div>
                                    <a href={file?.file} rel="noreferrer" target='_blank' download={file?.file}>
                                        <PiCloudArrowDownLight className='text-2xl cursor-pointer text-caspian-secondary-600' />
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))
            }
        </ Modal >
    )
}

export default DetailsDocCost