import React, { useEffect, useState } from "react";
import { PiCaretLeftBold } from "react-icons/pi";

const AllMembers = ({
  mainMembers,
  guestMembers,
  companyMembers,
  showAll,
  hideAll,
  setShowAll,
  setHideAll,
}) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (showAll) {
      setShow(true);
    }
  }, [showAll]);
  useEffect(() => {
    if (hideAll) {
      setShow(false);
    }
  }, [hideAll]);
  useEffect(() => {
    setShowAll(false);
  }, [show, setShowAll]);
  useEffect(() => {
    setHideAll(false);
  }, [show, setHideAll]);
  return (
    <div className="w-full flex flex-col gap-0 border border-caspian-gray2 rounded-md overflow-hidden">
      <div
        onClick={() => setShow(!show)}
        className="bg-caspian-gray3 p-3 w-full border-b border-b-caspian-gray2 flex items-center justify-between cursor-pointer"
      >
        <h2 className="text-xs">اعضای جلسه</h2>
        <PiCaretLeftBold
          className={show ? "-rotate-90 duration-500" : "rotate-0 duration-500"}
        />
      </div>
      {show && (
        <div className="flex flex-col gap-0 w-full">
          {/* تیتر */}
          <div className="w-full flex items-center gap-0 text-caspian-gray4 border-r-2 border-r-caspian-transparent">
            <div className="flex items-center gap-2 basis-[28%] p-3">
              <span className="text-xs">نام و نام خانوادگی</span>
            </div>
            <div className="flex items-center gap-2 basis-[28%] p-3 border-x border-x-caspian-gray2">
              <span className="text-xs">سازمان</span>
            </div>
            <div className="flex items-center gap-2 basis-[44%] p-3">
              <span className="text-xs">سمت سازمانی</span>
            </div>
          </div>
          {/* اعضا */}
          <div className="flex flex-col gap-0 w-full">
            {mainMembers?.map((m, i) => (
              <div
                key={i}
                className="w-full flex items-center gap-0 border-t border-t-caspian-gray2 border-r-2 border-r-caspian-secondary-100"
              >
                <div className="flex items-center gap-2 basis-[28%] p-2.5">
                  <span className="text-xs">{m.fullName}</span>
                </div>
                <div className="flex items-center gap-2 basis-[28%] p-2.5 border-x border-x-caspian-gray2">
                  <span className="text-xs">{m.company}</span>
                </div>
                <div className="flex items-center gap-2 basis-[44%] p-2.5">
                  <span className="text-xs">{m.position}</span>
                </div>
              </div>
            ))}
            {companyMembers?.map((m, i) => (
              <div
                key={i}
                className="w-full flex items-center gap-0 border-t border-t-caspian-gray2 border-r-2 border-r-caspian-secondary-300"
              >
                <div className="flex items-center gap-2 basis-[28%] p-2.5">
                  <span className="text-xs">{m.fullName}</span>
                </div>
                <div className="flex items-center gap-2 basis-[28%] p-2.5 border-x border-x-caspian-gray2">
                  <span className="text-xs">{m.company}</span>
                </div>
                <div className="flex items-center gap-2 basis-[44%] p-2.5">
                  <span className="text-xs">{m.position}</span>
                </div>
              </div>
            ))}
            {guestMembers?.map((m, i) => (
              <div
                key={i}
                className="w-full flex items-center gap-0 border-t border-t-caspian-gray2 border-r-2 border-r-caspian-error/50"
              >
                <div className="flex items-center gap-2 basis-[28%] p-2.5">
                  <span className="text-xs">{m.fullName}</span>
                </div>
                <div className="flex items-center gap-2 basis-[28%] p-2.5 border-x border-x-caspian-gray2">
                  <span className="text-xs">{m.company}</span>
                </div>
                <div className="flex items-center gap-2 basis-[44%] p-2.5">
                  <span className="text-xs">{m.position}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AllMembers;
