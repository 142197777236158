import React from "react";
import { useParams } from "react-router-dom";
import MainAuditListQuestion from "./list-question/MainAuditListQuestion";
import MainAuditProfileQuestion from "./profile-question/MainAuditProfileQuestion";

const MainAuditQuestion = () => {
  const { questionId } = useParams();
  console.log(questionId);
  return questionId ? <MainAuditProfileQuestion /> : <MainAuditListQuestion />;
};

export default MainAuditQuestion;
