import { PiCaretDoubleRight } from "react-icons/pi";
import Category from "./Category";
import ZoneTitle2 from "./ZoneTitle2";
// import ZoneTwoSlider from "./zone-two-slider/ZoneTwoSlider";
import { useRef, useState } from "react";
import { useDraggable } from "react-use-draggable-scroll";

const MainZoneTwo = ({ data, title }) => {
  const refZoneTwo = useRef();
  const { events } = useDraggable(refZoneTwo);

  const [showArrow, setShowArrow] = useState(true);
  // console.log(data.filter((d) => d.groupCategoryRelated.length > 0));

  return (
    <div className="w-full flex flex-col gap-1 h-full relative">
      <ZoneTitle2 title={title} />
      <div
        onPointerDownCapture={() => setShowArrow(false)}
        onPointerUpCapture={() => setShowArrow(true)}
        {...events}
        ref={refZoneTwo}
        className={`${data.length > 4 && showArrow && "cursor-grab"} ltr w-full h-full flex items-start gap-2 py-2 overflow-x-scroll`}
      >
        {data.length > 4 && showArrow && (
          <div className="absolute top-1/2 -translate-y-1/2 right-2">
            <PiCaretDoubleRight className="text-caspian-primary-300 text-2xl drop-shadow-[0_0_1px_#00000050]" />
          </div>
        )}
        {/* Start Category */}
        {data.map(
          (d, i) => (
            // d.groupCategoryRelated.length > 0 && (
            <Category
              key={i}
              dataGroup={d.groupCategoryRelated}
              title={d.title}
            />
          )
          // )
        )}
        {/* End Category */}
      </div>
    </div>
  );
};

export default MainZoneTwo;
