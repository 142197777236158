import React from 'react';

const ButtonTabs = ({ title, activeTab, active, onClick }) => {
    return (
        <button
            className={`pointer-cursor text-center px-3 py-2 ${activeTab === active ? 'border-2 border-b-0 rounded-tr-md rounded-tl-md text-caspian-secondary-600 border-caspian-secondary-600 border-solid font-semibold' : 'border-b-2 border-caspian-secondary-600 border-dashed'}`}
            onClick={onClick}
        >
            {title}
        </button>
    );
}

export default ButtonTabs;
