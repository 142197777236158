import React, { useState } from "react";
import { PiX } from "react-icons/pi";
import TabContact from "./TabContact";
import MainContentChat from "../chat/content-chat-contact/MainContentChat";

const MainContact = ({ setShowContact }) => {
  const [showChatPerson, setShowChatPerson] = useState(false);
  return (
    <div className="absolute left-14 top-2 bottom-0 h-[90vh] min-w-[280px] max-w-[280px] rounded-md p-2 shadow-[0_0_5px_#00000050] bg-caspian-surface">
      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-between w-full">
          <span className="text-sm">ارتباطات</span>
          <PiX
            className="cursor-pointer"
            onClick={() => setShowContact(false)}
          />
        </div>
        <TabContact setShowChatPerson={setShowChatPerson} />
        {showChatPerson && (
          <MainContentChat
            isIdUser={6}
            memberSelected={[6]}
            setShowChatPerson={setShowChatPerson}
          />
        )}
      </div>
    </div>
  );
};

export default MainContact;
