import React from "react";

const SingleOtherFolder = ({
  title,
  counter,
  icon,
  iconClass,
  onClick,
}) => {
  return (
    <div className="flex items-center justify-between w-full">
      <div onClick={onClick} className="flex items-center gap-1 cursor-pointer">
        <div className={iconClass}>{icon}</div>
        <span className="text-xs">{title}</span>
      </div>
      <div className="flex items-center gap-2">
        <span className="text-sm text-caspian-gray">{counter}</span>
      </div>
    </div>
  );
};

export default SingleOtherFolder;
