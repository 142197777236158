import React from "react";
import { PiCheckCircle } from "react-icons/pi";

const Details = ({ data }) => {
  console.log(data);
  return (
    <div className="w-full flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <h2 className="text-sm">{data?.title}</h2>
          <button
            className="text-[#FF5B5B] bg-[#FF5B5B12] p-1.5 rounded-lg text-xs w-fit"
            disabled
          >
            {data?.privacy?.toLowerCase()?.includes("public") &&
              "عمومی(داخل و برون سازمانی)"}
            {data?.privacy?.toLowerCase()?.includes("internal") && "داخلی"}
            {data?.privacy?.toLowerCase()?.includes("restricted") &&
              "دسترسی بر اساس طبقه‌بندی"}
            {data?.privacy?.toLowerCase()?.includes("confidential") && "محرمانه"}
            {data?.privacy?.toLowerCase()?.includes("secret") && "فوق محرمانه"}
          </button>
        </div>
        <div className="flex flex-col items-end gap-2">
          <div className="flex items-center gap-2">
            <h2 className="text-sm text-caspian-gray font-normal whitespace-nowrap">
              کد سند:
            </h2>
            <h2 className="text-sm whitespace-nowrap font-sans">
              {(
                data?.internalDocumentRelated?.code
              )?.toString()}
            </h2>
            {/* <h2 className="text-sm whitespace-nowrap font-sans">
              {(
                data?.internalDocumentRelated?.InternalDocumentTypeRelated
                  ?.symbol + data?.internalDocumentRelated?.code
              ).toString()}
            </h2> */}
          </div>
          <div className="flex items-center gap-2">
            <h2 className="text-xs text-caspian-gray4 font-normal whitespace-nowrap">
              کد سند قدیم:
            </h2>
            <h2 className="text-sm whitespace-nowrap font-sans">
            {(
                data?.internalDocumentRelated?.codeOld
              )?.toString()}
            </h2>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2">
          <h2 className="text-sm font-bold whitespace-nowrap">
            شماره آخرین نسخه:
          </h2>
          <h2 className="text-sm font-bold whitespace-nowrap">
            {data?.version}
            <span className="text-xs">.{data?.revision}</span>
          </h2>
        </div>
        <div className="flex items-center gap-2">
          <h2 className="text-sm text-caspian-gray font-normal whitespace-nowrap">
            تاریخ آخرین انتشار:
          </h2>
          <h2 className="text-sm text-caspian-gray font-normal whitespace-nowrap">
            {data?.versionDate
              ? new Date(data?.versionDate).toLocaleDateString("fa-IR")
              : "نا مشخص"}
          </h2>
        </div>
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h2
            className={`${
              data?.obsolete ? "border-caspian-gray" : "border-r-[#73AEAF]"
            } flex items-center justify-center py-2 pr-2 border-r-2 text-sm text-caspian-gray font-normal whitespace-nowrap`}
          >
            وضعیت:
          </h2>
          {data?.obsolete ? (
            <h2 className="text-sm whitespace-nowrap">منسوخ شده</h2>
          ) : (
            <div className="flex items-center gap-1">
              <h2 className="text-sm whitespace-nowrap text-[#73AEAF]">
                منتشر شده
              </h2>
              <PiCheckCircle className="text-[#73AEAF] text-lg" />
            </div>
          )}
        </div>

        {data?.fileDocument && data?.obsolete ? (
          <div className="flex items-center gap-4">
            <button
              type="button"
              disabled
              className="rounded-md outline outline-1 outline-caspian-secondary-600 text-caspian-secondary-600 text-sm p-1.5 disabled:outline-caspian-gray disabled:text-caspian-gray"
            >
              دانلود سند
            </button>
            {/* <button
              type="button"
              disabled
              className="rounded-md outline outline-1 outline-caspian-secondary-600 text-caspian-secondary-600 text-sm p-1.5 disabled:outline-caspian-gray disabled:text-caspian-gray"
            >
              نمایش
            </button> */}
          </div>
        ) : (
          data?.fileDocument &&
          !data?.obsolete && (
            <div className="flex items-center gap-4">
              <a
                href={data?.fileDocument}
                download={data?.fileDocument}
                target="_blank"
                rel="noreferrer"
                className=""
              >
                <button
                  type="button"
                  className="rounded-md outline outline-1 outline-caspian-secondary-600 text-caspian-secondary-600 text-sm p-1.5"
                >
                  دانلود سند
                </button>
              </a>
              {/* <a
                href={data?.fileDocument}
                download={data?.fileDocument}
                target="_blank"
                rel="noreferrer"
                className=""
              >
                <button
                  type="button"
                  className="rounded-md outline outline-1 outline-caspian-secondary-600 text-caspian-secondary-600 text-sm p-1.5"
                >
                  نمایش
                </button>
              </a> */}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Details;
