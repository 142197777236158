import React, { useState } from "react";
import { PiCaretLeftBold } from "react-icons/pi";

const Dropdown = ({ children, title, className }) => {
  const [show, setShow] = useState(true);
  return (
    <div className="w-full flex flex-col gap-0 overflow-hidden">
      <div
        onClick={() => setShow(!show)}
        className={`${
          className
            ? className
            : `bg-caspian-gray3 border border-caspian-gray2 p-3 ${
                show ? "rounded-t" : "rounded"
              }`
        } w-full flex items-center justify-between cursor-pointer`}
      >
        <h2 className="text-xs">{title}</h2>
        <PiCaretLeftBold
          className={show ? "-rotate-90 duration-500" : "rotate-0 duration-500"}
        />
      </div>
      {show && children}
    </div>
  );
};

export default Dropdown;
