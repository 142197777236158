import React from "react";

const InfoBox = ({ title, desc }) => {
  console.log(title);
  console.log(desc);
  return (
    <div className="flex items-start gap-0 odd:py-1 even:bg-caspian-surface odd:bg-caspian-gray3 p-1">
      <div className="basis-1/2 flex flex-col gap-2">
        <span className="text-xs text-caspian-gray4">{title}</span>
      </div>
      <div className="basis-1/2">
        {desc?.map((d, i) => (
          <div key={i} className="flex flex-col gap-0">
            <span className="text-xs">
              {d}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfoBox;
