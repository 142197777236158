import React, { useContext, useEffect, useState } from "react";
import { PiCheckCircle, PiCheckCircleFill, PiPlus, PiTrash, PiX, PiXCircleFill } from "react-icons/pi";
import API from "../../../../../api/API";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import Refrence from "./refrence/Refrence";
import RefrenceEdit from "./refrence/RefrenceEdit";
import { toast } from "react-toastify";

const RefrenceMeeting = ({
  setActiveTab,
  generalId,
  setShowDraftMeetingForm,
  setRefresh,
  setDataRefresh,
}) => {
  const { token } = useContext(AuthContext);
  const [deleteId, setDeleteId] = useState(null);
  const [showIsDelete, setShowIsDelete] = useState(false);

  const [showRefrence, setShowRefrence] = useState(false);
  const [showEditRefrence, setShowEditRefrence] = useState(false);
  useState("ManagementSystem");
  const [refreshRefrence, setRefreshRefrence] = useState(0);

  // مستندات درون سازمانی
  const [refrenceData, setRefrenceData] = useState([]);
  let urlRefrenceData = "/api/v1/meeting/profile/refrence/";
  useEffect(() => {
    const getRefrenceData = () => {
      API.get(urlRefrenceData, {
        headers: {
          Authorization: token,
        },
      })
        .then(async (res) => {
          await setRefrenceData(
            res?.data?.filter(
              (d) => d?.meetingProfileRefrenceRelated === generalId
            )
          );
          await setRefreshRefrence(0);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getRefrenceData();
  }, [token, urlRefrenceData, refreshRefrence, generalId]);

  const getRefrenceData = () => {
    if (token) {
      API.get(urlRefrenceData, {
        headers: {
          Authorization: token,
        },
      })
        .then(async (res) => {
          console.log(
            res?.data?.filter(
              (d) => d?.meetingProfileRefrenceRelated === generalId
            )
          );
          await setRefrenceData(
            res?.data?.filter(
              (d) => d?.meetingProfileRefrenceRelated === generalId
            )
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const removeHandler = (id) => {
    API.delete(`/api/v1/meeting/profile/refrence/${id}/`, {
      headers: {
        Authorization: token,
      },
    })
      .then(async (res) => {
        await getRefrenceData();
        // await setRefreshRefrence(0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataHandlerDraft = async () => {
    // e.preventDefault();
    await API.post(
      `/api/v1/meeting/profile/detail/${generalId}/status/`,
      {
        status: "refrence",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await toast.success("پروفایل به صورت پیشنویس ثبت شد.", {
          className: "toast-success",
          bodyClassName: "toast-success",
        });
        await setShowDraftMeetingForm(false);
        await setRefresh(1);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataHandlerNext = async () => {
    // e.preventDefault();
    await API.post(
      `/api/v1/meeting/profile/detail/${generalId}/status/`,
      {
        status: "roles",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await setActiveTab("roles");
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataHandlerBack = async () => {
    await API.post(
      `/api/v1/meeting/profile/detail/${generalId}/status/`,
      {
        status: "general",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await setActiveTab("generalMeeting");
        await setDataRefresh(1);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //   const [refId, setRefId] = useState(null);
  const [disableButton, setDisableButton] = useState(true);

  useEffect(() => {
    if (refrenceData?.length > 0) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [refrenceData, refreshRefrence]);
  return (
    <div className="min-h-[270px] flex flex-col justify-between">
      <div className="flex flex-col gap-3 h-full">
        {/* لیست انتخاب مرجع */}
        <div className="flex items-center gap-4 w-full">
          <button
            onClick={() => setShowRefrence(true)}
            className="flex items-center justify-between w-full border-y border-y-caspian-secondary-500 text-caspian-secondary-500 py-2 cursor-pointer hover:bg-caspian-secondary-50/20"
          >
            <span className="text-xs">اضافه کردن مرجع</span>
            <PiPlus className="text-sm" />
          </button>
          {showRefrence && (
            <Refrence
              generalId={generalId}
              showRefrence={showRefrence}
              setShowRefrence={setShowRefrence}
              setRefreshRefrence={setRefreshRefrence}
            />
          )}
          {showEditRefrence && (
            <RefrenceEdit
              setShowEditRefrence={setShowEditRefrence}
              //   refId={refId}
              setRefreshRefrence={setRefreshRefrence}
            />
          )}
        </div>
        <div className="max-h-[180px] overflow-y-scroll">
          <div className="flex flex-col gap-2">
            {refrenceData.length < 1 ? (
              <h2 className="text-caspian-gray1 text-xs">
                مرجعی ثبت نشده است!
              </h2>
            ) : (
              refrenceData?.map((d, i) => (
                <div
                  key={i}
                  className="bg-caspian-surface p-1.5 rounded-md border border-caspian-gray2 flex items-center justify-between"
                >
                  <div className="flex flex-col gap-1 w-[90%]">
                    <h2 className="text-xs">
                      {d.refrence === "ManagementSystem"
                        ? "سیستم مدیریتی"
                        : d.refrence === "ExternalDocuments"
                        ? "الزامات قانونی"
                        : d.refrence === "InternalDocumentProfile"
                        ? "مستندات درون سازمانی"
                        : d.refrence === "other" && "سایر"}
                    </h2>
                    <span className="text-xs text-caspian-gray1">
                      {d.refrenceText}
                    </span>
                  </div>
                  {
                    <div className="relative flex items-center justify-center w-[10%]">
                      <button
                        onClick={async () => {
                          await setDeleteId(d?.id);
                          await setShowIsDelete(true);
                        }}
                        className="text-caspian-error2/30 hover:text-caspian-error2 duration-300 text-xl"
                      >
                        <PiTrash />
                      </button>
                      <div
                        className={
                          d?.id === deleteId && showIsDelete
                            ? "absolute left-0 right-0 top-0 bottom-0 flex items-center gap-0.5 w-fit bg-caspian-surface shadow-[0_0_5px_#00000050] p-0.5 rounded-md duration-500"
                            : "hidden duration-500"
                        }
                      >
                        <PiCheckCircleFill
                          onClick={() => {
                            removeHandler(d?.id);
                            setShowIsDelete(false);
                          }}
                          className="text-caspian-primary-600 text-lg cursor-pointer"
                        />
                        <PiXCircleFill
                          onClick={() => {
                            setShowIsDelete(false);
                            setDeleteId(null);
                          }}
                          className="text-caspian-gray text-lg cursor-pointer"
                        />
                      </div>
                    </div>
                  }
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      <div className="flex items-center gap-2">
        <button
          onClick={async () => {
            await dataHandlerBack();
          }}
          className="min-w-[6rem] outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs p-1.5 rounded-md"
        >
          مرحله قبل
        </button>
        <button
          disabled={disableButton}
          onClick={async () => {
            await dataHandlerNext();
          }}
          className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
        >
          مرحله بعد
        </button>
        <button
          disabled={disableButton}
          onClick={async () => {
            await dataHandlerDraft();
          }}
          className={`disabled:cursor-not-allowed disabled:text-caspian-gray text-caspian-secondary-500 text-xs p-1.5 rounded-md`}
        >
          ذخیره پیشنویس
        </button>
      </div>
    </div>
  );
};

export default RefrenceMeeting;
