import {
  PiCalendarX,
  PiListBullets,
  PiStack,
} from "react-icons/pi";
import ListItemSingle from "./ListItemSingle";
import { useAppContext } from "../../context/app-context/AppContext";

const MeetingNavbar = ({ buttonActive }) => {
  const { setBradCrump3 } = useAppContext();

  return (
    <nav className="w-full bg-caspian-surface rtl">
      <ul className="flex items-center gap-0.5">
        <ListItemSingle
          title="پروفایل جلسات"
          active="profile-meeting"
          icon={<PiStack />}
          link="/managment-of-meetings/profile-meeting/all-profiles"
          buttonActive={buttonActive}
          onClick={() => setBradCrump3("همه پروفایل ها")}
        />
        <ListItemSingle
          title="لیست جلسات"
          active="list-meeting"
          icon={<PiListBullets />}
          link="/managment-of-meetings/list-meeting/all-meeting"
          buttonActive={buttonActive}
          onClick={() => setBradCrump3("")}
        />
        <ListItemSingle
          title="سوابق جلسات"
          active="records-meeting"
          icon={<PiCalendarX />}
          link="/managment-of-meetings/records-meeting"
          buttonActive={buttonActive}
          onClick={() => setBradCrump3("")}
        />
      </ul>
    </nav>
  );
};

export default MeetingNavbar;
