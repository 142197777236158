import React from "react";

const ColorPicker = ({ setColor, setColorPicker }) => {
  return (
    <div className="shadow-[0_0_5px_#00000050] bg-caspian-surface rounded-md p-2 flex flex-col gap-2 w-full absolute top-[100%]">
      <div className="flex items-center justify-around gap-2">
        <span
          onClick={() => {
            setColor("#BA7070");
            setColorPicker(false);
          }}
          className="bg-opacity-50 cursor-pointer w-4 h-4 rounded-full bg-[#BA7070]"
        ></span>
        <span
          onClick={() => {
            setColor("#F66B6B");
            setColorPicker(false);
          }}
          className="bg-opacity-50 cursor-pointer w-4 h-4 rounded-full bg-[#F66B6B]"
        ></span>
        <span
          onClick={() => {
            setColor("#FEBAFF");
            setColorPicker(false);
          }}
          className="bg-opacity-50 cursor-pointer w-4 h-4 rounded-full bg-[#FEBAFF]"
        ></span>
        <span
          onClick={() => {
            setColor("#FB4284");
            setColorPicker(false);
          }}
          className="bg-opacity-50 cursor-pointer w-4 h-4 rounded-full bg-[#FB4284]"
        ></span>
        <span
          onClick={() => {
            setColor("#DA5BFA");
            setColorPicker(false);
          }}
          className="bg-opacity-50 cursor-pointer w-4 h-4 rounded-full bg-[#DA5BFA]"
        ></span>
      </div>
      <div className="flex items-center justify-around gap-2">
        <span
          onClick={() => {
            setColor("#CE6FDD");
            setColorPicker(false);
          }}
          className="bg-opacity-50 cursor-pointer w-4 h-4 rounded-full bg-[#CE6FDD]"
        ></span>
        <span
          onClick={() => {
            setColor("#520060");
            setColorPicker(false);
          }}
          className="bg-opacity-50 cursor-pointer w-4 h-4 rounded-full bg-[#520060]"
        ></span>
        <span
          onClick={() => {
            setColor("#2962FF");
            setColorPicker(false);
          }}
          className="bg-opacity-50 cursor-pointer w-4 h-4 rounded-full bg-[#2962FF]"
        ></span>
        <span
          onClick={() => {
            setColor("#31B04D");
            setColorPicker(false);
          }}
          className="bg-opacity-50 cursor-pointer w-4 h-4 rounded-full bg-[#31B04D]"
        ></span>
        <span
          onClick={() => {
            setColor("#8AFF61");
            setColorPicker(false);
          }}
          className="bg-opacity-50 cursor-pointer w-4 h-4 rounded-full bg-[#8AFF61]"
        ></span>
      </div>
      <div className="flex items-center justify-around gap-2">
        <span
          onClick={() => {
            setColor("#285784");
            setColorPicker(false);
          }}
          className="bg-opacity-50 cursor-pointer w-4 h-4 rounded-full bg-[#285784]"
        ></span>
        <span
          onClick={() => {
            setColor("#7D91A4");
            setColorPicker(false);
          }}
          className="bg-opacity-50 cursor-pointer w-4 h-4 rounded-full bg-[#7D91A4]"
        ></span>
        <span
          onClick={() => {
            setColor("#FFF7CB");
            setColorPicker(false);
          }}
          className="bg-opacity-50 cursor-pointer w-4 h-4 rounded-full bg-[#FFF7CB]"
        ></span>
        <span
          onClick={() => {
            setColor("#98BB92");
            setColorPicker(false);
          }}
          className="bg-opacity-50 cursor-pointer w-4 h-4 rounded-full bg-[#98BB92]"
        ></span>
        <span
          onClick={() => {
            setColor("#A49D9D");
            setColorPicker(false);
          }}
          className="bg-opacity-50 cursor-pointer w-4 h-4 rounded-full bg-[#A49D9D]"
        ></span>
      </div>
    </div>
  );
};

export default ColorPicker;
