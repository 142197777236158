import Cookies from "js-cookie";
import React, { useState, useEffect, useRef } from "react";
import { useContext } from "react";
import {
  PiGearSix,
  PiHeadset,
  PiMoon,
  PiNotepad,
  PiPower,
  PiQuestion,
  PiToggleLeftFill,
  PiToggleRightFill,
  PiUser,
} from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/auth/AuthContext";
import DropDownButton from "./DropDownButton";

const UserDropDown = ({ picture, fullname }) => {
  const navigate = useNavigate();
  const [darkTheme, setDarkTheme] = useState(false);

  const { dispatch } = useContext(AuthContext);
  const logoutHandler = () => {
    dispatch({ type: "SIGNOUT" });
    localStorage.removeItem("_appSignging");
    Cookies.remove("auth_cookie");
    navigate("/login");
  };

  const [open, setOpen] = useState(false);
  let menuRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.altKey && e.key === "e") {
        e.preventDefault();
        logoutHandler();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  return (
    <div ref={menuRef} className="relative">
      {picture === undefined ? (
        <div className="w-9 h-9 bg-caspian-gray animate-pulse rounded-full"></div>
      ) : (
        <div
          onClick={() => setOpen(!open)}
          className="flex items-center justify-center outline outline-1 outline-caspian-primary-300 bg-caspian-primary-300 w-9 h-9 rounded-full overflow-hidden object-cover cursor-pointer"
        >
          <img
            src={picture ? picture : "/assets/images/users/not-picture.svg"}
            alt={fullname}
            className="w-full h-full"
          />
        </div>
      )}
      <div
        className={
          open
            ? "w-[194px] rounded-xl shadow-[0_0_10px_#00000050] bg-caspian-surface absolute bottom-8 right-8 z-50 px-2 py-4"
            : "hidden"
        }
      >
        <div className="flex flex-col gap-4">
          <DropDownButton
            onClick={() => setOpen(false)}
            link="/profile/personal-info"
            title="حساب کاربری"
            icon={<PiUser />}
          />
          <DropDownButton link="/" title="تنظیمات" icon={<PiGearSix />} />
          <DropDownButton link="/" title="تاریخچه کاربر" icon={<PiNotepad />} />
          <div className="flex items-center justify-between relative w-full">
            <div className={`flex items-center gap-4`}>
              <div className="text-caspian-gray text-2xl">
                <PiMoon />
              </div>
              <span
                onClick={() => setDarkTheme(!darkTheme)}
                className="text-xs cursor-pointer"
              >
                حالت تاریک
              </span>
            </div>
            {darkTheme ? (
              <PiToggleRightFill
                onClick={() => setDarkTheme(false)}
                className="text-3xl text-caspian-primary-500 cursor-pointer absolute left-0 top-1/2 -translate-y-1/2"
              />
            ) : (
              <PiToggleLeftFill
                onClick={() => setDarkTheme(true)}
                className="text-3xl text-caspian-gray2 cursor-pointer absolute left-0 top-1/2 -translate-y-1/2"
              />
            )}
          </div>
          <div className={`flex items-center gap-4`}>
            <div className="text-caspian-gray text-2xl">
              <PiPower />
            </div>
            <span onClick={logoutHandler} className="text-xs cursor-pointer">
              خروج
            </span>
          </div>
          <DropDownButton
            link="/"
            title="راهنما"
            icon={<PiQuestion />}
            className="border-t border-t-caspian-gray2 pt-3"
          />
          <DropDownButton link="/" title="پشتیبانی" icon={<PiHeadset />} />
          <div className="flex items-center justify-center">
            <span className="text-caspian-gray2 text-xs">نسخه 4.5.1</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDropDown;
