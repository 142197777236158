import React from 'react'

const IndicatorCollapse = ({ item }) => {
    return (
        <div className='w-full flex flex-col pb-5 items-start gap-5'>

            <div className='flex flex-col items-start'>
                <span className='text-xs 2xl:text-sm text-[#4B5563]'>فرمول شاخص :</span>
            </div>

            <div className='w-full flex flex-col items-start gap-5'>
                <span className='text-xs 2xl:text-sm text-[#4B5563]'>دوره سنجش :</span>

                <div className='w-full flex justify-start pr-12'>
                    <div className='basis-1/3 flex items-start justify-start'>
                        <span className='text-xs 2xl:text-sm text-[#4B5563]'>تاریخ مبنای دوره پایش:  :</span>
                        <span className='text-xs 2xl:text-sm font-semibold text-caspian-on_primary/80'>
                            {new Date(item?.startDate).toLocaleDateString('fa-IR')}

                        </span>
                    </div>

                    <div className='basis-1/3 flex items-start justify-start'>
                        <span className='text-xs 2xl:text-sm text-[#4B5563]'>مقدار اولیه  :</span>
                        <span className='text-xs 2xl:text-sm font-semibold text-caspian-on_primary/80'>{item?.startValue}</span>
                    </div>
                </div>

                <div className='w-full flex justify-start pr-12'>
                    <div className='basis-1/3 flex items-start justify-start'>
                        <span className='text-xs 2xl:text-sm text-[#4B5563]'>تاریخ انتهای دوره پایش:  :</span>
                        <span className='text-xs 2xl:text-sm font-semibold text-caspian-on_primary/80'>
                            {new Date(item?.endDate).toLocaleDateString('fa-IR')}
                        </span>
                    </div>

                    <div className='basis-1/3 flex items-start justify-start'>
                        <span className='text-xs 2xl:text-sm text-[#4B5563]'>مقدار مورد نظر  :</span>
                        <span className='text-xs 2xl:text-sm font-semibold text-caspian-on_primary/80'>{item?.endValue}</span>
                    </div>

                </div>


                <div className='w-full flex justify-start pr-12'>
                    <div className='basis-1/3 flex items-start justify-start'>
                        <span className='text-xs 2xl:text-sm text-[#4B5563]'>تاریخ پایش نتیجه شاخص :</span>
                        <span className='text-xs 2xl:text-sm font-semibold text-caspian-on_primary/80'>
                            {new Date(item?.reviewEndDate).toLocaleDateString('fa-IR')}
                        </span>
                    </div>

                    <div className='basis-1/3 flex items-start justify-start'>
                        <span className='text-xs 2xl:text-sm text-[#4B5563]'>دوره پایش روند تحقق شاخص :</span>
                        <span className='text-xs 2xl:text-sm font-semibold text-caspian-on_primary/80'>
                            {item?.period === "year" ? "سالانه" :
                                item?.period === "6month" ? "شش ماهه" :
                                    item?.period === "3month" ? "سه ماهه" :
                                        item?.period === "1month" && "ماهانه"}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IndicatorCollapse