import React from 'react'

const OneLineModelStackholder = ({  stackDataLenght1, stackDataLenght2 }) => {
    return (
        <div className='flex items-center justify-between relative w-full'>
            <div className='flex items-center justify-center relative basis-1/3'>
                <div className='border-l-2 w-2 h-9 border-dashed border-caspian-darkgray-600 absolute bottom-6'></div>
                <span className='font-bold text-xs 2xl:text-xl whitespace-nowrap absolute text-caspian-on_primary/80 -top-5'>
                    {stackDataLenght1?.slice(0, 1)?.map((item) => (
                        item?.categoryType === 'internal' ? "درون سازمانی" : item?.categoryType === 'external' && "برون سازمانی"
                    ))}


                    {stackDataLenght2?.slice(0, 1)?.map((item) => (
                        item?.categoryType === 'internal' ? "درون سازمانی" : item?.categoryType === 'external' && "برون سازمانی"
                    ))}
                </span>
                <div className='border-l-2 w-2 h-9 border-dashed border-caspian-darkgray-600 absolute top-2'></div>
            </div>
        </div>
    )
}

export default OneLineModelStackholder