import React from 'react'
import HistorySingle from './HistorySingle'

const HistoryMain = () => {
  return (
    <div className='flex flex-col gap-2 '>
      <HistorySingle profileHistory={"/assets/image/admin-profile-2.png"} fullName={"محمد حسینی"} positionHistory={"مجری"} edite={"ویرایش  عنوان وظیفه"} time={"13:01:00"} date={"1402/01/01"} />
      <HistorySingle profileHistory={"/assets/image/admin-profile-3.jpg"} fullName={"محمد حسینی"} positionHistory={"کنترل کننده"} edite={"ویرایش  بودجه وظیفه"} time={"13:01:00"} date={"1402/01/01"} />
    </div>
  )
}

export default HistoryMain