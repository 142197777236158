import { PiFolderOpen, PiNotebook } from "react-icons/pi";
import { useEffect, useState } from "react";
import FormNoteNew from "./FormNoteNew";

const EditNoteNew = ({
  loading,
  fullData,
  setRefresh,
  setShowAddNote,
  setColor,
  color,
  setShowEditNote,
  noteData,
}) => {
  console.log(fullData);
  const [notebook, setNotebook] = useState(noteData[0]?.notebook);
  useEffect(() => {
    if (notebook === "انتخاب دفترچه") {
      setNotebook(null);
    }
  }, [notebook]);

  return (
    <div className={`w-full flex flex-col gap-2 px-2 pb-2`}>
      <div className={``}>
        <div className="flex items-center gap-2 border-b border-b-caspian-gray4/50 pb-1">
          <PiNotebook className="text-2xl text-caspian-on_primary" />
          <select
            value={notebook ? +notebook : noteData[0]?.notebook}
            name="notebook"
            onChange={(e) => setNotebook(e.target.value)}
            className="text-xs outline-none bg-caspian-transparent cursor-pointer w-full 
          "
          >
            <option value={null}>انتخاب دفترچه</option>
            {fullData[0] !== -1 &&
              fullData?.map((data, i) => (
                <option key={i} value={+data?.id}>
                  {data?.title}
                </option>
              ))}
          </select>
        </div>
      </div>

      <FormNoteNew
        noteData={noteData}
        setRefresh={setRefresh}
        notebook={notebook}
        setShowAddNote={setShowAddNote}
        color={color}
        setColor={setColor}
      />
    </div>
  );
};

export default EditNoteNew;
