import React from 'react';

const TopProfile = ({ data }) => {
    return (
        <div className='bg-caspian-surface border border-caspian-gray2 rounded-md py-5 px-12 flex flex-col gap-y-3'>
            {/* <p className='text-[#B66521] font-medium'>
                <span>{data?.sectionRelated?.categoryRelated?.title}</span>
                <span> / </span> 
                <span> {data?.sectionRelated?.title} </span>
            </p> */}
            <div className='flex justify-between items-center px-3'>
                <div>
                    <span className='text-caspian-gray4'>گروه ذینفعان : </span>
                    <span className='font-bold'>
                        {/* مهندسان مشاور،پیمانکاران و متخصصان */}
                        {data?.title}
                    </span>
                </div>
                <div>
                    <span className='text-caspian-gray4'>کد گروه : </span>
                    <span className='font-bold'>
                        {/* O-103 */}
                        {data?.code}
                    </span>
                </div>
            </div>
            <div className='text-caspian-gray4 py-3'>
                <span className='font-semibold'>معرفی :</span>

                <p className='px-3 py-2 leading-8 text-xs 2xl:text-sm'>
                    {data?.describtion}
                </p>

            </div>
        </div>
    );
}

export default TopProfile;
