import React, { useEffect, useState } from "react";
import MeetingNavbar from "../MeetingNavbar";
import { useAppContext } from "../../../context/app-context/AppContext";
import API from "../../../api/API";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import MainDetailsListMeeting from "../list-meeting/details-list-meeting/MainDetailsListMeeting";
import MainListRecordsMeeting from "./list-records-meeting/MainListRecordsMeeting";
import MainDetailsRecordsMeeting from "./details-records-meeting/MainDetailsRecordsMeeting";
import { PiCaretLeft } from "react-icons/pi";

const MainRecordsMeeting = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const params = useParams();
  const { setBradCrump1, setBradCrump2, setBradCrump3 } = useAppContext();
  useEffect(() => {
    setBradCrump1("مدیریت جلسات");
    setBradCrump2("سوابق جلسات");
    setBradCrump3("");
  }, [setBradCrump1, setBradCrump2, setBradCrump3]);

  const [fullData, setFullData] = useState([-1]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    let url = "/api/v1/meeting/info/detail/";
    API.get(url)
      .then((d) => {
        setFullData(d.data.filter((d) => d.status === "archived"));
      })
      .catch((err) => {
        console.log("error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <MeetingNavbar buttonActive="records-meeting" />
      {params.id && (
        <button
          onClick={() => navigate("/managment-of-meetings/records-meeting")}
          className="mr-auto flex items-center gap-2 text-caspian-secondary-700 p-2"
        >
          <span className="text-sm">بازگشت</span>
          <PiCaretLeft />
        </button>
      )}
      <div className="p-2 flex flex-col gap-2">
        {params.id ? (
          <MainDetailsRecordsMeeting idMeeting={params.id} />
        ) : (
          <div className="flex flex-col gap-2">
            <MainListRecordsMeeting listMeeting={fullData} />
          </div>
        )}
      </div>
    </>
  );
};

export default MainRecordsMeeting;
