import { useContext, useEffect, useRef, useState } from "react";
import API from "../../../../../api/API";
import { AuthContext } from "../../../../../context/auth/AuthContext";

const TitleAndDescription = ({
  fullData,
  meetingProfile,
  setActiveTab,
  setShowPlanningMeetingForm,
  setMeetingId,
  thisId,
  setRefresh,
  setMeetingListRefresh,
}) => {
  const titleRef = useRef();
  const descriptionRef = useRef();
  const { token } = useContext(AuthContext);
  const [title, setTitle] = useState(fullData?.title);
  const [description, setDescription] = useState(fullData?.description);

  const dataHandler = async (input) => {
    if (title === "" || description === "") return null;
    if (await fullData?.id) {
      await API.patch(
        `/api/v1/meeting/info/detail/${fullData?.id}/`,
        {
          title: titleRef.current.value,
          description: descriptionRef.current.value,
          coordinator: null,
          recorder: null,
        },
        {
          headers: { Authorization: token },
        }
      )
        .then((res) => {
          setRefresh(1);
          if (input === "draft") {
            dataHandlerDraft(fullData?.id);
          } else {
            dataHandlerNext(fullData?.id);
          }
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await API.post(
        "/api/v1/meeting/info/detail/",
        {
          title: title,
          description: description,
          meetingProfileRelated: +meetingProfile?.id,
          coordinator: null,
          recorder: null,
        },
        {
          headers: { Authorization: token },
        }
      )
        .then(async (res) => {
          console.log(res.data);
          await setMeetingId(res.data.id);
          if (input === "draft") {
            dataHandlerDraft(res.data.id);
          } else {
            dataHandlerNext(res.data.id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const dataHandlerNext = async (id) => {
    await API.post(
      `/api/v1/meeting/info/detail/${id}/status/`,
      {
        status: "guest",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await setActiveTab("addGuest");
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataHandlerDraft = async (id) => {
    await API.post(
      `/api/v1/meeting/info/detail/${id}/status/`,
      {
        status: "general",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await setShowPlanningMeetingForm(false);
        await setMeetingListRefresh(1);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (
      title === "" ||
      title === undefined ||
      description === "" ||
      description === undefined
    ) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [title, description]);

  return (
    <div className="min-h-[270px] flex flex-col justify-between">
      <div className="flex flex-col gap-3">
        <div className="flex items-center justify-between gap-4">
          <div className="flex flex-col gap-1.5 w-full">
            <label className="text-xs text-caspian-gray">پروفایل جلسه</label>
            <input
              defaultValue={meetingProfile.title}
              disabled
              type="text"
              className="disabled:bg-caspian-gray2/40 outline-none outline-caspian-gray1 p-1.5 text-xs rounded-md outline-1"
            />
          </div>
          <div className="w-full flex flex-col gap-1.5">
            <label className="text-xs text-caspian-gray">عنوان جلسه</label>
            <input
              defaultValue={
                fullData?.title ? fullData?.title : titleRef?.current?.value
              }
              onChange={(e) => setTitle(e.target.value)}
              ref={titleRef}
              name="title"
              type="text"
              placeholder="عنوان جلسه را وارد کنید"
              className="outline-none outline-caspian-gray1 p-1.5 text-xs rounded-md outline-1"
            />
          </div>
        </div>
        <div className="w-full flex flex-col gap-1.5">
          <label className="text-xs text-caspian-gray">توضیحات</label>
          <textarea
            defaultValue={fullData?.description ? fullData?.description : ""}
            onChange={(e) => setDescription(e.target.value)}
            ref={descriptionRef}
            name="description"
            rows={5}
            className="resize-none p-2 rounded-md outline-none outline-1 outline-caspian-gray2 text-xs"
          />
        </div>
      </div>

      <div className="flex items-center gap-2">
        <button
          disabled={disableButton}
          onClick={async () => {
            await dataHandler();
          }}
          className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
        >
          مرحله بعد
        </button>
        <button
          disabled={disableButton}
          onClick={async () => {
            await dataHandler("draft");
          }}
          className={`disabled:cursor-not-allowed disabled:text-caspian-gray text-caspian-secondary-500 text-xs p-1.5 rounded-md`}
        >
          ذخیره پیشنویس
        </button>
      </div>
    </div>
  );
};

export default TitleAndDescription;
