import React, { useEffect, useState } from 'react'
import { useParams,useNavigate } from 'react-router-dom';
import { PiCaretLeft } from 'react-icons/pi';
import MyProfileBlog from './MyProfileBlog';
import API from '../../../api/API';
import Loading from '../../../Loading';

const MainProfileBlog = () => {
  const [data, setData] = useState([-1]);
  const navigate=useNavigate();
  const { id } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await API.get(`http://127.0.0.1:8000/api/v1/blog/post/${id}/`);
        setData(res.data);
        console.log(res.data);
      }
      catch (err) {
        if (err.response.status === 404) {
          console.log(err.message);
          setData([]);
        }
      }
    }
    fetchData();
  }, [id])
  return (
    <div className='flex flex-col gap-4 px-6'>
      <div className='relative'>
          <div className='border-t-2 absolute top-1/2 border-caspian-secondary-600 w-full'></div>
          <div className='w-[75%] xl:w-[85%] relative flex justify-between mr-[80px]'>
            <p className='bg-caspian-gray3 text-sm 2xl:text-lg p-2.5 px-8 text-caspian-secondary-600'>بلاگ</p>
            <button 
            onClick={()=>{data?.length>1?navigate(-1):navigate('/blog')}}
            className='flex items-center gap-2 p-2.5 text-xs 2xl:text-base text-caspian-secondary-600 bg-caspian-gray3' >
              <span>بازگشت</span>
              <PiCaretLeft />
            </button>
          </div>
        </div>
      {
        data[0] === -1 ? (<Loading />) : data?.length < 1 ? (<p className='text-caspian-gray4 text-xs 2xl:text-sm text-center font-bold'>پروفایل موردنظر موجود نمی باشد.</p>) : 
        (<MyProfileBlog data={data} />)
      }
    </div>
  )
}

export default MainProfileBlog