import React from 'react';

const TableRequirement = ({ items }) => {
    return (
        <div className="rounded-md">
            {
                items?.map((item, index) => (
                    <div key={item?.id} className="flex baisis-full border-t border-caspian-darkgray-100 first:border-0 ">
                        <div className=" p-5 py-2 text-center text-sm 2xl:text-base">{index + 1}</div>
                        <div className=" p-2 px-0 text-sm 2xl:text-base">{item?.title} : {item?.describtion}</div>
                        {/* <div className=" p-2 text-sm 2xl:text-base">{item?.describtion}</div> */}
                    </div>
                ))
            }
        </div>
    );
}

export default TableRequirement;
