import React, { useContext, useEffect, useState } from "react";
import {
  PiCaretDownBold,
  PiMagnifyingGlassBold,
  PiXBold,
} from "react-icons/pi";
import API from "../../../../../../api/API";
import { AuthContext } from "../../../../../../context/auth/AuthContext";

const RefrenceEdit = ({ setShowEditRefrence, setRefreshRefrence, refId }) => {
  const { token } = useContext(AuthContext);
  const [showInternalDocuments, setShowInternalDocuments] = useState(false);
  const [showExternalDocuments, setShowExternalDocuments] = useState(false);
  const [showManagementSystem, setShowManagementSystem] = useState(false);
  const [referenceSelected, setReferenceSelected] = useState(null);
  const [contentType, setContentType] = useState(null);

  const [refrenceData, setRefrenceData] = useState([]);
  useEffect(() => {
    const getRefrenceData = () => {
      API.get(`/api/v1/meeting/refrence/${refId}/`, {
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          setRefrenceData(res.data);
          setReferenceSelected(res?.data?.refrence);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getRefrenceData();
  }, [token, refId]);

  console.log(refrenceData);

  // مستندات درون سازمانی
  const [internalDocumentsData, setInternalDocumentsData] = useState([]);
  let urlInternalDocuments = "/api/v1/deocument/internal/profile";
  const getInternalDocuments = () => {
    API.get(urlInternalDocuments, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        setInternalDocumentsData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [searchByTitle, setSearchByTitle] = useState("");
  const [internalDocumentsId, setInternalDocumentsId] = useState(null);
  let searchTitle = [];
  if (internalDocumentsData !== -1) {
    searchTitle = searchByTitle
      ? internalDocumentsData.filter(
          (d) =>
            d?.title !== undefined &&
            d?.title
              ?.toLocaleLowerCase()
              ?.includes(searchByTitle?.toLocaleLowerCase())
        )
      : internalDocumentsData;
  }

  const [internalDocumentsDataId, setInternalDocumentsDataId] = useState([]);

  console.log(refrenceData?.object_id);
  useEffect(() => {
    if (refrenceData?.object_id) {
      const getInternalDocumentsId = async () => {
        await API.get(
          `/api/v1/deocument/internal/profile/${refrenceData?.object_id}/`,
          {
            headers: {
              Authorization: token,
            },
          }
        )
          .then((res) => {
            setInternalDocumentsDataId(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      };
      getInternalDocumentsId();
    }
  }, [token, refrenceData]);

  console.log(internalDocumentsDataId?.title);

  // مستندات برون سازمانی
  const [externalDocumentsData, setExternalDocumentsData] = useState([]);
  let urlExternalDocuments = "/api/v1/deocument/external/";
  const getExternalDocuments = () => {
    API.get(urlExternalDocuments, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        setExternalDocumentsData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [externalDocumentsDataId, setExternalDocumentsDataId] = useState([]);

  console.log(refrenceData?.object_id);
  useEffect(() => {
    if (refrenceData?.object_id) {
      const getExternalDocumentsId = async () => {
        await API.get(
          `/api/v1/deocument/external/${refrenceData?.object_id}/`,
          {
            headers: {
              Authorization: token,
            },
          }
        )
          .then((res) => {
            setExternalDocumentsDataId(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      };
      getExternalDocumentsId();
    }
  }, [token, refrenceData]);

  console.log(externalDocumentsDataId?.title);

  const [searchByTitleEx, setSearchByTitleEx] = useState("");
  const [externalDocumentsId, setExternalDocumentsId] = useState(null);
  let searchTitleEx = [];
  if (externalDocumentsData !== -1) {
    searchTitleEx = searchByTitleEx
      ? externalDocumentsData?.filter(
          (d) =>
            d?.title !== undefined &&
            d?.title
              ?.toLocaleLowerCase()
              ?.includes(searchByTitleEx?.toLocaleLowerCase())
        )
      : externalDocumentsData;
  }

  // سیستم های مدیریتی
  const [managementSystemData, setManagementSystemData] = useState([]);
  let urlManagementSystem = "/api/v1/standard/managmentSystem";
  const getManagementSystem = () => {
    API.get(urlManagementSystem, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        setManagementSystemData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [searchByTitleMs, setSearchByTitleMs] = useState("");
  const [managementSystemId, setManagementSystemId] = useState(null);
  let searchTitleMs = [];
  if (managementSystemData !== -1) {
    searchTitleMs = searchByTitleMs
      ? managementSystemData?.filter(
          (d) =>
            d?.title !== undefined &&
            d?.title
              ?.toLocaleLowerCase()
              ?.includes(searchByTitleMs?.toLocaleLowerCase())
        )
      : managementSystemData;
  }

  const [otherRefrenceData, setOtherRefrenceData] = useState(null);
  useState(null);

  useEffect(() => {
    setContentType(
      referenceSelected === "ManagementSystem"
        ? 58
        : referenceSelected === "ExternalDocuments"
        ? 49
        : referenceSelected === "InternalDocumentProfile"
        ? 48
        : null
    );
  }, [referenceSelected]);

  useEffect(() => {
    if (referenceSelected !== "other") {
      setOtherRefrenceData(null);
    }
    if (referenceSelected !== "ManagementSystem") {
      setShowManagementSystem(false);
    }
    if (referenceSelected !== "ExternalDocuments") {
      setShowExternalDocuments(false);
    }
    if (referenceSelected !== "InternalDocumentProfile") {
      setShowInternalDocuments(false);
    }
  }, [referenceSelected]);

  const dataHandler = async (e) => {
    e.preventDefault();
    await API.patch(
      `/api/v1/meeting/refrence/${refId}/`,
      {
        refrence:
          referenceSelected === "ManagementSystem"
            ? "ManagementSystem"
            : referenceSelected === "ExternalDocuments"
            ? "ExternalDocuments"
            : referenceSelected === "InternalDocumentProfile"
            ? "InternalDocumentProfile"
            : referenceSelected === "other" && "other",
        refrenceText: referenceSelected === "other" ? otherRefrenceData : null,
        content_type:
          referenceSelected === "ManagementSystem"
            ? 58
            : referenceSelected === "ExternalDocuments"
            ? 49
            : referenceSelected === "InternalDocumentProfile"
            ? 48
            : null,
        object_id:
          contentType === 58
            ? 58
            : contentType === 49
            ? externalDocumentsId?.id
            : contentType === 48
            ? internalDocumentsId?.id
            : null,
        meetingProfileRelated: 15,
      },
      {
        headers: { Authorization: token },
      }
    )
      .then((res) => {
        console.log(res);
        setRefreshRefrence(1);
        setShowEditRefrence(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full z-50">
      <div className="bg-caspian-surface rounded-md shadow-[0_0_5px_#00000050] p-3 w-[400px] flex flex-col gap-3">
        <div className="flex items-center justify-between">
          <h2 className="text-sm font-bold">ویرایش مرجع</h2>
          <button onClick={() => setShowEditRefrence(false)}>
            <PiXBold className={"text-caspian-gray4 text-sm"} />
          </button>
        </div>

        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-1.5 w-full">
            <label className="text-xs text-caspian-gray">مرجع</label>
            <select
              onChange={(e) => setReferenceSelected(e.target.value)}
              name="reference"
              value={
                referenceSelected ? referenceSelected : refrenceData?.refrence
              }
              // defaultValue={refrenceData?.refrence}
              className="text-xs outline outline-1 outline-caspian-gray1 p-1.5 rounded-md bg-caspian-transparent cursor-pointer"
            >
              <option value={"ManagementSystem"}>سیستم مدیریتی</option>
              <option value={"ExternalDocuments"}>
                الزامات قانونی و مقررات برون سازمانی
              </option>
              {/* <option value={"mosavabatDakheli"}>مصوبات داخلی</option> */}
              <option value={"InternalDocumentProfile"}>
                مستندات درون سازمانی
              </option>
              <option value={"other"}>سایر</option>
            </select>
          </div>

          {/* اینپوت سایر */}
          {referenceSelected === "other" && (
            <div className="flex flex-col gap-1.5 w-full">
              <label className="text-xs text-caspian-gray">توضیحات</label>
              <input
                defaultValue={refrenceData?.refrenceText}
                onChange={(e) => setOtherRefrenceData(e.target.value)}
                name="referenceDescription"
                type="text"
                placeholder="وارد کنید"
                className="outline-none outline-caspian-gray1 p-1.5 text-xs rounded-md outline-1"
              />
            </div>
          )}

          {/* لیست مدارک درون سازمانی */}
          {referenceSelected === "InternalDocumentProfile" && (
            <div className="flex flex-col gap-1.5 w-full relative">
              <label className="text-xs text-caspian-gray">
                لیست مستندات درون سازمانی
              </label>
              <div
                onClick={() => {
                  setShowInternalDocuments(!showInternalDocuments);
                  getInternalDocuments();
                }}
                className="text-xs outline outline-1 outline-caspian-gray1 p-[8px] rounded-md bg-caspian-transparent cursor-pointer flex items-center justify-between z-50"
              >
                {internalDocumentsId !== null ? (
                  <span className="text-xs">{internalDocumentsId.title}</span>
                ) : (
                  <span className="text-xs">
                    {internalDocumentsDataId?.title}
                  </span>
                )}
                <PiCaretDownBold className="text-caspian-gray" />
              </div>
              {showInternalDocuments && (
                <div className="rounded-md shadow-[0_0_5px_#00000050] w-full flex flex-col gap-3 py-3 absolute left-0 right-0 bottom-[30px] bg-caspian-surface z-50">
                  <div className="relative w-[92%] mx-auto">
                    <input
                      type="search"
                      onChange={(e) => setSearchByTitle(e.target.value)}
                      className="outline-none outline-caspian-gray1 p-1 text-xs rounded-md outline-1 w-full placeholder:text-xs placeholder:text-caspian-gray pr-6"
                      placeholder="جستجو"
                    />
                    <button className="absolute top-1/2 -translate-y-1/2 right-1">
                      <PiMagnifyingGlassBold className="text-caspian-gray text-" />
                    </button>
                  </div>
                  <div className="flex flex-col gap-2 w-full max-h-[160px] overflow-y-scroll">
                    {searchTitle !== -1 &&
                      searchTitle?.map((d, i) => (
                        <span
                          key={i}
                          onClick={() => {
                            setShowInternalDocuments(false);
                            setInternalDocumentsId({
                              id: d.id,
                              title: d.title,
                            });
                          }}
                          className="text-xs hover:bg-caspian-secondary-100 py-1 px-4 w-full cursor-pointer duration-300"
                        >
                          {d.title}
                        </span>
                      ))}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* لیست مدارک برون سازمانی */}
          {referenceSelected === "ExternalDocuments" && (
            <div className="flex flex-col gap-1.5 w-full relative">
              <label className="text-xs text-caspian-gray">
                لیست الزامات قانونی
              </label>
              <div
                onClick={() => {
                  setShowExternalDocuments(!showExternalDocuments);
                  getExternalDocuments();
                }}
                className="text-xs outline outline-1 outline-caspian-gray1 p-[8px] rounded-md bg-caspian-transparent cursor-pointer flex items-center justify-between z-50"
              >
                {externalDocumentsId !== null ? (
                  <span className="text-xs">{externalDocumentsId?.title}</span>
                ) : (
                  <span className="text-xs">
                    {externalDocumentsDataId?.title}
                  </span>
                )}
                <PiCaretDownBold className="text-caspian-gray" />
              </div>
              {showExternalDocuments && (
                <div className="rounded-md shadow-[0_0_5px_#00000050] w-full flex flex-col gap-3 py-3 absolute left-0 right-0 bottom-[30px] bg-caspian-surface z-50">
                  <div className="relative w-[92%] mx-auto">
                    <input
                      type="search"
                      onChange={(e) => setSearchByTitleEx(e.target.value)}
                      className="outline-none outline-caspian-gray1 p-1 text-xs rounded-md outline-1 w-full placeholder:text-xs placeholder:text-caspian-gray pr-6"
                      placeholder="جستجو"
                    />
                    <button className="absolute top-1/2 -translate-y-1/2 right-1">
                      <PiMagnifyingGlassBold className="text-caspian-gray text-" />
                    </button>
                  </div>
                  <div className="flex flex-col gap-2 w-full max-h-[160px] overflow-y-scroll">
                    {searchTitleEx !== -1 &&
                      searchTitleEx?.map((d, i) => (
                        <span
                          key={i}
                          onClick={() => {
                            setShowExternalDocuments(false);
                            setExternalDocumentsId({
                              id: d?.id,
                              title: d?.title,
                            });
                          }}
                          className="text-xs hover:bg-caspian-secondary-100 py-1 px-4 w-full cursor-pointer duration-300"
                        >
                          {d?.title}
                        </span>
                      ))}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* سیستم های مدیریتی */}
          {referenceSelected === "ManagementSystem" && (
            <div className="flex flex-col gap-1.5 w-full relative">
              <label className="text-xs text-caspian-gray">
                لیست سیستم های مدیریتی
              </label>
              <div
                onClick={() => {
                  setShowManagementSystem(!showManagementSystem);
                  getManagementSystem();
                }}
                className="text-xs outline outline-1 outline-caspian-gray1 p-[8px] rounded-md bg-caspian-transparent cursor-pointer flex items-center justify-between z-50"
              >
                {managementSystemId !== null ? (
                  <span className="text-xs">{managementSystemId.title}</span>
                ) : (
                  <span className="text-caspian-gray1 text-xs">
                    انتخاب کنید
                  </span>
                )}
                <PiCaretDownBold className="text-caspian-gray" />
              </div>
              {showManagementSystem && (
                <div className="rounded-md shadow-[0_0_5px_#00000050] w-full flex flex-col gap-3 py-3 absolute left-0 right-0 bottom-[30px] bg-caspian-surface z-50">
                  <div className="relative w-[92%] mx-auto">
                    <input
                      type="search"
                      onChange={(e) => setSearchByTitleMs(e.target.value)}
                      className="outline-none outline-caspian-gray1 p-1 text-xs rounded-md outline-1 w-full placeholder:text-xs placeholder:text-caspian-gray pr-6"
                      placeholder="جستجو"
                    />
                    <button className="absolute top-1/2 -translate-y-1/2 right-1">
                      <PiMagnifyingGlassBold className="text-caspian-gray text-" />
                    </button>
                  </div>
                  <div className="flex flex-col gap-2 w-full max-h-[160px] overflow-y-scroll">
                    {searchTitleMs !== -1 &&
                      searchTitleMs?.map((d, i) => (
                        <span
                          key={i}
                          onClick={() => {
                            setShowManagementSystem(false);
                            setManagementSystemId({
                              id: d.id,
                              title: d.title,
                            });
                          }}
                          className="text-xs hover:bg-caspian-secondary-100 py-1 px-4 w-full cursor-pointer duration-300"
                        >
                          {d.title}
                        </span>
                      ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex items-center gap-2 mt-2">
          <button
            onClick={dataHandler}
            className="outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs py-1.5 px-6 rounded-md"
          >
            به روزرسانی
          </button>
          <button
            onClick={() => setShowEditRefrence(false)}
            className="text-xs text-[#FF5B5B] mr-4"
          >
            لغو
          </button>
        </div>
      </div>
    </div>
  );
};

export default RefrenceEdit;
