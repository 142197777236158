import { priceType } from "../../../../../../helper/pricetype";

const DetailsMain = ({ taskData }) => {
  return (
    <div className="px-3">
      <div className="flex items-start gap-2 py-5 border-b-2 border-caspian-gray3 ">
        <span className="text-caspian-gray4 text-xs ">برنامه اجرایی : </span>
        <span className="text-xs">{taskData?.actionplanRelated?.title}</span>
      </div>

      <div className="flex items-start gap-2 py-5 border-b-2 border-caspian-gray3">
        <span className="text-caspian-gray4 whitespace-nowrap text-xs leading-7">
          توضیحات :
        </span>
        <p className="text-xs leading-7">{taskData?.description}</p>
      </div>

      <div className="flex items-start gap-2 py-5 border-b-2 border-caspian-gray3">
        <span className="text-caspian-gray4 whitespace-nowrap text-xs leading-7">
          نتایج قابل انتظار :
        </span>
        <p className="text-xs leading-7">{taskData?.outputTask}</p>
      </div>

      <div className="flex flex-col gap-8 py-10 ">
        <div className="flex gap-2">
          <span className="text-caspian-gray4 text-xs "> بودجه : </span>
          <span className="text-xs"> {priceType(taskData?.cost)} (ریال)</span>
        </div>

        <div className="flex gap-2">
          <span className="text-caspian-gray4 text-xs "> مجری : </span>
          <span className="text-xs">
            {taskData?.responsible_task?.firstName +
              " " +
              taskData?.responsible_task?.lastName}
          </span>
        </div>

        <div className="flex gap-2">
          <span className="text-caspian-gray4 text-xs "> کنترل کننده : </span>
          <span className="text-xs">
            {taskData?.supervisor?.firstName +
              " " +
              taskData?.supervisor?.lastName}
          </span>
        </div>

        <div className="flex gap-2">
          <span className="text-caspian-gray4 text-xs "> وزن : </span>
          <span className="text-xs">%{taskData?.weight * 100}</span>
        </div>

        <div className="flex gap-2">
          <span className="text-caspian-gray4 text-xs "> نوع : </span>
          <span className="text-xs">
            {taskData?.typeTask === "task"
              ? "وظیفه"
              : taskData?.typeTask === "milestone"
              ? "مایل استون"
              : "summary"}
          </span>
        </div>

        <div className="flex items-center gap-12">
          <div className="flex items-center gap-2">
            <span className="text-caspian-gray4 text-xs "> تاریخ شروع : </span>
            <span className="text-xs">
              {new Date(taskData?.startDate).toLocaleDateString("fa-IR")}
            </span>
          </div>

          <div className="flex items-center gap-2">
            <span className="text-caspian-gray4 text-xs "> تاریخ پایان : </span>
            <span className="text-xs">
              {new Date(taskData?.finishDate).toLocaleDateString("fa-IR")}
            </span>
          </div>
        </div>
        {taskData?.resources && (
          <div className="flex flex-col gap-3 w-[460px]">
            <span className="text-xs text-caspian-gray4">لیست اقلام مصرفی</span>
            <div className="flex items-end justify-between gap-4 pr-2 border-b border-b-caspian-gray2/50 pb-3">
              <span className="text-xs text-caspian-gray4 basis-1/4">نام</span>
              <span className="text-xs text-caspian-gray4 basis-1/4">واحد</span>
              <div className="basis-1/2 flex flex-col gap-0">
                <span className="text-xs text-caspian-gray4 basis-1/2 text-center pb-2">
                  حجم مصرف شده
                </span>
                <div className="flex items-center gap-0 border-t border-t-caspian-gray2/50 pt-2">
                  <span className="text-xs text-caspian-gray4 basis-1/2">
                    در این فعالیت
                  </span>
                  <span className="text-xs text-caspian-gray4 basis-1/2">
                    در کل برنامه
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4 border-r-2 border-r-caspian-primary-200 pr-2">
              {taskData?.resources?.map((r, i) => (
                <div
                  className="flex items-center justify-between gap-4"
                  key={i}
                >
                  <span className="text-xs text-caspian-gray4 basis-1/4 ">
                    {r.name}
                  </span>
                  <span className="text-xs text-caspian-gray4 basis-1/4 ">
                    {r.unit}
                  </span>
                  <span className="text-xs text-caspian-gray4 basis-1/4 ">
                    {0}
                  </span>
                  <span className="text-xs text-caspian-gray4 basis-1/4 ">
                    {0}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
        {/* <table>
          <thead>
            <th>
              <td>نام</td>
              <td>واحد</td>
              <td>مقدار مصرف شده</td>
            </th>
          </thead>
          <tbody>
            {taskData?.resources?.map((r, i) => (
              <tr>
                <td>{r.name}</td>
                <td>{r.unit}</td>
                <td>0</td>
              </tr>
            ))}
          </tbody>
        </table> */}

        <div className="flex items-center gap-12"></div>
      </div>
    </div>
  );
};

export default DetailsMain;
