import React, { useContext } from "react";
import { PiStack } from "react-icons/pi";
import { Link } from "react-router-dom";
import API from "../../../../api/API";
import { AuthContext } from "../../../../context/auth/AuthContext";

const CartableNotifactionBox = ({
  id,
  title,
  created_at,
  sender,
  is_readed,
  description,
  receiver,
  link,
  setOpen,
  deadLine,
}) => {
  const { token } = useContext(AuthContext);
  const readHandler = async () => {
    if (token) {
      try {
        await API.patch(
          `/api/v1/request/activity/${id}/`,
          {
            is_readed: true,
          },
          {
            headers: { Authorization: token },
          }
        );
      } catch (err) {
        console.log(err);
      }
    }
  };
  return (
    <Link
      onClick={() => {
        setOpen(false);
        !is_readed && readHandler();
      }}
      to={link}
      className={`${
        is_readed ? "bg-caspian-transparent" : !is_readed && "bg-[#EDAC00]/5"
      } p-2 flex flex-col gap-0 border-b border-b-[#e4e4e4]`}
    >
      <div className="w-full flex flex-col gap-2">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center gap-2">
            <PiStack
              className={`${
                is_readed
                  ? "text-caspian-gray1"
                  : !is_readed && "text-[#EDAC00]"
              }`}
            />
            <h2
              className={`${
                is_readed
                  ? "text-caspian-gray"
                  : !is_readed && "font-bold text-[#EDAC00]"
              } text-xs`}
            >
              {title}
            </h2>
          </div>
          <span className="text-caspian-gray text-[10px]">
            {created_at &&
              new Date(created_at).toLocaleDateString("fa-IR", {
                hour: "2-digit",
                minute: "2-digit",
              })}
          </span>
        </div>

        <div className="flex flex-col gap-2 mr-4">
          <h2
            className={`${
              is_readed
                ? "text-caspian-gray1"
                : !is_readed && "text-caspian-on_primary/70"
            } text-xs`}
          >
            {description}
          </h2>
        </div>
        <div className="flex items-center justify-between">
          <span className="text-xs text-caspian-gray">
            از طرف: {sender?.firstName + " " + sender?.lastName}
          </span>
          {deadLine && (
            <span className="text-xs text-caspian-gray">
              زمان پایان :{" "}
              {new Date(deadLine).toLocaleDateString("fa-IR", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </span>
          )}
        </div>
      </div>
    </Link>
  );
};

export default CartableNotifactionBox;
