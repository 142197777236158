import React, { useContext, useEffect, useState } from "react";
import FormApprovals from "./FormApprovals";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import API from "../../../../../api/API";
import ApprovalSingle from "./ApprovalSingle";

const MainApprovals = ({
  idMeeting,
  thisApproval,
  output,
  setRefreshFullData,
  setApprovals,
  setFilesMeeting,
  setPresent,
}) => {
  const { token } = useContext(AuthContext);
  const [members, setMembers] = useState([-1]);
  const [showApprovalForm, setShowApprovalForm] = useState(false);
  console.log(thisApproval);

  let url = "/api/v1/organization/role/placement/";
  useEffect(() => {
    const getMembers = () => {
      API.get(url, {
        headers: {
          Authorization: token,
        },
      })
        .then(async (res) => {
          await setMembers(res.data);
          // console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getMembers();
  }, [token, url]);

  const presentHandler = async () => {
    await API.post(
      `/api/v1/meeting/info/detail/${idMeeting}/status/`,
      {
        status: "finaldoc",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await setApprovals(false);
        await setFilesMeeting(true);
        await setRefreshFullData(1);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataHandlerBack = async () => {
    // await dataHandler();
    await API.post(
      `/api/v1/meeting/info/detail/${idMeeting}/status/`,
      {
        status: "present",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await setApprovals(false);
        await setPresent(true);
        await setRefreshFullData(1);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="w-full flex flex-col gap-2">
      <button
        onClick={() => setShowApprovalForm(true)}
        className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md w-fit"
      >
        اضافه کردن
      </button>
      {showApprovalForm && (
        <FormApprovals
          setRefreshFullData={setRefreshFullData}
          members={members}
          idMeeting={idMeeting}
          thisApproval={thisApproval}
          outputList={output}
          setShowApprovalForm={setShowApprovalForm}
        />
      )}
      <div className="p-0.5 max-h-[50vh] overflow-y-scroll">
        {thisApproval?.length > 0 ? (
          <table className="rounded-md overflow-hidden outline outline-1 outline-caspian-gray1 min-w-full">
            <thead className="table-fixed w-full text-xs text-caspian-gray text-right">
              <tr className="bg-caspian-gray3 w-full">
                <th className="p-2">عنوان</th>
                <th className="p-2">اجرا کننده</th>
                <th className="p-2">پیگیری کننده</th>
                <th className="p-2">خروجی</th>
                <th className="p-2">مهلت</th>
                <th className="p-2 w-20"></th>
              </tr>
            </thead>
            <tbody className="w-full text-xs text-caspian-gray4 text-right">
              {thisApproval.map((d, i) => (
                <ApprovalSingle
                  setRefreshFullData={setRefreshFullData}
                  key={i}
                  id={d.id}
                  title={d.title}
                  runner={
                    d?.responsibleExecutive?.firstName +
                    " " +
                    d?.responsibleExecutive?.lastName
                  }
                  follower={
                    d?.responsibleFollow?.firstName +
                    " " +
                    d?.responsibleFollow?.lastName
                  }
                  output={d?.outputRelated?.title}
                  deadline={d?.deadLine}
                />
              ))}
            </tbody>
          </table>
        ) : (
          <h2 className="text-xs text-caspian-gray1">
            مصوبه ای اضافه نشده است!
          </h2>
        )}
      </div>
      <div className="flex items-center gap-2">
        <button
          onClick={async () => {
            await dataHandlerBack();
          }}
          className="min-w-[6rem] outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs p-1.5 rounded-md"
        >
          بازگشت
        </button>
        <button
          onClick={async () => {
            await presentHandler();
          }}
          className="min-w-[6rem] outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs p-1.5 rounded-md"
        >
          ثبت
        </button>
      </div>
    </div>
  );
};

export default MainApprovals;
