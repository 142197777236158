import React, { useState } from "react";
import MainListDraftProfileMeeting from "./list-draft-profile-meeting/MainListDraftProfileMeeting";
import { useOutletContext } from "react-router-dom";
import MainDraftMeetingForm from "../../forms/draft-meeting/MainDraftMeetingForm";

const MainDraftProfile = () => {
  const [refresh, setRefresh, allProfileNumber, setAllProfileNumber] =
    useOutletContext();
  const [showDraftMeetingForm, setShowDraftMeetingForm] = useState(false);
  const [draftId, setDraftId] = useState(null);
  const [darftTitle, setDarftTitle] = useState(null);

  return (
    <>
      {showDraftMeetingForm && (
        <MainDraftMeetingForm
          draftId={draftId}
          darftTitle={darftTitle}
          setRefresh={setRefresh}
          setShowDraftMeetingForm={setShowDraftMeetingForm}
        />
      )}
      <MainListDraftProfileMeeting
        refresh={refresh}
        setRefresh={setRefresh}
        allProfileNumber={allProfileNumber}
        setAllProfileNumber={setAllProfileNumber}
        setShowDraftMeetingForm={setShowDraftMeetingForm}
        setDraftId={setDraftId}
        setDarftTitle={setDarftTitle}
      />
    </>
  );
};

export default MainDraftProfile;
