import { useContext, useEffect, useRef, useState } from "react";
import {
    PiCaretDownBold,
    PiCheckSquareBold,
    PiMagnifyingGlass,
    PiMagnifyingGlassBold,
    PiSquare,
    PiX,
} from "react-icons/pi";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import API from "../../../../../api/API";
import Modal from "../../../../modal/Modal";

const ListSelected = ({
    title,
    url,
    setShowListSelected,
    setFinalSelected,
    finalSelected,
    selected,
    setSelected,
    process
}) => {
    const searchRef = useRef();
    const { token } = useContext(AuthContext);
    const [search, setSearch] = useState("");

    const [selectedData, setSelectedData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const getList = async () => {
            if (!token || !url ||!process) return;

            setLoading(true);
            try {
                const res = await API.get(url, {
                    headers: { Authorization: token },
                });
                console.log(res?.data);
                setSelectedData(res?.data?.activityProfileProcessRelated);
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        };

        getList();
    }, [token, url,process]);

    // const finalHandler = (e) => {
    //     if (selected?.length > 0) {
    //         e.stopPropagation()
    //         setFinalSelected(selected);
    //         setShowListSelected(false);
    //     }
    // };
    let serachData = [];
    if (selectedData) {
        serachData = search
            ? selectedData?.filter((s) => s?.title?.includes(search))
            : selectedData;
    }

    // const clearSearch = () => {
    //     searchRef.current.value = "";
    //     setSearch("");
    // };

    return (

        <div 
        
        className="relative w-full"
        >
            <div
                onClick={() => {
                    if(process){
                        setOpen(!open);
                    }
                }}
                className={`${process?'cursor-pointer  border border-caspian-gray1':'text-caspian-gray2 border border-caspian-gray2 cursor-not-allowed'} rounded-md p-2 relative flex justify-between items-center`}
            >
                {selected.length > 0 ? (
                    <span className="text-xs whitespace-nowrap">
                        {selected?.length} مورد انتخاب شد
                    </span>
                ) : (
                    <span className="text-xs whitespace-nowrap">فعالیتی انتخاب نشد</span>
                )}
                <PiCaretDownBold className={`${!process && 'text-caspian-gray2'} text-caspian-gray text-sm`} />
            </div>

            {
                <div
                    className={
                        open
                            ? "rounded-md shadow-[0_0_5px_#00000050] w-full flex flex-col gap-3 py-3 absolute -translate-y-5 left-0 right-0 top-[58px] bg-caspian-surface z-50"
                            : "hidden"
                    }
                >
                    <div className="relative w-[92%] mx-auto">
                        <input
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                            type="search"
                            className="outline-none outline-caspian-gray1 p-1 text-xs rounded-md outline-1 w-full placeholder:text-xs placeholder:text-caspian-gray pr-6"
                            placeholder="جستجو"
                        />
                        <button className="absolute top-1/2 -translate-y-1/2 right-1">
                            <PiMagnifyingGlassBold className="text-caspian-gray text-" />
                        </button>
                    </div>
                    <div className="flex flex-col gap-2 w-full max-h-[200px] overflow-y-scroll">
                        {loading ? (
                            <div className="flex items-center justify-center w-full h-full py-12">
                                <span className="text-xs text-caspian-gray4">
                                    در حال بارگزاری...
                                </span>
                            </div>
                        ) : serachData?.length < 1 ? (
                            <div className="flex items-center justify-center w-full py-12">
                                <span className="text-xs text-caspian-gray4">
                                    موردی یافت نشد...
                                </span>
                            </div>
                        ) : (
                            serachData?.map((s, i) => (
                                <div
                                    onClick={() => {
                                        !selected?.includes(s?.id)
                                            ? setSelected([...selected, s?.id])
                                            : setSelected(selected?.filter((d) => d !== s?.id));
                                    }}
                                    key={i}
                                    className="flex items-center gap-4 justify-between w-full even:bg-caspian-gray2/30 p-2 cursor-pointer"
                                >
                                    <div className="flex items-center gap-2">
                                        {selected?.includes(s?.id) ? (
                                            <PiCheckSquareBold className="text-md text-caspian-primary-500 w-5" />
                                        ) : (
                                            <PiSquare className="text-md text-caspian-primary-500 w-5" />
                                        )}
                                        <span className="text-xs text-caspian-gray4 line-clamp-2 basis-3/4">{s?.title}</span>
                                    </div>
                                    <span className="text-xs text-caspian-gray4">
                                        {s?.groupCode ? s?.groupCode : s?.id}
                                    </span>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            }
        </div>





        // <Modal>
        //     <div className="flex flex-col gap-0 min-w-[400px] max-w-[400px] z-50 bg-caspian-surface rounded-md shadow-[0_0_5px_#00000050] overflow-hidden">
        //         <div className="flex items-center justify-between w-full p-2 border-b border-b-caspian-gray2">
        //             {/* <span className="text-xs text-caspian-gray font-bold">{title}</span> */}
        //             <div className="flex items-center gap-2 w-1/2 relative">
        //                 {search?.length > 0 && (
        //                     <PiX
        //                         onClick={clearSearch}
        //                         className="absolute top-1/2 -translate-y-1/2 left-2 text-sm text-caspian-gray cursor-pointer"
        //                     />
        //                 )}
        //                 <input
        //                     ref={searchRef}
        //                     onChange={(e) => setSearch(e.target.value)}
        //                     type="search"
        //                     placeholder="جستجو..."
        //                     className="bg-caspian-transparent text-xs outline-none pr-6"
        //                 />
        //                 <PiMagnifyingGlass className="absolute top-1/2 -translate-y-1/2 right-0 text-xl text-caspian-gray" />
        //             </div>
        //             <PiX
        //                 onClick={(e) => {
        //                     e.stopPropagation();
        //                     setShowListSelected(false);
        //                     setSelected(finalSelected);
        //                 }}
        //                 className="cursor-pointer"
        //             />
        //         </div>
        //         <div className="max-h-[360px] min-h-[360px] overflow-y-scroll">
        //             {loading ? (
        //                 <div className="flex items-center justify-center w-full h-full py-12">
        //                     <span className="text-xs text-caspian-gray4">
        //                         در حال بارگزاری...
        //                     </span>
        //                 </div>
        //             ) : serachData?.length < 1 ? (
        //                 <div className="flex items-center justify-center w-full py-12">
        //                     <span className="text-xs text-caspian-gray4">
        //                         موردی یافت نشد...
        //                     </span>
        //                 </div>
        //             ) : (
        //                 serachData?.map((s, i) => (
        //                     <div
        //                         onClick={() => {
        //                             !selected?.includes(s?.id)
        //                                 ? setSelected([...selected, s?.id])
        //                                 : setSelected(selected?.filter((d) => d !== s?.id));
        //                         }}
        //                         key={i}
        //                         className="flex items-center gap-4 justify-between w-full even:bg-caspian-gray2/30 p-2 cursor-pointer"
        //                     >
        //                         <div className="flex items-center gap-2">
        //                             {selected?.includes(s?.id) ? (
        //                                 <PiCheckSquareBold className="text-xl text-caspian-primary-500" />
        //                             ) : (
        //                                 <PiSquare className="text-xl text-caspian-primary-500" />
        //                             )}
        //                             <span className="text-xs text-caspian-gray4">{s?.title}</span>
        //                         </div>
        //                         <span className="text-xs text-caspian-gray4">
        //                             {s?.groupCode ? s?.groupCode : s?.id}
        //                         </span>
        //                     </div>
        //                 ))
        //             )}
        //         </div>
        //         <div className="p-2 border-t border-t-caspian-gray2">
        //             <button
        //                 onClick={(e)=>finalHandler(e)}
        //                 disabled={selected?.length < 1 ? true : false}
        //                 className="bg-caspian-secondary-500 text-xs rounded-md text-caspian-surface py-1.5 px-4 disabled:bg-caspian-gray2 disabled:text-caspian-gray4"
        //             >
        //                 تایید
        //             </button>
        //         </div>
        //     </div>
        // </Modal>
    );
};

export default ListSelected;
