import { useEffect, useState } from "react";
import { PiCaretUp } from "react-icons/pi";

const ScrollToTop = () => {
  const [showTopBtn, setShowTopBtn] = useState("-bottom-20");
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        setShowTopBtn("bottom-10");
      } else {
        setShowTopBtn("-bottom-20");
      }
    });
  }, []);
  const TopHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <PiCaretUp
      onClick={() => TopHandler()}
      className={`${showTopBtn} duration-300 fixed left-5 bg-indigo-500 rounded-md outline outline-caspian-primary-400 outline-1 bg-caspian-surface p-1 text-caspian-primary-400 text-4xl shadow-md z-50 cursor-pointer`}
    />
  );
};

export default ScrollToTop;
