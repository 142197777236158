import { useContext, useEffect, useState } from 'react';
import MainChartCostReport from './chart-cost-report/MainChartCostReport';
import MainListCostReport from './list-cost-report/MainListCostReport';
import API from '../../../../../../api/API';
import { AuthContext } from '../../../../../../context/auth/AuthContext';
import { useParams } from 'react-router-dom';

const MainCostReport = ({ fullData }) => {
    const { token } = useContext(AuthContext);
    const params = useParams()

    const [dataListCost, setDataListCost] = useState([]);



    useEffect(() => {
        if (!token) return;

        const handleListCost = async () => {
            try {
                const res = await API.get(`/api/v1/actionplan/reports/recordCost/${params?.id}`,
                    { headers: { Authorization: token } }
                )
                console.log(res?.data)
                setDataListCost(res?.data)

            } catch (error) {
                console.log(error)
            }
        }
        handleListCost()
    }, [params?.id, token])


    return (
        <div className='flex flex-col items-center w-full'>

            <div className='flex items-start justify-between w-full py-5'>
                <div className='w-1/2'>
                    <MainChartCostReport fullData={fullData} />
                </div>
                <div className='w-1/2'>
                    <MainListCostReport dataListCost={dataListCost} />
                </div>
            </div>

            <div className='flex items-start w-full gap-5 py-5'>
                <span className='whitespace-nowrap text-xs 2xl:text-base text-[#3E615F]'>توضیحات : </span>

                {fullData?.costRelatedreportProfile?.map((item) => (
                    <p className='text-xs font-semibold 2xl:text-sm' key={item?.id}>{item?.description}</p>
                ))}
            </div>
        </div >
    )
}

export default MainCostReport