import React, { useState } from "react";
import MainSystemNotifaction from "./system-notification/MainSystemNotifaction";
import MainCartableNotification from "./cartable-notification/MainCartableNotification";
import MainPublicNotification from "./public-notification/MainPublicNotification";

const TabNotification = ({ loading, data, setOpen, setTab }) => {
  const [systemNotification, setSystemNotification] = useState(false);
  const [cartableNotification, setCartableNotification] = useState(true);
  const [publicNotification, setPublicNotification] = useState(false);

  return (
    <div className="relative">
      <nav>
        <ul className="flex items-center gap-0 relativel">
          <li
            onClick={() => {
              setSystemNotification(false);
              setCartableNotification(true);
              setPublicNotification(false);
              setTab("cartable");
            }}
            className={`${
              cartableNotification
                ? "rounded-t-none bg-caspian-gray3/50 border-l border-caspian-gray2"
                : "bg-caspian-transparent border-b border-caspian-gray2"
            } text-xs cursor-pointer p-2 h-9 w-28 flex items-center justify-center relative`}
          >
            کارتابلی
          </li>
          <li
            onClick={() => {
              setSystemNotification(true);
              setCartableNotification(false);
              setPublicNotification(false);
              setTab("system");
            }}
            className={`${
              systemNotification
                ? "bg-caspian-gray3/50 border-r border-l border-caspian-gray2"
                : "bg-caspian-transparent border-b border-caspian-gray2"
            } text-xs cursor-pointer p-2 h-9 w-28 flex items-center justify-center relative`}
          >
            سیستمی
          </li>

          <li
            onClick={() => {
              setSystemNotification(false);
              setCartableNotification(false);
              setPublicNotification(true);
              setTab("info");
            }}
            className={`${
              publicNotification
                ? "rounded-tr-none bg-caspian-gray3/50 border-r border-caspian-gray2"
                : "bg-caspian-transparent border-b border-caspian-gray2"
            } text-xs cursor-pointer p-2 h-9 w-28 flex items-center justify-center relative`}
          >
            اطلاع رسانی
          </li>
        </ul>
      </nav>
      <div className="bg-caspian-gray3/50">
        {systemNotification ? (
          <MainSystemNotifaction data={data} setOpen={setOpen} />
        ) : cartableNotification ? (
          <MainCartableNotification data={data} setOpen={setOpen} />
        ) : publicNotification ? (
          <MainPublicNotification data={[]} setOpen={setOpen} />
        ) : (
          <MainSystemNotifaction />
        )}
      </div>
    </div>
  );
};

export default TabNotification;
