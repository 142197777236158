import React, { useContext, useEffect, useState } from "react";
import TabStepThree from "./tab-step-three/TabStepThree";
import { AuthContext } from "../../../../../../../../context/auth/AuthContext";
import API from "../../../../../../../../api/API";
import { toast } from "react-toastify";

const MainStepThree = ({
  setActiveTab,
  actionplanIdRelated,
  setShowCreateOfficeForm,
}) => {
  const { token } = useContext(AuthContext);
  const [refresh, setRefresh] = useState(0);
  const [description, setDescription] = useState("");
  const [isDisable, setIsDisable] = useState(true);
  const [actionPlanData, setActionPlanData] = useState([]);
  const [processSelected, setProcessSelected] = useState([]);
  const [processFinalSelected, setProcessFinalSelected] =
    useState(processSelected);
  const [goalSelected, setGoalSelected] = useState([]);
  const [goalFinalSelected, setGoalFinalSelected] = useState(goalSelected);
  const [stackholderList, setStackholderList] = useState([]);
  const [thisId, setThisId] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!token || !actionplanIdRelated) return;
    const getActionPlanData = async () => {
      setLoading(true);
      try {
        const res = await API.get(
          `/api/v1/actionplan/goals/actionplan/${actionplanIdRelated}`,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res.data);
        setThisId(res.data[0]?.id);
        setActionPlanData(res?.data[0]);
        setDescription(res.data[0]?.outputProject);
        if (res?.data?.length !== 0) {
          setProcessSelected(res.data[0]?.processes);
          setProcessFinalSelected(res.data[0]?.processes);
          setGoalSelected(res.data[0]?.goal);
          setGoalFinalSelected(res.data[0]?.goal);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getActionPlanData();
  }, [token, actionplanIdRelated]);
  useEffect(() => {
    setRefresh(1);
  }, []);
  useEffect(() => {
    if (!token || !actionplanIdRelated || refresh === 0) return;
    const getActionPlanStackholder = async () => {
      try {
        const res = await API.get(
          `/api/v1/actionplan/stackholders/actionplan/${actionplanIdRelated}`,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data);
        setStackholderList(res?.data);
        setRefresh(0);
      } catch (err) {
        console.log(err);
      }
    };
    getActionPlanStackholder();
  }, [token, actionplanIdRelated, refresh]);

  // useEffect(() => {
  //   if (actionplanIdRelated) {
  //     console.log(actionplanIdRelated);
  //   }
  // }, [actionplanIdRelated]);

  const submitHandler = async () => {
    // if (!description) return;
    if (
      actionPlanData &&
      actionPlanData?.outputProject === description &&
      actionPlanData?.processes === processSelected &&
      actionPlanData?.goal === goalSelected
    ) {
      console.log("none");
      setActiveTab("StepFour");
    } else if (
      actionPlanData &&
      (actionPlanData?.outputProject !== description ||
        actionPlanData?.processes !== processSelected ||
        actionPlanData?.goal !== goalSelected)
    ) {
      try {
        let formData = {
          outputProject: description,
          id: actionplanIdRelated,
          goal: goalFinalSelected,
          processes: processFinalSelected,
        };
        if (!thisId) return;
        const res = await API.patch(
          `/api/v1/actionplan/ActionPlanGoal/${thisId}/`,
          formData,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data);
        console.log("patch");
        setActiveTab("StepFour");
      } catch (err) {
        console.log(err);
        if (err?.message === "Network Error") {
          toast.error("اتصال با سرور برقرار نشد", {
            className: "toast-faild",
            bodyClassName: "toast-faild",
          });
        } else if (err?.response?.data?.goal[0]) {
          toast.error(err?.response?.data?.goal[0], {
            className: "toast-faild",
            bodyClassName: "toast-faild",
          });
        } else {
          toast.error("مشکل ناشناخته ای به وجود آمد.", {
            className: "toast-faild",
            bodyClassName: "toast-faild",
          });
        }
      }
    } else {
      try {
        let formData = {
          outputProject: description,
          actionplanRelated: actionplanIdRelated,
          goal: goalFinalSelected,
          processes: processFinalSelected,
        };
        const res = await API.post(
          `/api/v1/actionplan/ActionPlanGoal/`,
          formData,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data);
        console.log("post");
        setActiveTab("StepFour");
      } catch (err) {
        console.log(err);
        if (err?.message === "Network Error") {
          toast.error("اتصال با سرور برقرار نشد", {
            className: "toast-faild",
            bodyClassName: "toast-faild",
          });
        } else if (err?.response?.data?.goal[0]) {
          toast.error(err?.response?.data?.goal[0], {
            className: "toast-faild",
            bodyClassName: "toast-faild",
          });
        } else {
          toast.error("مشکل ناشناخته ای به وجود آمد.", {
            className: "toast-faild",
            bodyClassName: "toast-faild",
          });
        }
      }
    }
  };
  return (
    <div className="flex flex-col justify-between h-full gap-4 p-2 min-h-[370px]">
      <TabStepThree
        loading={loading}
        actionplanIdRelated={actionplanIdRelated}
        description={description}
        setDescription={setDescription}
        processFinalSelected={processFinalSelected}
        setProcessFinalSelected={setProcessFinalSelected}
        processSelected={processSelected}
        setProcessSelected={setProcessSelected}
        goalFinalSelected={goalFinalSelected}
        setGoalFinalSelected={setGoalFinalSelected}
        goalSelected={goalSelected}
        setGoalSelected={setGoalSelected}
        stackholderList={stackholderList}
        setRefresh={setRefresh}
        setIsDisable={setIsDisable}
      />
      <div className="flex items-center gap-2">
        <button
          onClick={async () => {
            await setActiveTab("StepTwo");
          }}
          className="min-w-[6rem] outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs p-1.5 rounded-md"
        >
          مرحله قبل
        </button>
        <button
          disabled={isDisable ? true : false}
          onClick={submitHandler}
          className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
        >
          مرحله بعد
        </button>
        {/* <button
          onClick={async () => {
            await setShowCreateOfficeForm(false);
          }}
          className={`disabled:cursor-not-allowed disabled:text-caspian-gray text-caspian-secondary-500 text-xs p-1.5 rounded-md`}
        >
          ذخیره پیشنویس
        </button> */}
      </div>
    </div>
  );
};

export default MainStepThree;
