import React, { useContext, useRef, useState } from "react";
import Draggable from "react-draggable";
import { PiCircleNotch, PiFileTextFill, PiPlus, PiX } from "react-icons/pi";
import API from "../../../api/API";
import { AuthContext } from "../../../context/auth/AuthContext";
import { toast } from "react-toastify";

export const isEmpty = (value) => {
  if (!value) return true;
  return false;
};

const initialState = {
  reciver: "",
  text: "",
  document: null,
};

const ReplyForm = ({
  showReplyForm,
  setShowReplyForm,
  reciver,
  activityId,
  setSuccessReply,
}) => {
  const titleRef = useRef();
  const textRef = useRef();
  const fileRef = useRef(null);
  const { token } = useContext(AuthContext);
  const [data, setData] = useState(initialState);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const { text } = data;
  const [errorText, setErrorText] = useState(false);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  // console.log(allUser);
  const submitHandler = async (e) => {
    e.preventDefault();
    if (isEmpty(text)) {
      return setErrorText(true);
    } else {
      setErrorText(false);
    }

    let formData = {
      content_type: null,
      text: text,
      status: "done",
      document: file ? file : null,
    };
    setLoading(true);
    try {
      const res = await API.patch(
        `/api/v1/request/activity/${activityId}/`,
        formData,
        {
          headers: {
            Authorization: token,
            "content-type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      );
      console.log(res?.data);
      setShowReplyForm(false);
      toast.success("پاسخ به گزارش با موفقیت ارسال شد.", {
        className: "toast-success",
        bodyClassName: "toast-success",
      });
      setSuccessReply(1);
    } catch (error) {
      console.log(error);
      toast.error("متاسفانه مشکلی به وجود آمد!", {
        className: "toast-faild",
        bodyClassName: "toast-faild",
      });
    } finally {
      setLoading(false);
    }
  };

  const clearHandler = (e) => {
    e.preventDefault();
    setShowReplyForm(false);
  };

  return (
    <>
      {showReplyForm && (
        <Draggable handle="strong">
          <div className="bg-caspian-surface rounded-md shadow-[0_0_5px_#00000050] w-[400px] fixed bottom-2 left-16 z-50">
            <strong className="flex items-center justify-between cursor-move p-4">
              <span className="text-sm">پاسخ</span>
              <button
                disabled={loading ? true : false}
                className="disabled:cursor-not-allowed disabled:text-caspian-gray"
              >
                <PiX onClick={clearHandler} className="text-xl" />
              </button>
            </strong>
            <form
              onSubmit={submitHandler}
              className="flex flex-col gap-3 p-3 pt-0"
            >
              {/* شروع گیرنده گزارش */}
              <div className="relative flex flex-col gap-2">
                <label className="text-xs font-bold text-caspian-on_primary/50">
                  گیرنده
                </label>
                <div className="relative">
                  <input
                    ref={titleRef}
                    disabled
                    placeholder=""
                    defaultValue={reciver}
                    name="reciver"
                    type="text"
                    onChange={handleChange}
                    className="p-1.5 text-sm rounded-md outline outline-1 outline-caspian-gray2 w-full"
                  />
                </div>
              </div>
              {/* پایان گیرنده گزارش */}
              {/* شروع شرح گزارش */}
              <div className="relative flex flex-col gap-2">
                <label className="text-xs font-bold text-caspian-on_primary/50">
                  شرح گزارش
                </label>
                <textarea
                  ref={textRef}
                  name="text"
                  placeholder="توضیحات خود را وارد کنید"
                  rows={10}
                  onChange={handleChange}
                  className="resize-none p-1.5 text-sm rounded-md outline outline-1 outline-caspian-gray2 w-full"
                />
                {errorText && (
                  <span className="text-caspian-error text-xs absolute left-2 top-8">
                    توضیحات گزارش را وارد کنید
                  </span>
                )}
              </div>
              {/* پایان شرح گزارش */}
              {/* شروع آپلود فایل */}
              <div className="relative flex flex-col gap-2">
                <label
                  htmlFor="fileData"
                  className="flex items-center justify-between gap-2 py-2 border-b border-b-caspian-gray2 w-full"
                >
                  <input
                    type="file"
                    ref={fileRef}
                    name="file"
                    id="fileData"
                    className="hidden"
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                      if (file) {
                        setFile(file);
                      }
                      e.preventDefault();
                    }}
                  />
                  <div className="flex flex-col items-center gap-4 w-full">
                    <div className="flex items-center justify-between w-full cursor-pointer">
                      <span className="text-sm text-caspian-secondary-600 whitespace-nowrap flex items-center gap-1">
                        فایل خود را اینجا بارگذاری کنید
                      </span>
                      <PiPlus className="text-caspian-secondary-600" />
                    </div>
                  </div>
                </label>
                {file !== undefined && file !== null && file.name && (
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center gap-4">
                      <h2 className="text-sm text-caspian-gray">{file.name}</h2>
                      <PiFileTextFill className="text-caspian-secondary-500 text-xl" />
                    </div>
                    <button type="button">
                      <PiX
                        className="cursor-pointer text-caspian-error"
                        onClick={() => {
                          setFile(undefined);
                          fileRef.current.value = "";
                        }}
                      />
                    </button>
                  </div>
                )}
              </div>

              {/* پایان آپلود فایل */}
              <div className="flex items-center gap-2">
                <button
                  disabled={loading ? true : false}
                  className={`disabled:cursor-not-allowed flex items-center gap-2 outline outline-1 outline-caspian-secondary-500 bg-caspian-secondary-500 text-caspian-surface py-1.5 px-3 rounded-md text-sm`}
                >
                  ارسال پاسخ
                  {loading && (
                    <PiCircleNotch className="text-caspian-surface text-lg animate-spin" />
                  )}
                </button>
                <button
                  disabled={loading ? true : false}
                  type="button"
                  onClick={clearHandler}
                  className="disabled:cursor-not-allowed disabled:outline-caspian-gray disabled:text-caspian-gray outline outline-1 
                outline-caspian-error text-caspian-error py-1.5 px-6 rounded-md text-sm"
                >
                  لغو
                </button>
              </div>
            </form>
          </div>
        </Draggable>
      )}
    </>
  );
};

export default ReplyForm;
