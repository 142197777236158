import React, { useState } from "react";
import { PiPlus } from "react-icons/pi";
import AddProcessActivitiesForm from "./AddProcessActivitiesForm";
const MainAddProcessActivities = ({
  scheduleTimeId,
  processSelected,
  setProcessSelected,
  activitiesSelected,
  setActivitiesSelected,
  activitiesFinalSelected,
  setActivitiesFinalSelected,
  setRefreshPL,
}) => {
  const [showAddActivites, setShowAddActivites] = useState(false);

  return (
    <div className="flex items-center gap-4 w-full">
      <button
        onClick={() => setShowAddActivites(true)}
        className="flex items-center gap-1 w-full text-caspian-secondary-500 py-2 cursor-pointer hover:bg-caspian-secondary-50/20"
      >
        <span className="text-xs 2xl:text-sm">
          افزودن فرایند و فعالیت های مرتبط
        </span>
        <PiPlus className="text-sm 2xl:text-lg" />
      </button>

      {showAddActivites && (
        <AddProcessActivitiesForm
          scheduleTimeId={scheduleTimeId}
          processSelected={processSelected}
          setProcessSelected={setProcessSelected}
          activitiesSelected={activitiesSelected}
          setActivitiesSelected={setActivitiesSelected}
          activitiesFinalSelected={activitiesFinalSelected}
          setActivitiesFinalSelected={setActivitiesFinalSelected}
          setShowAddActivites={setShowAddActivites}
          setRefreshPL={setRefreshPL}
        />
      )}
    </div>
  );
};

export default MainAddProcessActivities;
