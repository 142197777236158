import React from 'react';

const Skill = ({positionSkill}) => {
    return (
        <ul className='list-disc list-inside pr-10 text-xs xl:text-sm'>
            {
                positionSkill?.map(item=><li key={item?.id} className='py-3'>
                    <span>{item.title} : </span> <span>{item?.describtion}</span>
                </li>)
            }
            {/* <li className='py-3'>لورم ایپسوم</li>
            <li className='py-3'>لورم ایپسوم</li> */}
        </ul>
    );
}

export default Skill;
