import React, { PureComponent } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, } from 'recharts';
// import { BsGear } from "react-icons/bs";
import { LuFolderPlus } from "react-icons/lu";
import { PiFlowArrow } from "react-icons/pi";
import { PiChartPieSlice } from "react-icons/pi";
import { PiFileMagnifyingGlassThin } from "react-icons/pi";
const MainHomePage = ({ showMenu }) => {

  const data = [
    {
      subject: "4-4",
      A: 70,
      B: 70,
      fullMark: 20
    },
    {
      subject: "6-1",
      A: 55,
      B: 55,
      fullMark: 40
    },
    {
      subject: "6-2",
      A: 62,
      B: 62,
      fullMark: 60
    },
    {
      subject: "7-5",
      A: 80,
      B: 80,
      fullMark: 80
    },
    {
      subject: "8-1",
      A: 54,
      B: 54,
      fullMark: 100
    },
    {
      subject: "8-4",
      A: 65,
      B: 65,
      fullMark: 120
    },


  ];
  return (
    <div className="min-h-screen bg-caspian-background flex flex-col gap-0 w-full">
      {/* بخش بالایی */}
      <div className=" flex items-start justify-stretch w-full pt-8 px-5">
        {/* بخش راست */}
        <div className="bg-[#F2F2F2] shadow-[0_0_5px_#00000050] rounded-lg w-3/4 h-full min-h-[70vh] flex items-start justify-center py-10 px-5">
          <div className="flex h-full w-full gap-0 items-start">
            {/* بخش راست عکس */}
            <div className="flex flex-col h-full gap-4 w-1/3 p-3">
              {/* باکس اول */}
              <div className=" shadow-[0_0_5px_#00000050] min-h-[61vh] flex flex-col items-start py-2 gap-2  px-3 h-full rounded-lg bg-caspian-surface" >
                <span className="text-lg font-bold border-b-2 py-2">کارهای ارجاع شده</span>
                <div className="flex  flex-row justify-between items-center gap-2 w-full cursor-pointer text-caspian-graygreen-700  hover:text-caspian-green-700">
                  <span className="text-sm font-normal py-1 ">تایید مصوبات جلسه ممیزی نوبت اول</span>
                  <span className="text-sm font-normal text-center "> 1 روز پیش</span>
                </div>
                <div className="flex  flex-row justify-around items-center gap-2 w-full cursor-pointer text-caspian-graygreen-700  hover:text-caspian-green-700">
                  <span className="text-sm font-normal py-1 ">برنامه ریزی جلسه بازنگری و مدیریتی</span>
                  <span className="text-sm font-normal text-center "> 2 روز پیش</span>
                </div>
                <div className="flex  flex-row justify-between items-center gap-2 w-full cursor-pointer text-caspian-graygreen-700  hover:text-caspian-green-700">
                  <span className="text-sm font-normal py-1 ">پیگیری اقدام اصلاحی مدیریت زیرساخت</span>
                  <span className="text-sm font-normal text-center "> 3 روز پیش</span>
                </div>
                <div className="flex  flex-row justify-between items-center gap-2 w-full cursor-pointer text-caspian-graygreen-700  hover:text-caspian-green-700">
                  <span className="text-sm font-normal py-1 ">تایید گزارش ممیزی</span>
                  <span className="text-sm font-normal text-center ">2/6/1403</span>
                </div>

              </div>
            </div>
            {/* عکس وسط */}
            <div className="flex flex-col gap-4 w-2/3 p-3 " >
              <div className="shadow-[0_0_5px_#00000050] rounded-lg text-center w-full">
                <img src="/assets/images/home/bg.png" className="rounded-lg overflow-hidden" alt="bg" />
              </div>
              {/* بخش زیر عکس */}
              <div className="flex flex-row gap-4">
                <div className=" shadow-[0_0_5px_#00000050] w-2/3 flex flex-col items-center gap-2  px-3  rounded-lg bg-caspian-surface" >
                  <div className="flex py-2">
                    <span>کل ریسک های شناسایی شده <span className="text-sm font-bold">54 مورد</span></span>
                  </div>

                  <div className="flex flex-row items-center  w-full">
                    <div className="flex flex-col w-1/4 gap-y-2">
                      <span className="text-center text-sm">برطرف شده</span>
                      <span className="box-border whitespace-pre h-full bg-caspian-error w-full text-center text-caspian-background">17 مورد</span>
                    </div>
                    <div className="flex flex-col w-1/2 gap-y-2">
                      <span className="text-center text-sm">در حال بررسی </span>
                      <span className="box-border whitespace-pre h-full bg-caspian-primary-950 w-full text-center text-caspian-graygreen-700">24 مورد</span>
                    </div>
                    <div className="flex flex-col w-1/4 gap-y-2">
                      <span className="text-center text-sm">باقی مانده</span>
                      <span className="box-border whitespace-pre h-full bg-[#01BC8D] w-full text-center text-caspian-background">13 مورد</span>
                    </div>
                  </div>

                </div>
                <div className=" shadow-[0_0_5px_#00000050] w-1/3 flex items-center gap-2  px-3  rounded-lg bg-caspian-surface" >
                  <div className="flex flex-col w-2/3 h-[7rem] gap-2 justify-center items-center ">
                    <span className="text-sm font-bold">مورد اول</span>
                    <div className="bg-caspian-primary-300 w-full rounded-full text-center py-2">
                      <span className="text-sm font-normal text-caspian-primary-50 cursor-pointer">قابل قبول</span>
                    </div>


                  </div>
                  <div className="flex flex-col w-1/3 h-[7rem] gap-2 justify-center items-center">
                    <span className="text-sm text-caspian-gray font-normal">مورد اول</span>
                    <span className="text-2xl font-bold py-1">61%</span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        {/* بخش چپ */}
        <div className=" w-1/4 h-full min-h-[70vh] flex flex-col items-center justify-center">
          {/* نمودار رادار */}
          <div className="w-full flex flex-row py-5 justify-around">
            {/* <div className="text-2xl shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.1)] curs p-2 rounded-full cursor-pointer "><BsGear /></div> */}
            <div class="relative inline-block text-left">
              <div>
                <a href="#option" class="text-caspian-primary-600 inline-flex w-full justify-center gap-x-1.5 rounded-md  px-3 py-2 text-sm font-semibold text-gray-900" >
                  بررسی کلی
                  <svg class="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                  </svg>
                </a>
              </div>


            </div>
          </div>
          <div className="w-full ">
            <RadarChart
              cx="45%"
              cy="50%"
              outerRadius={100}
              width={250}
              height={250}
              data={data}
            >
              <PolarGrid />
              <PolarAngleAxis dataKey="subject" />
              <PolarRadiusAxis />
              <Radar
                name="Mike"
                dataKey="A"
                stroke="#1F6200"
                fill="#2BFF4D"
                fillOpacity={0.4}
              />
            </RadarChart>
          </div>
          {/* زیر نمودار رادار */}
          <div className="flex flex-col justify-center items-center gap-2 w-full p-3">
            <div className="flex  flex-row justify-around items-center gap-2 w-full">
              <span className="bg-[#08B86B] py-1  px-2 text-caspian-background  rounded-2xl">73%</span>
              <div className="border w-1/2 border-dotted px-2  border-caspian-gray"></div>
              <span className="text-sm text-caspian-gray font-normal text-center w-1/4"> مورد 4-4 </span>
            </div>
            <div className="flex  flex-row justify-around items-center gap-2 w-full">
              <span className="bg-[#FFCE27] py-1  px-2 text-caspian-background  rounded-2xl">53%</span>
              <div className="border w-1/2 border-dotted px-2  border-caspian-gray"></div>
              <span className="text-sm text-caspian-gray font-normal text-center w-1/4"> مورد 1-6 </span>
            </div>
            <div className="flex  flex-row justify-around items-center gap-2 w-full">
              <span className="bg-[#FF914B] py-1  px-2 text-caspian-background  rounded-2xl">22%</span>
              <div className="border w-1/2 border-dotted px-2  border-caspian-gray"></div>
              <span className="text-sm text-caspian-gray font-normal text-center w-1/4"> مورد 2-6 </span>
            </div>
            <div className="flex  flex-row justify-around items-center gap-2 w-full">
              <span className="bg-[#FFCF26] py-1  px-2 text-caspian-background  rounded-2xl">48%</span>
              <div className="border w-1/2 border-dotted px-2  border-caspian-gray"></div>
              <span className="text-sm text-caspian-gray font-normal text-center w-1/4"> مورد 5-7 </span>
            </div>
            <div className="flex  flex-row justify-around items-center gap-2 w-full">
              <span className="bg-[#08B86B] py-1  px-2 text-caspian-background  rounded-2xl">78%</span>
              <div className="border w-1/2 border-dotted px-2  border-caspian-gray"></div>
              <span className="text-sm text-caspian-gray font-normal text-center w-1/4"> مورد 1-8 </span>
            </div>
            <div className="flex  flex-row justify-around items-center gap-2 w-full">
              <span className="bg-[#08B86B] py-1  px-2 text-caspian-background  rounded-2xl">81%</span>
              <div className="border w-1/2 border-dotted px-2  border-caspian-gray"></div>
              <span className="text-sm text-caspian-gray font-normal text-center w-1/4"> مورد 4-8 </span>
            </div>

          </div>
        </div>

      </div>
      {/* بخش پایین */}
      <div className="flex items-start w-full py-5">
        <div className=" w-full h-full min-h-[30vh] flex items-start justify-center">
          <div className="flex flex-row justify-between items-start  w-3/4">
            {/* نوار پایین سمت راست
            <div className="flex flex-col w-1/2 px-5">
              <div className="flex flex-row justify-between items-start">
                <div className="text-2xl shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.1)] cursor-pointer p-2 rounded-full "><LuFolderPlus /></div>
                <div class="relative inline-block text-left">
                  <div>
                    <a href="#option" class="text-caspian-primary-600  border border-caspian-graygreen-400 inline-flex w-full justify-center gap-x-1.5 rounded-md  px-3 py-2 text-sm font-semibold text-gray-900" >
                      ارزیابی
                      <svg class="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex px-4 pt-20">
                <span>کل موارد ارزیابی شده<span className="text-sm font-bold">17 مورد</span></span>
              </div>
              <div className="flex flex-row items-center py-4 w-full">
                <div className="flex flex-col w-2/6 gap-y-2">
                  <span className="text-center text-sm">مورد سوم </span>
                  <span className="box-border whitespace-pre h-full bg-[#E0E0E0] w-full text-center text-caspian-graygreen-700 rounded-xl">50%</span>
                </div>
                <div className="flex flex-col w-1/6 gap-y-2">
                  <span className="text-center text-sm">مورد دوم</span>
                  <span className="box-border whitespace-pre h-full bg-[#FF812E] w-full text-center rounded-xl">17%</span>
                </div>
                <div className="flex flex-col w-3/6 gap-y-2">
                  <span className="text-center text-sm">مورد اول</span>
                  <span className="box-border whitespace-pre h-full bg-[#73AEAF] w-full text-center text-caspian-background rounded-xl">37%</span>
                </div>
              </div>
            </div> */}
            {/* بخش وسط صفحه پایین */}
            <div className="flex flex-col w-full px-5">
              {/* <div className="flex flex-row justify-between items-start">
                <div className="text-2xl shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.1)] cursor-pointer p-2 rounded-full "><LuFolderPlus /></div>
                <div class="relative inline-block text-left">
                  <div>
                    <a href="#option" class="text-caspian-primary-600  border border-caspian-graygreen-400  inline-flex w-full justify-center gap-x-1.5 rounded-md  px-3 py-2 text-sm font-semibold text-gray-900" >
                      گزارش ها
                      <svg class="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div> */}
              <div className="flex flex-row justify-around items-center py-16">
                <div className="flex py-4 flex-col">
                  <div className="bg-[#01BC8D] p-1 rounded-full">
                    <div className="bg-caspian-surface p-2  rounded-full">
                      <div className="bg-[#ECECEC] p-5 text-4xl rounded-full">
                        <PiFlowArrow />
                      </div>
                    </div>
                  </div>
                  <span className="text-sm font-normal text-caspian-gray4 py-1 text-center">مورد اول</span>
                </div>
                <div className="flex py-4 flex-col">
                  <div className="bg-[#01BC8D] p-1 rounded-full">
                    <div className="bg-caspian-surface p-2  rounded-full">
                      <div className="bg-[#ECECEC] p-5 text-4xl rounded-full">
                        <PiChartPieSlice />
                      </div>
                    </div>
                  </div>
                  <span className="text-sm font-normal text-caspian-gray4 py-1 text-center">مورد دوم</span>
                </div>
                <div className="flex py-4 flex-col">
                  <div className="bg-[#01BC8D] p-1 rounded-full">
                    <div className="bg-caspian-surface p-2  rounded-full">
                      <div className="bg-[#ECECEC] p-5 text-4xl rounded-full">
                        <PiFileMagnifyingGlassThin />
                      </div>
                    </div>
                  </div>
                  <span className="text-sm font-normal text-caspian-gray4 py-1 text-center">مورد سوم</span>
                </div>
                <div className="flex py-4 flex-col">
                  <div className="bg-[#01BC8D] p-1 rounded-full">
                    <div className="bg-caspian-surface p-2  rounded-full">
                      <div className="bg-[#ECECEC] p-5 text-4xl rounded-full">
                        <PiFlowArrow />
                      </div>
                    </div>
                  </div>
                  <span className="text-sm font-normal text-caspian-gray4 py-1 text-center">مورد اول</span>
                </div>
                <div className="flex py-4 flex-col">
                  <div className="bg-[#01BC8D] p-1 rounded-full">
                    <div className="bg-caspian-surface p-2  rounded-full">
                      <div className="bg-[#ECECEC] p-5 text-4xl rounded-full">
                        <PiChartPieSlice />
                      </div>
                    </div>
                  </div>
                  <span className="text-sm font-normal text-caspian-gray4 py-1 text-center">مورد دوم</span>
                </div>
                <div className="flex py-4 flex-col">
                  <div className="bg-[#01BC8D] p-1 rounded-full">
                    <div className="bg-caspian-surface p-2  rounded-full">
                      <div className="bg-[#ECECEC] p-5 text-4xl rounded-full">
                        <PiFileMagnifyingGlassThin />
                      </div>
                    </div>
                  </div>
                  <span className="text-sm font-normal text-caspian-gray4 py-1 text-center">مورد سوم</span>
                </div>
              </div>
            </div>
          </div>
          {/* بخش پایین چپ مقاله */}
          <div className="px-5 w-1/4">
            <div className="p-5 cursor-pointer shadow-[0_0_5px_#00000050] flex flex-col items-start h-full rounded-3xl bg-[#73AEAF]" >
              <img src="/assets/images/home/bg2.png" className=" rounded-3xl pb-3 overflow-hidden" alt="bg" />
              <span className="text-center rounded-xl text-caspian-surface text-sm py-1 px-2 border border-caspian-darkgray-300">سیستم های مدیریت کیفیت _ مبانی و واژگان</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainHomePage;
