import { useState } from 'react';
import AllNeeds from './allneeds/AllNeeds';
import StackholderTab from '../leftcomponent/StackholderTab';

const BottomProfile = ({ data }) => {
    return (
        <div className='bg-caspian-surface border border-caspian-gray2 rounded-md py-5 px-12 flex flex-col gap-y-3'>
            <h6>نیازها و انتظارات</h6>
            <div className='p-3'>
                <AllNeeds allNeeds={data?.allNeeds} />
            </div>
        </div>
    );
}

export default BottomProfile;
