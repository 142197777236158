import React, { useContext } from "react";
import { PiFileTextFill, PiXCircle } from "react-icons/pi";
import { AuthContext } from "../../../../../../context/auth/AuthContext";
import API from "../../../../../../api/API";

const FileConfigOldSingle = ({ data, setRefresh, setOldFileId, oldFileId }) => {
  const { token } = useContext(AuthContext);

  const deleteFile = async () => {
    await API.delete(`/api/v1/meeting/info/document/${data.id}/`, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        setOldFileId(oldFileId.filter((d) => d !== data.id));
        setRefresh(1);
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="inline-block border border-caspian-gray2 p-1.5 rounded-md w-fit">
      <div className="flex items-center gap-2">
        {/* <span className="text-caspian-gray2 text-xs">{data.toFixed()}KB</span> */}
        <div className="flex items-center gap-0">
          <span className="text-caspian-gray text-xs">
            {data.document.split("/").pop()}
          </span>
          <PiFileTextFill className="text-xl text-caspian-secondary-600" />
        </div>
        <PiXCircle
          onClick={() => deleteFile()}
          className="text-[#FF5B5B] text-xl cursor-pointer"
        />
      </div>
    </div>
  );
};

export default FileConfigOldSingle;
