import { PiCalendar, PiFolderOpen } from "react-icons/pi";
import FormNote from "./FormNote";
import { useState } from "react";

const AddNote = ({
  setRefresh,
  notebookData,
  setShowAddNote,
  folderTitle,
  setColor,
  color,
}) => {
  const [notebook, setNotebook] = useState(null);
  return (
    <div className="w-full flex flex-col gap-2 p-2">
      <div className="">
        <div className="flex items-center gap-2 border-b border-b-caspian-on_primary py-2">
          <PiFolderOpen className="text-2xl text-caspian-on_primary" />
          <select
            name="notebook"
            onChange={(e) => setNotebook(e.target.value)}
            defaultValue={null}
            className="text-xs outline-none bg-caspian-transparent cursor-pointer w-full"
          >
            {notebookData?.map((data, i) => (
              <option key={i} value={+data.id}>
                {data.title}
              </option>
            ))}
          </select>
        </div>
        <div className="flex items-center gap-2 border-b border-b-caspian-on_primary py-2">
          <PiCalendar className="text-2xl text-caspian-on_primary" />
          <h2 className="text-caspian-on_primary text-xs">تاریخ ایجاد</h2>
          <span className="text-caspian-on_primary text-xs">
            {new Date().toLocaleDateString("fa-IR")}
          </span>
        </div>
      </div>
      <FormNote
        setRefresh={setRefresh}
        notebook={notebook}
        setShowAddNote={setShowAddNote}
        color={color}
        setColor={setColor}
      />
    </div>
  );
};

export default AddNote;
