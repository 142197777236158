import React, { useState } from "react";
import MemberList from "./MemberList";
import MeetingThisProfile from "./MeetingThisProfile";
import { PiCalendar, PiUsers } from "react-icons/pi";

const TabSideProfile = ({ fullData }) => {
  const [memberList, setMemberList] = useState(true);
  return (
    <div className="flex flex-col gap-0">
      <div className="flex items-center gap-0 w-full bg-caspian-gray3 rounded-t-md">
        <button
          className={`${
            memberList
              ? "border rounded-t-md border-caspian-secondary-500 text-caspian-secondary-500 bg-caspian-surface"
              : "border border-caspian-transparent"
          } flex items-center justify-center gap-1 h-10 w-full`}
          onClick={() => setMemberList(true)}
        >
          <PiUsers className="text-xl" />
          <span className="text-xs">اعضای اصلی جلسه</span>
        </button>
        <button
          className={`${
            !memberList
              ? "border rounded-t-md border-caspian-secondary-500 text-caspian-secondary-500 bg-caspian-surface"
              : "border border-caspian-transparent"
          } flex items-center justify-center gap-1 h-10 w-full`}
          onClick={() => setMemberList(false)}
        >
          <PiCalendar className="text-xl" />
          <span className="text-xs">جلسات این پروفایل</span>
        </button>
      </div>
      {memberList ? <MemberList fullData={fullData} /> : <MeetingThisProfile fullData={fullData} />}
    </div>
  );
};

export default TabSideProfile;
