import React, { useContext } from "react";
import {
  PiBookmarkSimple,
  PiBookmarkSimpleFill,
  PiTrash,
} from "react-icons/pi";
import { AuthContext } from "../../context/auth/AuthContext";
import API from "../../api/API";

const MainNoteContent = ({
  noteId,
  title,
  created_at,
  update_at,
  content,
  color,
  refresh,
  setRefresh,
  fav,
  setShowEditNote,
  showEditNote,
}) => {
  const { token } = useContext(AuthContext);

  const trashHandler = async (id) => {
    API.patch(
      `/api/v1/note/note/${id}/`,
      {
        trash: true,
      },
      {
        headers: { Authorization: token },
      }
    )
      .then((res) => {
        console.log(res.data);
        setRefresh(1);
      })
      .catch((err) => {
        console.log("error");
      });
  };
  const favHandler = async (id) => {
    API.patch(
      `/api/v1/note/note/${id}/`,
      {
        fav: true,
      },
      {
        headers: { Authorization: token },
      }
    )
      .then((res) => {
        console.log(res.data);
        setRefresh(1);
      })
      .catch((err) => {
        console.log("error");
      });
  };
  const unfavHandler = async (id) => {
    API.patch(
      `/api/v1/note/note/${id}/`,
      {
        fav: false,
      },
      {
        headers: { Authorization: token },
      }
    )
      .then((res) => {
        console.log(res.data);
        setRefresh(1);
      })
      .catch((err) => {
        console.log("error");
      });
  };
  return (
    <div
      className={`min-w-[190px] max-w-[190px] min-h-[190px] max-h-[190px] rounded-md shadow-[0_0_5px_#00000050] border border-caspian-gray2 p-2 w-full ${
        color ? `bg-[${color}] bg-opacity-50` : "bg-caspian-surface"
      }`}
    >
      <div className="flex flex-col justify-between w-full h-full">
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-1">
            <h2 className="text-xs font-bold cursor-pointer" onClick={() => {
                !showEditNote.includes(noteId) &&
                  setShowEditNote((n) => [...n, noteId]);
              }}>{title}</h2>
            <span className="text-caspian-gray4 text-xs">
              {new Date(created_at).toLocaleDateString("fa-IR", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </span>
          </div>
          <div className="flex flex-col gap-2 w-full">
            <p
              
              className="text-xs line-clamp-4"
            >
              {content}
            </p>
          </div>
        </div>
        <div className="flex items-center justify-between w-full text-caspian-gray4">
          <div className="flex items-center gap-2">
            {fav ? (
              <PiBookmarkSimpleFill
                onClick={async () => {
                  await unfavHandler(noteId);
                }}
                className="cursor-pointer"
              />
            ) : (
              <PiBookmarkSimple
                onClick={async () => {
                  await favHandler(noteId);
                }}
                className="cursor-pointer"
              />
            )}

            <PiTrash
              onClick={async () => {
                await trashHandler(noteId);
              }}
              className="cursor-pointer"
            />
          </div>
          {/* <PiTimer className="cursor-pointer" /> */}
        </div>
      </div>
    </div>
  );
};

export default MainNoteContent;
