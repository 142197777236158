const ListProcess = ({ processList }) => {
  return processList?.length > 0 ? (
    processList?.map((p, i) => (
      <div key={i} className="p-2">
        {p?.groupProcessRelatedRiskTeam?.map((g, i) => (
          <div className="text-xs flex items-center gap-2 even:bg-caspian-gray2/30 p-1" key={i}>
            <span>{g?.groupCode}</span>
            <span>{g?.title}</span>
          </div>
        ))}
      </div>
    ))
  ) : (
    <div className="flex items-center justify-center gap-2 py-8">
      <span className="text-xs text-caspian-gray4">فرایندی موجود نیست</span>
    </div>
  );
};

export default ListProcess;
