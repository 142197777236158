import React, { useContext, useState } from "react";
import { PiX } from "react-icons/pi";
import API from "../../../../api/API";
import { AuthContext } from "../../../../context/auth/AuthContext";

const RejectRequest = ({ setShowReject, requestId, setSuccessReply }) => {
  const { token } = useContext(AuthContext);
  const [message, setMessage] = useState(null);
  const messageHandler = (e) => {
    e.preventDefault();
    if (message) {
      API.patch(
        `/api/v1/request/${requestId}/`,
        {
          comment: message,
          status: "rejected",
        },
        {
          headers: { Authorization: token },
        }
      )
        .then((res) => {
          setSuccessReply(1);
          setShowReject(false);
          setMessage(null);
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full z-50">
      <div
        onClick={() => setShowReject(false)}
        className="fixed inset-0 bg-caspian-gray3/50 z-40 p-4"
      ></div>
      <div className="z-50 fixed flex items-center justify-center p-2">
        <div className="min-w-[360px] bg-caspian-surface shadow-[0_0_5px_#00000050] rounded-lg border-r-4 border-r-[#FF5B5B] flex flex-col gap-3 py-3 px-4">
          <div className="flex items-center justify-between">
            <span className="text-sm">عدم تایید گزارش</span>
            <PiX
              onClick={() => setShowReject(false)}
              className="cursor-pointer z-50"
            />
          </div>
          <form onSubmit={messageHandler} className="flex flex-col gap-3">
            <textarea
              className="leading-5 resize-none outline-none outline-caspian-gray1 rounded-md outline-1 text-xs p-2"
              placeholder="دلیل عدم تایید گزارش را وارد کنید"
              rows={4}
              name="message"
              onChange={(e) => setMessage(e.target.value)}
            />
            <button className="w-fit text-caspian-secondary-600 outline outline-1 outline-caspian-secondary-600 py-1 px-4 text-xs rounded-md">
              ارسال
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RejectRequest;
