import React, { useState } from "react";
import MainListReport from "./list-report/MainLisReport";
import { PiFunnel, PiSortAscending } from "react-icons/pi";
import SearchInput from "./SearchInput";

const ReportMain = () => {
  const [serachReport, setSerachReport] = useState("");
  return (
    <div className="flex flex-col gap-0">
      <div className="flex items-center justify-between w-full">
        <SearchInput setSerachReport={setSerachReport} />
        <div className="flex items-center gap-2">
          <div className="flex flex-col gap-1">
            <label className="text-xs text-caspian-gray">وضعیت</label>
            <div className="relative">
              <PiFunnel className="text-caspian-gray text-xl absolute right-2 top-1/2 -translate-y-1/2" />
              <select
                onChange={(e) => console.log(e.target.value)}
                className="rounded-[4px] pr-8 outline outline-1 outline-caspian-gray2 p-2 font-light text-sm w-full cursor-pointer"
              >
                <option value="">همه</option>
                <option value="pm">PM</option>
                <option value="operational">مدیراجرایی</option>
                <option value="operational">تایید کننده</option>
                <option value="operational">تصویب کننده</option>
              </select>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-xs text-caspian-gray">مرتب‌سازی</label>
            <div className="relative">
              <PiSortAscending className="text-caspian-gray text-xl absolute right-2 top-1/2 -translate-y-1/2" />
              <select
                onChange={(e) => console.log(e.target.value)}
                className="rounded-[4px] pr-8 outline outline-1 outline-caspian-gray2 p-2 font-light text-sm w-full cursor-pointer"
              >
                <option value="">همه</option>
                <option value="pm">PM</option>
                <option value="operational">مدیراجرایی</option>
                <option value="operational">تایید کننده</option>
                <option value="operational">تصویب کننده</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <MainListReport serachReport={serachReport} />
    </div>
  );
};

export default ReportMain;

