import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../../context/app-context/AppContext";

const ListItemSingle = ({
  active,
  title,
  icon,
  onClick,
  link,
  buttonActive,
}) => {
  const { setNavbarActionPlan } = useAppContext();
  useEffect(() => {
    if (active === "my-status") {
      setNavbarActionPlan("my-status");
    } else if (active === "executive-programs") {
      setNavbarActionPlan("executive-programs");
    } else if (active === "pmo") {
      setNavbarActionPlan("pmo");
    } else if (active === "dashboard") {
      setNavbarActionPlan("dashboard");
    }
  }, [active, setNavbarActionPlan]);

  return (
    <li>
      <Link
        onClick={onClick}
        to={link}
        className={`${
          buttonActive === active
            ? "bg-caspian-gray3 border border-caspian-secondary-400 border-b-0 rounded-t-md font-bold"
            : "border-b-2 border-dashed border-caspian-gray"
        } flex items-center gap-2 px-4 py-2 cursor-pointer text-sm text-caspian-secondary-900`}
      >
        <div
          className={`${
            buttonActive === active ? "text-2xl" : "text-xl"
          } text-caspian-secondary-900`}
        >
          {icon}
        </div>
        <span>{title}</span>
      </Link>
    </li>
  );
};

export default ListItemSingle;
