import React, { useEffect, useState } from 'react'
import TableListGoalActionplan from './TableListGoalActionplan'
import FilterTabsProfileActionPlan from '../FilterTabsProfileActionPlan';
import API from '../../../../../../api/API';
import { useParams, useSearchParams } from 'react-router-dom';


const MainListProfileGoalActionplan = ({ activeTab }) => {

    const [listActionplan, setListActionplan] = useState([-1])
    const [filteredData, setFilteredData] = useState(listActionplan);
    const [roleProfileGoal, setRoleProfileGoal] = useState("all")
    const [stateProfileGoal, setStateProfileGoal] = useState("all")
    const [sortProfileGoal, setSortProfileGoal] = useState("a_start");
    const [searchProfileGoal, setSearchProfileGoal] = useState("");

    const [searchParams] = useSearchParams();
    const search = searchParams.get('search')
    const params = useParams()

    useEffect(() => {
        const handleData = async () => {
            try {
                const res = await API.get(`api/v1/goal/objective/actionplan/${params?.id}`)
                setListActionplan(res?.data)
                console.log(res?.data)

            } catch (error) {
                console.log(error)
            }
        }
        handleData()
    }, [params?.id])


    let searchTasks = [-1];
    let filterRole = [];
    let filterStatus = [];
    let sortData = [];

    if (listActionplan[0] !== -1) {

        searchTasks = searchProfileGoal                       //سرچ 
            ? listActionplan.filter((item) =>
                item?.title.toLowerCase().includes(searchProfileGoal.toLowerCase()) ||
                item?.manager?.firstName.toLowerCase().includes(searchProfileGoal.toLowerCase()) ||
                item?.manager?.lastName.toLowerCase().includes(searchProfileGoal.toLowerCase()) ||
                ("AP" + item?.id).toLowerCase().includes(searchProfileGoal.toLowerCase())
            )
            : listActionplan;

        filterRole =                                   // فیلتر نقش 
            roleProfileGoal !== "all"
                ? searchTasks.filter((item) => item?.userRole?.includes(roleProfileGoal))
                : searchTasks;

        filterStatus =                               // فیلتر وضعیت 
            stateProfileGoal !== "all"
                ? filterRole.filter((item) => item?.status === stateProfileGoal)
                : filterRole


        sortData =                                // فیلتر مرتب سازی
            sortProfileGoal === "a_start"
                ? filterStatus?.sort((a, b) =>
                    b?.actionplan_detail?.startDate > a?.actionplan_detail?.startDate ? -1 : 1)

                : sortProfileGoal === "b_start"
                    ? filterStatus?.sort((a, b) =>
                        b?.actionplan_detail?.startDate > a?.actionplan_detail?.startDate ? 1 : -1)

                    : sortProfileGoal === "a_end"
                        ? filterStatus?.sort((a, b) =>
                            b?.actionplan_detail?.endDate > a?.actionplan_detail?.endDate ? -1 : 1)

                        : sortProfileGoal === "b_end"
                            ? filterStatus?.sort((a, b) =>
                                b?.actionplan_detail?.endDate > a?.actionplan_detail?.endDate ? 1 : -1)


                            : sortProfileGoal === "a_cost"
                                ? filterStatus?.sort((a, b) =>
                                    Number(b?.actionplan_detail?.cost) > Number(a?.actionplan_detail?.cost) ? 1 : -1)

                                : sortProfileGoal === "b_cost"
                                    ? filterStatus?.sort((a, b) =>
                                        Number(b?.actionplan_detail?.cost) > Number(a?.actionplan_detail?.cost) ? -1 : 1)


                                    : sortProfileGoal === "a_progress"
                                        ? filterStatus?.sort((a, b) =>
                                            b?.actionplan_detail?.progress > a?.actionplan_detail?.progress ? 1 : -1

                                        )
                                        : sortProfileGoal === "b_progress" ?

                                            filterStatus?.sort((a, b) =>
                                                b?.actionplan_detail?.progress > a?.actionplan_detail?.progress ? -1 : 1
                                            )


                                            : filterStatus?.sort((a, b) =>
                                                b?.created_at > a?.created_at ? -1 : 1);


    }

    useEffect(() => {
        if (search) {
            setSearchProfileGoal(search)
        }
    }, [search])

    return (
        <div className='w-full flex flex-col gap-6'>
            <FilterTabsProfileActionPlan
                activeTab={activeTab}
                searchProfileGoal={searchProfileGoal}
                setSearchProfileGoal={setSearchProfileGoal}
                roleProfileGoal={roleProfileGoal}
                setRoleProfileGoal={setRoleProfileGoal}
                stateProfileGoal={stateProfileGoal}
                setStateProfileGoal={setStateProfileGoal}
                sortProfileGoal={sortProfileGoal}
                setSortProfileGoal={setSortProfileGoal}
            />
            <TableListGoalActionplan
                filteredData={filteredData}
                listActionplan={listActionplan}
                sortData={sortData}
            />
        </div>
    )
}
export default MainListProfileGoalActionplan

