import React from "react";
import Modal from "../../../../modal/Modal";
import { PiX } from "react-icons/pi";

const AllMembers = ({
  setShowAllMembers,
  membersGroupsFinalSelected,
  membersFinalSelected,
}) => {
  return (
    <Modal>
      <div className="flex flex-col gap-0 min-w-[280px] max-h-[340px] z-50 bg-caspian-surface rounded-md shadow-[0_0_5px_#00000050]">
        <div className="flex items-center justify-between w-full p-2 border-b border-b-caspian-gray2">
          <span className="text-xs text-caspian-gray font-bold">اعضای تیم</span>
          <PiX
            onClick={() => setShowAllMembers(false)}
            className="cursor-pointer"
          />
        </div>
        <div className="flex flex-col w-full overflow-y-scroll">
          <div className="flex flex-col w-full border-b border-b-caspian-gray2">
            {membersGroupsFinalSelected?.map((m, i) => (
              <div
                key={i}
                className="flex items-center gap-1 w-full even:bg-caspian-gray2/50 p-2"
              >
                <span className="font-bold text-xs text-caspian-gray4">
                  {m?.firstName + " " + m?.lastName}
                </span>
                <span className="text-xs text-caspian-gray4 mr-auto">
                  کارشناس
                </span>
              </div>
            ))}
            {membersFinalSelected?.map((m, i) => (
              <div
                key={i}
                className="flex items-center gap-1 w-full even:bg-caspian-gray2/50 p-2"
              >
                <span className="text-xs text-caspian-gray4">
                  {m?.firstName + " " + m?.lastName}
                </span>
                <span className="text-xs text-caspian-gray4 mr-auto">
                  کارشناس
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AllMembers;
