import { Outlet, useLocation } from "react-router-dom";
import { useAppContext } from "../../context/app-context/AppContext";
import { useEffect } from "react";

const RiskLayout = ({ showMenu }) => {
  const location = useLocation();
  const { setBradCrump1, setBradCrump2, setBradCrump3, setBrcLink2 } =
    useAppContext();
  useEffect(() => {
     if (location.pathname.includes("risk/teams")) {
      setBradCrump1("مدیریت ریسک");
      setBradCrump2("تیم‌ها");
    } else if (location.pathname.includes("risk/risks")) {
      setBradCrump1("مدیریت ریسک");
      setBradCrump2("ریسک‌ها");
    }
    else if (location.pathname.includes("risk/activities")) {
      setBradCrump1("مدیریت فعالیت");
      setBradCrump2("فعالیت‌ها");
    }
  }, [location, setBradCrump1, setBradCrump2, setBradCrump3, setBrcLink2]);
  return (
    <div
      className={
        showMenu
          ? `pl-[50px] mr-auto w-full md:w-[75%] lg:w-[80%] xl:w-[85%] 2xl:w-[85%] bg-caspian-gray3 p-1 overflow-y-scroll`
          : `pl-[50px] mr-[54px] w-full bg-caspian-gray3 p-1 overflow-y-scroll`
      }
    >
      <Outlet />
    </div>
  );
};

export default RiskLayout;
