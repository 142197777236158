import React, { useState } from 'react'
import { PiCaretLeft } from "react-icons/pi";
import ProfileCollapse from './ProfileCollapse';
import MainObjectiveProfileGoal from './objective-profile-goal/MainObjectiveProfileGoal';


const Profile = ({ data }) => {
    const [showCollaps, setShowCollaps] = useState(false)

    return (


        <div className="w-full relative rounded-md shadow-[0_0_10px_#00000050] bg-caspian-surface flex flex-col">

            <div className='flex py-6 pr-12 border-b-2 border-caspian-primary-100/60 items-center'>

                <div className='flex items-center gap-2 basis-1/2'>
                    <span className='text-xs 2xl:text-base text-[#4B5563]'>منظر :</span>
                    <span
                        style={{ background: `#${data?.goalRelated?.prespectiveRelated?.colorHex}` }}
                        className='text-xs 2xl:text-base text-caspian-surface font-extrabold px-6 py-2 rounded-md '>
                        {data?.goalRelated?.prespectiveRelated?.title ? data?.goalRelated?.prespectiveRelated?.title : '-'}
                    </span>

                </div>

                <div className='flex items-center gap-2 basis-1/2'>
                    <span className='text-xs 2xl:text-base text-[#4B5563]'>هدف کلان :</span>
                    <span
                        style={{ color: `#${data?.goalRelated?.colorHex}` }}
                        className='text-xs 2xl:text-base font-extrabold'>
                        {data?.goalRelated?.title ? data?.goalRelated?.title : '-'}
                    </span>
                    <span
                        style={{ color: `#${data?.goalRelated?.colorHex}` }}
                        className='text-xs xl:text-base font-extrabold'>
                        ( {data?.goalRelated?.code ? data?.goalRelated?.code : '-'} )
                    </span>
                </div>

            </div>

            <div className='flex flex-col items-end '>

                <div className='w-full'>
                    <MainObjectiveProfileGoal data={data} />
                    {showCollaps &&
                        <ProfileCollapse data={data} />
                    }
                </div>

                <span onClick={() => setShowCollaps(!showCollaps)}
                    className='flex items-center gap-1 p-5 cursor-pointer text-caspian-secondary-600 text-xs 2xl:text-sm'>
                    {showCollaps === false ? "جزئیات بیشتر" : "جزئیات کمتر"}
                    <PiCaretLeft />
                </span>
            </div>

        </div >
    )
}

export default Profile