import React from "react";

const SizeScreen = () => {
  return (
    <div className="w-auto rounded-br-lg text-caspian-on_primary/95 bg-caspian-transparent flex items-center justify-center fixed top-0 left-0 z-[500] font-sans min-w-[60px]">
      <div className="flex sm:hidden gap-0 items-center justify-center">
        <span className="text-sm">xs-</span>
        <span className="text-xs">100%</span>
      </div>

      <div className="hidden sm:flex md:hidden gap-0 items-center justify-center">
        <span className="text-sm">sm-</span>
        <span className="text-xs">640px</span>
      </div>

      <div className="hidden md:flex lg:hidden gap-0 items-center justify-center">
        <span className="text-sm">md-</span>
        <span className="text-xs">768px</span>
      </div>

      <div className="hidden lg:flex xl:hidden gap-0 items-center justify-center">
        <span className="text-sm">lg-</span>
        <span className="text-xs">1024px</span>
      </div>

      <div className="hidden xl:flex 2xl:hidden gap-0 items-center justify-center">
        <span className="text-sm">xl-</span>
        <span className="text-xs">1280px</span>
      </div>

      <div className="hidden 2xl:flex gap-0 items-center justify-center">
        <span className="text-sm">2xl-</span>
        <span className="text-xs">1536px</span>
      </div>
    </div>
  );
};

export default SizeScreen;
