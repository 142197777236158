import React from "react";
import { PiWarningFill } from "react-icons/pi";

const Notification = () => {
  return (
    <div className="flex items-center gap-4 w-full rounded-xl bg-caspian-surface p-4">
      <PiWarningFill className="text-2xl text-[#FB8C00]" />
      <p className="font-medium text-[#FB8C00]">
        اطلاعات شخصی خود را در زیر می‌توانید مشاهده کنید، در صورت وجود مغایرت به
        واحد پشتیبانی مراجعه کنید
      </p>
    </div>
  );
};

export default Notification;
