import React, { useEffect, useState } from "react";
import StartDateTimePicker from "./StartDateTimePicker";
import EndDateTimePicker from "./EndDateTimePicker";
import ListSelected from "./ListSelected";
import { PiPlusBold } from "react-icons/pi";

// const formatNumber = (num) => {
//   return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// };
const StepFourForm = ({
  privacy,
  setPrivacy,
  cost,
  setCost,
  setStartDate,
  setEndDate,
  membersSelected,
  setMembersSelected,
  membersFinalSelected,
  setMembersFinalSelected,
}) => {
  const [showListMembers, setShowListMembers] = useState(false);

  // const handleInputChange = (e) => {
  //   const value = e.target.value.replace(/,/g, "");

  //   const onlyNums = value.replace(/[^0-9]/g, "");
  //   setCost(formatNumber(onlyNums));
  // };
  const [showRestricted, setShowRestricted] = useState(false);

  useEffect(() => {
    if (privacy === "restricted") {
      setShowRestricted(true);
    } else {
      setShowRestricted(false);
    }
  }, [privacy]);
  return (
    <div className="flex items-start gap-2 w-full">
      <div className="w-full flex flex-col gap-2">
        <div className="w-full flex flex-col gap-1.5">
          <label className="text-xs text-caspian-gray">سطح محرمانگی</label>
          <select
            onChange={(e) => {
              setPrivacy(e.target.value);
            }}
            name="privacy"
            defaultValue={"public"}
            className="text-xs outline outline-1 outline-caspian-gray1 p-1.5 rounded-md bg-caspian-transparent cursor-pointer"
          >
            <option value={"public"}>عمومی</option>
            <option value={"internal"}>داخلی</option>
            <option value={"restricted"}>طبقه بندی</option>
            <option value={"secret"}>سری</option>
            {/* <option value={"confidential"}>خیلی محرمانه</option> */}
          </select>
        </div>
        {showRestricted && (
          <div className="w-full flex flex-col gap-1.5">
            <label className="text-xs text-caspian-gray">
              دسترسی برای مشاهده
            </label>

            <button
              onClick={() => setShowListMembers(true)}
              className="rounded-md border border-caspian-secondary-400 p-1.5 flex items-center justify-between gap-1 w-full"
            >
              <div className="flex items-center gap-1.5">
                <PiPlusBold className="text-caspian-secondary-500 text-md" />
                <span className="text-caspian-secondary-500 text-xs">
                  افزودن
                </span>
              </div>
              {membersFinalSelected?.length > 0 ? (
                <span className="text-xs">
                  {membersFinalSelected?.length} عضو افزوده شد
                </span>
              ) : (
                <span className="text-xs">عضوی انتخاب نشده</span>
              )}
            </button>
          </div>
        )}
        {showListMembers && (
          <ListSelected
            finalSelected={membersFinalSelected}
            setFinalSelected={setMembersFinalSelected}
            selected={membersSelected}
            setSelected={setMembersSelected}
            setShowListSelected={setShowListMembers}
            title="لیست کاربران"
            url="/api/v1/profile/"
          />
        )}
      </div>
      <div className="w-full flex flex-col gap-2">
        <div className="w-full flex flex-col gap-1.5">
          <label className="text-xs text-caspian-gray">
            تاریخ شروع برنامه اجرایی
          </label>
          <StartDateTimePicker setStartDate={setStartDate} />
        </div>
        <div className="w-full flex flex-col gap-1.5">
          <label className="text-xs text-caspian-gray">
            تاریخ پایان برنامه اجرایی
          </label>
          <EndDateTimePicker setEndDate={setEndDate} />
        </div>
        <div className="w-full flex flex-col gap-1.5">
          <label className="text-xs text-caspian-gray">
            بودجه تخمینی(ریال)
          </label>
          <div className="flex items-center gap-0 w-full border border-caspian-gray1 rounded-md overflow-hidden">
            <input
              type="text"
              name="cost"
              onChange={(e) => setCost(e.target.value)}
              // onChange={handleInputChange}
              value={cost}
              placeholder="عنوان برنامه اجرایی را وارد کنید"
              className="outline-none p-1.5 text-xs text-caspian-on_primary w-full"
            />
            <span className="bg-caspian-gray1 text-xs p-1.5 text-caspian-gray4">
              ریال
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepFourForm;
