import { Outlet, useLocation, useParams } from "react-router-dom";
import { useAppContext } from "../../context/app-context/AppContext";
import { useContext, useEffect } from "react";
import MainPmoProfile from "./pmo/profile/MainPmoProfile";
import { AuthContext } from "../../context/auth/AuthContext";

const ActionPlanLayout = ({ showMenu, pmo }) => {
  const location = useLocation();
  const params = useParams();
  const { user } = useContext(AuthContext);

  const { setBradCrump1, setBradCrump2, setBradCrump3, setBrcLink2 } =
    useAppContext();
  useEffect(() => {
    if (location.pathname.includes("action-plan/my-status")) {
      setBradCrump1("برنامه اجرایی");
      setBradCrump2("وظایف من");
    } else if (location.pathname.includes("action-plan/executive-programs")) {
      setBradCrump1("برنامه اجرایی");
      setBradCrump2("برنامه‌های اجرایی");
    } else if (location.pathname.includes("action-plan/pmo")) {
      setBradCrump1("برنامه اجرایی");
      setBradCrump2("دفتر pmo");
    } else if (location.pathname.includes("action-plan/dashboard")) {
      setBradCrump1("برنامه اجرایی");
      setBradCrump2("داشبورد");
    }
  }, [location, setBradCrump1, setBradCrump2, setBradCrump3, setBrcLink2]);
  return (
    <div
      className={
        showMenu
          ? `pl-[50px] mr-auto w-full md:w-[75%] lg:w-[80%] xl:w-[85%] 2xl:w-[85%] bg-caspian-gray3 p-1 overflow-y-scroll`
          : `pl-[50px] mr-[54px] w-full bg-caspian-gray3 overflow-y-scroll`
      }
    >
      {/* {params?.id && user?.id === pmo ? (
        <MainPmoProfile pmo={pmo} actionplanId={params?.id} />
      ) : ( */}
        <Outlet context={pmo} />
      {/* )} */}
    </div>
  );
};

export default ActionPlanLayout;
