
import React, { useContext, useEffect, useState } from "react";
import ActionPlanNavbar from "../../../ActionPlanNavbar";
import MainWorkProgressReport from "./work-progress-report/MainWorkProgressReport";
import MainCostReport from "./cost-report/MainCostReport";
import MainMaterialReport from "./material-report/MainMaterialReport";
import { PiChatTextLight, PiCoinsLight, PiScrollLight, PiShoppingCart } from "react-icons/pi";
import { PiChartLineLight } from "react-icons/pi";
import API from "../../../../../api/API";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import CommentsMain from "../../../comments/CommentsMain";

const MainProfileReportActionPaln = () => {
  const { token } = useContext(AuthContext)
  const [fullData, setFullData] = useState([])
  const [showComment, setShowComment] = useState(false)

  const params = useParams();

  useEffect(() => {
    if (!token) return
    const handleData = async () => {
      try {
        const res = await API.get(`/api/v1/actionplan/taskreport/${params?.id}`,
          { headers: { Authorization: token } })
        setFullData(res?.data)
        console.log(res?.data)
      } catch (error) {
        console.log(error)
      }
    }
    handleData()
  }, [params?.id, token])


  return (
    <div>
      <ActionPlanNavbar buttonActive="actions" />

      <div className="p-5">
        <div className="flex flex-col items-center w-full p-5 shadow-md lg:w-3/4 bg-caspian-surface">

          <div className="flex flex-col items-center w-full">

            <div className="flex items-center justify-end w-full gap-2 cursor-pointer text-caspian-secondary-600">
              <span onClick={() => setShowComment(true)}>ثبت‌ دیدگاه</span>
              <PiChatTextLight className="text-2xl" />
            </div>

            {showComment &&
              <CommentsMain setShowComments={setShowComment} />
            }

            <div className="flex items-center justify-around w-full gap-5 py-3 ">
              <div className="flex items-center gap-2 text-caspian-secondary-600">
                <PiChartLineLight className="text-2xl" />
                <span>گزارش</span>
              </div>
              <div className="w-full h-[2px] bg-caspian-on_primary/80"></div>
            </div>

            <div className="flex items-center w-full gap-2 py-5">
              <div className="flex flex-col items-start gap-2">
                <span className="text-[#3E615F] text-xs 2xl:text-base">عنوان برنامه اجرایی :</span>
                <span className="text-[#3E615F] text-xs 2xl:text-base">عنوان فعالیت :</span>
              </div>

              <div className="flex flex-col items-start gap-2">
                <span className="text-xs font-bold 2xl:text-base">
                  {fullData?.taskRelated?.actionplanRelated?.title ? fullData?.taskRelated?.actionplanRelated?.title : "-"}

                </span>
                <span className="text-xs font-bold 2xl:text-base">
                  {fullData?.taskRelated?.title ? fullData?.taskRelated?.title : "-"}
                </span>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center w-full py-5">

            {/* پیشرفت کار  */}
            <div className="flex flex-col items-center w-full gap-5">

              <div className='flex items-center w-full gap-2 '>
                <div className='w-1/2 h-[2px] bg-caspian-gray2'></div>
                <PiScrollLight className='text-[#3E615F] text-3xl' />
                <span className='text-xs text-[#3E615F] font-bold 2xl:text-base whitespace-nowrap'>گزارش پیشرفت کار</span>
                <div className='w-1/2 h-[2px] bg-caspian-gray2'></div>
              </div>

              {fullData?.progress_taskreportProfile?.length > 0 ?
                <MainWorkProgressReport fullData={fullData} />
                : <span className="w-full py-5 text-base font-bold text-center">گزارشی برای پیشرفت کار تعریف نشده است.</span>
              }
            </div>

            {/* هزینهّ */}
            <div className="flex flex-col items-center w-full gap-5 py-5">

              <div className='flex items-center w-full gap-2 '>
                <div className='w-1/2 h-[2px] bg-caspian-gray2'></div>
                <PiCoinsLight className='text-[#3E615F] text-3xl' />
                <span className='text-xs text-[#3E615F] font-bold 2xl:text-base whitespace-nowrap'>گزارش هزینه</span>
                <div className='w-1/2 h-[2px] bg-caspian-gray2'></div>
              </div>

              {fullData?.costRelatedreportProfile?.length > 0 ?
                <MainCostReport fullData={fullData} />
                : <span className="w-full py-5 text-base font-bold text-center">گزارشی برای هزینه تعریف نشده است. </span>
              }
            </div>


            {/* اقلام مصرفی */}
            <div className="flex flex-col items-center w-full gap-5">

              <div className='flex items-center w-full gap-2 '>
                <div className='w-1/2 h-[2px] bg-caspian-gray2'></div>
                <PiShoppingCart className='text-[#3E615F] text-3xl' />
                <span className='text-xs text-[#3E615F] font-bold 2xl:text-base whitespace-nowrap'>گزارش اقلام مصرفی</span>
                <div className='w-1/2 h-[2px] bg-caspian-gray2'></div>
              </div>

              {fullData?.reportProfileMaterialRelated?.length > 0 ?
                <MainMaterialReport fullData={fullData} />
                : <span className="w-full py-5 text-base font-bold text-center">گزارشی برای اقلام مصرفی تعریف نشده است. </span>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainProfileReportActionPaln;
