import React, { useContext, useRef, useState } from 'react';
import Modal from '../../../../../../../modal/Modal';
import { PiCircleNotch, PiDotBold, PiDotOutlineFill, PiTrash, PiX } from 'react-icons/pi';
import API from '../../../../../../../../api/API';
import { AuthContext } from '../../../../../../../../context/auth/AuthContext';

const EditForm = ({ id, edit, setEdit, postDescription, noStructureRole, setRefresh }) => {
    const { token } = useContext(AuthContext)
    console.log(noStructureRole);
    let existRes = noStructureRole ? noStructureRole?.split(',') : [];
    console.log(existRes);
    const [desc, setDesc] = useState(postDescription ? postDescription : '');
    const [structure, setStructure] = useState(existRes);
    console.log(structure);
    const [isLoading, setIsLoading] = useState(false);
    const ref = useRef(null);
    const handleSubmit = async (id) => {
        if (!token || !id) return
        let newStructure = structure?.length > 0 ? structure?.join(',') : null;
        let formData = {
            postDescription: desc,
            noStructureRole: newStructure
        }
        console.log(formData);
        setIsLoading(true);
        try {
            const res = await API.patch(`/api/v1/organization/role/position/${id}/`, formData, { headers: { Authorization: token } });
            if (res?.data) {
                console.log(res?.data);
                setIsLoading(false);
                setRefresh(true);
                setEdit(false);
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }

    const resHandle = (e) => {
        if (e.key === 'Enter') {
            if (e.target.value === '') return
            setStructure([...structure, e.target.value]);
            ref.current.value = '';
        }
    }

    const removeHandle = (index) => {
        const item = structure?.filter((s, i) => i !== index);
        setStructure(item);
    }
    return (
        <Modal>
            <div className='bg-caspian-surface w-[50%] max-w-[600px] rounded-md'>
                <div className='flex justify-between items-center p-1.5'>
                    <span className='font-semibold'>ویرایش فرم معرفی</span>
                    <PiX disabled={isLoading} className='cursor-pointer' onClick={() => setEdit(false)} />
                </div>
                <div className='border-t border-caspian-gray1 px-1.5 flex flex-col gap-2 py-5'>
                    <div className='flex flex-col gap-2 text-xs 2xl:text-sm'>
                        <span>تعریف پست :</span>
                        <textarea
                            defaultValue={postDescription}
                            onChange={(e) => setDesc(e?.target?.value)}
                            rows={5} cols={5} className='resize-none border border-caspian-gray1 rounded-md p-1.5' ></textarea>
                    </div>
                    <div className='flex flex-col gap-2 text-xs 2xl:text-sm'>
                        <span className='line-clamp-1'>نقش‌های سازمانی در این پست : ( غیر ساختاری) </span>
                        <input
                            type='text'
                            onKeyUp={(e) => resHandle(e)}
                            ref={ref}
                            // defaultValue={noStructureRole}
                            className='resize-none border border-caspian-gray1 rounded-md p-1.5' ></input>
                        <div className='h-[30vh] overflow-y-auto'>
                            {
                                structure?.length < 1 ? (<p className='text-xs text-caspian-gray4 pt-20 text-center'>نقشی ثبت نشده است.</p>) : structure?.map((s, i) => <div key={i} className='flex items-start justify-between my-2 first:mt-0'>
                                    <p className='flex gap-1.5 items-start'>
                                        <p className='w-[20px]'><PiDotOutlineFill className='text-xl' /></p>
                                        <span className='w-[90%]'>{s}</span>
                                    </p>
                                    <p className='w-[50px]'>
                                        <PiTrash className='cursor-pointer mr-auto' onClick={() => removeHandle(i)} />
                                    </p>
                                </div>)
                            }
                        </div>
                    </div>
                </div>
                <button onClick={() => handleSubmit(id)} className='bg-[#3C77C2] flex gap-2 justify-center items-center text-caspian-surface p-1.5 text-xs rounded-md px-5 mx-1.5 mb-1.5 w-[100px]'>
                    <span>ثبت</span>
                    {isLoading && <span>
                        <PiCircleNotch className='animate-spin' />
                    </span>}
                </button>
            </div>
        </Modal>
    );
}

export default EditForm;
