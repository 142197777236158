import React, { useContext, useEffect, useState } from 'react';
import FilterSection from '../../form-question/create-form-question/FilterSection';
import { AuthContext } from '../../../../../context/auth/AuthContext';
import API from '../../../../../api/API';
import FilterProcess from '../../form-question/create-form-question/FilterProcess';
import FilterActivity from '../../form-question/create-form-question/FilterActivity'
import { PiPlusBold } from 'react-icons/pi';
import SearchList from './SearchList';

const FilterItems = ({ search, setSearch, setCurrentPage, setOffset, setRefresh,filterBySec,setFilterBySec
    ,filterByProcess,setFilterByProcess,setFilterByActive,filterByActive
 }) => {
    const { token } = useContext(AuthContext);

    const [sectionList, setSectionList] = useState([]);
    const [section, setSection] = useState("");
    const [sectionSelected, setSectionSelected] = useState(null);
    const [showListSection, setShowListSection] = useState(false);

    const [processList, setProcessList] = useState([]);
    const [process, setProcess] = useState("");
    const [processSelected, setProcessSelected] = useState(null);
    const [showListProcess, setShowListProcess] = useState(false);

    const [showActivity2, setShowActivity2] = useState(false);
    const [activitiesSelected2, setActivitiesSelected2] = useState([]);
    const [activitiesFinalSelected2, setActivitiesFinalSelected2] =
        useState(activitiesSelected2);

    // const [isDisabled, setIsDisabled] = useState(process ? false : true);
    useEffect(() => {
        if (!token) return;
        const fetchSection = async () => {
            try {
                const res = await API.get('/api/v1/organization/structure/section/');
                setSectionList(res.data);
            }
            catch (err) {
                console.log(err.message);
            }
        }
        fetchSection();
    }, [token, section])

    useEffect(() => {
        if (!token) return
        const fetchProcess = async () => {
            try {
                const res = await API.get('/api/v1/businessProcess/process/');
                setProcessList(res.data);
                setActivitiesFinalSelected2('');
                console.log(res.data);
            }
            catch (err) {
                console.log(err.message);
            }
        }
        fetchProcess();
    }, [token, process])

    const rst = () => {
        setProcessSelected(null);
        setProcess('')
        setSectionSelected(null);
        setActivitiesSelected2([])
        setActivitiesFinalSelected2([]);
        setFilterBySec('');

        setFilterByProcess('');

        setFilterByActive([]);
        setRefresh(true)
    }
const handleFilter=()=>{
        setFilterBySec(section);

        setFilterByProcess(process);

        setFilterByActive(activitiesSelected2);

}
console.log('activity',activitiesSelected2);
    return (
        <div className='flex items-end'>
            <div className='flex basis-1/3 pb-2.5'>
                <SearchList search={search} setSearch={setSearch} setCurrenPage={setCurrentPage} setOffset={setOffset}
                    setRefresh={setRefresh} />
            </div>
            <div className='flex basis-2/3 items-end gap-1 2xl:gap-4'>
                <div className='w-1/3 pb-2.5'>
                    <FilterSection
                        lable="واحد"
                        sectionList={sectionList}
                        section={section}
                        setSection={setSection}
                        sectionSelected={sectionSelected}
                        setSectionSelected={setSectionSelected}
                        showListSection={showListSection}
                        setShowListSection={setShowListSection}
                    />
                </div>
                <div className='flex gap-2 border items-center border-[#D2D1D1] rounded-md w-2/3 p-2'>
                <div className='w-full'>
                    <FilterProcess
                        lable="فرآیند"
                        processList={processList}
                        process={process}
                        setProcess={setProcess}
                        processSelected={processSelected}
                        setProcessSelected={setProcessSelected}
                        showListProcess={showListProcess}
                        setShowListProcess={setShowListProcess}
                    />
                </div>
                <div className='flex flex-col gap-2 w-full'>
                    <span className='text-xs text-caspian-gray4'>فعالیت</span>
                    <FilterActivity
                        finalSelected={activitiesFinalSelected2}
                        setFinalSelected={setActivitiesFinalSelected2}
                        selected={activitiesSelected2}
                        setSelected={setActivitiesSelected2}
                        setShowListSelected={setShowActivity2}
                        title=" فعالیت "
                        url={`/api/v1/profileProcess/profile/${process}/`}
                        process={process}
                    />
                </div>
                </div>
                <div className='pb-2.5 flex flex-col-reverse gap-1'>
                    <button className='rounded-md bg-[#F5F5F5] border text-[#3C77A2] border-[#3C77A2] p-1.5 text-xs 2xl:text-xs whitespace-nowrap' onClick={rst}>ریست فیلتر </button>
                    <button 
                    onClick={handleFilter}
                    className='rounded-md bg-[#F5F5F5] border text-[#3C77A2] border-[#3C77A2] p-1.5 text-xs 2xl:text-xs w-max whitespace-nowrap'>اعمال فیلتر</button>
                </div>
            </div>


            {/* <div>
    <SearchList search={search} setSearch={setSearch} setCurrenPage={setCurrentPage} />
    </div>
            
                <div>
                    <FilterSection
                        lable="واحد"
                        sectionList={sectionList}
                        section={section}
                        setSection={setSection}
                        sectionSelected={sectionSelected}
                        setSectionSelected={setSectionSelected}
                        showListSection={showListSection}
                        setShowListSection={setShowListSection}
                    />
                </div>
                    <div className='w-full'>
                        <FilterProcess
                            lable="فرآیند"
                            processList={processList}
                            process={process}
                            setProcess={setProcess}
                            processSelected={processSelected}
                            setProcessSelected={setProcessSelected}
                            showListProcess={showListProcess}
                            setShowListProcess={setShowListProcess}
                        />
                        </div>
                    <div className='w-full flex'>
                    <span className='text-xs text-caspian-gray4'>فعالیت</span>
                                <FilterActivity
                                    finalSelected={activitiesFinalSelected2}
                                    setFinalSelected={setActivitiesFinalSelected2}
                                    selected={activitiesSelected2}
                                    setSelected={setActivitiesSelected2}
                                    setShowListSelected={setShowActivity2}
                                    title=" فعالیت "
                                    url={`/api/v1/profileProcess/profile/${process}/`}
                                    process={process}
                                />
                        </div>
            <div>
                {/* <button className='rounded-md bg-[#F5F5F5] border text-[#3C77A2] border-[#3C77A2] p-1.5 text-xs 2xl:text-sm' onClick={rst}>ریست فیلتر </button> */}
            {/* <button className='rounded-md bg-[#F5F5F5] border text-[#3C77A2] border-[#3C77A2] p-1.5 text-xs 2xl:text-sm'>اعمال فیلتر</button>
            </div> */}
        </div>
    );
}

export default FilterItems;
