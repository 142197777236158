import React, { useState } from "react";
import { PiCaretLeft } from "react-icons/pi";
import ItemSingle from "./ItemSingle";

const SingleManagmentSystem = ({ standard, bands }) => {
  const [show, setShow] = useState(false);
  return (
    <div className="border border-caspian-gray2 rounded-md p-4 flex flex-col gap-4">
      <div className="flex items-center justify-between gap-2">
        <span className="text-sm text-caspian-secondary-500">{standard}</span>
        <div className="h-[1px] bg-caspian-gray2 w-[60%]"></div>
        <button
          onClick={() => setShow(!show)}
          className="flex items-center gap-2 text-caspian-secondary-700"
        >
          <span className="text-xs 2xl:text-base">بندها</span>
          <PiCaretLeft
            className={`2xl:text-lg ${
              show ? "-rotate-90 duration-500" : "duration-500"
            }`}
          />
        </button>
      </div>
      {show && (
        <div className="flex flex-col gap-0">
          <div className="w-full odd:bg-caspian-gray3 p-1 flex flex-wrap gap-2 items-center">
            <span className="text-xs">بندها</span>
            {bands?.map((b, i) => (
              <ItemSingle key={i} title={b?.clauseNumber} data={b} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleManagmentSystem;
