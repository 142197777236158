import React, { useEffect, useState } from "react";
import RowView from "./RowView";
import Paginate from "./Paginate";
import { useSearchParams } from "react-router-dom";
import { PiCircleNotch } from "react-icons/pi";
import SingleTableListPmo from "./SingleTableListActionplan";

const TableListActionplan = ({ fullData, loading, setRefreshPmoList }) => {
  const [filterByTitle, setFilterByTitle] = useState("");
  const [filterByCode, setFilterByCode] = useState("");
  const [filterByManager, setFilterByManager] = useState("");
  const [filterByStartDate, setFilterByStartDate] = useState("");
  const [filterByEndDate, setFilterByEndDate] = useState("");

  const [searchParams] = useSearchParams();
  const search = searchParams.get("search");
  const page = searchParams.get("page");
  const rows = searchParams.get("rows");

  let filterTitle = [];
  let filterCode = [];
  let filterManager = [];
  let filterEndDate = [];
  let filterStartDate = [];

  useEffect(() => {
    if (search) {
      setCurrentPage(1);
    }
  }, [search]);

  let newData = [-1];
  if (fullData[0] !== -1) {
    newData = search
      ? fullData?.filter((d) =>
          d?.title?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase())
        )
      : fullData;
  }

  if (newData[0] !== -1) {
    filterTitle = filterByTitle
      ? newData.filter((d) =>
          d?.title
            .toLocaleLowerCase()
            .includes(filterByTitle?.toLocaleLowerCase())
        )
      : newData;

    filterManager = filterByManager
      ? filterTitle?.filter((d) =>
          d?.actionplan_responsibles[0]?.manager
            ?.toLocaleLowerCase()
            ?.includes(filterByManager?.toLocaleLowerCase())
        )
      : filterTitle;

    filterStartDate = filterByStartDate
      ? filterManager.filter((d) =>
          d?.actionplan_detail[0]?.startDate
            .toLocaleLowerCase()
            .includes(filterByStartDate?.toLocaleLowerCase())
        )
      : filterManager;

    filterEndDate = filterByEndDate
      ? filterStartDate.filter((d) =>
          (d?.actionplan_detail[0]?.endDate)
            .toLocaleLowerCase()
            .includes(filterByEndDate?.toLocaleLowerCase())
        )
      : filterStartDate;
  }

  const [currentPage, setCurrentPage] = useState(page ? page : 1);
  const [recordsPerPage, setRecordsPerPage] = useState(rows ? rows : 10);
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = filterEndDate.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filterEndDate.length / recordsPerPage);

  return (
    <div className="flex flex-col gap-4">
      {loading ? (
        <div className="flex items-center justify-center py-40">
          <PiCircleNotch className="text-caspian-secondary-600 text-2xl animate-spin" />
        </div>
      ) : (
        <div className="flex flex-col overflow-x-auto">
          <div className="sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-x-auto">
                <table className="min-w-full text-left text-xs font-light">
                  <thead className="border-b border-b-caspian-gray2 font-medium">
                    <tr className="">
                      <th
                        scope="col"
                        className="rounded-tr-xl bg-caspian-transparent text-right p-1 w-[15%]"
                      >
                        <input
                          // onClick={() => setCurrentPage(1)}
                          onChange={(e) => {
                            setFilterByTitle(e.target.value);
                          }}
                          type="search"
                          placeholder="عنوان"
                          disabled
                          className="bg-caspian-transparent rounded-md outline-none p-1.5 font-light text-xs w-full"
                        />
                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-transparent text-right p-1 w-[5%]"
                      >
                        <input
                          // onClick={() => setCurrentPage(1)}
                          onChange={(e) => {
                            setFilterByCode(e.target.value);
                          }}
                          type="search"
                          placeholder="کد"
                          disabled
                          className="bg-caspian-transparent rounded-md outline-none p-1.5 font-light text-xs w-full"
                        />
                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-transparent text-right p-1 w-[10%]"
                      >
                        <input
                          onChange={(e) => setFilterByManager(e.target.value)}
                          type="search"
                          placeholder="مسئول برنامه"
                          disabled
                          className="bg-caspian-transparent rounded-md outline-none p-1.5 font-light text-xs w-full"
                        />
                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-transparent text-right p-1 w-[10%]"
                      >
                        <input
                          onChange={(e) => setFilterByStartDate(e.target.value)}
                          type="search"
                          placeholder="شروع"
                          disabled
                          className="bg-caspian-transparent rounded-md outline-none p-1.5 font-light text-xs w-full"
                        />
                      </th>
                      {/* <th
                        scope="col"
                        className="bg-caspian-transparent text-right p-1 w-[10%]"
                      ></th> */}
                      <th
                        scope="col"
                        className="bg-caspian-transparent text-right p-1 w-[10%]"
                      >
                        <input
                          onChange={(e) => setFilterByEndDate(e.target.value)}
                          type="search"
                          placeholder="پایان"
                          disabled
                          className="bg-caspian-transparent rounded-md outline-none p-1.5 font-light text-xs w-full"
                        />
                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-transparent text-right p-1 w-[10%]"
                      >
                        <input
                          onChange={(e) => setFilterByEndDate(e.target.value)}
                          type="search"
                          placeholder="روز باقی مانده"
                          disabled
                          className="bg-caspian-transparent rounded-md outline-none p-1.5 font-light text-xs w-full"
                        />
                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-transparent text-right p-1 w-[10%]"
                      >
                        <input
                          onChange={(e) => setFilterByEndDate(e.target.value)}
                          type="search"
                          placeholder="بودجه(ریال)"
                          disabled
                          className="bg-caspian-transparent rounded-md outline-none p-1.5 font-light text-xs w-full"
                        />
                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-transparent text-right p-1 w-[10%]"
                      >
                        <input
                          onChange={(e) => setFilterByEndDate(e.target.value)}
                          type="search"
                          placeholder="درصد پیشرفت"
                          disabled
                          className="bg-caspian-transparent rounded-md outline-none p-1.5 font-light text-xs w-full"
                        />
                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-transparent text-right p-1 w-[10%]"
                      >
                        <input
                          onChange={(e) => setFilterByEndDate(e.target.value)}
                          type="search"
                          placeholder="وضعیت"
                          disabled
                          className="bg-caspian-transparent rounded-md outline-none p-1.5 font-light text-xs w-full"
                        />
                      </th>
                    </tr>
                  </thead>
                  {records.length > 0 ? (
                    <tbody>
                      {records.map((d, i) => (
                        <SingleTableListPmo
                          key={i}
                          id={d.id}
                          title={d.title}
                          code={"AP-" + d.id}
                          manager={
                            d?.manager?.firstName + " " + d?.manager?.lastName
                          }
                          startDate={
                            d?.startDateReal
                              ? new Date(d?.startDateReal).toLocaleDateString(
                                  "fa-IR"
                                )
                              : "-"
                          }
                          // پایان کار
                          endDate={
                            d?.endDateReal
                              ? new Date(d?.endDateReal).toLocaleDateString(
                                  "fa-IR"
                                )
                              : "-"
                          }
                          progress={d?.progress}
                          status={d?.status}
                          setRefreshPmoList={setRefreshPmoList}
                          remainedDay={d?.remained_day}
                          isPlan={d?.remained_weight < 1 ? true : false}
                          cost={d?.costTotalTasks}
                          duration={d?.duration}
                        />
                      ))}
                    </tbody>
                  ) : (
                    <tbody className="py-24">
                      <tr>
                        <td className="py-24">
                          <div className="flex items-center justify-center w-full py-10">
                            <div className="z-10 absolute left-1/2 -translate-x-1/2 mx-auto">
                              <div className="flex flex-col gap-2 items-center justify-center w-full">
                                <img
                                  src="/assets/images/library/not-found.svg"
                                  alt="برنامه سازمانی یافت نشد..."
                                />
                                <h2>برنامه سازمانی یافت نشد...</h2>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      {records.length > 0 && (
        <div className="flex items-center justify-between py-2">
          <RowView
            setRecordsPerPage={setRecordsPerPage}
            setCurrentPage={setCurrentPage}
          />
          <Paginate
            pageCount={npage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
          <div className="text-xs flex items-center gap-4">
            <span>نمایش {firstIndex + 1}</span>
            <span>تا {lastIndex - (recordsPerPage - records.length)}</span>
            <span>از کل {filterEndDate.length}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableListActionplan;
