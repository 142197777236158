import React, { useState } from "react";
import ActionPlanNavbar from "../ActionPlanNavbar";
import { Outlet, useOutletContext, useParams } from "react-router-dom";
import MainListActionplan from "./list-actionplan/MainListActionplan";

const MainMyActionplan = () => {
  const pmo = useOutletContext();
  const params = useParams();
  useState(false);
  const [refreshPmoList, setRefreshPmoList] = useState(0);
  return (
    <div>
      {params?.id ? (
        <div className="pb-4">

          <Outlet />
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          <ActionPlanNavbar pmo={pmo} buttonActive="my-actionplan" />
          <MainListActionplan
            refreshPmoList={refreshPmoList}
            setRefreshPmoList={setRefreshPmoList}
          />
        </div>
      )}
    </div>
  );
};

export default MainMyActionplan;
