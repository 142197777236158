import React, { useEffect, useState } from "react";
import RowView from "./RowView";
import Paginate from "./Paginate";
import { useSearchParams } from "react-router-dom";
import { PiCircleNotch } from "react-icons/pi";
import SingleTableListPmo from "./SingleTableListReport";

const TableListReport = ({ fullData, loading, setRefreshPmoList }) => {
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search");
  const page = searchParams.get("page");
  const rows = searchParams.get("rows");

  useEffect(() => {
    if (search) {
      setCurrentPage(1);
    }
  }, [search]);

  const [currentPage, setCurrentPage] = useState(page ? page : 1);
  const [recordsPerPage, setRecordsPerPage] = useState(rows ? rows : 10);
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = fullData.slice(firstIndex, lastIndex);
  const npage = Math.ceil(fullData.length / recordsPerPage);

  return (
    <div className="flex flex-col gap-4">
      {loading ? (
        <div className="flex items-center justify-center py-40">
          <PiCircleNotch className="text-caspian-secondary-600 text-2xl animate-spin" />
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          <div className="flex flex-col overflow-x-auto">
            <div className="sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <div className="overflow-x-auto">
                  <table className="min-w-full text-left text-xs font-light">
                    <thead className="border-b border-b-caspian-gray2 font-medium">
                      <tr className="">
                        <th
                          scope="col"
                          className="rounded-tr-xl bg-caspian-transparent text-right p-1 w-[25%]"
                        >
                          <input
                            // onClick={() => setCurrentPage(1)}
                            type="search"
                            placeholder="گزارش"
                            disabled
                            className="bg-caspian-transparent rounded-md outline-none p-1.5 font-light text-xs w-full"
                          />
                        </th>
                        <th
                          scope="col"
                          className="rounded-tr-xl bg-caspian-transparent text-right p-1 w-[15%]"
                        >
                          <input
                            // onClick={() => setCurrentPage(1)}
                            type="search"
                            placeholder="مسئول برنامه اجرایی"
                            disabled
                            className="bg-caspian-transparent rounded-md outline-none p-1.5 font-light text-xs w-full"
                          />
                        </th>
                        <th
                          scope="col"
                          className="rounded-tr-xl bg-caspian-transparent text-right p-1 w-[15%]"
                        >
                          <input
                            type="search"
                            placeholder="مجری"
                            disabled
                            className="bg-caspian-transparent rounded-md outline-none p-1.5 font-light text-xs w-full"
                          />
                        </th>
                        <th
                          scope="col"
                          className="bg-caspian-transparent text-right p-1 w-[15%]"
                        >
                          <input
                            type="search"
                            placeholder="ناظر"
                            disabled
                            className="bg-caspian-transparent rounded-md outline-none p-1.5 font-light text-xs w-full"
                          />
                        </th>
                        <th
                          scope="col"
                          className="bg-caspian-transparent text-right p-1 w-[15%]"
                        >
                          <input
                            type="search"
                            placeholder="وضعیت"
                            disabled
                            className="bg-caspian-transparent rounded-md outline-none p-1.5 font-light text-xs w-full"
                          />
                        </th>
                        <th
                          scope="col"
                          className="bg-caspian-transparent text-right p-1 w-[15%]"
                        >
                          <input
                            type="search"
                            placeholder="تاریخ"
                            disabled
                            className="bg-caspian-transparent rounded-md outline-none p-1.5 font-light text-xs w-full"
                          />
                        </th>
                      </tr>
                    </thead>
                    {records.length > 0 ? (
                      <tbody>
                        {records.map((d, i) => (
                          <SingleTableListPmo
                            key={i}
                            id={d.id}
                            title={d.title}
                            // مسئول برنامه اجرایی
                            manager={
                              d?.responsible_task?.firstName +
                              " " +
                              d?.responsible_task?.lastName
                            }
                            // مجری
                            manager1={
                              d?.responsible_task?.firstName +
                              " " +
                              d?.responsible_task?.lastName
                            }
                            // ناظر
                            manager2={
                              d?.responsible_task?.firstName +
                              " " +
                              d?.responsible_task?.lastName
                            }
                            // تاریخ
                            date={
                              d?.startDate
                                ? new Date(d?.startDate).toLocaleDateString(
                                    "fa-IR"
                                  )
                                : "-"
                            }
                            // وضعیت
                            status={d?.status}
                            setRefreshPmoList={setRefreshPmoList}
                          />
                        ))}
                      </tbody>
                    ) : (
                      <tbody className="py-24">
                        <tr>
                          <td className="py-24">
                            <div className="flex items-center justify-center w-full py-10">
                              <div className="z-10 absolute left-1/2 -translate-x-1/2 mx-auto">
                                <div className="flex flex-col gap-2 items-center justify-center w-full">
                                  <img
                                    src="/assets/images/library/not-found.svg"
                                    alt="برنامه سازمانی یافت نشد..."
                                  />
                                  <h2>برنامه سازمانی یافت نشد...</h2>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
          {records.length > 0 && (
            <div className="flex items-center justify-between py-2">
              <RowView
                setRecordsPerPage={setRecordsPerPage}
                setCurrentPage={setCurrentPage}
              />
              <Paginate
                pageCount={npage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
              <div className="text-xs flex items-center gap-4">
                <span>نمایش {firstIndex + 1}</span>
                <span>تا {lastIndex - (recordsPerPage - records.length)}</span>
                <span>از کل {fullData.length}</span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TableListReport;
