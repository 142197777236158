import React, { useContext, useEffect, useState } from "react";
import { PiCircleNotch } from "react-icons/pi";
// import ReferralForm from "../../forms/ReferralForm";
import API from "../../../../api/API";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../../../context/auth/AuthContext";
import RequestSentDetails from "./RequestSentDetails";
import RequestSentReplyDetails from "./RequestSentReplyDetails";
import RejectRequest from "./RejectRequest";
import AcceptRequest from "./AcceptRequest";

const NewDetails = () => {
  const { user, token } = useContext(AuthContext);
  // const [showReferralForm, setShowReferralForm] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const [serachParams, setSearchParams] = useSearchParams();
  const searchType = serachParams.get("search");
  const [successReply, setSuccessReply] = useState(0);

  const [fullData, setFullData] = useState([-1]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showReject, setShowReject] = useState(false);
  const [showAccept, setShowAccept] = useState(false);

  useEffect(() => {
    if (!token || !params?.id) return;
    let url = `/api/v1/request/${params.id}/`;
    // let url = `/api/v1/request/activity/${params.id}/`
    const getRequest = async () => {
      setLoading(true);
      try {
        const res = await API.get(url, {
          headers: { Authorization: token },
        });
        console.log(res?.data);
        setFullData(res.data);
      } catch (error) {
        console.log(error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    getRequest();
  }, [token, params?.id, successReply]);

  useEffect(() => {
    if (searchType) {
      navigate(`/requests/sent?search=${searchType}`);
    }
  }, [searchType, navigate]);
  return (
    <div className="w-2/3">
      {showReject && (
        <RejectRequest
          requestId={fullData?.id}
          setShowReject={setShowReject}
          setSuccessReply={setSuccessReply}
        />
      )}
      {showAccept && (
        <AcceptRequest
          requestId={fullData?.id}
          setShowAccept={setShowAccept}
          setSuccessReply={setSuccessReply}
        />
      )}
      {fullData[0] === -1 && !error ? (
        <div className="flex items-center justify-center py-24">
          <div className="flex items-center gap-2">
            <PiCircleNotch className="text-caspian-gray text-sm animate-spin" />
            <h2 className="text-caspian-gray text-sm">
              در حال بارگذاری اطلاعات
            </h2>
          </div>
        </div>
      ) : user?.placementProfileRelated?.id !==
          fullData?.firstActivityRelated?.sender?.id || error ? (
        <div className="flex items-center justify-center py-24">
          <h2 className="text-sm text-caspian-gray">گزارشی یافت نشد...</h2>
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          {/* شروع گزارش دریافتی */}
          <RequestSentDetails fullData={fullData} />
          {/* پایان گزارش دریافتی */}

          <div className="flex items-end justify-end">
            {fullData.status !== "unRead" ? (
              <h2 className="text-[10px] text-caspian-gray font-normal">
                در تاریخ
                {" " +
                  new Date(fullData?.readed_at).toLocaleDateString("fa-IR", {
                    hour: "2-digit",
                    minute: "2-digit",
                  }) +
                  " "}
                توسط
                {" " +
                  fullData?.firstActivityRelated?.receiver?.firstName +
                  " " +
                  fullData?.firstActivityRelated?.receiver?.lastName +
                  " "}
                مشاهده گردید.
              </h2>
            ) : (
              <h2 className="text-xs text-caspian-gray2 font-normal">
                هنوز مشاهده نشده است.
              </h2>
            )}
          </div>

          {/* شروع پاسخ دریافتی */}
          {fullData?.firstActivityRelated?.status === "done" && (
            <RequestSentReplyDetails
              fullData={fullData}
              setShowReject={setShowReject}
              setShowAccept={setShowAccept}
            />
          )}
          {/* پایان پاسخ دریافتی */}
        </div>
      )}
    </div>
  );
};

export default NewDetails;
