import React, { useContext, useEffect, useState } from "react";

import { PiPlusBold } from "react-icons/pi";
import ListSelected from "./members/ListSelected";
import { AuthContext } from "../../../../../../context/auth/AuthContext";
import API from "../../../../../../api/API";
import SupervisorMember from "./members/SupervisorMember";
import StartTimePicker from "./times/StartTimePicker";
import EndTimePicker from "./times/EndTimePicker";
import ListSelectedExpert from "./members/ListSelectedExpert";
import ListSelectedObserver from "./members/ListSelectedObserver";

const MainMembersAndTimesForm = ({
  membersList,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  supervisorSelected,
  setSupervisorSelected,
  membersSelected,
  setMembersSelected,
  membersFinalSelected,
  setMembersFinalSelected,
  membersExpertSelected,
  setMembersExpertSelected,
  membersExpertFinalSelected,
  setMembersExpertFinalSelected,
  membersObserverSelected,
  setMembersObserverSelected,
  membersObserverFinalSelected,
  setMembersObserverFinalSelected,
}) => {
  const { token } = useContext(AuthContext);
  const [supervisor, setSupervisor] = useState("");

  const [showListSupervisor, setShowListSupervisor] = useState(false);
  const [supervisorList, setSupervisorList] = useState([]);

  const [showListMembers, setShowListMembers] = useState(false);
  const [showListMembersExpert, setShowListMembersExpert] = useState(false);
  const [showListMembersObserver, setShowListMembersObserver] = useState(false);
  const [membersObject, setMembersObject] = useState([-1]);

  useEffect(() => {
    if (membersFinalSelected.length > 0) {
      setMembersObject(membersList.filter((member) => membersFinalSelected.includes(member.id)))
    }
  }, [membersList, membersFinalSelected]);

  // useEffect(() => {
  //   const getSupervisor = async () => {
  //     try {
  //       const res = await API.get("/api/v1/profile/", {
  //         headers: {
  //           Authorization: token,
  //         },
  //       });
  //       setSupervisorList(res.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getSupervisor();
  // }, [token]);
  console.log(membersObject);
  return (
    <div className="w-full flex flex-col h-full justify-between gap-2">
      <div className="flex flex-col gap-1 w-full">
        <label className="text-xs 2xl:text-sm text-caspian-gray4">
          انتخاب افراد تیم ممیزی
        </label>
        <div className="flex items-center gap-2 w-full">
          <button
            onClick={() => setShowListMembers(true)}
            className="rounded-md border border-caspian-secondary-400 p-2 flex items-center justify-between gap-1 w-full"
          >
            <div className="flex items-center gap-1.5">
              <PiPlusBold className="text-caspian-secondary-500 text-md" />
              <span className="text-caspian-secondary-500 text-xs 2xl:text-sm">
                افزودن
              </span>
            </div>
            {membersFinalSelected?.length > 0 ? (
              <span className="text-xs 2xl:text-sm">
                {membersFinalSelected?.length} فرد افزوده شد
              </span>
            ) : (
              <span className="text-xs 2xl:text-sm">فردی انتخاب نشده</span>
            )}
          </button>
        </div>
      </div>
      {showListMembers && (
        <ListSelected
          membersList={membersList}
          finalSelected={membersFinalSelected}
          setFinalSelected={setMembersFinalSelected}
          selected={membersSelected}
          setSelected={setMembersSelected}
          setShowListSelected={setShowListMembers}
          setMembersObject={setMembersObject}
          title="لیست افراد"
          url="/api/v1/profile/"
        />
      )}
      {showListMembersExpert && (
        <ListSelectedExpert
          // membersList={membersExpertList}
          finalSelected={membersExpertFinalSelected}
          setFinalSelected={setMembersExpertFinalSelected}
          selected={membersExpertSelected}
          setSelected={setMembersExpertSelected}
          setShowListSelected={setShowListMembersExpert}
          title="لیست افراد"
          url="/api/v1/profile/"
        />
      )}

      {showListMembersObserver && (
        <ListSelectedObserver
          // membersList={membersObserverList}
          finalSelected={membersObserverFinalSelected}
          setFinalSelected={setMembersObserverFinalSelected}
          selected={membersObserverSelected}
          setSelected={setMembersObserverSelected}
          setShowListSelected={setShowListMembersObserver}
          title="لیست افراد"
          url="/api/v1/profile/"
        />
      )}

      <SupervisorMember
        lable="انتخاب سرپرست تیم ممیزی"
        members={membersObject}
        // members={membersList}
        supervisor={supervisor}
        setSupervisor={setSupervisor}
        supervisorSelected={supervisorSelected}
        setSupervisorSelected={setSupervisorSelected}
        showListSupervisor={showListSupervisor}
        setShowListSupervisor={setShowListSupervisor}
      />

      <div className="flex flex-col gap-1 w-full">
        <label className="text-xs 2xl:text-sm text-caspian-gray4">
          انتخاب کارشناس فنی
        </label>
        <div className="flex items-center gap-2 w-full">
          <button
            onClick={() => setShowListMembersExpert(true)}
            className="rounded-md border border-caspian-secondary-400 p-2 flex items-center justify-between gap-1 w-full"
          >
            <div className="flex items-center gap-1.5">
              <PiPlusBold className="text-caspian-secondary-500 text-md" />
              <span className="text-caspian-secondary-500 text-xs 2xl:text-sm">
                افزودن
              </span>
            </div>
            {membersExpertFinalSelected?.length > 0 ? (
              <span className="text-xs 2xl:text-sm">
                {membersExpertFinalSelected?.length} فرد افزوده شد
              </span>
            ) : (
              <span className="text-xs 2xl:text-sm">فردی انتخاب نشده</span>
            )}
          </button>
        </div>
      </div>

      <div className="flex flex-col gap-1 w-full">
        <label className="text-xs 2xl:text-sm text-caspian-gray4">
          انتخاب مشاهده‌گر
        </label>
        <div className="flex items-center gap-2 w-full">
          <button
            onClick={() => setShowListMembersObserver(true)}
            className="rounded-md border border-caspian-secondary-400 p-2 flex items-center justify-between gap-1 w-full"
          >
            <div className="flex items-center gap-1.5">
              <PiPlusBold className="text-caspian-secondary-500 text-md" />
              <span className="text-caspian-secondary-500 text-xs 2xl:text-sm">
                افزودن
              </span>
            </div>
            {membersObserverFinalSelected?.length > 0 ? (
              <span className="text-xs 2xl:text-sm">
                {membersObserverFinalSelected?.length} فرد افزوده شد
              </span>
            ) : (
              <span className="text-xs 2xl:text-sm">فردی انتخاب نشده</span>
            )}
          </button>
        </div>
      </div>

      {/* <div className="hidden xl:flex items-center justify-between w-full gap-2">
        <div className="flex flex-col gap-1 w-full">
          <label className="text-xs 2xl:text-sm text-caspian-gray4">
            ساعت شروع
          </label>
          <StartTimePicker setStartTime={setStartTime} />
        </div>
        <div className="flex flex-col gap-1 w-full">
          <label className="text-xs 2xl:text-sm text-caspian-gray4">
            ساعت پایان
          </label>
          <EndTimePicker setEndTime={setEndTime} />
        </div>
      </div> */}
    </div>
  );
};

export default MainMembersAndTimesForm;
