import React, { useEffect, useRef, useState } from "react";
import { PiFolder } from "react-icons/pi";
import RightClickDetails from "./RightClickDetails";

const SingleNotebook = ({ data }) => {
  let menuRef = useRef();

  const [open, setOpen] = useState(false);
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleRightClick = (e) => {
    e.preventDefault(); // جلوگیری از نمایش منوی راست کلیک مرورگر
    setOpen(!open);
  };
  return (
    <div
      ref={menuRef}
      onContextMenu={handleRightClick}
      className={`flex items-center flex-col gap-0 hover:bg-caspian-surface rounded-md p-1 w-24 relative`}
    >
      <PiFolder className="text-caspian-secondary-400 text-4xl" />
      <input
        className="outline-none text-xs cursor-default bg-caspian-transparent text-center"
        readOnly
        defaultValue={
          data?.title?.length > 12
            ? data?.title?.slice(0, 12) + "..."
            : data?.title
        }
      />
      <RightClickDetails open={open} setOpen={setOpen} notebookId={data?.id} />
    </div>
  );
};

export default SingleNotebook;
