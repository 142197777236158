import { PiX } from "react-icons/pi";
import CreateOfficeForm from "./CreateOfficeForm";

const MainCreateOfficeForm = ({ setRefresh, setShowCreateOfficeForm }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full z-50">
      <div className="fixed inset-0 bg-caspian-gray3/50 z-40 p-4"></div>
      <div className="z-50 fixed flex items-center justify-center p-2">
        <div className="min-w-[50vw] max-w-[50vw] bg-caspian-surface shadow-[0_0_5px_#00000020] rounded-lg flex flex-col gap-3 py-3 px-4">
          <div className="flex items-center justify-between">
            <h2 className="text-sm font-bold">ایجاد برنامه اجرایی سازمانی</h2>
            <PiX
              onClick={() => setShowCreateOfficeForm(false)}
              className="cursor-pointer z-50"
            />
          </div>
          <p className="text-xs text-caspian-gray">
            برای ایجاد برنامه اجرایی اطلاعات خواسته شده را وارد کنید.
          </p>
          <CreateOfficeForm
            setRefresh={setRefresh}
            setShowCreateOfficeForm={setShowCreateOfficeForm}
          />
        </div>
      </div>
    </div>
  );
};

export default MainCreateOfficeForm;
