import React, { useContext, useEffect, useState } from "react";
import Paginate from "./Paginate";
import { AuthContext } from "../../../context/auth/AuthContext";
import API from "../../../api/API";
import { useSearchParams } from "react-router-dom";
import { PiCircleNotch } from "react-icons/pi";
import SingleTableReferRequest from "./SingleTableReferRequest";

const TableReferRequest = () => {
  const [searchParams] = useSearchParams();
  const [fullData, setFullData] = useState([-1]);
  const [loading, setLoading] = useState(false);
  const { token } = useContext(AuthContext);
  const page = searchParams.get("page");

  const search = searchParams.get("search");

  const [searchBySent, setSearchBySent] = useState("");
  const [searchByDescription, setSearchDescription] = useState("");
  const [searchByDate, setSearchDate] = useState("");

  useEffect(() => {
    if (token) {
      setLoading(true);
      API.get("/api/v1/request/request/sender", {
        headers: {
          Authorization: token,
        },
      })
        .then((d) => {
          setFullData(d.data);
        })
        .catch((err) => {
          console.log("error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);

  useEffect(() => {
    if (search) {
      setCurrentPage(1);
    }
  }, [search]);

  console.log(fullData);

  let data =
    fullData.length > 0 &&
    fullData.map((d) => ({
      id: d.id,
      firstname: d.reciver && d.reciver.placementProfileRelated.firstName,
      lastname: d.reciver && d.reciver.placementProfileRelated.lastName,
      fullname:
        d.reciver &&
        d.reciver.placementProfileRelated.firstName +
          " " +
          d.reciver.placementProfileRelated.lastName,
      job:
        d.reciver && d.reciver.placementPositionRelated === null
          ? "نامشخص"
          : d.reciver &&
            d.reciver.placementPositionRelated.positionJobRelated
              .jobLevelRelated.title,
      title: d.RequestRelated && d.RequestRelated.title,
      subject: d.RequestRelated && d.RequestRelated.description,
      date: d.created_at,
    }));
  // console.log(data);

  let newData = [-1];
  if (data[0] !== -1) {
    newData = search
      ? data.filter(
          (d) =>
            d.title !== undefined &&
            d.title.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        )
      : data;
  }

  let searchSent = [];
  let searchDescription = [];
  let searchDate = [];

  if (newData[0] !== -1) {
    searchSent = searchBySent
      ? newData.filter((d) =>
          d.fullname
            .toLocaleLowerCase()
            .includes(searchBySent.toLocaleLowerCase())
        )
      : newData;
    searchDescription = searchByDescription
      ? searchSent.filter((d) =>
          d.subject.toString().includes(searchByDescription)
        )
      : searchSent;
    searchDate = searchByDate
      ? searchDescription.filter((d) => d.date.includes(searchByDate))
      : searchDescription;
  }
  const [currentPage, setCurrentPage] = useState(page ? page : 1);
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records =
    searchDate.length > 0 &&
    searchDate
      .sort((a, b) => (b.date > a.date ? 1 : -1))
      .slice(firstIndex, lastIndex);
  const npage = Math.ceil(searchDate.length / recordsPerPage);
  return (
    <div className="flex flex-col gap-4 px-8 xl:px-16">
      <>
          <div className="overflow-x-auto min-h-[240px]">
            <table className=" min-w-[992px] w-full text-sm font-light">
              <thead className="border-b border-b-caspian-gray2 font-medium">
                <tr className="w-full">
                  <th
                    colSpan={1}
                    scope="col"
                    className="bg-caspian-primary-500 rounded-tr-xl relative p-2 w-[35%]"
                  >
                    <input
                      onChange={(e) => {
                        setSearchBySent(e.target.value);
                        setCurrentPage(1);
                      }}
                      type="search"
                      id="t1"
                      className={`${
                        searchBySent
                          ? "border border-caspian-surface/50 "
                          : "border border-caspian-transparent"
                      } text-[#FFF7CB] bg-caspian-transparent p-1.5 rounded-md outline-none  w-full focus:border focus:border-caspian-surface/50 pr-12 font-light peer cursor-pointer`}
                    />
                    <label  htmlFor="t1"
                            className={`${
                              searchBySent ? "right-0 left-auto" : ""
                            } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 bg-caspian-primary-500 rounded-md border border-caspian-primary-500 cursor-pointer font-light
                            peer-focus:left-auto peer-focus:right-0`}
                          >گیرنده</label>
                  </th>
                  <th
                    colSpan={1}
                    scope="col"
                    className="bg-caspian-primary-500 relative p-2 w-[50%]"
                  >
                    <input
                      onChange={(e) => {
                        setSearchDescription(e.target.value);
                        setCurrentPage(1);
                      }}
                      type="search"
                      id="t2"
                      className={`${
                        searchByDescription
                          ? "border border-caspian-surface/50 "
                          : "border border-caspian-transparent"
                      } text-[#FFF7CB] bg-caspian-transparent p-1.5 rounded-md outline-none w-full focus:border focus:border-caspian-surface/50 pr-14 font-light peer cursor-pointer`}
                    />
                    <label  htmlFor="t2"
                            className={`${
                              searchByDescription ? "right-0 left-auto" : ""
                            } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 bg-caspian-primary-500 rounded-md border border-caspian-primary-500 cursor-pointer font-light
                            peer-focus:left-auto peer-focus:right-0`}
                          >موضوع</label>
                  </th>
                  <th
                    colSpan={1}
                    scope="col"
                    className="bg-caspian-primary-500 relative rounded-tl-xl p-2 w-[15%]"
                  >
                    <input
                      onChange={(e) => setSearchDate(e.target.value)}
                      type="search"
                      id="t3"
                      className={`${
                        searchByDate
                          ? "border border-caspian-surface/50 "
                          : "border border-caspian-transparent"
                      } text-[#FFF7CB] bg-caspian-transparent p-1.5 rounded-md outline-none  w-full focus:border focus:border-caspian-surface/50 pr-10 font-light peer cursor-pointer`}
                    />
                    <label  htmlFor="t3"
                            className={`${
                              searchByDate ? "right-0 left-auto" : ""
                            } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 bg-caspian-primary-500 rounded-md border border-caspian-primary-500 cursor-pointer font-light
                            peer-focus:left-auto peer-focus:right-0`}
                          >تاریخ</label>
                  </th>
                </tr>
              </thead>
              {fullData[0] === -1 ? (
                <tbody>
                  <tr>
                    <td className="py-24">
                      <div className="flex items-center justify-center w-full py-10">
                        <div className="z-10 absolute left-1/2 -translate-x-1/2 mx-auto">
                          <div className="flex items-center gap-2">
                            <PiCircleNotch className="text-caspian-gray text-sm animate-spin" />
                            <h2 className="text-caspian-gray text-sm">
                              در حال بارگذاری گزارش های ارسالی...
                            </h2>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : fullData.length < 1 ? (
                <tbody className="py-24">
                  <tr>
                    <td className="py-24">
                      <div className="flex items-center justify-center w-full py-10">
                        <div className="z-10 absolute left-1/2 -translate-x-1/2 mx-auto">
                          <div className="flex flex-col gap-2 items-center justify-center w-full">
                            <img
                              src="/assets/images/library/not-found.svg"
                              alt="گزارش های دریافتی خالی می باشد"
                            />
                            <h2>گزارش های ارسالی خالی می باشد</h2>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {records.length > 0 ? (
                    records.map((d, i) => (
                      <SingleTableReferRequest
                        key={i}
                        id={d.id}
                        type="send"
                        to={d.firstname + " " + d.lastname}
                        jobSender={d.job}
                        title={d.title}
                        subject={d.subject}
                        date={d.date}
                      />
                    ))
                  ) : (
                    <tr>
                      <td className="py-24">
                        <div className="flex items-center justify-center w-full py-10">
                          <div className="z-10 absolute left-1/2 -translate-x-1/2 mx-auto">
                            <div className="flex flex-col gap-2 items-center justify-center w-full">
                              <img
                                src="/assets/images/library/not-found.svg"
                                alt="گزارشی مورد نظر یافت نشد..."
                              />
                              <h2>گزارش مورد نظر یافت نشد...</h2>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
          </div>
        {fullData[0] !== -1 && records.length > 0 ? (
          <Paginate
            pageCount={npage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : (
          <></>
        )}
      </>
    </div>
  );
};

export default TableReferRequest;
