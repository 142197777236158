import React from "react";
import MessageNavbar from "../MessageNavbar";
import SearchInput from "./SearchInput";
import NewMessage from "../NewMessage";
import { PiDotsThreeVertical } from "react-icons/pi";
import { useAppContext } from "../../../context/app-context/AppContext";
import { useEffect } from "react";

const SavedMessage = ({ showMenu }) => {
  const { setBradCrump1, setBradCrump2, setBradCrump3 } = useAppContext();
  useEffect(() => {
    setBradCrump1("پیام ها");
    setBradCrump2("پیام های ذخیره شده");
    setBradCrump3("");
  }, [setBradCrump1, setBradCrump2, setBradCrump3]);

  return (
    <div
      className={
        showMenu
          ? `pl-[60px] mr-auto w-full md:w-[75%] lg:w-[80%] xl:w-[85%] 2xl:w-[85%] bg-caspian-gray3 p-4 overflow-y-scroll`
          : `pl-[60px] mr-[54px] w-full bg-caspian-gray3 p-4 overflow-y-scroll`
      }
    >
      <div className="flex flex-col gap-4 h-full">
        <div className="flex items-center gap-2">
          {/* <SearchInput /> */}
          <NewMessage />
        </div>
        <div className="flex flex-col gap-0.5 h-full">
          <div className="flex items-center gap-4 px-5 py-2 bg-caspian-surface">
            <input type="checkbox" className="w-4 h-4" />
            <button>
              <PiDotsThreeVertical className="text-2xl" />
            </button>
          </div>
          <MessageNavbar buttonActive="saved" />
        </div>
      </div>
    </div>
  );
};

export default SavedMessage;
