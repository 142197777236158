const ButtonTab = ({ title, onClick, activeTab, active }) => {
  return (
    <button
      onClick={onClick}
      className={`${
        activeTab === active
          ? " border-b-caspian-secondary-500 text-caspian-secondary-500 cursor-default"
          : "border-b-caspian-transparent cursor-pointer"
      } border-b w-fit text-sm p-1`}
    >
      {title}
    </button>
  );
};

export default ButtonTab;
