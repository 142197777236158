import React, { useState } from "react";
import ButtonTab from "./ButtonTab";
import TitleAndDescription from "./forms/TitleAndDescription";
import TimeAndPlace from "./forms/TimeAndPlace";
import MeetingOrder from "./forms/MeetingOrder";
import PresentationFile from "./forms/PresentationFile";
import AddGuest from "./forms/AddGuest";
import Reminder from "./forms/Reminder";
// import Invitation from "./forms/Invitation";

const EditPlanningMeetingForm = ({
  thisId,
  setThisId,
  setShowEditPlanningMeetingForm,
  fullData,
  setRefresh,
  setMeetingListRefresh,
}) => {
  const [activeTab, setActiveTab] = useState("titleAndDescription");

  // useEffect(() => {
  //   if (fullData?.status === "general") {
  //     setActiveTab("titleAndDescription");
  //   } else if (fullData?.status === "invitation") {
  //     setActiveTab("addGuest");
  //   } else if (fullData?.status === "date") {
  //     setActiveTab("timeAndPlace");
  //   } else if (fullData?.status === "agenda") {
  //     setActiveTab("meetingOrder");
  //   } else if (fullData?.status === "presentation") {
  //     setActiveTab("presentationFile");
  //   } else if (fullData?.status === "reminder") {
  //     setActiveTab("reminder");
  //   }
  // }, [fullData]);
  return (
    <div className="flex flex-col justify-between h-full">
      {/* تب های فرم برنامه ریزی برگزار جلسه */}
      <div className="flex items-center justify-between gap-4 bg-caspian-gray2/40 py-1.5 px-2 rounded-md">
        <ButtonTab
          activeTab={activeTab}
          active="titleAndDescription"
          onClick={() => setActiveTab("titleAndDescription")}
          title="عنوان و توضیحات"
        />
        <ButtonTab
          activeTab={activeTab}
          active="addGuest"
          onClick={() => setActiveTab("addGuest")}
          title="افزودن مهمانان"
        />
        <ButtonTab
          activeTab={activeTab}
          active="timeAndPlace"
          onClick={() => setActiveTab("timeAndPlace")}
          title="زمان و محل جلسه"
        />
        <ButtonTab
          activeTab={activeTab}
          active="meetingOrder"
          onClick={() => setActiveTab("meetingOrder")}
          title="دستور جلسه"
        />
        <ButtonTab
          activeTab={activeTab}
          active="presentationFile"
          onClick={() => setActiveTab("presentationFile")}
          title="فایل ارائه"
        />
        <ButtonTab
          activeTab={activeTab}
          active="reminder"
          onClick={() => setActiveTab("reminder")}
          title="یادآوری"
        />
        {/* <ButtonTab
          activeTab={activeTab}
          active="invitation"
          onClick={() => setActiveTab("invitation")}
          title="دعوت نامه"
        /> */}
      </div>
      <div className="min-h-[270px] h-full flex flex-col justify-between">
        <div className="py-3">
          {/* عنوان و توضیحات */}
          {activeTab === "titleAndDescription" && (
            <TitleAndDescription
              setMeetingListRefresh={setMeetingListRefresh}
              fullData={fullData}
              meetingProfile={{
                id: fullData?.meetingProfileRelated?.id,
                title: fullData?.meetingProfileRelated?.title,
              }}
              setActiveTab={setActiveTab}
              setShowEditPlanningMeetingForm={setShowEditPlanningMeetingForm}
              thisId={thisId}
              setRefresh={setRefresh}
            />
          )}
          {/* افزودن مهمان */}
          {activeTab === "addGuest" && (
            <AddGuest
              setMeetingListRefresh={setMeetingListRefresh}
              fullData={fullData?.meetingMemberRelated?.filter(
                (d) => d?.memberType === "guest" || d?.memberType === "company"
              )}
              setActiveTab={setActiveTab}
              setShowEditPlanningMeetingForm={setShowEditPlanningMeetingForm}
              thisId={thisId}
              setRefresh={setRefresh}
            />
          )}
          {/* زمان و محل جلسه */}
          {activeTab === "timeAndPlace" && (
            <TimeAndPlace
              setMeetingListRefresh={setMeetingListRefresh}
              fullData={fullData?.meetingDatetimeRelated}
              setActiveTab={setActiveTab}
              setShowEditPlanningMeetingForm={setShowEditPlanningMeetingForm}
              thisId={thisId}
              setRefresh={setRefresh}
            />
          )}
          {/* دستور جلسه */}
          {activeTab === "meetingOrder" && (
            <MeetingOrder
              setMeetingListRefresh={setMeetingListRefresh}
              setActiveTab={setActiveTab}
              setShowEditPlanningMeetingForm={setShowEditPlanningMeetingForm}
              thisId={thisId}
              setRefresh={setRefresh}
              fullData={fullData?.meetingProfileRelated}
              meetingProfileRelated={fullData?.meetingProfileRelated}
              members={fullData?.meetingMemberRelated}
              meetingAgendaRelated={fullData?.meetingAgendaRelated}
            />
          )}
          {/* فایل ارائه */}
          {activeTab === "presentationFile" && (
            <PresentationFile
              setMeetingListRefresh={setMeetingListRefresh}
              fullData={fullData?.MeetingPresentationRelated}
              documentsFile={fullData?.MeetingPresentationRelated.filter(
                (d) => d.documentsRelated.length > 0
              )}
              thisId={thisId}
              setActiveTab={setActiveTab}
              setRefresh={setRefresh}
              setShowEditPlanningMeetingForm={setShowEditPlanningMeetingForm}
            />
          )}
          {/* یادآوری */}
          {activeTab === "reminder" && (
            <Reminder
              setMeetingListRefresh={setMeetingListRefresh}
              thisTitle={fullData?.title}
              fullData={fullData?.meetingReminderRelated}
              setRefresh={setRefresh}
              thisId={thisId}
              setActiveTab={setActiveTab}
              setShowEditPlanningMeetingForm={setShowEditPlanningMeetingForm}
            />
          )}
          {/* دعوت نامه */}
          {/* {activeTab === "invitation" && (
            <Invitation
              thisId={thisId}
              setActiveTab={setActiveTab}
              setShowEditPlanningMeetingForm={setShowEditPlanningMeetingForm}
            />
          )} */}
        </div>
      </div>
    </div>
  );
};

export default EditPlanningMeetingForm;
