import React from "react";
import NewDetails from "./NewDetails";

const MainDetailsSingleRecevied = () => {
  return (
    <div className="min-h-screen">
      <NewDetails />
    </div>
  );
};

export default MainDetailsSingleRecevied;
