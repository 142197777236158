import React, { useState } from 'react';
import SingeListTable from './SingeListTable';
import SearchList from './SearchList';
import PaginateApi from '../../../ui/PaginateApi';
import RowViewApi from '../../../ui/RowViewApi';
import { useSearchParams } from 'react-router-dom';
import Loading from '../../../../Loading';
const ListGoalsTable = ({ data, title, cat, setCat, post, setTitle, setPost, search, setSearch, offset, setOffset, limit, setLimit, setGroup }) => {

    const [searchParams] = useSearchParams();

    const page = searchParams.get("page");
    // const rows = searchParams.get("rows");

    const [currentPage, setCurrentPage] = useState(page ? page : 1);

    const lastIndex = currentPage * limit;
    const firstIndex = lastIndex - limit
    const npage = Math.ceil(data?.count / limit);
    return (
        <div className='py-10 px-8 xl:px-16 flex flex-col gap-6'>
            <SearchList search={search} setSearch={setSearch} setCurrenPage={setCurrentPage} setOffset={setOffset} />

                        <div className="w-full overflow-x-auto">
                            <table className='min-w-[992px] w-full table-fixed text-xs xl:text-sm rounded-tr-xl rounded-tl-xl bg-caspian-surface'>
                                <thead className=''>
                                    <tr className='rounded-tr-xl rounded-tl-xl'>
                                        <th className='bg-caspian-primary-500 w-[50%] p-2 relative rounded-tr-xl'>
                                            <input id='t3' type="text"
                                                className={`${title ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-16 border border-caspian-gray2`}
                                                onChange={(e) => { setTitle(e.target.value) }}
                                            />
                                            <label htmlFor="t3"
                                                className={`${title ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface whitespace-nowrap peer-focus:right-3`}>
                                                هدف خرد
                                            </label>
                                        </th>
                                        <th className='bg-caspian-primary-500 w-[30%] p-2 relative'>
                                            <input id='t2' type="text"
                                                className={`${post ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-[68px] border border-caspian-gray2`}
                                                onChange={(e) => { setPost(e.target.value) }}
                                            />
                                            <label htmlFor="t2"
                                                className={`${post ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface whitespace-nowrap peer-focus:right-3`}>
                                                هدف کلان
                                            </label>
                                        </th>

                                        <th className='bg-caspian-primary-500 w-[20%] px-2 py-1 rounded-tl-xl relative'>
                                            <input id='t1' type="text" className={`${cat ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-10 border border-caspian-gray2`}
                                                onChange={(e) => { setCat(e.target.value) }}
                                            />
                                            <label htmlFor="t1"
                                                className={`${cat ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface whitespace-nowrap peer-focus:right-3`}>
                                                مناظر
                                            </label>
                                        </th>
                                        {/* <th className='w-[30%] p-2 rounded-tl-xl relative'>
                                        <input id='t4' type="text"
                                            className={`${group ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50 cursor-default bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 px-10 border border-caspian-gray2`}
                                            onChange={(e) => { setGroup(e.target.value) }}
                                        />
                                        <label htmlFor="t4"
                                            className={`${group ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface peer-focus:right-3`}>
                                            گروه
                                        </label>
                                    </th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data[0]===-1?(
                                            <tr><td><Loading /></td></tr>
                                        ):
                                        data?.results?.length > 0 ? (data?.results?.map(item => <SingeListTable {...item} key={item.id} />)) : (<tr><td colSpan={3} className='bg-caspian-gray3'>
                                            <div className='py-5 flex flex-col gap-3 items-center justify-center'>
                                                <div className='w-[220px] h-[220px] flex justify-center'>
                                                    <img src="/assets/images/library/not-found.svg" alt="هدف یافت نشد..." />
                                                </div>
                                                <h2 className='text-center text-sm'> هدف یافت نشد...</h2>
                                            </div>
                                        </td></tr>)
                                    }
                                </tbody>
                            </table>
                        </div>

            {
                data?.results?.length > 0 && (<div className="flex items-center justify-between">
                    <RowViewApi
                        limit={limit}
                        setOffset={setOffset}
                        setLimit={setLimit}
                        setCurrentPage={setCurrentPage}
                    />
                    <PaginateApi
                        pageCount={npage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        setOffset={setOffset}
                        offset={offset}
                    />
                    <div className='flex gap-3 text-xs 2xl:text-sm'>
                        <span>نمایش {firstIndex + 1}</span>
                        <span>تا {lastIndex - (limit - data?.results?.length)}</span>
                        <span>از کل {data?.count}</span>
                    </div>
                </div>)
            }
        </div>
    );
}

export default ListGoalsTable;
