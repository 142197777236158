import React, { useEffect, useRef, useState } from "react";
import {
  PiBookmarkSimple,
  PiBookmarkSimpleFill,
  PiTimer,
  PiTrash,
} from "react-icons/pi";
import RightClickDetails from "./RightClickDetails";

const SingleNote = ({ data }) => {

  let menuRef = useRef();

  const [open, setOpen] = useState(false);
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleRightClick = (e) => {
    e.preventDefault(); // جلوگیری از نمایش منوی راست کلیک مرورگر
    setOpen(!open);
  };
  return (
    <div
      ref={menuRef}
      onContextMenu={handleRightClick}
      className={`relative min-w-[240px] max-w-[240px] min-h-[240px] max-h-[240px] rounded-md shadow-[0_0_5px_#00000050] border border-caspian-gray2 p-2 w-full ${
        data.color ? `bg-[${data.color}] bg-opacity-50` : "bg-caspian-surface"
      }`}
    >
      <div className="flex flex-col justify-between w-full h-full">
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-1">
            <h2 className="text-xs font-bold">{data.title}</h2>
            <span className="text-caspian-gray4 text-xs">
              {new Date(data.created_at).toLocaleDateString("fa-IR", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </span>
          </div>
          <div className="flex flex-col gap-2 w-full">
            <p className="text-xs line-clamp-4">{data.content}</p>
            <span className="text-caspian-gray4 text-xs flex justify-end w-full">
              {/* {data.update_at === data.created_at
                ? ""
                : "ویرایش " +
                  new Date(data.update_at).toLocaleDateString("fa-IR", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })} */}
            </span>
          </div>
        </div>
        <div className="flex items-center justify-between w-full text-caspian-gray4">
          <div className="flex items-center gap-2">
            {data.fav ? (
              <PiBookmarkSimpleFill
                // onClick={async () => {
                //   await unfavHandler(noteId);
                // }}
                className="cursor-pointer"
              />
            ) : (
              <PiBookmarkSimple
                // onClick={async () => {
                //   await favHandler(noteId);
                // }}
                className="cursor-pointer"
              />
            )}

            <PiTrash
              //   onClick={async () => {
              //     await trashHandler(noteId);
              //   }}
              className="cursor-pointer"
            />
          </div>
          <PiTimer className="cursor-pointer" />
        </div>
      </div>
      <RightClickDetails open={open} setOpen={setOpen} notebookId={data?.id} />
    </div>
  );
};

export default SingleNote;
