import React from 'react'

import MainItemListBlog from './item-list-blog/MainItemListBlog'
const MainListBlog = () => {
  return (
    <div>
      <MainItemListBlog />
    </div>
  )
}

export default MainListBlog