import TopProfile from './TopProfile';
import BottomProfile from './BottomProfile';

const MainRightComponent = ({data}) => {
    return (

        <div className="flex flex-col gap-3 text-sm 2xl:text-base">

            <TopProfile data={data} />
            <BottomProfile data={data} />
        </div>
    );
}

export default MainRightComponent;
