import Group from "./Group";

const Category = ({ dataGroup, title }) => {
  // console.log(dataGroup);
  return (
    <div className="flex-none flex items-center justify-center gap-0 w-[30%] h-full rtl">
      <div
        className="w-0 h-0
          border-t-[8rem] 
          border-t-caspian-transparent
          border-l-[10px] 
        border-l-[#DADBF5]
          border-b-[8rem] 
          border-b-caspian-transparent"
      ></div>
      <div className="bg-[#DADBF5] w-full p-2 rounded-xl flex items-center justify-center gap-0 h-full">
        <div className="flex flex-col items-center justify-between h-full gap-2">
          <h2 className="text-sm underline underline-offset-8 text-center">
            {title}
          </h2>
          <div className="flex flex-col gap-2 w-full">
            {/* Start Group */}
            {dataGroup.map((d, i) => (
              <Group key={i} dataProcessList={d} />
            ))}
            {/* End Group */}
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Category;
