import { useNavigate, useSearchParams } from "react-router-dom";
import { PiBookmarkSimple, PiCloudArrowDown, PiX } from "react-icons/pi";
import { useState } from "react";
import { useAppContext } from "../../../../context/app-context/AppContext";

const SingleTableInternalDocuments = ({
  id,
  idProfile,
  title,
  code,
  type,
  ownerParts,
  users,
  fileDocument,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const { setBradCrump3 } = useAppContext();

  const [showDownloadBox, setShowDownloadBox] = useState(false);
  const [deActiveProfileLink, setDeActiveProfileLink] = useState(false);

  const [showUsersList, setShowUsersList] = useState(false);

  return (
    <tr
      onClick={() => {
        // !deActiveProfileLink && setBradCrump3(title);
        // !deActiveProfileLink &&
        //   navigate(`${code}?ver=1`, {
        //     state: { search: `?${searchParams.toString()}` },
        //   });
        !showDownloadBox &&
          window.open(
            `/documents-and-records/documents/internal-documents/${idProfile}?ver=1`,
            "_blank"
          );
      }}
      className="cursor-pointer bg-caspian-surface duration-300 hover:bg-caspian-gray3/90"
    >
      <td
        // colSpan={3}
        className="text-center border-b border-b-caspian-gray2 px-3 py-2 w-[30%]"
      >
        <h2 className="line-clamp-1 text-xs">{title}</h2>
      </td>
      <td
        // colSpan={1}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 font-sans w-[10%]"
      >
        <h2 className="text-xs">{code}</h2>
      </td>
      <td
        // colSpan={1}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[15%]"
      >
        <h2 className="text-xs">{type}</h2>
      </td>
      <td
        // colSpan={3}
        className="text-center border-b border-b-caspian-gray2 px-3 py-2 w-[15%]"
      >
        <h2 className="text-xs">{ownerParts}</h2>
      </td>
      <td
        // colSpan={4}
        onMouseEnter={() => setShowDownloadBox(true)}
        onMouseLeave={() => setShowDownloadBox(false)}
        className="text-center border-b border-b-caspian-gray2 px-3 py-2 w-[30%]"
      >
        {showUsersList && (
          <div className="z-50 w-[190px] max-h-[400px] absolute left-10 top-1/2 -translate-y-1/2 bg-caspian-on_primary/90 rounded-md shadow-[0_0_10px_#00000050] flex flex-col gap-0">
            <div className="p-1 flex items-center justify-between w-full">
              <PiX
              className="text-xl text-caspian-surface mr-auto"
                onClick={() => {
                  setShowDownloadBox(false);
                  setShowUsersList(false);
                }}
              />
            </div>
            <div className="p-2 pt-0 flex flex-col gap-2 overflow-y-scroll">
              {users?.split(",").map((d, i) => (
                <h2 className="text-xs text-caspian-surface" key={i}>{d}</h2>
              ))}
            </div>
          </div>
        )}
        <div
          onClick={() => showDownloadBox && setShowUsersList(true)}
          // onMouseLeave={() => setShowUsersList(false)}
          className="flex items-center justify-center w-full"
        >
          <h2 className="text-xs">
            {users?.length > 24 ? users?.slice(0, 24) + "..." : users}
          </h2>
          {/* <div
            onMouseEnter={() => setDeActiveProfileLink(true)}
            onMouseLeave={() => setDeActiveProfileLink(false)}
            className={
              showDownloadBox
                ? "flex items-center gap-2 ml-4 absolute top-1/2 -translate-y-1/2 left-4 p-1"
                : "hidden"
            }
          >
            <button>
              <PiBookmarkSimple className="text-xl text-caspian-secondary-500" />
            </button>
            {fileDocument && (
              <a
                href={fileDocument}
                download={fileDocument}
                target="_blank"
                rel="noreferrer"
                className=""
              >
                <PiCloudArrowDown className="text-xl text-caspian-secondary-500" />
              </a>
            )}
          </div> */}
        </div>
      </td>
    </tr>
  );
};

export default SingleTableInternalDocuments;
