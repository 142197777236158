import React, { useContext, useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import {
  PiCalendar,
  PiCaretDownBold,
  PiCircleNotch,
  PiFileTextFill,
  PiMagnifyingGlassBold,
  PiPlus,
  PiX,
} from "react-icons/pi";
import API from "../../../api/API";
import { AuthContext } from "../../../context/auth/AuthContext";
import DateTimePickerCDTAD from "../../../layout/DateTimePickerCDTAD";
import { toast } from "react-toastify";

export const isEmpty = (value) => {
  if (!value) return true;
  return false;
};

const initialState = {
  title: "",
  description: "",
  privacy: "internal",
  periodRequest: "بدون دوره درخواست",
  source: "personal",
  document: null,
};

const RequestFormNew = ({
  showRequestForm,
  setShowRequestForm,
  setRefreshListSender,
}) => {
  const titleRef = useRef();
  // const reciverRef = useRef();
  const descriptionRef = useRef();
  // const deadlineRef = useRef();
  const fileRef = useRef(null);
  const { token } = useContext(AuthContext);
  const [data, setData] = useState(initialState);
  const [loading, setLoading] = useState(false);

  const { title, description, privacy, periodRequest } = data;
  const [errorTitle, setErrorTitle] = useState(false);
  const [errorReciver, setErrorReciver] = useState(false);
  const [errorDescription, setErrorDescription] = useState(false);
  const [errorDeadline, setErrorDeadline] = useState(false);
  const [reciver, setReciver] = useState("");
  const [dateTahvil, setDateTahvil] = useState("");

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const [file, setFile] = useState(null);
  // console.log(file);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!token) return;
    if (isEmpty(title)) {
      return setErrorTitle(true);
    } else {
      setErrorTitle(false);
    }
    if (isEmpty(reciver)) {
      return setErrorReciver(true);
    } else {
      setErrorReciver(false);
    }
    if (dateTahvil === undefined || dateTahvil === null) {
      return setErrorDeadline(true);
    } else {
      setErrorDeadline(false);
    }
    if (isEmpty(description)) {
      return setErrorDescription(true);
    } else {
      setErrorDescription(false);
    }

    const formData = {
      title: title,
      receiver: reciver,
      description: description,
      privacy: privacy,
      period: periodRequest,
      deadLine: dateTahvil,
      source: "personal",
      document: file ? file : null,
    };
    console.log(formData);
    setLoading(true);
    try {
      const res = await API.post("/api/v1/request/", formData, {
        headers: {
          Authorization: token,
          "content-type": "multipart/form-data",
          Accept: "application/json",
        },
      });
      setShowRequestForm(false);
      toast.error("درخواست گزارش با موفقیت ارسال شد.", {
        className: "toast-success",
        bodyClassName: "toast-success",
      });
      setRefreshListSender(1);
      console.log(res.data);
    } catch (error) {
      toast.error("متاسفانه مشکلی به وجود آمد!", {
        className: "toast-faild",
        bodyClassName: "toast-faild",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const clearHandler = (e) => {
    e.preventDefault();
    setShowRequestForm(false);
  };

  const [showListHeadMeeting, setShowListHeadMeeting] = useState(false);
  useState(false);

  const [members, setMembers] = useState([-1]);

  let url2 = "/api/v1/organization/role/subordinate";
  useEffect(() => {
    const getMembers = () => {
      API.get(url2, {
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          setMembers(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getMembers();
  }, [token, url2]);

  // رئیس جلسه
  const [headSelected, setHeadSelected] = useState(null);
  const [searchByHead, setSearchByHead] = useState("");
  let searchHead = [];
  if (members.length > 0) {
    searchHead = searchByHead
      ? members.filter(
          (d) =>
            (d.placementPositionRelated?.title !== undefined &&
              d.placementPositionRelated?.title
                .toLocaleLowerCase()
                .includes(searchByHead.toLocaleLowerCase())) ||
            (d.placementProfileRelated !== undefined &&
              (
                d.placementProfileRelated?.firstName +
                " " +
                d.placementProfileRelated?.lastName
              )
                .toLocaleLowerCase()
                .includes(searchByHead.toLocaleLowerCase()))
        )
      : members;
  }

  return (
    <>
      {showRequestForm && (
        <Draggable handle="strong">
          <div className="bg-caspian-surface rounded-md shadow-[0_0_5px_#00000050] w-[400px] fixed bottom-2 left-16 z-50">
            <strong className="flex items-center justify-between cursor-move p-4">
              <span className="text-sm">درخواست گزارش</span>
              <button
                disabled={loading ? true : false}
                className="disabled:cursor-not-allowed disabled:text-caspian-gray"
              >
                <PiX onClick={clearHandler} className="text-xl" />
              </button>
            </strong>
            <form
              onSubmit={submitHandler}
              className="flex flex-col gap-3 p-3 pt-0"
            >
              {/* شروع عنوان گزارش */}
              <div className="relative flex flex-col gap-2">
                <label className="text-xs font-bold text-caspian-on_primary/50">
                  عنوان گزارش
                </label>
                <div className="relative">
                  <input
                    ref={titleRef}
                    placeholder="عنوان گزارش را وارد کنید"
                    name="title"
                    type="text"
                    onChange={handleChange}
                    className="p-1.5 text-sm rounded-md outline outline-1 outline-caspian-gray2 w-full"
                  />
                  {errorTitle && (
                    <span className="text-caspian-error text-xs absolute left-2 top-1/2 -translate-y-1/2">
                      عنوان گزارش را وارد کنید
                    </span>
                  )}
                </div>
              </div>
              {/* پایان عنوان گزارش */}

              {/* شروع مسئول گزارش جدید */}
              <div className="relative flex flex-col gap-2">
                <label className="text-xs font-bold text-caspian-on_primary/50">
                  مسئول ارائه گزارش
                </label>

                <div
                  onClick={() => {
                    setShowListHeadMeeting(!showListHeadMeeting);
                  }}
                  className="text-xs outline outline-1 outline-caspian-gray1 p-1.5 rounded-md bg-caspian-transparent cursor-pointer flex items-center justify-between relative"
                >
                  {errorReciver && (
                    <span className="text-caspian-error text-xs absolute left-8 top-1/2 -translate-y-1/2">
                      مسئول ارائه گزارش را انتخاب کنید
                    </span>
                  )}
                  {members.length > 0 && headSelected !== null ? (
                    <span className="text-xs">
                      {headSelected?.placementProfileRelated?.firstName +
                        " " +
                        headSelected?.placementProfileRelated?.lastName}
                    </span>
                  ) : (
                    <span className="text-caspian-gray1 text-xs">
                      انتخاب کنید
                    </span>
                  )}
                  <PiCaretDownBold className="text-caspian-gray" />
                </div>
                {showListHeadMeeting && (
                  <div className="rounded-md shadow-[0_0_5px_#00000050] w-full flex flex-col gap-3 py-3 absolute left-0 right-0 top-[52px] bg-caspian-surface z-50">
                    <div className="relative w-[92%] mx-auto">
                      <input
                        onChange={(e) => {
                          setSearchByHead(e.target.value);
                        }}
                        type="search"
                        className="outline-none outline-caspian-gray1 p-1 text-xs rounded-md outline-1 w-full placeholder:text-xs placeholder:text-caspian-gray pr-6"
                        placeholder="جستجو"
                      />
                      <button className="absolute top-1/2 -translate-y-1/2 right-1">
                        <PiMagnifyingGlassBold className="text-caspian-gray text-" />
                      </button>
                    </div>
                    <div className="flex flex-col gap-2 w-full max-h-[200px] overflow-y-scroll">
                      {searchHead.length > 0 &&
                        searchHead.map((m, i) => (
                          <div
                            key={i}
                            onClick={() => {
                              setReciver(m?.placementProfileRelated?.id);
                              setHeadSelected(m);
                              setShowListHeadMeeting(false);
                              setSearchByHead("");
                              console.log(m);
                            }}
                            className="flex items-center justify-start gap-2 text-xs hover:bg-caspian-secondary-100 py-1 px-2 w-full cursor-pointer duration-300"
                          >
                            <span className="basis-[40%]">
                              {m?.placementProfileRelated?.firstName +
                                " " +
                                m?.placementProfileRelated?.lastName}
                            </span>
                            <span className="basis-[60%]">
                              {m?.placementPositionRelated?.title}
                            </span>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              {/* پایان مسئول گزارش جدید */}

              <div className="flex items-center gap-2">
                {/* شروع موعد تحویل */}
                <div className=" flex flex-col gap-2 w-full">
                  <span className="text-xs font-bold text-caspian-on_primary/50">
                    موعد تحویل
                  </span>
                  <div className="relative">
                    <DateTimePickerCDTAD setDateTahvil={setDateTahvil} />
                    <PiCalendar className="absolute left-3 top-1/2 -translate-y-1/2 text-xl text-caspian-gray" />
                    {errorDeadline && (
                      <span className="text-caspian-error text-xs absolute left-10 top-1/2 -translate-y-1/2">
                        موعد تحویل
                      </span>
                    )}
                  </div>
                </div>
                {/* پایان موعد تحویل */}

                {/* شروع دوره درخواست */}
                <div className="relative flex flex-col gap-2 w-full">
                  <label className="text-xs font-bold text-caspian-on_primary/50">
                    دوره درخواست
                  </label>
                  <select
                    name="periodRequest"
                    onChange={handleChange}
                    defaultValue={"بدون دوره درخواست"}
                    className="text-sm outline outline-1 outline-caspian-secondary-500 py-1 px-2 rounded-md bg-caspian-transparent cursor-pointer"
                  >
                    <option value={"بدون دوره درخواست"}>
                      بدون دوره درخواست
                    </option>
                    <option value={"یک هفته"}>یک هفته</option>
                    <option value={"دو هفته"}>دو هفته</option>
                    <option value={"سه هفته"}>سه هفته</option>
                    <option value={"یک ماه"}>یک ماه</option>
                  </select>
                </div>
                {/* پایان دوره درخواست */}
              </div>

              {/* شروع شرح مسئله */}
              <div className="relative flex flex-col gap-2">
                <label className="text-xs font-bold text-caspian-on_primary/50">
                  شرح مسئله
                </label>
                <textarea
                  ref={descriptionRef}
                  name="description"
                  placeholder="توضیحات خود را وارد کنید"
                  rows={6}
                  onChange={handleChange}
                  className="resize-none p-1.5 text-sm rounded-md outline outline-1 outline-caspian-gray2 w-full"
                />
                {errorDescription && (
                  <span className="text-caspian-error text-xs absolute left-2 top-8">
                    توضیحات گزارش را وارد کنید
                  </span>
                )}
              </div>
              {/* پایان شرح مسئله */}

              {/* شروع سطح محرمانگی */}
              <div className="relative flex flex-col gap-2">
                <label className="text-xs font-bold text-caspian-on_primary/50">
                  سطح محرمانگی
                </label>
                <select
                  name="privacy"
                  onChange={handleChange}
                  defaultValue={"internal"}
                  className="text-sm outline outline-1 outline-caspian-secondary-500 py-1 px-2 rounded-md bg-caspian-transparent cursor-pointer"
                >
                  <option value={"confidential"}>محرمانه</option>
                  <option value={"restricted"}>محدود</option>
                  <option value={"secret"}>سری</option>
                  <option value={"internal"}>داخلی</option>
                </select>
              </div>
              {/* پایان سطح محرمانگی */}

              {/* شروع آپلود فایل */}
              <div className="relative flex flex-col gap-2">
                <label
                  htmlFor="fileData"
                  className="flex items-center justify-between gap-2 py-2 border-b border-b-caspian-gray2 w-full"
                >
                  <input
                    type="file"
                    ref={fileRef}
                    name="file"
                    id="fileData"
                    className="hidden"
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                      if (file) {
                        // console.log(file);
                        setFile(file);
                      }
                      e.preventDefault();
                    }}
                    // multiple
                  />
                  <div className="flex flex-col items-center gap-4 w-full">
                    <div className="flex items-center justify-between w-full cursor-pointer">
                      <span className="text-sm text-caspian-secondary-600 whitespace-nowrap flex items-center gap-1">
                        فایل خود را اینجا بارگذاری کنید
                      </span>
                      <PiPlus className="text-caspian-secondary-600" />
                    </div>
                  </div>
                </label>
                {file !== undefined && file !== null && file.name && (
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center gap-4">
                      <h2 className="text-sm text-caspian-gray">{file.name}</h2>
                      <PiFileTextFill className="text-caspian-secondary-500 text-xl" />
                    </div>
                    <button type="button">
                      <PiX
                        className="cursor-pointer text-caspian-error"
                        onClick={() => {
                          setFile(undefined);
                          fileRef.current.value = "";
                        }}
                      />
                    </button>
                  </div>
                )}
              </div>
              {/* پایان آپلود فایل */}

              <div className="flex items-center gap-2">
                <button
                  disabled={loading ? true : false}
                  className={`disabled:cursor-not-allowed flex items-center gap-2 outline outline-1 outline-caspian-secondary-500 bg-caspian-secondary-500 text-caspian-surface py-1.5 px-3 rounded-md text-sm`}
                >
                  ارسال درخواست
                  {loading && (
                    <PiCircleNotch className="text-caspian-surface text-lg animate-spin" />
                  )}
                </button>
                <button
                  disabled={loading ? true : false}
                  type="button"
                  onClick={clearHandler}
                  className="disabled:cursor-not-allowed disabled:outline-caspian-gray disabled:text-caspian-gray outline outline-1 
                outline-caspian-error text-caspian-error py-1.5 px-6 rounded-md text-sm"
                >
                  لغو
                </button>
              </div>
            </form>
          </div>
        </Draggable>
      )}
    </>
  );
};

export default RequestFormNew;
