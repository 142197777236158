import React from 'react';
import SingleNeed from './SingleNeed';

const AllNeeds = ({allNeeds}) => {
    return (
        <div className='w-[98%]'>
            <div className="flex bg-caspian-primary-500 text-caspian-surface rounded-tr-md rounded-tl-md py-2 px-5">
                <div className='basis-1/2'>نیاز</div>
                <div className='w-[100px] 2xl:w-[300px]'></div>
                <div className='basis-1/2'>انتظارات</div>
            </div>
            {
                allNeeds?.map(item=><SingleNeed key={item.id} {...item} />)
            }
        </div>
    );
}

export default AllNeeds;
