import { useNavigate } from "react-router-dom";
import { PiCaretLeft } from "react-icons/pi";
import Profile from "./Profile";

const MainProfile = ({ actionPlanData, loading, costData, setRefresh }) => {
  const navigate = useNavigate();
  return loading ? (
    <div className="flex items-center justify-center w-full py-12">
      <span className="text-xs text-caspian-gray4">در حال بارگزاری...</span>
    </div>
  ) : (
    <div className="flex flex-col gap-2 p-2 pt-0">
      <Profile
        fullData={actionPlanData}
        costData={costData}
        setRefresh={setRefresh}
      />
    </div>
  );
};

export default MainProfile;
