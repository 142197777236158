import React, { useContext, useEffect, useState } from "react";
import {
  PiCheckCircleFill,
  PiPlus,
  PiTrash,
  PiXCircleFill,
} from "react-icons/pi";
import ChallengeForm from "./ChallengeForm";
import { AuthContext } from "../../../../../../../context/auth/AuthContext";
import { useParams } from "react-router-dom";
import API from "../../../../../../../api/API";

const ChallengeMain = ({ actionplanIdRelated }) => {
  const { token } = useContext(AuthContext);
  const params = useParams();
  const [showChallengeForm, setShowChallengeForm] = useState(false);
  const [showIsDelete, setShowIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [challengeList, setChallengeList] = useState([]);

  useEffect(() => {
    setRefresh(1);
  }, []);
  useEffect(() => {
    if (!token || !params?.id || refresh === 0) return;
    const getActionPlanChallenge = async () => {
      try {
        const res = await API.get(
          `/api/v1/actionplan/situations/actionplan/${params?.id}`,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data);
        setChallengeList(res?.data);
        setRefresh(0);
      } catch (err) {
        console.log(err);
      }
    };
    getActionPlanChallenge();
  }, [token, params?.id, refresh]);

  const removeHandler = async (id) => {
    try {
      await API.delete(`/api/v1/actionplan/situations/${id}/`, {
        headers: { Authorization: token },
      });
      setRefresh(1);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className="flex items-center gap-4 w-full">
        <button
          onClick={() => setShowChallengeForm(true)}
          className="flex items-center justify-between w-full border-y border-y-caspian-secondary-500 text-caspian-secondary-500 py-2 cursor-pointer hover:bg-caspian-secondary-50/20"
        >
          <span className="text-xs">افزودن چالش ها</span>
          <PiPlus className="text-sm" />
        </button>
        {showChallengeForm && (
          <ChallengeForm
            setShowChallengeForm={setShowChallengeForm}
            actionplanIdRelated={params?.id}
            setRefresh={setRefresh}
          />
        )}
      </div>
      <div className="max-h-[180px] overflow-y-scroll">
        <div className="flex flex-col gap-2">
          {challengeList?.length < 1 ? (
            <h2 className="text-caspian-gray1 text-xs">چالشی ثبت نشده است!</h2>
          ) : (
            challengeList?.map((d, i) => (
              d?.typeSituation === "challenge" &&
              <div
                key={i}
                className="bg-caspian-surface p-1.5 rounded-md border border-caspian-gray2 flex items-center justify-between"
              >
                <div className="flex flex-col gap-1 w-[90%]">
                  <span className="text-xs line-clamp-1">
                    آثار: {d?.impact}
                  </span>
                  <span className="text-xs line-clamp-1">
                    راهکار: {d?.solution}
                  </span>
                </div>
                {
                  <div className="relative flex items-center justify-center w-[10%]">
                    <button
                      onClick={async () => {
                        await setDeleteId(d?.id);
                        await setShowIsDelete(true);
                      }}
                      className="text-caspian-error2/30 hover:text-caspian-error2 duration-300 text-xl"
                    >
                      <PiTrash />
                    </button>
                    <div
                      className={
                        d?.id === deleteId && showIsDelete
                          ? "absolute left-0 right-0 top-0 bottom-0 flex items-center gap-0.5 w-fit bg-caspian-surface shadow-[0_0_5px_#00000050] p-0.5 rounded-md duration-500"
                          : "hidden duration-500"
                      }
                    >
                      <PiCheckCircleFill
                        onClick={() => {
                          removeHandler(d?.id);
                          setShowIsDelete(false);
                        }}
                        className="text-caspian-primary-600 text-lg cursor-pointer"
                      />
                      <PiXCircleFill
                        onClick={() => {
                          setShowIsDelete(false);
                          setDeleteId(null);
                        }}
                        className="text-caspian-gray text-lg cursor-pointer"
                      />
                    </div>
                  </div>
                }
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default ChallengeMain;
