import React, { useState } from 'react';
import { PiDotBold, PiDotOutlineFill, PiPencilSimpleLine } from "react-icons/pi"
import EditForm from './forms/EditForm';

const Intro = ({ id, postDescription, noStructureRole, setRefresh, user }) => {
    const [edit, setEdit] = useState(false);
    return (
        <div className='flex flex-col gap-8 relative'>
            {
                user?.id === 207 && (
                    <div className=' absolute left-5 text-lg'>
                        <PiPencilSimpleLine className='cursor-pointer' onClick={() => setEdit(true)} />
                    </div>
                )
            }
            {/* edit  */}
            {
                user?.id === 207 && edit && <EditForm id={id} edit={edit} setEdit={setEdit} postDescription={postDescription}
                    noStructureRole={noStructureRole} setRefresh={setRefresh} />
            }
            {/*  */}

            <div className='border-b border-[#F0F3FD]'>
                <span className='text-caspian-gray4 text-sm 2xl:text-base'>معرفی پست: </span>
                <p className='py-3 pr-3 leading-8 text-xs 2xl:text-sm 2xl:leading-8'>
                    {/* لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد */}
                    {postDescription}
                </p>
            </div>
            <div>
                <span className=' text-caspian-gray4 text-sm 2xl:text-base'>نقش‌های سازمانی در این پست : ( غیر ساختاری)</span>
                <ul className='py-3 pr-3 list-outside list-disc text-xs 2xl:text-sm leading-8 '>
                    {/* دبیر جلسات بازنگری مدیریت ، */}
                    {noStructureRole?.split(',')?.map((s,i)=>
                    <li key={i} className='list-item'>
                        {/* <p className='w-[20px]'><PiDotOutlineFill className='text-xl' /></p> */}
                        {/* <span className='w-[90%]'>{s}</span> */}
                        {s}
                    </li>)}
                </ul>
            </div>
        </div>
    );
}

export default Intro;
