import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";

const StartTimePicker = ({ setStartTime, oldStartTime }) => {
  const showSecond = false;
  const str = showSecond ? "HH:mm:ss" : "HH:mm:00";

  function onChange(value) {
    setStartTime(value && value.format(str));
  }

  return (
    <TimePicker
      minuteStep={10}
      className="placeholder:text-3xl"
      popupClassName="placeholder:text-3xl"
      style={{ width: "100%" }}
      showSecond={showSecond}
      inputReadOnly={true}
      onChange={onChange}
      allowEmpty={false}
      placeholder={oldStartTime ? oldStartTime.slice(0, 5) : "انتخاب کنید"}
      disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23]}
      hideDisabledOptions
    />
  );
};

export default StartTimePicker;
