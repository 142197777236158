import React from "react";

const MembersList = ({ userList }) => {
  return (
    <div className="w-[30%] lg:w-[25%] min-h-[80vh] max-h-[80vh] border-r border-r-caspian-gray2 overflow-y-scroll">
      <div className="flex flex-col w-full h-full">
        {userList?.map((m, i) => (
          <div
            key={i}
            className="flex items-center gap-1 w-full even:bg-caspian-gray2/30 p-2"
          >
            <span className="text-xs text-caspian-gray4 w-full">
              {m?.firstName + " " + m?.lastName}
            </span>
            <span className="text-xs text-caspian-gray4 mr-auto">
              کارشناس
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MembersList;
