import React, { useContext, useEffect, useRef, useState } from "react";
import {
  PiBookmarkSimple,
  PiBookmarkSimpleFill,
  PiCheck,
  PiCopy,
  PiDotsThreeVertical,
  PiNotePencil,
  PiTrash,
  PiX,
} from "react-icons/pi";
import API from "../../../../api/API";
import { AuthContext } from "../../../../context/auth/AuthContext";
import { copyToClipboard } from "../../../../helper/copyToClipboard";

const MenuAction = ({
  message,
  messageId,
  setRefreshMessageList,
  firstIndex,
  setMessageInput,
  setEditButton,
  setMessageId,
  isFav,
  reciverMenu,
}) => {
  let menuRef = useRef();
  const { token } = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
        setIsDelete(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const [isDelete, setIsDelete] = useState(false);

  const trashHandler = async (id) => {
    await API.put(
      `/api/v1/message/message/${id}/`,
      {
        is_trash: true,
      },
      {
        headers: { Authorization: token },
      }
    )
      .then((res) => {
        console.log(res.data);
        setOpen(false);
        setRefreshMessageList(1);
        setMessageId(null);
      })
      .catch((err) => console.log(err));
  };
  const [isFavirate, setIsFavirate] = useState(isFav);

  const favHandler = async (id) => {
    await API.patch(
      `/api/v1/message/message/${id}/`,
      {
        is_fav: true,
      },
      {
        headers: { Authorization: token },
      }
    )
      .then((res) => {
        setIsFavirate(true);
        console.log(res.data.is_fav);
        // setOpen(false);
        setRefreshMessageList(1);
      })
      .catch((err) => console.log(err));
  };
  const unfavHandler = async (id) => {
    await API.patch(
      `/api/v1/message/message/${id}/`,
      {
        is_fav: false,
      },
      {
        headers: { Authorization: token },
      }
    )
      .then((res) => {
        setIsFavirate(false);
        console.log(res.data);
        // setOpen(false);
        setRefreshMessageList(1);
      })
      .catch((err) => console.log(err));
  };


  return (
    <div ref={menuRef} className="">
      <PiDotsThreeVertical
        onClick={() => setOpen(!open)}
        className="cursor-pointer"
      />
      <div
        className={
          open
            ? `absolute ${firstIndex !== 0 ? "bottom-3" : "top-3"} left-3
              w-24 bg-caspian-surface/50 p-1 rounded-md flex items-center justify-center z-20 shadow-[0_0_5px_#00000050] backdrop-blur-sm rtlD`
            : "hidden"
        }
      >
        <ul className="flex flex-col z-50">
          {isDelete ? (
            <li className=" cursor-pointer border-b border-b-caspian-gray2 p-2 flex items-center gap-4 justify-center">
              <PiX
                onClick={() => {
                  setIsDelete(false);
                }}
                className="text-sm"
              />
              <PiCheck
                onClick={() => trashHandler(messageId)}
                className="text-sm"
              />
            </li>
          ) : (
            <li
              onClick={() => setIsDelete(true)}
              className=" cursor-pointer border-b border-b-caspian-gray2 p-2 flex items-center gap-1"
            >
              <PiTrash className="text-sm" />
              <span className="text-[9px]">حذف پیام</span>
            </li>
          )}

          {!reciverMenu && (
            <li
              onClick={() => {
                setEditButton(true);
                setMessageInput(message);
                setMessageId(messageId);
                setOpen(false);
              }}
              className="text-[9px] cursor-pointer border-b border-b-caspian-gray2 p-2 flex items-center gap-1"
            >
              <PiNotePencil className="text-sm" />
              <span className="text-[9px]">ویرایش پیام</span>
            </li>
          )}
          <li className="text-[9px] cursor-pointer border-b border-b-caspian-gray2 p-2">
            {isFavirate ? (
              <div
                onClick={() => {
                  unfavHandler(messageId);
                }}
                className="flex items-center gap-1"
              >
                <PiBookmarkSimpleFill className="text-sm" />
                <span className="text-[9px]">ذخیره شده</span>
              </div>
            ) : (
              <div
                onClick={() => {
                  favHandler(messageId);
                }}
                className="flex items-center gap-1"
              >
                <PiBookmarkSimple className="text-sm" />
                <span className="text-[9px]">ذخیره کردن</span>
              </div>
            )}
          </li>
          <li
            onClick={() => {
              copyToClipboard(message);
              setOpen(false);
              setMessageId(null);
            }}
            className="text-[9px] cursor-pointer p-2 flex items-center gap-1"
          >
            <PiCopy className="text-sm" />
            <span className="text-[9px]">کپی کردن</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MenuAction;
