import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "jalali-moment";
moment.locale("fa");

const Calendar = ({ eventData }) => {
  console.log(eventData);
  return (
    <FullCalendar
      dayMaxEvents={false}
      eventMaxStack={3}
      selectable={true}
      //   events={[
      //     {
      //       title: "نام فرآیند",
      //       start: "2024-08-24",
      //       end: "2024-08-30",
      //       color: "#73aeaf",
      //       backgroundColor: "#bcdbdd",
      //       textColor: "#314543",
      //     },
      //   ]}
      events={eventData}
      timeZone="IRST"
      direction="rtl"
      locale={"fa"}
      firstDay={6}
      plugins={[dayGridPlugin, interactionPlugin]}
      initialView="dayGridMonth"
      headerToolbar={{
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,dayGridWeek,dayGridDay",
      }}
      titleFormat={{ year: "numeric", month: "short" }}
      buttonText={{
        today: "امروز",
        month: "ماه",
        week: "هفته",
        day: "روز",
        list: "لیست",
      }}
      dayHeaderFormat={{ weekday: "short" }}
      datesSet={(dateInfo) => {
        let startDate = moment(dateInfo.start).startOf("jMonth");
        let endDate = moment(dateInfo.end).endOf("jMonth");
        console.log(
          `ماه شمسی از ${startDate.format("jYYYY/jM/jD")} تا ${endDate.format(
            "jYYYY/jM/jD"
          )}`
        );
      }}
    />
  );
};

export default Calendar;
