import React, { useContext, useEffect, useState } from 'react';
import { PiMagnifyingGlassBold } from 'react-icons/pi';
// import API from '../../../../../../../api/API';
// import { AuthContext } from '../../../../../../../context/auth/AuthContext';
// import Accordion from '../../../../list-question/list-question-item/Accordion';
// import ListStandard from '../../../create-form-question/ListStandard';
import ClauseSingleItem from './ClauseSingleItem';
// import ListStandard2 from '../../../create-form-question/ListStandard2';

const AllClauseList = ({standard2,setStandard2,standardSelected2,setStandardSelected2
    ,clauseList,setClauseList
}) => {
    // const { token } = useContext(AuthContext)
    // const [standardList, setStandardList] = useState([-1]);
    // const [standard, setStandard] = useState("");
    // const [standardSelected, setStandardSelected] = useState(null);
    // const [showListStandard, setShowListStandard] = useState(false);
    // const [clauseList, setClauseList] = useState([]);

    const [search, setSearch] = useState('');
    let searchData = [-1];

    const [isDisable,setIsDisable]=useState(false);

    // useEffect(() => {
    //     if (!token) return
    //     const fetchStandard = async () => {
    //         try {
    //             const res = await API.get('/api/v1/standard/', { headers: { Authorization: token } });
    //             setStandardList(res.data);
    //         }
    //         catch (err) {
    //             console.log(err);
    //         }
    //     }
    //     fetchStandard()
    // }, [token])
    // useEffect(() => {
    //     if (!standard2) return
    //     const fetchClause = async () => {
    //         try {
    //             const res = await API.get(`/api/v1/standard/clause/all/${standard2}`, { headers: { Authorization: token } });
    //             setClauseList(res.data);
    //             console.log(res.data);
    //         }
    //         catch (err) {
    //             console.log(err);
    //         }
    //     }
    //     fetchClause();
    // }, [standard2, token])

    if (clauseList[0] !== -1) {
        searchData = search ? (clauseList?.filter(d => (d?.clauseNumber?.toString().toLocaleLowerCase() + ' ' + d?.title?.toString().toLocaleLowerCase()).includes(search?.toString().toLocaleLowerCase())))
            : clauseList;
    }
    return (
        <div className='flex flex-col gap-0 flex-grow '>
            {/* standard search box */}
            {/* <div className='p-4 pt-2 gap-3 flex flex-col'>
                <div className='w-[60%]'>
                    <ListStandard2
                        lable=""
                        standardList={standardList}
                        standard={standard2}
                        setStandard={setStandard2}
                        standardSelected={standardSelected2}
                        setStandardSelected={setStandardSelected2}
                        showListStandard={showListStandard}
                        setShowListStandard={setShowListStandard}
                        // standard2={standard2}
                    />
                </div>
                <p className='text-center text-xs 2xl:text-sm'>
                    {
                        standard2?standardList?.map(m=>{
                            if(m?.id===standard2){
                                return m?.standardTitlePersian
                            }
                        }):
                        standardSelected2?.standardTitlePersian
                    }
                </p>
            </div> */}
            <div className='px-3 py-2 relative border-y border-caspian-gray'>
                {/* search box */}
                <input type="search" placeholder='جستجو'
                    className='border border-[#979797] rounded-lg w-full p-1.5 pl-0 pr-9 placeholder:text-xs text-sm outline-none'
                    onChange={(e) => setSearch(e.target.value)}
                />
                <PiMagnifyingGlassBold className='absolute top-1/2 -translate-y-1/2 right-6 text-caspian-gray ' />
            </div>
            <div className='flex gap-0 items-start justify-center h-44 overflow-x-hidden overflow-y-auto flex-grow '>
                <div className='w-full flex flex-col items-stretch min-h-fit h-full '>
                    {/* clause */}
                    {
                        !standard2? (<div className='flex items-center h-full justify-center text-xs 2xl:text-sm text-caspian-gray'>
                            استاندارد و بند انتخاب نشده
                        </div>) : searchData?.length < 1 ? (<div className='flex items-center h-full justify-center text-xs 2xl:text-sm text-caspian-gray'>
                            موردی یافت نشد
                        </div>) : searchData?.map((item,index) => <ClauseSingleItem isDisable={isDisable} setIsDisable={setIsDisable} index={index} key={item.id} {...item} />)
                    }
                </div>
            </div>
        </div>
    );
}

export default AllClauseList;
