import React, { useState } from "react";
import AbstractMain from "./AbstractMain";
import PicturesMain from "./PicturesMain";
import DownloadsMain from "./DownloadsMain";

const DetailsTabMain = ({
  dataAbstract,
  dataDownloads,
  dataPictures,
  tailsNotTopHandler,
}) => {
  const [abstract, setAbstract] = useState(true);
  const [pictures, setPictures] = useState(false);
  const [downloads, setDownloads] = useState(false);
  return (
    <div className="relative">
      <nav>
        <ul className="flex items-center gap-2 relative">
          <li
            onClick={() => {
              setAbstract(true);
              setPictures(false);
              setDownloads(false);
            }}
            className={`${
              abstract
                ? "rounded-t-xl bg-caspian-gray2/30"
                : "bg-caspian-transparent"
            } text-sm cursor-pointer p-2 h-9 w-24 flex items-center justify-center relative`}
          >
            توضیحات
          </li>
          <li
            onClick={() => {
              setAbstract(false);
              setPictures(true);
              setDownloads(false);
            }}
            className={`${
              pictures
                ? "rounded-t-xl bg-caspian-gray2/30"
                : "bg-caspian-transparent"
            } text-sm cursor-pointer p-2 h-9 w-24 flex items-center justify-center relative`}
          >
            تصاویر
          </li>
          <li
            onClick={() => {
              setAbstract(false);
              setPictures(false);
              setDownloads(true);
            }}
            className={`${
              downloads
                ? "rounded-t-xl bg-caspian-gray2/30"
                : "bg-caspian-transparent"
            } text-sm cursor-pointer p-2 h-9 w-24 flex items-center justify-center relative`}
          >
            دانلود
          </li>
        </ul>
      </nav>
      <div
        className={`${
          abstract ? "" : "rounded-tr-xl"
        } bg-caspian-gray2/30 p-4 rounded-b-xl rounded-tl-xl`}
      >
        {abstract ? (
          <AbstractMain
            dataAbstract={dataAbstract}
            tailsNotTopHandler={tailsNotTopHandler}
          />
        ) : pictures ? (
          <PicturesMain dataPictures={dataPictures} />
        ) : downloads ? (
          <DownloadsMain dataDownloads={dataDownloads} />
        ) : (
          <AbstractMain />
        )}
      </div>
    </div>
  );
};

export default DetailsTabMain;
