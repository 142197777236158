import React, { useContext, useEffect, useRef, useState } from "react";
import {
  PiCircleNotchBold,
  PiFileTextFill,
  PiPaperclip,
  PiPlus,
  PiX,
} from "react-icons/pi";
import API from "../../../../../../../api/API";
import { AuthContext } from "../../../../../../../context/auth/AuthContext";
import Modal from "../../../../../../modal/Modal";

const MainFileDocument = ({
  actionplanIdRelated,
  // file,
  // setFile,
  setShowUploadFile,
  setRefreshListFile,
}) => {
  const { token } = useContext(AuthContext);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const fileRef = useRef(null);
  const [fileLoading, setFileLoading] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [file, setFile] = useState(null);

  const getFile = async () => {
    if (!file || !title) return;
    setFileLoading(true);
    setFileError(false);
    try {
      const res = await API.post(
        "/api/v1/actionplan/projectDocument/",
        {
          fileDocument: file,
          name: title,
          description: description,
          actionplanRelated: actionplanIdRelated,
        },
        {
          headers: {
            Authorization: token,
            "content-type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      );
      console.log(res.data);
      setFileLoading(false);
      setRefreshListFile(1);
      setShowUploadFile(false);
    } catch (error) {
      console.log(error);
      setFileError(true);
    }
  };

  return (
    <Modal>
      <div className="bg-caspian-surface rounded-md w-1/2 flex flex-col gap-0">
        <div className="flex items-center justify-between w-full p-2 border-b border-b-caspian-gray2">
          <div className="flex items-center gap-1">
            <PiPaperclip className="text-caspian-gray4" />
            <span className="text-xs text-caspian-gray4">بارگذاری فایل</span>
          </div>
          <PiX
            className="cursor-pointer"
            onClick={() => setShowUploadFile(false)}
          />
        </div>
        <div className="relative flex flex-col gap-2 w-full p-4">
          <div className="w-full flex flex-col gap-1.5">
            <label className="text-xs text-caspian-gray">عنوان فایل</label>
            <input
              placeholder="عنوان فایل را یادداشت کنید"
              onChange={(e) => setTitle(e.target.value)}
              className="p-1 rounded-md outline-none outline-1 outline-caspian-gray2 text-xs"
            />
          </div>
          <div className="w-full flex flex-col gap-1.5">
            <label className="text-xs text-caspian-gray">توضیحات فایل</label>
            <textarea
              rows={6}
              placeholder="توضیحات فایل را یادداشت کنید"
              onChange={(e) => setDescription(e.target.value)}
              className="p-1 rounded-md outline-none outline-1 outline-caspian-gray2 text-xs resize-none"
            />
          </div>
          {file ? (
            file !== undefined &&
            file !== null &&
            file.name && (
              <div className="flex items-center justify-between w-full pt-2 pb-1">
                <div className="flex items-center gap-4">
                  <h2 className="text-sm text-caspian-gray">{file?.name}</h2>
                  <PiFileTextFill className="text-caspian-secondary-500 text-xl" />
                </div>
                <button type="button">
                  <PiX
                    className="cursor-pointer text-caspian-error"
                    onClick={() => {
                      setFile(null);
                      //   fileRef.current.value = "";
                    }}
                  />
                </button>
              </div>
            )
          ) : (
            <label
              htmlFor="fileData"
              className="flex items-center justify-between gap-2 py-2 border-b border-b-caspian-gray2 w-full"
            >
              <input
                type="file"
                ref={fileRef}
                name="file"
                id="fileData"
                className="hidden"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                  if (file) {
                    setFile(file);
                  }
                  e.preventDefault();
                }}
              />
              <div className="flex flex-col items-center gap-4 w-full">
                <div className="flex items-center justify-between w-full cursor-pointer">
                  <span className="text-xs text-caspian-secondary-600 whitespace-nowrap flex items-center gap-1">
                    فایل خود را بارگذاری کنید
                  </span>
                  <PiPlus className="text-caspian-secondary-600" />
                </div>
              </div>
            </label>
          )}
          <button
            disabled={!file || !title || fileLoading ? true : false}
            onClick={getFile}
            className={`${
              !file || !title
                ? "bg-caspian-gray2 text-caspian-gray4"
                : "bg-caspian-secondary-500 text-caspian-surface"
            } text-xs p-1.5 rounded-md w-[128px] flex items-center justify-center gap-2 relative`}
          >
            {fileLoading ? <span>در حال بارگذاری</span> : <span>بارگذاری</span>}

            {fileLoading && (
              <div className="absolute left-1 top-1/2 -translate-y-1/2">
                <PiCircleNotchBold
                  className={`text-caspian-surface animate-spin text-base 
                  `}
                />
              </div>
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default MainFileDocument;
