import React, { useEffect, useState } from "react";
import TableListExternalDocuments from "./TableListExternalDocuments";
import API from "../../../../api/API";

const MainExternalDocuments = () => {
  const [loading, setLoading] = useState(false);
  const [fullData, setFullData] = useState([-1]);

  useEffect(() => {
    setLoading(true);
    API.get("/api/v1/deocument/external/")
      .then((d) => {
        setFullData(d.data);
      })
      .catch((err) => {
        console.log("error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  console.log(fullData);

  let data = fullData.map((d) => ({
    id: d.id,
    title: d.title,
    code: d.code,
    type: d?.externalDocumentTypeRelated?.title,
    updateDate: d?.updated_at,
    reference: d.reference,
  }));

  return <TableListExternalDocuments data={data} loading={loading} />;
};

export default MainExternalDocuments;
