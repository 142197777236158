import React from 'react';
import { Link } from 'react-router-dom';

const TopProfileBlog = ({ data }) => {
    return (
        <>
            <div>
                <h1 className='2xl:text-2xl text-xl font-bold'>
                    {data?.title}
                </h1>
            </div>
            <div className='topBlog'>
                <div className='flex gap-8'>
                    <div className='flex flex-col gap-6 basis-2/3 border-b-2 border-caspian-primary-300'>
                        <div dangerouslySetInnerHTML={{__html:data?.describtion}}>
                            {/* توضیحات بلاگ لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است. */}
                            {/* {data?.describtion} */}
                        </div>
                        <div className='flex justify-between flex-col gap-3 md:flex-row text-caspian-darkgray-500 text-xs 2xl:text-sm'>
                            <p className='whitespace-nowrap flex gap-2'>
                                {/* ایجاد کننده:پست ذکر شود (مدیرعامل) */}
                                <span>ایجاد کننده : </span>
                                <span> {data?.authorSectionRelated?.title}</span>
                            </p>
                            <p className='text-caspian-darkgray-500 text-xs 2xl:text-sm flex gap-2 pb-3'>
                                {/* تاریخ :1403/03/12 */}
                                <span>تاریخ :</span>
                                <span>{new Date(data?.created_at).toLocaleDateString('fa-IR', {
                                    hour: '2-digit',
                                    minute: '2-digit'
                                })}</span>
                                {/* <span className='whitespace-nowrap text-xs 2xl:text-sm'> ساعت :14:30 </span> */}
                            </p>
                        </div>
                        <div className='flex mt-auto gap-2 items-baseline pb-5 text-xs 2xl:text-sm'>
                            <span>برچسب :</span>
                            <div className='flex gap-3 flex-wrap'>
                            {
                                data?.tags.map((tag, index) => <Link to={`/blog/tags/${tag}`} className='cursor-pointer text-caspian-primary-500 bg-caspian-primary-50 p-1.5 rounded-md' key={index}>{tag}</Link>)
                            }
                        </div>
                        </div>
                    </div>
                    <div className='basis-1/3 flex justify-end'>
                        <div className='max-w-[300px] w-full aspect-square'>
                            {data?.image?<img src={data?.image} className='w-full h-full object-cover object-center rounded-md' alt={data?.title} /> 
                            :<img src='/assets/images/blog/not-found.jpg' className='w-full h-full object-cover object-center rounded-md' alt='not found' />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TopProfileBlog;
