import React, { useState } from "react";
import {
  PiBookOpen,
  PiBookmarkSimple,
  PiBooks,
  PiHardDrives,
  PiHeadphones,
  PiMedalMilitary,
  PiMonitorPlay,
  PiStack,
} from "react-icons/pi";
import ListItemSingle from "./ListItemSingle";
import { useSearchParams } from "react-router-dom";

const LibraryNavbar = ({ setLibraryDetails }) => {
  const [serachParams, setSearchParams] = useSearchParams();
  const typeFilter = serachParams.get("type");
  const sortData = serachParams.get("sort");
  const [active, setActive] = useState(!typeFilter ? "" : typeFilter);
  function handleFilterChange(key, value) {
    setSearchParams((prevParams) => {
      if (value === null || value === "") {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    });
  }

  return (
    <nav className="w-full bg-caspian-surface rtl">
      <ul className="flex items-center gap-0.5">
        <ListItemSingle
          title="همه"
          active={active}
          setLibraryDetails={setLibraryDetails}
          icon={<PiHardDrives />}
          onClick={() => {
            setActive("all");
            handleFilterChange("type", "all");
            handleFilterChange("page", "");
            handleFilterChange("sort", sortData ? sortData : "date");
          }}
          buttonActive="all"
        />
        <ListItemSingle
          title="کتاب"
          active={active}
          setLibraryDetails={setLibraryDetails}
          icon={<PiBooks />}
          onClick={() => {
            setActive("book");
            handleFilterChange("type", "book");
            handleFilterChange("page", "");
            handleFilterChange("sort", sortData ? sortData : "date");
          }}
          buttonActive="book"
        />
        <ListItemSingle
          title="مقالات"
          active={active}
          setLibraryDetails={setLibraryDetails}
          icon={<PiBookOpen />}
          onClick={() => {
            setActive("article");
            handleFilterChange("type", "article");
            handleFilterChange("page", "");
            handleFilterChange("sort", sortData ? sortData : "date");
          }}
          buttonActive="article"
        />
        <ListItemSingle
          title="استاندارد ها"
          active={active}
          setLibraryDetails={setLibraryDetails}
          icon={<PiMedalMilitary />}
          onClick={() => {
            setActive("standard");
            handleFilterChange("type", "standard");
            handleFilterChange("page", "");
            handleFilterChange("sort", sortData ? sortData : "date");
          }}
          buttonActive="standard"
        />
        <ListItemSingle
          title="فیلم آموزشی"
          active={active}
          setLibraryDetails={setLibraryDetails}
          icon={<PiMonitorPlay />}
          onClick={() => {
            setActive("film");
            handleFilterChange("type", "film");
            handleFilterChange("page", "");
            handleFilterChange("sort", sortData ? sortData : "date");
          }}
          buttonActive="film"
        />
        <ListItemSingle
          title="فایل صوتی"
          active={active}
          setLibraryDetails={setLibraryDetails}
          icon={<PiHeadphones />}
          onClick={() => {
            setActive("podcast");
            handleFilterChange("type", "podcast");
            handleFilterChange("page", "");
            handleFilterChange("sort", sortData ? sortData : "date");
          }}
          buttonActive="podcast"
        />
        <ListItemSingle
          title="سایر مطالب"
          active={active}
          setLibraryDetails={setLibraryDetails}
          icon={<PiStack />}
          onClick={() => {
            setActive("other");
            handleFilterChange("type", "other");
            handleFilterChange("page", "");
            handleFilterChange("sort", sortData ? sortData : "date");
          }}
          buttonActive="other"
        />
        <ListItemSingle
          title="علاقه مندی ها"
          active={active}
          setLibraryDetails={setLibraryDetails}
          icon={<PiBookmarkSimple />}
          onClick={() => {
            setActive("favorites");
            handleFilterChange("type", "favorites");
            handleFilterChange("page", "");
            handleFilterChange("sort", sortData ? sortData : "date");
          }}
          buttonActive="favorites"
        />
      </ul>
    </nav>
  );
};

export default LibraryNavbar;
