import React, { useEffect, useState } from "react";
import TableSingle from "./TableSingle";
import Paginate from "./Paginate";
import PopUp from "./PopUp";
import Details from "./Details";
import { useLocation, useSearchParams } from "react-router-dom";
import PopUpPodCast from "./PopUpPodCast";

const Table = ({
  data,
  setDetails,
  dataId,
  setdataId,
  setShowDetailsTiles,
  // sortType,
  searchFiletr,
  // typeFilter,
  currentPage,
  setCurrentPage,
  // showSort,
  favorite,
  setFavorite,
  showFilter,
}) => {
  const [fullData, setFullData] = useState([-1]);

  const [showPopup, setShowPopup] = useState(false);
  const [showPopupPodCast, setShowPopupPodCast] = useState(false);

  const [popupData, setPopupData] = useState({});
  const [popupPodCastData, setPopupPodCastData] = useState({});

  const recordsPerPage = 8;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;

  const [serachParams, setSearchParams] = useSearchParams();
  const sortData = serachParams.get("sort");
  const page = serachParams.get("page");
  const typeFilter = serachParams.get("type");

  const tags = serachParams.get("tags");
  const langFa = serachParams.get("langFa");
  const langEn = serachParams.get("langEn");
  const startDate = serachParams.get("startDate");
  const endDate = serachParams.get("endDate");

  function handleFilterChange(key, value) {
    setSearchParams((prevParams) => {
      if (value === null || value === "") {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    });
  }

  useEffect(() => {
    setFullData(data);
    setCurrentPage(page ? page : 1);
  }, [data, sortData, setCurrentPage, typeFilter, page]);

  let typeData = [-1];
  if (fullData[0] !== -1) {
    typeData =
      typeFilter && typeFilter !== "all" && typeFilter !== "favorites"
        ? fullData.filter((d) => d.typeLibrary === typeFilter)
        : typeFilter === "favorites"
        ? fullData.filter((d) => d.profileFavorite === true)
        : fullData;
  }

  let newData = [-1];
  if (typeData[0] !== -1) {
    newData = searchFiletr
      ? typeData.filter(
          (d) =>
            d.title
              .toLocaleLowerCase()
              .includes(searchFiletr.toLocaleLowerCase()) ||
            (d.abstract !== null &&
              d.abstract
                .toLocaleLowerCase()
                .includes(searchFiletr.toLocaleLowerCase()))
        )
      : typeData;
  }

  const records = newData.slice(firstIndex, lastIndex);
  const npage = Math.ceil(newData.length / recordsPerPage);

  useEffect(() => {
    if (typeFilter === "favorites") {
      if (favorite === 2) {
        setdataId(records[0] && records[0].id);
      }
    }
  }, [favorite, setdataId, records, typeFilter]);

  const location = useLocation();

  const clearFilter = () => {
    handleFilterChange("startDate", "");
    handleFilterChange("endDate", "");
    handleFilterChange("tags", "");
    handleFilterChange("langFa", "");
    handleFilterChange("langEn", "");
    handleFilterChange("page", 1);
    location.search = "?type=all&sort=date";
    setFavorite(1);
  };
  return (
    <div className="flex items-start justify-between gap-2">
      <section className="w-full bg-caspian-surface rounded-xl p-4">
        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-between">
            <h2 className="font-bold">
              {sortData === "date" && "آخرین مطالب"}
              {sortData === "atoz" && "حروف الفبا"}
              {sortData === "view" && "پربازدیدترین"}
            </h2>
            {(startDate || endDate || tags || langFa || langEn) &&
              !showFilter && (
                <button
                  onClick={clearFilter}
                  className="text-xs text-caspian-error font-bold"
                >
                  پاک کردن فیلتر
                </button>
              )}
          </div>
          <div className="flex flex-col gap-4 w-full">
            {showPopup && (
              <PopUp setShowPopup={setShowPopup} popupData={popupData} />
            )}
            {showPopupPodCast && (
              <PopUpPodCast
                setShowPopupPodCast={setShowPopupPodCast}
                popupPodCastData={popupPodCastData}
              />
            )}

            {records[0] === -1 ? (
              <div className="flex flex-col gap-2 items-center justify-center py-24 w-full">
                <h2 className="text-center text-sm text-caspian-gray">
                  در حال بارگذاری اطلاعات...
                </h2>
              </div>
            ) : records.length < 1 ? (
              <div className="flex flex-col gap-2 items-center justify-center py-24 w-full">
                <img
                  src="/assets/images/library/not-found.svg"
                  alt="مطلبی یافت نشد..."
                />
                <h2 className="text-sm text-caspian-gray">مطلبی یافت نشد...</h2>
              </div>
            ) : (
              <>
                {sortData === "date" &&
                  records.map((d, i) => (
                    <TableSingle
                      dataId={dataId}
                      id={d.id}
                      key={i}
                      setShowPopup={setShowPopup}
                      setShowPopupPodCast={setShowPopupPodCast}
                      title={d.title}
                      abstract={d.abstract}
                      imagesLibrary={d.imagesLibrary}
                      filesLibrary={d.filesLibrary}
                      typeLibrary={d.typeLibrary}
                      created_at={d.created_at}
                      subjectEn={d.subjectEn}
                      shortDesc={d.shortDesc}
                      setDetails={setDetails}
                      setdataId={setdataId}
                      setShowDetailsTiles={setShowDetailsTiles}
                      setPopupData={setPopupData}
                      setPopupPodCastData={setPopupPodCastData}
                      language={d.language}
                      profileFavorite={d.profileFavorite}
                      setFavorite={setFavorite}
                    />
                  ))}

                {sortData === "atoz" &&
                  records.map((d, i) => (
                    <TableSingle
                      dataId={dataId}
                      id={d.id}
                      key={i}
                      setShowPopup={setShowPopup}
                      setShowPopupPodCast={setShowPopupPodCast}
                      title={d.title}
                      abstract={d.abstract}
                      imagesLibrary={d.imagesLibrary}
                      filesLibrary={d.filesLibrary}
                      typeLibrary={d.typeLibrary}
                      created_at={d.created_at}
                      subjectEn={d.subjectEn}
                      shortDesc={d.shortDesc}
                      setDetails={setDetails}
                      setdataId={setdataId}
                      setShowDetailsTiles={setShowDetailsTiles}
                      setPopupData={setPopupData}
                      setPopupPodCastData={setPopupPodCastData}
                      language={d.language}
                      profileFavorite={d.profileFavorite}
                      setFavorite={setFavorite}
                    />
                  ))}

                {sortData === "view" &&
                  records.map((d, i) => (
                    <TableSingle
                      dataId={dataId}
                      id={d.id}
                      key={i}
                      setShowPopup={setShowPopup}
                      setShowPopupPodCast={setShowPopupPodCast}
                      title={d.title}
                      abstract={d.abstract}
                      imagesLibrary={d.imagesLibrary}
                      filesLibrary={d.filesLibrary}
                      typeLibrary={d.typeLibrary}
                      created_at={d.created_at}
                      subjectEn={d.subjectEn}
                      shortDesc={d.shortDesc}
                      setDetails={setDetails}
                      setdataId={setdataId}
                      setShowDetailsTiles={setShowDetailsTiles}
                      setPopupData={setPopupData}
                      setPopupPodCastData={setPopupPodCastData}
                      language={d.language}
                      profileFavorite={d.profileFavorite}
                      setFavorite={setFavorite}
                    />
                  ))}
              </>
            )}

            {records.length < 1 || records[0] === -1 ? (
              <></>
            ) : (
              <Paginate
                pageCount={npage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </div>
        </div>
      </section>
      <Details
        details={data}
        dataId={dataId}
        setdataId={setdataId}
        recordsDataId={records[0] && records[0].id}
      />
    </div>
  );
};

export default Table;
