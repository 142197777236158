import React, { useContext, useEffect, useState } from "react";
import Modal from "../../../../../modal/Modal";
import { PiPlusBold, PiTrash, PiX } from "react-icons/pi";
import ProcessListSelected from "./ProcessListSelected";
import ListSelected from "./ListSelected";
import API from "../../../../../../api/API";
import { AuthContext } from "../../../../../../context/auth/AuthContext";

const AddProcessActivitiesForm = ({
  scheduleTimeId,
  activitiesSelected,
  setActivitiesSelected,
  activitiesFinalSelected,
  setActivitiesFinalSelected,
  setShowAddActivites,
  processSelected,
  setProcessSelected,
  setRefreshPL,
}) => {
  const { token } = useContext(AuthContext);
  const [process, setProcess] = useState(null);
  const [showListProcess, setShowListProcess] = useState(false);

  const [processList, setProcessList] = useState([-1]);

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const getProcess = async () => {
      if (!token) return;
      try {
        const res = await API.get("/api/v1/businessProcess/process/", {
          headers: { Authorization: token },
        });
        console.log(res?.data);
        setProcessList(res?.data);
      } catch (error) {
        console.log(error);
      }
    };
    getProcess();
  }, [token]);

  const [showListActivities, setShowListActivities] = useState(false);

  const activitiesHandler = async () => {
    if (!scheduleTimeId) return;
    let formData = {
      auditScheduleRelated: scheduleTimeId,
      processActivityRelated: activitiesFinalSelected,
      processProfileRelated: process,
    };
    console.log(formData);

    try {
      const res = await API.post(
        "/api/v1/audit/profile/schedule/process/",
        formData,
        {
          headers: { Authorization: token },
        }
      );
      console.log(res?.data);
      setActivitiesSelected([]);
      setActivitiesFinalSelected([]);
      setShowAddActivites(false);
      setProcessSelected(null);
      setRefreshPL(1);
    } catch (error) {
      console.log(error);
    }
  };

  const closeActivities = async () => {
    setActivitiesSelected([]);
    setActivitiesFinalSelected([]);
    setShowAddActivites(false);
    setProcessSelected(null);
  };

  useEffect(() => {
    activitiesFinalSelected.length > 0 ? setDisabled(false) : setDisabled(true);
  }, [activitiesFinalSelected]);
  return (
    <Modal>
      <div className="min-w-[400px] max-w-[400px] 2xl:min-w-[560px] 2xl:max-w-[560px] bg-caspian-surface shadow-[0_0_5px_#00000020] rounded-lg flex flex-col gap-0">
        <div className="py-3 2xl:py-6 border-b border-b-caspian-gray2">
          <div className="flex items-center justify-between px-2 2xl:px-8">
            <span className="text-xs 2xl:text-base font-bold">
              افزودن فرایند و فعالیت های مرتبط
            </span>
            <PiX onClick={closeActivities} className="cursor-pointer z-50" />
          </div>
        </div>
        <div className="flex flex-col gap-8 px-2 2xl:px-8 py-3 2xl:py-6">
          <ProcessListSelected
            lable="انتخاب فرایند"
            processList={processList}
            process={process}
            setProcess={setProcess}
            processSelected={processSelected}
            setProcessSelected={setProcessSelected}
            showListProcess={showListProcess}
            setShowListProcess={setShowListProcess}
            setActivitiesSelected={setActivitiesSelected}
            setActivitiesFinalSelected={setActivitiesFinalSelected}
          />
          <div className="flex flex-col gap-2 w-full">
            <label className="text-xs text-caspian-gray">افزودن فعالیت</label>
            <button
              onClick={() => setShowListActivities(true)}
              className="rounded-md border border-caspian-secondary-400 p-2 flex items-center justify-between gap-1 w-full"
            >
              <div className="flex items-center gap-1.5">
                <PiPlusBold className="text-caspian-secondary-500 text-md" />
                <span className="text-caspian-secondary-500 text-xs">
                  افزودن
                </span>
              </div>
              {activitiesFinalSelected?.length > 0 ? (
                <span className="text-xs">
                  {activitiesFinalSelected?.length} فعالیت افزوده شد
                </span>
              ) : (
                <span className="text-xs">فعالیتی انتخاب نشده</span>
              )}
            </button>
          </div>
          {showListActivities && (
            <ListSelected
              finalSelected={activitiesFinalSelected}
              setFinalSelected={setActivitiesFinalSelected}
              selected={activitiesSelected}
              setSelected={setActivitiesSelected}
              setShowListSelected={setShowListActivities}
              title="لیست فعالیت‌ ها"
              url={`/api/v1/profileProcess/profile/${process}/`}
            />
          )}
        </div>
        <div className="flex items-center gap-2 px-2 2xl:px-8 py-3 2xl:py-6">
          <button
            disabled={disabled}
            onClick={activitiesHandler}
            className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[3rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
          >
            تایید
          </button>
          <button
            onClick={closeActivities}
            className="min-w-[3rem] 2xl:min-w-[100px] 2xl:h-[40px] text-caspian-error2 text-xs p-1.5 rounded-md"
          >
            لغو
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddProcessActivitiesForm;
