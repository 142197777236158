import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../../../context/app-context/AppContext";

const ListItemSingle = ({
  active,
  title,
  icon,
  onClick,
  link,
  buttonActive,
  imageAddress,
  number,
}) => {
  const { navbarProfileMeeting, setNavbarProfileMeeting } = useAppContext();
  useEffect(() => {
    if (active === "all-profiles") {
      setNavbarProfileMeeting("all-profiles");
    } else if (active === "my-profiles") {
      setNavbarProfileMeeting("my-profiles");
    } else if (active === "draft-profile") {
      setNavbarProfileMeeting("draft-profile");
    }
  }, [active, setNavbarProfileMeeting]);

  return (
    <li>
      <Link
        onClick={onClick}
        to={link}
        className={`${
          navbarProfileMeeting === active
            ? "border border-caspian-secondary-600 shadow-[0_0_5px_#00000050]"
            : "border border-caspian-transparent"
        } flex items-center gap-2 p-3 pl-14 cursor-pointer rounded-lg bg-caspian-surface`}
      >
        <div
          className={`${
            active === "all-profiles"
              ? "bg-[#ACF1F2]"
              : active === "my-profiles"
              ? "bg-[#A3D1FF]"
              : "bg-[#F9D8FF]"
          } w-12 h-12 p-2 rounded-full flex items-center justify-center`}
        >
          <img src={imageAddress} alt={title} />
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-sm text-caspian-gray font-normal">{title}</span>
          <span className="text-sm font-medium">{number}</span>
        </div>
      </Link>
    </li>
  );
};

export default ListItemSingle;
