import React from 'react'
import MainListWorkProgressReport from './list-work-progress-report/MainListWorkProgressReport';
import MainChartWorkProgressReport from './chart-work-progress-report/MainChartWorkProgressReport';

const MainWorkProgressReport = ({ fullData }) => {

    return (
        <div className='flex flex-col items-center w-full gap-5'>

            <div className='flex items-start justify-between w-full py-5'>
                <div className='w-1/2'>
                    <MainChartWorkProgressReport fullData={fullData} />
                </div>

                {fullData?.progress_taskreportProfile[0]?.documents?.length > 0 ?
                    
                        <div className='w-1/2'>
                            <MainListWorkProgressReport fullData={fullData} />
                        </div>
                   :
                    <span className='w-1/2 text-xs text-center w 2xl:text-sm'>سندی وجود ندارد</span>
                }

            </div>

            <div className='flex items-start w-full gap-5 py-5'>
                <span className='whitespace-nowrap text-xs 2xl:text-base text-[#3E615F]'>توضیحات : </span>

                <p className='text-xs font-semibold 2xl:text-sm'>{fullData?.progress_taskreportProfile[0]?.description}</p>
            </div>
        </div >
    )
}

export default MainWorkProgressReport