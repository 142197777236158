import React from "react";
import { Link } from "react-router-dom";

const DropDownButton = ({ icon, title, onClick, className, link, onClickLink }) => {
  return (
    <Link to={link} onClick={onClickLink} className={`${className} flex items-center gap-4`}>
      <div className="text-caspian-gray text-2xl">{icon}</div>
      <span onClick={onClick} className="text-xs cursor-pointer">
        {title}
      </span>
    </Link>
  );
};

export default DropDownButton;
