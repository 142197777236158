import React from 'react'
import { PiTrash } from 'react-icons/pi'
import convertFileSize from '../../../../../../../helper/convertFileSize'

const ShowUploadFileCost = ({ fileRef, file, setFile, setShowFiles, showFiles }) => {

    const handleDeleteFile = () => {
        setFile(null)
        setShowFiles(false)
        console.log(file, showFiles,)
        fileRef.current.value = ''
    }

    return (
        <div className='w-full flex items-center gap-5'>
            <div className='flex items-center justify-between px-3 py-2 border-b-2 border-b-caspian-gray2/80 rounded-md w-[90%] '>
                <div className='flex items-center gap-5'>
                    <img src="/assets/images/action-plan/Group.svg" className='w-4 2xl:w-5' alt="" />
                    <span className='text-xs'>{file?.name}</span>
                </div>

                <span className='text-xs text-caspian-gray4'>{convertFileSize(file?.size)}</span>
            </div>
            <PiTrash
                onClick={handleDeleteFile}
                className='text-sm 2xl:text-[22px] cursor-pointer text-caspian-secondary-600' />
        </div>
    )
}

export default ShowUploadFileCost