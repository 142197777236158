import React, { useRef, useState } from "react";
import { PiPlus } from "react-icons/pi";
import MainCreateAuditForm from "../audit-form/create/MainCreateAuditForm";
import { useNavigate, useParams } from "react-router-dom";
import { useDraggable } from "react-use-draggable-scroll";
import PopupDetails from "./PopupDetails";
import DeleteSchedulePopup from "./DeleteSchedulePopup";

const AuditPlaning = ({ data, setRefreshProfile, isAuditer }) => {
  const { id } = useParams();
  const div1Ref = useRef(null);
  const div2Ref = useRef(null);
  const div3Ref = useRef(null);
  const { events } = useDraggable(div1Ref);

  const isSyncingScroll = useRef(false);

  const handleScroll = (sourceRef, targetRefs) => {
    if (isSyncingScroll.current) return;

    isSyncingScroll.current = true;

    const currentScrollLeft = sourceRef.current.scrollLeft;
    const currentScrollTop = sourceRef.current.scrollTop;

    targetRefs.forEach((targetRef) => {
      if (targetRef.current) {
        targetRef.current.scrollLeft = currentScrollLeft;
        targetRef.current.scrollTop = currentScrollTop;
      }
    });

    isSyncingScroll.current = false;
  };
  // const syncingScroll = useRef(false);

  // const handleScroll = (sourceRef, targetRefs) => {
  //   if (syncingScroll.current) return;
  //   syncingScroll.current = true;

  //   const currentScrollLeft = sourceRef.current.scrollLeft;
  //   const currentScrollTop = sourceRef.current.scrollTop;

  //   targetRefs.forEach((targetRef) => {
  //     if (targetRef.current) {
  //       if (targetRef.current.scrollLeft !== currentScrollLeft) {
  //         targetRef.current.scrollLeft = currentScrollLeft;
  //       }
  //       if (targetRef.current.scrollTop !== currentScrollTop) {
  //         targetRef.current.scrollTop = currentScrollTop;
  //       }
  //     }
  //   });

  //   setTimeout(() => {
  //     syncingScroll.current = false;
  //   }, 50);
  // };

  const timerRef = useRef(null);
  const [showPopupDetails, setShowPopupDetails] = useState(false);

  const handleMouseEnter = () => {
    timerRef.current = setTimeout(() => {
      setShowPopupDetails(true);
    }, 1500);
  };

  const handleMouseLeave = () => {
    setShowPopupDetails(false);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  const navigate = useNavigate();
  const [showCreateAuditForm, setShowCreateAuditForm] = useState(false);

  function convertTimeFormat(time) {
    let [hours, minutes] = time?.split(":");
    return `${hours}:${minutes}`;
  }

  const [mouseDown, setMouseDown] = useState(false);

  const [dataForm, setDataForm] = useState({ date: null, section: null });
  const [dataHover, setDataHover] = useState({
    date: null,
    section: null,
    dataIndex: null,
    sectionIndex: null,
  });

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [scheduleId, setScheduleId] = useState(null);

  return (
    <div
      className={`${
        isAuditer ? "h-[65vh] xl:h-[70vh]" : "h-[80vh]"
      } min-w-full flex flex-col gap-2`}
    >
      {dataForm.date && showCreateAuditForm && id && (
        <MainCreateAuditForm
          setShowCreateAuditForm={setShowCreateAuditForm}
          auditDate={dataForm.date}
          auditSection={dataForm.section.sectionRelated}
          setRefreshProfile={setRefreshProfile}
          auditId={id}
        />
      )}
      <div className="flex items-center gap-2 min-w-full basis-[10%]">
        <div className="basis-[20%] w-full h-16 2xl:h-20">
          {data[0] === -1 ? (
            <div className="w-full h-full bg-caspian-gray2 animate-pulse"></div>
          ) : (
            <div className="w-full h-full border border-caspian-gray2 rounded-md flex flex-col gap-0 bg-caspian-surface">
              <div className="flex items-center justify-center gap-2 border-b border-b-caspian-gray2 px-1 py-1.5">
                <span className="text-xs font-bold text-caspian-error">
                  برنامه ریزی ممیزی
                </span>
                <span className="text-xs">{data?.title}</span>
              </div>
              <div className="flex items-center justify-center gap-2 h-full">
                {data?.auditProfileMSRelatedProfile?.map((ms, i) => (
                  <span
                    key={i}
                    className="text-sm text-caspian-secondary-500 font-bold"
                  >
                    {ms?.msRelated?.title}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
        <div
          ref={div2Ref}
          onScroll={() => handleScroll(div1Ref, [div2Ref, div3Ref])}
          className={`pl-10 basis-[80%] w-full h-16 2xl:min-h-[80px] flex items-center gap-2 overflow-x-hidden ${
            data[0] === -1 ? "bg-caspian-gray1 animate-pulse" : ""
          }`}
        >
          {data?.days?.map((d, i) => (
            <div
              key={i}
              className={`flex flex-col gap-2 items-center justify-center border text-xs min-w-[96px] 2xl:min-w-[128px] h-16 2xl:min-h-[80px] rounded-md p-2 ${
                d?.mDate === dataHover?.date?.mDate
                  ? "bg-caspian-darkblue-800/20 text-caspian-darkblue-800 border-caspian-darkblue-600 delay-75"
                  : "border-caspian-secondary-500 text-caspian-secondary-500 bg-caspian-secondary-50 delay-75"
              }`}
            >
              <span className="text-center">{d?.sDate}</span>
              <span className="text-center">{d?.wDate}</span>
            </div>
          ))}
        </div>
      </div>
      <div
        className={`flex items-center gap-2 min-w-full h-[50vh] basis-[90%]`}
      >
        <div
          className={`pb-10 basis-[20%] w-full h-full flex flex-col gap-2 overflow-y-hidden ${
            data[0] === -1 ? "bg-caspian-gray1 animate-pulse" : ""
          }`}
          ref={div3Ref}
          onScroll={() => handleScroll(div1Ref, [div3Ref, div2Ref])}
        >
          {data?.auditRelatedSections?.map((s, i) => (
            <div
              key={i}
              className={`flex flex-col gap-2 items-start justify-center text-xs w-full h-16 2xl:h-20 rounded-md p-2 border ${
                s?.id === dataHover?.section?.id
                  ? "bg-caspian-darkblue-800/20 text-caspian-darkblue-800 border-caspian-darkblue-600 delay-75"
                  : s?.sectionStatus === "scheduled"
                  ? "bg-caspian-primary-50 text-caspian-primary-800 border-caspian-primary-600 delay-75"
                  : "bg-caspian-error/30 border-caspian-error text-caspian-on_primary delay-75"
              }`}
            >
              <span className="h-16">{s?.sectionRelated?.title}</span>
            </div>
          ))}
        </div>
        <div
          {...events}
          ref={div1Ref}
          onScroll={() => handleScroll(div1Ref, [div2Ref, div3Ref])}
          className={`basis-[80%] w-full h-full cursor-grabbing overflow-x-auto flex gap-2 ${
            data[0] === -1 ? "bg-caspian-gray2 animate-pulse" : ""
          }`}
        >
          {showDeletePopup && (
            <DeleteSchedulePopup
              setRefreshProfile={setRefreshProfile}
              setScheduleId={setScheduleId}
              scheduleTimeId={scheduleId}
              setShowDeletePopup={setShowDeletePopup}
            />
          )}
          {data?.days?.map((d, i) => (
            <div key={i} className="flex flex-col gap-2">
              {data?.auditRelatedSections?.map((s, j) =>
                data?.AuditProfileRelatedSchedule?.find(
                  (day) =>
                    day?.auditDate === d?.mDate &&
                    day?.sectionRelated?.id === s?.sectionRelated?.id
                ) ? (
                  <button
                    onMouseEnter={() => {
                      setDataHover({
                        date: d,
                        section: s,
                        dataIndex: i,
                        sectionIndex: j,
                      });
                      handleMouseEnter();
                    }}
                    onMouseLeave={() => {
                      setDataHover({
                        date: null,
                        section: null,
                        dataIndex: null,
                        sectionIndex: null,
                      });
                      handleMouseLeave();
                    }}
                    key={j}
                    // onClick={() => {
                    //   data?.AuditProfileRelatedSchedule?.find(
                    //     (day) =>
                    //       day?.auditDate === d?.mDate &&
                    //       day?.sectionRelated?.id === s?.sectionRelated?.id
                    //   )?.AuditScheduleRelatedResponsible.length !== 0
                    //     ? navigate(
                    //         `/audit/${id}/schedule/${
                    //           data?.auditRelatedSections?.find(
                    //             (sec) =>
                    //               sec?.sectionRelated?.id === s?.sectionRelated?.id
                    //           )?.id
                    //         }`,
                    //         { state: data?.title }
                    //       )
                    //     : setShowDeletePopup(true);
                      onClick={() => {
                        data?.AuditProfileRelatedSchedule?.find(
                          (day) =>
                            day?.auditDate === d?.mDate &&
                            day?.sectionRelated?.id === s?.sectionRelated?.id
                        )?.AuditScheduleRelatedResponsible.length !== 0
                          ? navigate(
                              `/audit/${id}/schedule/${
                                data?.AuditProfileRelatedSchedule?.find(
                                  (day) =>
                                    day?.auditDate === d?.mDate &&
                                    day?.sectionRelated?.id === s?.sectionRelated?.id
                                )?.id
                              }`,
                              { state: data?.title }
                            )
                          : setShowDeletePopup(true);

                      setScheduleId(
                        data?.AuditProfileRelatedSchedule?.find(
                          (day) =>
                            day?.auditDate === d?.mDate &&
                            day?.sectionRelated?.id === s?.sectionRelated?.id
                        ).id
                      );
                      console.log(
                        data?.AuditProfileRelatedSchedule?.find(
                          (day) =>
                            day?.auditDate === d?.mDate &&
                            day?.sectionRelated?.id === s?.sectionRelated?.id
                        )
                      );
                    }}
                    className={`relative min-w-[96px] 2xl:min-w-[128px] min-h-[64px] 2xl:min-h-[80px] h-16 flex items-center justify-center gap-1 rounded-md border border-caspian-gray bg-[#FCECCA] text-caspian-surface text-sm p-2 ${
                      mouseDown ? "cursor-grabbing" : "cursor-pointer"
                    }`}
                  >
                    <div className="flex flex-col gap-2 items-center">
                      {data?.AuditProfileRelatedSchedule?.find(
                        (day) =>
                          day?.auditDate === d?.mDate &&
                          day?.sectionRelated?.id === s?.sectionRelated?.id
                      )?.AuditScheduleRelatedResponsible.length !== 0 ? (
                        <div className="flex items-center justify-center gap-1">
                          <span className="text-caspian-gray4 text-xs">
                            {data?.AuditProfileRelatedSchedule?.find(
                              (day) =>
                                day?.auditDate === d?.mDate &&
                                day?.sectionRelated?.id ===
                                  s?.sectionRelated?.id
                            )?.AuditScheduleRelatedResponsible[0]?.startTime &&
                              convertTimeFormat(
                                data?.AuditProfileRelatedSchedule?.find(
                                  (day) =>
                                    day?.auditDate === d?.mDate &&
                                    day?.sectionRelated?.id ===
                                      s?.sectionRelated?.id
                                )?.AuditScheduleRelatedResponsible[0]?.startTime
                              )}
                          </span>
                          <span className="text-xs text-caspian-gray4">-</span>
                          <span className="text-caspian-gray4 text-xs">
                            {data?.AuditProfileRelatedSchedule?.find(
                              (day) =>
                                day?.auditDate === d?.mDate &&
                                day?.sectionRelated?.id ===
                                  s?.sectionRelated?.id
                            )?.AuditScheduleRelatedResponsible[0]?.endTime &&
                              convertTimeFormat(
                                data?.AuditProfileRelatedSchedule?.find(
                                  (day) =>
                                    day?.auditDate === d?.mDate &&
                                    day?.sectionRelated?.id ===
                                      s?.sectionRelated?.id
                                )?.AuditScheduleRelatedResponsible[0]?.endTime
                              )}
                          </span>
                        </div>
                      ) : (
                        <div>
                          <span className="text-caspian-error">ناقص</span>
                        </div>
                      )}

                      <div className="flex items-center gap-2">
                        {data?.AuditProfileRelatedSchedule?.find(
                          (day) =>
                            day?.auditDate === d?.mDate &&
                            day?.sectionRelated?.id === s?.sectionRelated?.id
                        )?.AuditScheduleRelatedResponsible[0]?.teamRelated.map(
                          (user, iu) => (
                            <div
                              key={iu}
                              style={{
                                backgroundColor: `#${user?.colorHexRelated?.hexCode}`,
                              }}
                              className="w-2.5 h-2.5 rounded-full shadow-[0_0_3px_#00000090]"
                            ></div>
                          )
                        )}
                      </div>
                    </div>
                    {showPopupDetails &&
                      data?.AuditProfileRelatedSchedule?.find(
                        (day) =>
                          day?.auditDate === d?.mDate &&
                          day?.sectionRelated?.id === s?.sectionRelated?.id
                      )?.AuditScheduleRelatedResponsible.length !== 0 &&
                      ((d?.mDate?.includes(dataHover?.date?.mDate) &&
                        j === dataHover?.sectionIndex) ||
                        (s?.id === dataHover?.section?.id &&
                          i === dataHover?.dataIndex)) && (
                        <PopupDetails
                          users={
                            data?.AuditProfileRelatedSchedule?.find(
                              (day) =>
                                day?.auditDate === d?.mDate &&
                                day?.sectionRelated?.id ===
                                  s?.sectionRelated?.id
                            )?.AuditScheduleRelatedResponsible[0]?.teamRelated
                          }
                        />
                      )}
                  </button>
                ) : (
                  <button
                    onMouseDown={() => setMouseDown(true)}
                    onMouseUp={() => setMouseDown(false)}
                    key={j}
                    onMouseEnter={() =>
                      setDataHover({
                        date: d,
                        section: s,
                        dataIndex: i,
                        sectionIndex: j,
                      })
                    }
                    onMouseLeave={() =>
                      setDataHover({
                        date: null,
                        section: null,
                        dataIndex: null,
                        sectionIndex: null,
                      })
                    }
                    onClick={() => {
                      setDataForm({ date: d, section: s });
                      // در صورت وجود ممیزی کامل یا ناقص اجازه باز شدن فرم در یک ردیف از واحدها داده نمی شود
                      !isAuditer ||
                      data?.AuditProfileRelatedSchedule?.find(
                        (sec) =>
                          sec?.sectionRelated.id === s?.sectionRelated?.id
                      )
                        ? setShowCreateAuditForm(false)
                        : setShowCreateAuditForm(true);
                    }}
                    className={`min-w-[96px] 2xl:min-w-[128px] min-h-[64px] 2xl:min-h-[80px] h-16 flex items-center justify-center gap-1 rounded-md border text-sm p-2 ${
                      (d?.mDate?.includes(dataHover?.date?.mDate) &&
                        j <= dataHover?.sectionIndex) ||
                      (s?.id === dataHover?.section?.id &&
                        i <= dataHover?.dataIndex)
                        ? "bg-caspian-darkblue-800/20 text-caspian-darkblue-800 border-caspian-darkblue-600 delay-75"
                        : "bg-caspian-gray2/40 text-caspian-gray border-caspian-gray delay-75"
                    } ${mouseDown ? "cursor-grabbing" : "cursor-pointer"}`}
                  >
                    <PiPlus className="text-xl" />
                  </button>
                )
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AuditPlaning;
