import React, { useContext, useEffect, useState } from "react";
import Draggable from "react-draggable";
import { PiX } from "react-icons/pi";
import { AuthContext } from "../../../context/auth/AuthContext";
import API from "../../../api/API";
import ColorPickerNew from "./ColorPickerNew";
import EditNoteNew from "./EditNoteNew";
import Menubar from "./Menubar";
import Modal from "../../modal/Modal";

const MainEditNote = ({
  showEditNote,
  setShowEditNote,
  thisNoteId,
  indexNote,
  refresh,
  setRefresh,
}) => {
  const { token } = useContext(AuthContext);
  const [fullData, setFullData] = useState([-1]);
  const [loading, setLoading] = useState(false);
  const [loadingNote, setLoadingNote] = useState(false);
  const [colorPicker, setColorPicker] = useState(false);
  const [noteData, setNoteData] = useState([-1]);
  const [color, setColor] = useState(noteData[0]?.color);
  const [thisClickNoteId, setThisClickNoteId] = useState(null);
  useEffect(() => {
    if (token) {
      setLoading(true);
      API.get("/api/v1/profile/notebook", {
        headers: { Authorization: token },
      })
        .then((d) => {
          setFullData(d.data);
          setRefresh(0);
        })
        .catch((err) => {
          console.log("error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token, refresh, setRefresh, showEditNote]);

  useEffect(() => {
    if (token) {
      if (!thisNoteId) return;
      setLoadingNote(true);
      API.get("/api/v1/profile/notebook/note", {
        headers: { Authorization: token },
      })
        .then((d) => {
          setNoteData(d?.data.filter((d) => d?.id === thisNoteId));
          setRefresh(0);
        })
        .catch((err) => {
          console.log("error");
        })
        .finally(() => {
          setLoadingNote(false);
        });
    }
  }, [token, refresh, thisNoteId, setRefresh, showEditNote]);
  const [showDetails, setShowDetails] = useState(false);

  console.log(showEditNote);
  return (
    <Draggable
      handle="strong"
      bounds={{ top: 0, bottom: 500, right: 740, left: -250 }}
    >
      <div
        className={
          "rounded-sm shadow-[0_0_5px_#00000050] w-[340px] fixed top-14 left-[16.3rem] bg-caspian-surface z-50"
        }
      >
        {showDetails && (
          <div className="bg-caspian-surface rounded-md shadow-[0_0_5px_#00000050] min-w-[280px] overflow-hidden z-50 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <div className="flex items-center justify-between w-full border-b border-b-caspian-gray2 p-1.5 bg-caspian-gray3">
              <span className="text-xs text-caspian-gray4">جزئیات یادداشت</span>
              <PiX
                className="text-sm text-caspian-gray4 cursor-pointer"
                onClick={() => {
                  setShowDetails(false);
                  // setDetailsFolder(null);
                }}
              />
            </div>
            <div className="flex flex-col gap-2 w-full p-2">
              <div className="flex items-center gap-2">
                <span className="text-xs">نام یادداشت:</span>
                <span className="text-xs underline underline-offset-4">
                  {noteData[0]?.title}
                </span>
              </div>
              <div className="flex items-center gap-2">
                <span className="text-xs">تاریخ ایجاد:</span>
                <span className="text-xs">
                  {new Date(noteData[0]?.created_at).toLocaleDateString(
                    "fa-IR"
                  )}
                </span>
              </div>
              <div className="flex items-center gap-2">
                <span className="text-xs">تاریخ بروزسانی:</span>
                <span className="text-xs">
                  {new Date(noteData[0]?.updated_at).toLocaleDateString(
                    "fa-IR"
                  )}
                </span>
              </div>
            </div>
          </div>
        )}

        {thisNoteId !== noteData[0]?.id ? null : loading &&
          thisNoteId === noteData[0]?.id ? (
          <div className="min-h-[425px] bg-caspian-surface">
            <div className="flex items-center justify-between p-2">
              <div className="w-32 h-5 bg-caspian-gray2 animate-pulse"></div>
            </div>
            <div className="flex flex-col gap-2.5 p-3">
              <div className="flex flex-col gap-2">
                <div className="w-full h-4 bg-caspian-gray2 animate-pulse"></div>
                <div className="w-full border-b border-b-caspian-gray2"></div>
              </div>
              <div className="w-32 h-5 bg-caspian-gray2 animate-pulse"></div>
              <div className="w-full h-4 bg-caspian-gray2 animate-pulse"></div>
              <div className="w-full h-4 bg-caspian-gray2 animate-pulse"></div>
              <div className="w-full h-4 bg-caspian-gray2 animate-pulse"></div>
              <div className="w-full h-4 bg-caspian-gray2 animate-pulse"></div>
              <div className="w-full h-4 bg-caspian-gray2 animate-pulse"></div>
              <div className="w-full h-4 bg-caspian-gray2 animate-pulse"></div>
              <div className="w-full h-4 bg-caspian-gray2 animate-pulse"></div>
              <div className="w-1/2 h-4 bg-caspian-gray2 animate-pulse"></div>
            </div>
          </div>
        ) : (
          <div
            className={`w-full h-full bg-[${
              color ? color : noteData[0]?.color
            }] bg-opacity-50`}
          >
            <strong
              className={`flex items-center justify-between cursor-move p-2`}
            >
              <div className="flex items-center gap-2">
                <Menubar
                  noteId={thisNoteId}
                  setShowDetails={setShowDetails}
                  isFav={noteData[0]?.fav}
                  isTrash={noteData[0]?.trash}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  showEditNote={showEditNote}
                  setShowEditNote={setShowEditNote}
                />
                <span className="text-xs">ویرایش یادداشت</span>
              </div>
              <div className="flex items-center gap-2">
                <div className="z-50 relative">
                  <div
                    onClick={() => setColorPicker(!colorPicker)}
                    className={`w-5 h-5 rounded-full cursor-pointer`}
                  >
                    <img
                      src="/assets/images/notebook/recolor-wheel.svg"
                      alt="color"
                    />
                  </div>

                  {colorPicker && (
                    <ColorPickerNew
                      setColor={setColor}
                      setColorPicker={setColorPicker}
                    />
                  )}
                </div>
                <PiX
                  onClick={async () => {
                    setShowEditNote(
                      showEditNote.filter((id) => id !== thisNoteId)
                    );
                  }}
                  className="text-xl cursor-pointer"
                />
              </div>
            </strong>
            {
              <EditNoteNew
                color={color}
                setColor={setColor}
                fullData={fullData}
                loading={loading}
                setShowEditNote={setShowEditNote}
                setRefresh={setRefresh}
                noteData={noteData}
              />
            }
          </div>
        )}
      </div>
    </Draggable>
  );
};

export default MainEditNote;
