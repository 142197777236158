import SingleListWorkProgressReport from './SingleListWorkProgressReport'

const MainListWorkProgressReport = ({ fullData }) => {
    return (
        <div className='flex flex-col items-center'>
            <SingleListWorkProgressReport fullData={fullData} />
        </div>
    )
}

export default MainListWorkProgressReport