import React, { useEffect, useState } from "react";
import RowView from "./RowView";
import Paginate from "./Paginate";
import { useSearchParams } from "react-router-dom";
import { PiCircleNotch } from "react-icons/pi";
import SingleTableListPmo from "./SingleTableListActionplan";

const TableListActionplan = ({ fullData, loading, setRefreshPmoList }) => {
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search");
  const page = searchParams.get("page");
  const rows = searchParams.get("rows");

  useEffect(() => {
    if (search) {
      setCurrentPage(1);
    }
  }, [search]);

  const [currentPage, setCurrentPage] = useState(page ? page : 1);
  const [recordsPerPage, setRecordsPerPage] = useState(rows ? rows : 10);
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = fullData.slice(firstIndex, lastIndex);
  const npage = Math.ceil(fullData.length / recordsPerPage);

  return (
    <div className="flex flex-col gap-4">
      {loading ? (
        <div className="flex items-center justify-center py-40">
          <PiCircleNotch className="text-caspian-secondary-600 text-2xl animate-spin" />
        </div>
      ) : (
        <div className="flex flex-col overflow-x-auto">
          <div className="sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-x-auto bg-caspian-transparent">
                <table className="min-w-full text-left text-xs font-light">
                  <thead className="border-b border-b-caspian-gray2 font-medium">
                    <tr className="">
                      <th
                        scope="col"
                        className="rounded-tr-xl bg-caspian-transparent text-right p-1 w-[22.5%]"
                      >
                        <input
                          // onClick={() => setCurrentPage(1)}
                          disabled
                          type="search"
                          placeholder="عنوان تیم"
                          className="rounded-md p-1.5 font-light text-xs w-full bg-caspian-transparent"
                        />
                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-transparent text-right p-1 w-[8%]"
                      >
                        <input
                          disabled
                          type="search"
                          placeholder="کد"
                          className="rounded-md p-1.5 font-light text-xs w-full bg-caspian-transparent"
                        />
                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-transparent text-right p-1 w-[18.5%]"
                      >
                        <input
                          disabled
                          type="search"
                          placeholder="مدیر پروژه"
                          className="rounded-md p-1.5 font-light text-xs w-full bg-caspian-transparent"
                        />
                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-transparent text-right p-1 w-[18.5%]"
                      >
                        <input
                          disabled
                          type="search"
                          placeholder="تاریخ ثبت درخواست"
                          className="rounded-md p-1.5 font-light text-xs w-full bg-caspian-transparent"
                        />
                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-transparent text-right p-1 w-[18.5%]"
                      >
                        <input
                          disabled
                          type="search"
                          placeholder="تاریخ تصویب درخواست"
                          className="rounded-md p-1.5 font-light text-xs w-full bg-caspian-transparent"
                        />
                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-transparent rounded-tl-xl text-center p-1 w-[15%]"
                      ></th>
                    </tr>
                  </thead>
                  {records.length > 0 ? (
                    <tbody>
                      {records.map((d, i) => (
                        <SingleTableListPmo
                          key={i}
                          id={d.id}
                          //عنوان تیم
                          title={d.title}
                          code={"AP-" + d?.id}
                          //مدیر پروژه
                          manager={
                            d?.manager?.firstName + " " + d?.manager?.lastName
                          }
                          // شروع کار
                          startDate={
                            d?.requestSubmitDate
                              ? new Date(
                                  d?.requestSubmitDate
                                ).toLocaleDateString("fa-IR")
                              : "-"
                          }
                          // پایان کار
                          endDate={
                            d?.approval_date
                              ? new Date(d?.approval_date).toLocaleDateString(
                                  "fa-IR"
                                )
                              : "-"
                          }
                          // وضعیت
                          statusAction={d?.statusAction}
                          setRefreshPmoList={setRefreshPmoList}
                          isPlan={d?.remained_weight < 1 ? true : false}
                        />
                      ))}
                    </tbody>
                  ) : (
                    <tbody className="py-24">
                      <tr>
                        <td className="py-24">
                          <div className="flex items-center justify-center w-full py-10">
                            <div className="z-10 absolute left-1/2 -translate-x-1/2 mx-auto">
                              <div className="flex flex-col gap-2 items-center justify-center w-full">
                                <img
                                  src="/assets/images/library/not-found.svg"
                                  alt="برنامه سازمانی یافت نشد..."
                                />
                                <h2>برنامه سازمانی یافت نشد...</h2>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      {records.length > 0 && (
        <div className="flex items-center justify-between py-2">
          <RowView
            setRecordsPerPage={setRecordsPerPage}
            setCurrentPage={setCurrentPage}
          />
          <Paginate
            pageCount={npage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
          <div className="text-xs flex items-center gap-4">
            <span>نمایش {firstIndex + 1}</span>
            <span>تا {lastIndex - (recordsPerPage - records.length)}</span>
            <span>از کل {fullData.length}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableListActionplan;
