// import { useEffect } from "react";
import { PiCaretLeftFill, PiCaretRightFill } from "react-icons/pi";
import ReactPaginate from "react-paginate";
import { useSearchParams } from "react-router-dom";

const PaginateApi = ({ pageCount, setOffset, currentPage, setCurrentPage, offset }) => {
  const [serachParams, setSearchParams] = useSearchParams();
  // const page = serachParams.get("page");
  const rows = serachParams.get('rows');

  // useEffect(() => {
  //   if (page && rows == 20) {
  //     setOffset(page === 1 ? 0 : (page - 1) * 20)
  //   }
  //   else if (page && rows == 30) {
  //     setOffset(page === 1 ? 0 : (page - 1) * 30)
  //   }
  //   else {
  //     setOffset(page === 1 ? 0 : (page - 1) * 10)
  //   }
  // }, [page, rows, offset])

  const handlePageClick = (data) => {
    if (rows === 20) { setOffset(data.selected === 0 ? 0 : data.selected * 20) }
    else if (rows === 30) { setOffset(data.selected === 0 ? 0 : data.selected * 30) }
    else { setOffset(data.selected === 0 ? 0 : (data.selected) * 10) }

    handleSearch("page", data.selected + 1);
    setCurrentPage(data.selected + 1);

    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  function handleSearch(key, value) {
    setSearchParams((prevParams) => {
      if (value === null || value === "") {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    });
  }
  return (
    <div className="mx-auto">
      <div className="flex items-center ">
          <ReactPaginate
            onPageChange={handlePageClick}
            previousLabel={<PiCaretRightFill />}
            nextLabel={<PiCaretLeftFill />}
            pageCount={pageCount}
            pageRangeDisplayed={1}
            containerClassName="flex items-center justify-between lg:gap-4"
            pageLinkClassName={
              "text-caspian-on_primary/50 w-6 h-6 rounded-md flex items-center justify-center text-xs xl:text-sm"
            }
            activeLinkClassName={
              "bg-caspian-surface border border-caspian-secondary-500 shadow-[0_0_5px_#00000020] text-caspian-secondary-900 w-6 h-6 rounded-md flex items-center justify-center cursor-default text-xs xl:text-sm"
            }
            previousLinkClassName="text-sm text-caspian-secondary-900"
            nextLinkClassName="text-sm text-caspian-secondary-900"
            disabledLinkClassName="cursor-default"
            key={pageCount}
            forcePage={pageCount > 1 && currentPage - 1}
          />
      </div>
    </div>
  );
};

export default PaginateApi;
