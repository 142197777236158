import React, { useContext, useState } from 'react'
import { PiCaretLeft, PiTrashLight } from 'react-icons/pi'
import DetailsDocMaterial from './DetailsDocMaterial'
import { AuthContext } from '../../../../../../../context/auth/AuthContext'
import API from '../../../../../../../api/API'
import { priceType } from '../../../../../../../helper/pricetype'

const SingleListUploadFileMaterial = ({ listDocMaterial, setRefreshList }) => {
    const { token } = useContext(AuthContext)

    const [showModalDetail, setShowModalDetail] = useState(false)
    const [selectItemIndex, setSelectItemIndex] = useState(0)


    const handleDeleteMaterial = async (id) => {
        if (!token) return;

        try {
            const res = await API.delete(`/api/v1/actionplan/report_material/${id}`,
                { headers: { Authorization: token } }
            )
            console.log(res?.data)
            setRefreshList(1)

        } catch (error) {

        }
    }



    return (
        <div className='w-full flex flex-col items-center gap-5'>

            {listDocMaterial?.map((item, index) => (
                <div key={index} className='w-full flex items-center justify-center gap-5'>
                    <div className='flex justify-between items-center w-full border-b-2 px-3 pb-2 border-caspian-gray2'>
                        <span className='text-xs 2xl:text-sm'>{item?.materialRelated?.name}</span>

                        <span className='text-xs text-caspian-gray'>
                            {priceType(item?.used_amount)} ({item?.materialRelated?.unit})
                        </span>

                        <div className='flex items-center cursor-pointer'>
                            <span onClick={() => {
                                setShowModalDetail(true)
                                setSelectItemIndex(index)
                            }}
                                className='text-caspian-blue-600 text-xs'>بیشتر</span>
                            <PiCaretLeft className='text-caspian-blue-600 text-base' />
                        </div>
                    </div>
                    <div className='pb-2'>
                        <PiTrashLight
                            onClick={() => handleDeleteMaterial(item?.id)}
                            className='text-base text-caspian-secondary-600 cursor-pointer' />
                    </div>
                </div>
            ))}

            {showModalDetail &&
                <DetailsDocMaterial selectItemIndex={selectItemIndex} listDocMaterial={listDocMaterial} setShowModalDetail={setShowModalDetail} />}
        </div>
    )
}

export default SingleListUploadFileMaterial