import React from "react";
import { priceType } from "../../../../helper/pricetype";
const BudgetSingle = ({ title, cost, className }) => {
  return (
    <div className="flex flex-col gap-2">
      <span className={`text-caspian-gray text-xs`}>{title}</span>
      <span className={`text-sm mr-4 ${className}`}>
        {cost ? priceType(cost) : "-"}
      </span>
    </div>
  );
};

export default BudgetSingle;
