import React, { useContext, useEffect, useRef, useState } from "react";
import { PiFunnel } from "react-icons/pi";
import API from "../../../../api/API";
import { AuthContext } from "../../../../context/auth/AuthContext";

const Filter = () => {
  const { token } = useContext(AuthContext);

  let menuRef = useRef();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const [dataType, setDataType] = useState([-1]);

  useEffect(() => {
    if (!token) return;
    const getDataType = async () => {
      try {
        const res = await API.get("/api/v1/deocument/type/");
        setDataType(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getDataType();
  }, [token]);

  return (
    <div ref={menuRef} className="relative w-fit">
      <div onClick={() => setOpen(!open)} className="relative cursor-pointer">
        <PiFunnel className="text-xl text-caspian-surface cursor-pointer" />
      </div>
      {/* Start Open Menu */}
      <div
        className={
          open
            ? "z-50 overflow-y-scroll absolute top-5 left-0 flex items-center flex-col drop-shadow-[0_0_1px_#00000050] min-w-[160px] max-h-[160px] bg-caspian-surface rounded-lg bg-scroll"
            : "hidden"
        }
      >
        {/* Start Box Menu */}
        <div className="flex flex-col items-start gap-4 w-full p-3">
          {dataType.map((dt, i) => (
            <div key={i} className="flex items-center gap-4 w-full">
              <input type="checkbox" id={dt.id} />
              <label
                htmlFor={dt.id}
                className="text-sm text-caspian-gray font-normal w-full cursor-pointer"
              >
                {dt.title}
              </label>
            </div>
          ))}
        </div>
        {/* End Box Menu */}
      </div>
      {/* End Open Menu */}
    </div>
  );
};

export default Filter;
