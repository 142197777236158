import React, { useEffect, useState } from "react";
import Paginate from "./Paginate";
import PopUp from "./PopUp";
import TableTilesSingle from "./TableTilesSingle";
import { useLocation, useSearchParams } from "react-router-dom";
import PopUpPodCast from "./PopUpPodCast";

const TableTiles = ({
  data,
  setDetails,
  setdataId,
  setShowDetailsTiles,
  searchFiletr,
  typeFilter,
  currentPage,
  setCurrentPage,
  setFavorite,
  // favorite,
  // dataFiltered,
  showFilter,
}) => {
  const [serachParams, setSearchParams] = useSearchParams();
  const serachFilter = serachParams.get("keyword");
  const sortData = serachParams.get("sort");
  const page = serachParams.get("page");

  const [fullData, setFullData] = useState([-1]);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupPodCast, setShowPopupPodCast] = useState(false);

  const [popupData, setPopupData] = useState({});
  const [popupPodCastData, setPopupPodCastData] = useState({});

  const recordsPerPage = 8;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;

  function handleFilterChange(key, value) {
    setSearchParams((prevParams) => {
      if (value === null || value === "") {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    });
  }

  const tags = serachParams.get("tags");
  const langFa = serachParams.get("langFa");
  const langEn = serachParams.get("langEn");
  const startDate = serachParams.get("startDate");
  const endDate = serachParams.get("endDate");

  useEffect(() => {
    setFullData(data);
    setCurrentPage(page ? page : 1);
  }, [data, sortData, setCurrentPage, typeFilter, page]);

  let typeData = [-1];
  if (fullData[0] !== -1) {
    typeData =
      typeFilter && typeFilter !== "all" && typeFilter !== "favorites"
        ? fullData?.filter((d) => d?.typeLibrary === typeFilter)
        : typeFilter === "favorites"
        ? fullData?.filter((d) => d?.profileFavorite === true)
        : fullData;
  }

  let newData = [-1];
  if (typeData[0] !== -1) {
    newData = searchFiletr
      ? typeData.filter(
          (d) =>
            d.title
              .toLocaleLowerCase()
              .includes(searchFiletr.toLocaleLowerCase()) ||
            (d.abstract !== null &&
              d.abstract
                .toLocaleLowerCase()
                .includes(searchFiletr.toLocaleLowerCase()))
        )
      : typeData;
  }

  const records = newData.slice(firstIndex, lastIndex);
  const npage = Math.ceil(newData.length / recordsPerPage);

  useEffect(() => {
    setShowDetailsTiles(false);
    setCurrentPage(page ? page : 1);
  }, [typeFilter, setShowDetailsTiles, setCurrentPage, serachFilter, page]);

  const location = useLocation();

  const clearFilter = () => {
    handleFilterChange("startDate", "");
    handleFilterChange("endDate", "");
    handleFilterChange("tags", "");
    handleFilterChange("langFa", "");
    handleFilterChange("langEn", "");
    handleFilterChange("page", 1);
    location.search = "?type=all&sort=date";
    setFavorite(1);
  };

  return (
    <section className="w-full bg-caspian-surface rounded-xl p-4">
      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between">
          <h2 className="font-bold">
            {sortData === "date" && "آخرین مطالب"}
            {sortData === "atoz" && "حروف الفبا"}
            {sortData === "view" && "پربازدیدترین"}
          </h2>
          {(startDate || endDate || tags || langFa || langEn) &&
            !showFilter && (
              <button
                onClick={clearFilter}
                className="text-xs text-caspian-error font-bold"
              >
                پاک کردن فیلتر
              </button>
            )}
        </div>
        {showPopup && (
          <PopUp setShowPopup={setShowPopup} popupData={popupData} />
        )}
        {showPopupPodCast && (
          <PopUpPodCast
            setShowPopupPodCast={setShowPopupPodCast}
            popupPodCastData={popupPodCastData}
          />
        )}
        <div
          className={
            records.length < 1 || records[0] === -1
              ? ""
              : "grid grid-cols-4 gap-4 w-full"
          }
        >
          {sortData === "date" &&
            (records[0] === -1 ? (
              <div className="flex flex-col gap-2 items-center justify-center py-24 w-full">
                <h2 className="text-center text-sm text-caspian-gray">
                  در حال بارگذاری اطلاعات...
                </h2>
              </div>
            ) : records.length < 1 ? (
              <div className="flex flex-col gap-2 items-center justify-center py-24 w-full">
                <img
                  src="/assets/images/library/not-found.svg"
                  alt="مطلبی یافت نشد..."
                />
                <h2 className="text-sm text-caspian-gray">مطلبی یافت نشد...</h2>
              </div>
            ) : (
              records.map((d, i) => (
                <TableTilesSingle
                  id={d.id}
                  key={i}
                  setShowPopup={setShowPopup}
                  setShowPopupPodCast={setShowPopupPodCast}
                  title={d.title}
                  abstract={d.abstract}
                  imagesLibrary={d.imagesLibrary}
                  filesLibrary={d.filesLibrary}
                  typeLibrary={d.typeLibrary}
                  createdAt={d.createdAt}
                  subjectEn={d.subjectEn}
                  shortDesc={d.shortDesc}
                  setDetails={setDetails}
                  setdataId={setdataId}
                  setShowDetailsTiles={setShowDetailsTiles}
                  setPopupData={setPopupData}
                  setPopupPodCastData={setPopupPodCastData}
                  language={d.language}
                  profileFavorite={d.profileFavorite}
                  setFavorite={setFavorite}
                />
              ))
            ))}

          {sortData === "atoz" &&
            (records.length < 1 ? (
              <h2>مطلبی یافت نشد...</h2>
            ) : (
              records.map((d, i) => (
                <TableTilesSingle
                  id={d.id}
                  key={i}
                  setShowPopup={setShowPopup}
                  setShowPopupPodCast={setShowPopupPodCast}
                  title={d.title}
                  abstract={d.abstract}
                  imagesLibrary={d.imagesLibrary}
                  filesLibrary={d.filesLibrary}
                  typeLibrary={d.typeLibrary}
                  createdAt={d.createdAt}
                  subjectEn={d.subjectEn}
                  shortDesc={d.shortDesc}
                  setDetails={setDetails}
                  setdataId={setdataId}
                  setShowDetailsTiles={setShowDetailsTiles}
                  setPopupData={setPopupData}
                  setPopupPodCastData={setPopupPodCastData}
                  language={d.language}
                  profileFavorite={d.profileFavorite}
                  setFavorite={setFavorite}
                />
              ))
            ))}
          {sortData === "view" &&
            (records.length < 1 ? (
              <h2>مطلبی یافت نشد...</h2>
            ) : (
              records.map((d, i) => (
                <TableTilesSingle
                  id={d.id}
                  key={i}
                  setShowPopup={setShowPopup}
                  setShowPopupPodCast={setShowPopupPodCast}
                  title={d.title}
                  abstract={d.abstract}
                  imagesLibrary={d.imagesLibrary}
                  filesLibrary={d.filesLibrary}
                  typeLibrary={d.typeLibrary}
                  createdAt={d.createdAt}
                  subjectEn={d.subjectEn}
                  shortDesc={d.shortDesc}
                  setDetails={setDetails}
                  setdataId={setdataId}
                  setShowDetailsTiles={setShowDetailsTiles}
                  setPopupData={setPopupData}
                  setPopupPodCastData={setPopupPodCastData}
                  language={d.language}
                  profileFavorite={d.profileFavorite}
                  setFavorite={setFavorite}
                />
              ))
            ))}
        </div>
        {records.length < 1 || records[0] === -1 ? (
          <></>
        ) : (
          <Paginate
            pageCount={npage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </section>
  );
};

export default TableTiles;
