import React, { useContext, useEffect, useState } from "react";
import Title from "./Title";
import DateTime from "./DateTime";
import Description from "./Description";
import Inputs from "./Inputs";
import HeadMembers from "./HeadMembers";
// import Members from "./Members";
import OrderMeeting from "./OrderMeeting";
// import Guest from "./Guest";
import Presentations from "./Presentations";
// import Invitation from "./Invitation";
import { PiCaretLeftBold } from "react-icons/pi";
import {
  useNavigate,
  useSearchParams,
  useParams,
  useLocation,
} from "react-router-dom";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import API from "../../../../../api/API";
import AllMembers from "./AllMembers";
import PresentMembers from "./PresentMembers";
import Approvals from "./Approvals";
import ApprovalsFile from "./ApprovalsFile";
import Outputs from "./Outputs";
import Refrence from "./Refrence";

const MainDetailsPlaninngMeeting = ({ setRefreshMeetingList }) => {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  console.log(params);
  const [isAccept, setIsAccept] = useState(false);

  const [serachParams, setSearchParams] = useSearchParams();
  const meeting = serachParams.get("meeting");

  function handleSearch(key, value) {
    setSearchParams((prevParams) => {
      if (value === null || value === "") {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    });
  }

  const [fullData, setFullData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const [showAll, setShowAll] = useState(false);
  const [hideAll, setHideAll] = useState(false);
  useEffect(() => {
    if (params) {
      setLoading(true);
      const getDataHandler = (e) => {
        API.get(`/api/v1/meeting/info/detail/${params.id}/`, {
          headers: { Authorization: token },
        })
          .then((res) => {
            setFullData(res?.data);
            setRefresh(0);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          })
          .finally(() => setLoading(false));
      };
      getDataHandler();
    }
  }, [token, refresh, params]);

  console.log(fullData);

  // status to wait
  const dataHandlerWait = async () => {
    if (!isAccept) return null;
    await API.post(
      `/api/v1/meeting/info/detail/${fullData?.id}/status/`,
      {
        status: "wait",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await postActivitySend();
        await setRefreshMeetingList(1);
        await navigate(`/managment-of-meetings/list-meeting/all-meeting`);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // get activity
  const getActivitySend = async () => {
    await API.get("/api/v1/profile/activity", {
      headers: { Authorization: token },
    })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // post activity
  const postActivitySend = async () => {
    await API.post(
      "/api/v1/meeting/info/activity",
      {
        meetingSelected_id: params.id,
        link: location.pathname + "?meeting=run",
      },
      {
        headers: { Authorization: token },
      }
    )
      .then(async (res) => {
        await getActivitySend();
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // status to archive
  const dataHandlerArchive = async () => {
    if (!isAccept) return null;
    await API.post(
      `/api/v1/meeting/info/detail/${fullData?.id}/status/`,
      {
        status: "archived",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await postActivitySend();
        await navigate(`/managment-of-meetings/records-meeting`);
        // await handleSearch("meeting", "");
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return fullData?.status === "archived" ? (
    navigate(`/managment-of-meetings/records-meeting/${fullData?.id}`, {
      relative: "path",
    })
  ) : loading ? (
    <h2 className="text-xs text-caspian-gray">در حال بارگزاری اطلاعات</h2>
  ) : fullData.length < 1 ? (
    <h2 className="text-xs text-caspian-gray">جلسه ی مورد نظر موجود نیست</h2>
  ) : (
    <div className="bg-caspian-surface p-3 pb-8 min-h-screen relative">
      <div className="flex items-center gap-0 absolute top-2 left-2">
        <button
          onClick={() => {
            setShowAll(true);
            setHideAll(false);
          }}
          className="text-[10px] p-1 bg-caspian-gray2/50 rounded-md rounded-l-none text-caspian-gray4 border-l border-l-caspian-gray2"
        >
          بازکردن همه
        </button>
        <button
          onClick={() => {
            setHideAll(true);
            setShowAll(false);
          }}
          className="text-[10px] p-1 bg-caspian-gray2/50 rounded-md rounded-r-none text-caspian-gray4"
        >
          بستن همه
        </button>
      </div>
      <div className="flex flex-col gap-3 w-full md:w-[90%] lg:w-[80%]">
        {/* تیتر */}
        <Title
          is_secretary={fullData?.is_secretary}
          id={fullData?.id}
          status={fullData?.status}
          showAll={showAll}
          hideAll={hideAll}
          setShowAll={setShowAll}
          setHideAll={setHideAll}
          title={fullData?.title}
          profileTitle={fullData?.meetingProfileRelated?.title}
        />
        {/* ساعت و تاریخ */}
        <DateTime fullData={fullData?.meetingDatetimeRelated[0]} />
        {/* توضیحات */}
        <Description
          showAll={showAll}
          hideAll={hideAll}
          setShowAll={setShowAll}
          setHideAll={setHideAll}
          description={fullData?.description}
        />
        {/* مرجع */}
        <Refrence
          showAll={showAll}
          hideAll={hideAll}
          setShowAll={setShowAll}
          setHideAll={setHideAll}
          fullData={
            fullData?.meetingProfileRelated?.meetingProfileRefrenceRelated
          }
        />
        {/* ورودی های جلسه */}
        <Inputs
          showAll={showAll}
          hideAll={hideAll}
          setShowAll={setShowAll}
          setHideAll={setHideAll}
          fullData={fullData?.meetingProfileRelated?.inputMeetingProfileRelated}
        />
        {/* خروجی های جلسه */}
        <Outputs
          showAll={showAll}
          hideAll={hideAll}
          setShowAll={setShowAll}
          setHideAll={setHideAll}
          fullData={
            fullData?.meetingProfileRelated?.outputMeetingProfileRelated
          }
        />
        {/* اعضای هیئت رئیسه */}
        <HeadMembers
          showAll={showAll}
          hideAll={hideAll}
          setShowAll={setShowAll}
          setHideAll={setHideAll}
          chairman={fullData?.meetingProfileRelated?.chairman}
          viceChairman={fullData?.meetingProfileRelated?.viceChairman}
          secretary={fullData?.meetingProfileRelated?.secretary}
        />
        {/* اعضای اصلی جلسه */}
        {/* <Members members={fullData?.meetingProfileRelated?.members} /> */}
        {/* همه ی اعضا */}
        <AllMembers
          showAll={showAll}
          hideAll={hideAll}
          setShowAll={setShowAll}
          setHideAll={setHideAll}
          mainMembers={fullData?.meetingMemberRelated?.filter(
            (d) => d.memberType === "main"
          )}
          guestMembers={fullData?.meetingMemberRelated?.filter(
            (d) => d.memberType === "guest"
          )}
          companyMembers={fullData?.meetingMemberRelated?.filter(
            (d) => d.memberType === "company"
          )}
        />

        {/* دستور */}
        <OrderMeeting
          showAll={showAll}
          hideAll={hideAll}
          setShowAll={setShowAll}
          setHideAll={setHideAll}
          fullData={fullData?.meetingAgendaRelated}
        />
        {/* مهمانان جلسه */}
        {/* <Guest
            fullData={fullData?.meetingMemberRelated?.filter(
              (d) => d.memberType !== "main"
            )}
          /> */}
        {/* فایل ارائه */}
        <Presentations
          showAll={showAll}
          hideAll={hideAll}
          setShowAll={setShowAll}
          setHideAll={setHideAll}
          fullData={fullData?.MeetingPresentationRelated[0].documentsRelated}
        />
        {/* دعوت‌نامه */}
        {/* <Invitation /> */}
        {/* لیست حضور غیاب */}
        {(fullData?.status === "approvalschairman" ||
          fullData?.status === "archived") && (
          <PresentMembers
            showAll={showAll}
            hideAll={hideAll}
            setShowAll={setShowAll}
            setHideAll={setHideAll}
            members={fullData?.meetingMemberRelated}
          />
        )}
        {/* مصوبات */}
        {(fullData?.status === "approvalschairman" ||
          fullData?.status === "archived") && (
          <Approvals
            showAll={showAll}
            hideAll={hideAll}
            setShowAll={setShowAll}
            setHideAll={setHideAll}
            fullData={fullData?.meetingAprovalRelated}
          />
        )}
        {/* فایل مصوبات */}
        {(fullData?.status === "approvalschairman" ||
          fullData?.status === "archived") && (
          <ApprovalsFile
            showAll={showAll}
            hideAll={hideAll}
            setShowAll={setShowAll}
            setHideAll={setHideAll}
            fullData={
              fullData?.MeetingFinalDocumentRelated[0]?.documentsRelated
            }
          />
        )}
        {fullData?.is_chairman && fullData?.status !== "wait" && (
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-2">
              <label
                htmlFor="accept"
                className="text-xs cursor-pointer font-bold"
              >
                اطلاعات فوق را تایید میفرمائید؟
              </label>
              <input
                onChange={(e) => setIsAccept(!isAccept)}
                id="accept"
                type="checkbox"
                className=" cursor-pointer accent-caspian-secondary-400"
              />
            </div>
            <div className="flex items-center gap-2">
              {fullData?.status === "approvalschairman" ? (
                <button
                  disabled={isAccept ? false : true}
                  onClick={async () => {
                    await dataHandlerArchive();
                  }}
                  className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
                >
                  ثبت مصوبات
                </button>
              ) : (
                <button
                  disabled={isAccept ? false : true}
                  onClick={async () => {
                    await dataHandlerWait();
                  }}
                  className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
                >
                  ثبت و ارسال
                </button>
              )}

              <button
                onClick={async () => {
                  await handleSearch("meeting", "");
                  // await setShowEditPlanningMeetingForm(true);
                  // console.log(fullData?.id);
                }}
                className="min-w-[6rem] outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs p-1.5 rounded-md flex items-center justify-center gap-1"
              >
                بازگشت
                <PiCaretLeftBold />
              </button>

              {/* <button
                onClick={async () => {
                  await console.log("cancel");
                }}
                className={`disabled:cursor-not-allowed disabled:text-caspian-gray text-caspian-error2 text-xs p-1.5 rounded-md`}
              >
                انصراف
              </button> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MainDetailsPlaninngMeeting;
