import React from "react";
import { PiCloudArrowDown, PiFileTextFill } from "react-icons/pi";

const SingleFile = ({ data }) => {
  return (
    <div className="flex items-center gap-2 w-[280px]">
      <div className="flex items-center justify-between border-b border-b-caspian-gray2 rounded-b-md w-full pb-1.5">
        <div className="flex items-center gap-2">
          <PiFileTextFill className="text-caspian-secondary-500 text-xl" />
          <span className="text-xs">{data?.name}</span>
        </div>
        <div className="flex items-center gap-1">
          <span className="text-xs text-caspian-gray4">MB</span>
          <span className="text-xs text-caspian-gray4">
            {data?.size ? data?.size : 5}
          </span>
        </div>
      </div>
      <a
        target="_blank"
        rel="noreferrer"
        download={data?.fileDocument}
        href={data?.fileDocument}
        className="text-lg text-caspian-secondary-300 hover:text-caspian-secondary-500"
      >
        <PiCloudArrowDown className="cursor-pointer text-caspian-gray4 text-xl" />
      </a>
    </div>
  );
};

export default SingleFile;
