import React, { useEffect, useRef, useState } from "react";
import { PiFunnel } from "react-icons/pi";

const Filter = ({
  setSearchTypeDocuments,
  searchByTypeDocuments,
  setCurrentPage,
}) => {
  let menuRef = useRef();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <div ref={menuRef} className="relative w-fit">
      <div onClick={() => setOpen(!open)} className="relative cursor-pointer">
        <PiFunnel className="text-xl text-caspian-surface cursor-pointer" />
      </div>
      {/* Start Open Menu */}
      <div
        className={
          open
            ? "z-50 overflow-y-scroll absolute top-5 left-0 flex items-center flex-col drop-shadow-[0_0_1px_#00000050] min-w-[160px] max-h-[160px] bg-caspian-surface rounded-lg bg-scroll"
            : "hidden"
        }
      >
        {/* Start Box Menu */}
        <div className="flex flex-col items-start gap-4 w-full p-3">
          <div className="flex items-center gap-4 w-full">
            <input type="checkbox" id="all" />
            <label
              htmlFor="all"
              className="text-sm text-caspian-gray font-normal w-full cursor-pointer"
            >
              همه
            </label>
          </div>
          <div className="flex items-center gap-4 w-full">
            <input
              onChange={(e) => {
                setSearchTypeDocuments(
                  e.target.checked
                    ? [...searchByTypeDocuments, e.target.value]
                    : searchByTypeDocuments.filter((d) => d !== e.target.value)
                );
                setCurrentPage(1);
              }}
              value="1"
              type="checkbox"
              id="1"
            />
            <label
              className="text-sm text-caspian-gray font-normal w-full cursor-pointer"
              htmlFor="1"
            >
              کد سند 1
            </label>
          </div>
          <div className="flex items-center gap-4 w-full">
            <input
              onChange={(e) => {
                setSearchTypeDocuments(
                  e.target.checked
                    ? [...searchByTypeDocuments, e.target.value]
                    : searchByTypeDocuments.filter((d) => d !== e.target.value)
                );
                setCurrentPage(1);
              }}
              value="2"
              type="checkbox"
              id="2"
            />
            <label
              className="text-sm text-caspian-gray font-normal w-full cursor-pointer"
              htmlFor="2"
            >
              کد سند 2
            </label>
          </div>
          <div className="flex items-center gap-4 w-full">
            <input
              onChange={(e) => {
                setSearchTypeDocuments(
                  e.target.checked
                    ? [...searchByTypeDocuments, e.target.value]
                    : searchByTypeDocuments.filter((d) => d !== e.target.value)
                );
                setCurrentPage(1);
              }}
              value="3"
              type="checkbox"
              id="3"
            />
            <label
              className="text-sm text-caspian-gray font-normal w-full cursor-pointer"
              htmlFor="3"
            >
              کد سند 3
            </label>
          </div>
        </div>
        {/* End Box Menu */}
      </div>
      {/* End Open Menu */}
    </div>
  );
};

export default Filter;
