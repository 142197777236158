import React, { useContext, useEffect, useState } from "react";
import { useAppContext } from "../../context/app-context/AppContext";
import Calendar from "./Calendar";
import API from "../../api/API";
import { AuthContext } from "../../context/auth/AuthContext";

const CalendarMain = ({ showMenu }) => {
  const { token } = useContext(AuthContext);
  const [eventData, setEventData] = useState([]);
  const { setBradCrump1 } = useAppContext();
  useEffect(() => {
    setBradCrump1("تقویم");
  }, [setBradCrump1]);

  useEffect(() => {
    if (!token) return;
    const eventHandler = async () => {
      try {
        const res = await API.get("/api/v1/profile/event", {
          headers: { Authorization: token },
        });
        setEventData(res?.data);
      } catch (error) {
        console.log(error);
      }
    };
    eventHandler();
  }, [token]);
  return (
    <div
      className={
        showMenu
          ? `pl-[60px] mr-auto w-full md:w-[75%] lg:w-[80%] xl:w-[85%] 2xl:w-[85%] bg-caspian-gray3 p-4 overflow-y-scroll min-h-screen`
          : `pl-[60px] mr-[54px] w-full bg-caspian-gray3 p-4 overflow-y-scroll`
      }
    >
      <Calendar eventData={eventData} />
    </div>
  );
};

export default CalendarMain;
