import { useNavigate } from "react-router-dom";

const SingleTableListActivites = ({
  id,
  title,
  text,
  sender,
  receiver,
  date,
  status,
  link,
  isReaded
}) => {
  const navigate = useNavigate();
  // const [showAction, setShowAction] = useState(true);
  const viewTeam = async () => {
    navigate(link);
  };

  return (
    <tr className={isReaded? "bg-caspian-primary-50":"bg-caspian-surface"}>
      <td
        onClick={viewTeam}
        className="text-center border-b border-b-caspian-gray2 p-2 w-[20%] cursor-pointer"
      >
        <h2 className="w-full text-xs text-caspian-secondary-800 font-bold">
          {sender}
        </h2>
      </td>
      <td
        onClick={viewTeam}
        className="text-center border-b border-b-caspian-gray2 p-2 w-[20%] cursor-pointer"
      >
        <h2 className="w-full text-xs text-caspian-secondary-800 font-bold">
          {receiver}
        </h2>
      </td>
      <td
        title={text}
        onClick={viewTeam}
        className="text-center border-b border-b-caspian-gray2 p-2 w-[25%] cursor-pointer"
      >
        <h2 className="w-full text-xs text-caspian-on_primary/80 font-bold line-clamp-1">
          {text}
          {/* {text?.length > 50 ? text?.slice(0, 50) + "..." : text} */}
        </h2>
      </td>
      <td
        onClick={viewTeam}
        className="text-center border-b border-b-caspian-gray2 p-2 w-[25%] cursor-pointer"
      >
        <h2 title={title} className="w-full text-xs text-caspian-on_primary/80 line-clamp-1">
          {title}
          {/* {title?.length > 50 ? title?.slice(0, 50) + "..." : title} */}
        </h2>
      </td>
      <td className="text-center border-b border-b-caspian-gray2 p-2 w-[10%]">
        <span className="w-full text-xs text-caspian-gray">
          {new Date(date).toLocaleDateString("fa-IR")}
        </span>
      </td>
    </tr>
  );
};

export default SingleTableListActivites;
