import React, { useContext, useEffect, useState } from 'react';
import { PiPlusBold, PiX } from 'react-icons/pi';
import ListStandard from '../../create-form-question/ListStandard';
import API from '../../../../../../api/API';
import { AuthContext } from '../../../../../../context/auth/AuthContext';
import ListClauseNew from './ListClauseNew';

const StandardModal = ({ setShowModal1, activitiesSelected3, setActivitiesSelected3, activitiesFinalSelected3, setActivitiesFinalSelected3, idPost, setList1,q,setQ }) => {
    const { token } = useContext(AuthContext);

    const [standardList, setStandardList] = useState([]);
    const [standard, setStandard] = useState("");
    const [standardSelected, setStandardSelected] = useState(null);
    const [showListStandard, setShowListStandard] = useState(false);

    const [showClause, setShowClause] = useState(false);

    const [finalObj, setFinalObj] = useState([]);
    const [clauseObj, setClauseObj] = useState([]);


    useEffect(() => {
        if (!token) return
        const fetchDataStandard = async () => {
            try {
                const res = await API.get('/api/v1/standard/', { headers: { Authorization: token } });
                setStandardList(res.data);
                // fetchClause()
                // setClauseSelected(null);
                // setClause('');
                setActivitiesSelected3([])
                setActivitiesFinalSelected3([])
            }
            catch (err) {
                console.log(err.message);
            }
        }
        fetchDataStandard();
    }, [token, standard])
console.log(finalObj);
    const handleStandard = async (id) => {

        // setList1({
        //     standardSelected,
        //     clause:finalObj
        // })
        if (!id) return
        let formData = {
            questionClauseRelated: id,
            standardRelated:standard,
            clauseRelated: activitiesFinalSelected3
        };
        try {
            const res = await API.post('/api/v1/audit/question/clause/', formData, { headers: { Authorization: token } });
            console.log(res.data);
            if (res.data) {
                setShowModal1(false);
                setQ(1);
        // setRefresh(true)
    }
    console.log(res.data);
    } catch (error) {
        console.log(error);
    }
}

return (
    <div className='fixed inset-0 z-[150] bg-caspian-on_primary/30 flex items-center justify-center'>
        <div className='bg-caspian-surface w-[30%] rounded-md'>
            <div className='flex justify-between items-center px-5 py-2'>
                <span>افزودن استاندارد و بند مرتبط</span>
                <PiX className='cursor-pointer' onClick={() => setShowModal1(false)} />
            </div>
            <div className='flex flex-col gap-12 border-t border-caspian-gray2'>
                <div className='flex flex-col gap-2 px-5 pt-3'>
                    {/* standard */}
                    <ListStandard
                        lable="استاندارد مرتبط"
                        standardList={standardList}
                        standard={standard}
                        setStandard={setStandard}
                        standardSelected={standardSelected}
                        setStandardSelected={setStandardSelected}
                        showListStandard={showListStandard}
                        setShowListStandard={setShowListStandard}
                    />
                </div>
                <div className='px-5 pb-3'>
                    {/* clause */}
                    <div
                        onClick={() => setShowClause(true)}
                        className='cursor-pointer border border-[#3C77A2] rounded-md py-2 p-3 '>
                        <div className='flex justify-between items-center'>
                            <p className='text-[#3C77A2] flex items-center gap-2 text-sm'>
                                <PiPlusBold />
                                <span>انتخاب</span>
                            </p>
                            {activitiesFinalSelected3?.length > 0 ? (
                                <span className="text-xs">
                                    {activitiesFinalSelected3?.length} بند افزوده شد
                                </span>
                            ) : (
                                <span className="text-xs">بندی انتخاب نشده</span>
                            )}
                        </div>
                        {showClause && (
                            <ListClauseNew
                                finalSelected={activitiesFinalSelected3}
                                setFinalSelected={setActivitiesFinalSelected3}
                                selected={activitiesSelected3}
                                setSelected={setActivitiesSelected3}
                                setShowSection={setShowClause}
                                showSection={showClause}
                                title="لیست واحد ها"
                                url={`/api/v1/standard/clause/all/${standard}`}
                                clauseObj={clauseObj}
                                setClauseObj={setClauseObj}
                                setFinalObj={setFinalObj}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className='flex gap-10 text-sm 2xl:text-xs px-5 pt-5 pb-2'>
                {/* button */}
                <button
                    onClick={() => handleStandard(idPost)}
                    className={`${activitiesFinalSelected3.length < 1 || !standard ? 'bg-caspian-gray4 cursor-not-allowed pointer-events-none text-caspian-gray2' : 'bg-caspian-secondary-500 text-caspian-surface'}  p-1.5 px-3 rounded-md`}>تایید</button>
                <button className='text-caspian-error2' onClick={()=>setShowModal1(false)}>لغو</button>
            </div>
        </div>
    </div>
);
}

export default StandardModal;
