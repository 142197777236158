import React from "react";

const MainDescription = ({ description }) => {
  return (
    <div className="flex flex-col gap-4 p-4">
      <div className="flex items-center gap-6">
        <span className="text-sm">توضیحات</span>
        <div className="h-[1px] bg-caspian-secondary-500 w-full"></div>
      </div>
      <p className="text-sm leading-6 text-justify">{description}</p>
    </div>
  );
};

export default MainDescription;
