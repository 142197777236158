import React, { useState, useEffect } from "react";
import StackholdersNavbar from "../StackholdersNavbar";
import CategoryStackholder from './CategoryStackholder';
import FloorStackholder from './FloorStackholder'
import API from '../../../api/API';
import MainPopUpStackholder from './pop-up-model-stackholder/MainPopUpStackholder';
import Loading from "../../../Loading";
import OneLineModelStackholder from "./line-model-stackholder/OneLineModelStackholder";
import TwoLineModelStackholder from "./line-model-stackholder/TwoLineModelStackholder";
import ThreeLineModelStackholder from "./line-model-stackholder/ThreeLineModelStackholder";



const MainModelStackholders = () => {
  const [stackData, setStackData] = useState([-1])
  const [showPopUp, setShowPopUp] = useState(false)
  const [stackholderId, setStackholderId] = useState(null)

  useEffect(() => {
    const handleData = async () => {
      try {
        const res = await API.get("/api/v1/stackholder/model")
        console.log(res.data)
        setStackData(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    handleData()
  }, [])

  let sidebar = localStorage.getItem('hide_sidebar')

  console.log(sidebar)

  let stackDataLenght1 = stackData.filter(item => item?.categoryType === "internal"); // درون سازمانی
  let stackDataLenght2 = stackData.filter(item => item?.categoryType === "external"); // برون سازمانی

  return (
    stackData[0] === -1 ? <Loading /> : stackData?.length < 1 ? <span>دیتایی یافت نشد</span> :
      <div className="flex flex-col gap-0" >
        <StackholdersNavbar buttonActive="model" />
        <div className='p-5 w-full flex flex-col items-center'>

          <div className="w-full min-h-screen px-10 pt-5 overflow-auto flex flex-col  items-center bg-caspian-surface shadow-[0_5px_5px_#00000050] rounded-md ">

            <div className="flex h-full justify-center items-center min-w-full">
              <div className={`flex flex-col items-center w-full h-full
              ${showPopUp ? 'translate-x-0'
                    : stackData?.length === 3 && sidebar ? 'translate-x-[3%]'

                      : stackData?.length === 4 && !sidebar ? ' md:-translate-x-[20%] lg:-translate-x-[5%] xl:-translate-x-[5%]'
                        : stackData?.length === 4 && sidebar ? ' md:-translate-x-[20%] lg:-translate-x-[0%] xl:-translate-x-[0%]'

                          : stackData?.length === 5 && !sidebar ? '-translate-x-[40%] md:-translate-x-[30%] lg:-translate-x-[25%] xl:-translate-x-[22%] 2xl:translate-x-[10%]'
                            : stackData?.length === 5 && sidebar ? '-translate-x-[30%] md:-translate-x-[25%] lg:-translate-x-[15%] xl:-translate-x-[15%] 2xl:translate-x-[10%]'

                              : stackData?.length === 6 && !sidebar ? '-translate-x-[35%] md:-translate-x-[32%] lg:-translate-x-[25%] xl:-translate-x-[25%]'
                                : stackData?.length === 6 && sidebar ? '-translate-x-[35%] md:-translate-x-[28%] lg:-translate-x-[18%] xl:-translate-x-[20%]'
                                  : 'translate-x-[0]'}}
                          `}>

                <div className={`min-w-full flex flex-col items-center
                ${stackData?.length === 5 ? '-translate-x-[5%]'
                    : '-translate-x-0'}
                  `}>
                  <span className='text-center px-10 py-2 text-xs 2xl:text-2xl text-caspian-on_primary/90 font-extrabold'>مدل ذینفعان</span>
                  {stackDataLenght1?.length !== 0 && stackDataLenght2?.length !== 0 &&
                    <div className='border-l-2 h-10 border-dashed border-caspian-darkgray-600'></div>
                  }
                </div>

                <div className={`border-b-2 h-2 border-dashed border-caspian-darkgray-600 relative flex bottom-0 w-3/6
                 ${stackData?.length === 1 ? 'translate-x-0 w-[0]'
                    : stackDataLenght1?.length === 0 && stackDataLenght2?.length === 2 ? 'translate-x-1/2 w-1/2 border-b-[0]'
                      : stackDataLenght1?.length === 2 && stackDataLenght2?.length === 0 ? '-translate-x-1/2 w-1/2 border-b-[0]'
                        : stackDataLenght1?.length === 0 && stackDataLenght2?.length === 3 ? 'translate-x-1/2 w-[67%] border-b-[0]'
                          : stackDataLenght1?.length === 3 && stackDataLenght2?.length === 0 ? '-translate-x-1/2 w-[67%] border-b-[0]'
                            : stackDataLenght1?.length === 1 && stackDataLenght2?.length === 1 ? 'translate-x-0'
                              : stackDataLenght1?.length === 1 && stackDataLenght2?.length === 2 ? 'translate-x-[16.5%]'
                                : stackDataLenght1?.length === 1 && stackDataLenght2?.length === 3 ? 'translate-x-[25%]'
                                  : stackDataLenght1?.length === 2 && stackDataLenght2?.length === 1 ? '-translate-x-[17%]'
                                    : stackDataLenght1?.length === 2 && stackDataLenght2?.length === 2 ? 'translate-x-0'
                                      : stackDataLenght1?.length === 2 && stackDataLenght2?.length === 3 ? 'translate-x-[10%] w-[50%]'
                                        : stackDataLenght1?.length === 3 && stackDataLenght2?.length === 1 ? '-translate-x-[25%]'
                                          : stackDataLenght1?.length === 3 && stackDataLenght2?.length === 2 ? '-translate-x-[10%] w-[50%]'
                                            : stackDataLenght1?.length === 3 && stackDataLenght2?.length === 3 && '-translate-x-[0%] w-[50%]'}`}>

                  {/* درون سازمانی */}

                  <div className='flex w-full items-center justify-start absolute -right-1 top-[70px]'>

                    {stackDataLenght1?.length === 1 ?
                      (<div>
                        <OneLineModelStackholder stackDataLenght1={stackDataLenght1} stackData={stackData} />
                      </div>
                      ) : stackDataLenght1?.length === 2 ? (
                        <div className="w-full" >
                          <TwoLineModelStackholder stackData={stackData} stackDataLenght1={stackDataLenght1} />
                        </div>
                      ) : stackDataLenght1?.length === 3 && (
                        <div className="w-full">
                          <ThreeLineModelStackholder stackData={stackData} stackDataLenght1={stackDataLenght1} />
                        </div>
                      )
                    }
                  </div>

                  {/* //////////////////////////////////////////////////////////////////////////////////// */}

                  {/* برون سازمانی */}

                  <div className='flex w-full items-center justify-end absolute -right-1 top-[71px]'>

                    {stackDataLenght2?.length === 1 ?
                      (<div>
                        <OneLineModelStackholder stackDataLenght2={stackDataLenght2} stackData={stackData} />
                      </div>
                      ) : stackDataLenght2?.length === 2 ? (
                        <div className="w-full">
                          <TwoLineModelStackholder stackData={stackData} stackDataLenght2={stackDataLenght2} />
                        </div>
                      ) : stackDataLenght2?.length === 3 && (
                        <div className="w-full">
                          <ThreeLineModelStackholder stackData={stackData} stackDataLenght2={stackDataLenght2} />
                        </div>
                      )
                    }
                  </div>
                </div>


                {/* درون سازمانی */}
                <div className='flex items-start justify-around pt-28 hf w-full'>
                  {stackDataLenght1?.map((item, index) => (
                    <div key={index} className='flex w-full gap-2 flex-col items-center'>
                      <div className='flex px-10 xl:px-20 items-start justify-center '>
                        <FloorStackholder categoryData={item} />
                      </div>
                      {item?.categoryRelatedSection?.map((section, index) => (
                        <div key={index} className='flex flex-col rounded-md'>
                          <CategoryStackholder section={section} sectionData={section} setShowPopUp={setShowPopUp} setStackholderId={setStackholderId} />

                          {showPopUp && section?.id === stackholderId && section?.sectionRelatedGroup?.length !== 0 &&
                            <MainPopUpStackholder categoryData={item} setStackholderId={setStackholderId} section={section} sectionData={section?.sectionRelatedGroup} setShowPopUp={setShowPopUp} />
                          }
                        </div>
                      ))}
                    </div>
                  ))
                  }

                  {/* //////////////////////////////////////////////////////////////////////////////////// */}


                  {/* برون سازمانی */}
                  {stackDataLenght2?.map((item, index) => (
                    <div key={index} className='flex w-full gap-2 flex-col items-center'>
                      <div className='flex px-10 xl:px-20 items-start justify-center'>
                        <FloorStackholder categoryData={item} />
                      </div>
                      {item?.categoryRelatedSection?.map((section, index) => (
                        <div key={index} className='flex flex-col rounded-md'>
                          <CategoryStackholder section={section} sectionData={section} setShowPopUp={setShowPopUp} setStackholderId={setStackholderId} />

                          {showPopUp && section?.id === stackholderId && section?.sectionRelatedGroup?.length !== 0 &&
                            <MainPopUpStackholder categoryData={item} setStackholderId={setStackholderId} section={section} sectionData={section?.sectionRelatedGroup} setShowPopUp={setShowPopUp} />
                          }
                        </div>
                      ))}
                    </div>
                  ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
  );
};

export default MainModelStackholders;