import React from "react";
import { PiCircleNotch } from "react-icons/pi";

const LoadingPlanning = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full z-50">
      <div className="fixed inset-0 bg-caspian-on_primary/70 z-50 p-4"></div>
      <div className="z-50 flex flex-col gap-2 items-center">
        <h2 className="text-caspian-surface text-xs">در حال انجام عملیات...</h2>
        <PiCircleNotch className="text-caspian-surface text-xl animate-spin" />
      </div>
    </div>
  );
};

export default LoadingPlanning;
