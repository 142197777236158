import React, { useState } from "react";
import DescriptionDetails from "./DescriptionDetails";
import ConsiderationsDetails from "./ConsiderationsDetails";
import DocumentDetails from "./DocumentDetails";
import RevisionMain from "./RevisionMain";

const TabProfile = ({ data }) => {
  const [documentDetails, setDocumentDetails] = useState(true);
  const [description, setDescription] = useState(false);
  const [considerationsDetails, setConsiderationsDetails] = useState(false);
  const [revision, setRevision] = useState(false);
  return (
    <div className="flex items-start w-full h-full rounded-xl bg-caspian-surface shadow-[0_0_5px_#00000010] border border-caspian-gray2 p-3">
      <div className="relative w-full flex flex-col gap-2">
        <nav>
          <ul className="flex items-center gap-4">
            <li
              onClick={() => {
                setDocumentDetails(true);
                setDescription(false);
                setConsiderationsDetails(false);
                setRevision(false);
              }}
              className={`${
                documentDetails
                  ? "border-b border-b-caspian-secondary-500 text-caspian-secondary-500"
                  : "border-b border-b-caspian-transparent"
              } text-sm cursor-pointer p-1`}
            >
              جزئیات سند
            </li>
            <li
              onClick={() => {
                setDocumentDetails(false);
                setDescription(true);
                setConsiderationsDetails(false);
                setRevision(false);
              }}
              className={`${
                description
                  ? "border-b border-b-caspian-secondary-500 text-caspian-secondary-500"
                  : "border-b border-b-caspian-transparent"
              } text-sm cursor-pointer p-1`}
            >
              توضیحات
            </li>
            <li
              onClick={() => {
                setDocumentDetails(false);
                setDescription(false);
                setConsiderationsDetails(true);
                setRevision(false);
              }}
              className={`${
                considerationsDetails
                  ? "border-b border-b-caspian-secondary-500 text-caspian-secondary-500"
                  : "border-b border-b-caspian-transparent"
              } text-sm cursor-pointer p-1`}
            >
              ملاحظات
            </li>
            <li
              onClick={() => {
                setDocumentDetails(false);
                setDescription(false);
                setConsiderationsDetails(false);
                setRevision(true);
              }}
              className={`${
                revision
                  ? "border-b border-b-caspian-secondary-500 text-caspian-secondary-500"
                  : "border-b border-b-caspian-transparent"
              } text-sm cursor-pointer p-1`}
            >
              بازبینی
            </li>
          </ul>
        </nav>
        <>
          {description ? (
            <DescriptionDetails data={data.description} />
          ) : documentDetails ? (
            <DocumentDetails data={data} />
          ) : considerationsDetails ? (
            <ConsiderationsDetails data={data} />
          ) : revision ? (
            <RevisionMain data={data} />
          ) : (
            <DescriptionDetails data={data.description} />
          )}
        </>
      </div>
    </div>
  );
};

export default TabProfile;
