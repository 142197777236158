import { useNavigate, useSearchParams } from "react-router-dom";
import { PiBookmarkSimple, PiCloudArrowDown } from "react-icons/pi";
import { useState } from "react";
import { useAppContext } from "../../../../context/app-context/AppContext";

const SingleTableExternalDocuments = ({
  businessProcessTitle,
  businessProcessCode,
  businessProcessCategory,
  businessProcessGroup,
  processResponsible,
}) => {
  const [serachParams] = useSearchParams();
  const navigate = useNavigate();
  const { setBradCrump3 } = useAppContext();

  const [showDownloadBox, setShowDownloadBox] = useState(false);
  const [deActiveProfileLink, setDeActiveProfileLink] = useState(false);

  return (
    <tr
      onMouseEnter={() => setShowDownloadBox(true)}
      onMouseLeave={() => setShowDownloadBox(false)}
      onClick={() => {
        !deActiveProfileLink && setBradCrump3(businessProcessTitle);
        !deActiveProfileLink && console.log("open link");
      }}
      className="cursor-pointer bg-caspian-surface odd:bg-caspian-gray3 duration-300"
    >
      <td colSpan={4} className="text-center border-b border-b-caspian-gray2 px-3 py-2">
        <h2 className="line-clamp-1">{businessProcessTitle}</h2>
      </td>
      <td colSpan={2} className="whitespace-nowrap text-center border-b border-b-caspian-gray2 font-sans">
        <h2>{businessProcessCode}</h2>
      </td>
      <td colSpan={2} className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2">
        <h2>{businessProcessCategory}</h2>
      </td>
      <td
        colSpan={4}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2"
      >
        <div className="flex items-center justify-between w-full">
          <h2>{processResponsible}</h2>
          {showDownloadBox && (
            <div
              onMouseEnter={() => setDeActiveProfileLink(true)}
              onMouseLeave={() => setDeActiveProfileLink(false)}
              className="flex items-center gap-2 ml-4"
            >
              <button>
                <PiBookmarkSimple className="text-xl text-caspian-secondary-500" />
              </button>
              <a download={"/"} href={"/"}>
                <PiCloudArrowDown className="text-xl text-caspian-secondary-500" />
              </a>
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};

export default SingleTableExternalDocuments;
