import { PiNote, PiClipboardText } from "react-icons/pi";
import ListItemSingle from "./ListItemSingle";
import { useAppContext } from "../../context/app-context/AppContext";
import { useLocation, useParams } from "react-router-dom";

const ProcessNavbar = ({ buttonActive }) => {
  const { setBradCrump2, setBradCrump3 } = useAppContext();
  const params = useParams();
  const location = useLocation();
  const search = (location.state && location.state.search) || "";
  return (
    <nav className="w-full bg-caspian-surface rtl">
      <ul className="flex items-center gap-0.5">
        <ListItemSingle
          title="مدل فرایندی"
          active="model"
          icon={<PiClipboardText />}
          link="/process-management/model"
          buttonActive={buttonActive}
          onClick={() => setBradCrump2("مدل فرایندی")}
        />
        {/* <ListItemSingle
          title="نقشه فرایندی"
          active="map"
          icon={<PiClipboardText />}
          link="/process-management/map"
          buttonActive={buttonActive}
          onClick={()=>setBradCrump2("نقشه فرایندی")}
        /> */}
        <ListItemSingle
          title="لیست فرایند ها"
          active="list-processes"
          icon={<PiClipboardText />}
          link={
            search
              ? `/process-management/${search}`
              : "/process-management/list-processes"
          }
          buttonActive={!params?.code && buttonActive}
          onClick={() => {
            setBradCrump2("لیست فرایندها");
            setBradCrump3("");
          }}
        />
        {params.code && (
          <ListItemSingle
            title="شناسنامه فرایند"
            active="list-processes"
            icon={<PiNote />}
            link={`/process-management/list-processes/${params?.code}`}
            buttonActive={buttonActive}
            // onClick={() => setBradCrump2("شناسنامه فرایند")}
          />
        )}
      </ul>
    </nav>
  );
};

export default ProcessNavbar;
