import axios from "axios";

export default axios.create({
  // baseURL: "https://caspian-team-server.onrender.com/api",
  // baseURL: "http://localhost:5000/api",
  // baseURL: "http://185.8.173.51:8000",
  // baseURL: "http://194.48.198.131:8000",
  // baseURL: "http://188.121.115.23",
  baseURL: "http://103.75.196.114",
});
