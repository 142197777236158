import React from "react";
import { useSearchParams } from "react-router-dom";

const VersionBoxActive = ({
  title,
  obsolete,
  date,
  zIndex,
  startVersion,
  active,
  onClick,
}) => {
  const [serachParams] = useSearchParams();
  const version = serachParams.get("version");
  return !obsolete ? (
    <div
      onClick={onClick}
      className={`flex items-center gap-0 ${!active && "cursor-pointer"}`}
      style={{ zIndex: zIndex }}
    >
      <div
        className={`${
          active
            ? "bg-[#01BC8D] text-caspian-surface"
            : "bg-[#01BC8D70] text-[#666666]"
        } pl-2 pr-6 w-auto h-12 rounded-md- flex items-start justify-center flex-col gap-1 relative ${
          startVersion && "rounded-s-md"
        }`}
      >
        <span className="text-sm">ویرایش {title}</span>
        <div className="flex items-center justify-between w-full gap-4">
          <span className="text-xs">
            {!obsolete && active ? "منتشر شده در تاریخ" : "معتبر"}
          </span>
          {active && date && (
            <span className="text-xs">
              {new Date(date).toLocaleDateString("fa-IR")}
            </span>
          )}
        </div>
        {startVersion !== true && (
          <div
            className="w-0 h-0
            border-t-[1.5rem] 
            border-t-caspian-transparent
            border-b-[1.5rem] 
            border-b-caspian-transparent
            border-r-[.75rem] 
            border-r-caspian-surface
            absolute top-1/2 -translate-y-1/2 right-0 z-50
            "
          ></div>
        )}
        <div
          className={`w-0 h-0
              border-t-[1.5rem] 
              border-t-caspian-transparent
              border-b-[1.5rem] 
              border-b-caspian-transparent
              border-r-[0.75rem] 
              rounded-t-md-
              rounded-b-md-
              absolute top-1/2 -translate-y-1/2 -left-3
              ${active ? "border-r-[#01BC8D]" : "border-r-[#01BC8D70]"}
              `}
        ></div>
      </div>
    </div>
  ) : (
    <div
      onClick={onClick}
      className={`flex items-center gap-0 ${!active && "cursor-pointer"}`}
      style={{ zIndex: zIndex }}
    >
      <div
        className={`${
          active
            ? "bg-caspian-gray2/80 text-caspian-on_primary/80"
            : "bg-caspian-gray2/50 text-[#808080]"
        } pl-2 pr-6 w-auto h-12 rounded-md- flex items-start justify-center flex-col gap-1 relative ${
          startVersion && "rounded-s-md"
        }`}
      >
        <span className="text-sm">ویرایش {title}</span>
        <div className="flex items-center gap-4">
          <span className="text-xs">
            {obsolete && active ? "منسوخ شده در تاریخ" : "منسوخ"}
          </span>
          {active && date && (
            <span className="text-xs">
              {new Date(date).toLocaleDateString("fa-IR")}
            </span>
          )}
        </div>
        {startVersion !== true && (
          <div
            className="w-0 h-0
            border-t-[1.5rem] 
            border-t-caspian-transparent
            border-b-[1.5rem] 
            border-b-caspian-transparent
            border-r-[.75rem] 
            border-r-caspian-surface
            absolute top-1/2 -translate-y-1/2 right-0 z-50
            "
          ></div>
        )}
        <div
          className={`w-0 h-0
              border-t-[1.5rem] 
              border-t-caspian-transparent
              border-b-[1.5rem] 
              border-b-caspian-transparent
              border-r-[0.75rem] 
              border-r-caspian-gray2/50
              border-
              absolute top-1/2 -translate-y-1/2 -left-3
              ${
                active
                  ? "border-r-caspian-gray2/80"
                  : "border-r-caspian-gray2/50"
              }
              `}
        ></div>
      </div>
    </div>
  );
};

export default VersionBoxActive;
