import React from 'react'
import Modal from '../../../../../../modal/Modal'
import { PiX, PiCloudArrowDownLight } from 'react-icons/pi'
import splitNameFile from '../../../../../../../helper/splitNameFile'

const ModalListWorkProgressReport = ({ setShowModal, selectId, fullData }) => {

    const convertSize = (size) => {
        if (size < (1024 * 1024)) {
            return (size / 1024).toFixed(1) + "KB"
        } else if (size < (1024 * 1024 * 1024)) {
            return (size / (1024 * 1024)).toFixed(1) + "MB"
        } else {
            return (size / (1024 * 1024 * 1024)).toFixed(1) + "GB"
        }
    }
    return (
        <Modal>
            {fullData?.progress_taskreportProfile[0]?.documents?.map((item) => (

                item?.id === selectId &&

                <div key={item?.id} className='flex flex-col items-center w-2/3 md:w-1/2 lg:w-1/3 gap-5 p-5 rounded-lg shadow-md bg-caspian-surface'>
                    <div className='flex items-center justify-between w-full'>
                        <span className='text-xs 2xl:text-base'>جزئیات سند</span>
                        <PiX onClick={() => setShowModal(false)}
                            className='text-base cursor-pointer text-caspian-gray4 2xl:text-2xl' />
                    </div>

                    <div className='flex flex-col items-center w-full gap-5 '>
                        <div className='flex items-center w-full gap-5 border-b-2 border-caspian-gray2'>
                            <span className='py-3 text-xs 2xl:text-sm whitespace-nowrap text-caspian-gray4'>عنوان سند :</span>
                            <span className='text-xs 2xl:text-sm'>{item?.name}</span>
                        </div>

                        <div className='flex items-start w-full gap-5 pb-3 border-b-2 border-caspian-gray2'>
                            <span className=' text-xs 2xl:text-sm whitespace-nowrap text-caspian-gray4'>توضیحات :</span>
                            <span className='text-xs 2xl:text-sm'>{item?.description ? item?.description : "-"}</span>
                        </div>


                        <div className='flex flex-col items-start w-full '>
                            <span className='py-3 text-xs 2xl:text-sm text-caspian-gray4'>فایل بارگذاری شده سند :</span>

                            <div className='flex items-center w-full gap-3'>
                                <div className='w-[90%] px-4 py-2 flex items-center justify-between border-b-2 rounded-md  border-caspian-gray2'>
                                    <div className='flex items-center w-full gap-5 '>
                                        <img src='/assets/images/action-plan/Group.svg' className='w-4 2xl:w-6' alt='' />
                                        <span className='text-xs '>{splitNameFile(item?.fileDocument)}</span>
                                    </div>

                                    <span className='text-xs  text-caspian-gray4'>{convertSize(item?.size)}</span>

                                </div>
                                <a href={item?.fileDocument} download={true}>
                                    <PiCloudArrowDownLight className='text-2xl cursor-pointer text-caspian-secondary-600' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </Modal >
    )
}

export default ModalListWorkProgressReport