import React from "react";

const PopupDetails = ({ users }) => {

  return (
    <div className="drop-shadow-[0_0_5px_#00000080] absolute top-[95%] right-0 z-50 flex flex-col gap-0">
      <div className="z-50 mr-10 right-2 top-0 w-0 h-0 border-l-[12px] border-l-caspian-transparent border-b-[12px] border-b-caspian-surface border-r-[12px] border-r-caspian-transparent"></div>
      <div className="min-w-[240px] max-w-[240px] min-h-[120px] p-3 text-xs leading-5  bg-caspian-surface rounded-md">
        <div className="flex flex-col items-start gap-2 text-caspian-on_primary">
          <span className="text-xs font-bold">تیم ممیزی</span>
          <div className="flex flex-col gap-2 max-h-[100px] overflow-y-auto w-full">
            {users.map((user, i) => (
              <div key={i} className="flex items-center gap-2">
                <div
                  style={{
                    backgroundColor: `#${user?.colorHexRelated?.hexCode}`,
                  }}
                  className="w-2 h-2 rounded-full shadow-[0_0_3px_#00000090] mr-1"
                ></div>
                <span className="text-xs">
                  {user?.profileAuditorRelated?.firstName +
                    " " +
                    user?.profileAuditorRelated?.lastName}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupDetails;
