import { PiCaretLeft } from "react-icons/pi";
import { useAppContext } from "../../context/app-context/AppContext";
import { useLocation } from "react-router-dom";

const BreadCrump = () => {
  const location = useLocation();

  const search = (location.state && location.state.search) || "";
  const { bradCrump1, bradCrump2, bradCrump3, brc, brcLink2 } = useAppContext();
  return (
    <div className="flex items-center gap-2">
      {bradCrump1 !== "" && (
        <h2 className="text-caspian-gray text-sm">{bradCrump1}</h2>
      )}
      {bradCrump1 !== "" &&
        bradCrump2 !== "" &&
        brc(bradCrump2, `${brcLink2}${search}`)}
      {bradCrump2 !== "" && bradCrump3 !== "" && (
        <div className="flex items-center gap-2">
          <PiCaretLeft className="text-lg" />
          <h2 title={bradCrump3} className="text-sm">
            {bradCrump3?.length > 0 && bradCrump3?.split(" ")?.length > 9
              ? bradCrump3?.split(" ")?.slice(0, 9)?.join(" ") + "..."
              : bradCrump3}
          </h2>
        </div>
      )}
    </div>
  );
};

export default BreadCrump;
