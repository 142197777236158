import { PiX } from 'react-icons/pi'
import Modal from '../../../../modal/Modal';
import { priceType } from '../../../../../helper/pricetype';

const InfoTaskProfile = ({ setShowInfoProfile }) => {
    const priceInfoProfile = 20000000;

    return (
        <Modal>
            <div className='w-1/2 flex flex-col gap-0 shadow-2xl fixed rounded-md bg-caspian-surface'>
                {/* start header info-profile */}
                <div className="flex items-center justify-between p-4">
                    <span className="">پروفایل گزارش</span>
                    <PiX className="text-2xl cursor-pointer" onClick={() => setShowInfoProfile(false)} />
                </div>
                {/* end header info-profile */}

                {/* start content info-profile */}
                <div className="flex flex-col gap-4 pb-5 px-4">
                    <div className="flex gap-1">
                        <span className="text-caspian-gray4 whitespace-nowrap text-sm leading-7">عنوان :</span>
                        <p className="text-md  leading-7">تعمیرات ساختمان در جهت بهبود بهتر بنا و تکمیل آن </p>
                    </div>

                    <div className="flex gap-1">
                        <span className="text-caspian-gray4 text-sm whitespace-nowrap leading-7">توضیحات :</span>
                        <p className="text-sm leading-7">در تعمیرات ساختمان باید در جهت بهبود بهتر بنا و تکمیل آن توضیحاتی در این قسمت بنویسید تا برای ادامه انجام کار مورد استفاده قرار گیرد بلورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است.</p>
                    </div>

                    <div className="flex gap-1">
                        <span className="text-caspian-gray4 text-sm whitespace-nowrap">بودجه :</span>
                        <span className="text-sm">{priceType(priceInfoProfile)}</span>
                    </div>

                    <div className="flex gap-1">
                        <span className="text-caspian-gray4 text-sm whitespace-nowrap">نوع :</span>
                        <span className="text-sm">معمولی</span>
                    </div>

                    <div className="flex gap-1">
                        <span className="text-caspian-gray4 text-sm whitespace-nowrap">مجری :</span>
                        <span className="text-sm">رضا پرتوی</span>
                    </div>

                    <div className="flex gap-1">
                        <span className="text-caspian-gray4 text-sm whitespace-nowrap">کنترل کننده :</span>
                        <span className="text-sm">کاظم محمودی</span>
                    </div>

                    <div className='flex gap-12'>

                        <div className='flex gap-1'>
                            <span className='text-caspian-gray4 text-sm '> تاریخ شروع  : </span>
                            <span className='text-sm'> 1402/01/01</span>
                        </div>

                        <div className='flex gap-1'>
                            <span className='text-caspian-gray4 text-sm '> تاریخ پایان  : </span>
                            <span className='text-sm'> 1402/01/01</span>
                        </div>

                    </div>

                    <div className='flex gap-1'>
                        <span className='text-caspian-gray4 text-sm '>وزن:</span>
                        <span className='text-sm'> 20%</span>
                    </div>

                </div>
                {/* end header info-profile */}

            </div>
        </Modal>
    )
}

export default InfoTaskProfile