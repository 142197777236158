import React, { useEffect, useRef, useState } from "react";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import DetailsMenubar from "./DetailsMenubar";

const Menubar = ({
  noteId,
  setShowDetails,
  isFav,
  refresh,
  setRefresh,
  isTrash,
  showEditNote,
  setShowEditNote,
}) => {
  let menuRef = useRef();

  const [open, setOpen] = useState(false);
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });
  return (
    <div
      ref={menuRef}
      onClick={() => setOpen(!open)}
      className={`relative cursor-pointer`}
    >
      <PiDotsThreeVerticalBold className="text-xl" />
      <DetailsMenubar
        showEditNote={showEditNote}
        setShowEditNote={setShowEditNote}
        open={open}
        setOpen={setOpen}
        noteId={noteId}
        setShowDetails={setShowDetails}
        isFav={isFav}
        refresh={refresh}
        setRefresh={setRefresh}
        isTrash={isTrash}
      />
    </div>
  );
};

export default Menubar;
