import React from 'react'

const OverBudget = ({ cost, costProgress, totalCost }) => {


    let value1 = cost // بودجه
    let total = ((totalCost + costProgress)) //  بودجه مصرف شده + جمع هزینه این مرحله  
    let value2 = total - value1;
    let valuePercentCost = ((value1 / total) * 100) // درصد بودجه
    let SpentBudgetPerecent = ((costProgress / value1) * 100); // درصد بودجه مصرف شده 
    let valuePercentTotalCost = ((totalCost / value1) * 100) // درصد جمع هزینه این مرحله
    let valuePercent = (100 - valuePercentCost) // درصد اضافه بودجه مصرفی 


    return (
        <div className='flex flex-col items-center w-full'>

            <div className='flex w-full max-w-[500px] flex-col items-center gap-5'>

                {/* بودجه  */}
                <div className='flex items-center w-full gap-2'>
                    <div className='relative flex flex-col items-end re basis-3/6'>


                        <div style={{ width: `${valuePercentCost}%` }}
                            className={`h-1 flex items-center justify-center relative rounded-lg bg-caspian-gray4`}>

                            {value1 > 0 &&
                                <span
                                    className='absolute text-xs text-center whitespace-nowrap bottom-2 text-caspian-on_primary/80'>
                                    {value1} ریال
                                </span>
                            }
                        </div>

                        <div style={{ left: `${valuePercentCost}%` }}
                            className={`border-l border-dashed border-l-caspian-gray h-[120px] absolute `}>
                        </div>

                    </div>
                    <div className='flex basis-2/6'>
                        <span className='text-xs 2xl:text-sm'>بودجه</span>
                    </div>
                    <div className='flex basis-1/6'>
                        <div className='w-3 h-3 rounded-sm bg-caspian-gray4'></div>
                    </div>
                </div>

                {/* بودجه مصرف شده */}
                <div className='flex items-center w-full gap-2'>
                    <div className='flex flex-col items-end basis-3/6'>
                        <div className='flex flex-col items-end' style={{ width: `${valuePercentCost}%` }}>

                            <div style={{ width: `${SpentBudgetPerecent}%` }}
                                className={`h-1 flex items-center justify-center relative rounded-lg text-xs bg-caspian-darkblue-700`}>

                                {costProgress > 0 &&
                                    <span
                                        className='absolute text-xs text-center whitespace-nowrap bottom-2 text-caspian-on_primary/80'>
                                        {costProgress} ریال
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='flex basis-2/6'>
                        <span className='text-xs 2xl:text-sm'>بودجه مصرف شده</span>
                    </div>
                    <div className='flex basis-1/6'>
                        <div className={`w-3 h-3 rounded-sm bg-caspian-darkblue-700`}></div>
                    </div>
                </div>

                {/* بودجه هزینه این مرحله */}
                <div className='flex items-center w-full gap-2'>
                    <div className='relative flex flex-col items-end w-full basis-3/6'>
                        <div className='flex flex-col w-full'>


                            <div className='flex justify-end w-full'>
                                <div style={{ width: `${valuePercentTotalCost}%` }}
                                    className={`h-1 flex items-center justify-center relative rounded-lg bg-caspian-blue-400`}>

                                    {costProgress > 0 &&
                                        <span className='absolute w-full text-xs text-center whitespace-nowrap bottom-2 text-caspian-on_primary/80'>
                                            {totalCost} ریال
                                        </span>
                                    }
                                </div>
                                <div style={{ width: `${(costProgress / cost) * 100}%` }}></div>
                            </div>
                        </div>
                    </div>

                    <div className='flex basis-2/6'>
                        <span className='text-xs 2xl:text-sm'>جمع هزینه این مرحله</span>
                    </div>
                    <div className='flex basis-1/6'>
                        <div className={`w-3 h-3 rounded-sm bg-caspian-blue-400`}></div>
                    </div>
                </div>

                {/* بودجه اضافی */}
                {value2 > 0 &&
                    < div className='flex items-center w-full gap-2'>
                        <div className='relative flex flex-col items-end basis-3/6'>


                            <div className='flex flex-col items-end w-full'>
                                <div className='flex justify-end w-full'>

                                    <div style={{ width: `${valuePercent}%` }}
                                        className={`h-1 flex items-center justify-center relative rounded-lg bg-caspian-error2`}>


                                        <span className='absolute w-full text-xs text-center whitespace-nowrap bottom-2 text-caspian-on_primary/80'>
                                            {value2} ریال
                                        </span>
                                    </div>

                                    <div style={{ width: `${valuePercentCost}%` }}></div>
                                </div>
                            </div>
                        </div>

                        <div className='flex basis-2/6'>
                            <span className='text-xs 2xl:text-sm'>اضافه بودجه مصرفی</span>
                        </div>
                        <div className='flex basis-1/6'>
                            <div className={`w-3 h-3 rounded-sm bg-caspian-error2`}></div>
                        </div>
                    </div>
                }
            </div>
        </div >
    )
}

export default OverBudget