import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { PiMagnifyingGlassBold, PiX } from "react-icons/pi";

const SearchListStackholders = ({ search, setSearch, setCurrenPage, setOffset }) => {
    const ref = useRef(null);
    const result = (e) => {
        if (e.key === 'Enter') {
            setSearch(e.target.value)
            handleSearch('search', e.target.value)
            setOffset(0)
            setCurrenPage(1);
            handleSearch('page', '')
        }
        else return
    }
    const rst = () => {
        setSearch('')
        ref.current.value = ""
        handleSearch('search', '')
        handleSearch('page', '')
        setCurrenPage(1);
    }
    const [searchParams, setSearchParams] = useSearchParams();
    const searchQuery = searchParams.get("search");

    function handleSearch(key, value) {
        setSearchParams((prevParams) => {
            if (value === null || value === "") {
                prevParams.delete(key);
            } else {
                prevParams.set(key, value);
            }
            return prevParams;
        });
    }

    useEffect(() => {
        if (searchQuery) {
            ref.current.value = searchQuery;
            setSearch(searchQuery)
        }
    }, [search])
    return (

        <div className='w-[30%] relative'>
            <PiMagnifyingGlassBold className='absolute -translate-y-1/2 top-1/2 right-2 text-caspian-gray2 text-xl' />
            <input
                className='w-full border focus:outline-none border-caspian-gray2 rounded-md pr-8 py-2 text-xs 2xl:text-sm'
                placeholder='جستجو'
                type="search"
                onKeyDown={(e) => result(e)}
                ref={ref}
            />
            <PiX
                onClick={() => rst()}
                className={`${search ? ' visible' : 'invisible'} absolute left-3 top-1/2 -translate-y-1/2 cursor-pointer`} />
        </div>
    );
}


export default SearchListStackholders;
