import React from "react";
import { useParams } from "react-router-dom";
import DetailsNote from "../note/DetailsNote";
import AllNotes from "./AllNotes";

const DetailsNotebook = () => {
  const params = useParams();

  return params.note ? (
    <DetailsNote />
  ) : (
    <AllNotes notebookId={params?.notebook} />
  );
};

export default DetailsNotebook;
