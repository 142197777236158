import React, { useState } from "react";
import MenuAction from "./MenuAction";

const ChatBoxReciver = ({
  firstIndex,
  messageId,
  reciver,
  message,
  date,
  avatar,
  setRefreshMessageList,
  setMessageInput,
  setEditButton,
  setMessageId,
  isFav,
  reciverMenu,
  x,
}) => {
  const [showName, setShowName] = useState(false);

  return (
    <div className={`${!x ? "" : "mt-4"} flex items-start gap-2 relative ltr`}>
      {/* <span>{sender}</span> */}
      <div
        onMouseEnter={() => setShowName(true)}
        onMouseLeave={() => setShowName(false)}
        className="w-8 h-8 rounded-full flex items-center justify-center overflow-hidden shadow-[0_0_2px_#00000050] cursor-pointer bg-[#34A853] rtlD"
      >
        {showName && (
          <div className="bg-caspian-on_primary bg-opacity-80 p-2 rounded-bl-none rounded-md flex items-center justify-center absolute -top-5 left-5 z-50">
            <span className="text-xs text-caspian-surface">{reciver}</span>
          </div>
        )}
        {avatar !== null &&
        avatar !== undefined &&
        !avatar?.endsWith("null") ? (
          <img
            className="w-8 h-8 rounded-full"
            src={avatar}
            alt={reciver.trim()[0]}
          />
        ) : (
          <h4 className="text-2xl text-caspian-surface">{reciver.trim()[0]}</h4>
        )}
      </div>
      <div className="flex flex-col gap-2 bg-caspian-secondary-10 p-2 rounded-md rounded-tl-none rounded-br-2xl w-fit min-w-[30%] max-w-[70%] shadow-[0_0_5px_#00000050] border border-caspian-secondary-200">
        <p className="text-xs leading-5 text-right ">{message}</p>
        <div className="flex items-center justify-between gap-2 w-full relative">
          <MenuAction
            reciverMenu={reciverMenu}
            isFav={isFav}
            setMessageId={setMessageId}
            setEditButton={setEditButton}
            message={message}
            setMessageInput={setMessageInput}
            messageId={messageId}
            setRefreshMessageList={setRefreshMessageList}
            firstIndex={firstIndex}
          />
          <span className="text-[9px] text-caspian-gray4">{date}</span>
        </div>
      </div>
    </div>
  );
};

export default ChatBoxReciver;
