import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../../context/auth/AuthContext";
import API from "../../../../api/API";
import { PiCaretLeft } from "react-icons/pi";
import ButtonTab from "./ButtonTab";
import Request from "./request/Request";
import MainPlanning from "./planning/MainPlanning";
import MainForm from "./planning/form/MainForm";

const MainFormActionplan = () => {
  const params = useParams();
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const [actionPlanData, setActionPlanData] = useState([]);
  const [actionPlanArchive, setActionPlanArchive] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingArchive, setLoadingArchive] = useState(false);
  const [activeTab, setActiveTab] = useState("request");

  useEffect(() => {
    if (!token || !params?.id) return;
    const getActionPlanData = async () => {
      setLoading(true);
      try {
        const res = await API.get(
          `/api/v1/actionplan/actionplanWithRole/${params?.id}/`,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data);
        setActionPlanData(res?.data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getActionPlanData();
  }, [token, params?.id]);

  useEffect(() => {
    if (!token || !params?.id) return;
    const getActionPlanArchive = async () => {
      setLoadingArchive(true);
      try {
        const res = await API.get(
          `/api/v1/actionplan/archives/actionplan/${params?.id}`,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data);
        setActionPlanArchive(res?.data?.filter((d) => d?.is_first === true)[0]);
      } catch (err) {
        console.log(err);
      } finally {
        setLoadingArchive(false);
      }
    };
    getActionPlanArchive();
  }, [token, params?.id]);

  return loading ? (
    <div className="flex items-center justify-center w-full py-12">
      <span className="text-xs text-caspian-gray4">در حال بارگزاری...</span>
    </div>
  ) : actionPlanData?.userRole?.includes("MANAGER") ? (
    <div className="flex flex-col gap-2 w-full">
      <div className="flex items-center justify-between w-full">
        <button
          onClick={() => navigate(-1)}
          className="flex gap-2 text-caspian-secondary-700 p-2 mr-auto"
        >
          <span className="text-sm">بازگشت</span>
          <PiCaretLeft />
        </button>
      </div>
      <div className="flex flex-col gap-2">
        <div className="w-full md:w-[90%] lg:w-85%] xl:w-[75%] flex items-center justify-between gap-4 bg-caspian-gray2/40 py-1.5 px-2 rounded-md">
          <ButtonTab
            activeTab={activeTab}
            active="request"
            // onClick={() => setActiveTab("request")}
            title="درخواست"
          />
          <ButtonTab
            activeTab={activeTab}
            active="planning"
            // onClick={() => setActiveTab("planning")}
            title="برنامه ریزی"
          />
          <ButtonTab
            activeTab={activeTab}
            active="complete"
            // onClick={() => setActiveTab("complete")}
            title="تکمیل اطلاعات"
          />
        </div>
        <div className="w-full md:w-[90%] lg:w-85%] xl:w-[75%] p-2 rounded-md shadow-[0_0_10px_#00000050] min-h-screen bg-caspian-surface flex flex-col gap-4">
          {activeTab === "request" ? (
            <Request setActiveTab={setActiveTab} fullData={actionPlanArchive} />
          ) : activeTab === "planning" ? (
            <MainPlanning setActiveTab={setActiveTab} />
          ) : (
            activeTab === "complete" && <MainForm />
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="flex items-center justify-center w-full py-12">
      <span className="text-xs text-caspian-gray4">
        شما به این صفحه دسترسی ندارید
      </span>
    </div>
  );
};

export default MainFormActionplan;
