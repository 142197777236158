import React, { useState } from "react";
import TableSentRequest from "./TableSentRequest";
import { useParams } from "react-router-dom";
import MainDetailsSingleSent from "./details-single-sent/MainDetailsSingleSent";

const DetailsSentRequest = () => {
  const params = useParams();

  return (
    <div>{params.id ? <MainDetailsSingleSent /> : <TableSentRequest />}</div>
  );
};

export default DetailsSentRequest;
