import React, { useContext, useEffect, useState } from "react";
import Modal from "../../../modal/Modal";
import { PiX } from "react-icons/pi";
import { AuthContext } from "../../../../context/auth/AuthContext";
import API from "../../../../api/API";
import MembersList from "./MembersList";
import Profile from "./Profile";
import MainRiskTeamSubject from "./risk-team-subject/MainRiskTeamSubject";
import ListProcess from "./risk-team-subject/ListProcess";
import ListGoal from "./risk-team-subject/ListGoal";

const MainProfile = ({
  setShowProfile,
  profileId,
  profileTitle,
  setRefreshTeamsList,
}) => {
  const { token } = useContext(AuthContext);

  const [profileData, setProfileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refreshTeamProfile, setRefreshTeamProfile] = useState(0);
  useEffect(() => {
    setRefreshTeamProfile(1);
  }, []);
  useEffect(() => {
    const getUser = async () => {
      if (!token || !profileId) return;
      if (refreshTeamProfile === 0) return;

      setLoading(true);
      try {
        const res = await API.get(`/api/v1/risk/team/detail/${profileId}/`, {
          headers: { Authorization: token },
        });
        console.log(res?.data);
        setProfileData(res?.data);
        setRefreshTeamProfile(0);
        setRefreshTeamsList(1);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    getUser();
  }, [token, profileId, refreshTeamProfile, setRefreshTeamsList]);
  return (
    <Modal>
      <div className="flex flex-col gap-0 min-w-[80vw] z-50 bg-caspian-surface rounded-md shadow-[0_0_5px_#00000050] overflow-hidden">
        <div className="flex items-center justify-between w-full p-2 border-b border-b-caspian-gray2">
          <span className="text-xs text-caspian-gray font-bold">
            {profileTitle}
          </span>
          <PiX
            onClick={() => setShowProfile(false)}
            className="cursor-pointer"
          />
        </div>
        {loading ? (
          <div className="flex items-center justify-center w-full h-full min-h-[80vh] max-h-[80vh]">
            <span className="text-caspian-gray4 text-xs">
              در حال بارگزاری اطلاعات...
            </span>
          </div>
        ) : (
          <div className="flex items-start justify-stretch gap-0 w-full">
            <div className="flex flex-col gap-0 w-[70%] lg:w-[75%] h-full">
              <Profile profileData={profileData} />
              {((profileData?.riskTeamSubjectType === "operational" &&
                profileData?.teamSpecializedRiskOperationalSubject?.length) ===
                0 ||
                (profileData?.riskTeamSubjectType === "strategic" &&
                  profileData?.teamSpecializedRiskStrategicSubject?.length ===
                    0)) && (
                <MainRiskTeamSubject
                  profileId={profileId}
                  riskTeamObject={profileData?.riskTeamSubjectType}
                  setRefreshTeamProfile={setRefreshTeamProfile}
                />
              )}
              <div className="max-h-[240px] min-h-[240px] overflow-y-scroll w-full">
                {profileData?.riskTeamSubjectType === "operational" && (
                  <ListProcess
                    processList={
                      profileData?.teamSpecializedRiskOperationalSubject
                    }
                  />
                )}
                {profileData?.riskTeamSubjectType === "strategic" && (
                  <ListGoal
                    goalList={profileData?.teamSpecializedRiskStrategicSubject}
                  />
                )}
              </div>
            </div>
            <MembersList userList={profileData?.memberRiskTeamSpecialized} />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default MainProfile;
