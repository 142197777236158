import { useContext, useEffect, useState } from "react";
import TableListActivites from "./TableListActivites";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import API from "../../../../../api/API";

const MainListActivites = () => {
  const { token } = useContext(AuthContext);
  const [fullData, setfullData] = useState([-1]);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (!token) return;
    let newUrl = "/api/v1/actionplan/supervisionactivities";
    const getActivites = async () => {
      await API.get(newUrl, {
        headers: { Authorization: token },
      })
        .then((res) => {
          setfullData(res.data);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getActivites();
  }, [token]);

  return <TableListActivites listActivites={fullData} />;
};

export default MainListActivites;
