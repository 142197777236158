import React, { useState } from 'react'
import { PiCaretLeft } from 'react-icons/pi'
import IndicatorCollapse from './IndicatorCollapse'
const SingleListGoalIndicator = ({ item }) => {
    const [showCollaps, setShowCollaps] = useState(false)

    let progress = 20;
    let widthProgress = progress > 100 || progress < 0 ? 0 : (progress / 100) * 100;

    return (
        <div className='w-[95%] flex items-center flex-col px-5 border-b-2 border-b-caspian-gray2 '>
            <div className='w-full flex items-center justify-between min-h-16 h-fit'>
                <span className='h-2/5 basis-1/5 text-xs 2xl:text-sm text-caspian-on_primary/90'>{item?.title}</span>

                <div className='basis-1/5 flex items-center justify-center gap-2 '>
                    <span className=' text-xs 2xl:text-sm text-[#4B5563]'>واحد سنجش :</span>
                    <span className='text-caspian-on_primary/80 text-xs 2xl:text-sm'>{item?.unit}</span>
                </div>

                <div className='basis-1/5 flex items-center gap-5 justify-center '>
                    <div className='basis-1/2 bg-caspian-darkgray-400 relative justify-end w-full flex-col'>
                        <div className={`border border-caspian-secondary-600 bg-caspian-secondary-600 rounded-full h-1`}
                            style={{ width: `${widthProgress}%` }}>
                        </div>
                        <span className='absolute left-2 bottom-2 text-xs text-caspian-secondary-600'>{progress}%</span>
                    </div>
                    <span className='whitespace-nowrap text-xs 2xl:text-sm basis-1/2 text-caspian-secondary-600'>پیشرفت {progress} درصدی</span>
                </div>

                <div className='basis-1/5 flex items-center justify-end gap-1 p-5 text-caspian-secondary-600 text-xs xl:text-sm'
                    onClick={() => setShowCollaps(!showCollaps)}>
                    <span className='cursor-pointer'>
                        جزئیات
                    </span>
                    <PiCaretLeft className='cursor-pointer' />
                </div>
            </div>

            {showCollaps && <IndicatorCollapse item={item} />}
        </div>
    )
}
export default SingleListGoalIndicator




