import React, { useContext } from "react";
import { AuthContext } from "../../../context/auth/AuthContext";
// import { PiCheckCircle, PiPencilSimpleLine, PiXCircle } from "react-icons/pi";

const AddressInfoBox = () => {
  const { user } = useContext(AuthContext);
  return (
    <div className="flex flex-col gap-2 w-full">
      <h2 className="border-b border-b-[#FF5B5B] w-fit text-sm">
        اطلاعات تماس
      </h2>
      <form className="w-full">
        <div className="grid grid-cols-2 gap-0 bg-caspian-surface rounded-xl overflow-hidden w-full border border-caspian-gray1">
          <label className="flex flex-col bg-caspian-surface p-2 w-full border-l border-l-caspian-gray1">
            <span className="text-sm text-caspian-gray">تلفن همراه</span>
            <input
              type="text"
              disabled
              className="disabled:bg-caspian-surface"
              defaultValue={
                user.placementProfileRelated &&
                user.placementProfileRelated.mobile
              }
            />
          </label>
          <label className="flex flex-col bg-caspian-surface p-2 w-full">
            <span className="text-sm text-caspian-gray">تلفن ثابت</span>
            <input
              type="text"
              disabled
              className="disabled:bg-caspian-surface"
              defaultValue={
                user.placementProfileRelated &&
                user.placementProfileRelated.personalInfoProfileRelated[0].phone
              }
            />
          </label>
          <label className="flex flex-col bg-caspian-surface p-2 w-full border-l border-l-caspian-gray1 border-y border-y-caspian-gray1">
            <span className="text-sm text-caspian-gray">ایمیل اداری</span>
            <input
              type="text"
              disabled
              className="disabled:bg-caspian-surface"
              defaultValue={
                user.placementProfileRelated &&
                user.placementProfileRelated.email
              }
            />
          </label>
          <label className="flex flex-col bg-caspian-surface p-2 w-full border-y border-y-caspian-gray1">
            <span className="text-sm text-caspian-gray">ایمیل</span>
            <input
              type="text"
              disabled
              className="disabled:bg-caspian-surface"
              defaultValue={
                user.placementProfileRelated &&
                user.placementProfileRelated.email
              }
            />
          </label>
          <label className="flex flex-col gap-0 w-full col-span-2 p-2">
            <span className="text-sm text-caspian-gray px-2">
              آدرس محل سکونت
            </span>
            <div className="relative">
              <input
                type="text"
                disabled
                className="disabled:bg-caspian-surface p-2 w-full"
                defaultValue={
                  user.placementProfileRelated &&
                  user.placementProfileRelated.personalInfoProfileRelated[0]
                    .location
                }
              />
            </div>
          </label>
        </div>
      </form>
    </div>
  );
};

export default AddressInfoBox;
