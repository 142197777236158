import React from "react";

const ZoneTitle1 = ({ title }) => {
  return (
    <div className="flex items-center gap-1 w-full">
      <div className="flex items-center gap-0 w-full">
        <div className={`bg-[#E86800] h-[2px] w-full`}></div>
        <div className={`bg-[#E86800] w-[10px] h-[10px] rounded-full`}></div>
      </div>
      <h2 className={`text-[#E86800] text-[18px] whitespace-nowrap`}>{title}</h2>
    </div>
  );
};

export default ZoneTitle1;
