import { useContext, useEffect, useState } from "react";
import SingleTablePresent from "./SingleTablePresent";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import API from "../../../../../api/API";

const PresentList = ({
  fullData,
  meetingId,
  setRefreshFullData,
  setApprovals,
  setPresent,
}) => {
  const { token } = useContext(AuthContext);

  const [isAbsent, setIsAbsent] = useState([]);
  useEffect(() => {
    setIsAbsent(
      fullData?.filter((d) => d?.is_absent === true).map((d) => d?.id)
    );
  }, [fullData, token]);

  const handlePresent = async () => {
    console.log({
      meetingSelected_id: meetingId,
      memberListId: isAbsent,
    });
    if (!meetingId) return null;
    await API.post(
      `/api/v1/meeting/info/present`,
      {
        meetingSelected_id: meetingId,
        memberListId: isAbsent,
      },
      {
        headers: { Authorization: token },
      }
    )
      .then((res) => {
        setRefreshFullData(1);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setRefreshFullData(1));
  };

  const presentHandler = async () => {
    await API.post(
      `/api/v1/meeting/info/detail/${meetingId}/status/`,
      {
        status: "approvals",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await handlePresent();
        await setPresent(false);
        await setApprovals(true);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-x-auto">
            <table className="min-w-full text-left text-xs font-light">
              <thead className="border-b border-b-caspian-gray2 font-medium">
                <tr>
                  <th
                    scope="col"
                    className="rounded-tr-md bg-caspian-surface text-right p-3 w-[20%]"
                  >
                    نام و نام خانوادگی
                  </th>
                  <th
                    scope="col"
                    className="bg-caspian-surface text-right p-3 w-[20%]"
                  >
                    نقش
                  </th>
                  <th
                    scope="col"
                    className="bg-caspian-surface text-right p-3 w-[20%]"
                  >
                    سمت
                  </th>
                  <th
                    scope="col"
                    className="bg-caspian-surface text-right p-3 w-[20%]"
                  >
                    سازمان
                  </th>
                  <th
                    scope="col"
                    className="rounded-tl-md bg-caspian-surface text-right p-3 w-[20%]"
                  >
                    حضور/غیاب
                  </th>
                </tr>
              </thead>
              <tbody>
                {fullData?.map((d, i) => (
                  <SingleTablePresent
                    isAbsent={isAbsent}
                    setIsAbsent={setIsAbsent}
                    key={i}
                    id={d.id}
                    //نام و نام خانوادگی
                    fullName={d?.fullName}
                    // نقش
                    role={d?.memberType}
                    //سمت
                    position={d?.position}
                    //سازمان
                    company={d?.company}
                    //حضور/غیاب
                    absent={d?.is_absent}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <button
        onClick={presentHandler}
        className="min-w-[6rem] outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs p-1.5 rounded-md w-fit"
      >
        ثبت
      </button>
    </div>
  );
};

export default PresentList;
