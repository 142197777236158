import { useState } from "react";
import { PiCaretLeft, PiCaretDown } from "react-icons/pi";

const Accordion = ({title,icon,children}) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <div className="border-b border-[#F0F3FD] last:border-0 rounded-md cursor-pointer py-5">
                <div className="flex justify-between" onClick={() => setOpen(!open)}>
                    <div className="accordion-title flex items-center">
                        <span className="text-[25px] p-2 rounded-md ">{icon}</span>
                        <span>{title}</span>
                    </div>
                    <div className="text-caspian-blue-700 flex items-center">
                        {/* <span>جزییات بیشتر</span> */}
                        <span  >{open ? <PiCaretDown /> : <PiCaretLeft />}</span>
                    </div>
                </div>
                <div className={open ?'p-5':'p-0'}>
                    {open && children}
                </div>
            </div>
        </>
    )
}
export default Accordion;