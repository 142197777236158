import { PiCheck, PiTelegramLogo, PiX } from "react-icons/pi";

const ChatInput = ({
  messageInput,
  handleInputChange,
  messageHandler,
  editMessageHandler,
  editButton,
  setMessageId,
  setEditButton,
  setMessageInput,
}) => {

  return (
    <div className="bg-caspian-gray2/50 w-full p-2">
      <div className="flex items-end gap-2 w-full">
        <textarea
          value={messageInput}
          onChange={handleInputChange}
          placeholder="پیام خود را اینجا وارد کنید"
          rows={3}
          className="resize-none p-2 w-[90%] outline-none bg-caspian-surface shadow-[0_0_5px_#00000050] rounded-md text-xs"
        />
        {editButton ? (
          <div className="flex flex-col justify-center items-center gap-1 w-[10%]">
            <button
              onClick={() => {
                setMessageId(null);
                setEditButton(false);
                setMessageInput("");
              }}
              type="button"
              className="text-xs text-caspian-surface bg-caspian-gray rounded-full p-1 w-fit duration-300"
            >
              <PiX />
            </button>
            <button
              type="button"
              onClick={editMessageHandler}
              className="w-8 h-8 rounded-full bg-caspian-primary-100 flex items-center justify-center"
            >
              <PiCheck className="text-2xl text-caspian-primary-700" />
            </button>
          </div>
        ) : (
          <div className="w-[10%]">
            <button
              type="button"
              onClick={messageHandler}
              className="w-10 h-10 rounded-full bg-caspian-primary-100 flex items-center justify-center shadow-[0_0_5px_#00000050]"
            >
              <PiTelegramLogo className="text-2xl text-caspian-primary-700" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatInput;
