import { PiPaperPlaneRight, PiTray } from "react-icons/pi";
import ListItemSingle from "./ListItemSingle";
import { useEffect } from "react";
import { useAppContext } from "../../../context/app-context/AppContext";
import { useLocation } from "react-router-dom";

const ListMeetingNavbar = ({allMeetingNumber}) => {
  const { setBradCrump3, setNavbarListMeeting } = useAppContext();

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("all-meeting")) {
      setNavbarListMeeting("all-meeting");
      setBradCrump3("همه جلسات");
    } else if (location.pathname.includes("my-meeting")) {
      setNavbarListMeeting("my-meeting");
      setBradCrump3("جلسات من");
    }
  }, [location, setNavbarListMeeting, setBradCrump3]);

  return (
    <nav className="w-full rtl">
      <ul className="flex items-center gap-2">
        <ListItemSingle
          title="همه جلسات"
          active="all-meeting"
          number={allMeetingNumber}
          icon={<PiTray />}
          imageAddress="/assets/images/meeting/meeting-navbar/all-meeting.svg"
          link="/managment-of-meetings/list-meeting/all-meeting"
          buttonActive={"all-meeting"}
          onClick={() => {
            setBradCrump3("همه جلسات");
            setNavbarListMeeting("all-meeting");
          }}
        />
        <ListItemSingle
          title="جلسات من"
          active="my-meeting"
          number={0}
          icon={<PiPaperPlaneRight />}
          imageAddress="/assets/images/meeting/meeting-navbar/my-meeting.svg"
          link="/managment-of-meetings/list-meeting/my-meeting"
          buttonActive="my-meeting"
          onClick={() => {
            setBradCrump3("جلسات من");
            setNavbarListMeeting("my-meeting");
          }}
        />
      </ul>
    </nav>
  );
};

export default ListMeetingNavbar;
