import { useState } from 'react';
import Accordion from '../accordion/Accordion';
import { PiGraduationCap, PiChalkboardTeacher, PiPencilSimpleLine } from "react-icons/pi";
import EditRuleForm from './forms/EditRuleForm';
import EditAssetForm from './forms/EditAssetForm';
import TableRequirement from './TableRequirement';

const Requirements = ({ positionRelatedForRequirementRules, positionRelatedForRequirementAsset, setRefresh, id, user }) => {
    const [editRule, setEditRule] = useState(false);
    const [editAsset, setEditAsset] = useState(false);
    return (
        <>
            <div className="flex items-baseline gap-2 justify-between w-full relative">
                <div className={` ${user?.id === 207 ? 'w-[95%]' : 'w-full'}`}>
                    <Accordion
                        title='قوانین و مقررات مرتبط با شغل'
                        icon={<PiGraduationCap />}
                    >
                        <TableRequirement items={positionRelatedForRequirementRules} />
                    </Accordion>
                    <div className="absolute left-3 top-8">
                        {
                            user?.id === 207 && (
                                <div className='text-lg'>
                                    <PiPencilSimpleLine className='cursor-pointer' onClick={() => setEditRule(true)} />
                                </div>
                            )
                        }
                        {
                            user?.id === 207 && editRule && <EditRuleForm id={id}
                                // existRes={existRes}
                                setEditRule={setEditRule} item={positionRelatedForRequirementRules} setRefresh={setRefresh} />
                        }
                    </div>
                </div>
            </div>

            <div className="flex items-baseline gap-2 justify-between w-full relative">
                <div className={` ${user?.id === 207 ? 'w-[95%]' : 'w-full'}`}>
                    <Accordion
                        title='تجهیزات استاندارد مرتبط با شغل'
                        icon={<PiChalkboardTeacher />}
                    >
                        <TableRequirement items={positionRelatedForRequirementAsset} />
                    </Accordion>
                    <div className="absolute left-3 top-8">
                        {
                            user?.id === 207 && (
                                <div className='text-lg'>
                                    <PiPencilSimpleLine className='cursor-pointer' onClick={() => setEditAsset(true)} />
                                </div>
                            )
                        }
                        {
                            user?.id === 207 && editAsset && <EditAssetForm id={id}
                                // existRes={existRes}
                                setEditAsset={setEditAsset} item={positionRelatedForRequirementAsset} setRefresh={setRefresh} />
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Requirements;
