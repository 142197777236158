import React, { useState } from "react";
import ProcessFilter from "./ProcessFilter";
import ActivityFilter from "./ActivityFilter";

const MainFilterProcessActivity = ({
  processFilters,
  processSelected,
  setProcessSelected,
}) => {
  const [disable, setDisable] = useState(false);

  const [process, setProcess] = useState("");
  const [showListProcess, setShowListProcess] = useState(false);
  console.log(processFilters);
  const [processList, setProcessList] = useState([]);

  const [activity, setActivity] = useState("");
  const [showListActivity, setShowListActivity] = useState(false);
  const [activityList, setActivityList] = useState(processSelected.processActivityRelated);
  const [activitySelected, setActivitySelected] = useState([]);

  console.log(processSelected);
  
  return (
    <div className="border border-caspian-blue-400 rounded-md p-4 bg-caspian-transparent w-full flex items-center justify-center">
      <div className="flex items-end justify-center gap-4 w-full">
        <ProcessFilter
          lable="فرایند"
          processList={processFilters}
          process={process}
          setProcess={setProcess}
          processSelected={processSelected}
          setProcessSelected={setProcessSelected}
          showListProcess={showListProcess}
          setShowListProcess={setShowListProcess}
        />
        <ActivityFilter
          lable="فعالیت"
          activityList={activityList}
          activity={activity}
          setActivity={setActivity}
          activitySelected={activitySelected}
          setActivitySelected={setActivitySelected}
          showListActivity={showListActivity}
          setShowListActivity={setShowListActivity}
        />
        <button
          disabled={disable ? true : false}
          onClick={() => console.log("filter")}
          className="disabled:border-caspian-gray1 disabled:text-caspian-gray4 rounded-md border border-caspian-blue-500 text-caspian-blue-700 p-2 px-8 text-xs w-auto lg:min-w-[160px]"
        >
          اعمال فیلتر
        </button>
      </div>
    </div>
  );
};

export default MainFilterProcessActivity;
