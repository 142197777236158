import React from "react";
import { PiCircleNotch } from "react-icons/pi";

const LoadingData = () => {
  return (
    <div className="flex items-center justify-center flex-col gap-2 py-12">
      <span className="text-xs text-caspian-gray4">
        در حال بارگزاری اطلاعات...
      </span>
      <PiCircleNotch className="text-caspian-primary-400 text-xl animate-spin" />
    </div>
  );
};

export default LoadingData;
