import React from 'react'
import ChartShm from './ChartShm'

const MainTreeChartShm = () => {
    return (
        <div className="flex w-full gap-2 flex-col items-start">
            <div className="px-5 py-10 w-full overflow-x-auto bg-caspian-surface shadow-[0_5px_5px_#00000050] rounded-md ">
                <ChartShm />
            </div >
        </div >

    )
}

export default MainTreeChartShm