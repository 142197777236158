
const FloorStackholder = ({ categoryData }) => {

    return (
        <div className='flex justify-center relative pb-10 w-full'>
            <div className='h-20 border-dashed border-r-2 absolute z-0 top-7 border-caspian-darkgray-600'></div>
            <span
                style={{ background: `#${categoryData?.colorHex}` }}
                className='text-xs 2xl:text-base font-bold py-4 z-10 w-40 text-center rounded-[50%] text-caspian-surface'>
                {categoryData?.title }
            </span>

        </div>
    )
}
export default FloorStackholder;    