import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppContext } from "../../../../../context/app-context/AppContext";

const SingleTableListMeeting = ({
  id,
  title,
  date,
  status,
  secretary,
  head,
}) => {
  const [serachParams] = useSearchParams();
  const navigate = useNavigate();
  const { setBradCrump3 } = useAppContext();

  return (
    <tr
      onClick={() => {
        console.log(id);
        navigate(`/managment-of-meetings/list-meeting/all-meeting/${id}`, {
          state: {
            search: `list-meeting/all-meeting?${serachParams.toString()}`,
          },
        });
        setBradCrump3(title);
      }}
      className="cursor-pointer bg-caspian-surface duration-300"
    >
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[20%]">
        <h2>{title}</h2>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 min-w-[100px] w-[20%]">
        <h2>{date}</h2>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[20%]">
        <h2>{secretary}</h2>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[20%]">
        <h2>{head}</h2>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[20%]">
        <button
          className={`text-xs rounded-md p-1 border ${
            (status === "wait" || status === "conf") &&
            "bg-[#F9BB1C2E] border-[#F9BB1C] text-[#F9BB1C]"
          } ${
            //   status === "present" &&
            //   "bg-[#01BC8D2E] border-[#01BC8D] text-[#01BC8D]"
            // } ${
            status === "approvalschairman" &&
            "bg-[#3C77A22E] border-[#3C77A2] text-[#3C77A2]"
          }`}
        >
          {status === "wait" || status === "conf"
            ? "در انتظار برگزاری"
            : status === "approvalschairman" && "برگزار شده"}
        </button>
      </td>
    </tr>
  );
};

export default SingleTableListMeeting;
