import React, { useEffect, useState } from "react";
import MeetingNavbar from "../MeetingNavbar";
import { useAppContext } from "../../../context/app-context/AppContext";
import { Outlet, useLocation, useParams } from "react-router-dom";
import ListMeetingNavbar from "./ListMeetingNavbar";
import MainDetailsListMeeting from "./details-list-meeting/MainDetailsListMeeting";
import API from "../../../api/API";

const MainListMeeting = () => {
  const { setBradCrump1, setBradCrump2, setBradCrump3, setBrcLink2 } =
    useAppContext();
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    if (location.pathname.includes("all-meeting")) {
      setBradCrump1("مدیریت جلسات");
      setBradCrump2("لیست جلسات");
      !params.id && setBrcLink2("");
    } else if (location.pathname.includes("my-meeting")) {
      setBradCrump1("مدیریت جلسات");
      setBradCrump2("لیست جلسات");
      setBradCrump3("جلسات من");
    }
  }, [
    location,
    setBradCrump1,
    setBradCrump2,
    setBradCrump3,
    setBrcLink2,
    params,
  ]);

  const [refreshMeetingList, setRefreshMeetingList] = useState(0);
  const [fullData, setFullData] = useState([-1]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    let url = "/api/v1/meeting/info/detail/";
    API.get(url)
      .then((d) => {
        setFullData(
          d.data.filter(
            (d) =>
              d.status === "wait" ||
              d.status === "conf" ||
              d.status === "present" ||
              d.status === "approvals" ||
              d.status === "finalDocument" ||
              d.status === "approvalschairman"
          )
        );
        setRefreshMeetingList(0);
      })
      .catch((err) => {
        console.log("error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [refreshMeetingList]);

  return (
    <>
      <MeetingNavbar buttonActive="list-meeting" />
      <div className="p-2 flex flex-col gap-2">
        {params.id ? (
          <MainDetailsListMeeting
            setRefreshMeetingList={setRefreshMeetingList}
            idMeeting={params.id}
          />
        ) : (
          <div className="flex flex-col gap-2">
            <ListMeetingNavbar allMeetingNumber={fullData.length} />
            <Outlet context={[fullData]} />
          </div>
        )}
      </div>
    </>
  );
};

export default MainListMeeting;
