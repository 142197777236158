import React, { useRef } from "react";
import { PiMagnifyingGlassBold, PiX } from "react-icons/pi";

const SearchInput = ({ searchAllHistoryChat, setSearchAllHistoryChat }) => {
  const searchRef = useRef();

  const clearHandler = (e) => {
    setSearchAllHistoryChat("");
    searchRef.current.value = "";
  };

  return (
    <form className="relative w-full">
      <input
        className="w-full rounded-md bg-caspian-surface px-8 py-2 focus:outline-none text-xs"
        placeholder="جستجو"
        ref={searchRef}
        type="search"
        onChange={(e) => setSearchAllHistoryChat(e.target.value)}
      />
      <PiMagnifyingGlassBold className="absolute top-1/2 -translate-y-1/2 right-2 text-caspian-gray2 text-lg" />
      {searchAllHistoryChat.length > 0 && (
        <PiX
          onClick={clearHandler}
          className="absolute top-1/2 -translate-y-1/2 left-2 text-caspian-gray4 text-lg"
        />
      )}
    </form>
  );
};

export default SearchInput;
