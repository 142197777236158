import React, { useContext, useEffect, useState } from "react";
import API from "../../../../../../api/API";
import { AppContext } from "../../../../../../context/app-context/AppContext";
import RelatedMeeting from "./RelatedMeeting";

const MeetingThisProfile = ({ fullData }) => {
  const { token } = useContext(AppContext);
  const [relatedMeeting, setRelatedMeeting] = useState([]);
  useEffect(() => {
    const getRelatedMeeting = () => {
      API.get(`/api/v1/meeting/path/${fullData?.id}/`, {
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          setRelatedMeeting(res.data);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getRelatedMeeting();
  }, [token, fullData]);

  return (
    <div className="p-3 flex flex-col gap-3">
      <div className="flex flex-col items-end gap-0">
        {relatedMeeting?.map((d, i) => (
          <RelatedMeeting thisId={fullData} data={d} key={i} />
        ))}
      </div>
    </div>
  );
};

export default MeetingThisProfile;
