import React, { useContext, useEffect, useState } from 'react';
import MainOrganizationProfile from './profile/MainOrganizationProfile';
import { useNavigate, useParams } from 'react-router-dom';
import API from '../../../api/API';
import Loading from '../../../Loading';
import { PiCaretLeft } from "react-icons/pi";
import ChartNavbar from '../ChartNavbar';
import { AuthContext } from '../../../context/auth/AuthContext';

const MainProfilePost = () => {
    const { token } = useContext(AuthContext);
    const [data, setData] = useState([-1]);
    const [refresh, setRefresh] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    useEffect(()=>{
        setRefresh(true);
    },[])
    useEffect(() => {
        const fetchData = async () => {
            if (!id && !token) return
            if(!refresh) return
            console.log(refresh);
            try {
                const res = await API.get(`/api/v1/organization/role/position/profile/${id}/`,
                    { headers: { Authorization: token } }
                );
                setRefresh(false)
                setData(res.data);
                console.log(res.data);
            }
            catch (err) {
                if (err.response.status === 404) {
                    console.log('error message:', err.message);
                    setData([])
                }
            }
        }
        fetchData();
    }, [id, token, refresh])
    return (
        <div>
            <ChartNavbar buttonActive="profile" />
            <div className='flex flex-col gap-2 p-3 text-sm 2xl:text-base'>
                <div>
                    <button className='flex items-center mr-auto text-caspian-primary-600' onClick={() => { data.length < 1 ? navigate('/chart/list-chart') : navigate(-1) }}>
                        <span>بازگشت</span>
                        <PiCaretLeft />
                    </button>
                </div>
                {
                    data[0] === -1 ? (<Loading />) : data?.length < 1 ? (<p className='font-bold text-center p-20'>یافت نشد.</p>) :
                        (
                            <div className='w-full xl:w-[75%]'>
                                <MainOrganizationProfile data={data} refresh={refresh} setRefresh={setRefresh} />
                            </div>
                        )
                }
            </div>
        </div>
    );
}

export default MainProfilePost;
