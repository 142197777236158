// import {
//   PiCalendarX,
//   PiListBullets,
//   PiStack,
// } from "react-icons/pi";
import { useContext } from "react";
import ListItemSingle from "./ListItemSingle";
import { AuthContext } from "../../context/auth/AuthContext";
// import { useAppContext } from "../../context/app-context/AppContext";

const ActionPlanNavbar = ({ buttonActive, pmo }) => {
  // const { setBradCrump3 } = useAppContext();
  const { user } = useContext(AuthContext);

  return (
    <nav className="w-full bg-caspian-surface rtl">
      <ul className="flex items-center gap-0.5">
        <ListItemSingle
          title="برنامه ها"
          active="plans"
          // icon={<PiStack />}
          link="/action-plan/plans"
          buttonActive={buttonActive}
          // onClick={() => setBradCrump3("برنامه‌های من")}
        />
        <ListItemSingle
          title="اقدامات"
          active="actions"
          // icon={<PiStack />}
          link="/action-plan/actions?action=executive"
          buttonActive={buttonActive}
          // onClick={() => setBradCrump3("برنامه‌های من")}
        />
        {/* {user?.id === pmo && (
          <ListItemSingle
            title="دفتر pmo"
            active="pmo"
            // icon={<PiListBullets />}
            link="/action-plan/pmo"
            buttonActive={buttonActive}
            // onClick={() => setBradCrump3("دفتر pmo")}
          />
        )} */}
        <ListItemSingle
          title="داشبورد"
          active="dashboard"
          // icon={<PiCalendarX />}
          link="/action-plan/dashboard"
          buttonActive={buttonActive}
          // onClick={() => setBradCrump3("داشبورد")}
        />
      </ul>
    </nav>
  );
};

export default ActionPlanNavbar;
