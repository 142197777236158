import React, { useContext, useEffect, useRef, useState } from "react";
import {
  PiBookmarkSimpleFill,
  PiCopy,
  PiDotsThreeVertical,
} from "react-icons/pi";
import { AuthContext } from "../../../context/auth/AuthContext";
import API from "../../../api/API";
import { copyToClipboard } from "../../../helper/copyToClipboard";

const MenuAction = ({
  message,
  messageId,
  firstIndex,
  isFav,
  setFavoritesRefresh,
}) => {
  let menuRef = useRef();
  const { token } = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const favHandler = async (id) => {
    await API.patch(
      `/api/v1/message/message/${id}/`,
      {
        is_fav: false,
      },
      {
        headers: { Authorization: token },
      }
    )
      .then((res) => {
        console.log(res.data);
        setFavoritesRefresh(1);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div ref={menuRef} className="">
      <PiDotsThreeVertical
        onClick={() => setOpen(!open)}
        className="cursor-pointer"
      />
      <div
        className={
          open
            ? `absolute ${firstIndex !== 0 ? "bottom-3" : "top-3"} left-3
              w-24 bg-caspian-surface/50 p-1 rounded-md flex items-center justify-center z-20 shadow-[0_0_5px_#00000050] backdrop-blur-sm rtlD`
            : "hidden"
        }
      >
        <ul className="flex flex-col z-50">
          <li
            onClick={() => {
              favHandler(messageId);
            }}
            className="text-[9px] cursor-pointer border-b border-b-caspian-gray2 p-2"
          >
            <div className="flex items-center gap-1">
              <PiBookmarkSimpleFill className="text-sm" />
              <span className="text-[9px]">ذخیره شده</span>
            </div>
          </li>
          <li
            onClick={() => {
              copyToClipboard(message);
              setOpen(false);
            }}
            className="text-[9px] cursor-pointer p-2 flex items-center gap-1"
          >
            <PiCopy className="text-sm" />
            <span className="text-[9px]">کپی کردن</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MenuAction;
