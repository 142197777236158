import { useContext, useEffect, useState } from "react";
import { PiCheckSquareBold, PiSquare, PiX } from "react-icons/pi";
import { AuthContext } from "../../../../../../context/auth/AuthContext";
import API from "../../../../../../api/API";
import Modal from "../../../../../modal/Modal";

const ListProcessDetailsSelected = ({
  setShowProcessDetailsList,
  processDetailsFinalSelected,
  setProcessDetailsFinalSelected,
  processDetailsSelected,
  setProcessDetailsSelected,
  thisIdProcess,
}) => {
  const { token } = useContext(AuthContext);

  const [processDetailsData, setProcessDetailsData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getProcessDetails = async () => {
      if (!token) return;

      setLoading(true);
      try {
        const res = await API.get(
          `/api/v1/businessProcess/process/group/${thisIdProcess}`,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data);
        setProcessDetailsData(res?.data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    getProcessDetails();
  }, [token, thisIdProcess]);

  const processDetailsFinalHandler = () => {
    if (processDetailsSelected?.length > 0) {
      setProcessDetailsFinalSelected(processDetailsSelected);
      setShowProcessDetailsList(false);
    }
  };

  console.log(processDetailsSelected);
  console.log(processDetailsFinalSelected);

  return (
    <Modal>
      <div className="flex flex-col gap-0 min-w-[400px] max-w-[400px] z-50 bg-caspian-surface rounded-md shadow-[0_0_5px_#00000050] overflow-hidden">
        <div className="flex items-center justify-between w-full p-2 border-b border-b-caspian-gray2">
          <span className="text-xs text-caspian-gray font-bold">
            گروه های فرایندی تاثیرپذیر
          </span>
          <PiX
            onClick={() => {
              setShowProcessDetailsList(false);
              setProcessDetailsSelected(processDetailsFinalSelected);
            }}
            className="cursor-pointer"
          />
        </div>
        <div className="max-h-[360px] min-h-[360px] overflow-y-scroll cursor-pointer">
          {processDetailsData?.map((p, i) => (
            <div
              key={i}
              onClick={() => {
                !processDetailsSelected.includes(p?.id)
                  ? setProcessDetailsSelected([
                      ...processDetailsSelected,
                      p?.id,
                    ])
                  : setProcessDetailsSelected(
                      processDetailsSelected.filter((d) => d !== p?.id)
                    );
              }}
              className="flex items-center justify-between gap-4 w-full even:bg-caspian-gray2/30 p-2"
            >
              <div className="flex items-center gap-2">
                {processDetailsSelected.includes(p?.id) ? (
                  <PiCheckSquareBold className="text-xl text-caspian-primary-500" />
                ) : (
                  <PiSquare className="text-xl text-caspian-primary-500" />
                )}
                <span className="text-xs text-caspian-gray4">{p?.title}</span>
              </div>
              <span className="text-xs text-caspian-gray4">
                {p?.processCode}
              </span>
            </div>
          ))}
        </div>
        <div className="p-2 border-t border-t-caspian-gray2">
          <button
            onClick={processDetailsFinalHandler}
            disabled={processDetailsSelected.length < 1 ? true : false}
            className="bg-caspian-secondary-500 text-xs rounded-md text-caspian-surface py-1.5 px-4 disabled:bg-caspian-gray2 disabled:text-caspian-gray4"
          >
            تایید
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ListProcessDetailsSelected;
