import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import SingleTableInternalDocuments from "./SingleTableInternalDocuments";
import RowView from "../../list-records/RowView";
import Paginate from "./Paginate";
import { PiCircleNotch } from "react-icons/pi";
import FilterType from "./FilterType";
import FilterUser from "./FilterUser";
import FilterOwner from "./FilterOwner";

const TableListInternalDocuments = ({ data, loadingList }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search");
  const page = searchParams.get("page");
  const rows = searchParams.get("rows");
  const tag = searchParams.get("tag");
  let getCookieTitle = sessionStorage.getItem("filterByTitle");
  let getCookieCode = sessionStorage.getItem("filterByCode");
  let getCookieOwnerParts = sessionStorage.getItem("filterByOwnerParts");
  let getCookieType = sessionStorage.getItem("filterByType");
  let getCookieUsers = sessionStorage.getItem("filterByUsers");

  const [searchByTitle, setSearchByTitle] = useState(getCookieTitle);
  const [searchByCode, setSearchByCode] = useState(getCookieCode);
  const [searchByType, setSearchByType] = useState(getCookieType);
  const [searchByOwnerParts, setSearchByOwnerParts] =
    useState(getCookieOwnerParts);
  const [searchByUsers, setSearchByUsers] = useState(getCookieUsers);

  // const [filterItems, setFilterItems] = useState([]);
  const [filterTypeItems, setFilterTypeItems] = useState([]);
  const [filterOwnerItems, setFilterOwnerItems] = useState([]);
  const [filterUserItems, setFilterUserItems] = useState([]);

  useEffect(() => {
    if (search) {
      setCurrentPage(1);
    }
  }, [search]);

  useEffect(() => {
    if (page === "1") {
      setCurrentPage(1);
    }
  }, [page]);

  let searchTitle = [];
  let searchCode = [];
  let searchType = [];
  let searchOwnerParts = [];
  let searchUsers = [];
  let filterOwner = [];
  let filterType = [];
  let filterUser = [];

  let newData = [-1];
  if (tag) {
    newData = data.filter((d) => d?.tag?.includes(tag));
  }

  // set cookie filter title
  // useEffect(() => {
  //   if (searchByTitle) {
  //     sessionStorage.setItem("filterByTitle", searchByTitle);
  //   } else if (!searchByTitle) {
  //     sessionStorage.removeItem("filterByTitle");
  //   }
  // }, [searchByTitle]);

  // useEffect(() => {
  //   if (getCookieTitle?.length > 0) {
  //     setSearchByTitle(getCookieTitle);
  //   } else if (getCookieTitle?.length < 1 || getCookieTitle === null) {
  //     sessionStorage.removeItem("filterByTitle");
  //   }
  // }, []);

  // set cookie filter code
  // useEffect(() => {
  //   if (searchByCode) {
  //     sessionStorage.setItem("filterByCode", searchByCode);
  //   } else if (!searchByCode) {
  //     sessionStorage.removeItem("filterByCode");
  //   }
  // }, [searchByCode]);

  // useEffect(() => {
  //   if (getCookieCode?.length > 0) {
  //     setSearchByCode(getCookieCode);
  //   } else if (getCookieCode?.length < 1 || getCookieCode === null) {
  //     sessionStorage.removeItem("filterByCode");
  //   }
  // }, []);

  // set cookie filter type
  // useEffect(() => {
  //   if (searchByType) {
  //     sessionStorage.setItem("filterByType", searchByType);
  //   } else if (!searchByType) {
  //     sessionStorage.removeItem("filterByType");
  //   }
  // }, [searchByType]);

  // useEffect(() => {
  //   if (getCookieType?.length > 0) {
  //     setSearchByType(getCookieType);
  //   } else if (getCookieType?.length < 1 || getCookieType === null) {
  //     sessionStorage.removeItem("filterByType");
  //   }
  // }, []);

  // set cookie filter owner parts
  // useEffect(() => {
  //   if (searchByOwnerParts) {
  //     sessionStorage.setItem("filterByOwnerParts", searchByOwnerParts);
  //   } else if (!searchByOwnerParts) {
  //     sessionStorage.removeItem("filterByOwnerParts");
  //   }
  // }, [searchByOwnerParts]);

  // useEffect(() => {
  //   if (getCookieOwnerParts?.length > 0) {
  //     setSearchByOwnerParts(getCookieOwnerParts);
  //   } else if (
  //     getCookieOwnerParts?.length < 1 ||
  //     getCookieOwnerParts === null
  //   ) {
  //     sessionStorage.removeItem("filterByOwnerParts");
  //   }
  // }, []);

  // set cookie filter users
  // useEffect(() => {
  //   if (searchByUsers) {
  //     sessionStorage.setItem("filterByUsers", searchByUsers);
  //   } else if (!searchByUsers) {
  //     sessionStorage.removeItem("filterByUsers");
  //   }
  // }, [searchByUsers]);

  // useEffect(() => {
  //   if (getCookieUsers?.length > 0) {
  //     setSearchByUsers(getCookieUsers);
  //   } else if (getCookieUsers?.length < 1 || getCookieUsers === null) {
  //     sessionStorage.removeItem("filterByUsers");
  //   }
  // }, []);

  if (data[0] !== -1) {
    newData = search
      ? data.filter(
        (d) =>
          d?.title !== undefined &&
          d?.title?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase())
      )
      : data;
  }

  if (newData[0] !== -1) {
    filterType =
      filterTypeItems?.length > 0
        ? newData.filter((d) => filterTypeItems?.includes(d?.type))
        : newData;

    filterOwner =
      filterOwnerItems?.length > 0
        ? filterType.filter((d) => filterOwnerItems?.includes(d?.ownerParts))
        : filterType;

    filterUser =
      filterUserItems?.length > 0
        ? filterOwner.filter((d) => filterUserItems?.includes(d?.users))
        : filterOwner;

    searchTitle = searchByTitle
      ? filterUser.filter((d) =>
        d?.title
          ?.toLocaleLowerCase()
          ?.includes(searchByTitle?.toLocaleLowerCase())
      )
      : filterUser;
    searchCode = searchByCode
      ? searchTitle.filter((d) => d?.code?.toString()?.includes(searchByCode))
      : searchTitle;

    searchType = searchByType
      ? searchCode.filter((d) =>
        d?.type
          ?.toLocaleLowerCase()
          ?.includes(searchByType?.toLocaleLowerCase())
      )
      : searchCode;

    searchOwnerParts = searchByOwnerParts
      ? searchType.filter((d) =>
        d?.ownerParts
          ?.toLocaleLowerCase()
          ?.includes(searchByOwnerParts.toLocaleLowerCase())
      )
      : searchType;
    searchUsers = searchByUsers
      ? searchOwnerParts?.filter((d) =>
        d?.users
          ?.toLocaleLowerCase()
          ?.includes(searchByUsers?.toLocaleLowerCase())
      )
      : searchOwnerParts;
  }

  // console.log(data);

  const [currentPage, setCurrentPage] = useState(page ? page : 1);
  const [recordsPerPage, setRecordsPerPage] = useState(rows ? rows : 10);
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = searchUsers.slice(firstIndex, lastIndex);
  const npage = Math.ceil(searchUsers?.length / recordsPerPage);

  const [openFilterType, setOpenFilterType] = useState(false);
  const [openFilterOwner, setOpenFilterOwner] = useState(false);
  const [openFilterUser, setOpenFilterUser] = useState(false);

  const [showHoverFilterType, setShowHoverFilterType] = useState(false);
  const [showHoverFilterOwner, setShowHoverFilterOwner] = useState(false);
  const [showHoverFilterUser, setShowHoverFilterUser] = useState(false);

  const handleMouseEnter = () => {
    if (!showHoverFilterType) {
      setShowHoverFilterType(true);
    }
  };

  const handleMouseLeave = () => {
    setShowHoverFilterType(false);
  };

  const handleOwnerMouseEnter = () => {
    if (!showHoverFilterOwner) {
      setShowHoverFilterOwner(true);
    }
  };

  const handleOwnerMouseLeave = () => {
    setShowHoverFilterOwner(false);
  };
  const handleUserMouseEnter = () => {
    if (!showHoverFilterUser) {
      setShowHoverFilterUser(true);
    }
  };

  const handleUserMouseLeave = () => {
    setShowHoverFilterUser(false);
  };

  useEffect(() => {
    // setCurrentPage(1);
    handleSearch("page", "1");
  }, [filterOwnerItems, filterTypeItems, filterUserItems]);

  function handleSearch(key, value) {
    setSearchParams((prevParams) => {
      if (value === null || value === "") {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    });
  }
  return (
    <div className="flex flex-col gap-4 py-6 px-8 xl:px-16">
      {loadingList ? (
        <div className="flex items-center justify-center py-40">
          <PiCircleNotch className="text-caspian-secondary-600 text-2xl animate-spin" />
        </div>
      ) : (
        <div>
          <div>
            <div>
              <div className="overflow-x-auto min-h-[240px]">
                <table className="min-w-[1200px] w-full text-left text-sm font-light">
                  <thead className="border-b border-b-caspian-gray2 font-medium">
                    <tr>
                      <th
                        // colSpan={3}
                        scope="col"
                        className="rounded-tr-xl bg-caspian-primary-500 relative p-2 w-[30%]"
                      >
                        <input
                          // defaultValue={getCookieTitle ? getCookieTitle : ""}
                          onChange={(e) => {
                            setSearchByTitle(e.target.value);
                            setCurrentPage(1);
                          }}
                          type="search"
                          id="t1"
                          className={`${searchByTitle
                            ? "border border-caspian-surface/50 "
                            : "border border-caspian-transparent"
                            } text-[#FFF7CB] bg-caspian-transparent p-1.5 rounded-md outline-none  w-full focus:border focus:border-caspian-surface/50 pr-12 font-light peer cursor-pointer`}
                        />
                        <label htmlFor="t1"
                          className={`${searchByTitle ? "right-0 left-auto" : ""
                            } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 bg-caspian-primary-500 rounded-md border border-caspian-primary-500 whitespace-nowrap cursor-pointer font-light
                            peer-focus:left-auto peer-focus:right-0`}
                        >عنوان</label>
                      </th>
                      <th
                        // colSpan={1}
                        scope="col"
                        className="bg-caspian-primary-500 p-2 relative w-[10%]"
                      >
                        <input
                          // defaultValue={getCookieCode ? getCookieCode : ""}
                          onChange={(e) => {
                            setSearchByCode(e.target.value);
                            setCurrentPage(1);
                          }}
                          type="search"
                          id="t2"
                          className={`${searchByCode
                            ? "border border-caspian-surface/50 "
                            : "border border-caspian-transparent"
                            } text-[#FFF7CB] bg-caspian-transparent p-1.5 pl-0 z-50 rounded-md outline-none  w-full focus:border focus:border-caspian-surface/50 pr-[48px] font-light peer cursor-pointer`}
                        />
                        <label htmlFor="t2"
                          className={`${searchByCode ? "right-3 left-full" : ""
                            } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 bg-caspian-primary-500 rounded-md border border-caspian-primary-500 whitespace-nowrap cursor-pointer font-light
                            peer-focus:left-full peer-focus:right-3 z-50`}
                        >کد سند</label>
                      </th>
                      {/* type filter */}
                      <th
                        // colSpan={1}
                        scope="col"
                        className="bg-caspian-primary-500 p-2 text-right w-[20%]"
                      >
                        <div className="flex items-center gap-0">
                          {/* <input
                            defaultValue={getCookieType ? getCookieType : ""}
                            onChange={(e) => setSearchByType(e.target.value)}
                            type="search"
                            placeholder="نوع مدرک"
                            className="focus:shadow-[0_0_5px_#4683ad] focus:outline-caspian-secondary-500 rounded-md outline outline-1 outline-caspian-gray2 p-1.5 font-light text-sm w-full"
                          /> */}

                          <div
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            className="p-1 font-light text-sm w-full relative border border-caspian-gray2 rounded-md "
                          >
                            {filterTypeItems.length < 1 ? (
                              <span className="text-xs text-caspian-surface">
                                نوع مدرک
                              </span>
                            ) : (
                              <span className="text-xs text-caspian-surface">
                                {filterTypeItems.length + " مورد انتخاب شده"}
                              </span>
                            )}
                            {!openFilterType &&
                              filterTypeItems.length > 0 &&
                              showHoverFilterType && (
                                <div className="z-50 min-w-[180px] max-h-[250px] overflow-y-scroll bg-caspian-on_primary/80 rounded-md shadow-[0_0_5px_#00000050] absolute right-0 left-0 px-1.5 pt-2">
                                  {filterTypeItems.map((d, i) => (
                                    <div key={i} className="">
                                      <span className="flex flex-col gap-2 mb-2 text-xs text-caspian-surface">
                                        {d}
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              )}
                          </div>
                          <FilterType
                            openFilterType={openFilterType}
                            setOpenFilterType={setOpenFilterType}
                            filterTypeItems={filterTypeItems}
                            setFilterTypeItems={setFilterTypeItems}
                            data={data.map((d) => d?.type)}
                          />
                        </div>
                      </th>
                      {/* onwer filter */}
                      <th
                        // colSpan={3}
                        scope="col"
                        className="bg-caspian-primary-500 text-right p-2 w-[20%]"
                      >
                        <div className="flex items-center gap-0">
                          {/* <input
                          defaultValue={
                            getCookieOwnerParts ? getCookieOwnerParts : ""
                          }
                          onChange={(e) =>
                            setSearchByOwnerParts(e.target.value)
                          }
                          type="search"
                          placeholder="واحد متولی"
                          className="focus:shadow-[0_0_5px_#4683ad] focus:outline-caspian-secondary-500 rounded-md outline outline-1 outline-caspian-gray2 p-1.5 font-light text-sm w-full"
                        /> */}

                          <div
                            onMouseEnter={handleOwnerMouseEnter}
                            onMouseLeave={handleOwnerMouseLeave}
                            className="p-1 font-light text-sm w-full relative border border-caspian-gray2 rounded-md"
                          >
                            {filterOwnerItems.length < 1 ? (
                              <span className="text-xs text-caspian-surface">
                                واحد متولی
                              </span>
                            ) : (
                              <span className="text-xs text-caspian-surface">
                                {filterOwnerItems.length + " مورد انتخاب شده"}
                              </span>
                            )}
                            {!openFilterOwner &&
                              filterOwnerItems.length > 0 &&
                              showHoverFilterOwner && (
                                <div className="z-50 min-w-[180px] max-h-[250px] overflow-y-scroll bg-caspian-on_primary/80 rounded-md shadow-[0_0_5px_#00000050] absolute right-0 left-0 top-6 px-1.5 pt-2">
                                  {filterOwnerItems.map((d, i) => (
                                    <div key={i} className="">
                                      <span className="flex flex-col gap-2 mb-2 text-xs text-caspian-surface">
                                        {d}
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              )}
                          </div>
                          <FilterOwner
                            openFilterOwner={openFilterOwner}
                            setOpenFilterOwner={setOpenFilterOwner}
                            filterOwnerItems={filterOwnerItems}
                            setFilterOwnerItems={setFilterOwnerItems}
                            data={data.map((d) => d?.ownerParts)}
                          />
                        </div>
                      </th>
                      {/* user filter */}
                      <th
                        // colSpan={4}
                        scope="col"
                        className="rounded-tl-xl bg-caspian-primary-500 text-right p-2 w-[20%]"
                      >
                        <div className="flex items-center gap-0">
                          {/* <input
                            defaultValue={getCookieUsers ? getCookieUsers : ""}
                            onChange={(e) => setSearchByUsers(e.target.value)}
                            type="search"
                            placeholder="واحد بهره بردار"
                            className="focus:shadow-[0_0_5px_#4683ad] focus:outline-caspian-secondary-500 rounded-md outline outline-1 outline-caspian-gray2 p-1.5 font-light text-sm w-full"
                          /> */}
                          <div
                            onMouseEnter={handleUserMouseEnter}
                            onMouseLeave={handleUserMouseLeave}
                            className="p-1 font-light text-sm w-full relative border border-caspian-gray2 rounded-md"
                          >
                            {filterUserItems.length < 1 ? (
                              <span className="text-xs text-caspian-surface">
                                واحد بهره‌بردار
                              </span>
                            ) : (
                              <span className="text-xs text-caspian-surface">
                                {filterUserItems.length + " مورد انتخاب شده"}
                              </span>
                            )}
                            {!openFilterUser &&
                              filterUserItems.length > 0 &&
                              showHoverFilterUser && (
                                <div className="z-50 min-w-[180px] max-h-[250px] overflow-y-scroll bg-caspian-on_primary/80 rounded-md shadow-[0_0_5px_#00000050] absolute right-0 left-0 px-1.5 pt-2">
                                  {filterUserItems.map((d, i) => (
                                    <div key={i} className="">
                                      <span className="flex flex-col gap-2 mb-2 text-xs text-caspian-surface">
                                        {d}
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              )}
                          </div>
                          <FilterUser
                            openFilterUser={openFilterUser}
                            setOpenFilterUser={setOpenFilterUser}
                            filterUserItems={filterUserItems}
                            setFilterUserItems={setFilterUserItems}
                            data={data.map((d) => d.users)}
                          />
                        </div>
                      </th>
                    </tr>
                  </thead>

                  {records?.length > 0 ? (
                    <tbody>
                      {records.map((d, i) => (
                        <SingleTableInternalDocuments
                          id={d.id}
                          idProfile={d?.idProfile}
                          key={i}
                          //عنوان
                          title={d.title}
                          //کد  سند
                          code={d?.code?.toString()}
                          codeOld={d?.internalDocumentRelated?.codeOld?.toString()}
                          //نوع مدرک
                          type={d.type}
                          //واحد متولی
                          ownerParts={d.ownerParts}
                          //واحد بهره بردار
                          users={d.users}
                          //فایل دانلودی
                          fileDocument={d?.fileDocument}
                          tag={d?.tag}
                        />
                      ))}
                    </tbody>
                  ) : (
                    <tbody className="py-24">
                      <tr>
                        <td className="py-24">
                          <div className="flex items-center justify-center w-full py-10">
                            <div className="z-10 absolute left-1/2 -translate-x-1/2 mx-auto">
                              <div className="flex flex-col gap-2 items-center justify-center w-full">
                                <img
                                  src="/assets/images/library/not-found.svg"
                                  alt="مطلبی یافت نشد..."
                                />
                                <h2>مطلبی یافت نشد...</h2>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
          <>
            {records?.length > 0 && (
              <div className="flex items-center justify-between px-1 py-2">
                <RowView
                  setRecordsPerPage={setRecordsPerPage}
                  setCurrentPage={setCurrentPage}
                />
                <Paginate
                  pageCount={npage}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
                <div className="text-sm flex items-center gap-4">
                  <span>نمایش {firstIndex + 1}</span>
                  <span>
                    تا {lastIndex - (recordsPerPage - records?.length)}
                  </span>
                  <span>از کل {searchUsers?.length}</span>
                </div>
              </div>
            )}
          </>
        </div>
      )}
    </div>
  );
};

export default TableListInternalDocuments;
