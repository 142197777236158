import SingleNotebook from "./SingleNotebook";

const AllNotebook = ({ fullDataNotebook }) => {
  return (
    <div className="flex flex-wrap gap-2">
      {fullDataNotebook?.map((data, index) => (
        <SingleNotebook key={index} data={data} />
      ))}
    </div>
  );
};

export default AllNotebook;
