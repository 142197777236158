import { PiTreeStructure } from "react-icons/pi";

const RelatedMeeting = ({ data, thisId }) => {

  return (
    <>
      {data && (
        <div className="w-full h-full flex flex-col items-start gap-0">
          {data?.meetingProfileRelated && thisId?.id === data.id && (
            <div
              className={`${
                thisId?.meetingProfileRelated === null
                  ? "mr-[0%]"
                  : data?.id > thisId?.id
                  ? "mr-[10%]"
                  : "mr-[5%]"
              } border-r border-r-caspian-gray2 h-4`}
            ></div>
          )}
          {data?.meetingProfileRelated && thisId?.id !== data.id && (
            <div
              className={`${
                data?.id < thisId?.id
                  ? "mr-[0%]"
                  : (data?.id > thisId?.id &&
                      thisId?.meetingProfileRelated > data?.id)
                  ? "mr-[5%]"
                  : "mr-[10%]"
              } border-r border-r-caspian-gray2 h-4`}
            ></div>
          )}
          <div
            className={
              thisId?.id === data.id
                ? `${
                    thisId?.meetingProfileRelated === null
                      ? "w-full"
                      : data?.id > thisId?.id
                      ? "w-[90%]"
                      : "w-[95%]"
                  } bg-caspian-surface border border-caspian-primary-500 border-r-4 rounded-l-md p-3 z-30 mr-auto`
                : `${
                    data?.id < thisId?.id
                      ? "w-full"
                      : (data?.id > thisId?.id &&
                          thisId?.meetingProfileRelated > data?.id) ||
                        thisId?.meetingProfileRelated === null
                      ? "w-[95%]"
                      : "w-[90%]"
                  } bg-caspian-surface border border-caspian-gray2 rounded-l-md p-3 z-30 mr-auto`
            }
          >
            <div className="flex items-center gap-2">
              <div className="bg-caspian-gray2/40 p-1.5 rounded-md outline-1 outline-caspian-surface outline -outline-offset-2">
                <PiTreeStructure
                  className={
                    thisId === data.id
                      ? "text-caspian-primary-500 text-xl"
                      : "text-caspian-gray4 text-xl"
                  }
                />
              </div>
              <div className="flex flex-col items-start gap-1">
                <h2 className="text-xs">{data?.title}</h2>
                <span className="text-xs text-caspian-gray line-clamp-1">
                  {data?.description}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RelatedMeeting;
