import React, { useEffect } from "react";
import RequestsNavbar from "../RequestsNavbar";
import DetailsReceviedRequest from "./DetailsReceviedRequest";
import { useAppContext } from "../../../context/app-context/AppContext";

const MainReceviedRequest = () => {
  const { setBradCrump1, setBradCrump2, setBradCrump3 } = useAppContext();
  useEffect(() => {
    setBradCrump1("گزارش ها");
    setBradCrump2("دریافت");
    setBradCrump3("");
  }, [setBradCrump1, setBradCrump2, setBradCrump3]);

  return (
    <div className='flex flex-col gap-4'>
      <RequestsNavbar buttonActive="req-received" />
      <DetailsReceviedRequest />
    </div>
  );
};

export default MainReceviedRequest;
