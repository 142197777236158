import React from 'react'
import ButtonChart from '../ButtonChart'

const Chart4 = ({ sidebar }) => {
    console.log(sidebar)
    return (
        <div className={`flex flex-col items-center w-full min-w-[990px] max-w-[1200px] 
        ${!sidebar ? "mx-40 2xl:mx-20" : "mx-40 2xl:mx-auto"}`}>

            <div className="flex flex-col w-full items-center h-full">
                {/* 1 */}
                <ButtonChart
                    title="معاون بهره برداری (1)"
                    bgColor="#DDEAF4"
                    textColor="#4B6B90"
                    borderColor="#4B6B90"
                />
                {/* 1 */}
                <div className="h-5 border-r-2 border-r-caspian-gray"></div>
            </div>

            <div className="h-5 border-r-2 border-r-caspian-gray"></div>

            <div className="flex flex-col w-full items-center">
                {/* 2 */}
                <div className="flex justify-center relative h-24 gap-10 items-center">
                    <div className="flex flex-col gap-2 h-full items-center">
                        <div className="flex items-center justify-center">
                            <ButtonChart
                                title="کارشناس مسئول نگهداری و تعمیرات (1)"
                                bgColor="#C7EBEA"
                                textColor="#225C58"
                                borderColor="#225C58">
                            </ButtonChart>
                            <div className="w-14 absolute right-[47%] h-2 border-b-2 border-b-caspian-gray"></div>
                        </div>


                    </div>

                    <div className="h-full border-r-2 border-r-caspian-gray"></div>

                    <div className="flex flex-col gap-2  h-full items-center">
                        <div className="flex items-center justify-center">
                            <ButtonChart
                                title="متصدی امور دفتري و کاربر رایانه(1)"
                                bgColor="#C7EBEA"
                                textColor="#225C58"
                                borderColor="#225C58">
                            </ButtonChart>
                            <div className="w-14 absolute left-[47%] h-2 border-b-2 border-b-caspian-gray"></div>
                        </div>
                    </div>
                </div>
                {/* 2 */}

                <div className="flex w-full flex-col justify-center relative gap-5 items-center">
                    <div className="w-[1100px] h-2 border-t-2 border-t-caspian-gray"></div>
                    {/* 3 */}
                    <div className="flex w-full items-start justify-center gap-10">
                        <div className="flex flex-col gap-2 h-full items-center">
                            <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
                            <div className="flex items-center relative h-full">
                                <div className="h-[78%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>
                                <div className="flex flex-col items-center gap-2 h-full">
                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="مدیر دفتر بهره برداری از تاسیسات آب (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="h-2 absolute bottom-[48%] border-l-2 border-l-caspian-gray"></div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس مسئول بهره برداری از تاسیسات آب(1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس  بهره برداری از تاسیسات آب(1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="كارشناس نگهداری و تعمیرات (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="flex flex-col gap-2 h-full items-center">
                            <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
                            <div className="flex items-center relative h-full">
                                <div className="h-[70%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>
                                <div className="flex flex-col items-center gap-2 h-full">
                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="مدیر دفتر بهره برداری از تاسیسات فاضلاب(1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس مسئول بهره برداری از تاسیسات فاضلاب(1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="كارشناس نگهداری و تعمیرات (2)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-2 h-full items-center">
                            <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
                            <div className="flex items-center relative h-full">
                                <div className="h-[70%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>
                                <div className="flex flex-col items-center gap-2 h-full">
                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="مدیر دفتر مدیریت مصرف و کاهش آب بدون در آمد (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس مدیریت مصرف (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس کاهش آب بدون در آمد (2)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-2 h-full items-center">
                            <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
                            <div className="flex items-center relative h-full">
                                <div className="h-[51%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>
                                <div className="flex flex-col items-center gap-2 h-full">
                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="مدیر امور کنترل کیفیت بهداشت آب و فاضلاب (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس مسئول کنترل کیفیت (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="h-2 absolute top-[31.8%] border-l-2 border-l-caspian-gray"></div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس کنترل کیفیت (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                    </div>


                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس مسئول آزمایشگاه(1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="h-2 absolute top-[66%] border-l-2 border-l-caspian-gray"></div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس آزمایشگاه(1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                    </div>

                                    <div className="h-2 absolute top-[83%] border-l-2 border-l-caspian-gray"></div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="تکنسین آزمایشگاه(1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-2 h-full items-center">
                            <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
                            <div className="flex items-center relative h-full">
                                <div className="h-[62%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>
                                <div className="flex flex-col items-center gap-2 h-full">
                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="مدیر دفتر سامانه های کنترل و انرژی(1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس انرژی (2)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس مسئول نگهداری و تعمیرات(1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس مسئول سامانه های کنترل (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="h-2 absolute top-[79.5%] border-l-2 border-l-caspian-gray"></div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس سیستم های تله متری و کنترل آب(2)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-2 h-full items-center">
                            <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
                            <div className="flex items-center relative h-full">
                                <div className="flex flex-col items-center gap-2 h-full">
                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="رئیس گروه سیستم های اطلاعات مکانی (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                    </div>

                                    <div className="h-2 absolute top-[46%] border-l-2 border-l-caspian-gray"></div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس سیستم های اطلاعات مکانی (3)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 3 */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Chart4