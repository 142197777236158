import React from 'react';
import { useNavigate } from 'react-router-dom';

const SingeListTable = ({ id, title, goalRelated,code,colorHex }) => {
    const navigate = useNavigate();
    return (
        <tr className='cursor-pointer border-b text-xs 2xl:text-sm border-b-caspian-gray2 whitespace-nowrap' onClick={() => navigate(`/goals/${id}`)}>
           
           <td className='w-[50%] whitespace-nowrap py-2 text-center' title={title}
            style={{backgroundColor:`#${colorHex}`}}>
                <p className="max-w-[90%] mx-auto truncate text-center">
                    {title}
                </p>
            </td>
           
            <td className='w-[30%] whitespace-nowrap py-2 text-center' title={goalRelated?.title}
            style={{backgroundColor:`#${goalRelated?.colorHex}`}}>
                <p className="max-w-[90%] mx-auto truncate text-center">
                    {goalRelated?.title}
                </p>
            </td>

            <td className='w-[20%] whitespace-nowrap truncate py-2 text-center' title={goalRelated?.prespectiveRelated?.title}
            style={{backgroundColor:`#${goalRelated?.prespectiveRelated?.colorHex}`}} >
                <p className="max-w-[90%] mx-auto truncate text-center">
                    {goalRelated?.prespectiveRelated?.title}
                </p>
            </td>
        </tr>
    );
}

export default SingeListTable;
