import React from 'react';

const ToolTip = ({title}) => {
    return (
        <div className='absolute z-[1000] pointer-events-none text-xs bg-caspian-on_primary min-w-[100px]  rounded-md left-1/2 -translate-x-1/2 whitespace-nowrap py-2 text-center text-caspian-surface'>
        <p>
          {title}  
        </p>
        <div className='border-b-caspian-on_primary -top-[12px] border-x-[10px] border-x-caspian-transparent border-b-[16px] absolute -translate-x-1/2
        left-1/2'></div>
        </div>
    );
}

export default ToolTip;
