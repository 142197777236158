import React, { useRef, useState } from "react";
import MainStandardBox from "./standard-box/MainStandardBox";
import MainNumberBox from "./number-box/MainNumberBox";
import MainAcceptChecklist from "./accept-checklist/MainAcceptChecklist";
import MainFilterProcessActivity from "./filter-process-activity/MainFilterProcessActivity";
import MainDragDropQuestion from "./drag-drop-question/MainDragDropQuestion";

const MainRightbarChecklistForm = ({
  questionData,
  standardList,
  clauseList,
  indexQuestion,
  setIndexQuestion,
  processFilter,
}) => {
  const refs = useRef({});
  const [questionSelectedList, setQuestionSelectedList] = useState([]);

  // const [standardList, setStandardList] = useState(standards);
  const [standardSelected, setStandardSelected] = useState(null);

  const [numberList, setNumberList] = useState(clauseList);
  const [numberSelected, setNumberSelected] = useState(null);

  const [allQuestionList, setAllQuestionList] = useState(questionData);

  const [isSelectedAnime, setIsSelectedAnime] = useState(null);

  const [allQuestionSelected, setAllQuestionSelected] = useState([]);

  const [processSelected, setProcessSelected] = useState([]);

  return (
    <div className="w-[80%] h-full flex flex-col gap-4 px-2 py-6 bg-caspian-surface rounded-md">
      <span className="w-full text-center">واحد تستی شماره 7</span>
      <div className="flex flex-col gap-1">
        <MainStandardBox
          standardList={standardList}
          // setStandardList={setStandardList}
          standardSelected={standardSelected}
          setStandardSelected={setStandardSelected}
          indexQuestion={indexQuestion}
          setIndexQuestion={setIndexQuestion}
          setAllQuestionList={setAllQuestionList}
        />

        <MainNumberBox
          numberList={clauseList}
          setNumberList={setNumberList}
          numberSelected={numberSelected}
          setNumberSelected={setNumberSelected}
        />
      </div>
      <div className="bg-caspian-blue-200 border border-[#D0DEFB] rounded-md text-[#0D358C] text-sm p-2 font-bold flex items-center justify-center">
        <span>درک سازمان و فضای آن</span>
      </div>
      <MainFilterProcessActivity
        processFilters={processFilter}
        processSelected={processSelected}
        setProcessSelected={setProcessSelected}
      />

      <MainDragDropQuestion
        numberSelected={numberSelected}
        allQuestionList={questionData}
        setAllQuestionList={setAllQuestionList}
        questionSelectedList={questionSelectedList}
        setQuestionSelectedList={setQuestionSelectedList}
        allQuestionSelected={allQuestionSelected}
        setAllQuestionSelected={setAllQuestionSelected}
        refs={refs}
      />
      <div className="h-[1px] w-full bg-caspian-gray2 my-2"></div>
      <MainAcceptChecklist allQuestionSelected={allQuestionSelected} />
    </div>
  );
};

export default MainRightbarChecklistForm;
