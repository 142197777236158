import { useContext } from "react";
import { AuthContext } from "../../../context/auth/AuthContext";

const JobInfoBox = () => {
  const { user } = useContext(AuthContext);
  // console.log(user);
  return (
    <div className="flex flex-col gap-2 w-full">
      <h2 className="border-b border-b-[#FF5B5B] w-fit text-sm">
        اطلاعات شغلی
      </h2>
      <form className="w-full">
        <div className="grid grid-cols-2 gap-0 bg-caspian-surface rounded-xl overflow-hidden w-full border border-caspian-gray1">
          <label className="flex flex-col bg-caspian-surface p-2 w-full border-l border-l-caspian-gray1">
            <span className="text-sm text-caspian-gray">نوع شغل</span>
            <input
              type="text"
              disabled
              className="disabled:bg-caspian-surface"
              defaultValue={
                user?.placementPositionRelated?.positionJobRelated?.jobTypeRelated
                  ?.title
              }
            />
          </label>
          <label className="flex flex-col bg-caspian-surface p-2 w-full">
            <span className="text-sm text-caspian-gray">شماره پرسنلی</span>
            <input
              type="text"
              disabled
              className="disabled:bg-caspian-surface"
              defaultValue={
                user.placementPositionRelated &&
                user.placementPositionRelated.code
              }
            />
          </label>
          <label className="flex flex-col bg-caspian-surface p-2 w-full border-l border-l-caspian-gray1 border-y border-y-caspian-gray1">
            <span className="text-sm text-caspian-gray">پست سازمانی</span>
            <input
              type="text"
              disabled
              className="disabled:bg-caspian-surface"
              defaultValue={
                user.placementPositionRelated &&
                user.placementPositionRelated.positionJobRelated.title
              }
            />
          </label>
          <label className="flex flex-col bg-caspian-surface p-2 w-full border-y border-y-caspian-gray1">
            <span className="text-sm text-caspian-gray">کد پست سازمانی</span>
            <input
              type="text"
              disabled
              className="disabled:bg-caspian-surface"
              defaultValue={
                user.placementPositionRelated &&
                user.placementPositionRelated.positionJobRelated.code
              }
            />
          </label>
          <label className="flex flex-col bg-caspian-surface p-2 w-full border-l border-l-caspian-gray1 border-b border-b-caspian-gray1">
            <span className="text-sm text-caspian-gray">سطح پست</span>
            <input
              type="text"
              disabled
              className="disabled:bg-caspian-surface"
              defaultValue={
                user.placementPositionRelated &&
                user.placementPositionRelated.positionJobRelated.jobLevelRelated
                  .title
              }
            />
          </label>
          <label className="flex flex-col bg-caspian-surface p-2 w-full border-b border-b-caspian-gray1">
            <span className="text-sm text-caspian-gray">شرح شغلی</span>
            <input
              type="text"
              disabled
              className="disabled:bg-caspian-surface"
              defaultValue={
                user.placementPositionRelated &&
                user.placementPositionRelated.title
              }
            />
          </label>
        </div>
      </form>
    </div>
  );
};

export default JobInfoBox;
