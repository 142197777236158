import { useContext, useEffect, useState } from "react";
import TableListTasks from "./TableListTasks";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import API from "../../../../../api/API";
import { useNavigate } from "react-router-dom";

const MainListTasks = ({ serachTask, statusTask, roleTask, sortTask }) => {
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [fullData, setfullData] = useState([-1]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (!token) return;
    setLoading(true);
    let newUrl = "/api/v1/actionplan/tasksWithRole/";
    const getTasks = async () => {
      await API.get(newUrl, {
        headers: { Authorization: token },
      })
        .then((res) => {
          setfullData(res.data);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    getTasks();
  }, [token]);
  let searchTasks = [-1];
  let filterRole = [];
  let filterStatus = [];
  let sortData = [];
  if (fullData[0] !== -1) {
    searchTasks = serachTask
      ? fullData?.filter(
          (s) =>
            s?.description?.includes(serachTask) ||
            s?.title?.includes(serachTask) ||
            ("T-" + s.id + "-" + s?.actionplanRelated?.id)?.includes(
              serachTask
            ) ||
            s?.actionplanRelated?.title?.includes(serachTask) ||
            (
              s?.responsible_task?.firstName +
              " " +
              s?.responsible_task?.lastName
            )?.includes(serachTask)
        )
      : fullData;
    filterRole =
      roleTask !== "all"
        ? searchTasks.filter(
            (d) =>
              d.taskRole.includes(roleTask) || d.userRole.includes(roleTask)
          )
        : searchTasks;
    filterStatus =
      statusTask !== "all"
        ? filterRole.filter((d) => d.status === statusTask)
        : filterRole;
    sortData =
      sortTask === "a_start"
        ? filterStatus?.sort((a, b) => (b?.startDate > a?.startDate ? -1 : 1))
        : sortTask === "b_start"
        ? filterStatus?.sort((a, b) => (b?.startDate > a?.startDate ? 1 : -1))
        : sortTask === "a_end"
        ? filterStatus?.sort((a, b) =>
            b?.remained_days > a?.remained_days ? -1 : 1
          )
        : sortTask === "b_end"
        ? filterStatus?.sort((a, b) =>
            b?.remained_days > a?.remained_days ? 1 : -1
          )
        : sortTask === "a_progress"
        ? filterStatus?.sort((a, b) => (b?.progress > a?.progress ? -1 : 1))
        : sortTask === "b_progress"
        ? filterStatus?.sort((a, b) => (b?.progress > a?.progress ? 1 : -1))
        : filterStatus?.sort((a, b) =>
            b?.created_at > a?.created_at ? -1 : 1
          );
  }

  useEffect(() => {
    if (error) {
      navigate(0);
    }
  }, [error]);
  return <TableListTasks fullData={sortData} loading={loading} />;
};

export default MainListTasks;
