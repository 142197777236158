const convertFileSize = (size) => {
    if (size < (1024 * 1024)) {
        return (size / 1024).toFixed(1) + "KB"
    } else if (size < (1024 * 1024 * 1024)) {
        return (size / (1024 * 1024)).toFixed(1) + "MB"
    } else {
        return (size / (1024 * 1024 * 1024)).toFixed(1) + "GB"
    }
}
module.exports = convertFileSize
