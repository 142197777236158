import React from 'react';

const TableAuthority = ({ items }) => {
    console.log(items);
    return (
        <div className="rounded-md">
            {
                items[0]?.describtion?.split(',')?.map((i,index) => (
                    <div key={index} className="flex baisis-ful border-t border-caspian-darkgray-100 first:border-0 ">
                        <div className="basis-1/12 p-2 text-center text-sm 2xl:text-base">{index+1}</div>
                        <div className="basis-11/12 p-2 text-sm 2xl:text-base">{i}</div>
                    </div>
                ))
            }
        </div>
    );
}

export default TableAuthority;
