import React from "react";
import SingleManagmentSystem from "./SingleManagmentSystem.js";

const MainManagmentSystem = ({ data }) => {
  const groupedById = data.reduce((acc, item) => {
    if (!acc[item.clauseStandardsRelated?.standardNumber]) {
      acc[item.clauseStandardsRelated?.standardNumber] = [];
    }

    // آیتم را به گروه اضافه کن
    acc[item.clauseStandardsRelated?.standardNumber].push(item);

    return acc;
  }, {});

  const resultArray = Object.values(groupedById);

  return (
    <div className="p-4 w-full flex flex-col gap-4">
      <span className="text-sm text-caspian-primary-500">معیارهای ممیزی</span>
      {resultArray?.map((m, i) => (
        <SingleManagmentSystem
          key={i}
          standard={m[0]?.clauseStandardsRelated?.standardNumber}
          bands={m}
        />
      ))}
    </div>
  );
};

export default MainManagmentSystem;
