import React, { useContext, useEffect, useState } from "react";
import {
  PiCheckCircleFill,
  PiCircleNotchBold,
  PiPaperclip,
  PiPlus,
  PiPlusBold,
  PiTrash,
  PiXCircleFill,
} from "react-icons/pi";
import ListSelected from "./ListSelected";
import API from "../../../../../../api/API";
import { AuthContext } from "../../../../../../context/auth/AuthContext";
import StackholderForm from "./stackholder/StackholderForm";
import { useNavigate, useParams } from "react-router-dom";
import SingleInput from "./SingleInput";
import ChallengeMain from "./challenge/ChallengeMain";
import OpportunityMain from "./opportunity/OpportunityMain";
import MainFileDocument from "./file/MainFileDocument";
import StackholderFormInf from "./stackholder/StackholderFormInf";
import SingleFile from "./file/SingleFile";
import MainMaterialForm from "./material/MainMaterialForm";

const PmForm = ({ fullData, costData }) => {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const params = useParams();
  const [showNewMaterialForm, setShowNewMaterialForm] = useState(false);
  const [showListProcess, setShowListProcess] = useState(false);
  const [showListGoal, setShowListGoal] = useState(false);
  const [processSelected, setProcessSelected] = useState(
    fullData?.actionplan_goal?.processes?.map((p) => p.id)
  );
  const [processFinalSelected, setProcessFinalSelected] =
    useState(processSelected);
  const [goalSelected, setGoalSelected] = useState(
    fullData?.actionplan_goal?.goal?.map((g) => g.id)
  );
  const [goalFinalSelected, setGoalFinalSelected] = useState(goalSelected);
  const [showStackholderForm, setShowStackholderForm] = useState(false);
  const [showStackholderFormInf, setShowStackholderFormInf] = useState(false);
  const [showIsDelete, setShowIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [stackholderList, setStackholderList] = useState([]);
  const [pmDescription, setPmDescription] = useState(
    fullData?.actionplan_PM?.pm_description
      ? fullData?.actionplan_PM?.pm_description
      : ""
  );
  const [pmOutput, setPmOutput] = useState(
    fullData?.actionplan_PM?.pm_output ? fullData?.actionplan_PM?.pm_output : ""
  );
  const [otherBudget, setOtherBudget] = useState(
    fullData?.actionplan_PM?.other_budget
      ? fullData?.actionplan_PM?.other_budget
      : ""
  );
  const [showUploadFile, setShowUploadFile] = useState(false);
  const [listFile, setListFile] = useState([]);
  const [refreshListFile, setRefreshListFile] = useState(0);
  const removeHandler = async (id) => {
    try {
      await API.delete(`/api/v1/actionplan/stockholders_plan/${id}/`, {
        headers: { Authorization: token },
      });
      setRefresh(1);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setRefresh(1);
  }, []);
  useEffect(() => {
    if (!token || !params?.id || refresh === 0) return;
    const getActionPlanStackholder = async () => {
      try {
        const res = await API.get(
          `/api/v1/actionplan/stackholders/actionplan/${params?.id}`,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data);
        setStackholderList(res?.data);
        setRefresh(0);
      } catch (err) {
        console.log(err);
      }
    };
    getActionPlanStackholder();
  }, [token, params?.id, refresh]);

  useEffect(() => {
    setRefreshListFile(1);
  }, []);
  useEffect(() => {
    if (!token || !params?.id || refreshListFile === 0) return;
    const getListFile = async () => {
      try {
        const res = await API.get(
          `/api/v1/actionplan/documents/actionplan/${params?.id}`,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data);
        setListFile(res?.data);
        setRefreshListFile(0);
      } catch (err) {
        console.log(err);
      }
    };
    getListFile();
  }, [token, params?.id, refreshListFile]);

  const goalHandler = async () => {
    if (
      // goalFinalSelected === fullData?.actionplan_goal?.goal?.map((g) => g.id) &&
      // processFinalSelected ===
      //   fullData?.actionplan_goal?.processes?.map((p) => p.id)
      !params?.id ||
      !token
    )
      return;
    let formData = {
      id: params?.id,
      actionplanRelated: params?.id,
      goal: goalFinalSelected,
      processes: processFinalSelected,
    };
    try {
      const res = await API.patch(
        `/api/v1/actionplan/ActionPlanGoal/${fullData?.actionplan_goal?.id}/`,
        formData,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const formHandler = async () => {
    if (fullData?.actionplan_PM?.id) {
      let formData = {
        pm_description: pmDescription,
        other_budget: +otherBudget,
        pm_output: pmOutput,
        actionplanRelated: +params?.id,
        id: +params?.id,
      };
      try {
        const res = await API.patch(
          `/api/v1/actionplan/actionPlanPM/${fullData?.actionplan_PM?.id}/`,
          formData,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        await goalHandler();
        console.log(res?.data);
        navigate(`/action-plan/my-actionplan/${params?.id}/profile`, {
          preventScrollReset: true,
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      let formData = {
        pm_description: pmDescription,
        other_budget: +otherBudget,
        pm_output: pmOutput,
        actionplanRelated: +params?.id,
      };
      try {
        const res = await API.post(
          "/api/v1/actionplan/actionPlanPM/",
          formData,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        await goalHandler();
        console.log(res?.data);
        navigate(`/action-plan/my-actionplan/${params?.id}/profile`);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const [materialRefresh, setMaterialRefresh] = useState(0);
  const [material, setMaterial] = useState([]);
  useEffect(() => {
    setMaterialRefresh(1);
  }, []);
  useEffect(() => {
    const getMaterialList = async () => {
      if (!fullData?.id || !token || materialRefresh !== 1) return;
      try {
        const res = await API.get(
          `/api/v1/actionplan/actionplan/material/${fullData?.id}`,
          {
            headers: { Authorization: token },
          }
        );
        setMaterial(res?.data);
        console.log(res?.data);
        setMaterialRefresh(0);
      } catch (err) {
        console.log(err);
      }
    };
    getMaterialList();
  }, [fullData, token, materialRefresh]);
  return (
    <div className="w-full flex flex-col gap-6">
      {showNewMaterialForm && (
        <MainMaterialForm
          setMaterialRefresh={setMaterialRefresh}
          actionplanIdRelated={fullData?.id}
          setShowNewMaterialForm={setShowNewMaterialForm}
        />
      )}
      <div className="flex items-center justify-between w-full">
        <h2 className="text-sm">تکمیل اطلاعات پروفایل</h2>
      </div>
      <div className="flex flex-col gap-2">
        <span className="text-xs text-caspian-gray4">عنوان برنامه اجرایی</span>
        <p className="text-xs">{fullData?.title}</p>
      </div>
      <div className="flex flex-col gap-2">
        <span className="text-xs text-caspian-gray4">توضیحات PMO</span>
        <p className="text-xs">{fullData?.description}</p>
      </div>
      <div className="flex flex-col gap-2">
        <span className="text-xs text-caspian-gray4">
          نتایج قابل انتظار PMO
        </span>
        <p className="text-xs">{fullData?.actionplan_goal?.outputProject}</p>
      </div>

      <div className="flex items-center w-full gap-1.5">
        {showListProcess && (
          <ListSelected
            finalSelected={processFinalSelected}
            setFinalSelected={setProcessFinalSelected}
            selected={processSelected}
            setSelected={setProcessSelected}
            setShowListSelected={setShowListProcess}
            title="لیست فرانیدهای مرتبط"
            url="/api/v1/businessProcess/group/"
          />
        )}
        {showListGoal && (
          <ListSelected
            finalSelected={goalFinalSelected}
            setFinalSelected={setGoalFinalSelected}
            selected={goalSelected}
            setSelected={setGoalSelected}
            setShowListSelected={setShowListGoal}
            title="لیست اهداف مرتبط"
            url="/api/v1/goal/goal/"
          />
        )}
        <div className="w-full flex flex-col gap-1.5">
          <label className="text-xs text-caspian-gray">
            فرایند مرتبط با این برنامه اجرایی
          </label>
          <button
            onClick={() => setShowListProcess(true)}
            className="rounded-md border border-caspian-secondary-400 p-1.5 flex items-center justify-between gap-1 w-full"
          >
            <div className="flex items-center gap-1.5">
              <PiPlusBold className="text-caspian-secondary-500 text-md" />
              <span className="text-caspian-secondary-500 text-xs">افزودن</span>
            </div>
            {processFinalSelected?.length > 0 ? (
              <span className="text-xs">
                {processFinalSelected?.length} فرایند افزوده شد
              </span>
            ) : (
              <span className="text-xs">فرایندی انتخاب نشده</span>
            )}
          </button>
        </div>
        <div className="w-full flex flex-col gap-1.5">
          <label className="text-xs text-caspian-gray">
            اهداف مرتبط با این برنامه اجرایی
          </label>
          <button
            onClick={() => setShowListGoal(true)}
            className="rounded-md border border-caspian-secondary-400 p-1.5 flex items-center justify-between gap-1 w-full"
          >
            <div className="flex items-center gap-1.5">
              <PiPlusBold className="text-caspian-secondary-500 text-md" />
              <span className="text-caspian-secondary-500 text-xs">افزودن</span>
            </div>
            {goalFinalSelected?.length > 0 ? (
              <span className="text-xs">
                {goalFinalSelected?.length} هدف افزوده شد
              </span>
            ) : (
              <span className="text-xs">هدفی انتخاب نشده</span>
            )}
          </button>
        </div>
      </div>

      <div className="flex items-start gap-4 w-full">
        <div className="flex flex-col gap-3 w-full h-full">
          {/* لیست انتخاب مرجع */}
          <div className="flex items-center gap-4 w-full">
            <button
              onClick={() => setShowStackholderForm(true)}
              className="flex items-center justify-between w-full border-y border-y-caspian-secondary-500 text-caspian-secondary-500 py-2 cursor-pointer hover:bg-caspian-secondary-50/20"
            >
              <span className="text-xs">افزودن ذینفع تاثیرپذیر</span>
              <PiPlus className="text-sm" />
            </button>
            {showStackholderForm && (
              <StackholderForm
                setShowStackholderForm={setShowStackholderForm}
                actionplanIdRelated={params?.id}
                setRefresh={setRefresh}
              />
            )}
          </div>
          <div className="max-h-[180px] overflow-y-scroll">
            <div className="flex flex-col gap-2">
              {stackholderList?.length < 1 ? (
                <h2 className="text-caspian-gray1 text-xs">
                  ذینفع ثبت نشده است!
                </h2>
              ) : (
                stackholderList?.map(
                  (d, i) =>
                    d.typeProject === "affected" && (
                      <div
                        key={i}
                        className="bg-caspian-surface p-1.5 rounded-md border border-caspian-gray2 flex items-center justify-between"
                      >
                        <div className="flex flex-col gap-1 w-[90%]">
                          <span className="text-xs line-clamp-1">
                            {d?.description}
                          </span>
                        </div>
                        {
                          <div className="relative flex items-center justify-center w-[10%]">
                            <button
                              onClick={async () => {
                                await setDeleteId(d?.id);
                                await setShowIsDelete(true);
                              }}
                              className="text-caspian-error2/30 hover:text-caspian-error2 duration-300 text-xl"
                            >
                              <PiTrash />
                            </button>
                            <div
                              className={
                                d?.id === deleteId && showIsDelete
                                  ? "absolute left-0 right-0 top-0 bottom-0 flex items-center gap-0.5 w-fit bg-caspian-surface shadow-[0_0_5px_#00000050] p-0.5 rounded-md duration-500"
                                  : "hidden duration-500"
                              }
                            >
                              <PiCheckCircleFill
                                onClick={() => {
                                  removeHandler(d?.id);
                                  setShowIsDelete(false);
                                }}
                                className="text-caspian-primary-600 text-lg cursor-pointer"
                              />
                              <PiXCircleFill
                                onClick={() => {
                                  setShowIsDelete(false);
                                  setDeleteId(null);
                                }}
                                className="text-caspian-gray text-lg cursor-pointer"
                              />
                            </div>
                          </div>
                        }
                      </div>
                    )
                )
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-3 w-full h-full">
          {/* لیست انتخاب مرجع */}
          <div className="flex items-center gap-4 w-full">
            <button
              onClick={() => setShowStackholderFormInf(true)}
              className="flex items-center justify-between w-full border-y border-y-caspian-secondary-500 text-caspian-secondary-500 py-2 cursor-pointer hover:bg-caspian-secondary-50/20"
            >
              <span className="text-xs">افزودن ذینفع تاثیرگذار</span>
              <PiPlus className="text-sm" />
            </button>
            {showStackholderFormInf && (
              <StackholderFormInf
                setShowStackholderFormInf={setShowStackholderFormInf}
                actionplanIdRelated={params?.id}
                setRefresh={setRefresh}
              />
            )}
          </div>
          <div className="max-h-[180px] overflow-y-scroll">
            <div className="flex flex-col gap-2">
              {stackholderList?.length < 1 ? (
                <h2 className="text-caspian-gray1 text-xs">
                  ذینفع ثبت نشده است!
                </h2>
              ) : (
                stackholderList?.map(
                  (d, i) =>
                    d.typeProject === "influencer" && (
                      <div
                        key={i}
                        className="bg-caspian-surface p-1.5 rounded-md border border-caspian-gray2 flex items-center justify-between"
                      >
                        <div className="flex flex-col gap-1 w-[90%]">
                          <span className="text-xs line-clamp-1">
                            {d?.description}
                          </span>
                        </div>
                        {
                          <div className="relative flex items-center justify-center w-[10%]">
                            <button
                              onClick={async () => {
                                await setDeleteId(d?.id);
                                await setShowIsDelete(true);
                              }}
                              className="text-caspian-error2/30 hover:text-caspian-error2 duration-300 text-xl"
                            >
                              <PiTrash />
                            </button>
                            <div
                              className={
                                d?.id === deleteId && showIsDelete
                                  ? "absolute left-0 right-0 top-0 bottom-0 flex items-center gap-0.5 w-fit bg-caspian-surface shadow-[0_0_5px_#00000050] p-0.5 rounded-md duration-500"
                                  : "hidden duration-500"
                              }
                            >
                              <PiCheckCircleFill
                                onClick={() => {
                                  removeHandler(d?.id);
                                  setShowIsDelete(false);
                                }}
                                className="text-caspian-primary-600 text-lg cursor-pointer"
                              />
                              <PiXCircleFill
                                onClick={() => {
                                  setShowIsDelete(false);
                                  setDeleteId(null);
                                }}
                                className="text-caspian-gray text-lg cursor-pointer"
                              />
                            </div>
                          </div>
                        }
                      </div>
                    )
                )
              )}
            </div>
          </div>
        </div>
      </div>

      {/* بودجه */}
      <div className="flex items-start gap-2 w-full">
        <div className="flex flex-col gap-2 w-full">
          <div className="flex items-center gap-2 w-full">
            <SingleInput lable="بودجه تخمینی PMO(ریال)">
              <input
                disabled
                value={fullData?.actionplan_detail?.cost}
                className="p-2 rounded-md bg-caspian-gray2 text-caspian-on_primary/80 outline-none text-xs w-full"
              />
            </SingleInput>
            <SingleInput lable="بودجه نهایی برنامه اجرایی">
              <input
                disabled
                defaultValue={costData?.costTotalTasks}
                className="p-2 rounded-md bg-caspian-gray2 text-caspian-on_primary/80 outline-none text-xs w-full"
              />
            </SingleInput>
          </div>
          <SingleInput lable="جمع بودجه وظایف(ریال)">
            <input
              disabled
              defaultValue={costData?.costTotalTasks}
              className="p-2 rounded-md bg-caspian-gray2 text-caspian-on_primary/80 outline-none w-full text-xs"
            />
          </SingleInput>
          <SingleInput lable="بودجه مورد نیاز بابت سایر موراد">
            <input
              onChange={(e) => setOtherBudget(e.target.value)}
              value={otherBudget}
              placeholder="رقم خود را به ریال وارد کنید"
              className="p-2 rounded-md bg-caspian-gray3 outline-none w-full text-xs"
            />
          </SingleInput>
        </div>

        {/* زمان */}
        <SingleInput lable="چهارچوب زمانی">
          {/* براورد PMO */}
          <div className="border border-caspian-gray2 rounded-md p-2 w-full flex items-center justify-between">
            <span className="text-xs text-caspian-gray2">برآوردی PMO</span>
            <div className="flex items-center gap-2">
              {/* شروع */}
              <div className="flex items-center gap-0 bg-caspian-gray2 rounded-md border border-caspian-gray/50">
                <span className="text-xs text-caspian-gray4 p-2 border-l border-l-caspian-gray/50">
                  شروع
                </span>
                <span className="text-xs text-caspian-gray4 p-2">
                  {new Date(
                    fullData?.actionplan_detail?.startDate
                  )?.toLocaleDateString("fa-IR")}
                </span>
              </div>
              {/* پایان */}
              <div className="flex items-center gap-0 bg-caspian-gray2 rounded-md border border-caspian-gray/50">
                <span className="text-xs text-caspian-gray4 p-2 border-l border-l-caspian-gray/50">
                  پایان
                </span>
                <span className="text-xs text-caspian-gray4 p-2">
                  {new Date(
                    fullData?.actionplan_detail?.endDate
                  )?.toLocaleDateString("fa-IR")}
                </span>
              </div>
            </div>
          </div>
          {/* برنامه ریزی مدیرپروژه */}
          <div className="border border-caspian-gray2 rounded-md p-2 w-full flex items-center justify-between">
            <span className="text-xs text-caspian-gray2">
              برنامه ریزی مدیر پروژه
            </span>
            <div className="flex items-center gap-2">
              {/* شروع */}
              <div className="flex items-center gap-0 bg-caspian-gray2 rounded-md border border-caspian-gray/50">
                <span className="text-xs text-caspian-gray4 p-2 border-l border-l-caspian-gray/50">
                  شروع
                </span>
                <span className="text-xs text-caspian-gray4 p-2">
                  {new Date(costData?.startDateReal).toLocaleDateString(
                    "fa-IR"
                  )}
                </span>
              </div>
              {/* پایان */}
              <div className="flex items-center gap-0 bg-caspian-gray2 rounded-md border border-caspian-gray/50">
                <span className="text-xs text-caspian-gray4 p-2 border-l border-l-caspian-gray/50">
                  پایان
                </span>
                <span className="text-xs text-caspian-gray4 p-2">
                  {new Date(costData?.endDateReal).toLocaleDateString("fa-IR")}
                </span>
              </div>
            </div>
          </div>
        </SingleInput>
      </div>
      {/* چالش ها و فرصت ها */}
      <div className="w-full flex items-start gap-2">
        {/* افزودن چالش ها */}
        <div className="flex flex-col gap-3 w-full h-full">
          <ChallengeMain actionplanIdRelated={params?.id} />
        </div>
        {/* افزودن فرصت ها */}
        <div className="flex flex-col gap-3 w-full h-full">
          <OpportunityMain actionplanIdRelated={params?.id} />
        </div>
      </div>
      {/* افزودن فایل */}
      <button
        className="border-b border-b-caspian-gray2 text-caspian-gray4 p-1 w-fit min-w-[200px] relative"
        onClick={() => setShowUploadFile(true)}
      >
        <span className="text-xs">انتخاب فایل</span>
        <PiPaperclip className="text-xl absolute right-2 top-1/2 -translate-y-1/2" />
      </button>
      {showUploadFile && (
        <MainFileDocument
          setRefreshListFile={setRefreshListFile}
          actionplanIdRelated={params?.id}
          setShowUploadFile={setShowUploadFile}
        />
      )}
      {/* لیست فایل ها */}
      {listFile?.length < 1 ? (
        <div>
          <h2 className="text-xs">فایلی آپلود نشده است</h2>
        </div>
      ) : (
        <div className="flex flex-wrap gap-2 items-center w-full">
          {listFile?.map((f, i) => (
            <SingleFile
              data={f}
              key={i}
              setRefreshListFile={setRefreshListFile}
            />
          ))}
        </div>
      )}
      <SingleInput lable={"نتایج مورد انتظار"}>
        <textarea
          onChange={(e) => setPmOutput(e.target.value)}
          value={pmOutput}
          rows={5}
          className="text-xs p-2 rounded-md bg-caspian-transparent outline-none border border-caspian-gray2 w-full resize-none"
          placeholder="نتایج مورد ا نتظار خود را وارد کنید"
        />
      </SingleInput>
      <SingleInput lable={"توضیحات"}>
        <textarea
          onChange={(e) => setPmDescription(e.target.value)}
          value={pmDescription}
          rows={5}
          className="text-xs p-2 rounded-md bg-caspian-transparent outline-none border border-caspian-gray2 w-full resize-none"
          placeholder="توضیحات خود را یادداشت کنید"
        />
      </SingleInput>
      {/* ثبت اطلاعات */}

      {material && (
        <div className="flex flex-col gap-3 w-[400px]">
          <div className="flex items-center justify-between">
            <span className="text-xs text-caspian-gray4">لیست اقلام مصرفی</span>
            <button
              onClick={() => setShowNewMaterialForm(true)}
              className="flex items-center gap-1 text-xs text-caspian-secondary-500"
            >
              <PiPlusBold className="text-sm" />
              <span>قلم جدید</span>
            </button>
          </div>
          <div className="flex items-center justify-between gap-4 pr-2 border-b border-b-caspian-gray2/50 pb-3">
            <span className="text-xs text-caspian-gray4 basis-1/3">نام</span>
            <span className="text-xs text-caspian-gray4 basis-1/3">واحد</span>
            <span className="text-xs text-caspian-gray4 basis-1/3">
              مقدار مصرف شده
            </span>
          </div>
          <div className="h-[150px] overflow-y-auto flex flex-col gap-0 ">
            {material?.map((r, i) => (
              <div
                className="flex items-center justify-between gap-4 border-r-2 border-r-caspian-primary-200 pr-2 py-2 first:pt-0"
                key={i}
              >
                <span className="text-xs text-caspian-gray4 basis-1/3 ">
                  {r?.materialRelated?.name}
                </span>
                <span className="text-xs text-caspian-gray4 basis-1/3 ">
                  {r?.materialRelated?.unit}
                </span>
                <span className="text-xs text-caspian-gray4 basis-1/3 ">
                  {0}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}

      <button
        onClick={formHandler}
        className="bg-caspian-secondary-500 text-caspian-surface text-xs py-1.5 px-7 rounded-md w-fit flex items-center gap-2 relative"
      >
        <span>ثبت اطلاعات</span>
        {false && (
          <div className="absolute left-1 top-1/2 -translate-y-1/2">
            <PiCircleNotchBold className="animate-spin text-base text-caspian-surface" />
          </div>
        )}
      </button>
    </div>
  );
};

export default PmForm;
