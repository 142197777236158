import React from 'react'
import ButtonChartShm from './ButtonChartShm'

const ChartShm = () => {

    let sidebar = localStorage.getItem('hide_sidebar')

    return (
        <div className={`flex flex-col items-center  w-full min-w-[1160px] max-w-[1160px]
            ${!sidebar ? "mx-10 2xl:mx-24" : "mx-12 2xl:mx-auto "}
        `}>

            <div className="flex flex-col w-full items-center h-full">
                {/* 1 */}
                <ButtonChartShm
                    title="هيئت مديره"
                    bgColor="#DDEAF4"
                    textColor="#4B6B90"
                    borderColor="#4B6B90"
                />
                {/* 1 */}
                <div className="h-5 border-r-2 border-r-caspian-gray"></div>
                {/* 2 */}
                <ButtonChartShm
                    title="مدیرعامل"
                    bgColor="#F6E5E7"
                    textColor="#7A3F47"
                    borderColor="#7A3F47"
                />
                {/* 2 */}
            </div>

            <div className="h-5 border-r-2 border-r-caspian-gray"></div>

            <div className="flex flex-col w-full items-center">
                {/* 3 */}
                <div className="flex justify-center relative h-0 gap-10 items-center">

                    <div className="flex flex-col gap-2 h-full items-center">
                        <div className="flex items-center justify-center">
                            <ButtonChartShm
                                title="مشاور مدیرعامل"
                                bgColor="#C7EBEA"
                                textColor="#225C58"
                                borderColor="#225C58">
                            </ButtonChartShm>
                            <div className="w-14 absolute left-[50%] h-2 border-b-2 border-b-caspian-gray"></div>
                        </div>
                    </div>

                    <div className="flex flex-col gap-2 h-full items-center">
                        <div className="flex items-center justify-center">
                            <div className="flex items-center justify-center">
                                <div className="w-[220px]"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="h-[50px] border-r-2 border-r-caspian-gray"></div>

                <div className="h-5 border-r-2 border-r-caspian-gray"></div>
                <ButtonChartShm
                    title="قائم مقام مدير عامل"
                    bgColor="#C7EBEA"
                    textColor="#225c58"
                    borderColor="#225c58"
                />

                <div className="h-[20px] border-r-2 border-r-caspian-gray"></div>

                {/* 3 */}

                <div className="flex w-full flex-col justify-center relative gap-5 items-center">
                    <div className="w-[715px] h-2 border-t-2 border-t-caspian-gray -translate-x-[12%]"></div>
                    {/* 4 */}
                    <div className="flex w-full items-start gap-8 translate-x-[3%] justify-between ">
                        <div className="flex flex-col gap-2 h-full items-center">
                            <div className="h-7 absolute border-l-2 -top-7 border-l-caspian-gray"></div>
                            <div className="flex items-center relative h-full">
                                <div className="flex flex-col items-center h-full">

                                    <div className='flex flex-col items-center gap-2'>
                                        <div className="flex items-center justify-center">
                                            <ButtonChartShm
                                                title="مدیر کارخانه / نماینده مدیریت"
                                                bgColor="#C7EBEA"
                                                textColor="#225C58"
                                                borderColor="#225C58">
                                            </ButtonChartShm>
                                        </div>
                                        <div className="h-2 absolute top-[8%] border-l-2 border-l-caspian-gray"></div>
                                        <div className="flex items-center justify-center">
                                            <ButtonChartShm
                                                title="قائم مقام کارخانه"
                                                bgColor="#C7EBEA"
                                                textColor="#225C58"
                                                borderColor="#225C58">
                                            </ButtonChartShm>
                                        </div>
                                    </div>
                                    <div className="h-7 border-l-2 border-l-caspian-gray"></div>
                                    <div className="flex flex-col items-center  h-full">
                                        <div className="w-[535px] border-t-2 border-t-caspian-gray"></div>

                                        <div className='flex items-start gap-7'>
                                            <div className="flex items-center flex-col relative justify-center">
                                                <div className="h-[56%] absolute top-[36%] -right-5 border-l-2 border-l-caspian-gray"></div>
                                                <div className="h-7 border-l-2 border-l-caspian-gray"></div>
                                                <div className='flex flex-col items-center gap-2'>

                                                    <div className="flex items-center flex-col justify-center">
                                                        <ButtonChartShm
                                                            title="مدير برنامه ريزی و کنترل توليد"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChartShm>
                                                    </div>

                                                    <div className="flex items-center flex-col justify-center">
                                                        <ButtonChartShm
                                                            title="فلزی و تراشکاری"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChartShm>
                                                        <div className="w-5 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>

                                                    <div className="flex items-center flex-col justify-center">
                                                        <ButtonChartShm
                                                            title="آبکاری"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChartShm>
                                                        <div className="w-5 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>

                                                    <div className="flex items-center flex-col justify-center">
                                                        <ButtonChartShm
                                                            title="پلیمر"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChartShm>
                                                        <div className="w-5 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>

                                                    <div className="flex items-center flex-col justify-center">
                                                        <ButtonChartShm
                                                            title="کیتینگ"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChartShm>
                                                        <div className="w-5 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex items-center relative flex-col justify-center">
                                                <div className="h-[72%] absolute top-[23%] -right-5 border-l-2 border-l-caspian-gray"></div>
                                                <div className="h-7 border-l-2 border-l-caspian-gray"></div>
                                                <div className='flex flex-col items-center gap-2'>
                                                    <ButtonChartShm
                                                        title="مدير فنی و مهندسی"
                                                        bgColor="#C7EBEA"
                                                        textColor="#225C58"
                                                        borderColor="#225C58">
                                                    </ButtonChartShm>

                                                    <div className="flex items-center flex-col justify-center">
                                                        <ButtonChartShm
                                                            title="R&D"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChartShm>
                                                        <div className="w-5 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>

                                                    <div className="flex items-center flex-col justify-center">
                                                        <ButtonChartShm
                                                            title="آزمایشگاه "
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChartShm>
                                                        <div className="w-5 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>

                                                    <div className="flex items-center flex-col justify-center">
                                                        <ButtonChartShm
                                                            title="کالیبراسیون"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChartShm>
                                                        <div className="w-5 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>

                                                    <div className="flex items-center flex-col justify-center">
                                                        <ButtonChartShm
                                                            title="فروش و بازاریابی"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChartShm>
                                                        <div className="w-5 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>

                                                    <div className="flex items-center flex-col justify-center">
                                                        <ButtonChartShm
                                                            title="ایمنی و بهداشت"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChartShm>
                                                        <div className="w-5 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>

                                                    <div className="flex items-center flex-col justify-center">
                                                        <ButtonChartShm
                                                            title="نت (Net)"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChartShm>
                                                        <div className="w-5 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>

                                                    <div className="flex items-center flex-col justify-center">
                                                        <ButtonChartShm
                                                            title="IT"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChartShm>
                                                        <div className="w-5 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex items-center flex-col relative justify-center">
                                                <div className="h-[30%] absolute top-[57%] -right-5 border-l-2 border-l-caspian-gray"></div>
                                                <div className="h-7 border-l-2 border-l-caspian-gray"></div>
                                                <div className='flex flex-col items-center gap-2'>

                                                    <div className="flex items-center flex-col justify-center">
                                                        <ButtonChartShm
                                                            title="خدمات پس از فروش "
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChartShm>
                                                    </div>

                                                    <div className="flex items-center flex-col justify-center">
                                                        <ButtonChartShm
                                                            title="پشتیبانی فروش "
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChartShm>
                                                        <div className="w-5 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>


                                                    <div className="flex items-center flex-col justify-center">
                                                        <ButtonChartShm
                                                            title="نصب"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChartShm>
                                                        <div className="w-5 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="flex items-center flex-col relative justify-center">
                                                <div className="h-[75%] absolute top-[17%] -right-5 border-l-2 border-l-caspian-gray"></div>
                                                <div className="h-7 border-l-2 border-l-caspian-gray"></div>
                                                <div className='flex flex-col items-center gap-2'>

                                                    <div className="flex items-center flex-col justify-center">
                                                        <ButtonChartShm
                                                            title="تضمين كيفيت"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChartShm>
                                                        <div className="w-5 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>

                                                    <div className="flex items-center flex-col justify-center">
                                                        <ButtonChartShm
                                                            title="کنترل کیفیت"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChartShm>
                                                        <div className="w-5 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>

                                                    <div className="flex items-center flex-col justify-center">
                                                        <ButtonChartShm
                                                            title="ارزیابی سیستم کیفیت"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChartShm>
                                                        <div className="w-5 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>

                                                    <div className="flex items-center flex-col justify-center">
                                                        <ButtonChartShm
                                                            title="مرکز اسناد"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChartShm>
                                                        <div className="w-5 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>

                                                    <div className="flex items-center flex-col justify-center">
                                                        <ButtonChartShm
                                                            title="شکایت مشتری"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChartShm>
                                                        <div className="w-5 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-2 h-full items-center">
                            <div className="h-7 absolute border-l-2 -top-7 border-l-caspian-gray"></div>
                            <div className="flex items-center relative h-full">
                                <div className="h-[87.5%] absolute top-6 -right-5 border-l-2 border-l-caspian-gray"></div>
                                <div className="flex flex-col items-center gap-2 h-full">
                                    <div className="flex items-center justify-center">
                                        <ButtonChartShm
                                            title="مدير مالی و منابع انسانی"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChartShm>
                                        <div className="w-5 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChartShm
                                            title="انبار"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChartShm>
                                        <div className="w-5 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChartShm
                                            title="حسابدار"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChartShm>
                                        <div className="w-5 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChartShm
                                            title="خرید (تدارکات)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChartShm>
                                        <div className="w-5 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChartShm
                                            title="صادرات مسئول اداری"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChartShm>
                                        <div className="w-5 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChartShm
                                            title="آموزش "
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChartShm>
                                        <div className="w-5 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <ButtonChartShm
                                            title="نگهبانی"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChartShm>
                                        <div className="w-5 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col h-full items-center">
                            <div className="h-7 absolute border-l-2 -top-7 border-l-caspian-gray"></div>

                            <div className="flex items-center justify-center">
                                <ButtonChartShm
                                    title="فروش"
                                    bgColor="#C7EBEA"
                                    textColor="#225C58"
                                    borderColor="#225C58">
                                </ButtonChartShm>
                            </div>

                            <div className="h-24 border-l-2 border-l-caspian-gray"></div>

                            <div className="flex items-center h-full">
                                <div className="flex flex-col items-center  h-full">
                                    <div className="w-[170px] border-t-2 border-t-caspian-gray"></div>

                                    <div className='flex items-start gap-5 relative'>
                                        <div className="h-[30%] absolute top-[57%] -right-5 border-l-2 border-l-caspian-gray"></div>
                                        <div className="flex items-center flex-col justify-center">
                                            <div className="h-7 border-l-2 border-l-caspian-gray"></div>
                                            <div className='flex flex-col items-center gap-2'>

                                                <div className="flex items-center flex-col justify-center">
                                                    <ButtonChartShm
                                                        title="سرپرست فروش فشار متوسط/ ضعيف"
                                                        bgColor="#C7EBEA"
                                                        textColor="#225C58"
                                                        borderColor="#225C58">
                                                    </ButtonChartShm>
                                                </div>

                                                <div className="flex items-center  justify-center">
                                                    <ButtonChartShm
                                                        title="فروش و بازاریابی"
                                                        bgColor="#C7EBEA"
                                                        textColor="#225C58"
                                                        borderColor="#225C58">
                                                    </ButtonChartShm>
                                                    <div className="w-5  absolute -right-5 border-b-2 border-b-caspian-gray"></div>

                                                </div>

                                                <div className="flex items-center flex-col justify-center">
                                                    <ButtonChartShm
                                                        title="نظرسنجی مشتریان (ارتباط با مشتری)"
                                                        bgColor="#C7EBEA"
                                                        textColor="#225C58"
                                                        borderColor="#225C58">
                                                    </ButtonChartShm>
                                                    <div className="w-5  absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="flex items-center flex-col justify-center">
                                            <div className="h-7 border-l-2 border-l-caspian-gray"></div>
                                            <div className='flex flex-col items-center gap-2'>
                                                <ButtonChartShm
                                                    title="سرپرست فروش فشار متوسط/ ضعيف"
                                                    bgColor="#C7EBEA"
                                                    textColor="#225C58"
                                                    borderColor="#225C58">
                                                </ButtonChartShm>

                                                <div className="flex items-center flex-col justify-center">
                                                    <ButtonChartShm
                                                        title="فروش و بازاریابی"
                                                        bgColor="#C7EBEA"
                                                        textColor="#225C58"
                                                        borderColor="#225C58">
                                                    </ButtonChartShm>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 4 */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChartShm