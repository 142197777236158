
import React, { useContext, useEffect, useState } from 'react'
import ListUploadMaterial from './upload-file-material/ListUploadMaterial'
import { PiCaretRightBold } from 'react-icons/pi'
import ModalDescriptionReport from '../../ModalDescriptionReport'
import API from '../../../../../../api/API'
import { AuthContext } from '../../../../../../context/auth/AuthContext'

const MainFormMaterial = ({ setActiveTab, reportId, handleReport, setShowModalFrom }) => {
    const { token } = useContext(AuthContext);



    const [listDocMaterial, setListDocMaterial] = useState([])
    const [refreshList, setRefreshList] = useState(0)
    const [checked, setChecked] = useState(true)
    const [showModalDesc, setShowModalDesc] = useState(false)

    const [deleteMaterial, setDeleteMaterial] = useState(false)

    useEffect(() => {
        if (!checked) {
            setDeleteMaterial(false)
        }
    }, [checked, setDeleteMaterial])


    const handleDeleteMaterial = async () => {
        if (!token || !reportId) return;

        try {
            const res = await API.delete(`/api/v1/actionplan/reports/materialdelete/${reportId}`)
            console.log(res?.data)
            setDeleteMaterial(true)
        } catch (error) {
            console.log(error)
        }
    }
    const clearData = () => {
        // setChecked(!checked)
        setDeleteMaterial(true)
        handleDeleteMaterial()
        setListDocMaterial([])
        console.log(checked)
        console.log(deleteMaterial)
    }


    return (
        <div className='flex h-[78vh] flex-col items-start justify-between'>


            <div className='w-full flex flex-col items-center gap-3'>
                <div className='flex flex-col items-center w-full px-5 '>
                    <div className='flex border-b border-b-caspian-secondary-600 flex-col items-start w-full gap-5 pb-3'>
                        <div className='flex items-center justify-start w-full border-2 border-caspian-gray1/80 py-2 px-5 rounded-lg gap-5'>

                            <input type='checkbox' id='material'
                                onClick={() => setChecked(!checked)}
                            />

                            <label htmlFor='material'

                                className={`text-xs 2xl:text-sm font-semibold ${checked ? "text-caspian-gray1" : "text-caspian-on_primary"}`}>
                                گزارشی ندارم
                            </label>
                        </div>

                        <div className='flex items-center w-full gap-5'>
                            <span className={`text-xs 2xl:text-sm whitespace-nowrap ${checked ? "text-caspian-gray4" : "text-caspian-gray4/30"}`}>
                                اقلام مصرفی
                            </span>
                            <div className='w-full h-[2px] bg-caspian-gray2/80'></div>
                        </div>
                    </div>
                </div>

                <ListUploadMaterial reportId={reportId} setListDocMaterial={setListDocMaterial} listDocMaterial={listDocMaterial}
                    checked={checked} setRefreshList={setRefreshList} refreshList={refreshList} />
            </div>


            <div className='w-full flex flex-col items-start gap-3 pb-2'>


                {(!checked && listDocMaterial?.length > 0) &&
                    <div className='px-5'>
                        <button
                            onClick={clearData}
                            className={`text-xs 2xl:text-sm bg-caspian-primary-500 text-caspian-surface duration-500 w-[80px] h-8 rounded-[4px]`}>
                            ثبت
                        </button>
                    </div>
                }

                <div className='w-full flex items-center border-t border-caspian-gray2 pt-3 px-5 gap-5'>
                    <button
                        onClick={() => setActiveTab("cost")}
                        className={`text-xs 2xl:text-sm flex items-center justify-center duration-500 w-[80px] h-8 rounded-[4px] border-2 text-caspian-secondary-600  border-caspian-secondary-600`}>

                        <PiCaretRightBold className='font-black' />
                        مرحله قبل
                    </button>

                    <button

                        disabled={((checked && (listDocMaterial?.length > 0)) || (!checked && listDocMaterial?.length < 1) || (!checked && deleteMaterial)) ? false : true}
                        onClick={() => setShowModalDesc(true)}
                        className={`text-xs 2xl:text-sm flex items-center justify-center duration-500  text-caspian-surface w-[80px] h-8 rounded-[4px]
                        ${((checked && (listDocMaterial?.length > 0)) || (!checked && listDocMaterial?.length < 1) || (!checked && deleteMaterial)) ? "bg-caspian-secondary-600" : "bg-caspian-gray2"}`}>
                        ثبت گزارش
                    </button>
                </div>
            </div >
            {showModalDesc &&

                <ModalDescriptionReport setShowModalFrom={setShowModalFrom} reportId={reportId} setShowModalDesc={setShowModalDesc} handleReport={handleReport} />
            }
        </div >
    )
}

export default MainFormMaterial