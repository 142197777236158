import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import API from "../../../../../api/API";

const ChallengeList = ({ actionplanIdRelated }) => {
  const { token } = useContext(AuthContext);
  const [challengeList, setChallengeList] = useState([]);
  useEffect(() => {
    if (!token || !actionplanIdRelated) return;
    const getActionPlanChallenge = async () => {
      try {
        const res = await API.get(
          `/api/v1/actionplan/situations/actionplan/${actionplanIdRelated}`,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data?.filter((c) => c?.typeSituation === "challenge"));
        setChallengeList(
          res?.data?.filter((c) => c?.typeSituation === "challenge")
        );
      } catch (err) {
        console.log(err);
      }
    };
    getActionPlanChallenge();
  }, [token, actionplanIdRelated]);
  return (
    <div className="max-h-[180px] overflow-y-scroll w-full">
      <div className="flex flex-col gap-2">
        {challengeList?.length < 1 ? (
          <h2 className="text-caspian-gray1 text-xs">چالشی ثبت نشده است!</h2>
        ) : (
          challengeList?.map((d, i) => (
            <div
              key={i}
              className="bg-caspian-surface p-1.5 rounded-md border border-caspian-gray2 flex items-center justify-between"
            >
              <div className="flex flex-col gap-1 w-[90%]">
                <span className="text-xs line-clamp-1">آثار: {d?.impact}</span>
                <span className="text-xs line-clamp-1">
                  راهکار: {d?.solution}
                </span>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ChallengeList;
