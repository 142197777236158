import React, { useEffect, useRef, useState } from "react";
import { PiCaretLeftBold, PiFunnel } from "react-icons/pi";

const Filter2 = ({ searchByOperatingUnit, setSearchByOperatingUnit }) => {
  let menuRef = useRef();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const [showSubMenu, setShowSubMenu] = useState(false);
  return (
    <div ref={menuRef} className="relative w-fit">
      <div onClick={() => setOpen(!open)} className="relative cursor-pointer">
        <PiFunnel className="text-xl text-caspian-surface cursor-pointer" />
      </div>
      {/* Start Open Menu */}
      <div
        className={
          open
            ? "z-50 overflow-y-scroll absolute top-5 left-0 flex items-center flex-col drop-shadow-[0_0_1px_#00000050] min-w-[240px] max-h-[260px] bg-caspian-surface rounded-lg bg-scroll"
            : "hidden"
        }
      >
        {/* Start Box Menu */}
        <div className="flex flex-col items-start gap-2 w-full p-3">
          <div className="flex items-center gap-4 w-full border-b border-b-caspian-gray2 pb-2">
            <input
              type="checkbox"
              id="all"
              className="accent-caspian-secondary-400"
            />
            <label
              htmlFor="all"
              className="text-sm text-caspian-gray font-normal w-full cursor-pointer"
            >
              همه
            </label>
          </div>
          <div
            className={`${
              showSubMenu ? "border-none" : "border-b"
            } flex items-center gap-4 w-full border-b-caspian-gray2 pb-2`}
          >
            <input
              onChange={(e) =>
                setSearchByOperatingUnit(
                  e.target.checked
                    ? [...searchByOperatingUnit, e.target.value]
                    : searchByOperatingUnit.filter((d) => d !== e.target.value)
                )
              }
              value="11"
              type="checkbox"
              id="11"
              className="accent-caspian-secondary-400"
            />
            <label
              className="text-sm text-caspian-gray font-normal w-full cursor-pointer flex items-center justify-between"
              htmlFor="11"
            >
              منابع انسانی
            </label>
            <PiCaretLeftBold
              onClick={() => setShowSubMenu(!showSubMenu)}
              className={
                showSubMenu
                  ? "text-caspian-gray text-xl -rotate-90 duration-300 cursor-pointer"
                  : "text-caspian-gray text-xl rotate-0 duration-300 cursor-pointer"
              }
            />
          </div>
          <div className={showSubMenu ? "w-full p-2" : "hidden"}>
            <div className="flex items-center gap-4 w-full border-b border-b-caspian-gray2 pb-2">
              <input
                onChange={(e) =>
                  setSearchByOperatingUnit(
                    e.target.checked
                      ? [...searchByOperatingUnit, e.target.value]
                      : searchByOperatingUnit.filter(
                          (d) => d !== e.target.value
                        )
                  )
                }
                value="12"
                type="checkbox"
                id="12"
                className="accent-caspian-secondary-400"
              />
              <label
                className="text-sm text-caspian-gray font-normal w-full cursor-pointer"
                htmlFor="12"
              >
                تست 12
              </label>
            </div>
          </div>
          <div className="flex items-center gap-4 w-full border-b border-b-caspian-gray2 pb-2">
            <input
              onChange={(e) =>
                setSearchByOperatingUnit(
                  e.target.checked
                    ? [...searchByOperatingUnit, e.target.value]
                    : searchByOperatingUnit.filter((d) => d !== e.target.value)
                )
              }
              value="22"
              type="checkbox"
              id="22"
              className="accent-caspian-secondary-400"
            />
            <label
              className="text-sm text-caspian-gray font-normal w-full cursor-pointer"
              htmlFor="22"
            >
              مدیرعامل
            </label>
          </div>
          <div className="flex items-center gap-4 w-full">
            <input
              onChange={(e) =>
                setSearchByOperatingUnit(
                  e.target.checked
                    ? [...searchByOperatingUnit, e.target.value]
                    : searchByOperatingUnit.filter((d) => d !== e.target.value)
                )
              }
              value="33"
              type="checkbox"
              id="33"
              className="accent-caspian-secondary-400"
            />
            <label
              className="text-sm text-caspian-gray font-normal w-full cursor-pointer"
              htmlFor="33"
            >
              منابع مالی
            </label>
          </div>
        </div>
        {/* End Box Menu */}
      </div>
      {/* End Open Menu */}
    </div>
  );
};

export default Filter2;
