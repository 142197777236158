import React from "react";
import { PiCloudArrowDown, PiFilePdf } from "react-icons/pi";

const DownloadsMain = ({ dataDownloads }) => {
  console.log(dataDownloads)
  return (
    <>
      {dataDownloads !== undefined ? (
        <div className="flex items-center justify-between gap-4">
          <h2 className="text-sm">برای دانلود فایل روی دکمه کلیک کنید.</h2>
          <button className="">
            <a
              download={dataDownloads}
              href={dataDownloads}
              className="flex items-center gap-1 w-full h-full bg-caspian-secondary-500 rounded-xl py-2 px-4 text-sm text-caspian-surface"
            >
              دانلود
              <PiCloudArrowDown className="text-lg" />
            </a>
          </button>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center gap-4 py-6">
          <PiFilePdf className="text-5xl text-caspian-gray" />
          <h2 className="text-caspian-gray text-sm">
            فایل جهت دانلود وجود ندارد!
          </h2>
        </div>
      )}
    </>
  );
};

export default DownloadsMain;
