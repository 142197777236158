import { PiPaperPlaneRight, PiTray } from "react-icons/pi";
import ListItemSingle from "./ListItemSingle";
import { useAppContext } from "../../context/app-context/AppContext";

const RequestsNavbar = ({ buttonActive }) => {
  const { requestSendNumber, requestReceivedNumber, setBradCrump3 } =
    useAppContext();
  return (
    <nav className="w-full rtl">
      <ul className="flex items-center gap-2">
        <ListItemSingle
          title="کارهایی که از من درخواست شده است"
          active="req-received"
          number={requestReceivedNumber}
          icon={<PiTray />}
          imageAddress="/assets/images/request/navbar/received.svg"
          link="/requests/received"
          buttonActive={buttonActive}
          onClick={() => setBradCrump3("")}
        />
        <ListItemSingle
          title="کارهایی که من از دیگران درخواست کردم"
          active="sent"
          number={requestSendNumber}
          icon={<PiPaperPlaneRight />}
          imageAddress="/assets/images/request/navbar/sent.svg"
          link="/requests/sent"
          buttonActive={buttonActive}
          onClick={() => setBradCrump3("")}
        />
        <ListItemSingle
          title="ارجاع"
          active="refer"
          number={0}
          icon={<PiPaperPlaneRight />}
          imageAddress="/assets/images/request/navbar/refer.svg"
          link="/requests/refer"
          buttonActive={buttonActive}
          onClick={() => setBradCrump3("")}
        />
      </ul>
    </nav>
  );
};

export default RequestsNavbar;
