import React, { useState } from "react";
import MenuAction from "./MenuAction";

const ChatBoxSender2 = ({
  firstIndex,
  messageId,
  sender,
  message,
  date,
  avatar,
  setRefreshMessageList,
  setMessageInput,
  setEditButton,
  setMessageId,
  isFav,
}) => {
  const [showName, setShowName] = useState(false);

  return (
    <div className="flex items-start gap-2 relative">
      <div className="mr-10 flex flex-col gap-2 bg-caspian-surface p-2 rounded-md rounded-tr-none rounded-bl-2xl w-fit min-w-[30%] max-w-[70%] shadow-[0_0_5px_#00000050] border border-caspian-secondary-200">
        <p className="text-xs leading-5 text-caspian-secondary-950">
          {message}
        </p>
        <div className="flex items-center justify-between gap-2 w-full relative">
          <span className="text-[9px] text-caspian-gray4">{date}</span>
          <MenuAction
            isFav={isFav}
            setMessageId={setMessageId}
            setEditButton={setEditButton}
            message={message}
            setMessageInput={setMessageInput}
            messageId={messageId}
            setRefreshMessageList={setRefreshMessageList}
            firstIndex={firstIndex}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatBoxSender2;
