import React, { useEffect, useState } from "react";
import API from "../../../../api/API";
import TableListFavoriteDocuments from "./TableListFavoriteDocuments";

const MainFavoriteDocuments = () => {
  const [loading, setLoading] = useState(false);
  const [fullData, setFullData] = useState([-1]);
  useEffect(() => {
    setLoading(true);
    API.get("/api/v1/businessProcess/process/")
      .then((d) => {
        setFullData(d.data);
      })
      .catch((err) => {
        console.log("error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return <TableListFavoriteDocuments data={fullData} loading={loading} />;
};

export default MainFavoriteDocuments;
