import React from 'react';

const Education = ({positionOrientation}) => {
    return (
        <div className=" rounded-md">
            <div className="header flex basis-full text-caspian-darkgray-600 text-sm xl:text-base">
                <div className="basis-1/12 p-4 text-center whitespace-nowrap">ردیف</div>
                <div className="basis-3/12 p-4 whitespace-nowrap">حداقل مدرک تحصیلی</div>
                <div className="basis-3/12 p-4 whitespace-nowrap">رشته تحصیلی</div>
                <div className="basis-5/12 p-4 whitespace-nowrap">گرایش‌ها</div>
            </div>
            {
                positionOrientation?.map((item,index) => (
                    <div key={item?.id} className="body flex basis-full border-t border-caspian-gray2 text-xs xl:text-sm">
                        <div className="basis-1/12 p-4 text-center">{index+1}</div>
                        <div className="basis-3/12 p-4">{item?.studyfieldRelated?.DegreeRelated?.grade}</div>
                        <div className="basis-3/12 p-4">{item?.studyfieldRelated?.field}</div>
                        <div className="basis-3/12 p-4">{item?.orientation}</div>
                    </div>
                ))
            }
            {/* <div className="body flex basis-full border-t border-caspian-darkgray-100">
                <div className="basis-1/12 p-4 text-center">1</div>
                <div className="basis-3/12 p-4">کارشناسی ارشد</div>
                <div className="basis-3/12 p-4"> مدیریت</div>
                <div className="basis-3/12 p-4"> مدیریت دولتی ، مدیریت بازرگانی</div>
            </div>
            <div className="body flex basis-full border-t border-caspian-darkgray-100">
                <div className="basis-1/12 p-4 text-center">2</div>
                <div className="basis-3/12 p-4">کارشناسی ارشد</div>
                <div className="basis-3/12 p-4"> مدیریت</div>
                <div className="basis-5/12 p-4"> مدیریت دولتی ، مدیریت بازرگانی</div>
            </div> */}
        </div>
    );
}


export default Education;
