import React from "react";
import { PiX } from "react-icons/pi";

const HoverItemsDetails = ({ open, setOpen, data }) => {
  return (
    <div
      className={
        open
          ? "fixed top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 z-[500]"
          : "hidden"
      }
    >
      <div className="flex flex-col gap-0 min-w-[320px] max-w-[320px] z-50 drop-shadow-[0_0_5px_#00000080]  bg-caspian-surface rounded-md">
        <div className=" flex flex-col gap-0">
          <div className="flex items-center justify-between w-full p-3">
            <h2 className="text-sm text-caspian-gray4">{data?.title}</h2>
            <PiX onClick={() => setOpen(false)} className="cursor-pointer" />
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: data?.description }}
            className="min-h-[180px] max-h-[180px] overflow-y-auto p-3"
          >
            {/* <p className="text-xs leading-5 text-justify"></p> */}
          </div>
        </div>
        {/* <div className="mr-10 right-2 top-0 w-0 h-0 border-l-[12px] border-l-caspian-transparent border-t-[12px] border-t-caspian-surface border-r-[12px] border-r-caspian-transparent"></div> */}
      </div>
    </div>
  );
};

export default HoverItemsDetails;
