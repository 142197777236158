import React, { useContext, useState } from "react";
import { PiArrowBendUpLeftLight, PiTrash, PiCheckLight, PiXLight, PiCaretDown } from "react-icons/pi";
import ReplyMain from "./reply/ReplyMain";
import Response from "./Response";
import { AuthContext } from "../../../context/auth/AuthContext";
import API from "../../../api/API";

const CommentSingle = ({ text, date, fullName, position, positionActionPlan, count, profileImg, setReplay, replay, item, setListReplay, setListComments }) => {
  const { token } = useContext(AuthContext)

  const [showText, setShowText] = useState(true);
  const [showReply, setShowReply] = useState(false)
  const [response, setResponse] = useState(false)
  const [deleteMessage, setDeleteMessage] = useState(false)

  const deleteComment = async (id) => {
    if (!token) return
    try {
      // const res = API.delete(``)
      // console.log(res?.data)
      console.log("delete")
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="border-b last:border-none border-caspian-gray2/90 w-full px-5 pt-2">
      <div className="flex justify-between items-center pb-2">
        <div className="flex items-center gap-2">
          <img alt="" className="rounded-full w-10 h-10" src={profileImg}></img>

          <div className="flex flex-col">
            <div className="flex items-center gap-2 ">
              <h3 className="text-caspian-secondary-900 text-sm">{fullName}</h3>
              <span className="text-caspian-gray text-xs relative ">
                {position}
              </span>
            </div>

            <span className="text-caspian-gray text-xs">{positionActionPlan}</span>
          </div>
        </div>
        <div className="flex justify-center items-center ">
          <p className="text-caspian-gray text-xs">
            {new Date(date).toLocaleDateString("fa-IR")}
          </p>
        </div>
      </div>
      <p className="text-xs  ">
        {showText ? item?.comment?.slice(0, 120) : item?.comment}
        {item?.comment?.length > 120 && (
          <span
            onClick={() => setShowText(!showText)}
            className="text-caspian-secondary-600 px-2 text-xs cursor-pointer">
            {showText ? "بیشتر" : "کمتر"}
          </span>
        )}
      </p>
      {response ? (<Response item={item} response={response} setResponse={setResponse} setListComments={setListComments} replay={replay} setReplay={setReplay} setListReplay={setListReplay} />) : (
        <div className=" w-full flex justify-between items-center pt-2 pb-3">
          <div className=" flex justify-center items-center cursor-pointer">
            <span
              className="text-caspian-gray text-xs"
              onClick={() => {
                setShowReply(!showReply);
              }}
            >
              مشاهده {count} پاسخ دیگر{" "}
            </span>
            <PiCaretDown className="text-caspian-gray" />
          </div>

          <div className="flex gap-5 items-center">
            <button className="flex gap-2 items-center cursor-pointer" onClick={() => setResponse(!response)}>
              <span className="text-xs  text-caspian-primary-300">پاسخ</span>
              <PiArrowBendUpLeftLight className="text-lg text-caspian-primary-300" />
            </button>

            {!deleteMessage
              ? (<button className=" flex gap-2 items-center cursor-pointer" onClick={() => setDeleteMessage(true)}>
                <span className="text-xs text-caspian-error2">حذف</span>
                <PiTrash className=" text-caspian-error2 text-lg" />
              </button>
              ) : (
                <div className="relative flex  gap-2">
                  <button className=" text-caspian-error2 rounded-full text-2xl "
                    onClick={deleteComment}>
                    {<PiCheckLight />}
                  </button>

                  <button className="text-caspian-gray4 text-2xl" onClick={() => setDeleteMessage(false)}><PiXLight /></button>
                </div>)
            }
          </div>
        </div>
      )
      }
      {showReply && <ReplyMain replay={replay} setReplay={setReplay} item={item} />}
    </div >
  );
};

export default CommentSingle;

