import React from "react";

const ConsiderationsDetails = ({ data }) => {
  return (
    <div className="p-4">
      <p className="text-sm text-justify">{data.considerations}</p>
    </div>
  );
};

export default ConsiderationsDetails;
