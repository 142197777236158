import { useNavigate, useSearchParams } from "react-router-dom";
import { PiBookmarkSimple, PiCloudArrowDown } from "react-icons/pi";
import { useState } from "react";
import { useAppContext } from "../../../../context/app-context/AppContext";

const SingleTableExternalDocuments = ({
  id,
  title,
  code,
  type,
  updateDate,
  reference,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const { setBradCrump3 } = useAppContext();

  const [showDownloadBox, setShowDownloadBox] = useState(false);
  const [deActiveProfileLink, setDeActiveProfileLink] = useState(false);

  return (
    <tr
      onMouseEnter={() => setShowDownloadBox(true)}
      onMouseLeave={() => setShowDownloadBox(false)}
      onClick={() => {
        !deActiveProfileLink && setBradCrump3(title);
        // !deActiveProfileLink &&
        //   navigate(`${id}`, {
        //     state: { search: `?${searchParams.toString()}` },
        //   });
          window.open(
            `/documents-and-records/documents/external-documents/${id}`,
            "_blank"
          );
      }}
      className="cursor-pointer bg-caspian-surface duration-300 w-full"
    >
      <td
        // colSpan={3}
        className="text-center border-b border-b-caspian-gray2 px-3 py-2  w-[30%]"
      >
        <h2 className="line-clamp-1">{title}</h2>
      </td>
      <td
        // colSpan={1}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 font-sans  w-[10%]"
      >
        <h2>{code}</h2>
      </td>
      <td
        // colSpan={1}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2  w-[15%]"
      >
        <h2>{type}</h2>
      </td>
      <td
        // colSpan={2}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[15%]"
      >
        <h2>{new Date(updateDate).toLocaleDateString("fa-IR")}</h2>
      </td>
      <td
        // colSpan={5}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[30%]"
      >
        <div className="flex items-center justify-between w-full relative">
          <h2>{reference}</h2>
          <div
            onMouseEnter={() => setDeActiveProfileLink(true)}
            onMouseLeave={() => setDeActiveProfileLink(false)}
            className={
              showDownloadBox
                ? "bg-caspian-surface z-40 flex items-center gap-2 absolute top-1/2 -translate-y-1/2 left-4 p-1"
                : "hidden"
            }
          >
            <button>
              <PiBookmarkSimple className="text-xl text-caspian-secondary-500" />
            </button>
            <a download={"/"} href={"/"}>
              <PiCloudArrowDown className="text-xl text-caspian-secondary-500" />
            </a>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default SingleTableExternalDocuments;
