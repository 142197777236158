import { useContext, useEffect, useState } from "react";
import Modal from "../../../../modal/Modal";
import { PiCheckSquareBold, PiSquare, PiX } from "react-icons/pi";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import API from "../../../../../api/API";

const ListRiskSubjectGoal = ({
  setShowListRiskSubject,
  setGoalFinalSelected,
  goalFinalSelected,
  goalSelected,
  setGoalSelected,
}) => {
  const { token } = useContext(AuthContext);

  const [goalData, setGoalData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getGoal = async () => {
      if (!token) return;

      setLoading(true);
      try {
        const res = await API.get(`/api/v1/goal/goal/`, {
          headers: { Authorization: token },
        });
        console.log(res?.data);
        setGoalData(res?.data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    getGoal();
  }, [token]);

  const goalFinalHandler = () => {
    if (goalSelected?.length > 0) {
      setGoalFinalSelected(goalSelected);
      setShowListRiskSubject(false);
    }
  };
  return (
    <Modal>
      <div className="flex flex-col gap-0 min-w-[400px] max-w-[400px] z-50 bg-caspian-surface rounded-md shadow-[0_0_5px_#00000050] overflow-hidden">
        <div className="flex items-center justify-between w-full p-2 border-b border-b-caspian-gray2">
          <span className="text-xs text-caspian-gray font-bold">
            لیست اهداف مرتبط
          </span>
          <PiX
            onClick={() => {
              setShowListRiskSubject(false);
              setGoalSelected(goalFinalSelected);
            }}
            className=""
          />
        </div>
        <div className="max-h-[360px] min-h-[360px] overflow-y-scroll">
          {goalData?.map((g, i) => (
            <div
              onClick={() => {
                !goalSelected.includes(g?.id)
                  ? setGoalSelected([...goalSelected, g?.id])
                  : setGoalSelected(goalSelected.filter((d) => d !== g?.id));
              }}
              key={i}
              className="flex items-center gap-4 justify-between w-full even:bg-caspian-gray2/30 p-2 cursor-pointer"
            >
              <div className="flex items-center gap-2">
                {goalSelected.includes(g?.id) ? (
                  <PiCheckSquareBold className="text-xl text-caspian-primary-500" />
                ) : (
                  <PiSquare className="text-xl text-caspian-primary-500" />
                )}
                <span className="text-xs text-caspian-gray4">{g?.title}</span>
              </div>
              <span className="text-xs text-caspian-gray4">
                {g?.id}
              </span>
            </div>
          ))}
        </div>
        <div className="p-2 border-t border-t-caspian-gray2">
          <button
            onClick={goalFinalHandler}
            disabled={goalSelected.length < 1 ? true : false}
            className="bg-caspian-secondary-500 text-xs rounded-md text-caspian-surface py-1.5 px-4 disabled:bg-caspian-gray2 disabled:text-caspian-gray4"
          >
            تایید
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ListRiskSubjectGoal;
