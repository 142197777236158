import { useContext, useState } from 'react';
import Intro from './content/intro/Intro';
import Authentication from './content/authentication/Authentication';
import Task from './content/task/Task';
import Safety from './content/safety/Safety';
import Process from './content/process/Process';
import Requirements from './content/requirements/Requirements';
import ButtonTab from '../../profile/tabs/buttontabs/ButtonTabs';
import { AuthContext } from '../../../../../context/auth/AuthContext';


const OrganizationTabs = ({ data, refresh, setRefresh }) => {
    const [activeTab, setActiveTab] = useState("intro");
    const { user } = useContext(AuthContext)
    return (
        <div className='bg-caspian-surface border border-caspian-gray2 py-7 px-5 shadow-sm rounded-lg flex flex-col gap-8 my-2'>
            <div className="flex gap-0">

                <ButtonTab
                    activeTab={activeTab}
                    active="intro"
                    onClick={() => setActiveTab("intro")}
                    title="معرفی"
                />

                <ButtonTab
                    activeTab={activeTab}
                    active="authentication"
                    onClick={() => setActiveTab("authentication")}
                    title="شرایط احراز"
                />

                <ButtonTab
                    activeTab={activeTab}
                    active="task"
                    onClick={() => setActiveTab("task")}
                    title="شرح وظایف"
                />

                <ButtonTab
                    activeTab={activeTab}
                    active="safety"
                    onClick={() => setActiveTab("safety")}
                    title="ایمنی و بهداشت حرفه‌ای"
                />


                <ButtonTab
                    activeTab={activeTab}
                    active="process"
                    onClick={() => setActiveTab("process")}
                    title="فرایند‌های مرتبط"
                />

                <ButtonTab
                    activeTab={activeTab}
                    active="requirements"
                    onClick={() => setActiveTab("requirements")}
                    title="الزامات"
                />
            </div>

            {activeTab === "intro" ? (
                <Intro
                    postDescription={data?.postDescription}
                    noStructureRole={data?.noStructureRole}
                    setRefresh={setRefresh}
                    id={data?.id}
                    user={user}
                />

            ) : activeTab === "authentication" ? (
                <Authentication
                    positionRelatedCondition={data?.positionRelatedCondition}
                    setRefresh={setRefresh}
                    id={data?.id}
                    user={user} />
            ) : activeTab === "task" ? (
                <Task data={data}
                    setRefresh={setRefresh}
                    id={data?.id}
                    user={user} />
            ) : activeTab === "safety" ? (
                <Safety
                    positionRelatedSafety={data?.positionRelatedSafety}
                    positionRelatedEquipment={data?.positionRelatedEquipment}
                    positionRelatedRules={data?.positionRelatedRules} />
            ) : activeTab === "process" ? (
                <Process />
            ) : (
                <Requirements
                    positionRelatedForRequirementRules={data?.positionRelatedForRequirementRules}
                    positionRelatedForRequirementAsset={data?.positionRelatedForRequirementAsset}
                    setRefresh={setRefresh}
                    id={data?.id}
                    user={user} />
            )
            }
        </div>
    );
}

export default OrganizationTabs;
