import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import SingleTableObsoleteDocuments from "./SingleTableObsoleteDocuments";
import RowView from "../../list-records/RowView";
import Paginate from "./Paginate";
import { PiCircleNotch } from "react-icons/pi";
import Filter from "./Filter";
import Filter2 from "./Filter2";

const TableListObsoleteDocuments = ({ data, loading }) => {
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search");
  const page = searchParams.get("page");
  const rows = searchParams.get("rows");

  const [searchByTitle, setSearchByTitle] = useState("");
  const [searchByCode, setSearchCode] = useState("");
  const [searchByType, setSearchType] = useState("");
  const [searchByOwnerParts, setSearchOwnerParts] = useState("");
  const [searchByUsers, setSearchUsers] = useState("");

  const [searchByOperatingUnit, setSearchByOperatingUnit] = useState([]);
  // const [searchByTypeDocuments, setSearchTypeDocuments] = useState([]);

  useEffect(() => {
    if (search) {
      setCurrentPage(1);
    }
  }, [search]);

  let searchTitle = [];
  let searchCode = [];
  let searchType = [];
  let searchOwnerParts = [];
  let searchUsers = [];
  // let typeDocuments = [];

  let newData = [-1];
  if (data[0] !== -1) {
    newData = search
      ? data.filter(
        (d) =>
          d.title !== undefined &&
          d.title.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
      : data;
  }

  if (newData[0] !== -1) {
    searchTitle = searchByTitle
      ? newData.filter((d) =>
        d.title
          .toLocaleLowerCase()
          .includes(searchByTitle.toLocaleLowerCase())
      )
      : newData;
    searchCode = searchByCode
      ? searchTitle.filter((d) => d.code.toString().includes(searchByCode))
      : searchTitle;

    searchType = searchByType
      ? searchCode.filter((d) =>
        d.type.toLocaleLowerCase().includes(searchByType.toLocaleLowerCase())
      )
      : searchCode;

    searchOwnerParts = searchByOwnerParts
      ? searchType.filter((d) =>
        d.ownerParts
          .toLocaleLowerCase()
          .includes(searchByOwnerParts.toLocaleLowerCase())
      )
      : searchType;

    searchUsers = searchByUsers
      ? searchOwnerParts.filter((d) =>
        d.users
          .toLocaleLowerCase()
          .includes(searchByUsers.toLocaleLowerCase())
      )
      : searchOwnerParts;

    // typeDocuments =
    //   searchByTypeDocuments.length > 0
    //     ? searchCode.filter(
    //         (d) =>
    //           d.id.toString().includes(searchByTypeDocuments[0]) ||
    //           d.id.toString().includes(searchByTypeDocuments[1]) ||
    //           d.id.toString().includes(searchByTypeDocuments[2])
    //       )
    //     : searchCode;
  }

  const [currentPage, setCurrentPage] = useState(page ? page : 1);
  const [recordsPerPage, setRecordsPerPage] = useState(rows ? rows : 10);
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = searchUsers.slice(firstIndex, lastIndex);
  const npage = Math.ceil(searchUsers.length / recordsPerPage);

  return (
    <div className="flex flex-col gap-4 py-6 px-8 xl:px-16">
      {loading ? (
        <div className="flex items-center justify-center py-40">
          <PiCircleNotch className="text-caspian-secondary-600 text-2xl animate-spin" />
        </div>
      ) : (
        <div className="flex flex-col overflow-x-auto">
          <div className="overflow-x-auto min-h-[240px]">
            <table className="min-w-[992px] w-full text-left text-sm font-light">
              <thead className="border-b border-b-caspian-gray2 font-medium">
                <tr>
                  <th
                    colSpan={3}
                    scope="col"
                    className="rounded-tr-xl bg-caspian-primary-500 relative p-2"
                  >
                    <input
                      onChange={(e) => {
                        setSearchByTitle(e.target.value);
                        setCurrentPage(1);
                      }}
                      type="search"
                      id="t1"
                      className={`${searchByTitle
                        ? "border border-caspian-surface/50 "
                        : "border border-caspian-transparent"
                        } text-[#FFF7CB] bg-caspian-transparent p-1.5 rounded-md outline-none  w-full focus:border focus:border-caspian-surface/50 pr-12 font-light peer cursor-pointer`}
                    />
                    <label htmlFor="t1"
                      className={`${searchByTitle ? "right-0 left-auto" : ""
                        } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 bg-caspian-primary-500 rounded-md border border-caspian-primary-500 z-50 whitespace-nowrap cursor-pointer font-light
                            peer-focus:left-auto peer-focus:right-0`}
                    >عنوان</label>
                  </th>
                  <th
                    colSpan={1}
                    scope="col"
                    className="bg-caspian-primary-500 relative p-2"
                  >
                    <input
                      onChange={(e) => {
                        setSearchCode(e.target.value);
                        setCurrentPage(1);
                      }}
                      type="search"
                      id="t2"
                      className={`${searchByCode
                        ? "border border-caspian-surface/50 "
                        : "border border-caspian-transparent"
                        } text-[#FFF7CB] bg-caspian-transparent p-1.5 rounded-md outline-none  w-full focus:border focus:border-caspian-surface/50 pr-12 font-light peer cursor-pointer`}
                    />
                    <label htmlFor="t2"
                      className={`${searchByCode ? "right-0 left-auto" : ""
                        } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 bg-caspian-primary-500 rounded-md border border-caspian-primary-500 z-50 whitespace-nowrap cursor-pointer font-light
                            peer-focus:left-auto peer-focus:right-0`}
                    >کد سند</label>
                  </th>
                  <th
                    colSpan={1}
                    scope="col"
                    className="bg-caspian-primary-500 p-2"
                  >
                    <div className="flex items-center gap-0">
                      <input
                        onChange={(e) => setSearchType(e.target.value)}
                        type="search"
                        placeholder="نوع مدرک"
                        disabled='disabled'
                        className="focus:shadow-[0_0_5px_#4683ad] focus:outline-caspian-secondary-500 rounded-md outline outline-1 outline-caspian-gray2 p-1.5 font-light text-sm w-full bg-caspian-primary-500 text-caspian-surface placeholder:text-caspian-surface"
                      />
                      <Filter />
                    </div>
                  </th>
                  <th
                    colSpan={3}
                    scope="col"
                    className="bg-caspian-primary-500 p-2 relative"
                  >
                    <input
                      onChange={(e) => setSearchOwnerParts(e.target.value)}
                      type="search"
                      id="t4"
                      className={`${searchByOwnerParts
                        ? "border border-caspian-surface/50 "
                        : "border border-caspian-transparent"
                        } text-[#FFF7CB] bg-caspian-transparent p-1.5 rounded-md outline-none  w-full focus:border focus:border-caspian-surface/50 pr-24 font-light peer cursor-pointer`}
                    />
                    <label htmlFor="t4"
                      className={`${searchByOwnerParts ? "right-5 left-full" : ""
                        } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 bg-caspian-primary-500 rounded-md border border-caspian-primary-500 z-50 whitespace-nowrap cursor-pointer font-light
                            peer-focus:left-full peer-focus:right-5`}
                    >تاریخ منسوخ</label>
                  </th>
                  <th
                    colSpan={4}
                    scope="col"
                    className="rounded-tl-xl bg-caspian-primary-500 p-2"
                  >
                    <div className="flex items-center gap-0">
                      <input
                        onChange={(e) => setSearchUsers(e.target.value)}
                        type="search"
                        placeholder="واحد بهره بردار"
                        disabled='disabled'
                        className="focus:shadow-[0_0_5px_#4683ad] focus:outline-caspian-secondary-500 rounded-md outline outline-1 outline-caspian-gray2 p-1.5 font-light text-sm
                        bg-caspian-primary-500 text-caspian-surface placeholder:text-caspian-surface"
                      />
                      <Filter2
                        setSearchByOperatingUnit={setSearchByOperatingUnit}
                        searchByOperatingUnit={searchByOperatingUnit}
                        data={data.map((d) => d.users)}
                      />
                    </div>
                  </th>
                </tr>
              </thead>

              {records.length > 0 ? (
                <tbody>
                  {records.map((d, i) => (
                    <SingleTableObsoleteDocuments
                      id={d.id}
                      key={i}
                      //عنوان
                      title={d.title}
                      //کد  سند
                      code={d.code.toString()}
                      //نوع مدرک
                      type={d.type}
                      //واحد متولی
                      ownerParts={d.ownerParts}
                      //واحد بهره بردار
                      users={d.users}
                    />
                  ))}
                </tbody>
              ) : (
                <tbody className="py-24">
                  <tr>
                    <td className="py-24">
                      <div className="flex items-center justify-center w-full py-10">
                        <div className="z-10 absolute left-1/2 -translate-x-1/2 mx-auto">
                          <div className="flex flex-col gap-2 items-center justify-center w-full">
                            <img
                              src="/assets/images/library/not-found.svg"
                              alt="مطلبی یافت نشد..."
                            />
                            <h2>مطلبی یافت نشد...</h2>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
          <>
            {records.length > 0 && (
              <div className="flex items-center justify-between px-1 py-2">
                <RowView
                  setRecordsPerPage={setRecordsPerPage}
                  setCurrentPage={setCurrentPage}
                />
                <Paginate
                  pageCount={npage}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
                <div className="text-sm flex items-center gap-4">
                  <span>نمایش {firstIndex + 1}</span>
                  <span>
                    تا {lastIndex - (recordsPerPage - records.length)}
                  </span>
                  <span>از کل {searchUsers.length}</span>
                </div>
              </div>
            )}
          </>
        </div>
      )}
    </div>
  );
};

export default TableListObsoleteDocuments;
