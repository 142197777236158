import React, { useContext, useEffect, useState } from "react";
import {
  PiCaretDownBold,
  PiMagnifyingGlassBold,
  PiXBold,
} from "react-icons/pi";
import API from "../../../../../../api/API";
import { AuthContext } from "../../../../../../context/auth/AuthContext";
import TimePickerOrder from "./TimePickerOrder";

const MeetingOrderForm = ({
  setRefresh,
  thisId,
  data,
  setData,
  fullData,
  members,
  setShowMeetingOrderForm,
  meetingProfileRelated,
}) => {
  const { token } = useContext(AuthContext);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [timeDuration, setTimeDuration] = useState("");
  const [presentor, setPresentor] = useState(null);
  const [inputRelated, setInputRelated] = useState(null);
  const [inputData, setInputData] = useState([]);

  useEffect(() => {
    setInputData(fullData?.inputMeetingProfileRelated);
  }, [fullData]);

  console.log(fullData);

  const orderHandle = async () => {
    if (
      title === "" ||
      description === "" ||
      timeDuration === "" ||
      presentor === null ||
      presentor === undefined ||
      inputRelated === null ||
      inputRelated === "null" ||
      inputRelated === undefined
    )
      return null;
    console.log({
      title: title,
      description: description,
      timeDuration: timeDuration,
      meetingAgendaRelated: +thisId,
      presentor: presentor?.id,
      InputRelated: +inputRelated,
    });
    API.post(
      "/api/v1/meeting/info/agenda/",
      {
        title: title,
        description: description,
        timeDuration: timeDuration,
        meetingAgendaRelated: +thisId,
        presentor: +presentor?.id,
        InputRelated: +inputRelated,
      },
      {
        headers: { Authorization: token },
      }
    )
      .then((res) => {
        console.log(res.data);
        setRefresh(1);
      })
      .catch((err) => {
        console.log(err);
      });
    await setShowMeetingOrderForm(false);
  };

  // const orderHandle = async () => {
  //   await setData([
  //     ...data,
  //     {
  //       title: title,
  //       description: description,
  //       timeDuration: timeDuration,
  //       meetingRelated: null,
  //       presentor: presentor,
  //       InputRelated: inputRelated,
  //     },
  //   ]);
  //   await setShowMeetingOrderForm(false);
  // };

  // ارائه دهنده
  const [showListPresentorMeeting, setShowListPresentorMeeting] =
    useState(false);
  const [presentorSelected, setPresentorSelected] = useState(null);
  const [searchByPresentor, setSearchByPresentor] = useState("");
  let searchPresentor = [];
  if (members?.length > 0) {
    searchPresentor = searchByPresentor
      ? members.filter((d) =>
          d.fullName
            .toLocaleLowerCase()
            .includes(searchByPresentor.toLocaleLowerCase())
        )
      : members;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full z-50">
      <div className="bg-caspian-surface rounded-md shadow-[0_0_5px_#00000050] p-3 w-[400px] flex flex-col gap-3">
        <div className="flex items-center justify-between p-2">
          <h2 className="text-sm font-bold">افزودن دستور جلسه</h2>
          <button onClick={() => setShowMeetingOrderForm(false)}>
            <PiXBold className={"text-caspian-gray1 text-sm"} />
          </button>
        </div>

        <div className="flex flex-col gap-3">
          <div className="w-full flex flex-col gap-1.5">
            <label className="text-xs text-caspian-gray">عنوان</label>
            <input
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              name="title"
              type="text"
              placeholder="وارد کنید"
              className="outline-none outline-caspian-gray1 p-1.5 text-xs rounded-md outline-1"
            />
          </div>

          <div className="flex flex-col gap-1.5 w-full">
            <label className="text-xs text-caspian-gray">ورودی</label>
            <select
              onChange={(e) => setInputRelated(e.target.value)}
              name="inputRelated"
              // value={"null"}
              className={`text-xs outline outline-1 outline-caspian-gray1 p-1.5 rounded-md bg-caspian-transparent cursor-pointer`}
            >
              <option
                value={"null"}
                className="text-caspian-on_primary"
              ></option>
              {inputData?.map((d, i) => (
                <option
                  className="text-caspian-on_primary"
                  key={i}
                  value={d?.id}
                >
                  {d?.title}
                </option>
              ))}
            </select>
          </div>

          <div className="flex items-center justify-between gap-2 w-full">
            {/* ارائه دهنده */}
            <div className="flex flex-col gap-1.5 w-1/2 relative">
              <label className="text-xs text-caspian-gray">ارائه دهنده</label>
              <div
                onClick={() => {
                  setShowListPresentorMeeting(!showListPresentorMeeting);
                }}
                className="text-xs outline outline-1 outline-caspian-gray1 p-2 rounded-md bg-caspian-transparent cursor-pointer flex items-center justify-between"
              >
                {presentorSelected !== null ? (
                  <span className="text-xs">{presentorSelected?.fullName}</span>
                ) : (
                  <span className="text-caspian-gray1 text-xs">
                    انتخاب کنید
                  </span>
                )}
                <PiCaretDownBold className="text-caspian-gray" />
              </div>
              {showListPresentorMeeting && (
                <div className="rounded-md shadow-[0_0_5px_#00000050] w-full flex flex-col gap-3 py-3 absolute left-0 right-0 top-[52px] bg-caspian-surface z-50">
                  <div className="relative w-[92%] mx-auto">
                    <input
                      onChange={(e) => {
                        setSearchByPresentor(e.target.value);
                      }}
                      type="search"
                      className="outline-none outline-caspian-gray1 p-1 text-xs rounded-md outline-1 w-full placeholder:text-xs placeholder:text-caspian-gray pr-6"
                      placeholder="جستجو"
                    />
                    <button className="absolute top-1/2 -translate-y-1/2 right-1">
                      <PiMagnifyingGlassBold className="text-caspian-gray text-" />
                    </button>
                  </div>
                  <div className="flex flex-col gap-2 w-full max-h-[200px] overflow-y-scroll">
                    {searchPresentor?.length > 0 &&
                      searchPresentor.map((m, i) => (
                        <div
                          key={i}
                          onClick={() => {
                            setPresentorSelected(m);
                            setPresentor(m);
                            setShowListPresentorMeeting(false);
                            setSearchByPresentor("");
                          }}
                          className="flex items-center justify-start gap-2 text-xs hover:bg-caspian-secondary-100 py-1 px-2 w-full cursor-pointer duration-300"
                        >
                          <span className="">{m?.fullName}</span>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>

            {/* <div className="w-1/2 flex flex-col gap-1.5">
              <label className="text-xs text-caspian-gray">ارائه دهنده</label>
              <input
                onChange={(e) => {
                  setPresentor(e.target.value);
                }}
                name="presentor"
                type="text"
                placeholder="ارائه دهنده"
                className="outline-none outline-caspian-gray1 p-1.5 text-xs rounded-md outline-1"
              />
            </div> */}
            <div className="w-1/2 flex flex-col gap-1.5">
              <label className="text-xs text-caspian-gray">
                مدت زمان ارائه
              </label>
              {/* <TimePickerOrder setTimeDuration={setTimeDuration} /> */}
              <div className="flex items-center justify-between rounded-md outline-1 outline outline-caspian-gray1">
                <input
                  onChange={(e) => {
                    setTimeDuration(e.target.value);
                  }}
                  name="timeDuration"
                  type="number"
                  placeholder="به دقیقه وارد کنید"
                  className="outline-none text-xs p-2 w-[70%]"
                />
                <span className="text-xs bg-caspian-gray2/50 p-2 flex items-center justify-center w-[30%] text-caspian-gray4">
                  دقیقه
                </span>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col gap-1.5">
            <label className="text-xs text-caspian-gray">توضیحات</label>
            <textarea
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              rows={6}
              className="resize-none p-2 rounded-md outline-none outline-1 outline-caspian-gray2 text-xs"
            />
          </div>
        </div>
        <div className="flex items-center gap-2 mt-2">
          <button
            onClick={() => {
              orderHandle();
            }}
            className="outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs py-1.5 px-6 rounded-md"
          >
            افزودن
          </button>
          <button className="text-xs text-[#FF5B5B] mr-4">لغو</button>
        </div>
      </div>
    </div>
  );
};

export default MeetingOrderForm;
