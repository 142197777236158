import React from "react";
//<h2 className="text-xs"></h2>
const DateTime = ({ fullData }) => {
  console.log(fullData);
  return (
    <div className="w-full flex flex-col gap-0 border border-caspian-gray2 rounded-md">
      <div className="flex items-center gap-0">
        <div className="p-3 flex items-center gap-2 basis-1/3 w-full">
          <h2 className="text-xs">تاریخ شروع جلسه:</h2>
          <span className="text-caspian-gray text-xs">
            {new Date(fullData?.meetingDate)?.toLocaleDateString("fa-IR")}
          </span>
        </div>
        <div className="border-x border-x-caspian-gray2 p-3 flex items-center gap-2 basis-1/3 w-full">
          <h2 className="text-xs">ساعت شروع جلسه:</h2>
          <span className="text-caspian-gray text-xs">
            {fullData?.meetingTimeStart}
          </span>
        </div>
        <div className="p-3 flex items-center gap-2 basis-1/3 w-full">
          <h2 className="text-xs">مدت زمان:</h2>
          <span className="text-caspian-gray text-xs">
            {fullData?.meetingTimeEnd}
          </span>
        </div>
      </div>
      <div className="flex items-center gap-0 border-t border-t-caspian-gray2">
        <div className="p-3 flex items-center gap-2 basis-1/3 w-full">
          <h2 className="text-xs">جلسه بصورت:</h2>
          <span className="text-caspian-gray text-xs">
            {fullData?.presentationType === "inperson" ? "حضوری" : "آنلاین"}
          </span>
        </div>
        <div className="border-r border-r-caspian-gray2 p-3 flex items-center gap-2 basis-2/3 w-full">
          <h2 className="text-xs">
            {fullData?.presentationType === "inperson" ? "آدرس" : "لینک"}
          </h2>
          <span className="text-caspian-gray text-xs">
            {fullData?.presentationType === "inperson" &&
            fullData?.meetingRoomOther
              ? fullData?.meetingRoomOther
              : fullData?.presentationType === "inperson" &&
                fullData?.meetingRoomRelated
              ? fullData?.meetingRoomRelated?.floorRelated?.buildingRelated
                  ?.address +
                " / " +
                fullData?.meetingRoomRelated?.floorRelated?.title +
                " / " +
                fullData?.meetingRoomRelated?.title
              : fullData?.presentationType === "Online" &&
                fullData?.meetingOnlineLink}
          </span>
        </div>
      </div>
    </div>
  );
};

export default DateTime;
