import React, { useContext, useRef, useState } from "react";
import {
  PiCaretDownBold,
  PiCaretLeftBold,
  PiMagnifyingGlassBold,
  PiX,
} from "react-icons/pi";
import DateTimePicker from "./DateTimePicker";
import API from "../../../../../api/API";
import { AuthContext } from "../../../../../context/auth/AuthContext";

const FormApprovals = ({
  members,
  outputList,
  idMeeting,
  thisApproval,
  setShowApprovalForm,
  setRefreshFullData,
}) => {
  const { token } = useContext(AuthContext);
  console.log(outputList);
  console.log(thisApproval);
  const checkedRef = useRef();
  const [isAccept, setIsAccept] = useState(false);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [date, setDate] = useState(null);
  const [runner, setRunner] = useState(null);
  const [showListRunnerMeeting, setShowListRunnerMeeting] = useState(false);

  const [runnerSelected, setRunnerSelected] = useState(null);

  const [searchByRunner, setSearchByRunner] = useState("");
  let searchRunner = [];
  if (members?.length > 0) {
    searchRunner = searchByRunner
      ? members.filter(
          (d) =>
            (d.placementPositionRelated?.title !== undefined &&
              d.placementPositionRelated?.title
                .toLocaleLowerCase()
                .includes(searchByRunner.toLocaleLowerCase())) ||
            (d.placementProfileRelated !== undefined &&
              (
                d.placementProfileRelated?.firstName +
                " " +
                d.placementProfileRelated?.lastName
              )
                .toLocaleLowerCase()
                .includes(searchByRunner.toLocaleLowerCase()))
        )
      : members;
  }

  const [follower, setFollower] = useState(null);
  const [showListFollowerMeeting, setShowListFollowerMeeting] = useState(false);
  const [followerSelected, setFollowerSelected] = useState(null);
  const [searchByFollower, setSearchByFollower] = useState("");
  let searchFollower = [];
  if (members?.length > 0) {
    searchFollower = searchByFollower
      ? members.filter(
          (d) =>
            (d.placementPositionRelated?.title !== undefined &&
              d.placementPositionRelated?.title
                .toLocaleLowerCase()
                .includes(searchByFollower.toLocaleLowerCase())) ||
            (d.placementProfileRelated !== undefined &&
              (
                d.placementProfileRelated?.firstName +
                " " +
                d.placementProfileRelated?.lastName
              )
                .toLocaleLowerCase()
                .includes(searchByFollower.toLocaleLowerCase()))
        )
      : members;
  }

  const [output, setOutput] = useState(null);
  const [showListOutputMeeting, setShowListOutputMeeting] = useState(false);
  const [outputSelected, setOutputSelected] = useState(null);
  const [searchByOutput, setSearchByOutput] = useState("");
  let searchOutput = [];
  if (outputList?.length > 0) {
    searchOutput = searchByOutput
      ? outputList.filter((d) =>
          d.fullName
            .toLocaleLowerCase()
            .includes(searchByOutput.toLocaleLowerCase())
        )
      : outputList;
  }

  const approvalsHandler = async () => {
    if (
      title === "" ||
      description === "" ||
      date === null ||
      runner === null ||
      follower === null
    )
      return null;
    let data = {
      title: title,
      description: description,
      deadLine: date,
      is_planed_neaded: isAccept,
      meetingAprovalRelated: +idMeeting,
      responsibleExecutive: runner,
      responsibleFollow: follower,
      outputRelated: output.id,
      // meetingAprovalPlaneRelated: "",
      // meetingAprovalReportRelated: "",
    };
    // console.log(data);

    await API.post(`/api/v1/meeting/info/aproval/`, data, {
      headers: { Authorization: token },
    })
      .then((res) => {
        console.log(res.data);
        setShowApprovalForm(false);
        setRefreshFullData(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(searchRunner);
  console.log(runnerSelected);

  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full z-50">
      <div className="flex flex-col gap-4 bg-caspian-surface p-4 rounded-md shadow-[0_0_10px_#00000050] w-2/3">
        <div className="flex items-center justify-between">
          <h2 className="text-sm">افزودن مصوبه</h2>
          <button onClick={() => setShowApprovalForm(false)}>
            <PiX />
          </button>
        </div>
        <form className="flex flex-col gap-4">
          {/* عنوان */}
          <div className="w-1/2 flex flex-col gap-1.5">
            <label className="text-xs text-caspian-gray">عنوان مصوبه</label>
            <input
              onChange={(e) => setTitle(e.target.value)}
              name="title"
              type="text"
              placeholder="عنوان مصوبه را وارد کنید"
              className="outline-none outline-caspian-gray1 p-1.5 text-xs rounded-md outline-1"
            />
          </div>
          <div className="flex items-center gap-2">
            {/* مسئول اجرا */}
            <div className="flex flex-col gap-1.5 w-1/2 relative">
              <label className="text-xs text-caspian-gray">مسئول اجرا</label>
              <div
                onClick={() => {
                  setShowListRunnerMeeting(!showListRunnerMeeting);
                }}
                className="text-xs outline outline-1 outline-caspian-gray1 p-2 rounded-md bg-caspian-transparent cursor-pointer flex items-center justify-between"
              >
                {runnerSelected !== null ? (
                  <span className="text-xs">
                    {runnerSelected?.placementProfileRelated?.firstName +
                      " " +
                      runnerSelected?.placementProfileRelated?.lastName}
                  </span>
                ) : (
                  <span className="text-caspian-gray1 text-xs">
                    انتخاب کنید
                  </span>
                )}
                <PiCaretDownBold className="text-caspian-gray" />
              </div>
              {showListRunnerMeeting && (
                <div className="rounded-md shadow-[0_0_5px_#00000050] w-full flex flex-col gap-3 py-3 absolute left-0 right-0 top-[52px] bg-caspian-surface z-50">
                  <div className="relative w-[92%] mx-auto">
                    <input
                      onChange={(e) => {
                        setSearchByRunner(e.target.value);
                      }}
                      type="search"
                      className="outline-none outline-caspian-gray1 p-1 text-xs rounded-md outline-1 w-full placeholder:text-xs placeholder:text-caspian-gray pr-6"
                      placeholder="جستجو"
                    />
                    <button className="absolute top-1/2 -translate-y-1/2 right-1">
                      <PiMagnifyingGlassBold className="text-caspian-gray text-" />
                    </button>
                  </div>
                  <div className="flex flex-col gap-2 w-full max-h-[200px] overflow-y-scroll">
                    {searchRunner?.length > 0 &&
                      searchRunner.map((m, i) => (
                        <div
                          key={i}
                          onClick={() => {
                            setRunnerSelected(m);
                            setRunner(m?.placementProfileRelated?.id);
                            setShowListRunnerMeeting(false);
                            setSearchByRunner("");
                          }}
                          className="flex items-center justify-start gap-2 text-xs hover:bg-caspian-secondary-100 py-1 px-2 w-full cursor-pointer duration-300"
                        >
                          <span className="">
                            {m?.placementProfileRelated?.firstName +
                              " " +
                              m?.placementProfileRelated?.lastName}
                          </span>
                          <span className="text-caspian-gray">
                            {m?.placementPositionRelated?.title}
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
            {/* پیگیری کننده */}
            <div className="flex flex-col gap-1.5 w-1/2 relative">
              <label className="text-xs text-caspian-gray">پیگیری کننده</label>
              <div
                onClick={() => {
                  setShowListFollowerMeeting(!showListFollowerMeeting);
                }}
                className="text-xs outline outline-1 outline-caspian-gray1 p-2 rounded-md bg-caspian-transparent cursor-pointer flex items-center justify-between"
              >
                {followerSelected !== null ? (
                  <span className="text-xs">
                    {followerSelected?.placementProfileRelated?.firstName +
                      " " +
                      followerSelected?.placementProfileRelated?.lastName}
                  </span>
                ) : (
                  <span className="text-caspian-gray1 text-xs">
                    انتخاب کنید
                  </span>
                )}
                <PiCaretDownBold className="text-caspian-gray" />
              </div>
              {showListFollowerMeeting && (
                <div className="rounded-md shadow-[0_0_5px_#00000050] w-full flex flex-col gap-3 py-3 absolute left-0 right-0 top-[52px] bg-caspian-surface z-50">
                  <div className="relative w-[92%] mx-auto">
                    <input
                      onChange={(e) => {
                        setSearchByFollower(e.target.value);
                      }}
                      type="search"
                      className="outline-none outline-caspian-gray1 p-1 text-xs rounded-md outline-1 w-full placeholder:text-xs placeholder:text-caspian-gray pr-6"
                      placeholder="جستجو"
                    />
                    <button className="absolute top-1/2 -translate-y-1/2 right-1">
                      <PiMagnifyingGlassBold className="text-caspian-gray text-" />
                    </button>
                  </div>
                  <div className="flex flex-col gap-2 w-full max-h-[200px] overflow-y-scroll">
                    {searchFollower?.length > 0 &&
                      searchFollower.map((m, i) => (
                        <div
                          key={i}
                          onClick={() => {
                            setFollowerSelected(m);
                            setFollower(m?.placementProfileRelated?.id);
                            setShowListFollowerMeeting(false);
                            setSearchByFollower("");
                          }}
                          className="flex items-center justify-start gap-2 text-xs hover:bg-caspian-secondary-100 py-1 px-2 w-full cursor-pointer duration-300"
                        >
                          <span className="">
                            {m?.placementProfileRelated?.firstName +
                              " " +
                              m?.placementProfileRelated?.lastName}
                          </span>
                          <span className="text-caspian-gray">
                            {m?.placementPositionRelated?.title}
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center gap-2">
            {/* خروجی ها */}
            <div className="flex flex-col gap-1.5 w-1/2 relative">
              <label className="text-xs text-caspian-gray">خروجی</label>
              <div
                onClick={() => {
                  setShowListOutputMeeting(!showListOutputMeeting);
                }}
                className="text-xs outline outline-1 outline-caspian-gray1 p-2 rounded-md bg-caspian-transparent cursor-pointer flex items-center justify-between"
              >
                {outputSelected !== null ? (
                  <span className="text-xs">{outputSelected?.title}</span>
                ) : (
                  <span className="text-caspian-gray1 text-xs">
                    انتخاب کنید
                  </span>
                )}
                <PiCaretDownBold className="text-caspian-gray" />
              </div>
              {showListOutputMeeting && (
                <div className="rounded-md shadow-[0_0_5px_#00000050] w-full flex flex-col gap-3 py-3 absolute left-0 right-0 top-[52px] bg-caspian-surface z-50">
                  <div className="relative w-[92%] mx-auto">
                    <input
                      onChange={(e) => {
                        setSearchByOutput(e.target.value);
                      }}
                      type="search"
                      className="outline-none outline-caspian-gray1 p-1 text-xs rounded-md outline-1 w-full placeholder:text-xs placeholder:text-caspian-gray pr-6"
                      placeholder="جستجو"
                    />
                    <button className="absolute top-1/2 -translate-y-1/2 right-1">
                      <PiMagnifyingGlassBold className="text-caspian-gray text-" />
                    </button>
                  </div>
                  <div className="flex flex-col gap-2 w-full max-h-[200px] overflow-y-scroll">
                    {searchOutput?.length > 0 &&
                      searchOutput.map((m, i) => (
                        <div
                          key={i}
                          onClick={() => {
                            setOutputSelected(m);
                            setOutput(m);
                            setShowListOutputMeeting(false);
                            setSearchByOutput("");
                          }}
                          className="flex items-center justify-start gap-2 text-xs hover:bg-caspian-secondary-100 py-1 px-2 w-full cursor-pointer duration-300"
                        >
                          <span className="">{m?.title}</span>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
            {/* مهلت */}
            <div className="flex flex-col w-1/2 gap-1.5">
              <label className="text-xs text-caspian-gray">مهلت</label>
              <DateTimePicker setDate={setDate} />
            </div>
          </div>
          {/* شرح مصوبه */}
          <div className="w-full flex flex-col gap-1.5">
            <label className="text-xs text-caspian-gray">شرح مصوبه</label>
            <textarea
              placeholder="وارد کنید"
              onChange={(e) => setDescription(e.target.value)}
              name="description"
              rows={5}
              className="resize-none p-2 rounded-md outline-none outline-1 outline-caspian-gray2 text-xs"
            />
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-1">
              <label
                htmlFor="accept"
                className="text-xs cursor-pointer font-bold"
              >
                دارای اقدام اجرایی می باشد
              </label>
              <input
                ref={checkedRef}
                onChange={(e) => setIsAccept(!isAccept)}
                id="accept"
                type="checkbox"
                className=" cursor-pointer accent-caspian-secondary-400"
              />
            </div>
            <div className="flex items-center gap-4">
              <button
                // disabled={isAccept ? false : true}
                onClick={async (e) => {
                  e.preventDefault();
                  await approvalsHandler();
                }}
                className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
              >
                ثبت
              </button>
              {/* <button
                onClick={async (e) => {
                  e.preventDefault();
                  console.log("back");
                }}
                className="min-w-[6rem] outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs p-1.5 rounded-md flex items-center justify-center gap-1"
              >
                بازگشت
                <PiCaretLeftBold />
              </button> */}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShowApprovalForm(false);
                }}
                className="text-xs text-[#FF5B5B]"
              >
                لغو
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormApprovals;
