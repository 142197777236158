import React, { useEffect, useState } from "react";
import DocumentsRecordsNavbar from "../DocumentsRecordsNavbar";
import { useAppContext } from "../../../context/app-context/AppContext";
import TableListRecords from "./TableListRecords";
import API from "../../../api/API";
import SearchInput from "./SearchInput";

const MainListRecords = ({ showMenu }) => {
  const { setBradCrump1, setBradCrump2, setBradCrump3 } = useAppContext();
  useEffect(() => {
    setBradCrump1("مدارک و سوابق");
    setBradCrump2("لیست سوابق");
    setBradCrump3("");
  }, [setBradCrump1, setBradCrump2, setBradCrump3]);

  const [loading, setLoading] = useState(false);
  const [fullData, setFullData] = useState([-1]);
  useEffect(() => {
    setLoading(true);
    API.get("/api/v1/deocument/record/")
      .then((d) => {
        setFullData(d.data);
      })
      .catch((err) => {
        console.log("error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  console.log(fullData)

  return (
    <div
      className={
        showMenu
          ? `pl-[50px] mr-auto w-full md:w-[75%] lg:w-[80%] xl:w-[85%] 2xl:w-[85%] bg-caspian-gray3 p-1 overflow-y-scroll`
          : `pl-[50px] mr-[54px] w-full bg-caspian-gray3 p-1 overflow-y-scroll`
      }
    >
      <DocumentsRecordsNavbar buttonActive="list-records" />

      <div className="flex flex-col gap-6 py-10 px-8 xl:px-16">
        <div className="flex items-center gap-2">
          <SearchInput />
          {/* <button className="flex items-center gap-1 rounded-md bg-caspian-secondary-500 text-caspian-surface text-sm p-2">
            <PiPlusCircle className="text-2xl" />
            <span>ثبت درخواست</span>
          </button> */}
        </div>
        <TableListRecords data={fullData} />
      </div>
    </div>
  );
};

export default MainListRecords;
