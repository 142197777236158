import React, { useContext, useEffect, useState } from "react";
import {
  PiCaretDownBold,
  PiMagnifyingGlassBold,
  PiPlusBold,
} from "react-icons/pi";
import ListMembers from "./ListMembers";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import API from "../../../../../api/API";
import { toast } from "react-toastify";

const Roles = ({
  setActiveTab,
  generalId,
  setShowEditMeetingForm,
  setRefresh,
  dataEdit,
  setDataRefresh,
  editTitle,
}) => {
  console.log(dataEdit)
  const [showListHeadMeeting, setShowListHeadMeeting] = useState(false);
  const [showListDeputyChairman, setShowListDeputyChairman] = useState(false);
  const [showListSecretaryMeeting, setShowListSecretaryMeeting] =
    useState(false);

  const [showMembers, setShowMembers] = useState(false);

  const { token } = useContext(AuthContext);

  const [memberSelected, setMemberSelected] = useState(dataEdit?.members);

  const [members, setMembers] = useState([-1]);
  const [membersFinalSelected, setMembersFinalSelected] = useState(
    dataEdit?.members
  );
  let url = "/api/v1/organization/role/placement/";
  useEffect(() => {
    const getMembers = () => {
      API.get(url, {
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          setMembers(res.data);
          // console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getMembers();
  }, [token, url]);

  // رئیس جلسه
  const [headSelected, setHeadSelected] = useState(dataEdit.chairman);
  const [searchByHead, setSearchByHead] = useState("");
  let searchHead = [];
  if (membersFinalSelected?.length > 0) {
    searchHead = searchByHead
      ? membersFinalSelected.filter(
          (d) =>
            (d.placementPositionRelated?.title !== undefined &&
              d.placementPositionRelated?.title
                .toLocaleLowerCase()
                .includes(searchByHead.toLocaleLowerCase())) ||
            (d.placementProfileRelated !== undefined &&
              (
                d.placementProfileRelated?.firstName +
                " " +
                d.placementProfileRelated?.lastName
              )
                .toLocaleLowerCase()
                .includes(searchByHead.toLocaleLowerCase()))
        )
      : membersFinalSelected;
  }

  // جانشین ریئس
  const [deputyChairmanSelected, setDeputyChairmanSelected] = useState(
    dataEdit.viceChairman
  );
  const [searchByDeputyChairman, setSearchByDeputyChairman] = useState("");
  let searchDeputyChairman = [];
  if (membersFinalSelected?.length > 0) {
    searchDeputyChairman = searchByDeputyChairman
      ? membersFinalSelected.filter(
          (d) =>
            (d.placementPositionRelated?.title !== undefined &&
              d.placementPositionRelated?.title
                .toLocaleLowerCase()
                .includes(searchByDeputyChairman.toLocaleLowerCase())) ||
            (d.placementProfileRelated !== undefined &&
              (
                d.placementProfileRelated?.firstName +
                " " +
                d.placementProfileRelated?.lastName
              )
                .toLocaleLowerCase()
                .includes(searchByDeputyChairman.toLocaleLowerCase()))
        )
      : membersFinalSelected;
  }

  //  دبیر جلسه
  const [secretarySelected, setSecretarySelected] = useState(
    dataEdit.secretary
  );
  const [searchBySecretary, setSearchBySecretary] = useState("");
  let searchSecretary = [];
  if (membersFinalSelected?.length > 0) {
    searchSecretary = searchBySecretary
      ? membersFinalSelected.filter(
          (d) =>
            (d.placementPositionRelated?.title !== undefined &&
              d.placementPositionRelated?.title
                .toLocaleLowerCase()
                .includes(searchBySecretary.toLocaleLowerCase())) ||
            (d.placementProfileRelated !== undefined &&
              (
                d.placementProfileRelated?.firstName +
                " " +
                d.placementProfileRelated?.lastName
              )
                .toLocaleLowerCase()
                .includes(searchBySecretary.toLocaleLowerCase()))
        )
      : membersFinalSelected;
  }

  const dataHandler = async () => {
    if (
      membersFinalSelected?.length < 1 ||
      headSelected === null ||
      deputyChairmanSelected === null ||
      secretarySelected === null
    )
      return null;
    await API.patch(
      `/api/v1/meeting/profile/detail/${generalId}/`,
      {
        members: membersFinalSelected.map(
          (member) => +member?.id
        ),
        chairman: +headSelected.id,
        viceChairman: +deputyChairmanSelected.id,
        secretary: +secretarySelected.id,
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataHandlerSubmit = async () => {
    // e.preventDefault();
    await API.post(
      `/api/v1/meeting/profile/detail/${generalId}/status/`,
      {
        status: "roles",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await dataHandler();
        await toast.success(`${editTitle} با موفقیت ویرایش و ثبت شد.`, {
          className: "toast-success",
          bodyClassName: "toast-success",
        });
        await setShowEditMeetingForm(false);
        await setRefresh(1);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataHandlerNext = async () => {
    // e.preventDefault();
    await API.post(
      `/api/v1/meeting/profile/detail/${generalId}/status/`,
      {
        status: "io",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await dataHandler();
        await setDataRefresh(1);
        await setActiveTab("inputOutput");
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataHandlerBack = async () => {
    await API.post(
      `/api/v1/meeting/profile/detail/${generalId}/status/`,
      {
        status: "refrence",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        // await dataHandler();
        await setActiveTab("refrence");
        await setDataRefresh(1);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (
      membersFinalSelected?.length < 1 ||
      headSelected === null ||
      deputyChairmanSelected === null ||
      secretarySelected === null
    ) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [
    membersFinalSelected,
    headSelected,
    deputyChairmanSelected,
    secretarySelected,
  ]);

  return (
    <div className="min-h-[270px] flex flex-col justify-between">
      <div className="flex flex-col gap-3 h-full">
        {showMembers && (
          <ListMembers
            setMembersFinalSelected={setMembersFinalSelected}
            membersFinalSelected={membersFinalSelected}
            members={members}
            setShowMembers={setShowMembers}
            memberSelected={memberSelected}
            setMemberSelected={setMemberSelected}
            setHeadSelected={setHeadSelected}
            setDeputyChairmanSelected={setDeputyChairmanSelected}
            setSecretarySelected={setSecretarySelected}
          />
        )}
        <div className="grid grid-cols-2 gap-3">
          <div className="flex flex-col gap-1.5 w-full relative">
            <label className="text-xs text-caspian-gray">افزودن اعضا</label>
            <div className="flex items-center justify-center border border-caspian-secondary-500 rounded-md relative p-1.5">
              <span className="text-xs font-medium">
                {membersFinalSelected?.length > 0
                  ? membersFinalSelected?.length + " عضو افزوده شد"
                  : "عضوی وجود ندارد"}
              </span>
              <button
                onClick={() => {
                  setShowMembers(true);
                  setShowListHeadMeeting(false);
                  setShowListDeputyChairman(false);
                  setShowListSecretaryMeeting(false);
                }}
                className="text-xs text-caspian-secondary-500 absolute-caspian-secondary-500 absolute right-2 top-1/2 -translate-y-1/2 flex items-center gap-1"
              >
                <PiPlusBold />
                <span>افزودن</span>
              </button>
            </div>
          </div>

          {/* رئیس جلسه */}
          <div className="flex flex-col gap-1.5 w-full relative">
            <label className="text-xs text-caspian-gray">رئیس جلسه</label>
            <div
              onClick={() => {
                setShowListHeadMeeting(!showListHeadMeeting);
                setShowListDeputyChairman(false);
                setShowListSecretaryMeeting(false);
              }}
              className="text-xs outline outline-1 outline-caspian-gray1 p-1.5 rounded-md bg-caspian-transparent cursor-pointer flex items-center justify-between"
            >
              {membersFinalSelected?.length > 0 && headSelected !== null ? (
                <span className="text-xs">
                  {headSelected?.placementProfileRelated?.firstName +
                    " " +
                    headSelected?.placementProfileRelated?.lastName}
                </span>
              ) : (
                <span className="text-caspian-gray1 text-xs">انتخاب کنید</span>
              )}
              <PiCaretDownBold className="text-caspian-gray" />
            </div>
            {showListHeadMeeting && (
              <div className="rounded-md shadow-[0_0_5px_#00000050] w-full flex flex-col gap-3 py-3 absolute left-0 right-0 top-[52px] bg-caspian-surface z-50">
                <div className="relative w-[92%] mx-auto">
                  <input
                    onChange={(e) => {
                      setSearchByHead(e.target.value);
                    }}
                    type="search"
                    className="outline-none outline-caspian-gray1 p-1 text-xs rounded-md outline-1 w-full placeholder:text-xs placeholder:text-caspian-gray pr-6"
                    placeholder="جستجو"
                  />
                  <button className="absolute top-1/2 -translate-y-1/2 right-1">
                    <PiMagnifyingGlassBold className="text-caspian-gray text-" />
                  </button>
                </div>
                <div className="flex flex-col gap-2 w-full max-h-[200px] overflow-y-scroll">
                  {searchHead?.length > 0 &&
                    searchHead.map((m, i) => (
                      <div
                        key={i}
                        onClick={() => {
                          setHeadSelected(m);
                          setShowListHeadMeeting(false);
                          setSearchByHead("");
                        }}
                        className="flex items-center justify-start gap-2 text-xs hover:bg-caspian-secondary-100 py-1 px-2 w-full cursor-pointer duration-300"
                      >
                        <span className="basis-[40%]">
                          {m?.placementProfileRelated?.firstName +
                            " " +
                            m?.placementProfileRelated?.lastName}
                        </span>
                        <span className="basis-[60%]">
                          {m?.placementPositionRelated?.title}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
          {/* جانشین رئیس */}
          <div className="flex flex-col gap-1.5 w-full relative">
            <label className="text-xs text-caspian-gray">
              جانشین رئیس(اختیاری)
            </label>
            <div
              onClick={() => {
                setShowListHeadMeeting(false);
                setShowListDeputyChairman(!showListDeputyChairman);
                setShowListSecretaryMeeting(false);
              }}
              className="text-xs outline outline-1 outline-caspian-gray1 p-1.5 rounded-md bg-caspian-transparent cursor-pointer flex items-center justify-between bg-caspian-surface"
            >
              {membersFinalSelected?.length > 0 &&
              deputyChairmanSelected !== null ? (
                <span className="text-xs">
                  {deputyChairmanSelected?.placementProfileRelated?.firstName +
                    " " +
                    deputyChairmanSelected?.placementProfileRelated?.lastName}
                </span>
              ) : (
                <span className="text-caspian-gray1 text-xs">انتخاب کنید</span>
              )}
              <PiCaretDownBold className="text-caspian-gray" />
            </div>
            {showListDeputyChairman && (
              <div className="rounded-md shadow-[0_0_5px_#00000050] w-full flex flex-col gap-3 py-3 absolute left-0 right-0 top-[52px] bg-caspian-surface z-50">
                <div className="relative w-[92%] mx-auto">
                  <input
                    onChange={(e) => {
                      setSearchByDeputyChairman(e.target.value);
                    }}
                    type="search"
                    className="outline-none outline-caspian-gray1 p-1 text-xs rounded-md outline-1 w-full placeholder:text-xs placeholder:text-caspian-gray pr-6"
                    placeholder="جستجو"
                  />
                  <button className="absolute top-1/2 -translate-y-1/2 right-1">
                    <PiMagnifyingGlassBold className="text-caspian-gray text-" />
                  </button>
                </div>
                <div className="flex flex-col gap-2 w-full max-h-[200px] overflow-y-scroll">
                  {searchDeputyChairman?.length > 0 &&
                    searchDeputyChairman.map((m, i) => (
                      <div
                        key={i}
                        onClick={() => {
                          setDeputyChairmanSelected(m);
                          setShowListDeputyChairman(false);
                          setSearchByDeputyChairman("");
                        }}
                        className="flex items-center justify-start gap-2 text-xs hover:bg-caspian-secondary-100 py-1 px-2 w-full cursor-pointer duration-300"
                      >
                        <span className="basis-[40%]">
                          {m?.placementProfileRelated?.firstName +
                            " " +
                            m?.placementProfileRelated?.lastName}
                        </span>
                        <span className="basis-[60%]">
                          {m?.placementPositionRelated?.title}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
          {/* دبیر جلسه رئیس */}
          <div className="flex flex-col gap-1.5 w-full relative">
            <label className="text-xs text-caspian-gray">دبیر جلسه</label>
            <div
              onClick={() => {
                setShowListHeadMeeting(false);
                setShowListDeputyChairman(false);
                setShowListSecretaryMeeting(!showListSecretaryMeeting);
              }}
              className="text-xs outline outline-1 outline-caspian-gray1 p-1.5 rounded-md bg-caspian-transparent cursor-pointer flex items-center justify-between bg-caspian-surface"
            >
              {membersFinalSelected?.length > 0 &&
              secretarySelected !== null ? (
                <span className="text-xs">
                  {secretarySelected?.placementProfileRelated?.firstName +
                    " " +
                    secretarySelected?.placementProfileRelated?.lastName}
                </span>
              ) : (
                <span className="text-caspian-gray1 text-xs">انتخاب کنید</span>
              )}
              <PiCaretDownBold className="text-caspian-gray" />
            </div>
            {showListSecretaryMeeting && (
              <div className="rounded-md shadow-[0_0_5px_#00000050] w-full flex flex-col gap-3 py-3 absolute left-0 right-0 top-[52px] bg-caspian-surface z-50">
                <div className="relative w-[92%] mx-auto">
                  <input
                    onChange={(e) => {
                      setSearchBySecretary(e.target.value);
                    }}
                    type="search"
                    className="outline-none outline-caspian-gray1 p-1 text-xs rounded-md outline-1 w-full placeholder:text-xs placeholder:text-caspian-gray pr-6"
                    placeholder="جستجو"
                  />
                  <button className="absolute top-1/2 -translate-y-1/2 right-1">
                    <PiMagnifyingGlassBold className="text-caspian-gray text-" />
                  </button>
                </div>
                <div className="flex flex-col gap-2 w-full max-h-[200px] overflow-y-scroll">
                  {searchSecretary?.length > 0 &&
                    searchSecretary.map((m, i) => (
                      <div
                        key={i}
                        onClick={() => {
                          setSecretarySelected(m);
                          setShowListSecretaryMeeting(false);
                          setSearchBySecretary("");
                        }}
                        className="flex items-center justify-start gap-2 text-xs hover:bg-caspian-secondary-100 py-1 px-2 w-full cursor-pointer duration-300"
                      >
                        <span className="basis-[40%]">
                          {m?.placementProfileRelated?.firstName +
                            " " +
                            m?.placementProfileRelated?.lastName}
                        </span>
                        <span className="basis-[60%]">
                          {m?.placementPositionRelated?.title}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <button
          onClick={async () => {
            await dataHandlerBack();
          }}
          className="min-w-[6rem] outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs p-1.5 rounded-md"
        >
          مرحله قبل
        </button>
        <button
          onClick={async () => {
            await dataHandlerNext();
          }}
          disabled={disableButton}
          className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
        >
          مرحله بعد
        </button>
        <button
          disabled={disableButton}
          onClick={async () => {
            await dataHandlerSubmit();
          }}
          className={`disabled:cursor-not-allowed disabled:text-caspian-gray text-caspian-secondary-500 text-xs p-1.5 rounded-md`}
        >
          ثبت ویرایش
        </button>
        {/* <button
          onClick={async () => {
            await testHandler();
          }}
          className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-error2 text-caspian-surface text-xs p-1.5 rounded-md"
        >
          تست دیتا
        </button> */}
      </div>
    </div>
  );
};

export default Roles;
