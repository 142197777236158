import React, { useContext, useEffect, useState } from "react";
import TimeChart from "./TimeChart";
// import BudgetChart from "./BudgetChart";
import WeightChart from "./WeightChart";
import BudgetChartPmo from "./BudgetChartPmo";
import BudgetChart2 from "./BudgetChart2";
import { AuthContext } from "../../../../../../context/auth/AuthContext";
import { useParams } from "react-router-dom";
import API from "../../../../../../api/API";

const MainReport = ({ refreshBrief, setRefreshBrief, fullData, loading }) => {
  // const { token } = useContext(AuthContext);
  // const params = useParams();
  // const [loading, setLoading] = useState(false);
  // const [fullData, setFullData] = useState([]);

  // useEffect(() => {
  //   setRefreshBrief(1);
  // }, []);
  // useEffect(() => {
  //   if (!token || !params?.id || refreshBrief === 0) return;
  //   const getActionPlanBrief = async () => {
  //     setLoading(true);
  //     try {
  //       const res = await API.get(
  //         `/api/v1/actionplan/actionplan/actionplanBrief/${params?.id}`,
  //         {
  //           headers: { Authorization: token },
  //         }
  //       );
  //       console.log(res?.data);
  //       setRefreshBrief(0);
  //       setFullData(res?.data[0]);
  //     } catch (err) {
  //       console.log(err);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   getActionPlanBrief();
  // }, [token, params?.id, refreshBrief, setRefreshBrief]);
  return (
    <div className="shadow-[0_0_5px_#00000050] rounded-md w-full bg-caspian-surface">
      {loading ? (
        <div className="flex items-center justify-center w-full py-16">
          <h2 className="text-xs text-caspian-gray4">در حال بارگزاری</h2>
        </div>
      ) : fullData?.remained_weight >= 1 ? (
        <div className="flex items-center justify-center w-full py-16">
          <h2 className="text-xs text-caspian-gray4">
            برای ایجاد نمودار لطفا یک وظیفه ایجاد کنید!
          </h2>
        </div>
      ) : (
        <div className="flex flex-col gap-0">
          <div className="flex flex-col gap-6 pb-6 border-b border-b-caspian-gray2">
            <div className="bg-caspian-gray2/30 p-2 rounded-bl-md w-fit">
              <span className="text-xs font-normal">بازه زمانی</span>
            </div>
            <TimeChart
              startDatePmo={fullData?.startDate}
              endDatePmo={fullData?.endDate}
              startDate={fullData?.startDateReal}
              endDate={fullData?.endDateReal}
            />
          </div>

          <div className="flex  gap-0 w-full">
            <div className="border-l border-l-caspian-gray2">
              <div className="bg-caspian-gray2/30 p-2 rounded-bl-md w-fit">
                <span className="text-xs font-normal">وزن فعالیت ها</span>
              </div>
              <div className="pr-12 pl-6 py-6">
                {!fullData?.remained_weight ? (
                  <WeightChart percentage={0} />
                ) : (
                  <WeightChart
                    percentage={(fullData?.remained_weight * 100).toFixed()}
                  />
                )}
              </div>
            </div>
            <div className=" w-full">
              <div className="bg-caspian-gray2/30 p-2 rounded-bl-md w-fit">
                <span className="text-xs font-normal">برآورد هزینه ها</span>
              </div>
              <div className=" py-6">
                {fullData?.cost > fullData?.costTotalTasks ? (
                  <BudgetChartPmo
                    cost={fullData?.cost}
                    costTotalTasks={fullData?.costTotalTasks}
                  />
                ) : (
                  <BudgetChart2
                    cost={fullData?.cost}
                    costTotalTasks={fullData?.costTotalTasks}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainReport;
