import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import API from "../../../../../api/API";

const OpportunityList = ({ actionplanIdRelated }) => {
  const { token } = useContext(AuthContext);
  const [opportunityList, setOpportunityList] = useState([]);
  useEffect(() => {
    if (!token || !actionplanIdRelated) return;
    const getActionPlanOpportunity = async () => {
      try {
        const res = await API.get(
          `/api/v1/actionplan/situations/actionplan/${actionplanIdRelated}`,
          {
            headers: { Authorization: token },
          }
        );
        console.log(
          res?.data?.filter((c) => c?.typeSituation === "opportunity")
        );
        setOpportunityList(
          res?.data?.filter((c) => c?.typeSituation === "opportunity")
        );
      } catch (err) {
        console.log(err);
      }
    };
    getActionPlanOpportunity();
  }, [token, actionplanIdRelated]);
  return (
    <div className="max-h-[180px] overflow-y-scroll w-full">
      <div className="flex flex-col gap-2">
        {opportunityList?.length < 1 ? (
          <h2 className="text-caspian-gray1 text-xs">فرصتی ثبت نشده است!</h2>
        ) : (
          opportunityList?.map(
            (d, i) =>
              d?.typeSituation === "opportunity" && (
                <div
                  key={i}
                  className="bg-caspian-surface p-1.5 rounded-md border border-caspian-gray2 flex items-center justify-between"
                >
                  <div className="flex flex-col gap-1 w-[90%]">
                    <span className="text-xs line-clamp-1">
                      آثار: {d?.impact}
                    </span>
                    <span className="text-xs line-clamp-1">
                      راهکار: {d?.solution}
                    </span>
                  </div>
                </div>
              )
          )
        )}
      </div>
    </div>
  );
};

export default OpportunityList;
