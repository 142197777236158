import React from "react";
import { PiFolderOpen, PiInfo, PiTrash } from "react-icons/pi";
import { useNavigate } from "react-router-dom";

const RightClickDetails = ({ open, setOpen, notebookId }) => {
  const navigate = useNavigate();
  return (
    <div
      className={
        open
          ? `p-1 absolute top-10 right-10
              w-24 bg-caspian-surface/50 rounded-md flex items-center justify-center z-20 shadow-[0_0_5px_#00000050] backdrop-blur-sm`
          : "hidden"
      }
    >
      <ul className="flex flex-col gap-0 items-center justify-center w-full">
        <li
          onClick={() => navigate(`${notebookId}`)}
          className="hover:bg-caspian-surface border-b border-b-caspian-gray2 p-1.5 w-full cursor-pointer flex items-center gap-1"
        >
          <PiFolderOpen className="text-lg text-caspian-gray4" />
          <span className="text-xs text-caspian-gray4">باز کردن</span>
        </li>
        <li
          onClick={() => {
            setOpen(false);
            // setShowDeleteAnswer(true);
          }}
          className="hover:bg-caspian-surface border-b border-b-caspian-gray2 p-1.5 w-full cursor-pointer flex items-center gap-1"
        >
          <PiTrash className="text-lg text-caspian-gray4" />
          <span className="text-xs text-caspian-gray4">حذف</span>
        </li>
        <li
          onClick={() => {
            setOpen(false);
            // setShowDetails(true);
          }}
          className="hover:bg-caspian-surface p-1.5 w-full cursor-pointer flex items-center gap-1"
        >
          <PiInfo className="text-lg text-caspian-gray4" />
          <span className="text-xs text-caspian-gray4">جزئیات</span>
        </li>
      </ul>
    </div>
  );
};

export default RightClickDetails;
