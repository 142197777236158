import React, { useState } from 'react'
import { PiCaretLeft } from "react-icons/pi";
import ModalListWorkProgressReport from './ModalListWorkProgressReport';

const SingleListWorkProgressReport = ({ fullData }) => {
    const [showModal, setShowModal] = useState(false)
    const [selectId, setSelectId] = useState(null)

    return (
        <div className='flex flex-col items-center w-full h-[150px] overflow-auto gap-5'>

            {fullData?.progress_taskreportProfile[0]?.documents?.map((item) => (
                <div key={item?.id} className='flex items-center justify-between px-2 w-[90%] border-b-2 pb-1 border-b-caspian-gray2'>
                    <span className='text-xs 2xl:text-sm'>{item?.name}</span>
                    <div className='flex items-center gap-2 cursor-pointer text-caspian-secondary-600'
                        onClick={() => {
                            setShowModal(true)
                            setSelectId(item?.id)
                        }}>
                        <span className='text-xs 2xl:text-sm'>بیشتر</span>
                        <PiCaretLeft />
                    </div>
                </div>
            ))}
            {showModal && <ModalListWorkProgressReport setShowModal={setShowModal} selectId={selectId} fullData={fullData} />}
        </div>
    )
}

export default SingleListWorkProgressReport