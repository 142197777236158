import { Outlet, useLocation, useParams } from "react-router-dom";
import { useAppContext } from "../../context/app-context/AppContext";
import { useEffect } from "react";

const GoalsLayout = ({ showMenu }) => {
  const location = useLocation();
  const { id } = useParams();
  const { setBradCrump1, setBradCrump2, setBradCrump3, setBrcLink2 } =
    useAppContext();
  useEffect(() => {
    if (location.pathname.includes("goals/list")) {
      setBradCrump1("اهداف");
      setBradCrump2("لیست اهداف");
    } else if (location.pathname.includes("goals/model")) {
      setBradCrump1("اهداف");
      setBradCrump2("مدل اهداف");
    } else if (location.pathname.includes(`goals/${id}`)) {
      setBradCrump1("اهداف");
      setBradCrump2(id);
    }
  }, [id, location, setBradCrump1, setBradCrump2, setBradCrump3, setBrcLink2]);
  return (
    <div
      className={
        showMenu
          ? `pl-[50px] mr-auto w-full md:w-[75%] lg:w-[80%] xl:w-[85%] 2xl:w-[85%] bg-caspian-gray3 p-1 overflow-y-scroll`
          : `pl-[50px] mr-[54px] w-full bg-caspian-gray3 p-1 overflow-y-scroll`
      }
    >
      <Outlet />
    </div>
  );
};

export default GoalsLayout;
