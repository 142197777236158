import React, { useContext } from "react";
import { PiCheck, PiTrash, PiX } from "react-icons/pi";
import API from "../../../api/API";
import { AuthContext } from "../../../context/auth/AuthContext";

const MenuActionBox = ({
  //   setConversationId,
  setRefreshMyconversation,
  isDelete,
  setIsDelete,
  open,
  setOpen,
  firstIndex,
  conversationId,
}) => {
  const { token } = useContext(AuthContext);
  const removeHandler = async (id) => {
    await API.delete(`/api/v1/message/myconversations/${id}/`, {
      headers: { Authorization: token },
    })
      .then((res) => {
        console.log(res.data);
        setOpen(false);
        setRefreshMyconversation(1);
        // setConversationId(null);
      })
      .catch((err) => console.log(err));
  };
  return (
    <div
      className={
        open
          ? `absolute ${firstIndex !== 0 ? "bottom-3" : "top-3"} left-3
              w-24 bg-caspian-surface/50 p-1 rounded-md flex items-center justify-center z-20 shadow-[0_0_5px_#00000050] backdrop-blur-sm rtlD`
          : "hidden"
      }
    >
      <ul className="flex flex-col z-50">
        {isDelete ? (
          <li className=" cursor-pointer p-2 flex items-center gap-4 justify-center">
            <PiX
              onClick={() => {
                setIsDelete(false);
                setOpen(false);
              }}
              className="text-sm"
            />
            <PiCheck
              onClick={() => removeHandler(conversationId)}
              className="text-sm"
            />
          </li>
        ) : (
          <li
            onClick={() => setIsDelete(true)}
            className=" cursor-pointer p-2 flex items-center gap-1"
          >
            <PiTrash className="text-sm" />
            <span className="text-[9px]">حذف چت</span>
          </li>
        )}
      </ul>
    </div>
  );
};

export default MenuActionBox;
