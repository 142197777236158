import React, { useContext, useEffect, useState } from "react";
import { PiPlusBold } from "react-icons/pi";
import SecretMembersList from "./other-information/SecretMembersList";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import API from "../../../../../api/API";
import { toast } from "react-toastify";

const OtherInformation = ({
  setActiveTab,
  generalId,
  setShowEditMeetingForm,
  setRefresh,
  dataEdit,
  setDataRefresh,
  editTitle,
}) => {
  const { token } = useContext(AuthContext);
  const [secretMembersList, setSecretMembersList] = useState(false);
  const [secretButton, setSecretButton] = useState(null);

  const [description, setDescription] = useState(
    dataEdit?.description?.length > 0 ? dataEdit?.description : ""
  );
  const [meetingProfileRelated, setMeetingProfileRelated] = useState([]);
  const [meetingProfileRelatedId, setMeetingProfileRelatedId] = useState(
    dataEdit?.meetingProfileRelated
      ? dataEdit?.meetingProfileRelated?.id
      : "null"
  );
  const [privacy, setPrivacy] = useState("internal");

  // const [thisDateProfile, setThisDateProfile] = useState(null);
  useEffect(() => {
    const getMeetingProfileRelated = () => {
      API.get("/api/v1/meeting/profile/detail/", {
        headers: { Authorization: token },
      })
        .then((res) => {
          setMeetingProfileRelated(
            res?.data?.filter((d) => d.id !== generalId)
          );
          // setThisDateProfile(
          //   new Date(
          //     res?.data?.filter((d) => d?.id === generalId)[0]?.created_at
          //   ).valueOf()
          // );
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getMeetingProfileRelated();
  }, [token, generalId]);

  const dataHandler = () => {
    // e.preventDefault();
    if (description === "") return null;
    API.patch(
      `/api/v1/meeting/profile/detail/${generalId}/`,
      {
        description:
          description.length > 0
            ? description
            : dataEdit?.description
            ? dataEdit?.description
            : "",
        meetingProfileRelated:
          meetingProfileRelatedId === "null" ? null : +meetingProfileRelatedId,
        privacy: privacy ? privacy : dataEdit?.privacy,
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (description === "") {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [description]);

  const dataHandlerSubmit = async () => {
    // e.preventDefault();
    await API.post(
      `/api/v1/meeting/profile/detail/${generalId}/status/`,
      {
        status: "other",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await dataHandler();
        await toast.success(`${editTitle} با موفقیت ویرایش و ثبت شد.`, {
          className: "toast-success",
          bodyClassName: "toast-success",
        });
        await setShowEditMeetingForm(false);
        await setRefresh(1);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataHandlerBack = async () => {
    await API.post(
      `/api/v1/meeting/profile/detail/${generalId}/status/`,
      {
        status: "io",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await dataHandler();
        await setActiveTab("inputOutput");
        await setDataRefresh(1);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="min-h-[270px] flex flex-col justify-between">
      <div className="flex flex-col gap-3">
        <div className="flex items-center gap-3">
          {/* شروع سطح محرمانگی */}
          <div className="w-full flex flex-col gap-1.5">
            <label className="text-xs text-caspian-gray">سطح محرمانگی</label>
            <select
              onChange={(e) => {
                setPrivacy(e.target.value);
                setSecretButton(e.target.value);
              }}
              name="privacy"
              defaultValue={"internal"}
              className="text-xs outline outline-1 outline-caspian-gray1 p-1.5 rounded-md bg-caspian-transparent cursor-pointer"
            >
              <option value={"confidential"}>خیلی محرمانه</option>
              <option value={"restricted"}>محرمانه</option>
              <option value={"secret"}>دسترسی خاص</option>
              <option value={"internal"}>عادی</option>
              <option value={"public"}>عمومی</option>
            </select>
          </div>
          {secretMembersList && (
            <SecretMembersList setSecretMembersList={setSecretMembersList} />
          )}
          {/* پایان سطح محرمانگی */}

          {/* شروع پروفایل وابسته */}
          <div className="w-full flex flex-col gap-1.5">
            <label className="text-xs text-caspian-gray">
              انتخاب پروفایل وابسته
            </label>
            <select
              onChange={(e) => setMeetingProfileRelatedId(e.target.value)}
              name="relatedProfile"
              value={meetingProfileRelatedId}
              className="text-xs outline outline-1 outline-caspian-gray1 p-1.5 rounded-md bg-caspian-transparent cursor-pointer"
            >
              <option value={"null"}>بدون پروفایل وابسته</option>
              {meetingProfileRelated
                // .filter(
                //   (d) => new Date(d?.created_at).valueOf() < thisDateProfile
                // )
                .map((d, i) => (
                  <option key={i} value={+d.id}>
                    {d.title}
                  </option>
                ))}
            </select>
          </div>
          {/* پایان پروفایل وابسته */}
        </div>
        {secretButton === "secret" && (
          <div
            onClick={() => setSecretMembersList(true)}
            className="flex items-center justify-between py-2 border-y border-y-caspian-gray2 w-[49%] cursor-pointer"
          >
            <div className="flex items-center gap-6">
              <span className="text-caspian-secondary-500 text-xs">
                افزودن دسترسی خاص
              </span>
              <span className="text-xs font-medium">12 عضو افزوده شد</span>
            </div>
            <button className="text-sm text-caspian-secondary-500">
              <PiPlusBold />
            </button>
          </div>
        )}
        <div className="flex flex-col gap-1.5 w-full">
          <label className="text-xs text-caspian-gray">توضیحات</label>
          <textarea
            defaultValue={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="توضیحات پروفایل را وارد کنید"
            rows={4}
            className="p-2 rounded-md outline-none outline-1 outline-caspian-gray2 text-xs"
          />
        </div>
      </div>
      <div className="flex items-center gap-2">
        <button
          onClick={async () => {
            await dataHandlerBack();
          }}
          className="min-w-[6rem] outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs p-1.5 rounded-md"
        >
          مرحله قبل
        </button>
        <button
          disabled={disableButton}
          onClick={async () => {
            await dataHandlerSubmit();
          }}
          className={`disabled:cursor-not-allowed disabled:text-caspian-gray text-caspian-secondary-500 text-xs p-1.5 rounded-md`}
        >
          ثبت ویرایش
        </button>
        {/* <button
          onClick={async () => {
            await testHandler();
          }}
          className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-error2 text-caspian-surface text-xs p-1.5 rounded-md"
        >
          تست دیتا
        </button> */}
      </div>
    </div>
  );
};

export default OtherInformation;
