import React, { useEffect, useState } from "react";
import { PiCaretLeftBold } from "react-icons/pi";

const Outputs = ({ fullData, showAll, hideAll, setShowAll, setHideAll }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (showAll) {
      setShow(true);
    }
  }, [showAll]);
  useEffect(() => {
    if (hideAll) {
      setShow(false);
    }
  }, [hideAll]);
  useEffect(() => {
    setShowAll(false);
  }, [show, setShowAll]);
  useEffect(() => {
    setHideAll(false);
  }, [show, setHideAll]);
  console.log(fullData);
  return (
    <div className="w-full flex flex-col gap-0 border border-caspian-gray2 rounded-md overflow-hidden">
      <div
        onClick={() => setShow(!show)}
        className="bg-caspian-gray3 p-3 w-full border-b border-b-caspian-gray2 flex items-center justify-between cursor-pointer"
      >
        <h2 className="text-xs">خروجی های جلسه</h2>
        <PiCaretLeftBold
          className={show ? "-rotate-90 duration-500" : "rotate-0 duration-500"}
        />
      </div>
      {show && (
        <div className="w-full flex flex-col gap-0">
          {fullData.map((d, i) => (
            <span
              key={i}
              className="text-xs text-caspian-gray4 p-3 border-b border-b-caspian-gray2"
            >
              {d.title}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default Outputs;
