import React, { useEffect, useState } from "react";
import { PiCaretLeftBold } from "react-icons/pi";

const HeadMembers = ({
  chairman,
  viceChairman,
  secretary,
  showAll,
  hideAll,
  setShowAll,
  setHideAll,
}) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (showAll) {
      setShow(true);
    }
  }, [showAll]);
  useEffect(() => {
    if (hideAll) {
      setShow(false);
    }
  }, [hideAll]);
  useEffect(() => {
    setShowAll(false);
  }, [show, setShowAll]);
  useEffect(() => {
    setHideAll(false);
  }, [show, setHideAll]);
  console.log({ chairman, viceChairman, secretary });
  return (
    <div className="w-full flex flex-col gap-0 border border-caspian-gray2 rounded-md overflow-hidden">
      <div
        onClick={() => setShow(!show)}
        className="bg-caspian-gray3 p-3 w-full border-b border-b-caspian-gray2 flex items-center justify-between cursor-pointer"
      >
        <h2 className="text-xs">اعضای هئیت رئیسه</h2>
        <PiCaretLeftBold
          className={show ? "-rotate-90 duration-500" : "rotate-0 duration-500"}
        />
      </div>
      {show && (
        <div className="w-full flex flex-col gap-0">
          <div className="flex items-center gap-0 border-b border-b-caspian-gray2">
            <div className="flex items-center gap-2 basis-1/2 p-3 border-l border-l-caspian-gray2">
              <span className="text-xs font-bold">رئیس جلسه:</span>
              <span className="text-xs">
                {chairman.placementProfileRelated.firstName +
                  " " +
                  chairman.placementProfileRelated.lastName}
              </span>
              <span className="text-xs text-caspian-gray">
                {chairman.placementPositionRelated.title}
              </span>
            </div>
            <div className="flex items-center gap-2 basis-1/2 p-3">
              <span className="text-xs font-bold">دبیر جلسه:</span>
              <span className="text-xs">
                {secretary.placementProfileRelated.firstName +
                  " " +
                  secretary.placementProfileRelated.lastName}
              </span>
              <span className="text-xs text-caspian-gray">
                {secretary.placementPositionRelated.title}
              </span>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-2 basis-1/2 p-3 border-l border-l-caspian-gray2">
              <span className="text-xs font-bold">جانشین رئیس:</span>
              <span className="text-xs">
                {viceChairman.placementProfileRelated.firstName +
                  " " +
                  viceChairman.placementProfileRelated.lastName}
              </span>
              <span className="text-xs text-caspian-gray">
                {viceChairman.placementPositionRelated.title}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeadMembers;
