import { useContext } from "react";
import { AuthContext } from "../../../context/auth/AuthContext";

const PersonalInfoBox = () => {
  const { user } = useContext(AuthContext);
  const data = user.placementProfileRelated;
  return (
    <div className="flex flex-col gap-2 w-full">
      <h2 className="border-b border-b-[#FF5B5B] w-fit text-sm">
        اطلاعات فردی
      </h2>
      {data === 1 ? (
        <h2 className="text-caspian-gray text-xs py-4">
          در حال بارگذاری اطلاعات...
        </h2>
      ) : (
        <form className="w-full">
          <div className="grid grid-cols-2 gap-0 bg-caspian-surface rounded-xl overflow-hidden w-full border border-caspian-gray1">
            <label className="flex flex-col bg-caspian-surface p-2 w-full border-l border-l-caspian-gray1">
              <span className="text-sm text-caspian-gray">نام</span>
              <input
                type="text"
                disabled
                className="disabled:bg-caspian-surface"
                defaultValue={data && data.firstName}
              />
            </label>
            <label className="flex flex-col bg-caspian-surface p-2 w-full">
              <span className="text-sm text-caspian-gray">نام خانوادگی</span>
              <input
                type="text"
                disabled
                className="disabled:bg-caspian-surface"
                defaultValue={data && data.lastName}
              />
            </label>
            <label className="flex flex-col bg-caspian-surface p-2 w-full border-l border-l-caspian-gray1 border-y border-y-caspian-gray1">
              <span className="text-sm text-caspian-gray">کد ملی</span>
              <input
                type="text"
                disabled
                className="disabled:bg-caspian-surface"
                defaultValue={
                  data &&
                  data.personalInfoProfileRelated &&
                  data &&
                  data.personalInfoProfileRelated[0].postalId
                }
              />
            </label>
            <label className="flex flex-col bg-caspian-surface p-2 w-full border-y border-y-caspian-gray1">
              <span className="text-sm text-caspian-gray">شماره شناسنامه</span>
              <input
                type="text"
                disabled
                className={"disabled:bg-caspian-surface"}
                defaultValue={data && data.idNumber}
              />
            </label>
            <label className="flex flex-col bg-caspian-surface p-2 w-full border-l border-l-caspian-gray1">
              <span className="text-sm text-caspian-gray">جنسیت</span>
              {data && data.gender === "men" && (
                <input
                  type="text"
                  disabled
                  className="disabled:bg-caspian-surface"
                  defaultValue={"مرد"}
                />
              )}
              {data && data.gender === "women" && (
                <input
                  type="text"
                  disabled
                  className="disabled:bg-caspian-surface"
                  defaultValue={"زن"}
                />
              )}
            </label>
            <label className="flex flex-col bg-caspian-surface p-2 w-full">
              <span className="text-sm text-caspian-gray">تاریخ تولد</span>
              <input
                type="text"
                disabled
                className={"disabled:bg-caspian-surface"}
                defaultValue={data && data.birthday}
              />
            </label>
          </div>
        </form>
      )}
    </div>
  );
};

export default PersonalInfoBox;
