import React, { useEffect } from "react";
import RequestsNavbar from "../RequestsNavbar";
import { useAppContext } from "../../../context/app-context/AppContext";
import DetailsReferRequest from "./DetailsReferRequest";

const MainReferRequest = () => {
  const { setBradCrump1, setBradCrump2, setBradCrump3 } = useAppContext();
  useEffect(() => {
    setBradCrump1("گزارش ها");
    setBradCrump2("ارسال");
    setBradCrump3("");
  }, [setBradCrump1, setBradCrump2, setBradCrump3]);

  return (
    <div className="flex flex-col gap-4">
      <RequestsNavbar buttonActive="refer" />
      <DetailsReferRequest />
    </div>
  );
};

export default MainReferRequest;
