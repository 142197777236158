import React, { useState } from "react";
import {
  PiArrowBendDownLeft,
  PiCaretDoubleDown,
  PiCaretDoubleUp,
  PiFileTextFill,
} from "react-icons/pi";

const RequestSentReplyDetails = ({
  fullData,
  setShowReject,
  setShowAccept,
}) => {
  const [showRequest, setShowRequest] = useState(true);

  return (
    <div className="bg-caspian-surface flex flex-col border border-caspian-gray2 rounded-xl p-4 shadow-[0_0_5px_#00000050] border-r-8 border-r-[#3C77A280]">
      <div className="flex items-center justify-between pb-2 border-b border-b-caspian-gray2">
        {/* نام ارسال کننده */}
        <div className="flex flex-col items-start gap-0">
          <div className="flex items-center gap-2">
            <div className="w-8 h-8 bg-caspian-primary-200 rounded-full overflow-hidden">
              <img
                src={
                  fullData?.firstActivityRelated?.receiver
                    ?.placementProfileRelated?.personalInfoProfileRelated[0]
                    ?.avatar
                }
                alt={
                  fullData?.firstActivityRelated?.receiver?.firstName +
                  " " +
                  fullData?.firstActivityRelated?.receiver?.lastName
                }
              />
            </div>
            <h2 className="text-sm">
              {fullData?.firstActivityRelated?.receiver?.firstName +
                " " +
                fullData?.firstActivityRelated?.receiver?.lastName}
            </h2>
            <span className="text-xs text-caspian-gray">
              {fullData?.reciver &&
              fullData?.reciver?.placementPositionRelated === null
                ? "نامشخص"
                : fullData?.reciver &&
                  fullData?.reciver?.placementPositionRelated
                    ?.positionJobRelated?.jobLevelRelated?.title}
            </span>
          </div>
          <div className="flex items-center gap-1 mr-10">
            <PiArrowBendDownLeft className="text-lg text-caspian-secondary-600" />
            <span className="text-xs text-caspian-secondary-600">پاسخ به:</span>
            <span className="text-xs">من</span>
          </div>
        </div>
        {/* تاریخ پاسخ به گزارش */}
        <span className="text-xs text-caspian-gray">
          {new Date(
            fullData?.firstActivityRelated?.updated_at
          ).toLocaleDateString("fa-IR", {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </span>
      </div>

      <div>
        {showRequest && (
          <div className="py-2 border-b border-b-caspian-gray2">
            <div className="flex flex-col gap-3">
              {/* توضیحات درخواست */}
              <p className="text-sm">{fullData?.firstActivityRelated?.text}</p>

              {/* فایل پیوست */}
              <div>
                {fullData?.firstActivityRelated?.document !== null ? (
                  <a
                    download={fullData?.firstActivityRelated?.document}
                    href={fullData?.firstActivityRelated?.document}
                    target="_blank"
                    rel="noreferrer"
                    className="text-sm"
                  >
                    <div className="flex items-center gap-2 text-caspian-gray text-[15px] p-2 rounded-md border border-caspian-gray w-fit">
                      {fullData?.firstActivityRelated?.document.replace(
                        /^.*[\\/]/,
                        ""
                      )}
                      <PiFileTextFill className="text-caspian-secondary-600 text-2xl" />
                    </div>
                  </a>
                ) : (
                  <div className="flex items-center justify-start p-2">
                    <h2 className="text-xs text-caspian-gray">
                      فایلی جهت دانلود وجود ندارد!
                    </h2>
                  </div>
                )}
              </div>
            </div>
            {/* تایید و عدم تایید */}
            {fullData?.status === "progressing" ? (
              <div className="flex items-center gap-2 pt-2">
                <button
                  onClick={() => setShowAccept(true)}
                  className="text-caspian-surface bg-[#73AEAF] text-xs p-1.5 rounded-md"
                >
                  تایید گزارش
                </button>
                <button
                  onClick={() => {
                    setShowReject(true);
                  }}
                  className="text-caspian-surface bg-[#FF5B5B] text-xs p-1.5 rounded-md"
                >
                  رد گزارش
                </button>
              </div>
            ) : fullData?.status === "complated" ? (
              <div className="pt-2 flex flex-col gap-2 border-t border-t-caspian-gray2 border-dotted mt-2">
                <span className="bg-[#73AEAF30] text-[#73AEAF] p-1.5 rounded-md text-[10px] font-bold w-fit">
                  گزارش توسط شما تایید شد.
                </span>
                <p className="text-xs text-caspian-gray">
                  امتیاز شما به این گزارش{" "}
                  <span className="font-bold text-xs text-caspian-gray">
                    {fullData?.score === "1" && "خیلی ضعیف"}
                    {fullData?.score === "2" && "ضعیف"}
                    {fullData?.score === "3" && "متوسط"}
                    {fullData?.score === "4" && "خوب"}
                    {fullData?.score === "5" && "عالی "}
                  </span>{" "}
                  می باشد.
                </p>
                {fullData?.comment ? (
                  <div className="flex items-center gap-1 text-xs text-caspian-gray">
                    <span>پیام شما:</span>
                    <p>{fullData?.comment}</p>
                  </div>
                ) : (
                  <div className="flex items-center gap-1 text-xs text-caspian-gray">
                    <span>پیامی وجود ندارد!</span>
                  </div>
                )}
              </div>
            ) : (
              fullData?.status === "rejected" &&
              fullData?.comment !== null && (
                <div className="pt-2 flex flex-col gap-2 border-t border-t-caspian-gray2 border-dotted mt-2">
                  <span className="bg-[#FF5B5B30] text-[#FF5B5B] p-1.5 rounded-md text-[10px] font-bold w-fit">
                    گزارش توسط شما تایید نشد!
                  </span>
                  <div className="flex items-center gap-1 text-xs text-caspian-gray">
                    <span>پیام شما:</span>
                    <p>{fullData?.omment}</p>
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </div>
      <div className={`justify-between flex items-center w-full gap-4 pt-4`}>
        <div className="flex items-center gap-2">
          <button className="flex items-center gap-2 text-caspian-secondary-600 outline outline-1 outline-caspian-secondary-600 py-1 px-3 rounded-md">
            <span className="text-xs">باز ارسال</span>
          </button>
        </div>
        <button
          onClick={() => setShowRequest(!showRequest)}
          className="text-caspian-secondary-500 text-sm"
        >
          {!showRequest ? <PiCaretDoubleDown /> : <PiCaretDoubleUp />}
        </button>
      </div>
    </div>
  );
};

export default RequestSentReplyDetails;
