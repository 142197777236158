import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppContext } from "../../../../context/app-context/AppContext";

const ProcessList = ({ dataProcessListTable }) => {
  // console.log(dataProcessListTable);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setBradCrump3 } = useAppContext();
  const openNewWindow = (url) => {
    const newWindow = window.open(url, "_blank");
    if (newWindow) {
      localStorage.setItem("referrer", window.location.href);
    }
  };
  return (
    <div className="bg-caspian-surface w-full rounded-md">
      {dataProcessListTable?.length > 0 ? (
        <table className="bg-caspian-surface w-full rounded-md whitespace-nowrap overflow-hidden">
          <thead className="rounded-t-md">
            <tr className="text-xs text-caspian-gray">
              <th scope="col" className="py-1 px-2">
                کد فرایند
              </th>
              <th scope="col" className="py-1 px-2 text-right">
                عنوان فرایند
              </th>
            </tr>
          </thead>
          <tbody>
            {dataProcessListTable.map((d, i) => (
              <tr
                key={i}
                className="text-xs hover:bg-caspian-primary-50/50 cursor-pointer"
                onClick={() => {
                  openNewWindow(`/process-management/list-processes/${d.id}`);
                  // window.open(
                  //   `/process-management/list-processes/${d.id}`,
                  //   "_blank"
                  // );
                  // navigate(`/process-management/list-processes/${d.id}`, {
                  //   state: { search: `model` },
                  // });
                  setBradCrump3(d.title);
                }}
              >
                <td className="py-1 px-2">{d.id}</td>
                <td className="py-1 px-2 text-right">{d.title}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="bg-caspian-surface w-full rounded-md whitespace-nowrap overflow-hidden p-2">
          <span className="text-xs">موجود نیست</span>
        </div>
      )}
    </div>
  );
};

export default ProcessList;
