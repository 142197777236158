import React, { useContext } from "react";
import { PiTrashFill } from "react-icons/pi";
import API from "../../../../../../../api/API";
import { AuthContext } from "../../../../../../../context/auth/AuthContext";

const SingleFile = ({ data, setRefreshListFile }) => {
  const { token } = useContext(AuthContext);

  const removeHandler = async () => {
    try {
      await API.delete(`/api/v1/actionplan/projectDocument/${data?.id}/`, {
        headers: { Authorization: token },
      });
      setRefreshListFile(1);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="bg-caspian-surface shadow-[0_0_7px_#00000040] rounded-md px-2 py-1.5 flex items-center gap-4">
      <a
        target="_blank"
        rel="noreferrer"
        download={data?.fileDocument}
        href={data?.fileDocument}
        className="w-full"
      >
        <span className="text-xs">{data?.name}</span>
      </a>
      <div className="flex items-center gap-1 z-50">
        <button
          onClick={removeHandler}
          className="text-lg text-caspian-secondary-300 hover:text-caspian-secondary-500"
        >
          <PiTrashFill />
        </button>
      </div>
    </div>
  );
};

export default SingleFile;
