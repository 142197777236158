
const SingleModelGoal = ({ titleMacroGoal, setShowPopUp, setGoalsId }) => {

    return (
        <div className='flex max-w-[200px] w-[200px] items-center justify-center flex-col' >

            <span onClick={() => {
                setShowPopUp(true);
                setGoalsId(titleMacroGoal?.id)
            }}
                style={{ background: `#${titleMacroGoal?.colorHex}` }}
                className='text-xs leading-5 text-center p-2 cursor-pointer w-[95%]  flex items-center justify-center rounded-lg text-caspian-on_primary/70 font-semibold'>
                {titleMacroGoal.title}
            </span>
        </div>
    )
}

export default SingleModelGoal





