import React from "react";

const SingleStandardBox = ({ msTitle, standardTitle, onClick, isSelected }) => {
  return (
    <button
      onClick={onClick}
      className={`rounded-t-md min-w-[32px] p-2 text-[#0D358C] border border-caspian-secondary-600 flex items-center gap-4 text-xs font-bold ${
        isSelected ? "bg-caspian-blue-200" : "bg-caspian-surface"
      }`}
    >
      <span>{standardTitle}</span>
      <span>{msTitle}</span>
    </button>
  );
};

export default SingleStandardBox;
