import React, { useContext, useEffect, useState } from 'react';
import ButtonTab from './buttontab/ButtonTab';
import AllClauseList from './all-clause-list/AllClauseList';
import AllQuestionList from './all-question-list/AllQuestionList';
import ListStandard2 from '../../create-form-question/ListStandard2';
import { AuthContext } from '../../../../../../context/auth/AuthContext';
import API from '../../../../../../api/API';

const MainGuidList = ({standard2,setStandard2,setStandardSelected2,standardSelected2}) => {
    const [activeTab, setActiveTab] = useState('clause');
    const { token } = useContext(AuthContext);
    const [standardList, setStandardList] = useState([-1]);
    // const [standard, setStandard] = useState("");
    const [standardSelected, setStandardSelected] = useState(null);
    const [showListStandard, setShowListStandard] = useState(false);
    const [clauseList, setClauseList] = useState([]);

    useEffect(() => {
        if (!token) return
        const fetchStandard = async () => {
            try {
                const res = await API.get('/api/v1/standard/', { headers: { Authorization: token } });
                setStandardList(res.data);
            }
            catch (err) {
                console.log(err);
            }
        }
        fetchStandard()
    }, [token])
    useEffect(() => {
        if (!standard2) return
        const fetchClause = async () => {
            try {
                const res = await API.get(`/api/v1/standard/clause/all/${standard2}`, { headers: { Authorization: token } });
                setClauseList(res.data);
                console.log(res.data);
            }
            catch (err) {
                console.log(err);
            }
        }
        fetchClause();
    }, [standard2, token])
    return (
        <div className='min-h-full flex flex-col'>
        <div className='flex gap-0'>
            <ButtonTab
                title='توضیحات بند'
                activeTab={activeTab}
                buttonTab='clause'
                handleClick={() => setActiveTab('clause')}
            />
            <ButtonTab
                title='سوالات'
                activeTab={activeTab}
                buttonTab='questions'
                handleClick={() => setActiveTab('questions')}
            />
        </div>
        <div className='p-4 pt-2 gap-3 flex flex-col'>
                <div className='w-[80%] 2xl:w-[60%]'>
                    <ListStandard2
                        lable=""
                        standardList={standardList}
                        standard={standard2}
                        setStandard={setStandard2}
                        standardSelected={standardSelected2}
                        setStandardSelected={setStandardSelected2}
                        showListStandard={showListStandard}
                        setShowListStandard={setShowListStandard}
                        // standard2={standard2}
                    />
                </div>
                <p className='text-center text-xs 2xl:text-sm'>
                    {
                        standard2?standardList?.map(m=>{
                            if(m?.id===standard2){
                                return m?.standardTitlePersian
                            }
                        }):
                        standardSelected2?.standardTitlePersian
                    }
                </p>
            </div>
        {
            activeTab==='clause'?(
                <AllClauseList standard2={standard2} setStandard2={setStandard2}
                setStandardSelected2={setStandardSelected2} standardSelected2={standardSelected2}
                setClauseList={setClauseList} clauseList={clauseList}
                />
            ):(
                <AllQuestionList standard2={standard2} />
            )
        }
        </div>
    );
}

export default MainGuidList;
