import { PiClipboardText, PiFileTextDuotone } from "react-icons/pi";
import ListItemSingle from "./ListItemSingle";
import { useParams } from "react-router-dom";

const GoalsNavbar = ({ buttonActive }) => {

  const { id } = useParams()

  return (
    <nav className="w-full bg-caspian-surface rtl">
      <ul className="flex items-center gap-0.5">
        <ListItemSingle
          title="لیست اهداف"
          active="list"
          icon={<PiFileTextDuotone />}
          link="/goals/list"
          buttonActive={buttonActive}
        // onClick={() => setBradCrump3("تیم ها")}
        />
        <ListItemSingle
          title="مدل اهداف"
          active="model"
          icon={<PiFileTextDuotone />}
          link="/goals/model"
          buttonActive={buttonActive}
        // onClick={() => setBradCrump3("ریسک ها")}
        />

        {id &&
          <ListItemSingle
            title="پروفایل اهداف"
            active="profile"
            icon={<PiClipboardText />}
            link="/goals/profile"
            buttonActive={buttonActive}
          // onClick={() => setBradCrump3("ریسک ها")}
          />
        }

      </ul>
    </nav>
  );
};

export default GoalsNavbar;
