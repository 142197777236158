import { useNavigate } from "react-router-dom";

const SingleTableChart = ({
  id,
  title,
  partDivision,
  divisionDepartman,
  department,
}) => {
  const navigate=useNavigate();
  return (
    <tr className="bg-caspian-surface duration-300 cursor-pointer" onClick={()=>navigate(`/chart/list-chart/${id}`)}>
      <td className="border-b border-b-caspian-gray2 py-2 w-[20%]" title={title}>
        <h2 className="text-center max-w-[90%] truncate mx-auto text-xs 2xl:text-sm">{title}</h2>
      </td>
      <td className="border-b border-b-caspian-gray2 min-w-[100px] w-[20%]">
        <h2 className="text-center text-[11px] max-w-[90%] truncate mx-auto text-xs 2xl:text-sm text-caspian-gray4">
          {partDivision !== divisionDepartman ? partDivision : ""}
        </h2>
      </td>
      <td className="border-b border-b-caspian-gray2 px-3 py-2 w-[20%]">
        <h2 className="text-center text-[11px] max-w-[90%] truncate mx-auto text-xs 2xl:text-sm text-caspian-gray4">{divisionDepartman}</h2>
      </td>
      <td className="border-b border-b-caspian-gray2 px-3 py-2 w-[20%]">
        <h2 className="text-center text-[11px] max-w-[90%] truncate mx-auto text-xs 2xl:text-sm text-caspian-gray4">{department}</h2>
      </td>
    </tr>
  );
};

export default SingleTableChart;
