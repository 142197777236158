import { PiInfoFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import API from "../../../../api/API";
import { AuthContext } from "../../../../context/auth/AuthContext";
import { useContext } from "react";

const PublicNotifactionBox = ({
  id,
  title,
  created_at,
  sender,
  personalStatus,
  description,
  reciver,
  link,
  setOpen,
  deadLine,
}) => {
  const { token } = useContext(AuthContext);
  const readHandler = async () => {
    if (token) {
      try {
        await API.patch(`/api/v1/notification/${id}/`, {
          title: title,
          personalStatus: "read",
          reciver: reciver,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };
  return (
    <div
      onClick={() => {
        // setOpen(false);
        personalStatus === "pending" && readHandler();
      }}
      className={`${
        personalStatus === "read"
          ? "bg-caspian-transparent"
          : personalStatus === "pending" && "bg-caspian-secondary-50/40"
      } p-2 flex flex-col gap-0 border-b border-b-[#e4e4e4]`}
    >
      <div className="w-full flex flex-col gap-2">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center gap-2">
            <PiInfoFill
              className={`${
                personalStatus === "read"
                  ? "text-caspian-gray1"
                  : personalStatus === "pending" && "text-caspian-secondary-600"
              }`}
            />
            <h2
              className={`${
                personalStatus === "read"
                  ? "text-caspian-gray"
                  : personalStatus === "pending" &&
                    "font-bold text-caspian-secondary-600"
              } text-xs`}
            >
              {title}
            </h2>
          </div>
          <span className="text-caspian-gray text-[10px]">
            {created_at &&
              new Date(created_at).toLocaleDateString("fa-IR", {
                hour: "2-digit",
                minute: "2-digit",
              })}
          </span>
        </div>

        <div className="flex flex-col gap-2 mr-4">
          <h2
            className={`${
              personalStatus === "read"
                ? "text-caspian-gray1"
                : personalStatus === "pending" && "text-caspian-on_primary/70"
            } text-xs line-clamp-1`}
          >
            {description}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default PublicNotifactionBox;
