import React from 'react'
import SingleObjectiveProfileGoal from './SingleObjectiveProfileGoal'

const MainObjectiveProfileGoal = ({ data }) => {
    return (
        <div className='px-6 xl:px-12 flex flex-col pt-7 w-full'>

            <div className='flex items-stretch h-12 w-full gap-2'>
                <div className='basis-[15%] xl:basis-[12%]'>
                    <span className='border-y-2 text-xs 2xl:text-lg border-caspian-gray2 flex items-center h-full pr-2 text-caspian-darkyellow-700'>عنوان هدف خرد</span>
                </div>

                <div className='basis-[85%] xl:basis-[88%] flex justify-between items-center h-12 px-5 bg-caspian-darkyellow-300'>
                    <span className='text-xs 2xl:text-lg font-semibold text-caspian-on_primary/80'>{data?.title}</span>
                    <span className='text-xs 2xl:text-lg font-semibold text-caspian-on_primary/80'>
                        <span className='text-caspian-on_primary/60'>کد :</span>
                        {data?.code}
                    </span>
                </div>
            </div>

            <div className='flex flex-col gap-2 py-4'>
                <SingleObjectiveProfileGoal shape={"S"} title={"خاص"} desc={data?.specific} />
                <SingleObjectiveProfileGoal shape={"M"} title={"قابل‌ اندازه‌گیری"} desc={data?.measurable} />
                <SingleObjectiveProfileGoal shape={"A"} title={"قابل‌ دستیابی"} desc={data?.achievable} />
                <SingleObjectiveProfileGoal shape={"R"} title={"مرتبط"} desc={data?.realistic} />
                <SingleObjectiveProfileGoal shape={"T"} title={"مبتنی ‌بر‌ زمان"} desc={data?.timely} />
            </div>
        </div>
    )
}

export default MainObjectiveProfileGoal