import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppContext } from "../../../context/app-context/AppContext";

const SingleTableSentRequest = ({
  id,
  to,
  jobSender,
  title,
  description,
  date,
  type,
}) => {
  const navigate = useNavigate();
  const { setBradCrump3 } = useAppContext();
  const [searchParams] = useSearchParams();

  return (
    <tr
      onClick={() => {
        setBradCrump3(id);
        navigate(`${id}`, {
          state: { search: `/requests/sent?${searchParams.toString()}` },
        });
      }}
      className="cursor-pointer bg-caspian-surface hover:bg-caspian-gray3 duration-300 w-full"
    >
      <td
        colSpan={1}
        className="text-center border-b border-b-caspian-gray2 p-2 w-[35%]"
      >
        <div className=" flex justify-center items-center gap-1">
          {type === "send" && (
            <span className="text-xs text-[#31B04D]">ارسال به:</span>
          )}
          {type === "reply" && (
            <span className="text-xs text-[#3C77A2]">بازارسال:</span>
          )}
          {type === "erja" && (
            <span className="text-xs text-[#F9BB1C]">ارجاع به:</span>
          )}
          <h2 className="text-sm">{to}</h2>
          <span className="text-xs text-caspian-gray">{jobSender}</span>
        </div>
      </td>
      <td
        colSpan={1}
        className="text-center border-b border-b-caspian-gray2 p-2 w-[50%]"
      >
        <div className=" flex justify-center items-center gap-1">
          <h2 className="text-sm whitespace-nowrap">{title}</h2>-
          <p className="text-xs text-caspian-on_primary/80 line-clamp-1">{description}</p>
        </div>
      </td>
      <td
        colSpan={1}
        className="text-center border-b border-b-caspian-gray2 p-2 w-[15%]"
      >
        <h2 className="text-xs text-center text-[#666666]">
          {new Date(date).toLocaleDateString("fa-IR", {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </h2>
      </td>
    </tr>
  );
};

export default SingleTableSentRequest;
