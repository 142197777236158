import React, { useContext, useEffect, useState } from "react";
import {
  PiCircleNotch,
  // PiArrowBendUpLeft,
  // PiArrowBendUpRight,
  PiDownloadSimple,
  PiFileTextFill,
} from "react-icons/pi";
// import ReferralForm from "../../forms/ReferralForm";
import API from "../../../../api/API";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../../../context/auth/AuthContext";

const Details = () => {
  const { user } = useContext(AuthContext);
  // const [showReferralForm, setShowReferralForm] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const [serachParams, setSearchParams] = useSearchParams();
  const searchType = serachParams.get("search");

  const [fullData, setFullData] = useState([-1]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    API.get(`/api/v1/request/activity/${params.id}/`)
      .then((d) => {
        setFullData(d.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params]);

  console.log(fullData);

  useEffect(() => {
    if (searchType) {
      navigate(`/requests/sent?search=${searchType}`);
    }
  }, [searchType, navigate]);
  return (
    <div className="w-2/3 px-2 py-8">
      {fullData[0] === -1 && !error ? (
        <div className="flex items-center justify-center py-24">
          <div className="flex items-center gap-2">
            <PiCircleNotch className="text-caspian-gray text-sm animate-spin" />
            <h2 className="text-caspian-gray text-sm">
              در حال بارگذاری اطلاعات
            </h2>
          </div>
        </div>
      ) : user?.placementProfileRelated?.id !==
          fullData?.sender?.placementProfileRelated?.id || error ? (
        <div className="flex items-center justify-center py-24">
          <h2 className="text-sm text-caspian-gray">گزارشی یافت نشد...</h2>
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-8 pb-4">
            <div className="flex items-center justify-between">
              <h2 className="text-caspian-secondary-600">درخواست گزارش</h2>
              <span className="text-sm text-caspian-gray">
                {new Date(fullData.created_at).toLocaleDateString("fa-IR", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex items-center gap-4">
                <h2 className="text-sm">
                  {fullData.sender &&
                    fullData.sender.placementProfileRelated.firstName +
                      " " +
                      fullData.sender.placementProfileRelated.lastName}
                </h2>
                <span className="text-xs text-caspian-gray">
                  {fullData.sender &&
                  fullData.sender.placementPositionRelated === null
                    ? "نامشخص"
                    : fullData?.sender?.placementPositionRelated
                        ?.positionJobRelated?.jobLevelRelated?.title}
                </span>
              </div>
              <p className="text-sm text-justify leading-7">
                {fullData?.RequestRelated?.description}
              </p>
            </div>
          </div>
          <div className="border-t border-t-caspian-gray2/25">
            <div className="border-t border-t-caspian-gray2/25 flex items-center justify-between py-6">
              <div className="flex items-center gap-2">
                <span className="text-[13px] text-caspian-gray">
                  موعد تحویل گزارش:
                </span>
                <span className="text-sm">
                  {new Date(fullData?.deadLine).toLocaleDateString("fa-IR", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </span>
              </div>
              <div className="flex items-center gap-2">
                <span className="text-[13px] text-caspian-gray">
                  دوره درخواست:
                </span>
                <span className="text-sm">
                  {fullData?.RequestRelated?.period}
                </span>
              </div>
            </div>
            <div className="border-t border-t-caspian-gray2/25 flex flex-col gap-4">
              <div className="flex flex-col gap-3 border-b border-t-caspian-gray2/25 border-b-caspian-gray pt-6">
                <span className="text-caspian-on_primary/80">فایل پیوست</span>
                <div className="flex items-center justify-between w-full">
                  {fullData.RequestRelated &&
                  fullData.RequestRelated.document !== null ? (
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center gap-2 text-caspian-gray text-[15px]">
                        <span className="text-sm">
                          {fullData.RequestRelated &&
                            fullData.RequestRelated.document.replace(
                              /^.*[\\/]/,
                              ""
                            )}
                        </span>
                        <PiFileTextFill className="text-caspian-secondary-600 text-2xl" />
                      </div>

                      <a
                        download={
                          fullData.RequestRelated &&
                          fullData.RequestRelated.document
                        }
                        href={
                          fullData.RequestRelated &&
                          fullData.RequestRelated.document
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <PiDownloadSimple className="text-caspian-gray text-2xl cursor-pointer" />
                      </a>
                    </div>
                  ) : (
                    <div className="flex items-center justify-center w-full">
                      <h2 className="text-sm text-caspian-gray">
                        فایلی جهت دانلود وجود ندارد!
                      </h2>
                    </div>
                  )}
                </div>
                <div className="flex items-end justify-end pt-6 pb-1.5">
                  {fullData.status === "read" ? (
                    <h2 className="text-xs text-caspian-gray2 font-normal">
                      در تاریخ
                      {" " +
                        new Date(fullData?.readed_at).toLocaleDateString(
                          "fa-IR",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        ) +
                        " "}
                      توسط
                      {" " +
                        fullData?.reciver?.placementProfileRelated?.firstName +
                        " " +
                        fullData?.reciver?.placementProfileRelated?.lastName +
                        " "}
                      مشاهده گردید.
                    </h2>
                  ) : (
                    <h2 className="text-xs text-caspian-gray2 font-normal">
                      هنوز مشاهده نشده است.
                    </h2>
                  )}
                </div>
              </div>

              {/* <div className="flex items-center gap-4 py-4">
                <button className="flex items-center gap-2 text-caspian-secondary-600 outline outline-1 outline-caspian-secondary-600 py-1.5 px-3 rounded-md">
                  <PiArrowBendUpRight className="text-xl" />
                  <span className="text-sm">پاسخ</span>
                </button>
                <button
                  onClick={() => setShowReferralForm(true)}
                  className="flex items-center gap-2 text-caspian-secondary-600 outline outline-1 outline-caspian-secondary-600 py-1.5 px-3 rounded-md"
                >
                  <PiArrowBendUpLeft className="text-xl" />
                  <span className="text-sm">ارجاع</span>
                </button>
                {showReferralForm && (
                  <ReferralForm
                    setShowReferralForm={setShowReferralForm}
                    showReferralForm={showReferralForm}
                  />
                )}
              </div> */}

              {fullData.status === "done" ? (
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col gap-4">
                    <div className="flex items-center gap-2">
                      <h2 className="text-caspian-secondary-700">
                        {fullData.reciver &&
                          fullData.reciver.placementProfileRelated.firstName +
                            " " +
                            fullData.reciver.placementProfileRelated.lastName}
                      </h2>
                      <span className="text-sm text-caspian-gray">
                        {fullData.reciver &&
                        fullData.reciver.placementPositionRelated === null
                          ? "نامشخص"
                          : fullData.reciver &&
                            fullData.reciver.placementPositionRelated
                              .positionJobRelated.jobLevelRelated.title}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-xs text-[#31B04D]">پاسخ به:</span>
                      <span className="text-sm text-caspian-secondary-700">
                        {fullData.sender &&
                          fullData.sender.placementProfileRelated.firstName +
                            " " +
                            fullData.sender.placementProfileRelated.lastName}
                      </span>
                      <span className="text-sm text-caspian-gray">
                        {fullData?.sender?.placementPositionRelated === null
                          ? "نامشخص"
                          : fullData?.sender?.placementPositionRelated
                              ?.positionJobRelated?.jobLevelRelated?.title}
                      </span>
                    </div>
                    <div>
                      <p className="text-sm text-justify leading-7">
                        {fullData.text}
                      </p>
                    </div>
                  </div>
                  <div className="border-t border-t-caspian-gray2/25 flex flex-col gap-4">
                    <div className="flex flex-col gap-3 py-6">
                      <span className="text-caspian-on_primary/80">
                        فایل پیوست گزارش
                      </span>

                      <div className="flex items-center justify-between w-full">
                        {fullData.document && fullData.document !== null ? (
                          <div className="flex items-center justify-between w-full">
                            <div className="flex items-center gap-2 text-caspian-gray text-[15px]">
                              <span className="text-sm">
                                {fullData.document.replace(/^.*[\\/]/, "")}
                              </span>
                              <PiFileTextFill className="text-caspian-secondary-600 text-2xl" />
                            </div>

                            <a
                              download={fullData.document}
                              href={fullData.document}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <PiDownloadSimple className="text-caspian-gray text-2xl cursor-pointer" />
                            </a>
                          </div>
                        ) : (
                          <div className="flex items-center justify-center w-full">
                            <h2 className="text-sm text-caspian-gray">
                              فایلی جهت دانلود وجود ندارد!
                            </h2>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Details;
