import React from 'react';
import { PiCircleNotch, PiX } from 'react-icons/pi';

const DeleteAlertBox = ({loadingAlert,setShowAlert,handleDelete,idPost}) => {
    return (
        <>
            <div className='w-[50%] max-w-[350px] h-[150px] fixed z-[200] top-1/2 -translate-y-1/2 -translate-x-1/2 left-1/2
                    bg-caspian-surface shadow-[0_0_10px_#00000040] rounded-md p-1.5 flex flex-col gap-6'>
                    <button disabled={loadingAlert} onClick={()=>setShowAlert(false)} className='ltr'><PiX /></button>
                    <div className='flex items-center justify-center'>
                        <div className='w-[70%] flex flex-col gap-10 text-center'>
                            <p className='text-sm 2xl:text-base'>از حذف سوال مطمئن هستید؟</p>
                            <div className='flex justify-between items-center text-xs 2xl:text-sm'>
                                <button
                                    disabled={loadingAlert}
                                    onClick={() => handleDelete(idPost)}
                                    className='bg-caspian-error2 flex gap-2 text-caspian-surface p-1.5 rounded-md'>
                                    <span>بله، حذف شود</span>
                                    {loadingAlert && <span>
                                        <PiCircleNotch className='animate-spin' />
                                    </span>}
                                </button>
                                <button 
                                disabled={loadingAlert}
                                onClick={() => setShowAlert(false)}>لغو</button>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
}

export default DeleteAlertBox;
