import { useNavigate, useSearchParams } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../../../context/auth/AuthContext";
import { useAppContext } from "../../../../context/app-context/AppContext";

const SingleTableListActionplan = ({
  id,
  title,
  manager,
  endDate,
  startDate,
  setRefreshPmoList,
  statusAction,
}) => {
  const { token } = useContext(AuthContext);

  const [serachParams] = useSearchParams();
  const navigate = useNavigate();
  const { setBradCrump3 } = useAppContext();
  // const [showAction, setShowAction] = useState(true);
  const viewTeam = async () => {
    navigate(`/action-plan/my-actionplan/${id}/profile`);
  };

  return (
    <tr className="bg-caspian-surface duration-300">
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-right border-b border-b-caspian-gray2 px-3 py-2 w-[22.5%] cursor-pointer"
      >
        <h2>{title}</h2>
      </td>
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-right border-b border-b-caspian-gray2 px-3 py-2 w-[18.55%] cursor-pointer"
      >
        <h2>{manager}</h2>
      </td>
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-right border-b border-b-caspian-gray2 px-3 py-2 w-[22.5%] cursor-pointer"
      >
        <h2>{startDate}</h2>
      </td>
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-right border-b border-b-caspian-gray2 w-[22.5%] cursor-pointer"
      >
        <h2>{endDate}</h2>
      </td>
      <td className="whitespace-nowrap text-right border-b border-b-caspian-gray2 w-[15%]">
        <div className="flex items-center justify-between gap-2 pl-2">
          <span
            className={`
                bg-caspian-secondary-50 border-caspian-secondary-600 text-caspian-secondary-500"
                
                  py-0.5 px-1 rounded-md border text-xs`}
          >
            {statusAction}
          </span>
        </div>
      </td>
    </tr>
  );
};

export default SingleTableListActionplan;
