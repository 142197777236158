import React from 'react';
import TopProfile from './TopProfile';
import BottomProfile from './BottomProfile';

const MainOrganizationProfile = ({ data,refresh,setRefresh}) => {
    return (
        <div>
            <TopProfile data={data} />
            <BottomProfile data={data} refresh={refresh} setRefresh={setRefresh} />
        </div>
    );
}

export default MainOrganizationProfile;

