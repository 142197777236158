import React, { useEffect, useState } from "react";
import {
  PiCalendar,
  PiCopySimple,
  PiFolderSimple,
  PiGlobe,
  PiNotePencil,
  PiPencilSimpleLine,
  PiTranslate,
  PiShieldCheck,
  PiStorefront,
  PiTimer,
  // PiFileText,
  // PiFloppyDisk,
} from "react-icons/pi";
import FeatureBox from "./FeatureBox";
import DetailsTabMain from "./details-tab/DetailsTabMain";
import { useSearchParams } from "react-router-dom";

const Details = ({ details, dataId, setdataId, recordsDataId }) => {
  const [serachParams, setSearchParams] = useSearchParams();
  const page = serachParams.get("page");
  const searchType = serachParams.get("keyword");
  const [fullData, setFullData] = useState([-1]);

  useEffect(() => {
    setFullData(details);
  }, [details, recordsDataId, setdataId]);

  useEffect(() => {
    setdataId(recordsDataId);
  }, [setdataId, page, recordsDataId, searchType]);

  function handleFilterChange(key, value) {
    setSearchParams((prevParams) => {
      if (value === null || value === "") {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    });
  }

  return (
    <section className={recordsDataId ? "w-full" : ""}>
      {fullData[0] !== -1 &&
        fullData.map(
          (d, i) =>
            dataId === d.id && (
              <div key={i} className="flex flex-col gap-1">
                <div className="flex flex-col gap-4 bg-caspian-surface rounded-xl p-4">
                  <div className="flex items-start justify-between gap-4">
                    <h1
                      className={`${
                        d.language === "EN" ? "text-left" : "text-right"
                      } text-xl font-bold text-caspian-secondary-900`}
                    >
                      {d.title}
                    </h1>
                  </div>
                  <h3 className="text-caspian-gray font-medium">
                    موضوع:{" "}
                    <span className="text-caspian-secondary-900 text-sm">
                      {d.typeLibrary === "book" && "کتاب"}
                      {d.typeLibrary === "article" && "مقاله"}
                      {d.typeLibrary === "standard" && "استاندارد"}
                    </span>
                  </h3>
                  <div className="flex flex-col gap-8">
                    <div className="grid grid-cols-2 gap-4 justify-between">
                      {/* {d.type !== null && d.type && (
                    <FeatureBox
                      title="نوع فایل"
                      dataTitle={d.type}
                      icon={<PiFileText />}
                    />
                  )} */}

                      {/* {d.size && (
                    <FeatureBox
                      title="سایز فایل"
                      dataTitle={d.size}
                      icon={<PiFloppyDisk />}
                    />
                  )} */}

                      {d.language && (
                        <FeatureBox
                          title="زبان"
                          dataTitle={d.language === "EN" ? "انگلیسی" : "فارسی"}
                          icon={<PiGlobe />}
                        />
                      )}

                      {d.code && (
                        <FeatureBox
                          title="کد فایل"
                          dataTitle={d.code}
                          icon={<PiFolderSimple />}
                        />
                      )}

                      {d.author && (
                        <FeatureBox
                          title={
                            d.typeLibrary === "article"
                              ? "پدیدآورنده"
                              : d.typeLibrary === "book"
                              ? "نویسنده"
                              : "پدیدآورنده/نویسنده"
                          }
                          dataTitle={d.author}
                          icon={<PiPencilSimpleLine />}
                        />
                      )}
                      {d.revision && (
                        <FeatureBox
                          title="ویرایش"
                          dataTitle={d.revision}
                          icon={<PiNotePencil />}
                        />
                      )}
                      {d.pages && (
                        <FeatureBox
                          title="تعداد صفحات"
                          dataTitle={d.pages}
                          icon={<PiCopySimple />}
                        />
                      )}
                      {d.translator && (
                        <FeatureBox
                          title="مترجم"
                          dataTitle={d.translator}
                          icon={<PiTranslate />}
                        />
                      )}

                      {d.source && (
                        <FeatureBox
                          title={
                            d.typeLibrary === "article"
                              ? "مرجع"
                              : d.typeLibrary === "standard"
                              ? "مرجع صدور ISO"
                              : "مرجع/منبع"
                          }
                          dataTitle={d.source}
                          icon={<PiStorefront />}
                        />
                      )}

                      {d.standardClassificationNumber && (
                        <FeatureBox
                          title="شماره استاندارد"
                          dataTitle={d.standardClassificationNumber}
                          icon={<PiShieldCheck />}
                        />
                      )}

                      {d.publicationDate && (
                        <FeatureBox
                          title="تاریخ انتشار"
                          dataTitle={new Date(
                            d.publicationDate
                          ).toLocaleDateString("fa-IR", {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                          icon={<PiCalendar />}
                        />
                      )}

                      {d.creationDate && (
                        <FeatureBox
                          title="تاریخ نگارش"
                          dataTitle={new Date(
                            d.creationDate
                          ).toLocaleDateString("fa-IR", {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                          icon={<PiCalendar />}
                        />
                      )}

                      {d.fileDuration && (
                        <FeatureBox
                          title={
                            d.typeLibrary === "film"
                              ? "زمان فیلم"
                              : d.typeLibrary === "podcast"
                              ? "زمان"
                              : "زمان"
                          }
                          dataTitle={d.fileDuration}
                          icon={<PiTimer />}
                        />
                      )}
                    </div>
                    {d?.tags?.length > 0 && (
                      <div className="border-t border-t-caspian-gray2 pt-4 flex flex-col gap-4">
                        <h2>کلید واژه:</h2>
                        <div className="flex flex-wrap gap-2">
                          {d.tags.map((k, i) => (
                            <button
                              className="bg-caspian-gray3 text-caspian-on_primary/70 outline outline-1 outline-caspian-on_primary/70 p-2 rounded-xl text-[10px] font-bold"
                              key={i}
                              type="button"
                              onClick={() => {
                                handleFilterChange("keyword", k);
                                handleFilterChange("page", 1);
                              }}
                            >
                              {k}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="border-t border-t-caspian-gray2 pt-4 flex flex-col gap-4">
                    {/* <h3>توضیحات</h3>
                    <p
                      className={`${
                        d.language === "EN" ? "text-left" : "text-right"
                      } text-sm text-caspian-on_primary/50 text-justify`}
                    >
                      {d.abstract}
                    </p> */}
                    <DetailsTabMain
                      dataAbstract={d.abstract}
                      dataDownloads={d?.filesLibrary[0]?.fileDocument}
                      dataPictures={d.imagesLibrary}
                    />
                  </div>
                </div>
              </div>
            )
        )}
    </section>
  );
};

export default Details;
