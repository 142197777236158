import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppContext } from "../../../../../context/app-context/AppContext";

const SingleTableProfileMeeting = ({
  id,
  title,
  type,
  code,
  secretary,
  head,
}) => {
  const [serachParams] = useSearchParams();
  const navigate = useNavigate();
  const { setBradCrump3 } = useAppContext();

  return (
    <tr
      onClick={() => {
        navigate(`/managment-of-meetings/profile-meeting/all-profiles/${id}`, {
          state: {
            search: `profile-meeting/all-profiles?${serachParams.toString()}`,
          },
        });
        setBradCrump3(title);
      }}
      className="cursor-pointer text-xs 2xl:text-sm bg-caspian-surface duration-300"
    >
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[20%]">
        <h2>{title}</h2>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 min-w-[100px] w-[20%]">
        <h2>{type.title}</h2>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 font-sans px-3 py-2 w-[20%]">
        <h2>{code}</h2>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[20%]">
        <h2>{secretary}</h2>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[20%]">
        <h2>{head}</h2>
      </td>
    </tr>
  );
};

export default SingleTableProfileMeeting;
