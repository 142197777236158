import React, { useEffect, useRef, useState } from "react";
import { PiX } from "react-icons/pi";
import { useLocation, useSearchParams } from "react-router-dom";
import StartDateTimePicker from "./StartDateTimePicker";
import EndDateTimePicker from "./EndDateTimePicker";

const FilterComponent = ({ setShowFilter, data, setFavorite, favorite }) => {
  const location = useLocation();
  let uniq = (item) => [...new Set(item)];
  const deactiveEnterSubmit = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const [serachParams, setSearchParams] = useSearchParams();

  const tagsRef = useRef();
  const langFaRef = useRef();
  const langEnRef = useRef();
  const startDateRef = useRef();
  const endDateRef = useRef();

  const tags = serachParams.get("tags");
  const langFa = serachParams.get("langFa");
  const langEn = serachParams.get("langEn");
  const startDate = serachParams.get("startDate");
  const endDate = serachParams.get("endDate");
  const view = serachParams.get("view");

  const [tagsList, setTagsList] = useState(tags ? tags : "");

  const [startDateValue, setStartDateValue] = useState(
    startDate ? startDate : undefined
  );
  const [endDateValue, setEndDateValue] = useState(
    endDate ? endDate : undefined
  );

  const [tag, setTag] = useState([]);
  let tagData = data?.filter((t) =>
    t?.tags?.length > 0 ? t?.tags?.length : []
  );
  tagData = tagData?.map((t) => t?.tags);
  tagData = tagData?.concat?.call(...tagData);
  let tagSearch = tagData?.filter((t) => t?.includes(tag));

  function handleFilterChange(key, value) {
    setSearchParams((prevParams) => {
      if (value === null || value === "") {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    });
  }

  useEffect(() => {
    if (startDateValue !== undefined) {
      handleFilterChange("startDate", new Date(startDateValue).toISOString());
    }
  }, [startDateValue, startDate]);

  useEffect(() => {
    if (endDateValue !== undefined) {
      handleFilterChange("endDate", new Date(endDateValue).toISOString());
    }
  }, [endDateValue, endDate]);

  const clearFilter = () => {
    handleFilterChange("page", 1);
    setTagsList("");
    setStartDateValue(undefined);
    setEndDateValue(undefined);
    handleFilterChange("startDate", "");
    handleFilterChange("endDate", "");
    handleFilterChange("tags", "");
    handleFilterChange("langFa", "");
    handleFilterChange("langEn", "");
    if (view) {
      location.search = "?type=all&sort=date&view=list";
    } else {
      location.search = "?type=all&sort=date";
    }
    setFilter(location);
    // if (startDate || endDate || tags || langFa || langEn) {
    setFavorite(1);
    // }
  };
  const [filter, setFilter] = useState(location);

  const filterSubmit = (e) => {
    e.preventDefault();
    handleFilterChange("page", 1);
    // if (startDate || endDate || tags || langFa || langEn) {
    setFavorite(1);
    setShowFilter(false);
    setFilter(location);
    // }
  };

  const close = () => {
    location.search = filter.search;
    setSearchParams(filter.search);
  };

  return (
    <div className="z-40 absolute top-8 right-0 bg-caspian-surface w-[300px] rounded-xl shadow-[0_0_10px_#00000050]">
      <form onSubmit={filterSubmit} onKeyDown={deactiveEnterSubmit}>
        <div className="flex items-center justify-between py-2 px-4">
          <h2 className="text-caspian-gray">فیلتر</h2>
          <button
            type="button"
            onClick={() => {
              setShowFilter(false);
              close();
            }}
          >
            <PiX className="text-xl" />
          </button>
        </div>

        <div className="flex flex-col">
          <div className="bg-caspian-gray3 p-2">
            <h2>کلیدواژه</h2>
          </div>
          <div className="p-4 pb-2">
            <div className="relative w-[90%] mx-auto">
              <input
                ref={tagsRef}
                onChange={(e) => {
                  setTag(e.target.value);
                }}
                type="text"
                placeholder="کلیدواژه"
                className="p-1.5 rounded-md outline outline-1 outline-caspian-gray w-full text-sm"
              />
              <div>
                {tag?.length > 0
                  ? tag?.length > 2 &&
                    uniq(tagSearch).map((t, i) => (
                      <div key={i} className="flex flex-col gap-2 text-xs">
                        <h2
                          onClick={() => {
                            handleFilterChange("tags", t);
                            setTagsList(t);
                            setTag("");
                            console.log(t);
                            tagsRef.current.value = "";
                          }}
                          className="p-2 border-b border-b-caspian-gray2 cursor-pointer text-left ltr hover:bg-caspian-primary-50"
                        >
                          #{t}
                        </h2>
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          </div>
          {tagsList?.length > 0 && (
            <div className="flex gap-0.5 justify-start flex-wrap px-2 pb-2">
              {
                <div className="flex items-center gap-0.5 p-0.5 rounded-md bg-caspian-primary-50">
                  <span className="text-caspian-primary-700 text-[12px]">
                    {tagsList}
                  </span>
                  <div
                    className="text-caspian-primary-400 flex items-center justify-center text-[12px] cursor-pointer"
                    onClick={() => {
                      setTagsList("");
                      handleFilterChange("tags", "");
                    }}
                  >
                    <PiX />
                  </div>
                </div>
              }
            </div>
          )}
        </div>

        <div className="flex flex-col">
          <div className="bg-caspian-gray3 p-2">
            <h2>زبان</h2>
          </div>
          <div className="px-4 py-2">
            <div className="">
              <ul className="flex items-center justify-around">
                <li className="flex items-center gap-4 text-sm">
                  <input
                    ref={langFaRef}
                    type="checkbox"
                    defaultChecked={langFa ? true : false}
                    onChange={() => {
                      langFaRef?.current?.checked
                        ? handleFilterChange("langFa", "fa")
                        : handleFilterChange("langFa", "");
                    }}
                    id={"fa"}
                  />
                  <label
                    htmlFor={"fa"}
                    className="text-caspian-gray cursor-pointer"
                  >
                    فارسی
                  </label>
                </li>
                <li className="flex items-center gap-4 text-sm">
                  <input
                    ref={langEnRef}
                    defaultChecked={langEn ? true : false}
                    type="checkbox"
                    onChange={() => {
                      langEnRef?.current?.checked
                        ? handleFilterChange("langEn", "en")
                        : handleFilterChange("langEn", "");
                    }}
                    id={"en"}
                  />
                  <label
                    htmlFor={"en"}
                    className="text-caspian-gray cursor-pointer"
                  >
                    انگلیسی
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <div className="bg-caspian-gray3 p-2">
            <h2>تاریخ بارگزاری</h2>
          </div>
          <div className="p-4 flex items-center justify-around gap-2">
            <div className="w-[80%] mx-auto">
              <StartDateTimePicker
                setStartDateValue={setStartDateValue}
                placeholder="از تاریخ"
              />
            </div>
            <div className="relative w-[80%] mx-auto">
              <EndDateTimePicker
                setEndDateValue={setEndDateValue}
                placeholder="تا تاریخ"
              />
            </div>
          </div>
        </div>

        <div className="p-4 bg-caspian-gray3">
          <div className="flex items-center justify-around gap-4">
            <button
              type="submit"
              className="w-full py-2 px-3 rounded-md outline outline-1 outline-caspian-secondary-600 text-caspian-secondary-600 text-sm"
            >
              اعمال
            </button>
            <button
              onClick={clearFilter}
              type="reset"
              className="w-full py-2 px-3 text-caspian-error text-sm"
            >
              حذف فیلترها
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FilterComponent;
