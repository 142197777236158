import React from "react";
import { PiCircleNotch } from "react-icons/pi";

const Loading = () => {
  return (
    <div className="h-screen w-full flex items-center justify-center bg-white fixed inset-0 z-50">
      <PiCircleNotch className="animate-spin text-4xl text-caspian-secondary-500" />
    </div>
  );
};

export default Loading;
