import Accordion from '../accordion/Accordion';
import { PiCheckBold, PiPencilSimpleLine } from "react-icons/pi";
import { PiGraduationCap, PiChalkboardTeacher, PiFilePlusDuotone } from "react-icons/pi";
import Education from '../accordion/accordion-content/education/Education';
import Knowledge from '../accordion/accordion-content/knowledge/Knowledge';
import Skill from '../accordion/accordion-content/skill/Skill';
import { useState } from 'react';
import EditAuthForm from './forms/EditAuthForm';
const Authentication = ({ id, positionRelatedCondition, setRefresh, user }) => {
    const [edit, setEdit] = useState(false);
    console.log(positionRelatedCondition?.requirementYear);
    return (
        <div className='flex flex-col gap-8 relative'>
            {
                user?.id === 207 && (
                    <div className=' absolute left-5 text-lg'>
                        <PiPencilSimpleLine className='cursor-pointer' onClick={() => setEdit(true)} />
                    </div>
                )
            }
            {
                user?.id === 207 && edit && <EditAuthForm id={id} setEdit={setEdit} item={positionRelatedCondition} setRefresh={setRefresh} />
            }
            <div className='flex items-center gap-x-4'>
                <span className='text-caspian-gray4'>جنسیت :</span>
                <div className='flex items-center text-xs xl:text-sm'>
                    <span>مرد</span>
                    {(positionRelatedCondition?.genderType === 'both' || positionRelatedCondition?.genderType === 'men') && <PiCheckBold />}
                </div>
                <div className='flex items-center text-xs xl:text-sm'>
                    <span>زن</span>
                    {(positionRelatedCondition?.genderType === 'both' || positionRelatedCondition?.genderType === 'woman') && <PiCheckBold />}
                </div>
            </div>

            <div className='border-y border-[#F0F3FD] py-4'>
                <span className='text-caspian-gray4'>شرایط فیزیکی :</span>
                <p className='py-3 pr-3 leading-8 text-xs 2xl:text-sm 2xl:leading-8'>
                    {positionRelatedCondition?.physical}
                </p>
            </div>


            <div className='flex basis-full'>
                <div className='basis-1/2'>
                    <span className='text-caspian-gray4'> سابقه کار مورد نیاز مرتبط : </span>
                    <span className='text-xs xl:text-sm'>
                        {positionRelatedCondition?.requirementYear === 0 ? 'بدون سابقه کار' :
                            positionRelatedCondition?.requirementYear !== null && positionRelatedCondition?.requirementYear !== undefined
                                ? positionRelatedCondition?.requirementYear + ' سال' : ''}
                    </span>
                </div>
                <div className='basis-1/2'>
                    <span className='text-caspian-gray4'> سابقه کار مورد قبول :</span>
                    <span className='text-xs xl:text-sm'>{positionRelatedCondition?.approvedYear === 0 ? 'بدون سابقه کار' :
                        positionRelatedCondition?.approvedYear !== null && positionRelatedCondition?.approvedYear!==undefined ? positionRelatedCondition?.approvedYear + ' سال' : ''}</span>
                </div>
            </div>


            <div className='border-y border-[#F0F3FD] py-4'>
                <span className='text-caspian-gray4'> توضیحات :</span>
                <p className='py-3 pr-3  leading-8 text-xs xl:text-sm xl:leading-8'>
                    {positionRelatedCondition?.describtion}
                </p>
            </div>


            <div className="accordion">
                <Accordion
                    title='تحصیلات مورد نیاز'
                    icon={<PiGraduationCap />}>
                    <Education positionOrientation={positionRelatedCondition?.positionOrientation} />
                </Accordion>


                <Accordion
                    title='دانش مورد نیاز'
                    icon={<PiChalkboardTeacher />}>
                    <Knowledge positionKnowledge={positionRelatedCondition?.positionKnowledge} />
                </Accordion>

                <Accordion
                    title='مهارت مورد نیاز'
                    icon={<PiFilePlusDuotone />}>
                    <Skill positionSkill={positionRelatedCondition?.positionSkill} />
                </Accordion>

            </div>

        </div >
    );
}

export default Authentication;
