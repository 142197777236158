import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ToolTip from './ToolTip';

const ButtonNavNew = ({ icon, url, active, buttonActvie,onClick,title,bg}) => {
    const [show,setShow]=useState(false);
    return (
        <div className='relative'>
        <Link
        onMouseEnter={()=>setShow(true)}
        onMouseLeave={()=>setShow(false)}
        onClick={onClick}
            to={url}
            className={`${active === buttonActvie ? 'text-caspian-primary-500' : ""
                } flex items-center justify-center w-10 h-10 rounded-full`}
        >
            <div className={`${active === buttonActvie ? 'text-[#3C77A2] border-2 border-[#3C77A2] rounded-full' : "bg-caspian-surface"
                } text-2xl rounded-full p-1.5`}
                style={{backgroundColor:buttonActvie===active?bg:''}}
                >{icon}</div>
        </Link>
        {
            show && <ToolTip title={title} />
        }
        </div>
    );
}

export default ButtonNavNew;
