import React, { useState } from "react";
import LibraryNavbar from "../components/library-components/LibraryNavbar";
import AllLibrary from "../components/library-components/all-library/AllMainLibrary";

const Library = ({ showMenu }) => {
  const [libraryDetails, setLibraryDetails] = useState(<AllLibrary />);

  return (
    <div
      className={
        showMenu
          ? `pl-[50px] mr-auto w-full md:w-[75%] lg:w-[80%] xl:w-[85%] 2xl:w-[85%] bg-caspian-gray3 p-1 overflow-y-scroll`
          : `pl-[50px] mr-[54px] w-full bg-caspian-gray3 p-1 overflow-y-scroll`
      }
    >
      <div className="flex flex-col w-full">
        <LibraryNavbar setLibraryDetails={setLibraryDetails} />
        <div className="p-4">{libraryDetails}</div>
      </div>
    </div>
  );
};

export default Library;
