import React from "react";
import MainChallengeSingle from "./MainChallengeSingle";

const MainChallenge = ({ data }) => {
  return (
    <div className="border border-caspian-gray2 rounded-lg">
      <div className="bg-[#FBFCFD] p-4 rounded-t-lg border-b border-caspian-gray2">
        <div>
          <h2 className="text-caspian-secondary-600">{data.description}</h2>
        </div>
      </div>
      <table className="min-w-full text-left text-sm">
        <thead className="bg-[#FBFCFD] p-4 border-b border-caspian-gray2">
          <tr>
            <th scope="col" className="text-right px-3 py-6 ">
              <h2 className="text-caspian-gray">عنوان</h2>
            </th>
            <th scope="col" className="text-right px-3 py-6">
              <h2 className="text-caspian-gray">نوع مشکل</h2>
            </th>
            <th scope="col" className="text-right px-3 py-6 w-2/3">
              <h2 className="text-caspian-gray">راهکار</h2>
            </th>
          </tr>
        </thead>
        <tbody>
          <MainChallengeSingle
            proplemTitle={data.title}
            problemType={
              data.description === "مشکلات کسب و کار" ||
              data.description === "نیازمندی کسب و کار"
                ? (data.businessType === "manually" && "سیستمی نبودن") ||
                  (data.businessType === "disintegration" && "عدم یکپارچگی") ||
                  (data.businessType === "sysyemPoorQuality" &&
                    "کیفیت کم سیستم") ||
                  (data.businessType === "resource" && "منابع")
                : (data.systemType === "documents" &&
                    "فرم ها و دستورالعمل ها") ||
                  (data.systemType === "jobDescription" && "شرح وظایف") ||
                  (data.systemType === "workflow" && "روند اجرای کار") ||
                  (data.systemType === "resource" && "منابع")
            }
            solution={data.solution}
          />
        </tbody>
      </table>
    </div>
  );
};

export default MainChallenge;
