import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/auth/AuthContext";
import API from "../../../api/API";
import Loading from "../../../Loading";
import MainListProfile from "./list-profile/MainListProfile";
import ChartComponent from "./chart-component/ChartComponent";

const MainAuditChart = () => {
  const { token } = useContext(AuthContext);
  const [data, setData] = useState([-1]);
  useEffect(() => {
      const fetchData=async()=>{
        try {
          const res=await API.get('/api/v1/audit/profile/detial/',{headers:{Authorization:token}})
          if(res?.data){
            setData(res?.data);
            console.log(res?.data);
          }
        } catch (error) {
          console.log(error);
        }
      }
      fetchData();
  }, [])
  return (
    // <div className="flex flex-col gap-2 relative">
    //   <span></span>
    // </div>
    <div>
      {
        data[0]===-1?<Loading />:data?.length<1?
        <p className="text-caspian-gray4 text-xs text-center pt-20">داده‌ای یافت نشد. </p>
        :<div className="flex gap-2">
          <MainListProfile data={data} />
          <ChartComponent data={data} />
        </div>
      }
    </div>
  );
};

export default MainAuditChart;
