
import React, { useContext, useEffect, useState } from "react"
import API from "../../../../api/API";
import Loading from "../../../../Loading";
import AuditListQuestion from "./list-question-item/AuditListQuestion";
import Modal from "./list-question-item/Modal";
import { PiPlusBold } from "react-icons/pi";
import { AuthContext } from "../../../../context/auth/AuthContext";
import SearchList from "./list-question-item/SearchList";
import { useSearchParams } from "react-router-dom";
import TableListQuestion from "./list-question-item/TableListQuestion";
import FilterItems from "./list-question-item/FilterItems";
import MainCreateFormQuestion from "../form-question/create-form-question-new/MainCreateFormQuestion";

const MainAuditListQuestion = () => {
  const { token } = useContext(AuthContext)
  const [data, setData] = useState([-1]);
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(true);

  // const [searchRes, setSearchRes] = useState([-1]);
  // const [ttileRes, setTitleRes] = useState([-1]);

  //quwation and criterion
  const [filterByQues, setFilterByQues] = useState('');
  const [filterByCri, setFilterByCri] = useState('');
  const [filterBysec, setFilterBySec] = useState('');
  const [filterByProcess, setFilterByProcess] = useState('');
  const [filterByActive,setFilterByActive]=useState([]);
  const [search, setSearch] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();
  // const searchQuery = searchParams.get('search')
  let page = searchParams.get('page');
  // console.log('pagee',page);
  // const rows = searchParams.get('rows');
  const [currentPage, setCurrentPage] = useState(page ? page : 1);
  // const [offset, setOffset] = useState(prev => {
  //   if (page && rows === 20) {
  //     return (page === 1 ? 0 : (page - 1) * 20)
  //   }
  //   else if (page && rows === 30) {
  //     return (page === 1 ? 0 : (page - 1) * 30)
  //   }
  //   else if (page) {
  //     return (page === 1 ? 0 : (page - 1) * 10)
  //   }
  //   else return 0
  // })
  // const [limit, setLimit] = useState(rows ? rows : 10);
  // // const [pageCount, setPageCount] = useState('');
  // const [search, setSearch] = useState(prev => {
  //   if (searchQuery) {
  //     return searchQuery;
  //   }
  // });
  // function handleSearch(key, value) {
  //   setSearchParams((prevParams) => {
  //     if (value === null || value === "") {
  //       prevParams.delete(key);
  //     } else {
  //       prevParams.set(key, value);
  //     }
  //     console.log(key,value);
  //     return prevParams;
  //   });
  // }
  // console.log(currentPage);
  // useEffect(() => {
  //   setRefresh(true);
  // }, [offset, limit])
  // useEffect(() => {
  //   if (data?.results?.length < 1 && currentPage !== 1) {
  // setOffset(offset - 1);
  //     setCurrentPage(currentPage - 1);
  //     handleSearch('page', page - 1);
  //   }
  // }, [refresh, offset, page,currentPage])
  // useEffect(() => {
  //   setOffset(prev => {
  //     if (page && rows === 20) {
  //       return (page === 1 ? 0 : (page - 1) * 20)
  //     }
  //     else if (page && rows === 30) {
  //       return (page === 1 ? 0 : (page - 1) * 30)
  //     }
  //     else if (page) {
  //       return (page === 1 ? 0 : (page - 1) * 10)
  //     }
  //     else return 0
  //   })
  // if(page){
  //   setCurrentPage(page)
  // }
  // }, [page, offset])
  // console.log('page',page);
  // console.log(refresh);
  // useEffect(() => {
  //   if (!token || !refresh) return;
  //   let res;
  //   const fetchData = async () => {
  //     try {
  //       if (search) {
  //         res = await API.get(`/api/v1/audit/question/detial/?limit=${limit}&offset=${offset}&search=${search}`, { headers: { Authorization: token } });
  //         setSearchRes(res.data);
  //         console.log(res?.data);
  //       }
  //       else {
  //         res = await API.get(`/api/v1/audit/question/detial/?limit=${limit}&offset=${offset}`, { headers: { Authorization: token } });
  //         setData(res.data);
  //         setSearchRes([-1]);
  //         setRefresh(false)
  //         console.log(res.data);
  //       }
  //     }
  //     catch (err) {
  //       if (err.response.status === 404) {
  //         console.log(err.message);
  //         setData([]);
  //       }
  //     }
  //   }
  //   fetchData();
  // }, [token, refresh, limit, offset, search])

  // useEffect(() => {

  //   const fetchData = async () => {
  //     let res;
  //     try {
  //       if (filterByQues?.length > 2 || filterByCri?.length > 2) {
  //         if (search) {
  //           res = await API.get(`/api/v1/audit/question/detial/?title=${filterByQues}&criteriaon=${filterByCri}
  //             &limit=${limit}&offset=${offset}&search=${search}`);
  //           console.log(res.data);
  //         }
  //         else {
  //           res = await API.get(`/api/v1/audit/question/detial/?title=${filterByQues}&criteriaon=${filterByCri}
  //             &limit=${limit}&offset=${offset}`);
  //           console.log(res.data);
  //         }
  //         setTitleRes(res.data)
  //       }
  //       else {
  //         setTitleRes([-1])
  //       }
  //     }
  //     catch (err) {
  //       if (err.response.status === 404) {
  //         console.log(err.message);
  //         setData([])
  //       }
  //     }
  //   }
  //   const time = setTimeout(() => {
  //     fetchData();
  //   }, 1000)

  //   return () => {
  //     clearTimeout(time)
  //   }

  // }, [filterByQues, filterByCri, search, offset, limit, token])
  useEffect(() => {
    if (!token || refresh === false) return
    const fetchData = async () => {
      const res = await API.get('/api/v1/audit/question/detial/', { headers: { Authorization: token } });
      setRefresh(false);
      setData(res?.data);
      console.log(res.data);
    }
    fetchData();
  }, [refresh, token])
  return (
    <div className="flex flex-col gap-2 py-5  xl:px-16">
      <button onClick={() => { setShowModal(true) }} id='createQuestion' className="flex gap-2 bg-[#3C77A2] text-caspian-surface py-3 px-3 text-sm rounded-lg
        items-center w-fit">
        <PiPlusBold />
        <span>ایجاد سوال ممیزی</span>
      </button>
      <div>
        <FilterItems
          search={search} setSearch={setSearch} setCurrentPage={setCurrentPage} 
          filterBysec={filterBysec} setFilterBySec={setFilterBySec} filterByProcess={filterByProcess}
          setFilterByProcess={setFilterByProcess} filterByActive={filterByActive} setFilterByActive={setFilterByActive}
          // setOffset={setOffset}
          setRefresh={setRefresh} />
      </div>

      {
        // showModal && (<Modal setShowModal={setShowModal} setRefresh={setRefresh} />)
        showModal && <MainCreateFormQuestion setShowModal={setShowModal} setRefresh={setRefresh} />
      }
      <div>
      </div>
      {
        // (!search && data[0] === -1)
        data[0] === -1 ? <Loading /> : data?.length < 1 ? (<p className="text-center py-20 text-xs 2xl:text-sm font-bold text-caspian-gray4">داده وجود ندارد.</p>) :
          <TableListQuestion
            data={data}
            // data={(ttileRes[0] !== -1) ? ttileRes : search ? searchRes : data}
            // offset={offset} setOffset={setOffset} limit={limit} setLimit={setLimit}
            refresh={refresh} setRefresh={setRefresh}
            currentPage={currentPage} setCurrentPage={setCurrentPage}
            search={search} filterBysec={filterBysec} setFilterBySec={setFilterBySec}
            filterByCri={filterByCri} setFilterByCri={setFilterByCri} setFilterByQues={setFilterByQues} filterByQues={filterByQues}
            filterByProcess={filterByProcess} setFilterByProcess={setFilterByProcess} filterByActive={filterByActive}
            setFilterByActive={setFilterByActive}
          />
      }
    </div>
  );

};

export default MainAuditListQuestion;
