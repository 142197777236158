import React, { useState } from "react";
import { PiCaretLeftBold } from "react-icons/pi";
import IdProcessInputBox from "./IdProcessInputBox";

const IdProcess = ({ data }) => {
  const [showCollapse, setShowCollapse] = useState(false);
  return (
    <div className="w-full bg-caspian-surface rounded-xl shadow-md">
      <div
        onClick={() => setShowCollapse(!showCollapse)}
        className="w-full flex items-center justify-between p-3 cursor-pointer"
      >
        <div className="flex items-center gap-2 text-caspian-secondary-600">
          <span className="font-medium">کلیات فرایند</span>
        </div>
        <button>
          <PiCaretLeftBold
            className={
              showCollapse ? "-rotate-90 duration-300" : "rotate-0 duration-300"
            }
          />
        </button>
      </div>
      {showCollapse && (
        <div className="p-3">
          <div className="border border-caspian-gray2 rounded-lg overflow-hidden">
            <div className="bg-[#FBFCFD] rounded-b-lg">
              <div className="px-4 py-6">
                <div className="flex items-center gap-2">
                  <div className="flex items-center gap-4 w-1/2">
                    <span className="text-caspian-gray text-sm">حوزه</span>
                    <h2 className="text-sm">{data?.groupCategoryRelated}</h2>
                  </div>
                  <div className="flex items-center gap-4">
                    <span className="text-caspian-gray text-sm">گروه</span>
                    <h2 className="text-sm">{data?.processGroupRelated}</h2>
                  </div>
                </div>
              </div>
            </div>

            <IdProcessInputBox
              title="هدف فرایند"
              defaultValue1={
                data.processGoal !== null ? data.processGoal : "توضیحات"
              }
            />
            <IdProcessInputBox
              className="bg-[#FBFCFD]"
              title="ماموریت فرایند"
              defaultValue1={
                data.processMission !== null ? data.processMission : "توضیحات"
              }
            />
            <IdProcessInputBox
              title="محرک فرایند"
              defaultValue1={
                data.processMotivator !== null
                  ? data.processMotivator
                  : "توضیحات"
              }
              // defaultValue1="طرح/پروژه"
              // defaultValue2="ایجاد منشور پروژه"
              // defaultValue3="انجام عملیات اجرایی"
              // defaultValue4=" میزان مشخصی از تاخیر پس از اعلام دفتر مجازی PMO"
            />
            <IdProcessInputBox
              className="bg-[#FBFCFD]"
              title="متولی"
              defaultValue1={
                data.processResponsibleJanamai !== null
                  ? data.processResponsibleJanamai
                  : "توضیحات"
              }
            />
            <IdProcessInputBox
              className="rounded-b-xl"
              title="ذینفع"
              defaultValue1={
                data.processStackHolder !== null
                  ? data.processStackHolder
                  : "توضیحات"
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default IdProcess;
