import React from 'react'

const ThreeLineModelStackholder = ({ stackData, stackDataLenght1, stackDataLenght2 }) => {

    return (
        <div className={`flex flex-col justify-around relative -top-4
                 ${(stackDataLenght2?.length === 3 && 'items-end') || (stackDataLenght1?.length === 3 && 'items-start')}`}>

            <div className='flex items-center justify-center right-2 relative basis-1/2 '>
                <div className='border-r-2 w-2 h-9 border-dashed border-caspian-darkgray-600 absolute bottom-2 xl:bottom-2'></div>
                <span className='font-bold text-xs 2xl:text-xl whitespace-nowrap absolute text-caspian-on_primary/80 -top-1 2xl:-top-3'>
                    {stackDataLenght1?.slice(0, 1)?.map((item) => (
                        item?.categoryType === 'internal' ? " درون سازمانی " : item?.categoryType === 'external' && " برون سازمانی "
                    ))}

                    {stackDataLenght2?.slice(0, 1)?.map((item) => (
                        item?.categoryType === 'internal' ? " درون سازمانی " : item?.categoryType === 'external' && " برون سازمانی "
                    ))}

                </span>
                <div className='border-r-2 w-2 h-5 border-dashed border-caspian-darkgray-600 absolute top-6'></div>
            </div>

            <div className={`border-b-2 h-2 flex top-10 right-2 relative border-dashed border-caspian-darkgray-600
                        ${(stackDataLenght2?.length === 3 && '-translate-x-1/2') || (stackDataLenght1?.length === 3 && 'translate-x-1/2')}
                        ${stackData?.length === 5 ? ' w-[81%]' : stackData?.length === 6 ? ' w-[67%]' : 'w-full'}
                        `}>
                <div className='border-r-2 h-9 absolute top-2 left-0 border-dashed border-caspian-darkgray-600'></div>
                <div className='border-r-2 h-9 absolute top-2 right-0 border-dashed border-caspian-darkgray-600'></div>
                <div className='border-r-2 h-9 absolute top-2 right-[49.5%] border-dashed border-caspian-darkgray-600'></div>
            </div>
        </div>
    )
}

export default ThreeLineModelStackholder