import React from "react";
import { useNavigate } from "react-router-dom";

const Title = ({ id, title, profileTitle, status, is_secretary }) => {
  console.log(status);
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="flex items-center justify-between">
        <h1 className="text-xs font-bold">{title}</h1>
        {is_secretary && status === "wait" && (
          <button
            onClick={() => {
              navigate(
                `/managment-of-meetings/list-meeting/all-meeting/${id}?meeting=run`
              );
            }}
            className="rounded-md py-2 px-6 text-xs border border-caspian-secondary-500 text-caspian-secondary-500"
          >
            برگزاری جلسه
          </button>
        )}
      </div>
      <h2 className="text-caspian-gray text-xs">{profileTitle}</h2>
    </div>
  );
};

export default Title;
