import React from "react";

const StepOneForm = ({
  title,
  setTitle,
  description,
  setDescription,
  titleRef,
}) => {
  return (
    <div>
      <div className="flex flex-col gap-2">
        {/* عنوان */}
        <div className="w-1/2 flex flex-col gap-1.5">
          <label className="text-xs text-caspian-gray">عنوان</label>
          <input
            ref={titleRef}
            name="title"
            defaultValue={title}
            onChange={(e) => setTitle(e.target.value)}
            type="text"
            placeholder="عنوان برنامه اجرایی را وارد کنید"
            className="outline-none border border-caspian-gray1 p-1.5 text-xs rounded-md"
          />
        </div>
        {/* توضیحات */}
        <div className="w-full flex flex-col gap-1.5">
          <label className="text-xs text-caspian-gray">توضیحات</label>
          <textarea
            name="description"
            defaultValue={description}
            rows={8}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="توضیحات برنامه اجرایی را وارد کنید"
            className="resize-none outline-none border border-caspian-gray1 p-1.5 text-xs rounded-md"
          />
        </div>
      </div>
    </div>
  );
};

export default StepOneForm;
