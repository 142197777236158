import React from 'react'
import { useNavigate } from 'react-router-dom';

const SingListPopUpModelStackholder = ({ section }) => {

    const navigate = useNavigate()
    
    return (
        <div className='flex w-full'>
            <span
                onClick={() => navigate('/stackholders/' + section?.id)}
                style={{ background: `#${section?.colorHex}` }}
                className='cursor-pointer text-xs 2xl:text-sm py-2 text-center w-full z-50 rounded-lg text-caspian-on_primary '>
                {section?.title}
            </span>
        </div>
    )
}

export default SingListPopUpModelStackholder;