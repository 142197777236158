import React from "react";

const MemberList = ({ fullData }) => {
  return (
    <div className="p-3 flex flex-col gap-3">
      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-0.5">
            <h2 className="text-xs font-bold">
              {fullData?.chairman?.placementProfileRelated?.firstName +
                " " +
                fullData?.chairman?.placementProfileRelated?.lastName}
            </h2>
            <span className="text-xs text-caspian-gray4">
              {fullData?.chairman?.placementPositionRelated?.title}
            </span>
          </div>
          <span className="border border-[#01BC8D] text-[#01BC8D] text-xs rounded-md px-3 py-1 w-full min-w-[30%] max-w-[40%] text-center">
            رئیس جلسه
          </span>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-0.5">
            <h2 className="text-xs font-bold">
              {fullData?.secretary?.placementProfileRelated?.firstName +
                " " +
                fullData?.secretary?.placementProfileRelated?.lastName}
            </h2>
            <span className="text-xs text-caspian-gray4">
              {fullData?.secretary?.placementPositionRelated?.title}
            </span>
          </div>
          <span className="border border-[#3C77A2] text-[#3C77A2] text-xs rounded-md px-3 py-1 w-full min-w-[30%] max-w-[40%] text-center">
            دبیر جلسه
          </span>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-0.5">
            <h2 className="text-xs font-bold">
              {fullData?.viceChairman?.placementProfileRelated?.firstName +
                " " +
                fullData?.viceChairman?.placementProfileRelated?.lastName}
            </h2>
            <span className="text-xs text-caspian-gray4">
              {fullData?.viceChairman?.placementPositionRelated?.title}
            </span>
          </div>
          <span className="border border-[#FF5B5B] text-[#FF5B5B] text-xs rounded-md px-3 py-1 w-full min-w-[30%] max-w-[40%] text-center">
            جانشین اول
          </span>
        </div>
      </div>
      <div className="flex flex-col gap-4 border-t border-t-caspian-gray2 pt-3">
        {fullData?.members?.map((m, i) => (
          <div key={i} className="flex items-center gap-4">
            <h2 className="text-xs basis-1/4">
              {m?.placementProfileRelated?.firstName +
                " " +
                m?.placementProfileRelated?.lastName}
            </h2>
            <span className="text-xs text-caspian-gray">
              {m?.placementPositionRelated?.title}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MemberList;
