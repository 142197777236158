import React, { useEffect } from "react";
import { useAppContext } from "../../context/app-context/AppContext";
import { Link } from "react-router-dom";

const ListItemSingle = ({
  active,
  title,
  icon,
  onClick,
  link,
  buttonActive,
}) => {
  const { setNavbarIdProcessActive } = useAppContext();
  useEffect(() => {
    if (active === "chat") {
      setNavbarIdProcessActive("chat");
    } else if (active === "saved") {
      setNavbarIdProcessActive("saved");
    }
  }, [active, setNavbarIdProcessActive]);

  return (
    <li>
      <Link
        onClick={onClick}
        to={link}
        className={`${
          buttonActive === active
            ? "text-caspian-secondary-500 border-b-2 border-b-caspian-secondary-500 font-bold"
            : "border-b-2 border-b-caspian-transparent text-caspian-gray"
        } flex items-center gap-4 px-4 py-2 cursor-pointer`}
      >
        <div
          className={`${
            buttonActive === active ? "font-bold" : "text-caspian-gray"
          } text-2xl`}
        >
          {icon}
        </div>
        <span className="text-sm">{title}</span>
      </Link>
    </li>
  );
};

export default ListItemSingle;
