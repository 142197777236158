import React, { useEffect, useRef, useState } from "react";
import { PiFunnel, PiFunnelFill } from "react-icons/pi";

export const uniqueArray = (arr) => [...new Set(arr)];

const Filter = ({ data, filterByPdca, setFilterByPdca }) => {
    let menuRef = useRef();

    const [open, setOpen] = useState(false);
    useEffect(() => {
        let handler = (e) => {
            if (!menuRef.current.contains(e.target)) {
                setOpen(false);
            }
        };
        document.addEventListener("mousedown", handler);

        return () => {
            document.removeEventListener("mousedown", handler);
        };
    });

    const filterSubmit = (e) => {
        if (e.target.checked) {
            setFilterByPdca([...filterByPdca, e.target.value]);
        } else if (!e.target.checked) {
            setFilterByPdca(filterByPdca.filter((f) => f !== e.target.value));
        }
    };
    // console.log(filterByPdca);

    return (
        <div ref={menuRef} className=" absolute top-1/2 -translate-y-1/2 left-5 w-fit">
            <div onClick={() => setOpen(!open)} className="relative cursor-pointer">
                {filterByPdca.length > 0 ? (
                    <PiFunnelFill className="text-xl text-caspian-surface cursor-pointer" />
                ) : (
                    <PiFunnel className="text-xl text-caspian-surface cursor-pointer" />
                )}
            </div>
            {/* Start Open Menu */}
            <div
                className={
                    open
                        ? "z-50 overflow-y-scroll absolute top-5 left-0 flex items-center flex-col drop-shadow-[0_0_1px_#00000050] w-[100px] max-h-[180px] bg-caspian-surface rounded-lg bg-scroll"
                        : "hidden"
                }
            >
                {/* Start Box Menu */}
                <div className="flex flex-col items-start gap-4 w-full p-1">
                    <ul className="flex flex-col items-start gap-4 w-full p-1 bg-white rounded-sm">
                        {uniqueArray(data).map((d, i) => (
                            <li
                                key={i}
                                className="flex items-center gap-2 text-xs font-semibold text-caspian-primary-500 w-full"
                            >
                                <input
                                    type="checkbox"
                                    value={d !== null && d}
                                    id={d?.toString() + i?.toString()}
                                    onChange={filterSubmit}
                                    className="accent-blue-300"
                                />
                                <label
                                    className="cursor-pointer"
                                    htmlFor={d?.toString() + i?.toString()}
                                >
                                    {d}
                                </label>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            {/* End Open Menu */}
        </div>
    );
};

export default Filter;
