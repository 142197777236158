import React, { useEffect, useState } from "react";
import { PiCaretLeftBold } from "react-icons/pi";

const Presentations = ({
  fullData,
  showAll,
  hideAll,
  setShowAll,
  setHideAll,
}) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (showAll) {
      setShow(true);
    }
  }, [showAll]);
  useEffect(() => {
    if (hideAll) {
      setShow(false);
    }
  }, [hideAll]);
  useEffect(() => {
    setShowAll(false);
  }, [show, setShowAll]);
  useEffect(() => {
    setHideAll(false);
  }, [show, setHideAll]);
  console.log(fullData);
  return (
    <div className="w-full flex flex-col gap-0 border border-caspian-gray2 rounded-md overflow-hidden">
      <div
        onClick={() => setShow(!show)}
        className="bg-caspian-gray3 p-3 w-full border-b border-b-caspian-gray2 flex items-center justify-between cursor-pointer"
      >
        <h2 className="text-xs">فایل ارائه</h2>
        <PiCaretLeftBold
          className={show ? "-rotate-90 duration-500" : "rotate-0 duration-500"}
        />
      </div>
      {show && (
        <div className="p-3 w-full flex flex-col gap-3 h-auto">
          {fullData?.map((f, i) => (
            <a
              href={f.document}
              download={f.document}
              key={i}
              className="flex items-center gap-3 w-fit"
              rel="noreferrer"
              target="_blank"
            >
              <h2 className="text-xs font-bold ltr">
                {f.document.split("/").pop()}
              </h2>
              {/* <span className="text-xs text-caspian-gray4">{f.id}MB</span> */}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default Presentations;
