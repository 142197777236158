import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../../../context/auth/AuthContext';
import API from '../../../../../../api/API';
import FilterProcess from '../../create-form-question/FilterProcess';
import { PiPlusBold, PiX } from 'react-icons/pi';
import ListActivity from './ListActivity';

const ProcessModal = ({ setFormdata2, setList2, setShowModal2, idPost, q, setQ }) => {
    const { token } = useContext(AuthContext);
    const [processList, setProcessList] = useState([]);
    const [process, setProcess] = useState("");
    const [processSelected, setProcessSelected] = useState(null);
    const [showListProcess, setShowListProcess] = useState(false);

    const [showActivity2, setShowActivity2] = useState(false);
    const [activitiesSelected2, setActivitiesSelected2] = useState([]);
    const [activitiesFinalSelected2, setActivitiesFinalSelected2] =
        useState(activitiesSelected2);
    const [actObj, setActObj] = useState([])
    const [finalActObj, setFinalActObj] = useState([])

    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        if (!token) return
        const fetchProcess = async () => {
            try {
                const res = await API.get('/api/v1/businessProcess/process/');
                setProcessList(res.data);
                setActivitiesFinalSelected2('');
                console.log(res.data);
            }
            catch (err) {
                console.log(err.message);
            }
        }
        fetchProcess();
    }, [token, process])
    console.log(finalActObj);
    const handleProcess = async (id) => {
        console.log(id);
        // setList2([{
        //     processSelected,
        //     activity:actObj
        // }])
        setShowModal2(false)
        if (!id) return
        let formData = {
            questionRelated: id,
            processProfileRelated: process,
            processActivityRelated: activitiesFinalSelected2
        };
        try {
            const res = await API.post('/api/v1/audit/question/process/', formData, { headers: { Authorization: token } });
            if (res.data) {
                console.log('res process', res.data);
                setQ(1);
                setShowModal2(false);
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        if (activitiesSelected2?.length > 0 && process) {
            setIsDisabled(true);
        }
        else {
            setIsDisabled(false);
        }
    }, [process, activitiesSelected2])
    return (
        <div className='fixed inset-0 z-[150] bg-caspian-on_primary/30 flex items-center justify-center'>
            <div className='bg-caspian-surface w-[30%] rounded-md'>
                <div className='flex justify-between items-center px-5 py-2'>
                    <span>افزودن فرآیند و فعالیت مرتبط</span>
                    <PiX className='cursor-pointer' onClick={() => setShowModal2(false)} />
                </div>
                <div className='flex flex-col gap-12 border-t border-caspian-gray2'>
                    <div className='flex flex-col gap-2 px-5 pt-3'>
                        {/* process */}
                        <FilterProcess
                            lable="فرآیند"
                            processList={processList}
                            process={process}
                            setProcess={setProcess}
                            processSelected={processSelected}
                            setProcessSelected={setProcessSelected}
                            showListProcess={showListProcess}
                            setShowListProcess={setShowListProcess}
                        />
                    </div>
                    <div className='px-5 pb-3'>
                        {/* activity */}
                        <div
                            onClick={() => setShowActivity2(true)}
                            className='cursor-pointer border border-[#3C77A2] rounded-md py-2 p-3 '>
                            <div className='flex justify-between items-center'>
                                <p className='text-[#3C77A2] flex items-center gap-2 text-sm'>
                                    <PiPlusBold />
                                    <span>انتخاب</span>
                                </p>
                                {activitiesFinalSelected2?.length > 0 ? (
                                    <span className="text-xs">
                                        {activitiesFinalSelected2?.length} فعالیت افزوده شد
                                    </span>
                                ) : (
                                    <span className="text-xs">فعالیتی انتخاب نشده</span>
                                )}
                            </div>
                            {showActivity2 && (
                                <ListActivity
                                    finalSelected={activitiesFinalSelected2}
                                    setFinalSelected={setActivitiesFinalSelected2}
                                    selected={activitiesSelected2}
                                    setSelected={setActivitiesSelected2}
                                    setShowSection={setShowActivity2}
                                    showSection={showActivity2}
                                    title="لیست واحد ها"
                                    url={`/api/v1/profileProcess/profile/${process}/`}
                                    actObj={actObj}
                                    setActObj={setActObj}
                                    setFinalActObj={setFinalActObj}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className='flex gap-10 text-sm 2xl:text-xs px-5 pt-5 pb-2'>
                    {/* button */}
                    <button
                        onClick={() => handleProcess(idPost)}
                        className={`
                        ${!isDisabled ? 'bg-caspian-gray4 cursor-not-allowed pointer-events-none text-caspian-gray2' : 'bg-caspian-secondary-500 text-caspian-surface'}
                        p-1.5 px-3 rounded-md`}
                    >تایید</button>
                    <button className='text-caspian-error2' onClick={() => setShowModal2(false)}>لغو</button>
                </div>
            </div>
        </div>
    );
}

export default ProcessModal;
