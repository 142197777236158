import React from "react";

const ZoneTitle3 = ({ title }) => {
  return (
    <div className="flex items-center gap-1 w-full">
      <div className="flex items-center gap-0 w-full">
        <div className={`bg-[#1F7957] h-[2px] w-full`}></div>
        <div className={`bg-[#1F7957] w-[10px] h-[10px] rounded-full`}></div>
      </div>
      <h2 className={`text-[#1F7957] text-[18px] whitespace-nowrap`}>{title}</h2>
    </div>
  );
};

export default ZoneTitle3;
