import React from "react";

const Line = ({ color }) => {
  return (
    <div
      className={`${
        color ? color : "bg-caspian-primary-600"
      } h-[2px] w-full relative`}
    >
      <div
        className={`${
          color ? color : "bg-caspian-primary-600"
        } w-[8px] h-[8px] rounded-full absolute right-0 top-1/2 -translate-y-1/2`}
      ></div>
    </div>
  );
};

export default Line;
