import React, { useContext, useState } from "react";
import { PiCheckCircleFill, PiTrash, PiXCircleFill } from "react-icons/pi";
import API from "../../../../../../api/API";
import { AuthContext } from "../../../../../../context/auth/AuthContext";

const SingleListReminder = ({ id, members, date, time, post, setRefresh }) => {
  const { token } = useContext(AuthContext);

  const [showIsDelete, setShowIsDelete] = useState(false);

  const removeHandler = () => {
    API.delete(`/api/v1/meeting/info/reminder/${id}/`, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        setRefresh(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <tr className="bg-caspian-surface border-t border-t-caspian-gray1 min-w-full">
      <td className="p-2">
        {members}{" "}
        <span className="text-caspian-gray1 text-[10px]">
          {post !== undefined && post}
        </span>
      </td>
      <td className="p-2">{new Date(date).toLocaleDateString("fa-IR")}</td>
      <td className="p-2">{time}</td>
      <td className="p-2 w-20">
        <div className="w-full flex items-center justify-center gap-1 relative mx-auto">
          <button
            onClick={async () => {
              await setShowIsDelete(true);
            }}
            className="text-caspian-error2/30 hover:text-caspian-error2 duration-300 text-lg"
          >
            <PiTrash />
          </button>
          <div
            className={
              showIsDelete
                ? "absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center mx-auto gap-0.5 w-fit bg-caspian-surface shadow-[0_0_5px_#00000050] p-0.5 rounded-md duration-500"
                : "hidden duration-500"
            }
          >
            <PiCheckCircleFill
              onClick={() => {
                removeHandler();
                setShowIsDelete(false);
              }}
              className="text-caspian-primary-600 text-lg cursor-pointer"
            />
            <PiXCircleFill
              onClick={() => {
                setShowIsDelete(false);
              }}
              className="text-caspian-gray text-lg cursor-pointer"
            />
          </div>
        </div>
      </td>
    </tr>
  );
};

export default SingleListReminder;
