import React, { useEffect, useState } from 'react'
import { PiX, PiCloudArrowUp, PiWarningCircleBold } from 'react-icons/pi'
import ListUploadFile from './ListUploadFile'
import UploadFileCol from './UploadFileCol'
import TypeList from '../library-components/forms/TypeList'
import ArticleForms from '../library-components/forms/ArticleForms'
import BookForm from '../library-components/forms/BookForm';
import StandardForm from '../library-components/forms/StandardForm';
import FilmForm from '../library-components/forms/FilmForm';
import PodcastForm from '../library-components/forms/PodcastForm';

const UploadFileRow = ({ setShowModal, setShowSuccess, type, setType, typeList, setTypeList, typeSelected, setTypeSelected }) => {
    const [isDisable, setIsDisable] = useState(false)
    const [file, setFile] = useState(null)
    const [listFile, setListFile] = useState([])
    const [showFiles, setShowFiles] = useState(listFile.length > 0)
    const [showForm, setShowForm] = useState('');
    const [showArticle, setShowArticle] = useState(true);
    const [showBook, setShowBook] = useState(true);
    const [showStandard, setShowStandard] = useState(true);
    const [showFilm, setShowFilm] = useState(true);
    const [showPodcast, setShowPodcast] = useState(true);
    useEffect(() => {
        const handleFile = async () => {

            if (!file) return

            console.log(file)
            setShowFiles(true)
            setIsDisable(false)


            setListFile((prevData) => [...prevData, file])
            setFile(null)
        }

        handleFile()
    }, [file])

    useEffect(() => {
        if (listFile.length === 0) {
            setShowFiles(false)
            setIsDisable(true)
        }
    }, [listFile])
    useEffect(() => {
        if (listFile?.length > 0 && type) {
            setShowForm(type);
            switch (type) {
                case 'article': setShowArticle(true)

                    break;
                case 'book': setShowBook(true)

                    break;
                case 'standard': setShowStandard(true)

                    break;
                case 'film': setShowFilm(true)

                    break;
                case 'audio': setShowPodcast(true)

                    break;

                default:
                    break;
            }
        }
    }, [type, listFile, setShowForm])
    console.log('showForm', type);
    console.log("file", listFile);
    return (

        <div className="fixed inset-0 flex items-center justify-center w-full h-full z-50">
            <div className='fixed inset-0 bg-caspian-on_primary/30 z-50 p-4' ></div>
            <div className="z-50 gap-2 fixed flex flex-col items-end rounded-lg shadow-[0_0_10px_#00000070] bg-caspian-surface justify-center w-8/12 h-4/5">

                <div className='flex justify-end p-8'>
                    <span onClick={() => {
                        setShowForm('')
                        setType('')
                        setShowModal(false)
                    }}><PiX className='text-xl cursor-pointer' /></span>
                </div>

                <div className='flex flex-col items-center w-full h-full  gap-2'>
                    {/* {showFiles ? (<UploadFileCol setFile={setFile} />) : ( */}
                    <div className='flex flex-col items-center h-full w-full gap-5'>
                        <div className='flex items-center gap-4 flex-col w-full'>
                            <span className='text-xs xl:text-xl'>بارگذاری فایل</span>
                            <span className='text-xs xl:text-sm text-caspian-on_primary/60'>دراین قسمت میتوانید فایل‌های خود را بارگزاری کنید</span>
                        </div>
                        {/* type list */}
                        <div className='w-[200px]'>
                            <TypeList
                                type={type}
                                setType={setType}
                                typeList={typeList}
                                setTypeList={setTypeList}
                                typeSelected={typeSelected}
                                setTypeSelected={setTypeSelected}
                                lable='انتخاب نوع فایل'
                            />
                        </div>

                        {/* {showFiles ? (<UploadFileCol setFile={setFile} />) : */}

                            {/* ( */}
                                <div className={`${showFiles?'flex px-3 gap-6 h-2/3':'flex flex-col h-2/3 justify-center gap-4'}  w-1/2 items-center rounded-lg border-dashed border-2 border-caspian-on_primary/25 `}>
                                <PiCloudArrowUp className='text-[44px] text-caspian-on_primary/40' />

                                <div className='flex flex-col gap-2'>
                                    <span className='text-xs xl:text-sm '>لطفا فایل خود را انتخاب کنید</span>
                                    <span className='text-xs text-caspian-on_primary/40 '>فایل xls و حداکثر تا 20 مگابایت</span>
                                </div>

                                <input disabled={!type ? true : false} type='file' id='upload-fila' className='hidden peer' onChange={(e) => setFile(e.target.files[0])} />
                                <label htmlFor='upload-fila' className={`${showFiles?'mr-auto':''} border-2 border-caspian-secondary-600 rounded-lg text-caspian-secondary-600 text-xs p-3
                                peer-disabled:text-caspian-gray peer-disabled:border peer-disabled:border-caspian-gray`}>انتخاب فایل</label>
                            </div>
                            {/* )} */}
                    </div>
                    {/* // )
                    // } */}
                    {showFiles && (<ListUploadFile listFile={listFile} setListFile={setListFile} file={file} />)}

                </div>

                {
                    showForm === 'article' && showArticle ? <ArticleForms setShowForm={setShowForm} setType={setType} setShowArticle={setShowArticle} />
                        : showForm === 'book' && showBook ? <BookForm setShowForm={setShowForm} setType={setType} setShowBook={setShowBook} />
                            : showForm === 'standard' && showStandard ? <StandardForm setShowForm={setShowForm} setType={setType} setShowStandard={setShowStandard} />
                                : showForm === 'film' && showFilm ? <FilmForm setShowForm={setShowForm} setType={setType} setShowFilm={setShowFilm} />
                                    : showForm === 'audio' && showPodcast ? <PodcastForm setShowForm={setShowForm} setType={setType} setShowPodcast={setShowPodcast} />
                                        : ''
                }

                <div className='flex p-4 items-center justify-between w-full rounded-b-lg bg-caspian-darkgray-50'>

                    <div className='flex gap-5  items-center rounded-b-lg '>
                        <button
                            disabled={isDisable ? true : false}
                            className='text-xs xl:text-sm  disabled:border-caspian-darkgray-300 disabled:text-caspian-darkgray-600 duration-700  border-2 border-caspian-secondary-600 text-caspian-secondary-600 py-3 px-6 rounded-lg'
                            onClick={() => { setShowSuccess(true); setShowModal(false) }}
                        >
                            ارسال
                        </button>

                        <button
                            disabled={isDisable ? true : false}
                            className='text-xs xl:text-sm  disabled:text-caspian-darkgray-400  text-caspian-error2 py-2 px-4 rounded-lg'
                            onClick={() => setShowModal(false)}
                        >
                            لغو
                        </button>
                    </div>

                    <p className='px-2 flex items-center text-xs xl:text-sm text-caspian-gray4'>
                        <PiWarningCircleBold className='px-1 text-2xl' />
                        برای دریافت فرمت فایل بارگذاری اطلاعات
                        <a className='text-xs xl:text-sm px-1 text-caspian-secondary-600  underline underline-offset-4  decoration-caspian-secondary-600' href='/assets/files/فایل راهنما.pdf' download={true} > اینجا </a>
                        کلیک کنید
                    </p>

                </div>

            </div>
        </div>
    )
}

export default UploadFileRow;