import React, { useEffect, useState } from "react";
import { PiQuestion } from "react-icons/pi";
import NotificationDropDown from "./notification/NotificationDropDown";
import Cookies from "js-cookie";
import { copyToClipboard } from "../../helper/copyToClipboard";

const Notices = () => {
  const [showNotification, setShowNotification] = useState(false);
  const refresh = () => {
    let refresh = Cookies.get("refresh");
    copyToClipboard(refresh);
    setShowNotification(true);
  };
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.key === "q") {
        e.preventDefault();
        refresh();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (showNotification) {
      const notificationHide = setTimeout(() => {
        setShowNotification(false);
      }, 2000);
      return () => {
        clearTimeout(notificationHide);
      };
    }
  }, [showNotification]);

  return (
    <div className="flex items-center gap-4 ml-12">
      {showNotification && (
        <div className="fixed bottom-10 left-1/2 -translate-x-1/2 bg-caspian-green-700/10 text-caspian-green-700 border border-caspian-green-700 p-2 rounded-md flex items-center justify-center">
          <span className="text-xs">توکن کپی شد</span>
        </div>
      )}
      {/* <button>
        <PiEnvelopeSimple className="text-2xl text-caspian-secondary-700" />
      </button> */}
      <NotificationDropDown />
      <button onClick={refresh}>
        <PiQuestion className="text-2xl text-caspian-secondary-700" />
      </button>
    </div>
  );
};

export default Notices;
