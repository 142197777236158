import React, { useContext, useEffect, useRef, useState } from "react";
import { PiTrash } from "react-icons/pi";
import DetailsPopup from "./add-process-activites/DetailsPopup";
import API from "../../../../../api/API";
import { AuthContext } from "../../../../../context/auth/AuthContext";

const SingleProcessList = ({ title, id, data, setRefreshPL }) => {
  const { token } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  let menuRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const removeProcess = async () => {
    if (!id || !token) return;
    try {
      const res = await API.delete(`/api/v1/audit/profile/schedule/process/${id}`, {
        headers: {
          Authorization: token,
        },
      });
      console.log(res.data);
      setRefreshPL(1);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      ref={menuRef}
      className="relative flex items-center justify-between w-full border-b border-b-caspian-gray1 last:border-b-0 py-1"
    >
      <span className="text-xs">{title}</span>
      <div className="flex items-center gap-2">
        <span
          onClick={() => setOpen(true)}
          className="text-caspian-secondary-500 text-xs cursor-pointer"
        >
          بیشتر
        </span>
        <PiTrash
          onClick={removeProcess}
          className="text-caspian-secondary-500 cursor-pointer"
        />
      </div>
      <DetailsPopup open={open} setOpen={setOpen} data={data} title={title} />
    </div>
  );
};

export default SingleProcessList;
