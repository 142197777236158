import React, { useState } from "react";
import ProcressTopProfile from "./ProcressTopProfile";
import InfoTaskProfile from "../info-task-profile/InfoTaskProfile";
import MainCreateFormReport from "../../../report/form-report-action-plan/create-form-report/MainCreateFormReport";

const MainTopProfile = ({ reportId, taskData, showModalFrom, setShowModalFrom, setCostListObject, costListObject }) => {
  const [showInfoProfile, setShowInfoProfile] = useState(false);

  const inDay = taskData?.durationDate - taskData?.remained_days;
  const valuePercentTime = inDay < 1 ? 0 : inDay;
  const maxValuePercentTime = taskData?.durationDate;

  const valuePercentProgress = taskData?.progress;
  const maxValuePercentprogress = 100;

  const valuePercentCost = taskData?.costProgress;
  const maxValuePercentCost = taskData?.cost;

  const valuePercent = ((valuePercentProgress / maxValuePercentprogress) * 100)

  let status = taskData?.status;

  let statusName = "";
  let statusClass = "";

  if (status === "Working") {
    statusName = "در حال انجام";
    statusClass = "text-[#3C77A2] bg-[#3C77A210] border-[#3C77A2]";
  } else if (status === "NotStarted") {
    statusName = "شروع نشده";
    statusClass = "text-[#99999999] bg-[#99999910] border-[#99999999]";
  } else if (status === "Done") {
    statusName = "انجام شده";
    statusClass = "text-[#01BC8D] bg-[#01BC8D10] border-[#01BC8D]";
  } else if (status === "PrePlaning") {
    statusName = "درحال برنامه‌ریزی";
    statusClass = "text-[#D6A834] bg-[#D6A83410] border-[#D6A834]";
  } else if (status === "Closed") {
    statusName = "بسته شده";
    statusClass = "text-[#FF440A] bg-[#FF440A10] border-[#FF440A]";
  } else if (status === "Delayed") {
    statusName = "با تاخیر";
    statusClass = "text-[#000000] bg-[#FFFFFF] border-[#000000]";
  }
  return (
    <div className="w-full p-3">
      {showInfoProfile && (
        <InfoTaskProfile setShowInfoProfile={setShowInfoProfile} />
      )}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <h2 className="text-sm font-bold">{taskData?.title}</h2>
          <span className={`border p-1.5 rounded-lg text-xs ${statusClass}`}>
            {statusName}
          </span>
        </div>
        <span
          className="text-xs font-bold"
        // onClick={() => setShowInfoProfile(true)}
        >
          کد وظیفه{" "}
          <span>
            {"T-" + taskData?.id + "-" + taskData?.actionplanRelated?.id}
          </span>
        </span>
      </div>

      <div className="flex flex-col gap-8 w-[50%] pt-10">
        <div className="flex items-center">
          <span className="basis-1/2 text-xs">پیشرفت زمانی</span>
          <ProcressTopProfile
            value={(valuePercentTime / maxValuePercentTime) * 100}
            valuePercent={valuePercentTime}
            valueType={"روز"}
            // minValue={0}
            maxValue={maxValuePercentTime}
          />
        </div>

        <div className="flex items-center ">
          <span className="basis-1/2 text-xs"> درصد پیشرفت </span>
          <ProcressTopProfile
            value={(valuePercentProgress / maxValuePercentprogress) * 100}
            // timeSubmit={"13:01:00"}
            // dateSubmit={"1402/01/01"}
            valuePercent={valuePercentProgress}
            valueType={"%"}
            // minValue={0}
            maxValue={maxValuePercentprogress}
          />
        </div>

        <div className="flex items-center">
          <span className="basis-1/2 text-xs">بودجه استفاده شده</span>
          <ProcressTopProfile
            value={(valuePercentCost / maxValuePercentCost) * 100}
            // timeSubmit={"13:01:00"}
            // dateSubmit={"1402/01/01"}
            valuePercent={valuePercentCost}
            valueType={"ریال"}
            // minValue={0}
            maxValue={maxValuePercentCost}
          />
        </div>
      </div>
      {showModalFrom &&
        <MainCreateFormReport valuePercent={valuePercent} setCostListObject={setCostListObject} costListObject={costListObject} reportId={reportId} setShowModalFrom={setShowModalFrom} />
      }
    </div>
  );
};

export default MainTopProfile;
