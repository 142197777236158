import React from 'react';

const SinglePersonalTable = ({ id, placementProfileRelated, placementPositionRelated }) => {
    return (
        <tr className='border-b border-caspian-gray2 cursor-pointer'>
            <th className='w-[30%] text-center font-light text-xs 2xl:text-sm py-2'
                title={placementProfileRelated?.firstName + ' ' + placementProfileRelated?.lastName}>
                <p className='w-[90%] mx-auto truncate'>
                    {placementProfileRelated?.firstName} {placementProfileRelated?.lastName}
                </p>
            </th>
            <th className='w-[15%] text-center font-light text-xs 2xl:text-sm py-2'
                // title={placementPositionRelated?.title}
                >
                <p className='w-[90%] mx-auto truncate'>
                    {/* {placementPositionRelated?.title} */}
                </p>
            </th>
            <th className='w-[35%] text-center font-light text-xs 2xl:text-sm py-2'
                title={placementPositionRelated?.title}>
                <p className='w-[90%] mx-auto truncate'>
                    {placementPositionRelated?.title}
                </p>
            </th>
            <th className='w-[20%] text-center font-light text-xs 2xl:text-sm py-2'
                title={placementPositionRelated?.positionSectionRelated?.title}>
                <p className='w-[90%] mx-auto truncate'>
                    {placementPositionRelated?.positionSectionRelated?.title}
                </p>
            </th>
        </tr>
    )
}

export default SinglePersonalTable;
