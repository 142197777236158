import { useNavigate } from "react-router-dom";

const SingleList = ({ supervisor, startDate, endDate, title, id }) => {
  const navigate = useNavigate();
  const clickHandler = () => {
    navigate(`/action-plan/plans/${id}`);
  };
  return (
    <tr onClick={clickHandler} className="cursor-pointer bg-caspian-surface border-t border-t-caspian-gray1 min-w-full">
      <td className="p-2.5 w-1/4">
        {title ? title : "نامشخص"}
      </td>
      <td className="p-2.5 w-1/4">
        {startDate ? new Date(startDate).toLocaleDateString("fa-IR") : "نامشخص"}
      </td>
      <td className="p-2.5 w-1/4">
        {endDate ? new Date(endDate).toLocaleDateString("fa-IR") : "نامشخص"}
      </td>
      <td className="p-2.5 w-1/4">{supervisor ? supervisor : "نامشخص"}</td>
    </tr>
  );
};

export default SingleList;
