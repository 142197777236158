import React, { useState } from "react";
import { Link } from "react-router-dom";
import MainAddNote from "../notebook/add-note/MainAddNote";
import MainSidebar from "../notebook/sidebar/MainSidebar";
import MainEditNote from "../notebook/edit-note/MainEditNote";
import { PiNotebook, PiPlusCircle } from "react-icons/pi";
import MainNotebook from "../notebook/MainNotebook";
import MainContact from "../contact/MainContact";

const Toolbar = ({
  showMainNotebook,
  setShowMainNotebook,
  showSidebar,
  setShowSidebar,
}) => {
  const [showMenuNotebook, setShowMenuNotebook] = useState(false);
  const [showAddNote, setShowAddNote] = useState(false);
  const [showEditNote, setShowEditNote] = useState([]);
  const [thisNoteId, setThisNoteId] = useState(null);
  const [showTooltipNotebook, setShowTooltipNotebook] = useState(false);
  const [showTooltipNote, setShowTooltipNote] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [showContact, setShowContact] = useState(false);
  return (
    <div className="fixed left-0 top-12 bottom-0 min-h-screen z-40 w-[50px] shadow-[0_0_5px_#00000050] bg-caspian-surface">
      <div className="flex flex-col items-center justify-center gap-8 py-6">
        <div className="cursor-pointer">
          <img
            src="/assets/images/toolbar/cartable.svg"
            alt="کارتابل"
            className="w-full h-full"
          />
        </div>
        <Link to="/calendar" className="cursor-pointer">
          <img
            src="/assets/images/toolbar/calendar.svg"
            alt="تقویم"
            className="w-full h-full"
          />
        </Link>
        <Link to="/message/chat" className="cursor-pointer">
          <img
            src="/assets/images/toolbar/messenger.svg"
            alt="چت روم"
            className="w-full h-full"
          />
        </Link>
        {showAddNote && (
          <MainAddNote
            setShowAddNote={setShowAddNote}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        )}
        {showEditNote?.map(
          (n, i) =>
            showEditNote.includes(n) && (
              <MainEditNote
                key={i}
                indexNote={i}
                setShowEditNote={setShowEditNote}
                showEditNote={showEditNote}
                thisNoteId={n}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            )
        )}
        {showSidebar && (
          <MainSidebar
            refresh={refresh}
            setRefresh={setRefresh}
            setThisNoteId={setThisNoteId}
            setShowMainNotebook={setShowMainNotebook}
            setShowSidebar={setShowSidebar}
            setShowAddNote={setShowAddNote}
            showEditNote={showEditNote}
            setShowEditNote={setShowEditNote}
          />
        )}
        {showMainNotebook && (
          <MainNotebook
            refresh={refresh}
            setRefresh={setRefresh}
            setThisNoteId={setThisNoteId}
            setShowMainNotebook={setShowMainNotebook}
            setShowSidebar={setShowSidebar}
            setShowAddNote={setShowAddNote}
            showEditNote={showEditNote}
            setShowEditNote={setShowEditNote}
          />
        )}
        <div
          onClick={() => setShowMenuNotebook(!showMenuNotebook)}
          className="cursor-pointer relative"
        >
          {showMenuNotebook && (
            <div className="absolute left-8 top-1/2 -translate-y-1/2 flex items-center gap-0 drop-shadow-[0_0_2px_#00000050] z-50">
              <div className="bg-caspian-surface rounded-md flex items-center justify-center">
                <div className="flex flex-col items-center justify-center w-full gap-0 relative">
                  <div
                    onMouseLeave={() => setShowTooltipNotebook(false)}
                    onMouseEnter={() => setShowTooltipNotebook(true)}
                    className="relative inline-block px-4 pt-2 pb-1"
                  >
                    {showTooltipNotebook && (
                      <div className="absolute left-1/2 -translate-x-1/2 bottom-6 flex items-center justify-center flex-col">
                        <span
                          onClick={() => setShowAddNote(true)}
                          className="text-[11px] text-caspian-surface rounded-md w-24 p-2 flex items-center justify-center bg-caspian-on_primary/70"
                        >
                          دفترچه‌ها
                        </span>
                        <div
                          className="
                          w-0
                          h-0
                          border-l-[6px] 
                          border-l-caspian-transparent
                          border-t-[6px] 
                          border-t-caspian-on_primary/70
                          border-r-[6px] 
                          border-r-caspian-transparent"
                        ></div>
                      </div>
                    )}
                    <PiNotebook
                      onClick={() => {
                        setShowSidebar(true);
                        setShowTooltipNotebook(false);
                      }}
                      className="text-2xl text-caspian-secondary-500"
                    />
                  </div>

                  <div
                    onMouseLeave={() => setShowTooltipNote(false)}
                    onMouseEnter={() => setShowTooltipNote(true)}
                    className="relative inline-block px-4 pt-1 pb-2"
                  >
                    {showTooltipNote && (
                      <div className="absolute left-1/2 -translate-x-1/2 top-6 flex items-center justify-center flex-col">
                        <div
                          className="
                          w-0
                          h-0
                          border-l-[6px] 
                          border-l-caspian-transparent
                          border-b-[6px] 
                          border-b-caspian-on_primary/70
                          border-r-[6px] 
                          border-r-caspian-transparent"
                        ></div>
                        <span
                          onClick={() => setShowAddNote(true)}
                          className="text-[11px] text-caspian-surface rounded-md w-24 p-2 flex items-center justify-center bg-caspian-on_primary/70"
                        >
                          ایجاد یادداشت
                        </span>
                      </div>
                    )}
                    <PiPlusCircle
                      onClick={() => {
                        setShowAddNote(true);
                        setShowTooltipNote(false);
                      }}
                      className="text-2xl text-caspian-secondary-500"
                    />
                  </div>
                </div>
              </div>
              <div
                className="
              w-0
              h-0
              border-b-[6px] 
              border-b-caspian-transparent
              border-r-[6px] 
            border-r-caspian-surface
              border-t-[6px] 
              border-t-caspian-transparent"
              ></div>
            </div>
          )}
          <div className="cursor-pointer">
            <img
              src="/assets/images/toolbar/note.svg"
              alt="یادداشت ها"
              className="w-full h-full"
            />
          </div>
        </div>
        <div
          className="cursor-pointer w-8 h-auto"
          onClick={() => setShowContact(true)}
        >
          <img
            src="/assets/images/toolbar/contacts.svg"
            alt="ارتباطات"
            className="w-full h-full"
          />
        </div>
        {showContact && <MainContact setShowContact={setShowContact} />}
        {/* <Link to="/note" className="cursor-pointer">
          <div className="cursor-pointer">
            <img
              src="/assets/images/toolbar/note.svg"
              alt="یادداشت ها"
              className="w-full h-full"
            />
          </div>
        </Link> */}
      </div>
    </div>
  );
};

export default Toolbar;
