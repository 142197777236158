import React, { useState } from "react";
import MenuAction from "./MenuAction";

const ChatBoxReciver2 = ({
  firstIndex,
  messageId,
  reciver,
  message,
  date,
  avatar,
  setRefreshMessageList,
  setMessageInput,
  setEditButton,
  setMessageId,
  isFav,
  reciverMenu,
}) => {
  const [showName, setShowName] = useState(false);

  return (
    <div className="relative flex items-start gap-2 ltr">
      <div className="ml-10 flex flex-col gap-2 bg-caspian-secondary-10 p-2 rounded-md rounded-tl-none rounded-br-2xl w-fit min-w-[30%] max-w-[70%] shadow-[0_0_5px_#00000050] border border-caspian-secondary-200">
        <p className="text-xs leading-5 text-right ">{message}</p>
        <div className="flex items-center justify-between gap-2 w-full relative">
          <MenuAction
            reciverMenu={reciverMenu}
            isFav={isFav}
            setMessageId={setMessageId}
            setEditButton={setEditButton}
            message={message}
            setMessageInput={setMessageInput}
            messageId={messageId}
            setRefreshMessageList={setRefreshMessageList}
            firstIndex={firstIndex}
          />
          <span className="text-[9px] text-caspian-gray4">{date}</span>
        </div>
      </div>
    </div>
  );
};

export default ChatBoxReciver2;
