import React from "react";
import { PiMagnifyingGlassBold, PiXBold } from "react-icons/pi";

const SecretMembersList = ({ setSecretMembersList }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full z-50">
      <div className="bg-caspian-surface rounded-md shadow-[0_0_5px_#00000050] p-3 w-[400px] flex flex-col gap-3">
        <div className="flex items-center justify-between">
          <h2 className="text-sm font-bold">انتخاب اعضای اصلی</h2>
          <button onClick={() => setSecretMembersList(false)}>
            <PiXBold className={"text-caspian-gray1 text-sm"} />
          </button>
        </div>
        {/* جستجو و لغو */}
        <div className="flex items-center justify-between w-full">
          <div className="relative w-[50%]">
            <input
              type="search"
              className="outline-none outline-caspian-gray1 p-0.5 text-xs rounded-md outline-1 w-full placeholder:text-xs placeholder:text-caspian-gray pr-6"
              placeholder="جستجو"
            />
            <button className="absolute top-1/2 -translate-y-1/2 right-1">
              <PiMagnifyingGlassBold className="text-caspian-gray text-" />
            </button>
          </div>
          <span className="text-xs text-caspian-secondary-600">
            1 مورد انتخاب شده
          </span>
          <button className="border border-caspian-gray2 py-1 px-2 rounded-md text-[#FF5B5B] text-xs">
            لغو انتخاب
          </button>
        </div>
        {/* لیست اعضا */}
        <div className="">
          <div className="flex items-center gap-1 py-2 border-t border-t-caspian-gray2">
            <input
              type="checkbox"
              id="m1"
              className="accent-caspian-primary-400"
            />
            <label
              htmlFor="m1"
              className="flex items-center gap-8 w-full text-xs"
            >
              <span className="basis-[20%]">آرش خیرابی</span>
              <span>کارشناس توسعه مدیریت</span>
            </label>
          </div>
          <div className="flex items-center gap-1 py-2 border-t border-t-caspian-gray2">
            <input
              type="checkbox"
              id="m2"
              className="accent-caspian-primary-400"
            />
            <label
              htmlFor="m2"
              className="flex items-center gap-8 w-full text-xs"
            >
              <span className="basis-[20%]">علی احمدی</span>
              <span>رئیس اداره خدمات مشترکین</span>
            </label>
          </div>
          <div className="flex items-center gap-1 py-2 border-t border-t-caspian-gray2">
            <input
              type="checkbox"
              id="m3"
              className="accent-caspian-primary-400"
            />
            <label
              htmlFor="m3"
              className="flex items-center gap-8 w-full text-xs"
            >
              <span className="basis-[20%]">رضا حقی</span>
              <span>تکنسین بهره برداری</span>
            </label>
          </div>
          <div className="flex items-center gap-1 py-2 border-t border-t-caspian-gray2">
            <input
              type="checkbox"
              id="m4"
              className="accent-caspian-primary-400"
            />
            <label
              htmlFor="m4"
              className="flex items-center gap-8 w-full text-xs"
            >
              <span className="basis-[20%]">آرمین جعفری</span>
              <span>کمک کارشناس خدمات مشترکین</span>
            </label>
          </div>
          <div className="flex items-center gap-1 py-2 border-t border-t-caspian-gray2">
            <input
              type="checkbox"
              id="m5"
              className="accent-caspian-primary-400"
            />
            <label
              htmlFor="m5"
              className="flex items-center gap-8 w-full text-xs"
            >
              <span className="basis-[20%]">ابراهیم مقدم</span>
              <span>رئیس اداره بهره برداری فاضلاب</span>
            </label>
          </div>
          {/* تایید و لغو */}
          <div className="flex items-center gap-4 border-t border-t-caspian-gray2 pt-3">
            <button className="rounded-md py-1.5 px-4 text-xs text-caspian-secondary-600 border border-caspian-secondary-600">
              افزودن
            </button>
            <button
              onClick={() => setSecretMembersList(false)}
              className="text-xs text-[#FF5B5B]"
            >
              لغو
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecretMembersList;
