import { useNavigate, useSearchParams } from "react-router-dom";
import { PiBookmarkSimple, PiCloudArrowDown } from "react-icons/pi";
import { useState } from "react";
import { useAppContext } from "../../../../context/app-context/AppContext";

const SingleTableObsoleteDocuments = ({
  id,
  title,
  code,
  type,
  ownerParts,
  users,
}) => {
  const [serachParams] = useSearchParams();

  const navigate = useNavigate();
  const { setBradCrump3 } = useAppContext();

  const [showDownloadBox, setShowDownloadBox] = useState(false);
  const [deActiveProfileLink, setDeActiveProfileLink] = useState(false);

  return (
    <tr
      onMouseEnter={() => setShowDownloadBox(true)}
      onMouseLeave={() => setShowDownloadBox(false)}
      onClick={() => {
        !deActiveProfileLink && setBradCrump3(title);
        // !deActiveProfileLink &&
        //   navigate(`${code}`, {
        //     state: { search: `?${serachParams.toString()}` },
        //   });
        window.open(
          `/documents-and-records/documents/obsolete-documents/${id}`,
          "_blank"
        );
      }}
      className="cursor-pointer bg-caspian-surface hover:bg-caspian-gray3 duration-300"
    >
      <td
        colSpan={3}
        className="text-center border-b border-b-caspian-gray2 px-3 py-6"
      >
        <h2 className="line-clamp-1">{title}</h2>
      </td>
      <td
        colSpan={1}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 font-sans"
      >
        <h2>{code}</h2>
      </td>
      <td
        colSpan={1}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-6"
      >
        <h2>{type}</h2>
      </td>
      <td
        colSpan={3}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-6"
      >
        <h2>{ownerParts}</h2>
      </td>
      <td
        colSpan={4}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-6"
      >
        <div className="flex items-center justify-between w-full">
          <h2>{users}</h2>
          {showDownloadBox && (
            <div
              onMouseEnter={() => setDeActiveProfileLink(true)}
              onMouseLeave={() => setDeActiveProfileLink(false)}
              className="flex items-center gap-2 ml-4"
            >
              <button>
                <PiBookmarkSimple className="text-xl text-caspian-secondary-500" />
              </button>
              <a download={"/"} href={"/"}>
                <PiCloudArrowDown className="text-xl text-caspian-secondary-500" />
              </a>
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};

export default SingleTableObsoleteDocuments;
