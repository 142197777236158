import React from 'react'
import SingleListPopUpGoal from './SingleListPopUpGoal'

const SinglePopUpGoal = ({ macroGoals, titleGoal }) => {
    return (
        <div className='flex flex-col gap-2 w-full'>
            {macroGoals?.map((objective, index) => (
                <SingleListPopUpGoal titleGoal={titleGoal} key={index} titleObjective={objective} />
            ))}
        </div>
    )
}

export default SinglePopUpGoal