import React, { useEffect, useRef } from "react";
import { PiMagnifyingGlassBold, PiX } from "react-icons/pi";
import { useLocation, useSearchParams } from "react-router-dom";

const SearchInput = ({ setSerachTask }) => {
  const searchRef = useRef();
  const [serachParams, setSearchParams] = useSearchParams();
  const search = serachParams.get("search");
  const location = useLocation();

  const searchHandler = (e) => {
    e.preventDefault();
    console.log(searchRef?.current?.value);
    handleSearch("search", searchRef?.current?.value);
    handleSearch("page", "1");
    setSerachTask(searchRef?.current?.value);
  };

  function handleSearch(key, value) {
    setSearchParams((prevParams) => {
      if (value === null || value === "") {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    });
  }

  useEffect(() => {
    searchRef.current.value = search;
  }, [search]);

  return (
    <form onSubmit={searchHandler} className="relative w-1/3">
      <input
        className="text-sm w-full rounded-lg bg-caspian-surface px-8 py-2 focus:outline-none"
        placeholder="جستجو"
        ref={searchRef}
        type="search"
      />
      <button className="absolute top-1/2 -translate-y-1/2 right-2">
        <PiMagnifyingGlassBold className="text-caspian-gray2 text-xl" />
      </button>
      {search && location.search.length > 0 && (
        <button
          onClick={() => (searchRef.current.value = "")}
          className="absolute top-1/2 -translate-y-1/2 left-1"
        >
          <PiX className="text-rose-400 text-lg" />
        </button>
      )}
    </form>
  );
};

export default SearchInput;
