import { useContext, useEffect, useState } from "react";
import TableListReport from "./TableListReport";
import { AuthContext } from "../../../../../../../context/auth/AuthContext";
import API from "../../../../../../../api/API";


const MainListReport = ({ serachTask }) => {
  const { token } = useContext(AuthContext);
  const [fullData, setfullData] = useState([-1]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (!token) return;
    setLoading(true);
    let newUrl = "/api/v1/actionplan/tasksWithRole/";
    const getReport = async () => {
      await API.get(newUrl, {
        headers: { Authorization: token },
      })
        .then((res) => {
          setfullData(res.data);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    getReport();
  }, [token]);
  let searchReport = [-1];
  if (fullData[0] !== -1) {
    searchReport = serachTask
      ? fullData?.filter(
          (s) =>
            s?.description?.includes(serachTask) ||
            s?.title?.includes(serachTask) ||
            s?.actionplanRelated?.title?.includes(serachTask) ||
            (
              s?.responsible_task?.firstName +
              " " +
              s?.responsible_task?.lastName
            )?.includes(serachTask)
        )
      : fullData;
  }
  return <TableListReport fullData={searchReport} loading={loading} />;
};

export default MainListReport;
