import React from "react";

import SingleProcessActivities from "./SingleProcessActivities";

const MainProcessAndActivities = ({ data }) => {
  return (

    <div className="p-4 w-full flex flex-col gap-4">
      <span className="text-sm text-caspian-primary-500">
        فرایند و فعالیت های مرتبط
      </span>
      {data.map((p, i) => (
        <SingleProcessActivities
          key={i}
          processTitle={
            p?.processProfileRelated?.activityProfileProcessRelated[0]
              ?.processTitle
          }
          activitiesList={p?.processActivityRelated}
        />
        // <SingleProcessActivities
        //   key={i}
        //   processTitle={
        //     p?.processActivityRelated[0]?.activityProfileProcessRelated
        //       ?.activityProfileProcessRelated[0]?.processTitle
        //   }
        //   activitiesList={p?.processActivityRelated}
        // />
      ))}
    </div>
  );
};

export default MainProcessAndActivities;
