import React from 'react';

const TableTask = ({ items }) => {
    return (
        <div className="rounded-md">
            {
                items?.map((item,index) => (
                    <div key={item?.id} className="flex baisis-ful border-t border-caspian-darkgray-100 first:border-0 ">
                        <div className="basis-1/12 p-2 text-center text-sm 2xl:text-base">{index+1}</div>
                        <div className="basis-11/12 p-2 text-sm 2xl:text-base">{item?.describtion}</div>
                    </div>
                ))
            }
        </div>
    );
}

export default TableTask;
