import React, { useContext, useEffect, useRef, useState } from 'react'
import SingleListItemBlog from './SingleListItemBlog'
import { useSearchParams } from 'react-router-dom';
import PaginateListBlog from './PaginateListBlog';
import { PiMagnifyingGlassBold, PiX } from 'react-icons/pi';
import { AuthContext } from '../../../../context/auth/AuthContext';
import API from '../../../../api/API';
import Loading from '../../../../Loading';

const MainItemListBlog = () => {
    const {
        token
    } = useContext(AuthContext)

    const [fullData, setFullData] = useState([-1])
    const [searchBlog, setSearchBlog] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const search = searchParams.get("search");
    const page = searchParams.get("page");
    const searchRef = useRef()

    useEffect(() => {
        if (!token) return
        const handleData = async () => {
            try {
                const res = await API.get('/api/v1/blog/post/')
                console.log(res?.data)
                setFullData(res?.data)
            } catch (error) {
                console.log(error)
            }
        }
        handleData()
    }, [token])


    useEffect(() => {
        if (search) {
            setCurrentPage(1);
        }
    }, [search]);

    const searchHandler = (e) => {
        e.preventDefault();
        handleSearch('search', searchRef?.current?.value)
        handleSearch("page", "1");
        setSearchBlog(searchRef?.current?.value);
    }
    const resetFilter = () => {
        searchRef.current.value = "";
        handleSearch('search', '')
        handleSearch("page", '')
        setSearchBlog('')
    }

    const recordsPerPage = 8;
    const [currentPage, setCurrentPage] = useState(page ? page : 1);
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;

    const handleSearch = (key, value) => {
        setSearchParams((prev) => {
            if (value === null || value === "") {
                prev.delete(key)
            } else {
                prev.set(key, value)
            }
            return prev;
        })
    }

    let searchData = [-1]

    if (fullData[0] !== -1) {
        searchData = searchBlog ?
            fullData?.filter((item) =>
                item?.title?.includes(searchBlog) ||
                item?.describtion?.includes(searchBlog) ||
                item?.text?.includes(searchBlog)

            )
            : fullData;
    }

    useEffect(() => {
        if (search) {
            searchRef.current.value = search;
            setSearchBlog(search);
        }
    }, [search, setSearchBlog]);

    const records = searchData.slice(firstIndex, lastIndex);
    const npage = Math.ceil(searchData.length / recordsPerPage);

    return (
        fullData[0] === -1 ? <Loading /> : fullData?.length < 1 ? <span>دیتایی یافت نشد</span> :

            <div className='p-5 w-full flex flex-col items-center'>


                <form onSubmit={searchHandler} className='flex w-full items-end pb-5 justify-between '>
                    <div className='flex w-1/2 md:w-1/3 items-center shadow-md justify-end px-3 gap-3 rounded-lg bg-caspian-surface '>
                        <PiMagnifyingGlassBold className='text-xl rotate-90 text-caspian-gray' />
                        <input className='py-2 w-full outline-none' type='search' placeholder='جست و جو'
                            ref={searchRef}
                        />
                        <PiX
                            onClick={resetFilter}
                            className={`${searchBlog?.length > 0 ? ' visible' : ' invisible'} cursor-pointer`} />
                    </div>
                </form>

                <div className="min-h-full w-full flex items-center flex-col p-5 bg-caspian-surface shadow-[0_5px_5px_#00000050] rounded-xl">
                    <div className='flex flex-wrap items-start justify-start w-full'>
                        {records?.length > 0 ? records?.map((item) => (
                            <SingleListItemBlog key={item?.id} item={item} />
                        )) :
                            <div className='py-5 w-full flex flex-col gap-3 items-center justify-center'>
                                <div className='w-[220px] h-[220px] flex flex-col justify-center'>
                                    <img src="/assets/images/library/not-found.svg" alt="هدف یافت نشد..." />
                                    <h2 className='text-center text-sm'> موردی یافت نشد...</h2>
                                </div>
                            </div>
                        }
                    </div>
                    {records?.length > 0 &&
                        <div className="flex items-center w-full px-10 py-5 justify-between">
                            <PaginateListBlog
                                pageCount={npage}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </div>
                    }
                </div>
            </div>
    )
}

export default MainItemListBlog