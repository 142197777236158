import React, { useContext, useEffect, useState } from "react";
import StepFourForm from "./StepFourForm";
import { AuthContext } from "../../../../../../../../context/auth/AuthContext";
import API from "../../../../../../../../api/API";

const MainStepFour = ({
  setActiveTab,
  actionplanIdRelated,
  setShowEditOfficeForm,
  setRefresh,
}) => {
  const { token } = useContext(AuthContext);

  const [privacy, setPrivacy] = useState("public");
  const [cost, setCost] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [membersSelected, setMembersSelected] = useState([]);
  const [membersFinalSelected, setMembersFinalSelected] =
    useState(membersSelected);

  const [isDisable, setIsDisable] = useState(true);
  const [actionPlanData, setActionPlanData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [thisId, setThisId] = useState(null);
  const [oldStartDate, setOldStartDate] = useState("");
  const [oldEndDate, setOldEndDate] = useState("");

  useEffect(() => {
    if (!token || !actionplanIdRelated) return;

    const getActionPlanData = async () => {
      setLoading(true);
      try {
        const res = await API.get(
          `/api/v1/actionplan/details/actionplan/${actionplanIdRelated}`,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data);
        setActionPlanData(res?.data[0]);
        setCost(res?.data[0]?.cost);
        setThisId(res?.data[0]?.id);
        setOldStartDate(res?.data[0]?.startDate);
        setOldEndDate(res?.data[0]?.endDate);
        setPrivacy(res?.data[0]?.privacy);
        setMembersSelected(res?.data[0]?.observers_selected);
        setMembersFinalSelected(res?.data[0]?.observers_selected);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getActionPlanData();
  }, [token, actionplanIdRelated]);

  const submitHandler = async () => {
    // if (!description) return;

    if (
      actionPlanData &&
      thisId &&
      privacy === actionPlanData?.privacy &&
      startDate === actionPlanData?.startDate &&
      endDate === actionPlanData?.endDate &&
      cost === actionPlanData?.cost &&
      membersFinalSelected === actionPlanData?.observers_selected
    ) {
      console.log("none");
      setShowEditOfficeForm(false);
      setRefresh(1);
    } else if (
      actionPlanData &&
      thisId &&
      (privacy !== actionPlanData?.privacy ||
        startDate !== actionPlanData?.startDate ||
        endDate !== actionPlanData?.endDate ||
        cost !== actionPlanData?.cost ||
        membersFinalSelected !== actionPlanData?.observers_selected)
    ) {
      try {
        let formData = {
          privacy: privacy,
          cost: cost,
          startDate: startDate,
          endDate: endDate,
          id: actionplanIdRelated,
          observers_selected:
            privacy !== "restricted" ? [] : membersFinalSelected,
        };
        if (!thisId) return;
        const res = await API.patch(
          `/api/v1/actionplan/actionplanDetail/${thisId}/`,
          formData,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data);
        console.log("patch");
        setShowEditOfficeForm(false);
        setRefresh(1);
      } catch (err) {
        console.log(err);
      }
    } else if (!thisId) {
      try {
        let formData = {
          privacy: privacy,
          cost: cost,
          startDate: startDate,
          endDate: endDate,
          actionplanRelated: actionplanIdRelated,
          observers_selected:
            privacy !== "restricted" ? [] : membersFinalSelected,
        };
        const res = await API.post(
          `/api/v1/actionplan/actionplanDetail/`,
          formData,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data);
        console.log("post");
        setShowEditOfficeForm(false);
        setRefresh(1);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (!startDate || !endDate || !cost) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [startDate, endDate, cost]);

  // useEffect(() => {
  //   if (actionplanIdRelated) {
  //     console.log(actionplanIdRelated);
  //   }
  // }, [actionplanIdRelated]);
  return (
    <div className="flex flex-col justify-between h-full gap-4 p-2 min-h-[370px]">
      {loading ? (
        <div className="flex items-center justify-center w-full py-12">
          <span className="text-xs text-caspian-gray4">در حال بارگزاری...</span>
        </div>
      ) : (
        <StepFourForm
          privacy={privacy}
          setPrivacy={setPrivacy}
          cost={cost}
          setCost={setCost}
          oldStartDate={oldStartDate}
          oldEndDate={oldEndDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          membersSelected={membersSelected}
          setMembersSelected={setMembersSelected}
          membersFinalSelected={membersFinalSelected}
          setMembersFinalSelected={setMembersFinalSelected}
        />
      )}
      <div className="flex items-center gap-2">
        <button
          onClick={async () => {
            await setActiveTab("StepThree");
          }}
          className="min-w-[6rem] outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs p-1.5 rounded-md"
        >
          مرحله قبل
        </button>
        <button
          disabled={isDisable ? true : false}
          onClick={submitHandler}
          className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
        >
          ثبت
        </button>
        {/* <button
          onClick={async () => {
            await setShowEditOfficeForm(false);
          }}
          className={`disabled:cursor-not-allowed disabled:text-caspian-gray text-caspian-secondary-500 text-xs p-1.5 rounded-md`}
        >
          ذخیره پیشنویس
        </button> */}
      </div>
    </div>
  );
};

export default MainStepFour;
