import React from 'react';
import { useNavigate } from 'react-router-dom';

const SingleListAudit = ({ id, title, startDate, auditProcess }) => {
    const navigate = useNavigate();
    return (
        <div className='border border-[#B5B5B5] rounded-lg mx-4 cursor-pointer' onClick={() => navigate(`/audit/${id}`)}>
            <div className='flex gap-4 border-b border-[#E2E2E2] py-2 px-2 xl:px-4 items-center'>
                <span className='text-caspian-brown-700 text-sm xl:text-base'>
                    ممیزی داخلی
                </span>
                <span className='text-xs xl:text-sm'> {title}</span>
                <span className='text-xs xl:text-sm'>{new Date(startDate).toLocaleDateString("fa-IR").split('/')[0]}</span>
            </div>
            <div className='py-2 px-2 xl:px-4 h-8'>
                {/* {
    auditProcess==='schedule-create'?<span>پروفایل ایجاد شده</span>:
    a
} */}
            </div>
        </div>
    );
}

export default SingleListAudit;
