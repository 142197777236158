import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useContext } from "react";
import { AuthContext } from "../../context/auth/AuthContext";
import { useForm } from "react-hook-form";
import { PiCircleNotch, PiEyeBold, PiEyeClosed } from "react-icons/pi";
import API from "../../api/API";

const NewLogin = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({});

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const loginSubmit = async (e) => {
    const formData = {
      username: watch("username"),
      password: watch("password"),
    };
    setLoading(true);
    API.post("/auth/jwt/create/", formData)
      .then((d) => {
        console.log(d.data.access);
        toast.success("با موفقیت وارد شدید.", {
          className: "toast-success",
          bodyClassName: "toast-success",
        });
        localStorage.setItem("_appSignging", true);
        Cookies.set("refresh", d.data.refresh, {
          expires: 7,
          sameSite: "lax",
        });
        dispatch({ type: "SIGNING" });
        navigate("/");
      })
      .catch((err) => {
        if (err?.message === "Network Error") {
          toast.error("اتصال با سرور برقرار نشد", {
            className: "toast-faild",
            bodyClassName: "toast-faild",
          });
        } else if (err?.response?.status === 401) {
          toast.error("هیچ حساب فعالی با اطلاعات کاربری داده شده یافت نشد", {
            className: "toast-faild",
            bodyClassName: "toast-faild",
          });
        } else {
          toast.error("مشکل ناشناخته ای به وجود آمد.", {
            className: "toast-faild",
            bodyClassName: "toast-faild",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClick = () => {
    setVisible(!visible);
  };

  return (
    <form
      onSubmit={handleSubmit(loginSubmit)}
      className="w-full h-1/2 flex flex-col items-center justify-start"
    >
      <div className="flex flex-col items-center justify-center gap-3 w-[90%]">
        <div className="flex flex-col gap-1 w-full">
          <div className="relative">
            <input
              type="text"
              name="username"
              placeholder="نام کاربری"
              {...register("username", {
                required: true,
              })}
              className={`outline-caspian-gray ${
                errors.username && errors.username.type === "required"
                  ? "focus-visible:outline-caspian-error"
                  : "focus-visible:outline-caspian-secondary-600"
              } bg-white outline outline-1 p-3 rounded-md w-full text-right text-sm`}
            />
          </div>
          {errors.username && errors.username.type === "required" && (
            <span className="text-caspian-error text-sm">
              لطفا نام کاربری را وارد کنید
            </span>
          )}
        </div>

        <div className="flex flex-col gap-1 w-full">
          <div className="relative">
            <div
              onClick={handleClick}
              className="text-caspian-gray text-lg absolute left-3 top-1/2 -translate-y-1/2 cursor-pointer"
            >
              {visible ? <PiEyeBold /> : <PiEyeClosed />}
            </div>
            <input
              type={visible ? "text" : "password"}
              name="password"
              placeholder="کلمه عبور"
              {...register("password", {
                required: true,
                minLength: 4,
              })}
              className={`outline-caspian-gray ${
                errors.password &&
                (errors.password.type === "required" ||
                  errors.password.type === "minLength")
                  ? "focus-visible:outline-caspian-error"
                  : "focus-visible:outline-caspian-secondary-600"
              }  outline outline-1 bg-white p-3 rounded-md w-full text-right text-sm`}
            />
          </div>

          {errors.password && errors.password.type === "required" && (
            <span className="text-caspian-error text-sm">
              لطفا کلمه عبور را وارد کنید
            </span>
          )}
          {errors.password && errors.password.type === "minLength" && (
            <span className="text-caspian-error text-sm">
              کلمه عبور باید بیشتر از 6 کارکتر باشد..
            </span>
          )}
        </div>
        <button
          type="submit"
          className="relative bg-caspian-primary-400 text-caspian-surface rounded-md p-3 flex items-center justify-center gap-2 text-sm w-full"
        >
          <span>ورود</span>
          {loading && (
            <div className="absolute top-1/2 -translate-y-1/2 left-3">
              <PiCircleNotch className="animate-spin" />
            </div>
          )}
        </button>
      </div>
    </form>
  );
};

export default NewLogin;
