import React, { useState } from "react";
import Draggable from "react-draggable";
import { PiCaretUpBold, PiChatCircleDots, PiX } from "react-icons/pi";
import DetailsPersonInternalContact from "./DetailsPersonInternalContact";

const MainPersonInternalContact = ({
  setShowPersonInternalContact,
  showChatPerson,
  setShowChatPerson,
}) => {
  const [heightHandler, setHeightHandler] = useState(false);

  return (
    <Draggable
      handle="strong"
      bounds={{ top: 0, bottom: 500, right: 740, left: -250 }}
    >
      <div
        className={`w-[280px] fixed left-24 top-14 rounded-md shadow-[0_0_10px_#00000050] z-[47] flex flex-col overflow-hidden bg-caspian-surface`}
      >
        <strong className="flex items-center justify-between cursor-move p-2 border-b border-b-caspian-gray2 bg-caspian-surface">
          <div className="flex items-center gap-2">
            <div className="w-8 h-8 rounded-full bg-caspian-primary-300"></div>
            <div className="flex flex-col gap-0">
              <span className="text-xs font-bold">علی حسینی</span>
              <span className="text-xs font-thin text-caspian-gray4">
                مدیرعامل
              </span>
            </div>
          </div>
          <div className="flex items-center gap-2">
            {/* <PiChatCircleDots
              onClick={() => setShowChatPerson(!showChatPerson)}
              className="cursor-pointer text-lg text-caspian-secondary-500"
            /> */}
            <PiX
              onClick={() => setShowPersonInternalContact(false)}
              className="cursor-pointer"
            />
          </div>
        </strong>
        <div
          className={`flex flex-col gap-2 p-2 ${
            heightHandler
              ? "min-h-[230px] max-h-full"
              : "min-h-[230px] max-h-[230px]"
          }`}
        >
          {heightHandler ? (
            <div className="w-full bg-caspian-surface flex items-center justify-center absolute bottom-0 left-0 right-0 mx-auto py-2">
              <PiCaretUpBold
                onClick={() => setHeightHandler(false)}
                className="text-lg text-caspian-secondary-500 cursor-pointer"
              />
            </div>
          ) : (
            <div className="w-full bg-caspian-surface flex items-center justify-center absolute bottom-0 left-0 right-0 mx-auto py-2">
              <span
                onClick={() => setHeightHandler(true)}
                className="font-bold text-xs text-caspian-secondary-500 cursor-pointer"
              >
                بیشتر
              </span>
            </div>
          )}
          <DetailsPersonInternalContact heightHandler={heightHandler} />
        </div>
      </div>
    </Draggable>
  );
};

export default MainPersonInternalContact;
