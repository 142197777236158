import React, { useContext, useEffect, useState } from "react";
import TableListObsoleteDocuments from "./TableListObsoleteDocuments";
import API from "../../../../api/API";
import { AuthContext } from "../../../../context/auth/AuthContext";

const MainObsoleteDocuments = () => {
  const [loading, setLoading] = useState(false);
  const [fullData, setFullData] = useState([-1]);
  const { token } = useContext(AuthContext);

  useEffect(() => {
    if (!token) return;
    const getDocObsolete = async () => {
      setLoading(true);
      await API.get("/api/v1/deocument/internal/profile")
        .then((d) => {
          setFullData(d.data.filter((o) => o.obsolete === true));
        })
        .catch((err) => {
          console.log("error");
        })
        .finally(() => {
          setLoading(false);
        });
    };
    getDocObsolete();
  }, [token]);

  let data = fullData.map((d) => ({
    id: d.id,
    title: d.title,
    code:
      d?.internalDocumentRelated?.InternalDocumentTypeRelated?.symbol +
      d?.internalDocumentRelated?.code,
    type: d?.internalDocumentRelated?.InternalDocumentTypeRelated?.title,
    ownerParts: d.OwnerPartsRelated && d.OwnerPartsRelated[0].title,
    users: d.users && d.users[0].title,
  }));

  // console.log(data);

  return <TableListObsoleteDocuments data={data} loading={loading} />;
};

export default MainObsoleteDocuments;
