import React, { useContext, useEffect, useState } from "react";
import { PiPlusBold, PiX } from "react-icons/pi";
import SingleInput from "./SingleInput";
import StartDateTimePicker from "./StartDateTimePicker";
import EndDateTimePicker from "./EndDateTimePicker";
import { AuthContext } from "../../../../../../../../context/auth/AuthContext";
import API from "../../../../../../../../api/API";
import Modal from "../../../../../../../modal/Modal";
// import UploadFile from "./UploadFile";
import SupervisorMember from "./SupervisorMember";
import ResponsibleTaskMember from "./ResponsibleTaskMember";
import ListSelected from "./ListSelected";
import MainMaterialForm from "./material/MainMaterialForm";
import { toast } from "react-toastify";

const MainCreateForm = ({
  setShowCreateTask,
  actionplanIdRelated,
  setRefresh,
  setRefreshBrief,
  berifWeight,
}) => {
  const { token } = useContext(AuthContext);

  const [title, setTitle] = useState("");
  const [taskType, setTaskType] = useState("task");
  const [outputTask, setOutputTask] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [cost, setCost] = useState("");
  const [weight, setWeight] = useState("");

  const [members, setMembers] = useState([-1]);
  // const [file, setFile] = useState(null);

  const [supervisor, setSupervisor] = useState("");
  const [supervisorSelected, setSupervisorSelected] = useState(null);
  const [showListSupervisor, setShowListSupervisor] = useState(false);

  const [responsibleTask, setResponsibleTask] = useState("");
  const [responsibleTaskSelected, setResponsibleTaskSelected] = useState(null);
  const [showListResponsibleTask, setShowListResponsibleTask] = useState(false);

  const [showListMaterial, setShowListMaterial] = useState(false);
  const [materialSelected, setMaterialSelected] = useState([]);
  const [materialFinalSelected, setMaterialFinalSelected] =
    useState(materialSelected);

  const [showNewMaterialForm, setShowNewMaterialForm] = useState(false);

  const handleChange = (e) => {
    const inputValue = e.target.value;

    if (!isNaN(inputValue) && Number(inputValue) <= berifWeight) {
      setWeight(inputValue);
    } else if (inputValue === "") {
      setWeight("");
    }
  };

  let url2 = "/api/v1/profile/";
  useEffect(() => {
    const getMembers = () => {
      API.get(url2, {
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          setMembers(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getMembers();
  }, [token, url2]);

  const dataHandler = async () => {
    if (!token || !title || !description) return;
    let dataForm = {
      title: title,
      outputTask: outputTask,
      description: description,
      typeTask: taskType,
      startDate: startDate,
      finishDate: endDate,
      cost: +cost,
      weight: +weight / 100,
      actionplanRelated: +actionplanIdRelated,
      responsible_task: +responsibleTask,
      supervisor: +supervisor,
      resources: materialFinalSelected,
    };

    try {
      const res = await API.post("/api/v1/actionplan/tasks/", dataForm, {
        headers: { Authorization: token },
      });
      console.log(res?.data);
      setShowCreateTask(false);
      setRefresh(1);
      setRefreshBrief(1);
    } catch (err) {
      console.log(err);
      if (err?.message === "Network Error") {
        toast.error("اتصال با سرور برقرار نشد", {
          className: "toast-faild",
          bodyClassName: "toast-faild",
        });
      } else if (err?.response?.data?.[0]) {
        toast.error("مجموع وزن ها بیشتر از 100 نمیتواند باشد", {
          className: "toast-faild",
          bodyClassName: "toast-faild",
        });
      } else {
        toast.error("مشکل ناشناخته ای به وجود آمد.", {
          className: "toast-faild",
          bodyClassName: "toast-faild",
        });
      }
    }
  };

  const [isDisable, setIsDisable] = useState(false);
  useEffect(() => {
    if (
      !title ||
      !description ||
      !weight ||
      !outputTask ||
      !startDate ||
      !endDate ||
      !cost ||
      materialFinalSelected.length < 1 ||
      !responsibleTask ||
      !supervisor
    ) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [
    title,
    description,
    weight,
    outputTask,
    startDate,
    endDate,
    cost,
    materialFinalSelected,
    responsibleTask,
    supervisor,
  ]);

  // const [showHseForm, setShowHseForm] = useState(false);

  return (
    <Modal>
      <div className="w-2/3 min-h-1/2 rounded-md flex flex-col gap-0 fixed top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-caspian-surface shadow-[0_0_10px_#00000050]">
        <div className="flex items-center justify-between w-full p-2">
          <span className="text-xs">ایجاد وظیفه</span>
          <PiX
            onClick={() => setShowCreateTask(false)}
            className="cursor-pointer"
          />
        </div>
        <div className="p-2 grid grid-cols-2 gap-2 w-full">
          {/* start عنوان */}
          <SingleInput lable={"عنوان"}>
            <input
              onChange={(e) => setTitle(e.target.value)}
              className="text-xs p-2 rounded-md bg-caspian-transparent outline-none border border-caspian-gray2 w-full"
              placeholder="عنوان این وظیفه را وارد کنید"
            />
          </SingleInput>
          {/* end عنوان */}
          {/* start نوع وظیفه */}
          <SingleInput lable={"نوع وظیفه"}>
            <select
              onChange={(e) => setTaskType(e.target.value)}
              value={taskType}
              className={`w-full text-xs outline outline-1 outline-caspian-gray1 p-2 rounded-md bg-caspian-transparent cursor-pointer`}
            >
              <option>task</option>
              <option>milestone</option>
            </select>
          </SingleInput>
          {/* end نوع وظیفه */}
          {/* start کنترل کننده */}
          <SupervisorMember
            lable="کنترل کننده"
            members={members}
            supervisor={supervisor}
            setSupervisor={setSupervisor}
            supervisorSelected={supervisorSelected}
            setSupervisorSelected={setSupervisorSelected}
            showListSupervisor={showListSupervisor}
            setShowListSupervisor={setShowListSupervisor}
          />
          {/* end کنترل کننده */}
          {/* start مجری */}
          <ResponsibleTaskMember
            lable="مجری"
            members={members}
            responsibleTask={responsibleTask}
            setResponsibleTask={setResponsibleTask}
            responsibleTaskSelected={responsibleTaskSelected}
            setResponsibleTaskSelected={setResponsibleTaskSelected}
            showListResponsibleTask={showListResponsibleTask}
            setShowListResponsibleTask={setShowListResponsibleTask}
          />
          {/* end مجری */}
          <div className="flex items-center gap-2 w-full">
            {/* start تاریخ شروع */}
            <SingleInput lable={"تاریخ شروع"}>
              <StartDateTimePicker setStartDate={setStartDate} />
            </SingleInput>
            {/* end تاریخ شروع */}
            {/* start تاریخ پایان */}
            <SingleInput lable={"تاریخ پایان"}>
              <EndDateTimePicker setEndDate={setEndDate} />
            </SingleInput>
            {/* end تاریخ پایان */}
          </div>
          {/* start بودجه */}
          <SingleInput lable={"بودجه(ریال)"}>
            <input
              onChange={(e) => setCost(e.target.value)}
              className="text-xs p-2 rounded-md bg-caspian-transparent outline-none border border-caspian-gray2 w-full"
              placeholder="بودجه را وارد کنید"
            />
          </SingleInput>
          {/* end بودجه */}
          {/* start وزن */}
          <SingleInput lable={"وزن(درصد)"}>
            <input
              value={weight}
              onChange={handleChange}
              className="text-xs p-2 rounded-md bg-caspian-transparent outline-none border border-caspian-gray2 w-full"
              placeholder="وزن را وارد کنید"
            />
          </SingleInput>
          {/* end وزن */}
          {/* start لیست اقلام مصرفی */}
          {showListMaterial && (
            <ListSelected
              finalSelected={materialFinalSelected}
              setFinalSelected={setMaterialFinalSelected}
              selected={materialSelected}
              setSelected={setMaterialSelected}
              setShowListSelected={setShowListMaterial}
              title="لیست اقلام مصرفی"
              url="/api/v1/material/materials/"
            />
          )}
          {showNewMaterialForm && (
            <MainMaterialForm setShowNewMaterialForm={setShowNewMaterialForm} />
          )}
          <div className="w-full flex flex-col gap-1.5">
            <div className="w-full flex items-center justify-between">
              <label className="text-xs text-caspian-gray">
                لیست اقلام مصرفی
              </label>
              <button
                onClick={() => setShowNewMaterialForm(true)}
                className="flex items-center gap-1 text-xs text-caspian-secondary-500"
              >
                <PiPlusBold className="text-sm" />
                <span>قلم جدید</span>
              </button>
            </div>
            <button
              onClick={() => setShowListMaterial(true)}
              className="rounded-md border border-caspian-secondary-400 p-1.5 flex items-center justify-between gap-1 w-full"
            >
              <div className="flex items-center gap-1.5">
                <PiPlusBold className="text-caspian-secondary-500 text-md" />
                <span className="text-caspian-secondary-500 text-xs">
                  افزودن
                </span>
              </div>
              {materialSelected?.length > 0 ? (
                <span className="text-xs">
                  {materialSelected?.length} قلم مصرفی افزوده شد
                </span>
              ) : (
                <span className="text-xs">قلم مصرفی انتخاب نشده</span>
              )}
            </button>
          </div>
          {/* end لیست اقلام مصرفی */}
          {/* الزامات ایمنی */}
          {/* {showHseForm && <MainHse setShowHseForm={setShowHseForm} />}
          <div className="w-full">
            <button className="flex items-center gap-2">
              <span
                onClick={() => setShowHseForm(true)}
                className="text-xs text-caspian-secondary-500"
              >
                افزودن الزامات ایمنی و بهداشت
              </span>
              <PiPlusBold className="texl-2xl text-caspian-secondary-500" />
            </button>
          </div> */}
          {/* الزامات ایمنی */}
          <div className="flex flex-col gap-2 w-full">
            {/* start توضیحات */}
            <SingleInput lable={"توضیحات"}>
              <textarea
                onChange={(e) => setDescription(e.target.value)}
                rows={5}
                className="text-xs p-2 rounded-md bg-caspian-transparent outline-none border border-caspian-gray2 w-full resize-none"
                placeholder="توضیحات خود را وارد کنید"
              />
            </SingleInput>
            {/* end توضیحات */}
            {/* start انتخاب فایل */}
            {/* <UploadFile file={file} setFile={setFile} /> */}
            {/* end انتخاب فایل */}
          </div>
          {/* start نتیجه مورد انتظار */}
          <SingleInput lable={"نتیجه مورد انتظار"}>
            <textarea
              onChange={(e) => setOutputTask(e.target.value)}
              rows={5}
              className="text-xs p-2 rounded-md bg-caspian-transparent outline-none border border-caspian-gray2 w-full resize-none"
              placeholder="نتیجه مورد انتظار را وارد کنید"
            />
          </SingleInput>
          {/* end نتیجه مورد انتظار */}
        </div>
        {/* start دکمه ها */}
        <div className="flex items-center gap-2 p-2 pb-4">
          <button
            disabled={isDisable ? true : false}
            onClick={() => dataHandler()}
            className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
          >
            ایجاد
          </button>
          <button
            onClick={() => console.log("cancel")}
            className="text-xs text-[#FF5B5B] mr-4"
          >
            انصراف
          </button>
        </div>
        {/* end دکمه ها */}
      </div>
    </Modal>
  );
};

export default MainCreateForm;
