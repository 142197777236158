import React from "react";
import ActionPlanNavbar from "../ActionPlanNavbar";
import { useOutletContext } from "react-router-dom";

const MainDashboard = () => {
  const pmo = useOutletContext();
  return (
    <div>
      <ActionPlanNavbar pmo={pmo} buttonActive="dashboard" />
    </div>
  );
};

export default MainDashboard;
