import React from "react";
import { useParams } from "react-router-dom";
import MainDetailsSingleRefer from "./details-single-refer/MainDetailsSingleSent";
import TableReferRequest from "./TableReferRequest";

const DetailsReferRequest = () => {
  const params = useParams();
  return (
    <div>{params.id ? <MainDetailsSingleRefer /> : <TableReferRequest />}</div>
  );
};

export default DetailsReferRequest;
