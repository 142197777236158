import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { PiX } from 'react-icons/pi';

const ClauseSingleItem = ({ id, title, description, clauseNumber,index,isDisable,setIsDisable }) => {

    const [open, setOpen] = useState(false);
    const handleShow=()=>{
        if(isDisable) return;
        setIsDisable(true);
        setOpen(true);
    }
    const handleClose=()=>{
        setIsDisable(false);
        setOpen(false);
    }
    return (
        <div>
            <div onClick={handleShow} className={`${index % 2 === 0 ? 'bg-[#E4F0F1]' : 'bg-caspian-surface'} p-4 2xl:px-8 py-2 flex gap-3 2xl:gap-5 w-full text-xs 2xl:text-sm cursor-pointer`}>
                <p className='whitespace-nowrap'>{clauseNumber}</p>
                <p className='line-clamp-1' title={title}>
                    {title}
                </p>
            </div>
            {
                open && <Draggable
                bounds='html'
                    positionOffset={{ x: index + 10, y: index + 30 }}
                >
                    <div className="w-[30%] h-[40vh] bg-[#FFF7CB] fixed right-32 top-14 rounded-md shadow-[0_0_10px_#00000050] z-[200] flex flex-col"
                    // style={{top:index*10}}
                    >
                        <div className='cursor-move ltr handle p-1.5 pr-2'>
                            <PiX onClick={handleClose} className='cursor-pointer' />
                        </div>
                        <div className='flex flex-col gap-2 h-[90%] overflow-auto p-2 pt-0'>
                            <p className='flex gap-3 text-caspian-gray4 text-sm 2xl:text-base'>
                                <span>{clauseNumber}</span>
                                <span>{title}</span>
                            </p>
                            <p className='text-xs 2xl:text-sm cursor-default  text-justify leading-5'  dangerouslySetInnerHTML={{ __html: description }}></p>
                        </div>
                    </div>
                </Draggable>
            }
        </div>
    );
}

export default ClauseSingleItem;
