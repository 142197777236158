import React from "react";
import { PiFolder, PiFolderOpen } from "react-icons/pi";

const SingleFolderList = ({
  title,
  counter,
  onClick,
  folder,
  folderId,
}) => {
  return (
    <div className="flex items-center justify-between w-full">
      <div onClick={onClick} className="flex items-center gap-1 cursor-pointer">
        {folder === folderId ? (
          <PiFolderOpen className="text-2xl text-caspian-secondary-500" />
        ) : (
          <PiFolder className="text-2xl text-caspian-secondary-500" />
        )}
        <span className="text-xs">{title}</span>
      </div>
      <div className="flex items-center gap-2">
        <span className="text-sm text-caspian-gray">{counter}</span>
      </div>
    </div>
  );
};

export default SingleFolderList;
