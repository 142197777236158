import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppContext } from "../../../../context/app-context/AppContext";
import { PiCheckCircleFill, PiTrash, PiXCircleFill } from "react-icons/pi";
import API from "../../../../api/API";
import { useContext, useState } from "react";
import { AuthContext } from "../../../../context/auth/AuthContext";

const SingleTableListActivites = ({
  id,
  title,
  teamType,
  startDate,
  endDate,
  statusType,
  setRefreshActivitiesList,
}) => {
  const { token } = useContext(AuthContext);

  const [serachParams] = useSearchParams();
  const navigate = useNavigate();
  const { setBradCrump3 } = useAppContext();
  const [showIsDelete, setShowIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const removeHandler = async () => {
    API.delete(`/api/v1/risk/identification/detail/${id}/`, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        setRefreshActivitiesList(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <tr
      onClick={() =>
        statusType !== "outdate" && navigate(`/risk/activities/${id}`)
      }
      className="bg-caspian-surface duration-300"
    >
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[25%] cursor-pointer">
        <h2>{title}</h2>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[17%] cursor-pointer">
        <h2>
          {teamType === "strategic" ? "گروه اهداف کلان" : "گروه فرایندها"}
        </h2>
      </td>

      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[17%] cursor-pointer">
        <h2>{new Date(startDate).toLocaleDateString("fa-IR")}</h2>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-3 py-2 w-[17%] cursor-pointer">
        <h2>{new Date(endDate).toLocaleDateString("fa-IR")}</h2>
      </td>
      <td className="border-b border-b-caspian-gray2 px-3 py-2 w-[17%] cursor-pointer">
        <h2
          className={`${
            statusType === "doing"
              ? "bg-caspian-secondary-400/20 text-caspian-secondary-700 border-caspian-secondary-00"
              : statusType === "done"
              ? "bg-caspian-primary-400/20 text-caspian-primary-700 border-caspian-primary-400"
              : statusType === "outdate" &&
                "bg-caspian-error2/20 text-caspian-error2 border-caspian-error2"
          } w-fit rounded-md px-2 py-0.5 text-xs border`}
        >
          {statusType === "doing"
            ? "در حال انجام"
            : statusType === "done"
            ? "انجام شده"
            : statusType === "outdate" && "منقضی شده"}
        </h2>
      </td>
      {/* <td className="whitespace-nowrap text-right border-b border-b-caspian-gray2 w-[7%]"> */}
        {/* <div className="flex items-center gap-2">
          {
            <div className="flex items-center gap-1">
              <div className="relative flex items-center justify-center">
                <button
                  onClick={async () => {
                    await setDeleteId(id);
                    await setShowIsDelete(true);
                  }}
                  className="text-caspian-error2 text-lg"
                >
                  <PiTrash />
                </button>
                <div
                  className={
                    id === deleteId && showIsDelete
                      ? "absolute left-0 right-0 top-0 bottom-0 flex items-center gap-0.5 w-fit bg-caspian-surface shadow-[0_0_5px_#00000050] p-0.5 rounded-md duration-500"
                      : "hidden duration-500"
                  }
                >
                  <PiCheckCircleFill
                    onClick={() => {
                      removeHandler(id);
                      setShowIsDelete(false);
                    }}
                    className="text-caspian-primary-600 text-lg cursor-pointer"
                  />
                  <PiXCircleFill
                    onClick={() => {
                      setShowIsDelete(false);
                      setDeleteId(null);
                    }}
                    className="text-caspian-gray text-lg cursor-pointer"
                  />
                </div>
              </div>
            </div>
          }
        </div> */}
      {/* </td> */}
    </tr>
  );
};

export default SingleTableListActivites;
