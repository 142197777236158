import React, { useContext, useEffect, useState } from "react";
import { PiX } from "react-icons/pi";
import CreateAuditForm from "./CreateAuditForm";
import API from "../../../../api/API";
import { AuthContext } from "../../../../context/auth/AuthContext";
import DeleteSchedulePopup from "./DeleteSchedulePopup";

const MainCreateAuditForm = ({
  setShowCreateAuditForm,
  auditDate,
  auditSection,
  setRefreshProfile,
  auditId,
}) => {
  const { token } = useContext(AuthContext);
  const [profileData, setProfileData] = useState([-1]);
  const [scheduleTimeId, setScheduleTimeId] = useState(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  useEffect(() => {
    if (!auditId) return;
    const getMs = async () => {
      try {
        const res = await API.get(`/api/v1/audit/profile/detial/${auditId}/`, {
          headers: { Authorization: token },
        });
        console.log(res?.data);
        setProfileData(res?.data);
      } catch (error) {
        console.log(error);
      }
    };
    getMs();
  }, [token, auditId]);

  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full z-50">
      <div className="fixed inset-0 bg-caspian-gray2/50 z-40 p-4"></div>
      <div className="z-50 fixed flex items-center justify-center p-2">
        <div className="min-w-[60vw] max-w-[60vw] bg-caspian-surface shadow-[0_0_5px_#00000020] rounded-lg flex flex-col gap-4 py-3">
          <div className="flex items-center justify-between border-b border-b-caspian-gray3 pb-3 px-4">
            <span className="text-xs font-bold">برنامه ریزی ممیزی</span>
            <PiX
              onClick={() =>
                scheduleTimeId
                  ? setShowDeletePopup(true)
                  : setShowCreateAuditForm(false)
              }
              className="cursor-pointer z-50"
            />
          </div>
          {showDeletePopup && (
            <DeleteSchedulePopup
              setShowDeletePopup={setShowDeletePopup}
              setShowCreateAuditForm={setShowCreateAuditForm}
              scheduleTimeId={scheduleTimeId}
              title={auditSection?.title}
            />
          )}

          <CreateAuditForm
            auditDate={auditDate}
            auditSection={auditSection}
            profileData={profileData}
            scheduleTimeId={scheduleTimeId}
            setScheduleTimeId={setScheduleTimeId}
            setShowCreateAuditForm={setShowCreateAuditForm}
            setRefreshProfile={setRefreshProfile}
            auditId={auditId}
          />
        </div>
      </div>
    </div>
  );
};

export default MainCreateAuditForm;
