import React, { useContext, useState } from 'react'
import { PiCaretLeft, PiTrashLight } from 'react-icons/pi'
import DetailsDocCost from './DetailsDocCost'
import { priceType } from '../../../../../../../helper/pricetype'
import { AuthContext } from '../../../../../../../context/auth/AuthContext'
import API from '../../../../../../../api/API'


const SingleListUploadFileCost = ({ listDocCost, setCostListObject,setAddRecord, costListObject, setRefreshList, idRecord, checked }) => {

    const { token } = useContext(AuthContext);

    const [showModalDetail, setShowModalDetail] = useState(false)
    const [selectItemIndex, setSelectItemIndex] = useState(0)

    const handleDeleteRecord = async (id) => {
        if (!token) return
        try {
            const res = await API.delete(`/api/v1/accounting/record/${id}`,
                {
                    headers: {
                        Authorization: token,
                        "content-type": "multipart/form-data",
                        Accept: "application/json"
                    }
                }
            )
            console.log(res?.data)
            setRefreshList(1)

            setAddRecord(true)


        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className='w-full flex flex-col items-center gap-3' >
            {listDocCost?.map((item, index) => (
                <div key={index} className='w-full flex items-center justify-center gap-5' >
                    <div className='flex justify-between items-center w-full border-b-2 px-3 pb-2 border-caspian-gray2'>

                        <span className={`text-xs 2xl:text-sm ${checked ? "text-caspian-on_primary" : "text-caspian-gray2"}`}>
                            {item?.title}
                        </span>

                        <span className={`text-xs 2xl:text-sm ${checked ? "text-caspian-gray" : "text-caspian-gray2"}`}>
                            {priceType(item?.amount)} (ریال)
                        </span>
                        <div className={`flex items-cente ${checked ? "cursor-pointer" : ""}`}>
                            <span onClick={() => {
                                checked &&
                                    setShowModalDetail(true)
                                setSelectItemIndex(index)
                            }}
                                className={`${checked ? "text-caspian-blue-600" : "text-caspian-blue-400"} text-xs`}>بیشتر</span>
                            <PiCaretLeft className={`${checked ? "text-caspian-blue-600" : "text-caspian-blue-400"} text-base`} />

                        </div>
                    </div>
                    <div className='pb-2'>
                        <PiTrashLight

                            onClick={() => checked && handleDeleteRecord(item?.id)}
                            className={`text-base ${checked ? "text-caspian-secondary-600 cursor-pointer" : "text-caspian-secondary-200"}`} />

                    </div>
                </div>

            ))

                // لیست بر اساس state داخل گزارش دهی
            }
            {/* {costListObject?.map((item, index) => (
                <div key={index} className='w-full flex items-center justify-center gap-5' >
                    <div className='flex justify-between items-center w-full border-b-2 px-3 pb-2 border-caspian-gray2'>
                        <span className='text-xs 2xl:text-sm'>{item?.title}</span>

                        <span className='text-xs text-caspian-gray'>
                            {priceType(item?.amount)}
                        </span>
                        <div className='flex items-center gap-2 cursor-pointer'>
                            <span onClick={() => {
                                setShowModalDetail(true)
                                setSelectItemIndex(index)
                            }}
                                className='text-caspian-blue-600 text-sm'>بیشتر</span>
                            <PiCaretLeft className='text-caspian-blue-600 text-xl' />
                        </div>
                    </div>
                    <div className='pb-2'>
                        <PiTrashLight
                            // onClick={handleDelete}
                            className='text-xl text-caspian-secondary-600 cursor-pointer' />
                    </div>
                </div>
            ))} */}

            {
                showModalDetail &&
                <DetailsDocCost idRecord={idRecord} setCostListObject={setCostListObject} costListObject={costListObject} selectItemIndex={selectItemIndex} listDocCost={listDocCost} showModalDetail={showModalDetail} setShowModalDetail={setShowModalDetail} />
            }
        </ div>
    )
}

export default SingleListUploadFileCost