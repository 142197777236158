const AuthReducer = (state, action) => {
  switch (action.type) {
    case "SIGNING":
      return {
        ...state,
        isLoggedIn: true,
      };
    case "GET_TOKEN":
      return {
        ...state,
        token: action.payload,
      };
    case "GET_USER":
      return {
        ...state,
        user: action.payload,
      };
    case "GET_PROFILE_PERSONAL":
      return {
        ...state,
        profilePersonal: action.payload,
      };
    case "GET_PROFILE_JOB":
      return {
        ...state,
        profileJob: action.payload,
      };
    case "SIGNOUT":
      return {
        ...state,
        isLoggedIn: false,
        token: "",
        user: [],
        profilePersonal: [],
        profileJob: [],
      };
    default:
      return state;
  }
};

export default AuthReducer;
