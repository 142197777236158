import { useState } from "react";
import { PiX } from "react-icons/pi";

const PicturesView = ({ pictures, setShowImage }) => {
  const [zome, setZome] = useState(false);
  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center w-full h-full z-50">
        <PiX
          onClick={() => setShowImage(false)}
          className="cursor-pointer absolute top-2 right-2 text-4xl text-caspian-primary-500 z-50"
        />
        <div
          onClick={() => setShowImage(false)}
          className="fixed inset-0 bg-caspian-gray3/50 z-40"
        ></div>
        <img
          src={pictures}
          alt={"alt"}
          width={600}
          height={600}
          onClick={() => setZome(!zome)}
          className={`${
            zome
              ? "scale-125 duration-300 cursor-zoom-out-new"
              : "duration-300 cursor-zoom-in-new"
          } w-1/3 h-auto rounded-2xl bg-caspian-primary-500 z-50 shadow-[0_0_5px_#00000050]`}
        />
      </div>
    </>
  );
};

export default PicturesView;
