import React from "react";
import MessageNavbar from "./MessageNavbar";

const MainMessage = () => {
  return (
    <div>
      <div className="flex items-center gap-2">
        <h2>search</h2>
        <h2>add message</h2>
      </div>
      <MessageNavbar />
    </div>
  );
};

export default MainMessage;
