import React from "react";

const Logo = () => {
  return (
    <div className="">
      <img
        src="/assets/images/logo/caspian-logo-org.svg"
        alt="caspian team"
        className="w-auto h-auto"
      />
    </div>
  );
};

export default Logo;
