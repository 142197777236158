import React from "react";

const SingleNumberBox = ({ numberTitle, isSelected, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`min-w-[32px] py-1 px-4 text-caspian-secondary-600 border border-caspian-secondary-600 flex items-center gap-4 text-xs font-bold ${
        isSelected ? "bg-caspian-blue-200" : "bg-caspian-transparent"
      }`}
    >
      <span>{numberTitle}</span>
    </button>
  );
};

export default SingleNumberBox;
