import React, { useState } from "react";
import { PiFileText } from "react-icons/pi";

const AbstractMain = ({ dataAbstract, tailsNotTopHandler }) => {
  const [showText, setShowText] = useState(true);

  const topHandler = () => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      {dataAbstract ? (
        <div className="flex flex-col gap-4">
          <p
            className={`${showText ? "line-clamp-3" : ""} text-sm text-justify`}
          >
            {dataAbstract}
          </p>
          <span
            className="text-caspian-secondary-600 font-bold text-xs underline underline-offset-4 cursor-pointer"
            onClick={() => {
              setShowText(!showText);
              !showText && !tailsNotTopHandler && topHandler();
            }}
          >
            {showText ? "نمایش متن" : "مخفی کردن"}
          </span>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center gap-4 py-6">
          <PiFileText className="text-5xl text-caspian-gray" />
          <h2 className="text-caspian-gray text-sm">
            متن جهت نمایش وجود ندارد!
          </h2>
        </div>
      )}
    </>
  );
};

export default AbstractMain;
