
import MainListReportsActionPlan from './list-reports-action-plan/MainListReportsActionPlan'

const MainReportsActionPlan = () => {

  return (
    <div className='flex w-full flex-col gap-10 items-start'>
      <MainListReportsActionPlan />
    </div>
  )
}

export default MainReportsActionPlan