import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";

const EndTimePicker = ({ setEndTime, oldEndTime }) => {
  const showSecond = false;
  const str = showSecond ? "HH:mm:ss" : "HH:mm:00";
  function onChange(value) {
    setEndTime(value && value.format(str));
  }

  return (
    <TimePicker
      minuteStep={10}
      style={{ width: "100%" }}
      showSecond={showSecond}
      inputReadOnly={true}
      onChange={onChange}
      allowEmpty={false}
      placeholder={oldEndTime ? oldEndTime.slice(0, 5) : "انتخاب کنید"}
      disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 22, 23]}
      hideDisabledOptions
    />
  );
};

export default EndTimePicker;
