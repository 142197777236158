import React, { useContext, useEffect, useState } from "react";

import { PiX } from "react-icons/pi";
import { AuthContext } from "../../../../../../../context/auth/AuthContext";
import API from "../../../../../../../api/API";
import Modal from "../../../../../../modal/Modal";

const ChallengeForm = ({
  setShowChallengeForm,
  actionplanIdRelated,
  setRefresh,
}) => {
  const { token } = useContext(AuthContext);
  const [title, setTitle] = useState("");
  const [impact, setImpact] = useState("");
  const [solution, setSolution] = useState("");
  const [challengeData, setChallengeData] = useState([]);
  const [challengeIdSelected, setChallengeIdSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!token) return;
    const getChallengeData = async () => {
      setLoading(true);
      try {
        const res = await API.get(`/api/v1/actionplan/situations/`, {
          headers: { Authorization: token },
        });
        console.log(res?.data);
        setChallengeData(res?.data);
        setChallengeIdSelected(res?.data[0].id);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    getChallengeData();
  }, [token, setRefresh]);

  const dataHandler = async () => {
    if (!token) return;
    let formData = {
      typeSituation: "challenge",
      title: title,
      impact: impact,
      solution: solution,
      id: actionplanIdRelated,
      actionplanRelated: actionplanIdRelated,
    };

    try {
      const res = await API.post(`/api/v1/actionplan/situations/`, formData, {
        headers: { Authorization: token },
      });
      setRefresh(1);
      console.log(res?.data);
      setShowChallengeForm(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal>
      <div className="flex flex-col gap-1.5 w-[460px] bg-caspian-surface p-2 rounded-md shadow-[0_0_10px_#00000050]">
        <div className="flex items-center justify-between w-full pb-2 border-b border-b-caspian-gray2">
          <span className="text-xs text-caspian-gray4">افزودن چالش</span>
          <PiX
            className="cursor-pointer"
            onClick={() => setShowChallengeForm(false)}
          />
        </div>
        <div className="w-full flex flex-col gap-1.5">
          <label className="text-xs text-caspian-gray">عنوان چالش</label>
          <input
            onChange={(e) => setTitle(e.target.value)}
            className="p-2 rounded-md outline-none outline-1 outline-caspian-gray2 text-xs"
          />
        </div>

        <div className="flex flex-col gap-1.5 w-full">
          <label className="text-xs text-caspian-gray">
            آثار این چالش را وارد کنید
          </label>
          <textarea
            onChange={(e) => setImpact(e.target.value)}
            rows={4}
            className="p-2 rounded-md outline-none outline-1 outline-caspian-gray2 text-xs"
          />
        </div>
        <div className="flex flex-col gap-1.5 w-full">
          <label className="text-xs text-caspian-gray">
            راهکارهای حال این چالش را وارد کنید
          </label>
          <textarea
            onChange={(e) => setSolution(e.target.value)}
            rows={4}
            className="p-2 rounded-md outline-none outline-1 outline-caspian-gray2 text-xs"
          />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <button
            onClick={dataHandler}
            className="outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs py-1.5 px-6 rounded-md"
          >
            افزودن
          </button>
          <button
            onClick={() => setShowChallengeForm(false)}
            className="text-xs text-[#FF5B5B] mr-4"
          >
            لغو
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ChallengeForm;
