import React from 'react';

const SingleNeed = ({ id, title, allExpections }) => {
    return (
        <div className='flex justify-between px-3 py-2 odd:bg-caspian-background text-xs 2xl:text-sm'>
            <div className='basis-1/2'>
                <span className='leading-6 line-clamp-2 2xl:line-clamp-3'>
                    {title}
                </span>
            </div>
            <div className='w-[100px] 2xl:w-[300px]'></div>
            <div className='basis-1/2'>
                {
                    allExpections?.map(item => <p className='leading-6' key={item.id}>{item.title}</p>)
                }
            </div>
        </div>
    );
}

export default SingleNeed;
