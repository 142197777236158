import React from "react";
import Group from "./Group";

const Category = ({ dataGroup, title }) => {
  // console.log(title)
  return (
    <div className="bg-[#BFE1D9] rounded-md p-4 min-w-[260px] max-w-[260px] rtl">
      <div className="flex flex-col items-center gap-4">
        <h2 className="text-sm underline underline-offset-8">{title}</h2>
        <div className="flex flex-col gap-2">
          {/* Start Group #1 */}
          {dataGroup.map((d, i) => (
            <Group key={i} dataProcessList={d} />
          ))}
          {/* End Group #1 */}
        </div>
      </div>
    </div>
  );
};

export default Category;
