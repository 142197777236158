import React, { useContext, useEffect, useState } from "react";
import MainAuditChecklistForm from "./audit-checklist-form/MainAuditChecklistForm";
import API from "../../../api/API";
import { AuthContext } from "../../../context/auth/AuthContext";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

const MainAuditChecklist = () => {
  const { token } = useContext(AuthContext);
  const [questionData, setQuestionData] = useState([-1]);
  const [questionFilter, setQuestionFilter] = useState([-1]);
  const location = useLocation();
  const { id, idChecklist, idSection } = useParams();
  console.log(idSection);
  console.log(location?.pathname?.includes("profile"));
  const search = useLocation();
  console.log(search);
  useEffect(() => {
    const getQuestion = async () => {
      try {
        const res = await API.get(
          `/api/v1/audit/question/filter/${idSection}`,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res.data);
        setQuestionData(res?.data);
      } catch (error) {
        console.log(error);
      }
    };
    getQuestion();
  }, [token, idSection]);

  useEffect(() => {
    const getNewQuestion = async () => {
      try {
        const res = await API.get(
          `/api/v1/audit/question/filter/detial/${idSection}`,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data);
        setQuestionFilter(res?.data);
      } catch (error) {
        console.log(error);
      }
    };
    getNewQuestion();
  }, [token, idSection]);

  return location?.pathname?.includes(`/${idSection}/profile`) ? (
    <Outlet />
  ) : (
    <MainAuditChecklistForm
      questionData={questionData.map((q) => q.questionSectionRelated)}
      questionFilter={questionFilter?.questionClause}
      processFilter={questionFilter?.questionProcess}
    />
  );
};

export default MainAuditChecklist;
