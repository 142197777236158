import React, { useState } from "react";
import { PiCaretLeftBold } from "react-icons/pi";
import OutputTableSingle from "./OutputTableSingle";

const OutputTable = ({ data }) => {
  const [showCollapse, setShowCollapse] = useState(false);
  return (
    <div className="w-full bg-caspian-surface rounded-xl shadow-md">
      <div
        onClick={() => setShowCollapse(!showCollapse)}
        className="w-full flex items-center justify-between p-3 cursor-pointer"
      >
        <div className="flex items-center gap-2 text-caspian-secondary-600">
          <span className="font-medium">خروجی</span>
        </div>
        <button>
          <PiCaretLeftBold
            className={
              showCollapse ? "-rotate-90 duration-300" : "rotate-0 duration-300"
            }
          />
        </button>
      </div>
      {showCollapse && (
        <div className="p-3">
          <div className="border border-caspian-gray2 rounded-lg overflow-auto">
            <table className="min-w-full text-left text-sm">
              <thead className="bg-[#FBFCFD] p-4 border-b border-caspian-gray2">
                <tr>
                  <th scope="col" className="text-right px-3 py-6 w-1/3">
                    <h2 className="text-caspian-gray">عنوان</h2>
                  </th>
                  <th scope="col" className="text-right px-3 py-6">
                    <h2 className="text-caspian-gray">نوع</h2>
                  </th>
                  <th scope="col" className="text-right px-3 py-6">
                    <h2 className="text-caspian-gray">ارائه کننده</h2>
                  </th>
                  <th scope="col" className="text-right px-3 py-6">
                    <h2 className="text-caspian-gray">تبادل اطلاعات</h2>
                  </th>
                  <th
                    scope="col"
                    className="rounded-tl-xl text-right px-3 py-6"
                  >
                    <h2 className="text-caspian-gray">عنوان سیستم</h2>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((d, i) => (
                  <OutputTableSingle
                    key={i}
                    //عنوان
                    title={d.informationHeadline}
                    //نوع
                    type={(d.reciverType === "process" && "فرایند") ||
                    (d.reciverType === "stakeholder" && "ذینفع") ||
                    (d.reciverType === "other" && "سایر") 
                  }
                    //ارائه کننده
                    provider={d.reciverData !== null ? d.reciverData : "-"}
                    //نوع تبادل اطلاعات
                    dataTransfer={
                      d.dataTransferType === "manually"
                        ? "دستی"
                        : d.dataTransferType === "automatically"
                        ? "سیستمی"
                        : d.dataTransferType
                    }
                    //عنوان سیستم
                    system={d.dataTransferSoftwar}
                    className={i % 2 !== 0 && "bg-[#FBFCFD]"}
                  />
                ))}
                {/* <OutputTableSingle
                  title="ساختار شکست زمان بندی شده ی مصوب قرارداد"
                  type="از فرایند"
                  provider="امور مالی"
                  dataTransfer="دستی"
                />
                <OutputTableSingle
                  title="گزارش پیشرفت"
                  type="از فرایند"
                  provider="پیمانکار"
                  dataTransfer="سیستمی"
                  system="عنوان سیستم"
                  className="bg-[#FBFCFD]"
                />
                <OutputTableSingle
                  title="صورت وضعیت های تائید شده"
                  type="از ذینفع"
                  provider="کارفرما"
                  dataTransfer="سیستمی"
                  system="عنوان سیستم"
                />
                <OutputTableSingle
                  title="برنامه زمانی بندی بروز شده"
                  type="سایر"
                  provider="امور مالی"
                  dataTransfer="دستی"
                  className="bg-[#FBFCFD]"
                />
                <OutputTableSingle
                  title="نماینده کنترل پروژه"
                  type="سایر"
                  provider="امور مالی"
                  dataTransfer="سیستمی"
                  system="عنوان سیستم"
                /> */}
                {/* <OutputTableSingle
                  title=""
                  type=""
                  provider=""
                  dataTransfer=""
                  system=""
                /> */}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default OutputTable;
