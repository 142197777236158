import React from "react";
import Group from "./Group";

const Category = ({ dataGroup, title }) => {
  // console.log(dataGroup);
  return (
    <div className="rtl flex flex-col justify-center items-center gap-0 w-full">
      <div className="bg-[#F9E2A6] w-full p-2 rounded-t-xl">
        <h2 className="text-sm underline underline-offset-8 text-center">
          {title}
        </h2>
      </div>
      <div className="bg-[#F9E2A6] w-full p-4 rounded-b-xl flex items-center justify-center gap-2">
        {/* Start Group */}
        {dataGroup.map((d, i) => (
          <Group key={i} dataProcessList={d} />
        ))}
        {/* End Group */}
      </div>
      <div
        className="w-0 h-0
                border-l-[5rem] 
                md:border-l-[10rem] 
                lg:border-l-[20rem] 
                border-l-caspian-transparent
                border-t-[15px] 
              border-t-[#F9E2A6]
                border-r-[5rem] 
                md:border-r-[10rem] 
                lg:border-r-[20rem] 
                border-r-caspian-transparent"
      ></div>
    </div>
  );
};

export default Category;
