import Accordion from "../accordion/Accordion";
import TableTask from "../accordion/accordion-content/table-task/TableTask";
import { PiGraduationCap, PiChalkboardTeacher } from "react-icons/pi";

const Safety = ({positionRelatedSafety,positionRelatedEquipment,positionRelatedRules}) => {
    return (
        <>
            <Accordion
                    title=' نکات ایمنی و بهداشت حرفه‌ای '
                    icon={<PiGraduationCap />}>
                    <TableTask items={positionRelatedSafety}/>
                </Accordion>


                <Accordion
                    title='وسایل و تجهیزات ایمنی و بهداشت حرفه‌ای '
                    icon={<PiChalkboardTeacher />}>
                    <TableTask items={positionRelatedEquipment}/>
                </Accordion>

                <Accordion
                    title='قوانین و مقررات ایمنی و بهداشت حرفه‌ای '
                    icon={<PiChalkboardTeacher />}>
                    <TableTask items={positionRelatedRules}/>
                </Accordion>
        </>
    );
}

export default Safety;
