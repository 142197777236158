import React, { useEffect, useState } from "react";
import ChartNavbar from "../ChartNavbar";
import API from "../../../api/API";
import TableListChart from "./TableListChart";
import { Outlet, useParams } from "react-router-dom";
import Loading from "../../../Loading";

const MainListChart = () => {
  const [fullData, setFullData] = useState([-1]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  useEffect(() => {
        const fetchData=async()=>{
          try{
            const res=await API.get('/api/v1/organization/role/position/');
            setFullData(res.data);
            console.log(res.data);
          }
          catch(err){
            console.log('error message:',err.message);
          }
        }
        fetchData();
  }, []);

  return id ? (
    <Outlet />
  ) :fullData[0]===-1?(<Loading />) :fullData.length<1?(<p>داده وجود ندارد.</p>): (
    <>
      <ChartNavbar buttonActive="list-chart" />
      <TableListChart data={fullData} loading={loading} />
    </>
  );
};

export default MainListChart;
