import React, { useEffect, useState } from "react";
import MainProfileDetails from "./main-profile/MainProfileDetails";
import SideProfileDetails from "./side-profile/SideProfileDetails";
import API from "../../../../../api/API";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { PiCaretLeft, PiCircleNotch } from "react-icons/pi";
import { useAppContext } from "../../../../../context/app-context/AppContext";
import MainEditMeetingForm from "../../../forms/edit-meeting/MainEditMeetingForm";
import MeetingList from "./meeting-list/MeetingList";
import MainDetailsPlaninngMeeting from "../details-planning-meeting/MainDetailsPlaninngMeeting";

const MainDetailsProfileMeeting = () => {
  const [showEditMeetingForm, setShowEditMeetingForm] = useState(false);
  const [showEditPlanningMeetingForm, setShowEditPlanningMeetingForm] =
    useState(false);
  const [fullData, setFullData] = useState([-1]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const params = useParams();
  const location = useLocation();
  const search = (location.state && location.state.search) || "";
  const [refresh, setRefresh] = useState(0);
  const [editId, setEditId] = useState(null);
  const [editTitle, setEditTitle] = useState(null);
  const [meetingListRefresh, setMeetingListRefresh] = useState(0);

  useEffect(() => {
    setLoading(true);
    let url = `/api/v1/meeting/profile/detail/${params?.id}/`;
    API.get(url)
      .then((d) => {
        setFullData(d.data);
        setEditId(+params?.id);
        setEditTitle(d?.data?.title);
        setRefresh(0);
      })
      .catch((err) => {
        console.log("error");
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params, refresh]);

  console.log(fullData?.secretary?.placementProfileRelated?.user);
  const { setBradCrump3, setBrcLink2 } = useAppContext();
  useEffect(() => {
    setBrcLink2(
      search
        ? `/managment-of-meetings/`
        : `/managment-of-meetings/profile-meeting/all-profiles`
    );
    setBradCrump3("کد پروفایل w-" + params.id);
  }, [search, location, setBradCrump3, setBrcLink2, params]);

  const [serachParams, setSearchParams] = useSearchParams();
  const meeting = serachParams.get("meeting");

  function handleSearch(key, value) {
    setSearchParams((prevParams) => {
      if (value === null || value === "") {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    });
  }

  return (
    <div className="bg-caspian-gray3 w-full min-h-screen">
      {fullData[0] === -1 && !error ? (
        <div className="flex items-center justify-center py-24">
          <div className="flex items-center gap-2">
            <PiCircleNotch className="text-caspian-gray text-sm animate-spin" />
            <h2 className="text-caspian-gray text-sm">
              در حال بارگذاری اطلاعات
            </h2>
          </div>
        </div>
      ) : error ? (
        <div>
          <Link
            to={`./all-profiles${search}`}
            className="flex items-center gap-2 text-caspian-secondary-700 p-2 mr-auto w-fit"
          >
            <span className="text-sm">بازگشت</span>
            <PiCaretLeft />
          </Link>
          <div className="flex items-center justify-center py-24">
            <div className="flex items-center gap-2">
              <h2 className="text-caspian-gray text-sm">اطلاعات یافت نشد</h2>
            </div>
          </div>
        </div>
      ) : (
        <>
          {meeting ? (
            <div className="flex flex-col gap-2">
              <button
                onClick={() => handleSearch("meeting", "")}
                className="mr-auto flex items-center gap-2 text-caspian-secondary-700 p-2"
              >
                <span className="text-sm">بازگشت</span>
                <PiCaretLeft />
              </button>
              <MainDetailsPlaninngMeeting
                setShowEditPlanningMeetingForm={setShowEditPlanningMeetingForm}
              />
            </div>
          ) : (
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-between">
                <button
                  onClick={() => setShowEditMeetingForm(true)}
                  className="min-w-[6rem] outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs p-2 rounded-md"
                >
                  ویرایش اطلاعات
                </button>
                <Link
                  to={`../${search}`}
                  className="mr-auto flex items-center gap-2 text-caspian-secondary-700 p-2"
                >
                  <span className="text-sm">بازگشت</span>
                  <PiCaretLeft />
                </Link>
              </div>

              <div className="flex items-start gap-2">
                <div className="w-[70%] flex flex-col gap-2">
                  <div className="bg-caspian-surface rounded-md p-3 shadow-[0_0_2px_#00000050]">
                    <MainProfileDetails fullData={fullData} />
                  </div>
                  <MeetingList
                    secretaryId={
                      fullData?.secretary?.placementProfileRelated?.user
                    }
                    profileId={+params?.id}
                    meetingListRefresh={meetingListRefresh}
                    setMeetingListRefresh={setMeetingListRefresh}
                    showEditPlanningMeetingForm={showEditPlanningMeetingForm}
                    setShowEditPlanningMeetingForm={
                      setShowEditPlanningMeetingForm
                    }
                  />
                </div>
                <div className="w-[30%]">
                  <SideProfileDetails
                    fullData={fullData}
                    setMeetingListRefresh={setMeetingListRefresh}
                  />
                </div>
              </div>
            </div>
          )}
          {showEditMeetingForm && (
            <MainEditMeetingForm
              setRefresh={setRefresh}
              editId={editId}
              editTitle={editTitle}
              setShowEditMeetingForm={setShowEditMeetingForm}
            />
          )}
        </>
      )}
    </div>
  );
};

export default MainDetailsProfileMeeting;
