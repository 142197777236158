import React, { useEffect, useRef, useState } from 'react'
import ButtonTab from '../../../action-plan/actions/ButtonTab'
import MainTabsProfileActionPlan from './tabs-profile-action-plan/MainTabsProfileActionPlan'
import MainListGoalIndicator from './tabs-profile-indicator/MainListGoalIndicator'
import { useSearchParams } from 'react-router-dom'
const TabsProfile = ({ data }) => {

    const [activeTab, setActiveTab] = useState("actionPlan")
    const [queryIndicator, setQueryIndicator] = useState("")
    const [searchParams, setSearchParams] = useSearchParams();
    const query = searchParams.get("P")
    // const searchHandlerTabIND = (e) => {
    //     e.preventDefault();
    //     handleSearch('search', '')
    //     handleSearch("page", '')
    //     handleSearch("rows", '')
    //     handleSearch("P", "indicator");

    //     setActiveTab("indicator")
    // }
    // const searchHandlerTabACT = () => {
    //     setActiveTab("actionPlan")
    //     handleSearch("P", "");
    // }

    const handleSearch = (key, value) => {
        setSearchParams((prev) => {
            if (value === null || value === "") {
                prev.delete(key)
            } else {
                prev.set(key, value)
            }
            return prev;
        })
    }
    console.log(query)
    useEffect(() => {
        if (query === "indicator") {
            setActiveTab("indicator")
        } else {
            setActiveTab("actionPlan")
        }
    }, [query, activeTab])

    useEffect(() => {
        if (query) {
            setQueryIndicator(query)
        }
    }, [query, setQueryIndicator])

    return (
        <div className="w-full p-2 rounded-md shadow-[0_0_10px_#00000050] min-h-fit bg-caspian-surface flex flex-col gap-4">
            <div className="flex items-center gap-4 py-1.5 px-2 rounded-md">

            </div>
            <div className="flex items-center gap-4 py-1.5 px-2 rounded-md">
                <ButtonTab
                    activeTab={activeTab}
                    active="actionPlan"
                    onClick={() => {
                        setActiveTab("actionPlan")
                        handleSearch("P", "");
                    }}

                    title="برنامه‌های اجرایی مرتبط"
                />
                <ButtonTab
                    activeTab={activeTab}
                    active="indicator"
                    onClick={() => {
                        handleSearch('search', '')
                        handleSearch("page", '')
                        handleSearch("rows", '')
                        handleSearch("P", "indicator");
                    }}
                    title="پایش اندازه گیری"
                />
            </div>

            {activeTab === "actionPlan" ? (
                <MainTabsProfileActionPlan activeTab={activeTab} />
            ) : (activeTab === "indicator" && (
                < MainListGoalIndicator data={data} />
            ))}
        </div>
    )
}

export default TabsProfile
