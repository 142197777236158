import React, { useContext, useEffect, useRef, useState } from 'react'
import Modal from '../../../../../../modal/Modal'
import { PiPaperclip, PiX } from 'react-icons/pi'
import ShowUploadFileCost from './ShowUploadFileCost'
import API from '../../../../../../../api/API'
import { AuthContext } from '../../../../../../../context/auth/AuthContext'
import DateTimePicker from './DateTimePicker'


const ModalUploadFileCost = ({ setShowModalUploadFile, reportId, setListDocCost, setAddRecord, setIdRecord, setRefreshList }) => {

    const { token } = useContext(AuthContext);

    const fileRef = useRef()
    const titleRef = useRef();
    const descreiptionRef = useRef();
    const dateRef = useRef();
    const numberRef = useRef();
    const costRef = useRef();

    const [titleDocCost, setTitleDocCost] = useState("")
    const [file, setFile] = useState(null)
    const [idFile, setIdFile] = useState(null)
    const [showFiles, setShowFiles] = useState(file?.length > 0)
    const [descreiptionDocCost, setDescreiptionDocCost] = useState("")
    const [dateDocCost, setDateDocCost] = useState()
    const [costDocCost, setCostDocCost] = useState("")
    const [numberDocCost, setNumberDocCost] = useState("")
    const [emptyTitle, setEmptyTitle] = useState(false)
    const [emptyFile, setEmptyFile] = useState(false)
    const [emptyCost, setEmptyCost] = useState(false)
    const [emptyDate, setEmptyDate] = useState(false)

    useEffect(() => {
        if (file) {
            handleFile()
        }
    }, [file])

    //record
    const handleDocument = async () => {
        if (!token || !reportId) return;

        let hasError = false;
        if (!titleDocCost) {
            setEmptyTitle(true);
            hasError = true;
        }

        if (!dateDocCost) {
            setEmptyDate(true);
            hasError = true;
        }

        if (!costDocCost) {
            setEmptyCost(true);
            hasError = true;
        }

        if (!file) {
            setEmptyFile(true);
            hasError = true;
        }

        if (hasError) return;

        if (file) {
            let formData = {
                object_id: reportId,
                content_type: "actionplan_app.taskreportprofile",
                title: titleDocCost,
                recordDate: dateDocCost,
                number: numberDocCost,
                amount: costDocCost,
                description: descreiptionDocCost,
                documentsRelated: idFile
            }
            if (idFile) {
                try {
                    // console.log(formData)
                    const res = await API.post('/api/v1/accounting/record/', formData,
                        {
                            headers: {
                                Authorization: token,
                                "content-type": "multipart/form-data",
                                Accept: "application/json"
                            }
                        }
                    )
                    console.log(res?.data)
                    setIdRecord(prevIdRecord => [...prevIdRecord, res?.data?.id])

                    setListDocCost(prevList => [...prevList, res?.data]);
                    setRefreshList(1)
                    setAddRecord(true)

                    setShowModalUploadFile(false)
                }
                catch (error) {
                    console.log(error)
                }
            }

        } else {

            let formData = {
                object_id: reportId,
                content_type: "actionplan_app.taskreportprofile",
                title: titleDocCost,
                recordDate: dateDocCost,
                number: numberDocCost,
                amount: costDocCost,
                description: descreiptionDocCost,
                documentsRelated: idFile
            }

            try {
                // console.log(formData)
                const res = await API.post('/api/v1/accounting/record/', formData,
                    {
                        headers: {
                            Authorization: token,
                            "content-type": "multipart/form-data",
                            Accept: "application/json"
                        }
                    }
                )
                console.log(res?.data)
                setIdRecord(prevIdRecord => [...prevIdRecord, res?.data?.id])
                setRefreshList(1)
                // setListDocCost(prevList => [...prevList, res?.data]);
                // setCostListObject(costObject => [...costObject, res?.data])
                setShowModalUploadFile(false)

                console.log(res?.data)
            } catch (error) {
                console.log(error)
            }
            setShowModalUploadFile(false)
        }
    }

    // FileRecord
    const handleFile = async () => {
        if (!token || !file) return;

        let formData = {
            document: file,
            title: file?.name,
        }

        try {
            const res = await API.post('/api/v1/accounting/file/', formData,
                {
                    headers: {
                        Authorization: token,
                        "content-type": "multipart/form-data",
                        Accept: "application/json"
                    }
                }
            )
            console.log(res?.data)
            setIdFile(res?.data?.id)
        } catch (error) {
            console.log(error)
        }
    }

    const clearDate = () => {
        setShowModalUploadFile(false)
        setShowFiles(false)
        setFile(null);
        setTitleDocCost("");
        setDateDocCost("");
        setCostDocCost("");
        setDescreiptionDocCost("");
    }

    return (
        <Modal>
            <div className='flex flex-col items-center w-[400px] md:w-[480px] px-5 bg-caspian-surface rounded-lg'>
                <div className='w-full flex items-center justify-between border-b-2 py-5 border-b-caspian-gray2'>
                    <span className='text-xs 2xl:text-base'>افزودن فاکتور</span>

                    <PiX className='text-xl cursor-pointer text-caspian-gray4'
                        onClick={clearDate}
                    />
                </div>
                <div className='flex flex-col items-start w-full py-5 gap-3'>

                    <div className='flex flex-col items-start w-full gap-2'>
                        <div className='w-full flex items-center gap-5'>
                            <span className='text-xs text-caspian-gray4'>
                                عنوان فاکتور
                            </span>
                            {emptyTitle &&
                                <span className='text-xs text-caspian-error2'>فیلد الزامی *</span>
                            }
                        </div>

                        <input type='text' placeholder='عنوان فاکتور را مشخص کنید' onChange={(e) => setTitleDocCost(e.target.value)} ref={titleRef}
                            className='w-full rounded-md py-2 px-3 text-xs font-semibold placeholder:text-caspian-gray placeholder:font-normal outline-none border-2 border-caspian-gray2/80' />
                    </div>


                    <div className='flex flex-col items-start w-full gap-2'>
                        <div className='w-full flex items-center gap-5'>
                            <span className='text-xs text-caspian-gray4'>
                                انتخاب تاریخ فاکتور
                            </span>
                            {emptyDate &&
                                <span className='text-xs text-caspian-error2'>فیلد الزامی *</span>
                            }
                        </div>

                        <div className='w-full rounded-md py-2 px-3 text-xs font-semibold placeholder:text-caspian-gray placeholder:font-normal outline-none border-2 border-caspian-gray2/80' >
                            <DateTimePicker setDate={setDateDocCost} />
                        </div>
                    </div>

                    <div className='flex flex-col items-start w-full gap-2'>
                        <span className='text-xs text-caspian-gray4'>
                            شماره فاکتور
                        </span>

                        <input type='text' placeholder='شماره فاکتور را وارد کنید' onChange={(e) => setNumberDocCost(e.target.value)} ref={numberRef}
                            className='w-full rounded-md py-2 px-3 text-xs font-semibold placeholder:text-caspian-gray placeholder:font-normal outline-none border-2 border-caspian-gray2/80' />
                    </div>

                    <div className='flex flex-col items-start w-full gap-2'>
                        <div className='w-full flex items-center gap-5'>

                            <span className='text-xs text-caspian-gray4'>
                                مبلغ فاکتور
                            </span>
                            {emptyCost &&
                                <span className='text-xs text-caspian-error2'>فیلد الزامی *</span>
                            }
                        </div>

                        <input type='text' placeholder='رقم مبلغ فاکتور را به ریال وارد کنید' onChange={(e) => setCostDocCost(e.target.value)} ref={costRef}
                            className='w-full rounded-md py-2 px-3 text-xs font-semibold placeholder:text-caspian-gray placeholder:font-normal outline-none border-2 border-caspian-gray2/80' />
                    </div>

                    <div className='flex flex-col items-start w-full gap-2'>
                        <span className='text-xs text-caspian-gray4'>توضیحات</span>
                        <textarea placeholder='توضیحات خود را وارد کنید' rows={5} onChange={(e) => setDescreiptionDocCost(e.target.value)} ref={descreiptionRef}
                            className='w-full rounded-md py-2 px-3 resize-none text-xs font-semibold placeholder:text-caspian-gray placeholder:font-normal outline-none border-2 border-caspian-gray2/80'
                        />
                    </div>

                    <div className='flex flex-col items-start w-full gap-2'>
                        <div className='w-full flex items-center gap-5'>

                            <label htmlFor='upload-file' className='flex gap-2 items-center text-caspian-secondary-600 cursor-pointer'>
                                <PiPaperclip />
                                <span className='text-xs'>انتخاب فایل</span>
                            </label>
                            {emptyFile &&
                                <span className='text-xs text-caspian-error2'>فیلد الزامی *</span>
                            }
                        </div>

                        <input type='file' ref={fileRef} id='upload-file' className='hidden' onChange={(e) => {
                            setFile(e.target.files[0])
                            setShowFiles(true)
                        }} />

                        {showFiles &&
                            <ShowUploadFileCost fileRef={fileRef} setShowFiles={setShowFiles} idFile={idFile} file={file} setFile={setFile} setListDocCost={setListDocCost} />
                        }
                    </div>
                </div>
                <div className='flex gap-5 items-center justify-start w-full pb-5'>
                    <button
                        onClick={handleDocument}
                        className='text-sm text-caspian-secondary-600 border border-caspian-secondary-600 rounded-md w-[70px] h-[30px] '>
                        تائید
                    </button>

                    <button
                        onClick={clearDate}
                        className='text-sm text-caspian-error2 '>
                        لغو
                    </button>
                </div>
            </div>
        </Modal >
    )
}

export default ModalUploadFileCost