import { PiCalendar, PiFolderOpen } from "react-icons/pi";
import FormNote from "./FormNote";
import { useEffect, useState } from "react";
import FormNoteNew from "./FormNoteNew";

const AddNoteNew = ({
  loading,
  fullData,
  setRefresh,
  setShowAddNote,
  setColor,
  color,
}) => {
  const [notebook, setNotebook] = useState(null);
  useEffect(() => {
    if (notebook === "انتخاب دفترچه") {
      setNotebook(null);
    }
  }, [notebook]);
  console.log(notebook)
  return loading ? (
    <></>
  ) : (
    <div className={`w-full flex flex-col gap-2 px-2 pb-2`}>
      <div className={``}>
        <div className="flex items-center gap-2 border-b border-b-caspian-gray4/50 pb-1">
          <PiFolderOpen className="text-2xl text-caspian-on_primary" />
          <select
            name="notebook"
            onChange={(e) => setNotebook(e.target.value)}
            className="text-xs outline-none bg-caspian-transparent cursor-pointer w-full 
          "
          >
            <option value={null}>انتخاب دفترچه</option>
            {fullData[0] !== -1 &&
              fullData?.map((data, i) => (
                <option key={i} value={+data?.id}>
                  {data?.title}
                </option>
              ))}
          </select>
        </div>
        {/* <div className="flex items-center gap-2 border-b border-b-caspian-gray4 py-2">
          <PiCalendar className="text-2xl text-caspian-gray4" />
          <h2 className="text-caspian-gray4 text-xs">تاریخ ایجاد</h2>
          <span className="text-caspian-gray4 text-xs">
            {new Date().toLocaleDateString("fa-IR")}
          </span>
        </div> */}
      </div>

      <FormNoteNew
        setRefresh={setRefresh}
        notebook={notebook}
        setShowAddNote={setShowAddNote}
        color={color}
        setColor={setColor}
      />
    </div>
  );
};

export default AddNoteNew;
