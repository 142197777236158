import React from "react";
import { priceType } from "../../../../../../helper/pricetype";

const BudgetChartPmo = ({ cost, costTotalTasks }) => {
  const maxCost = cost;
  const minCost = costTotalTasks;
  const remainingCost = maxCost - minCost;
  const costPercent = (minCost / maxCost) * 100;
  return (
    <div className="flex flex-col gap-4 w-full">
      {/* بودجه تخمینی PMO */}
      <div className="flex flex-col items-end gap-2 w-full">
        
        <div className="flex items-center gap-4 w-full">
          <div className="text-xs basis-[5%]"></div>
          <div className="w-full basis-[75%] bg-caspian-gray2/30 h-1">
            <div className={`bg-caspian-gray4 h-1 mr-auto relative`}>
              <div className="flex items-center gap-1 absolute left-1/2 -translate-x-1/2 bottom-2">
                <span className="text-xs ltr">{priceType(maxCost)}</span>
                <span className="text-xs">ریال</span>
              </div>
            </div>
          </div>
          <div className="basis-[20%]">
          <span className="text-xs whitespace-nowrap">براورد تخمینی PMO</span>
          </div>
        </div>
      </div>
      {/* جمع بودجه وظایف */}
      <div className="flex flex-col items-end gap-2 w-full">
        <div className="flex items-center gap-4 w-full">
          <div className="text-xs basis-[5%]"></div>
          <div className="w-full basis-[75%] bg-caspian-gray2/30 h-1">
            <div
              style={{
                width: costPercent + "%",
              }}
              className={`bg-caspian-error2 h-1 mr-auto relative`}
            >
              <div className="flex items-center gap-1 absolute left-1/2 -translate-x-1/2 bottom-2">
                <span className="text-xs ltr">{priceType(minCost)}</span>
                <span className="text-xs">ریال</span>
              </div>
            </div>
          </div>
          <div className="basis-[20%]">
            <span className="text-xs">جمع بودجه وظایف</span>
          </div>
        </div>
      </div>
      {/* بودجه باقیمانده */}
      <div className="flex flex-col items-end gap-2 w-full">
        <div className="flex items-center gap-4 w-full">
          <div className="basis-[5%]"></div>
          <div className="w-full basis-[75%] bg-caspian-gray2/30 h-1">
            <div
              style={{
                width: 100 - costPercent + "%",
              }}
              className="bg-caspian-primary-500 h-1 relative"
            >
              <div className="flex items-center gap-1 absolute left-1/2 -translate-x-1/2 bottom-2">
                <span className="text-xs ltr">{priceType(remainingCost)}</span>
                <span className="text-xs">ریال</span>
              </div>
              <div className="border-l border-dashed border-l-caspian-gray h-20 absolute left-0 bottom-0"></div>
            </div>
          </div>
          <div className="basis-[20%]">
            <span className="text-xs">بودجه باقیمانده</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BudgetChartPmo;
