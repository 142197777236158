import { PiX } from 'react-icons/pi';
import SinglePopUpGoal from './SinglePopUpGoal';
import Modal from '../../../ui/ModalModel';

const MainPopUpGolas = ({ titleGoal, titleMacroGoal, macroGoals, setGoalsId, setShowPopUp }) => {
    return (
        <Modal>
            <div className='w-full h-full fixed '>
                <PiX className='cursor-pointer text-3xl absolute left-10 top-10' onClick={() => {
                    setShowPopUp(false)
                    setGoalsId(null)
                }} />
            </div>
            <div className='z-50 gap-2 flex flex-col relative items-center'>
                <div className='flex items-center '>

                    <span
                        style={{ background: `#${titleGoal?.colorHex}` }}
                        className='shadow-[0_0_10px_#00000080] text-xs 2xl:text-base text-center text-caspian-surface/95 z-50 font-bold p-4 min-w-[120px] rounded-[50%] '>
                        {titleGoal.title}
                    </span>

                    <div className='w-3/5 h-[1px] absolute -right-24 2xl:-right-28 bg-caspian-on_primary/50'></div>
                    <span className='absolute text-xs 2xl:text-base font-bold -right-36 2xl:-right-44'>منظر</span>

                </div>

                <div className='h-[45px]'>
                    <img className='drop-shadow-[0_0_5px_#00000090]' src="/assets/images/goals/arrow3.svg" alt="arrow" />
                </div>

                <div className='flex items-center'>
                    <span
                        style={{ background: `#${titleMacroGoal?.colorHex}` }}
                        className='shadow-[0_0_20px_#00000050] z-50 text-xs 2xl:text-sm text-center py-3 2xl:py-6 px-5 min-w-[360px] max-w-[360px] 2xl:min-w-[410px] 2xl:max-w-[410px] text-caspian-on_primary flex items-center justify-center rounded-lg'>
                        {titleMacroGoal.title}
                    </span>
                    <div className='w-1/4 h-[0.5px] absolute -right-24 2xl:-right-28 bg-caspian-on_primary/50'></div>
                    <span className='absolute text-xs 2xl:text-base font-bold -right-40 2xl:-right-52'>هدف کلان</span>

                </div>
                <div className='h-[45px]'>
                    <img className='drop-shadow-[0_0_5px_#00000090]' src="/assets/images/goals/arrow3.svg" alt="arrow" />
                </div>

                <div className='flex flex-col justify-start items-center w-[380px] 2xl:w-[430px] h-fit max-h-[300px] 2xl:max-h-[400px] overflow-auto p-3 2xl:p-5 rounded-lg shadow-[0_0_20px_#00000050] bg-caspian-surface'>

                    <div className='flex items-center'>
                        <div className='w-3/12 h-[1px] absolute -right-24 2xl:-right-28 bg-caspian-on_primary/50'></div>
                        <span className='absolute text-xs 2xl:text-base font-bold -right-40 2xl:-right-52'>اهداف خرد</span>
                    </div>
                    <SinglePopUpGoal titleGoal={titleGoal} macroGoals={macroGoals} setGoalsId={setGoalsId} setShowPopUp={setShowPopUp} />
                </div>
            </div>

        </Modal >

    )
}

export default MainPopUpGolas;