import React, { useEffect, useState } from "react";
import GoalsNavbar from "../GoalsNavbar";
import API from '../../../api/API';
import SingleModel from "./SingleModel";
import SingleModelGoal from "./SingleModelGoal";
import MainPopUpGolas from "./pop-up-model-goals/MainPopUpGoals";
import Loading from "../../../Loading";

const MainModelGoals = () => {
  const [dataGoals, setDataGoals] = useState([-1])
  const [showPopUp, setShowPopUp] = useState(false)
  const [goalsId, setGoalsId] = useState(null)

  useEffect(() => {
    const handleData = async () => {
      try {
        const res = await API.get('/api/v1/goal/model')
        setDataGoals(res?.data)
        console.log(res?.data)
      } catch (error) {
        console.log(error)
      }
    }
    handleData()
  }, [])


  return (
    dataGoals[0] === -1 ? <Loading   /> : dataGoals?.length < 1 ? <span>دیتایی یافت نشد</span> :

    <div className="flex flex-col gap-0">
      <GoalsNavbar buttonActive="model" />

      <div className='p-5 w-full flex flex-col items-center'>
        <span className="w-full p-3 2xl:p-5 bg-caspian-primary-100 text-xs 2xl:text-lg rounded-t-md shadow-[0_0_5px_#00000050]">مدل اهداف سازمانی</span>
        <div className="min-h-screen w-full overflow-x-auto flex justify-start flex-col items-start px-5 bg-caspian-surface shadow-[0_5px_5px_#00000050] rounded-b-md ">

          <div className=" flex justify-start items-start pt-10 ">

            <div className='flex items-start justify-start pt-5 2xl:pt-4 flex-col gap-24 '>
              <div className='flex items-center relative top-2 gap-10'>
                <span className='whitespace-nowrap text-caspian-darkgray-700 text-xs xl:text-base font-bold'>مناظر</span>
                <div className='w-20'></div>
              </div>

              <div className='flex items-center gap-2'>
                <span className='whitespace-nowrap text-caspian-darkgray-700 text-xs xl:text-base font-bold'>اهداف کلان</span>
                <div className='w-20 h-0.5 text-xs xl:text-base bg-caspian-darkgray-600'></div>
              </div>
            </div>

            <div className='w-2/3 flex items-start gap-5 2xl:gap-8 justify-start pt-2 basis-3/5'>
              {dataGoals?.map((goals, index) => (
                <div key={index} className='flex flex-col items-center w-fit gap-2'>
                  <SingleModel titleGoal={goals} />
                  {goals?.prespectiveRelatedGoal?.map((macroGoals, index) => (
                    <div key={index} className='flex flex-col rounded-md'>
                      <SingleModelGoal setGoalsId={setGoalsId} titleMacroGoal={macroGoals} setShowPopUp={setShowPopUp} />

                      {showPopUp && macroGoals?.id === goalsId && macroGoals?.goalRelatedObjective?.length !== 0 &&
                        <MainPopUpGolas titleGoal={goals} titleMacroGoal={macroGoals} macroGoals={macroGoals?.goalRelatedObjective} setGoalsId={setGoalsId} setShowPopUp={setShowPopUp} />
                      }
                    </div>
                  ))}
                </div>
              ))}

            </div>
          </div>
        </div>
      </div >
    </div>
  );
};

export default MainModelGoals;
