import React from "react";
import Details from "./Details";

const MainDetailsSingleRefer = () => {
  return (
    <div className="bg-caspian-surface min-h-screen">
      <Details />
    </div>
  );
};

export default MainDetailsSingleRefer;
