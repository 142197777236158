import React, { useEffect, useState } from "react";
import ChartNavbar from "../ChartNavbar";
import API from '../../../api/API';
import Loading from "../../../Loading";
import PersonalTable from "./personal-table/PersonalTable";

const MainListPersonal = () => {
  const [data, setData] = useState([-1]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await API.get('/api/v1/organization/role/placement/');
        setData(res.data)
        console.log(res.data);
      }
      catch (err) {
        if(err.response.status===404){
          console.log('error message:',err.message);
          setData([]);
        }
      }
    }
    fetchData();
  }, [])
  return (
    <>
      <ChartNavbar buttonActive="list-personal" />
      {
        data[0]===-1?(<Loading />):data?.length<1?(<p className="text-center py-20 text-xs 2xl:text-sm font-bold text-caspian-gray4">داده وجود ندارد.</p>):
        (<PersonalTable data={data} />)
      }
    </>
  );
};

export default MainListPersonal;
