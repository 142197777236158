import splitNameFile from '../../../../../../../helper/splitNameFile'
import Modal from '../../../../../../modal/Modal'
import { PiCloudArrowDownLight, PiX } from 'react-icons/pi'

const ModalListCostReport = ({ setShowModal, dataListCost, selectId }) => {

    const convertSize = (size) => {
        if (size < (1024 * 1024)) {
            return (size / 1024).toFixed(1) + "KB"
        } else if (size < (1024 * 1024 * 1024)) {
            return (size / (1024 * 1024)).toFixed(1) + "MB"
        } else {
            return (size / (1024 * 1024 * 1024)).toFixed(1) + "GB"
        }
    }
    return (
        <Modal>

            {dataListCost?.map((item) => (
                item?.id === selectId &&

                <div key={item?.id} className='w-2/3 md:w-1/2 lg:w-1/3 flex gap-10 rounded-lg shadow-[0_0_5px_#00000050] items-center flex-col bg-caspian-surface p-7' >

                    <div className='flex items-center justify-between w-full'>
                        <span className='text-xs 2xl:text-base'>جزئیات فاکتور</span>

                        <PiX onClick={() => setShowModal(false)}
                            className='text-base cursor-pointer text-caspian-gray4 2xl:text-2xl' />
                    </div>

                    <div className='flex flex-col items-center w-full gap-3'>

                        <div className='flex items-center justify-between w-full pb-2 border-b-2 border-b-caspian-gray2/40'>
                            <span className='text-xs 2xl:text-sm text-caspian-gray4'>عنوان فاکتور :</span>
                            <span className='text-xs 2xl:text-sm'>{item?.title}</span>
                        </div>

                        <div className='flex items-center justify-between w-full pb-2 border-b-2 border-b-caspian-gray2/40'>
                            <span className='text-xs 2xl:text-sm text-caspian-gray4'>تاریخ فاکتور :</span>
                            <span className='text-xs 2xl:text-sm'>{new Date(item?.recordDate).toLocaleDateString('fa-IR')}</span>
                        </div>

                        <div className='flex items-center justify-between w-full pb-2 border-b-2 border-b-caspian-gray2/40'>
                            <span className='text-xs 2xl:text-sm text-caspian-gray4'>شماره فاکتور :</span>
                            <span className='text-xs 2xl:text-sm'>{item?.number}</span>
                        </div>

                        <div className='flex items-center justify-between w-full pb-2 border-b-2 border-b-caspian-gray2/40'>
                            <span className='text-xs 2xl:text-sm text-caspian-gray4'>مبلغ فاکتور(ريال) :</span>
                            <span className='text-xs 2xl:text-sm'>{item?.amount}</span>
                        </div>

                        <div className='flex items-center w-full gap-5 py-2 pb-2 border-b-2 border-b-caspian-gray2/40'>
                            <span className='text-xs 2xl:text-sm whitespace-nowrap text-caspian-gray4'>توضیحات :</span>
                            <span className='text-xs leading-7 2xl:text-sm'>{item?.describtion}</span>
                        </div>

                        <div className='flex flex-col items-start w-full '>
                            <span className='py-3 text-xs 2xl:text-sm text-caspian-gray4'>فایل بارگذاری شده سند :</span>

                            <div className='flex items-center w-full gap-3'>
                                <div className='w-[90%] px-4 py-2 flex items-center justify-between border-b-2 rounded-md  border-caspian-gray2'>
                                    <div className='flex items-center w-full gap-5 '>
                                        <img src='/assets/images/action-plan/Group.svg' className='w-4 2xl:w-6' alt='' />
                                        <span className='text-xs '>{splitNameFile(item?.fileDocument ? item?.fileDocument : "-")}</span>
                                    </div>

                                    <span className='text-xs text-caspian-gray4'>{convertSize(item?.size ? item?.size : 0)}</span>

                                </div>
                                <a href={item?.fileDocument} rel="noreferrer" target='_blank' download={true}>
                                    <PiCloudArrowDownLight className='text-2xl cursor-pointer text-caspian-secondary-600' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

            ))}
        </Modal >
    )
}

export default ModalListCostReport