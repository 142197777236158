import { useState } from "react";
import API from "../../../api/API";
import MenuAction from "./MenuAction";

const HistoryBox = ({
  convId,
  convName,
  lastMessage,
  newMessage,
  setShowChatContent,
  setIsIdConversation,
  setIsIdUser,
  userId,
  avatar,
  thisChatType,
  setChatType,
  thisGroupName,
  setGroupName,
  setRefreshMyconversation,
}) => {
  const [open, setOpen] = useState(false);
  // const [conversationId, setConversationId] = useState()
  return (
    <div
      onContextMenu={(e) => {
        e.preventDefault();
        setOpen(!open);
      }}
      onClick={() => {
        !open && setIsIdConversation(convId);
        !open && setIsIdUser(userId);
        !open && setShowChatContent(true);
        !open && setChatType(thisChatType);
        !open && setGroupName(thisGroupName);
      }}
      className="relative py-2 border-b border-b-caspian-gray2 last:border-b-0 flex items-center justify-between gap-2 w-full cursor-pointer"
    >
      <MenuAction
        open={open}
        setOpen={setOpen}
        conversationId={convId}
        setRefreshMyconversation={setRefreshMyconversation}
      />
      {avatar ? (
        <img
          className="w-7 h-7 rounded-full"
          src={API.defaults.baseURL + avatar}
          alt={convName}
        />
      ) : (
        <div className="w-8 h-8 rounded-full flex items-center justify-center bg-[#FCC934] text-caspian-surface">
          {convName ? convName[0] : ""}
        </div>
      )}
      <div className="flex flex-col gap-2 w-[70%]">
        <div className="flex items-center gap-2 w-full">
          <span className="text-xs font-bold">{convName}</span>
        </div>
        <div className="flex items-center gap-2 w-full">
          <span className="text-[11px] text-caspian-gray line-clamp-1">{lastMessage}</span>
        </div>
      </div>
      {newMessage < 1 ? (
        <div className="w-5 h-5"></div>
      ) : (
        <span className="w-5 h-5 flex items-center justify-center rounded-full bg-caspian-secondary-500 text-[12px] text-caspian-surface">
          {newMessage}
        </span>
      )}
    </div>
  );
};

export default HistoryBox;
