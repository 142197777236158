import React, { useState } from "react";
import { PiCaretLeftBold } from "react-icons/pi";
import ProcessIndicatorsTableSingle from "./ProcessIndicatorsTableSingle";

const ProcessIndicatorsTable = ({ data }) => {
  const [showCollapse, setShowCollapse] = useState(false);
  return (
    <div className="w-full bg-caspian-surface rounded-xl shadow-md">
      <div
        onClick={() => setShowCollapse(!showCollapse)}
        className="w-full flex items-center justify-between p-3 cursor-pointer"
      >
        <div className="flex items-center gap-2 text-caspian-secondary-600">
          <span className="font-medium">شاخص های فرایندی</span>
        </div>
        <button>
          <PiCaretLeftBold
            className={
              showCollapse ? "-rotate-90 duration-300" : "rotate-0 duration-300"
            }
          />
        </button>
      </div>
      {showCollapse && (
        <div className="p-3">
          <div className="border border-caspian-gray2 rounded-lg overflow-auto">
            {data.length < 1 ? (
              <h2 className="text-caspian-gray2 p-4 w-full flex items-center justify-center">
                شاخص های فرایندی وجود ندارد!
              </h2>
            ) : (
              <table className="min-w-full text-left text-sm">
                <thead className="bg-[#FBFCFD] p-4 border-b border-caspian-gray2">
                  <tr>
                    <th scope="col" className="text-right px-3 py-6 w-1/5">
                      <h2 className="text-caspian-gray">عنوان</h2>
                    </th>
                    <th scope="col" className="text-right px-3 py-6 w-[10%]">
                      <h2 className="text-caspian-gray">نوع</h2>
                    </th>
                    <th scope="col" className="text-right px-3 py-6">
                      <h2 className="text-caspian-gray">فرمول/نحوه محاسبه</h2>
                    </th>
                    <th scope="col" className="text-right px-3 py-6 w-1/6">
                      <h2 className="text-caspian-gray">زمان/دوره پایش</h2>
                    </th>
                    <th
                      scope="col"
                      className="text-right px-3 py-6 w-[12%] whitespace-nowrap"
                    >
                      <h2 className="text-caspian-gray">منبع ذخیره اطلاعات</h2>
                    </th>
                    <th scope="col" className="text-right px-3 py-6">
                      <h2 className="text-caspian-gray">آدرس ذخیره</h2>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((d, i) => (
                    <ProcessIndicatorsTableSingle
                      key={i}
                      title={d.title}
                      type={
                        d.indicatorType === "efficiency"
                          ? "کارایی"
                          : d.indicatorType === "effectiveness"
                          ? "اثربخشی"
                          : d.indicatorType
                      }
                      formula={d.formula}
                      monitoringPeriod={d.monitoringPeriod}
                      dataStorageSources={
                        d.dataStorageSources === "manually"
                          ? "دستی"
                          : d.dataStorageSources === "automatically"
                          ? "سیستمی"
                          : d.dataStorageSources
                      }
                      addressDataStorage={d.dataStorageAdress}
                      className={i % 2 !== 0 && "bg-[#FBFCFD]"}
                    />
                  ))}
                  {/* <ProcessIndicatorsTableSingle
                  title="نسبت قیمت فروش هرمتر مکعب  اب به برنامه مصوب97"
                  type="کارایی"
                  formula="قیمت فروش هرمترمکعب آب به /قیمت فروش هرمترمکعب طبق برنامه مصوب"
                  monitoringPeriod="6 ماهه"
                  dataStorageSources="دستی"
                  addressDataStorage="آدرس"
                  className={"bg-[#FBFCFD]"}
                />
                <ProcessIndicatorsTableSingle
                  title="نسبت تعداد بدهی های شامل اشکال در عدم وصول مبلغ پرداخت شده قبض توسط مشترکین به کلیه بدهی "
                  type="اثربخشی"
                  formula="نسبت وصولی معوقه/کل حسابهی دریافتنی راکد
                  نسبت وصولی معوقه / کل حسابهای دریافتنی"
                  monitoringPeriod="5 ماهه"
                  dataStorageSources="سیستمی"
                  addressDataStorage="آدرس"
                  className="bg-[#FBFCFD]"
                />
                <ProcessIndicatorsTableSingle
                  title="میزان تعویض کنتورهای خراب"
                  type="کارایی"
                  formula="تعداد کنتورهای خراب تعویض شده"
                  monitoringPeriod="6 ماهه"
                  dataStorageSources="سیستمی"
                  addressDataStorage="آدرس"
                /> */}
                </tbody>
              </table>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProcessIndicatorsTable;
