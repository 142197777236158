import ListItemSingle from "./ListItemSingle";

const ProfileNavbar = ({ buttonActive }) => {
  return (
    <nav className="w-full bg-caspian-surface rtl">
      <ul className="flex items-center gap-0.5">
        <ListItemSingle
          title="اطلاعات فردی"
          active="personal-info"
          link="/profile/personal-info"
          buttonActive={buttonActive}
        />
        <ListItemSingle
          title="اطلاعات شغلی"
          active="job-info"
          link="/profile/job-info"
          buttonActive={buttonActive}
        />
        <ListItemSingle
          title="تغییر کلمه عبور"
          active="forget-password"
          link="/profile/forget-password"
          buttonActive={buttonActive}
        />
      </ul>
    </nav>
  );
};

export default ProfileNavbar;
