import React, { useContext, useEffect, useState } from 'react'
import { PiPlus } from 'react-icons/pi'
import SingleListUploadFileMaterial from './SingleListUploadFileMaterial'
import ModalUploadFileMaterial from './ModalUploadFileMaterial'
import { AuthContext } from '../../../../../../../context/auth/AuthContext'
import API from '../../../../../../../api/API'

const ListUploadMaterial = ({ reportId, setListDocMaterial, listDocMaterial, checked, setRefreshList, refreshList }) => {
    const { token } = useContext(AuthContext);
    const [showModalUploadFile, setShowModalUploadFile] = useState(false)


    useEffect(() => {
        setRefreshList(1)
    }, [setRefreshList])

    useEffect(() => {
        if (!token || !reportId || refreshList !== 1) return

        const handleDataMaterial = async () => {
            try {
                const res = await API.get(`/api/v1/actionplan/reports/materialreports/${reportId}`)
                console.log(res?.data)
                setListDocMaterial(res?.data)
                setRefreshList(0)

            } catch (error) {
                console.log(error)
            }
        }
        handleDataMaterial()
    }, [token, reportId, setListDocMaterial, refreshList, setRefreshList])



    return (

        <div className='w-full flex flex-col items-start gap-7 px-5 '>

            <div className='flex items-center gap-2'
                onClick={() => checked && setShowModalUploadFile(true)}>

                <span className={`text-xs 2xl:text-sm ${checked ? "cursor-pointer text-caspian-secondary-600" : " text-caspian-secondary-600/30"} `}>افزدون قلم مصرفی</span>
                <PiPlus className={`text-base ${checked ? "cursor-pointer text-caspian-secondary-600" : "text-caspian-secondary-600/30"} `} />
            </div>


            <div className='h-[38vh] px-3 w-full flex flex-col items-center gap-10 overflow-auto'>

                <SingleListUploadFileMaterial listDocMaterial={listDocMaterial} reportId={reportId} setListDocMaterial={setListDocMaterial}
                    setRefreshList={setRefreshList} />
            </div>

            {showModalUploadFile &&
                <ModalUploadFileMaterial reportId={reportId} setShowModalUploadFile={setShowModalUploadFile}
                    listDocMaterial={listDocMaterial} setListDocMaterial={setListDocMaterial} setRefreshList={setRefreshList} />
            }
        </div>
    )
}

export default ListUploadMaterial