import React, { useContext, useEffect, useState } from "react";
import AddNote from "./AddNote";
import Draggable from "react-draggable";
import { PiX } from "react-icons/pi";
import { AuthContext } from "../../../context/auth/AuthContext";
import API from "../../../api/API";
import AddNoteNew from "./AddNoteNew";
import ColorPickerNew from "./ColorPickerNew";

const MainAddNote = ({ setShowAddNote, refresh, setRefresh }) => {
  const [color, setColor] = useState("#FFF7CB");
  const { token } = useContext(AuthContext);
  const [fullData, setFullData] = useState([-1]);
  const [loading, setLoading] = useState(false);
  // const [refresh, setRefresh] = useState(0);
  const [colorPicker, setColorPicker] = useState(false);
  useEffect(() => {
    if (token) {
      setLoading(true);
      API.get("/api/v1/profile/notebook", {
        headers: { Authorization: token },
      })
        .then((d) => {
          setFullData(d.data);
          setRefresh(0);
        })
        .catch((err) => {
          console.log("error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token, refresh, setRefresh]);
  return (
    <Draggable handle="strong">
      <div
        className={`rounded-sm shadow-[0_0_5px_#00000050] w-[340px] fixed top-14 left-[16.3rem] z-50 bg-caspian-surface`}
      >
        <div className={`w-full h-full bg-[${color}] bg-opacity-50`}>
          <strong
            className={`flex items-center justify-between cursor-move p-2`}
          >
            <span className="text-xs">ایجاد یادداشت</span>
            <div className="flex items-center gap-2">
              <div className="z-50 relative">
                <div
                  onClick={() => setColorPicker(!colorPicker)}
                  className={`w-5 h-5 rounded-full cursor-pointer`}
                >
                  <img
                    src="/assets/images/notebook/color-management.svg"
                    alt="color"
                  />
                </div>

                {colorPicker && (
                  <ColorPickerNew
                    setColor={setColor}
                    setColorPicker={setColorPicker}
                  />
                )}
              </div>
              <PiX
                onClick={() => setShowAddNote(false)}
                className="text-xl cursor-pointer"
              />
            </div>
          </strong>
          <AddNoteNew
            color={color}
            setColor={setColor}
            fullData={fullData}
            loading={loading}
            setShowAddNote={setShowAddNote}
            setRefresh={setRefresh}
          />
          {/* <AddNote
            color={color}
            setColor={setColor}
            fullData={fullData}
            loading={loading}
            setShowAddNote={setShowAddNote}
            setRefresh={setRefresh}
          /> */}
        </div>
      </div>
    </Draggable>
  );
};

export default MainAddNote;
