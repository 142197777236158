import React from "react";

const ButtonTab = ({ title, onClick, activeTab, active }) => {
  return (
    <button
      // onClick={onClick}
      className={`${
        activeTab === active
          ? "bg-caspian-surface outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 rounded-md"
          : ""
      } w-fit text-xs p-2 cursor-default`}
    >
      {title}
    </button>
  );
};

export default ButtonTab;
