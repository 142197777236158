import React from "react";
import SingleNumberBox from "./SingleNumberBox";

const MainNumberBox = ({
  numberList,
  setNumberList,
  numberSelected,
  setNumberSelected,
}) => {
  const numberHandler = (input) => {
    setNumberSelected(input);
    console.log(input);
  };
  return (
    <div className="w-full rounded-md flex items-center justify-center">
      <div className="flex items-center gap-0">
        {numberList?.map((n, i) => (
          <SingleNumberBox
            key={i}
            numberTitle={n.clauseNumber}
            onClick={() => numberHandler(n.clauseNumber)}
            isSelected={numberSelected === n.clauseNumber ? true : false}
          />
        ))}
      </div>
    </div>
  );
};

export default MainNumberBox;
