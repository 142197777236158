import React, { useContext, useEffect, useState } from "react";
import {
  PiCheckCircleFill,
  PiPlus,
  PiTrash,
  PiXCircleFill,
} from "react-icons/pi";
import StackholderForm from "./StackholderForm";
import { AuthContext } from "../../../../../../../../../../context/auth/AuthContext";
import API from "../../../../../../../../../../api/API";

const TwoTabStepThree = ({
  setTabStep,
  stackholderList,
  actionplanIdRelated,
  setRefresh,
  setIsDisable,
}) => {
  const { token } = useContext(AuthContext);
  const [showStackholderForm, setShowStackholderForm] = useState(false);

  const [showIsDelete, setShowIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const removeHandler = async (id) => {
    try {
      await API.delete(`/api/v1/actionplan/stockholders_plan/${id}/`, {
        headers: { Authorization: token },
      });
      setRefresh(1);
    } catch (err) {
      console.log(err);
    }
  };

  const [isDisableTwo, setIsDisableTwo] = useState(true);
  useEffect(() => {
    if (stackholderList?.length > 0) {
      setIsDisableTwo(false);
    } else {
      setIsDisableTwo(true);
    }
  }, [stackholderList, setIsDisableTwo]);

  useEffect(() => {
    setIsDisable(true);
  }, [setIsDisable]);
  return (
    <div className="flex flex-col justify-between h-full  min-h-[260px] max-h-[240px]">
      <div className="flex flex-col gap-3 h-full">
        {/* لیست انتخاب مرجع */}
        <div className="flex items-center gap-4 w-full">
          <button
            onClick={() => setShowStackholderForm(true)}
            className="flex items-center justify-between w-full border-y border-y-caspian-secondary-500 text-caspian-secondary-500 py-2 cursor-pointer hover:bg-caspian-secondary-50/20"
          >
            <span className="text-xs">افزودن ذینفع تاثیرگذار</span>
            <PiPlus className="text-sm" />
          </button>
          {showStackholderForm && (
            <StackholderForm
              setShowStackholderForm={setShowStackholderForm}
              actionplanIdRelated={actionplanIdRelated}
              setRefresh={setRefresh}
            />
          )}
        </div>
        <div className="max-h-[180px] overflow-y-scroll">
          <div className="flex flex-col gap-2">
            {stackholderList?.length < 1 ? (
              <h2 className="text-caspian-gray1 text-xs">
                ذینفع ثبت نشده است!
              </h2>
            ) : (
              stackholderList?.map((d, i) => (
                <div
                  key={i}
                  className="bg-caspian-surface p-1.5 rounded-md border border-caspian-gray2 flex items-center justify-between"
                >
                  <div className="flex flex-col gap-1 w-[90%]">
                    <span className="text-xs text-caspian-gray1 line-clamp-1">
                      {d?.description}
                    </span>
                  </div>
                  {
                    <div className="relative flex items-center justify-center w-[10%]">
                      <button
                        onClick={async () => {
                          await setDeleteId(d?.id);
                          await setShowIsDelete(true);
                        }}
                        className="text-caspian-error2/30 hover:text-caspian-error2 duration-300 text-xl"
                      >
                        <PiTrash />
                      </button>
                      <div
                        className={
                          d?.id === deleteId && showIsDelete
                            ? "absolute left-0 right-0 top-0 bottom-0 flex items-center gap-0.5 w-fit bg-caspian-surface shadow-[0_0_5px_#00000050] p-0.5 rounded-md duration-500"
                            : "hidden duration-500"
                        }
                      >
                        <PiCheckCircleFill
                          onClick={() => {
                            removeHandler(d?.id);
                            setShowIsDelete(false);
                          }}
                          className="text-caspian-primary-600 text-lg cursor-pointer"
                        />
                        <PiXCircleFill
                          onClick={() => {
                            setShowIsDelete(false);
                            setDeleteId(null);
                          }}
                          className="text-caspian-gray text-lg cursor-pointer"
                        />
                      </div>
                    </div>
                  }
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      <div className="flex items-center gap-2">
        <button
          onClick={async () => {
            await setTabStep("StepOne");
          }}
          className="min-w-[6rem] outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs p-1.5 rounded-md"
        >
          بازگشت به گام قبل
        </button>
        <button
          onClick={async () => {
            await setTabStep("StepThree");
          }}
          disabled={isDisableTwo ? true : false}
          className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
        >
          ثبت و رفتن به گام بعد
        </button>
      </div>
    </div>
  );
};

export default TwoTabStepThree;
