import React from 'react'
import ButtonChart from '../ButtonChart'

const Chart6 = () => {
    return (
        <div className="flex flex-col items-center mx-10 2xl:mx-auto w-full min-w-[1100px] max-w-[1100px] ">

            <div className="flex flex-col w-full items-center h-full">
                {/* 1 */}
                <ButtonChart
                    title="معاون برنامه ریزی و منابع انسانی (1)"
                    bgColor="#DDEAF4"
                    textColor="#4B6B90"
                    borderColor="#4B6B90"
                />
                {/* 1 */}
                <div className="h-5 border-r-2 border-r-caspian-gray"></div>
            </div>

            <div className="h-5 border-r-2 border-r-caspian-gray"></div>

            <div className="flex flex-col w-full items-center">
                {/* 2 */}
                <div className="flex justify-center w-full relative h-0 gap-10 items-center">

                    <div className="flex flex-col gap-2 h-full items-center">
                        <div className="flex items-center justify-center">
                            <div className="flex items-center justify-center">
                                <div className="w-[250px]"></div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col h-full items-center">
                        <div className="flex items-center">
                            <ButtonChart
                                title="متصدی امور دفتري و کاربر رایانه(1)"
                                bgColor="#C7EBEA"
                                textColor="#225C58"
                                borderColor="#225C58">
                            </ButtonChart>
                            <div className="w-14 absolute right-[50%] h-2 border-b-2 border-b-caspian-gray"></div>
                        </div>
                    </div>
                </div>
                <div className="h-[100px] border-r-2 border-r-caspian-gray"></div>
                {/* 2 */}

                <div className="flex w-full flex-col justify-center relative gap-5 items-center">
                    <div className="w-[880px] h-2 border-t-2 border-t-caspian-gray"></div>
                    {/* 3 */}
                    <div className="flex w-full items-start justify-center gap-10">
                        <div className="flex flex-col gap-2 h-full items-center">
                            <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
                            <div className="flex items-center relative h-full">
                                <div className="flex flex-col items-center gap-2 h-full">
                                    <div className="h-[85%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>
                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="مدیر دفتر برنامه ریزی و بودجه (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس مسئول آمار و برنامه ریزی(1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="h-2 absolute top-[31.8%] border-l-2 border-l-caspian-gray"></div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس آمار و برنامه ریزی(1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="كارشناس مسئول بودجه و تامین اعتبار (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="h-2 absolute top-[66%] border-l-2 border-l-caspian-gray"></div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="كارشناس بودجه و تامین اعتبار(1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="كارشناس مسئول کنترل پروژه (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-2 h-full items-center">
                            <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
                            <div className="flex items-center relative h-full">
                                <div className="flex flex-col items-center gap-2 h-full">
                                    <div className="h-[82%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>
                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="مدیر دفتر تشکیلات آموزش و منابع انسانی (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس مسئول تشکیلات و منابع انسانی (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="h-2 absolute top-[65%] border-l-2 border-l-caspian-gray"></div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="كارشناس منابع انسانی (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="كارشناس طبقه بندی و ارزشیابی مشاغل(1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="كارشناس مسئول آموزش (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-2 h-full items-center">
                            <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
                            <div className="flex items-center relative h-full">
                                <div className="flex flex-col items-center gap-2 h-full">
                                    <div className="h-[78%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>
                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="مدیر دفتر توسعه مدیریت و تحقیقات (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="كارشناس مسئول نظام پیشنهادات (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="كارشناس تحقیقات(1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="كارشناس توسعه مدیریت(1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-2 h-full items-center">
                            <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
                            <div className="flex items-center relative h-full">
                                <div className="flex flex-col items-center gap-2 h-full">
                                    <div className="h-[68%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>
                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="مدیر امور کارکنان و رفاه (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="رئیس قسمت کارکنان (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="h-2 absolute top-[31.8%] border-l-2 border-l-caspian-gray"></div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس مسئول اداري(1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                    </div>

                                    <div className="h-2 absolute top-[48.8%] border-l-2 border-l-caspian-gray"></div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس اداري(3)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس مسئول رفاه و درمان (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="h-2 absolute top-[83%] border-l-2 border-l-caspian-gray"></div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس رفاه و درمان (2)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-2 h-full items-center">
                            <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
                            <div className="flex items-center relative h-full">
                                <div className="h-[78%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>

                                <div className="flex flex-col items-center gap-2 h-full">
                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="رئیس گروه فناوری اطلاعات و ارتباطات (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>
                                    
                                    <div className="h-2 absolute top-[22.3%] border-l-2 border-l-caspian-gray"></div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس فن آوری اطلاعات و ارتباطات (2)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس سخت افزار(1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس شبکه(1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 3 */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Chart6