import React from 'react'
import SingleListMaterialReport from './SingleListMaterialReport'

const MainMaterialReport = ({ fullData }) => {
    return (
        <div className='flex flex-col items-center w-full gap-5'>

            <div className='w-full'>
                <table className='flex flex-col items-center w-full gap-2 table-fixed '>
                    <thead className='w-full'>
                        <tr className='flex items-center justify-between w-full h-8 pr-5 border-2 rounded-t-xl border-caspian-gray2'>
                            <th className='text-caspian-gray4 text-xs w-[20%]'>اقلام مصرفی</th>
                            <th className='text-caspian-gray4 text-xs w-[20%]'>واحد</th>
                            <th className='text-caspian-gray4 text-xs w-[20%]'>استفاده پیش از این مرحله</th>
                            <th className='text-caspian-gray4 text-xs w-[20%]'>استفاده در این مرحله</th>
                            <th className='text-caspian-gray4 text-xs w-[20%] '>تجمیع استفاده از این قلم</th>
                        </tr>
                    </thead>
                    <tbody className='w-full flex flex-col gap-2 max-h-[200px] overflow-auto'>
                        {fullData?.reportProfileMaterialRelated?.map((item) => (
                            <SingleListMaterialReport key={item?.id} item={item} />
                        ))}
                    </tbody>
                </table>
            </div>

            {/* <div className='flex items-start w-full gap-5 py-5'>
                <span className='whitespace-nowrap text-xs 2xl:text-base text-[#3E615F]'>توضیحات : </span>

                {fullData?.reportProfileMaterialRelated?.map((item) => (
                    <p className='text-xs font-semibold 2xl:text-sm' key={item?.id}>{item?.description}</p>
                ))}

            </div> */}
        </div>
    )
}

export default MainMaterialReport
