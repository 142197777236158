import React from "react";
import SingleStandardBox from "./SingleStandardBox";

const MainStandardBox = ({
  standardList,
  setStandardList,
  standardSelected,
  setStandardSelected,
  indexQuestion,
  setIndexQuestion,
  setAllQuestionList,
}) => {
  return (
    <div className="w-full rounded-md flex items-center justify-center gap-2">
      <div className="flex items-center gap-0">
        {standardList?.map((s, i) => (
          <SingleStandardBox
            key={i}
            msTitle={s?.symbol}
            standardTitle={s?.standardNumber}
            onClick={() => setIndexQuestion(i)}
            // onClick={() => setStandardSelected(s?.standardNumber)}
            isSelected={i === indexQuestion ? true : false}
          />
        ))}
      </div>
      <button
        onClick={() => setAllQuestionList([])}
        className={`rounded-md py-2 px-6 text-[#0D358C] border border-caspian-secondary-600 flex items-center gap-4 text-xs font-bold ${
          standardSelected ? "bg-caspian-surface" : "bg-caspian-blue-200"
        }`}
      >
        <span>همه</span>
      </button>
    </div>
  );
};

export default MainStandardBox;
