import { PiPaperPlaneRight, PiTray } from "react-icons/pi";
import ListItemSingle from "./ListItemSingle";
import { useEffect } from "react";
import { useAppContext } from "../../../context/app-context/AppContext";
import { useLocation } from "react-router-dom";

const ProfileMeetingNavbar = ({ buttonActive, allProfileNumber }) => {
  const { setBradCrump3, setNavbarProfileMeeting } = useAppContext();

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("all-profiles")) {
      setNavbarProfileMeeting("all-profiles");
      setBradCrump3("همه پروفایل ها");
    // } else if (location.pathname.includes("my-profiles")) {
    //   setNavbarProfileMeeting("my-profiles");
    //   setBradCrump3("پروفایل های من");
    } else if (location.pathname.includes("draft-profile")) {
      setNavbarProfileMeeting("draft-profile");
      setBradCrump3("پیش نویس پروفایل");
    }
  }, [location, setNavbarProfileMeeting, setBradCrump3]);

  return (
    <nav className="w-full rtl">
      <ul className="flex items-center gap-2">
        <ListItemSingle
          title="همه پروفایل‌ها"
          active="all-profiles"
          number={allProfileNumber.data.publish}
          icon={<PiTray />}
          imageAddress="/assets/images/meeting/profile-navbar/all-profiles.svg"
          link="/managment-of-meetings/profile-meeting/all-profiles"
          buttonActive={"all-profiles"}
          onClick={() => {
            setBradCrump3("همه پروفایل ها");
            setNavbarProfileMeeting("all-profiles");
          }}
        />
        {/* <ListItemSingle
          title="پروفایل‌های من"
          active="my-profiles"
          number={0}
          icon={<PiPaperPlaneRight />}
          imageAddress="/assets/images/meeting/profile-navbar/my-profiles.svg"
          link="/managment-of-meetings/profile-meeting/my-profiles"
          buttonActive="my-profiles"
          onClick={() => {
            setBradCrump3("پروفایل های من");
            setNavbarProfileMeeting("my-profiles");
          }}
        /> */}
        <ListItemSingle
          title="پیش نویس پروفایل"
          active="draft-profile"
          number={allProfileNumber.data.isDraft}
          icon={<PiPaperPlaneRight />}
          imageAddress="/assets/images/meeting/profile-navbar/draft-profile.svg"
          link="/managment-of-meetings/profile-meeting/draft-profile"
          buttonActive={buttonActive}
          onClick={() => {
            setBradCrump3("پیش نویس پروفایل");
            setNavbarProfileMeeting("draft-profile");
          }}
        />
      </ul>
    </nav>
  );
};

export default ProfileMeetingNavbar;
