import React, { useContext, useEffect, useState } from "react";
import MainAddressAndTime from "./address-time/MainAddressAndTime";
import MainMembers from "./members/MainMembers";
import MainManagmentSystem from "./managment-system/MainManagmentSystem";
import MainProcessAndActivities from "./process-activities/MainProcessAndActivities";
import MainDescription from "./description/MainDescription";
import { PiCaretLeft, PiTrash } from "react-icons/pi";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import Loading from "../../../Loading";
import DeleteSchedulePopup from "./DeleteSchedulePopup";
import { AuthContext } from "../../../context/auth/AuthContext";
import API from "../../../api/API";

const MainAuditProfile = () => {
  const [isAuditer, isChecklist] = useOutletContext();
  // const [isAuditer, setRefreshProfile] = useOutletContext();
  const location = useLocation();
  const { token } = useContext(AuthContext);
  const { id, idProfile } = useParams();
  const navigate = useNavigate();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [profileData, setProfileData] = useState([-1]);

  useEffect(() => {
    if (!token || !idProfile || !id) return;
    const getProfileData = async () => {
      try {
        const res = await API.get(
          `/api/v1/audit/profile/schedule/time/${idProfile}/`,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data);
        if (+id === +res.data.auditProfileRelated) {
          setProfileData(res?.data);
        } else {
          setProfileData([]);
        }
      } catch (error) {
        setProfileData([]);
        console.log(error);
      }
    };
    getProfileData();
  }, [token, idProfile, id]);

  return (
    <div className="w-full p-4 flex flex-col gap-6">
      <div className="relative h-[2px] bg-caspian-secondary-400 w-full">
        <span className="absolute top-1/2 -translate-y-1/2 right-10 bg-caspian-gray3 px-4 text-caspian-secondary-500 text-base">
          برنامه ریزی ممیزی
        </span>
        <button
          onClick={() => navigate(`/audit/${id}`)}
          className="absolute top-1/2 -translate-y-1/2 left-10 flex items-center gap-2 text-caspian-secondary-700 p-2 bg-caspian-gray3"
        >
          <span className="text-sm 2xl:text-base">بازگشت</span>
          <PiCaretLeft className="2xl:text-lg" />
        </button>
      </div>

      {profileData[0] === -1 ? (
        <Loading />
      ) : profileData?.length < 1 ? (
        <div className="flex items-center justify-center py-14 w-full">
          <span className="text-xs 2xl:text-base text-caspian-gray4">
            شناسنامه ممیزی مورد نظر یافت نشد!
          </span>
        </div>
      ) : (
        <div className="w-full flex flex-col gap-2 lg:w-[80%] 2xl:w-[70%]">
          {/* {isAuditer && (
            <button
              onClick={() => setShowDeletePopup(true)}
              className="mr-auto flex items-center gap-2 bg-caspian-error rounded-md py-2 px-4 w-fit text-caspian-surface"
            >
              <span className="text-xs">حذف</span>
              <PiTrash className="" />
            </button>
          )} */}
          {isAuditer && showDeletePopup && (
            <DeleteSchedulePopup
              scheduleTimeId={idProfile}
              setShowDeletePopup={setShowDeletePopup}
              title={profileData?.sectionRelated.title}
              // setRefreshProfile={setRefreshProfile}
            />
          )}
          <div className="bg-caspian-surface rounded-md shadow-[0_0_10px_#00000020] flex flex-col gap-0 w-full">
            <MainAddressAndTime
              idPlaning={idProfile}
              idProfile={id}
              data={profileData}
              title={location?.state}
              isAuditer={isAuditer}
              isChecklist={isChecklist}
              idSection={profileData?.sectionRelated?.id}
              setShowDeletePopup={setShowDeletePopup}
            />
            <MainMembers
              data={profileData?.AuditScheduleRelatedResponsible[0]}
            />
            <MainManagmentSystem data={profileData?.clauseRelated} />
            <MainProcessAndActivities
              data={profileData?.AuditScheduleProcessActivity}
            />
            <MainDescription
              description={
                profileData?.AuditScheduleRelatedResponsible[0]?.describtion
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MainAuditProfile;
