import React, { useState } from "react";
import AddNote from "./AddNote";
import Draggable from "react-draggable";
import { PiX } from "react-icons/pi";

const MainAddNote = ({
  notebookData,
  setShowAddNote,
  folderTitle,
  setRefresh,
}) => {
  const [color, setColor] = useState("#FFF7CB");
  return (
    <Draggable handle="strong">
      <div
        className={`bg-caspian-surface rounded-sm shadow-[0_0_5px_#00000050] w-[360px] fixed bottom-2 left-20 z-50`}
      >
        <div className={`w-full h-full bg-[${color}] bg-opacity-50`}>
          <strong className="flex items-center justify-between cursor-move p-2">
            <span className="text-xs">ایجاد یادداشت</span>
            <PiX
              onClick={() => setShowAddNote(false)}
              className="text-sm cursor-pointer"
            />
          </strong>
          <AddNote
            color={color}
            setColor={setColor}
            notebookData={notebookData}
            setShowAddNote={setShowAddNote}
            folderTitle={folderTitle}
            setRefresh={setRefresh}
          />
        </div>
      </div>
    </Draggable>
  );
};

export default MainAddNote;
