import React, { useContext, useEffect, useState } from "react";
import {
  PiCheckCircleFill,
  PiPlus,
  PiTrash,
  PiXCircleFill,
} from "react-icons/pi";
import OutputForm from "./input-output/OutputForm";
import API from "../../../../../api/API";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import InputFormOld from "./input-output/InputFormOld";
import { toast } from "react-toastify";

const InputOutput = ({
  setActiveTab,
  generalId,
  setShowCreateMeetingForm,
  setRefresh,
  setDataRefresh,
}) => {
  const [showInputForm, setShowInputForm] = useState(false);
  const [showOutputForm, setShowOutputForm] = useState(false);
  const { token } = useContext(AuthContext);

  const [showIsDelete, setShowIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [inputListData, setInputListData] = useState([]);
  const [outputListData, setOutputListData] = useState([]);

  const [refreshInput, setRefreshInput] = useState(0);
  const [refreshOutput, setRefreshOutput] = useState(0);
  useEffect(() => {
    const getInputList = () => {
      API.get("/api/v1/meeting/profile/input/", {
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          setInputListData(
            res?.data?.filter(
              (d) => d?.inputMeetingProfileRelated === generalId
            )
          );
          setRefreshInput(0);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getInputList();
  }, [token, refreshInput, generalId]);

  useEffect(() => {
    const getOutputList = () => {
      API.get("/api/v1/meeting/profile/output/", {
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          setOutputListData(
            res?.data?.filter(
              (d) => d?.outputMeetingProfileRelated === generalId
            )
          );
          setRefreshOutput(0);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getOutputList();
  }, [token, refreshOutput, generalId]);

  // console.log(inputListData);
  // console.log(outputListData);

  const removeHandler = (id) => {
    API.delete(`/api/v1/meeting/profile/input/${id}/`, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        setRefreshInput(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const removeHandlerOutput = (id) => {
    API.delete(`/api/v1/meeting/profile/output/${id}/`, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        setRefreshOutput(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataHandlerDraft = async () => {
    // e.preventDefault();
    await API.post(
      `/api/v1/meeting/profile/detail/${generalId}/status/`,
      {
        status: "io",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await toast.success("پروفایل به صورت پیشنویس ثبت شد.", {
          className: "toast-success",
          bodyClassName: "toast-success",
        });
        await setShowCreateMeetingForm(false);
        await setRefresh(1);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataHandlerNext = async () => {
    // e.preventDefault();
    await API.post(
      `/api/v1/meeting/profile/detail/${generalId}/status/`,
      {
        status: "other",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await setActiveTab("otherInformation");
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataHandlerBack = async () => {
    await API.post(
      `/api/v1/meeting/profile/detail/${generalId}/status/`,
      {
        status: "roles",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await setDataRefresh(1);
        await setActiveTab("roles");
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (inputListData.length < 1 || outputListData.length < 1) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [inputListData, outputListData]);
  return (
    <div className="min-h-[270px] flex flex-col gap-2 justify-between">
      <div className={`${true ? "gap-3" : "gap-0"} flex flex-col`}>
        <div className="flex flex-col gap-2">
          <button
            onClick={() => setShowInputForm(true)}
            className="flex items-center justify-between w-full border-y border-y-caspian-secondary-500 text-caspian-secondary-500 py-2 cursor-pointer hover:bg-caspian-secondary-50/20"
          >
            <span className="text-xs">افزودن ورودی های جلسه</span>
            <PiPlus className="text-sm" />
          </button>
          <div className="max-h-[110px] overflow-y-scroll">
            <div className="flex flex-col gap-2">
              {inputListData?.length < 1 ? (
                <h2 className="text-caspian-gray1 text-xs">
                  ورودی جلسه ثبت نشده است!
                </h2>
              ) : (
                inputListData?.map((d, i) => (
                  <div
                    key={i}
                    className="flex items-center justify-between bg-caspian-surface p-1.5 rounded-md border border-caspian-gray2"
                  >
                    <div className="flex flex-col gap-1 w-[90%]">
                      <p className="text-xs">{d.description}</p>
                      <h2 className="text-xs text-caspian-gray1">{d.title}</h2>
                    </div>

                    {
                      <div className="relative flex items-center justify-center w-[10%]">
                        <button
                          onClick={async () => {
                            await setDeleteId(d?.id);
                            await setShowIsDelete(true);
                          }}
                          className="text-caspian-error2/30 hover:text-caspian-error2 duration-300 text-xl"
                        >
                          <PiTrash />
                        </button>
                        <div
                          className={
                            d?.id === deleteId && showIsDelete
                              ? "absolute left-0 right-0 top-0 bottom-0 flex items-center gap-0.5 w-fit bg-caspian-surface shadow-[0_0_5px_#00000050] p-0.5 rounded-md duration-500"
                              : "hidden duration-500"
                          }
                        >
                          <PiCheckCircleFill
                            onClick={() => {
                              removeHandler(d?.id);
                              setShowIsDelete(false);
                            }}
                            className="text-caspian-primary-600 text-lg cursor-pointer"
                          />
                          <PiXCircleFill
                            onClick={() => {
                              setShowIsDelete(false);
                              setDeleteId(null);
                            }}
                            className="text-caspian-gray text-lg cursor-pointer"
                          />
                        </div>
                      </div>
                    }
                  </div>
                ))
              )}
            </div>
          </div>
          {showInputForm && (
            <InputFormOld
              generalIdNumber={generalId}
              setRefreshInput={setRefreshInput}
              setShowInputForm={setShowInputForm}
            />
          )}
        </div>
        <div className="flex flex-col gap-2">
          <button
            onClick={() => setShowOutputForm(true)}
            className={`${
              true
                ? "border-y border-y-caspian-secondary-500"
                : "border-b border-b-caspian-secondary-500"
            } flex items-center justify-between w-full text-caspian-secondary-500 py-2 cursor-pointer hover:bg-caspian-secondary-50/20`}
          >
            <span className="text-xs">افزودن خروجی های جلسه</span>
            <PiPlus className="text-sm" />
          </button>
          <div className="max-h-[110px] overflow-y-scroll">
            <div className="flex flex-col gap-2">
              {outputListData?.length < 1 ? (
                <h2 className="text-caspian-gray1 text-xs">
                  خروجی جلسه ثبت نشده است!
                </h2>
              ) : (
                outputListData?.map((d, i) => (
                  <div
                    key={i}
                    className="flex items-center justify-between bg-caspian-surface p-1.5 rounded-md border border-caspian-gray2"
                  >
                    <div className="flex flex-col gap-1 w-[90%]">
                      <p className="text-xs">{d.description}</p>
                      <h2 className="text-xs text-caspian-gray1">{d.title}</h2>
                    </div>

                    {
                      <div className="relative flex items-center justify-center w-[10%]">
                        <button
                          onClick={async () => {
                            await setDeleteId(d?.id);
                            await setShowIsDelete(true);
                          }}
                          className="text-caspian-error2/30 hover:text-caspian-error2 duration-300 text-xl"
                        >
                          <PiTrash />
                        </button>
                        <div
                          className={
                            d?.id === deleteId && showIsDelete
                              ? "absolute left-0 right-0 top-0 bottom-0 flex items-center gap-0.5 w-fit bg-caspian-surface shadow-[0_0_5px_#00000050] p-0.5 rounded-md duration-500"
                              : "hidden duration-500"
                          }
                        >
                          <PiCheckCircleFill
                            onClick={() => {
                              removeHandlerOutput(d?.id);
                              setShowIsDelete(false);
                            }}
                            className="text-caspian-primary-600 text-lg cursor-pointer"
                          />
                          <PiXCircleFill
                            onClick={() => {
                              setShowIsDelete(false);
                              setDeleteId(null);
                            }}
                            className="text-caspian-gray text-lg cursor-pointer"
                          />
                        </div>
                      </div>
                    }
                  </div>
                ))
              )}
            </div>
          </div>
          {showOutputForm && (
            <OutputForm
              generalIdNumber={generalId}
              setRefreshOutput={setRefreshOutput}
              setShowOutputForm={setShowOutputForm}
            />
          )}
        </div>
      </div>
      <div className="flex items-center gap-2">
        <button
          onClick={async () => {
            await dataHandlerBack();
          }}
          className="min-w-[6rem] outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs p-1.5 rounded-md"
        >
          مرحله قبل
        </button>
        <button
          disabled={disableButton}
          onClick={async () => {
            await dataHandlerNext();
          }}
          className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
        >
          مرحله بعد
        </button>
        <button
          disabled={disableButton}
          onClick={async () => {
            await dataHandlerDraft();
          }}
          className={`disabled:cursor-not-allowed disabled:text-caspian-gray text-caspian-secondary-500 text-xs p-1.5 rounded-md`}
        >
          ذخیره پیشنویس
        </button>
      </div>
    </div>
  );
};

export default InputOutput;
