import React, { useEffect, useState } from "react";
import RowView from "./RowView";
import Paginate from "./Paginate";
import { useSearchParams } from "react-router-dom";
import { PiCircleNotch } from "react-icons/pi";
import SingleTableListActivites from "./SingleTableListActivites";

const TableListActivities = ({
  listActivities,
  loading,
  setRefreshActivitiesList,
}) => {
  const [filterByTitle, setFilterByTitle] = useState("");
  const [filterByTeamType, setFilterByTeamType] = useState("");
  const [filterByStartDate, setFilterByStartDate] = useState("");
  const [filterByEndDate, setFilterByEndDate] = useState("");
  const [filterByStatus, setFilterByStatus] = useState("");

  const [searchParams] = useSearchParams();
  const search = searchParams.get("search");
  const page = searchParams.get("page");
  const rows = searchParams.get("rows");

  let filterTitle = [];
  let filterTeamType = [];
  let filterStartDate = [];
  let filterEndDate = [];
  let filterStatus = [];

  useEffect(() => {
    if (search) {
      setCurrentPage(1);
    }
  }, [search]);

  let newData = [-1];
  if (listActivities[0] !== -1) {
    newData = search
      ? listActivities?.filter((d) =>
        d?.title?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase())
      )
      : listActivities;
  }

  if (newData[0] !== -1) {
    filterTitle = filterByTitle
      ? newData.filter((d) =>
        d?.title
          .toLocaleLowerCase()
          .includes(filterByTitle.toLocaleLowerCase())
      )
      : newData;

    filterTeamType = filterByTeamType
      ? filterTitle.filter((d) =>
        d?.teamIdentifier?.riskTeamSubjectType
          .toLocaleLowerCase()
          .includes(filterByTeamType.toLocaleLowerCase())
      )
      : filterTitle;

    filterStartDate = filterByStartDate
      ? filterTeamType.filter((d) =>
        d?.startDate
          .toLocaleLowerCase()
          .includes(filterByStartDate.toLocaleLowerCase())
      )
      : filterTeamType;

    filterEndDate = filterByEndDate
      ? filterStartDate.filter((d) =>
        (d?.endDate)
          .toLocaleLowerCase()
          .includes(filterByEndDate.toLocaleLowerCase())
      )
      : filterStartDate;

    filterStatus = filterByStatus
      ? filterEndDate.filter((d) =>
        d?.statusType
          ?.toLocaleLowerCase()
          ?.includes(filterByStatus?.toLocaleLowerCase())
      )
      : filterEndDate;
  }

  const [currentPage, setCurrentPage] = useState(page ? page : 1);
  const [recordsPerPage, setRecordsPerPage] = useState(rows ? rows : 10);
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = filterStatus.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filterStatus.length / recordsPerPage);

  return (
    <div className="flex py-10 px-10 flex-col gap-4">
      {loading ? (
        <div className="flex items-center justify-center py-40">
          <PiCircleNotch className="text-caspian-secondary-600 text-2xl animate-spin" />
        </div>
      ) : (
              <div className="w-full overflow-x-auto">
                <table className="min-w-[992px] w-full text-left text-sm font-light">
                  <thead className="border-b border-b-caspian-gray2 font-medium">
                    <tr className='rounded-tr-xl rounded-tl-xl'>
                      <th
                        scope="col"
                        className='bg-caspian-primary-500 w-[25%] p-2 rounded-tr-xl relative'
                      >
                        <input
                          // onClick={() => setCurrentPage(1)}
                          id="t1"
                          onChange={(e) => {
                            setFilterByTitle(e.target.value);
                          }}
                          type="search"
                          className={`${filterByTitle ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50  cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-16 border border-caspian-gray2`}
                        />

                        <label htmlFor="t1"
                          className={`${filterByTitle ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface peer-focus:right-3 whitespace-nowrap`}>
                          عنوان تیم
                        </label>
                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-primary-500 p-2 w-[17%]"
                      >
                        <select
                          onChange={(e) => setFilterByTeamType(e.target.value)}
                          className="rounded-[4px] outline outline-1 outline-caspian-gray2 p-1 font-light text-sm w-full cursor-pointer bg-caspian-primary-500 text-caspian-surface"
                        >
                          <option value="">همه گروه ها</option>
                          <option value="strategic">گروه اهداف کلان</option>
                          <option value="operational">گروه فرایندها</option>
                        </select>
                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-primary-500 p-2 relative  w-[17%]"
                      >
                        <input
                          onChange={(e) => setFilterByStartDate(e.target.value)}
                          id="t2"
                          type="search"
                          className={`${filterByStartDate ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50  cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-[68px] border border-caspian-gray2`}
                        />

                        <label htmlFor="t2"
                          className={`${filterByStartDate ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface peer-focus:right-3 whitespace-nowrap`}>
                          تاریخ شروع
                        </label>

                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-primary-500 relative p-2  w-[17%]"
                      >
                        <input
                          onChange={(e) => setFilterByEndDate(e.target.value)}
                          type="search"
                          id="t3"
                          className={`${filterByEndDate ? "outline-caspian-surface/50 border" : ""}peer text-caspian-darkyellow-300 border-none outline-none focus:outline-caspian-surface/50  cursor-pointer bg-caspian-primary-500 text-sm w-full font-normal rounded-md p-1.5 pr-[68px] border border-caspian-gray2`}
                        />
                        <label htmlFor="t3"
                          className={`${filterByEndDate ? 'absolute left-full right-3 font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 text-caspian-surface' : ''}absolute cursor-pointer font-normal text-sm top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 peer-focus:left-full text-caspian-surface peer-focus:right-3 whitespace-nowrap`}>
                          تاریخ پایان
                        </label>
                      </th>

                      <th
                        scope="col"
                        className="bg-caspian-primary-500 p-2 rounded-tl-xl  w-[17%]"
                      >
                        <select
                          onChange={(e) => setFilterByStatus(e.target.value)}
                          id="select2"
                          className="rounded-[4px] outline outline-1 outline-caspian-gray2 p-1 font-light text-sm w-full cursor-pointer bg-caspian-primary-500 text-caspian-surface"
                        >
                          <option value="" className="hover:bg-caspian-surface hover:text-caspian-gray4">وضعیت</option>
                          <option value="done" className="hover:bg-caspian-surface hover:text-caspian-gray4">انجام شده</option>
                          <option value="doing" className="hover:bg-caspian-surface hover:text-caspian-gray4">در حال انجام</option>
                          <option value="outdate" className="hover:bg-caspian-surface hover:text-caspian-gray4">منقضی شده</option>
                        </select>
                      </th>
                    </tr>




                    {/* <tr>
                      <th
                        scope="col"
                        className="rounded-tr-xl bg-caspian-surface text-right px-3 py-6 w-[25%]"
                      >
                        <input
                          // onClick={() => setCurrentPage(1)}
                          onChange={(e) => {
                            setFilterByTitle(e.target.value);
                          }}
                          type="search"
                          placeholder="عنوان تیم"
                          className="rounded-md outline outline-1 outline-caspian-gray2 p-1.5 font-light text-sm w-full"
                        />
                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-surface text-right px-3 py-6 w-[17%]"
                      >
                        <select
                          onChange={(e) => setFilterByTeamType(e.target.value)}
                          className="rounded-[4px] outline outline-1 outline-caspian-gray2 p-1 font-light text-sm w-full cursor-pointer"
                        >
                          <option value="">همه گروه ها</option>
                          <option value="strategic">گروه اهداف کلان</option>
                          <option value="operational">گروه فرایندها</option>
                        </select>
                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-surface text-right px-3 py-6 w-[17%]"
                      >
                        <input
                          onChange={(e) => setFilterByStartDate(e.target.value)}
                          type="search"
                          placeholder="تاریخ شروع"
                          className="rounded-md outline outline-1 outline-caspian-gray2 p-1.5 font-light text-sm w-full"
                        />
                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-surface text-right px-3 py-6 w-[17%]"
                      >
                        <input
                          onChange={(e) => setFilterByEndDate(e.target.value)}
                          type="search"
                          placeholder="تاریخ پایان"
                          className="rounded-md outline outline-1 outline-caspian-gray2 p-1.5 font-light text-sm w-full"
                        />
                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-surface text-right px-3 py-6 w-[17%]"
                      >
                        <select
                          onChange={(e) => setFilterByStatus(e.target.value)}
                          className="rounded-[4px] outline outline-1 outline-caspian-gray2 p-1 font-light text-sm w-full cursor-pointer"
                        >
                          <option value="">وضعیت</option>
                          <option value="done">انجام شده</option>
                          <option value="doing">در حال انجام</option>
                          <option value="outdate">منقضی شده</option>
                        </select>
                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-surface rounded-tl-xl text-right px-3 py-6 w-[7%]"
                      ></th>
                    </tr> */}
                  </thead>
                  {records.length > 0 ? (
                    <tbody>
                      {records.map((d, i) => (
                        <SingleTableListActivites
                          key={i}
                          id={d.id}
                          //عنوان تیم
                          title={d?.activityRelated?.title}
                          //نوع تیم
                          teamType={d?.teamIdentifier?.riskTeamSubjectType}
                          //تاریخ شروع
                          startDate={d?.activityRelated?.startDate}
                          //تاریخ پایان
                          endDate={d?.activityRelated?.deadLine}
                          //وضعیت
                          statusType={d?.statusType}
                          setRefreshActivitiesList={setRefreshActivitiesList}
                        />
                      ))}
                    </tbody>
                  ) : (
                    <tbody className="py-24">
                      <tr>
                        <td className="py-24">
                          <div className="flex items-center justify-center w-full py-10">
                            <div className="z-10 absolute left-1/2 -translate-x-1/2 mx-auto">
                              <div className="flex flex-col gap-2 items-center justify-center w-full">
                                <img
                                  src="/assets/images/library/not-found.svg"
                                  alt="فعالیت یافت نشد..."
                                />
                                <h2>فعالیت یافت نشد...</h2>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
      )}
      {records.length > 0 && (
        <div className="flex items-center justify-between py-2">
          <RowView
            setRecordsPerPage={setRecordsPerPage}
            setCurrentPage={setCurrentPage}
          />
          <Paginate
            pageCount={npage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
          <div className="text-sm flex items-center gap-4">
            <span>نمایش {firstIndex + 1}</span>
            <span>تا {lastIndex - (recordsPerPage - records.length)}</span>
            <span>از کل {filterStatus.length}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableListActivities;
