import React, { useContext, useRef, useState } from 'react';
import Modal from '../../../../../../../modal/Modal';
import { PiCircleNotch, PiDotBold, PiDotOutlineFill, PiTrash, PiX } from 'react-icons/pi';
import API from '../../../../../../../../api/API';
import { AuthContext } from '../../../../../../../../context/auth/AuthContext';

const EditAuthorityForm = ({ data, id, setRefresh, setEditAuth }) => {
    const { token } = useContext(AuthContext);
    let idAuth = data?.positionRelatedForAuthority?.length > 0 ? data?.positionRelatedForAuthority[0]?.id : -1;
    console.log(idAuth);
    const ref = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    console.log(data?.positionRelatedForAuthority);
    let existAuth = data?.positionRelatedForAuthority?.length > 0 ? data?.positionRelatedForAuthority[0]?.describtion
        : [];
    existAuth = existAuth?.length > 0 ? existAuth?.split(',') : []
    // console.log(data?.positionRelatedForResponsible);
    console.log("existAuth", existAuth);
    const [res, setRes] = useState(existAuth);

    const resHandle = (e) => {
        console.log(e.target.value);
        if (e.key === 'Enter') {
            if (e.target.value === '') return
            setRes([...res, e.target.value]);
            ref.current.value = '';
        }
    }
    const submitHandler = async (id) => {
        let newRes = res.join(',');
        let formData = {
            describtion: newRes,
            positionRelatedAuthority: id
        }
        console.log("formData", formData);
        setIsLoading(true);
        if (newRes?.length < 1 && idAuth) {
            removeAll();
            return
        }
        let response;
        try {
            if (idAuth !== -1) {
                response = await API.patch(`/api/v1/organization/role/authorityposition/${idAuth}/`, formData, { headers: { Authorization: token } })
            }
            else {
                response = await API.post('/api/v1/organization/role/authorityposition/', formData, { headers: { Authorization: token } });
            }
            if (response?.data) {
                console.log(response?.data);
                setRefresh(true);
                setEditAuth(false);
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }
    const removeHandle = (index) => {
        const item = res?.filter((s, i) => i !== index);
        setRes(item);
    }
    const removeAll = async () => {
        try {
            const response = await API.delete(`/api/v1/organization/role/authorityposition/${idAuth}/`, { headers: { Authorization: token } })
            setIsLoading(false);
            setEditAuth(false)
            setRefresh(true);
            idAuth = -1;
            if (!response?.data) {
                console.log('hi');
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }
    return (
        <Modal>
            <div className='bg-caspian-surface w-[50%] max-w-[600px] rounded-md'>
                <div className='flex justify-between items-center p-1.5'>
                    <span className='font-semibold'>ویرایش فرم حدود و اختیارات</span>
                    <PiX disabled={isLoading} className='cursor-pointer' onClick={() => setEditAuth(false)} />
                </div>
                <div className='border-t border-caspian-gray1 px-1.5 flex flex-col gap-4 py-5'>
                    <div className='flex flex-col gap-2'>
                        <span className='text-caspian-gray4 text-xs'>حدود و اختیارات :</span>
                        <textarea
                            rows={3}
                            cols={3}
                            ref={ref}
                            onKeyUp={(e) => resHandle(e)}
                            placeholder='حدود و اختیار...'
                            className='border text-xs placeholder:text-xs placeholder:text-caspian-gray4 border-caspian-gray1 rounded-md p-1.5 resize-none' />
                    </div>
                    <div className='h-[40vh] overflow-y-auto'>
                        {/* responsible */}
                        <div className='p-1.5 pt-0 pr-0'>
                            {
                                res?.length > 0 ? res?.map((item, index) =>
                                    <div className='flex justify-between items-start my-2 first:mt-0' key={index}>
                                        <p className='flex gap-1.5 items-start'>
                                            <p className='w-[20px]'>
                                            <PiDotOutlineFill className='text-xl' /> 
                                            </p>
                                            <span className='w-[90%]'>{item}</span>
                                        </p>
                                        <p className='w-[50px]'><PiTrash className='cursor-pointer mr-auto' onClick={() => removeHandle(index)} /></p>
                                    </div>) : <p className='text-center pt-10 text-xs text-caspian-gray4'>موردی ثبت نشده</p>
                            }
                        </div>
                    </div>
                    <button
                        onClick={() => submitHandler(id)}
                        className='flex justify-center gap-2 p-1.5 rounded-md text-caspian-surface bg-[#3C77C2] '>
                        <span>ثبت</span>
                        {isLoading && <span>
                            <PiCircleNotch className='animate-spin' />
                        </span>}
                    </button>
                </div>
            </div>

        </Modal>
    );
}

export default EditAuthorityForm;
