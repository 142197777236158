import React from "react";
import PresentList from "./PresentList";

const MainPresent = ({
  fullData,
  meetingId,
  setRefreshFullData,
  setApprovals,
  setPresent,
}) => {
  return (
    <>
      <PresentList
        setApprovals={setApprovals}
        setPresent={setPresent}
        fullData={fullData}
        meetingId={meetingId}
        setRefreshFullData={setRefreshFullData}
      />
    </>
  );
};

export default MainPresent;
