import React from "react";
import ChartNavbar from "../ChartNavbar";
import ChartView from "./ChartView";
import MainTreeChartStatic from "./tree-chart-static/MainTreeChartStatic";

const MainTreeChart = () => {
  return (
    <div>
      <ChartNavbar buttonActive="tree-chart" />
      {/* <ChartView /> */}
      <MainTreeChartStatic />
    </div>
  );
};

export default MainTreeChart;
