import React, { useEffect, useState } from "react";
import ButtonTab from "./ButtonTab";
import TitleAndDescription from "./forms/TitleAndDescription";
import TimeAndPlace from "./forms/TimeAndPlace";
import MeetingOrder from "./forms/MeetingOrder";
import PresentationFile from "./forms/PresentationFile";
import AddGuest from "./forms/AddGuest";
import Reminder from "./forms/Reminder";
// import Invitation from "./forms/Invitation";

const PlanningMeetingForm = ({
  thisId,
  setThisId,
  meetingProfile,
  setShowPlanningMeetingForm,
  fullData,
  setRefresh,
  setMeetingListRefresh,
}) => {
  const [activeTab, setActiveTab] = useState("titleAndDescription");
  const [meetingId, setMeetingId] = useState(null);

  useEffect(() => {
    if (meetingId !== null) {
      setThisId(meetingId);
    }
  }, [meetingId, setThisId]);

  console.log(fullData);
  return (
    <div className="flex flex-col justify-between h-full">
      {/* تب های فرم برنامه ریزی برگزار جلسه */}
      <div className="flex items-center justify-between gap-4 bg-caspian-gray2/40 py-1.5 px-2 rounded-md">
        <ButtonTab
          activeTab={activeTab}
          active="titleAndDescription"
          onClick={() => setActiveTab("titleAndDescription")}
          title="عنوان و توضیحات"
        />
        <ButtonTab
          activeTab={activeTab}
          active="addGuest"
          onClick={() => setActiveTab("addGuest")}
          title="افزودن مهمانان"
        />
        <ButtonTab
          activeTab={activeTab}
          active="timeAndPlace"
          onClick={() => setActiveTab("timeAndPlace")}
          title="زمان و محل جلسه"
        />
        <ButtonTab
          activeTab={activeTab}
          active="meetingOrder"
          onClick={() => setActiveTab("meetingOrder")}
          title="دستور جلسه"
        />
        <ButtonTab
          activeTab={activeTab}
          active="presentationFile"
          onClick={() => setActiveTab("presentationFile")}
          title="فایل ارائه"
        />
        <ButtonTab
          activeTab={activeTab}
          active="reminder"
          onClick={() => setActiveTab("reminder")}
          title="یادآوری"
        />
        {/* <ButtonTab
          activeTab={activeTab}
          active="invitation"
          onClick={() => setActiveTab("invitation")}
          title="دعوت نامه"
        /> */}
      </div>
      <div className="min-h-[270px] h-full flex flex-col justify-between">
        <div className="py-3">
          {/* عنوان و توضیحات */}
          {activeTab === "titleAndDescription" && (
            <TitleAndDescription
              fullData={fullData}
              meetingProfile={meetingProfile}
              setActiveTab={setActiveTab}
              setShowPlanningMeetingForm={setShowPlanningMeetingForm}
              setMeetingId={setMeetingId}
              thisId={thisId}
              setRefresh={setRefresh}
              setMeetingListRefresh={setMeetingListRefresh}
            />
          )}
          {/* افزودن مهمان */}
          {activeTab === "addGuest" && (
            <AddGuest
              fullData={fullData?.meetingMemberRelated?.filter(
                (d) => d?.memberType === "guest" || d?.memberType === "company"
              )}
              setActiveTab={setActiveTab}
              setShowPlanningMeetingForm={setShowPlanningMeetingForm}
              thisId={thisId}
              setRefresh={setRefresh}
              setMeetingListRefresh={setMeetingListRefresh}
            />
          )}
          {/* زمان و محل جلسه */}
          {activeTab === "timeAndPlace" && (
            <TimeAndPlace
              fullData={fullData?.meetingDatetimeRelated}
              setActiveTab={setActiveTab}
              setShowPlanningMeetingForm={setShowPlanningMeetingForm}
              thisId={thisId}
              setRefresh={setRefresh}
              setMeetingListRefresh={setMeetingListRefresh}
            />
          )}
          {/* دستور جلسه */}
          {activeTab === "meetingOrder" && (
            <MeetingOrder
              setActiveTab={setActiveTab}
              setShowPlanningMeetingForm={setShowPlanningMeetingForm}
              thisId={thisId}
              setRefresh={setRefresh}
              fullData={fullData?.meetingProfileRelated}
              meetingProfileRelated={fullData?.meetingProfileRelated}
              members={fullData?.meetingMemberRelated}
              meetingAgendaRelated={fullData?.meetingAgendaRelated}
              setMeetingListRefresh={setMeetingListRefresh}
            />
          )}
          {/* فایل ارائه */}
          {activeTab === "presentationFile" && (
            <PresentationFile
              fullData={fullData?.MeetingPresentationRelated}
              documentsFile={fullData?.MeetingPresentationRelated.filter(
                (d) => d.documentsRelated.length > 0
              )}
              thisId={thisId}
              setActiveTab={setActiveTab}
              setRefresh={setRefresh}
              setShowPlanningMeetingForm={setShowPlanningMeetingForm}
              setMeetingListRefresh={setMeetingListRefresh}
            />
          )}
          {/* یادآوری */}
          {activeTab === "reminder" && (
            <Reminder
              thisTitle={fullData?.title}
              fullData={fullData?.meetingReminderRelated}
              setRefresh={setRefresh}
              thisId={thisId}
              setActiveTab={setActiveTab}
              setShowPlanningMeetingForm={setShowPlanningMeetingForm}
              setMeetingListRefresh={setMeetingListRefresh}
            />
          )}
          {/* دعوت نامه */}
          {/* {activeTab === "invitation" && (
            <Invitation
              thisId={thisId}
              setActiveTab={setActiveTab}
              setShowPlanningMeetingForm={setShowPlanningMeetingForm}
            />
          )} */}
        </div>
      </div>
    </div>
  );
};

export default PlanningMeetingForm;
