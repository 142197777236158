import React from "react";
import SystemNotifactionBox from "./SystemNotifactionBox";

const MainSystemNotifaction = ({ setOpen, data }) => {
  // console.log(data);
  return (
    <div className="flex flex-col gap-0 max-h-[300px] overflow-y-scroll">
      {data?.length > 0 ? (
        data
          .sort((a, b) => (b.created_at > a.created_at ? 1 : -1))
          .map((m, i) => (
            <SystemNotifactionBox
              key={i}
              setOpen={setOpen}
              id={m.id}
              title={m.title}
              description={m.description}
              sender={m.sender}
              created_at={m.created_at}
              link={m.link}
              personalStatus={m.personalStatus}
              reciver={m.reciver}
              deadLine={m.deadLine}
            />
          ))
      ) : (
        <div className="text-center p-2">
          <h2 className="text-xs text-caspian-gray py-3">
            اعلانی وجود ندارد...
          </h2>
        </div>
      )}
    </div>
  );
};

export default MainSystemNotifaction;
