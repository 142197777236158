import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const TabChartStatic = ({ setActiveChart, showChartSHM }) => {
  const [showLine, setShowLine] = useState(null)
  const [serachParams, setSearchParams] = useSearchParams();

  function handleChart(key, value) {
    setSearchParams((prevParams) => {
      if (value === null || value === "") {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    });
  }

  const handleButton = (name) => {
    setActiveChart(name);
    !showChartSHM && handleChart("c", name);
  };

  let buttons = [
    {
      chart: 2,
      text: "مدیرعامل",
    },
    {
      chart: 3,
      text: "معاون مهندسی و توسعه (1)",
    },
    {
      chart: 4,
      text: "معاون بهره برداری (1)",
    },
    {
      chart: 5,
      text: "معاون خدمات مشترکین و درآمد (1)",
    },
    {
      chart: 6,
      text: "معاون برنامه ریزی و منابع انسانی (1)",
    },
    {
      chart: 7,
      text: "معاون مالی و پشتیبانی (1)",
    },
    {
      chart: 8,
      text: "مدیریت ناحیه یک (1)",
    },
    {
      chart: 9,
      text: "مدیریت ناحیه دو منطقه یک",
    },
    {
      chart: 10,
      text: "مدیریت ناحیه سه منطقه یک",
    },

  ]
  const totalButtons = buttons.length;
  const radius = 200;


  return (
    <div className=" flex items-center justify-center h-[80vh] bg-caspian-surface shadow-[0_5px_5px_#00000050] rounded-md ">

      <div className="flex items-center justify-center relative">
        <button
          className="absolute w-40 h-40 z-50 flex items-center justify-center text-xs 2xl:text-lg border-4 border-caspian-secondary-900 bg-caspian-secondary-700 text-caspian-surface rounded-full"
          onClick={() => handleButton("chart1")}
          onMouseMove={() => setShowLine("chart1")}
          onMouseLeave={() => setShowLine(null)}>
          مدیرعامل
        </button>

        <div className="absolute w-full h-full flex items-center justify-center">

          {buttons?.map((item, index) => {
            const angle = (index * 360) / totalButtons;
            const x = radius * Math.cos((angle * Math.PI) / 180);
            const y = radius * Math.sin((angle * Math.PI) / 180);

            return (
              <button
                key={index}
                className={`absolute w-28 h-28 flex top-[${item?.chart}] items-center justify-center text-xs 2xl:text-sm px-2 rounded-full border-4 border-caspian-secondary-500 bg-caspian-secondary-300 text-caspian-surface`}
                style={{ transform: `translate(${x}px, ${y}px)` }}


                onClick={() => handleButton(`chart${item?.chart}`)}
                onMouseMove={() => setShowLine(`chart${item?.chart}`)}
                onMouseLeave={() => setShowLine(null)}>
                {item?.text}
                {showLine === `chart${item.chart}` && (
                  <div
                    className="absolute -z-50 border-b-caspian-gray4 border-b-2"
                    style={{
                      width: "100px", 
                      left: "-50%",
                      top: "50%",
                      transform: `translate(-50%, -50%) rotate(${angle}deg)`,
                    }}
                  ></div>
                )}
              </button>
            )
          })}
        </div>
      </div>
    </div>
  );
};

export default TabChartStatic;

{/* <button
  className="absolute w-28 h-28 flex items-center justify-center text-xs 2xl:text-sm px-2 rounded-full border-4 border-caspian-secondary-500 bg-caspian-secondary-300 text-caspian-surface"
  style={{ transform: "rotate(320deg) translate(200px) rotate(-320deg)" }}
  onClick={() => handleButton("chart3")}
  onMouseMove={() => setShowLine("chart3")}
  onMouseLeave={() => setShowLine(null)}>
  معاون مهندسی و توسعه (1)
  {showLine === "chart3" &&
    <div className="w-full h-full absolute -z-50 border-b-caspian-gray4 -translate-x-[110%] translate-y-[40%] -rotate-[50deg] border-b-2"></div>
  }
</button>

<button
  className="absolute w-28 h-28 flex items-center justify-center text-xs 2xl:text-sm px-2 rounded-full border-4 border-caspian-secondary-500 bg-caspian-secondary-300 text-caspian-surface"
  style={{ transform: "rotate(0deg) translate(200px) rotate(-0deg)" }}
  onClick={() => handleButton("chart4")}
  onMouseMove={() => setShowLine("chart4")}
  onMouseLeave={() => setShowLine(null)}>
  معاون بهره برداری (1)
  {showLine === "chart4" &&
    <div className="w-full h-full absolute -z-50 border-b-caspian-gray4 -translate-x-[104%] -translate-y-[55%] border-b-2"></div>
  }
</button>

<button
  className="absolute w-28 h-28 flex items-center justify-center text-xs 2xl:text-sm px-2 rounded-full border-4 border-caspian-secondary-500 bg-caspian-secondary-300 text-caspian-surface"
  style={{ transform: "rotate(40deg) translate(200px) rotate(-40deg)" }}
  onClick={() => handleButton("chart5")}
  onMouseMove={() => setShowLine("chart5")}
  onMouseLeave={() => setShowLine(null)}>
  معاون خدمات مشترکین و درآمد (1)
  {showLine === "chart5" &&
    <div className="w-full h-full absolute -z-50 border-b-caspian-gray4 -translate-x-[48%] -translate-y-[100%] rotate-[40deg] border-b-2"></div>
  }
</button>

<button
  className="absolute w-28 h-28 flex items-center justify-center text-xs 2xl:text-sm px-2 rounded-full border-4 border-caspian-secondary-500 bg-caspian-secondary-300 text-caspian-surface"
  style={{ transform: "rotate(80deg) translate(200px) rotate(-80deg)" }}
  onClick={() => handleButton("chart6")}
  onMouseMove={() => setShowLine("chart6")}
  onMouseLeave={() => setShowLine(null)}>
  معاون برنامه ریزی و منابع انسانی (1)
  {showLine === "chart6" &&
    <div className="w-full h-full absolute -z-50 border-b-caspian-gray4 -translate-x-[70%] -translate-y-[90%] -rotate-[100deg] border-b-2"></div>
  }
</button>

<button
  className="absolute w-28 h-28 flex items-center justify-center text-xs 2xl:text-sm px-2 rounded-full border-4 border-caspian-secondary-500 bg-caspian-secondary-300 text-caspian-surface"
  style={{ transform: "rotate(120deg) translate(200px) rotate(-120deg)" }}
  onClick={() => handleButton("chart7")}
  onMouseMove={() => setShowLine("chart7")}
  onMouseLeave={() => setShowLine(null)}>
  معاون مالی و پشتیبانی (1)
  {showLine === "chart7" &&
    <div className="w-full h-full absolute -z-50 border-b-caspian-gray4 translate-x-[100%] -translate-y-[60%] rotate-[120deg] border-b-2"></div>
  }
</button>

<button
  className="absolute w-28 h-28 flex items-center justify-center text-xs 2xl:text-sm px-2 rounded-full border-4 border-caspian-secondary-500 bg-caspian-secondary-300 text-caspian-surface"
  style={{ transform: "rotate(160deg) translate(200px) rotate(-160deg)" }}
  onClick={() => handleButton("chart8")}
  onMouseMove={() => setShowLine("chart8")}
  onMouseLeave={() => setShowLine(null)}>
  مدیریت ناحیه یک (1)
  {showLine === "chart8" &&
    <div className="w-full h-full absolute -z-50 border-b-caspian-gray4 translate-x-[120%] -translate-y-[0%] rotate-[150deg] border-b-2"></div>
  }
</button>

<button
  className="absolute w-28 h-28 flex items-center justify-center text-xs 2xl:text-sm px-2 rounded-full border-4 border-caspian-secondary-500 bg-caspian-secondary-300 text-caspian-surface"
  style={{ transform: "rotate(200deg) translate(200px) rotate(-200deg)" }}
  onClick={() => handleButton("chart9")}
  onMouseMove={() => setShowLine("chart9")}
  onMouseLeave={() => setShowLine(null)}>
  مدیریت ناحیه دو منطقه یک
  {showLine === "chart9" &&
    <div className="w-full h-full absolute -z-50 border-b-caspian-gray4 translate-x-[115%] -translate-y-[15%] rotate-[20deg] border-b-2"></div>
  }
</button>

<button
  className="absolute w-28 h-28 flex items-center justify-center text-xs 2xl:text-sm px-2 rounded-full border-4 border-caspian-secondary-500 bg-caspian-secondary-300 text-caspian-surface"
  style={{ transform: "rotate(240deg) translate(200px) rotate(-240deg)" }}
  onClick={() => handleButton("chart10")}
  onMouseMove={() => setShowLine("chart10")}
  onMouseLeave={() => setShowLine(null)}>
  مدیریت ناحیه سه منطقه یک
  {showLine === "chart10" &&
    <div className="w-full h-full absolute -z-50 border-b-caspian-gray4 translate-x-[100%] translate-y-[60%]  rotate-[60deg] border-b-2"></div>
  }
</button> */}