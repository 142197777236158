import React, { useEffect, useState } from 'react'
import UploadFile from './UploadFileRow'
import SuccessUpload from './SuccessUpload'

const MainUploadFile = ({type,setType,typeList,setTypeList,typeSelected,setTypeSelected}) => {
    const [showModal, setShowModal] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)

    useEffect(() => {
        if (showSuccess === true) {
            setTimeout(() => {
                setShowSuccess(false)
            }, 3000);
            // return clearTimeout(timeoutSucces)
        }
    }, [showSuccess])

    return (
        <>
            <span className='cursor-pointer bg-caspian-secondary-500 text-caspian-surface text-sm rounded-md p-2.5'
                onClick={() => setShowModal(true)}>
                بارگذاری فایل
            </span>

            {showModal &&
                (<UploadFile
                    type={type}
                    setType={setType}
                    typeList={typeList}
                    setTypeList={setTypeList}
                    typeSelected={typeSelected}
                    setTypeSelected={setTypeSelected}
                    setShowModal={setShowModal} setShowSuccess={setShowSuccess} />)
            }
            {showSuccess && <SuccessUpload />}
        </>

    )
}

export default MainUploadFile