import React, { useContext, useEffect, useRef, useState } from 'react';
import Modal from '../../../../../../../modal/Modal';
import { PiCircleNotch, PiDotBold, PiTrash, PiX } from 'react-icons/pi';
import API from '../../../../../../../../api/API';
import { AuthContext } from '../../../../../../../../context/auth/AuthContext';
import ListDutyType from './ListDutyType';

const EditDutyForm = ({ data, id, setRefresh, setEditDuty, publicRes, proRes, specialRes }) => {
    const { token } = useContext(AuthContext);

    const idAuth = data?.positionRelatedForDuty?.length > 0 ? data?.positionRelatedForDuty[0]?.id : -1;
    console.log(idAuth);
    const ref = useRef(null);
    //dutyType
    const [isLoading, setIsLoading] = useState(false);
    const [duty, setDuty] = useState('');
    const [dutyList, setDutyList] = useState([{ title: 'وظیفه عمومی/سازمانی', val: 'public' }, { title: 'وظیفه تحصصی شغل', val: 'pro' }, { title: 'وظیفه خاص پست', val: 'special' }]);
    const [dutySelected, setDutySelected] = useState('');

    const [inp, setInp] = useState('');
    console.log(data?.positionRelatedForDuty);
    // let existAuth = data?.positionRelatedForDuty?.length > 0 ? data?.positionRelatedForDuty[0]?.describtion
    //     : [];
    // existAuth = existAuth?.length > 0 ? existAuth?.split(',') : []

    // console.log("existAuth", existAuth);
    const [res, setRes] = useState([]);
    useEffect(() => {
        if (duty) {
            let item = data?.positionRelatedForDuty?.length > 0 ? data?.positionRelatedForDuty?.filter(p => p?.dutyType === duty) : []
            // item = item?.length > 0 ? item[0]?.describtion.split(',') : []
            console.log('item', item);
            setRes(item);
        }
    }, [duty, setRes])
    // const resHandle = (e) => {
    // console.log(e.target.value);
    // if (e.key === 'Enter') {
    //     if (e.target.value === '') return
    //     setRes(e.target.value);
    //     ref.current.value = '';
    // }
    // }
    console.log(duty);
    const submitHandler = async (id) => {
        // let newRes = res.join(',');
        if (!inp || !duty) return
        setRes([...res, { dutyType: duty, describtion: inp, positionRelatedDuty: id }])
        ref.current.value = '';
        let formData = {
            dutyType: duty,
            describtion: inp,
            positionRelatedDuty: id
        }
        console.log("formData", formData);
        setIsLoading(true);
        let response;
        try {
            // if (idAuth !== -1) {
            //     response = await API.patch(`link/${idAuth}/`, formData, { headers: { Authorization: token } })
            // }
            // else {
            response = await API.post('/api/v1/organization/role/dutyposition/', formData, { headers: { Authorization: token } });
            // }
            if (response?.data) {
                console.log(response?.data);
                setRefresh(true);
                // setEditDuty(false);
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }
    const removeHandle = async (id, index) => {
        setIsLoading(true);
        try {
            const item = res?.filter((s, i) => i !== index);
            setRes(item);
            const response = await API.delete(`/api/v1/organization/role/dutyposition/${id}/`);
            setRefresh(true);
            setIsLoading(false);

        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }
    return (
        <Modal>
            <div className='bg-caspian-surface w-[50%] max-w-[600px] rounded-md'>
                <div className='flex justify-between items-center p-1.5'>
                    <span className='font-semibold'>ویرایش فرم وظایف سازمانی</span>
                    <PiX disabled={isLoading} className='cursor-pointer' onClick={() => setEditDuty(false)} />
                </div>
                <div className='border-t border-caspian-gray1 px-1.5 flex flex-col gap-4 py-5'>
                    <div className='flex flex-col gap-2'>
                        <div className='w-[200px]'>
                            <ListDutyType
                                gender={duty}
                                setGender={setDuty}
                                genderList={dutyList}
                                genderSelected={dutySelected}
                                setGenderSelected={setDutySelected}
                                lable='نوع وظیفه'
                            />
                        </div>
                        <span className='text-caspian-gray4 text-xs'>وظایف سازمانی :</span>
                        <textarea
                            cols={5}
                            rows={3}
                            ref={ref}
                            onChange={(e) => setInp(e.target.value)}
                            placeholder=' وظایف...'
                            className='border text-xs placeholder:text-xs placeholder:text-caspian-gray4 border-caspian-gray1 rounded-md p-1.5 resize-none' />
                    </div>
                    <div className='h-[40vh] overflow-y-auto'>
                        {/* responsible */}
                        <div className='p-1.5 pt-0 pr-0'>
                            {
                                !duty ? <p className='text-caspian-gray4 text-xs text-center pt-10'>نوع وظیفه  مشخص نشده است.</p>
                                    : res?.length > 0 ? res?.map((item, index) =>
                                        <div className='flex justify-between items-start my-2 first:mt-0'
                                            key={index}>
                                            <p className='flex gap-1.5 items-start'>
                                                <p className='w-[20px]'><PiDotBold className='text-2xl' /></p>
                                                <span className='w-[90%]'>{item?.describtion}</span>
                                            </p>
                                            <p className='w-[50px]'>
                                                <PiTrash className='cursor-pointer mr-auto' onClick={() => removeHandle(item?.id, index)} />
                                            </p>
                                        </div>) : <p className='text-center pt-10 text-xs text-caspian-gray4'>موردی ثبت نشده</p>
                            }
                        </div>
                    </div>
                    <button
                        onClick={() => submitHandler(id)}
                        className='flex justify-center gap-2 p-1.5 rounded-md text-caspian-surface bg-[#3C77C2] '>
                        <span>ثبت</span>
                        {isLoading && <span>
                            <PiCircleNotch className='animate-spin' />
                        </span>}
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default EditDutyForm;
