import React, { useEffect, useState } from "react";
import RowView from "./RowView";
import Paginate from "./Paginate";
import SingleTableProcesses from "./SingleTableProcesses";
import { useSearchParams } from "react-router-dom";
import { PiCircleNotch } from "react-icons/pi";

const TableListProcesses = ({ data, loading }) => {
  const [searchByProfileProcessRelated, setSearchByProfileProcessRelated] =
    useState("");
  const [searchById, setSearchId] = useState("");

  const [searchByGroupCategoryRelated, setSearchByGroupCategoryRelated] =
    useState("");
  const [searchByProcessGroupRelated, setSearchByProcessGroupRelated] =
    useState("");
  //
  const [vahed, setVahed] = useState('');
  //

  const [searchParams] = useSearchParams();
  const search = searchParams.get("search");
  const page = searchParams.get("page");
  const rows = searchParams.get("rows");

  let searchProfileProcessRelated = [];
  let searchId = [];
  // حوزه
  let searchGroupCategoryRelated = [];
  // گروه
  let searchProcessGroupRelated = [];

  useEffect(() => {
    if (search) {
      setCurrentPage(1);
    }
  }, [search]);

  let newData = [-1];
  if (data[0] !== -1) {
    newData = search
      ? data.filter((d) =>
        d.title.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
      : data;
  }

  if (newData[0] !== -1) {
    searchProfileProcessRelated = searchByProfileProcessRelated
      ? newData.filter((d) =>
        d.title
          .toLocaleLowerCase()
          .includes(searchByProfileProcessRelated.toLocaleLowerCase())
      )
      : newData;
    searchId = searchById
      ? searchProfileProcessRelated.filter((d) =>
        d.id.toString().includes(searchById)
      )
      : searchProfileProcessRelated;

    searchGroupCategoryRelated = searchByGroupCategoryRelated
      ? searchId.filter((d) =>
        d?.processGroupRelated?.groupCategoryRelated?.title
          .toString()
          .includes(searchByGroupCategoryRelated)
      )
      : searchId;

    searchProcessGroupRelated = searchByProcessGroupRelated
      ? searchGroupCategoryRelated.filter((d) =>
        d?.processGroupRelated?.title
          .toString()
          .includes(searchByProcessGroupRelated)
      )
      : searchGroupCategoryRelated;
  }

  const [currentPage, setCurrentPage] = useState(page ? page : 1);
  const [recordsPerPage, setRecordsPerPage] = useState(rows ? rows : 10);
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = searchProcessGroupRelated.slice(firstIndex, lastIndex);
  const npage = Math.ceil(searchProcessGroupRelated.length / recordsPerPage);

  return (
    <div className="flex flex-col gap-4">
      {loading ? (
        <div className="flex items-center justify-center py-40">
          <PiCircleNotch className="text-caspian-secondary-600 text-2xl animate-spin" />
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-[1200px] w-full text-left text-sm font-light">
            <thead className="border-b border-b-caspian-gray2 font-medium">
              <tr>
                <th
                  scope="col"
                  className="rounded-tr-xl bg-caspian-primary-500 p-2 relative min-w-[100px] w-[25%]"
                >
                  <input
                    // onClick={() => setCurrentPage(1)}
                    onChange={(e) => {
                      setSearchByProfileProcessRelated(e.target.value);
                      setCurrentPage(1);
                    }}
                    type="search"
                    id="t1"
                    className={`${searchByProfileProcessRelated
                      ? "border border-caspian-surface/50 "
                      : "border border-caspian-transparent"
                      } text-[#FFF7CB] bg-caspian-transparent p-1.5 rounded-md outline-none  w-full focus:border focus:border-caspian-surface/50 pr-11 font-light peer cursor-pointer`}
                  />
                  <label htmlFor="t1"
                    className={`${searchByProfileProcessRelated ? "right-0 left-auto" : ""
                      } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 bg-caspian-primary-500 rounded-md border border-caspian-primary-500 z-50 whitespace-nowrap cursor-pointer font-light
                            peer-focus:left-auto peer-focus:right-0`}
                  >عنوان</label>
                </th>
                <th
                  scope="col"
                  className="bg-caspian-primary-500 p-2 relative w-[10%]"
                >
                  <input
                    onChange={(e) => {
                      setSearchId(e.target.value);
                      setCurrentPage(1);
                    }}
                    type="search"
                    id="t2"
                    className={`${searchById
                      ? "border border-caspian-surface/50 "
                      : "border border-caspian-transparent"
                      } text-[#FFF7CB] bg-caspian-transparent p-1.5 pl-0 rounded-md outline-none  w-full focus:border focus:border-caspian-surface/50 pr-14 font-light peer cursor-pointer`}
                  />
                  <label htmlFor="t2"
                    className={`${searchById ? "right-3 left-full" : ""
                      } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 bg-caspian-primary-500 rounded-md z-50 whitespace-nowrap cursor-pointer font-light peer-focus:left-full peer-focus:right-3`}
                  >کد فرایند</label>
                </th>
                <th
                  scope="col"
                  className="bg-caspian-primary-500 relative p-2 w-[25%]"
                >
                  <input
                    onChange={(e) =>
                      setSearchByGroupCategoryRelated(e.target.value)
                    }
                    type="search"
                    id="t3"
                    className={`${searchByGroupCategoryRelated
                      ? "border border-caspian-surface/50 "
                      : "border border-caspian-transparent"
                      } text-[#FFF7CB] bg-caspian-transparent p-1.5 rounded-md outline-none  w-full focus:border focus:border-caspian-surface/50 pr-10 font-light peer cursor-pointer`}
                  />
                  <label htmlFor="t3"
                    className={`
                      ${searchByGroupCategoryRelated ? "right-4 left-full -translate-x-0" : ""}
                       text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 whitespace-nowrap rounded-md border border-caspian-primary-500 z-50 cursor-pointer font-light
                            peer-focus:left-full peer-focus:right-4 focus:-translate-x-0`}
                  > حوزه</label>
                </th>
                <th
                  scope="col"
                  className="bg-caspian-primary-500 p-2 relative w-[25%]"
                >
                  <input
                    onChange={(e) =>
                      setSearchByProcessGroupRelated(e.target.value)
                    }
                    type="search"
                    id="t4"
                    className={`${searchByProcessGroupRelated
                      ? "border border-caspian-surface/50 "
                      : "border border-caspian-transparent"
                      } text-[#FFF7CB] bg-caspian-transparent p-1.5 rounded-md outline-none  w-full focus:border focus:border-caspian-surface/50 pr-10 font-light peer cursor-pointer`}
                  />
                  <label htmlFor="t4"
                    className={`${searchByProcessGroupRelated ? "right-4 left-full -translate-x-0" : ""
                      } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 whitespace-nowrap rounded-md border border-caspian-primary-500 z-50 cursor-pointer font-light
                            peer-focus:left-full peer-focus:right-4 focus:-translate-x-0`}
                  > گروه</label>
                </th>
                <th
                  scope="col"
                  className="rounded-tl-xl bg-caspian-primary-500 p-2 relative w-[15%]"
                >
                  <input
                    // onChange={(e) =>
                    //   setSearchByProcessResponsibleJanamai(e.target.value)
                    // }
                    type="search"
                    onChange={(e) => setVahed(e.target.value)}
                    id="t5"
                    className={`${vahed
                      ? "border border-caspian-surface/50 "
                      : "border border-caspian-transparent"
                      } text-[#FFF7CB] bg-caspian-transparent p-1.5 rounded-md outline-none  w-full focus:border focus:border-caspian-surface/50 pr-20 font-light peer cursor-pointer`}
                  />
                  <label htmlFor="t5"
                    className={`${vahed ? "right-4 left-full -translate-x-0" : ""
                      } text-caspian-surface absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 p-0 whitespace-nowrap rounded-md border border-caspian-primary-500 z-50 cursor-pointer font-light
                            peer-focus:left-full peer-focus:right-4 focus:-translate-x-0`}
                  >واحد متولی</label>
                </th>
              </tr>
            </thead>
            {records?.length > 0 ? (
              <tbody>
                {records?.map((d, i) => (
                  <SingleTableProcesses
                    key={i}
                    //عنوان
                    businessProcessTitle={d?.title}
                    //کد فرایند
                    businessProcessCode={d?.id}
                    //حوزه
                    groupCategoryRelated={
                      d?.processGroupRelated?.groupCategoryRelated?.title
                    }
                    //گروه
                    processGroupRelated={d?.processGroupRelated?.title}
                    //واحد متولی
                    vahedMotevali={"واحد متولی"}
                  />
                ))}
              </tbody>
            ) : (
              <tbody className="py-24">
                <tr>
                  <td className="py-24">
                    <div className="flex items-center justify-center w-full py-10">
                      <div className="z-10 absolute left-1/2 -translate-x-1/2 mx-auto">
                        <div className="flex flex-col gap-2 items-center justify-center w-full">
                          <img
                            src="/assets/images/library/not-found.svg"
                            alt="مطلبی یافت نشد..."
                          />
                          <h2>مطلبی یافت نشد...</h2>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      )}
      {records.length > 0 && (
        <div className="flex items-center justify-between py-2">
          <RowView
            setRecordsPerPage={setRecordsPerPage}
            setCurrentPage={setCurrentPage}
          />
          <Paginate
            pageCount={npage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
          <div className="text-sm flex items-center gap-4">
            <span>نمایش {firstIndex + 1}</span>
            <span>تا {lastIndex - (recordsPerPage - records.length)}</span>
            <span>از کل {searchProcessGroupRelated.length}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableListProcesses;
