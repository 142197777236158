import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { PiCheckSquareFill, PiSquare, PiWarning } from "react-icons/pi";

const SingleQuestion = ({ item, index, onClick, isSelected }) => {
  return (
    <Draggable
      isDragDisabled={isSelected ? true : false}
      key={item?.id || index}
      draggableId={item?.id ? item.id.toString() : `draggable-${index}`}
      index={index}
    >
      {(provided, snapshot) => {
        const dragStyle = provided.draggableProps.style;
        const rotateTransform = snapshot.isDragging ? "rotate(10deg)" : "none";

        const combinedTransform =
          dragStyle && dragStyle.transform
            ? `${dragStyle.transform} ${rotateTransform}`
            : rotateTransform;

        const boxShadow = snapshot.isDragging
          ? "0 10px 20px rgba(0, 0, 0, 0.2)" // سایه هنگام درگ
          : "none";

        const opacity = snapshot.isDragging ? 0.2 : 1;
        return (
          <div
            onClick={isSelected && onClick}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`relative odd:bg-[#fcf4f5] even:bg-caspian-brown-100 p-4 w-full ${
              isSelected && "cursor-no-drop"
            }`}
            style={{
              ...dragStyle,
              userSelect: "none",

              transform: combinedTransform,
              transition: "transform 0.1s ease",
              boxShadow: boxShadow,
              //   opacity: opacity,
            }}
          >
            <div className="flex items-center gap-0 w-full">
              <div className="flex flex-col items-start gap-2 basis-[20%] 2xl:basis-[15%]">
                <span className="text-sm">{item?.code}</span>
                <div className="flex items-center gap-2">
                  {isSelected ? (
                    <PiCheckSquareFill className="text-2xl text-caspian-secondary-500" />
                  ) : (
                    <PiSquare
                      onClick={onClick}
                      className="text-2xl text-caspian-secondary-500 cursor-pointer"
                    />
                  )}
                  <PiWarning className="text-[#FF3333] text-lg" />
                </div>
                <div className="flex items-center justify-center">
                  <img
                    src={`/assets/images/audit/pdca-${item?.pdcaType}.svg`}
                    alt={item?.pdcaType}
                    className=""
                  />
                </div>
              </div>
              <div className="flex flex-col gap-2 basis-[80%] 2xl:basis-[85%] border-r pr-2 border-r-caspian-gray/20">
                <p className="text-xs leading-5 w-full font-medium">
                  <span className="font-normal text-xs text-caspian-gray4">
                    موضوع
                  </span>{" "}
                  {item?.title}
                </p>
                <p className="text-xs leading-5 w-full text-caspian-secondary-500 font-medium">
                  <span className="font-normal text-xs text-caspian-gray4">
                    معیار
                  </span>{" "}
                  {item?.criteriaon}
                </p>
              </div>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

export default SingleQuestion;
