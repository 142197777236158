import React, { useContext, useEffect, useRef, useState } from "react";
import StepOneForm from "./StepOneForm";
import { AuthContext } from "../../../../../../../../context/auth/AuthContext";
import API from "../../../../../../../../api/API";

const MainStepOne = ({
  setActiveTab,
  actionplanIdRelated,
  // setActionplanIdRelated,
  setShowCreateOfficeForm,
}) => {
  const { token } = useContext(AuthContext);
  const titleRef = useRef();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [actionPlanData, setActionPlanData] = useState([]);
  const [isDisable, setIsDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!token || !actionplanIdRelated) return;

    const getActionPlanData = async () => {
      setLoading(true);
      try {
        const res = await API.get(
          `/api/v1/actionplan/actionplans/${actionplanIdRelated}/`,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data);
        setActionPlanData(res?.data);
        setTitle(res?.data?.title);
        setDescription(res?.data?.description);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getActionPlanData();
  }, [token, actionplanIdRelated]);

  const submitHandler = async () => {
    // if (!title) return;

    if (
      actionplanIdRelated &&
      title === actionPlanData?.title &&
      description === actionPlanData?.description
    ) {
      setActiveTab("StepTwo");
    } else if (
      actionplanIdRelated &&
      (title !== actionPlanData?.title ||
        description !== actionPlanData?.description)
    ) {
      try {
        let formData = {
          id: actionplanIdRelated,
          title: title,
          description: description,
        };
        const res = await API.patch(
          `/api/v1/actionplan/actionplans/${actionplanIdRelated}/`,
          formData,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data);
        await setActiveTab("StepTwo");
      } catch (err) {
        console.log(err);
      }
    } else if (!actionplanIdRelated) {
      try {
        let formData = {
          title: title,
          description: description,
        };
        const res = await API.post(
          `/api/v1/actionplan/actionplans/`,
          formData,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data);
        // setActionplanIdRelated(res?.data?.id);
        await setActiveTab("StepTwo");
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (!title || !description || titleRef?.current?.value === "") {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [title, description]);

  return (
    <div className="flex flex-col justify-between h-full gap-4 p-2 min-h-[370px]">
      {loading ? (
        <div className="flex items-center justify-center w-full py-12">
          <span className="text-xs text-caspian-gray4">در حال بارگزاری...</span>
        </div>
      ) : (
        <StepOneForm
          titleRef={titleRef}
          title={title}
          setTitle={setTitle}
          description={description}
          setDescription={setDescription}
          titleData={actionPlanData?.title}
          descriptionData={actionPlanData?.description}
        />
      )}
      <div className="flex items-center gap-2">
        <button
          disabled={isDisable ? true : false}
          onClick={submitHandler}
          className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
        >
          مرحله بعد
        </button>
        {/* <button
          onClick={async () => {
            setShowCreateOfficeForm(false);
          }}
          className={`disabled:cursor-not-allowed disabled:text-caspian-gray text-caspian-secondary-500 text-xs p-1.5 rounded-md`}
        >
          ذخیره پیشنویس
        </button> */}
      </div>
    </div>
  );
};

export default MainStepOne;
