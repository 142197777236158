import SingleListCostReport from './SingleListCostReport'

const MainListCostReport = ({ dataListCost }) => {

    return (
        <div className='flex flex-col items-center w-full'>
            <SingleListCostReport dataListCost={dataListCost} />
        </div>
    )
}

export default MainListCostReport