import React, { useContext, useEffect, useState } from "react";
import SingleInput from "./SingleInput";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import API from "../../../../../api/API";

const MainSample = ({
  setTitle,
  setRiskTeamType,
  setRiskTeamSubjectType,
  setMsSelectedId,
  setAim,
  setDescription,
  setShowMemberList,
  title,
  description,
  aim,
  riskTeamType,
  riskTeamSubjectType,
  msSelectedId,
  setShowCreateForm,
}) => {
  const { token } = useContext(AuthContext);

  // سیستم های مدیریتی
  const [managementSystemData, setManagementSystemData] = useState([]);
  useEffect(() => {
    let urlManagementSystem = "/api/v1/standard/managmentSystem";
    if (token) {
      const getManagementSystem = () => {
        API.get(urlManagementSystem, {
          headers: {
            Authorization: token,
          },
        })
          .then((res) => {
            setManagementSystemData(res.data);
            setMsSelectedId(res.data[0]?.id);
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      };
      getManagementSystem();
    }
  }, [token, setMsSelectedId]);

  const [disabledSubmit, setDisabledSubmit] = useState(false);
  useEffect(() => {
    const getMembersList = async () => {
      if (
        !title ||
        !aim ||
        !riskTeamType ||
        !riskTeamSubjectType ||
        !msSelectedId
      ) {
        setDisabledSubmit(false);
      } else {
        setDisabledSubmit(true);
      }
    };
    getMembersList();
  }, [
    title,
    aim,
    riskTeamType,
    riskTeamSubjectType,
    msSelectedId,
  ]);

  return (
    <div className="w-full h-full flex flex-col gap-2 justify-between px-2">
      <div className="grid grid-cols-2 gap-2 w-full">
        {/* start عنوان */}
        <SingleInput lable={"عنوان"}>
          <input
            onChange={(e) => setTitle(e.target.value)}
            className="text-xs p-2 rounded-md bg-caspian-transparent outline-none border border-caspian-gray2 w-full"
            placeholder="عنوان تیم را وارد کنید"
          />
        </SingleInput>
        {/* end عنوان */}
        {/* start نوع */}
        <SingleInput lable={"نوع"}>
          <select
            onChange={(e) => setRiskTeamType(e.target.value)}
            className={`outline-none text-xs border border-caspian-gray2 p-1.5 rounded-md bg-caspian-transparent cursor-pointer w-full`}
          >
            <option className="text-caspian-on_primary" value={"identify"}>
              شناسایی
            </option>
            <option className="text-caspian-on_primary" value={"evaluation"}>
              ارزیابی
            </option>
          </select>
        </SingleInput>
        {/* end نوع */}
        {/* start سرفصل */}
        <SingleInput lable={"سرفصل"}>
          <select
            onChange={(e) => setRiskTeamSubjectType(e.target.value)}
            className={`outline-none text-xs border border-caspian-gray2 p-1.5 rounded-md bg-caspian-transparent cursor-pointer w-full`}
          >
            <option className="text-caspian-on_primary" value={"operational"}>
              عملیاتی
            </option>
            <option className="text-caspian-on_primary" value={"strategic"}>
              راهبردی
            </option>
          </select>
        </SingleInput>
        {/* end سرفصل */}
        {/* start سیستم مدیریتی */}
        <SingleInput lable={"سیستم مدیریتی"}>
          <select
            onChange={(e) => setMsSelectedId(e.target.value)}
            defaultValue={managementSystemData[0]?.id}
            className={`outline-none text-xs border border-caspian-gray2 p-1.5 rounded-md bg-caspian-transparent cursor-pointer w-full`}
          >
            {managementSystemData?.map((ms, i) => (
              <option
                key={i}
                className="text-caspian-on_primary"
                value={ms?.id}
              >
                {ms?.title}
              </option>
            ))}
          </select>
        </SingleInput>
        {/* end سیستم مدیریتی */}
      </div>
      <div className="w-full flex flex-col gap-2">
        {/* start هدف */}
        <SingleInput lable={"هدف"}>
          <input
            onChange={(e) => setAim(e.target.value)}
            className="text-xs p-2 rounded-md bg-caspian-transparent outline-none border border-caspian-gray2 w-full"
            placeholder="هدف را وارد کنید"
          />
        </SingleInput>
        {/* end هدف */}
        {/* start توضیحات */}
        <SingleInput lable={"توضیحات"}>
          <textarea
            onChange={(e) => setDescription(e.target.value)}
            rows={5}
            className="text-xs p-2 rounded-md bg-caspian-transparent outline-none border border-caspian-gray2 w-full resize-none"
            placeholder="توضیحات را اینجا بنویسید"
          />
        </SingleInput>
        {/* end توضیحات */}
      </div>
      {/* start دکمه ها */}
      <div className="flex items-center gap-2 p-2 pb-4">
        <button
          disabled={disabledSubmit ? false : true}
          onClick={() => disabledSubmit && setShowMemberList(true)}
          className="disabled:bg-caspian-gray2 bg-caspian-secondary-500 text-caspian-surface text-xs py-1.5 px-6 rounded-md"
        >
          بعدی
        </button>
        <button
          onClick={() => setShowCreateForm(false)}
          className="text-xs text-[#FF5B5B] mr-4"
        >
          انصراف
        </button>
      </div>
      {/* end دکمه ها */}
    </div>
  );
};

export default MainSample;
