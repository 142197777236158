import React from "react";
import { Link } from "react-router-dom";

const SideBarNavLink = ({
  setButtonActive,
  buttonActive,
  titleButtonActive,
  showMenu,
  icon,
  title,
  link,
}) => {
  return (
    <li>
      <Link
        to={link}
        onClick={() => {
          setButtonActive(titleButtonActive);
        }}
        className={`${
          buttonActive === titleButtonActive
            ? "bg-caspian-primary-300/10 border-r-2 border-r-caspian-primary-300"
            : " border-r-2 border-r-caspian-surface"
        } relative hover:bg-neutral-700 duration-300 flex items-center justify-center cursor-pointer p-2 ${
          showMenu ? "w-[80%] md:w-full" : "w-full"
        }`}
      >
        <div className="text-2xl">{icon}</div>
        {showMenu && <span className="w-full pr-3">{title}</span>}
      </Link>
    </li>
  );
};

export default SideBarNavLink;
