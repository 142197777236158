import { Outlet, useLocation, useParams } from "react-router-dom";
import { useAppContext } from "../../context/app-context/AppContext";
import { useEffect } from "react";

const StackholdersLayout = ({ showMenu }) => {
  const location = useLocation();
  const { id } = useParams();
  const { setBradCrump1, setBradCrump2, setBradCrump3, setBrcLink2 } =
    useAppContext();
  useEffect(() => {
    if (location.pathname.includes("stackholders/list")) {
      setBradCrump1("ذینفعان");
      setBradCrump2("لیست ذینفعان");
    } else if (location.pathname.includes("stackholders/model")) {
      setBradCrump1("ذینفعان");
      setBradCrump2("مدل ذینفعان");
    } else if (location.pathname.includes(`stackholders/${id}`)) {
      setBradCrump1("ذینفعان");
      setBradCrump2(id);
    }
  }, [id, location, setBradCrump1, setBradCrump2, setBradCrump3, setBrcLink2]);
  return (
    <div
      className={
        showMenu
          ? `pl-[50px] mr-auto w-full md:w-[75%] lg:w-[80%] xl:w-[85%] 2xl:w-[85%] bg-caspian-gray3 p-1 overflow-y-scroll`
          : `pl-[50px] mr-[54px] w-full bg-caspian-gray3 p-1 overflow-y-scroll`
      }
    >
      <Outlet />
    </div>
  );
};

export default StackholdersLayout;
