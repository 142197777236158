import React from "react";

const MainAddressDescForm = ({
  description,
  setDescription,
  address,
  setAddress,
  windowHeight,
}) => {
  return (
    <div className="flex items-start gap-2 w-full">
      <div className="w-full flex flex-col gap-1.5">
        <label className="text-xs 2xl:text-sm text-caspian-gray">
          آدرس واحد
        </label>
        <textarea
          defaultValue={address}
          name="address"
          rows={windowHeight > 700 ? 6 : 5}
          onChange={(e) => setAddress(e.target.value)}
          placeholder="آدرس واحد مورد نظر خود را کنید"
          className="resize-none outline-none border border-caspian-gray1 p-1.5 text-xs 2xl:text-sm 2xl:leading-7 rounded-md"
        />
      </div>
      <div className="w-full flex flex-col gap-1.5">
        <label className="text-xs 2xl:text-sm text-caspian-gray">توضیحات</label>
        <textarea
          defaultValue={description}
          name="description"
          rows={windowHeight > 700 ? 6 : 5}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="توضیحات مورد نظر را یادداشت کنید"
          className="resize-none outline-none border border-caspian-gray1 p-1.5 text-xs 2xl:text-sm 2xl:leading-7 rounded-md"
        />
      </div>
    </div>
  );
};

export default MainAddressDescForm;
