import Logo from "./Logo";
import BreadCrump from "./BreadCrump";
import Notices from "./Notices";

const Header = () => {
  return (
    <header className="bg-caspian-surface shadow-md px-2 py-1 w-full z-50 sticky top-0 mx-auto">
      <div className="flex items-center gap-8">
        <Logo />
        <div className="flex items-center justify-between w-full">
          <BreadCrump />
          <Notices />
        </div>
      </div>
    </header>
  );
};

export default Header;
