
const CategoryStackholder = ({ sectionData, setStackholderId, setShowPopUp, section }) => {

    return (
        <div className='flex w-[200px] xl:w-[300px] max-w-[300px] items-center justify-center flex-col'>
            <span onClick={() => {
                setStackholderId(section?.id)
                setShowPopUp(true)
            }}
                style={{ background: `#${sectionData?.colorHex}` }}
                className='text-xs leading-5 text-center py-3 z-10 cursor-pointer w-full flex items-center justify-center rounded-lg text-caspian-on_primary/70 font-semibold'>
                {sectionData?.title}
            </span>
        </div>
    )
}

export default CategoryStackholder;