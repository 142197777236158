import React, { useContext, useEffect, useState } from 'react'
import SingleListUploadFile from './SingleListUploadFile'
import { PiPlus } from 'react-icons/pi'
import ModalUploadFile from './ModalUploadFile'
import { AuthContext } from '../../../../../../../context/auth/AuthContext'
import API from '../../../../../../../api/API'



const ListUploadFile = ({ reportId, listDoc, setListDoc, setRefreshList, setAddDoc, refreshList, checked, progressPercent, dataProgress }) => {
    const { token } = useContext(AuthContext);

    const [showModalUploadFile, setShowModalUploadFile] = useState(false)
    const [idDocument, setIdDocument] = useState(0)


    useEffect(() => {
        setRefreshList(1)
    }, [setRefreshList])

    useEffect(() => {
        const handleDataDoc = async () => {

            if (!token || refreshList !== 1 || !reportId || !progressPercent) return;

            try {
                const res = await API.get(`/api/v1/actionplan/reports/reportProgressDocument/${reportId}`,
                    { headers: { Authorization: token } })

                console.log(res?.data)
                setListDoc(res?.data)
                setRefreshList(0)

            } catch (error) {
                console.log(error)
            }
        }
        handleDataDoc()


    }, [token, setListDoc, reportId, refreshList, setRefreshList, progressPercent])

    return (
        <div className='w-full flex flex-col items-start gap-2 pt-2 '>
            <div className='flex items-center gap-2'
                onClick={() => {
                    (checked && (progressPercent?.length > 0 || dataProgress?.progress_percent)) && setShowModalUploadFile(true)
                }}>

                <span className={`text-xs 2xl:text-sm ${(checked && (progressPercent?.length > 0 || dataProgress?.progress_percent)) ? "text-caspian-secondary-600 cursor-pointer" : "text-caspian-secondary-600/30"} `}>افزدون سند</span>
                <PiPlus className={`text-base ${(checked && (progressPercent?.length > 0 || dataProgress?.progress_percent)) ? "text-caspian-secondary-600 cursor-pointer" : "text-caspian-secondary-600/30"}`} />
            </div>


            <div className='h-[20vh] px-3 w-full flex flex-col items-center gap-10 overflow-auto'>
                <SingleListUploadFile setAddDoc={setAddDoc} idDocument={idDocument} listDoc={listDoc} setListDoc={setListDoc} reportId={reportId} checked={checked}
                    setRefreshList={setRefreshList} refresList={refreshList} setShowModalUploadFile={setShowModalUploadFile} />
            </div>

            {
                showModalUploadFile &&
                <ModalUploadFile setAddDoc={setAddDoc} listDoc={listDoc} setListDoc={setListDoc} reportId={reportId} idDocument={idDocument} setIdDocument={setIdDocument}
                    setShowModalUploadFile={setShowModalUploadFile} setRefreshList={setRefreshList} />
            }
        </div >


    )
}

export default ListUploadFile