import React, { useContext, useEffect, useState } from "react";
import API from "../../../api/API";
import { AuthContext } from "../../../context/auth/AuthContext";
import { PiCheckCircleFill } from "react-icons/pi";

const MembersList = ({
  memberSelected,
  setMemberSelected,
  serachMember,
  setSerachMember,
}) => {
  const { token } = useContext(AuthContext);
  const [members, setMembers] = useState([-1]);
  let url2 = "/api/v1/profile/";
  useEffect(() => {
    const getMembers = () => {
      API.get(url2, {
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          setMembers(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getMembers();
  }, [token, url2]);

  let serach = [];

  if (members.length > 0) {
    serach = serachMember
      ? members.filter((d) =>
          (d?.firstName + " " + d?.lastName)
            .toLocaleLowerCase()
            .includes(serachMember.toLocaleLowerCase())
        )
      : members;
  }

  return (
    <div className="overflow-y-scroll h-full p-2">
      {serach[0] === -1 ? (
        <div className="flex items-center justify-center pt-12">
          <span className="text-xs text-caspian-gray4">درحال بارگزاری...</span>
        </div>
      ) : (
        <div className="flex flex-col gap-1">
          {serach.map((member, i) => (
            <div
              onClick={() => {
                memberSelected.includes(member?.id)
                  ? setMemberSelected(
                      memberSelected.filter((m) => m !== member?.id)
                    )
                  : setMemberSelected((m) => [...m, member?.id]);
              }}
              key={i}
              className="py-2 border-b border-b-caspian-gray2 last:border-b-0 flex gap-2 cursor-pointer"
            >
              <div className="relative w-8 h-8 rounded-full flex items-center justify-center bg-[#FCC934] text-caspian-surface">
                {member?.personalInfoProfileRelated[0]?.avatar ? (
                  <img
                    src={member?.personalInfoProfileRelated[0]?.avatar}
                    alt="test"
                    className="rounded-full"
                  />
                ) : (
                  member.firstName[0]
                )}
                {memberSelected.includes(member?.id) && (
                  <div className="bg-caspian-surface absolute -right-1 top-5 rounded-full">
                    <PiCheckCircleFill className="text-caspian-secondary-500" />
                  </div>
                )}
              </div>
              <div className="flex items-center gap-2 w-[75%]">
                <div className="flex items-center gap-2 w-full">
                  <span className="text-xs font-bold">
                    {member.firstName + " " + member.lastName}
                  </span>
                </div>
                <div className="flex items-center gap-2 w-full">
                  <span className="text-[11px] text-caspian-gray">
                    کارشناس حقوقی
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MembersList;
