import IdProcessNavbar from "../components/process-management-components/ProcessNavbar";

const ProcessManagement = ({ showMenu }) => {
  return (
    <div
      className={
        showMenu
          ? `pl-[50px] mr-auto w-full md:w-[75%] lg:w-[80%] xl:w-[85%] 2xl:w-[85%] bg-caspian-gray3 p-1 overflow-y-scroll`
          : `pl-[50px] mr-[54px] w-full bg-caspian-gray3 p-1 overflow-y-scroll`
      }
    >
      <IdProcessNavbar />
    </div>
  );
};

export default ProcessManagement;
