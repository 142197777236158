import React, { useContext, useState } from "react";
import { PiXBold } from "react-icons/pi";
import API from "../../../../../../api/API";
import { AuthContext } from "../../../../../../context/auth/AuthContext";
import DateTimePicker from "./DateTimePicker";
import TimePickerReminder from "./TimePickerReminder";

const ReminderForm = ({
  data,
  setData,
  setShowReminderForm,
  thisId,
  setRefresh,
  thisTitle,
}) => {
  const { token } = useContext(AuthContext);
  const [members, setMember] = useState(thisTitle);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  const addReminderHandler = async () => {
    if (members === "" || date === "" || time === "") return null;
    await API.post(
      "/api/v1/meeting/info/reminder/",
      {
        title: members,
        meetingReminderDate: date,
        meetingReminderTime: time,
        meetingReminderRelated: +thisId,
      },
      {
        headers: { Authorization: token },
      }
    )
      .then((res) => {
        console.log(res.data);
        setRefresh(1);
      })
      .catch((err) => {
        console.log(err);
      });
    await setShowReminderForm(false);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full z-50">
      <div className="bg-caspian-surface rounded-md shadow-[0_0_5px_#00000050] p-3 w-[400px] flex flex-col gap-3">
        <div className="flex items-center justify-between p-2">
          <h2 className="text-sm font-bold">افزودن یادآوری</h2>
          <button onClick={() => setShowReminderForm(false)}>
            <PiXBold className={"text-caspian-gray4 text-sm"} />
          </button>
        </div>

        <div className="flex flex-col gap-3">
          <div className="w-full flex flex-col gap-1.5 basis-1/3">
            <label className="text-xs text-caspian-gray4">عنوان یادآوری</label>
            <input
              onChange={(e) => {
                setMember(e.target.value);
              }}
              name="members"
              type="text"
              defaultValue={members ? members : thisTitle}
              placeholder="انتخاب کنید"
              className="outline-none outline-caspian-gray1 p-1.5 text-xs rounded-md outline-1"
            />
          </div>
          <div className="flex items-center justify-between gap-4">
            <div className="w-full flex flex-col gap-1.5 basis-1/2">
              <label className="text-xs text-caspian-gray4">
                تاریخ یادآوری
              </label>
              <DateTimePicker setDate={setDate} />
            </div>
            <div className="relative w-full flex flex-col gap-1.5 basis-1/2">
              <label className="text-xs text-caspian-gray4">ساعت یادآوری</label>
              <TimePickerReminder setTime={setTime} />
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2 mt-2">
          <button
            onClick={() => addReminderHandler()}
            className="outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs py-1.5 px-6 rounded-md"
          >
            افزودن
          </button>
          <button className="text-xs text-[#FF5B5B] mr-4">لغو</button>
        </div>
      </div>
    </div>
  );
};

export default ReminderForm;
