import React, { useContext, useEffect, useState } from "react";
import MainReport from "./report/MainReport";
import MainTask from "./task/MainTask";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import { useParams } from "react-router-dom";
import API from "../../../../../api/API";

const MainPlanning = ({ setActiveTab }) => {
  const [refreshBrief, setRefreshBrief] = useState(0);

  const { token } = useContext(AuthContext);
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [fullData, setFullData] = useState([]);

  useEffect(() => {
    setRefreshBrief(1);
  }, []);
  useEffect(() => {
    if (!token || !params?.id || refreshBrief === 0) return;
    const getActionPlanBrief = async () => {
      setLoading(true);
      try {
        const res = await API.get(
          `/api/v1/actionplan/actionplan/actionplanBrief/${params?.id}`,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data);
        setRefreshBrief(0);
        setFullData(res?.data[0]);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getActionPlanBrief();
  }, [token, params?.id, refreshBrief, setRefreshBrief]);
  return (
    <div className="flex flex-col gap-2">
      <MainReport
        loading={loading}
        fullData={fullData}
        refreshBrief={refreshBrief}
        setRefreshBrief={setRefreshBrief}
      />
      <MainTask
        berifWeight={fullData?.remained_weight * 100}
        isWeight={!loading && fullData?.remained_weight === 0 ? false : true}
        setActiveTab={setActiveTab}
        setRefreshBrief={setRefreshBrief}
      />
    </div>
  );
};

export default MainPlanning;
