import React from "react";

const MainChallengeSingle = ({
  proplemTitle,
  problemType,
  solution,
  className,
}) => {
  return (
    <tr className={`hover:bg-caspian-gray3 duration-300 text-sm ${className}`}>
      <td className="text-right px-3 py-6">
        <h2>{proplemTitle}</h2>
      </td>
      <td className="text-right px-3 py-6">
        <input
          type="text"
          disabled
          className="w-full p-2.5 rounded-md text-sm disabled:bg-caspian-transparent outline outline-1 disabled:outline-caspian-gray2"
          defaultValue={problemType}
        />
      </td>
      <td className="text-right px-3 py-6">
        <input
          type="text"
          disabled
          className="w-full p-2.5 rounded-md text-sm disabled:bg-caspian-transparent outline outline-1 disabled:outline-caspian-gray2"
          defaultValue={solution}
        />
      </td>
    </tr>
  );
};

export default MainChallengeSingle;
