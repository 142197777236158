import { PiCalendar } from "react-icons/pi";

const Profile = ({ profileData }) => {
  return (
    <div className="flex flex-col gap-2 w-full h-full p-2">
      <div className="flex items-center justify-between gap-2 w-full">
        <h2 className="text-sm font-bold">{profileData?.title}</h2>
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-1">
            <PiCalendar className="text-lg text-caspian-gray4" />
            <span className="text-xs text-caspian-gray">تاریخ ایجاد</span>
          </div>
          <span className="text-xs">
            {new Date(profileData?.created_at).toLocaleDateString("fa-IR")}
          </span>
        </div>
      </div>
      <div className="flex items-stretch justify-stretch gap-2 border-b border-b-caspian-gray2/20 pb-2">
        <span className="text-xs text-caspian-gray leading-6">توضیحات</span>
        <p className="text-xs whitespace-pre-wrap leading-6 border-r-2 border-r-caspian-primary-300 pr-2">
          {profileData?.description}
        </p>
      </div>
      {/* شروع هدف */}
      <div className="flex items-center gap-2 border-b border-b-caspian-gray2/20 pb-2">
        <span className="text-xs text-caspian-gray">هدف</span>
        <p className="text-xs whitespace-pre-wrap">{profileData?.aim}</p>
      </div>
      {/* پایان هدف */}
      {/* شروع مدیریت سیستم */}
      <div className="flex items-center gap-2 border-b border-b-caspian-gray2/20 pb-2">
        <span className="text-xs text-caspian-gray">مدیریت سیستم</span>
        <p className="text-xs">
          {profileData?.msRelated?.standardTitlePersian +
            " - " +
            profileData?.msRelated?.standardTitleEnglish}
        </p>
      </div>
      {/* پایان مدیریت سیستم */}
      <div className="flex items-center gap-2 justify-around w-full border-b border-b-caspian-gray2/20 pb-2">
        {/* شروع نوع ریسک */}
        <div className="flex items-center gap-2">
          <span className="text-xs text-caspian-gray">نوع ریسک</span>
          <span className="text-xs">{profileData?.riskTeamType}</span>
        </div>
        {/* پایان نوع ریسک */}
        {/* شروع سرفصل ها */}
        <div className="flex items-center gap-2">
          <span className="text-xs text-caspian-gray">نوع سرفصل</span>
          <span className="text-xs">{profileData?.riskTeamSubjectType}</span>
        </div>
        {/* پایان سرفصل ها */}
      </div>
      <div className="flex items-center gap-2 justify-around w-full border-b border-b-caspian-gray2/20 pb-2">
        {/* شروع مسئول تیم */}
        <div className="flex items-center gap-2">
          <span className="text-xs text-caspian-gray">مسئول تیم</span>
          <span className="text-xs">
            {profileData?.leader?.firstName +
              " " +
              profileData?.leader?.lastName}
          </span>
        </div>
        {/* پایان مسئول تیم */}
        {/* شروع هماهنگ کننده */}
        <div className="flex items-center gap-2">
          <span className="text-xs text-caspian-gray">
            هماهنگ کننده
          </span>
          <span className="text-xs">
            {profileData?.coordinator?.firstName +
              " " +
              profileData?.coordinator?.lastName}
          </span>
        </div>
        {/* پایان هماهنگ کننده */}
      </div>
    </div>
  );
};

export default Profile;
