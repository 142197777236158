import { useState } from "react";
import { AppContext } from "../app-context/AppContext";
import { Link } from "react-router-dom";
import { PiCaretLeft } from "react-icons/pi";

const ContextProvider = ({ children }) => {
  function brc(title, link) {
    return (
      <div className="flex items-center gap-2">
        <PiCaretLeft className="text-lg" />
        {bradCrump3 && link ? (
          <Link onClick={() => setBradCrump3("")} to={link} className="text-sm">
            {title}
          </Link>
        ) : (
          <h2 className="text-sm">{title}</h2>
        )}
      </div>
    );
  }
  const [refreshUser, setRefreshUser] = useState(0);
  const [getAvatar, setGetAvatar] = useState(null);
  const [bradCrump1, setBradCrump1] = useState("");
  const [bradCrump2, setBradCrump2] = useState("");
  const [brcLink2, setBrcLink2] = useState("");
  const [bradCrump3, setBradCrump3] = useState("");
  const [navbarLibraryActive, setNavbarLibraryActive] = useState("all");
  const [navbarIdProcessActive, setNavbarIdProcessActive] = useState("model");
  const [navbarReports, setNavbarReports] = useState("received");
  const [navbarRequests, setNavbarRequests] = useState("req-received");
  const [navbarChart, setNavbarChart] = useState("list-chart");
  const [navbarMeeting, setNavbarMeeting] = useState("profile-meeting");
  const [navbarActionPlan, setNavbarActionPlan] = useState("plans");
  const [navbarRisk, setNavbarRisk] = useState("teams");
  const [navbarProfileMeeting, setNavbarProfileMeeting] =
    useState("all-profiles");
  const [navbarInprogressMeeting, setNavbarInprogressMeeting] =
    useState("all-meeting");
  const [navbarListMeeting, setNavbarListMeeting] = useState("all-meeting");
  const [navbarDocsActive, setNavbarDocsActive] = useState("documents");
  const [navbarProfileActive, setNavbarProfileActive] =
    useState("personal-info");
  const [receivedNumber, setRreceivedNumber] = useState(0);
  const [sendNumber, setSendNumber] = useState(0);
  const [requestSendNumber, setRequestSendNumber] = useState(0);
  const [requestReceivedNumber, setRequestReceivedNumber] = useState(0);
  const [requestNumber, setRequestNumber] = useState({
    received: 0,
    sent: 0,
    refer: 0,
  });

  return (
    <AppContext.Provider
      value={{
        sendNumber,
        setSendNumber,
        receivedNumber,
        setRreceivedNumber,
        requestSendNumber,
        setRequestSendNumber,
        requestReceivedNumber,
        setRequestReceivedNumber,
        requestNumber,
        setRequestNumber,
        navbarChart,
        setNavbarChart,
        navbarActionPlan,
        setNavbarActionPlan,
        navbarRisk,
        setNavbarRisk,
        navbarMeeting,
        setNavbarMeeting,
        navbarProfileMeeting,
        setNavbarProfileMeeting,
        navbarInprogressMeeting,
        setNavbarInprogressMeeting,
        navbarListMeeting,
        setNavbarListMeeting,
        refreshUser,
        setRefreshUser,
        getAvatar,
        setGetAvatar,
        navbarLibraryActive,
        setNavbarLibraryActive,
        navbarIdProcessActive,
        setNavbarIdProcessActive,
        navbarProfileActive,
        setNavbarProfileActive,
        navbarDocsActive,
        setNavbarDocsActive,
        navbarReports,
        setNavbarReports,
        navbarRequests,
        setNavbarRequests,
        bradCrump1,
        setBradCrump1,
        bradCrump2,
        setBradCrump2,
        bradCrump3,
        setBradCrump3,
        brc,
        brcLink2,
        setBrcLink2,
        // currentPage,
        // setCurrentPage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default ContextProvider;
