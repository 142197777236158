import React, { useEffect, useState } from "react";
import ListSelected from "./ListSelected";
import { PiPlusBold } from "react-icons/pi";
import ItemSingle from "./ItemSingle";

const MainAddStandardForm = ({
  title,
  standard,
  standardId,
  allMsId,
  setAllMsId,
  scheduleData,
  scheduleDataId,
}) => {
  const [showListItems, setShowListItems] = useState(false);

  const [itemsSelected, setItemsSelected] = useState([]);
  const [itemsFinalSelected, setItemsFinalSelected] = useState(itemsSelected);
  const [itemsAllData, setItemsAllData] = useState([]);

  useEffect(() => {
    setAllMsId((prevItems) => [
      ...prevItems,
      { standardIds: itemsFinalSelected, title: standardId },
    ]);
  }, [setAllMsId, itemsFinalSelected, standardId]);

  return (
    <div className="w-full p-2 rounded-md border border-caspian-gray2 flex flex-col gap-2">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-2 w-1/2">
          <span className="text-caspian-secondary-500 text-xs">{title}</span>
          <span className="text-xs">{standard}</span>
        </div>

        <div className="w-1/2 flex flex-col gap-1.5">
          <div className="items-center flex gap-2 w-full">
            <button
              onClick={() => {
                setShowListItems(true);
              }}
              className="rounded-md border border-caspian-secondary-400 p-2 flex items-center justify-between gap-1 w-full"
            >
              <div className="flex items-center gap-1.5">
                <PiPlusBold className="text-caspian-secondary-500 text-md" />
                <span className="text-caspian-secondary-500 text-xs">
                  افزودن
                </span>
              </div>
              {itemsFinalSelected?.length > 0 ? (
                <span className="text-xs">
                  {itemsFinalSelected?.length} بند افزوده شد
                </span>
              ) : (
                <span className="text-xs">بند انتخاب نشده</span>
              )}
            </button>
          </div>
          {showListItems && (
            <ListSelected
              scheduleData={scheduleData}
              scheduleDataId={scheduleDataId}
              setItemsAllData={setItemsAllData}
              standardId={standardId}
              finalSelected={itemsFinalSelected}
              setFinalSelected={setItemsFinalSelected}
              selected={itemsSelected}
              setSelected={setItemsSelected}
              setShowListSelected={setShowListItems}
              title="لیست بندها"
              url={`/api/v1/standard/clause/all/${standardId}`}
            />
          )}
        </div>
      </div>
      {itemsFinalSelected?.length > 0 && (
        <div className="flex flex-col gap-0 w-full">
          <div className="text-xs even:bg-caspian-gray2/50 odd:bg-caspian-gray3 p-2 flex flex-wrap gap-2">
            <span className="text-caspian-gray4">بند:</span>
            {itemsAllData?.map((item, index) => (
              <ItemSingle key={index} title={item?.clauseNumber} data={item} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MainAddStandardForm;
