import React from "react";
import CartableNotifactionBox from "./CartableNotifactionBox";

const MainCartableNotification = ({ data, setOpen }) => {
  return (
    <div className="flex flex-col gap-0 max-h-[300px] overflow-y-scroll">
      {data?.length > 0 ? (
        data
          .sort((a, b) => (b.created_at > a.created_at ? 1 : -1))
          .map((m, i) => (
            <CartableNotifactionBox
              key={i}
              setOpen={setOpen}
              id={m?.id}
              title={m?.title}
              description={m?.text}
              sender={m?.sender}
              created_at={m?.created_at}
              link={m?.link}
              is_readed={m?.is_readed}
              receiver={m?.receiver}
              deadLine={m?.deadLine}
            />
          ))
      ) : (
        <div className="text-center p-2">
          <h2 className="text-xs text-caspian-gray py-3">
            اعلانی وجود ندارد...
          </h2>
        </div>
      )}
    </div>
  );
};

export default MainCartableNotification;
