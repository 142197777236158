import React, { useEffect, useRef, useState } from "react";
import { PiFunnel, PiFunnelFill } from "react-icons/pi";
import { useSearchParams } from "react-router-dom";

export const uniqueArray = (arr) => [...new Set(arr)];
const FilterType = ({ data, filterTypeItems, setFilterTypeItems }) => {
  let menuRef = useRef();

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");

  const [open, setOpen] = useState(false);
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const filterSubmit = (e) => {
    if (e.target.checked) {
      setFilterTypeItems([...filterTypeItems, e.target.value]);
    } else if (!e.target.checked) {
      setFilterTypeItems(filterTypeItems.filter((f) => f !== e.target.value));
    }
    handleFilter("page", 1);
  };

  function handleFilter(key, value) {
    setSearchParams((prevParams) => {
      if (value === null || value === "") {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    });
  }

  return (
    <div ref={menuRef} className="relative w-fit">
      <div onClick={() => setOpen(!open)} className="relative cursor-pointer">
        {filterTypeItems.length > 0 ? (
          <PiFunnelFill className="text-xl text-caspian-surface cursor-pointer" />
        ) : (
          <PiFunnel className="text-xl text-caspian-surface cursor-pointer" />
        )}
      </div>
      {/* Start Open Menu */}
      <div
        className={
          open
            ? "z-50 overflow-y-scroll absolute top-5 left-0 flex items-center flex-col drop-shadow-[0_0_1px_#00000050] min-w-[180px] max-h-[180px] bg-caspian-surface rounded-lg bg-scroll"
            : "hidden"
        }
      >
        {/* Start Box Menu */}
        <div className="flex flex-col items-start gap-4 w-full p-1">
          <ul className="flex flex-col items-start gap-4 w-full p-1 bg-white rounded-sm">
            {uniqueArray(data).map((d, i) => (
              <li
                key={i}
                className="flex items-center gap-1 text-xs font-normal text-neutral-500 w-full"
              >
                <input
                  type="checkbox"
                  value={d !== null && d}
                  id={d?.toString() + i?.toString()}
                  onChange={filterSubmit}
                  className="accent-blue-300"
                />
                <label
                  className="cursor-pointer"
                  htmlFor={d?.toString() + i?.toString()}
                >
                  {d}
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* End Open Menu */}
    </div>
  );
};

export default FilterType;
