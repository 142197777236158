import React from 'react'

const TooltipObjectiveProfileGoal = ({ children, shape }) => {
  return (
    <div className={`flex items-center w-2/3 rounded-lg z-50 absolute bottom-[65px] left-0 text-xs 2xl:text-sm p-3 bg-caspian-gray3 shadow-[0px_0px_1px_4px]
    ${shape === "S" && ' shadow-caspian-purple-600'} ${shape === "M" && ' shadow-caspian-green-600'}  ${shape === "A" && ' shadow-caspian-brown-600'} ${shape === "R" && ' shadow-caspian-blue-600'} ${shape === "T" && ' shadow-caspian-darkblue-600'}`}>
      <div className={`w-0 h-0 absolute left-20 -bottom-[16px] border-x-[16px] border-x-caspian-transparent border-t-[16px] border-caspian-gray3 `}>
        <div className={`w-0 h-0 absolute -right-[20px] -z-10 -top-[14px] border-x-[20px] border-t-[20px] border-x-caspian-transparent 
         ${shape === "S" && 'border-t-caspian-purple-600'} ${shape === "M" && 'border-t-caspian-green-600'}  ${shape === "A" && 'border-t-caspian-brown-600'} ${shape === "R" && 'border-t-caspian-blue-600'} ${shape === "T" && 'border-t-caspian-darkblue-600'}`}></div>
      </div>
      {children}
    </div >
  )
}

export default TooltipObjectiveProfileGoal;
