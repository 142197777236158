import React, { useState } from "react";
import MainLeftbarChecklistForm from "./leftbar-checklist-form/MainLeftbarChecklistForm";
import MainRightbarChecklistForm from "./rightbar-checklist-form/MainRightbarChecklistForm";

const MainAuditChecklistForm = ({
  questionData,
  questionFilter,
  processFilter,
}) => {
  const [indexQuestion, setIndexQuestion] = useState(0);

  return (
    <div className="flex items-start gap-0 w-full min-h-screen py-4">
      <MainRightbarChecklistForm
        indexQuestion={indexQuestion}
        setIndexQuestion={setIndexQuestion}
        questionData={questionData}
        standardList={questionFilter?.map((s) => s?.standardRelated)}
        clauseList={questionFilter?.map((s) => s?.clauseRelated)[indexQuestion]}
        processFilter={processFilter}
      />
      <MainLeftbarChecklistForm />
    </div>
  );
};

export default MainAuditChecklistForm;
