import React, { useContext, useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { AuthContext } from "../../../context/auth/AuthContext";
import API from "../../../api/API";

const IdActionPlan = () => {
  const params = useParams();
  const { token } = useContext(AuthContext);
  const [actionPlanData, setActionPlanData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [costData, setCostData] = useState([]);
  const [refresh, setRefresh] = useState(0);
  // useEffect(() => {
  //   setRefresh(1);
  // }, []);

  const getActionPlanBrief = async () => {
    try {
      const res = await API.get(
        `/api/v1/actionplan/actionplan/actionplanBrief/${params?.id}`,
        {
          headers: { Authorization: token },
        }
      );
      console.log(res?.data);
      setCostData(res?.data[0]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!token || !params?.id || refresh === 0) return;

    const getActionPlanData = async () => {
      setLoading(true);
      try {
        const res = await API.get(
          `/api/v1/actionplan/actionplanWithRole/${params?.id}/`,
          {
            headers: { Authorization: token },
          }
        );
        console.log(res?.data);
        setActionPlanData(res?.data);
        getActionPlanBrief();
        setRefresh(0);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getActionPlanData();
  }, [token, params?.id, refresh]);
  return (
    <div>
      <Outlet
        context={[loading, params?.id, actionPlanData, costData, setRefresh]}
      />
    </div>
  );
};

export default IdActionPlan;
