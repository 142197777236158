import React, { useContext, useEffect, useState } from 'react';
import ListTypeDocs from './ListTypeDocs';
import ListConfidentiality from './ListConfidentiality';
import ListSystem from './ListSystem';
import ListTag from './listTag'
import { PiCalendar, PiPlusBold } from 'react-icons/pi';
import API from '../../../../api/API';
import DateTimePicker from './DateTimePicker';
import { AuthContext } from '../../../../context/auth/AuthContext';

const TopForm = ({ title,setTitle,oldCode,setOldCode,version,setVersion,typeDocs,setTypeDocs,conf,setConf,system,setSystem,
    tagFinalSelected,setTagFinalSelected,tagSelected,setTagSelected,setStartDate,check,setCheck
 }) => {
    const {token}=useContext(AuthContext);

    // const [title, setTitle] = useState('');
    // const [oldCode, setOldCode] = useState('');
    // const [version, setVersion] = useState('');

    const [typeDocsList, setTypeDocsList] = useState([]);
    // const [typeDocs, setTypeDocs] = useState("");
    const [typeDocsSelected, setTypeDocsSelected] = useState(null);
    const [showListTypeDocs, setShowListTypeDocs] = useState(false);


    const [confList, setconfList] = useState([
        { val: 'internal', title: 'داخلی' }, { val: 'public', title: 'عمومی' }, { val: 'restricted', title: 'محدود' },
        { val: 'confidential', title: 'محرمانه' }, { val: 'secret', title: 'سری' },
    ]);
    // const [conf, setConf] = useState("");
    const [confSelected, setConfSelected] = useState({ val: 'internal', title: 'داخلی' });
    const [showListconf, setShowListconf] = useState(false);


    const [systemList, setSystemList] = useState([]);
    // const [system, setSystem] = useState("");
    const [systemSelected, setSystemSelected] = useState(null);
    const [showListSystem, setShowListSystem] = useState(false);

    const [showTag, setShowTag] = useState(false);
    // const [tagSelected, setTagSelected] = useState([]);
    // const [tagFinalSelected, setTagFinalSelected] =
    //     useState(tagSelected);

    useEffect(() => {
        if (!token) return
        const fetchType = async () => {
            try {
                const res = await API.get('/api/v1/deocument/type/', { headers: { Authorization: token } });
                console.log(res.data);
                setTypeDocsList(res.data);
            }
            catch (err) {
                console.log(err);
            }
        }
        fetchType();
    }, [])
    useEffect(() => {
        if (!token) return
        const fetchSystem = async () => {
            try {
                const res = await API.get('/api/v1/standard/managmentSystem', { headers: { Authorization: token } });
                setSystemList(res.data);
                console.log('system:', res.data);
            }
            catch (err) {
                console.log(err);
            }
        }
        fetchSystem();
    }, [])
    return (
        <div className='border-b border-caspian-gray2 pb-4'>
            <div className='flex flex-col gap-2 2xl:gap-4 px-2 2xl:px-5'>
                {/* top */}
                <div className='flex justify-between items-center gap-4'>
                    <div className='flex flex-col gap-1 2xl:gap-2 basis-1/2'>
                        {/* مدرک */}
                        <ListTypeDocs
                            lable="نوع مدرک"
                            typeDocsList={typeDocsList}
                            typeDocs={typeDocs}
                            setTypeDocs={setTypeDocs}
                            typeDocsSelected={typeDocsSelected}
                            setTypeDocsSelected={setTypeDocsSelected}
                            showListTypeDocs={showListTypeDocs}
                            setShowListTypeDocs={setShowListTypeDocs}
                            check={check}
                        />
                    </div>
                    <div className='flex flex-col gap-1 2xl:gap-2 basis-1/2'>
                    <div className='flex justify-between items-center'>
                            <span className='text-xs text-caspian-gray4'>عنوان</span>
                            {check && !title && <span className='text-xs text-caspian-error2'>این فیلد الزامی است.</span>}
                        </div>
                        <input
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder='عنوان این سند را وارد کنید'
                            className='text-xs placeholder:text-xs border border-caspian-gray2 p-2 rounded-lg'
                            type="text" />
                    </div>
                </div>

                <div className='flex justify-between items-center gap-4'>
                    <div className='flex flex-col gap-1 2xl:gap-2 basis-1/2'>
                        <span className='text-xs text-caspian-gray4'>کد قدیم سند</span>
                        <input
                            onChange={(e) => setOldCode(e.target.value)}
                            placeholder='کد این سند را وارد کنید'
                            className='text-xs placeholder:text-xs border border-caspian-gray2 p-2 rounded-lg'
                            type="text" />
                    </div>
                    <div className='flex flex-col gap-1 2xl:gap-2 basis-1/2'>
                        {/* مدرک */}
                        <ListConfidentiality
                            lable='سطح محرمانگی'
                            confList={confList}
                            conf={conf}
                            setConf={setConf}
                            confSelected={confSelected}
                            setConfSelected={setConfSelected}
                            showListconf={showListconf}
                            setShowListconf={setShowListconf}
                        />
                    </div>
                </div>

                <div className='flex justify-between items-end gap-4'>
                    <div className='flex flex-col gap-1 2xl:gap-2 basis-1/2'>
                        {/* management */}
                        <ListSystem
                            lable='سیستم مدیریتی مرتبط'
                            systemList={systemList}
                            system={system}
                            setSystem={setSystem}
                            systemSelected={systemSelected}
                            setSystemSelected={setSystemSelected}
                            showListSystem={showListSystem}
                            setShowListSystem={setShowListSystem}
                        />
                    </div>
                    <div className='flex justify-between items-start gap-1 basis-1/2'>
                        {/* tag and number */}
                        <div className='flex flex-col gap-1.5'>
                            <span className='text-xs text-caspian-gray4'>تاریخ</span>
                            <DateTimePicker setDate={setStartDate} />
                        </div>
                        <div className='flex flex-col gap-1'>
                            <span className='text-xs text-caspian-gray4 whitespace-nowrap'>شماره ویرایش</span>
                            <input type="number"
                                onChange={(e) => setVersion(e.target.value)}
                                defaultValue='0'
                                min='0'
                                className='border border-caspian-gray2 text-sm text-center rounded-md py-1.5 w-[60px] 2xl:w-[100px]'
                            />
                        </div>
                        <div className='flex flex-col gap-1 w-full '>
                            <span className='text-xs text-caspian-gray4'>انتخاب تگ</span>
                            <div
                                onClick={() => setShowTag(true)}
                                className='pointer-events-none cursor-pointer border border-[#3C77A2] rounded-md py-2 p-3 '>
                                <div className='flex justify-between items-center'>
                                    <p className='text-[#3C77A2] flex items-center 2xl:gap-1 text-xs'>
                                        <PiPlusBold />
                                        <span>انتخاب</span>
                                    </p>
                                    {tagFinalSelected?.length > 0 ? (
                                        <span className="text-xs whitespace-nowrap">
                                            {tagFinalSelected?.length} تگ افزوده شد
                                        </span>
                                    ) : (
                                        <span className="text-xs whitespace-nowrap">تگی انتخاب نشده</span>
                                    )}
                                </div>

                            </div>
                            {showTag && (
                                <ListTag
                                    finalSelected={tagFinalSelected}
                                    setFinalSelected={setTagFinalSelected}
                                    selected={tagSelected}
                                    setSelected={setTagSelected}
                                    setShowTag={setShowTag}
                                    showTag={showTag}
                                    title="لیست تگ ها"
                                    url={''}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopForm;
