import { useContext, useEffect, useState } from "react";
import TableListActionplan from "./TableListActionplan";
import { AuthContext } from "../../../../context/auth/AuthContext";
import API from "../../../../api/API";

const MainListActionplan = ({ refreshPmoList, setRefreshPmoList }) => {
  const { token } = useContext(AuthContext);
  const [fullData, setfullData] = useState([-1]);

  useEffect(() => {
    if (!token && refreshPmoList !== 1) return;
    let newUrl = "/api/v1/actionplan/actionplanWithRole/";
    const getPmo = async () => {
      await API.get(newUrl, {
        headers: { Authorization: token },
      })
        .then((res) => {
          setfullData(res.data);
          console.log(res.data);
          setRefreshPmoList(0);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getPmo();
  }, [token, refreshPmoList, setRefreshPmoList]);

  useEffect(() => {
    setRefreshPmoList(1);
  }, [setRefreshPmoList]);

  return (
    <TableListActionplan
      listActionplan={fullData}
      setRefreshPmoList={setRefreshPmoList}
    />
  );
};

export default MainListActionplan;
