import React, { useEffect, useState } from "react";
import { PiCirclesFour, PiRows } from "react-icons/pi";
import { useSearchParams } from "react-router-dom";

const ViewButton = ({ setTilesView, setListView, setShowDetailsTiles }) => {
  const [activeListButton, setActiveListButton] = useState(false);
  const [activeTilesButton, setActiveTilesButton] = useState(true);
  const [serachParams, setSearchParams] = useSearchParams();

  const view = serachParams.get("view");
  const tilesHandler = () => {
    setActiveListButton(false);
    setListView(false);
    setActiveTilesButton(true);
    setTilesView(true);
    handleFilterChange("view", "");
  };
  const listHandler = () => {
    setActiveListButton(true);
    setListView(true);
    setActiveTilesButton(false);
    setTilesView(false);
    setShowDetailsTiles(false);
    handleFilterChange("view", "list");
  };

  useEffect(() => {
    if (view) {
      setActiveListButton(true);
      setActiveTilesButton(false);
    }
  }, [view]);

  function handleFilterChange(key, value) {
    setSearchParams((prevParams) => {
      if (value === null || value === "") {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    });
  }
  return (
    <div className="flex items-center gap-0 rounded-md outline outline-1 outline-caspian-secondary-900 text-caspian-secondary-900">
      <button
        onClick={tilesHandler}
        className={`${
          activeTilesButton
            ? "bg-caspian-secondary-900 text-caspian-surface"
            : "text-caspian-secondary-900"
        } rounded-md p-1`}
      >
        <PiCirclesFour className="text-xl" />
      </button>
      <button
        onClick={listHandler}
        className={`${
          activeListButton
            ? "bg-caspian-secondary-900 text-caspian-surface"
            : "text-caspian-secondary-900"
        } rounded-md p-1`}
      >
        <PiRows className="text-xl" />
      </button>
    </div>
  );
};

export default ViewButton;
