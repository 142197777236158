import React, { useContext, useEffect, useState } from "react";
import {
  PiCaretDownBold,
  PiMagnifyingGlassBold,
  PiXBold,
} from "react-icons/pi";
import API from "../../../../../../api/API";
import { AuthContext } from "../../../../../../context/auth/AuthContext";

const InputFormOld = ({
  setShowInputForm,
  setRefreshInput,
  generalIdNumber,
}) => {
  const { token } = useContext(AuthContext);
  const [showInternalDocuments, setShowInternalDocuments] = useState(false);
  const [showExternalDocuments, setShowExternalDocuments] = useState(false);
  const [showStandard, setShowStandard] = useState(false);
  const [referenceSelected, setReferenceSelected] = useState("Standard");

  const [contentType, setContentType] = useState(null);
  const [description, setDescription] = useState("");

  // مستندات درون سازمانی
  const [internalDocumentsData, setInternalDocumentsData] = useState([]);
  let urlInternalDocuments = "/api/v1/deocument/internal/list";
  const getInternalDocuments = () => {
    API.get(urlInternalDocuments, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        setInternalDocumentsData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [searchByTitle, setSearchByTitle] = useState("");
  const [internalDocumentsId, setInternalDocumentsId] = useState(null);
  let searchTitle = [];
  if (internalDocumentsData !== -1) {
    searchTitle = searchByTitle
      ? internalDocumentsData.filter(
          (d) =>
            d?.title !== undefined &&
            d?.title
              ?.toLocaleLowerCase()
              ?.includes(searchByTitle?.toLocaleLowerCase())
        )
      : internalDocumentsData;
  }

  // مستندات برون سازمانی
  const [externalDocumentsData, setExternalDocumentsData] = useState([]);
  let urlExternalDocuments = "/api/v1/deocument/external/list";
  const getExternalDocuments = () => {
    API.get(urlExternalDocuments, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        setExternalDocumentsData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [searchByTitleEx, setSearchByTitleEx] = useState("");
  const [externalDocumentsId, setExternalDocumentsId] = useState(null);
  let searchTitleEx = [];
  if (externalDocumentsData !== -1) {
    searchTitleEx = searchByTitleEx
      ? externalDocumentsData?.filter(
          (d) =>
            d?.title !== undefined &&
            d?.title
              ?.toLocaleLowerCase()
              ?.includes(searchByTitleEx?.toLocaleLowerCase())
        )
      : externalDocumentsData;
  }

  // استاندارد ها
  const [standardData, setStandardData] = useState([]);
  let urlStandard = "/api/v1/standard/";
  const getStandard = () => {
    API.get(urlStandard, {
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        setStandardData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [searchByTitleStandard, setSearchByTitleStandard] = useState("");
  const [standardId, setStandardId] = useState(null);
  let searchTitleMs = [];
  if (standardData !== -1) {
    searchTitleMs = searchByTitleStandard
      ? standardData?.filter(
          (d) =>
            d?.standardTitlePersian !== undefined &&
            d?.standardTitlePersian
              ?.toLocaleLowerCase()
              ?.includes(searchByTitleStandard?.toLocaleLowerCase())
        )
      : standardData;
  }

  const [otherRefrenceData, setOtherRefrenceData] = useState(null);
  useState(null);

  useEffect(() => {
    setContentType(
      referenceSelected === "Standard"
        ? "standard_app.standard"
        : referenceSelected === "ExternalDocuments"
        ? "document_app.externaldocument"
        : referenceSelected === "InternalDocumentProfile"
        ? "document_app.internaldocumentprofile"
        : null
    );
  }, [referenceSelected]);

  useEffect(() => {
    if (referenceSelected !== "other") {
      setOtherRefrenceData(null);
    }
    if (referenceSelected !== "Standard") {
      setShowStandard(false);
    }
    if (referenceSelected !== "ExternalDocuments") {
      setShowExternalDocuments(false);
    }
    if (referenceSelected !== "InternalDocumentProfile") {
      setShowInternalDocuments(false);
    }
  }, [referenceSelected]);

  useEffect(() => {
    if (referenceSelected !== "other") {
      setOtherRefrenceData(null);
    }
    if (referenceSelected !== "Standard") {
      setShowStandard(false);
      setStandardId(null);
    }
    if (referenceSelected !== "ExternalDocuments") {
      setShowExternalDocuments(false);
      setExternalDocumentsId(null);
    }
    if (referenceSelected !== "InternalDocumentProfile") {
      setShowInternalDocuments(false);
      setInternalDocumentsId(null);
    }
  }, [referenceSelected]);

  const dataHandler = (e) => {
    e.preventDefault();
    if (
      description.length < 1 ||
      (referenceSelected === "other" && otherRefrenceData === null) ||
      (referenceSelected === "Standard" && standardId === null) ||
      (referenceSelected === "ExternalDocuments" &&
        externalDocumentsId === null) ||
      (referenceSelected === "InternalDocumentProfile" &&
        internalDocumentsId === null)
    )
      return null;
    API.post(
      "/api/v1/meeting/profile/input/",
      {
        title:
          referenceSelected === "Standard"
            ? standardId?.title
            : referenceSelected === "ExternalDocuments"
            ? externalDocumentsId?.title
            : referenceSelected === "InternalDocumentProfile"
            ? internalDocumentsId?.title
            : referenceSelected === "other" && otherRefrenceData,
        content_type:
          referenceSelected === "Standard"
            ? "standard_app.standard"
            : referenceSelected === "ExternalDocuments"
            ? "document_app.externaldocument"
            : referenceSelected === "InternalDocumentProfile"
            ? "document_app.internaldocumentprofile"
            : null,
        object_id:
          contentType === "standard_app.standard"
            ? standardId?.id
            : contentType === "document_app.externaldocument"
            ? externalDocumentsId?.id
            : contentType === "document_app.internaldocumentprofile"
            ? internalDocumentsId?.id
            : null,
        inputMeetingProfileRelated: generalIdNumber,
        description: description,
      },
      { headers: { Authorization: token } }
    )
      .then((res) => {
        setRefreshInput(1);
        setShowInputForm(false);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full z-50">
      <div className="bg-caspian-surface rounded-md shadow-[0_0_5px_#00000050] p-3 w-[400px] flex flex-col gap-3">
        <div className="flex items-center justify-between">
          <h2 className="text-sm font-bold">افزودن ورودی</h2>
          <button onClick={() => setShowInputForm(false)}>
            <PiXBold className={"text-caspian-gray4 text-sm"} />
          </button>
        </div>

        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-1.5 w-full">
            <label className="text-xs text-caspian-gray">مرجع ورودی</label>
            <select
              onChange={(e) => setReferenceSelected(e.target.value)}
              name="reference"
              defaultValue={"Standard"}
              className="text-xs outline outline-1 outline-caspian-gray1 p-1.5 rounded-md bg-caspian-transparent cursor-pointer"
            >
              <option value={"Standard"}>استانداردها</option>
              <option value={"ExternalDocuments"}>
                الزامات قانونی و مقررات برون سازمانی
              </option>
              {/* <option value={"mosavabatDakheli"}>مصوبات داخلی</option> */}
              <option value={"InternalDocumentProfile"}>
                مستندات درون سازمانی
              </option>
              <option value={"other"}>سایر</option>
            </select>
          </div>

          {/* اینپوت سایر */}
          {referenceSelected === "other" && (
            <div className="flex flex-col gap-1.5 w-full">
              <label className="text-xs text-caspian-gray">توضیحات</label>
              <input
                onChange={(e) => setOtherRefrenceData(e.target.value)}
                name="referenceDescription"
                type="text"
                placeholder="وارد کنید"
                className="outline-none outline-caspian-gray1 p-1.5 text-xs rounded-md outline-1"
              />
            </div>
          )}

          {/* لیست مدارک درون سازمانی */}
          {referenceSelected === "InternalDocumentProfile" && (
            <div className="flex flex-col gap-1.5 w-full relative">
              <label className="text-xs text-caspian-gray">
                لیست مستندات درون سازمانی
              </label>
              <div
                onClick={() => {
                  setShowInternalDocuments(!showInternalDocuments);
                  getInternalDocuments();
                }}
                className="text-xs outline outline-1 outline-caspian-gray1 p-[8px] rounded-md bg-caspian-transparent cursor-pointer flex items-center justify-between z-50"
              >
                {internalDocumentsId !== null ? (
                  <span className="text-xs">{internalDocumentsId.title}</span>
                ) : (
                  <span className="text-caspian-gray1 text-xs">
                    انتخاب کنید
                  </span>
                )}
                <PiCaretDownBold className="text-caspian-gray" />
              </div>
              {showInternalDocuments && (
                <div className="rounded-md shadow-[0_0_5px_#00000050] w-full flex flex-col gap-3 py-3 absolute left-0 right-0 bottom-[30px] bg-caspian-surface z-50">
                  <div className="relative w-[92%] mx-auto">
                    <input
                      type="search"
                      onChange={(e) => setSearchByTitle(e.target.value)}
                      className="outline-none outline-caspian-gray1 p-1 text-xs rounded-md outline-1 w-full placeholder:text-xs placeholder:text-caspian-gray pr-6"
                      placeholder="جستجو"
                    />
                    <button className="absolute top-1/2 -translate-y-1/2 right-1">
                      <PiMagnifyingGlassBold className="text-caspian-gray text-" />
                    </button>
                  </div>
                  <div className="flex flex-col gap-2 w-full max-h-[160px] overflow-y-scroll">
                    {searchTitle !== -1 &&
                      searchTitle?.map((d, i) => (
                        <span
                          key={i}
                          onClick={() => {
                            setShowInternalDocuments(false);
                            setInternalDocumentsId({
                              id: d.id,
                              title: d.title,
                            });
                          }}
                          className="text-xs hover:bg-caspian-secondary-100 py-1 px-4 w-full cursor-pointer duration-300"
                        >
                          {d.title}
                        </span>
                      ))}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* لیست مدارک برون سازمانی */}
          {referenceSelected === "ExternalDocuments" && (
            <div className="flex flex-col gap-1.5 w-full relative">
              <label className="text-xs text-caspian-gray">
                لیست الزامات قانونی
              </label>
              <div
                onClick={() => {
                  setShowExternalDocuments(!showExternalDocuments);
                  getExternalDocuments();
                }}
                className="text-xs outline outline-1 outline-caspian-gray1 p-[8px] rounded-md bg-caspian-transparent cursor-pointer flex items-center justify-between z-50"
              >
                {externalDocumentsId !== null ? (
                  <span className="text-xs">{externalDocumentsId?.title}</span>
                ) : (
                  <span className="text-caspian-gray1 text-xs">
                    انتخاب کنید
                  </span>
                )}
                <PiCaretDownBold className="text-caspian-gray" />
              </div>
              {showExternalDocuments && (
                <div className="rounded-md shadow-[0_0_5px_#00000050] w-full flex flex-col gap-3 py-3 absolute left-0 right-0 bottom-[30px] bg-caspian-surface z-50">
                  <div className="relative w-[92%] mx-auto">
                    <input
                      type="search"
                      onChange={(e) => setSearchByTitleEx(e.target.value)}
                      className="outline-none outline-caspian-gray1 p-1 text-xs rounded-md outline-1 w-full placeholder:text-xs placeholder:text-caspian-gray pr-6"
                      placeholder="جستجو"
                    />
                    <button className="absolute top-1/2 -translate-y-1/2 right-1">
                      <PiMagnifyingGlassBold className="text-caspian-gray text-" />
                    </button>
                  </div>
                  <div className="flex flex-col gap-2 w-full max-h-[160px] overflow-y-scroll">
                    {searchTitleEx !== -1 &&
                      searchTitleEx?.map((d, i) => (
                        <span
                          key={i}
                          onClick={() => {
                            setShowExternalDocuments(false);
                            setExternalDocumentsId({
                              id: d?.id,
                              title: d?.title,
                            });
                          }}
                          className="text-xs hover:bg-caspian-secondary-100 py-1 px-4 w-full cursor-pointer duration-300"
                        >
                          {d?.title}
                        </span>
                      ))}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* لیست استانداردها*/}
          {referenceSelected === "Standard" && (
            <div className="flex flex-col gap-1.5 w-full relative">
              <label className="text-xs text-caspian-gray">
                لیست استانداردها
              </label>
              <div
                onClick={() => {
                  setShowStandard(!showStandard);
                  getStandard();
                }}
                className="text-xs outline outline-1 outline-caspian-gray1 p-[8px] rounded-md bg-caspian-transparent cursor-pointer flex items-center justify-between z-50"
              >
                {standardId !== null ? (
                  <span className="text-xs">{standardId.title}</span>
                ) : (
                  <span className="text-caspian-gray1 text-xs">
                    انتخاب کنید
                  </span>
                )}
                <PiCaretDownBold className="text-caspian-gray" />
              </div>
              {showStandard && (
                <div className="rounded-md shadow-[0_0_5px_#00000050] w-full flex flex-col gap-3 py-3 absolute left-0 right-0 bottom-[30px] bg-caspian-surface z-50">
                  <div className="relative w-[92%] mx-auto">
                    <input
                      type="search"
                      onChange={(e) => setSearchByTitleStandard(e.target.value)}
                      className="outline-none outline-caspian-gray1 p-1 text-xs rounded-md outline-1 w-full placeholder:text-xs placeholder:text-caspian-gray pr-6"
                      placeholder="جستجو"
                    />
                    <button className="absolute top-1/2 -translate-y-1/2 right-1">
                      <PiMagnifyingGlassBold className="text-caspian-gray text-" />
                    </button>
                  </div>
                  <div className="flex flex-col gap-2 w-full max-h-[160px] overflow-y-scroll">
                    {searchTitleMs !== -1 &&
                      searchTitleMs?.map((d, i) => (
                        <span
                          key={i}
                          onClick={() => {
                            setShowStandard(false);
                            setStandardId({
                              id: d.id,
                              title: d.standardTitlePersian,
                            });
                            console.log({
                              id: d.id,
                              title: d.standardTitlePersian,
                            });
                          }}
                          className="text-xs hover:bg-caspian-secondary-100 py-1 px-4 w-full cursor-pointer duration-300"
                        >
                          {d.standardTitlePersian}
                        </span>
                      ))}
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="flex flex-col gap-1.5 w-full">
            <label className="text-xs text-caspian-gray">موضوع</label>
            <textarea
              onChange={(e) => setDescription(e.target.value)}
              rows={4}
              className="p-2 rounded-md outline-none outline-1 outline-caspian-gray2 text-xs"
            />
          </div>
        </div>
        <div className="flex items-center gap-2 mt-2">
          <button
            onClick={dataHandler}
            className="outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs py-1.5 px-6 rounded-md"
          >
            افزودن
          </button>
          <button
            onClick={() => setShowInputForm(false)}
            className="text-xs text-[#FF5B5B] mr-4"
          >
            لغو
          </button>
        </div>
      </div>
    </div>
  );
};

export default InputFormOld;
