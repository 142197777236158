import React, { useEffect, useState } from "react";
import MeetingNavbar from "../MeetingNavbar";
import { PiPlusCircle } from "react-icons/pi";
import { useAppContext } from "../../../context/app-context/AppContext";
import { Outlet, useLocation, useParams } from "react-router-dom";
import ProfileMeetingNavbar from "./ProfileMeetingNavbar";
import MainDetailsProfileMeeting from "./all-profiles/details-profile-meeting/MainDetailsProfileMeeting";
import MainCreateMeetingForm from "../forms/create-meeting/MainCreateMeetingForm";

const MainProfileMeeting = () => {
  const { setBradCrump1, setBradCrump2, setBradCrump3, setBrcLink2 } =
    useAppContext();
  const location = useLocation();
  const params = useParams();
  const [refresh, setRefresh] = useState(0);
  const [allProfileNumber, setAllProfileNumber] = useState({
    data: { idDraft: 0, public: 0 },
  });

  useEffect(() => {
    if (location.pathname.includes("all-profiles")) {
      setBradCrump1("مدیریت جلسات");
      setBradCrump2("پروفایل جلسات");
      !params.id && setBrcLink2("");
    // } else if (location.pathname.includes("my-profiles")) {
    //   setBradCrump1("مدیریت جلسات");
    //   setBradCrump2("پروفایل جلسات");
    //   setBradCrump3("پروفایل های من");
    } else if (location.pathname.includes("draft-profile")) {
      setBradCrump1("مدیریت جلسات");
      setBradCrump2("پروفایل جلسات");
      setBradCrump3("پیش نویس پروفایل");
    }
  }, [
    location,
    setBradCrump1,
    setBradCrump2,
    setBradCrump3,
    setBrcLink2,
    params,
  ]);

  const [showCreateMeetingForm, setShowCreateMeetingForm] = useState(false);
  return (
    <>
      <MeetingNavbar buttonActive="profile-meeting" />
      <div className="p-2 flex flex-col gap-2">
        {!params.id && (
          <div className="flex items-center justify-between gap-4">
            <button
              onClick={() => {
                setShowCreateMeetingForm(true);
              }}
              className="flex items-center gap-1 rounded-md bg-caspian-secondary-500 text-caspian-surface text-sm p-2"
            >
              <PiPlusCircle className="text-xl" />
              <span className="text-xs">ایجاد پروفایل</span>
            </button>
            {showCreateMeetingForm && (
              <MainCreateMeetingForm
                setRefresh={setRefresh}
                setShowCreateMeetingForm={setShowCreateMeetingForm}
                showCreateMeetingForm={showCreateMeetingForm}
              />
            )}
          </div>
        )}
        {params.id ? (
          <MainDetailsProfileMeeting />
        ) : (
          <div className="flex flex-col gap-2">
            <ProfileMeetingNavbar allProfileNumber={allProfileNumber} />
            <Outlet
              context={[
                refresh,
                setRefresh,
                allProfileNumber,
                setAllProfileNumber,
              ]}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default MainProfileMeeting;
