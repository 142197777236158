import React from "react";

const DescriptionDetails = ({ data }) => {
  return (
    <div className="p-4">
      <p className="text-sm text-justify">{data}</p>
    </div>
  );
};

export default DescriptionDetails;
