import { useContext } from "react";
import {
  PiBookmarkSimple,
  PiBookmarkSimpleFill,
  PiCloudArrowDown,
  PiPlayCircle,
} from "react-icons/pi";
import API from "../../../api/API";
import { AuthContext } from "../../../context/auth/AuthContext";
import { toast } from "react-toastify";

const TableSingle = ({
  id,
  title,
  abstract,
  imagesLibrary,
  created_at,
  setShowPopup,
  setShowPopupPodCast,
  filesLibrary,
  typeLibrary,
  setdataId,
  setShowDetailsTiles,
  setPopupData,
  setPopupPodCastData,
  language,
  profileFavorite,
  setFavorite,
  dataId
}) => {
  const topHandler = () => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const { token } = useContext(AuthContext);
  const favoriteHandler = async (idf) => {
    await API.patch(
      `/api/v1/library/favorite/${idf}/`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        setFavorite(1);
        setShowDetailsTiles(false);
        toast.info(
          `${
            title.length > 40 ? title.slice(0, 40) + "..." : title
          } به علاقه مندی ها اضافه شد`,
          {
            className: "toast-success-library",
            bodyClassName: "toast-success-library",
          }
        );
      } else if (res.status === 204) {
        setFavorite(1);
        setShowDetailsTiles(false);
        toast.info(
          `${
            title.length > 40 ? title.slice(0, 40) + "..." : title
          } از علاقه مندی ها حذف شد`,
          {
            className: "toast-faild-library",
            bodyClassName: "toast-faild-library",
          }
        );
      }
    });
  };
  return (
    <div
      onClick={() => {
        topHandler();
        setdataId(id);
      }}
      className={`outline ${dataId === id ? "outline-3 outline-caspian-secondary-500" : "outline-1 outline-caspian-gray2"}  p-2 rounded-xl hover:shadow-md hover:bg-caspian-gray2/20 duration-300`}
    >
      <div className="relative flex items-center justify-between gap-2 z-0">
        <div className="flex items-stretch gap-4 h-auto w-full cursor-pointer">
          {imagesLibrary !== undefined && imagesLibrary.length > 0 ? (
            <div className="relative min-w-[80px] h-[59px] rounded-xl overflow-hidden">
              <div className="flex items-center justify-center w-full h-full">
                <img
                  src={imagesLibrary[0].image}
                  alt={title}
                  className="w-full h-full"
                />
                {typeLibrary === "film" && (
                  <div className="w-1/3 absolute top-1/2 -translate-y-1/2 right-1/2 translate-x-1/2 z-20 cursor-pointer">
                    <img
                      onClick={() => {
                        setShowPopup(true);
                        setPopupData({
                          title,
                          file: filesLibrary[0]?.fileDocument,
                          picture: imagesLibrary[0].image,
                        });
                      }}
                      src="/assets/images/library/video/player.svg"
                      alt={title}
                      className="w-auto h-auto"
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="relative min-w-[80px] h-[59px] rounded-xl overflow-hidden">
              <div className="flex items-center justify-center w-full h-full">
                <img
                  src={`/assets/images/library/${
                    (typeLibrary === "book" && "icon_book.svg") ||
                    (typeLibrary === "standard" && "icon_iso.svg") ||
                    (typeLibrary === "article" && "icon_article.svg") ||
                    (typeLibrary === "podcast" && "icon_audio.svg")
                  }`}
                  alt={title}
                  className="w-[40px] h-auto"
                />
              </div>
            </div>
          )}
          <div className="flex flex-col justify-between z-50 w-full">
            {language === "EN" ? (
              <h2 className="text-sm font-bold line-clamp-2 text-left">
                {title}
              </h2>
            ) : (
              <h2 className="text-sm font-bold line-clamp-2 text-right">
                {title}
              </h2>
            )}
            <span className="text-xs text-caspian-gray">
              {created_at &&
                new Date(created_at).toLocaleDateString("fa-IR", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
            </span>
          </div>
        </div>
        <div className="flex items-center gap-2 ml-2">
          {profileFavorite ? (
            <PiBookmarkSimpleFill
              onClick={() => favoriteHandler(id)}
              className="cursor-pointer text-xl text-caspian-secondary-500"
            />
          ) : (
            <PiBookmarkSimple
              onClick={() => {
                favoriteHandler(id);
              }}
              className="cursor-pointer text-xl text-caspian-secondary-500"
            />
          )}
          {typeLibrary === "podcast" && (
            <button
              onClick={() => {
                setShowPopupPodCast(true);
                setPopupPodCastData({
                  title,
                  file: filesLibrary[0]?.fileDocument,
                });
              }}
            >
              <PiPlayCircle className="text-xl text-caspian-secondary-500" />
            </button>
          )}
          {typeLibrary === "film" && (
            <button
              onClick={() => {
                setShowPopup(true);
                setPopupData({
                  title,
                  file: filesLibrary[0]?.fileDocument,
                  picture: imagesLibrary[0]?.image,
                });
              }}
            >
              <PiPlayCircle className="text-xl text-caspian-secondary-500" />
              {/* {typeLibrary === "film" ? (
              <PiPlayCircle className="text-xl text-caspian-secondary-500" />
            ) : (
              <PiEye className="text-xl text-caspian-secondary-500" />
            )} */}
            </button>
          )}
          {filesLibrary !== undefined && filesLibrary.length > 0 && (
            <a
              download={filesLibrary[0].fileDocument}
              href={filesLibrary[0].fileDocument}
            >
              <PiCloudArrowDown className="text-xl text-caspian-secondary-500" />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default TableSingle;
