import React, { useContext, useEffect, useRef, useState } from "react";
import { PiDotsThreeCircle } from "react-icons/pi";
import { AuthContext } from "../../context/auth/AuthContext";

const MenuActionMain = ({
  setFavoritesShow,
  setShowChatBox,
  setFavoritesRefresh,
}) => {
  let menuRef = useRef();
  const { token } = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <div ref={menuRef} className="">
      <PiDotsThreeCircle
        onClick={() => setOpen(!open)}
        className="text-caspian-gray4 text-xl cursor-pointer"
      />
      <div
        className={
          open
            ? `absolute top-7 left-5 z-50 w-24 bg-caspian-surface/50 p-1 rounded-md flex items-center justify-center shadow-[0_0_5px_#00000050] backdrop-blur-sm rtlD`
            : "hidden"
        }
      >
        <ul className="flex flex-col z-50">
          <li
            onClick={() => {
              setOpen(false);
              setFavoritesRefresh(1);
              setFavoritesShow(true);
              setShowChatBox(false);
            }}
            className="text-[9px] cursor-pointer p-2 flex items-center gap-1"
          >
            <span className="text-[9px]">ذخیره شده ها</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MenuActionMain;
