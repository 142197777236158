import React, { useEffect, useState } from "react";
import ButtonTab from "./ButtonTab";
import MainStepOne from "./step-one/MainStepOne";
import MainStepTwo from "./step-two/MainStepTwo";

const CreateAuditForm = ({
  profileData,
  auditDate,
  auditSection,
  scheduleTimeId,
  setScheduleTimeId,
  setShowCreateAuditForm,
  setRefreshProfile,
  auditId,
}) => {
  const [activeTab, setActiveTab] = useState("StepOne");

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <div className="flex flex-col gap-4 px-4 h-full">
      <div className="flex items-center justify-between gap-3">
        <div className="flex items-center gap-2">
          <span className="text-xs text-caspian-gray">واحد:</span>
          <span className="text-xs">{auditSection?.title}</span>
        </div>

        <div className="flex items-center gap-3">
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <span className="text-xs text-caspian-gray">نوع:</span>
              <span className="text-xs">
                {profileData?.auditType === "period" ? "دوره ای" : "موردی"}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-xs text-caspian-gray">نوبت:</span>
              <span className="text-xs">{profileData?.title}</span>
            </div>
            {/* <div className="flex items-center gap-2">
              <span className="text-xs text-caspian-gray">سال:</span>
              <span className="text-xs">1403</span>
            </div> */}
          </div>
          <div className="flex items-center gap-2">
            <span className="text-xs text-caspian-gray">تاریخ ممیزی:</span>
            <span className="text-xs">{auditDate?.sDate}</span>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between w-full gap-2">
        <ButtonTab
          activeTab={activeTab}
          active="StepOne"
          onClick={() => setActiveTab("StepOne")}
          title="مرحله اول"
        />
        <div className="w-full h-0.5 bg-caspian-secondary-300"></div>
        <ButtonTab
          activeTab={activeTab}
          active="StepTwo"
          onClick={() => setActiveTab("StepTwo")}
          title="مرحله دوم"
        />
      </div>
      {/* مرحله اول  */}
      {activeTab === "StepOne" && (
        <MainStepOne
          msList={profileData?.auditProfileMSRelatedProfile}
          auditDate={auditDate}
          auditSection={auditSection}
          setActiveTab={setActiveTab}
          scheduleTimeId={scheduleTimeId}
          setScheduleTimeId={setScheduleTimeId}
          auditId={auditId}
          windowHeight={windowHeight}
        />
      )}
      {/* مرحله دوم */}
      {activeTab === "StepTwo" && (
        <MainStepTwo
          membersList={profileData?.auditorRelated?.map((user) => user)}
          scheduleTimeId={scheduleTimeId}
          setActiveTab={setActiveTab}
          setShowCreateAuditForm={setShowCreateAuditForm}
          setRefreshProfile={setRefreshProfile}
          windowHeight={windowHeight}
        />
      )}
    </div>
  );
};

export default CreateAuditForm;
