import "rc-time-picker/assets/index.css";
// import moment from "moment";
import TimePicker from "rc-time-picker";

const TimePickerReminder = ({ setTime }) => {
  const showSecond = false;
  const str = showSecond ? "HH:mm:ss" : "HH:mm";
  function onChange(value) {
    setTime(value && value.format(str));
  }

  return (
    <TimePicker
      style={{ width: "100%" }}
      showSecond={showSecond}
      // defaultValue={moment()}
      inputReadOnly={true}
      onChange={onChange}
      allowEmpty={false}
      placeholder="انتخاب کنید"
    />
  );
};

export default TimePickerReminder;
