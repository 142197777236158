import React from "react";
import SingleList from "./SingleList";

const MainPlanning = ({ taskData }) => {
  return (
    <div className="flex items-center py-10 w-full">
      {taskData?.length < 0 ? (
        <div className="flex items-center justify-center w-full">
          <span className="text-xs text-caspian-gray4">
            وظیفه‌ای وجود ندارد!
          </span>
        </div>
      ) : (
        <div className="p-0.5 max-h-[300px] overflow-y-scroll w-full">
          <table className="rounded-md overflow-hidden outline outline-1 outline-caspian-gray1 min-w-full">
            <thead className="table-fixed w-full text-xs text-caspian-gray text-right">
              <tr className="bg-caspian-gray3 w-full">
                <th className="p-2 w-1/4">عنوان</th>
                <th className="p-2 w-1/4">شروع</th>
                <th className="p-2 w-1/4">پایان</th>
                <th className="p-2 w-1/4">مجری</th>
              </tr>
            </thead>
            <tbody className="w-full text-xs text-caspian-gray4 text-right">
              {taskData?.map((d, i) => (
                <SingleList
                  key={i}
                  id={d.id}
                  title={d.title}
                  startDate={d.startDate}
                  endDate={d.finishDate}
                  supervisor={
                    d.responsible_task?.firstName +
                    " " +
                    d.responsible_task?.lastName
                  }
                />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default MainPlanning;
