import SearchList from './SearchList';

const StackholderTab = ({ data }) => {
    const bg1=data?.sectionRelated?.categoryRelated?.colorHex;
    const bg2=data?.sectionRelated?.colorHex;
    const bg3=data?.colorHex;
    return (
        <div className='border border-caspian-gray2 rounded-md bg-caspian-surface flex flex-col gap-2 h-[423px] pb-4 pt-1 pl-4 pr-2 xl:px-5 xl:pt-1'>
            <h2 className='text-center font-bold text-base 2xl:text-lg'>{data?.sectionRelated?.categoryRelated?.categoryType==='internal'?'درون سازمانی':'برون سازمانی'}</h2>
            <div className='relative h-[99%] text-sm 2xl:text-base'>
                <div className='absolute w-[85%] rounded-tr-md rounded-tl-md h-full'
                style={{backgroundColor:`#${bg1}`}}>
                    <p className='p-2 px-4 text-sm 2xl:text-base max-w-[98%] truncate text-caspian-surface font-bold'>
                        {data?.sectionRelated?.categoryRelated?.title}
                    </p>
                </div>
                <div className='absolute rounded-tr-md rounded-tl-md w-[90%] h-[88%] right-[10px] bottom-0'
                style={{backgroundColor:`#${bg2}`}}>
                    <p className='text-caspian-surface max-w-[98%] truncate text-sm 2xl:text-base py-2 px-4 font-bold relative '>
                        {data?.sectionRelated?.title}</p>
                </div>
                <div className='absolute rounded-tr-md rounded-tl-md  w-[95%] h-[77%] right-5 bottom-0 flex flex-col gap-2'
                style={{backgroundColor:`#${bg3}`}}>
                    <p className='pt-2 text-sm 2xl:text-base px-[10px] max-w-[98%] truncate text-[#4B5563] font-bold relative'>{data?.title}</p>
                    <div className='flex flex-col gap-4 w-[90%] rounded-md mr-[10px] mb-4 py-[10px] px-3 h-[80%]'
                        style={{backgroundColor:'#ffffff95'}}
                        >
                        <SearchList groupRelatedStackholder={data?.groupRelatedStackholder} />
                    </div>
                </div>
            </div>

        </div>
    );
}

export default StackholderTab;

