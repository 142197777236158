import React, { useContext, useEffect, useState } from "react";
import StepTwoForm from "./StepTwoForm";
import API from "../../../../../api/API";
import { AuthContext } from "../../../../../context/auth/AuthContext";

const MainStepTwo = ({
  setActiveTab,
  membersList,
  scheduleTimeId,
  setShowCreateAuditForm,
  setRefreshProfile,
  windowHeight,
}) => {
  const { token } = useContext(AuthContext);
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [supervisorSelected, setSupervisorSelected] = useState(null);
  const [membersSelected, setMembersSelected] = useState([]);
  const [membersFinalSelected, setMembersFinalSelected] =
    useState(membersSelected);
  const [membersExpertSelected, setMembersExpertSelected] = useState([]);
  const [membersExpertFinalSelected, setMembersExpertFinalSelected] = useState(
    membersExpertSelected
  );
  const [membersObserverSelected, setMembersObserverSelected] = useState([]);
  const [membersObserverFinalSelected, setMembersObserverFinalSelected] =
    useState(membersObserverSelected);
  const [processSelected, setProcessSelected] = useState(null);
  const [activitiesSelected, setActivitiesSelected] = useState([]);
  const [activitiesFinalSelected, setActivitiesFinalSelected] =
    useState(activitiesSelected);
  const [processSchedulesList, setProcessSchedulesList] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const submitHandler = async () => {
    if (!scheduleTimeId) return;
    let formData = {
      address: address,
      describtion: description,
      startTime: startTime,
      endTime: endTime,
      responsibleTeam: supervisorSelected?.id,
      teamRelated: membersFinalSelected,
      auditScheduleRelated: scheduleTimeId,
      expert: membersExpertFinalSelected,
      observer: membersObserverFinalSelected,
    };
    if (!address) return;
    console.log(formData);
    try {
      const res = await API.post(
        `/api/v1/audit/profile/schedule/responsible/`,
        formData,
        {
          headers: { Authorization: token },
        }
      );
      console.log(res?.data);
      setShowCreateAuditForm(false);
      setRefreshProfile(1);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    !address ||
    // processSchedulesList?.length < 1 ||
    !startTime ||
    !endTime ||
    // !description ||
    membersFinalSelected?.length < 1 ||
    !supervisorSelected
      ? setDisabled(true)
      : setDisabled(false);
  }, [
    address,
    // processSchedulesList,
    startTime,
    endTime,
    // description,
    membersFinalSelected,
    supervisorSelected,
  ]);
  return (
    <div
      className={`w-full flex flex-col justify-between gap-2 pt-4 ${
        windowHeight > 700
          ? "min-h-[50vh] xl:min-h-[55vh] 2xl:min-h-[60vh]"
          : "min-h-[70vh] xl:min-h-[70vh] 2xl:min-h-[70vh]"
      }`}
    >
      <div className="flex flex-col gap-2 w-full h-full">
        <StepTwoForm
          scheduleTimeId={scheduleTimeId}
          membersList={membersList}
          address={address}
          setAddress={setAddress}
          description={description}
          setDescription={setDescription}
          startTime={startTime}
          setStartTime={setStartTime}
          endTime={endTime}
          setEndTime={setEndTime}
          supervisorSelected={supervisorSelected}
          setSupervisorSelected={setSupervisorSelected}
          membersSelected={membersSelected}
          setMembersSelected={setMembersSelected}
          membersFinalSelected={membersFinalSelected}
          setMembersFinalSelected={setMembersFinalSelected}
          membersExpertSelected={membersExpertSelected}
          setMembersExpertSelected={setMembersExpertSelected}
          membersExpertFinalSelected={membersExpertFinalSelected}
          setMembersExpertFinalSelected={setMembersExpertFinalSelected}
          membersObserverSelected={membersObserverSelected}
          setMembersObserverSelected={setMembersObserverSelected}
          membersObserverFinalSelected={membersObserverFinalSelected}
          setMembersObserverFinalSelected={setMembersObserverFinalSelected}
          processSelected={processSelected}
          setProcessSelected={setProcessSelected}
          activitiesSelected={activitiesSelected}
          setActivitiesSelected={setActivitiesSelected}
          activitiesFinalSelected={activitiesFinalSelected}
          setActivitiesFinalSelected={setActivitiesFinalSelected}
          processSchedulesList={processSchedulesList}
          setProcessSchedulesList={setProcessSchedulesList}
          windowHeight={windowHeight}
        />
      </div>
      <div className="flex items-center gap-2">
        {/* <button
          onClick={async () => {
            await setActiveTab("StepOne");
          }}
          className="min-w-[6rem] outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs p-1.5 rounded-md"
        >
          مرحله قبل
        </button> */}
        <button
          disabled={disabled ? true : false}
          onClick={submitHandler}
          className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
        >
          ثبت و ارسال
        </button>
      </div>
    </div>
  );
};

export default MainStepTwo;
