import React, { useContext, useEffect, useState } from "react";
import TableListInternalDocuments from "./TableListInternalDocuments";
import API from "../../../../api/API";
import { AuthContext } from "../../../../context/auth/AuthContext";
import { useOutletContext } from "react-router-dom";

const MainInternalDocuments = () => {
  const { token } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [fullData, setFullData] = useState([-1]);
  const [fullDataList, setFullDataList] = useState([-1]);
  const [refresh, setRefresh] = useOutletContext()
  useEffect(() => {
    setRefresh(1)
  }, [setRefresh])
  useEffect(() => {
    console.log(refresh);
    if (!token || refresh === 0) return;
    const getDocList = async () => {
      setLoadingList(true);
      try {
        let url='/api/v1/deocument/internal/profile';
        let url2="/api/v3/deocument/list";
        const res = await API.get(url2, {
          headers: { Authorization: token },
        });
        setFullDataList(res.data);
        console.log(res.data);
        setRefresh(0);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingList(false);
      }
    };
    getDocList();
  }, [token, refresh, setRefresh]);

  let data = fullDataList.map((d) => ({
    id: d?.code,
    idProfile: d?.idProfile,
    title: d?.title,
    code: d?.code,
    codeOld: d?.internalDocumentRelated?.codeOld,
    type: d?.typeRelated,
    ownerParts: d?.ownerRelated,
    users: d?.usersRelated,
    fileDocument: "",
    tag: [],
  }));
  // let data = fullData.map((d) => ({
  //   id: d?.id,
  //   title: d?.title,
  //   code:
  //     d?.internalDocumentRelated?.InternalDocumentTypeRelated?.symbol +
  //     d?.internalDocumentRelated?.code,
  //   type: d?.internalDocumentRelated?.InternalDocumentTypeRelated?.title,
  //   ownerParts: d?.OwnerPartsRelated && d?.OwnerPartsRelated[0]?.title,
  //   users: d?.users && d?.users[0]?.title,
  //   fileDocument: d?.fileDocument,
  //   tag: d?.keyWords,
  // }));

  return <TableListInternalDocuments data={data} loadingList={loadingList} />;
};

export default MainInternalDocuments;
