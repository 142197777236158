import React, { useContext, useEffect, useState } from "react";
import SearchInput from "./SearchInput";
import FilterButton from "./FilterButton";
import ViewButton from "./ViewButton";
import Table from "./Table";
import TableTiles from "./TableTiles";
import DetailsTiles from "./DetailsTiles";
import { useAppContext } from "../../../context/app-context/AppContext";
import API from "../../../api/API";
import { useSearchParams } from "react-router-dom";
import Sort from "./Sort";
import { AuthContext } from "../../../context/auth/AuthContext";
import MainUploadFile from "../../upload-file/MainUploadFile";

const AllMainLibrary = () => {
  const [showFilter, setShowFilter] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const { setBradCrump1, setBradCrump2, setBradCrump3 } = useAppContext();
  const [serachParams] = useSearchParams();
  const searchFiletr = serachParams.get("keyword");
  const typeFilter = serachParams.get("type");
  const sortData = serachParams.get("sort");
  const view = serachParams.get("view");

  const tags = serachParams.get("tags");
  const langFa = serachParams.get("langFa");
  const langEn = serachParams.get("langEn");
  const startDate = serachParams.get("startDate");
  const endDate = serachParams.get("endDate");

  const { token } = useContext(AuthContext);
  const [favorite, setFavorite] = useState(0);

  const [type, setType] = useState('');
  const [typeList, setTypeList] = useState([{ title: 'کتاب', val: 'book' }, { title: 'مقاله', val: 'article' }, { title: 'استاندارد', val: 'standard' },
  { title: 'فیلم آموزشی', val: 'film' },
  { title: 'فایل صوتی', val: 'audio' },
  { title: 'سایر مطالب', val: 'content' },
  { title: 'علاقه مندی‌ها', val: 'favorit' }
  ]);
  const [typeSelected, setTypeSelected] = useState(null);

  let bradCrumpTr =
    typeFilter === "all"
      ? "همه"
      : typeFilter === "book"
        ? "کتاب"
        : typeFilter === "article"
          ? "مقالات"
          : typeFilter === "standard"
            ? "استانداردها"
            : typeFilter === "film"
              ? "فیلم آموزشی"
              : typeFilter === "podcast"
                ? "فایل صوتی"
                : typeFilter === "other"
                  ? "سایر مطالب"
                  : typeFilter === "favorites"
                    ? "علاقه مندی ها"
                    : "همه";

  useEffect(() => {
    setBradCrump1("مخزن دانش");
    setBradCrump2(typeFilter === "all" ? "همه" : bradCrumpTr);
    setBradCrump3("");
  }, [setBradCrump1, setBradCrump2, setBradCrump3, typeFilter, bradCrumpTr]);

  const [data, setData] = useState([-1]);
  const [dataTags, setDataTags] = useState([-1]);

  const [dataId, setdataId] = useState("");

  const [listView, setListView] = useState(false);
  const [tilesView, setTilesView] = useState(true);
  const [showDetailsTiles, setShowDetailsTiles] = useState(false);

  useEffect(() => {
    if (token) {
      API.get("api/v1/library", {
        headers: {
          Authorization: token,
        },
      }).then((d) => {
        setDataTags(d.data);
        let dataFilterByTags = [-1];
        let dataFilterByLang = [-1];
        let dataFilterByDate = [-1];

        dataFilterByTags = tags
          ? d.data.filter((tag) =>
            tag?.tags?.find((t) => t.includes(tags.replaceAll("_", " ")))
          )
          : d.data;

        dataFilterByLang =
          langFa && langEn
            ? dataFilterByTags
            : langFa
              ? (dataFilterByLang = dataFilterByTags.filter(
                (lang) => lang?.language === "FA"
              ))
              : langEn
                ? (dataFilterByLang = dataFilterByTags.filter(
                  (lang) => lang?.language === "EN"
                ))
                : dataFilterByTags;

        dataFilterByDate =
          startDate && endDate
            ? dataFilterByLang.filter(
              (d) =>
                new Date(d?.publicationDate).valueOf() >
                new Date(startDate).valueOf() &&
                new Date(d?.publicationDate).valueOf() <
                new Date(endDate).valueOf()
            )
            : startDate
              ? (dataFilterByDate = dataFilterByLang.filter(
                (d) =>
                  new Date(d?.publicationDate).valueOf() >
                  new Date(startDate).valueOf() &&
                  new Date(d?.publicationDate).valueOf() < new Date().valueOf()
              ))
              : endDate
                ? (dataFilterByDate = dataFilterByLang.filter(
                  (d) =>
                    new Date(d?.publicationDate).valueOf() >
                    new Date("2023-10-23").valueOf() &&
                    new Date(d?.publicationDate).valueOf() <
                    new Date(endDate).valueOf()
                ))
                : dataFilterByLang;

        setData(dataFilterByDate);
      });
    }
  }, [token, favorite]);

  // console.log(data);

  useEffect(() => {
    setData(data);
    setFavorite(0);
  }, [data, sortData, typeFilter]);

  useEffect(() => {
    if (sortData === "date") {
      setData(data?.sort((a, b) => (b?.id > a?.id ? 1 : -1)));
    } else if (sortData === "atoz") {
      setData(
        data.sort((a, b) =>
          a?.title?.toLocaleLowerCase() > b?.title?.toLocaleLowerCase() ? 1 : -1
        )
      );
    } else if (sortData === "view") {
      setData(data?.sort((a, b) => (b?.viewers > a?.viewers ? 1 : -1)));
    }
  }, [data, sortData]);

  useEffect(() => {
    if (view) {
      setListView(true);
      setTilesView(false);
    }
  }, [view]);

  const [showUploadAndForm, setShowUploadAndForm] = useState(false);

  return (
    <section className="flex flex-col gap-4 pb-4">
      {TableTiles && showDetailsTiles && (
        <DetailsTiles
          details={data}
          dataId={dataId}
          setShowDetailsTiles={setShowDetailsTiles}
        />
      )}
      <div className="flex items-center gap-2">
        <SearchInput setCurrentPage={setCurrentPage} />

        <MainUploadFile
          type={type}
          setType={setType}
          typeList={typeList}
          setTypeList={setTypeList}
          typeSelected={typeSelected}
          setTypeSelected={setTypeSelected}
        />
      </div>
      {/* {showUploadAndForm && <MainUploadFile />} */}
      <div className="flex items-center gap-4">
        <FilterButton
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          setFavorite={setFavorite}
          data={dataTags}
          favorite={favorite}
          setCurrentPage={setCurrentPage}
        />

        <Sort
          data={data}
          setData={setData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />

        <ViewButton
          setShowDetailsTiles={setShowDetailsTiles}
          setTilesView={setTilesView}
          setListView={setListView}
        />
      </div>
      <div>
        {listView && (
          <Table
            typeFilter={typeFilter}
            searchFiletr={searchFiletr}
            data={data}
            dataId={dataId}
            setdataId={setdataId}
            setShowDetailsTiles={setShowDetailsTiles}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setFavorite={setFavorite}
            favorite={favorite}
            showFilter={showFilter}
          />
        )}
        {tilesView && (
          <div className="flex items-start justify-between gap-2">
            <TableTiles
              typeFilter={typeFilter}
              searchFiletr={searchFiletr}
              data={data}
              setdataId={setdataId}
              setShowDetailsTiles={setShowDetailsTiles}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setFavorite={setFavorite}
              favorite={favorite}
              showFilter={showFilter}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default AllMainLibrary;
