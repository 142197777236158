import React, { useContext, useEffect, useRef, useState } from 'react'
import Modal from '../../../../../../modal/Modal'
import { PiX } from 'react-icons/pi';
import { AuthContext } from '../../../../../../../context/auth/AuthContext';
import API from '../../../../../../../api/API';
import { useParams } from 'react-router-dom';


const ModalUploadFileMaterial = ({ setShowModalUploadFile, setListDocMaterial, reportId, setRefreshList }) => {

    const { token } = useContext(AuthContext);
    const params = useParams()

    const titleMaterialRef = useRef();
    const numberMaterialRef = useRef();

    const [emptyNumber, setEmptyNumber] = useState(false)
    const [emptyTitle, setEmptyTitle] = useState(false)
    const [numberMaterial, setNumberMaterial] = useState(null)
    const [idMaterial, setIdMaterial] = useState(null)
    const [listMaterials, setListMaterials] = useState([])


    const handleDocument = async () => {
        if (!token || !reportId) return;

        let hasError = false;
        if (!numberMaterial) {
            setEmptyNumber(true);
            hasError = true;
        }

        if (!idMaterial) {
            setEmptyTitle(true);
            hasError = true;
        }
        if (hasError) return;

        let formData = {
            reportProfileRelated: reportId,
            used_amount: numberMaterial,
            materialRelated: idMaterial
        }

        try {
            // console.log(formData)
            const res = await API.post('/api/v1/actionplan/report_material/', formData,
                {
                    headers: { Authorization: token }
                }
            )
            console.log(res?.data)
            setShowModalUploadFile(false)
            setRefreshList(1)


        } catch (error) {
            console.log(error)
        }

    }


    // لیست اقلام مصرفی ها
    useEffect(() => {
        if (!token || !params?.id) return;
        const handleData = async () => {
            try {
                const res = await API.get(`/api/v1/actionplan/tasksWithRole/${params?.id}`,
                    { headers: { Authorization: token } })

                console.log(res?.data)
                setListMaterials(res?.data)

            } catch (error) {
                console.log(error)
            }
        }
        handleData()
    }, [token, params?.id, setListDocMaterial])



    const clearData = () => {
        setShowModalUploadFile(false)
        setIdMaterial()
        setNumberMaterial("")
    }

    return (
        <Modal>
            <div className='flex flex-col items-center w-[400px] md:w-[480px] px-5 bg-caspian-surface rounded-lg'>
                <div className='w-full flex items-center justify-between border-b-2 py-5 border-b-caspian-gray2'>
                    <span className='text-xs 2xl:text-base'>افزودن قلم مصرفی</span>

                    <PiX className='text-xl cursor-pointer text-caspian-gray4'
                        onClick={clearData} />
                </div>

                <div className='w-full flex flex-col items-center py-5 gap-10'>
                    <div className='w-full flex items-center gap-5 justify-between'>
                        <div className='w-3/4 flex flex-col gap-3 justify-start'>
                            <div className='w-full flex items-center gap-5'>

                                <span className='text-xs '>انتخاب نوع قلم مصرفی</span>
                                {emptyTitle &&
                                    <span className='text-xs text-caspian-error2'>فیلد الزامی *</span>
                                }
                            </div>

                            <select
                                className='text-xs  border-2 border-caspian-gray2 bg-caspian-gray3 outline-none py-2 px-3 cursor-pointer rounded-md w-full '
                                ref={titleMaterialRef} onChange={(e) => setIdMaterial(Number(e.target.value))}>

                                <option>انتخاب کنید</option>

                                {listMaterials?.resources?.map((resource, index) => (
                                    <option key={index} value={resource?.id}>
                                        {resource?.name}
                                    </option>
                                ))}

                            </select>
                        </div>
                        <div className='w-1/4 flex flex-col gap-3 justify-start'>
                            <div className='w-full flex items-center gap-5'>
                                <span className='text-xs  text-caspian-gray4'>
                                    واحد
                                </span>
                            </div>

                            <div className='text-xs flex items-center justify-center bg-caspian-gray2 rounded-md outline-none h-9 px-2' >
                                {listMaterials?.resources?.map((resource, index) => (
                                    idMaterial === resource?.id &&
                                    <span key={index}>{resource?.unit}</span>
                                ))}
                            </div>

                        </div>
                    </div>

                    <div className='w-full flex flex-col gap-3 justify-start'>
                        <div className='w-full flex items-center gap-5'>
                            <span className='text-xs  text-caspian-gray4'>
                                میزان مصرف در این مرحله
                            </span>

                            {emptyNumber &&
                                <span className='text-xs text-caspian-error2'>فیلد الزامی *</span>
                            }
                        </div>
                        <input type='text' id='number' placeholder='مقدار قلم مصرفی را به رقم وارد کنید'
                            onChange={(e) => setNumberMaterial(Number(e.target.value))} ref={numberMaterialRef}
                            className='text-xs font-semibold placeholder:font-normal border-2 border-caspian-gray2 rounded-md outline-none p-2' />
                    </div>


                </div>

                <div className='flex items-center justify-start w-full pb-5'>
                    <button
                        onClick={(handleDocument)}
                        className='text-xs 2xl:text-sm text-caspian-secondary-600 border border-caspian-secondary-600 rounded-md w-[70px] h-[30px]'>
                        تائید
                    </button>

                    <button
                        onClick={clearData}
                        className='text-xs 2xl:text-sm text-caspian-error2  w-[70px] h-[30px]  '>
                        لغو
                    </button>
                </div>
            </div>
        </Modal >
    )
}

export default ModalUploadFileMaterial