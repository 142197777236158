import React, { useContext, useEffect, useState } from "react";
import { PiCircleNotch } from "react-icons/pi";
import API from "../../../../api/API";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../../../context/auth/AuthContext";
import RequestReceivedDetails from "./RequestReceivedDetails";
import RequestReplyDetails from "./RequestReplyDetails";

const NewDetails = () => {
  const { user, token } = useContext(AuthContext);
  const params = useParams();
  const navigate = useNavigate();

  const [serachParams, setSearchParams] = useSearchParams();
  const [successReply, setSuccessReply] = useState(0);

  const searchType = serachParams.get("search");

  const [fullData, setFullData] = useState([-1]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [showRequest, setShowRequest] = useState(false);

  useEffect(() => {
    if (!token || !params?.id) return;
    let url = `/api/v1/request/${params.id}/`;
    // let url = `/api/v1/request/activity/${params.id}/`
    const getRequest = async () => {
      setLoading(true);
      try {
        const res = await API.get(url, {
          headers: { Authorization: token },
        });
        console.log(res?.data);
        setFullData(res.data);
      } catch (error) {
        console.log(error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    getRequest();
  }, [token, params?.id, successReply]);

  useEffect(() => {
    if (searchType) {
      navigate(`/requests/received?search=${searchType}`);
    }
  }, [searchType, navigate]);
  return (
    <div className="w-2/3">
      {fullData[0] === -1 && !error ? (
        <div className="flex items-center justify-center py-24">
          <div className="flex items-center gap-2">
            <PiCircleNotch className="text-caspian-gray text-sm animate-spin" />
            <h2 className="text-caspian-gray text-sm">
              در حال بارگذاری اطلاعات
            </h2>
          </div>
        </div>
      ) : user?.placementProfileRelated?.id !==
          fullData?.firstActivityRelated?.receiver?.id || error ? (
        <div className="flex items-center justify-center py-24">
          <h2 className="text-sm text-caspian-gray">گزارشی یافت نشد...</h2>
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          {/* شروع گزارش دریافتی */}
          <RequestReceivedDetails
            fullData={fullData}
            setSuccessReply={setSuccessReply}
          />
          {/* پایان گزارش دریافتی */}

          {/* شروع پاسخ به گزارش */}
          {fullData?.firstActivityRelated?.status === "done" && (
            <RequestReplyDetails
              fullData={fullData}
              setSuccessReply={setSuccessReply}
            />
          )}
          {/* پایان پاسخ به گزارش */}
        </div>
      )}
    </div>
  );
};

export default NewDetails;
