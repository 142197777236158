import React, { useContext, useEffect, useState } from "react";
import { PiPlusBold } from "react-icons/pi";
import AddGuestForm from "./add-guest/AddGuestForm";
import SingleListGuest from "./add-guest/SingleListGuest";
import { toast } from "react-toastify";
import API from "../../../../../api/API";
import { AuthContext } from "../../../../../context/auth/AuthContext";

const AddGuest = ({
  setActiveTab,
  setShowEditPlanningMeetingForm,
  thisId,
  fullData,
  setRefresh,
  setMeetingListRefresh,
}) => {
  const { token } = useContext(AuthContext);

  const [showAddGuestForm, setShowAddGuestForm] = useState(false);
  const [data, setData] = useState([]);
  const [disableButton, setDisableButton] = useState(false);

  const dataHandler = async () => {
    console.log(data);
  };

  console.log(fullData);

  const dataHandlerDraft = async () => {
    // e.preventDefault();
    await API.post(
      `/api/v1/meeting/info/detail/${thisId}/status/`,
      {
        status: "guest",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await dataHandler();
        await toast.success("برنامه ریزی جلسه به صورت پیشنویس ثبت شد.", {
          className: "toast-success",
          bodyClassName: "toast-success",
        });
        await setShowEditPlanningMeetingForm(false);
        await setMeetingListRefresh(1);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataHandlerNext = async () => {
    // e.preventDefault();
    await API.post(
      `/api/v1/meeting/info/detail/${thisId}/status/`,
      {
        status: "date",
      },
      { headers: { Authorization: token } }
    )
      .then(async (res) => {
        await dataHandler();
        await setActiveTab("timeAndPlace");
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dataHandlerBack = async () => {
    await setActiveTab("titleAndDescription");
  };

  useEffect(() => {
    if (fullData.length < 1) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [fullData]);
  return (
    <div className="min-h-[270px] flex flex-col justify-between">
      <div className="flex flex-col gap-3">
        <button
          onClick={() => setShowAddGuestForm(true)}
          className="flex items-center justify-between w-full border-y border-y-caspian-secondary-500 text-caspian-secondary-500 py-2 cursor-pointer hover:bg-caspian-secondary-50/20"
        >
          <span className="text-xs">افزودن مهمان به جلسه</span>
          <PiPlusBold className="text-sm" />
        </button>
        {showAddGuestForm && (
          <AddGuestForm
            setRefresh={setRefresh}
            thisId={thisId}
            data={data}
            setData={setData}
            setShowAddGuestForm={setShowAddGuestForm}
          />
        )}
        <div className="p-0.5 max-h-[180px] overflow-y-scroll">
          {fullData?.length > 0 ? (
            <table className="rounded-md overflow-hidden outline outline-1 outline-caspian-gray1 min-w-full">
              <thead className="table-fixed w-full text-xs text-caspian-gray text-right">
                <tr className="bg-caspian-gray3 w-full">
                  <th className="p-2">نام و نام خانوادگی</th>
                  <th className="p-2">سمت</th>
                  <th className="p-2">سازمان</th>
                  <th className="p-2 w-20"></th>
                </tr>
              </thead>
              <tbody className="w-full text-xs text-caspian-gray4 text-right">
                {fullData.map((d, i) => (
                  <SingleListGuest
                    setRefresh={setRefresh}
                    key={i}
                    id={d.id}
                    fullName={d.fullName}
                    post={d.position}
                    category={d.company}
                  />
                ))}
              </tbody>
            </table>
          ) : (
            <h2 className="text-xs text-caspian-gray1">
              مهمانی اضافه نشده است!
            </h2>
          )}
        </div>
      </div>
      <div className="flex items-center gap-2">
        <button
          onClick={async () => {
            await dataHandlerBack();
          }}
          className="min-w-[6rem] outline outline-1 outline-caspian-secondary-500 text-caspian-secondary-500 text-xs p-1.5 rounded-md"
        >
          مرحله قبل
        </button>
        <button
          // disabled={disableButton}
          onClick={async () => {
            await dataHandlerNext();
          }}
          className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
        >
          مرحله بعد
        </button>
        <button
          // disabled={disableButton}
          onClick={async () => {
            await dataHandlerDraft();
          }}
          className={`disabled:cursor-not-allowed disabled:text-caspian-gray text-caspian-secondary-500 text-xs p-1.5 rounded-md`}
        >
          ذخیره پیشنویس
        </button>
      </div>
    </div>
  );
};

export default AddGuest;
