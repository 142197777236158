import React, { useContext, useState } from "react";
import { AuthContext } from "../../../../../context/auth/AuthContext";
import API from "../../../../../api/API";
import Line from "../../../../ui/Line";
import Dropdown from "../../../../ui/Dropdown";
import BudgetSingle from "./BudgetSingle";
import ResponsibleSingle from "./ResponsibleSingle";
import { PiPencilSimpleLine } from "react-icons/pi";

const Request = ({ fullData, setActiveTab }) => {
  const { token } = useContext(AuthContext);
  const [isAccept, setIsAccept] = useState(false);

  const dataHandler = async () => {
    let formData = {
      ActionPlanSelected_id: fullData?.id,
      link: `/action-plan/my-actionplan/${fullData?.id}/profile`,
    };
    if (!fullData?.id) return;
    console.log(formData);
    try {
      const res = await API.post(
        `/api/v1/actionplan/actionplanactivity`,
        formData,
        {
          headers: { Authorization: token },
        }
      );
      console.log(res?.data);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="w-full flex flex-col gap-4">
      <button
        onClick={() => setActiveTab("planning")}
        className="w-fit flex items-center gap-1 rounded-md border border-caspian-secondary-500 text-caspian-secondary-500 text-xs p-2"
      >
        <PiPencilSimpleLine className="text-xl" />
        <span className="text-xs">ویرایش اطلاعات</span>
      </button>
      {/* عنوان */}
      <div className="flex flex-col gap-4 w-full">
        <div className="flex items-center gap-2 w-full">
          <span className="text-sm font-bold basis-[20%] text-caspian-primary-500">
            عنوان
          </span>
          <h2 className="text-sm font-bold basis-[80%]">{fullData?.title}</h2>
        </div>
        <div className="flex items-start gap-2 w-full">
          <span className="text-sm font-bold basis-[20%] text-caspian-primary-500">
            توضیحات
          </span>
          <p className="text-xs basis-[80%]">{fullData?.description}</p>
        </div>
        <div className="flex items-start gap-2 w-full">
          <span className="text-sm font-bold basis-[20%] text-caspian-primary-500">
            نتایج مورد انتظار
          </span>
          <p className="text-xs basis-[80%]">{fullData?.outputProject}</p>
        </div>
      </div>
      {/* هدف و فرایند */}
      <div className="flex flex-col gap-4 w-full">
        <div className="flex items-center justify-between gap-2 w-full">
          <span className="text-sm font-bold w-[30%] whitespace-nowrap">
            هدف و فرایند
          </span>
          <Line color="bg-caspian-secondary-100" />
        </div>
        <div className="flex items-start justify-between w-full gap-2">
          <Dropdown
            className={
              "border border-caspian-secondary-700 text-caspian-secondary-700 text-xs p-3 rounded-tl-xl"
            }
            title="اهداف مرتبط"
          >
            <div className="flex flex-col gap-3 p-2">
              {fullData?.goal?.map((p, i) => (
                <div key={i} className="flex items-center gap-1">
                  <div className="w-[4px] h-[4px] rounded-full bg-caspian-secondary-500"></div>
                  <p className="text-xs text-caspian-secondary-500 font-bold">
                    {p?.title}
                  </p>
                </div>
              ))}
            </div>
          </Dropdown>
          <Dropdown
            className={
              "border border-caspian-secondary-700 text-caspian-secondary-700 text-xs p-3 rounded-tl-xl"
            }
            title="فرانید‌های مرتبط"
          >
            <div className="flex flex-col gap-3 p-2">
              {fullData?.processes?.map((p, i) => (
                <div key={i} className="flex items-center gap-1">
                  <div className="w-[4px] h-[4px] rounded-full bg-caspian-secondary-500"></div>
                  <p className="text-xs text-caspian-secondary-500 font-bold">
                    {p?.title}
                  </p>
                </div>
              ))}
            </div>
          </Dropdown>
        </div>
      </div>
      {/* ذینفعان */}
      <div className="flex flex-col gap-4 w-full">
        <div className="flex items-center justify-between gap-2 w-full">
          <span className="text-sm font-bold w-[30%] whitespace-nowrap">
            ذینفعان
          </span>
          <Line color="bg-caspian-primary-100" />
        </div>
        <div className="flex items-start justify-between w-full gap-2">
          <Dropdown
            className={
              "border border-caspian-primary-700 text-caspian-primary-700 text-xs p-3 rounded-tl-xl"
            }
            title="ذینفعان تاثیرگذار"
          >
            <div className="flex flex-col gap-3 p-2">
              {fullData?.stackholders_profiles

                ?.filter((s) => s?.typeProject === "influencer")
                ?.map((s, i) => (
                  <div key={i} className="flex flex-col gap-1">
                    <div className="flex items-center gap-1">
                      <div className="w-[4px] h-[4px] rounded-full bg-caspian-primary-500"></div>
                      <span className="text-xs text-caspian-primary-500 font-bold">
                        {s?.StackHolderRelated?.title}
                      </span>
                    </div>
                    <p className="mr-2 text-xs text-caspian-gray4">
                      {s?.description}
                    </p>
                  </div>
                ))}
            </div>
          </Dropdown>
          <Dropdown
            className={
              "border border-caspian-primary-700 text-caspian-primary-700 text-xs p-3 rounded-tl-xl"
            }
            title="ذینفعان تاثیرپذیر"
          >
            <div className="flex flex-col gap-3 p-2">
              {fullData?.stackholders_profiles
                ?.filter((s) => s?.typeProject === "affected")
                ?.map((s, i) => (
                  <div key={i} className="flex flex-col gap-1">
                    <div className="flex items-center gap-1">
                      <div className="w-[4px] h-[4px] rounded-full bg-caspian-primary-500"></div>
                      <span className="text-xs text-caspian-primary-500 font-bold">
                        {s?.StackHolderRelated?.title}
                      </span>
                    </div>
                    <p className="mr-2 text-xs text-caspian-gray4">
                      {s?.description}
                    </p>
                  </div>
                ))}
            </div>
          </Dropdown>
        </div>
      </div>

      <div className="flex items-start w-full gap-2">
        {/* بودجه و چهارچوب زمانی */}
        <div className="flex flex-col gap-4 w-full">
          {/* بودجه */}
          <div className="flex flex-col gap-4 w-full">
            <div className="flex items-center justify-between gap-2 w-full">
              <span className="text-sm font-bold w-[30%] whitespace-nowrap">
                بودجه
              </span>
              <Line color="bg-caspian-gray2" />
            </div>
            <div className="grid grid-cols-2 gap-2">
              <BudgetSingle
                title="بودجه تخمینی PMO(ریال)"
                cost={fullData?.cost}
              />
              <BudgetSingle
                title="بودجه برنامه اجرایی"
                // cost={fullData?.cost}
                className="text-[#5E7D06] font-bold"
              />
              <BudgetSingle
                title="جمع بودجه وظایف(ریال)"
                cost={fullData?.actionplan_detail?.cost}
              />
              <BudgetSingle
                title="بودجه برای سایر موارد"
                // cost={fullData?.actionplan_detail?.cost}
              />
            </div>
          </div>
          {/* چهارچوب زمانی */}
          <div className="flex flex-col gap-4 w-full">
            <div className="flex items-center justify-between gap-2">
              <span className="text-sm font-bold w-[30%] whitespace-nowrap">
                چهارچوب زمانی
              </span>
              <Line color="bg-caspian-gray2" />
            </div>
            <div className="flex items-center justify-between gap-2">
              <span className="text-xs text-caspian-gray4">
                برنامه‌ریزی PMO
              </span>
              <div className="flex items-center gap-2">
                <div className="flex items-center gap-0 bg-caspian-gray2/40 rounded-lg border border-caspian-gray">
                  <span className="text-xs rounded-lg bg-caspian-gray3 p-2 border-l border-l-caspian-gray">
                    شروع
                  </span>
                  <span className="text-xs p-2">
                    {new Date(fullData?.startDate).toLocaleDateString("fa-IR")}
                  </span>
                </div>
                <div className="flex items-center gap-0 bg-caspian-gray2/40 rounded-lg border border-caspian-gray">
                  <span className="text-xs rounded-lg bg-caspian-gray3 p-2 border-l border-l-caspian-gray">
                    پایان
                  </span>
                  <span className="text-xs p-2">
                    {new Date(fullData?.endDate).toLocaleDateString("fa-IR")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* مجریان */}
        <div className="flex flex-col gap-4 w-full">
          <div className="flex items-center justify-between gap-2">
            <span className="text-sm font-bold w-[30%] whitespace-nowrap">
              مجریان
            </span>
            <Line color="bg-caspian-gray2" />
          </div>
          <Dropdown
            className={
              "border border-caspian-secondary-400 text-caspian-secondary-400 text-xs p-3 rounded-tl-xl"
            }
            title="نقش‌ها"
          >
            <div className="flex flex-col gap-2 p-2">
              <ResponsibleSingle
                title="مسئول برنامه اجرایی"
                fullName={
                  fullData?.manager?.firstName +
                  " " +
                  fullData?.manager?.lastName
                }
              />
              <ResponsibleSingle
                title="کنترل کننده برنامه"
                fullName={
                  fullData?.controller?.firstName +
                  " " +
                  fullData?.controller?.lastName
                }
              />
              <ResponsibleSingle
                title="تایید کننده"
                fullName={
                  fullData?.varify_placement?.placementProfileRelated
                    ?.firstName +
                  " " +
                  fullData?.varify_placement?.placementProfileRelated?.lastName
                }
              />
              <ResponsibleSingle
                title="تصویب کننده"
                fullName={
                  fullData?.approval_placement?.placementProfileRelated
                    ?.firstName +
                  " " +
                  fullData?.approval_placement?.placementProfileRelated
                    ?.lastName
                }
              />
              <ResponsibleSingle
                title="ناظر فنی"
                fullName={
                  fullData?.tech_observer?.placementProfileRelated?.firstName +
                  " " +
                  fullData?.tech_observer?.placementProfileRelated?.lastName
                }
              />
              <ResponsibleSingle
                title="ناظر حقوقی"
                fullName={
                  fullData?.legal_observer?.placementProfileRelated?.firstName +
                  " " +
                  fullData?.legal_observer?.placementProfileRelated?.lastName
                }
              />
              <ResponsibleSingle
                title="ناظر بودجه"
                fullName={
                  fullData?.budget_observer?.placementProfileRelated
                    ?.firstName +
                  " " +
                  fullData?.budget_observer?.placementProfileRelated?.lastName
                }
              />
              <ResponsibleSingle
                title="ناظر عمومی"
                fullName={
                  fullData?.general_observer?.placementProfileRelated
                    ?.firstName +
                  " " +
                  fullData?.general_observer?.placementProfileRelated?.lastName
                }
              />
              <ResponsibleSingle
                title="ناظر HSE"
                fullName={
                  fullData?.hse_observer?.placementProfileRelated?.firstName +
                  " " +
                  fullData?.hse_observer?.placementProfileRelated?.lastName
                }
              />
              <ResponsibleSingle
                title="ناظر حسابداری"
                fullName={
                  fullData?.accounting_observer?.placementProfileRelated
                    ?.firstName +
                  " " +
                  fullData?.accounting_observer?.placementProfileRelated
                    ?.lastName
                }
              />
            </div>
          </Dropdown>
        </div>
      </div>

      {/* تایید PMO */}
      {fullData?.id && fullData?.statusAction === "PMOPlan" && (
        <div className="flex flex-col gap-4 border-t border-t-caspian-gray2 pt-4">
          <div className="flex items-center gap-2">
            <input
              onChange={(e) => fullData?.id && setIsAccept(!isAccept)}
              id="accept"
              type="checkbox"
              className=" cursor-pointer accent-caspian-secondary-400"
            />
            <label
              htmlFor="accept"
              className="text-xs cursor-pointer font-bold"
            >
              با ارسال اطلاعات فوق به PM موافقت میفرمائید؟
            </label>
          </div>
          <div className="flex items-center gap-2">
            <button
              disabled={isAccept ? false : true}
              onClick={dataHandler}
              className="disabled:cursor-not-allowed disabled:bg-caspian-gray min-w-[6rem] bg-caspian-secondary-500 text-caspian-surface text-xs p-1.5 rounded-md"
            >
              ثبت و ارسال
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Request;
