import React, { useContext, useRef, useState } from 'react';
import Modal from '../../modal/Modal';
import { PiCircleNotch, PiPlusCircle, PiX } from 'react-icons/pi';
import DateForm from './DateForm';
import API from '../../../api/API';
import { AuthContext } from '../../../context/auth/AuthContext';

const PodcastForm = ({ setShowForm, setType,setShowPodcast }) => {
    const { token } = useContext(AuthContext);
    const refTag = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [subject, setSubject] = useState('');
    const [lang, setLang] = useState('');
    const [tag, setTag] = useState('');
    const [tagList, setTagList] = useState([]);
    const [desc, setDesc] = useState('');
    const [files, setFiles] = useState([]);
    console.log('tag', tag);
    const handleTag = (e) => {
        if (e.key === 'Enter') {
            if (e.target.value === '') return
            setTagList([...tagList, e.target.value]);
            refTag.current.value = '';
            refTag.current.focus();
        }
    }
    const removeTag = (index) => {
        const item = tagList?.filter((t, i) => i !== index);
        if (item?.length < 1) {
            setTag('');
        }
        setTagList(item);
    }
    const closeBtn = () => {
        setType('');
        setShowPodcast(false);
        setShowForm('');
    }
    const handleUpload = (event) => {
        const uploadFiles = event.target.files;
        if (uploadFiles.length > 0) {
            const newFiles = Array.from(uploadFiles);
            setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        }
    }
    const handleRemoveFile = (i) => {
        // console.log(i);
        const item = files?.filter((f, index) => index !== i);
        setFiles(item);
    }
    const handleSubmit = async () => {
        if (!token) return
        let formData={
            tags:tagList,
            title,
            subject,
            language:lang,
            description:desc,
            typeLibrary:'podcast'
        }
        setIsLoading(true);
        try {
            const res=await API.post('/api/v1/library/',formData,{headers:{Authorization:token}})
            if(res?.data.id){
                console.log(res?.data);
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }
    return (
        <Modal>
            <div className='bg-caspian-surface w-[50%] rounded-md pb-2'>
                <div className='flex justify-between items-center p-2'>
                    <span className='text-xs xl:text-sm'>
                        لطفا اطلاعات زیر را وارد کنید.
                    </span>
                    <button
                        disabled={isLoading}
                        onClick={closeBtn} >
                        <PiX />
                    </button>
                </div>
                <div className='flex flex-col gap-1 xl:gap-1.5 px-4'>
                    <div class="flex flex-col gap-1 xl:gap-2">
                        <label htmlFor="floating_outlined" className='text-caspian-gray4 text-xs'>عنوان:</label>
                        <input type="text" id="floating_outlined" className='text-xs placeholder:text-xs border border-caspian-gray2 p-2 rounded-lg' placeholder=" عنوان پادکست را وارد کنید"
                            onChange={(e) => { setTitle(e.target.value) }} />
                    </div>

                    {/* subject */}
                    <div className='flex flex-col gap-1 xl:gap-2'>
                        <label htmlFor="t2" className='text-caspian-gray4 text-xs'>موضوع:</label>
                        <input type="text"
                            id='t2'
                            onChange={(e) => setSubject(e.target.value)}
                            placeholder=' موضوع پادکست را وارد کنید'
                            className='text-xs placeholder:text-xs border border-caspian-gray2 p-1 xl:p-2 rounded-lg' />

                    </div>


                    {/* language  */}
                    <div className='flex flex-col gap-1 xl:gap-2'>
                        <label htmlFor="t6" className='text-caspian-gray4 text-xs'>زبان:</label>
                        <input type="text"
                            id='t6'
                            onChange={(e) => setLang(e.target.value)}
                            placeholder=' زبان پادکست را وارد کنید'
                            className='text-xs placeholder:text-xs border border-caspian-gray2 p-1 xl:p-2 rounded-lg' />

                    </div>
                    {/* tag */}
                    <div className='flex flex-col gap-1 xl:gap-2'>
                        <label htmlFor="t8" className='text-caspian-gray4 text-xs'>کلیدواژه‌ها:</label>
                        <div className='border border-caspian-gray1 rounded-lg p-1 xl:p-1.5'>
                            <input type="text"
                                id='t8'
                                placeholder='کلیدواژه‌ها را وارد کنید'
                                ref={refTag}
                                onKeyUp={(e) => handleTag(e)}
                                onChange={(e) => setTag(e.target.value)}
                                className='text-xs w-full focus:outline-none ' />

                            {/* <div className='flex gap-2 items-center'>
                        <label htmlFor="t8" className='text-caspian-gray4 text-xs'>کلیدواژه‌ها:</label>
                        <input type="text"
                            id='t8'
                            ref={refTag}
                            onKeyUp={(e) => handleTag(e)}
                            onChange={(e) => setTag(e.target.value)}
                            className='text-xs w-full p-1.5 focus:outline-none '/>
                        </div> */}

                            <div className='h-10 px-2 overflow-y-auto flex flex-wrap gap-2 items-center text-xs'>
                                {
                                    tagList?.map((t, index) => <div key={index} className='flex rounded-full border border-[#0A66C2] bg-[#DAE8F6] text-[#0A66C2]
                                text-xs p-1.5 pb-0 gap-2 items-start'>
                                        <span>{t}</span>
                                        <PiX onClick={() => removeTag(index)} className='cursor-pointer text-xs' />
                                    </div>)
                                }
                            </div>
                        </div>
                    </div>
                    {/* description */}
                    <div className='flex flex-col gap-1 xl:gap-2'>
                        <label htmlFor="t9" className='text-caspian-gray4 text-xs'>توضیحات:</label>
                        <textarea
                            id='t9'
                            placeholder='توضیحات پادکست را وارد کنید'
                            className='text-xs placeholder:text-xs border border-caspian-gray2 p-1 xl:p-2 rounded-lg resize-none'
                            onChange={(e) => setDesc(e.target.value)}
                            rows={4} cols={4}></textarea>

                    </div>

                    <button className=' w-[80px] justify-center p-1 xl:p-2 text-xs xl:text-sm rounded-md bg-[#3C77A2] flex gap-2 text-caspian-surface'
                        onClick={handleSubmit}>
                        <span>بارگزاری</span>
                        {isLoading && <PiCircleNotch className='animate-spin' />}
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default PodcastForm;
