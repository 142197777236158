import React, { useContext, useState } from "react";
import { PiCaretDown } from "react-icons/pi";
import ColorPicker from "./ColorPicker";
import { AuthContext } from "../../../context/auth/AuthContext";
import API from "../../../api/API";
import ColorPickerNew from "./ColorPickerNew";

const FormNoteNew = ({
  notebook,
  setShowAddNote,
  setRefresh,
  color,
  setColor,
  colorPicker,
  setColorPicker,
}) => {
  const { user, token } = useContext(AuthContext);

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const addNote = () => {
    if (!token) return null;
    if (!title || !user) return null;
    API.post(
      "/api/v1/note/note/",
      {
        title: title,
        content: content,
        color: color,
        notebook: notebook,
      },
      {
        headers: { Authorization: token },
      }
    )
      .then((res) => {
        console.log(res.data);
        setShowAddNote(false);
        setRefresh(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className={`w-full flex flex-col gap-0`}>
      <div className="relative">
        <input
        maxLength={20}
          type="text"
          className="placeholder:text-caspian-gray4 w-full outline-none bg-caspian-transparent p-2 pb-0"
          placeholder="عنوان یادداشت"
          onChange={(e) => setTitle(e.target.value)}
        />
        <div className="w-full h-[0.1px] bg-caspian-gray4/20 absolute top-[1.7rem]"></div>
      </div>
      <div className="relative w-full">
        <textarea
          rows={10}
          maxLength={590}
          className={`leading-6 w-full outline-none p-2 text-xs resize-none bg-caspian-transparent placeholder:text-caspian-gray4`}
          placeholder="متن یادداشت"
          onChange={(e) => setContent(e.target.value)}
        />
        <div className="w-full h-[1px] bg-caspian-gray4/20 absolute top-[1.5rem]"></div>
        <div className="w-full h-[1px] bg-caspian-gray4/20 absolute top-[3rem]"></div>
        <div className="w-full h-[1px] bg-caspian-gray4/20 absolute top-[4.5rem]"></div>
        <div className="w-full h-[1px] bg-caspian-gray4/20 absolute top-[6rem]"></div>
        <div className="w-full h-[1px] bg-caspian-gray4/20 absolute top-[7.5rem]"></div>
        <div className="w-full h-[1px] bg-caspian-gray4/20 absolute top-[9rem]"></div>
        <div className="w-full h-[1px] bg-caspian-gray4/20 absolute top-[10.5rem]"></div>
        <div className="w-full h-[1px] bg-caspian-gray4/20 absolute top-[12rem]"></div>
        <div className="w-full h-[1px] bg-caspian-gray4/20 absolute top-[13.5rem]"></div>
        <div className="w-full h-[1px] bg-caspian-gray4/20 absolute top-[15rem]"></div>
        {/* <div className="w-full h-[1px] bg-caspian-gray4/20 absolute top-[16.5rem]"></div>
        <div className="w-full h-[1px] bg-caspian-gray4/20 absolute top-[18rem]"></div>
        <div className="w-full h-[1px] bg-caspian-gray4/20 absolute top-[19.5rem]"></div>
        <div className="w-full h-[1px] bg-caspian-gray4/20 absolute top-[21rem]"></div>
        <div className="w-full h-[1px] bg-caspian-gray4/20 absolute top-[22.5rem]"></div>
        <div className="w-full h-[1px] bg-caspian-gray4/20 absolute top-[24rem]"></div> */}
      </div>
      <div className="flex items-center gap-4">
        <button
          disabled={notebook === null || !title || !content ? true : false}
          onClick={addNote}
          className={`disabled:bg-caspian-gray2 text-xs rounded-sm bg-caspian-secondary-500 text-caspian-surface px-3 py-1`}
        >
          ایجاد
        </button>
        <button
          onClick={() => setShowAddNote(false)}
          className="text-xs text-caspian-error2"
        >
          انصراف
        </button>
      </div>
    </div>
  );
};

export default FormNoteNew;
