import React, { useContext, useEffect, useRef, useState } from 'react'
import ListUploadFileCost from './upload-file-cost/ListUploadFileCost'
import API from '../../../../../../api/API'
import { AuthContext } from '../../../../../../context/auth/AuthContext';
import { useParams } from 'react-router-dom';
import { priceType } from '../../../../../../helper/pricetype';
import { PiCaretLeftBold, PiCaretRightBold } from 'react-icons/pi';


const MainFormCost = ({ setActiveTab, reportId, setCostListObject,
    costListObject, dataCost, setDataCost, setRecordCost, recordCost,
    idCost, setIdCost, succesDataCost, setSuccesDataCost }) => {


    const { token } = useContext(AuthContext);
    const params = useParams()
    let descRef = useRef()

    const [listDocCost, setListDocCost] = useState(dataCost?.documentsRelated ? dataCost?.documentsRelated : [])

    const [description, setDescription] = useState(dataCost?.description ? dataCost?.description : "")
    const [idRecord, setIdRecord] = useState([])
    const [costProgress, setCostProgress] = useState(0)
    const [refreshList, setRefreshList] = useState(0)
    const [checked, setChecked] = useState(true)
    const [deleteDataCost, setDeleteDataCost] = useState(false)
    const [addRecord, setAddRecord] = useState(false)




    // تجمیع هزینه فاکتور های این مرحله (ریال):

    useEffect(() => {
        if (!token || !params?.id) return;
        const handleDataCost = async () => {
            try {
                const res = await API.get(`/api/v1/actionplan/tasks/${params?.id}`,
                    {
                        headers: { Authorization: token }
                    }
                )
                console.log(res?.data)
                setCostProgress(res?.data)
            } catch (error) {
                console.log(error)
            }

        }
        handleDataCost()
    }, [token, params?.id, setCostProgress])



    // post هزینه

    const handleDocument = async () => {
        if (listDocCost) {
            if (!token || !reportId) return;

            let formData = {
                description: description,
                reportProfileRelated: reportId,
            }

            try {
                // console.log(formData)
                const res = await API.post('/api/v1/actionplan/reportCost/', formData,
                    { headers: { Authorization: token } }
                )
                console.log(res?.data)
                setIdCost(res?.data?.id)

                setDataCost(res?.data)
                setSuccesDataCost(true)
                setDeleteDataCost(false)
                setAddRecord(false)


            } catch (error) {
                console.log(error)
            }

        } else {

            let formData = {

                description: description,
                reportProfileRelated: reportId,
            }

            if (!token || !reportId) return;

            try {

                // console.log(formData)
                const res = await API.post('/api/v1/actionplan/reportCost/', formData,
                    { headers: { Authorization: token } }
                )
                console.log(res?.data)
                setIdCost(res?.data?.id)
                setDataCost(res?.data)
                setSuccesDataCost(true)
                setDeleteDataCost(false)


            } catch (error) {
                console.log(error)
            }
        }
    }


    // useEffect(() => {
    //     if (!token || !idCost) return;

    //     const handleDataProgress = async () => {
    //         try {
    //             const res = await API.get(`/api/v1/actionplan/reportCost/${idCost}/`)
    //             console.log(res?.data)
    //             setDataCost(res?.data)

    //         } catch (error) {
    //             console.log(error)
    //         }
    //     }
    //     handleDataProgress()
    // }, [token, idCost, setDataCost])


    // patch هزینه
    const editCost = async () => {
        if (!token || !idCost || !succesDataCost) return;

        let formData = {
            description: description,
        }

        if (succesDataCost) {
            try {
                const res = await API.patch(`/api/v1/actionplan/reportCost/${idCost}/`, formData,
                    { headers: { Authorization: token } }
                )
                console.log(res?.data)
                setDataCost(res?.data)
                setAddRecord(false)
            } catch (error) {
                console.log(editCost)
            }
        }
    }

    //get فاکتور

    useEffect(() => {
        if (!token || !reportId) return;

        const handleDataProgress = async () => {
            try {
                const res = await API.get(`/api/v1/actionplan/reports/recordCost/${reportId}`)
                console.log(res?.data)
                setRecordCost(res?.data)

            } catch (error) {
                console.log(error)
            }
        }
        handleDataProgress()
    }, [token, reportId, setRecordCost])



    //delete هزینه
    const deleteCost = async () => {
        if (!token || !idCost) return;

        try {
            const res = await API.delete(`/api/v1/actionplan/reportCost/${idCost}/`,
                { headers: { Authorization: token } }
            )
            console.log(res?.data)
            setDeleteDataCost(true)
            setSuccesDataCost(false)
        } catch (error) {
            console.log(error)
        }
    }

    const clearData = async () => {
        setDataCost({})
        setListDocCost([])
        descRef.current.value = ""
        setDescription("")
        setDeleteDataCost(true)
        setIdCost(null)
        setRefreshList(0)
        deleteCost()
    }

    console.log(deleteDataCost)

    const recrodData = listDocCost?.reduce((a, b) => a + (b?.amount || 0), 0);

    return (
        <div className='flex h-full flex-col items-start justify-between'>


            <div className='flex flex-col items-center w-full px-5 '>
                <div className='flex border-b border-b-caspian-secondary-600 flex-col items-start h-full w-full gap-5 pb-3'>


                    <div className='flex flex-col items-center gap-2 w-full'>

                        <div className='flex items-center justify-start w-full border-2 border-caspian-gray1/80 py-1.5 px-5 rounded-lg gap-5'>
                            <input type='checkbox' id='cost'

                                onClick={() => setChecked(!checked)} />


                            <label htmlFor='cost'
                                className={`text-xs 2xl:text-sm font-semibold ${checked ? "text-caspian-gray1" : "text-caspian-on_primary"}`}>
                                گزارشی ندارم
                            </label>
                        </div>


                        <div className='flex items-center w-full gap-5'>
                            <span className={`text-xs 2xl:text-sm whitespace-nowrap ${checked ? "text-caspian-gray4" : "text-caspian-gray4/30"} `}>
                                جمع هزینه
                            </span>
                            <div className='w-full h-[1px] bg-caspian-gray2/80'></div>
                        </div>

                        <div className='flex flex-col items-center gap-2 w-full h-full'>
                            <div className='flex items-start w-full gap-10'>
                                <div className='flex flex-col items-start justify-around gap-3 h-[90px]'>
                                    <span className={`text-xs font-semibold whitespace-normal
                                ${checked ? "text-caspian-gray" : "text-caspian-gray/30"}`}>
                                        تجمیع هزینه فاکتور های این مرحله (ریال):
                                    </span>

                                    <span className={`text-xs font-semibold whitespace-normal 
                                ${checked ? "text-caspian-on_primary" : "text-caspian-on_primary/30"}`}>
                                        هزینه پیش از این مرحله (ریال):

                                    </span>
                                    <span className={`text-xs font-semibold whitespace-normal
                                ${checked ? "text-caspian-on_primary" : "text-caspian-on_primary/30"}`}>
                                        هزینه تجمیعی (ریال):
                                    </span>
                                </div>

                                <div className={`flex flex-col items-start justify-around gap-2 pt-1 h-[90px]
                               ${checked ? "text-caspian-on_primary" : "text-caspian-on_primary/30"}`}>
                                    {/* هزینه پیش از این مرحله */}
                                    <span className={`text-xs 2xl:text-sm ${checked ? "text-caspian-gray" : "text-caspian-gray/30"}`}>
                                        {priceType(costProgress?.costProgress ? costProgress?.costProgress : 0)}
                                    </span>

                                    {/* تجمیع هزینه فاکتورها */}
                                    <span className={`text-xs 2xl:text-sm font-semibold ${checked ? "text-caspian-on_primary" : "text-caspian-on_primary/30"}`}>
                                        {priceType(recrodData)}
                                    </span>

                                    {/* هزینه تجمیعی */}
                                    <span className={`text-xs 2xl:text-sm font-semibold ${checked ? "text-caspian-on_primary" : "text-caspian-on_primary/30"}`}>
                                        {priceType(((costProgress?.costProgress ? costProgress?.costProgress : 0)
                                            +
                                            (recrodData ? recrodData : 0)))}
                                    </span>

                                </div>

                            </div>

                            <div className='flex flex-col items-start gap-1'>
                                <span className={` text-xs 2xl:text-sm ${checked ? "text-caspian-gray4" : "text-caspian-gray4/30"}`}>توضیحات</span>
                                <textarea
                                    defaultValue={dataCost?.description}
                                    disabled={checked ? false : true}
                                    ref={descRef}
                                    onChange={(e) => setDescription(e.target.value)}

                                    className={`resize-none px-5 py-3 w-full font-semibold placeholder:font-normal text-xs outline-none border-2 border-caspian-gray2 rounded-lg
                                        ${checked ? "placeholder:text-caspian-gray4" : "placeholder:text-caspian-gray4/30 text-caspian-on_primary/50"} `}

                                    placeholder='توضیحات برنامه اجرایی را وارد کنید'
                                    cols={100}
                                    rows={4}
                                />
                            </div>
                        </div>
                    </div>
                </div>


                <ListUploadFileCost setAddRecord={setAddRecord} refreshList={refreshList} setRefreshList={setRefreshList} setCostListObject={setCostListObject}

                    costListObject={costListObject} idRecord={idRecord} setIdRecord={setIdRecord} reportId={reportId} checked={checked}
                    listDocCost={listDocCost} setListDocCost={setListDocCost} />
            </div>


            <div className='w-full flex flex-col items-start gap-3 pb-2'>
                <div className='px-5'>
                    {checked ?
                        (!succesDataCost || !listDocCost || deleteDataCost) ?
                            <button
                                disabled={(listDocCost?.length > 0) ? false : true}
                                onClick={handleDocument}
                                className={`text-xs 2xl:text-sm  text-caspian-surface duration-500 w-[80px] h-8 rounded-[4px] 
                            ${(listDocCost?.length > 0) ? "bg-caspian-primary-500" : "bg-caspian-gray"}`}>
                                ثبت
                            </button>
                            :
                            <button
                                disabled={(addRecord || (description?.length !== dataCost?.description?.length)) ? false : true}
                                onClick={editCost}
                                className={`text-xs 2xl:text-sm  text-caspian-surface duration-500 w-[80px] h-8 rounded-[4px] 
                                     ${(addRecord || (description?.length !== dataCost?.description?.length)) ? "bg-caspian-primary-500" : "bg-caspian-gray"}`}>
                                ویرایش
                            </button>
                        :
                        <button
                            disabled={(!checked) && (!deleteDataCost) ? false : true}
                            onClick={clearData}
                            className={`text-xs 2xl:text-sm  text-caspian-surface duration-500 w-[80px] h-8 rounded-[4px] 
                            ${(!checked) && (!deleteDataCost) ? "bg-caspian-primary-500" : "bg-caspian-gray"}`}>
                            ثبت
                        </button>
                    }

                </div>

                <div className='w-full flex items-center border-t border-caspian-gray2 pt-3 px-5 gap-5'>
                    <button

                        onClick={() => setActiveTab("workProgress")}
                        // disabled={
                        //     (!checked && (deleteDataCost)) || (checked && (description?.length === dataCost?.description?.length))
                        //         ? false
                        //         : true
                        // }

                        // ${(!checked && (deleteDataCost)) || (checked && (description?.length === dataCost?.description?.length))
                        //     ? "text-caspian-secondary-600  border-caspian-secondary-600"
                        //     : "text-caspian-gray2 border-caspian-gray2"
                        // }
                        className={`text-xs 2xl:text-sm text-caspian-secondary-600  border-caspian-secondary-600 flex items-center justify-center duration-500 w-[80px] h-8 rounded-[4px] border-2
                            `}>


                        <PiCaretRightBold className='font-black' />
                        مرحله قبل
                    </button>

                    <button

                        onClick={() => setActiveTab("material")}
                        disabled={
                            (!checked && (deleteDataCost)) || (checked && ((description?.length === dataCost?.description?.length)) && !addRecord)
                                ? false
                                : true
                        }
                        className={`text-xs 2xl:text-sm flex items-center justify-center duration-500 text-caspian-surface w-[80px] h-8 rounded-[4px]
                        ${(!checked && (deleteDataCost)) || (checked && ((description?.length === dataCost?.description?.length)) && !addRecord)
                                ? "bg-caspian-secondary-600"
                                : "bg-caspian-gray2"}`
                        }>


                        مرحله بعد
                        <PiCaretLeftBold className='font-black' />
                    </button>
                </div>
            </div>


        </div >
    )
}

export default MainFormCost