import React, { useContext, useState } from "react";
import {
  PiCaretLeftBold,
  PiChatCircleDots,
  PiUsers,
  PiX,
} from "react-icons/pi";
import SearchInput from "./SearchInput";
import MembersList from "./MembersList";
import API from "../../../api/API";
import { AuthContext } from "../../../context/auth/AuthContext";

const MainContactChat = ({
  setShowChatBox,
  setShowContactChat,
  showChatContent,
  setShowChatContent,
  memberSelected,
  setMemberSelected,
  setRefreshMyconversation,
}) => {
  const { token } = useContext(AuthContext);
  const [showNameGroupChat, setShowNameGroupChat] = useState(false);
  const [groupName, setGroupName] = useState("");

  const [serachMember, setSerachMember] = useState("");

  const groupChatHandler = async () => {
    if (memberSelected.length < 2) return null;
    if (groupName === "") return null;
    await API.post(
      "/api/v1/message/conversation/",
      {
        convType: "group",
        convCode: memberSelected.toString(),
        title: groupName,
      },
      {
        headers: { Authorization: token },
      }
    )
      .then((res) => {
        setShowNameGroupChat(false);
        // setShowChatContent(true);
        setShowContactChat(false);
        setMemberSelected([]);
        setRefreshMyconversation(1);
        setGroupName("");
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="min-w-[280px] max-w-[280px] min-h-[460px] max-h-[460px] bg-caspian-primary-50 rounded-md flex flex-col fixed bottom-16 left-28 z-40">
      {showNameGroupChat && (
        <div className="w-full h-full absolute inset-0 bg-caspian-surface/20 backdrop-blur-sm z-50">
          <div className="shadow-[0_0_5px_#00000050] rounded-md bg-caspian-surface p-2 absolute bottom-24 left-1/2 -translate-x-1/2 flex flex-col gap-2 w-[80%]">
            <div className="flex items-center justify-between p-1.5">
              <PiX
                className="cursor-pointer"
                onClick={() => setShowNameGroupChat(false)}
              />
              <span className="text-xs">عنوان گروه</span>
            </div>
            <input
              onChange={(e) => setGroupName(e.target.value)}
              className="text-xs outline-none border-caspian-gray border rounded-md p-1.5 w-full"
              placeholder="عنوان گروه را وارد کنید"
            />
            <div className="flex items-center gap-4">
              <button
                onClick={groupChatHandler}
                className="outline-none text-caspian-secondary-500 text-xs p-2"
              >
                ایجاد
              </button>
              <button
                onClick={() => setShowNameGroupChat(false)}
                className="outline-none text-caspian-error2 text-xs p-2"
              >
                انصراف
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="flex items-center justify-between w-full p-2 border-b border-b-caspian-gray2">
        <PiX
          onClick={() => {
            setShowChatBox(false);
            setShowContactChat(false);
          }}
          className="text-caspian-gray4 text-xl cursor-pointer"
        />
        <PiCaretLeftBold
          onClick={() => setShowContactChat(false)}
          className="text-caspian-gray4 cursor-pointer"
        />
      </div>
      <div className="w-full flex items-center gap-2 p-2">
        <SearchInput
          serachMember={serachMember}
          setSerachMember={setSerachMember}
        />
      </div>
      {memberSelected.length > 0 && (
        <div className="bg-caspian-surface rounded-full min-w-[40px] p-2 absolute top-20 left-2 flex items-center gap-3 shadow-[0_0_5px_#00000050]">
          <div className="flex items-center gap-1">
            <span className="text-sm text-caspian-gray4">
              {memberSelected.length}
            </span>
            <span className="text-xs text-caspian-gray4">نفر</span>
          </div>
          <PiX
            className="cursor-pointer text-sm"
            onClick={() => setMemberSelected([])}
          />
        </div>
      )}
      <MembersList
        serachMember={serachMember}
        setSerachMember={setSerachMember}
        setMemberSelected={setMemberSelected}
        memberSelected={memberSelected}
      />
      {memberSelected?.length === 1 ? (
        <button
          onClick={() => setShowChatContent(true)}
          className="bg-caspian-secondary-400 text-caspian-surface rounded-md py-2 flex items-center justify-center gap-2 absolute bottom-5 mx-auto z-40 left-5 right-5"
        >
          <PiChatCircleDots className="text-xl" />
          <span className="text-xs">ایجاد گفتگو</span>
        </button>
      ) : memberSelected?.length > 1 ? (
        <button
          onClick={() => setShowNameGroupChat(true)}
          className="bg-caspian-primary-400 text-caspian-surface rounded-md py-2 flex items-center justify-center gap-2 absolute bottom-5 mx-auto z-40 left-5 right-5"
        >
          <PiUsers className="text-xl" />
          <span className="text-xs">ایجاد گروه</span>
        </button>
      ) : null}
    </div>
  );
};

export default MainContactChat;
