import React from "react";

const ButtonChart = ({ title, bgColor, textColor, borderColor }) => {
  return (
    <div
      className="rounded-md p-2 w-[180px] max-w-[180px] h-[50px]  border flex items-center justify-center text-center text-xs "
      style={{
        backgroundColor: bgColor,
        color: textColor,
        borderColor: borderColor,
      }}
    >
      {title}
    </div>
  );
};

export default ButtonChart;
