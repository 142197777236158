import React from 'react'
import ButtonChart from '../ButtonChart'

const Chart2 = ({ sidebar }) => {
  return (
    <div className={`flex flex-col items-center mx-40 2xl:mx-auto w-full min-w-[990px] max-w-[990px] 
      `}>

      <div className="flex flex-col w-full items-center h-full">
        {/* 1 */}
        <ButtonChart
          title="مدیرعامل"
          bgColor="#DDEAF4"
          textColor="#4B6B90"
          borderColor="#4B6B90"
        />
        {/* 1 */}
        <div className="h-5 border-r-2 border-r-caspian-gray"></div>
      </div>

      <div className="h-5 border-r-2 border-r-caspian-gray"></div>

      <div className="flex flex-col w-full items-center">
        {/* 2 */}
        <div className="flex justify-center relative h-48 gap-10 items-center">
          <div className="flex flex-col gap-2 h-full items-center">
            <div className="flex items-center justify-center">
              <ButtonChart
                title="قائم مقام(1)"
                bgColor="#C7EBEA"
                textColor="#225C58"
                borderColor="#225C58">
              </ButtonChart>
              <div className="w-14 absolute right-[47%] h-2 border-b-2 border-b-caspian-gray"></div>
            </div>

            <div className="flex items-center justify-center">
              <ButtonChart
                title="متصدی امور دفتری و کاربر رایانه (1)"
                bgColor="#C7EBEA"
                textColor="#225C58"
                borderColor="#225C58">
              </ButtonChart>
              <div className="w-14 absolute right-[47%] h-2 border-b-2 border-b-caspian-gray"></div>
            </div>
          </div>

          <div className="h-full border-r-2 border-r-caspian-gray"></div>

          <div className="flex flex-col gap-2  h-full items-center">
            <div className="flex items-center justify-center">
              <ButtonChart
                title="مشاور(1)"
                bgColor="#C7EBEA"
                textColor="#225C58"
                borderColor="#225C58">
              </ButtonChart>
              <div className="w-14 absolute left-[47%] h-2 border-b-2 border-b-caspian-gray"></div>
            </div>

            <div className="flex items-center justify-center">
              <ButtonChart
                title="كارشناس مسئول حسابرسی داخلی (1)"
                bgColor="#C7EBEA"
                textColor="#225C58"
                borderColor="#225C58">
              </ButtonChart>
              <div className="w-14 absolute left-[47%] h-2 border-b-2 border-b-caspian-gray"></div>
            </div>

            <div className="flex items-center justify-center">
              <ButtonChart
                title="كارشناس مسئول بازرسی (1)"
                bgColor="#C7EBEA"
                textColor="#225C58"
                borderColor="#225C58">
              </ButtonChart>
              <div className="w-10 absolute left-[41%] h-2 border-b-2 border-b-caspian-gray"></div>
            </div>
          </div>
        </div>
        {/* 2 */}

        <div className="flex w-full flex-col justify-center relative gap-5 items-center">
          <div className="w-[1100px] h-2 border-t-2 border-t-caspian-gray"></div>
          {/* 3 */}
          <div className="flex w-full items-start justify-center gap-10">
            <div className="flex flex-col gap-2 h-full items-center">
              <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
              <div className="flex items-center relative h-full">
                <div className="h-[53%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>
                <div className="flex flex-col items-center gap-2 h-full">
                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="رئیس دفتر هیئت مدیره و مدیر عامل (1)"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="متصدی امور دفتری و کاربر رایانه(1)"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>
                </div>
              </div>
            </div>


            <div className="flex flex-col gap-2 h-full items-center">
              <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
              <div className="flex items-center relative h-full">
                <div className="h-[83%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>
                <div className="flex flex-col items-center gap-2 h-full">
                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="مدیر دفتر مدیریت بحران، پدافند غیرعامل و HSE(1)"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="کارشناس مسئول مدیریت بحران (1)"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="کارشناس پدافند غیر عامل (1)"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="رئیس گروه بهداشت، ایمنی و حفاظت کار (1)"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="کارشناس بهداشت، ایمنی و حفاظت کار(1)"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-2 h-full items-center">
              <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
              <div className="flex items-center relative h-full">
                <div className="h-[85%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>
                <div className="flex flex-col items-center gap-2 h-full">
                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="مدیر دفتر قراردادها و بازرگانی (1)"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="کارشناس مسئول قراردادها (1)"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="کارشناس قرارداها ( 1)"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="کارشناس بازرگانی ( 1)"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="کارشناس مسئول رسیدگی به پیمان ( 1)"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="کارشناس رسیدگی به پیمان ( 1)"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-2 h-full items-center">
              <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
              <div className="flex items-center relative h-full">
                <div className="h-[55%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>
                <div className="flex flex-col items-center gap-2 h-full">
                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="مدیردفتر حقوقی رسیدگی به شکایات (1)"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="کارشناس حقوقی (5)"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-2 h-full items-center">
              <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
              <div className="flex items-center relative h-full">
                <div className="h-[78%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>
                <div className="flex flex-col items-center gap-2 h-full">
                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="مدیردفتر روابط عمومی و آموزش همگانی (1)"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="کارشناس روابط عمومی (2)"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="کارشناس آموزش همگانی (1)"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="کمک کارشناس روابط عمومی (1)"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-2 h-full items-center">
              <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>

              <div className="flex items-center relative h-full">
                <div className="h-[82%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>

                <div className="flex flex-col items-center gap-2 h-full">
                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="مدیر دفتر حراست و امور محرمانه(1)"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="كارشناس مسئول حفاظت پرسنلي(1)"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>

                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="كارشناس مسئول حفاظت فيزيكي(1)"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>


                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="كارشناس حفاظت اسناد وIT"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>


                  <div className="flex items-center justify-center">
                    <ButtonChart
                      title="كارشناس فنی (1)"
                      bgColor="#C7EBEA"
                      textColor="#225C58"
                      borderColor="#225C58">
                    </ButtonChart>
                    <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* 3 */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Chart2