import React, { useContext, useEffect, useState } from 'react'
import { PiCaretLeft, PiTrashLight } from 'react-icons/pi'
import DetailsDoc from './DetailsDoc'
import { AuthContext } from '../../../../../../../context/auth/AuthContext';
import API from '../../../../../../../api/API';


const SingleListUploadFile = ({ listDoc, setRefreshList, checked, setAddDoc }) => {

    const { token } = useContext(AuthContext);

    const [showModalDetail, setShowModalDetail] = useState(false)
    const [selectItemIndex, setSelectItemIndex] = useState(0)

    //delete document
    const handleDeleteDocument = async (id) => {

        if (!token ) return;

        try {
            const res = await API.delete(`/api/v1/actionplan/projectDocument/${id}`,
                {
                    headers: {
                        Authorization: token,
                        "content-type": "multipart/form-data",
                        Accept: "application/json"
                    }
                }
            )
            console.log(res?.data)
            setRefreshList(1)

            setAddDoc(true)

        } catch (error) {
            console.log(error)
        }
    }


    return (

        <div className='w-full flex flex-col items-center gap-5'>
            {listDoc?.map((doc, index) => (
                <div key={index} className='w-full flex items-center justify-center gap-5' >
                    <div className='flex justify-between items-center w-full border-b-2 px-3 pb-2 border-caspian-gray2'>

                        <span className={`text-xs 2xl:text-sm ${checked ? "text-caspian-on_primary" : "text-caspian-gray2"}`}>
                            {doc?.name}
                        </span>

                        <div className={`flex items-cente ${checked ? "cursor-pointer" : ""}`}>
                            <span onClick={() => {
                                checked &&
                                    setShowModalDetail(true)
                                setSelectItemIndex(doc?.id)
                            }}
                                className={`${checked ? "text-caspian-blue-600" : "text-caspian-blue-400"} text-xs`}>بیشتر</span>
                            <PiCaretLeft className={`${checked ? "text-caspian-blue-600" : "text-caspian-blue-400"} text-base`} />

                        </div>
                    </div>
                    <div className='pb-2'>
                        <PiTrashLight

                            onClick={() => checked && handleDeleteDocument(doc?.id)}
                            className={`text-base ${checked ? "text-caspian-secondary-600 cursor-pointer" : "text-caspian-secondary-200"}`} />

                    </div>
                </div>
            ))}

            {showModalDetail &&
                <DetailsDoc selectItemIndex={selectItemIndex} listDoc={listDoc} showModalDetail={showModalDetail} setShowModalDetail={setShowModalDetail} />
            }

        </div >
    )
}

export default SingleListUploadFile