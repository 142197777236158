import {
  PiClipboardText,
  PiFileTextDuotone,
} from "react-icons/pi";
import ListItemSingle from "./ListItemSingle";
import { useParams } from "react-router-dom";

const StackholdersNavbar = ({ buttonActive }) => {
  const {id}=useParams();
  return (
    <nav className="w-full bg-caspian-surface rtl">
      <ul className="flex items-center gap-0.5">
        <ListItemSingle
          title="لیست ذینفعان"
          active="list"
          icon={<PiFileTextDuotone />}
          link="/stackholders/list"
          buttonActive={buttonActive}
          // onClick={() => setBradCrump3("تیم ها")}
        />
        <ListItemSingle
          title="مدل ذینفعان"
          active="model"
          icon={<PiFileTextDuotone />}
          link="/stackholders/model"
          buttonActive={buttonActive}
          // onClick={() => setBradCrump3("ریسک ها")}
        />

        {
          id && <ListItemSingle
          title="پروفایل ذینفعان"
          active="profile"
          icon={<PiClipboardText />}
          link="/stackholders/profile"
          buttonActive={buttonActive}
          // onClick={() => setBradCrump3("ریسک ها")}
        />
        }
        
      </ul>
    </nav>
  );
};

export default StackholdersNavbar;
