import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { PiCaretLeft, PiPlusCircle } from "react-icons/pi";
// import RequestForm from "./forms/RequestForm";
import SearchInput from "./SearchInput";
import { useAppContext } from "../../context/app-context/AppContext";
import RequestFormNew from "./forms/RequestFormNew";
import { AuthContext } from "../../context/auth/AuthContext";
import API from "../../api/API";

const RequestLayout = ({ showMenu }) => {
  const { token } = useContext(AuthContext);
  const { setRequestSendNumber, setRequestReceivedNumber, setBradCrump3 } =
    useAppContext();

  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const search = (location.state && location.state.search) || "";
  const [showRequestForm, setShowRequestForm] = useState(false);
  const [refreshListSender, setRefreshListSender] = useState(0);
  useEffect(() => {
    setRefreshListSender(1);
  }, []);
  useEffect(() => {
    params.id ? setBradCrump3(params.id) : setBradCrump3("");
  }, [setBradCrump3, params]);

  useEffect(() => {
    if (!token || refreshListSender !== 1) return;
    API.get("/api/v1/request/request/reciver", {
      headers: {
        Authorization: token,
      },
    }).then((d) => {
      setRequestReceivedNumber(d.data.length);
    });
  }, [token, setRequestReceivedNumber, refreshListSender]);
  useEffect(() => {
    if (!token || refreshListSender !== 1) return;
    API.get("/api/v1/request/request/sender", {
      headers: {
        Authorization: token,
      },
    }).then((d) => {
      setRequestSendNumber(d.data.length);
    });
  }, [token, setRequestSendNumber, refreshListSender]);

  return (
    <div
      className={
        showMenu
          ? `pl-[50px] mr-auto w-full md:w-[75%] lg:w-[80%] xl:w-[85%] 2xl:w-[85%] bg-caspian-gray3 p-1 overflow-y-scroll`
          : `pl-[50px] mr-[54px] w-full bg-caspian-gray3 p-1 overflow-y-scroll`
      }
    >
      <div className="flex flex-col gap-4 p-4">
        <div className="flex flco items-center justify-between">
          <div className="flex items-center gap-4 w-full">
            <SearchInput />
            {!params.id && (
              <button
                onClick={() => setShowRequestForm(true)}
                className="flex items-center gap-1 rounded-md bg-caspian-secondary-500 text-caspian-surface text-sm p-2"
              >
                <PiPlusCircle className="text-xl" />
                <span className="text-sm">درخواست گزارش</span>
              </button>
            )}
            {showRequestForm && (
              <RequestFormNew
                setShowRequestForm={setShowRequestForm}
                showRequestForm={showRequestForm}
                refreshListSender={refreshListSender}
                setRefreshListSender={setRefreshListSender}
              />
            )}
          </div>
          {params.id && (
            <button
              className="mr-auto flex items-center gap-2 text-caspian-secondary-700"
              onClick={() => {
                navigate(`${search === "" ? "/requests/received" : search}`);
                setBradCrump3("");
              }}
            >
              <span className="">بازگشت</span>
              <PiCaretLeft />
            </button>
          )}
        </div>
        <Outlet context={[refreshListSender, setRefreshListSender]} />
      </div>
    </div>
  );
};

export default RequestLayout;
