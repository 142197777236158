import { PiXCircleLight, PiFileXlsFill } from "react-icons/pi";

const ListUploadFile = ({ listFile, setListFile }) => {

    const handleDelete = (file) => {
        setListFile(listFile.filter((item) => item !== file))

        console.log(listFile)
    }

    const convertSize = (size) => {
        if (size < (1024 * 1024)) {
            return (size / 1024).toFixed(1) + "KB"
        } else if (size < (1024 * 1024 * 1024)) {
            return (size / (1024 * 1024)).toFixed(1) + "MB"
        } else {
            return (size / (1024 * 1024 * 1024)).toFixed(1) + "GB"
        }
    }


    return (
        <div className='w-1/2 h-[200px] overflow-auto flex flex-col gap-3 justify-start items-center'>

            {listFile.map((item, index) =>
                <div className='flex items-center justify-between w-full px-2' key={index} >
                    <div className='flex items-center gap-5'>
                        <PiXCircleLight onClick={() => handleDelete(item)} className='text-xs xl:text-xl cursor-pointer text-caspian-darkgray-400' />
                        <span className='text-xs text-caspian-on_primary/70'>{convertSize(item.size)}</span>

                    </div>

                    <div className='flex items-center gap-2'>
                        <span className='text-xs'>{item?.name}</span>
                        <PiFileXlsFill className='text-xs xl:text-2xl text-caspian-secondary-600' />
                    </div>
                </div>
            )
            }
        </div >
    )
}

export default ListUploadFile