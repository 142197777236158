import React, { useContext, useState } from "react";
import {
  PiPencilSimpleLine,
  PiPlusBold,
  PiStar,
  PiStarFill,
  PiX,
} from "react-icons/pi";
import API from "../../../../api/API";
import { AuthContext } from "../../../../context/auth/AuthContext";

const AcceptRequest = ({ setShowAccept, requestId, setSuccessReply }) => {
  const { token } = useContext(AuthContext);

  const [rate, setRate] = useState(3);
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const messageHandler = async (e) => {
    e.preventDefault();
    await API.patch(
      `/api/v1/request/${requestId}/`,
      {
        score: rate,
        status: "complated",
        comment: message.length > 0 ? message : null,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((res) => {
        setSuccessReply(1);
        setShowAccept(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center w-full h-full z-50">
      <div
        onClick={() => setShowAccept(false)}
        className="fixed inset-0 bg-caspian-gray3/50 z-40 p-4"
      ></div>
      <div className="z-50 fixed flex items-center justify-center p-2">
        <div className="min-w-[360px] bg-caspian-surface shadow-[0_0_5px_#00000050] rounded-lg border-r-4 border-r-[#73AEAF] flex flex-col gap-3 py-3 px-4">
          <div className="flex items-center justify-between">
            <span className="text-sm">تایید گزارش</span>
            <PiX
              onClick={() => setShowAccept(false)}
              className="cursor-pointer z-50"
            />
          </div>
          <form onSubmit={messageHandler} className="flex flex-col gap-3">
            <div className="flex items-center justify-center gap-2">
              <h2 className="text-xs text-caspian-gray1">
                به این گزارش چه امتیازی می دهید؟
              </h2>
              <span className="font-bold text-sm">
                {rate === 1 && "خیلی ضعیف"}
                {rate === 2 && "ضعیف"}
                {rate === 3 && "متوسط"}
                {rate === 4 && "خوب"}
                {rate === 5 && "عالی"}
              </span>
            </div>
            <div className="flex items-center justify-center gap-2 ltr">
              <span className="text-xs text-caspian-gray1">خیلی ضعیف</span>
              <div onClick={() => setRate(1)} className="cursor-pointer">
                {rate >= 1 ? (
                  <PiStarFill className="text-[#F9E26B] text-xl" />
                ) : (
                  <PiStar className="text-caspian-gray1 text-xl" />
                )}
              </div>
              <div onClick={() => setRate(2)} className="cursor-pointer">
                {rate >= 2 ? (
                  <PiStarFill className="text-[#F9E26B] text-xl" />
                ) : (
                  <PiStar className="text-caspian-gray1 text-xl" />
                )}
              </div>
              <div onClick={() => setRate(3)} className="cursor-pointer">
                {rate >= 3 ? (
                  <PiStarFill className="text-[#F9E26B] text-xl" />
                ) : (
                  <PiStar className="text-caspian-gray1 text-xl" />
                )}
              </div>
              <div onClick={() => setRate(4)} className="cursor-pointer">
                {rate >= 4 ? (
                  <PiStarFill className="text-[#F9E26B] text-xl" />
                ) : (
                  <PiStar className="text-caspian-gray1 text-xl" />
                )}
              </div>
              <div onClick={() => setRate(5)} className="cursor-pointer">
                {rate >= 5 ? (
                  <PiStarFill className="text-[#F9E26B] text-xl" />
                ) : (
                  <PiStar className="text-caspian-gray1 text-xl" />
                )}
              </div>
              <span className="text-xs text-caspian-gray1">عالی</span>
            </div>
            {showMessage && (
              <textarea
                className="leading-5 resize-none outline-none outline-caspian-gray1 rounded-md outline-1 text-xs p-2"
                placeholder="توضیحات اضافه را وارد کنید"
                rows={4}
                value={message?.length > 0 ? message : ""}
                name="message"
                onChange={(e) => setMessage(e.target.value)}
              />
            )}
            <div className="flex items-center justify-between gap-4">
              <button className="w-fit text-caspian-secondary-600 outline outline-1 outline-caspian-secondary-600 py-1 px-4 text-xs rounded-md">
                ارسال
              </button>
              {message?.length > 0 ? (
                <button
                  onClick={() => setShowMessage(!showMessage)}
                  type="button"
                  className="text-caspian-secondary-500"
                >
                  {showMessage ? (
                    <span className="text-xs">بستن</span>
                  ) : (
                    <div className="flex items-center gap-1">
                      <PiPencilSimpleLine className="text-sm" />
                      <span className="text-xs">ویرایش توضیحات</span>
                    </div>
                  )}
                </button>
              ) : (
                <button
                  onClick={() => setShowMessage(!showMessage)}
                  type="button"
                  className="flex items-center gap-1 text-caspian-secondary-500"
                >
                  {showMessage ? (
                    <span className="text-xs">بستن</span>
                  ) : (
                    <div className="flex items-center gap-1">
                      <PiPlusBold className="text-sm" />
                      <span className="text-xs">توضیحات</span>
                    </div>
                  )}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AcceptRequest;
