import DatePicker, { DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { useEffect, useState } from "react";

//gregorian calendar & locale
import gregorian from "react-date-object/calendars/gregorian";
import gregorian_fa from "react-date-object/locales/gregorian_fa";

function toEnglishNumber(number) {
  // تبدیل عدد به رشته
  var str = number?.toString();

  // جایگزینی کاراکترهای فارسی با کاراکترهای انگلیسی
  str = str?.replace(/۰/g, "0");
  str = str?.replace(/۱/g, "1");
  str = str?.replace(/۲/g, "2");
  str = str?.replace(/۳/g, "3");
  str = str?.replace(/۴/g, "4");
  str = str?.replace(/۵/g, "5");
  str = str?.replace(/۶/g, "6");
  str = str?.replace(/۷/g, "7");
  str = str?.replace(/۸/g, "8");
  str = str?.replace(/۹/g, "9");

  // بازگشت رشته تبدیل‌شده
  return str;
}
const DateTimePicker = ({ setDate }) => {
  const [state, setState] = useState({ format: "YYYY/MM/DD" });
  const convert = (date, format = state?.format) => {
    let object = { date, format };

    setState({
      gregorian: new DateObject(object)
        .convert(gregorian, gregorian_fa)
        .format(),
      persian: new DateObject(object).format(),
      jsDate: date?.toDate(),
      ...object,
    });
  };

  useEffect(() => {
    setDate(
      state?.gregorian && toEnglishNumber(state?.gregorian).replaceAll("/", "-")
    );
  }, [state?.gregorian, setDate]);

  let today = new Date();
  // تنظیم ساعت 00:00:00 برای امروز
  today.setHours(0, 0, 0, 0);

  return (
    <DatePicker
      onlyShowInRangeDates
      editable={false}
      minDate={today}
      value={state?.persian}
      onChange={convert}
      calendar={persian}
      locale={persian_fa}
      calendarPosition="bottom-right"
      format="YYYY/MM/DD"
      placeholder="انتخاب کنید"
      inputClass="outline-none outline-caspian-gray1 p-1.5 text-xs rounded-md outline-1 w-full"
    />
  );
};

export default DateTimePicker;
