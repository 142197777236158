import React from "react";
import { PiX } from "react-icons/pi";
import ReactPlayer from "react-player";

const PopUp = ({ setShowPopup, popupData }) => {
  console.log(popupData);
  return (
    <div className="fixed inset-0 bg-caspian-gray/50 z-50">
      <div className="flex items-center justify-center w-full h-full">
        <div className="relative bg-caspian-surface rounded-xl w-1/2 h-auto">
          <div className="flex items-center justify-between absolute top-2 right-5 left-5 z-50">
            <h2 className="text-caspian-surface bg-caspian-on_primary/50 shadow-md p-2 rounded-xl text-sm">
              {popupData.title}
            </h2>
            <button onClick={() => setShowPopup(false)} className="">
              <PiX className="text-2xl text-caspian-surface" />
            </button>
          </div>
          <div className="flex items-center justify-center w-full h-full">
            <div className="w-full h-full rounded-xl overflow-hidden">
              <div className="bg-caspian-gray2 bg-cover w-full h-auto flex items-center justify-center">
                <ReactPlayer
                  url={popupData.file}
                  controls
                  width="100%"
                  height="100%"
                  // light={popupData.picture}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUp;
