import React, { useState } from "react";
import {
  PiArchive,
  PiCaretLeft,
  PiCaretRight,
  PiChartPieSlice,
  PiChats,
  PiCirclesThreePlus,
  PiClipboardText,
  PiFileMagnifyingGlassDuotone,
  PiFileText,
  PiFolders,
  PiGitFork,
  PiHouse,
  PiMonitorPlay,
  PiPulse,
  PiSquaresFour,
  PiTarget,
  PiUsersThree,
  PiUsersThreeBold,
} from "react-icons/pi";
import { MdGpsFixed } from "react-icons/md";
import { useLocation } from "react-router-dom";
import UserComponent from "./UserComponent";
import { useEffect } from "react";
import SideBarNavLink from "./SideBarNavLink";

const Sidebar = ({ showMenu, setShowMenu }) => {
  const { pathname } = useLocation();
  const [buttonActive, setButtonActive] = useState("home-page");

  const [hideIconMenu, setHideIconMenu] = useState(false);

  useEffect(() => {
    if (pathname === "/home-page" || pathname === "/") {
      setButtonActive("home-page");
    } else if (pathname.startsWith("/goals")) {
      setButtonActive("goal");
    } else if (pathname.startsWith("/stackholders")) {
      setButtonActive("stackholder");
    } else if (pathname.startsWith("/risk")) {
      setButtonActive("teams");
    } else if (pathname.startsWith("/audit")) {
      setButtonActive("chart");
    } else if (pathname.startsWith("/action-plan")) {
      setButtonActive("plans");
    } else if (pathname.startsWith("/requests")) {
      setButtonActive("req-received");
      // } else if (pathname.startsWith("/reports")) {
      //   setButtonActive("received");
    } else if (pathname.startsWith("/documents-and-records")) {
      setButtonActive("documents-and-records");
    } else if (pathname.startsWith("/chart")) {
      setButtonActive("tree-chart");
    } else if (pathname.startsWith("/process-management")) {
      setButtonActive("process-management");
    } else if (pathname.startsWith("/managment-of-meetings")) {
      setButtonActive("profile-meeting");
    } else if (pathname === "/library") {
      setButtonActive("library");
    } else if (pathname === "/all-library") {
      setButtonActive("all-library");
    } else if (pathname.startsWith("/blog")) {
      setButtonActive("blog");
    } else {
      setButtonActive("");
    }
  }, [pathname]);
  return (
    <div
      onMouseEnter={() => {
        setHideIconMenu(true);
      }}
      onMouseLeave={() => {
        setHideIconMenu(false);
      }}
      className={
        showMenu
          ? `min-h-screen w-[80%] md:w-[25%] lg:w-[20%] xl:w-[15%] 2xl:w-[15%] bg-caspian-surface fixed top-12 right-0 shadow-[0_0_10px_#00000050] z-40`
          : `min-h-screen w-[52px] bg-caspian-surface fixed top-12 right-0 shadow-[0_0_10px_#00000050] z-40`
      }
    >
      {showMenu ? (
        <button
          onClick={() => {
            setShowMenu(false);
            localStorage.setItem("hide_sidebar", "TRUE");
          }}
          className={
            hideIconMenu
              ? "absolute -left-3 top-5 p-1 w-6 h-6 flex items-center justify-center bg-caspian-primary-600 text-caspian-surface cursor-pointer rounded-full z-30 duration-300"
              : "absolute -left-3 top-5 duration-300 p-1 w-6 h-6 flex items-center justify-center text-caspian-transparent"
          }
        >
          <PiCaretRight className="text-lg text-neutral-700" />
        </button>
      ) : (
        <button
          onClick={() => {
            setShowMenu(true);
            localStorage.removeItem("hide_sidebar");
          }}
          className="absolute -left-3 top-5 p-1 w-6 h-6 flex items-center justify-center bg-caspian-primary-600 text-caspian-surface cursor-pointer rounded-full z-30 duration-300"
        >
          <PiCaretLeft className="text-lg text-neutral-700" />
        </button>
      )}
      <nav
        className={
          showMenu
            ? `scroll_firefox my-14 overflow-y-auto fixed w-full md:w-[24.8%] lg:w-[19.8%] xl:w-[14.8%] 2xl:w-[14.8%] h-auto bottom-24 top-12 text-sm`
            : `scroll_firefox my-14 overflow-y-auto fixed w-fit h-auto bottom-24 top-12`
        }
      >
        <ul className="flex flex-col gap-3 text-sm">
          <SideBarNavLink
            title="خانه"
            icon={<PiHouse />}
            titleButtonActive="home-page"
            link="/"
            showMenu={showMenu}
            buttonActive={buttonActive}
            setButtonActive={setButtonActive}
          />
          <SideBarNavLink
            title="اهداف سازمان"
            icon={<PiTarget />}
            titleButtonActive="goal"
            link="/goals/list"
            showMenu={showMenu}
            buttonActive={buttonActive}
            setButtonActive={setButtonActive}
          />
          <SideBarNavLink
            title="ذینفعان سازمان"
            icon={<PiUsersThree />}
            titleButtonActive="stackholder"
            link="/stackholders/list"
            showMenu={showMenu}
            buttonActive={buttonActive}
            setButtonActive={setButtonActive}
          />
          <SideBarNavLink
            title="ممیزی"
            icon={<PiFileMagnifyingGlassDuotone />}
            titleButtonActive="chart"
            link="/audit/chart"
            showMenu={showMenu}
            buttonActive={buttonActive}
            setButtonActive={setButtonActive}
          />
          <SideBarNavLink
            title="ریسک"
            icon={<PiPulse />}
            titleButtonActive="teams"
            link="/risk/teams"
            showMenu={showMenu}
            buttonActive={buttonActive}
            setButtonActive={setButtonActive}
          />
          <SideBarNavLink
            title="برنامه اجرایی"
            icon={<PiChartPieSlice />}
            titleButtonActive="plans"
            link="/action-plan/plans"
            showMenu={showMenu}
            buttonActive={buttonActive}
            setButtonActive={setButtonActive}
          />
          <SideBarNavLink
            title="درخواست ها"
            icon={<PiChats />}
            titleButtonActive="req-received"
            link="/requests/received"
            showMenu={showMenu}
            buttonActive={buttonActive}
            setButtonActive={setButtonActive}
          />
          {/* <SideBarNavLink
            title="گزارش ها"
            icon={<PiClipboardText />}
            titleButtonActive="received"
            link="/reports/received"
            showMenu={showMenu}
            buttonActive={buttonActive}
            setButtonActive={setButtonActive}
          /> */}
          <SideBarNavLink
            title="مدارک و سوابق"
            icon={<PiFolders />}
            titleButtonActive="documents-and-records"
            link="/documents-and-records/documents/internal-documents"
            showMenu={showMenu}
            buttonActive={buttonActive}
            setButtonActive={setButtonActive}
          />
          <SideBarNavLink
            title="ساختار سازمانی"
            icon={<PiGitFork />}
            titleButtonActive="tree-chart"
            link="/chart/tree-chart"
            showMenu={showMenu}
            buttonActive={buttonActive}
            setButtonActive={setButtonActive}
          />
          <SideBarNavLink
            title="مدیریت فرایند"
            icon={<PiCirclesThreePlus />}
            titleButtonActive="process-management"
            link="/process-management/model"
            showMenu={showMenu}
            buttonActive={buttonActive}
            setButtonActive={setButtonActive}
          />
          <SideBarNavLink
            title="مدیریت جلسات"
            icon={<PiMonitorPlay />}
            titleButtonActive="profile-meeting"
            link="/managment-of-meetings/profile-meeting/all-profiles"
            showMenu={showMenu}
            buttonActive={buttonActive}
            setButtonActive={setButtonActive}
          />
          <SideBarNavLink
            title="مخزن دانش"
            icon={<PiArchive />}
            titleButtonActive="library"
            link="/library?type=all&sort=date"
            showMenu={showMenu}
            buttonActive={buttonActive}
            setButtonActive={setButtonActive}
          />
          <SideBarNavLink
            title="مقاله"
            icon={<PiFileText />}
            titleButtonActive="blog"
            link="/blog"
            showMenu={showMenu}
            buttonActive={buttonActive}
            setButtonActive={setButtonActive}
          />
        </ul>
      </nav>
      <UserComponent showMenu={showMenu} />
    </div>
  );
};

export default Sidebar;
