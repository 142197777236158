import { useSearchParams } from "react-router-dom";
import VersionBoxActive from "../VersionBoxActive";
import { useEffect } from "react";

const MainHistory = ({ data }) => {
  const [serachParams, setSearchParams] = useSearchParams();
  const ver = serachParams.get("ver");

  function handleFilterChange(key, value) {
    setSearchParams((prevParams) => {
      if (value === null || value === "") {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    });
  }

  // useEffect(() => {
  //   if (!ver) {
  //     handleFilterChange("ver", data?.version);
  //   }
  // }, []);

  return (
    <div className="flex items-center gap-2 z-50">
      {/* <VersionBoxActive
        title={0}
        obsolete={true}
        date={data?.versionDate}
        zIndex={0}
        startVersion={true}
        active={ver === "0" ? true : false}
        onClick={() => {
          handleFilterChange("ver", 0);
        }}
      /> */}
      <VersionBoxActive
        title={data?.version}
        obsolete={data?.obsolete}
        date={data?.versionDate}
        zIndex={data?.version}
        active={ver === data?.version ? true : false}
        onClick={() => {
          ver !== data?.version && handleFilterChange("ver", data?.version);
        }}
        startVersion={data?.version === "1" ? true : false}
      />
    </div>
  );
};

export default MainHistory;
