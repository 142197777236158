import React, { useContext } from "react";
import { toast } from "react-toastify";
import {
  PiBookmarkSimple,
  PiBookmarkSimpleFill,
  PiCaretLeftBold,
  PiCloudArrowDown,
  PiEye,
  PiPlayCircle,
} from "react-icons/pi";
import { AuthContext } from "../../../context/auth/AuthContext";
import API from "../../../api/API";

const TableTilesSingle = ({
  id,
  title,
  abstract,
  imagesLibrary,
  subjectEn,
  setShowPopup,
  setShowPopupPodCast,
  filesLibrary,
  typeLibrary,
  setdataId,
  setShowDetailsTiles,
  setPopupData,
  setPopupPodCastData,
  language,
  profileFavorite,
  setFavorite,
}) => {
  const { token } = useContext(AuthContext);
  const favoriteHandler = async (idf) => {
    await API.patch(
      `/api/v1/library/favorite/${idf}/`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        setFavorite(1);
        setShowDetailsTiles(false);
        toast.info(
          `${
            title.length > 40 ? title.slice(0, 40) + "..." : title
          } به علاقه مندی ها اضافه شد`,
          {
            className: "toast-success-library",
            bodyClassName: "toast-success-library",
          }
        );
      } else if (res.status === 204) {
        setFavorite(1);
        setShowDetailsTiles(false);
        toast.info(
          `${
            title.length > 40 ? title.slice(0, 40) + "..." : title
          } از علاقه مندی ها حذف شد`,
          {
            className: "toast-faild-library",
            bodyClassName: "toast-faild-library",
          }
        );
      }
    });
  };

  return (
    <div className="outline outline-1 p-2 outline-caspian-gray2 rounded-xl">
      <div className="flex flex-col items-center justify-between h-full gap-4 z-0">
        <div className="flex flex-col gap-8 h-auto w-full">
          {imagesLibrary !== undefined && imagesLibrary.length > 0 ? (
            <div className="relative w-full h-[120px] rounded-xl overflow-hidden flex items-center justify-center">
              <img
                src={imagesLibrary[0]?.image}
                alt={title}
                className="w-auto h-auto"
              />
              {typeLibrary === "film" && filesLibrary[0]?.fileDocument && (
                <div className="absolute top-1/2 -translate-y-1/2 right-1/2 translate-x-1/2 z-20 cursor-pointer">
                  <img
                    onClick={() => {
                      setShowPopup(true);
                      setPopupData({
                        title,
                        file: filesLibrary[0]?.fileDocument,
                        picture: imagesLibrary[0].image,
                      });
                    }}
                    src="/assets/images/library/video/player.svg"
                    alt={title}
                    className="w-auto h-auto"
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="relative w-full h-[120px] rounded-xl overflow-hidden flex items-center justify-center bg-[#f5f5f5]">
              <img
                src={`/assets/images/library/${
                  (typeLibrary === "book" && "icon_book.svg") ||
                  (typeLibrary === "standard" && "icon_iso.svg") ||
                  (typeLibrary === "article" && "icon_article.svg") ||
                  (typeLibrary === "podcast" && "icon_audio.svg")
                }`}
                alt={title}
                className="w-[55px] h-auto"
              />
            </div>
          )}
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-1">
              {language === "EN" ? (
                <h2 className="leading-4 text-xs line-clamp-2 text-left text-caspian-gray">
                  {title}
                </h2>
              ) : (
                <h2 className="leading-4 text-xs line-clamp-2 text-caspian-gray">
                  {title}
                </h2>
              )}
              {/* <p className="text-xs text-caspian-gray line-clamp-3">
                {abstract}
              </p> */}
              <button
                onClick={() => {
                  setdataId(id);
                  setShowDetailsTiles(true);
                }}
                className="text-caspian-secondary-500 text-xs w-fit mr-auto flex items-center gap-1"
              >
                <span>جزییات بیشتر</span>
                <PiCaretLeftBold className="text-lg" />
              </button>
            </div>
          </div>
        </div>
        <div className="flex mr-auto justify-end gap-2">
          {profileFavorite ? (
            <PiBookmarkSimpleFill
              onClick={() => favoriteHandler(id)}
              className="cursor-pointer text-xl text-caspian-secondary-500"
            />
          ) : (
            <PiBookmarkSimple
              onClick={() => {
                favoriteHandler(id);
              }}
              className="cursor-pointer text-xl text-caspian-secondary-500"
            />
          )}

          {typeLibrary === "podcast" && (
            <button
              onClick={() => {
                setShowPopupPodCast(true);
                setPopupPodCastData({
                  title,
                  file: filesLibrary[0]?.fileDocument,
                });
              }}
            >
              <PiPlayCircle className="text-xl text-caspian-secondary-500" />
            </button>
          )}
          {typeLibrary === "film" &&
            filesLibrary !== undefined &&
            filesLibrary.length > 0 && (
              <button
                onClick={() => {
                  setShowPopup(true);
                  setPopupData({
                    title,
                    file: filesLibrary[0]?.fileDocument,
                    picture: imagesLibrary[0].image,
                  });
                }}
              >
                <PiPlayCircle className="text-xl text-caspian-secondary-500" />
              </button>
            )}
          {filesLibrary !== undefined && filesLibrary.length > 0 && (
            <a
              download={filesLibrary[0].fileDocument}
              href={filesLibrary[0].fileDocument}
            >
              <PiCloudArrowDown className="text-xl text-caspian-secondary-500" />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default TableTilesSingle;
