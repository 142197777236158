import { useSearchParams } from "react-router-dom";

const SingleTableRecords = ({
  title,
  code,
  preservation,
  privacy,
  responsible,
  showDetails,
  setShowDetails,
}) => {

  const [searchParams, setSearchParams] = useSearchParams();
  const c = searchParams.get("code");

  function handleCode(key, value) {
    setSearchParams((prevParams) => {
      if (value === null || value === "") {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    });
  }

  return (
    <>
      <tr
        onClick={() => {
          setShowDetails(true);
          handleCode("code", code);
        }}
        className={`${
          showDetails
            ? "bg-[#F2F8FF]"
            : "bg-caspian-surface"
        } cursor-pointer border-b border-b-caspian-gray2/50`}
      >
        <td className="whitespace-nowrap text-center px-3 py-2">
          <h2>{title}</h2>
        </td>
        <td className="whitespace-nowrap text-center font-sans py-2">
          <h2>{code}</h2>
        </td>
        <td className="whitespace-nowrap text-center px-3 py-2">
          <h2>{preservation}</h2>
        </td>
        <td className="whitespace-nowrap text-center px-3 py-2">
          <h2>{privacy}</h2>
        </td>
        <td className="whitespace-nowrap text-center px-3 py-2">
          <div className="flex items-center justify-between w-full">
            <h2>{responsible}</h2>
          </div>
        </td>
      </tr>
    </>
  );
};

export default SingleTableRecords;
