import { PiClipboardText } from "react-icons/pi";
import ListItemSingle from "../process-management-components/ListItemSingle";
import { useAppContext } from "../../context/app-context/AppContext";
import { useLocation } from "react-router-dom";

const DocumentsRecordsNavbar = ({ buttonActive }) => {
  const { setBradCrump2, setBradCrump3 } = useAppContext();
  const location = useLocation();
  const search = (location.state && location.state.search) || "";

  return (
    <nav className="w-full bg-caspian-surface rtl">
      <ul className="flex items-center gap-0.5">
        <ListItemSingle
          title="اسناد و مدارک"
          active="documents"
          icon={<PiClipboardText />}
          link="/documents-and-records/documents/internal-documents"
          buttonActive={buttonActive}
          onClick={() => setBradCrump2("اسناد و مدارک")}
        />
        <ListItemSingle
          title="لیست سوابق"
          active="list-records"
          icon={<PiClipboardText />}
          link={`/documents-and-records/list-records${search}`}
          buttonActive={buttonActive}
          onClick={() => {
            setBradCrump2("لیست سوابق");
            setBradCrump3("");
          }}
        />
      </ul>
    </nav>
  );
};

export default DocumentsRecordsNavbar;
