import React, { useContext, useEffect, useState } from "react";
import { PiArrowsClockwise, PiPlusCircleFill, PiX } from "react-icons/pi";
import SearchInput from "./SearchInput";
import HistoryBox from "./HistoryBox";
import API from "../../../api/API";
import { AuthContext } from "../../../context/auth/AuthContext";
import MenuActionMain from "../MenuActionMain";

const MainHistoryChat = ({
  setFavoritesRefresh,
  setShowChatBox,
  setShowContactChat,
  setShowChatContent,
  setIsIdConversation,
  setIsIdUser,
  refreshMyconversation,
  setRefreshMyconversation,
  setChatType,
  setGroupName,
  setFavoritesShow,
}) => {
  const { token, user } = useContext(AuthContext);
  const [allHistoryChat, setAllHistoryChat] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setRefreshMyconversation(1);
  }, [setRefreshMyconversation]);
  useEffect(() => {
    const messageHandler = async () => {
      if (!token || refreshMyconversation === 0) return;
      try {
        setLoading(true);
        const res = await API.get(`/api/v1/message/myconversations/`, {
          headers: { Authorization: token },
        });
        console.log(res.data);
        setAllHistoryChat(res?.data);
        setRefreshMyconversation(0);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    messageHandler();
  }, [token, refreshMyconversation, setRefreshMyconversation]);

  let searchHistory = [];
  const [searchAllHistoryChat, setSearchAllHistoryChat] = useState("");
  if (allHistoryChat.length > 0) {
    searchHistory = searchAllHistoryChat
      ? allHistoryChat.filter((d) =>
          d?.convName
            ?.toLocaleLowerCase()
            ?.includes(searchAllHistoryChat?.toLocaleLowerCase())
        )
      : allHistoryChat;
  }

  return (
    <div className="shadow-[0_0_5px_#00000050] min-w-[280px] max-w-[280px] min-h-[460px] max-h-[460px] bg-caspian-primary-50 rounded-md flex flex-col fixed bottom-16 left-28 z-40">
      <div className="flex items-center justify-between w-full p-2 border-b border-b-caspian-gray2">
        <PiX
          onClick={() => {
            setFavoritesShow(false);
            setShowChatBox(false);
          }}
          className="text-caspian-gray4 text-xl cursor-pointer"
        />
        <div className="flex items-center gap-2">
          <PiArrowsClockwise
            onClick={() => setRefreshMyconversation(1)}
            className={`z-50 text-xl cursor-pointer text-caspian-gray4 ${
              loading ? "animate-spin" : ""
            }`}
          />
          <MenuActionMain
            setFavoritesRefresh={setFavoritesRefresh}
            setFavoritesShow={setFavoritesShow}
            setShowChatBox={setShowChatBox}
          />
          {/* <PiDotsThreeCircle className="text-caspian-gray4 text-xl cursor-pointer" /> */}
        </div>
      </div>
      <div className="w-full flex items-center gap-2 p-2">
        <SearchInput
          searchAllHistoryChat={searchAllHistoryChat}
          setSearchAllHistoryChat={setSearchAllHistoryChat}
        />
        <button>
          <PiPlusCircleFill
            onClick={() => setShowContactChat(true)}
            className="text-caspian-secondary-500 text-3xl"
          />
        </button>
      </div>
      <div className="overflow-y-auto min-h-[360px] max-h-[360px] w-full p-2">
        {searchHistory[0] === -1 ? (
          <div className="flex items-center justify-center pt8">
            <span className="text-xs text-caspian-gray4">
              در حال بارگزاری...
            </span>
          </div>
        ) : searchHistory?.length < 1 ? (
          <div className="flex items-center justify-center pt8">
            <span className="text-xs text-caspian-gray4">
              پیام ها خالی می باشد
            </span>
          </div>
        ) : (
          searchHistory.map((message, index) => (
            <HistoryBox
              setRefreshMyconversation={setRefreshMyconversation}
              setGroupName={setGroupName}
              thisGroupName={message?.convName}
              setChatType={setChatType}
              thisChatType={message?.conversationRelated?.convType}
              setIsIdConversation={setIsIdConversation}
              setIsIdUser={setIsIdUser}
              userId={
                +message?.conversationRelated?.convCode.split(",")[0] ===
                user?.placementProfileRelated?.user
                  ? +message?.conversationRelated?.convCode.split(",")[1]
                  : +message?.conversationRelated?.convCode.split(",")[0]
              }
              convId={+message?.conversationRelated?.id}
              key={index}
              convName={message?.convName}
              lastMessage={
                message?.lastMessage ? message?.lastMessage : "بدون پیام..."
              }
              newMessage={message?.countUnread}
              setShowChatContent={setShowChatContent}
              avatar={message?.avatar}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default MainHistoryChat;
