import React, { useEffect, useState } from 'react'
import SingleTableListGoalActionplan from './SingleTableListGoalActionplan'
import { useSearchParams } from 'react-router-dom';
import RowViewProfileGoal from './RowViewProfileGoal';
import PaginateProfileGoal from './PaginateProfileGoal';

const TableListGoalActionplan = ({ sortData }) => {

    const [searchParams] = useSearchParams();
    const search = searchParams.get("search");
    const page = searchParams.get("page");
    const rows = searchParams.get('rows');
    useEffect(() => {
        if (search) {
            setCurrentPage(1);
        }
    }, [search]);

    const [currentPage, setCurrentPage] = useState(page ? page : 1);
    const [recordsPerPage, setRecordsPerPage] = useState(rows ? rows : 10);
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = sortData.slice(firstIndex, lastIndex);
    const npage = Math.ceil(sortData.length / recordsPerPage);


    return (
        <div className='w-full gap-5 overflow-auto flex flex-col items-center '>

            <div className='w-full overflow-auto '>
                <table className='min-w-[992px] table-fixed w-full flex items-center justify-center flex-col text-caspian-surface' >
                    <thead className='w-full h-8'>
                        <tr className='w-full flex justify-between'>
                            <th className='flex items-center justify-center h-8 bg-caspian-primary-500 w-[14%] text-xs font-thin 2xl:text-[15px] rounded-tr-lg'>عنوان</th>
                            <th className='flex items-center justify-center h-8 bg-caspian-primary-500 w-[8%]  text-xs font-thin 2xl:text-[15px]'>کد</th>
                            <th className='flex items-center justify-center h-8 bg-caspian-primary-500 w-[13%] text-xs font-thin 2xl:text-[15px]'>مسئول برنامه</th>
                            <th className='flex items-center justify-center h-8 bg-caspian-primary-500 w-[8%]  text-xs font-thin 2xl:text-[15px]'>شروع</th>
                            <th className='flex items-center justify-center h-8 bg-caspian-primary-500 w-[8%]  text-xs font-thin 2xl:text-[15px]'></th>
                            <th className='flex items-center justify-center h-8 bg-caspian-primary-500 w-[8%]  text-xs font-thin 2xl:text-[15px]'>پایان</th>
                            <th className='flex items-center justify-center h-8 bg-caspian-primary-500 w-[14%] text-xs font-thin 2xl:text-[15px]'>هزینه </th>
                            <th className='flex items-center justify-center h-8 bg-caspian-primary-500 w-[8%] text-xs font-thin 2xl:text-[15px]'>تاریخ تصویب</th>
                            <th className='flex items-center justify-center h-8 bg-caspian-primary-500 w-[12%] text-xs font-thin 2xl:text-[15px]'>درصد پیشرفت</th>
                            <th className='flex items-center justify-center h-8 bg-caspian-primary-500 w-[12%] text-xs font-thin 2xl:text-[15px] rounded-tl-lg'>وضعیت</th>
                        </tr>
                    </thead>

                    <tbody className='w-full text-caspian-on_primary bg-caspian-surface'>

                        {records?.length > 0 ? records?.map((item, index) => (
                            <SingleTableListGoalActionplan key={index} item={item} />
                        )) :
                            <tr className='py-5 w-full flex flex-col gap-3 items-center justify-center'>
                                <td className='w-[220px] h-[220px] flex flex-col justify-center'>
                                    <img src="/assets/images/library/not-found.svg" alt="هدف یافت نشد..." />
                                    <h2 className='text-center text-sm'> مورد یافت نشد...</h2>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>

            </div>
            {records?.length > 0 &&
                <div className="flex items-center w-full px-10 py-5 justify-between">
                    <RowViewProfileGoal
                        setRecordsPerPage={setRecordsPerPage}
                        setCurrentPage={setCurrentPage}
                    />
                    <PaginateProfileGoal
                        pageCount={npage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                    <div className='flex gap-3 text-xs 2xl:text-sm'>
                        <span>نمایش {firstIndex + 1}</span>
                        <span>تا {lastIndex - (recordsPerPage - records?.length)}</span>
                        <span>از کل {sortData?.length}</span>
                    </div>
                </div>
            }
        </div>

    )
}

export default TableListGoalActionplan