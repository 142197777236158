import { useOutletContext } from "react-router-dom";
import MainListProfileMeeting from "./list-profile-meeting/MainListProfileMeeting";

const MainAllProfiles = () => {
  const [refresh, setRefresh, allProfileNumber, setAllProfileNumber] =
    useOutletContext();

  return (
    <MainListProfileMeeting
      refresh={refresh}
      setRefresh={setRefresh}
      allProfileNumber={allProfileNumber}
      setAllProfileNumber={setAllProfileNumber}
    />
  );
};
export default MainAllProfiles;
