import React, { useEffect, useState } from 'react'
import SingleListGoalIndicator from './SingleListGoalIndicator'
import API from '../../../../../api/API'

const MainListGoalIndicator = ({ data }) => {


    return (
        <div className='flex gap-4 pb-6 flex-col w-full items-start'>
            <span className='px-12'>شاخص ها : </span>

            <div className='flex w-full flex-col items-center '>

                {data?.objectiveRelatedIndicator?.map((item, index) => (
                    <SingleListGoalIndicator key={index} item={item}  {...item} />
                ))
                }
            </div>

        </div>
    )
}

export default MainListGoalIndicator