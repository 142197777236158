import React, { useContext, useEffect, useState } from "react";
import { PiPlusCircle } from "react-icons/pi";
import SingleList from "./SingleList";
import MainCreateForm from "./form/create/MainCreateForm";
import { useParams } from "react-router-dom";
import API from "../../../../../../api/API";
import { AuthContext } from "../../../../../../context/auth/AuthContext";

const MainTask = ({ setRefreshBrief, setActiveTab, isWeight, berifWeight }) => {
  const params = useParams();
  const { token } = useContext(AuthContext);
  const [refresh, setRefresh] = useState(0);
  const [taskData, setTaskData] = useState([]);
  const [showCreateTask, setShowCreateTask] = useState(false);

  useEffect(() => {
    setRefresh(1);
  }, []);
  useEffect(() => {
    if (!token || refresh === 0) return;
    const getTask = () => {
      API.get(`/api/v1/actionplan/actionplan/tasks/${params?.id}`, {
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          setTaskData(res.data);
          console.log(res?.data);
          setRefresh(0);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getTask();
  }, [token, refresh, params?.id]);

  return (
    <div className="flex flex-col justify-between h-full gap-4">
      <div className="flex flex-col gap-4 h-full">
        {isWeight && (
          <button
            onClick={() => setShowCreateTask(true)}
            className="w-fit flex items-center gap-1 rounded-md border border-caspian-secondary-500 text-caspian-secondary-500 text-sm p-2"
          >
            <PiPlusCircle className="text-xl" />
            <span className="text-sm">ایجاد وظیفه</span>
          </button>
        )}
        {showCreateTask && (
          <MainCreateForm
            berifWeight={berifWeight}
            setRefreshBrief={setRefreshBrief}
            actionplanIdRelated={params?.id}
            setShowCreateTask={setShowCreateTask}
            setRefresh={setRefresh}
          />
        )}
        {taskData.length < 0 ? (
          <div className="flex items-center justify-center w-full">
            <span className="text-xs text-caspian-gray4">
              وظیفه‌ای وجود ندارد!
            </span>
          </div>
        ) : (
          <div className="p-0.5 max-h-[300px] overflow-y-scroll">
            <table className="rounded-md overflow-hidden outline outline-1 outline-caspian-gray1 min-w-full">
              <thead className="table-fixed w-full text-xs text-caspian-gray text-right">
                <tr className="bg-caspian-gray3 w-full">
                  <th className="p-2">عنوان</th>
                  <th className="p-2">شروع</th>
                  <th className="p-2">پایان</th>
                  <th className="p-2">مجری</th>
                  <th className="p-2"></th>
                </tr>
              </thead>
              <tbody className="w-full text-xs text-caspian-gray4 text-right">
                {taskData.map((d, i) => (
                  <SingleList
                    key={i}
                    id={d.id}
                    title={d.title}
                    startDate={d.startDate}
                    endDate={d.finishDate}
                    supervisor={
                      d.responsible_task?.firstName +
                      " " +
                      d.responsible_task?.lastName
                    }
                    setRefresh={setRefresh}
                    setRefreshBrief={setRefreshBrief}
                  />
                ))}
              </tbody>
            </table>
          </div>
        )}
        <button
          disabled={taskData?.length > 0 ? false : true}
          onClick={() => setActiveTab("complete")}
          className="bg-caspian-secondary-500 text-caspian-surface text-xs py-1.5 px-6 rounded-md w-fit mt-20 disabled:bg-caspian-gray2 disabled:text-caspian-gray4"
        >
          ثبت برنامه
        </button>
      </div>
    </div>
  );
};

export default MainTask;
