import React from 'react'
import { PiCloudArrowDownLight, PiX } from 'react-icons/pi'
import Modal from '../../../../../../modal/Modal'
import convertFileSize from '../../../../../../../helper/convertFileSize'
import splitNameFile from '../../../../../../../helper/splitNameFile'

const DetailsDoc = ({ setShowModalDetail, listDoc, selectItemIndex }) => {


    return (
        <Modal>
            {listDoc?.map((item, index) => (
                item?.id === selectItemIndex &&

                <div key={index} className='flex flex-col items-center w-[350px] md:w-[450px] gap-5 p-5 rounded-lg shadow-md bg-caspian-surface' >
                    <div className='flex items-center justify-between w-full'>
                        <span className='text-xs 2xl:text-base'>جزئیات سند</span>
                        <PiX onClick={() => setShowModalDetail(false)}
                            className='text-base cursor-pointer text-caspian-gray4 2xl:text-2xl' />
                    </div>

                    <div className='flex flex-col items-center w-full gap-5 '>
                        <div className='flex items-center w-full gap-5 border-b-2 border-caspian-gray2'>
                            <span className='py-3 text-xs 2xl:text-sm text-caspian-gray4'>عنوان سند :</span>
                            <span className='text-xs 2xl:text-sm'>{item?.name}</span>
                        </div>

                        <div className='flex items-start pb-3 w-full gap-5 border-b-2 border-caspian-gray2'>
                            <span className='text-xs 2xl:text-sm whitespace-nowrap text-caspian-gray4'>توضیحات :</span>
                            <span className='text-xs 2xl:text-sm'>{item?.description ? item?.description : "-"}</span>
                        </div>


                        <div className='flex flex-col items-start w-full '>
                            <span className='py-3 text-xs 2xl:text-sm text-caspian-gray4'>فایل بارگذاری شده سند :</span>

                            <div className='flex items-center w-full gap-3'>
                                <div className='w-[90%] px-4 py-2 flex items-center justify-between border-b-2 rounded-md  border-caspian-gray2'>
                                    <div className='flex items-center w-full gap-5 '>
                                        <img src='/assets/images/action-plan/Group.svg' className='w-4 2xl:w-6' alt='' />
                                        <span className='text-xs ltr'>{splitNameFile(item?.fileDocument)}</span>
                                    </div>

                                    <span className='text-xs  text-caspian-gray4'>{convertFileSize(item?.size)}</span>

                                </div>
                                <a href={item?.fileDocument} rel="noreferrer" target='_blank' download={true}>
                                    <PiCloudArrowDownLight className='text-2xl cursor-pointer text-caspian-secondary-600' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            ))
            }
        </ Modal >
    )
}

export default DetailsDoc