import React from 'react'
import ButtonChart from '../ButtonChart'

const Chart8 = ({ sidebar }) => {
    return (
        <div className={`flex flex-col items-center w-full min-w-[1250px] max-w-[1250px] 
            ${!sidebar ? "mx-10 2xl:mx-10" : "mx-0 "}
            `}>

            <div className="flex flex-col w-full items-center h-full">
                {/* 1 */}
                <ButtonChart
                    title="مديريت ناحیه یک (1)"
                    bgColor="#DDEAF4"
                    textColor="#4B6B90"
                    borderColor="#4B6B90"
                />
                {/* 1 */}
            </div>

            <div className="h-5 border-r-2 border-r-caspian-gray"></div>

            <div className="flex flex-col w-full items-center">
                {/* 3 */}
                <div className="flex justify-center relative h-64 gap-10 items-center">
                    <div className="flex flex-col gap-2 h-full items-center">
                        <div className="flex items-center justify-center">
                            <ButtonChart
                                title="متصدی امور دفتری و کاربر رایانه(1)"
                                bgColor="#C7EBEA"
                                textColor="#225C58"
                                borderColor="#225C58">
                            </ButtonChart>
                            <div className="w-14 absolute right-[47%] h-2 border-b-2 border-b-caspian-gray"></div>
                        </div>

                        <div className="flex items-center justify-center">
                            <ButtonChart
                                title="کارشناس اداری (1)"
                                bgColor="#C7EBEA"
                                textColor="#225C58"
                                borderColor="#225C58">
                            </ButtonChart>
                            <div className="w-14 absolute right-[47%] h-2 border-b-2 border-b-caspian-gray"></div>
                        </div>

                        <div className="flex items-center justify-center">
                            <ButtonChart
                                title="*کارگر خدمات عمومی(3)"
                                bgColor="#C7EBEA"
                                textColor="#225C58"
                                borderColor="#225C58">
                            </ButtonChart>
                            <div className="w-14 absolute right-[47%] h-2 border-b-2 border-b-caspian-gray"></div>
                        </div>

                        <div className="flex items-center justify-center">
                            <ButtonChart
                                title="*نگهبان (2)"
                                bgColor="#C7EBEA"
                                textColor="#225C58"
                                borderColor="#225C58">
                            </ButtonChart>
                            <div className="w-14 absolute right-[47%] h-2 border-b-2 border-b-caspian-gray"></div>
                        </div>
                    </div>

                    <div className="h-full border-r-2 border-r-caspian-gray"></div>

                    <div className="flex flex-col gap-2  h-full items-center">
                        <div className="flex items-center justify-center">
                            <ButtonChart
                                title="کارشناس مسئول حراست و امور محرمانه(1)"
                                bgColor="#C7EBEA"
                                textColor="#225C58"
                                borderColor="#225C58">
                            </ButtonChart>
                            <div className="w-14 absolute left-[47%] h-2 border-b-2 border-b-caspian-gray"></div>
                        </div>

                        <div className="flex items-center justify-center">
                            <ButtonChart
                                title="کارشناس بهداشت،ایمنی و حفاظت کار(1)"
                                bgColor="#C7EBEA"
                                textColor="#225C58"
                                borderColor="#225C58">
                            </ButtonChart>
                            <div className="w-14 absolute left-[47%] h-2 border-b-2 border-b-caspian-gray"></div>
                        </div>

                        <div className="flex items-center justify-center">
                            <ButtonChart
                                title="کارشناس خدمات عمومی(1)"
                                bgColor="#C7EBEA"
                                textColor="#225C58"
                                borderColor="#225C58">
                            </ButtonChart>
                            <div className="w-14 absolute left-[47%] h-2 border-b-2 border-b-caspian-gray"></div>
                        </div>

                        <div className="flex items-center justify-center">
                            <ButtonChart
                                title="*راننده خودرو سبک (3)"
                                bgColor="#C7EBEA"
                                textColor="#225C58"
                                borderColor="#225C58">
                            </ButtonChart>
                            <div className="w-14 absolute left-[47%] h-2 border-b-2 border-b-caspian-gray"></div>
                        </div>
                    </div>
                </div>
                {/* 3 */}

                <div className="flex w-full flex-col justify-center relative items-center">
                    <div className={`h-2 border-t-2 border-t-caspian-gray 
                        ${!sidebar
                            ? "translate-x-[3.2%] 2xl:translate-x-[3.2%] w-[1008px] 2xl:w-[1008px]"
                            : "translate-x-[3.2%] 2xl:translate-x-[3.2%] w-[1008px] 2xl:w-[1008px]"}`}></div>
                    {/* 4 */}
                    <div className="flex w-full items-start pt-5 gap-4">
                        <div className="flex flex-col gap-2 h-full items-center">
                            <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
                            <div className="flex items-center relative h-full">
                                <div className="h-[70%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>
                                <div className="flex flex-col items-center gap-2 h-full">
                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="رئیس قسمت فنی و اجرایی آب و فاضلاب(1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس فنی آب (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>

                                    <div className="flex items-center justify-center">
                                        <ButtonChart
                                            title="کارشناس فنی فاضلاب (1)"
                                            bgColor="#C7EBEA"
                                            textColor="#225C58"
                                            borderColor="#225C58">
                                        </ButtonChart>
                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col h-full w-full items-center">
                            <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
                            <div className="flex items-center flex-col relative w-full h-full">
                                <div className='flex flex-col items-center gap-2'>
                                    <div className="flex flex-col items-center h-full">
                                        <div className="flex items-center justify-center">
                                            <ButtonChart
                                                title="مدیر امور بهره برداری از شبکه های آب و فاضلاب(1)"
                                                bgColor="#C7EBEA"
                                                textColor="#225C58"
                                                borderColor="#225C58">
                                            </ButtonChart>
                                        </div>
                                    </div>

                                    <div className="h-11 absolute top-12 border-r-2 border-r-caspian-gray"></div>

                                    <div className="flex justify-center w-full relative h-0 gap-10 items-center">
                                        <div className="flex flex-col h-full items-center">
                                            <div className="flex items-center relative">
                                                <ButtonChart
                                                    title="معاون مهندسی و توسعه (1)"
                                                    bgColor="#C7EBEA"
                                                    textColor="#225C58"
                                                    borderColor="#225C58">
                                                </ButtonChart>
                                                <div className="w-14 h-2 absolute -left-14 border-b-2 border-b-caspian-gray"></div>
                                            </div>
                                        </div>

                                        <div className="flex flex-col gap-2 h-full items-center">
                                            <div className="flex items-center justify-center">
                                                <div className="flex items-center justify-center">
                                                    <div className="w-[250px]"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="h-16 border-r-2 border-r-caspian-gray"></div>

                                <div className='flex flex-col items-center relative w-[650px]'>
                                    <div className="w-[470px] h-5 border-t-2 border-t-caspian-gray"></div>

                                    <div className="flex w-full items-start justify-between ">
                                        <div className="flex flex-col gap-2 h-full items-center">
                                            <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
                                            <div className="flex items-center relative h-full">
                                                <div className="flex flex-col items-center gap-2 h-full">
                                                    <div className="flex items-center justify-center">
                                                        <ButtonChart
                                                            title="رئیس اداره نگهداری و تعمیرات آب و فاضلاب (1)"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChart>
                                                    </div>

                                                    <div className="h-2 absolute top-[30.5%] border-l-2 border-l-caspian-gray"></div>

                                                    <div className="flex items-center justify-center">
                                                        <ButtonChart
                                                            title="کارشناس نگهداری و تعمیرات آب و فاضلاب (1)"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChart>
                                                    </div>

                                                    <div className="h-2 absolute top-[65%] border-l-2 border-l-caspian-gray"></div>

                                                    <div className="flex items-center justify-center">
                                                        <ButtonChart
                                                            title="تکنسین نگهداری و تعمیرات آب و فاضلاب(1)"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChart>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex flex-col gap-2 h-full items-center">
                                            <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
                                            <div className="flex items-center relative h-full">
                                                <div className="flex flex-col items-center gap-2 h-full">
                                                    <div className="flex items-center justify-center">
                                                        <ButtonChart
                                                            title="رئیس اداره بهره برداری فاضلاب (1)"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChart>
                                                    </div>

                                                    <div className="h-2 absolute top-[46.5%] border-l-2 border-l-caspian-gray"></div>

                                                    <div className="flex items-center justify-center">
                                                        <ButtonChart
                                                            title="کارشناس بهره برداری فاضلاب(2)"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChart>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex flex-col gap-2 h-full items-center">
                                            <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
                                            <div className="flex items-center relative h-full">
                                                <div className="flex flex-col items-center gap-2 h-full">
                                                    <div className="h-[82%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>
                                                    <div className="flex items-center justify-center">
                                                        <ButtonChart
                                                            title="رئیس اداره امداد و حوادث آب(1)"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChart>
                                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>

                                                    <div className="flex items-center justify-center">
                                                        <ButtonChart
                                                            title="کارشناس بهره برداری آب و فاضلاب(1)"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChart>
                                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>

                                                    <div className="flex items-center justify-center">
                                                        <ButtonChart
                                                            title="تکنسین بهره برداری از شبکه های آبرسانی(2)"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChart>
                                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>

                                                    <div className="flex items-center justify-center">
                                                        <ButtonChart
                                                            title="*سرشیفت امداد و حوادث (2)"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChart>
                                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>

                                                    <div className="flex items-center justify-center">
                                                        <ButtonChart
                                                            title="*کار گرفنی آب و فاضلاب(13)"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChart>
                                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col h-full w-full items-center">
                            <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
                            <div className="flex items-center flex-col relative w-full h-full">
                                <div className='flex flex-col items-center gap-2'>
                                    <div className="flex flex-col items-center h-full">
                                        <div className="flex items-center justify-center">
                                            <ButtonChart
                                                title="مدیرامور مشترکین (1)"
                                                bgColor="#C7EBEA"
                                                textColor="#225C58"
                                                borderColor="#225C58">
                                            </ButtonChart>
                                        </div>
                                    </div>

                                    <div className="h-11 absolute top-12 border-r-2 border-r-caspian-gray"></div>

                                    <div className="flex justify-center w-full relative h-0 gap-10 items-center">
                                        <div className="flex flex-col h-full items-center">
                                            <div className="flex items-center relative">
                                                <ButtonChart
                                                    title="متصدی بایگانی مشترکین(1)"
                                                    bgColor="#C7EBEA"
                                                    textColor="#225C58"
                                                    borderColor="#225C58">
                                                </ButtonChart>
                                                <div className="w-14 h-2 absolute -left-14 border-b-2 border-b-caspian-gray"></div>
                                            </div>
                                        </div>

                                        <div className="flex flex-col gap-2 h-full items-center">
                                            <div className="flex items-center justify-center">
                                                <div className="flex items-center justify-center">
                                                    <div className="w-[250px]"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="h-16 border-r-2 border-r-caspian-gray"></div>
                                <div className='flex flex-col items-center relative w-[400px]'>
                                    <div className="w-[220px] h-5 border-t-2 border-t-caspian-gray"></div>

                                    <div className="flex w-full items-start justify-between ">
                                        <div className="flex flex-col gap-2 h-full items-center">
                                            <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
                                            <div className="flex items-center relative h-full">
                                                <div className="flex flex-col items-center gap-2 h-full">
                                                    <div className="h-[54%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>
                                                    <div className="flex items-center justify-center">
                                                        <ButtonChart
                                                            title="رئیس اداره درآمد و وصول مطالبات(1)"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChart>
                                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>

                                                    <div className="flex items-center justify-center">

                                                        <ButtonChart
                                                            title="کارشناس در آمد و وصول مطالبات(2)"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChart>
                                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex flex-col gap-2 h-full items-center">
                                            <div className="h-7 absolute border-l-2 top-0 border-l-caspian-gray"></div>
                                            <div className="flex items-center relative h-full">
                                                <div className="flex flex-col items-center gap-2 h-full">
                                                    <div className="h-[78%] absolute top-7 -right-5 border-l-2 border-l-caspian-gray"></div>
                                                    <div className="flex items-center justify-center">
                                                        <ButtonChart
                                                            title="رئیس اداره خدمات مشترکین (1)"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChart>
                                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>

                                                    <div className="flex items-center justify-center">
                                                        <ButtonChart
                                                            title="کارشناس خدمات مشترکین (4)"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChart>
                                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>

                                                    <div className="flex items-center justify-center">
                                                        <ButtonChart
                                                            title="کمک کارشناس خدمات مشترکین (1)"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChart>
                                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>

                                                    <div className="flex items-center justify-center">
                                                        <ButtonChart
                                                            title="کارشناس مسئول نظارت بر قرائت (1)"
                                                            bgColor="#C7EBEA"
                                                            textColor="#225C58"
                                                            borderColor="#225C58">
                                                        </ButtonChart>
                                                        <div className="w-5 h-2 absolute -right-5 border-b-2 border-b-caspian-gray"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 4 */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Chart8