import React, { useState, useEffect, useRef, useContext } from "react";
import { PiBell, PiMagnifyingGlassBold, PiXBold } from "react-icons/pi";
import API from "../../../api/API";
import TabNotification from "./TabNotification";
import { AuthContext } from "../../../context/auth/AuthContext";

const NotificationDropDown = () => {
  const { user, token } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState("cartable");
  const [searchByNotification, setSearchByNotification] = useState("");
  const [searchStyle, setSearchStyle] = useState(false);
  const searchRef = useRef();
  let searchNotification = [];
  let menuRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const [loading, setLoading] = useState(false);
  const [fullData, setFullData] = useState([-1]);

  const [cartableData, setCartableData] = useState([-1]);

  useEffect(() => {
    if (token) {
      setLoading(true);
      API.get("/api/v1/profile/activity", {
        headers: { Authorization: token },
      })
        .then((d) => {
          setCartableData(d.data);
        })
        .catch((err) => {
          console.log("error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [open, token]);

  useEffect(() => {
    if (token) {
      setLoading(true);
      API.get("/api/v1/profile/notifications", {
        headers: { Authorization: token },
      })
        .then((d) => {
          setFullData(d.data);
        })
        .catch((err) => {
          console.log("error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [open, token]);

  let data =
    tab === "cartable"
      ? cartableData?.length > 0 &&
        cartableData?.filter(
          (r) =>
            r?.receiver?.id ===
            (user?.placementProfileRelated !== undefined &&
              user?.placementProfileRelated.id)
        )
      : tab === "system"
      ? fullData?.length > 0 &&
        fullData?.filter(
          (r) =>
            r?.reciver ===
            (user?.placementProfileRelated !== undefined &&
              user?.placementProfileRelated.id)
        )
      : tab === "info" &&
        fullData?.length > 0 &&
        fullData?.filter(
          (r) =>
            r?.reciver ===
            (user?.placementProfileRelated !== undefined &&
              user?.placementProfileRelated.id)
        );

  if (data[0] !== -1) {
    searchNotification = searchByNotification
      ? data?.filter((d) =>
          d?.title
            .toLocaleLowerCase()
            .includes(searchByNotification.toLocaleLowerCase())
        )
      : data;
  }

  const [allNotification, setAllNotification] = useState(0);
  useEffect(() => {
    setAllNotification(
      fullData?.filter((r) => !r.personalStatus === "pending")?.length +
        cartableData.filter((r) => !r.is_readed).length
    );
  }, [fullData, cartableData]);

  return (
    <div ref={menuRef} className="relative">
      {
        <div
          onClick={() => setOpen(!open)}
          className="relative flex items-center justify-center cursor-pointer"
        >
          <PiBell className="text-2xl text-caspian-secondary-700" />
          {loading ? (
            <></>
          ) : allNotification > 0 ? (
            <span className="absolute -right-1 -top-1 bg-[#FF5B5B] text-caspian-primary-50 rounded-full text-xs p-0.5 w-4 h-4 flex items-center justify-center">
              {allNotification}
            </span>
          ) : (
            <></>
          )}
        </div>
      }
      <div
        className={
          open
            ? "w-auto rounded-md shadow-[0_0_5px_#00000050] bg-caspian-surface absolute top-8 left-0 z-50 overflow-hidden"
            : "hidden"
        }
      >
        <div className="flex flex-col gap-0 w-full">
          <div className="border-b border-b-caspian-gray2">
            <div className="flex items-center justify-between p-2 w-full drop-shadow-[0_0_2px_#00000030]">
              <span className="text-caspian-gray text-sm">اعلانات</span>

              <div className="relative flex items-center w-full">
                <input
                  onChange={(e) => {
                    setSearchByNotification(e.target.value);
                  }}
                  id="searchId"
                  ref={searchRef}
                  type="search"
                  placeholder="جستجو"
                  className={
                    searchStyle
                      ? "absolute left-0 top-1/2 -translate-y-1/2 rounded-s-md bg-caspian-gray3 p-1 font-light text-sm w-[80%] z-20 duration-500 -ml-2 pr-2 focus:outline-0 focus:outline-none"
                      : "absolute left-0 top-1/2 -translate-y-1/2 w-[0px] roundeds-s-md bg-caspian-gray3 py-1 text-sm z-20 duration-500 -ml-2 focus:outline-0 focus:outline-none"
                  }
                />
              </div>
              <label
                htmlFor="searchId"
                // type="button"
                className="bg-caspian-gray3 p-0.5 rounded-md z-50 cursor-pointer"
              >
                {searchStyle ? (
                  <PiXBold
                    onClick={() => {
                      setSearchStyle(false);
                      setSearchByNotification("");
                      searchRef.current.value = "";
                    }}
                    className="text-caspian-gray2 text-2xl"
                  />
                ) : (
                  <PiMagnifyingGlassBold
                    onClick={() => setSearchStyle(true)}
                    className="text-caspian-gray2 text-2xl"
                  />
                )}
              </label>
            </div>
          </div>

          <div className="overflow-hidden">
            <TabNotification
              setTab={setTab}
              data={searchNotification}
              loading={loading}
              setOpen={setOpen}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationDropDown;
