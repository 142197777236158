import React from "react";
import AddFiles from "./AddFiles";

const MainFilesMeeting = ({
  documentsFile,
  fullData,
  thisId,
  setRefresh,
  setApprovals,
  setFilesMeeting,
  setRefreshFullData,
}) => {
  console.log({ documentsFile, fullData, thisId });
  return (
    <AddFiles
      fullData={fullData}
      documentsFile={documentsFile}
      thisId={thisId}
      setRefresh={setRefresh}
      setRefreshFullData={setRefreshFullData}
      setApprovals={setApprovals}
      setFilesMeeting={setFilesMeeting}
    />
  );
};

export default MainFilesMeeting;
