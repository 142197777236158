import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../context/auth/AuthContext";
import { PiPencilSimpleLine, PiX } from "react-icons/pi";
import API from "../../../api/API";
import { useAppContext } from "../../../context/app-context/AppContext";
import PicturesView from "./PicturesView";

const ProfilePicture = () => {
  const { user, token, dispatch } = useContext(AuthContext);
  const { getAvatar, setGetAvatar } = useAppContext();
  const imageRef = useRef(null);
  const [file, setFile] = useState(null);
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    let formData = {
      avatar: file ? file : null,
    };
    if (file !== null) {
      const getImage = async () => {
        const res = await API.patch("/api/v1/profile/avatar", formData, {
          headers: {
            Authorization: token,
            "content-type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        setGetAvatar(res.data.avatar);
      };
      getImage();
      setFile(null);
    }
  }, [file, dispatch, token, user, setGetAvatar]);

  const removePicture = async () => {
    let formData = new FormData();
    await API.patch("/api/v1/profile/avatar", formData.append("avatar", null), {
      headers: {
        Authorization: token,
        "content-type": "multipart/form-data",
        Accept: "application/json",
      },
    })
      .then((res) => {
        console.log(res.data);
        setGetAvatar("/assets/images/users/not-picture.svg");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="flex items-center justify-between gap-4 w-full rounded-xl bg-caspian-surface outline outline-1 outline-caspian-gray2 p-4">
      <div className="flex items-center gap-4 relative">
        {/* <button
          onClick={removePicture}
          className="z-50 bg-caspian-error2 rounded-full absolute top-0 right-0"
        >
          <PiX className="text-xl text-caspian-surface" />
        </button> */}
        <div
          onClick={() => setShowImage(true)}
          className="cursor-pointer bg-caspian-gray2 rounded-full w-16 h-16 overflow-hidden"
        >
          <img
            src={
              getAvatar !== null
                ? getAvatar
                : user?.placementProfileRelated?.personalInfoProfileRelated[0]
                    ?.avatar
                ? API.defaults.baseURL +
                  user.placementProfileRelated.personalInfoProfileRelated[0]
                    .avatar
                : "/assets/images/users/not-picture.svg"
            }
            alt={
              user?.placementProfileRelated?.firstName +
              " " +
              user?.placementProfileRelated?.lastName
            }
            className="w-full h-full"
          />
        </div>
        <div className="flex flex-col gap-0">
          <h2 className="">
            {user.placementProfileRelated &&
              user.placementProfileRelated.firstName +
                " " +
                user.placementProfileRelated.lastName}
          </h2>
          <span className="text-caspian-gray text-sm">
            {
              user?.placementPositionRelated?.positionJobRelated
                ?.jobLevelRelated?.title
            }
          </span>
        </div>
      </div>
      <div>
        <label
          htmlFor="imageFile"
          className="flex items-center gap-2 cursor-pointer rounded-md outline outline-1 outline-caspian-secondary-600 text-caspian-secondary-600 p-1.5 w-full"
        >
          <div className="flex items-center gap-1">
            <PiPencilSimpleLine className="text-lg" />
            <span className="text-sm">ویرایش تصویر</span>
          </div>
          <input
            name="file"
            className="hidden"
            ref={imageRef}
            id="imageFile"
            type="file"
            accept=".png,.jpg"
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
          />
        </label>
      </div>
      {showImage && (
        <PicturesView
          setShowImage={setShowImage}
          pictures={
            getAvatar !== null
              ? getAvatar
              : user?.placementProfileRelated?.personalInfoProfileRelated[0]
                  ?.avatar
              ? API.defaults.baseURL +
                user.placementProfileRelated.personalInfoProfileRelated[0]
                  .avatar
              : "/assets/images/users/not-picture.svg"
          }
        />
      )}
    </div>
  );
};

export default ProfilePicture;
