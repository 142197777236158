import { useNavigate, useSearchParams } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../../../../../context/auth/AuthContext";
import { useAppContext } from "../../../../../../context/app-context/AppContext";

const SingleTableListActionplan = ({
  id,
  title,
  code,
  manager,
  endDate,
  startDate,
  setRefreshPmoList,
  statusAction,
  isPlan,
}) => {
  const { token } = useContext(AuthContext);

  const [serachParams] = useSearchParams();
  const navigate = useNavigate();
  const { setBradCrump3 } = useAppContext();
  // const [showAction, setShowAction] = useState(true);
  const viewTeam = async () => {
    if (isPlan) {
      navigate(`/action-plan/my-actionplan/${id}/profile`);
    } else {
      navigate(`/action-plan/my-actionplan/${id}/action`);
    }
  };

  let statusName = "";
  let statusStyle = "";
  if (statusAction === "PMOPlan") {
    statusName = "برنامه ریزی PMO";
    statusStyle = "text-caspian-gray4";
  } else if (statusAction === "ManagerPlan") {
    statusName = "تکمیل پروفایل PM";
    statusStyle = "text-caspian-secondary-500";
  } else if (statusAction === "PMOConfirm") {
    statusName = "در انتظار تایید PMO";
    statusStyle = "text-caspian-secondary-500";
  } else if (statusAction === "ObseversCommentsApproval") {
    statusName = "در انتظار نظارت ناظر";
    statusStyle = "text-caspian-secondary-500";
  } else if (statusAction === "VarifyConfirm") {
    statusName = "در انتظار تایید کننده";
    statusStyle = "text-caspian-secondary-500";
  } else if (statusAction === "ApprovalConfirm") {
    statusName = "در انتظار تصویب کننده";
    statusStyle = "text-caspian-secondary-500";
  } else if (statusAction === "StartTask") {
    statusName = "تصویب شده";
    statusStyle = "text-[#01BC8D]";
  }

  return (
    <tr className="bg-caspian-surface duration-300">
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-right border-b border-b-caspian-gray2 px-3 py-2 w-[22.5%] cursor-pointer"
      >
        <h2>{title}</h2>
      </td>
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-right border-b border-b-caspian-gray2 px-3 py-2 w-[8%] cursor-pointer"
      >
        <h2>{code}</h2>
      </td>
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-right border-b border-b-caspian-gray2 px-3 py-2 w-[18.5%] cursor-pointer"
      >
        <h2>{manager}</h2>
      </td>
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-right border-b border-b-caspian-gray2 px-3 py-2 w-[18.5%] cursor-pointer"
      >
        <h2>{startDate}</h2>
      </td>
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-right border-b border-b-caspian-gray2 w-[18.5%] cursor-pointer"
      >
        <h2>{endDate}</h2>
      </td>
      <td className="whitespace-nowrap text-right border-b border-b-caspian-gray2 w-[15%]">
        <div className="flex items-center justify-between gap-2 pl-2">
          <span
            className={`
                ${statusStyle} py-0.5 px-1 text-xs`}
          >
            {statusName}
          </span>
        </div>
      </td>
    </tr>
  );
};

export default SingleTableListActionplan;
