import React from "react";
import { Link } from "react-router-dom";

const BasicInformationLayout = ({ showMenu }) => {
  return (
    <div
      className={
        showMenu
          ? `pl-[50px] mr-auto w-full md:w-[75%] lg:w-[80%] xl:w-[85%] 2xl:w-[85%] bg-caspian-gray3 p-1 overflow-y-scroll`
          : `pl-[50px] mr-[54px] w-full bg-caspian-gray3 p-1 overflow-y-scroll`
      }
    >
      <div className="flex items-center gap-4 p-4">
        <Link
          className="min-w-[120px] flex items-center justify-center bg-caspian-primary-100 hover:bg-caspian-primary-200 duration-300 text-xs p-2 rounded-md"
          to={"/goals/list"}
        >
          اهداف
        </Link>
        <Link
          className="min-w-[120px] flex items-center justify-center bg-caspian-primary-100 hover:bg-caspian-primary-200 duration-300 text-xs p-2 rounded-md"
          to={"/stackholders/list"}
        >
          ذینفعان
        </Link>
      </div>
    </div>
  );
};

export default BasicInformationLayout;
