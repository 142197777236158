import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../context/auth/AuthContext";
import API from "../../../api/API";

function Response({ setResponse, setReplay, replay, item, setListComments }) {
  const { token } = useContext(AuthContext);
  const replayRef = useRef()
  const [isDisable, setIsDisable] = useState(false)

  useEffect(() => {
    if (replay.length < 1) {
      setIsDisable(true)
    } else {
      setIsDisable(false)
    }
  }, [replay])


  const clearHandler = async () => {
    setResponse(false);
    setReplay("");
    replayRef.current.value = ""
  };

  // patch replay
  const formHandler = async () => {
    if (!token || !replay) return;

    try {
      // const res= await API.patch(`` , formData,
      // { headers: { Authorization: token } }
      // )
      if (item?.id === 1) {
        // setListComments({ ...item, replay: replay })
        clearHandler()
      }
    } catch (error) {
      console.log(error)
    }
  };


  return (
    <div className="flex items-center gap-2 pt-2 pb-4">
      <textarea
        ref={replayRef}
        placeholder="پاسخ خود را بنویسید"
        className="w-full resize-none text-xs outline-none text-caspian-gray border-2 border-caspian-gray2 rounded-lg p-2"
        rows={3}
        onChange={(e) => setReplay(e.target.value)}
      />

      <div className="flex flex-col items-center gap-3">
        <button
          disabled={isDisable ? true : false}
          onClick={formHandler}
          className={`text-xs py-[6px] px-3 cursor-pointer duration-500 rounded-[4px] 
          ${isDisable ? 'bg-caspian-gray text-caspian-gray1' : 'bg-[#01BC8D] text-caspian-surface'}`}>
          ارسال
        </button>
        <button
          type="button"
          onClick={clearHandler}
          className="text-caspian-error2 text-sm">
          لغو
        </button>
      </div>
    </div>
  );
}

export default Response;
