import React, { useState } from "react";
import AllNotebook from "./AllNotebook";
import { useParams } from "react-router-dom";
import DetailsNotebook from "./DetailsNotebook";
import MainAddNote from "../add-note/MainAddNote";
import SearchInput from "../SearchInput";
import { PiPlusCircle } from "react-icons/pi";

const MainNotebook = ({ fullDataNotebook, setRefresh }) => {
  const params = useParams();
  const [showAddNote, setShowAddNote] = useState(false);
  return (
    <div className="w-[75%] flex flex-col gap-4">
      {showAddNote && (
        <MainAddNote
          notebookData={fullDataNotebook.map((id) => ({
            id: id.id,
            title: id.title,
          }))}
          setShowAddNote={setShowAddNote}
          setRefresh={setRefresh}
        />
      )}
      <div className="flex items-center gap-2 w-full">
        <SearchInput />
        <button
          onClick={() => setShowAddNote(true)}
          className="flex items-center gap-1 rounded-md bg-caspian-secondary-500 text-caspian-surface p-2"
        >
          <PiPlusCircle className="text-xl" />
          <span className="text-xs">ایجاد یادداشت</span>
        </button>
      </div>
      {params?.notebook ? (
        <DetailsNotebook />
      ) : (
        <AllNotebook fullDataNotebook={fullDataNotebook} />
      )}
    </div>
  );
};

export default MainNotebook;
