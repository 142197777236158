import React, { useEffect, useState } from "react";
import ActionPlanNavbar from "../ActionPlanNavbar";
import ButtonTab from "./ButtonTab";
import MainExecutive from "./executive/MainExecutive";
import MainSupervision from "./supervision/MainSupervision";
import { useSearchParams } from "react-router-dom";
import MainReportsActionPlan from "./reports/MainReportsActionPlan";

const MainActions = () => {
  const [activeTab, setActiveTab] = useState("executive");
  const [serachParams, setSearchParams] = useSearchParams();
  const action = serachParams.get("action");

  useEffect(() => {
    if (action === "executive") {
      setActiveTab("executive");
    } else if (action === "supervision") {
      setActiveTab("supervision");
    } else if (action === "reports") {
      setActiveTab("reports");
    } else {
      setActiveTab("executive");
    }
  }, [action]);

  function handleSearch(key, value) {
    setSearchParams((prevParams) => {
      if (value === null || value === "") {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    });
  }

  return (
    <div>
      <ActionPlanNavbar buttonActive="actions" />
      <div className="w-full flex flex-col gap-4">
        <div className="flex items-center gap-4 py-1.5 px-2 rounded-md">
          <ButtonTab
            activeTab={activeTab}
            active="executive"
            onClick={() => {
              setActiveTab("executive");
              handleSearch("action", "executive");
              handleSearch("search", "")
            }}
            title="اقدامات اجرایی"
          />
          <ButtonTab
            activeTab={activeTab}
            active="supervision"
            onClick={() => {
              setActiveTab("supervision");
              handleSearch("action", "supervision");
              handleSearch("search", "")
            }}
            title="اقدامات نظارتی"
          />
          <ButtonTab
            activeTab={activeTab}
            active="reports"
            onClick={() => {
              setActiveTab("reports");
              handleSearch("action", "reports");
              handleSearch("search", "")
            }}
            title="لیست گزارش‌ها"
          />
        </div>
      </div>
      <div className="p-2">
        {activeTab === "executive" ? (
          <MainExecutive />
        ) : activeTab === "supervision" ? (
          <MainSupervision />
        ) : (
          activeTab === "reports" && <MainReportsActionPlan />
        )}
      </div>
    </div>
  );
};

export default MainActions;
