import React from "react";

const Details = ({ data }) => {
  console.log(data)
  return (
    <div className="w-full flex flex-col gap-4">
      <h2 className="whitespace-nowrap">شناسنامه سند</h2>

      <div className="grid grid-cols-2">
        <div className="flex items-center gap-2 basis-[60%] w-full py-4">
          <h2 className="text-caspian-gray text-sm font-normal">عنوان سند:</h2>
          <h2 className="text-sm font-medium">
            {data?.title ? data?.title : "-"}
          </h2>
        </div>

        <div className="flex items-center gap-2 basis-[40%] w-full py-4">
          <h2 className="text-caspian-gray text-sm font-normal">کد سند:</h2>
          <h2 className="text-sm font-medium">
            {data?.code ? data?.code.toString() : "-"}
          </h2>
        </div>

        <div className="flex items-center gap-2 basis-[40%] w-full border-t border-t-caspian-gray3 py-4">
          <h2 className="text-caspian-gray text-sm font-normal">نوع:</h2>
          <h2 className="text-sm font-medium">
            {data?.externalDocumentTypeRelated
              ? data?.externalDocumentTypeRelated?.title
              : "-"}
          </h2>
        </div>

        <div className="flex items-center gap-2 basis-[40%] w-full border-t border-t-caspian-gray3 py-4">
          <h2 className="text-caspian-gray text-sm font-normal">نام مرجع:</h2>
          <h2 className="text-sm font-medium">
            {data?.reference ? data?.reference : "-"}
          </h2>
        </div>

        <div className="flex items-center gap-2 basis-[40%] w-full border-t border-t-caspian-gray3 py-4">
          <h2 className="text-caspian-gray text-sm font-normal">
            تاریخ انتشار:
          </h2>
          <h2 className="text-sm font-medium">
            {data?.publicationDate
              ? new Date(data.publicationDate).toLocaleDateString("fa-IR")
              : "-"}
          </h2>
        </div>

        <div className="flex items-center gap-2 basis-[40%] w-full border-t border-t-caspian-gray3 py-4">
          <h2 className="text-caspian-gray text-sm font-normal">
            تاریخ انقضا:
          </h2>
          <h2 className="text-sm font-medium">
            {data?.withdrawalDate
              ? new Date(data.withdrawalDate).toLocaleDateString("fa-IR")
              : "-"}
          </h2>
        </div>

        <div className="flex items-center gap-2 basis-[40%] w-full border-t border-t-caspian-gray3 py-4">
          <h2 className="text-caspian-gray text-sm font-normal">
            دوره به روز رسانی:
          </h2>
          <h2 className="text-sm font-medium">
            {data?.UpdatePeriod ? data.UpdatePeriod : "-"}
          </h2>
        </div>

        <div className="flex items-center gap-2 basis-[40%] w-full border-t border-t-caspian-gray3 py-4">
          <h2 className="text-caspian-gray text-sm font-normal">
            پست مسئول به روزرسانی:
          </h2>
          <h2 className="text-sm font-medium">
            {data?.externalUpdatingPositionRelated?.positionJobRelated
              ? data?.externalUpdatingPositionRelated?.positionJobRelated?.title
              : "-"}
          </h2>
        </div>

        <div className="flex items-center gap-2 basis-[40%] w-full border-t border-t-caspian-gray3 py-4">
          <h2 className="text-caspian-gray text-sm font-normal">
            مستندات درون سازمانی مرتبط:
          </h2>
          {data?.InternalDocumentRelated?.map((d, i) => (
            <h2 key={i} className="text-sm font-medium">
              {d?.InternalDocumentTypeRelated?.symbol + (d?.code).toString()}
            </h2>
          ))}
        </div>

        <div className="flex items-center gap-2 basis-[40%] w-full border-t border-t-caspian-gray3 py-4">
          <h2 className="text-caspian-gray text-sm font-normal">
            طبقه بندی دسترسی:
          </h2>
          <button
            className="text-[#FF5B5B] bg-[#FF5B5B12] p-1.5 rounded-lg text-xs"
            disabled
          >
            {data?.privacy.toLowerCase().includes("public") &&
              "عمومی(داخل و برون سازمانی)"}
            {data?.privacy.toLowerCase().includes("internal") && "داخلی"}
            {data?.privacy.toLowerCase().includes("restricted") &&
              "دسترسی بر اساس طبقه‌بندی"}
            {data?.privacy.toLowerCase().includes("confidential") && "محرمانه"}
            {data?.privacy.toLowerCase().includes("secret") && "فوق محرمانه"}
          </button>
        </div>
      </div>

      {data?.fileDocument && data.obsolete ? (
        <div className="flex items-center gap-4">
          <button
            type="button"
            disabled
            className="rounded-md outline outline-1 outline-caspian-secondary-600 text-caspian-secondary-600 text-sm p-1.5 disabled:outline-caspian-gray disabled:text-caspian-gray"
          >
            دانلود سند
          </button>
          <button
            type="button"
            disabled
            className="rounded-md outline outline-1 outline-caspian-secondary-600 text-caspian-secondary-600 text-sm p-1.5 disabled:outline-caspian-gray disabled:text-caspian-gray"
          >
            نمایش
          </button>
        </div>
      ) : (
        data?.fileDocument &&
        !data.obsolete && (
          <div className="flex items-center gap-4">
            <a
              href={data?.fileDocument}
              download={data?.fileDocument}
              target="_blank"
              rel="noreferrer"
              className=""
            >
              <button
                type="button"
                className="rounded-md outline outline-1 outline-caspian-secondary-600 text-caspian-secondary-600 text-sm p-1.5"
              >
                دانلود سند
              </button>
            </a>
            <a
              href={data?.fileDocument}
              download={data?.fileDocument}
              target="_blank"
              rel="noreferrer"
              className=""
            >
              <button
                type="button"
                className="rounded-md outline outline-1 outline-caspian-secondary-600 text-caspian-secondary-600 text-sm p-1.5"
              >
                نمایش
              </button>
            </a>
          </div>
        )
      )}
    </div>
  );
};

export default Details;
