import React, { useState } from "react";
import MainListTasks from "./list-tasks/MainListTasks";
import SearchInput from "./SearchInput";
import { PiFunnel, PiSortAscending, PiUsers } from "react-icons/pi";

const MainExecutive = () => {
  const [serachTask, setSerachTask] = useState("");
  const [statusTask, setStatusTask] = useState("all");
  const [roleTask, setRoleTask] = useState("all");
  const [sortTask, setSortTask] = useState("default");

  return (
    <div className="flex flex-col gap-0">
      <div className="flex items-center justify-between w-full">
        <SearchInput setSerachTask={setSerachTask} />
        <div className="flex items-center gap-2">
          <div className="flex flex-col gap-1">
            <label className="text-xs text-caspian-gray">نقش</label>
            <div className="relative">
              <PiUsers className="text-caspian-gray text-xl absolute right-2 top-1/2 -translate-y-1/2" />
              <select
                onChange={(e) => setRoleTask(e.target.value)}
                className="rounded-[4px] pr-8 outline outline-1 outline-caspian-gray2 p-2 font-light text-sm w-full cursor-pointer"
              >
                <option value="all">همه</option>
                <option value="SUPERVISOR">مجری تسک</option>
                <option value="RESPONSIBLE">ناظر تسک</option>
                <option value="MANAGER">مدیر برنامه اجرایی</option>
              </select>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-xs text-caspian-gray">وضعیت</label>
            <div className="relative">
              <PiFunnel className="text-caspian-gray text-xl absolute right-2 top-1/2 -translate-y-1/2" />
              <select
                onChange={(e) => setStatusTask(e.target.value)}
                className="rounded-[4px] pr-8 outline outline-1 outline-caspian-gray2 p-2 font-light text-sm w-full cursor-pointer"
              >
                <option value="all">همه</option>
                <option value="Working">درحال انجام</option>
                <option value="NotStarted">شروع نشده</option>
                <option value="Done">انجام شده</option>
                <option value="prePlaning">قبل از تصویب</option>
                <option value="Closed">بسته شده</option>
                <option value="Delayed">با تاخیر</option>
              </select>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-xs text-caspian-gray">مرتب‌سازی</label>
            <div className="relative">
              <PiSortAscending className="text-caspian-gray text-xl absolute right-2 top-1/2 -translate-y-1/2" />
              <select
                onChange={(e) => setSortTask(e.target.value)}
                className="rounded-[4px] pr-8 outline outline-1 outline-caspian-gray2 p-2 font-light text-sm w-full cursor-pointer"
              >
                <option value="default">پیشفرض</option>
                <option value="a_start">زودترین آغاز</option>
                <option value="b_start">دیرترین آغاز</option>
                <option value="a_end">نزدیکترین پایان</option>
                <option value="b_end">دیرترین پایان</option>
                <option value="a_progress">کمترین پیشرفت</option>
                <option value="b_progress">بیشترین پیشرفت</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <MainListTasks
        serachTask={serachTask}
        statusTask={statusTask}
        roleTask={roleTask}
        sortTask={sortTask}
      />
    </div>
  );
};

export default MainExecutive;
