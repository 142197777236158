import React, { useEffect, useRef, useState } from "react";
import MenuActionBox from "./MenuActionBox";

const MenuAction = ({
  conversationId,
  setRefreshMyconversation,
  firstIndex,
  // setConversationIdId,
  open,
  setOpen,
}) => {
  let menuRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
        setIsDelete(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const [isDelete, setIsDelete] = useState(false);

  return (
    <div ref={menuRef} className="">
      <MenuActionBox
        open={open}
        setOpen={setOpen}
        isDelete={isDelete}
        setIsDelete={setIsDelete}
        firstIndex={firstIndex}
        conversationId={conversationId}
        // setConversationIdId={setConversationIdId}
        setRefreshMyconversation={setRefreshMyconversation}
      />
    </div>
  );
};

export default MenuAction;
