import React, { useEffect, useState } from "react";
import RowView from "./RowView";
import Paginate from "./Paginate";
import { useSearchParams } from "react-router-dom";
import { PiCircleNotch } from "react-icons/pi";
import SingleTableListPmo from "./SingleTableListActionplan";

const TableListActionplan = ({ listActionplan, loading, setRefreshPmoList }) => {
  const [filterByTitle, setFilterByTitle] = useState("");
  const [filterByManager, setFilterByManager] = useState("");
  const [filterByStartDate, setFilterByStartDate] = useState("");
  const [filterByEndDate, setFilterByEndDate] = useState("");

  const [searchParams] = useSearchParams();
  const search = searchParams.get("search");
  const page = searchParams.get("page");
  const rows = searchParams.get("rows");

  let filterTitle = [];
  let filterManager = [];
  let filterEndDate = [];
  let filterStartDate = [];

  useEffect(() => {
    if (search) {
      setCurrentPage(1);
    }
  }, [search]);

  let newData = [-1];
  if (listActionplan[0] !== -1) {
    newData = search
      ? listActionplan?.filter((d) =>
          d?.title?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase())
        )
      : listActionplan;
  }

  if (newData[0] !== -1) {
    filterTitle = filterByTitle
      ? newData.filter((d) =>
          d?.title
            .toLocaleLowerCase()
            .includes(filterByTitle?.toLocaleLowerCase())
        )
      : newData;

    filterManager = filterByManager
      ? filterTitle?.filter((d) =>
          d?.actionplan_responsibles[0]?.manager
            ?.toLocaleLowerCase()
            ?.includes(filterByManager?.toLocaleLowerCase())
        )
      : filterTitle;

    filterStartDate = filterByStartDate
      ? filterManager.filter((d) =>
          d?.actionplan_detail[0]?.startDate
            .toLocaleLowerCase()
            .includes(filterByStartDate?.toLocaleLowerCase())
        )
      : filterManager;

    filterEndDate = filterByEndDate
      ? filterStartDate.filter((d) =>
          (d?.actionplan_detail[0]?.endDate)
            .toLocaleLowerCase()
            .includes(filterByEndDate?.toLocaleLowerCase())
        )
      : filterStartDate;
  }

  const [currentPage, setCurrentPage] = useState(page ? page : 1);
  const [recordsPerPage, setRecordsPerPage] = useState(rows ? rows : 10);
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = filterEndDate.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filterEndDate.length / recordsPerPage);

  return (
    <div className="flex flex-col gap-4">
      {loading ? (
        <div className="flex items-center justify-center py-40">
          <PiCircleNotch className="text-caspian-secondary-600 text-2xl animate-spin" />
        </div>
      ) : (
        <div className="flex flex-col overflow-x-auto">
          <div className="sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-x-auto">
                <table className="min-w-full text-left text-sm font-light">
                  <thead className="border-b border-b-caspian-gray2 font-medium">
                    <tr>
                      <th
                        scope="col"
                        className="rounded-tr-xl bg-caspian-surface text-right px-3 py-6 w-[22.5%]"
                      >
                        <input
                          // onClick={() => setCurrentPage(1)}
                          onChange={(e) => {
                            setFilterByTitle(e.target.value);
                          }}
                          type="search"
                          placeholder="عنوان تیم"
                          className="rounded-md outline outline-1 outline-caspian-gray2 p-1.5 font-light text-sm w-full"
                        />
                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-surface text-right px-3 py-6 w-[18.5%]"
                      >
                        <input
                          onChange={(e) => setFilterByManager(e.target.value)}
                          type="search"
                          placeholder="مدیر پروژه"
                          className="rounded-md outline outline-1 outline-caspian-gray2 p-1.5 font-light text-sm w-full"
                        />
                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-surface text-right px-3 py-6 w-[22.5%]"
                      >
                        <input
                          onChange={(e) => setFilterByStartDate(e.target.value)}
                          type="search"
                          placeholder="تاریخ شروع"
                          className="rounded-md outline outline-1 outline-caspian-gray2 p-1.5 font-light text-sm w-full"
                        />
                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-surface text-right px-3 py-6 w-[22.5%]"
                      >
                        <input
                          onChange={(e) => setFilterByEndDate(e.target.value)}
                          type="search"
                          placeholder="تاریخ پایان"
                          className="rounded-md outline outline-1 outline-caspian-gray2 p-1.5 font-light text-sm w-full"
                        />
                      </th>
                      <th
                        scope="col"
                        className="bg-caspian-surface rounded-tl-xl text-center px-3 py-6 w-[15%]"
                      ></th>
                    </tr>
                  </thead>
                  {records.length > 0 ? (
                    <tbody>
                      {records.map((d, i) => (
                        <SingleTableListPmo
                          key={i}
                          id={d.id}
                          //عنوان تیم
                          title={d.title}
                          //مدیر پروژه
                          // manager={
                          //   d?.actionplan_responsibles[0]?.manager?.firstName +
                          //   " " +
                          //   d?.actionplan_responsibles[0]?.manager?.lastName
                          // }
                          manager={"مدیر"}
                          // شروع کار
                          // startDate={
                          //   d?.actionplan_detail[0]?.startDate
                          //     ? new Date(
                          //         d?.actionplan_detail[0]?.startDate
                          //       ).toLocaleDateString("fa-IR")
                          //     : "-"
                          // }
                          startDate={"12/12/1403"}
                          // پایان کار
                          // endDate={
                          //   d?.actionplan_detail[0]?.endDate
                          //     ? new Date(
                          //         d?.actionplan_detail[0]?.endDate
                          //       ).toLocaleDateString("fa-IR")
                          //     : "-"
                          // }
                          endDate={"12/12/1403"}
                          // وضعیت
                          statusAction={d?.statusAction}
                          setRefreshPmoList={setRefreshPmoList}
                        />
                      ))}
                    </tbody>
                  ) : (
                    <tbody className="py-24">
                      <tr>
                        <td className="py-24">
                          <div className="flex items-center justify-center w-full py-10">
                            <div className="z-10 absolute left-1/2 -translate-x-1/2 mx-auto">
                              <div className="flex flex-col gap-2 items-center justify-center w-full">
                                <img
                                  src="/assets/images/library/not-found.svg"
                                  alt="برنامه سازمانی یافت نشد..."
                                />
                                <h2>برنامه سازمانی یافت نشد...</h2>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      {records.length > 0 && (
        <div className="flex items-center justify-between py-2">
          <RowView
            setRecordsPerPage={setRecordsPerPage}
            setCurrentPage={setCurrentPage}
          />
          <Paginate
            pageCount={npage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
          <div className="text-sm flex items-center gap-4">
            <span>نمایش {firstIndex + 1}</span>
            <span>تا {lastIndex - (recordsPerPage - records.length)}</span>
            <span>از کل {filterEndDate.length}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableListActionplan;
