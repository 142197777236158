import { useNavigate, useSearchParams } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../../../context/auth/AuthContext";
import { useAppContext } from "../../../../context/app-context/AppContext";

const SingleTableListActionplan = ({
  id,
  title,
  code,
  manager,
  endDate,
  startDate,
  status,
  remainedWeight,
  statusAction,
  isPlan,
  setRefreshPmoList,
  remainedDay,
  progress,
  duration,
}) => {
  const { token } = useContext(AuthContext);

  const [serachParams] = useSearchParams();
  const navigate = useNavigate();
  const { setBradCrump3 } = useAppContext();
  // const [showAction, setShowAction] = useState(true);
  const viewTeam = async () => {
    if (isPlan) {
      navigate(`/action-plan/my-actionplan/${id}/profile`);
    } else {
      navigate(`/action-plan/my-actionplan/${id}/action`);
    }
  };
  let statusName = "";
  let statusClass = "";

  if (status === "Working") {
    statusName = "در حال انجام";
    statusClass = "text-[#3C77A2]";
  } else if (status === "NotStarted") {
    statusName = "شروع نشده";
    statusClass = "text-[#99999999]";
  } else if (status === "Done") {
    statusName = "انجام شده";
    statusClass = "text-[#01BC8D]";
  } else if (status === "PrePlaning") {
    statusName = "درحال برنامه‌ریزی";
    statusClass = "text-[#D6A834]";
  } else if (status === "Closed") {
    statusName = "بسته شده";
    statusClass = "text-[#FF440A]";
  }

  let widthDuration =
    duration - remainedDay < 1 ? 0 : 100 - (remainedDay / duration) * 100;

  let durationStyle = "";

  if (widthDuration < 33.3) {
    durationStyle = "bg-[#01BC8D]";
  } else if (widthDuration > 33.4 && widthDuration < 66.5) {
    durationStyle = "bg-[#D6A834]";
  } else if (widthDuration > 66.6) {
    durationStyle = "bg-[#FF440A]";
  }

  return (
    <tr className="bg-caspian-surface duration-300">
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-2 py-4 w-[20%] cursor-pointer"
      >
        <h2 className="text-xs">{title}</h2>
      </td>
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-2 py-4 w-[12.5%] cursor-pointer"
      >
        <h2 className="text-xs">{code}</h2>
      </td>
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-2 py-4 w-[12.5%] cursor-pointer"
      >
        <h2 className="text-xs">{manager}</h2>
      </td>
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-2 py-4 w-[10%] cursor-pointer"
      >
        <h2 className="text-xs">{startDate}</h2>
      </td>
      <td
        onClick={viewTeam}
        className="border-b border-b-caspian-gray2 px-2 py-4 w-[10%] cursor-pointer"
      >
        <div className="text-xs h-1 w-full bg-caspian-gray2/50 relative rounded-full overflow-hidden">
          <div
            style={{ width: widthDuration + "%" }}
            className={`text-xs h-1 rounded-full ${durationStyle}`}
          ></div>
        </div>
      </td>
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 w-[10%] cursor-pointer"
      >
        <h2 className="text-xs">{endDate}</h2>
      </td>
      <td
        onClick={viewTeam}
        className="whitespace-nowrap text-center border-b border-b-caspian-gray2 px-2 py-4 w-[15%] cursor-pointer"
      >
        <div className="text-xs h-1 w-full bg-caspian-gray2/50 relative rounded-full">
          <div
            style={{ width: progress + "%", maxWidth: "100%" }}
            className="text-xs h-1 bg-caspian-secondary-600 rounded-full"
          ></div>
          <span className="text-xs absolute text-caspian-secondary-600 bottom-1 left-0">
            %{progress}
          </span>
        </div>
      </td>
      <td className="whitespace-nowrap text-center border-b border-b-caspian-gray2 w-[10%]">
        <div className="flex items-center justify-center gap-2 pl-2">
          <span className={`${statusClass} text-xs`}>{statusName}</span>
        </div>
      </td>
    </tr>
  );
};

export default SingleTableListActionplan;
