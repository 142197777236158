import { useContext, useEffect, useState } from "react";
import TableListActionplan from "./TableListActionplan";
import { AuthContext } from "../../../../context/auth/AuthContext";
import API from "../../../../api/API";
import SearchInput from "./SearchInput";
import { PiFunnel, PiSortAscending, PiUsers } from "react-icons/pi";
import { useSearchParams } from "react-router-dom";

const MainListActionplan = () => {
  const { token } = useContext(AuthContext);
  const [fullData, setfullData] = useState([-1]);
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search");

  const [serachTask, setSerachTask] = useState("");
  const [statusTask, setStatusTask] = useState("all");
  const [roleTask, setRoleTask] = useState("all");
  const [sortTask, setSortTask] = useState("default");

  useEffect(() => {
    if (token) {
      // let newUrl = "/api/v1/actionplan/actionplanWithRole/";
      let url = "/api/v1/actionplan/actionplanLISTROLE";
      const getPmo = async () => {
        API.get(url, {
          headers: { Authorization: token },
        })
          .then((res) => {
            setfullData(
              res.data?.filter((d) => d?.statusAction === "StartTasks")
            );
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      };
      getPmo();
    }
  }, [token]);

  let searchTasks = [-1];
  let filterRole = [];
  let filterStatus = [];
  let sortData = [];
  if (fullData[0] !== -1) {
    
    searchTasks = serachTask
      ? fullData?.filter(
          (s) =>
            s?.description?.includes(serachTask) ||
            s?.title?.includes(serachTask) ||
            ("AP-" + s?.id)?.includes(serachTask) ||
            s?.actionplanRelated?.title?.includes(serachTask) ||
            (
              s?.actionplan_responsibles?.manager?.firstName +
              " " +
              s?.actionplan_responsibles?.manager?.lastName
            )?.includes(serachTask)
        )
      : fullData;
    filterRole =
      roleTask !== "all"
        ? searchTasks.filter((d) => d.userRole.includes(roleTask))
        : searchTasks;
    filterStatus =
      statusTask !== "all"
        ? filterRole.filter((d) => d.status === statusTask)
        : filterRole;
    sortData =
      sortTask === "a_start"
        ? filterStatus?.sort((a, b) =>
            b?.actionplan_detail?.startDate > a?.actionplan_detail?.startDate
              ? -1
              : 1
          )
        : sortTask === "b_start"
        ? filterStatus?.sort((a, b) =>
            b?.actionplan_detail?.startDate > a?.actionplan_detail?.startDate
              ? 1
              : -1
          )
        : sortTask === "a_end"
        ? filterStatus?.sort((a, b) =>
            b?.actionplan_detail?.endDate > a?.actionplan_detail?.endDate
              ? -1
              : 1
          )
        : sortTask === "b_end"
        ? filterStatus?.sort((a, b) =>
            b?.actionplan_detail?.endDate > a?.actionplan_detail?.endDate
              ? 1
              : -1
          )
        : sortTask === "a_progress"
        ? filterStatus?.sort((a, b) =>
            b?.actionplan_detail?.progress > a?.actionplan_detail?.progress
              ? -1
              : 1
          )
        : sortTask === "b_progress"
        ? filterStatus?.sort((a, b) =>
            b?.actionplan_detail?.progress > a?.actionplan_detail?.progress
              ? 1
              : -1
          )
        : filterStatus?.sort((a, b) =>
            b?.created_at > a?.created_at ? -1 : 1
          );
  }

  useEffect(() => {
    if (search) {
      setSerachTask(search);
    }
  }, [search]);

  return (
    <div className="flex flex-col gap-0">
      <div className="flex items-center justify-between w-full">
        <SearchInput setSerachTask={setSerachTask} />
        <div className="flex items-center gap-2">
          <div className="flex flex-col gap-1">
            <label className="text-xs text-caspian-gray">نقش</label>
            <div className="relative">
              <PiUsers className="text-caspian-gray text-xl absolute right-2 top-1/2 -translate-y-1/2" />
              <select
                onChange={(e) => setRoleTask(e.target.value)}
                className="rounded-[4px] pr-8 outline outline-1 outline-caspian-gray2 p-2 font-light text-sm w-full cursor-pointer"
              >
                <option value="all">همه</option>
                <option value="MANAGER">مدیر برنامه اجرایی</option>
                <option value="CONTROLLER">کنترل کننده</option>
                <option value="VARIFY_PLACEMENT">تایید کننده</option>
                <option value="APPROVAL_PLACEMENT">تصویب کننده</option>
                <option value="OBSERVER">ناظر</option>
              </select>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-xs text-caspian-gray">وضعیت</label>
            <div className="relative">
              <PiFunnel className="text-caspian-gray text-xl absolute right-2 top-1/2 -translate-y-1/2" />
              <select
                onChange={(e) => setStatusTask(e.target.value)}
                className="rounded-[4px] pr-8 outline outline-1 outline-caspian-gray2 p-2 font-light text-sm w-full cursor-pointer"
              >
                <option value="all">همه</option>
                <option value="Working">درحال انجام</option>
                <option value="NotStarted">شروع نشده</option>
                <option value="Done">انجام شده</option>
                <option value="prePlaning">قبل از تصویب</option>
                <option value="Closed">بسته شده</option>
              </select>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-xs text-caspian-gray">مرتب‌سازی</label>
            <div className="relative">
              <PiSortAscending className="text-caspian-gray text-xl absolute right-2 top-1/2 -translate-y-1/2" />
              <select
                onChange={(e) => setSortTask(e.target.value)}
                className="rounded-[4px] pr-8 outline outline-1 outline-caspian-gray2 p-2 font-light text-sm w-full cursor-pointer"
              >
                <option value="default">پیشفرض</option>
                <option value="a_start">زودترین آغاز</option>
                <option value="b_start">دیرترین آغاز</option>
                <option value="a_end">نزدیکترین پایان</option>
                <option value="b_end">دیرترین پایان</option>
                <option value="a_progress">کمترین پیشرفت</option>
                <option value="b_progress">بیشترین پیشرفت</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <TableListActionplan listActionplan={sortData} />
    </div>
  );
};

export default MainListActionplan;
